import {AppBar, CardContent, Grid, Link as MaterialLink, List, Tab, Tabs, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {withRouter} from "react-router";
import {useActions} from "../../../actions";
import * as AccountActions from "../../../actions/account";
import * as CarrierAction from "../../../actions/carrier";
import {MultiLifeTable, Pagination} from "../../../components/table";
import {ImoDashboardListType} from "../../../model/account";
import {RootState} from "../../../reducers";
import {dateUtils} from "../../../utils";
import {ImoAdminActions} from "../../agent/AgentProfile/AdminActions";
import {BackButton, DetailBreadcrumb, DetailLayout} from "../../agent/AgentProfile/AgentProfile";
import {QuickViewSection} from "../../agent/dashboard/AgentDashboard";
import {CardSnapshot} from "../../client/ClientProfile/component/ClientSnapshot";
import {a11yProps, TabPanel, useStyles} from "../../client/ClientProfile/component/TabsMenu";
import {ImoAgentTable} from "../dashboard/ImoAgentTable";
import {ImoKpis, useImoStatistics} from "../dashboard/ImoDashboard";
import {ImoProfileTabReduxForm} from "./ImoProfileTab";
import {ImoTeamMembersTable} from "./ImoTeamMembersTable";
import {can} from "../../../components/navigation/AdminNavigation";
import {getImoNameByValue, PermissionTypes} from "../../../constants";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {DeleteModel} from "../../../components/formInputs";
import {AccountStatusArr} from "../../../model";
import TableWrap from "../../../components/TableWrap/TableWrap";

import Overrides from "../../Overrides";
import AssignDivision from "../../../components/imo/AssignDivision";

const ImoProfile = (props: any) => {
    const accountActions = useActions(AccountActions);
    const carrierAction = useActions(CarrierAction);
    const account = useSelector((state: RootState) => state.account);

    const { match } = props;
    const userId: string = match.params.id;
    const role = 3;

    React.useEffect(() => {
        carrierAction.getAllCarriers();
        accountActions.getAccount(userId, role);
    }, [userId, role]);    

    const name = account.imo && account.imo.groupName

    return (
        <DetailLayout title="IMO Detail">
            <Grid item xs={12} md={12} lg={9}>
                <BackButton history={props.history} />
                <DetailBreadcrumb of="Imo" to='/accounts/imo' name={name} />
            </Grid>
            <Grid item xs={12} md={12} lg={8} xl={9}>
                <Typography variant="h2" className="floatLeft w100">
                    Imo Details {name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TabsMenu
                    role={role}
                    userId={userId}
                />
            </Grid>
        </DetailLayout>
    )
}

export function LinkTab(props) {
    const match: any = useRouteMatch();
    const { value } = props;

    return (
      <Tab
        component={Link}
        to={`${match.url}/${value}`} 
        {...props}
        {...a11yProps(value)}
      />
    );
  }


const imoTabs = [
  "profile",
  "agents",
  "groups",
  "team-members",
  "quick-view",
  "documents",
  "overrides"
]

export const useTabWithLocation = (tabs: string[], defaultTab: string = tabs[0]) => {
    let location = useLocation();
    
    const [value, setValue] = React.useState(defaultTab);
    React.useEffect(() => {
        const tabValue = location.pathname.split("/").pop();
        if(tabValue) {
            setValue(tabs.includes(tabValue)? tabValue: defaultTab);
        }
    }, [location])

    return {value, setValue};
}

const TabsMenu = ({ userId, role }) => {
    const account = useSelector((state: RootState) => state.account);
    const auth = useSelector((state: RootState) => state.auth);
    const classes = useStyles();
    const {value, setValue} = useTabWithLocation(imoTabs, "profile");
    const [assignDivision, setAssignDivision] = React.useState(false);
    const {
        totalAgentsInvited,
        totalAgentAccounts,
        totalActiveAgents,
        clientStatistics,
        leadsStatistics, 
        groupStatistics
    } = useImoStatistics(userId)
    
    const handleChange = async (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue)
    };

    const [refresh, setRefresh] = useState(0);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="scrollable force tabs example"
                        >
                            <LinkTab label="IMO PROFILE" value={imoTabs[0]}/>
                            <LinkTab label="IMO AGENTS" value={imoTabs[1]} />
                            <LinkTab label="IMO GROUPS" value={imoTabs[2]} />
                            <LinkTab label="IMO TEAM MEMBERS" value={imoTabs[3]} />
                            <LinkTab label="IMO QUICK VIEW" value={imoTabs[4]} />
                            <LinkTab label="DOCUMENTS" value={imoTabs[5]} />
                            {(auth.user.role === 1 || auth.user.role === 2) &&
                                    <LinkTab label="IMO OVERRIDES" value={imoTabs[6]} />
                            }
                        </Tabs>

                    </AppBar>
                </Grid>
                <Grid item xs={12} md={12} lg={8} >
                    <TabPanel value={value} index={imoTabs[0]}>
                        <ImoProfileTabReduxForm initialValues={account} userId={userId}/>
                    </TabPanel>
                    <TabPanel value={value} index={imoTabs[1]}>
                            <ImoAgentsTab imoUserId={userId}/>
                    </TabPanel>
                    <TabPanel value={value} index={imoTabs[2]}>
                        <ImoGroupsTab imoUserId={userId} />                        
                    </TabPanel>
                    <TabPanel value={value} index={imoTabs[3]}>
                        <ImoTeamMembersTab imoUserId={userId} refresh={refresh}/>                        
                    </TabPanel>
                    <TabPanel value={value} index={imoTabs[4]}>
                        <ImoKpis
                            imoUserId={userId}
                            totalAgentsInvited={totalAgentsInvited}
                            totalAgentAccounts={totalAgentAccounts}
                            totalActiveAgents={totalActiveAgents}
                            clientStatistics={clientStatistics}
                            leadsStatistics={leadsStatistics} 
                            groupStatistics={groupStatistics}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={imoTabs[5]}>
                        Coming soon ...
                    </TabPanel>
                    {(auth.user.role === 1 || auth.user.role === 2) &&
                        <TabPanel value={value} index={imoTabs[6]}>
                          <Overrides userRole={3} />
                        </TabPanel>
                    }
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    {
                        auth.user && (can(auth.user, PermissionTypes.ACCOUNT_MANAGEMENT) && (auth.user.role === 1 || auth.user.role === 2)) &&    
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <ImoAdminActions 
                                    account={account} 
                                    userId={userId} 
                                    role={role}
                                    onAddTeamMember={() => {setRefresh(prevRefresh => prevRefresh + 1)}} 
                                />
                            </Grid>
                        </Grid>
                    }
                    <ImoSnapshot 
                        totalClients={clientStatistics.totalAccountCreated}
                        enrolling={clientStatistics.enrolling}
                        enrolled={clientStatistics.enrolled}
                        totalGroups={groupStatistics.totalGroups}
                        totalAgents={totalAgentAccounts}
                        dateJoinedIlia={account.createdAt}
                        status={AccountStatusArr[account.inviteStatus || 0]}
                        imo={account.imo}
                        setAssignDivision={setAssignDivision}
                    />
                </Grid>
                {
                    assignDivision &&
                        <AssignDivision open={assignDivision} handleClose={()=>{setAssignDivision(false)}} userId={userId} imoDetails={account.imo}/>
                }
            </Grid>
        </div>
    )
}

const ImoTeamMembersTab = ({ imoUserId, refresh }) => {
    return (
        <div>
            <ImoTeamMembersTableSection refresh={refresh} imoUserId={imoUserId} title="Invited team members" isAccountCreated={false} />
            <ImoTeamMembersTableSection refresh={refresh} imoUserId={imoUserId} title="Current team members" isAccountCreated />
        </div>
    )
}

const ImoTeamMembersTableSection = ({ imoUserId, title, isAccountCreated, refresh }) => {
    const imoTeamMemberActions = useActions(AccountActions);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [imoTeamMemberList, setImoTeamMemberList] = useState({ rows: [], count: 0 });

    React.useEffect(() => {
        getImoTeamMembers();
    }, [page, rowsPerPage, imoUserId, refresh ]);

    const [teamMemberUserIdToDelete, setTeamMemberUserIdToDelete] = useState("");

    const getImoTeamMembers = async () => {
        let queryObj: any = { isAccountCreated, page, rowsPerPage, searchText: "", filter: 2, sortBy: "createdAt", order: "desc" };
        let response = await imoTeamMemberActions.listImoTeamMembers(queryObj, imoUserId);
        if (response.status) {
            setImoTeamMemberList(response.data);
        }
        return;
    };

    const deleteUser = async () => {
        await imoTeamMemberActions.deleteAdminImoTeamMember(teamMemberUserIdToDelete);
        setTeamMemberUserIdToDelete("");
        await getImoTeamMembers();
    };

    const handleNotificationStatus = async (imoMemberId:number, status:boolean) => {
        let payload:any = {
            sendNotification: status
        }
        await imoTeamMemberActions.updateImoAdditionalMembersTableByKeyValPair(payload, imoMemberId, true, "Updated Successfully", 200);
        await getImoTeamMembers();
    }

    return (
        <QuickViewSection title={title}>
            <TableWrap>
                <ImoTeamMembersTable 
                    kpiData={imoTeamMemberList.rows} 
                    onDelete={setTeamMemberUserIdToDelete}
                    handleNotificationStatus={handleNotificationStatus}/>
            </TableWrap>
            <Pagination page={page} rowsPerPage={rowsPerPage} setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    accountList={imoTeamMemberList}/>
            {
                teamMemberUserIdToDelete &&
                <DeleteModel
                        open={true}
                        handleClose={() => setTeamMemberUserIdToDelete("")}
                        handleYes={deleteUser}
                        accountType={"IMO Team Member"}
                        title={"IMO Team Member"}
                        content={`Are you sure you want to delete this IMO Team Member?`}
                        disableDelete={false}
                />
            }
        </QuickViewSection>
    )
}



const ImoGroupsTab = withRouter((props: any) => {
    const { history, imoUserId } = props;
    const [kpiData, setKpiData] = React.useState([]);
    const accountActions = useActions(AccountActions);
    const page = 0;
    const rowsPerPage = 25;
    const doNone = () => null


    useEffect(() => {
        getImoGroups(imoUserId)
    }, [imoUserId])
    
    const getImoGroups = async (imoUserId) => {
        let resp = await accountActions.getImoDashboardLists({ 
            listType: ImoDashboardListType.TotalGroups, 
            listDataType: "json",
            imoUserId
        });
        
        if (resp && resp.length > 0) {
            setKpiData(resp);
        }
    }

    return (
        <TableWrap>
            <MultiLifeTable
                accountList={{rows: kpiData}}
                order={"NONE"}
                orderBy={"NONE"}
                handleRequestSort={doNone}
                callingFrom='accountList'
                history={history}
                handleRefresh={doNone}
                page={page}
                rowsPerPage={rowsPerPage}
        />
        </TableWrap>
    )
})

const ImoAgentsTab = ({ imoUserId }) => {
    const [kpiData, setKpiData] = React.useState([]);
    const accountActions = useActions(AccountActions);

    useEffect(() => {
        getImoAgents(imoUserId)
    }, [imoUserId])
    
    const getImoAgents = async (imoUserId) => {
        let resp = await accountActions.getImoDashboardLists({ 
            listType: ImoDashboardListType.TotalAgentAccounts, 
            listDataType: "json",
            imoUserId
        });
        
        if (resp && resp.length > 0) {
            setKpiData(resp);
        }
    }

    return (
       <TableWrap>
            <ImoAgentTable kpiData={kpiData} />
       </TableWrap>
    )
}

const ImoSnapshot = ({ totalClients, enrolling, enrolled, totalGroups, totalAgents, dateJoinedIlia , imo, status, setAssignDivision}) => {
    return (
        <CardSnapshot title="IMO Snapshot">
             <CardContent className="floatLeft w100">
                <List className="ClientDetailsList">
                    {
                        imo &&
                        <li>
                            <label>IMO Type</label> 
                            <span className="labelValue">{getImoNameByValue(imo.imoClass)}</span>
                        </li>
                    }
                    {
                        imo &&
                        <li>
                            <label>IMO Status</label> 
                            <span className="labelValue">{status}</span>
                        </li>
                    }
                    <li>
                        <label>Date Joined NIW</label> 
                        <span className="labelValue">{dateJoinedIlia? dateUtils.asDbDateFormat(dateJoinedIlia):'NA'}</span>
                    </li>
                    {
                        imo &&
                        <li>
                            <label>IMO Region</label> 
                            <span className="labelValue">{imo.region}</span>
                        </li>
                    }
                    {
                            imo &&
                            <li>
                                <label>IMO Aggregator</label>
                                <span className="labelValue">{imo.aggregator ? imo.aggregator.aggregatorName : 'NA' }</span>
                            </li>
                    }
                    {
                            imo &&
                            <li>
                                <label>IMO Aggregator Division</label>
                                <span className="labelValue">{imo.division ? imo.division.divisionName : <>{imo.aggregator && imo.aggregator.aggregatorName ? <>
                                    <MaterialLink variant="body2"
                                                  className={'clickableLink'}
                                                  onClick={() => {
                                                      console.log("click called");
                                                      setAssignDivision(true)
                                                  }}>
                                        Assign Division
                                    </MaterialLink></> : "NA"}</>}</span>
                            </li>
                    }
                    <li>
                        <label>Total Agents</label> 
                        <span className="labelValue">{totalAgents}</span>
                    </li>
                    <li>
                        <label>Total Clients</label> 
                        <span className="labelValue">{totalClients}</span>
                    </li>
                    <li>
                        <label>Clients Currently Enrolling</label> 
                        <span className="labelValue">{enrolling}</span>
                    </li>
                    <li>
                        <label>Total Enrollments Completed</label> 
                        <span className="labelValue">{enrolled}</span>
                    </li>
                    <li>
                        <label>Total Groups</label> 
                        <span className="labelValue">{totalGroups}</span>
                    </li>
                </List>
            </CardContent>
        </CardSnapshot>
    )
}

export default ImoProfile