import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#e5e5e5",
			main: "#3971EC",
			dark: "#3971EC",
			contrastText: "#fff",
		},
		secondary: {
			light: "#ff5e50",
			main: "#d2d4d5",
			dark: "#bec0c1",
			contrastText: "#fff",
		},
	}
});

export default theme;

