import {createBrowserHistory} from "history";
import * as localforage from "localforage";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {PersistConfig, persistReducer, persistStore} from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {ActionType} from './model'
import {Api} from "./api";
import {API_URL} from './constants';
import {isDev} from "./utils/commonUtils";


//Staging //Local

const api = new Api(API_URL);

const actionTypeEnumToString = (action: {type: unknown, payload?: unknown}): any => typeof action.type === 'number' && ActionType[action.type] ? ({
  type: ActionType[action.type],
  payload: action.payload,
}) : action;

const persistConfig: PersistConfig = {
	key: "root",
	version: 1,
	storage: localforage,
	whitelist: ["auth", "leftPanelOpen"],
};

const composeEnhancers = composeWithDevTools({ actionSanitizer: actionTypeEnumToString });
const history = createBrowserHistory();

let middleware = isDev ?  applyMiddleware(thunk.withExtraArgument(api)) :  applyMiddleware(thunk.withExtraArgument(api));

if (isDev) {
	middleware = composeEnhancers(middleware);
}

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default () => {
	const store = createStore(persistedReducer, {}, middleware);
	// persistStore(store).purge()
	const persistor = persistStore(store);
	return { store, persistor };
};

export { history };
