import React  from "react";
import {Checkbox, FormHelperText, Typography} from "@material-ui/core";
import {Field, FieldArray, useFormikContext} from "formik";
import {Grid} from "@material-ui/core";
import {TextField} from "../../../../../../../components/formikFormInputs";
import _ from "lodash";
import debounce from "lodash/debounce";
import {Label} from "../../../../../../../components/formInputs";
import { NOTA } from "../../MedicalAndFinancial/component/MedicalPageQuestions";
import { ErrorHelper } from "../../../../../../../components/formikFormInputs/ErrorCommon";
import { PIQ } from "../InsuredQuestions";

/**
 * @constructor
 * @param props (
 * name: A string representing the name of the field.
 * fieldName: A string representing the name of the field's subfield.
 * field: An object containing information about the field, like placeholder and label.
 * values: An object containing values for the fields.
 * setFieldValue: A function used to set the value of a field.
 * clientSigned: A boolean value indicating whether the client has signed or not.
 * )
 * return This component is meant to render a TextField inside a Field component.
 */
const RenderChildField = (props:{name:string, fieldName:string, field:any, values:any, setFieldValue:Function, clientSigned:boolean}) => {
    const {name, fieldName, field, values, setFieldValue, clientSigned} = props;

    const setValueForCheckBoxDesc = debounce((name, value) => setFieldValue(name, value), 500);

    const handleChangeCheckBoxDesc = ({ target }) => {
        let index:number = _.findIndex(values[name], (e:any) => {
            return e && e.name === fieldName
        },0);
        if(index > -1) {
            setValueForCheckBoxDesc(`${name}.${index}.desc`, target.value)
        }
    };

    const setDefaultValueForDesc = (fieldName) => {
        let index:number = _.findIndex(values[name], (e:any) => {
            return e && e.name === fieldName
        },0);
        if(index > -1) {
            return values[name][index].desc
        }
        return "";
    };

    return <Field
            className="mt10 ml15 mb15"
            fullWidth
            name={`${fieldName}_desc`}
            component={TextField}
            placeholder={field.placeholder}
            label={<Label label={field.label} required={true}/>}
            onChange={handleChangeCheckBoxDesc}
            defaultValue={setDefaultValueForDesc(fieldName)}
            variant="outlined"
            disabled={clientSigned}
    />
}

/**
 * @param clientSigned
 * @param question
 * @constructor
 * return FieldArray(Component)
 */
export default function InsuredPageCheckBox({ clientSigned, question }: { question: any, clientSigned: boolean }) {
    const { values, setFieldValue, errors, handleChange, handleBlur }: any = useFormikContext();
    const name:string = question && question.name;

    /**
     * return Boolean
     * @param fieldArray
     * @param fieldName
     */
    const checkBoxDefaultValue = (fieldArray:any, fieldName:string) => {
        let index = _.findIndex(fieldArray, (e:any) => {
            return e && e.name === fieldName;
        }, 0);
        return index > -1;
    }

    /**
     * This function dynamically handles both nested and non-nested checkbox fields
     * return Function
     * @param values
     * @param name
     * @param key
     * @param fieldValue
     */
    const isChecked = (values, name, key, fieldValue) => {
        let accessArray = name.split(".");
        if (accessArray.length > 1) {
            let accessValue = values;
            for (let k of accessArray) {
                accessValue = accessValue[k] || "";
            }
            return checkBoxDefaultValue(accessValue, key);
        } else {
            return checkBoxDefaultValue(values[name], key);
        }
    }

    const handleNoneOfThese = async (name: string) => {
        return await setFieldValue(name, []);
    }

    /**
     * function is used to remove an element from an array field
     * @param arrayHelpers
     * @param e
     */
    const removeElement = (arrayHelpers, e) => {
        let accessArray = name.split(".");
        if (accessArray.length > 1) {
            let accessValue = values;
            for (let k of accessArray) {
                accessValue = accessValue[k] || "";
            }
            let index = _.findIndex(accessValue, (ee: any) => {
                return ee && ee.name === e.target.name;
            }, 0);
            arrayHelpers.remove(index);

        } else {
            let index = _.findIndex(values[name], (ee: any) => {
                return ee && ee.name === e.target.name;
            }, 0);
            arrayHelpers.remove(index);
        }
    }



    return (
            <FieldArray
                    name={name}
                    render={arrayHelpers => {
                        return (
                                <Grid container spacing={1}>
                                    {
                                            question && question.additionalFieldOptions && question.additionalFieldOptions.map((fieldVal: any, index: number) => {
                                                const fieldName: string = `${name}_${index}`;
                                                const handleChkBoxChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    if (e.target.checked) {
                                                        let obj: any = {
                                                            name: fieldName,
                                                            value: fieldVal.value,
                                                            desc: "",
                                                        };
                                                        if ((obj && obj.value) === NOTA) {
                                                            await handleNoneOfThese(name).then(() => {
                                                                arrayHelpers.push(obj);
                                                            });
                                                            setFieldValue(`${PIQ}-60XT_desc`,"empty");
                                                        } else {
                                                            let index = _.findIndex(values[name], (ee: any) => {
                                                                return ee && ee.value === NOTA;
                                                            }, 0);
                                                            if (index > -1) {
                                                                setFieldValue(`${PIQ}-60XT_desc`, "");
                                                                arrayHelpers.remove(index);
                                                            }
                                                            arrayHelpers.push(obj);
                                                        }
                                                    } else {
                                                        removeElement(arrayHelpers, e)
                                                    }
                                                };
                                                return (
                                                        <Grid item xs={12} lg={6}>
                                                            <label key={fieldVal.value} className="checkboxStyle ml0">
                                                                <Checkbox
                                                                        name={fieldName}
                                                                        value={fieldVal.value}
                                                                        //checked={checkBoxDefaultValue(values[name], fieldName)}
                                                                        checked={isChecked(values, name, fieldName, fieldVal.value)}
                                                                        onChange={handleChkBoxChange}
                                                                        disabled={clientSigned}
                                                                />
                                                                <Typography
                                                                        className="mb0 pt9"
                                                                        variant="body1"
                                                                >
                                                                    {fieldVal.text}
                                                                </Typography>
                                                            </label>
                                                            {
                                                                    (checkBoxDefaultValue(values[name], fieldName) && fieldVal.showDetailsBox) &&
                                                                    <>
                                                                        <RenderChildField
                                                                                clientSigned={clientSigned}
                                                                                field={fieldVal}
                                                                                fieldName={`${fieldName}`}
                                                                                name={name}
                                                                                setFieldValue={setFieldValue}
                                                                                values={values}
                                                                        />
                                                                        <ErrorHelper
                                                                                errors={errors}
                                                                                name={`${fieldName}_desc`}
                                                                        />
                                                                    </>
                                                            }
                                                        </Grid>
                                                );
                                            })
                                    }
                                    <ErrorHelper errors={errors} name={name} />


                                    {
                                            (question && question.additionalSubFieldType && question.additionalSubFieldName && question.additionalSubFieldName !== `${PIQ}-60XT_desc`) ||
                                            (question && question.additionalSubFieldType && question.additionalSubFieldName &&
                                                    (values &&
                                                            values[`${PIQ}-60XC`] &&
                                                            values[`${PIQ}-60XC`][0] &&
                                                            values[`${PIQ}-60XC`][0]["name"] !== `${PIQ}-60XC_4`)
                                            ) &&
                                            <>
                                                <div className="floatLeft w100">
                                                    <Label label={question.additionalFieldLabel} />
                                                </div>
                                                <Field
                                                        fullWidth
                                                        name={question.additionalSubFieldName}
                                                        component={TextField}
                                                        value={values[question.additionalSubFieldName]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        label={<Label label={question.additionalSubFieldLabel} required={true}/>}
                                                        placeholder={question.additionalSubFieldLabel}
                                                        disabled={clientSigned}
                                                        variant="outlined"
                                                />
                                                <ErrorHelper errors={errors} name={question.additionalSubFieldName} />
                                            </>
                                    }
                                </Grid>
                        )
                    }}
            />
    );
}