import { NOTA as _NOTA } from "../../../../../../../constants";


// From here its Checkbox Till 680 lines
export const NAME_IDENTIFIER = "MedicalQuestionsX";
export const NOTA = _NOTA;
const commonProps = {
    placeholder: "Please Provide Details",
    label: "Please Provide Details",
    showDetailsBox: false,
    showStaticName: false,
    detailBoxType: "text"
}

const medicalQuestionsCommonProps = {
    required: true,
    requiredDescription: true
}

const medicalTobaccoQuestionsCommonProps = {
    required: true
}

export enum DetailBoxType {
    Date = "date",
    Text = "text",
}
const mTQsTimeSince = [
    { label: "This week", type: DetailBoxType.Text },
    { label: "2-3 years ago", type: DetailBoxType.Date },
    { label: "In the last 12 months", type: DetailBoxType.Text },
    { label: "More than 3 years ago", type: DetailBoxType.Date },
    { label: "1-2 years ago", type: DetailBoxType.Date },
    { label: "NEVER USED" },
];

const additionalFieldOptionsList = (index: number) => {
    const generateJsonArr = () => {
        return mTQsTimeSince.map(({ label, type }) => {
            const nvrUsedCase = label === "NEVER USED";
            return {
                ...commonProps,
                detailBoxType: type,
                label: (!nvrUsedCase && type === DetailBoxType.Date) ? "Select Date" : "Specify Amount",
                showDetailsBox: !nvrUsedCase,
                showStaticName: true,
                text: label,
                value: label,
            }
        });
    };
    const objectInitiator = {
        0: [
            {
                ...commonProps,
                value: "Amyotrophic Lateral Sclerosis(ALS)/Lou Gehrig's Disease",
                text: "Amyotrophic Lateral Sclerosis(ALS)/Lou Gehrig's Disease"
            },
            {
                ...commonProps,
                value: "Muscular Dystrophy",
                text: "Muscular Dystrophy"
            },
            {
                ...commonProps,
                value: "Aneurysm",
                text: "Aneurysm"
            },
            {
                ...commonProps,
                value: "Nervous Disorder",
                text: "Nervous Disorder"
            },
            {
                ...commonProps,
                value: "Anxiety including PTSD, ADD, ADHD, and OCD",
                text: "Anxiety including PTSD, ADD, ADHD, and OCD"
            },
            {
                ...commonProps,
                value: "Neuromuscular Degeneration",
                text: "Neuromuscular Degeneration"
            },
            {
                ...commonProps,
                value: "Cerebral Hemorrhage",
                text: "Cerebral Hemorrhage"
            },
            {
                ...commonProps,
                value: "Parkinson’s Disease",
                text: "Parkinson’s Disease"
            },
            {
                ...commonProps,
                value: "Depression",
                text: "Depression"
            },
            {
                ...commonProps,
                value: "Psychiatric Disorder",
                text: "Psychiatric Disorder"
            },
            {
                ...commonProps,
                value: "Seizure/Epilepsy",
                text: "Seizure/Epilepsy"
            },
            {
                ...commonProps,
                value: "Stroke or Transient Ischemic Attack (TIA)/Mini-Stroke",
                text: "Stroke or Transient Ischemic Attack (TIA)/Mini-Stroke"
            },
            {
                ...commonProps,
                value: "Dizziness, Numbness, or Weakness",
                text: "Dizziness, Numbness, or Weakness"
            },
            {
                ...commonProps,
                value: "Other disease or disorder of the brain/nervous system",
                text: "Other disease or disorder of the brain/nervous system"
            },
            {
                ...commonProps,
                value: "Eating Disorder",
                text: "Eating Disorder"
            },
            {
                ...commonProps,
                value: "Paralysis",
                text: "Paralysis"
            },
            {
                ...commonProps,
                value: "Huntington’s Disease",
                text: "Huntington’s Disease"
            },
            {
                ...commonProps,
                value: "Mental Disorder",
                text: "Mental Disorder"
            },
            {
                ...commonProps,
                value: "Multiple Sclerosis (MS)",
                text: "Multiple Sclerosis (MS)"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        1: [
            {
                ...commonProps,
                value: "Anemia",
                text: "Anemia",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Heart Attack",
                text: "Heart Attack",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Cardiomyopathy",
                text: "Cardiomyopathy",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Heart Disease or Valvular Heart Disease",
                text: "Heart Disease or Valvular Heart Disease",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Chest pain",
                text: "Chest pain",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Heart Murmur",
                text: "Heart Murmur",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Congestive Heart Failure",
                text: "Congestive Heart Failure",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "High Blood Pressure",
                text: "High Blood Pressure",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Coronary Artery Disease",
                text: "Coronary Artery Disease",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Irregular Heartbeat",
                text: "Irregular Heartbeat",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Elevated Cholesterol or Triglycerides",
                text: "Elevated Cholesterol or Triglycerides",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Peripheral Vascular Disease (excluding varicose veins)",
                text: "Peripheral Vascular Disease (excluding varicose veins)",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Heart Arrhythmia",
                text: "Heart Arrhythmia",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Other disease or disorder of the heart/blood system",
                text: "Other disease or disorder of the heart/blood system",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA,
                showDetailsBox: false
            }
        ],
        2: [
            {
                ...commonProps,
                value: "Asthma",
                text: "Asthma",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Emphysema",
                text: "Emphysema",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Chronic Obstructive Pulmonary Disease (COPD)",
                text: "Chronic Obstructive Pulmonary Disease (COPD)",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Sleep Apnea",
                text: "Sleep Apnea",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Cystic Fibrosis",
                text: "Cystic Fibrosis",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Other disease of the lungs/respiratory system",
                text: "Other disease of the lungs/respiratory system",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA,
                showDetailsBox: false
            }
        ],
        3: [
            {
                ...commonProps,
                value: "Barrett’s Esophagus",
                text: "Barrett’s Esophagus",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Hepatitis",
                text: "Hepatitis",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Biliary Cholangitis",
                text: "Biliary Cholangitis",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Other disease or disorder of the liver or an abnormal liver enzyme test",
                text: "Other disease or disorder of the liver or an abnormal liver enzyme test",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Cirrhosis",
                text: "Cirrhosis",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Other disease or disorder of the pancreas",
                text: "Other disease or disorder of the pancreas",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Colitis/Ulcerative Colitis",
                text: "Colitis/Ulcerative Colitis",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Other disease or disorder of the rectum or intestines",
                text: "Other disease or disorder of the rectum or intestines",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Crohn’s/Regional Enteritis",
                text: "Crohn’s/Regional Enteritis",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Other disease or disorder of the stomach or esophagus",
                text: "Other disease or disorder of the stomach or esophagus",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Gluten Intolerance/Celiac",
                text: "Gluten Intolerance/Celiac",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA,
                showDetailsBox: false
            }
        ],
        4: [
            {
                ...commonProps,
                value: "Disease or disorder of the Breasts",
                text: "Disease or disorder of the Breasts",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Disease or disorder of the Reproductive System",
                text: "Disease or disorder of the Reproductive System",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Disease or disorder of the Genitals",
                text: "Disease or disorder of the Genitals",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Disease or disorder of the Urinary System",
                text: "Disease or disorder of the Urinary System",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Disease or disorder of the Prostate",
                text: "Disease or disorder of the Prostate",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Sexually Transmitted Diseases (excluding HIV, AIDS, and ARC)",
                text: "Sexually Transmitted Diseases (excluding HIV, AIDS, and ARC)",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Disease or disorder of the Kidneys or an abnormal urine test or blood kidney function test",
                text: "Disease or disorder of the Kidneys or an abnormal urine test or blood kidney function test",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA,
                showDetailsBox: false
            }
        ],
        5: [
            {
                ...commonProps,
                value: "Diabetes including Borderline Diabetes, Impaired Glucose Intolerance (IGT), and Gestational Diabetes",
                text: "Diabetes including Borderline Diabetes, Impaired Glucose Intolerance (IGT), and Gestational Diabetes",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Disease or disorder of the Thyroid or other Endocrine Glands",
                text: "Disease or disorder of the Thyroid or other Endocrine Glands",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Disease or disorder of Lymph Glands",
                text: "Disease or disorder of Lymph Glands",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA,
                showDetailsBox: false
            }
        ],
        6: [
            {
                ...commonProps,
                value: "Arthritis",
                text: "Arthritis",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Joint Replacement",
                text: "Joint Replacement",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Back Trouble or Back Surgery",
                text: "Back Trouble or Back Surgery",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Osteoporosis",
                text: "Osteoporosis",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Chronic Fatigue",
                text: "Chronic Fatigue",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Systemic Lupus (SLE)",
                text: "Systemic Lupus (SLE)",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Chronic Pain",
                text: "Chronic Pain",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Other disease or disorder of the joints, muscles, or bones",
                text: "Other disease or disorder of the joints, muscles, or bones",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: "Fibromyalgia",
                text: "Fibromyalgia",
                showDetailsBox: false
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA,
                showDetailsBox: false
            }
        ],
        7: [
            {
                ...commonProps,
                value: "Disease or disorder of the nose",
                text: "Disease or disorder of the nose"
            },
            {
                ...commonProps,
                value: "Disease or disorder of the throat",
                text: "Disease or disorder of the throat"
            },
            {
                ...commonProps,
                value: "Disease or disorder of the skin",
                text: "Disease or disorder of the skin"
            },
            {
                ...commonProps,
                value: "Disease or disorder of the ears",
                text: "Disease or disorder of the ears"
            },
            {
                ...commonProps,
                value: "Disease or disorder of the eyes (excluding glasses, corrective lens, & Lasik)",
                text: "Disease or disorder of the eyes (excluding glasses, corrective lens, & Lasik)"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        8: [
            {
                ...commonProps,
                value: "Disease or disorder of the Immune System (excluding HIV, AIDS, and ARC)",
                text: "Disease or disorder of the Immune System (excluding HIV, AIDS, and ARC)"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        9: [
            {
                ...commonProps,
                value: "Consultation or check-up",
                text: "Consultation or check-up"
            },
            {
                ...commonProps,
                value: "Surgical operation",
                text: "Surgical operation"
            },
            {
                ...commonProps,
                value: "Prescription for medication(s)",
                text: "Prescription for medication(s)"
            },
            {
                ...commonProps,
                value: "Treatment or diagnosis for any other medical condition not previously disclosed",
                text: "Treatment or diagnosis for any other medical condition not previously disclosed"
            },
            {
                ...commonProps,
                value: "In-patient or out-patient in a hospital, clinic, medical facility, or similar entity (other than for normal childbirth)",
                text: "In-patient or out-patient in a hospital, clinic, medical facility, or similar entity (other than for normal childbirth)"
            },
            {
                ...commonProps,
                value: "Diagnostic test, including EKG, mammogram, colonoscopy, MRI, CT Scan, ultrasound, blood test, or urine test (excluding any disorder related to HIV Antibody, T-Cell, AIDS, or ARC)",
                text: "Diagnostic test, including EKG, mammogram, colonoscopy, MRI, CT Scan, ultrasound, blood test, or urine test (excluding any disorder related to HIV Antibody, T-Cell, AIDS, or ARC)"
            },
            {
                ...commonProps,
                value: "Refusal of or not yet completed recommended medical test, medical treatment, surgery or hospitalization, (excluding any disorder related to HIV Antibody, T-Cell, AIDS, or ARC)",
                text: "Refusal of or not yet completed recommended medical test, medical treatment, surgery or hospitalization, (excluding any disorder related to HIV Antibody, T-Cell, AIDS, or ARC)"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        10: [
            {
                ...commonProps,
                value: "Disability or long-term care insurance plan",
                text: "Disability or long-term care insurance plan"
            },
            {
                ...commonProps,
                value: "State or federal disability program",
                text: "State or federal disability program"
            },
            {
                ...commonProps,
                value: "Medical assistance/Medicaid",
                text: "Medical assistance/Medicaid"
            },
            {
                ...commonProps,
                value: "Worker’s compensation",
                text: "Worker’s compensation"
            },
            {
                ...commonProps,
                value: "State or county assistance program",
                text: "State or county assistance program"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        11: [
            {
                ...commonProps,
                value: "Alzheimer’s Disease",
                text: "Alzheimer’s Disease"
            },
            {
                ...commonProps,
                value: "Incontinence or bowel function abnormality",
                text: "Incontinence or bowel function abnormality"
            },
            {
                ...commonProps,
                value: "Confusion",
                text: "Confusion"
            },
            {
                ...commonProps,
                value: "Tremor",
                text: "Tremor"
            },
            {
                ...commonProps,
                value: "Dementia or Memory Loss",
                text: "Dementia or Memory Loss"
            },
            {
                ...commonProps,
                value: "Trouble swallowing",
                text: "Trouble swallowing"
            },
            {
                ...commonProps,
                value: "Imbalance, gait disturbance, or falling",
                text: "Imbalance, gait disturbance, or falling"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        12: [
            {
                ...commonProps,
                value: "Bathing",
                text: "Bathing"
            },
            {
                ...commonProps,
                value: "Driving",
                text: "Driving"
            },
            {
                ...commonProps,
                value: "Eating",
                text: "Eating"
            },
            {
                ...commonProps,
                value: "Managing Money",
                text: "Managing Money"
            },
            {
                ...commonProps,
                value: "Mobility",
                text: "Mobility"
            },
            {
                ...commonProps,
                value: "Toileting",
                text: "Toileting"
            },
            {
                ...commonProps,
                value: "Managing Medication",
                text: "Managing Medication"
            },
            {
                ...commonProps,
                value: "Dressing",
                text: "Dressing"
            },
            {
                ...commonProps,
                value: "Using the Telephone",
                text: "Using the Telephone"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        13: [
            {
                ...commonProps,
                value: "Brace",
                text: "Brace"
            },
            {
                ...commonProps,
                value: "Wheelchair",
                text: "Wheelchair"
            },
            {
                ...commonProps,
                value: "Dialysis machine",
                text: "Dialysis machine"
            },
            {
                ...commonProps,
                value: "Catheter",
                text: "Catheter"
            },
            {
                ...commonProps,
                value: "Walker",
                text: "Walker"
            },
            {
                ...commonProps,
                value: "Respirator",
                text: "Respirator"
            },
            {
                ...commonProps,
                value: "Cane",
                text: "Cane"
            },
            {
                ...commonProps,
                value: "Other medical equipment or appliance",
                text: "Other medical equipment or appliance"
            },
            {
                ...commonProps,
                value: "Oxygen equipment",
                text: "Oxygen equipment"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        14: generateJsonArr(),
        15: generateJsonArr(),
        16: generateJsonArr(),
        17: generateJsonArr(),
        18: generateJsonArr(),
        19: generateJsonArr(),
        20: generateJsonArr(),
        21: generateJsonArr(),
        22: generateJsonArr(),
        23: [
            {
                ...commonProps,
                value: "Been advised by physician to reduce consumption, discontinue use, or to seek counseling",
                text: "Been advised by physician to reduce consumption, discontinue use, or to seek counseling"
            },
            {
                ...commonProps,
                value: "Sought advice/counseling or been a member of any self-help group such as Alcoholics Anonymous or Narcotics Anonymous",
                text: "Sought advice/counseling or been a member of any self-help group such as Alcoholics Anonymous or Narcotics Anonymous"
            },
            {
                ...commonProps,
                value: "Received treatment/counseling",
                text: "Received treatment/counseling"
            },
            {
                ...commonProps,
                value: NOTA,
                text: NOTA
            }
        ],
        24: [
            {
                ...commonProps,
                value: "Daily",
                text: "Daily"
            },
            {
                ...commonProps,
                value: "Periodically on a monthly basis, not every week",
                text: "Periodically on a monthly basis, not every week"
            },
            {
                ...commonProps,
                value: "4-5 days per week",
                text: "4-5 days per week"
            },
            {
                ...commonProps,
                value: "Periodically on an annual basis, not every month",
                text: "Periodically on an annual basis, not every month"
            },
            {
                ...commonProps,
                value: "2-3 days per week",
                text: "2-3 days per week"
            },
            {
                ...commonProps,
                value: "I do not drink alcoholic beverages",
                text: "I do not drink alcoholic beverages"
            },
            {
                ...commonProps,
                value: "1 day per week",
                text: "1 day per week"
            }
        ],
        25:[
            {
                ...commonProps,
                value: "9 or more",
                text: "9 or more"
            },
            {
                ...commonProps,
                value: "1-3",
                text: "1-3"
            },
            {
                ...commonProps,
                value: "7-8",
                text: "7-8"
            },
            {
                ...commonProps,
                value: "I do not drink alcoholic beverages",
                text: "I do not drink alcoholic beverages"
            },
            {
                ...commonProps,
                value: "4-6",
                text: "4-6"
            }
        ],
        26:[
            {
                ...commonProps,
                value: "Daily",
                text: "Daily"
            },
            {
                ...commonProps,
                value: "5-11 times per year",
                text: "5-11 times per year"
            },
            {
                ...commonProps,
                value: "16-24 times per month",
                text: "16-24 times per month"
            },
            {
                ...commonProps,
                value: "1-4 times per year",
                text: "1-4 times per year"
            },
            {
                ...commonProps,
                value: "9-15 times per month",
                text: "9-15 times per month"
            },
            {
                ...commonProps,
                value: "I do not use marijuana",
                text: "I do not use marijuana"
            },
            {
                ...commonProps,
                value: "1-8 times per month",
                text: "1-8 times per month"
            }
        ],
        27:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            }
        ],
        28:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            }
        ],
        29:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            }
        ],
        30:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            }
        ],
        31:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            }
        ],
        32:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            }
        ],
        33:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            }
        ],
        34:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            }
        ],
        35:[
            {
                ...commonProps,
                value: "Use on a regular basis",
                text: "Use on a regular basis"
            },
            {
                ...commonProps,
                value: "Used more than 10 years ago",
                text: "Used more than 10 years ago"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 5 years",
                text: "Used once or more within the last 5 years"
            },
            {
                ...commonProps,
                value: "NEVER USED",
                text: "NEVER USED"
            },
            {
                ...commonProps,
                value: "Used once or more within the last 6 to 10 years",
                text: "Used once or more within the last 6 to 10 years"
            }
        ]
    }
    return objectInitiator[index];
};

export const medicalQuestions: any = [
    {
        ...medicalQuestionsCommonProps,
        key: 0,
        label: "Brain / Nervous System",
        labelHeading: "Over the last 10 years, please indicate for which of the following you have received advice, treatment, or a diagnosis from a licensed member of the medical profession (check ALL that apply)",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(0),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 1,
        label: "Heart / Blood System",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(1),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 2,
        label: "Lungs / Respiratory System",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(2),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 3,
        label: "Digestive System",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(3),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 4,
        label: "Excretory & Reproductive System",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(4),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 5,
        label: "Glandular System",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(5),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 6,
        label: "Skeletal System",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(6),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 7,
        label: "Eyes, Ears, Nose, Throat & Skin",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(7),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 8,
        label: "Immune System",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(8),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 9,
        labelText: "Over the last 5 years, please indicate which of the following you’ve had completed by a licensed member of the medical profession that was not disclosed in a previous question (check ALL that apply).",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(9),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 10,
        labelText: "Over the last 5 years, please indicate if you have received benefits from any of the following (check ALL that apply).",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(10),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 11,
        labelText: "Over the last 5 years, please indicate for which of the following you have received advice, treatment, or a diagnosis from a licensed member of the medical profession (check ALL that apply).",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(11),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 12,
        labelText: "Over the last 12 months, please indicate for which of the following you have required or currently require assistance or supervision or for which your performance was limited (check ALL that apply).",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(12),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
    {
        ...medicalQuestionsCommonProps,
        key: 13,
        labelText: "Over the last 12 months, please indicate for which of the following you have required or currently require the use of (check ALL that apply).",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(13),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "CHECKBOXGROUP",
        requiredDescription: false,
        showMedicalDetail: true
    },
];
export const medicalTobaccoQuestions: any = [
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 14,
        name: "Medical_QuestionsX14",
        label: "Cigar",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(14),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP"
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 15,
        name: "Medical_QuestionsX15",
        label: "Cigarette, e-cigarette, electronic cigarette",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(15),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP"
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 16,
        name: "Medical_QuestionsX16",
        label: "Pipe",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(16),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP"
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 17,
        name: "Medical_QuestionsX17",
        label: "Vapor product, vaping device, hookah, shisha, bong, juuling, water pipe, e-pipe, electronic pipe",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(17),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP"
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 18,
        name: "Medical_QuestionsX18",
        label: "Chewing tobacco",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(18),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP"
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 19,
        name: "Medical_QuestionsX19",
        label: "Betel nuts",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(19),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP"
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 20,
        name: "Medical_QuestionsX20",
        label: "Snuff",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(20),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP"
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 21,
        name: "Medical_QuestionsX21",
        label: "Nicotine patch, nicotine gum",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(21),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP"
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 22,
        name: "Medical_QuestionsX22",
        label: "Other nicotine delivery product",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(22),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "RADIOGROUP"
    },
];


export type MedFieldType = {
    additionalField: boolean;
    additionalFieldOptions: any;
    key: number;
    labelHeading?: string;
    labelText?: string;
    label?: string;
    name: string;
    required: boolean;
    showBottomHrLine: boolean;
    showUpperHrLine: boolean;
    type: string;
}

export const medicalAlcoholQuestions: MedFieldType[] = [
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 23,
        name: "Medical_QuestionsX23",
        labelHeading: "Over the last 10 years, please indicate which of the following best describes your experience with the use of alcohol, prescription drugs, and/or non-prescription drugs?",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(23),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 24,
        name: "Medical_QuestionsX24",
        labelHeading: "On average, how many days per week do you typically consume alcoholic beverages?",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(24),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 25,
        name: "Medical_QuestionsX25",
        labelHeading: "On average, how many alcoholic beverages do you typically consume per sitting?",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(25),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "RADIOGROUP",
    },
];
export const medicalMarijuanaQuestions: MedFieldType[] = [
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 26,
        name: "Medical_QuestionsX26",
        labelHeading: "On average, how often do you typically use marijuana?",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(26),
        showUpperHrLine: false,
        showBottomHrLine: true,
        type: "RADIOGROUP",
    },
];
export const medicalNarcoticsQuestions: MedFieldType[] = [
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 27,
        name: "Medical_QuestionsX27",
        labelText: "Cocaine",
        labelHeading: "On average, how often do you typically use the following narcotics, excluding usage prescribed by a physician?",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(27),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 28,
        name: "Medical_QuestionsX28",
        label: "Heroin",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(28),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 29,
        name: "Medical_QuestionsX29",
        label: "Amphetamines (including Methamphetamine)",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(29),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 30,
        name: "Medical_QuestionsX30",
        label: "Opium, Opiates, Opioids",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(30),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 31,
        name: "Medical_QuestionsX31",
        label: "Barbiturates",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(31),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 32,
        name: "Medical_QuestionsX32",
        label: "Morphine",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(32),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 33,
        name: "Medical_QuestionsX33",
        label: "LSD",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(33),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 34,
        name: "Medical_QuestionsX34",
        label: "PCP",
        additionalField: false,
        additionalFieldOptions: additionalFieldOptionsList(34),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
    {
        ...medicalTobaccoQuestionsCommonProps,
        key: 35,
        name: "Medical_QuestionsX35",
        label: "Other Hallucinogenic, Narcotic Drug, Controlled Substance, or Habit Forming Drug?",
        additionalField: true,
        additionalFieldOptions: additionalFieldOptionsList(35),
        showUpperHrLine: false,
        showBottomHrLine: false,
        type: "RADIOGROUP",
    },
];