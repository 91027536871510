import React from "react";
import MUIRadio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { RadioProps } from "@material-ui/core/Radio";
interface CustomRadioProps extends RadioProps {
	label: string
}
export const Radio = ({
												checked,
												color,
												disabled,
												id,
												inputRef,
												label,
												onChange,
												value,
												className
											}:CustomRadioProps)  => (
	<FormControlLabel
		control={
			<MUIRadio
				checked={checked}
				color={color}
				disabled={disabled}
				id={id}
				inputRef={inputRef}
				value={value}
				onChange={onChange}
				className={className}
				data-testid="radio"
			/>
		}
		label={label}
		labelPlacement="end"
	/>
);

export default Radio;
