import {makeStyles} from "@material-ui/core";
import {createStyles, Theme} from "@material-ui/core/styles";

export const PhysicianAndMedicalDetailsStyle = makeStyles((theme: Theme) =>
    createStyles({
        radioGroup: {
            [theme.breakpoints.down(767)]: {
                marginTop: "0",
            }
        },

    })
);

export const AddEditPhysician = makeStyles ( (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: '10px',
            top: '24px',
            color: theme.palette.grey[500],
        },
    })
);
