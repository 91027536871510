import React from 'react';
import { Container, Grid, ListItem, ListItemText, Typography, List } from "@material-ui/core";
import KaiZenDifferentImg from "./Images/KaiZenDifferent.jpg";

function KaiZenIsDifferent(props: {strategy: string}) {
    const {strategy} = props;
    return <div className="colorBg pdXsScreenTop0">
        {/*<OvalBottom direction="left"/>*/}
        <Container maxWidth="lg" className="pdXsScreenNone">
            <Grid container spacing={3} alignItems="center" className="colReverse colMarginNone">
                <Grid item xs={12} md={6} className="pdXsScreenNone pdXsScreenBottom">
                    <img style={{maxWidth:667,width:'100%'}} src={KaiZenDifferentImg} alt="WhyKaiZen"/>
                </Grid>
                <Grid item xs={12} md={6} className="pdXsScreen20">
                    <Typography variant="h3" className="title positionR" color="primary">{strategy} is Different</Typography>
                    <Typography variant="body1" color="primary">
                        {strategy} is the only strategy that provides you the opportunity to add up to 3 times more money
                        to fund a
                        cash accumulating life insurance policy. Your contributions and the policy provide the security
                        needed to obtain the loan and your participation is easy. Simply create an account, estimate
                        your short 5-year annual contribution amount, and let {strategy} leverage do the rest. {strategy}
                        was designed to minimize expenses and maximize your potential for accumulation. We then combined
                        it with leverage to provide you the potential for 60-100% more:*</Typography>

                </Grid>
            </Grid>
        </Container>
        <Container maxWidth="lg">
            <Grid container spacing={3} className="mt40md">
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" component="strong" color="primary">Benefits of Smart Leverage</Typography>
                    <List className="forTickStyle">
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">No credit checks.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">No loan documents.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">No personal guarantees.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">No interest payments.</Typography></>}/>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" component="strong" color="primary">Benefits of More Protection</Typography>
                    <List className="forTickStyle">
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">Death benefit with living benefit riders.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">Chronic illness.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">Terminal illness.</Typography></>}/>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" component="strong" color="primary">Benefits of More Growth</Typography>
                    <List className="forTickStyle">
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">Upside crediting (subject to cap).</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">No negative returns due to market decline.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">Tax-free distributions with potential to access cash value using tax-free policy loans.</Typography></>}/>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="caption" color="primary" className="f12">The {strategy}&nbsp;
                        Strategy is dependent on the client making contributions for the first 5 years therefore not
                        defaulting on the policy, which could result in policy lapse and surrender charges. The client
                        will not have access to the policy, the cash values, the death benefits or the living benefits
                        until the loan is repaid and the assignment is released. The lender has the right to discontinue
                        funding new premiums, exit the market, or to demand loan repayment based on the terms and
                        conditions signed by the Master Trust. See the Master Trust documents for additional
                        information. There are some exceptions to this rule. Please consult a tax professional for
                        advice concerning your individual situation.</Typography>

                </Grid>
            </Grid>
        </Container>
    </div>
}

export default KaiZenIsDifferent;