import React from 'react';
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import StrategyName from "../../../../../components/client/StrategyName";
import InfoIcon from "@material-ui/icons/Info";
import NumberFormat from "react-number-format";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/estimation";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { allocationQuestions, isALGCarrier } from "../../utils/utils";
import { getCarrierCode } from "../../../../../utils/commonUtils";

function Assumptions(props: any) {
    const {strategyName, common , callingFrom= "ESTIMATOR", tag="" } = props;

    let growthRate:number = ((common && common.growthrate)*100) || 0;
    let incometaxrate:number = ((common && common.incometaxrate)*100) || 0;
    let { user: { id }, user } =  useSelector((state: RootState) => state.auth);
    const [investType, setInvestType] = React.useState()
    const carrierId = user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.carrier ? user.client.carrier.id : 2);
    let capitalgainstaxrate:number = ((common && common.capitalgainstaxrate)*100) || 0;
    const isAlZ =  getCarrierCode(carrierId || 2) == "ALZ"
    const estimationActions = useActions(Actions);
    React.useEffect(()=>{
        (async ()=>{
            let estimationDetails: any = await estimationActions.getEstimationDetailsByUserId(id, callingFrom);
            if(estimationDetails){
                setInvestType(estimationDetails.InvestmentType)
            }
        })()
    },[])
    const  getAllocations = () => {
        switch(tag) {
            case "QUICK-ESTIMATION":
                    if(isAlZ){
                        return  <>PIMCO (1% Bonus), Blended Futures (1% Bonus), S&P Futures (1% Bonus)), BUDBI (1% Bonus), Fixed Allocations (Illustrated Growth Rate: <NumberFormat
                                value={growthRate}
                                displayType={"text"}
                                decimalScale={2}
                                thousandSeparator={false}
                                suffix={"%"}/>)</>
                    }
                     return  <>S&P Annual PtP No Bonus No Additional Charge (Illustrated Growth Rate: <NumberFormat
                             value={growthRate}
                             displayType={"text"}
                             decimalScale={2}
                             thousandSeparator={false}
                             suffix={"%"}/>)</>
            default:
                    return <>{allocationQuestions[investType] && allocationQuestions[investType].labels.toString()}
                        (Illustrated Growth Rate: <NumberFormat
                        value={growthRate}
                        displayType={"text"}
                        decimalScale={2}
                        thousandSeparator={false}
                        suffix={"%"}/>)
                    </>
         }
    }
    let afrrate:number = ((common && common.afrrate)*100) || 0;
        return (<Grid container spacing={3} className="mt20">
                <Grid item xs={12} md={8}>
                    <Typography component="div" className="txtGray f12 floatLeft w100">
                        <Typography component="div" className="txtGray f12 mb0" style={{lineHeight: '20px'}} ><b>Assumptions Used
                            for <StrategyName strategyName={strategyName}/> and Alternative Choices:</b>
                            <Tooltip
                                    color="primary"
                                    title="All assumptions are subject to change and are provided for illustrative purposes only. Actual results may be more or less favorable. This presentation is not valid unless accompanied by a complete insurance company illustration. Please see the full illustration for guaranteed values and other important information."
                                    arrow>
                                <IconButton className="pl0" aria-label="Info">
                                    <InfoIcon className="f18"/>
                                </IconButton>
                            </Tooltip>
                            allocated to {getAllocations()}
                        </Typography>
                        <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>IUL insurance policy
                            distributions begin at the latter of age 65 or after the lender loan has been repaid
                            (typically in the 15th policy year) and continue through age 90.</Typography>
                        <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>Long Term Capital Gains Rate of {capitalgainstaxrate}%.</Typography>
                        <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>Income tax rate of {incometaxrate}%.</Typography>
                        <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>Participation in Kai-Zen
                            requires a minimum contribution amount of $21,350 annually, which includes the annual trust fees of
                            $1,350 and a portion of the IUL insurance policy premium. Kai-Zen also requires a
                            participant trust to be established as part of the strategy. The total IUL insurance premium
                            is paid by the trust to the life insurer once you and the commercial lender contribute
                            sufficient funds to pay the IUL insurance policy premium to the trust.</Typography>

                        {(strategyName === "Tri-Zen") &&
                                <Typography component="strong" className="txtGray f12 mb0 floatLeft w100" style={{lineHeight: '20px'}}>AFR Rate of {afrrate}%.
                                    <Tooltip arrow color="primary"
                                             title="The Applicable Federal Rate is the minimum regulatory interest rate that must be charged on split dollar loans. The interest is accumulated and repaid with the loan principle either at the insured death or the split dollar loan exit (typically shown two years after final distribution from the policy - e.g age 92 in most designs).  As the loan is a fixed duration loan due typically in 30 years plus, the long term AFR rate is assumed. The rate shown, is the rate used for the first contribution and is FIXED for the duration of the note.  Each contribution is treated as a new loan at the then prevailing AFR rate (i.e. each yearly contribution will be at the long term AFR rate at that time). For assumption purposes the design increases the AFR rate assumed on each new contribution. AFR rates are published monthly.">
                                        <IconButton aria-label="Info">
                                            <InfoIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                        }

                    </Typography>
                </Grid>
            </Grid>);

}

export default Assumptions;