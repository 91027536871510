import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import LoaderGif from "../styles/images/mainloader_ilia_new.gif";
import { cssPaths } from "../constants";
import Register from "../components/Register";

interface Props extends RouteComponentProps<void> {}

function RegisterPage(props: Props) {
    const [progress, setProgress] = React.useState<boolean>(true);

    React.useEffect(() => {
        const updateRootClasses = () => {
            document.body.classList.remove("landingcss");
            document.body.classList.remove("clientClasses");
            document.body.classList.add("adminClasses");
            let frontendCss = document.getElementById('frontendCss');
            frontendCss && frontendCss.setAttribute("href", cssPaths.layout);
            setTimeout(()=> setProgress( false ), 500);
        }

        updateRootClasses();
    }, []);

    const myStyle = {
        position: "absolute" as "absolute",
        "z-index": "999999",
        width: "100%",
        height: "100%",
        background: "#ffffff",
    };
    const imgStyle = {
        position: "absolute" as "absolute",
        left: "0",
        right: "0",
        margin:'0 auto',
        top:'30%'
    };
    return (
            <>
                {progress &&
                        <div style={myStyle}>
                            <img style={imgStyle} src={LoaderGif} alt='Loading...'/>
                        </div>}
                <Register {...props} />
            </>
    );
}

export default RegisterPage;
