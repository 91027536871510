import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Login from "../components/Login";
import LoaderGif from "../styles/images/mainloader_ilia_new.gif";
import { cssPaths } from "../constants";

interface Props extends RouteComponentProps<void> {}

function LoginPage(props: Props) {
    const [progress, setProgress] = React.useState<boolean>(true);

	React.useEffect(() => {
        const updateRootClasses = () => {
            document.body.classList.remove("landingcss");
            document.body.classList.remove("clientClasses");
            document.body.classList.add("adminClasses");
            let frontendCss = document.getElementById('frontendCss');
            frontendCss && frontendCss.setAttribute("href", cssPaths.layout);
            setTimeout(()=> setProgress( false ), 500);
        }

		 updateRootClasses();
	  }, []);

    const urlParams = new URLSearchParams(window.location.search);
    const token:string|null = urlParams.get('token');
    let url:string = "";
    if(token){
        url = atob(token)
    }
    const myStyle = {
        position: "absolute" as "absolute",
        "z-index": "999999",
        width: "100%",
        height: "100%",
        background: "#ffffff",
    };
    const imgStyle = {
        position: "absolute" as "absolute",
        left: "0",
        right: "0",
        margin:'0 auto',
        top:'30%'
    };
	return (
	        <>
                {progress &&
                <div style={myStyle}>
                    <img style={imgStyle} src={LoaderGif} alt='Loading...'/>
                </div>}
                    <Login url={url}/>
            </>
	);
}

export default LoginPage;
