import moment from 'moment';

const TIME_FORMAT_ACC_DB = 'HH:mm';
const DATE_FORMAT_WITH_TIME_ACC_DB = 'MM-DD-YYYY HH:mm:ss';
export const DATE_FORMAT_ACC_DB = 'MM-DD-YYYY';
const DATE_PICKER_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT_ACTIVITY_LOG = 'hh:mm A MM-DD-YYYY';
export const DATE_FORMAT_ACC_DB_SAVE = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_FOR_ALL_ACC_DB_SAVE = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_DOB_SAVE = 'YYYY-MM-DD';
export const DOB_LIMIT = moment().subtract(14, 'year').format(DATE_FORMAT_ACC_DB);
export const DATE_FORMAT_SERVICE_DASHBOARD = "MM/DD/YYYY";

export const asDbTimeFormat = (date: any = undefined, format = TIME_FORMAT_ACC_DB) => {
    return moment(date).format(format);
}

export const asDbDateTimeFormat = (date: any = undefined, format = DATE_FORMAT_WITH_TIME_ACC_DB) =>
		moment(date).format(format);

export const asDbDateFormat = (date: any = undefined, format = DATE_FORMAT_ACC_DB) =>
	moment(date).format(format);

export const localToUtc = (date: any = undefined, ft: any = undefined) =>
		moment(date, ft).utc().toISOString();

export const utcToLocal = (date: any = undefined, format = DATE_FORMAT_WITH_TIME_ACC_DB) =>
		moment.utc(date).local().format(format);

export const getDateDiffInYear = (date: any) => moment().diff(date, 'year');

export const getDateDiffInDays = (date: any) => moment().diff(date, 'days');

export const datePickerFormat = (date: any = undefined, format = DATE_PICKER_FORMAT) =>
		moment(date).format(format);

export const getAge = (dateString: string) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    if(isNaN(age)){
        return "";
    }
    return age;
}

export const activityLogDateFormat = (date: any = undefined, format = DATE_FORMAT_ACTIVITY_LOG) =>
    moment(date).format(format);

export function timeCalculator(countDownDate){
    return moment(countDownDate).fromNow(false);
};

export const getCurrentYear = () =>{
    let d = new Date();
    return d.getFullYear();
}
export const twentyFourHrsGone = (firstDateTime: string, secondDateTime) => {
    const diff = (Date.parse(firstDateTime) - Date.parse(secondDateTime)) / 3600000;
    if (!isNaN(diff) && Math.round(Math.abs(diff)) < 24) {
        return false;
    }
    return true;
}

export const isValidDate = (dateString) => {
    const age:number = getDateDiffInYear(dateString);
    const dateType: any = Date.parse(dateString);
    return typeof dateType === "number" && !isNaN(dateType) && (age >= 18 && age < 66);
}

export const asDbDDMM = (date: any) => moment(date).format("DD MMMM YYYY")