import * as React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useState } from "react";
import { useSelector } from "react-redux";
import { change } from "redux-form";
import { useActions } from "../actions";
import * as Actions from "../actions/account";
import { FormInputProps } from "../model";
import { ConfirmationDialog } from "../pages/ticket/TicketPage";
import { RootState } from "../reducers";
import { CarrierStrategiesTable } from "./CarrierStrategiesTable";
import { DialogCarrierAndStrategies, DialogCarriersType } from "./imo/DialogCarrierAndStrategies";
import ImoDetails from "./ImoDetails";
import PrimaryContactDetail from "./PrimaryContactDetail";
import { FileUpload } from "./formInputs";
import { LOGO_BASE_URL, PermissionTypes, stateDropDown } from "../constants";
import { can } from "./navigation/AdminNavigation";
import { pez } from "../utils/commonUtils";

const hidden = (field: FormInputProps) => {
    return <input {...field.input} type="hidden"/>;
};

interface AddImoAccountProps {
    brandLogo?: any;
    carriers?: any[];
    dispatch: Function;
    emailExist?: boolean;
    initialReBrand?: boolean;
    initialReBrandLogo?: string;
    page: string;
    setEmailExist?: Function;
    setEmailRef?: Function;
    validateIMOCarriers?: Function;
}

export type CarrierStrategiesForm = {
    id?: number
    carrier: number | null
    strategies: number[]
}

const initialImoCarrier: CarrierStrategiesForm = {
    carrier: null,
    strategies: []
}

export default function AddImoAccount(props: AddImoAccountProps) {
    const accountActions = useActions(Actions);

    const {dispatch, emailExist, initialReBrand, initialReBrandLogo, page, setEmailExist, setEmailRef} = props;
    const formName = page;

    const imoAccount: any = useSelector((state: RootState) => state.account);
    const auth = useSelector((state: RootState) => state.auth);
    // const [reBrand, setReBrand] = React.useState<number>(0);
    const disable = (auth.user.role === 18 || auth.user.role === 19);
    const [state, setState] = React.useState({
        rebrandSwitch: false,
    });
    const [reBrandLogo, setReBrandLogo] = React.useState(initialReBrand && pez(initialReBrandLogo));

    const fuProps = {
        allowZoomOut: true,
        dirName: "reBrandLogo",
        fileType: "image/gif, image/jpg, image/jpeg, image/png",
        height: "70",
        onFinish: (publicUrl: string, name: string) => {
            setReBrandLogo(name);
            dispatch(change(formName, "imo.reBrandLogo", name));
        },
        scale: 0.8,
        width: "200",
    };

    const handleChangeSwitch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        // setReBrand(Number(event.target.checked) || 0);
        dispatch(change(formName, "imo.reBrand", Number(event.target.checked) || 0));
    };


    const handleChangeState: any = async (state: string) => {
        const stateOption = stateDropDown.find(x => x.value === state);
        dispatch(change(formName, "imo.region", stateOption && stateOption.region));
    };


    //Intial Setup
    React.useEffect(() => {
        setState({
            ...state,
            ["rebrandSwitch"]: imoAccount && imoAccount.imo && imoAccount.imo.reBrand,
        });
        // setReBrand(imoAccount && imoAccount.imo && imoAccount.imo.reBrand || 0);
    }, [imoAccount.imo]);

    const [imoCarriers, setImoCarriers] = useState<any[]>([])

    const toRow = imoCarrier => ({
        ...imoCarrier,
        carrier: {
            ...imoCarrier.carrier,
            name: imoCarrier.carrier && imoCarrier.carrier.carrierName
        },
        offerTypes: imoCarrier.carrier && imoCarrier.carrier.carrierOfferTypes.map(x => ({ name: x.offerTypeName }))
    })

    React.useEffect(() => {
        if(formName !== 'AddAccount' && imoAccount && imoAccount.imo && imoAccount.imo.carriers) {
            setImoCarriers(imoAccount.imo.carriers.map(toRow));
        } else {
            setImoCarriers([])
        }
    }, [formName, imoAccount])

	const handleDeleteSelectedCarrier = async () => {
        await deleteImoCarrier(imoCarrierToDelete.carrier.id);
        const newImoCarriers = imoCarriers.filter(x => x !== imoCarrierToDelete);
        setImoCarriers(newImoCarriers);
        setImoCarrierToDelete(null);
        dispatch(change(formName, "imo.carriers", newImoCarriers));
    };

    const addImoCarrier =  async (imoCarrier:  CarrierStrategiesForm) => {
        let newImoCarrier = imoCarrier
        if(formName !== 'AddAccount' ){
            let imoId =  imoAccount && imoAccount.imo && imoAccount.imo.id || 0;
            const response = await accountActions.addImoCarrier(imoId, imoCarrier);
            newImoCarrier = toRow(response)
        }
        const newImoCarriers = [...imoCarriers, newImoCarrier];
        setImoCarriers(newImoCarriers)
        await dispatch(change(formName, "imo.carriers", newImoCarriers));
    }

    const editImoCarrier = async (imoCarrier: any) => {
        let newImoCarrier = {id: imoCarrierDialog.data.id, ...imoCarrier}
        console.log("Details:", newImoCarrier);
        if(formName !== 'AddAccount' ){
            let imoId =  imoAccount && imoAccount.imo && imoAccount.imo.id || 0;
            await accountActions.editImoCarrier(imoId, newImoCarrier);
        }

        const newImoCarriers = imoCarriers.map(item => {
            if(newImoCarrier && item.carrier.name !== newImoCarrier.carrier.name) {
                return item;
            }

            return newImoCarrier;
        })
        console.log("edit to set carrier", newImoCarriers);
        setImoCarriers(newImoCarriers)
        await dispatch(change(formName, "imo.carriers", newImoCarriers));
    }

    const deleteImoCarrier = async ( carrierId:number ) => {
        if(formName !== 'AddAccount'){
            let imoId =  imoAccount && imoAccount.imo && imoAccount.imo.id || 0;
            return await accountActions.deleteImoCarrier(imoId, carrierId);
        }
    }

    const initialDialog = {title: null, data: initialImoCarrier};
    const [imoCarrierDialog, setImoCarrierDialog] = React.useState<{ title: DialogCarriersType | null; data: CarrierStrategiesForm }>(initialDialog);

    const submitCarrier = (imoCarrier: any) => {
       if(imoCarrierDialog.title === DialogCarriersType.ADD) {
        return addImoCarrier(imoCarrier);
       }

       if(imoCarrierDialog.title === DialogCarriersType.EDIT) {
        return editImoCarrier(imoCarrier);
       }
    }

    const [imoCarrierToDelete, setImoCarrierToDelete] = useState<any>(null);

    const handleSelect = (selectedOpt, imoCarrier) => {
        if (selectedOpt === "View / Edit") {            
            setImoCarrierDialog({ 
                title: DialogCarriersType.EDIT, 
                data: {
                    id: imoCarrier.id,
                    carrier: imoCarrier.carrier.id, 
                    strategies: imoCarrier.imoStrategies.map(x => x.strategies.id)
                }
            })
        }
        if (selectedOpt === "Delete") {
            setImoCarrierToDelete(imoCarrier);
        }
    };

    return (
            <React.Fragment>
                <ImoDetails
                    showImoClass={formName === 'AddAccount'}
                    state={state}
                    handleChangeSwitch={handleChangeSwitch}
                    hidden={hidden}
                    handleChangeState={handleChangeState}
                    validateIMOCarriers={props.validateIMOCarriers}
                />

                <Grid item xs={12} sm={12} className="pb0">
                    <Typography variant="subtitle1" className="floatLeft colorBlue mb0" component="strong">
                        IMO Primary Contact
                    </Typography>
                </Grid>
                <PrimaryContactDetail
                        setEmailRef={setEmailRef}
                        emailExist={emailExist}
                        setEmailExist={setEmailExist}
                        disabledEmail={auth.user.role === 2 && formName === "EditAccountPage"}
                        disabledPhone={auth.user.role === 2 && formName === "EditAccountPage"}
                        disabled={disable}
                />

                {
                        state.rebrandSwitch &&
                        <>
                            <Grid item xs={12} sm={12} className="pb0">
                                <Typography className="floatLeft colorBlue mb0" component="strong" variant="subtitle1">
                                    Re-brand Image
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FileUpload {...fuProps} />
                            </Grid>
                            {
                                    reBrandLogo &&
                                    <Grid item xs={12} sm={6} md={6} className="mb10">
                                        <img alt="reBrandLogo" className="img-responsive" src={LOGO_BASE_URL + reBrandLogo} title="ReBrandLogo" />
                                    </Grid>
                            }
                        </>
                }


                <Grid item xs={12} sm={12}>
                    <Box display="flex" justifyContent="space-between" className="mt30">
                        <Typography  component="strong" variant="subtitle1" className="floatLeft colorBlue mb0">
                            Carrier and Ilia Product Type
                        </Typography>
                        {
                                auth.user && (can(auth.user, PermissionTypes.ACCOUNT_MANAGEMENT) && (auth.user.role === 1 || auth.user.role === 2)) &&
                            <Button color="primary"
                                    startIcon={<AddCircleIcon/>}
                                    onClick={() => {
                                        if(disable){
                                            return;
                                        }
                                        setImoCarrierDialog({title: DialogCarriersType.ADD, data: initialImoCarrier})
                                    }}
                            >
                                Add Carriers and Ilia Product Type
                            </Button>
                        }
                        <DialogCarrierAndStrategies
                                existingCarriers={imoCarriers}
                                open={!!imoCarrierDialog.title}
                                handleClose={() => setImoCarrierDialog(initialDialog)}
                                onSubmit={imo => submitCarrier(imo)}
                                initialImo={imoCarrierDialog.data}
                                title={imoCarrierDialog.title}
                        />
                    </Box>
                </Grid>
                <CarrierStrategiesTable
                    rows={imoCarriers}
                    onSelect={handleSelect}
                    disable={disable}
                />
                {
				    imoCarrierToDelete && 
                    <ConfirmationDialog
                        onCancel={() => setImoCarrierToDelete(null)}
                        onOk={handleDeleteSelectedCarrier}
                        text="Are you sure want to delete this imo carrier permanently?"
                        title="Delete confirmation"
                    />
                }
            </React.Fragment>
    );
}