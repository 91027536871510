import React, {FunctionComponent} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Card, CardContent, Grid} from '@material-ui/core';
import TableWrap from '../../components/TableWrap/TableWrap';
import {Pagination} from '../../components/table';
import ActionItemsList from '../ActionItemsList';
import useQueryString from '../../hooks/useQueryString';
import * as AccountActions from '../../actions/account';
import {useActions} from '../../actions';
import {RootState} from '../../reducers';
import {AccountSortList} from '../../model/account';
import {ActionItemsHeadRow} from '../../model/ActionItems';
import {User} from '../../model/user';
import {ActionItems, defaultRowsPerPage} from "../../constants";


interface SellerAgreementActionItemsProps {
    tabValue: number;
}


const SellerAgreementActionItems: FunctionComponent<SellerAgreementActionItemsProps> = ({tabValue}) => {
    const [page, setPage] = useQueryString('page', 0);
    const [rowsPerPage, setRowsPerPage] = useQueryString('rowsPerPage', defaultRowsPerPage);
    const [orderBy, setOrderBy] = useQueryString('orderBy', 'createdAt');
    const [order, setOrder] = useQueryString('order', 'desc');
    const [actionItemsList, setActionItemsList]: [any, Function] = React.useState([]);
    const [filterType, setFilterType] = React.useState(ActionItems.AGENT_SELLER_AGREEMENT.actionType);
    const accountActions = useActions(AccountActions);
    const auth: User = useSelector((state: RootState) => state.auth);
    const isViaAgentApprovalPage = useLocation().pathname === '/approved-agents';
    const headRows: ActionItemsHeadRow[] = [
        {id: 'clientName', label: 'Client Name'},
        {id: 'actionRequired', label: 'Action Item'},
        {id: 'agentName', label: 'Agent Name'},
        {id: 'createdAt', label: 'Date Added'},
        {id: 'actionNeeded', label: 'Action Needed'},
    ];
    const getSellerAgreementActionItems = async () => {
        const [queryObj, role] = [
            {
                filter: 2,
                filterType,
                isViaAgentApprovalPage,
                order,
                page,
                rowsPerPage,
                searchText: '',
                sortBy: orderBy,
                status: tabValue,
                trancheNo: 0,
                type: tabValue,
            }, 'agent'
        ];
        const response = await accountActions.listActionItems(queryObj, role);

        if (response.status === true) {
            setActionItemsList(response.data);
        }
        return;
    };
    const firstRunEffect = () => {
        getSellerAgreementActionItems().then();
    };
    const orderByEffect = () => {
        if (tabValue === 0 && orderBy === 'updatedAt') {
            setOrderBy('createdAt');
        }
        if (tabValue === 1 && orderBy === 'createdAt') {
            setOrderBy('updatedAt');
        }
    };
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };
    const mockUpdateSellerAgreement = (sellerAgreementActionId: number) => {
        const _actionItemIndex = actionItemsList.rows.findIndex((i: any) => i.id === sellerAgreementActionId);
        Object.assign(actionItemsList.rows[_actionItemIndex], {status: 1});
        setActionItemsList(actionItemsList);
    };
    const ailProps = {
        actionItemsList: actionItemsList,
        headRows: headRows,
        mockUpdate: mockUpdateSellerAgreement,
        onRequestSort: handleRequestSort,
        order: order,
        orderBy: orderBy,
        role: auth.user.role,
        setFilterType: setFilterType,
        value: tabValue,
        isViaAgentApprovalPage
    };
    const paginationProps = {
        accountList: actionItemsList,
        page: Number(page),
        rowsPerPage: Number(rowsPerPage),
        setPage: setPage,
        setRowsPerPage: setRowsPerPage,
    };

    React.useEffect(firstRunEffect, []);

    React.useEffect(firstRunEffect, [page, rowsPerPage, orderBy, order]);

    React.useEffect(orderByEffect, [tabValue]);

    return (
            <Grid item xs={12}>
                <Card className="muiCard">
                    <CardContent>
                        <TableWrap>
                            <ActionItemsList {...ailProps} />
                        </TableWrap>
                        <Pagination {...paginationProps} />
                    </CardContent>
                </Card>
            </Grid>
    );
};

export default SellerAgreementActionItems;
