import React from "react";
import {RootState} from "../../../reducers";
import {connect} from "react-redux";
import {Box, Card, CardContent, Chip, Grid, Link, Typography} from "@material-ui/core";
import {DashboardStyle as Styles} from "../dashboard/dashboardStyle";
import * as LeadActions from "../../../actions/lead";
import {useActions} from "../../../actions";
import LeadsModal from "./LeadsModal";

const Stats = [
    {
        type: "TotalLinkViews",
        title: "Total Link Views",
        description: "Prospects that have visited landing pages form all links.",
        countClass: "btnGreen",
        kpiModal: "",
        kpiModalTitle: ""
    },
    {
        type: "TotalLeads",
        title: "Total Email Leads",
        description: "Total emails captured as leads from all links.",
        countClass: "btnGreen",
        kpiModal: "TotalLeads",
        kpiModalTitle: "Total Email Leads"
    },
    {
        type: "LeadsClosed",
        title: "Total Closed Leads",
        description: "Number enrolled from all  links.",
        countClass: "btnGreen",
        kpiModal: "LeadsClosed",
        kpiModalTitle: "Total Closed Leads"
    },
]

// TODO:
const doNone = something => {}

type Props = {
    stats?: any,
    onClickKpi?: any,
    user: any
}

function AgentLinksStatsWithEffect ({ stats, onClickKpi, user }: Props) {
    const leadActions = useActions(LeadActions);

    React.useEffect(() => {
        getLeadStats();
    }, []);
    
    const getLeadStats = async () => {
        await leadActions.leadStats("AGENT_LINK");
    };

    return <AgentLinksStats stats={stats} onClickKpi={onClickKpi} user={user}></AgentLinksStats>
}

type AgentLinksStatsProps = {
    stats: any,
    onClickKpi?: any,
    user: any
};

export function AgentLinksStats(props: AgentLinksStatsProps) {
    const { stats, onClickKpi, user } = props

    const leadActions = useActions(LeadActions);
    const classes = Styles();

    const [open, setOpen] = React.useState(false);
    const [modalValue, setModalValue] = React.useState("");
    const [modalTitle, setModalTitle] = React.useState("");
    const [kpiData, setKpiData] = React.useState([]);

    const handleExportCSV = (statType:string) => {
        leadActions.exportAgentLinksCSV(user.id, statType, 'csv', 'AGENT_LINK', user.role);
    };
    const exportList = async (kpi: string) => {
        handleExportCSV(kpi)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleKpi = async (kpi: string, kpiTitle: string) => {
        setModalValue(kpi);
        setModalTitle(kpiTitle);
        /*let resp = await leadActions.exportLeadsCSV(user.id, kpi, 'json', 'AGENT_LINK');
        if (resp && resp.length > 0) {
            setKpiData(resp);
            handleClickOpen();
        }*/
    }

    const handleClickKpi = onClickKpi || handleKpi

    return (
            <Grid container>
                {
                    Stats.map((lead) => {
                        let count: number = getCount(stats, lead);
                        return (
                                <AgentLinkBox 
                                    classes={classes}
                                    handleClickKpi={handleClickKpi}
                                    lead={lead}
                                    count={count}
                                    onClickLink={() => {
                                        if (user.role === 1 || user.role === 2) {
                                            exportList(lead.kpiModal)
                                        } else {
                                            handleKpi(lead.kpiModal, lead.kpiModalTitle)
                                        }
                                    }}
                                    linkTitle={
                                        (user.role === 1 || user.role === 2)
                                        ? "Export List" : "View and Export List"}
                                ></AgentLinkBox>
                        );
                    })
                }
                <LeadsModal
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        setOpen={setOpen}
                        open={open}
                        modalValue={modalValue}
                        modalTitle={modalTitle}
                        kpiData={kpiData}
                        exportList={exportList}
                />
            </Grid>
    );
}

function getCount(stats: any, lead: { type: string; title: string; description: string; countClass: string; kpiModal: string; kpiModalTitle: string; }) {
    let count: number = 0;
    if (stats && Object.keys(stats).length > 0) {
        switch (lead.type) {
            case "TotalLinkViews": {
                count = stats.TotalLinkViews;
                break;
            }
            case "TotalLeads": {
                count = stats.TotalLeadsCount;
                break;
            }
            case "LeadsClosed": {
                count = stats.LeadsClosedCount;
            }
        }
    }
    return count;
}

function mapStateToProps(state: RootState) {
    return {
        stats: state.leadStats,
    };
}

export const AgentLinkBox = ({classes, onClickLink, handleClickKpi, lead, count, linkTitle}) => {
    return(
        <Box className={classes.boxItem} m={1}>
            <Card className={classes.cardRoot + " " + classes.cardShadow + " " + classes.radius8 + " " + classes.minHeight170}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography variant="body1" component="strong"
                                    className={classes.disPlay + " " + classes.mb0}>
                            {lead.title}
                        </Typography>
                        <Typography variant="caption"
                                    className={classes.disPlay + " " + classes.mt10} style={{color:'rgba(0,0,0,0.54)'}}>
                            {lead.description}
                        </Typography>

                        {(lead.type === "TotalLeads" || lead.type === "LeadsClosed") && count > 0 &&
                        <Link variant="caption" className="mt10 floatLeft" onClick={onClickLink}>
                            {linkTitle}
                        </Link>
                        }
                    </CardContent>
                </div>
                <div className={classes.btnSection}>
                    <Chip
                        label={count}
                        classes={{root: classes[lead.countClass]}}
                        onClick={() => (lead.kpiModal === "TotalLeads") ? handleClickKpi(lead.kpiModal, lead.kpiModalTitle) :
                                        (lead.kpiModal === "LeadsClosed") ? handleClickKpi(lead.kpiModal, lead.kpiModalTitle) : '' }
                    />
                </div>
            </Card>
        </Box>
    );
}


export default connect(mapStateToProps)(AgentLinksStatsWithEffect);