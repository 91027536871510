import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UploadDocumentsForm from "../../../Enrollment/allianzAppHome/basicInfo/UploadDocumentsForm";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import BeneficiariesPage from "../../../Enrollment/allianzAppHome/EnrollmentApplication/Beneficiaries/Page";
import FinancialAndLiabilities
    from "../../../Enrollment/allianzAppHome/EnrollmentApplication/FinancialAndLiabilities/FinancialAndLiabilitiesContent";
import CommonLoader from "../../../../../components/CommonLoader";
import InsuredQuestionsContent
    from "../../../Enrollment/allianzAppHome/EnrollmentApplication/InsuredQuestions/InsuredQuestionsContent";
import {
    makeAllFormValues,
} from "../../../Enrollment/allianzAppHome/EnrollmentApplication/FinancialAndLiabilities/financialAndLiabilitiesUtil";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import { useOfferType } from "../../../../../hooks/useOfferType";
import PersonalDetailFormV2 from "../../../Enrollment/allianzAppHome/basicInfo/PersonalDetailFormV2";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const AllianzAppEnrollmentTab = (props: any) => {
    const classes = useStyles();
    const { clientDetail, setActionItem } = props;
    const { client, enrollment } = clientDetail;
    let offerType = useOfferType();
    let clientSigned: boolean = (enrollment && (enrollment.clientSigned || enrollment.envelopeId)) || false;
    if (offerType === "consent") {
        offerType = "gi";
    }
    const [personalDetailRef, setPersonalDetailRef] = React.useState<any>("");
    const [questionnaireRef, setQuestionnaireRef] = React.useState<any>("");
    const [isPersonalFormDirty, setIsPersonalFormDirty] = React.useState<boolean>(false);
    const [isQuestionFormDirty, setIsQuestionFormDirty] = React.useState<boolean>(false);
    const [validationError, setValidationError] = React.useState<boolean>(false);
    const [stateValue, setStateValue] = React.useState("");
    const [communityStateVal, setCommunityStateVal] = React.useState("");
    const [financialAndLiabilitiesArr, setFinancialAndLiabilitiesArr] = React.useState<any>([]);
    const formVal = makeAllFormValues();
    const accountActions = useActions(Actions);

    React.useEffect(() => {
        formVal.addVal(financialAndLiabilitiesArr);
    }, [financialAndLiabilitiesArr]);

    const [panelArr, setPanelArr] = React.useState({
        panel1: true,
        panel2: true,
        panel3: true,
        panel4: true,
        panel5: false,
    });

    if (!clientDetail.client) {
        return (
                <CommonLoader />
        );
    }

    const strategySlug: string = (clientDetail.client.strategy && clientDetail.client.strategy.slug) || "kaizen";

    const handleSubmit = async () => {
        const allFormValArr = formVal.getVal();
        await accountActions.updateClientFinancialAndLiabilitiesFields(allFormValArr, clientDetail && clientDetail.client && clientDetail.client.id, true);
    };

    return (
            <div className={classes.root}>
                <ExpansionPanel style={{ boxShadow: "0 0px 12px 0px #c1c1c1" }} expanded={panelArr.panel1}
                                onChange={(event: object, expanded: boolean) => {
                                    setPanelArr({
                                        panel1: expanded,
                                        panel2: panelArr.panel2,
                                        panel3: panelArr.panel3,
                                        panel4: panelArr.panel4,
                                        panel5: panelArr.panel5,
                                    });
                                }}>
                    <ExpansionPanelSummary
                            className="ExpansionPanelHead"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="identity-content"
                            id="identity-header"
                    >
                        <Typography variant="subtitle1" className="mb0">Identity Verification, Address and
                            Contributions</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="pdMini10">
                        {panelArr.panel1 &&
                                <div>
                                    <Card className="alertMessageWrap success stepperWrap alert mb40- "
                                          style={{ border: "1px solid #3DDB93" }}>
                                        <CardContent className="alertMessageWrapCardContent ">
                                            <div className="imgSec floatLeft textHighLight">
                                                <InfoOutlinedIcon />
                                            </div>
                                            <div className="textSec floatLeft">
                                                <Typography variant="body1" className="mb20">
                                                    Pre-fill any fields for the client to verify during enrollment.
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </Card>

                                    <PersonalDetailFormV2
                                            clientDetail={clientDetail}
                                            setActionItem={(flag: boolean) => setActionItem(flag)}
                                            setNextStep={() => {
                                            }}
                                            isFromClientDetail={false}
                                    />
                                </div>
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <br />
                <ExpansionPanel style={{ boxShadow: "0 0px 12px 0px #c1c1c1" }} expanded={panelArr.panel2}
                                onChange={(event: object, expanded: boolean) => {
                                    setPanelArr({
                                        panel1: panelArr.panel1,
                                        panel2: expanded,
                                        panel3: panelArr.panel3,
                                        panel4: panelArr.panel4,
                                        panel5: panelArr.panel5,
                                    });
                                }}
                >
                    <ExpansionPanelSummary
                            className="ExpansionPanelHead"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="incomeDocs-content"
                            id="incomeDocs-header"
                    >
                        <Typography variant="subtitle1" className="mb0">Identity and Income Verification
                            Documents</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {panelArr.panel2 &&
                                <div>
                                    <Card className="alertMessageWrap success stepperWrap alert mb40 "
                                          style={{ border: "1px solid #3DDB93" }}>
                                        <CardContent className="alertMessageWrapCardContent ">
                                            <div className="imgSec floatLeft textHighLight">
                                                <InfoOutlinedIcon />
                                            </div>
                                            <div className="textSec floatLeft">
                                                <Typography variant="body1" className="mb20">
                                                    You can upload on your clients behalf. You cannot delete any client
                                                    uploaded
                                                    documents. All documents are also visible under the “documents” tab.
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </Card>

                                    <UploadDocumentsForm
                                            setNextStep={() => {
                                            }}
                                            setPrevStep={() => {
                                            }}
                                            clientDetail={clientDetail}
                                            isFromClientDetail={true}
                                    />
                                </div>
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                {(strategySlug !== "cashstream") &&
                        <React.Fragment>
                            <br />
                            <ExpansionPanel style={{ boxShadow: "0 0px 12px 0px #c1c1c1" }} expanded={panelArr.panel3}
                                            onChange={(event: object, expanded: boolean) => {
                                                setPanelArr({
                                                    panel1: panelArr.panel1,
                                                    panel2: panelArr.panel2,
                                                    panel3: expanded,
                                                    panel4: panelArr.panel4,
                                                    panel5: panelArr.panel5,
                                                });
                                            }}
                            >
                                <ExpansionPanelSummary
                                        className="ExpansionPanelHead"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="incomeDocs-content"
                                        id="questionaire-header"
                                >
                                    <Typography variant="subtitle1" className="mb0">
                                        Carrier Application Questions
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {panelArr.panel3 &&
                                            <div>
                                                <Card className="alertMessageWrap success stepperWrap alert mb40- "
                                                      style={{ border: "1px solid #3DDB93" }}>
                                                    <CardContent className="alertMessageWrapCardContent ">
                                                        <div className="imgSec floatLeft textHighLight">
                                                            <InfoOutlinedIcon />
                                                        </div>
                                                        <div className="textSec floatLeft">
                                                            <Typography variant="body1" className="mb20">
                                                                You can pre-fill the clients carrier questionnaire if
                                                                you know your clients
                                                                personal information. Your client will be able to make
                                                                changes and submit
                                                                their application during enrollment.
                                                            </Typography>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <InsuredQuestionsContent
                                                                setNextStep={() => {
                                                                }}
                                                                setIsError={() => {
                                                                }}
                                                                user={clientDetail}
                                                                isFromClientDetail={true}
                                                                setQuestionButtonRef={() => {
                                                                }}
                                                                setActionItem={(flag: boolean) => setActionItem(flag)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                    }
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </React.Fragment>
                }

                <br />
                <ExpansionPanel style={{ boxShadow: "0 0px 12px 0px #c1c1c1" }} expanded={panelArr.panel4}
                                onChange={(event: object, expanded: boolean) => {
                                    setPanelArr({
                                        panel1: panelArr.panel1,
                                        panel2: panelArr.panel2,
                                        panel3: panelArr.panel3,
                                        panel4: expanded,
                                        panel5: panelArr.panel5,
                                    });
                                }}
                >
                    <ExpansionPanelSummary
                            className="ExpansionPanelHead"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="incomeDocs-content"
                            id="questionaire-header"
                    >
                        <Typography variant="subtitle1" className="mb0">
                            Beneficiaries
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {panelArr.panel4 &&
                                <div className="floatLeft w100">
                                    <BeneficiariesPage
                                            isFromClientDetail={true}
                                            setActionItem={(flag: boolean) => setActionItem(flag)}
                                            clientDetail={clientDetail}
                                            setPrevStep={() => {
                                            }}
                                            setNextStep={() => {
                                            }}
                                            stateValue={stateValue}
                                            setStateValue={setStateValue}
                                            setCommunityStateVal={(val) => {
                                                setCommunityStateVal(val);
                                            }}
                                    />
                                </div>
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <br />
                {client && client.company && client.company.groupType && client.company.groupType === "Corporation" && offerType === "gi" ?
                        <React.Fragment></React.Fragment>
                        :
                        <ExpansionPanel style={{ boxShadow: "0 0px 12px 0px #c1c1c1" }} expanded={panelArr.panel5}
                                        onChange={(event: object, expanded: boolean) => {
                                            setPanelArr({
                                                panel1: panelArr.panel1,
                                                panel2: panelArr.panel2,
                                                panel3: panelArr.panel3,
                                                panel4: panelArr.panel4,
                                                panel5: expanded,
                                            });
                                        }}
                        >
                            <ExpansionPanelSummary
                                    className="ExpansionPanelHead"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="incomeDocs-content"
                                    id="questionaire-header"
                            >
                                <Typography variant="subtitle1" className="mb0">
                                    Financial and Liabilities
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {panelArr.panel5 &&
                                        <div className="floatLeft w100">
                                            <Card className="alertMessageWrap success stepperWrap alert mb40- "
                                                  style={{ border: "1px solid #3DDB93" }}>
                                                <CardContent className="alertMessageWrapCardContent ">
                                                    <div className="imgSec floatLeft textHighLight">
                                                        <InfoOutlinedIcon />
                                                    </div>
                                                    <div className="textSec floatLeft">
                                                        <Typography variant="body1" className="mb20">
                                                            If you know your clients financials you can pre-fill them
                                                            out here.
                                                            The
                                                            client will
                                                            need to verify these during enrollment.
                                                        </Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                            <FinancialAndLiabilities
                                                    clientDetail={clientDetail} setPageChecked={true}
                                                    isFromClientDetail={true}
                                                    setFinancialAgreementRef={() => {
                                                    }}
                                                    setSourceOfFundsRef={() => {
                                                    }}
                                                    setIsError={() => {
                                                    }}
                                                    setAttestRef={() => {
                                                    }}
                                                    setAssetsVerifyRef={() => {
                                                    }}
                                                    setAssetsVerified={() => {
                                                    }}
                                                    setLiabilitiesVerifyRef={() => {
                                                    }}
                                                    setLiabilitiesVerified={() => {
                                                    }}
                                                    setEarnedVerifyRef={() => {
                                                    }}
                                                    setEarnedVerified={() => {
                                                    }}
                                                    setUnEarnedVerifyRef={() => {
                                                    }}
                                                    setUnEarnedVerified={() => {
                                                    }}
                                                    setRealEstateVerifyRef={() => {
                                                    }}
                                                    setRealEstateVerified={() => {
                                                    }}
                                                    setBusinessEquityVerifyRef={() => {
                                                    }}
                                                    setBusinessEquityVerified={() => {
                                                    }}
                                                    setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                                                    financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                                                    offerType={offerType}
                                            />
                                        </div>
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                }
                <Grid item xs={12}>
                    <div className="stickyFooter" id={"stickyFooter"} style={{
                        display: isQuestionFormDirty || (isPersonalFormDirty && !validationError) ? "block" : "none",
                        transition: isQuestionFormDirty || (isPersonalFormDirty && !validationError) ? "height 2s ease-in-out 2s" : "none",
                    }}>
                        <Typography className="mb0">Make sure to save your changes.
                            <Button size="large"
                                    className="ml20"
                                    variant="contained"
                                    disabled={!!clientSigned}
                                    onClick={() => {
                                        if (personalDetailRef && personalDetailRef.current) {
                                            personalDetailRef.current.click();
                                        }
                                        if (questionnaireRef && questionnaireRef.current) {
                                            setTimeout(() => {
                                                questionnaireRef.current.click();
                                            }, 100);
                                        }
                                        let detailPageSubmitBtn: any = document.getElementsByClassName("detailPageSubmitBtn");
                                        if (detailPageSubmitBtn && detailPageSubmitBtn.length) {
                                            for (let index in detailPageSubmitBtn) {
                                                if (detailPageSubmitBtn[index] && detailPageSubmitBtn[index].click && typeof (detailPageSubmitBtn[index].click) === "function" && typeof (detailPageSubmitBtn[index].click) !== "undefined") {
                                                    detailPageSubmitBtn[index].click();
                                                }
                                            }
                                        }
                                        setTimeout(() => {
                                            handleSubmit().then(() => {
                                            });
                                        }, 1200);
                                    }}
                                    type={"button"} color="primary">
                                Save Changes
                            </Button>
                        </Typography>
                    </div>
                </Grid>
            </div>
    );
};
export default AllianzAppEnrollmentTab;