import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Grid, Typography } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Label } from "../../../../../../../components/formInputs";
import { EnrollmentFinancialAgreementProps } from "../../../../../../../model/enrollment";
import getSourceOfFundsQuestions from "./SourceOfFundQuestions";
import * as Yup from "yup";
import { ErrorCommon, TextField, TextFieldCommon } from "../../../../../../../components/formikFormInputs";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import SourceOfFundsForm from "./SourceOfFundsForm";
import { isIliaApp, isLongForm, convertToRadioValue, isWorksheet } from "../../Utilities";
import { GWT_GEN2_STRATEGY, REQUIRED_FIELD } from "../../../../../../../constants";

type Props = {
    autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void;
    isFromClientDetail: boolean,
    user: any,
    setSourceOfFundsRef: Function,
    setFinancialAndLiabilitiesArr: Function,
    financialAndLiabilitiesArr: any,
    sourceOfFundsRefs:any,
    sendSaveRequest?:Function
    formType:string
    setFormType:Function,
    setSaveSourceOfFundsForm:Function,
    offerType: string
}
const SourceOfFunds = (props: Props) => {
    const {
        autoSaveTrigger,
        financialAndLiabilitiesArr,
        formType,
        isFromClientDetail,
        offerType,
        sendSaveRequest,
        setFinancialAndLiabilitiesArr,
        setFormType,
        setSaveSourceOfFundsForm,
        setSourceOfFundsRef,
        sourceOfFundsRefs,
        user,
    } = props;
    const { updateClientFields } = useActions(AccountActions);
    let fullQuestions = getSourceOfFundsQuestions({offerType});
    const questionList = _.filter(fullQuestions, function(o) { return o.show });
    const [checkBoxObjError, setCheckBoxObjError] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [errorInForm, setErrorInForm] = useState(false);
    let clientDetail = (user && user.client && user.client) || undefined;
    let strategy = (user && user.client && user.client.strategy) || undefined;
    const sourceOfFundRef = React.useRef<any>();
    let clientSigned: boolean = user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? true : false;
    const isGWTGen2 = clientDetail.strategyId === GWT_GEN2_STRATEGY;
    const [state, setState] = React.useState({
        "Earned Income": false,
        "Mutual Fund / Brokerage Account": false,
        "Money Market Fund": false,
        "Loans": false,
        "Annuity Contract": false,
        "Other Life Insurance Policy": false,
        "Savings": false,
        "Wealth-Gift Transfer": false,
    });
    const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>, values = {}) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if(Object.keys(values).length > 0) {
            checkCheckBoxValues(values, event.target.name, event.target.checked);
        }
    };

    const checkCheckBoxValues = (values, currentTargetName:string|null = null, currentTargetValue:boolean|null = null) => {
        let checkBoxChecked = false;
        let formValues = Object.assign({}, values);
        if(currentTargetName){
            formValues[currentTargetName] = currentTargetValue;
            setInitialLoad(false);
        }
        checkBoxObj.forEach((item, index) => {
            if(formValues[item.name] === true){
                checkBoxChecked = true;
                setCheckBoxObjError(false);
            }
        })
        if(!checkBoxChecked) {
            setCheckBoxObjError(true);
        }
    }

    React.useEffect(()=>{
        checkCheckBoxValues(initialValues);
    },[]);

    const enableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "block";
        }
    }
    const disableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "none";
        }
    }

    let isWealthTransfer:boolean = false;
    if(clientDetail && clientDetail.currentlyEmployed && clientDetail.currentlyEmployed.toLowerCase() === "other"){
        if(clientDetail && clientDetail.parentsAreIncomeEarner){  //WT
            isWealthTransfer = true;
        }
    }

    let initialValues:any = {
        earnedIncome: clientDetail.earnedIncome ? true : false,
        mutualFund: clientDetail.mutualFund ? true : false,
        moneyMarketFund: clientDetail.moneyMarketFund ? true : false,
        loans: clientDetail.loans ? true : false,
        annuityContract: clientDetail.annuityContract ? true : false,
        otherLifeInsurancePolicy: clientDetail.otherLifeInsurancePolicy ? true : false,
        savings: clientDetail.savings ? true : false,
        wealthGiftTransfer: clientDetail.wealthGiftTransfer || isWealthTransfer || isGWTGen2 ? true : false,
        qualifiedFunds: clientDetail.qualifiedFunds ? true : false,
        qualifiedFundsDesc: clientDetail.qualifiedFundsDesc || "",
        premiumFinancing: clientDetail.premiumFinancing ? true : false,
        premiumFinancingDesc: clientDetail.premiumFinancingDesc || "",
        lenderFundsDesc: clientDetail.lenderFundsDesc || "",
        loanRadio: clientDetail.loanRadio || "",
        clientObligated: clientDetail.clientObligated === 1 ? "Yes" : clientDetail.clientObligated === 0 ? "No" : null,
        liquidAssets: convertToRadioValue(clientDetail.liquidAssets),
        liquidAssetsInheritance: clientDetail.liquidAssetsInheritance ? true : false,
        liquidAssetsOther: clientDetail.liquidAssetsOther ? true : false,
        liquidAssetsOtherDesc: clientDetail.liquidAssetsOtherDesc || "",
        liquidAssetsInheritanceDesc: clientDetail.liquidAssetsInheritanceDesc || ""
    };
    let groupUserInitVal = {
        loans: true
    }

    let checkBoxObjGroupUser = [
        {
            name: "loans",
            label: "Loans",
        }
    ];

    let checkBoxObj:any = [{
        name: "earnedIncome",
        label: "Earned Income",
    }, {
        name: "mutualFund",
        label: "Mutual Fund / Brokerage Account",
    }, {
        name: "moneyMarketFund",
        label: "Money Market Fund",
    }, {
        name: "loans",
        label: "Loans",
    }, {
        name: "annuityContract",
        label: "Annuity Contract",
    }, {
        name: "otherLifeInsurancePolicy",
        label: "Other Life Insurance Policy",
    }, {
        name: "savings",
        label: "Savings",
    }];

    const checkBoxObj1 = [{
        name: "qualifiedFunds",
        label: "Qualified Funds - i.e. IRA, Beneficial IRA, 401(k), SEP, 403(b)",
        placeholder: "Provide Details",
        showDetailsBox: true,
        detailBoxType: "text"
    }];

    const checkBoxObj2 = [{
        name: "liquidAssetsInheritance",
        label: "Inheritance",
        placeholder: "Inheritance Details",
        showDetailsBox: (isLongForm(offerType) || isWorksheet(offerType)),
        detailBoxType: "text"
    }, {
        name: "liquidAssetsOther",
        label: "Other",
        placeholder: "Provide Other Details",
        showDetailsBox: (isLongForm(offerType) || isWorksheet(offerType)),
        detailBoxType: "text"
    }];

    const checkBoxObj3 = [{
        name: "premiumFinancing",
        label: "Premium Financing",
        placeholder: "Name of the company who is administering the premium finance",
        showDetailsBox: true,
        detailBoxType: "text"
    }];

    const checkBoxObj4 = [{
        name: "wealthGiftTransfer",
        label: "Wealth-Gift Transfer"
    }];

    if(!isLongForm(offerType)){
        checkBoxObj.push(...checkBoxObj4);
    }

    if(isLongForm(offerType) || isWorksheet(offerType)){
        checkBoxObj.push(...checkBoxObj1);
    }

    if (!isIliaApp(offerType)) {
        checkBoxObj.push(...checkBoxObj2);
    }

    if(isLongForm(offerType) || isWorksheet(offerType)){
        checkBoxObj.push(...checkBoxObj3);
    }

    if((user && user.role === 6) && ((strategy && strategy.slug === 'trizen') || (strategy && strategy.slug === 'esd'))){
        initialValues = groupUserInitVal;
        checkBoxObj = checkBoxObjGroupUser
    }

    useEffect(() => {
        if (sourceOfFundRef.current) {
            setSourceOfFundsRef(sourceOfFundRef);
        }
    }, [sourceOfFundRef.current]);

    const keysValidationBuilder = {
        is: true,
        then: Yup.string().required(REQUIRED_FIELD),
        otherwise: Yup.string().nullable(),
    }
    let keysValidation = {};
    if (isLongForm(offerType)) {
        keysValidation = {
            qualifiedFunds: Yup.boolean(),
            qualifiedFundsDesc: Yup.string().when("qualifiedFunds", keysValidationBuilder),
            liquidAssetsInheritance: Yup.boolean(),
            liquidAssetsInheritanceDesc: Yup.string().when("liquidAssetsInheritance", keysValidationBuilder),
            liquidAssetsOther: Yup.boolean(),
            liquidAssetsOtherDesc: Yup.string().when("liquidAssetsOther", keysValidationBuilder),
            premiumFinancing: Yup.boolean(),
            premiumFinancingDesc: Yup.string().when("premiumFinancing", keysValidationBuilder),
            lenderFundsDesc: Yup.string().when("premiumFinancing", keysValidationBuilder),
            loanRadio: Yup.string().when("premiumFinancing", keysValidationBuilder),
            clientObligated: Yup.string().nullable().when("premiumFinancing", keysValidationBuilder),
        };
    }
    if (isWorksheet(offerType)) {
        keysValidation = {
            liquidAssetsInheritance: Yup.boolean(),
            liquidAssetsInheritanceDesc: Yup.string().when("liquidAssetsInheritance", keysValidationBuilder),
            liquidAssetsOther: Yup.boolean(),
            liquidAssetsOtherDesc: Yup.string().when("liquidAssetsOther", keysValidationBuilder),
        };
    }

    //NIWC-2276
    // let validateDataFields: string[] = [];
    // if(isFromClientDetail){
    //     validateDataFields = [];
    // }
    //
    // if (isIliaApp(offerType)) {
    //     validateDataFields = ["liquidAssetsOtherDesc", "liquidAssetsInheritanceDesc"];
    // }
    //
    // validateDataFields.forEach((field: any) => {
    //     if(field === "liquidAssetsOtherDesc"){
    //         keysValidation[field] = Yup.string().ensure().when('liquidAssetsOther', {
    //             is: true,
    //             then: Yup.string().required("Required Field")
    //         })
    //     } else if(field === "liquidAssetsInheritanceDesc"){
    //         keysValidation[field] = Yup.string().ensure().when('liquidAssetsInheritance', {
    //             is: true,
    //             then: Yup.string().required("Required Field")
    //         })
    //     } else {
    //         keysValidation[field] = Yup.string().required("Required Field");
    //     }
    // });
    const validationSchema = Yup.object().shape(keysValidation);
    const textFieldCommon = {
        isRequired: true,
        disabled: clientSigned ? true : false,
        className: "mt30 floatLeft",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
    };
    const showComponent = (question: EnrollmentFinancialAgreementProps, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned) => {
        switch (question.additionalFieldType) {
            case "checkbox":
                return (
                        <>
                            {
                                question && values[question.name] === "Yes" &&
                                checkBoxObj2.map((item, index) => {
                                        return (<>
                                                <FormGroup className="floatLeft w100">
                                                    <FormControlLabel className="checkGroupMb0 floatLeft mt30"
                                                            control={<Checkbox className="padding0 mr10" checked={values[`${item.name}`] ? true : false}
                                                                               value={values[`${item.name}`] ? true : false}
                                                                               disabled={clientSigned ? true : false}
                                                                               color={"primary"}
                                                                               onChange={(e)=> {
                                                                                   enableSaveButton();
                                                                                   handleChangeCheckBox(e);
                                                                                   setFieldValue(`${item.name}`, e.target.checked)}}
                                                                               name={`${item.name}`}/>}
                                                            label={`${item.label}`}
                                                    />
                                                </FormGroup>
                                            {
                                                item && values[item.name] &&
                                                <>
                                                    <TextFieldCommon
                                                            {...textFieldCommon}
                                                            id={`${item.name}Desc`}
                                                            name={`${item.name}Desc`}
                                                            values={values}
                                                            onChange={(e)=>{
                                                                handleChange(e);
                                                                enableSaveButton();
                                                            }}
                                                            onBlur={handleBlur}
                                                            label={<Label label={`${item.label} Details`} required={!isFromClientDetail}/>}
                                                            placeholder={`${item.label === "Other" ? "Provide Other Details" : item.label+" "+ "Details"}`}
                                                    />
                                                    <ErrorCommon errors={errors}
                                                                 name={`${item.name}Desc`}
                                                                 touched={errors}/>
                                                </>
                                            }
                                        </>)}
                                )
                            }
                        </>
                );
            case "radio":
                return <></>;
                break;
            default:
                return (<>
                </>);
        }
    };

    return (
            <Grid container spacing={2} justify="space-between" className="mb15">
                <Grid item xs={12}>
                    <span className="hLine"/>
                    <Typography variant="body1" component="strong" className="mb15 floatLeft w100">
                        <Label label={"Source of Funds (how will you fund your policy?)"}
                               required={!isFromClientDetail}/>
                    </Typography>
                    <Typography variant="body2">
                        Payments made with foreign currency or payments drawn on or originating from a foreign bank or
                        other foreign source are prohibited. Funding through a Mortgage/Reverse Mortgage or Home Equity
                        Loan is prohibited.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <SourceOfFundsForm
                        autoSaveTrigger={autoSaveTrigger}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        updateClientFields={updateClientFields}
                        user={user}
                        disableSaveButton={disableSaveButton}
                        isFromClientDetail={isFromClientDetail}
                        errorInForm={errorInForm}
                        setErrorInForm={setErrorInForm}
                        checkBoxObj={checkBoxObj}
                        questionList={questionList}
                        enableSaveButton={enableSaveButton}
                        showComponent={showComponent}
                        clientSigned={clientSigned}
                        sourceOfFundRef={sourceOfFundRef}
                        //setIsError={setIsError}
                        handleChangeCheckBox={handleChangeCheckBox}
                        checkBoxObjError={checkBoxObjError}
                        setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr} financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                        sourceOfFundsRefs={sourceOfFundsRefs}
                        isWealthTransfer={isWealthTransfer}
                        sendSaveRequest={sendSaveRequest}
                        formType={formType}
                        setFormType={setFormType}
                        setSaveSourceOfFundsForm={setSaveSourceOfFundsForm}
                        offerType={offerType}
                />
                </Grid>
            </Grid>
    );
};

export default SourceOfFunds;