import {Field, useFormikContext} from "formik";
import {Label} from "../../../../../../components/formInputs";
import {FormHelperText, Grid, Typography} from "@material-ui/core";
import {TextField} from "../../../../../../components/formikFormInputs";
import React from "react";
import EnrollmentCheckBoxGroup from "./EnrollmentCheckBoxGroup";

type AdditionalFieldCheckBoxProps = {
    question: any,
    clientSigned: boolean,
    isFromClientDetail: boolean,
    checkIsError: Function,
    offerType: string
}

const AdditionalFieldCheckBox = (props: AdditionalFieldCheckBoxProps) => {
    const {question, clientSigned, isFromClientDetail, checkIsError} = props;
    const {values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur}: any = useFormikContext();
    const {name, additionalFieldCaption, additionalFieldName, additionalFieldLabel, additionalFieldType, additionalFieldOptions, additionalSubFieldName, additionalSubFieldType, additionalSubFieldLabel} = question;
    const additionalFieldValue = values[additionalFieldName];
    const additionalSubFieldValue = values[additionalSubFieldName];
    return (
            <div className="mt30">
                {!!additionalFieldCaption && <Grid item xs={12} md={12}>
                    <Typography variant="subtitle1" component="strong"
                                className="floatLeft">
                        {additionalFieldCaption}
                    </Typography>
                </Grid>}
                <Field fullWidth name={additionalFieldName}
                       label={additionalFieldLabel}
                       component={EnrollmentCheckBoxGroup}
                       disabled={clientSigned}
                       meta={{
                           error: isFromClientDetail ? false : !!errors[additionalFieldName],
                           touched: isFromClientDetail ? false : !!touched[additionalFieldName],
                       }}
                       input={{
                           name: additionalFieldName,
                           value: additionalFieldValue,
                           onChange: (value: any) => {
                               setFieldTouched(additionalFieldName);
                               setFieldValue(additionalFieldName, value);
                           },
                           style: {marginBottom: 0, display: "block"},
                       }}
                       options={additionalFieldOptions}
                       isFromClientDetail={isFromClientDetail}
                       clientSigned={clientSigned}
                       validate={(values) => {
                           let error = {};
                           if (Array.isArray(values) && values.length === 0) {
                               error[`${additionalFieldName}`] = "At least one checkbox required";
                               return error;
                           }
                       }}
                />

               {/* {!isFromClientDetail && additionalFieldType === "checkbox" && additionalFieldValue && additionalFieldValue.length == 0 &&
                        <FormHelperText className={"Mui-error"}> At least one checkbox
                            required </FormHelperText>
                }// Note : Do not need this because we are showing this (error) in the below.  */}
                {
                    errors[`${additionalFieldName}`] && <FormHelperText className={"Mui-error"}>{errors[`${additionalFieldName}`][`${additionalFieldName}`]}</FormHelperText>
                }
                {additionalSubFieldType && additionalSubFieldName && additionalFieldValue && additionalFieldValue.length > 0 &&
                        <>
                            <div className="floatLeft w100">
                                <Label label={additionalFieldLabel}/>
                            </div>
                            <Field
                                    fullWidth
                                    name={additionalSubFieldName}
                                    component={TextField}
                                    value={additionalSubFieldValue}
                                    error={isFromClientDetail ? false : (errors[additionalSubFieldName] ? true : checkIsError(additionalSubFieldValue, additionalFieldValue, values[name]))}
                                    helperText={isFromClientDetail ? "" : (errors[additionalSubFieldName] ? errors[additionalSubFieldName] : (checkIsError(additionalSubFieldValue, additionalFieldValue, values[name]) ? "Required" : ""))}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder={additionalSubFieldLabel}
                                    disabled={clientSigned}
                                    variant="outlined"
                            />
                        </>
                }
            </div>
    );
}

export default AdditionalFieldCheckBox;