import React from 'react';
import FocusError from "../../../../../../components/FocusError";
import {EnrollmentQuestionProps} from "../../../../../../model/enrollment";
import {Button} from "@material-ui/core";
import {Formik} from "formik";
import EnrollmentField from "./EnrollmentField";
type PropsType = {
    initialAnswer: any,
    validationSchema: any,
    offerType: string,
    setOpenKickOutPopup: Function,
    questionList: any,
    isFromClientDetail: boolean,
    setNextStep: Function,
    setInitialAnswer: Function,
    enrollmentActions: any,
    setIsQuestionFormDirty: Function | undefined,
    role: any,
    applicationQuestionSubmit: any, enrollment: any, client: any
    clientSigned: boolean,
    enrollmentId: string, checkIsError: Function, carrierValAlert: boolean | undefined,
    setEnrollmentId: Function, setActionItem: Function | undefined, onChangeOperationForQuestionAns: Function,
    user?: any,
    applicationQuestions?:any,
    applicationQuestionPayload?: Function
    setSaveApplicationQuestions?: Function
    errorInPhysicianAndMedicalDetails?: boolean
}
function ApplicationQuestionnaireFormContent(props: PropsType){
    const {
        initialAnswer,
        validationSchema,
        offerType,
        setOpenKickOutPopup,
        questionList,
        isFromClientDetail,
        setNextStep,
        setInitialAnswer,
        setIsQuestionFormDirty,
        role,
        applicationQuestionSubmit,
        clientSigned, checkIsError,
        onChangeOperationForQuestionAns,
        applicationQuestions,
        applicationQuestionPayload,
        setSaveApplicationQuestions,
        errorInPhysicianAndMedicalDetails
    } = props;
    return (
            <Formik
                    innerRef={applicationQuestions}
                    initialValues={initialAnswer}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        let payload:any = {};
                        if(offerType === "gi"){
                            if(values["Proposed_Insured_Questions-10"] === "No" || values["Proposed_Insured_Questions-26"] === "Yes"){
                                setOpenKickOutPopup(true);
                                setSubmitting(false);
                                return false;
                            }
                        }
                        if(offerType === "si"){
                            for(let qKey = 8; qKey <= 25; qKey++){
                                if(qKey !== 10 && values["Proposed_Insured_Questions-"+qKey] === "Yes"){
                                    setOpenKickOutPopup(true);
                                    setSubmitting(false);
                                    return false;
                                }
                                if(qKey === 10 && values["Proposed_Insured_Questions-"+qKey] === "No"){
                                    setOpenKickOutPopup(true);
                                    setSubmitting(false);
                                    return false;
                                }
                            }
                        }

                        let isError: boolean = false;
                        questionList.forEach((field) => {
                            if ((field.additionalFieldType === "checkbox") && values[(field && field.name) || ""] === "Yes" && values[(field && field.additionalFieldName) || ""] && values[(field && field.additionalFieldName) || ""].length === 0) {
                                isError = true;
                            }
                            if (values[(field && field.name) || ""] === "Yes" && field.additionalSubFieldType && field.additionalSubFieldType === "textfield" && checkIsError(values[(field && field.additionalSubFieldName) || ""], values[(field && field.additionalFieldName) || ""], values[(field && field.name) || ""])) {
                                isError = true;
                            }
                        });
                        if (isError && !isFromClientDetail) {
                            setSubmitting(false);
                            return false;
                        }
                        setNextStep();
                        setInitialAnswer(values);
                        if(typeof applicationQuestionPayload === 'function'){
                            payload = applicationQuestionPayload(values, errorInPhysicianAndMedicalDetails ? false:true);
                        }
                        if(payload) {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 400);
                        }
                    }}
            >
                {
                    ({
                         errors,
                         handleSubmit,
                         isSubmitting,
                         dirty,
                     }) => {
                        if (typeof setIsQuestionFormDirty === "function") {
                            setIsQuestionFormDirty(dirty);
                            if (Object.keys(errors).length > 0) {
                                setIsQuestionFormDirty(!dirty);
                            }
                        }
                        return (
                                <form onSubmit={handleSubmit}>
                                    <FocusError offset={true}/>
                                    {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                                            <EnrollmentField
                                                    question={question}
                                                    clientSigned={clientSigned}
                                                    isFromClientDetail={isFromClientDetail}
                                                    checkIsError={checkIsError}
                                                    onChangeFn={onChangeOperationForQuestionAns}
                                                    role={role}
                                                    offerType={offerType}
                                                    setSaveApplicationQuestions={setSaveApplicationQuestions}
                                            />
                                    ))}
                                    {(role === 1 || role === 2 || role === 4 || role === 5 || role === 6) ?
                                            <div style={{
                                                display: "none",
                                            }}><Button type="submit" className={"detailPageSubmitBtn"}
                                                       disabled={isSubmitting}
                                                       ref={applicationQuestionSubmit}></Button>
                                            </div> : <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="floatRight mb30"
                                                    disabled={isSubmitting || (clientSigned ? true : false)}
                                                    type={"submit"}
                                            >
                                                Save and Continue
                                            </Button>
                                    }
                                </form>
                        );
                    }}

            </Formik>
    )
}
export default ApplicationQuestionnaireFormContent;