import {ActionType, PagingParams} from "../model";
import {setAlert} from "./alert";
import {ProfileDetail} from "../model/account";

type GA = () => (dispatch: Function, getState: Function, api: { get: Function }) => Promise<any>;

const aggregatorApiPath = 'aggregator';

export const getAggregatorProfileDetails = (userId: string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get(aggregatorApiPath, {}, {userId});
        return dispatch({
            type: ActionType.GET_PROFILE_DETAILS,
            payload: resp
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getAggregatorDivision = (userId: string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get(`${aggregatorApiPath}/divisions`, {}, {userId});
        dispatch({
            type: ActionType.LIST_DIVISIONS,
            payload: resp
        });
        return resp;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

/**
 * Add Division to the Aggregator
 * @param payload
 */
export const addAggregatorDivision = (payload: string) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        let resp = await api.post(`${aggregatorApiPath}/divisions`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Division Added Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getAggregatorImos = (query: {any}) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get(`${aggregatorApiPath}/imos`, {}, query);
        dispatch({type: ActionType.GET_AGGREGATOR_IMO_LIST, payload: resp});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const deleteAggregatorDivision = (id: string) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        await api.delete(`${aggregatorApiPath}/divisions/${id}`, {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Division Deleted Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getImoAll = (searchText?: string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get(`${aggregatorApiPath}/imos/all`, {}, {searchText});
        return resp;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getSnapShotDetail = (userId: string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        let resp = await api.get(`${aggregatorApiPath}/snap-shot`, {}, {userId});
        return resp;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}
export const addAggregatorImo = (payload: string) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        await api.post(`${aggregatorApiPath}/imo`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "IMO Added Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const deleteImoFromAggregator = (imoId: number) => async (dispatch: Function, getState: Function, api: { delete: Function }) => {
    try {
        await api.delete(`${aggregatorApiPath}/imo/${imoId}`, {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "IMO Deleted Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

/**
 * Get Aggregators
 */
export const getAggregators: GA = () => async (dispatch, getState, api) => {
    try {
        const resp = await api.get(aggregatorApiPath);
        return dispatch({payload: resp, type: ActionType.GET_AGGREGATORS});
    } catch (err) {
        return dispatch({payload: err, type: ActionType.ALERT});
    }
};

/**
 * Flush Aggregators
 */
export const flushAggregators = () => async (dispatch)  => {
    return dispatch({type: ActionType.FLUSH_AGGREGATORS, payload: []});
};

/**
 * Create Aggregator
 * @param data
 */
export const addAggregator = (data: any) => async (dispatch: Function, _getState: Function, api: any)  => {
    try {
        let payload: any = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            aggregator: {
                aggregatorName: data.aggregator.aggregatorName,
                aggregatorEmail: data.aggregator.aggregatorEmail,
                aggregatorAddress: data.aggregator.aggregatorAddress,
                city: data.aggregator.city,
                state: data.aggregator.state,
                zipCode: data.aggregator.zipCode,
                phoneNumber: data.aggregator.phoneNumber,
                region: data.aggregator.region,
                divisions: data.aggregator.divisions,
                imoDivisions: data.aggregator.imoDivisions
            }
        };
        await api.post(aggregatorApiPath, payload, {});
        dispatch(setAlert('Account added successfully.', 'success'));
        return true;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

/**
 * Update Aggregator Details
 * @param data
 * @param userId
 */
export const updateAggregatorDetails = (data: any, userId: string) => async (dispatch: Function, _getState: Function, api: any)  => {
    try {
        let payload: any = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            aggregator : {
                aggregatorName: data.aggregator.aggregatorName,
                aggregatorEmail: data.aggregator.aggregatorEmail,
                aggregatorAddress: data.aggregator.aggregatorAddress,
                city: data.aggregator.city,
                state: data.aggregator.state,
                zipCode: data.aggregator.zipCode,
                phoneNumber: data.aggregator.phoneNumber,
                region: data.aggregator.region,
                divisions: data.aggregator.divisions,
                imoDivisions: data.aggregator.imoDivisions
            }
        };
        await api.put(`${aggregatorApiPath}/${userId}`, payload, {});
        dispatch(setAlert('Account Updated Successfully.', 'success'));
        // Getting user details
        return dispatch({
            type: ActionType.GET_PROFILE_DETAILS,
            payload
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

/**
 * Update Aggregator User Detail
 * @param data
 */
export const updateAggregatorUserDetail = (data: ProfileDetail) => async (dispatch: Function, _getState: Function, api: { put: Function }) => {
    try {
        let payload: any = data;
        await api.put(aggregatorApiPath, payload, {});
        dispatch(setAlert('Profile Updated Successfully.', 'success'));
        return dispatch({
            type: ActionType.GET_PROFILE_DETAILS,
            payload
        });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

/**
 * Admin Update Aggregator User Detail
 * @param data
 * @param userId
 */
export const adminUpdateAggregatorUserDetail = (data: ProfileDetail, userId: string) => async (dispatch: Function, _getState: Function, api: { put: Function }) => {
    try {
        let payload: any = data;
        await api.put(`${aggregatorApiPath}/admin/${userId}`, payload, {});
        return dispatch(setAlert('Profile Updated Successfully.', 'success'));
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

/**
 * List Aggregator Team Members
 * @param query
 * @param aggregatorUserId
 */
export const listAggregatorTeamMembers = (query: PagingParams, aggregatorUserId?: string) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
        const url = aggregatorUserId ? `${aggregatorApiPath}/${aggregatorUserId}/members` : `${aggregatorApiPath}/members`;
        let response = await api.get(url, {}, query);
        return {status: true, data: response}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
};

/**
 * Delete Admin Aggregator Team Member
 * @param id
 */
export const deleteAdminAggregatorTeamMember = (id: number) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
        await api.delete(`${aggregatorApiPath}/member/${id}`);
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Team member deleted.", statusCode: 200}
        });
        return {status: true, data: []}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
};

/**
 * Add Aggregator Member
 * @param payload
 */
export const addAggregatorMember = (payload: any) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
        let response = await api.post(`${aggregatorApiPath}/member`, payload);
        dispatch(setAlert('Team member added successfully.', 'success'));
        return {status: true, data: response}
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err

        });
        return {status: false, data: []}
    }
};