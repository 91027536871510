import React from "react";
import {
    Button,
    Grid,
    InputAdornment, Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField, Tooltip,
    Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TableContainer from "@material-ui/core/TableContainer";
import EnhancedTableHead from "../table/EnhancedTableHead";
import { formatBytes } from "../../utils/commonUtils";
import { asDbDateFormat } from "../../utils/dateUtils";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { DeleteModel } from "../../components/formInputs";
import { Pagination } from "../table";
import { UploadDocumentModal } from "./index";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import useQueryString from "../../hooks/useQueryString";
import { useActions } from "../../actions";
import * as CompanyActions from "../../actions/company";
import * as AWSActions from "../../actions/aws";
import { AccountSortList } from "../../model/account";
import TableWrap from "../TableWrap/TableWrap";

const useStyles = makeStyles((theme: Theme) => createStyles({

    root: {
        width: "100%",
        marginTop: "0",
        overflow: "auto",
        borderRadius: 6,
    },
    table: {
        minWidth: 700,
        overflowX: "auto",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    container: {
        maxHeight: 600,
    },

}));

const headRows: any[] = [
    { id: "documentName", label: "Name" },
    { id: "type", label: "Type" },
    { id: "size", label: "Size" },
    { id: "updatedAt", label: "Date Modified" },
    { id: "uploadedBy", label: "Uploaded By" },
];

export default function Documents(props: any) {
    const { detail } = props;
    const classes = useStyles();
    const user: any = useSelector((state: RootState) => state.auth.user);
    //const company = (user && user.role ) === 8 ? user.company: detail.company;

    const [open, setOpen] = React.useState(false);

    const [page, setPage] = useQueryString("page", 0),
            [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", 25),
            [search, setSearch] = useQueryString("search", ""),
            [orderBy, setOrderBy] = useQueryString("orderBy", "createdAt"),
            [order, setOrder] = useQueryString("order", "desc"),
            [id, setId] = React.useState<number>(0);

    const [documentList, setDocumentList] = React.useState<any>({ "count": 0, "rows": [] });

    const companyActions = useActions(CompanyActions);
    const awsActions = useActions(AWSActions);
    const getCompanyId = (user: any): number => {
        if ((user && user.role) === 8) {
            return user.company.id;
        } else if ((user && user.role) === 16) {
            return user.groupSigners.company.id;
        } else {
            return detail.company.id;
        }
    };

    async function documentlist() {
        /*let companyId;
        if (company && company.id) {
            companyId = company.id;
        } else if (props.match.params.id) {
            companyId = props.match.params.id;
        }*/
        const companyId = getCompanyId(user);
        if (companyId) {
            let queryObj: any = {
                page: page,
                rowsPerPage: rowsPerPage,
                searchText: search,
                sortBy: orderBy,
                order,
                companyId: companyId,
            };
            const list = await companyActions.getGroupDocuments(queryObj);
            setDocumentList(list);
        }
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    React.useEffect(() => {
        documentlist();
    }, [page, rowsPerPage, search, props.match.params, orderBy, order, props.history.location.pathname]);


    const handleSaveDocuments = async (payload: any) => {
        await companyActions.saveGroupDocuments(payload);
        documentlist();
    };

    const downloadDocument = async (document: any) => {
        const contentType = document.type.toLowerCase() === "pdf" ? "application/pdf" : "image/*";
        let file = document.fileName.split("/");
        const queryObj = {
            objectName: document.fileName,
            contentType: contentType,
        };
        const s3url = await awsActions.getSecureUrlAndDownload(file[0], queryObj, true);
        let blob = new Blob([new Buffer(s3url.Body, "binary")]
                , { type: s3url.ContentType });
        const url = window.URL.createObjectURL(blob);
        const link = window.document.createElement("a");
        link.href = url;
        link.setAttribute("download", file[1]);
        window.document.body.appendChild(link);
        link.click();
    };

    const deleteItem = async () => {
        await companyActions.deleteGroupDocuments(id);
        setId(0);
        documentlist();
    };

    return (
            <>
                <Grid container spacing={3} justify="space-between">
                    {
                        user && (user.role === 1 || user.role === 2) &&
                        <Grid item xs={12} md={12}>
                            <Typography variant="body1"> All documents relevant for this group including images,
                                applications, and proposals are available to view and download.
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <TextField
                                value={search}
                                label={"Search Documents"}
                                placeholder={"Search Documents"}
                                onChange={e => {
                                    setSearch(e.target.value);
                                }}
                                fullWidth
                                variant={"outlined"}
                                InputProps={{
                                    endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                        className="iconBtn"
                                                        aria-label="search"
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                    ),
                                }}
                        />
                    </Grid>
                    {((user && user.role === 1) || user.role === 2 || user.role === 8) &&
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                className="floatRight"
                                onClick={() => setOpen(true)}
                        >
                            Upload
                        </Button>
                    </Grid>
                    }
                </Grid>
                <TableWrap>
                        <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headRows={headRows}
                        />
                        <TableBody>
                            {
                                documentList.count === 0 &&
                                <TableRow>
                                    <TableCell colSpan={6} align="left">
                                        <Typography variant="caption" align="left" className="floatLeft">No
                                            Document</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                documentList.rows && documentList.rows.length > 0 &&
                                documentList.rows.map((item, idx) => {
                                            let role: string = "Group Admin";
                                            if (item.user && (item.user.role === 1 || item.user.role === 2)) {
                                                role = "NIW Admin";
                                            } else if (item.user && item.user.role === 16) {
                                                role = "Group Signer";
                                            }
                                            return (
                                                    <TableRow key={idx}>
                                                        <TableCell align="left">
                                                            <Typography color="primary" className="mb0">
                                                                {
                                                                    item.documentName
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                item.type.toUpperCase()
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                formatBytes(item.size)
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                asDbDateFormat(item.updatedAt)
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {`${item.user.firstName} ${item.user.lastName} (${role})`}
                                                        </TableCell>
                                                        <TableCell align="left">

                                                            <Tooltip title="Download">
                                                                <GetAppOutlinedIcon color="primary"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() => downloadDocument(item)}/>
                                                            </Tooltip>
                                                            {
                                                                (user.role === 1 || user.role === 2 || user.role === 8) &&
                                                                <Tooltip title="Delete">
                                                                    <DeleteOutlineIcon style={{ cursor: "pointer" }}
                                                                                className="colorRed" onClick={() => {
                                                                        setId(item.id);
                                                                    }}/>
                                                                </Tooltip>
                                                            }

                                                        </TableCell>
                                                    </TableRow>
                                            );
                                        },
                                )
                            }
                        </TableBody>
                </TableWrap>
                <Pagination page={Number(page)} rowsPerPage={Number(rowsPerPage)}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                            accountList={documentList}/>
                {
                    open && <UploadDocumentModal
                            onClose={() => setOpen(false)}
                            onSave={(payload: any) => {
                                setOpen(false);
                                payload.companyId = getCompanyId(user);
                                handleSaveDocuments(payload);
                            }}
                    />
                }
                {id > 0 &&
                <DeleteModel
                        open={true}
                        handleClose={() => setId(0)}
                        handleYes={deleteItem}
                        accountType={"image"}
                        title={"Delete Document"}
                        disableDelete={false}
                />
                }
            </>
    );
}