import React from 'react';
import {Field as FormikField} from "formik";
import {Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import {ErrorHelper, FormikValues} from "../formikFormInputs";
import {CommonProps} from "./index";
import {sleep, va} from "../../utils/commonUtils";


interface PLProps extends CommonProps, FormikValues {
    answerValue?: string;
    isRadio?: boolean;
    isSpecialCase?: boolean;
    options: string[];
}

const NoneOfThese = ['None', 'Unknown', 'No'];

const PickList: React.FC<PLProps> = (props) => {
    const {
        alias,
        answerValue,
        errors,
        helperText,
        isSpecialCase,
        isRadio,
        label,
        name,
        options,
        setFieldValue,
        values
    } = props;

    const currentValue = isSpecialCase ? (answerValue || values[name]) : values[name];
    const [selections, setSelections] = React.useState<string[]>(!!currentValue && !['true', 'false'].includes(currentValue) ? currentValue : []);

    const getCheckedState: (value: string) => boolean = (value) => {
        return isRadio ? currentValue === value : (!!va(currentValue) ? currentValue.includes(value) : false);
    };

    const handleChange = (e) => {
        const selVal = e.target.value;
        const selectedValues = typeof currentValue === 'object' && va(currentValue) ? (currentValue || selections) : selections;
        let finalSelections = [...selectedValues];
        if (!isRadio) {
            const foundAtIndex = finalSelections.findIndex(x => x === selVal);
            if (foundAtIndex > -1) {
                finalSelections.splice(foundAtIndex, 1);
            } else {
                const haseNoneOfTheseValue = finalSelections.findIndex((ele) => NoneOfThese.includes(ele)) >= 0
                // if User select NoneOfThese value then all fields should be unselected EXCEPT selected value OR if haseNoneOfTheseValue is true then no selectedVals arry length will be 1 no other value will be selected
                if (haseNoneOfTheseValue || NoneOfThese.includes(selVal)) {
                    finalSelections = [selVal];
                } else {
                    finalSelections.push(selVal);
                }
            }
            setSelections(finalSelections);
        }

        setFieldValue(name, isRadio ? selVal : finalSelections);
        return sleep(50);
    };

    const renderControl: (value: string) => JSX.Element = (value) => (
            <FormikField
                    as={isRadio ? Radio : Checkbox}
                    name={name}
                    onChange={handleChange}
                    value={value}
            />
    );

    React.useEffect(() => {
        if (isSpecialCase && values && name && values[name] && ['true', 'false'].includes(values[name])) {
            sleep(250).then(() => setFieldValue(name, []));
        }
    }, []);

    React.useEffect(() => {
        if (isSpecialCase && values && name && values[name] && va(values[name]) === 0) {
            sleep(300).then(() => {
                setSelections([]);
            });
        }
    }, [values && name && values[name]]);

    return (
            <FormControl component="fieldset" className="radioGroupAlz">
                <Typography className="floatLeft mt0 mb10" variant="body1">
                    {!!label && label}{alias && <> (<strong>{alias}</strong>) </>}
                </Typography>
                <RadioGroup aria-label={label} name={name} row>
                    <Grid container spacing={2}>
                        {
                                options && options.map((opt, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={4}>
                                            <FormControlLabel
                                                    checked={getCheckedState(opt)}
                                                    className="mb5 mt5"
                                                    control={renderControl(opt)}
                                                    label={opt}
                                            />
                                        </Grid>
                                ))
                        }
                    </Grid>
                </RadioGroup>
                {
                        !!label && helperText &&
                        <Typography className="floatLeft w100 f16 textBlackLight" variant="caption">
                            {helperText}
                        </Typography>
                }
                {
                        !!label &&
                        <ErrorHelper errors={errors} name={name}/>
                }
            </FormControl>
    )
};


export default PickList;
