import * as React from "react";
import {connect, useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import {Strategy} from "../../../../../model/account";
import LandingHeader from "./LandingHeader";
import LandingBody from "./LandingBody";
import {LOGO_BASE_URL} from "../../../../../constants";
import StrategyName from "../../../../../components/client/StrategyName";
import * as QueryString from "query-string";
import AOS from "aos";
import "aos/dist/aos.css";

// You can also pass an optional settings object
// below listed default settings
AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
    initClassName: "aos-init", // class applied after initialization
    animatedClassName: "aos-animate", // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation

});

type Props = {
    accountType: string,
    verifiedInvite: boolean | undefined;
    checked: boolean,
    updated: boolean,
    firstName: string,
    phoneNumber: string,
    email: string,
    role: number | undefined;
    strategy: Strategy;
    agent: any,
    client: any,
    match: any,
    authenticType?: string,
    companyId: number,
    groupDetail?: any,
    history?: any,
    location?: any,
    participant?: any,
    carrier?: any
}

const updateRootClasses = () => {
    document.body.classList.remove("clientClasses");
    document.body.classList.remove("adminClasses");
    const rootElement: any = document.querySelector("#rootElement");
    rootElement.classList.remove("bgBlue");
    rootElement.style.display = "flex";
    rootElement.style.boxShadow = "none";
};

const getLandingPageData = (props: any) => {
    let data: any = props;
    let strategyName: string = (data && data.strategy && data.strategy.name) || "Kai-Zen";
    let strategySlug: string = (data && data.strategy && data.strategy.slug) || "kaizen";

    const strategyLogo: string = data.strategy && data.strategy.landingPageLogo ? LOGO_BASE_URL + data.strategy.landingPageLogo : "";

    let videoUrl: string = "https://vimeo.com/660980875";

    if (strategySlug === 'esd') {
        videoUrl = "https://vimeo.com/394500327";
    }

    if (strategySlug === "kaizen") {
        videoUrl = "https://vimeo.com/660980875";
    }

    if (strategySlug === "trizen") {
        videoUrl = "https://vimeo.com/379864685";
    }

    if ( props.strategy && props.strategy.reBrand && data.strategy.slug === 'kaizen' ) {
        videoUrl = "https://vimeo.com/332479982/9820d53963";
    }
    if (  props.agent && props.agent.imo && props.agent.imo.reBrand && data.strategy.slug === 'esd' ) {
        videoUrl = "https://vimeo.com/731874277";
    }
    if (props.groupDetail && props.groupDetail.landingPageVideo) {
        videoUrl = props.groupDetail.landingPageVideo;
    }
    return {
        ...props.strategy,
        logo: strategyLogo,
        name: strategyName,
        videoUrl: videoUrl,
        slug : strategySlug
    };
};

const getStrategyName = (strategyName: string, registrationMark: boolean | undefined) => {
    return <StrategyName strategyName={strategyName} registrationMarkLandingPage={registrationMark}/>;
};

const ALLOWED_ACCOUNT_TYPE: { GROUP: string, INDIVIDUAL: string, CLIENTLIST: string } = {
    GROUP: "GROUP",
    INDIVIDUAL: "INDIVIDUAL",
    CLIENTLIST: "CLIENTLIST",
};

const LandingPage = (props: Props) => {
    const { accountType } = props;
    const [progress, setProgress] = React.useState(true);
    const [startEstimation, setStartEstimation] = React.useState(0);
    const { user, accessToken } = useSelector((state: RootState) => state.auth);

    const params = QueryString.parse(props.location.search);
    const strategyLogo: string = props.strategy && props.strategy.landingPageLogo ? LOGO_BASE_URL + props.strategy.landingPageLogo : "";


    let strategyName: string = (props.strategy && props.strategy.name) || "Kai-Zen";
    let strategySlug: string = (props && props.strategy && props.strategy.slug) || "kaizen";
    let registrationMark: boolean | undefined = true;
    if(props.strategy) {
        registrationMark = props.strategy.registrationMark;
    }
    let videoUrl: string = "https://vimeo.com/680515029";

    if (strategySlug === "kaizen") {
        videoUrl = "https://vimeo.com/680515029";
    }

    if (strategySlug === "trizen") {
        videoUrl = "https://vimeo.com/379864685";
    }

    if (strategySlug === "esd") {
        videoUrl = "https://vimeo.com/731874277";
    }

    if ( props.strategy && props.strategy.reBrand && strategySlug === 'kaizen' ) {
        videoUrl = "https://vimeo.com/332479982";
    }

    if (  props.agent && props.agent.imo && props.agent.imo.reBrand && strategySlug === 'esd' ) {
        videoUrl = "https://vimeo.com/394500327";
    }

    if (props.groupDetail && props.groupDetail.landingPageVideo) {
        videoUrl = props.groupDetail.landingPageVideo;
    }

    let strategyObj: any = {
        ...props.strategy,
        logo: strategyLogo,
        name: getStrategyName(strategyName, registrationMark),
        text: strategyName,
        slug: strategySlug,
        videoUrl: videoUrl,
    };
    const [strategyData, setStrategyData] = React.useState(strategyObj);

    async function verifyAccount() {
        setProgress(false);
    }

    async function verifyGroupAccount() {
        updateRootClasses();
        setProgress(false);
    }

    function verifyAccountAndRender(pageAccountType: string) {
        if (accessToken && user) {
            if ([0, 5, 6].indexOf(user.role || props.role || 0) === -1) {
                window.location.href = "/login";
                return false;
            }
            if( user && strategyData ){
                window.location.href = '/dashboard';
            }
            return false;
        }

        if (params && params.video === 'play') {
            props.history.push({
                pathname: window.location.pathname,
                search: "?landing=0&video=play",
            });
        } else {
            props.history.push({
                pathname: window.location.pathname,
                search: props && props.location.search ? props && props.location.search: "?landing=0", //sac
            });
        }

        switch (pageAccountType) {
            case ALLOWED_ACCOUNT_TYPE.GROUP :
                verifyGroupAccount();
                break;
            case ALLOWED_ACCOUNT_TYPE.CLIENTLIST :
                verifyGroupAccount();
                break;
            default :
                verifyAccount();
        }
    }

    //Render configuration for specific account
    React.useEffect(() => {
        let pageAccountType: string = accountType || ALLOWED_ACCOUNT_TYPE.INDIVIDUAL;
        verifyAccountAndRender(pageAccountType);
    }, []);

    React.useEffect(() => {
        let strategyObj: any = getLandingPageData(props);
        if (strategyObj && strategyObj.name) {
            strategyObj.name = getStrategyName(strategyObj.name, registrationMark);
            strategyObj.text = strategyName;
            strategyObj.slug = props.strategy ? props.strategy.slug : strategyObj.slug;
            setStrategyData(strategyObj);
        }
        setTimeout(() => {
            document.body.classList.remove("adminClasses");
            document.body.classList.remove("clientClasses");
        }, 500);
    }, [user, props.client]);

    if (progress) {
        return (
                <> </>
        );
    }

    const nextPage = (step: number) => {
        setStartEstimation(step);
        props.history.push({
            pathname: window.location.pathname,
            search: "?landing=" + step,
        });
    };

    const customProps: any = {
        ...props,
        strategyData,
        startEstimation,
        ALLOWED_ACCOUNT_TYPE,
        setStartEstimation: (step: number) => nextPage(step),
    };

    return (
            <>
                <LandingHeader
                        {...customProps}
                />
                <LandingBody {...customProps} />
            </>
    );
};

function mapStateToProps(state: RootState, ownProps: any) {
    let verifyData: any;
    if (state.verify.firstName) {
        verifyData = state.verify;
    }
    let agent: any = verifyData && verifyData.agent ? verifyData.agent : null;
    let authUser: any = state.auth && state.auth.user ? state.auth.user : null;

    if (!state.verify.firstName && !authUser && ownProps) {
        return ownProps;
    }

    if (authUser) {
        verifyData = authUser;
        return {
            accountType: ALLOWED_ACCOUNT_TYPE.INDIVIDUAL,
            authenticType: "",
            companyId: 0,
            groupDetail: null,
            verifiedInvite: verifyData && verifyData.verifiedInvite ? verifyData.verifiedInvite : "",
            checked: verifyData && verifyData.checked ? verifyData.checked : false,
            updated: verifyData && verifyData.updated ? verifyData.updated : false,
            firstName: verifyData && verifyData.firstName ? verifyData.firstName : "",
            phoneNumber: verifyData && verifyData.phoneNumber ? verifyData.phoneNumber : "",
            email: verifyData && verifyData.email ? verifyData.email : "",
            role: verifyData && verifyData.role ? verifyData.role : "",
            strategy: verifyData && verifyData.client && verifyData.client.strategy ? verifyData.client.strategy : null,
            client: verifyData && verifyData.client ? verifyData.client : null,
            agent: agent ? agent : (verifyData && verifyData.client && verifyData.client.agent ? verifyData.client.agent : null),
            participant: verifyData.participant,
            carrier: (verifyData.client && verifyData.client.carrier) || null,
            nationalLife : (verifyData && verifyData.nationalLife) || null
        };
    }
    return {
        accountType: ALLOWED_ACCOUNT_TYPE.INDIVIDUAL,
        authenticType: "",
        companyId: 0,
        groupDetail: null,
        verifiedInvite: verifyData && verifyData.verifiedInvite ? verifyData.verifiedInvite : "",
        checked: verifyData && verifyData.checked ? verifyData.checked : false,
        updated: verifyData && verifyData.updated ? verifyData.updated : false,
        firstName: verifyData && verifyData.firstName ? verifyData.firstName : "",
        phoneNumber: verifyData && verifyData.phoneNumber ? verifyData.phoneNumber : "",
        email: verifyData && verifyData.email ? verifyData.email : "",
        role: verifyData && verifyData.role ? verifyData.role : "",
        strategy: verifyData && verifyData.strategy ? verifyData.strategy : null,
        client: verifyData && verifyData.client ? verifyData.client : null,
        agent: agent ? agent : null,
        participant: verifyData.participant,
        carrier: (verifyData && verifyData.carrier) || null,
        nationalLife : (verifyData && verifyData.nationalLife) || null
    };
}

export default connect(mapStateToProps)(LandingPage);