import React from "react";
import { animateScroll as scroll } from "react-scroll/modules";
import { Grid, Typography } from "@material-ui/core";
import AppointmentSection from "./AppointmentSection";
import LeadContent from "./LeadContent";
import LeadEstimationForm from "../../components/lead/LeadEstimationForm";
import LeadFooter from "./LeadFooter";
import LearnEnrollment from "./LearnEnrollment";
import LearnMore from "./LearnMore";
import PrivacyPolicyPopUp from "./PrivacyPolicyPopUp";
import { noop } from "../../utils/commonUtils";
import { getQueryStringValue } from "../../utils/queryString";
import animation_Icon from "../client/landingPage/v2/images/animation_Icon.gif";

export default function LeadFinalStep(props: any) {
    const [estimatedData, setEstimatedData] = React.useState(0);
    const [totalContribution, setTotalContribution] = React.useState(props.totalContribution || 0);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = React.useState(false);
    const isMobile = window.innerWidth < 992;
    const age = getQueryStringValue("age");

    return (<>
                <div className="first-step">
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={8} lg={8} className="textCenter mt30">
                            <Typography variant="h1" className="mb20" color="primary">
                                Calculate Your Results
                            </Typography>
                            <Typography variant="body1" color="primary">
                                Enter your age, gender and an annual contribution amount to view your estimate.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={8} lg={8} className="textCenter mt0">
                            <LeadEstimationForm
                                    setTotalContribution={(val) => setTotalContribution(val)}
                                    setEstimationInputValues={noop}
                                    sendEstimationOnParent={(estimationData: any) => setEstimatedData(estimationData)}
                                    setAnnualContribution={noop}
                                    {...props}
                            />
                        </Grid>
                    </Grid>
                    {
                            !!estimatedData &&
                            <Grid container spacing={3} justify="center">
                                <Grid id={"estmateSectionThreeContant"} className="padding16" item xs={12} md={12}>
                                    <LeadContent
                                            estimatedDetail={estimatedData}
                                            totalContributionValue={totalContribution}
                                            {...props}
                                    />
                                </Grid>
                            </Grid>
                    }
                </div>
                <Grid item xs={12} md={12}>
                    <AppointmentSection {...props} />
                    <LearnMore {...props} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <LearnEnrollment />
                </Grid>
                <Grid item xs={12} md={12}>
                    <LeadFooter setShowPrivacyPolicy={setShowPrivacyPolicy} {...props} />
                </Grid>
                <PrivacyPolicyPopUp showPrivacyPolicy={showPrivacyPolicy}
                                    setShowPrivacyPolicy={setShowPrivacyPolicy} />

            </>

    );
}