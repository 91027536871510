import * as React from "react";
import { InputLabelProps } from "@material-ui/core";

export interface LabelProps extends InputLabelProps {
    label?: string|any;
	required?: boolean;
    style?: React.CSSProperties;
}

const label = ({ label, required, style}: LabelProps) => {
	return (
		<React.Fragment>
			<label style={style} className="mb0">{label} {
                    required && <span className="requiredHighlight">*</span>
            }
            </label>

		</React.Fragment>
	);
}
export default label;