import React from "react"
import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import { Field, Formik } from "formik";
import { DialogTitle } from "../../components/ConfirmEnrollment";
import { Label } from "../../components/formInputs";
import { PhoneNumberField, TextField } from "../../components/formikFormInputs";
import { doNone } from "../agent/AgentProfile/AgentGroupsTab";

export const ImoRequestDetailDialog = ({ status, requestDetail, handleClose, onApprove, onReject }) => {

    return (
            <Dialog
                    maxWidth={"md"}
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    IMOs and Carriers
                </DialogTitle>
                <Formik
                    initialValues={{
                        ...requestDetail
                    }}
                    onSubmit={doNone}>
                    {({
                          values,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <Field
                                                name={`imo.groupName`}
                                                label={<Label label={"IMO Name"}/>}
                                                component={TextField}
                                                value={values.imo.groupName}
                                                className={"fullWidthcustomFields"}
                                                disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Field name={`imo.imoClass`}
                                                label={<Label label={"IMO Status"}/>}
                                                component={TextField}
                                                value={values.imo.imoClass}
                                                className={"fullWidthcustomFields"}
                                                disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Field name={`imo.user.firstName`}
                                                label={<Label label={"Contact First Name"}/>}
                                                component={TextField}
                                                value={values.imo.user.firstName}
                                                className={"fullWidthcustomFields"}
                                                disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Field name={`imo.user.lastName`}
                                                label={<Label label={"Contact Last Name"}/>}
                                                component={TextField}
                                                value={values.imo.user.lastName}
                                                className={"fullWidthcustomFields"}
                                                disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Field name={`imo.officeNumber`}
                                                label={<Label label={"Primary Contact #"}/>}
                                                component={PhoneNumberField}
                                                value={values.imo.officeNumber}
                                                className={"fullWidthcustomFields"}
                                                disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Field name={`imo.carriers`}
                                                label={<Label label={"Carrier(s)"}/>}
                                                component={TextField}
                                                value={values.imo.carriers.map(x => x.carrier.carrierName).join(", ")}
                                                className={"fullWidthcustomFields"}
                                                disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <span className="hLine"/>
                                    </Grid>
                                </Grid>

                                <Button className="floatLeft floatRightSM mt30 mb30" variant="contained"
                                        color="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                {
                                    (status === "PENDING" || status === "REJECTED")  &&
                                    <Button
                                        className="floatRight mt30 mb30 ml15"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onApprove()}
                                    >
                                        Approve
                                    </Button>
                                }
                                {
                                    (status === "PENDING")  &&
                                    <Button
                                            className="floatRight mt30 mb30 bgRed"
                                            variant="contained"
                                            onClick={() => onReject()}
                                    >
                                        Reject
                                    </Button>
                                }
                            </DialogContent>
                        </form>)}
                </Formik>

            </Dialog>
    );
};
