import React from "react";
import {getQueryStringValue} from "../../../utils/queryString";
import {useActions} from "../../../actions";
import * as Actions from "../../../actions/enrollment";
import * as AccountActions from "../../../actions/account";
import * as DocusignActions from "../../../actions/docusign";
import LoaderGif from "../../../styles/images/loader_nw.gif";
import { sleep } from "../../../utils/commonUtils";

const AgentApplicationThanks = () => {
    let searchParams = window.location.search;
    let event_status: any;
    let enrollmentId: any;
    let isUploadTrusteeDocs: string | string[] = "0";
    let isSignTrusteeDoc: string | string[] = "0";
    let isAdminApprove: string | string[] = "0";
    let hipaaForm: string | string[] = "0";
    let userType: string | string[] = "0";
    let isAdminApproveSellerAgreement: string | string[] = "0";
    if (searchParams) {
        event_status = getQueryStringValue("event", searchParams) || "";
        enrollmentId = getQueryStringValue("enrollmentId", searchParams) || "";
        isUploadTrusteeDocs = getQueryStringValue("isUploadTrusteeDocs", searchParams) || "0";
        isSignTrusteeDoc = getQueryStringValue("isSignTrusteeDoc", searchParams) || "0";
        isAdminApprove = getQueryStringValue("isAdminApprove", searchParams) || "0";
        hipaaForm = getQueryStringValue("hipaaForm", searchParams) || "0";
        userType = getQueryStringValue("userType", searchParams) || "0";
        isAdminApproveSellerAgreement = getQueryStringValue("isAdminApproveSellerAgreement", searchParams) || "0";
        let isSignTrusteeDocFlag: any = searchParams.split("?isSignTrusteeDoc=1?");
        if (isSignTrusteeDocFlag.length === 2) {
            enrollmentId = getQueryStringValue("enrollmentId", isSignTrusteeDocFlag[1]) || "";
            isSignTrusteeDoc = "1";
        }
        let isUploadTrusteeDocsFlag: any = searchParams.split("?isUploadTrusteeDocs=1?");
        if (isUploadTrusteeDocsFlag.length === 2) {
            enrollmentId = getQueryStringValue("enrollmentId", isUploadTrusteeDocsFlag[1]) || "";
            isUploadTrusteeDocs = "1";
        }
        let splitAdminFlag: any = searchParams.split("?isAdminApprove=1?");
        if (splitAdminFlag.length === 2) {
            enrollmentId = getQueryStringValue("enrollmentId", splitAdminFlag[1]) || "";
            isAdminApprove = "1";
        }
    }

    let rootEl:any = document.getElementById('root');
    rootEl && rootEl.setAttribute('style', 'height:90vh;');
    console.log("**searchParams***", { searchParams, enrollmentId, event_status });

    const enrollmentActions = useActions(Actions);
    const accountActions = useActions(AccountActions);
    const docActions = useActions(DocusignActions);


    React.useEffect(() => {
        async function updateEnrollmentDetails() {
            if(isUploadTrusteeDocs == "1"){
                if (event_status === "signing_complete" && enrollmentId) {
                    console.log("***trustee_signing_complete***");
                    const payloadAdmin = {
                        enrollmentId,
                        actionType: 'UPLOAD_TRUSTEE_FINAL_DOCS',
                        type:'trustee'
                    }
                    const payloadTrustee = {
                        enrollmentId,
                        actionType: "SIGN_TRUSTEE_DOCUMENTS",
                    };
                    await accountActions.updateAdminActionItemsStatus(payloadAdmin);
                    await accountActions.createTrusteeActionItems(payloadTrustee);
                    setTimeout(()=>{Window['closeIframe']()}, 1000);
                }
                if (event_status === "viewing_complete") {
                    console.log("***trustee_viewing_complete***");
                    //perform action on viewing_complete event status on Upload final Trustee Documents
                    Window["closeIframe"]();
                }
            } else if (isSignTrusteeDoc == "1") {
                console.log("************isSignTrusteeDoc*******");
                if (event_status === "signing_complete" && enrollmentId) {
                    console.log("***isSignTrusteeDoc_signing_complete***");
                    const payloadTrustee = {
                        enrollmentId,
                        actionType: "SIGN_TRUSTEE_DOCUMENTS",
                    };
                    await accountActions.signTrusteeActionItemsStatus(payloadTrustee);
                    await enrollmentActions.updateloggedInUserId({enrollmentId: enrollmentId, actionItemOf: "Trustee"});
                    await enrollmentActions.updateEnrollmentStatus({
                        id: enrollmentId,
                        enrollmentStatus: 4,
                        enrollmentSubStatus: 1,
                    });
                    setTimeout(()=>{Window['adminCloseIframe']()}, 5000);
                }
                if (event_status === "viewing_complete") {
                    console.log("***viewing_complete***");
                    //perform action on viewing_complete event status on Sign Trustee Documents
                    Window['closeIframe']();
                }
            } else if( isAdminApprove == '1' ){
                console.log('***isAdminApprove***');
                if (event_status === "signing_complete" && enrollmentId) {
                    console.log('***signing_complete***');
                    const payloadAdmin = {
                        enrollmentId,
                        actionType: 'APPROVE_DOCUSIGN',
                        type:'admin'
                    }
                    await enrollmentActions.updateloggedInUserId({enrollmentId: enrollmentId, actionItemOf: "Admin"});
                    await accountActions.updateAdminActionItemsStatus(payloadAdmin);
                }
                if (event_status === "viewing_complete") {
                    console.log('***viewing_complete***');
                }
                setTimeout(()=>{Window['adminCloseIframe']()}, 5000);
            } else if (isAdminApproveSellerAgreement == '1') {
                // sellerAgreementId, sellerAgreementActionId
                const sellerAgreementActionId = getQueryStringValue("sellerAgreementActionId", searchParams) || "0";
                const sellerAgreementId = getQueryStringValue("sellerAgreementId", searchParams) || "0";
                console.log('***isAdminApproveSellerAgreement***', {sellerAgreementActionId, sellerAgreementId});
                if (event_status === 'signing_complete' && sellerAgreementId) {
                    console.log('***signing_complete***');
                    const resSDSAB = await docActions.sellerDocumentSetApprovedBy(sellerAgreementId, sellerAgreementActionId);
                    console.log('***seller_document_set_approved_by***', resSDSAB);
                }
                if (event_status === 'viewing_complete') {
                    console.log('***viewing_complete***');
                }
                await sleep();
                Window['adminCloseIframe']();
                await sleep(2500);
                const [confirmElemId, elemId] = ['viewer_iframe_confirm', 'viewer_iframe'];
                const confirmBtn: any = window.document.getElementById(confirmElemId);
                const iFrame: any = window.document.getElementById(elemId);
                confirmBtn && confirmBtn.click();
                iFrame && iFrame.remove();
            } else if (event_status === 'signing_complete' && hipaaForm == '-1') {
                console.log('***hipaaForm1***');
                if(enrollmentId){
                    await enrollmentActions.updateEnrollment({
                        id: enrollmentId,
                        enrollmentStatus: 1,
                        enrollmentSubStatus: 0,
                        version: "v2",
                    });
                }
                setTimeout(()=>{Window['closeIframe']()}, 2000);
            } else if (event_status === 'signing_complete' && hipaaForm == '5') {
                console.log('***hipaaForm2***');
                if(enrollmentId){
                    await enrollmentActions.updateEnrollment({
                        id: enrollmentId,
                        enrollmentStatus: 1,
                        enrollmentSubStatus: 52,
                        clientSigned: 1,
                        version: "v2",
                    });
                }
                setTimeout(()=>{Window['closeIframe']()}, 2000);
            } else if (event_status === 'signing_complete' && hipaaForm == '52' && userType == 'admin') {
                console.log('***isAdminApprove***');
                if (event_status === "signing_complete" && enrollmentId) {
                    console.log('***signing_complete***');
                    const payloadAdmin = {
                        enrollmentId,
                        actionType: 'APPROVE_DOCUSIGN',
                        type:'admin'
                    }
                    await enrollmentActions.updateloggedInUserId({enrollmentId: enrollmentId, actionItemOf: "Admin"});
                    await accountActions.updateAdminActionItemsStatus(payloadAdmin);
                }
                if (event_status === "viewing_complete") {
                    console.log('***viewing_complete***');
                }
                setTimeout(()=>{Window['adminCloseIframe']()}, 5000);
            } else if (event_status === 'signing_complete' && userType == 'trustee') {
                if (event_status === "signing_complete" && enrollmentId) {
                    console.log("***trustee_signing_complete***");
                    const payloadTrustee = {
                        enrollmentId,
                        actionType: "SIGN_TRUSTEE_DOCUMENTS",
                    };
                    await accountActions.signTrusteeActionItemsStatus(payloadTrustee);
                    await enrollmentActions.updateloggedInUserId({enrollmentId: enrollmentId, actionItemOf: "Trustee"});
                    await enrollmentActions.updateEnrollmentStatus({
                        id: enrollmentId,
                        enrollmentStatus: 4,
                        enrollmentSubStatus: 1,
                    });
                    setTimeout(()=>{Window['closeIframe']()}, 1000);
                }
                if (event_status === "viewing_complete") {
                    console.log("***trustee_viewing_complete***");
                    Window["closeIframe"]();
                }
            } else if (event_status === 'signing_complete' && hipaaForm == '2' && userType == 'client') {
                if (event_status === "signing_complete" && enrollmentId) {
                    console.log("***Client_Review_Proposal***");
                    if(enrollmentId){
                        await enrollmentActions.updateEnrollment({
                            id: enrollmentId,
                            enrollmentStatus: 3,
                            enrollmentSubStatus: 0,
                            clientSigned: 1,
                            version: "v2",
                        });
                    }
                    setTimeout(()=>{Window['closeIframe']()}, 1000);
                }
                if (event_status === "viewing_complete") {
                    console.log("***Client_Review_Proposal_complete***");
                    Window["closeIframe"]();
                }
            } else {
                if (event_status === "signing_complete" && enrollmentId) {
                    console.log('***signing_complete***');
                    await enrollmentActions.updateloggedInUserId({enrollmentId: enrollmentId, actionItemOf: "Agent"}); //update logged in user id in Agent Action Items as he sign and
                    // approve client application
                    await enrollmentActions.updateEnrollmentActionItem({enrollmentId: enrollmentId});
                    setTimeout(()=>{Window['closeIframe']()}, 1000);
                }
                if (event_status === "viewing_complete") {
                    console.log("***viewing_complete***");
                    Window["closeIframe"]();
                }
            }
            if (event_status === "cancel") {
                Window["closeIframe"]();
            }
        }
        updateEnrollmentDetails();
    }, []);


    Window["closeIframe"] = () => {
        // eslint-disable-next-line no-restricted-globals
        let backbtn: any = parent.document.getElementById("closebtn");
        backbtn && backbtn.click();
    };

    Window["adminCloseIframe"] = () => {
        // eslint-disable-next-line no-restricted-globals
        let backbtn: any = parent.document.getElementById("adminclosebtn");
        backbtn && backbtn.click();
    };

    return (
            <div className="commonLoaderWrap textCenter">
                <img src={LoaderGif} alt='Loading...'/>
            </div>
    );
};

export default AgentApplicationThanks;