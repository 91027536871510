import React from "react";
import RadioGroupCommon from "./RadioGroupCommon";

type SpouseIncomeProps = {
	disabled: boolean,
	values: any,
	conditionalRequired: boolean,
	touched: any,
	errors: any,
	classes: any,
	setFieldValue: Function,
	sendSaveRequest?: Function
}

function SpouseIncomeStatus(props: SpouseIncomeProps) {
	const {
		conditionalRequired,
		disabled,
		values,
		touched,
		errors,
		classes,
		setFieldValue,
		sendSaveRequest,
	} = props;

	const radioOptionArr = [
		{
			label: "Yes",
			value: "1",
		},
		{
			label: "No",
			value: "0",
		},
	];
	const callBack = (e: any) => {
		if (e && e.target) {
			setFieldValue("usingSpouseIncome", e.target.value);
		}

		if (Number(e.target.value) === 0) {
			setFieldValue("spouseIncome", "");
		}

		setFieldValue("currentlyEmployed", "");
		setFieldValue("parentsAreIncomeEarner", "");
	};
	return (
		<RadioGroupCommon
			cb={callBack}
			classes={classes}
			conditionalRequired={conditionalRequired}
			disabled={disabled}
			errors={errors}
			fieldName="usingSpouseIncome"
			label={"Do you need your spouse's income to help qualify for this product?"}
			radioOptionArr={radioOptionArr}
			sendSaveRequest={sendSaveRequest}
			touched={touched}
			values={values}
		/>
	);
}

export default SpouseIncomeStatus;