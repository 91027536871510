import React from 'react';
import GroupLinkAuth from '../components/GroupLinkAuth';
import { useActions } from "../actions";
import * as AccountActions from "../actions/account";

export default function GroupLinkPage(props: any) {
	const params: any = props.match.params;
	const [groupLinkDetail, setGroupLinkDetail] = React.useState(undefined);
	const accountActions = useActions(AccountActions);
	const getGroupLink = async () => {
		const resp = await accountActions.getGroupLinkByUrlId(params.id);
		setGroupLinkDetail(resp);
	}
	React.useEffect( () => {
		getGroupLink();
	}, []);

	return (
		<GroupLinkAuth>
			{{
				groupLinkDetail,
				history: props.history
			}}
		</GroupLinkAuth>
	);
}

