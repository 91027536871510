import React from "react";
import {AppBar, Card, CardContent, Grid, IconButton, Tab, Tabs, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {useActions} from "../../actions";
import * as ApplicationActions from "../../actions/application";
import {
    ApplicationAnswers,
    ApplicationInterview,
    ApplicationSection,
    EditQA,
    NextQuestion,
    OriginalAnswerPayload,
} from "../../model/application";
import {NS} from "../../model/misc";
import {Loader, NewlineToBreak, TabPanel} from "../Common";
import {ExpansionBox} from "../ExpansionBox";
import GenericDialog from "../dialog/GenericDialog";
import {Button} from "../formInputs";
import {
    AVIATION_QUESTION_CODE,
    AVOCATIONAL_QUESTION_CODE,
    DynamicForm,
    DynamicFormFieldEnum,
    HEIGHT_QUESTION_CODE,
    NO_MORE_MSG
} from "./index";
import {convertBooleanToYesNo, isDebug, isFunc, va} from "../../utils/commonUtils";
import {convertInchToFeet} from "./utilities";


type FinalizeApplicationProps = {
    enrollmentId: string;
    setBasicInfoStep?: Function;
};

const questionsCodeMapper = {
    OLI_UNKNOWN: "Unknown",
    OLI_OTHER: "No",
    OLI_TOBACCO_CURRENT: "Yes"
}

/**
 * Summary Page
 * @param enrollmentId
 * @param setBasicInfoStep
 * @constructor
 */
const Finalize: React.FC<FinalizeApplicationProps> = ({enrollmentId, setBasicInfoStep}) => {
    const debug = isDebug();
    const [answers, setAnswers] = React.useState<ApplicationAnswers>({} as ApplicationAnswers);
    const [interview, setInterview] = React.useState<ApplicationInterview[]>([] as ApplicationInterview[]);
    const [isExpanded, setIsExpanded] = React.useState<NS[]>([]);
    const [isFirstRun, setIsFirstRun] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState(true);
    const [reLoading, setReLoading] = React.useState(true);
    const [editAns, setEditAns] = React.useState<EditQA>({} as EditQA);
    const [enableSubmitAppButton, setEnableSubmitAppButton] = React.useState(false);
    const [startSubmitting, setStartSubmitting] = React.useState(false);
    const [tabVal, setTabVal] = React.useState(0);

    const applicationActions = useActions(ApplicationActions as any);

    const onSync = async (baseQuestionCode: string) => {
        debug && console.info('onSync: ', baseQuestionCode);
        return applicationActions.syncAnswers({baseQuestionCode, enrollmentId});
    };

    // Extra step to sync Aviation & Avocational questions
    const beforeSubmit = async () => {
        await onSync(AVIATION_QUESTION_CODE);
        await onSync(AVOCATIONAL_QUESTION_CODE);
    };

    const onSubmitApplication = async () => {
        setLoading(true);
        setStartSubmitting(true);
        await beforeSubmit();
        const payload = {enrollmentId};
        await applicationActions.submitApplication(payload);
        if (setBasicInfoStep && isFunc(setBasicInfoStep)) {
            setBasicInfoStep("1.3");
        }
        setLoading(false);
        setStartSubmitting(false);
    };

    const onGetApplication = async () => {
        if (!enrollmentId) {
            return;
        }

        // get application
        const data = await applicationActions.getApplication({enrollmentId: enrollmentId});
        if (data && data.body && data.body.applicationId) {
            const application = data.body;
            const {answers, interview} = application;
            setAnswers(answers);
            setInterview(interview);

            const sectionCodes = interview.flatMap(t => t.sections.map(c => c.code));
            setIsExpanded(sectionCodes);
            setLoading(false);
        }
    };

    const fetchNextQuestion = async () => {
        // disable submit application button
        setEnableSubmitAppButton(false);

        const data = await applicationActions.getNextQuestion({enrollmentId});

        // all questions answered
        if (data && data.message === NO_MORE_MSG) {
            // enable submit application button
            setEnableSubmitAppButton(true);
            // if lastBaseQuestion & is not first run after refresh then call sync
            if (!!data.lastBaseQuestionCode && !isFirstRun) {
                await onSync(data.lastBaseQuestionCode)
            }
        }
    };

    const handleExpansionChange = (s: ApplicationSection) => {
        const newIsExpanded = [...isExpanded];
        if (newIsExpanded.includes(s.code)) {
            // exists remove
            newIsExpanded.splice(newIsExpanded.findIndex(i => i === s.code), 1);
        } else {
            // insert
            newIsExpanded.push(s.code)
        }
        setIsExpanded(newIsExpanded);
    };

    const onCloseEdit = async () => {
        // initiate loader
        setReLoading(true);
        // verify all questions answered or not
        await fetchNextQuestion();
        await onGetApplication();
        setReLoading(false);
        return setEditAns({} as EditQA);
    };

    const onEdit = (question: any, isNonBase: boolean, answer: any) => {
        setEditAns({code: question.code, isNonBase, question, answer});
        setReLoading(false);
    };

    const renderQuestion = (q: NextQuestion) => {
        const type = q.answerType || q.questionType;
        const fo = {};
        Object.keys(DynamicFormFieldEnum).map(k => (Object.assign(fo, {[DynamicFormFieldEnum[k]]: k})));
        const isKnownType = !!(fo[type] && type !== DynamicFormFieldEnum.Heading);
        const foundAnswer = (!!va(answers[(q.customParentId || q.code) as string]) ? answers[(q.customParentId || q.code) as string].find(x => x.code === q.code) : {}) as OriginalAnswerPayload;
        const foundAnswerVal = !!q.customParentId ? q.answerValue : (!!foundAnswer ? foundAnswer.answerValue : '');
        const getValue = () => {
            switch (type) {
                case DynamicFormFieldEnum.Boolean:
                case DynamicFormFieldEnum.YesNo:
                    return convertBooleanToYesNo(foundAnswerVal);
                case DynamicFormFieldEnum.Text:
                    return <NewlineToBreak text={(questionsCodeMapper[foundAnswerVal] || foundAnswerVal)}/>;
            }
            switch (q.code) {
                case HEIGHT_QUESTION_CODE:
                    const [Feet, Inches] = convertInchToFeet(foundAnswerVal);
                    return `${Feet} feet ${Inches} inches`;
                default:
                    return (questionsCodeMapper[foundAnswerVal] || foundAnswerVal);
            }
        };

        return (
                <React.Fragment key={q.code}>
                    <Grid item md={6} xs={12}>
                        {
                                debug &&
                                <Typography className="mb0" variant="body1">
                                    <i>{q.code}</i>
                                </Typography>
                        }
                        {
                                !!q.aliasName &&
                                <Typography className="mb0 floatLeft w100" variant="body1" component="strong">
                                    {
                                        q.aliasName
                                    }
                                </Typography>
                        }
                        <Typography className="mb0" variant="caption">
                            {
                                q.questionText
                            }
                        </Typography>
                    </Grid>
                    <Grid className="textCenter" item md={4} xs={12}>
                        <Typography
                                className="mb0"
                                component="strong"
                                variant="caption"
                                style={{wordBreak: 'break-word'}}
                        >
                            <div style={{wordBreak: 'break-word'}}>
                                {
                                        !!foundAnswerVal && !!va(foundAnswerVal) &&
                                        (foundAnswerVal as unknown as string[]).join(", ")
                                }
                                {
                                        !!foundAnswerVal && !va(foundAnswerVal) &&
                                        getValue()
                                }
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        {
                                isKnownType &&
                                <IconButton
                                        aria-label={`edit_answer_${q.code}`}
                                        className="f16 floatRight"
                                        onClick={() => onEdit(q, !!q.customParentId, foundAnswerVal)}
                                >
                                    <EditIcon/>
                                </IconButton>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <span className="hLine"/>
                    </Grid>

                    {
                        // recursively render disclosure questions
                            q.disclosures && va(q.disclosures) &&
                            q.disclosures
                                    .flatMap(disclosure => disclosure.questions && disclosure.questions.map(questions => ({
                                        ...questions,
                                        aliasName: disclosure.aliasName
                                    })))
                                    .filter((question) => !!(question && question.customId))
                                    .map((question) => renderQuestion({
                                        ...question,
                                        baseQuestionCode: q.code,
                                        code: question.customId,
                                        customParentId: q.code,
                                        questionType: question.answerType
                                    } as unknown as NextQuestion))
                    }
                </React.Fragment>
        );
    };

    React.useEffect(() => {
        onGetApplication()
                .then(fetchNextQuestion)
                .then(() => setIsFirstRun(false));
    }, []);

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {
                            loading &&
                            <Loader/>
                    }
                    <div className="tabPositionSticky">
                        <AppBar position="sticky" color="secondary" className="boxShadowGlobal borderRadius6">
                            <Tabs
                                    aria-label="Finalize Summarize Application"
                                    className=""
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={(event: React.ChangeEvent<{}>, newValue: number) => setTabVal(newValue)}
                                    orientation="horizontal"
                                    scrollButtons="on"
                                    value={tabVal}
                                    variant="scrollable"
                            >
                                {
                                        !!va(interview) && interview.filter(a => a.sections.flatMap(b => b.baseQuestions).some(x => x.visible)).map(a => (
                                                <Tab className="" key={a.code} label={a.text}/>
                                        ))
                                }
                            </Tabs>
                        </AppBar>
                    </div>
                    <Card className="boxShadowGlobal">
                        <CardContent>
                            {
                                    !!va(interview) && interview.map((a, index) => {
                                        return (
                                                <TabPanel index={index} key={a.code || index} value={tabVal}>
                                                    {
                                                        a.sections.map(s => (
                                                                <ExpansionBox
                                                                        children={
                                                                            <Grid
                                                                                    alignContent="center"
                                                                                    container
                                                                                    justify="center"
                                                                            >
                                                                                {
                                                                                    s.baseQuestions.filter(q => q.visible).map(renderQuestion)
                                                                                }
                                                                                {
                                                                                        va(s.baseQuestions.filter(q => q.visible)) === 0 &&
                                                                                        <Grid item xs={12}>
                                                                                            <Typography variant="body1">
                                                                                                No data
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                        expanded={isExpanded.includes(s.code)}
                                                                        key={s.code}
                                                                        mainClassName="cardBoxShadowNone"
                                                                        onExpansionChange={() => handleExpansionChange(s)}
                                                                        summaryClassName="margin0 heightAuto padding0 customExpansionBox"
                                                                        summaryChildren={
                                                                            <Typography
                                                                                    className="mb0"
                                                                                    component="strong"
                                                                                    variant="subtitle2"
                                                                            >
                                                                                {
                                                                                    s.text
                                                                                }
                                                                            </Typography>
                                                                        }
                                                                />
                                                        ))
                                                    }
                                                </TabPanel>
                                        )
                                    })
                            }
                        </CardContent>
                    </Card>
                    <Typography variant="body1" className="mt30 floatLeft w100">
                        Please review your answers above. Press the pencil icon to make any changes if needed. Press
                        "Submit" to continue your enrollment application.
                    </Typography>
                    <Button
                            className="floatRight"
                            color="primary"
                            disabled={startSubmitting || !enableSubmitAppButton}
                            label="Submit Application"
                            loading={startSubmitting}
                            onClick={onSubmitApplication}
                            type="button"
                            variant="contained"
                    />

                    {
                            editAns && editAns.code &&
                            <GenericDialog
                                    aria-describedby="modify-application-pop-up"
                                    aria-labelledby="Modify Application"
                                    fullWidth
                                    maxWidth="lg"
                                    open={!!editAns && !!editAns.code}
                                    title="Modify Application"
                                    titleVariant="caption"
                            >
                                {
                                        reLoading &&
                                        <Loader/>
                                }
                                <DynamicForm editAns={editAns} enrollmentId={enrollmentId} onCloseEdit={onCloseEdit}/>
                            </GenericDialog>
                    }
                </Grid>
            </Grid>
    );
};


export default Finalize;
