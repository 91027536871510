import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Grid, IconButton, Typography} from "@material-ui/core";
import {
    ErrorCommon,
    RadioGroup as FormikRadioGroup,
    SelectField,
    TextField,
    TextFieldCommon,
} from "../../../../../components/formikFormInputs";
import {Label} from "../../../../../components/formInputs";
import * as AccountActions from "../../../../../actions/account";
import {
    inForcedFieldOptions,
    notTakenOptions,
    policyStatus,
    policyStatusArray,
    PolicyStatusEnum,
    revertPolicyStatus
} from "../../../../../constants";
import {Formik} from "formik";
import {useActions} from "../../../../../actions";
import CloseIcon from "@material-ui/icons/Close";
import PolicyOverrideConfirm from "./PolicyOverrideConfirm";

export default function PolicyStatus(props: any) {
    const { policyStatusPopup, handleClose, clientId, caseDetails, fetchCaseDetail } = props;
    const { inForcePolicyStatus } = caseDetails;
    const [policyStatusOptions, setPolicyStatusOptions] = React.useState<any>(policyStatus);
    const [submitDisable, setSubmitDisable] = React.useState(false);
    const [confirmPolicyOverride, setConfirmPolicyOverride] = React.useState(false);
    const [revertPolicyStatusOverride, setRevertPolicyStatusOverride] = React.useState(false);
    const fieldObj = {
        id: "currentPolicyStatus",
        name: "currentPolicyStatus",
        label: <Label label={"Current Policy Status "} required={true}/>,
        isRequired: true,
        component: SelectField,
        className: "SelectFieldWidth",
        variant: "outlined",
        options: policyStatusOptions,
        sizeMd: 6,
        onSelectChange: (val: string, setFieldValue) => {
            setSubmitDisable(false);
            setConfirmPolicyOverride(false);
            if(val === PolicyStatusEnum.Surrendered || val === PolicyStatusEnum.Deceased){
                setInitialFields([fieldObj, message]);
                setInForcedFields([]);
            } else if(val === PolicyStatusEnum.InForce){
                setInitialFields([fieldObj, inForceFieldObj]);
                setFieldValue("afterInForcedPolicyStatus", "");
                setInForcedFields([]);
            } else if (val === PolicyStatusEnum.InForceOverride) {
                setInitialFields([fieldObj]);
                setFieldValue("afterInForcedPolicyStatus", "");
                setInForcedFields([]);
                setSubmitDisable(true);
                if (caseDetails.currentPolicyStatus !== PolicyStatusEnum.InForceOverride) {
                    setConfirmPolicyOverride(true);
                }
            } else if (val === PolicyStatusEnum.InForceOverrideRevert) {
                setInitialFields([fieldObj]);
                setFieldValue("afterInForcedPolicyStatus", "");
                setInForcedFields([]);
                setSubmitDisable(true);
                setConfirmPolicyOverride(true);
                setRevertPolicyStatusOverride(true);
            }
        },
    };

    const reasons = {
        id: "reasons",
        name: "reasons",
        label: <Label label={"Reason for Change"} required={true}/>,
        isRequired: true,
        component: TextField,
        variant: "outlined",
        className: "TextFieldWidth",
        multiline: true,
        sizeMd: 12,
        rows: 2,
    };

    const message = {
        id: "message",
        name: "message",
        label: <Label label={"Reason for Change"} required={true}/>,
        isRequired: true,
        component: TextField,
        variant: "outlined",
        className: "TextFieldWidth",
        multiline: true,
        sizeMd: 12,
        rows: 2,
    };


    const notTakenObj = {
        id: "notTaken",
        name: "notTaken",
        label: <Label label={"Not Taken Options"} required={true}/>,
        isRequired: true,
        component: SelectField,
        className: "SelectFieldWidth",
        variant: "outlined",
        options: notTakenOptions,
        sizeMd: 6,
        onSelectChange: (val: string) => {
        },
    };

    const accountServiced = {
        id: "isAccountInService",
        name: "isAccountInService",
        label: <Label label={"Will this account continue to be serviced?"} required={true}/>,
        component: FormikRadioGroup,
        variant: "outlined",
        sizeMd: 6,
        className:"radioGroupForPolicyStatus"
    };

    const inForceFieldObj = {
        id: "afterInForcedPolicyStatus",
        name: "afterInForcedPolicyStatus",
        label: <Label label={"Additional Options for Policy Status"}/>,
        isRequired: true,
        component: SelectField,
        className: "SelectFieldWidth",
        variant: "outlined",
        options: inForcedFieldOptions,
        sizeMd: 6,
        onSelectChange: (val: string, setFieldValue:any) => {
            let options:any = [];
            switch (val) {
                case "cash-out":
                    options = [accountServiced, reasons];
                    setInForcedFields(options);
                    setFieldValue("notTaken", "");
                    setFieldValue("reasons", "");
                    break;
                case "living-benefits":
                    options = [accountServiced, reasons];
                    setInForcedFields(options);
                    setFieldValue("notTaken", "");
                    setFieldValue("reasons", "");
                    break;
                case "not-taken":
                    options = [notTakenObj, reasons];
                    setInForcedFields(options);
                    setFieldValue("isAccountInService", "");
                    setFieldValue("reasons", "");
                    break;
                default:
                    setInForcedFields([]);
                    break;
            }
        },
    };

    const formVal = {
        currentPolicyStatus: (caseDetails && caseDetails.currentPolicyStatus) || "",
        afterInForcedPolicyStatus: (inForcePolicyStatus && inForcePolicyStatus.afterInForcedPolicyStatus) || "",
        reasons: (inForcePolicyStatus && inForcePolicyStatus.reasons) || "",
        notTaken: (inForcePolicyStatus && inForcePolicyStatus.notTaken) || "",
        isAccountInService: (inForcePolicyStatus && inForcePolicyStatus.isAccountInService) || "",
        message:""
    };

    const { updatePolicyStatus } = useActions(AccountActions);
    const [initialFields, setInitialFields] = React.useState<any>([fieldObj]);
    const [initialValues, setInitialValues] = React.useState(formVal);
    const [inForcedFields, setInForcedFields] = React.useState([]);

    React.useEffect(() => {
        let inviteStatus:number = caseDetails && caseDetails.user && caseDetails.user.inviteStatus;
        let override:number = caseDetails && caseDetails.user && caseDetails.user.override;
        if(caseDetails && caseDetails.currentPolicyStatus === PolicyStatusEnum.InForce){
            inviteStatus = 22;
        }
        switch (inviteStatus) {
            case 0:
            case 1:
            case 2:
                const invitedObj = policyStatusArray.find((item)=>item.status === "INVITED");
                setPolicyStatusOptions(invitedObj.options);
                break;
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
                const enrollingObj = policyStatusArray.find((item)=>item.status === "ENROLLING");
                setPolicyStatusOptions(enrollingObj.options);
                break;
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                const proposedObj = policyStatusArray.find((item)=>item.status === "PROPOSED");
                setPolicyStatusOptions(proposedObj.options);
                break;
            case 17:
            case 18:
            case 19:
            case 20:
                const quotedObj = policyStatusArray.find((item)=>item.status === "QUOTED");
                setPolicyStatusOptions(quotedObj.options);
                break;
            case 21:
                const paymentReceivedObj = policyStatusArray.find((item)=>item.status === "PAYMENTRECEIVED");
                setPolicyStatusOptions(paymentReceivedObj.options);
                break;
            case 22:
                const caseSubmittedObj = policyStatusArray.find((item)=>item.status === "CASESUBMITTED");
                setPolicyStatusOptions(caseSubmittedObj.options);
                break;
            case 23:
            case 24:
            case 25:
                if (override && caseDetails.currentPolicyStatus === PolicyStatusEnum.InForceOverride) {
                    setPolicyStatusOptions([...policyStatus, ...revertPolicyStatus]);
                } else {
                    setPolicyStatusOptions(policyStatus);
                }
                break;
            case 26:
                const designObj = policyStatusArray.find((item)=>item.status === "DESIGN");
                setPolicyStatusOptions(designObj.options);
                break;
            default :
                setPolicyStatusOptions(policyStatus);
                break;
        }

        return (()=>{
            setPolicyStatusOptions([]);
        })
    },[])

    React.useEffect(() => {
        if(caseDetails && caseDetails.currentPolicyStatus === PolicyStatusEnum.InForce) {
            setInitialFields([fieldObj, inForceFieldObj]);
            if(caseDetails && caseDetails.inForcePolicyStatus){
                let afterInForcePolicyStatus:string = caseDetails.inForcePolicyStatus.afterInForcedPolicyStatus;
                let options:any = [];
                switch (afterInForcePolicyStatus) {
                    case "cash-out":
                        options = [accountServiced, reasons];
                        setInForcedFields(options);
                        break;
                    case "living-benefits":
                        options = [accountServiced, reasons];
                        setInForcedFields(options);
                        break;
                    case "not-taken":
                        options = [notTakenObj, reasons];
                        setInForcedFields(options);
                        break;
                    default:
                        break;
                }
            }
        } else if (caseDetails && caseDetails.currentPolicyStatus === PolicyStatusEnum.InForceOverride) {
            setInitialFields([fieldObj]);
            setSubmitDisable(true);
        } else {
            setInitialFields([fieldObj, message]);
            setInForcedFields([]);
        }
    }, [caseDetails, initialValues, policyStatusPopup]);

    React.useEffect(() => {
        let formVal:any = {};
        if((caseDetails && caseDetails.currentPolicyStatus === PolicyStatusEnum.InForce) ||
                (caseDetails && caseDetails.user && caseDetails.user.inviteStatus === 22)){
            formVal = {
                currentPolicyStatus: (caseDetails && caseDetails.currentPolicyStatus) || "",
                afterInForcedPolicyStatus: (inForcePolicyStatus && inForcePolicyStatus.afterInForcedPolicyStatus) || "",
                reasons: (inForcePolicyStatus && inForcePolicyStatus.reasons) || "",
                notTaken: (inForcePolicyStatus && inForcePolicyStatus.notTaken) || "",
                isAccountInService: (inForcePolicyStatus && inForcePolicyStatus.isAccountInService) || "",
                message:""
            };
        } else {
            formVal = {
                currentPolicyStatus: (caseDetails && caseDetails.currentPolicyStatus) || "",
                afterInForcedPolicyStatus: "",
                reasons: "",
                notTaken: "",
                isAccountInService: "",
                message: ""
            };
        }
        setInitialValues(formVal);
    }, [caseDetails]);

    const validate = ((value: any) => {
        const errors: { currentPolicyStatus?: string, reasons?: any, message?:any, isAccountInService?:string, notTaken?:string, afterInForcedPolicyStatus?:string } = {};
        if (!value.currentPolicyStatus) {
            errors.currentPolicyStatus = "Required";
        }
        if (["ENROLLING", "PROPOSED", "DESIGN", "QUOTED", "PAYMENTRECEIVED", "CASESUBMITTED", PolicyStatusEnum.Deceased, PolicyStatusEnum.Surrendered].indexOf(value.currentPolicyStatus) > -1 &&
            !value.message) {
            errors.message = "Required";
        }

        if(['cash-out','living-benefits','not-taken'].indexOf(value.afterInForcedPolicyStatus) > -1) {
            if(value.afterInForcedPolicyStatus === 'cash-out' || value.afterInForcedPolicyStatus === 'living-benefits'){
                if(!value.isAccountInService){
                    errors.isAccountInService = "Required";
                }
                if(!value.reasons){
                    errors.reasons = "Required";
                }
            }
            if(value.afterInForcedPolicyStatus === 'not-taken'){
                if(!value.notTaken){
                    errors.notTaken = "Required";
                }
                if(!value.reasons){
                    errors.reasons = "Required";
                }
            }
        }
        return errors;
    });

    return (
            <div>
                <Dialog fullWidth={true} maxWidth="md" open={policyStatusPopup} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Change Policy Status
                        <IconButton edge="end" className="floatRight" color="inherit" onClick={(e) => handleClose()}
                                    aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1" className="mb30">
                            You can change the policy status and leave the note for the notes tab. Make sure to leave a
                            detailed description with the reason for the status change.
                        </Typography>

                        <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validate={values => validate(values)}
                                //validationSchema={personalDetailSchema}
                                validateOnChange={true}
                                onSubmit={async (values, { setSubmitting }) => {
                                    let inForceData:any = [];
                                    inForceData = {
                                        currentPolicyStatus: values && values.currentPolicyStatus,
                                        afterInForcedPolicyStatus: values && values.afterInForcedPolicyStatus,
                                        isAccountInService: values && values.isAccountInService,
                                        notTaken: values && values.afterInForcedPolicyStatus === "not-taken" ? values && values.notTaken : "",
                                        reasons: values && values.reasons,
                                        message: values && values.message,
                                        notesType: "POLICY_STATUS"
                                     }

                                    await updatePolicyStatus(clientId, inForceData);
                                    setTimeout(async () => {
                                        setSubmitting(false);
                                        const formVal:any = {
                                            currentPolicyStatus: (caseDetails && caseDetails.currentPolicyStatus) || "",
                                            inForcedPolicyStatus: (values && values.afterInForcedPolicyStatus) || "",
                                            reasons: (values && values.reasons) || "",
                                            notTaken: (values && values.notTaken) || "",
                                            isAccountInService: (values && values.isAccountInService) || "",
                                            message: (values && values.message) || ""
                                        };
                                        setInitialValues(formVal);
                                        await fetchCaseDetail();
                                        handleClose();
                                    }, 500);
                                }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  setFieldValue,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty,
                              }) => (<form onSubmit={handleSubmit}>
                                <Grid container spacing={2} className="mb30">
                                    {initialFields.map((field, index) => {
                                        return (
                                                <Grid item md={field.sizeMd === 6 ? 6 : 12}
                                                      key={index} className="mb15">
                                                    <TextFieldCommon
                                                            {...field}
                                                            values={values}
                                                            onChange={handleChange}
                                                    />
                                                    <ErrorCommon errors={errors} name={field.name}
                                                                 touched={touched}/>
                                                </Grid>
                                        );
                                    })
                                    }
                                    {inForcedFields.length > 0 && inForcedFields.map((field:any, index) => {
                                        return (
                                                <Grid item md={field.sizeMd === 6 ? 6 : 12}
                                                      key={index} className="mb15">
                                                    <TextFieldCommon
                                                            {...field}
                                                            values={values}
                                                            onChange={handleChange}
                                                    />
                                                    <ErrorCommon errors={errors} name={field.name}
                                                                 touched={touched}/>
                                                </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                                <Button size="small" variant={"contained"} onClick={handleClose} color="secondary">
                                    Cancel
                                </Button>

                                <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="ml20 floatRight"
                                        size="small"
                                        disabled={submitDisable}
                                        //disabled={isSubmitting || (clientSigned ? true : false)}
                                >
                                    Save
                                </Button>
                            </form>)}
                        </Formik>
                    </DialogContent>
                </Dialog>
                { confirmPolicyOverride &&
                    <PolicyOverrideConfirm openPopup={confirmPolicyOverride} setConfirmPolicyOverride={setConfirmPolicyOverride} setSubmitDisable={setSubmitDisable}
                                           revertPolicyStatusOverride={revertPolicyStatusOverride}
                    />
                }
            </div>
    );
}
