import React from 'react';
import {IconButton, InputAdornment, InputAdornmentProps} from '@material-ui/core';


interface InpAdornmentProps extends InputAdornmentProps {
}

const InpAdornment: React.FC<InpAdornmentProps> = ({children, ...inputAdornmentProps}) => {
    return (
            <InputAdornment {...inputAdornmentProps}>
                <IconButton aria-label="search" className="iconBtn">
                    {
                        children
                    }
                </IconButton>
            </InputAdornment>
    );
};

export default InpAdornment;
