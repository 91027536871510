import React, { useEffect } from "react";
import { Box, Checkbox, Grid, Tooltip, Typography, withStyles } from "@material-ui/core";
import AttestationOfTruth from "../FinancialAndLiabilities/AttestationOfTruth";
import * as Yup from "yup";
import { EnrollmentFinancialAgreementProps } from "../../../../../../model/enrollment";
import Info from "@material-ui/icons/Info";
import { Label } from "../../../../../../components/formInputs";
import {
    ErrorCommon,
    NumberTextField,
    SelectField,
    TextField,
    TextFieldCommon,
} from "../../../../../../components/formikFormInputs";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useActions } from "../../../../../../actions";
import * as AccountActions from "../../../../../../actions/account";
import { pez, unmaskCurrency } from "../../../../../../utils/commonUtils";
import moment from "moment";
import { scroller } from "react-scroll";
import InforceLifeAlertPopup from "../InsuredQuestions/InforceLifeAlertPopup";
import InsuredQuestionsForm from "../InsuredQuestions/InsuredQuestionsForm";
import {
    iliaAppInsuredQuestionsNameArr,
    isIliaApp
} from "../Utilities";
import { useField, useFormikContext } from "formik";
import { GWT_GEN2_STRATEGY } from "../../../../../../constants";

const getNumberOfPolicies = () => {
    const policies: any = [];
    for (let i = 1; i <= 10; i++) {
        policies.push({
            id: i,
            key: i,
            value: i,
            name: i,
        });
    }
    return policies;
};
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#2f2f2f",
        maxWidth: "500px",
        minWidth: "100px",
        color: "#ffccc",
        lineHeight: "18px",
        fontWeight: "normal",
        padding: "20px",
        margin: "0",
        boxShadow: "0 0 15px 0px #8c8c8c",
    },
}))(Tooltip);

type Props = {
    isFromClientDetail: boolean,
    setIsError: Function,
    clientDetail: any,
    user: any,
    setQuestionButtonRef: Function,
    setNextStep: Function,
    offerType?: string,
    setActionItem?: Function
}

const InsuranceType = (props: { setInsuranceTypeVal: Function, handleChange: Function, setFieldValue: Function, clientDetail: any, values: any, insuranceTypeVal: string, errors: any, touched: any, clientSigned: boolean, isGWTGen2: boolean }) => {
    const {
        setInsuranceTypeVal,
        handleChange,
        setFieldValue,
        clientDetail,
        values,
        insuranceTypeVal,
        errors,
        touched,
        clientSigned,
        isGWTGen2
    } = props;
    const [field] = useField("insuranceType");
    return <RadioGroup
            {...field}
            onChange={(e) => {
                setInsuranceTypeVal(e.target.value);
                handleChange(e);
                if (e.target.value === "personalInsurance") {
                    setFieldValue("businessInsurance", "");
                    setFieldValue("personalInsurance", values["personalInsurance"]);
                } else if (e.target.value === "businessInsurance") {
                    setFieldValue("personalInsurance", "");
                    setFieldValue("businessInsurance", values["businessInsurance"]);
                }
            }}
            value={values["insuranceType"] || insuranceTypeVal}
            style={{display: "inline"}}>
        <Grid container spacing={2}>
            {[
                {
                    label: "Personal Insurance",
                    value: "personalInsurance",
                },
                {
                    label: "Business Insurance",
                    value: "businessInsurance",
                },
            ].map((option: any, idx: number) => (
                    <Grid item xs={12} md={6} lg={6}>
                        <FormControlLabel
                                className="mb0"
                                disabled={!!clientSigned || isGWTGen2}
                                value={option.value}
                                control={
                                    <Radio
                                            className="mb0"
                                            color="primary"/>}
                                label={option.label}
                        />
                    </Grid>
            ))}
        </Grid>
        {errors[`insuranceType`] && (
                <ErrorCommon errors={errors}
                             name={`insuranceType`}
                             touched={touched}/>
        )}
    </RadioGroup>
}


const PersonalInsurance = (props: {setFieldValue: Function, personalInsuranceType: string | null, values: any, enableSaveButton: Function, setInsuranceError: Function, personalRadio: any,
    clientSigned: boolean, isGWTGen2: boolean, touched: any, errors: any
}) => {
    const {personalInsuranceType, setFieldValue, enableSaveButton, setInsuranceError, values, personalRadio, clientSigned, isGWTGen2, touched, errors} = props;
    const [field] = useField("personalInsurance");
    return <RadioGroup aria-label="personalInsurance" {...field}
                       value={personalInsuranceType ? personalInsuranceType : values["personalInsurance"]}
                       onChange={(e) => {
                           setFieldValue("personalInsurance", e.target.value);
                           setFieldValue("businessInsurance", "");
                           enableSaveButton();
                           setInsuranceError(false);
                       }}>
        <Grid container spacing={2}>
            {
                personalRadio.map((item, index) => {
                    return (
                            <Grid item xs={12} md={12} lg={6}>
                                <FormControlLabel
                                        className="formikRadio ml0 mr5"
                                        value={`${item.value}`}
                                        disabled={!!clientSigned || isGWTGen2}
                                        control={<Radio
                                                color={"primary"}
                                                className="padding0"/>}
                                        label={`${item.label}`}/>
                                {
                                        item.label == "Wealth-gift Transfer" &&
                                        <Box component="span">
                                            <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography
                                                                    className="f16 txtWhite mb0">
                                                                A gift tax is a
                                                                tax on the
                                                                transfer of
                                                                property by one
                                                                individual to
                                                                another while
                                                                receiving
                                                                nothing, or less
                                                                than full value,
                                                                in return. If
                                                                you are paying
                                                                the premiums for
                                                                another party
                                                                other than your
                                                                spouse, it may
                                                                be considered a
                                                                gift or transfer
                                                                of wealth and my
                                                                be subject to
                                                                tax, unless it
                                                                is within the
                                                                annual exclusion
                                                                amount or under
                                                                the lifetime
                                                                wealth transfer
                                                                amount. Any
                                                                amounts beyond
                                                                the approved
                                                                exclusion
                                                                amounts would be
                                                                considered a
                                                                gift and the tax
                                                                applied whether
                                                                or not the donor
                                                                intends the
                                                                transfer to be a
                                                                gift. NIW does
                                                                not give tax
                                                                advice or
                                                                guidance please
                                                                consult with a
                                                                tax specialist
                                                                for advice.
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                            >
                                                <Info className="f22 icon mt5"
                                                      color="primary"/>
                                            </HtmlTooltip>
                                        </Box>
                                }

                            </Grid>);
                })
            }
            {
                    (touched && errors) &&
                    <ErrorCommon errors={errors} name="personalInsurance"
                                 touched={touched}/>
            }

        </Grid>
    </RadioGroup>
}

const BusinessInsurance = (props: {
    values: any, setFieldValue: Function, enableSaveButton: Function, setInsuranceError: Function, businessInsurance: any, clientSigned: boolean
    touched: any, errors: any
}) => {
    const {
        values,
        setFieldValue,
        enableSaveButton,
        setInsuranceError,
        businessInsurance,
        clientSigned,
        touched,
        errors
    } = props;
    const [field] = useField("businessInsurance");
    return <RadioGroup aria-label="businessInsurance"
                       {...field}
                       value={values["businessInsurance"]}
                       onChange={(e) => {
                           setFieldValue("businessInsurance", e.target.value);
                           setFieldValue("personalInsurance", "");
                           enableSaveButton();
                           setInsuranceError(false);
                       }}>
        <Grid container spacing={2}>
            {
                businessInsurance.map((item, index) => {
                    return <Grid item xs={12} md={12} xl={6}
                                 style={{display: "flex"}}><FormControlLabel
                            className="formikRadio ml0"
                            value={`${item.value}`}
                            disabled={clientSigned ? true : false}
                            control={<Radio color={"primary"}
                                            className="padding0 mb0"/>}
                            label={`${item.label}`}/>
                    </Grid>;
                })
            }
            {
                    (touched && errors) &&
                    <ErrorCommon errors={errors}
                                 name="businessInsurance"
                                 touched={touched}/>
            }
        </Grid>
    </RadioGroup>
}
const InsuredQuestionsContent = (props: Props) => {
    const classes = useStyles();
    const { isFromClientDetail, setQuestionButtonRef, user, setNextStep, setActionItem, offerType = "" } = props;
    const { updateClientFields, getNationalLifeDetails } = useActions(AccountActions);
    const [isSubmittedQuestions, setIsSubmittedQuestions] = React.useState<boolean>(false);
    const [attestRef, setAttestRef] = React.useState<any>(null);
    const [insuranceError, setInsuranceError] = React.useState<any>(false);
    const [nationalLifeId, setNationalLifeId] = React.useState(0);
    const [openInforceLifeAlertPopup, setOpenInforceLifeAlertPopup] = React.useState(false);
    const [insuranceTypeVal, setInsuranceTypeVal] = React.useState<string>("personalInsurance");
    const [personalInsuranceType, setPersonalInsuranceType] = React.useState<string | null>(null);
    const [saveApplicationQuestions, setSaveApplicationQuestions] = React.useState<object>({});
    const insureRef: any = React.useRef<any>();
    const insuredQuestions: any = React.useRef<any>(null);
    const buttonRef = React.useRef<any>();
    let enrollment: any = (user && user.enrollment) || undefined;
    let clientSigned: boolean = (enrollment && (enrollment.clientSigned || enrollment.envelopeId)) || false;
    const answersJson = enrollment && enrollment.answers ? JSON.parse(enrollment.answers) : [];
    //console.log("enrollment in line 90 -=-=-=-", enrollment);
    let isChecked: boolean = false;
    if (enrollment) {
        isChecked = `${enrollment.enrollmentStatus}.${enrollment.enrollmentSubStatus}` > "1.2";
    }
    const [pageChecked, setPageChecked] = React.useState<boolean>(isChecked);

    let clientDetail = (user && user.client && user.client) || undefined;

    React.useEffect(() => {
        setPageChecked(clientDetail.insuredAgreed);
    }, [clientDetail.insuredAgreed]);

    React.useEffect(() => {
        if (!pageChecked && offerType !== "gi" && isSubmittedQuestions && attestRef && attestRef.current) {
            setIsSubmittedQuestions(false);
            scroller.scrollTo(attestRef.current.id, {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: -200,
            });
        }
    }, [isSubmittedQuestions === true, pageChecked]);

    const strategySlug: string = clientDetail && clientDetail.strategy && clientDetail.strategy.slug ? clientDetail.strategy.slug : "";

    const isCorporation = (user && user.client && user.client.company && user.client.company.groupType && user.client.company.groupType === "Corporation") || false;

    const isAssociation = (user && user.client && user.client.company && user.client.company.groupType && user.client.company.groupType === "Association") || false;

    // GWT Gen2 Great Wealth Transfer
    const isGWTGen2 = clientDetail.strategyId === GWT_GEN2_STRATEGY;

    const initialValues = {
        noOfPolicies: clientDetail.noOfPolicies ? clientDetail.noOfPolicies + "" : "",
        insuredAgreed: false,
        insuranceType: clientDetail.insuranceType || "",
        personalInsurance: isGWTGen2 ? "Wealth-gift Transfer" : clientDetail.personalInsurance || (offerType === 'gi' || offerType === 'si' || strategySlug === "esd" ? "Other" : ""),//Other
        businessInsurance: clientDetail.businessInsurance || (offerType === "gi" || offerType === "si" ? "" : ""),//Split Dollar
        mortgageAmount: clientDetail.mortgageAmount || "",
        insuranceDescription: pez(clientDetail.insuranceDescription, isGWTGen2 ? "This is a Great Wealth Transfer Case." : ""),
        inforcePolicy: clientDetail.inforcePolicy ? "Yes" : clientDetail.inforcePolicy === 0 ? "No" : null,
        noOfInforcePolicies: clientDetail.noOfInforcePolicies ? clientDetail.noOfInforcePolicies + "" : "",
        inforceAmount: clientDetail.inforceAmount || "",
        liAmount: (clientDetail.liAmountNone === null ? "" :  clientDetail.liAmountNone ? "" : clientDetail.liAmount) || "",
        inforceLifeDesc: clientDetail.inforceLifeDesc || "",
        existPolicies: clientDetail.existPolicies ? "Yes" : clientDetail.existPolicies === 0 ? "No" : null,
        isInforceLife: clientDetail.isInforceLife ? "Yes" : clientDetail.isInforceLife === 0 ? "No" : null,
        "inforceAmountNone": clientDetail.inforceAmount ? false : true,
        "liAmountNone": clientDetail.liAmountNone === null ? false :  clientDetail.liAmountNone ? true : clientDetail.liAmount ? false : true,
        "Insured_Questions-2": "",
        //"Insured_Questions-3": "",
        //"Insured_Questions-4": "",
        //"Insured_Questions-5": "",
        "Insured_Questions-6": "",
        "Insured_Questions-7": "",
        "Insured_Questions-8": "",
        "Insured_Questions-9": "",
        "Insured_Questions-6-checkbox": false,
        "Insured_Questions-9-checkbox": false,
        "inForcePolicies": clientDetail.ClientInforcePolicies && clientDetail.ClientInforcePolicies.length > 0 ?
                clientDetail.ClientInforcePolicies.map(policy => ({
                    id: policy.id,
                    nameOfCompany: policy.nameOfCompany,
                    policyNumber: policy.policyNumber,
                    dateIssuedOrAppliedFor: policy.dateIssuedOrAppliedFor ? policy.dateIssuedOrAppliedFor : moment().format("YYYY"),
                    coverageFaceAmount: policy.coverageFaceAmount,
                    adbCoverage: policy.adbCoverage,
                    type: policy.type,
                    willThisBeReplaced: policy.willThisBeReplaced,
                    // TODO // willThisBeReplaced_desc: "",
                    willBothPoliciesBeTaken: policy.willBothPoliciesBeTaken,
                    status: policy.status,
                })) : [],

    };
    if (isIliaApp(offerType)) {
        iliaAppInsuredQuestionsNameArr.forEach((iteam) => { // set initial values from enrollment answers JSON
            initialValues[`${iteam}`] = answersJson[`${iteam}`];
        });
        /*participationArr.forEach((iteam) => { // set initial values from enrollment answers JSON
            initialValues[`${iteam}`] = answersJson[`${iteam}`];
        });*/
    }

    React.useEffect(() => {
        const getNatioanlLifeCarrierDetails = async () => {
            const nationalLifeDate = await getNationalLifeDetails();
            if (nationalLifeDate && nationalLifeDate.nationalLife) {
                setNationalLifeId(nationalLifeDate.nationalLife.id);
            }
        };
        setInsuranceTypeVal(clientDetail && clientDetail.insuranceType);
        if (clientDetail && clientDetail.currentlyEmployed && clientDetail.currentlyEmployed.toLowerCase() === "other") {
            if (clientDetail && clientDetail.parentsAreIncomeEarner) {  //WT
                setInsuranceTypeVal("personalInsurance");
                setPersonalInsuranceType("Wealth-gift Transfer");
            }
        }
        getNatioanlLifeCarrierDetails();
    }, []);

    React.useEffect(() => {
        if (Object.keys(saveApplicationQuestions).length > 0) {
            saveMe(saveApplicationQuestions); // auto save
        }
    }, [saveApplicationQuestions]);

    /*React.useEffect(()=>{
        console.log("insuredQuestions && insuredQuestions.current && insuredQuestions.current.dirty", insuredQuestions && insuredQuestions.current && insuredQuestions.current.dirty)
        if(insuredQuestions && insuredQuestions.current && insuredQuestions.current.dirty) {
            saveMe(insuredQuestions && insuredQuestions.current && insuredQuestions.current.values);
        }
    },[insuredQuestions && insuredQuestions.current && insuredQuestions.current.values])*/

    let keysValidation = {};
    let validateDataFields = ["noOfPolicies", "inforcePolicy", "noOfInforcePolicies", "inforceAmount", "liAmount", "inforceLifeDesc", "insuranceType", "businessInsurance", "personalInsurance"];

    if (offerType === "gi-to-full" || offerType === "si-to-full") {
        validateDataFields = ["noOfPolicies", "inforcePolicy", "noOfInforcePolicies", "inforceAmount", "liAmount", "inforceLifeDesc", "insuranceType", "businessInsurance", "personalInsurance"];
    }
    if (offerType === "full") {
        validateDataFields = ["noOfPolicies", "inforcePolicy", "noOfInforcePolicies", "inforceLifeDesc", "insuranceType", "businessInsurance", "personalInsurance"];
    }

    if (offerType === "gi") {
        validateDataFields = ["noOfPolicies"];
    }
    if (offerType === "gi" && isAssociation) {
        validateDataFields = ["noOfPolicies"];
    }
    if ((offerType === "si" || offerType === "gi") && isCorporation) {
        let insuranceTypeIndex = validateDataFields.indexOf("insuranceType");
        if (insuranceTypeIndex !== -1) {
            validateDataFields.splice(insuranceTypeIndex, 3);
        }
    }
    if (isIliaApp(offerType)) {
        validateDataFields = ["noOfPolicies", "noOfInforcePolicies", "insuranceType", "businessInsurance", "personalInsurance",
            "Proposed_Insured_Questions-3", "Remark-101", "Proposed_Insured_Questions-4", "Remark-102",
            "Proposed_Insured_Questions-5", "Proposed_Insured_Questions-10", "inforceLifeDesc",
            "Proposed_Insured_Questions-12", "Remark-110", "Insured_ParticipationDetails",
            "Proposed_Insured_Questions-20", "Proposed_Insured_Questions-30", "Proposed_Insured_Questions-21", "isInforceLife", "liAmount"
        ];
        if(!(user && user.role === 6 && user.client && user.client.companyId)){
            validateDataFields = [...validateDataFields, "existPolicies"]
        }
        if(!(offerType === 'gi' && !isAssociation) ){
            validateDataFields = [...validateDataFields, "inforcePolicy"]
        }
    }
    if (isFromClientDetail) {
        validateDataFields = [];
    }

    validateDataFields.forEach((field: any) => {
        if (field === "noOfPolicies") {
            keysValidation[field] = Yup.string().ensure().when("existPolicies", {
                is: "Yes",
                then: Yup.string().required("Required Field"),
            });
        } else if (field === "noOfInforcePolicies") {
            keysValidation[field] = Yup.string().ensure().when("inforcePolicy", {
                is: "Yes",
                then: Yup.string().required("Required Field"),
            });
        } else if (field === "inforceAmount") {
            keysValidation[field] = Yup.string().when("inforceAmountNone", {
                is: true,
                then: Yup.string().notRequired(),
                otherwise: Yup.string().trim().test(
                        "inforceAmount",
                        "Min value should be 1 or more",
                        function(item) {
                            const inforceAmountValue = Number(unmaskCurrency(this.parent.inforceAmount));
                            return (inforceAmountValue > 0);
                        },
                ).required("Required Field"),
            });
        } else if (field === "liAmount") {
            keysValidation[field] = Yup.string().when("liAmountNone", {
                is: true,
                then: Yup.string().notRequired().nullable(),
                otherwise: Yup.string().trim().test(
                        "liAmount",
                        "Min value should be 1 or more",
                        function(item) {
                            const liAmountValue = Number(unmaskCurrency(this.parent.liAmount));
                            return (liAmountValue > 0);
                        },
                ).required("Required Field").nullable(),
            });
        } else if (field === "inforceLifeDesc") {
            keysValidation[field] = Yup.string().when("isInforceLife", {
                is: "No",
                then: Yup.string().notRequired(),
                otherwise: Yup.string().trim().required("Required Field"),
            });
        } else if (field === "businessInsurance") {
            keysValidation[field] = Yup.string().when("insuranceType", {
                is: "personalInsurance",
                then: Yup.string().notRequired(),
                otherwise: Yup.string().trim().required("Required Field"),
            }).nullable();
        } else if (field === "personalInsurance") {
            keysValidation[field] = Yup.string().when("insuranceType", {
                is: "businessInsurance",
                then: Yup.string().notRequired(),
                otherwise: Yup.string().trim().required("Required Field"),
            }).nullable();
        } else if (field === "Remark-101") {
            keysValidation[field] = Yup.string().ensure().when("Proposed_Insured_Questions-3", {
                is: "Yes",
                then: Yup.string().required("Required Field"),
            });
        } else if (field === "Remark-102") {
            keysValidation[field] = Yup.string().ensure().when("Proposed_Insured_Questions-4", {
                is: "Yes",
                then: Yup.string().required("Required Field"),
            });
        } else if (field === "Remark-10-Remarks") {
            keysValidation[field] = Yup.string().ensure().when("Proposed_Insured_Questions-10", {
                is: "Yes",
                then: Yup.string().required("Required Field"),
            });
        } else if (field === "Remark-110") {
            keysValidation[field] = Yup.string().ensure().when("Proposed_Insured_Questions-12", {
                is: "Yes",
                then: Yup.string().required("Required Field"),
            });
        } else if (field === "Insured_ParticipationDetails") {
            keysValidation[field] = Yup.string().ensure().when("Insured_Other_Hazardous_Sports_or_Avocations", {
                is: true,
                then: Yup.string().required("Required Field"),
            });
        }
        else if(field === "Proposed_Insured_Questions-30" || field === "Proposed_Insured_Questions-3" || field === "Proposed_Insured_Questions-4" || field === "Proposed_Insured_Questions-5" ||
                field === "Proposed_Insured_Questions-10" || field === "Proposed_Insured_Questions-12" || field === "Proposed_Insured_Questions-20" || field === "Proposed_Insured_Questions-21"
                || field === "inforcePolicy" || field === "isInforceLife" || field === "existPolicies"
        ){
            keysValidation[field] = Yup.string().required("Required Field").nullable();
        } else {
            keysValidation[field] = Yup.string().required("Required Field");
        }
    });

    const yesNoArr = [{
        label: "Yes",
        value: "Yes",
    }, {
        label: "No",
        value: "No",
    }];

    const typeAnsArr = [{
        label: "Life",
        value: "Life",
    }, {
        label: "Annuity",
        value: "Annuity",
    }];

    const statusAnsArr = [{
        label: "In Force",
        value: "In Force",
    }, {
        label: "Applied For",
        value: "Applied For",
    }];

    const validationSchema = Yup.object().shape(keysValidation);

    const policiesObj = {
        label: <Label label={"Number of Policies"} required={!isFromClientDetail}/>,
        isRequired: !isFromClientDetail,
        filedType: "selectbox",
        helperFieldText: "How many policies do you have with us?",
        component: SelectField,
        className: "SelectFieldWidth",
        variant: "outlined",
        options: getNumberOfPolicies(),
        disabled: clientSigned ? true : false,
        onSelectChange: (val: number, setFieldValue: Function, values: any) => {
        },
        getValuesOnChange: (val: number, setFieldValue: Function, values: any) => {
            let inForcePolicies: any = [];
            for (let i = 1; i <= Number(val); i++) {
                inForcePolicies.push({
                    nameOfCompany: (values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].nameOfCompany) || "",
                    policyNumber: (values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].policyNumber) || "",
                    dateIssuedOrAppliedFor: (values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].dateIssuedOrAppliedFor) || moment().format("YYYY"),
                    coverageFaceAmount: (values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].coverageFaceAmount) || "",
                    adbCoverage: (values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].adbCoverage) || "",
                    type: (values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].type) || "",
                    willThisBeReplaced: (values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].willThisBeReplaced) || null,
                    willBothPoliciesBeTaken: (values["inForcePolicies"].length > 0 && values["inForcePolicies"][i - 1] && values["inForcePolicies"][i - 1].willBothPoliciesBeTaken) || "",
                });
            }
            setFieldValue("inForcePolicies", inForcePolicies);
            enableSaveButton();
        },
    };

    const secQuesTextField = {
        id: "insuranceDescription",
        name: "insuranceDescription",
        helperFieldText: "",
        label: <Label label={"Please Explain"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    };

    const mortgageTextField = {
        id: "mortgageAmount",
        name: "mortgageAmount",
        label: <Label label={`Mortgage Amount`} required={!isFromClientDetail}/>,
        isRequired: !isFromClientDetail,
        helperFieldText: "",
        component: NumberTextField,
        variant: "outlined",
        className: "TextFieldWidth dollerIcon",
        dollarSign: true,
        InputProps: {
            startAdornment: <InputAdornment className="margin0 dollerIcon"
                                            position="start">$</InputAdornment>,
        },
    };

    const personalRadio = [
        {
            label: "Income Replacement",
            value: "Income Replacement",
        }, {
            label: "Final Expenses",
            value: "Final Expenses",
        }, {
            label: "Wealth-gift Transfer",
            value: "Wealth-gift Transfer",
        }, {
            label: "Retirement Planning",
            value: "Retirement Planning",
        }, {
            label: "Estate Conservation",
            value: "Estate Conservation",
        },
        // {
        //     label: "Mortgage Protection",
        //     value: "Mortgage Protection",
        // },
        {
            label: "College Funding",
            value: "College Funding",
        }, {
            label: "Other",
            value: "Other",
        }];

    const businessInsurance = [
        {
            label: "Deferred Compensation",
            value: "Deferred Compensation",
        }, {
            label: "Buy / Sell",
            value: "Buy / Sell",
        }, {
            label: "Key Person",
            value: "Key Person",
        }, {
            label: "Business Continuation",
            value: "Business Continuation",
        }, {
            label: "Split Dollar",
            value: "Split Dollar",
        }, {
            label: "Executive Bonus",
            value: "Executive Bonus",
        }];

    const textFieldCommon = {
        helperFieldText: "",
        label: <Label label={"Please Explain"} required={!isFromClientDetail}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    };

    const numberTextFieldCommon = {
        isRequired: !isFromClientDetail,
        helperFieldText: "",
        component: NumberTextField,
        variant: "outlined",
        className: "TextFieldWidth dollerIcon",
        dollarSign: true,
        InputProps: {
            startAdornment: <InputAdornment className="margin0 dollerIcon"
                                            position="start">$</InputAdornment>,
        },
    };

    const getLast30Years = () => {
        const currentYear = new Date().getFullYear();

        const years: any = [];
        for (let index = 0; index < 30; index++) {
            const year = currentYear - index;

            years.push({
                id: year,
                key: year,
                name: year,
            });
        }

        return years;
    };

    const policy1Obj = [
        {
            fixName: "nameOfCompany",
            label: <Label label={"Name of company"} required={!isFromClientDetail}/>,
            isRequired: !isFromClientDetail,
            disabled: clientSigned ? true : false,
            filedType: "string",
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            validate: (value: any) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error: string = "";
                if (!value) {
                    return `Required`;
                }
                return error;
            },
        }, {
            fixName: "policyNumber",
            label: <Label label={"Policy Number"}/>,
            isRequired: false,
            disabled: !!clientSigned,
            filedType: "string",
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        }, {
            fixName: "dateIssuedOrAppliedFor",
            component: SelectField,
            className: "TextFieldWidth",
            variant: "outlined",
            isRequired: !isFromClientDetail,
            label: <Label label={"Year Issued or Applied For"} required={!isFromClientDetail}/>,
            options: getLast30Years(),
            disabled: !!clientSigned,
            onSelectChange: (val: number) => {

            },
        }, {
            fixName: "coverageFaceAmount",
            label: <Label label={"Coverage / Face Amount"} required={!isFromClientDetail}/>,
            isRequired: !isFromClientDetail,
            disabled: !!clientSigned,
            filedType: "string",
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            validate: (value: any) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error: string = "";
                if (!value) {
                    return `Required`;
                }
                return error;
            },
        }, {
            fixName: "adbCoverage",
            label: <Label label={"ADB Coverage"}/>,
            isRequired: !isFromClientDetail,
            filedType: "string",
            disabled: !!clientSigned,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            validate: (value: any) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error: string = "";

                return error;
            },
        }];

    const radioChoice = [
        {
            label: "Type",
            choice: typeAnsArr,
            fixName: "type",
            validate: (value: any) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error: string = "";
                if (!value) {
                    return `Required`;
                }
                return error;
            },
        }, {
            label: "Will This be Replaced?",
            choice: yesNoArr,
            fixName: "willThisBeReplaced",
            validate: (value: any) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error: string = "";
                if (!value) {
                    return `Required`;
                }
                return error;
            },
        }, {
            label: "Will both policies be taken?",
            choice: yesNoArr,
            fixName: "willBothPoliciesBeTaken",
            validate: (value: any) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error: string = "";
                if (!value) {
                    return `Required`;
                }
                return error;
            },
        }, {
            label: "Status",
            choice: statusAnsArr,
            fixName: "status",
            validate: (value: any) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error: string = "";
                if (!value) {
                    return `Required`;
                }
                return error;
            },
        }];


    const showComponent = (question: EnrollmentFinancialAgreementProps, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors) => {
        switch (question.additionalFieldType) {
            case "selectBox":
                if (values[`${question.additionalFieldName}`] && errors[`${question.additionalFieldName}`]) {
                    delete errors[`${question.additionalFieldName}`];
                    setErrors({ ...errors });
                }
                return <>
                    {
                            question && values[question.name] === "Yes" &&
                            <Grid item xs={8} md={4}>
                                <TextFieldCommon
                                        {...policiesObj}
                                        name={question.additionalFieldName || ""}
                                        values={values}
                                        onChange={(e) => {
                                            handleChange(e);
                                            enableSaveButton();
                                        }}
                                        onBlur={handleBlur}
                                        helperText={question.additionalHelperText ? question.additionalHelperText : ""}
                                />
                                <ErrorCommon errors={errors} name={question.additionalFieldName}
                                             touched={touched}/></Grid>
                    }
                </>;
                break;
            case "multi-radio":
                return (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InsuranceType setInsuranceTypeVal={setInsuranceTypeVal} handleChange={handleChange} setFieldValue={setFieldValue}
                                               clientDetail={clientDetail} isGWTGen2={isGWTGen2} values={values} insuranceTypeVal={insuranceTypeVal} errors={errors} touched={touched} clientSigned={clientSigned} />
                            </Grid>
                            {insuranceTypeVal === "personalInsurance" &&
                                    <Grid item xs={12} lg={6}>
                                        <FormControl component="fieldset" error={false}
                                                     className={classes.formControl + " mb20 "}>
                                            <Typography ref={insureRef} id={"insureRef"} variant="caption"
                                                        className="textBlueDark mt30 mb20"
                                                        component="strong"><Label
                                                    label={"Personal Insurance"}
                                                    required={!isFromClientDetail}/></Typography>
                                            <PersonalInsurance setFieldValue={setFieldValue}
                                                               personalInsuranceType={personalInsuranceType}
                                                               values={values} enableSaveButton={enableSaveButton}
                                                               setInsuranceError={setInsuranceError}
                                                               personalRadio={personalRadio} clientSigned={clientSigned} isGWTGen2={isGWTGen2}
                                                               touched={touched} errors={errors}/>
                                        </FormControl>

                                        {/*<TextFieldCommon*/}
                                        {/*        {...mortgageTextField}*/}
                                        {/*        values={values}*/}
                                        {/*        onChange={handleChange}*/}
                                        {/*        onBlur={handleBlur}*/}
                                        {/*/>*/}
                                        {/*<ErrorCommon errors={errors} name={mortgageTextField.name}*/}
                                        {/*             touched={touched}/>*/}
                                        {insuranceError &&
                                                <p style={{ color: "#FF1744", fontSize: "12px" }}> Personal Insurance or
                                                    Business Insurance from both one is required </p>
                                        }
                                    </Grid>
                            }

                            {(offerType === "gi" || offerType === "si") && isAssociation ?
                                    <React.Fragment></React.Fragment>
                                    :
                                    <React.Fragment>
                                        {insuranceTypeVal === "businessInsurance" &&
                                                <Grid item xs={12} lg={6}>
                                                    <FormControl component="fieldset" error={false}
                                                                 className={classes.formControl}>
                                                        <Typography variant="caption" className="textBlueDark mt30 mb20"
                                                                    component="strong">
                                                            <Label
                                                                    label={"Business Insurance"}
                                                                    required={!isFromClientDetail}/></Typography>
                                                        <BusinessInsurance values={values} setFieldValue={setFieldValue}
                                                                           enableSaveButton={enableSaveButton}
                                                                           setInsuranceError={setInsuranceError}
                                                                           businessInsurance={businessInsurance}
                                                                           clientSigned={clientSigned}
                                                                           touched={touched} errors={errors}/>
                                                    </FormControl>
                                                </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextFieldCommon
                                                    {...secQuesTextField}
                                                    values={values}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        enableSaveButton();
                                                    }}
                                                    onBlur={handleBlur}
                                            />
                                            <ErrorCommon errors={errors} name={secQuesTextField.name}
                                                         touched={touched}/>
                                        </Grid>
                                    </React.Fragment>
                            }

                        </Grid>
                );
                break;
            case "textField":
                return (
                        <>
                            {
                                    question && values[question.name] === "Yes" &&
                                    <Grid item
                                          xs={question.additionalFieldName === "Insured_question-7-text" || question.additionalFieldName === "inforceLifeDesc" ? 12 : 9}>
                                        <TextFieldCommon
                                                {...textFieldCommon}
                                                id={`${question.additionalFieldName}`}
                                                name={`${question.additionalFieldName}`}
                                                values={values}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    enableSaveButton();
                                                }}
                                                onBlur={handleBlur}
                                        />
                                        <ErrorCommon errors={errors} name={question.additionalFieldName}
                                                     touched={touched}/>
                                        {
                                                question.additionalFieldName === "Insured_question-7-text" &&
                                                <Typography className="f12 mt10">
                                                    Note: You can’t do a 1035 exchange. If you are using money from
                                                    another
                                                    policy you
                                                    would have to surrender the policy first.
                                                </Typography>
                                        }
                                    </Grid>
                            }
                        </>
                );
                break;
            case "numberTextField":
                let disabled: any = {};
                if (question.additionalFieldName === "inforceAmount") {
                    disabled = {
                        disabled: values["inforceAmountNone"] || clientSigned ? true : false,
                    };
                }
                if (question.additionalFieldName === "liAmount") {
                    disabled = {
                        disabled: values["liAmountNone"] || clientSigned ? true : false,
                    };
                }
                return (
                        <>
                            <Grid item xs={4}>
                                <TextFieldCommon
                                        {...numberTextFieldCommon}
                                        {...disabled}
                                        id={`${question.additionalFieldName}`}
                                        name={`${question.additionalFieldName}`}
                                        label={<Label label={"Enter Amount"} required={!isFromClientDetail}/>}
                                        values={values}
                                        onChange={(e) => {
                                            handleChange(e);
                                            enableSaveButton();
                                        }}
                                        onBlur={handleBlur}
                                />
                                <ErrorCommon errors={errors} name={question.additionalFieldName}
                                             touched={touched}/>
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{ display: "flex" }} className="mt15 ml35">
                                    <Checkbox style={{ height: "28px", marginRight: "10px" }}
                                              disabled={clientSigned ? true : false}
                                              className="floatLeft pd0 mr10" color={"primary"}
                                              value={values[`${question.additionalCheckBoxName}`]}
                                              name={`${question.additionalCheckBoxName}`}
                                              checked={values[`${question.additionalCheckBoxName}`]}
                                              onChange={(e, v) => {
                                                  setFieldValue(`${question.additionalFieldName}`, "");
                                                  setFieldValue(`${question.additionalCheckBoxName}`, e.target.checked);
                                                  enableSaveButton();
                                              }}
                                              inputProps={{ "aria-label": "uncontrolled-checkbox" }}/>
                                    <Typography variant="caption" style={{ marginTop: 2 }}>
                                        <span> None </span>
                                    </Typography>
                                </div>
                            </Grid>
                        </>
                );
            default:
                break;
        }
    };

    useEffect(() => {
        if (buttonRef.current) {
            setQuestionButtonRef(buttonRef);
        }
    }, [buttonRef.current]);

    const enableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "block";
        }
    };

    const disableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "none";
        }
    };

    const getPolicyGrids = (values, handleChange, handleBlur, errors, touched, setFieldValue) => {
        let noOfInforcePolicies: number[] = [];
        for (let i = 1; i <= Number(values["noOfInforcePolicies"]); i++) {
            noOfInforcePolicies.push(i);
        }
        return noOfInforcePolicies.map((val, inforceIndex) => {
            return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="body1"
                                            className="mb0 floatLeft" component="strong">Policy
                                    {`  ${inforceIndex + 1}`}</Typography>
                            </Grid>
                            {
                                policy1Obj.map((item, index) => {
                                    let fieldProps: any = {
                                        id: `inForcePolicies[${inforceIndex}].${item.fixName}`,
                                        name: `inForcePolicies[${inforceIndex}].${item.fixName}`,
                                        values: values,
                                    };
                                    if (values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex][item.fixName]) {
                                        if (item.filedType !== "date") {
                                            fieldProps = {
                                                ...fieldProps,
                                                value: (values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex][item.fixName] + "") || "",
                                            };
                                        } else {
                                            fieldProps = {
                                                ...fieldProps,
                                                value: (values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && moment(values.inForcePolicies[inforceIndex][item.fixName]).format("MM-DD-YYYY") + "") || moment().format("MM-DD-YYYY"),
                                            };

                                        }
                                    }
                                    if (item.filedType === "date" && !values.inForcePolicies[inforceIndex]) {
                                        setFieldValue(`inForcePolicies[${inforceIndex}][${item.fixName}]`, moment().format("MM-DD-YYYY"));
                                    }
                                    return (
                                            <Grid item xs={12} md={6} xl={3}>
                                                <TextFieldCommon
                                                        {...item}
                                                        {...fieldProps}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            enableSaveButton();
                                                        }}
                                                />
                                                {errors.inForcePolicies && errors.inForcePolicies[inforceIndex] && errors.inForcePolicies[inforceIndex][item.fixName] && (
                                                        <ErrorCommon isMultiLevel={true}
                                                                     errors={errors.inForcePolicies[inforceIndex][item.fixName]}
                                                                     name={`inForcePolicies[${inforceIndex}].${item.fixName}`}/>
                                                )}
                                            </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <Grid container spacing={1} className="mt30">
                            {
                                radioChoice.map((item, index) => {
                                            return (<Grid item xs={12} md={6} xl={4}>
                                                <FormControl component="fieldset"
                                                             disabled={clientSigned ? true : false}
                                                             className={classes.formControl}>
                                                    <Typography component="strong"
                                                                variant="body1">{item.label}</Typography>
                                                    <RadioGroup aria-label="quiz"
                                                                id={`inForcePolicies[${inforceIndex}].${item.fixName}`}
                                                                name={`inForcePolicies[${inforceIndex}].${item.fixName}`}
                                                                value={values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex][item.fixName] ? values.inForcePolicies[inforceIndex][item.fixName] : item.choice[0].value}
                                                            //onChange={(e) => setFieldValue(`${item.fixName}_${inforceIndex + 1}`, e.target.value)}>
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    enableSaveButton();
                                                                }}
                                                    >
                                                        <Grid container>
                                                            {
                                                                item.choice.map((element, index) => {
                                                                    return <Grid item xs={12}
                                                                                 md={6}><FormControlLabel
                                                                            className="RadioFontSize"
                                                                            value={`${element.value}`}
                                                                            control={<Radio
                                                                                    color={"primary"}
                                                                                    className="padding0"/>}
                                                                            label={`${element.label}`}/></Grid>;
                                                                })
                                                            }
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                                {errors.inForcePolicies && errors.inForcePolicies[inforceIndex] && errors.inForcePolicies[inforceIndex][item.fixName] && (
                                                        <ErrorCommon isMultiLevel={true}
                                                                     errors={errors.inForcePolicies[inforceIndex][item.fixName]}
                                                                     name={`inForcePolicies[${inforceIndex}].${item.fixName}`}/>
                                                )}
                                            </Grid>);
                                        },
                                )
                            }
                        </Grid>
                    </>
            );
        });
    };

    const insuredQuestionPayload = (values: any) => {
        console.log("Payload Called====>");
        let inForcePolPayload = values.inForcePolicies && values.inForcePolicies.length > 0 ? values.inForcePolicies.map((item: any) => {
            return {
                ...item,
                status: item.status ? item.status : "In Force",
                type: item.type ? item.type : "Life",
                willBothPoliciesBeTaken: item.willBothPoliciesBeTaken ? item.willBothPoliciesBeTaken : "Yes",
                willThisBeReplaced: item.willThisBeReplaced ? item.willThisBeReplaced : null,
                dateIssuedOrAppliedFor: item.dateIssuedOrAppliedFor ? item.dateIssuedOrAppliedFor + "" : undefined,
                // yearsIssuedOrAppliedFor: item.yearsIssuedOrAppliedFor,
                coverageFaceAmount: Number(unmaskCurrency(item.coverageFaceAmount)),
                nameOfCompany: item.nameOfCompany ? item.nameOfCompany + "" : "",
                policyNumber: item.policyNumber ? item.policyNumber + "" : "",
                adbCoverage: item.adbCoverage ? item.adbCoverage + "" : "",
            };
        }) : [];
        let payload: any = {
            insuredAgreed: pageChecked ? 1 : 0,
            noOfPolicies: values.noOfPolicies || 0,
            existPolicies: values.existPolicies === "Yes" ? 1 : values.existPolicies === "No" ? 0 : null,
            insuranceType: values.insuranceType || "personalInsurance",
            personalInsurance: values.personalInsurance || null,
            businessInsurance: values.businessInsurance || null,
            mortgageAmount: values.mortgageAmount ? Number(unmaskCurrency(values.mortgageAmount)) : null,
            insuranceDescription: values.insuranceDescription,
            inforcePolicy: values.inforcePolicy === "Yes" ? 1 : values.inforcePolicy === "No" ? 0 : null,
            noOfInforcePolicies: values.noOfInforcePolicies || 0,
            inforceAmount: values.inforceAmount ? Number(unmaskCurrency(values.inforceAmount)) : null,
            liAmount: values.liAmount ? Number(unmaskCurrency(values.liAmount+'')) : null,
            liAmountNone: values.liAmountNone ? 1 : 0,
            inforceLifeDesc: values.inforceLifeDesc ? values.inforceLifeDesc : null,
            isInforceLife: values.isInforceLife === "Yes" ? 1 : values.isInforceLife === "No" ? 0 : null,
            inForcePolicies: offerType !== "gi" && values.inforcePolicy === "Yes" && values.noOfInforcePolicies ? inForcePolPayload : [],
        };

        if (isIliaApp(offerType)) {
            let insuredQuestionObj = {};

            iliaAppInsuredQuestionsNameArr.forEach((iteam: string) => { // set values from enrollment answers JSON
                insuredQuestionObj[`${iteam}`] = !!values[`${iteam}`] ? values[`${iteam}`] : null;
            });
           /* participationArr.forEach((iteam) => { // set values from enrollment answers JSON
                insuredQuestionObj[`${iteam}`] = !!values[`${iteam}`] ? values[`${iteam}`] : null;
            });*/

            payload.insuredQuestions = insuredQuestionObj;
        }
        return payload;
    };

    const saveMe = async (values: any) => {
        let payload: any = {};
        payload = insuredQuestionPayload(values);
        await updateClientFields({
            ...payload,
            formType: "INSURED_QUESTIONS",
        }, (user && user.client && user.client.id) || "", isFromClientDetail);
        disableSaveButton();
        if (typeof setActionItem === "function" && isFromClientDetail) {
            setActionItem();
        }
    };

    return (
            <>
                {openInforceLifeAlertPopup &&
                        <InforceLifeAlertPopup open={openInforceLifeAlertPopup}
                                               setOpen={(open) => setOpenInforceLifeAlertPopup(open)}/>
                }
                {!isFromClientDetail &&
                        <Grid container spacing={2}>
                            {/*START GI CONDITION*/}
                            {offerType !== "gi" &&
                                    <AttestationOfTruth
                                            checked={(user && user.client && user.client.agreeOnInsuredQuestion) ? true : false}
                                            setPageChecked={setPageChecked}
                                            setAttestRef={setAttestRef}
                                            name={"INSURED_QUESTIONS"}
                                            userData={user}
                                    />
                            }
                        </Grid>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <InsuredQuestionsForm
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                pageChecked={pageChecked}
                                isFromClientDetail={isFromClientDetail}
                                offerType={offerType}
                                insureRef={insureRef}
                                setInsuranceError={setInsuranceError}
                                setIsSubmittedQuestions={setIsSubmittedQuestions}
                                updateClientFields={updateClientFields}
                                enableSaveButton={enableSaveButton}
                                showComponent={showComponent}
                                getPolicyGrids={getPolicyGrids}
                                buttonRef={buttonRef} user={user} setActionItem={setActionItem}
                                setNextStep={setNextStep} disableSaveButton={disableSaveButton}
                                isAssociation={isAssociation}
                                clientSigned={clientSigned} isCorporation={isCorporation}
                                nationalLifeId={nationalLifeId}
                                setOpenInforceLifeAlertPopup={setOpenInforceLifeAlertPopup}
                                insuredQuestions={insuredQuestions}
                                insuredQuestionPayload={insuredQuestionPayload}
                                setSaveApplicationQuestions={setSaveApplicationQuestions}
                                saveMe={saveMe}
                        />
                    </Grid>
                </Grid>
            </>
    );
};

export default React.memo(InsuredQuestionsContent);