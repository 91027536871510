import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import React from "react";
import moment from "moment";
import Typography from "@material-ui/core/Typography/Typography";
import CardContent from "@material-ui/core/CardContent/CardContent";
import { Grid, IconButton, List, ListItem, Tooltip } from "@material-ui/core";
import { usePolicyContext } from "../../../../../components/clientServicing/Payments/PolicyContext";
import NumberFormat from "react-number-format";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles(theme => ({
    remainingPayments: {
        maxHeight: 200,
        overflow: "auto",
    },
}));

function TotalRemainingPayments(props: { paymentFrequency: string }) {
    const classes = useStyles();
    const { paymentFrequency } = props;
    const { policy } = usePolicyContext();
    let paymentTimeTable = policy && policy.paymentTimetableAnnual || {};
    if (paymentFrequency === "monthly") {
        paymentTimeTable = policy && policy.paymentTimetableMonthly || {};
    }
    const paymentFrequencyType: { monthly: string; annual: string } = {
        "monthly": "Monthly",
        "annual": "Yearly"
    }

    const recurringPayments = paymentTimeTable.recurringPayments || [];

    return (
            <>
                <Card className="enrollmentHelpCard boxShadow w100 mt20">
                    <CardHeader className="headerWrap pt15 pb0"
                                title={<React.Fragment>
                                    <Typography
                                            component="span"
                                            variant="h5"
                                    >
                                        <span className="floatLeft f20">Recurring {paymentFrequencyType[paymentFrequency]} Payments</span>
                                        {paymentFrequency === "monthly" && <Tooltip
                                            title="Your recurring monthly payments will be automatically debited from
                                            your account on the 1st of each month as shown below.">
                                        <IconButton>
                                            <InfoIcon color="primary" className="ml15 floatLeft"/>
                                        </IconButton>
                                    </Tooltip>}
                                    </Typography>
                                </React.Fragment>}
                    />
                    <CardContent>
                        <Grid container justify="center">
                            <Grid item xs={12} md={12} lg={12} xl={12} className="textLeft">
                                <div className={classes.remainingPayments}>
                                    {recurringPayments.map((payment) => {
                                        const amount = payment.amount[0].amount > 0 ? payment.amount[0].amount + payment.amount[1].amount + payment.amount[2].amount : 0;
                                        return (<Typography variant="caption" className="mb10 floatLeft w100">
                                            {moment(payment.date).format("MM/DD/YYYY") + "     "}
                                            <span className="floatRight mr10">
                                        <NumberFormat
                                                value={amount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"} />
                                    </span>
                                        </Typography>);
                                    })}
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </>
    );
}

export default TotalRemainingPayments;