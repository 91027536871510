import React from "react";
import {Grid, Typography} from "@material-ui/core";
import RenderDistribution from "./RenderDistribution";

interface StepThreeProps {
    issueAge: number,
    callingFrom?: string
}

export function setOptions(startFrom: number = 65) {
    let itemArr: any[] = [];
    for (let i = startFrom; i <= 100; i++) {
        let obj: {value, name, key} = {
            value: i,
            name: i,
            key: i,
        };
        itemArr.push(obj);
    }
    return itemArr;
}

export default function StepThreeTwo(props: StepThreeProps) {
    const { issueAge, callingFrom } = props;
    let distributionsLabel = "What ages would you like to receive distributions?";
    switch (callingFrom) {
        case 'CUSTOM_DESIGN':
            distributionsLabel = "Set distribution ages";
            break;
    }

    return (
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <Typography className="mt20 mb15 floatLeft w100" variant="body1" component="strong">{distributionsLabel}</Typography>
                </Grid>
                <RenderDistribution issueAge={issueAge} showTitle={false} editMode={false}/>
            </Grid>
    );
}
