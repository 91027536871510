import React from 'react';
import {DialogContent} from '@material-ui/core';

type DialogContentProps = { children: React.ReactNode | any; dividers: boolean | undefined; };

const Content: React.FC<DialogContentProps> = ({children, dividers = true}) => {
    return (
            <DialogContent dividers={dividers}>
                {children}
            </DialogContent>
    );
};

export default Content;
