import React from "react";
import { useFormikContext } from "formik";
import { Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import { ErrorCommon, TextField, TextFieldCommon } from "../../../../../../../components/formikFormInputs";
import InsuredPageRadioButton from "../component/InsuredPageRadioButton";
import CCL from "../component/CommonCustomLabel";
import { ExerciseEnum, FormControlChkBox, PIQ } from "../InsuredQuestions";

const fre: any = [
    {
        text: "1-2 days per week",
        value: "1-2 days per week",
    },
    {
        text: "3-4 days per week",
        value: "3-4 days per week",
    },
    {
        text: "5 or more days per week",
        value: "5 or more days per week",
    },
];

const ds: any = [
    {
        text: "Less than 20 minutes",
        value: "Less than 20 minutes",
    },
    {
        text: "20-30 minutes",
        value: "20-30 minutes",
    },
    {
        text: "30-45 minutes",
        value: "30-45 minutes",
    },
    {
        text: "45 minutes or more",
        value: "45 minutes or more",
    },
];

type YPType = { dLabel: string; fName: string; fLabel: string; sName: string; dName: string; type: ExerciseEnum; }

const fieldName82X = `${PIQ}-82X`;
const exRoutineFields: YPType[] = [
    {
        dLabel: "Which of the following have you experienced? (Check ALL that apply)",
        dName: `${fieldName82X}Ryps`,
        fLabel: "Yoga, Pilates, or Other Flexibility with Strength Exercise",
        fName: `${fieldName82X}Rypf`,
        sName: `${fieldName82X}Typsa`,
        type: ExerciseEnum.YOGA,
    },
    {
        dLabel: "Which of the following have you experienced? (Check ALL that apply)",
        dName: `${fieldName82X}Rlis`,
        fLabel: "Low Intensity Cardio/Aerobic Activity/Walking (less than 60% of maximum heart rate)",
        fName: `${fieldName82X}Rlif`,
        sName: `${fieldName82X}Tlisa`,
        type: ExerciseEnum.LIC,
    },
    {
        dLabel: "Which of the following have you experienced? (Check ALL that apply)",
        dName: `${fieldName82X}Rmis`,
        fLabel: "Medium Intensity Cardio/Aerobic Activity/Jogging (60-80% of maximum heart rate)",
        fName: `${fieldName82X}Rmif`,
        sName: `${fieldName82X}Tmia`,
        type: ExerciseEnum.MIC,
    },
    {
        dLabel: "Which of the following have you experienced? (Check ALL that apply)",
        dName: `${fieldName82X}Rhis`,
        fLabel: "High Intensity Cardio/Aerobic Activity/Running (greater than 80% of maximum heart rate)",
        fName: `${fieldName82X}Rhif`,
        sName: `${fieldName82X}Thia`,
        type: ExerciseEnum.HIC,
    },
    {
        dLabel: "Which of the following have you experienced? (Check ALL that apply)",
        dName: `${fieldName82X}Rwls`,
        fLabel: "Weight Lifting or Weight Training Exercise",
        fName: `${fieldName82X}Rwlf`,
        sName: `${fieldName82X}Twla`,
        type: ExerciseEnum.WL,
    },
];



function SpecificActivity(props: { name: string, clientSigned:boolean }) {
    const { name, clientSigned } = props;
    const { values, handleChange, handleBlur, errors, touched }: any = useFormikContext();

    const disableProperty:any = {
        disabled: clientSigned
    }

    return (
            <>
                <TextFieldCommon
                        name={name}
                        id={name}
                        placeholder={"Specify activity"}
                        component={TextField}
                        values={values}
                        label={<Label label={"Specify activity"} required={true} />}
                        onChange={(e: any) => handleChange(e)}
                        onBlur={handleBlur}
                        {...disableProperty}
                />
                <ErrorCommon errors={errors} name={name} touched={touched} />
            </>
    );
}

const ExerciseRoutine = (p: YPType & { clientSigned: boolean }) => {
    const { values } = useFormikContext();
    const { clientSigned, dLabel, fName, fLabel, sName, dName, type } = p;
    const [controlName, durationQues, question] = [
        `${fieldName82X}C_${type}`,
        {
            additionalFieldOptions: ds,
            additionalFieldType: "radioBoxGroup",
            label: <Label label={dLabel} required={true} />,
            name: dName,
            notShowHrLine: false,
        },
        {
            additionalFieldOptions: fre,
            additionalFieldType: "radioBoxGroup",
            label: <Label label={fLabel} required={true} />,
            name: fName,
            notShowHrLine: false,
        },
    ];

    return (
            <Grid container spacing={3} className={"mt20 mb10"}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="subtitle2" component="strong" className="mt20 floatLeft w100 mb0">
                        <FormControlChkBox
                                controlClassName={"mr10"}
                                controlName={controlName}
                                disabled={clientSigned}
                                fieldControlLabelClassName={"formikRadio"}
                                fieldControlLabel={""}
                        />
                        {fLabel}
                        <span className="requiredHighlight">*</span>
                    </Typography>
                </Grid>

                {
                        values && values[controlName] &&
                        <>
                            <Grid item xs={12} lg={6} className="mb10">
                                <CCL name="Frequency" require={true} />
                                <InsuredPageRadioButton question={question} clientSigned={clientSigned} />
                            </Grid>

                            <Grid item xs={12} lg={6} className="mb10">
                                <CCL name="Average duration per session" require={true} />
                                <InsuredPageRadioButton question={durationQues} clientSigned={clientSigned} />
                            </Grid>

                            <Grid item xs={12}>
                                <SpecificActivity name={sName} clientSigned={clientSigned}/>
                            </Grid>
                        </>
                }
            </Grid>
    );
};
const ProposedInsuredQuestion83 = (props: { clientSigned: boolean }) => {
    const { clientSigned } = props;
    const { values }: any = useFormikContext();
    const RoutineLabel: React.ReactNode = (
            <Typography variant="body2">I do not follow a regular exercise routine</Typography>
    );
    return (
            <>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControlChkBox
                            controlClassName="mr10"
                            controlName={`${PIQ}-83XCee`}
                            disabled={clientSigned}
                            fieldControlLabelClassName="formikRadio ml5"
                            fieldControlLabel={RoutineLabel}
                    />
                </Grid>
                {
                        (values && values[`${PIQ}-83XCee`] === false) &&
                        <React.Fragment>
                            {
                                exRoutineFields.map((ypField, index) => (
                                        <ExerciseRoutine {...ypField} clientSigned={clientSigned} key={index} />
                                ))
                            }
                        </React.Fragment>
                }
            </>
    );
};

export default ProposedInsuredQuestion83;