import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import theme from "./theme";

const kaiZenTheme = createMuiTheme({


    palette: {
        primary: {
            light: "#3671ED",
            main: "#3671ED",
            dark: "#01305F",
            contrastText: "#fff",
        },
        secondary: {
            light: "#FFFFFF",
            main: "#FFFFFF",
            dark: "#FFFFFF",
            contrastText: "#000000",
        },

    },

    // shape: {
    //     borderRadius: 50,
    // },
    typography: {
        h1: {
            fontFamily: "'Playfair Display', serif",
            marginBottom: "1.3rem",
            [theme.breakpoints.up("xs")]: {
                fontSize: "34px",
                fontWeight: "900",
                lineHeight: "42px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "42px ",
                fontWeight: "900 ",
                lineHeight: "63px ",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "60px",
                fontWeight: "900 ",
                lineHeight: "80px",
                letterSpacing: "0 ",
            },

        },
        h2: {
            fontFamily: "'Playfair Display', serif ",
            marginBottom: "1.3rem ",
            color: "#424242",
            [theme.breakpoints.up("xs")]: {
                fontSize: "30px ",
                fontWeight: "900 ",
                letterSpacing: "-0.25px ",
                lineHeight: "45px ",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "42px ",
                fontWeight: "900 ",
                letterSpacing: "0 ",
                lineHeight: "63px ",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "48px",
                letterSpacing: "-0.4px",
                lineHeight: "62px",
            },
        },
        h3: {
            fontFamily: "'Playfair Display', serif ",
            marginBottom: "1.3rem ",
            [theme.breakpoints.up("xs")]: {
                fontSize: "26px ",
                fontWeight: "900",
                lineHeight: "39px ",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "42px",
                fontWeight: "900 ",
                lineHeight: "55px",
            },
        },
        h4: {
            fontFamily: "'Roboto', sans-serif ",
            marginBottom: "1.3rem ",
            [theme.breakpoints.up("xs")]: {
                fontSize: "24px ",
                lineHeight: "36px ",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "34px ",
                letterSpacing: "0.07px ",
                lineHeight: "39px ",
            },
        },
        h5: {
            [theme.breakpoints.up("xs")]: {
                fontSize: "22px",
                fontFamily: "'Roboto', sans-serif",
                letterSpacing: "0",
                lineHeight: "33px",
                marginBottom: "1.3rem",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "22px",
                fontFamily: "'Roboto', sans-serif",
                letterSpacing: "0",
                lineHeight: "33px",
                marginBottom: "1.3rem",
            },
        },
        h6: {
            fontFamily: "'Roboto', sans-serif",
            [theme.breakpoints.up("xs")]: {
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: "500",
                letterSpacing: "0.07px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "20px ",
                fontWeight: "500 ",
                letterSpacing: "0.07px ",
                lineHeight: "32px ",
            },
        },
        body1: {
            fontFamily: "'Roboto', sans-serif ",
            marginBottom: "1.3rem ",
            [theme.breakpoints.up("xs")]: {
                fontSize: "16px",
                lineHeight: "24px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "18px",
                lineHeight: "27px",
            },
        },
        body2: {
            fontFamily: "'Roboto', sans-serif ",
            [theme.breakpoints.up("xs")]: {
                fontSize: "14px",
                lineHeight: "21px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "16px",
                letterSpacing: "0 ",
                lineHeight: "24px",
            },
        },
        subtitle1: {
            fontFamily: "'Roboto', sans-serif",
            [theme.breakpoints.up("xs")]: {
                fontSize: "18px",
                lineHeight: "27px",
                letterSpacing: "0.1px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "20px",
                letterSpacing: "0.11px",
                lineHeight: "30px",
                marginBottom: "1.3rem",
            },
        },
        subtitle2: {
            fontFamily: "'Roboto', sans-serif",
            [theme.breakpoints.up("xs")]: {
                fontSize: "18px",
                lineHeight: "27px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "18px",
                letterSpacing: "0.12px",
                lineHeight: "27px",
                marginBottom: "1.3rem",
            },
        },
        caption: {
            [theme.breakpoints.up("xs")]: {
                fontSize: "14px",
                letterSpacing: "0.4px",
                lineHeight: "21px",
                fontFamily: "'Roboto', sans-serif",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "16px",
                letterSpacing: "0.4px",
                lineHeight: "24px",
                fontFamily: "'Roboto', sans-serif",
            },

        },
        overline: {
            [theme.breakpoints.up("xs")]: {
                fontSize: "14px",
                fontWeight: "bold",
                letterSpacing: "0.97px",
                lineHeight: "21px",
                marginBottom: "1.3rem",
                fontFamily: "'Roboto', sans-serif",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "16px",
                fontWeight: "bold",
                letterSpacing: "1.11px",
                lineHeight: "24px",
                marginBottom: "1.3rem",
                fontFamily: "'Roboto', sans-serif",
            },
        },
    },

    overrides: {
        MuiFormHelperText: {
            filled: {
                fontSize: "12px !important",
                lineHeight: "14px !important",
            },
        },
        MuiListItemText: {
            root: {
                marginTop: 0,
            },
            primary: {
                marginBottom: "0",
            },
        },
        MuiFormControl: {
            root: {
                display: "flex",
            },
        },
        MuiFormControlLabel: {
            root: {
                display: "flex",
                verticalAlign: "top",
                alignItems: "flex-start",
                marginBottom: 15,
            },
        },
        MuiDialog: {
            paper: {
                margin: 10,
            },
        },
        MuiDialogActions: {
            root: {
                display: "flex",
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: "#2f2f2f",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#ffffff",
                fontWeight: "normal",
                margin: "0",
                boxShadow: "0 0 15px 0px #8c8c8c",
            },
            arrow: {
                color: "#2f2f2f",
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "rgb(255 255 255 / 70%)",
            },
        },
        MuiIconButton: {
            root: {
                padding: "4px 15px",
                margin: "0 5px",
                minHeight: 22,
                height: "auto",
                width: 35,
                borderRadius: "50%",
            },
        },

        MuiListItem: {
            secondaryAction: {
                paddingBottom: "0",
                paddingTop: "0",
                paddingRight: "192px",
            },
        },
        MuiButton: {
            root: {
                textTransform: "none",
                padding: "10px 50px",
                color: "#ffffff",
                borderRadius: 50,
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "normal",
                fontFamily: "'Roboto', sans-serif",
                whiteSpace: "pre",
            },
            containedSizeSmall: {
                padding: "6px 25px",
                fontSize: "16px",
            },
            containedSizeLarge: {
                padding: "15px 25px",
            },
            containedSecondary: {
                backgroundColor: "#cfcfcf",
            },
            contained: {
                boxShadow: "none",
            },
            outlined: {
                paddingTop: "15px",
                paddingBottom: "15px",
                // fontWeight: "bold",
            },
            outlinedSizeSmall: {
                padding: "6px 25px",
                minWidth: "200px",
                fontSize: "18px",
                fontWeight: 500,
            },
            fullWidth: {
                maxWidth: "100%",
            },
        },

        MuiInputBase: {
            root: {
                marginBottom: 0,
                background: "#ffffff",
            },
            input: {
                height: "18px",
                fontSize: "18px !important",
                marginBottom: 0,
                lineHeight: "20px",
                background: "#ffffff",
            },
        },
        MuiSelect: {
            selectMenu: {
                marginBottom: 0,
                height: "18px",
                fontSize: "16px",
                lineHeight: "20px",
                width: "100%",
            },
        },
        MuiFormLabel: {
            root: {
                color: "#000 !important",
                fontSize: "16px",
                lineHeight: "14px !important",
                whiteSpace: "pre",
                marginBottom: "0 !important",
            },
        },
        MuiTab: {
            root: { maxWidth: "auto" },
            wrapped: {
                fontSize: 16,
            },
            wrapper: {
                display: "inline-block",
            },

        },
        MuiListItemIcon: {
            root: {
                minWidth: "auto",
            },
        },

        MuiCardHeader: {
            content: {
                tittle: {
                    color: "#2C2C2C",
                    fontSize: 20,
                    fontWeight: "bold",
                    letterSpacing: 0,
                    lineHeight: 30,
                },
            },
        },
        MuiMenuItem: {
            root: {
                marginBottom: 0,
                minHeight: 20,
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 16,
                color: "#263238",
            },
            formControl: {
                color: "#263238 !important",
                lineHeight: "18px !important",
            },
            shrink: {
                transform: "translate(14px, -6px) scale(0.90) !important",
                background: "#ffffff !important ",
                padding: "0 5px",
            },
        },
        MuiAppBar: {
            colorDefault: {
                backgroundColor: "#FFFFFF",
            },
        },
        MuiStepLabel: {
            label: {
                fontSize: "18px !important",
            },
        },
        MuiTable: {
            stickyHeader: {
                borderCollapse: "collapse",
            },
        },
        MuiTableCell: {
            head: {
                whiteSpace: "pre",
                padding: "16px 15px",
                fontWeight: "normal" as "normal",
                fontSize: "18px",
            },
            body: {
                padding: "8px 15px",
            },
        },
    },
});


export const KaiZenThemeGlobalCss = withStyles({
    "@global": {


        ".badgeItem": {
            background: "rgb(255, 255, 255)",
            color: "rgb(54, 113, 237)",
        },
        ".customLinks .Mui-selected .badgeItem": {
            background: "rgb(54, 113, 237)",
            color: "rgb(255, 255, 255)",
        },
        ".customLinks .Mui-selected": {
            background: "#FFFFFF",
            color: "#0c52ec",
        },
        ".MuiTable-stickyHeader": {
            backgroundColor: "#edf3f8 !important",
        },
        ".MuiAppBar-colorPrimary ": {
            color: "#323232 !important",
            backgroundColor: "#fafafa !important",
            padding: 15,
            boxShadow: "15px -11px 22px 0px #8c8c8c !important",
            [theme.breakpoints.up(992)]: {
                padding: "0 10px !important",
            },
        },
        " .colorRed": {
            color: "red !important",
        },
        "body": {

            background: "#FFFFFF !important",

            fontFamily: "Roboto, Arial, sans-serif !important",

            fontSize: "16px",

            overflowX: "hidden",

        },
        ".graphMap": {

            width: "100%",

            margin: "0 auto",

            height: "400px",

        },
        ".react-multiple-carousel__arrow": {
            background: "#3772ED !important",
        },
        ".MuiFormLabel-root": {

            fontSize: "18px",

        },
        ".marginNone p": {

            margin: "0",

        },
        "button.Mui-selected.active,.drawerClose:hover": {

            background: "#3671ED",

            color: "#ffffff",

        },
        "button.Mui-selected.active:hover": {

            background: "#3671ED",

            color: "#ffffff",

        },
        ".textHighLight": {
            color: "#3DDB93",
        },
        ".closeicon": {
            background: "#3671ED",
        },
        ".tabsIcons": {
            color: "#3671ED",
        },
        ".scrollRight,.scrollRightMini": {
            backgroundColor: "#3671ED",
        },
        ".dashBoardHero": {
            background: "url(\"../images/landing_page_v2/welcome-card-image.png\"),linear-gradient(90deg, rgba(20, 57, 94, 1) 0%, rgba(27, 109, 178, 1) 35%, rgba(38, 145, 236, 1) 100%)",
            width: "100%",
            minHeight: "350px",
            backgroundPosition: "top right",
            backgroundSize: "cover",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "15px",
            borderRadius: "8px",
        },
        ".estimationTabs .estimationHeader": {
            background: "#3671ED",
        },
        ".FormForMobile": {
            background: "#3671ED",
        },
        ".estimationTabs .estimationHeader button": {
            background: "#3671ED",
            opacity: "1 ",
            color: "#ffffff",
        },
        ".estimationTabs .estimationHeader button .tabsIcons ": {
            border: "2px solid transparent !important",
        },
        ".estimationTabs .estimationHeader button.Mui-selected .tabsIcons ": {
            background: "transparent",
            border: "2px solid #3671ED !important",
            color: "#3671ED",
        },
        ".estimationTabs .estimationHeader button.Mui-selected": {
            color: "#3671ED !important",
        },
        ".backBtn svg": {
            color: "#3671ED !important",
        },
        ".strategyAndAlternatives table thead tr th:nth-child(2)": {
            background: "#3671ED",
        },
        ".LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active, .LeftNavigation .menuHead.MuiListItem-gutters.active": {
            color: "#3671ed !important",
            background: "rgba(0, 0, 0, 0.07) !important",
            borderLeft: "4px solid #3671ed",
        },
        ".scrollRight, .scrollRightMini,.scrollRight:hover, .scrollRightMini:hover": {
            backgroundColor: "#3671ED",
        },
        ".MuiButtonBase-root.MuiListItem-root.active.MuiListItem-gutters.MuiListItem-button .MuiListItemIcon-root svg": {
            color: "#3671ed !important",
        },
        ".enrollmentServicing": {
            background: "url(\"../images/landing_page_v2/Shape.png\"),linear-gradient(90deg, rgba(20, 57, 94, 1) 0%, rgba(27, 109, 178, 1) 35%, rgba(38, 145, 236, 1) 100%)",
            minHeight: "350px",
            backgroundPosition: "bottom right",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.18)",
            padding: "50px 15px 0",
            borderRadius: "8px",
            margin: "0 15px 50px",
        },
        ".MuiLink-underlineHover": {
            cursor: "pointer",
        },
        "input:disabled, .MuiFilledInput-root.Mui-disabled,div.Mui-disabled.Mui-disabled,textarea.Mui-disabled.Mui-disabled": {
            backgroundColor: "#d4d4d4",
            color: "#263238 !important",
        },
        "label.Mui-disabled.Mui-disabled, label.Mui-disabled": {
            color: "#263238 !important",
            backgroundColor: "transparent",
        },
        ".checkGroup label span.Mui-disabled.Mui-disabled.Mui-disabled,.checkGroup label.Mui-disabled": {
            backgroundColor: "transparent !important",
        },
        // ".checkGroup label span:first-child": {
        //     color: "#3671ED !important",
        // },
        "@media(max-width:1456px)": {

            ".dashBoardHero": {
                backgroundPosition: "bottom right",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
            },

            ".clientClasses .heroBgFooter": {
                background: "#FFFFFF",
            },

        },
        "@media(max-width:992px)": {
            ".graphMap": {
                width: "1200px",
                margin: "0 auto",
                height: "250px",
                paddingBottom: "10px",
            },

            ".MuiSnackbarContent-action": {
                paddingLeft: "0",
            },
            ".MuiToggleButton-sizeSmall": {
                padding: "10px 50px !important",
            },
        },


        //CSS Added 7/Dec/2021
        " .highlightTxt": {
            color: "#3671ed !important",
        },
        " .highlightTxtGreen": {
            color: "#3A9E2D !important",
        },
        " .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active svg.MuiSvgIcon-root .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active svg.MuiSvgIcon-root": {
            color: "#2C2C2C !important",
        },
        " .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary": {
            color: "#424242 !important",
        },
        " .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active svg.MuiSvgIcon-root": {
            color: "#3671ed !important",
        },
        ".chatBtn,.scroll-downs, .btnBack:hover, .plansCard.hover, .plansCard.hover:before, .plansCard.hover:after,.participantsTabs button.Mui-selected,.chatHeader": {
            background: "#3671ed",
        },
        "  .LeftNavigation .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft": {
            background: "#ffffff",
            borderRight: "1px solid #D8D8D8",
        },
        " .subList .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active svg.MuiSvgIcon-root": {
            color: "#ecb339 !important",
        },
        " .LeftNavigation .MuiListItem-button:hover, .subList .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:hover": {
            color: "#85a7f2 !important",
            backgroundColor: "rgba(0,0,0,0.07) !important",
        },
        " .menuHead:hover": {
            backgroundColor: "rgba(0,0,0,0.07) !important",
            cursor: "pointer !important",
        },
        "clientClasses .customStyleTabs button": {
            backgroundColor: "#e4ecff !important",
        },
        // ".testimonials .muiCard:hover p, .testimonials .muiCard:hover h4": {
        //     color: "#ffffff !important",
        // },
        " .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button svg.MuiSvgIcon-root": {
            color: "#2C2C2C !important",
        },
        " .ulStyling ul.blueDot li:before, ul.groupLink.blueDot li:before": {
            border: "4px solid #3671ed",
        },
        ".chatHeader button": {
            background: "#3671ed ",
        },
        ".chatHeader button:hover,.chatBtn:hover": {
            background: "#3671ed ",
        },
        // ".testimonials .muiCard:hover": {
        //     background: "#3671ed !important",
        //     border: "1px solid #3671ed;",
        // },
        " .annualContributions input,.cardHeader h4, .plansCard strong.icon svg": {
            color: "#3671ed !important",
        },
        " .annualContributions": {
            color: "#3671ed !important",
        },
        " button.btnPrimary, button.btnPrimaryBig": {
            background: "#3671ed",
        },
        " button.btnPrimary:hover, button.btnPrimaryBig:hover,button.compareBtn span + span:after": {
            background: "#598fff !important",
        },
        " button.btnPrimary.Mui-disabled.Mui-disabled, button.btnPrimaryBig.Mui-disabled.Mui-disabled": {
            background: "rgba(0, 0, 0, 0.12)",
        },
        ".animated-box:after": {
            background: "linear-gradient(120deg, #ECB339, #107903, #ECB339)",
            backgroundSize: "300% 300%",
            clipPath: "polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%)",
        },
        " .bordertop": {
            borderTop: "1px solid #63c756",
        },
        " .customeTabs button:hover": {
            border: "3px solid #3671ed",
            color: "#3671ed",
            background: "#dde5f5 !important",
        },
        " .customeTabs button.Mui-selected": {
            background: "#3671ed !important",
            color: "#ffffff !important",
        },
        " .listView:hover li, .listView:hover:before, .listView:hover:after, .listView:hover li:nth-child(2)": {
            background: "#3671ed !important",
        },
        " .listView:hover li div h6 span.highlightTxt, .listView:hover li div h6 span.textBlack ": {
            color: "#FFFFFF !important",
        },
        " .listView li div svg": {
            color: "#3671ed !important",
        },
        " .listViewhead li, .listView li, .graphView li": {
            background: "#dee9fd",
        },
        " .listView:hover li div svg": {
            color: "#ffffff !important",
        },
        " .modalCSS button.slideBtn": {
            background: "#3671ed !important",
        },
        " .modalCSS": {
            borderBottom: "5px solid #3671ed !important",
        },
        " .listView li h6": {
            color: "#3671ed",
        },
        " .customStyleTabs button.Mui-selected": {
            color:'#3671ed',
        },
        " .muiCardLightBlue,  .muiCardLightBlue": {
            background: "#eef4f9",
        },
        " h2": {
            color: "#344C6E;",
        },
        ".colorBlue": {
            color: "#3671ed",
        },
        ".enrollNow .RadioGroup label span svg": {
            color: "#3671ed",
        },
        ".customModalContent strong": {
            color: "#3671ed",
        },
        ".remarks label": {
            color: "#3671ed !important",
        },
        ".remarks input": {
            background: "#dde8ff",
        },
        ".textBlue": {
            color: "#3671ed",
        },
        ".Mui-checked": {
            color: "#3971EC !important",
        },
        " .subHeader": {
            color: "#323232 !important",
            fontFamily: "roboto-light !important",
            fontSize: "22px !important",
            letterSpacing: "0 !important",
            lineHeight: "34px !important",
            float: "left",
        },
        " h2.NewDashBoardTitle": {
            color: "#323232",
            fontSize: "46px !important",
            letterSpacing: "0 !important",
            lineHeight: "64px !important",
            fontFamily: "roboto-regular !important",
        },
        " p.text": {
            color: "#323232",
            fontFamily: "roboto-regular !important",
        },
        " h2.NewDashBoardTitle strong": {
            fontWeight: "bolder !important",
            fontFamily: "roboto-bold !important",
        },
        " h2.NewDashBoardTitle.f36": {
            fontSize: "36px !important",
            lineHeight: "52px !important",
            fontFamily: "roboto-light !important",
        },
        "h4.itemTitle": {
            fontFamily: "roboto-bold !important",
            fontSize: "20px !important",
            lineHeight: "34px !important",
            color: "#323232 !important",
        },
        " .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active,  .LeftNavigation .menuHead.MuiListItem-gutters.active": {
            background: "rgba(0, 0, 0, 0.07) !important",
            color: "#3671ed !important",
            borderLeft: "4px solid #3671ed",
        },
        " .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary": {
            color: "#3671ed !important",
        },
        " .LeftNavigation .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary, .menuHead.active .MuiListItemText-root .MuiListItemText-primary span": {
            color: "#3671ed !important",
        },
        " .subList .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.active .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary": {
            color: "#3671ed !important",
        },
        " .LeftNavigation .menuHead.MuiListItem-gutters.active svg": {
            color: "#3671ed !important",
        },
        ".menuHead span": {
            color: "#000000",
        },
        ".active.menuHead span": {
            color: "#3671ed",
        },
        ".dashBoardHero .tittle:before": {
            top: "-20px !important",
        },
        ".clientClasses .dashBoardHero h3": {
            color: "#FFFFFF !important",
        },
        ".clientClasses .dashBoardHero p": {
            color: "#FFFFFF !important",
        },
        ".clientClasses .customeTabs button": {
            border: "3px solid #3671ed",
            color: "#3671ed",
        },
        ".chatBtn": {
            position: " fixed !important",
            right: 30,
            bottom: 10,
        },
        ".chatWrap": {
            background: "#ffffff",
            width: "300px",
            minHeight: "275px",
            position: "fixed",
            right: "30px",
            bottom: "0",
            boxShadow: "0 -5px 48px -15px #b7b7b7 !important",
        },
        ".chatHeader": {
            float: "left",
            width: "100%",
        },
        ".chatContent": {
            float: "left",
            width: "100%",
        },
        ".chatContent form": {
            position: "relative",
            float: "left",
            width: "100%",
            padding: "10px",
            bottom: 0,
        },
        ".chatContent form textarea": {
            height: "100px !important",
            width: "92%",
            marginLeft: "10px",
            fontSize: "14px",
            overflowY: "auto !important",
        },
        ".clientClasses .chatContent form p.Mui-error": {
            marginLeft: "10px !important",
        },
        ".clientClasses .mr15": {
            marginRight: "15px !important",
        },
        ".clientClasses .img-responsive": {
            maxWidth: "100%",
            height: "auto",
            margin: '0 auto',
            display: "block",
        },
        ".chatHeader img": {
            float: "left",
            width: 60,
            height: "60px !important",
            margin: "5px 15px 5px 5px !important",
            borderRadius: '100%',
        },
        ".boxShadowNone": {
            boxShadow: "none !important",
        },
        ".chatHeader .f14": {
            fontSize: "14px !important",
            letterSpacing: "0",
            lineHeight: "22px",
        },
        ".chatContent form button": {
            width: "100%",
        },
        ".chatContent .msgSendNotification": {
            textAlign: "center",
        },
        ".txtWhite ": {
            color: "#ffffff",
        },

        "@media(max-width:1356px)": {
            ".clientClasses .heroBgFooter": {
                background: "#FFFFFF",
            },
        },
        "@media(max-width:767px)": {
            ".dashBoardHero": {
                background: "linear-gradient(90deg, rgba(20, 57, 94, 1) 0%, rgba(27, 109, 178, 1) 35%, rgba(38, 145, 236, 1) 100%) !important",
            },
        },


    },
})
(() => null);
export default kaiZenTheme;
