import React  from "react";
import {Checkbox, FormHelperText, Typography} from "@material-ui/core";
import {Field, FieldArray, useFormikContext} from "formik";
import {Grid} from "@material-ui/core";
import {TextField} from "../../../../../../../components/formikFormInputs";
import _ from "lodash";
import debounce from "lodash/debounce";
import {NAME_IDENTIFIER} from "../FinancialAgreement/FinancialQuestions";
import {Label} from "../../../../../../../components/formInputs";
import {NOTA} from "../FinancialAgreement/FinancialQuestions";
import NumberTextField from "../../../../../../../components/formikFormInputs/NumberTextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const RenderChildField = (props:{name:string, fieldName:string, field:any, values:any, setFieldValue:Function, clientSigned:boolean, error:boolean}) => {
    const {name, fieldName, field, values, setFieldValue, clientSigned, error} = props;

    const setValueForCheckBoxDesc = debounce((name, value) => setFieldValue(name, value), 500);

    const handleChangeCheckBoxDesc = ({ target }) => {
        let index:number = _.findIndex(values[name], (e:any) => {
            return e && e.name === fieldName
        },0);
        if(index > -1) {
            setValueForCheckBoxDesc(`${name}.${index}.desc`, target.value)
        }
    };

    const setDefaultValueForDesc = (fieldName) => {
        let index:number = _.findIndex(values[name], (e:any) => {
            return e && e.name === fieldName
        },0);
        if(index > -1) {
            return values[name][index].desc
        }
        return "";
    }

    const renderField = (detailBoxType:string) => {
        switch (detailBoxType) {
            case "amount":
                return <Field
                        className="mt10 mb5"
                        fullWidth
                        name={`${fieldName}_desc`}
                        component={NumberTextField}
                        placeholder={field.placeholder}
                        label={<Label label={field.label} required={true}/>}
                        onChange={handleChangeCheckBoxDesc}
                        defaultValue={setDefaultValueForDesc(fieldName)}
                        variant="outlined"
                        disabled={clientSigned}
                        error={error}
                        InputProps={{
                            startAdornment: <InputAdornment className="dollerIcon"
                                                            position="start">$</InputAdornment>,
                        }}
                />
                break;
            default:
                return <Field
                        className="mt10 mb5"
                        fullWidth
                        name={`${fieldName}_desc`}
                        component={TextField}
                        placeholder={field.placeholder}
                        label={<Label label={field.label} required={true}/>}
                        onChange={handleChangeCheckBoxDesc}
                        defaultValue={setDefaultValueForDesc(fieldName)}
                        variant="outlined"
                        disabled={clientSigned}
                        error={error}
                />
                break;
        }
    }
    return renderField(field && field.detailBoxType || "text")
}

export default function FinancialPageCheckBox(props:{
    fieldOptions:any,
    mainIndex:number,
    clientSigned:boolean
}) {
    const {
        fieldOptions,
        mainIndex,
        clientSigned
    } = props;

    const {values, setFieldValue, errors, touched}: any = useFormikContext();

    const name:string = `${NAME_IDENTIFIER}C_${mainIndex}`;

    const checkBoxDefaultValue = (fieldArray:any, fieldName:string) => {
        let index = _.findIndex(fieldArray, (e:any) => {
            return e && e.name === fieldName;
        }, 0);
        return index > -1;
    }

    const handleNoneOfThese = async (name) => {
        return await setFieldValue(name, [])
    }

    return (
            <FieldArray
                    name={name}
                    render={arrayHelpers => {
                        return (
                                <Grid container spacing={2}>
                                    {fieldOptions.map((fieldVal: any, index:number) => {
                                        const fieldName:string = `${NAME_IDENTIFIER}C_${mainIndex}_${index}`;
                                        return (
                                                <Grid item xs={12} lg={6} >
                                                    <label key={fieldVal.value} className="checkboxStyle ml0">
                                                        <Checkbox
                                                                className="margin0 pt0"
                                                                name={fieldName}
                                                                value={fieldVal.value}
                                                                checked={checkBoxDefaultValue(values[name], fieldName)}
                                                                onChange={async (e) => {
                                                                    if (e.target.checked) {
                                                                        let obj:any = {
                                                                            name: fieldName,
                                                                            value: fieldVal.value,
                                                                            desc:""
                                                                        };
                                                                        if((obj && obj.value) === NOTA) {
                                                                            await handleNoneOfThese(name).then(() => {
                                                                                arrayHelpers.push(obj);
                                                                            })} else {
                                                                            let index = _.findIndex(values[name], (ee:any) => {
                                                                                return ee && ee.value === NOTA;
                                                                            }, 0);
                                                                            if(index > -1){
                                                                                arrayHelpers.remove(index)
                                                                            }
                                                                            arrayHelpers.push(obj);
                                                                        }
                                                                    } else {
                                                                        let index = _.findIndex(values[name], (ee:any) => {
                                                                            return ee && ee.name === e.target.name;
                                                                        }, 0);
                                                                        arrayHelpers.remove(index)
                                                                    }
                                                                }}
                                                                disabled={clientSigned}

                                                        />
                                                        <Typography variant="body2" className="mb0"> {fieldVal.text}</Typography>
                                                    </label>

                                                    {
                                                            (checkBoxDefaultValue(values[name], fieldName) && fieldVal.showDetailsBox) &&

                                                            <RenderChildField name={name} fieldName={`${fieldName}`}
                                                                              field={fieldVal} values={values}
                                                                              setFieldValue={setFieldValue}
                                                                              clientSigned={clientSigned}
                                                                              error={(errors && errors[name] && errors[name].length) > 0}
                                                            />

                                                    }
                                                </Grid>
                                        )
                                    })}
                                    <>
                                        {(errors && errors[name] && errors[name].length) > 0 &&
                                                <Grid item xs={12}>
                                                    <FormHelperText  style={{margin: '0', fontSize: '10px', color: "#FF1744"}}>
                                                        <span className={""}>Required Field</span>
                                                    </FormHelperText>
                                                </Grid>
                                        }
                                    </>
                                </Grid>
                        )
                    }}
            />
    );}