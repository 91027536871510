import React from "react";
import { Button, Dialog, DialogContent, CircularProgress, Typography, DialogActions } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import AddNotesPage from './AddNotes';
const defaultTitle = "Jamin Austin";
const defaultContent = "Are you sure want to delete permanently ?";
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            wrapper: {
                position: 'relative',
            },
            buttonProgress: {
                color: blue[500],
                position: 'absolute',
                top: '10px',
                left: '35%',
                marginTop:'0px',
                width:'100%'
            },
        })
);

export default function DeleteModelWithNotes({
                                        open,
                                        handleClose,
                                        accountType = "",
                                        title = defaultTitle,
                                        content = defaultContent,
                                        disableDelete = false,
                                        handleYes,
                                        setNotes,
                                        notes,
                                        buttonLoader = false
                                    }: any) {
    const [notesArea, setNotesArea] = React.useState(false);
    const [buttonText, setbuttonText] = React.useState('Add');
    const classes = useStyles();
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"xs"}
                    className="customModal"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <div id="alert-dialog-title" className="pt20 pr30 pl30 mb0">
                    <PersonIcon/> <Typography component="span" className="mb0"> <strong className="w100 floatLeft">{title}</strong>
                    <i className="f12 w100 floatLeft">{`${accountType} Account`}</i></Typography>
                </div>

                <DialogContent dividers>
                        <Typography variant="body1" className="mb0">{content}</Typography>
                </DialogContent>

                <DialogActions>
                    {!disableDelete && <Button size="small"
                            className="bgRed"
                            type="submit"
                            variant="contained"
                            disabled={buttonLoader}
                            color="primary" onClick={handleYes} autoFocus>
                        Yes
                        {buttonLoader &&  <CircularProgress className={classes.buttonProgress}
                                                            size={20} /> }
                    </Button>}
                    <Button
                            size="small"
                            type="submit"
                            variant="contained"
                            color="primary" onClick={handleClose}>
                        {!disableDelete ? "No" : "Cancel"}
                    </Button>
                    <Button color="primary"
                            size="small"
                            onClick={() => {setNotesArea(true)}} variant="contained">
                        {buttonText} Notes
                    </Button>
                </DialogActions>
                {notesArea && <AddNotesPage setNotesArea={setNotesArea} notes={notes} sendNotes={ (val:any) => {
                    const text = (val === '') ? 'Add': 'Edit'
                    setNotes(val)
                    setbuttonText(text)
                } } />}

            </Dialog>
    );
}
