import React from "react";
import { Table, TableContainer } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

type content = {
    children?: React.ReactNode,
};
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            table: {
                minWidth: 767,
            },
            tableHead: {
                backgroundColor: "#c1d1ff",
            },
            container: {
                maxHeight: 600,
                marginTop:20,
                [theme.breakpoints.down("lg")]: {
                    maxHeight: 450,
                },
                [theme.breakpoints.down("xs")]: {
                    maxHeight: 280,
                },

            },

        }),
);


const TableWrap = (Props: content) => {
    const { children } = Props;
    const classes = useStyles();
    return (
            <TableContainer className={classes.container}>
                <Table className="materialUiTable" stickyHeader>

                    {children}

                </Table>

            </TableContainer>


    );
};

export default TableWrap;