import React from "react";
import { Grid } from "@material-ui/core";
import { KaiZenIsDifferent, WhyKaiZen } from "./groupRebrand";
import { CircularLoading as CircularProgress } from "../../../../components/Common";
import { VideoCarouselGroup } from "../../../../components/marketingMaterials";
import LandingFooter from "./LandingFooter";
import { useActions } from "../../../../actions";
import * as Actions from "../../../../actions/vimeo";
import { checkIfRebrand } from "../../../../utils/commonUtils";
import {ATLANTIC_HEALTH} from "../../../../constants";

type ShowCase = { id: number; name: string; showcaseUrl: string; };
type ShowCaseVideos = { id: number; name: string; showcaseUrl: string; videos: any[] };
function GroupRebrandSection(props: any) {
    const [loading, setLoading] = React.useState(false);
    const [showCases, setShowCases] = React.useState<ShowCaseVideos[]>([]);
    const strategy: string = props.strategyData.name;
    const isAtlanticHealth = ATLANTIC_HEALTH.includes(props.groupDetail && props.groupDetail.companyLinkUrl);

    const vimeoActions = useActions(Actions);

    const { isRebrand } = checkIfRebrand(props);

    console.info({isAtlanticHealth, isRebrand})

    const VAS: ShowCase[] = [
        {
            id: isAtlanticHealth ? 10740965 : (isRebrand ? 9691535 : 8305190),
            name: "Personal Testimonial",
            showcaseUrl: "https://vimeo.com/showcase/",
        },
    ];

    const getAllShowcaseVideos = async () => {
        setLoading(true);
        const result: ShowCaseVideos[] = [];
        for (const showcase of VAS) {
            const resp = await vimeoActions.getShowcaseDetails(showcase.id);
            if (!resp || !resp.data) {
                setLoading(false);
                return;
            }

            const videos = resp && resp.data && resp.data.map((v: any) => ({
                desc: v.description,
                title: v.name,
                videoUrl: v.player_embed_url || v.link,
            }));
            result.push({ ...showcase, videos: videos || [] });
            setShowCases([...showCases, ...result]);
            setLoading(false);
        }
    };
    const firstEffect = () => {
        getAllShowcaseVideos().then();
    };

    React.useEffect(firstEffect, []);

    return (
            <>
                {
                        loading &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <CircularProgress size={40} />
                            </Grid>
                        </Grid>
                }
                <WhyKaiZen strategy={strategy} />
                <KaiZenIsDifferent strategy={strategy} />
                {
                        !loading &&
                        showCases.map((s, key) => (
                                <VideoCarouselGroup {...s} key={key} />
                        ))
                }
                <Grid item xs={12} md={12} className="pdTB15">
                    <LandingFooter {...props} />
                </Grid>
            </>
    );
}

export default GroupRebrandSection;