import React from "react";
import clsx from "clsx";
import { connect, useDispatch, useSelector } from "react-redux";
import { CssBaseline, Drawer, IconButton } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { CustomNavigation } from "./index";
import { RootState } from "../reducers";
import { Account } from "../model/account";
import { useStyles } from "./css/LeftPanelCss";
import { isTouchScreen, noop } from "../utils/commonUtils";
import { ActionType } from "../model";


type LeftPanelProps = {
    // leftPanelOpen: boolean;
    // setLeftPanelOpen: Function;
    setIsEstimation: Function;
}


function LeftPanel(props: LeftPanelProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const leftPanelOpen = useSelector((state: RootState) => state.leftPanelOpen);
    const user: Account = useSelector((state: RootState) => state.auth.user);
    const { setIsEstimation } = props;

    const handleNavClick = () => {
        if (isTouchScreen()) {
            dispatch({ type: ActionType.LEFTPANEL, payload: false });
            document.body.style.overflow = null;
        }
    }

    return (
            <div className={classes.root}>
                <CssBaseline/>

                <nav className={`LeftNavigation ${!leftPanelOpen ? "demo" : ""}`} aria-label="mailbox folders">
                    <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: leftPanelOpen,
                                [classes.drawerClose]: !leftPanelOpen,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: leftPanelOpen,
                                    [classes.drawerClose]: !leftPanelOpen,
                                }),
                            }}
                            open={leftPanelOpen}
                    >
                        <CustomNavigation
                                className="LeftNavigation"
                                user={user}
                                handleNavClick={handleNavClick}
                                SetIsEstimation={setIsEstimation}
                        />
                    </Drawer>
                </nav>
            </div>
    );
}

export default connect()(LeftPanel);

export const IliaAvatar = ({ topRightProfileImage, onClick }: any) => {
    const classes = useStyles();

    return <IconButton
            className="hoverNone profileImage"
            aria-label="User Welcome"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={onClick ? onClick : noop}

    >
        {topRightProfileImage ? <img className={classes.profileImage} alt="ProfileImage"
                                     src={topRightProfileImage}
                                     title="User Image"/> : <PersonIcon/>}
    </IconButton>;
};