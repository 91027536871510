import React from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import {FormikValues} from "../formikFormInputs";
import {Loader} from "../Common";
import {DynamicFormField, getOptions} from "./index";
import {InputChangeFunc} from "../../model/fields";
import {isDebug} from "../../utils/commonUtils";
import {EditQA} from "../../model/application";
import {BS} from "../../model/misc";


interface DynamicChildrenFormProps extends FormikValues {
    editAns?: EditQA;
    enrollmentId: string;
    fields: any[];
    forceNo?: boolean;
    hasPhysicians?: BS;
    isHeadingType?: boolean;
    loading?: boolean;
    onClearSearch?: Function;
    onSearch?: InputChangeFunc;
    searching?: boolean;
    searchOptions?: string[];
    setForceNo?: Function;
    setHasPhysicians?: Function;
    setIsCountryUSA?: Function;
    stoppedErr?: string;
}

const ChildrenForm: React.FC<DynamicChildrenFormProps> = (props) => {
    const debug = isDebug();
    const {
        editAns,
        enrollmentId,
        errors,
        fields,
        forceNo,
        handleBlur,
        handleChange,
        handleSubmit,
        hasPhysicians,
        initialValues,
        isHeadingType,
        isSubmitting,
        isValid,
        loading,
        onClearSearch,
        onSearch,
        searching,
        searchOptions,
        setFieldTouched,
        setFieldValue,
        setForceNo,
        setHasPhysicians,
        setIsCountryUSA,
        stoppedErr,
        values,
    } = props;
    return (
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {
                            loading &&
                            <Loader/>
                    }
                    {
                        fields.map((field: any, index) => {
                            const extractFieldProperties = (fProps: any) => {
                                const {
                                    aliasName,
                                    answerType,
                                    answerValue,
                                    baseQuestionHelp,
                                    caseDataQuestionMeta,
                                    code,
                                    dateGranularity,
                                    disclosureCode,
                                    disclosureSource,
                                    listItems,
                                    picklistQuestionMeta,
                                    questionText,
                                    questionType
                                } = fProps;
                                const options = getOptions(answerType, disclosureSource, searchOptions, fProps, picklistQuestionMeta);
                                return {
                                    aliasName,
                                    answerValue,
                                    dataType: caseDataQuestionMeta && caseDataQuestionMeta.dataType,
                                    dateGranularity,
                                    disclosureCode,
                                    disclosureSource,
                                    editAns,
                                    enrollmentId,
                                    errors,
                                    forceNo,
                                    handleBlur,
                                    handleChange,
                                    helperText: baseQuestionHelp,
                                    initialValues,
                                    label: questionText,
                                    listItems,
                                    loading,
                                    maxValue: caseDataQuestionMeta && caseDataQuestionMeta.maximum,
                                    name: code,
                                    onClearSearch,
                                    onSearch,
                                    options,
                                    hasPhysicians,
                                    picklistSearchEnabled: picklistQuestionMeta && picklistQuestionMeta.picklistSearchEnabled,
                                    searching,
                                    searchOptions,
                                    setFieldTouched,
                                    setFieldValue,
                                    setForceNo,
                                    setHasPhysicians,
                                    setIsCountryUSA,
                                    type: answerType || questionType,
                                    values,
                                };
                            };
                            const fieldProps = extractFieldProperties(field);

                            return (
                                    <React.Fragment key={index}>
                                        {
                                                !loading && !!field &&
                                                <DynamicFormField {...fieldProps} />
                                        }
                                        {
                                                stoppedErr &&
                                                <Grid item xs={12}>
                                                    <Typography className="colorRed" variant="body1">
                                                        {stoppedErr}
                                                    </Typography>
                                                </Grid>
                                        }
                                        {
                                                debug && isDebug('extraDebug') &&
                                                <Grid item xs={12}>
                                                    <pre>{JSON.stringify(fieldProps, null, 2)}</pre>
                                                </Grid>
                                        }
                                    </React.Fragment>
                            )
                        })
                    }
                    {
                            !stoppedErr &&
                            <Grid item xs={12}>
                                <Button
                                        className="floatRight"
                                        color="primary"
                                        disabled={isSubmitting || !isValid || loading}
                                        type="submit"
                                        variant="contained"
                                >
                                    {`${isHeadingType ? '' : 'Save &'} Next`}
                                </Button>
                            </Grid>
                    }
                    <Grid item xs={12} style={{display: debug ? 'display' : 'none'}}>
                        <pre>
                            {
                                JSON.stringify({isHeadingType, hasPhysicians, errors, values}, null, 2)
                            }
                        </pre>
                    </Grid>
                </Grid>
            </form>
    );
};
export default ChildrenForm;
