import {DynamicFormFieldEnum, INCH_IN_FEET} from "./index";
import {isDebug, tis} from "../../utils/commonUtils";
import {FormikValues} from "../formikFormInputs";
import {NextQuestion, SavePayload} from "../../model/application";
import {BUN, NS} from "../../model/misc";

/**
 * Generating Save Answer Payload
 * @param enrollmentId
 * @param isUpdated
 * @param nextQuestionJSON
 * @param values
 */
export const generateSaveAnswerPayload = (enrollmentId: string, isUpdated: BUN, nextQuestionJSON: NextQuestion, values: FormikValues) => {
    const debug = isDebug();

    let type = nextQuestionJSON.answerType || nextQuestionJSON.questionType;
    switch (type) {
        case DynamicFormFieldEnum.YesNo:
            type = nextQuestionJSON.disclosureSource === DynamicFormFieldEnum.Picklist ? DynamicFormFieldEnum.Picklist : type;
            break;
        case DynamicFormFieldEnum.CaseData:
            type = nextQuestionJSON.caseDataQuestionMeta && nextQuestionJSON.caseDataQuestionMeta.dataType ? nextQuestionJSON.caseDataQuestionMeta.dataType : type;
            break;
        case DynamicFormFieldEnum.Boolean:
        case DynamicFormFieldEnum.Date:
        case DynamicFormFieldEnum.EnterDetails:
        case DynamicFormFieldEnum.Heading:
        case DynamicFormFieldEnum.Month:
        case DynamicFormFieldEnum.Numeric:
        case DynamicFormFieldEnum.Number:
        case DynamicFormFieldEnum.Picklist:
        case DynamicFormFieldEnum.Selection:
        case DynamicFormFieldEnum.Search:
        case DynamicFormFieldEnum.Text:
        default:
            debug && console.info('TYPE', type);
    }

    // answer payload
    const payload = {} as SavePayload;
    Object.keys(values).forEach(key => {
        return Object.assign(payload, {
                aliasName: nextQuestionJSON.aliasName,
                answerType: type,
                answerValue: tis(values[key]),
                baseQuestionCode: nextQuestionJSON.baseQuestionCode || key,
                enrollmentId,
                isUpdated,
                questionId: key,
                questionText: nextQuestionJSON.questionText
            }
        );
    });

    return payload;
};

/**
 * convert from database where inches are stored and have "inc" suffix, this function will return feet and inch
 * @param inches
 */
export const convertInchToFeet: (inches: string) => ([NS, NS]) = (inches: string) => {
    const inc = parseInt(inches);
    if (inc) {
        const HFeet = Math.floor(inc / 12);
        const HInch = inc % 12;
        return [HFeet, HInch]
    }

    return ['', ''];
};

/**
 * Convert feet and inches to inches (number)
 * @param ft
 * @param inc
 */
export const convertFeetInches = (ft: number, inc: number) => {
    return ((ft || 0) * INCH_IN_FEET) + (inc || 0);
};