import {isFunc} from "./commonUtils";

/**
 * Get strategy details for user
 * @param user
 */
export const getStrategyDetails = (user) => {
    const roleArr = [1, 2, 3, 4, 15];
    let registrationMark: boolean | undefined = true;
    let strategyName: any = "Kai-Zen";
    let strategySlug: string = "kaizen";
    const { client, accessType, role } = user;
    if (client) {
        strategyName = (client.strategy && client.strategy.name) || strategyName;
        strategySlug = (client.strategy && client.strategy.slug) || strategySlug;
        registrationMark = client.strategy && client.strategy.registrationMark;
    } else if (accessType === "client" && roleArr.indexOf(role) !== -1) {
        strategyName = (user.demoStrategy && user.demoStrategy.name) || strategyName;
        strategySlug = (user.demoStrategy && user.demoStrategy.slug) || strategySlug;
        registrationMark = user.demoStrategy && user.demoStrategy.registrationMark;
    }
    return {strategyName, strategySlug, registrationMark};
}

export const getContributionFrequencyText = (frequency: string, capitalize: boolean = false) => {
    let frequencyText = (frequency === "Monthly" ? "monthly": "annual");
    return capitalize ? frequencyText[0].toUpperCase() + frequencyText.substring(1) : frequencyText;
}

/**
 * Repeated calls a function after given time for given number of times
 * @param func
 * @param time
 * @param repetitions
 * @param afterLastCall
 */
export function repeatCaller(func: Function, time: number, repetitions: number = 2, afterLastCall?: Function) {
    const callBack = () => {
        afterLastCall && isFunc(afterLastCall) && afterLastCall();
    };
    return () => {
        let called = 1;
        // first call
        func();
        // check min repetitions
        if (repetitions < 2) {
            callBack();
        }

        // setting interval
        let interval = setInterval(() => {
            // making repeat call
            func();
            // incrementing how many times we called
            called++;
            // check exit condition
            if (called === repetitions) {
                // clear
                clearInterval(interval);
                // callback
                callBack();
            }
        }, time);
    };
}