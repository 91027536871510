import React from "react";
import {Box} from "@material-ui/core";
import {NS} from "../../model/misc";


type TabPanelProps = { children: any; index: NS; key: NS; value: NS; }

const TabPanel = (props: TabPanelProps) => {
    const {children, index, value, ...other} = props;

    return (
            <div
                    aria-labelledby={`vertical-tab-${index}`}
                    hidden={value !== index}
                    id={`tab-panel-${index}`}
                    role="tabpanel"
                    {...other}
            >
                <Box>{children}</Box>
            </div>
    );
};

export default TabPanel;
