import * as React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { history } from "../../configureStore";
import HowToRegOutlinedIcon from "@material-ui/icons/HowToRegOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import AssignmentLateOutlinedIcon from "@material-ui/icons/AssignmentLateOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import { cssPaths } from "../../constants";

function GroupNavigation(props: any) {
    const {handleNavClick} = props;
    const [menu, setMenu] = React.useState(0);

    React.useEffect(() => {
        const pathName: string = history.location.pathname;
        if(pathName.search('/group-participants') > -1) {
            setMenu(1);
        } else if(pathName.search('/group-action-items') > -1) {
            setMenu(2);
        } else if(pathName.search('/group-documents') > -1) {
            setMenu(3);
        } else if(pathName.search('/group-notes') > -1) {
            setMenu(4);
        }
    }, [history.location.pathname])

    const handleMenuClick = (menuId: number) => {
        setMenu(menuId);
        switch (menuId) {
            case 0:
                history.push("/group-dashboard");
                break;
            case 1:
                history.push("/group-participants");
                break;
            case 2:
                history.push("/group-action-items");
                break;
            case 3:
                history.push("/group-documents");
                break;
            case 4:
                history.push("/group-notes");
                break;
        }
        handleNavClick();
    }
	return (
		<React.Fragment>
            <link rel="stylesheet" href={`${process.env.PUBLIC_URL + cssPaths.layout}`}/>
			<List className="mt20 mb20">
                <ListItem className={ menu === 0 ? "active" : ""} button onClick={() => handleMenuClick(0)}>
                    <ListItemIcon>
                        <HomeOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Group Dashboard"/>
                </ListItem>

                <ListItem className={ menu === 1 ? "active" : ""} button onClick={() => handleMenuClick(1)}>
                    <ListItemIcon>
                        <HowToRegOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Participant Management"/>
                </ListItem>

                <ListItem className={ menu === 2 ? "active" : ""} button onClick={() => handleMenuClick(2)}>
                    <ListItemIcon>
                        <AssignmentLateOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Action Items"/>
                </ListItem>

                <ListItem className={ menu === 3 ? "active" : ""} button onClick={() => handleMenuClick(3)}>
                    <ListItemIcon>
                        <AssignmentOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Documents"/>
                </ListItem>

                <ListItem className={ menu === 4 ? "active" : ""} button onClick={() => handleMenuClick(4)}>
                    <ListItemIcon>
                        <ChatOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Notes"/>
                </ListItem>
			</List>
		</React.Fragment>
	);
}

export default GroupNavigation;