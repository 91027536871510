import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import url from 'url';
import api, { Response } from "superagent";
import {
	Grid,
	Button,
	Typography,
	LinearProgress, Tooltip,
} from "@material-ui/core";
import { Theme, makeStyles, createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { CloudUpload, Edit } from "@material-ui/icons";
import { useActions } from "../../actions";
import * as Aws from "../../actions/aws";
import { getRandomString, parseEmptyString } from "../../utils/commonUtils";
import CropImage from "../CropImage";

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
	gridList: {
		flexWrap: "nowrap",
		transform: "translateZ(0)",
	},
	title: {
		color: theme.palette.primary.light,
	},
	titleBar: {
		background:
			"linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
	},
	dashed: {
		borderStyle: "dashed",
		color: "#cccccc",
		backgroundColor: "#eeeeee",
		// height: '50px'
	},
	mOne: {
		margin: theme.spacing(1),
	},
	mLeftOne: {
		marginLeft: theme.spacing(1),
	},
	mRightTwo: {
		marginRight: theme.spacing(2),
	},
	barColorPrimary: {
		backgroundColor: "#179917",
	},
	colorPrimary: {
		backgroundColor: "#cfcfcf",
	},
	root: {
		height: "10px",
		marginTop: "-4px !important",
	}
}));

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function FileUpload({
    dirType,
	dirName,
	onFinish,
	fileType,
	width,
	height,
	getFileName,
	getOrigionalFileName,
	iconOnly = false,
	isSecuredSignedUrl = false,
        allowZoomOut,
	scale
}: any) {
	const [finalfile, setFinalfile] = useState([]);
	const [validate] = useState(false);
	const [uploadingPercentage, setUploadingPercentage] = useState(0);
	const [fileUrl, setFileUrl] = useState();
	const [src, setSrc] = useState('');
	const [open, setOpen] = React.useState(false);
	const [ext, setExt] = useState('.png');
	const [showError, setShowError] = useState(false);

	const classes = useStyles();
	const awsActions = useActions(Aws);

	const onSelectFile = (files:any) => {
		if (!files || files.length === 0) {
			return false;
		}

		const reader : any = new FileReader();
		reader.addEventListener('load', () => {
			setSrc(  reader.result );
			handleClickOpen();
		})
		let fileExt = '.png';
		let splitArray = files[0].name.split('.');
		fileExt = splitArray[splitArray.length - 1];
		setExt('.' + fileExt);
		reader.readAsDataURL(files[0]);
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: fileType || "image/*",
		onDrop: (acceptedFiles:any) => {
			onSelectFile(acceptedFiles);
		}
	});

	const uploadImage = async() => {
		if(showError){
			return false;
		}
		setUploadingPercentage(5);
		await handleSendToS3(finalfile);
		return handleClose();
	}

	interface awsResp extends Response {
		req?: {
			url: string
		}
	}
	const handleSendToS3 = async (file: any) => {
		let fileName = getRandomString() + ext;
		let onlyExt = ext.split('.');
		const queryObj = {
			objectName: isSecuredSignedUrl ? getRandomString() + '.csv' : fileName,
			contentType: 'image/'+ onlyExt[1],
			dirName : dirName,
            dirType : dirType
		};
		const { payload: { signedUrl, fileKey, filename } } = await awsActions.SignedUrlGet(queryObj, isSecuredSignedUrl);
		api
			.put(signedUrl)
			.send(file)
			.on("progress", (e) => {
				setUploadingPercentage(Number(e.percent));
			})
			.then((res: awsResp) => {
				const parseUrl = parseEmptyString(res.req && res.req.url);

				const parsedUrl = url.parse(parseUrl, true);
				const fileUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.pathname}`;
				setFileUrl(fileUrl);
				onFinish(fileUrl, fileKey);
				if (getFileName) {
					getFileName(dirName + "/" + fileName);
				}
				if (getOrigionalFileName) {
					getOrigionalFileName(filename)
				}
			})
			.catch(console.error);
	};

	const uploadingBar = () => (
		<LinearProgress
			classes={{
				colorPrimary: classes.colorPrimary,
				barColorPrimary: classes.barColorPrimary,
				root: classes.root,
			}}
			variant="determinate"
			value={uploadingPercentage}
		/>
	);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	}

	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12} sm={12} md={12} {...getRootProps()}>
					<div className={iconOnly ? 'editIcon' : "fileUpload mt5"}>
						{
							(!iconOnly && fileUrl && !validate) && ((!width && !height) && <Edit className="floatLeft" />)
						}
						<input {...getInputProps()} />
						<div className="linearProgress">
							<span className="linearProgressInput">
								{
									uploadingPercentage > 0 && uploadingPercentage < 100 &&
									uploadingBar()
								}
							</span>

						</div>
						<div className="textCenter">
						{
							iconOnly
								? <Tooltip title="Edit" className="floatRight mr5">
									<Edit className={classes.mRightTwo} />
								</Tooltip>
								: <React.Fragment>
									<div className="floatLeft">
										<CloudUpload className="floatLeft" />
										<Typography align="left" className="floatLeft te">Drag and Drop File<br /> Here to Upload</Typography>
									</div>
									<div className="floatRight">
										<Button
											disabled
											className="btnPrimary"
											type="submit"
											variant="contained"
											color="primary"
										>
											Browse Files
										</Button>
									</div>
									{
										validate &&
										<strong className="w100 floatLeft" style={{ color: 'red' }}>Please check Width or Height of File</strong>
									}
								</React.Fragment>
						}
					</div>
					</div>
				</Grid>
				<Dialog fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
					<DialogTitle id="customized-dialog-title" onClose={handleClose}>
						Crop Image
					</DialogTitle>
					<DialogContent dividers>
						<CropImage iconOnly={iconOnly}  src={src} ext={ext} width={width} height={height} uploadImage={(file:any)=>{ setFinalfile(file) }} showError={ (state:boolean)=> {setShowError(state)} } allowZoomOut={allowZoomOut} scale={scale} />
						{!iconOnly &&
						<p className="floatLeft w100 mt0 mb0 f12">Specs: file must be width:{width}px Height: {height}px, as jpg, jpeg, png format</p>
						}
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={uploadImage} color="primary">
							Upload Image
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</React.Fragment>
	);
}