import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import pdfIcon from "../../../styles/images/pdfIcon.png";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useActions } from "../../../actions";
import * as PaymentActions from "../../../actions/payment"


const PaymentFileViewer = (props) => {
    let {
        title,
        fileName,
        policyNumber,
        readOnly = true,
        deleteFile
    } = props;
    const {getViewSignedUrl} = useActions(PaymentActions);
    const [secureFilePath, setSecureFilePath] = React.useState("");
    const [fileExt, setFileExt] = React.useState("");
    const [popUpImage, setPopUpImage] = React.useState(false);
    useEffect(() => {
        if (fileName) {
            getSignedAndSecuredURL(fileName, policyNumber);
        }
    }, [fileName]);

    const getSignedAndSecuredURL = async (fileName, policyNumber) => {
        const file = fileName.split(".");
        const fileType = file[file.length - 1].toUpperCase();
        const payload = {
            fileName: fileName,
            policyNumber: policyNumber,
        };
        const response: any = await getViewSignedUrl(payload);
        const signedUrl = atob(response.preSignedURL);
        setSecureFilePath(signedUrl);
        setFileExt(fileType);
    };


    return (
        <React.Fragment>

            <div className="imgWrap uploadVoided">
                {fileExt == "PDF" ?
                    <img alt="Payment Document"
                         style={{ minWidth:"100%",maxWidth: 250, maxHeight: 200 }}
                         className="img-responsive floatLeft"
                         src={pdfIcon}
                         title="Payment Document"/>
                    :
                    <img alt="Payment Document"
                         style={{ minWidth:"100%", maxWidth: 250, maxHeight: 200 }}
                         className="img-responsive floatLeft"
                         src={secureFilePath}
                         title="Payment Document"/>
                }
                {!readOnly &&
                <DeleteIcon
                    className="cursor floatRight"
                    color="error"
                    onClick={deleteFile}
                    style={{position: "absolute", right: 10, top: 5}}
                /> }
                <div className="btnfix">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setPopUpImage(true);
                        }}
                    >
                        View
                    </Button>
                </div>
            </div>
            {popUpImage &&
                <PreviewFileDialog
                    fileExt={fileExt}
                    popUpImage={popUpImage}
                    title={title}
                    secureFilePath={secureFilePath}
                    onClose={() => {
                        setPopUpImage(false);
                    }}
                />
            }
        </React.Fragment>
    );
};

const PreviewFileDialog = ({ fileExt, popUpImage, title, secureFilePath, onClose }) => {
    return (
        <Dialog
            fullWidth={fileExt === "PDF"}
            maxWidth={fileExt === "PDF" ? "md" : "sm"}
            open={popUpImage}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Typography variant="h6" className="mt20 mb0 ml15"> {title} </Typography>
            <DialogContent>
                <div className={fileExt === "PDF" ? "" : "imgWrap"}>
                    {fileExt === "PDF" ?
                        <object data={`${secureFilePath}#toolbar=0`} type="application/pdf"
                                style={{ width: "100%", height: "80vh" }}>
                            <embed src={`${secureFilePath}#toolbar=0`} type="application/pdf"/>
                        </object>
                        :
                        <img width="100%" alt="Uploaded Document"
                             className="img-responsive"
                             src={secureFilePath}
                             title="Payment Document"
                        />}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    className="mb10 mr15"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PaymentFileViewer;