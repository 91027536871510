import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "20px",
        float:'left'
    },
    pd10:{
        padding:10
    } ,
    mb20:{
        marginBottom:20
    }
}));

export default function DocumentApprovalSnackBar(props: { allDocApproved: boolean }) {
    const classes = useStyles();
    const { allDocApproved } = props;

    return (
            <div className={classes.root}>
                {
                    allDocApproved ?
                            <Alert severity="success" variant="outlined" className={classes.pd10 + " " + classes.mb20}>All Documents are approved.</Alert> :
                            <Alert severity="error" variant="outlined" className={classes.pd10 + " " + classes.mb20}>Documents need approval.</Alert>
                }
            </div>
    );
}
