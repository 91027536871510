import React from "react";
import {
    Grid,
    IconButton,
    InputAdornment, Link as MuiLink, Popover,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableRow,
    TextField, Tooltip, Typography, Paper,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EnhancedTableHead from "../table/EnhancedTableHead";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { AccountStatusArr } from "../../model";
import HowToRegOutlinedIcon from "@material-ui/icons/HowToRegOutlined";
import BlockIcon from "@material-ui/icons/Block";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Pagination } from "../table";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useActions } from "../../actions";
import * as CompanyActions from "../../actions/company";
import * as AccountActions from "../../actions/account";
import useQueryString from "../../hooks/useQueryString";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { AccountSortList } from "../../model/account";
import { DisableParticipantNoteModal } from "./index";
import TableWrap from "../TableWrap/TableWrap";
import {
    getEmployerAndParticipantCont
} from "../../pages/client/Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        width: "100%",
        minWidth: 260,
        display: "inline-block",
    },
    root: {
        width: "100%",
        marginTop: "0",
        overflow: "auto",
        borderRadius: 6,
    },
    table: {
        minWidth: 700,
        overflowX: "auto",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
    list: {
        width: 500,
    },
}));

export default function ParticipantList(props: any) {
    const { detail, companyStats } = props;

    const companyActions = useActions(CompanyActions),
            accountActions = useActions(AccountActions),
            [page, setPage] = useQueryString("page", 0),
            [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", 25),
            [search, setSearch] = useQueryString("search", ""),
            [orderBy, setOrderBy] = useQueryString("orderBy", "createdAt"),
            [order, setOrder] = useQueryString("order", "desc"),
            [openDialog, setOpenDialog] = React.useState(false),
            [disableParticipant, setDisableParticipant] = React.useState(false),
            [participant, setParticipant] = React.useState<any>(undefined);


    const loginUser: any = useSelector((state: RootState) => state.auth.user);

    const getCompanyId = (user: any): number => {
        if ((user && user.role) === 8) {
            return user.company.id;
        } else if ((user && user.role) === 16) {
            return user.groupSigners.company.id;
        } else {
            return detail && detail.company && detail.company.id;
        }
    };

    const getCompanyName = (user: any): number => {
        if ((user && user.role) === 8) {
            return user.company.companyName;
        } else if ((user && user.role) === 16) {
            return user.groupSigners.company.companyName;
        } else {
            return detail && detail.company && detail.company.companyName;
        }
    };

    const [accountList, setAccountList] = React.useState<any>({ "count": 0, "rows": [] });
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any, participant: any) => {
        setAnchorEl(event.currentTarget);
        setParticipant(participant);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleActivateDeactivateParticipant(val: any) {
        if (val) {
            if (val.status !== -2) {
                setDisableParticipant(true);
                setOpenDialog(true);
            } else {
                await companyActions.updateGroupParticipant(val.id, undefined);
                setAnchorEl(null);
                companylist();
            }
        }
    }

    function handleDeleteOption() {
        setDisableParticipant(false);
        setOpenDialog(true);
    }

    async function deleteAccount(note: string) {
        setAnchorEl(null);
        const companyId = getCompanyId(loginUser);
        if (participant && participant.user) {
            await accountActions.deleteAccount(participant.userId, "USER", note);
        } else {
            await accountActions.deleteAccount(participant.id, "PARTICIPANT", note);
        }
        setOpenDialog(false);
        companylist();
        companyStats(companyId);
    }

    async function handleParticipantNote(participant: any, note: string) {
        if (participant) {
            setOpenDialog(false);
            await companyActions.updateGroupParticipant(participant.id, note);
            setAnchorEl(null);
            companylist();
        }
    }

    async function companylist() {
        const companyId = getCompanyId(loginUser);
        if (companyId) {
            let queryObj: any = {
                page: page,
                rowsPerPage: rowsPerPage,
                searchText: search,
                sortBy: orderBy,
                order,
                companyId: companyId,
            };
            const clientList = await companyActions.companyClientList(queryObj);
            setAccountList(clientList);
        }
    }


    React.useEffect(() => {
        companylist();
    }, [page, rowsPerPage, search, props.match.params, orderBy, order, props.history.location.pathname]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    const open = Boolean(anchorEl);

    let companyName = getCompanyName(loginUser);

    const isEmployerContributionNotVisible = detail && detail.company && detail.company.contributionType && detail.company.contributionType === "PARTICIPANT_ONLY";
    const isParticipantContributionNotVisible = detail && detail.company && detail.company.contributionType && detail.company.contributionType && detail.company.contributionType === "EMPLOYER_ONLY";
    const isContributionVisible = account => !(
            detail && detail.company && detail.company.strategies && detail.company.strategies.slug === "cashstream" &&
            (account.underWritingOfferType === "gi" || account.underWritingOfferType === "consent")
    );

    let disableButtonEnabled: boolean = false;
    if (participant && participant.status !== -2) {
        disableButtonEnabled = true;
        if (participant.user && participant.user.inviteStatus > 2) {
            disableButtonEnabled = false;
        }
    }

    return (
            <>
                {
                        openDialog &&
                        <DisableParticipantNoteModal
                                isDisableParticipant={disableParticipant}
                                onClose={() => setOpenDialog(false)}
                                onSave={(values: any) => {
                                    if (disableParticipant) {
                                        handleParticipantNote(participant, values.note);
                                    } else {
                                        deleteAccount(values.note);
                                    }
                                }}
                        />
                }
                {loginUser && (loginUser.role === 8 || loginUser.role === 16) &&
                        <Grid item xs={12} md={8} xl={9}>
                            <Typography className="mb15" variant="h3" align="left">
                                Participant Management
                            </Typography>
                            <Typography variant="body1" align="left">
                                Your participant list for viewing and managing your group members.
                            </Typography>
                        </Grid>
                }
                {loginUser && (loginUser.role === 1 || loginUser.role === 2) &&
                        <Grid item xs={12} md={8} xl={9}>
                            <Typography variant="body1" align="left">
                                Below are the group member participants for {companyName}.
                            </Typography>
                        </Grid>
                }
                <Grid item xs={12} md={4} xl={3}>
                    <TextField
                            className="mt15"
                            value={search}
                            variant="outlined"
                            placeholder={"Search Participants"}
                            label={"Search Participants"}
                            onChange={e => {
                                setPage(0);
                                setSearch(e.target.value);
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                    className="iconBtn"
                                                    aria-label="search client"
                                            >
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                ),
                            }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableWrap>
                        <ParticipantTable
                                accountList={accountList}
                                order={order}
                                orderBy={orderBy}
                                handleRequestSort={handleRequestSort}
                                isContributionVisible={isContributionVisible}
                                isEmployerContributionNotVisible={isEmployerContributionNotVisible}
                                isParticipantContributionNotVisible={isParticipantContributionNotVisible}
                                page={page}
                                rowsPerPage={rowsPerPage}
                        >
                            {
                                ({ account }) =>
                                        <TableCell padding="default" align="right">
                                            <>
                                                {
                                                    account.status !== -2 ?
                                                            <MuiLink
                                                                    aria-describedby={"popover"}
                                                                    color="primary"
                                                                    onClick={(event: any) => handleClick(event, account)}>
                                                                Active
                                                            </MuiLink> :
                                                            <Tooltip title={account.note || ""}
                                                                     placement="top-start">
                                                                <MuiLink
                                                                        aria-describedby={"popover"}
                                                                        className="colorRed"
                                                                        onClick={(event: any) => handleClick(event, account)}>
                                                                    Disabled
                                                                </MuiLink>
                                                            </Tooltip>
                                                }
                                                {(participant && (loginUser.role === 1 || loginUser.role === 2 || loginUser.role === 8)) &&
                                                        <Popover
                                                                open={open}
                                                                id={"popover"}
                                                                anchorEl={anchorEl}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                    vertical: "bottom",
                                                                    horizontal: "center",
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: "top",
                                                                    horizontal: "center",
                                                                }}
                                                        >
                                                            <div className="tablePopover" style={{
                                                                padding: 15,
                                                                float: "left",
                                                                width: "100%",
                                                            }}>
                                                                {participant && participant.status === -2 &&
                                                                        <MuiLink variant="caption"
                                                                                 className="floatLeft w100 textBlack"
                                                                                 onClick={() => handleActivateDeactivateParticipant(participant)}
                                                                        >
                                                                            <HowToRegOutlinedIcon
                                                                                    className="floatLeft mr10"/>Activate</MuiLink>
                                                                }
                                                                {
                                                                        disableButtonEnabled &&
                                                                        <MuiLink variant="caption"
                                                                                 className="floatLeft w100 textBlack"
                                                                                 onClick={() => handleActivateDeactivateParticipant(participant)}
                                                                        >
                                                                            <BlockIcon className="floatLeft mr10"/>
                                                                            Disable
                                                                        </MuiLink>
                                                                }
                                                                {loginUser && (loginUser.role === 1 || loginUser.role === 2 || loginUser.role === 8) &&
                                                                        <MuiLink variant="caption"
                                                                                 onClick={handleDeleteOption}
                                                                                 className="floatLeft w100 mt15 textBlack">
                                                                            <DeleteOutlineIcon
                                                                                    className="floatLeft colorRed mr10"/>Delete</MuiLink>}
                                                            </div>
                                                        </Popover>
                                                }
                                            </>
                                        </TableCell>
                            }
                        </ParticipantTable>
                    </TableWrap>
                    <Pagination page={Number(page)} rowsPerPage={Number(rowsPerPage)}
                                setPage={setPage}
                                setRowsPerPage={setRowsPerPage}
                                accountList={accountList}/>
                </Grid>
            </>
    );
}

const DefaultGetId = ({ account }) =>
        account.user ?
                <Link to={`/new-client/detail/${account.user && account.user.client && account.user.client.id}`}>
                    {`${account.firstName} ${account.lastName}`}
                </Link> :
                <>{`${account.firstName} ${account.lastName}`}</>;


const defaultGetOfferType = account => account.underWritingOfferType;

export const ParticipantTable = ({
                                     accountList, order, orderBy, handleRequestSort,
                                     isContributionVisible,
                                     isEmployerContributionNotVisible = false,
                                     isParticipantContributionNotVisible = false,
                                     AccountName = DefaultGetId,
                                     getOfferType = defaultGetOfferType,
                                     page,
                                     rowsPerPage,
                                     children,
                                 }) => {
    let headRows: any[] = [
        { id: "sNo", label: "S.No" },
        { id: "firstName", label: "Client Name" },
        { id: "email", label: "Client Email" },
        { id: "inviteStatus", label: "Status" },
        { id: "underWritingOfferType", label: "Offer Type" },
        { id: "combinedContribution", label: "Combined Contribution" },
        { id: "contributionAmount", label: "Participant Contribution" },
        { id: "employerContribution", label: "Employer Contribution" },
        { id: "deathBenefit", label: "Death Benefit" },
        { id: "annualPremium", label: "Premium" },
        { id: "trancheNumber", label: "Current Tranche" },
    ];

    if (
            accountList.rows && accountList.rows.length > 0 && !isContributionVisible(accountList.rows[0])
    ) {
        headRows = [
            { id: "sNo", label: "S.No" },
            { id: "firstName", label: "Client Name" },
            { id: "email", label: "Client Email" },
            { id: "inviteStatus", label: "Status" },
            { id: "underWritingOfferType", label: "Offer Type" },
            { id: "deathBenefit", label: "Death Benefit" },
            { id: "annualPremium", label: "Premium" },
            { id: "trancheNumber", label: "Current Tranche" },
        ];
    } else if (isEmployerContributionNotVisible) {
        headRows = [
            { id: "sNo", label: "S.No" },
            { id: "firstName", label: "Client Name" },
            { id: "email", label: "Client Email" },
            { id: "inviteStatus", label: "Status" },
            { id: "underWritingOfferType", label: "Offer Type" },
            { id: "contributionAmount", label: "Participant Contribution" },
            { id: "deathBenefit", label: "Death Benefit" },
            { id: "annualPremium", label: "Premium" },
            { id: "trancheNumber", label: "Current Tranche" },
        ];
    } else if (isParticipantContributionNotVisible) {
        headRows = [
            { id: "sNo", label: "S.No" },
            { id: "firstName", label: "Client Name" },
            { id: "email", label: "Client Email" },
            { id: "inviteStatus", label: "Status" },
            { id: "underWritingOfferType", label: "Offer Type" },
            { id: "employerContribution", label: "Combine Contribution" },
            { id: "employerContribution", label: "Employer Contribution" },
            { id: "deathBenefit", label: "Death Benefit" },
            { id: "annualPremium", label: "Premium" },
            { id: "trancheNumber", label: "Current Tranche" },
        ];
    }
    let sNo = page * rowsPerPage;

    return (
            <>
                <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headRows={headRows}
                        //includeAction={loginUser.role === 8 ? true:false}
                />
                <TableBody>
                    {
                            (!accountList.rows || accountList.count === 0)
                            && <TableRow>
                                <TableCell align="left" colSpan={8}>
                                    <Typography align="left">
                                        No Participant Found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                    }
                    {
                            accountList.rows && accountList.rows.map((account: any, idx: number) => {
                                const user: any = account.user;
                                let deathBenefit: string = user ? numeral(user && user.client && user.client.deathBenefit).format("$0,0") : numeral(account.deathBenefit).format("$0,0");
                                let annualPremium: string = user ? numeral(user && user.client && user.client.annualPremium).format("$0,0") : "N/A";
                                let contributionValue = user && user.client && user.client.contributionAmount;
                                let contributionAmount: string = user ? numeral(contributionValue).format("$0,0") : "N/A";
                                let contributionBreakUp: any = getEmployerAndParticipantCont(contributionValue, 50 , 15000);
                                let employerContribution: string = user ? numeral(contributionBreakUp && contributionBreakUp.employer).format("$0,0") : "N/A";
                                let participantContribution: string = user ? numeral(contributionBreakUp && contributionBreakUp.participant).format("$0,0") : "N/A";
                                let trancheNumber: string = (user && user.client && user.client.tranche) ? user.client.tranche.trancheNumber : "N/A";

                                return (
                                        <TableRow hover key={idx}>
                                            <TableCell padding="default">{++sNo}</TableCell>
                                            <TableCell padding="default">
                                                <AccountName account={account}></AccountName>
                                            </TableCell>
                                            <TableCell padding="default">{account.email}</TableCell>
                                            <TableCell
                                                    padding="default">{user ? AccountStatusArr[user.inviteStatus || 0] : "Pending"}</TableCell>
                                            <TableCell
                                                    padding="default">{getOfferType(account)}</TableCell>
                                            {isContributionVisible(account) &&
                                                    <React.Fragment>
                                                        {isParticipantContributionNotVisible ?
                                                                <></> :
                                                                <TableCell
                                                                        padding="default">{contributionAmount}</TableCell>
                                                        }
                                                        {isEmployerContributionNotVisible ?
                                                                <></> :
                                                                <>
                                                                    <TableCell padding="default">{participantContribution}</TableCell>
                                                                    <TableCell padding="default">{employerContribution}</TableCell>
                                                                </>
                                                        }
                                                    </React.Fragment>
                                            }
                                            <TableCell padding="default">{deathBenefit}</TableCell>
                                            <TableCell padding="default">{annualPremium}</TableCell>
                                            <TableCell padding="default">{trancheNumber}</TableCell>

                                            {children({ account })}
                                        </TableRow>
                                );
                            })
                    }
                </TableBody>
            </>
    );
};