import React  from "react";
import {Grid, Typography} from "@material-ui/core";
import MedicalPageCheckBox from "./MedicalPageCheckBox";
import MedicalPageRadioButton from "./MedicalPageRadioButton";
import {Label} from "../../../../../../../components/formInputs";

function FieldOptions(props:any) {
    const {
        clientSigned,
        fieldOptions,
        mainIndex,
        offerType,
        sendSaveRequest,
        showMedicalDetail,
        type,
    } = props;
    switch (type) {
        case "RADIOGROUP":
            return (
                    <MedicalPageRadioButton
                            clientSigned={clientSigned}
                            fieldOptions={fieldOptions}
                            mainIndex={mainIndex}
                            sendSaveRequest={sendSaveRequest}
                    />
            );
            break;
        case "CHECKBOXGROUP":
            return (
                    <MedicalPageCheckBox
                            clientSigned={clientSigned}
                            fieldOptions={fieldOptions}
                            mainIndex={mainIndex}
                            offerType={offerType}
                            showMedicalDetail={showMedicalDetail}
                            sendSaveRequest={sendSaveRequest}
                    />
            );
            break;
    }
    return <></>
}

export default function RenderMedicalQuestions(props: any) {
    const {
        question: { label, labelText, labelHeading, additionalFieldOptions, type, showMedicalDetail },
        index,
        clientSigned,
        offerType,
        sendSaveRequest,
    } = props;
    return (
            <Grid item xs={12} sm={12} md={12}>
                {
                        !!labelHeading &&
                        <Grid item xs={12} md={12}>
                            <Typography variant="body1" component="strong" className="floatLeft mt10 mb10 ">
                                <Label label={labelHeading} required={true} />
                            </Typography>
                        </Grid>
                }
                {
                        !!label &&
                        <Grid item xs={12} md={12}>
                            <Typography variant="body2" component="strong" className="floatLeft mt20 mb10">
                                <Label className="floatLeft W100" label={label} required={true} />
                            </Typography>
                        </Grid>
                }
                {
                        !!labelText &&
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography variant="body2" component="strong" className="floatLeft mt20 mb10 w100">
                                    <Label label={labelText} required={true} />
                                </Typography>
                            </Grid>
                        </React.Fragment>

                }
                {
                    //additionalFieldOptions && additionalFieldOptions.length > 0 &&
                    <FieldOptions
                            question={props.question}
                            fieldOptions={additionalFieldOptions}
                            type={type}
                            mainIndex={index}
                            clientSigned={clientSigned}
                            showMedicalDetail={showMedicalDetail}
                            offerType={offerType}
                            sendSaveRequest={sendSaveRequest}
                    />
                }
            </Grid>
    )
}