import { Action, ActionType } from "../model";
import createReducer from "./createReducer";
import { EstimationResponse } from "../model/estimation";

export const estimation = createReducer([], {
	[ActionType.GET_ESTIMATION](state: EstimationResponse, action: Action<EstimationResponse>) {
		return action.payload;
	},
});

export const clientDetailsWhileEstimate = createReducer([],{ // get Client detail filled on Estimation form during client invite
    [ActionType.ADD_INVITED_CLIENT_DETAIL](state: any, action: {type: string, payload: any}) {
        return action.payload;
    },
})

export const stressUUID = createReducer("",{
    [ActionType.ADD_STRESS_UUID](state: any, action: {type: string, payload: any}) {
        return action.payload;
    },
})

export const reportUUID = createReducer("",{
    [ActionType.ADD_REPORT_UUID](state: any, action: {type: string, payload: any}) {
        return action.payload;
    },
})

export const executionArn = createReducer("",{
    [ActionType.EXECUTION_ARN](state: any, action: {type: string, payload: any}) {
        return action.payload;
    },
})

export const minimumContribution = createReducer(null,{
    [ActionType.ADD_MINIMUM_CONTRIBUTION](state: any, action: {type: string, payload: any}) {
        return action.payload;
    },
})

export const quickEstimation = createReducer(null,{
    [ActionType.QUICK_ESTIMATION](state: any, action: {type: string, payload: any}) {
        return action.payload;
    },
})
