import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import { NumberTextField} from "../../../../../../../components/formikFormInputs";
import * as Yup from "yup";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import LiabilitiesForm from "./LiabilitiesForm";

const Liabilities = (props: any) => {
	const {
		autoSaveTrigger,
		financialAndLiabilitiesArr,
		formType,
		isFromClientDetail,
		liabilitiesRef,
		setFinancialAndLiabilitiesArr,
		setFormType,
		setLiabilitiesVerified,
		setLiabilitiesVerifyRef,
		setSaveFormContent,
		user,
	} = props;
	const clientSigned = user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? 1 : 0;
	const { updateClientFields } = useActions(AccountActions);
	const [isConfirmCheckbox, setIsConfirmCheckbox] = React.useState(user && user.client && user.client.verifyLiabilities ? true : false);

	const liabilitiesVerifyRef = React.useRef<any>();

	useEffect(() => {
		if (liabilitiesVerifyRef.current && typeof setLiabilitiesVerifyRef === "function") {
			setLiabilitiesVerifyRef(liabilitiesVerifyRef);
		}
	}, [liabilitiesVerifyRef.current]);

	useEffect(() => {
		setLiabilitiesVerified(isConfirmCheckbox);
	}, [isConfirmCheckbox]);

	const fieldObj = [
		{
			id: "unpaidInterestTaxes",
			name: "unpaidInterestTaxes",
			helperFieldText: "",
			label: <Label label={"Unpaid Interest & Taxes"} required={false} />,
			isRequired: true,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned,
		},
		{
			id: "mortgages",
			name: "mortgages",
			helperFieldText: "",
			label: <Label label={"Mortgages"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "creditCardDebt",
			name: "creditCardDebt",
			helperFieldText: "",
			label: <Label label={"Credit Card Debt"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "loansOnLifeInsurance",
			name: "loansOnLifeInsurance",
			helperFieldText: "",
			label: <Label label={"Secured Loans"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "personalNotes",
			name: "personalNotes",
			helperFieldText: "",
			label: <Label label={"Personal Notes"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		/*{
			id: "accountsPayable",
			name: "accountsPayable",
			helperFieldText: "",
			label: <Label label={"Accounts Payable"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},*/
		{
			id: "otherLongTermDebt",
			name: "otherLongTermDebt",
			helperFieldText: "",
			label: <Label label={"Other Long Term Debt"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "otherLiabilities",
			name: "otherLiabilities",
			helperFieldText: "",
			label: <Label label={"Other Liabilities (Provide Details)"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		/*{
			id: "otherLiabilitiesDetails",
			name: "otherLiabilitiesDetails",
			helperFieldText: "",
			label: <Label label={"Other Liabilities Details"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: TextField,
			variant: "outlined",
			size: {
				md: 12,
				xl: 12,
			},
			disabled: !!clientSigned
		},*/
	];

	/*Set initial values*/
	const initialValues = {};
	/* Validations of fields set */
	let keysValidation = {};

	fieldObj.forEach((field) => {
		initialValues[field.name] = user && user.client && user.client[field.name] || "";
	});

	const validationSchema = Yup.object().shape(keysValidation);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<LiabilitiesForm
					autoSaveTrigger={autoSaveTrigger}
					initialValues={initialValues}
					validationSchema={validationSchema}
					updateClientFields={updateClientFields}
					isConfirmCheckbox={isConfirmCheckbox}
					user={user}
					isFromClientDetail={isFromClientDetail}
					fieldObj={fieldObj}
					setIsConfirmCheckbox={setIsConfirmCheckbox}
					liabilitiesVerifyRef={liabilitiesVerifyRef}
					financialAndLiabilitiesArr={financialAndLiabilitiesArr}
					setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
					liabilitiesRef={liabilitiesRef}
					setSaveFormContent={setSaveFormContent}
					formType={formType}
					setFormType={setFormType}
				/>
			</Grid>
		</Grid>
	);
};

export default Liabilities;