import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import { NumberTextField, TextField } from "../../../../../../components/formikFormInputs";
import { unmaskCurrency } from "../../../../../../utils/commonUtils";

type IndividualIncomeProp = {
	conditionalRequired: boolean,
	CommonField: Function,
	handleChange: Function,
	handleBlur: Function,
	setFieldValue: Function,
	touched: any,
	errors: any,
	values: any
}
type AllFieldsProps = {
	lastYearArr: any,
	CommonField: Function,
	values: any,
	errors: any,
	touched: any,
	handleChange: Function,
	handleBlur: Function,
	setFieldValue: Function,
	yearsArr: any,
	prefixText: string
}
export const AllFields = (props: AllFieldsProps) => {
	const {
		lastYearArr,
		CommonField,
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		setFieldValue,
		yearsArr,
		prefixText,
	} = props;

	return (<>
        {
            yearsArr.map((item, index) => {
                return (
                        <Grid container spacing={2} key={index}>
                            <Typography variant="caption" className="w100 mb15 floatLeft ml15">{item.label}</Typography>
                            {lastYearArr.map((field, index) => {
                                return (
                                        <Grid item xs={12} md={6} key={`indIncome${item.name}${field.name}`}>
                                            <div className="mb15">
                                                {
                                                    CommonField(
                                                            {
                                                                ...field,
                                                                name: `${prefixText}${item.name}${field.name}`,
                                                                id: `${prefixText}${item.name}${field.name}`,
                                                            },
                                                            {
                                                                values,
                                                                errors,
                                                                touched,
                                                                handleChange,
                                                                handleBlur,
                                                                setFieldValue,
                                                            },
                                                    )
                                                }
                                            </div>
                                        </Grid>
                                );
                            })}
                        </Grid>
                );
            })

        }
	</>);
};
export const allFieldsArr = (conditionalRequired) => {
    return [
        {
            id: "AnnualSalary",
            name: "AnnualSalary",
            label: <Label label={"Annual Earned Income"} required={true} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            className: "dollerIcon",
            dollarSign: true,
        },
        {
            id: "OtherIncome",
            name: "OtherIncome",
            label: <Label label={"Annual Unearned Income"} required={true} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            className: "dollerIcon",
            dollarSign: true,
        },
    ];
};

const indOtherIncomeDetail = (values) => {
   return [{
        name: "indOtherIncomeDetail",
        label: <Label label={"Please indicate source of unearned income"} required={unmaskCurrency(values['indIncomeLastYearOtherIncome'] )> 0} />,
        isRequired: values['indIncomeLastYearOtherIncome'] > 0,
        component: TextField,
        variant: "outlined",
        className: "TextFieldWidth",
    }];
};
function IndividualIncome(props: IndividualIncomeProp) {
	const {
		conditionalRequired,
		CommonField,
		handleChange,
		handleBlur,
		setFieldValue,
		values,
		touched,
		errors,
	} = props;
	const indYearsArr = [
		{ label: (<>Individual Income <strong>last year</strong></>), name: "LastYear" },
	];
	const lastYearArr = allFieldsArr(conditionalRequired);

	const individualNetWorth = [{
		id: "netWorth",
		name: "netWorth",
		label: <Label label={"Individual Net Worth"} required={true} />,
		isRequired: true,
		component: NumberTextField,
		variant: "outlined",
		className: "TextFieldWidth dollerIcon",
		dollarSign: true,
	}];


	return <>
		<Grid item xs={12}>
			<div className="hLine" />
		</Grid>
		<Grid item xs={12}>
			<Typography variant="h6" component="strong" className="w100">Individual Income</Typography>
		</Grid>
		<AllFields lastYearArr={lastYearArr} CommonField={CommonField} values={values}
				   errors={errors} handleChange={handleChange} handleBlur={handleBlur}
				   setFieldValue={setFieldValue} yearsArr={indYearsArr} touched={touched}
				   prefixText="indIncome"
		/>
            <Grid item xs={12} md={6} >
                {
                    indOtherIncomeDetail(values).map((field, index) => {
                        return (

                                <div key={index + field["name"]}>
                                    {
                                        CommonField(
                                                field,
                                                {
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setFieldValue,
                                                },
                                        )
                                    }
                                </div>

                        );
                    })
                }
        </Grid>
		<Grid item xs={12}>
			<Typography variant="h6" component="strong" className="mb0 w100 floatLeft">Individual Net
				Worth</Typography>
		</Grid>
		{
			individualNetWorth.map((field, index) => {
				return (
					<Grid item xs={12} md={6} xl={4} key={index + field["name"]}>
						<div>
							{
								CommonField(
									field,
									{
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										setFieldValue,
									},
								)
							}
						</div>
					</Grid>
				);
			})
		}
		<Grid item xs={12}>
			<div className="hLine" />
		</Grid>
	</>;
}

export default IndividualIncome;