import { isLongForm } from "../../pages/client/Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

const allianzQuestions = (params:{offerType:string}) => {
    const { offerType } = params;
    return [
    // {
	// 	key: "1",
	// 	name: "Proposed_Insured_Questions-1",
	// 	label: "Are you a US Citizen?",
	// 	type: "radio",
	// 	additionalFieldName:"",
	// 	default: true,
	// 	onYes: false,
	// },
	{
		key: "2",
		name: "Proposed_Insured_Questions-2",
		label: "Will this insurance replace any existing annuities and/or life insurance?",
		type: "radio",
		default: false,
		onYes: false,
        show: !(isLongForm(offerType))
	},
	// {
	// 	key: "55",
	// 	name: "Proposed_Insured_Questions-55",
	// 	label: "Are you self employed?",
	// 	type: "radio",
	// 	default: false,
	// 	onYes: false,
	// 	additionalRemarks: true,
	// 	showRemarks: false,
	// 	additionalFieldName: "Annual_Income_Years_Prior",
	// 	additionalFieldType: "multiIncome",
	// 	additionalFieldRequired: true,
	// 	additionalFieldMinValue :  true,
	// 	additionalFields: ['Annual_Income_Years_Prior-1', 'Annual_Income_Years_Prior-2', 'Annual_Income_Years_Prior-3'],
	// 	additionalFieldLabel: "Annual Income :count years prior"
	// },
    {
        key: "15",
        name: "Proposed_Insured_Questions-15",
        label: "Have you ever received medical advice or has treatment been recommended or received by a licensed member of the medical profession for any cancer, tumor, cyst, or other abnormal growth or lump?",
        type: "radio",
        default: false,
        onYes: false,
        showRemarks: false,
        showMedicalDetail: true,
        resetFields:[{
            name:'Proposed_Insured_Questions-15XT_0',
            value: ""
        }, {
            name:'Proposed_Insured_Questions-15XT_1',
            value:""
        }],
        show: isLongForm(offerType)
    },
    {
        key: "40",
        name: "Proposed_Insured_Questions-40",
        label: "Have you ever tested positive for exposure to the HIV infection, HIV antibodies in a test taken for the purpose of obtaining insurance, or been diagnosed by a licensed member of the medical profession as having AIDS Related Complex (ARC) or Acquired Immune Deficiency Syndrome (AIDS) caused by the HIV infection or other sickness or condition derived from such infection?",
        type: "radio",
        default: false,
        onYes: true,
        showMedicalDetail: true,
        show: isLongForm(offerType),
        resetFields: [{
            name: "Proposed_Insured_Questions-40XT_0",
            value: ""
        }, {
            name: "Proposed_Insured_Questions-40XT_1",
            value: ""
        }]
    },
];
}

export default allianzQuestions;
