import React from "react";
import { useField } from "formik";
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import { ErrorCommon } from "../../../../../../components/formikFormInputs";

type RadioGroupCommonProps = {
    cb: Function,
    classes: any,
    conditionalRequired: boolean,
    disabled?: boolean,
    errors: any,
    fieldName: string,
    label: string,
    radioOptionArr: { label: string, value: string }[],
    sendSaveRequest?: Function,
    touched: any,
    values: any,
}

function RadioGroupCommon(props: RadioGroupCommonProps) {
    const {
        cb,
        classes,
        conditionalRequired,
        disabled,
        errors,
        fieldName,
        label,
        radioOptionArr,
        sendSaveRequest,
        touched,
        values,
    } = props;
    const [field] = useField(`${fieldName}`);
    const handleChange = (e: any) => {
        cb(e);
        if (typeof sendSaveRequest === "function") {
            sendSaveRequest(Math.random());
        }
    };
    const radioControl = (<Radio className="mb0" color="primary" />);
    return (
            <Grid item xs={12} md={12} xl={12}>
                <Typography variant="caption" className="floatLeft mb15">
                    <Label label={label} required={conditionalRequired} />
                </Typography>
                <RadioGroup
                        className="mb0 checkGroup checkGroupEnrollment"
                        {...field}
                        onChange={handleChange}
                        value={values[`${fieldName}`]}
                        style={{display: "inline"}}
                >
                    <Grid container spacing={2}>
                        {
                            radioOptionArr.map((option: any, idx: number) => (
                                    <Grid item xs={12} md={6} className={classes.radioGroup} key={idx}>
                                        <FormControlLabel
                                                className="mb0 RadioFontSize"
                                                disabled={disabled}
                                                value={option.value}
                                                control={radioControl}
                                                label={option.label}
                                        />
                                    </Grid>
                            ))
                        }
                    </Grid>
                </RadioGroup>
                {
                        touched[`${fieldName}`] && errors[`${fieldName}`] &&
                        <ErrorCommon
                                errors={errors}
                                name={`${fieldName}`}
                                touched={touched}
                        />
                }
            </Grid>
    )
}

export default RadioGroupCommon;