import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import {useActions} from "../../../../../actions";
import * as ClientActions from "../../../../../actions/client";
import {ErrorCommon, TextField, TextFieldCommon} from "../../../../../../src/components/formikFormInputs";
import {Formik} from "formik";
import {Button as FormikButton, Label} from "../../../../../../src/components/formInputs";

const styles: any = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "10px",
        top: "16px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle: any = withStyles(styles)((props: any) => {
    const {children, classes, onClose, ...other} = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="body1" component="strong">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function PaymentReceivedPopUp(props: {open: boolean, setProvideCorrectEmailPopUp: Function}) {
    const {open, setProvideCorrectEmailPopUp} = props;
    const clientActions = useActions(ClientActions);
    const handleClose = () => {
        setProvideCorrectEmailPopUp(false);
    };
    const initialValues = {
        email: "",
    };
    const validate = ((value: any) => {
        const errors: { email?: string } = {};
        if (!value.email) {
            errors.email = "Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
            errors.email = "Invalid email address";
        }
        return errors;
    });

    const fieldObj = [
        {
            id: "email",
            name: "email",
            label: <Label label={"Enter New Email"} required={true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            disabled: false,
        },
    ];
    return (
            <div>
                <Dialog fullWidth={true} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={open}>
                    <DialogTitle className="closeSvg" id="customized-dialog-title" onClose={handleClose}>
                        Enter Email
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1" className="f18">
                            Please provide us with your correct email.
                        </Typography>
                        <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validate={values => validate(values)}
                                validateOnChange={true}
                                onSubmit={async (values, {setSubmitting}) => {
                                    await clientActions.sendEmailUpdateRequest({email: values.email});
                                    setProvideCorrectEmailPopUp(false);
                                }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  setFieldValue,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty,
                              }) => (<form onSubmit={handleSubmit}>
                                <Grid container spacing={2}  className="mb20">
                                    {fieldObj.map((field: any, index) => {
                                        return (
                                                <Grid item xs={12}>
                                                    <TextFieldCommon
                                                            {...field}
                                                            values={values}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                    />
                                                    <ErrorCommon errors={errors} name={field.name}
                                                                 touched={touched}/>
                                                </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                                <FormikButton
                                        fullWidth={false}
                                        type="submit"
                                        label="Confirm"
                                        variant="contained"
                                        className="floatRight"
                                        color="primary"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                />
                            </form>)}

                        </Formik>
                    </DialogContent>
                </Dialog>
            </div>
    );
}
