import React from 'react';
import {CircularProgress} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

type Props = {
    loading: boolean;
};

const SearchProgress: React.FC<Props> = (props) => {
    return (props.loading ? <CircularProgress size={20} /> : <SearchIcon />);
};

export default SearchProgress;
