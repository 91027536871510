import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

type ButtonTypes = {
    buttonText?:string,
    onClick:Function
}
export default function Confirmation(props: { confirmDialog: boolean, content?:string , onCancel:ButtonTypes , onConfirm:ButtonTypes  }) {
    const { confirmDialog , onCancel , onConfirm , content } = props;
    const [uploadDoc, setUploadDoc] = React.useState(false);
    const handleClose = () => {
        onCancel.onClick()
    };

    return (
            <div>
                {
                    !uploadDoc &&
                    <Dialog
                            fullWidth={true}
                            maxWidth={"sm"}
                            open={confirmDialog}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="customized-dialog-title">{"Confirm Upload"}</DialogTitle>
                        <DialogContent dividers>
                            <div id="alert-dialog-description">
                                <Typography variant="body1" className="mb0">{ content ||'Are you sure you want to overwrite this document with a new upload?'}</Typography>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <DialogActions>
                                <Button
                                        className=" floatLeft"
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="secondary" 
                                        onClick={(e)=>{
                                            onCancel.onClick(e)
                                        }}>
                                    {onCancel.buttonText ||  "Cancel" }
                                </Button>
                                <Button
                                        className="bgBlue"
                                        type="button"
                                        size="small"
                                        variant="contained"
                                        disabled={false}
                                        onClick={(e)=>{
                                            onConfirm.onClick(e)
                                        }}
                                        color="primary" autoFocus>
                                   {onConfirm.buttonText || 'Yes I’m Sure'}
                                </Button>
                                <span className="clearFix"/>

                            </DialogActions>
                        </DialogActions>
                    </Dialog>
                }
                
            </div>
    );
}
