import * as React from "react";
import TextField from '@material-ui/core/TextField';
import { FormInputProps } from "../../model";
import NumberFormat from "react-number-format"

const NumberTextField = ({
      input,
      meta: { touched, invalid, error },
      ...custom
    }: FormInputProps) => (
      <NumberFormat
        thousandSeparator={true}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
        variant="outlined"
        customInput={TextField}
      />
    );

export default NumberTextField;


