import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "49%",
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
type Option = { text: string; value: string; };
type PropsType = {
    label: string,
    handleChange: Function,
    items: Array<string | Option>,
    value: string,
    name: string,
    clientSigned: boolean
}
const SelectDocTypeField = (props: PropsType) => {
    const { label, handleChange, items, name, value = "", clientSigned } = props;
    const classes = useStyles();

    return <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id={name}>{label}</InputLabel>
        <Select
                labelId={name}
                id={name}
                name={name}
                value={value}
                onChange={(e) => handleChange(e)}
                label={label}
                disabled={clientSigned}
        >
            {
                items.map((item, index) => {
                    const isString = typeof item === 'string';
                    const value = isString ? item as string : (item as Option).value;
                    const text = isString ? item as string : (item as Option).text;
                    return (
                            <MenuItem value={value} key={`${text}-${index}`}>
                                {text}
                            </MenuItem>
                    );
                })
            }
        </Select>
    </FormControl>;
};
export default SelectDocTypeField;