import React from "react";
import { useFormikContext } from "formik";
import { Grid, Typography } from "@material-ui/core";
import { EnrollmentQuestionProps } from "../../../../../../model/enrollment";
import EnrollmentField from "./EnrollmentField";
import { checkAllApplied } from "../Utilities";
import InsuredAviationSubQuestions from "./InsuredAviationSubQuestions";
import InsuredForeignTravelSubQuestions from "./InsuredForeignTravelSubQuestions";
import InsuredAvocationSubQuestions from "./InsuredAvocationSubQuestions";
import { FormikFieldArray } from "../../../../../../components/formikFormInputs";

type PropsType = {
    questionList: any,
    clientSigned: boolean,
    isFromClientDetail: boolean,
    setDisableSaveButton: Function,
    role: number,
    offerType: string,
    setFieldValue: Function,
    enableSaveButton: Function,
    touched: any,
    handleBlur: Function,
    handleChange: Function,
    setSaveAviationAvocationQuestions: Function,
    textFieldCommon: any
}
function AviationAvocationForeignTravelForm(props: PropsType) {
    const {
        questionList,
        clientSigned,
        isFromClientDetail,
        setDisableSaveButton,
        role,
        offerType,
        setFieldValue,
        enableSaveButton,
        touched,
        handleBlur,
        handleChange,
        setSaveAviationAvocationQuestions,
        textFieldCommon
    } = props;

    const {values, errors, dirty}: any = useFormikContext();
    React.useEffect(() => {
        let deBounceFn;
        if (dirty) {
            const errArray: any = Object.values(errors);
            const errKeyVal = errArray.find((err, key) => err.toLowerCase() !== "required field");
            if (!(!!errKeyVal)) {
                deBounceFn = setTimeout(() => {
                    if (typeof setSaveAviationAvocationQuestions === 'function') {
                        setSaveAviationAvocationQuestions(values)
                    }
                }, 1000);
                return () => clearTimeout(deBounceFn);
            }
        }
        return () => {
            if (deBounceFn) {
                clearTimeout(deBounceFn)
            }
        };
    }, [values, errors, dirty]);


    const insuredAviationSubQuestions = () => <InsuredAviationSubQuestions
        values={values} clientSigned={clientSigned}
        setFieldValue={setFieldValue}
        isFromClientDetail={isFromClientDetail}
        offerType={offerType}
        setSaveAviationAvocationQuestions={setSaveAviationAvocationQuestions}
        role={role}
        enableSaveButton={enableSaveButton}
        handleBlur={handleBlur}
        handleChange={handleChange} errors={errors}
        touched={touched}
    />

    const insuredForeignTravelSubQuestions = () => <InsuredForeignTravelSubQuestions
        values={values}
        isFromClientDetail={isFromClientDetail}
        clientSigned={clientSigned}
        errors={errors} touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        role={role}
        setSaveAviationAvocationQuestions={setSaveAviationAvocationQuestions}
        offerType={offerType}
    />

    const insuredAvocationSubQuestions = () => <InsuredAvocationSubQuestions
        values={values} clientSigned={clientSigned}
        setFieldValue={setFieldValue}
        enableSaveButton={enableSaveButton}
        textFieldCommon={textFieldCommon}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        isFromClientDetail={isFromClientDetail}
        role={role}
        setSaveAviationAvocationQuestions={setSaveAviationAvocationQuestions}
        offerType={offerType}
    />

    return <>
        <Grid item xs={12}>
            {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                <EnrollmentField
                    question={question}
                    clientSigned={clientSigned}
                    isFromClientDetail={isFromClientDetail}
                    checkIsError={() => {
                    }}
                    onChangeFn={(value, values, question) => {
                        if (question && question.name === "Proposed_Insured_Questions-32" && (value === "Yes" || value === "No")) {
                            setDisableSaveButton(false);
                        }
                    }}
                    role={role}
                    offerType={offerType}
                    setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                />
            ))}
        </Grid>
        {
            values["Proposed_Insured_Questions-32"] === "Yes" &&
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="subtitle1" component="strong" className="mb15 floatLeft w100">Check
                        all that
                        apply</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormikFieldArray
                                fieldName={"InsuredAviationAvocation"}
                                fieldCollection={checkAllApplied}
                                renderObjectData={{
                                    insuredAviationSubQuestions,
                                    insuredForeignTravelSubQuestions,
                                    insuredAvocationSubQuestions
                                }}
                                clientSigned={clientSigned}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }</>
}

export default AviationAvocationForeignTravelForm;