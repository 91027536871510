import React from "react";
import {Link as RouterLink} from 'react-router-dom';
import { Typography, Card, CardHeader, CardContent, List, Link, Tooltip, Button } from "@material-ui/core";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import clientSnapshot from "../../pages/client/ClientProfile/images/client_snapshot.svg";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import { asDbDateFormat } from "../../utils/dateUtils";
import {contributionType} from "../../constants";
import SetGroupExpiryDate from "./SetGroupExpiryDate";

export default function GroupSnapShot(props: any) {
    const { detail, totalInvites, totalAccountCreated, enrolling, enrolled, auth, getGroupDetails, companyId } = props;
    const accountActions = useActions(AccountActions);
    const [inviteLink, setInviteLink] = React.useState<string>('');
    let groupUrl: string = '';
    let linkExpiryDate:string|null = null;
    let isLinkExpired:boolean = true;

    if(detail && detail.company) {
        groupUrl = `${window.location.origin}/groupwm/${detail.company.companyLinkUrl}`;
        linkExpiryDate = detail && detail.company && detail.company.linkExpiry;
        if (detail.company.showMarketing) {
            groupUrl = `${window.location.origin}/group/${detail.company.companyLinkUrl}`;
        }
    }
    const getContributionTypeName = (conType: string) => {
        const conTypeDetail: any = contributionType.find((item: any) => item.value === conType);
        if (conTypeDetail) {
            return conTypeDetail.name;
        }
        return "";
    }
    function valHandler(groupUrl : string){
        setInviteLink(groupUrl);
        accountActions.copyInvitationLink("Group Link Has Been Copied to Your Clipboard")
    }

    var SelectedDate = moment(linkExpiryDate).format("MM/DD/YYYY");
    var date = moment(SelectedDate);
    const now = moment().format("MM/DD/YYYY")
    let days:number = date.diff(now, 'days');
    if(days > 0){
        isLinkExpired = false;
    }

    return (
            <Card className="customCard boxShadow w100 mb20">
                <CardHeader
                        className="pb0"
                        avatar={
                            <img src={clientSnapshot} alt="#" />
                        }
                        title={<React.Fragment>
                            <Typography
                                    component="span"
                                    variant="body1"
                            >
                                <strong>{(detail && detail.company) ? detail.company.companyName : 'N/A'} Snapshot</strong>
                            </Typography>
                        </React.Fragment>}
                />
                <CardContent className="floatLeft w100">
                    <List className="ClientDetailsList">
                        <li>
                            <label>Date Group Created</label>
                            <span className="labelValue">
                                {detail && detail.company ? asDbDateFormat(detail.company.createdAt) : 'NA'}
                            </span>
                        </li>
                        {/*<li>*/}
                        {/*    <label>Total Clients on Census</label>*/}
                        {/*    <span className="labelValue colorBlue">{totalInvites}</span>*/}
                        {/*</li>*/}
                        <li>
                            <label>Total Accounts Created</label> <span className="labelValue colorBlue">{totalAccountCreated}</span>
                        </li>
                        <li>
                            <label>Total Currently Enrolling</label> <span className="labelValue colorBlue">{enrolling}</span>
                        </li>
                        <li>
                            <label>Total Enrollments Completed</label> <span className="labelValue colorBlue">{enrolled}</span>
                        </li>
                        <li>
                            <label>Group Type</label> <span className="labelValue colorBlue">{(detail && detail.company && detail.company.groupType) || ''}</span>
                        </li>
                        <li>
                            <label>Contribution Type</label> <span
                                className="labelValue colorBlue">{detail && detail.company && detail.company.contributionType ? getContributionTypeName(detail.company.contributionType) : ""}</span>
                        </li>
                        <li>
                            <label>Group Agent</label>
                            {
                                detail && detail.company && detail.company.agent && detail.company.agent.user ?
                                        auth && auth.user && auth.user.role !== 4 ?
                                        <span className="labelValue colorBlue">
                                            <Link
                                                component={RouterLink}
                                                to={'/new-agent/detail/'+ detail.company.agent.user.id}
                                                >
                                                {detail.company.agent.user.firstName + " " + detail.company.agent.user.lastName}
                                            </Link>
                                        </span>:<span className="labelValue">{detail.company.agent.user.firstName + " " + detail.company.agent.user.lastName}</span>
                                : null
                            }
                        </li>
                        <li>
                            <label>Active Group Link</label>
                            <Tooltip placement="left-start" title={ (groupUrl === inviteLink)
                                    ?  <React.Fragment>Group Link Has Been <br/>Copied to Your Clipboard</React.Fragment>
                                    : "Copy Invite Link"}>
                                <CopyToClipboard text={groupUrl}
                                                 onCopy={()=>valHandler(groupUrl)}>
                                    <Link color="primary" style={{wordBreak:'break-word',float:'left',width:'100%'}}>{groupUrl}</Link>
                                </CopyToClipboard>
                            </Tooltip>
                        </li>
                        {
                        detail && detail.company && detail.company.notAuthenticated === 1 ?
                        <>
                        {
                            isLinkExpired &&
                            <Typography variant="caption" className="colorRed f12 w100 textCenter">Currently Expired
                            {
                                isLinkExpired &&
                                <SetGroupExpiryDate getGroupDetails={getGroupDetails} companyId={companyId} linkExpiryDate={linkExpiryDate} />
                            }
                            </Typography>
                        }
                        <li>
                            {linkExpiryDate && !isLinkExpired &&
                                    <Typography variant="caption" className="colorRed f12 w100 textCenter">This
                                        invitation expires
                                        on {moment(linkExpiryDate).format("MMMM DD, YYYY")}</Typography>}
                            {!isLinkExpired &&
                                    <SetGroupExpiryDate getGroupDetails={getGroupDetails} companyId={companyId}
                                                        linkExpiryDate={linkExpiryDate}/>}

                        </li>
                        </>:<></>}
                    </List>

                </CardContent>
            </Card>
    )
}