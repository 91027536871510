import {parse} from 'json2csv';
import moment from "moment";
import {downloadAsCSV, formatPhoneNumber} from "../utils/commonUtils";
import {ActionType, CsvField} from "../model";
import {Lead} from "../model/lead";
import {formatDate} from "./account";
const API_PATH_AAR = 'marketingemail';
const prefix = 'leads';

export const list = (payload: { actionType:string, searchText:string, page:number, rowsPerPage:number }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get(`${prefix}`, {}, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const leadStatsFromAdmin = (pageType = 'ALL', agentId) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const res = await api.get(`${prefix}/stats/${agentId}`, {},{pageType});
        return dispatch({ type: ActionType.LEADSTATS, payload: res });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const leadStats = (pageType = 'ALL') => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const res = await api.get(`${prefix}/stats`, {},{pageType});
        return dispatch({ type: ActionType.LEADSTATS, payload: res });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const addLead = (data: Lead) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.post(`${prefix}`, data, {});
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        return false;
    }
};

export const rejectLead = (payload: { id:number, reason:string, reasonType:string }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post(`${prefix}/reject`, payload);
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Lead Rejected Successfully.",
                statusCode: 200
            }
        });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const assignLead = (payload: { id:number, assignedTo:number }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post(`${prefix}/assign`, payload);
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Lead Assigned Successfully.",
                statusCode: 200
            }
        });
        return true;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getAgents = (leadId, payload: { searchText:string, page:number, rowsPerPage:number }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
       return api.get( `${prefix}/agents/${leadId}`, {}, payload );
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const checkDuplicateLead = (payload: { email:string }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
       return await api.get(`${prefix}/duplicate-check`, {}, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const estimateLeadResult = (payload: {
    strategyId : number,
    email: string,
    age: number
    gender: number,
    contribution: string,
    healthType: string,
    guid:string,
    agentId: number
}) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.post(`${prefix}/estimateresult`, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const deleteLead = (id: number) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const res = await api.delete(`${prefix}/deletelead/${id}`, {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Deleted Successfully.",
                statusCode: 200
            }
        });
        return  res;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const exportLeadsCSV = (id:string, statType:string, type:string="csv", pageType:string="ALL") => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const query = {id, pageType, statType, type};
        let leads = await api.get(`${prefix}/exportleads`, {}, query);
        if (type === "json") {
            return leads;
        } else {
            const fields: CsvField [] = [
                {
                    label: "First Name",
                    value: "firstName"
                },
                {
                    label: "Last Name",
                    value: "lastName"
                },
                {
                    label: "Email",
                    value: "email"
                },
                {
                    label: "Phone",
                    value: (row: any) => row.phone ? formatPhoneNumber(row.phone) : ''
                },
                {
                    label: "Date Received",
                    value: (row: any) => row.createdAt ? moment(row.createdAt).format("MM-DD-YYYY") : ''
                },
                {
                    label: "Lead ilia Product Type",
                    value: "strategy.name"
                },
                {
                    label: "Appointment Time",
                    value: (row: any) => {
                        if (row.contactType) {
                            return row.contactType === "NEXT_2_BUSINESS_DAY" ?
                                "Next 2 business days" :
                                (
                                    row.specificDate ?
                                        moment(row.specificDate).format("MM-DD-YYYY") + " (" + row.specificTime + ")" :
                                        ""
                                );
                        }
                        return '';
                    }
                }
            ];

            if (leads.length === 0) {
                leads = [{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    createdAt: '',
                    'strategy.name': '',
                    appointmentTime: ''
                }];
            }

            const [fileName, csvRes] = [`${query.statType}`, parse(leads, {fields})];

            downloadAsCSV(fileName, csvRes);

            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "Leads exported successfully", statusCode: 200},
            });
        }
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const exportAgentLinksCSV = (id:string, statType:string, type:string="csv", pageType:string="ALL", role:number) => async (dispatch: Function, getState: Function, api: {get: Function}) => {
    try {
        let leads = await api.get(`${prefix}/export`, {}, {id, statType, type, pageType});
            let fields: { label:string, value: any }[] = [
                {
                    label: "Email",
                    value: "email"
                },
                {
                    label: "ilia Product Type",
                    value: "strategy.name"
                },
                {
                    label: "Carrier",
                    value: "carrier.carrierName"
                },
                {
                    label: "Date",
                    value: (row: any, field: any) =>row.createdAt ? moment(row.createdAt).format("MM-DD-YYYY"): ''
                },
                {
                    label: "Age",
                    value: "age"
                },
                {
                    label: "Gender",
                    value: (row: any) => row.gender === 1 ? 'Male' : 'Female'
                },
                {
                    label: "Household Income",
                    value: "householdIncome"
                },
                {
                    label: "Health",
                    value: "healthType"
                }
            ];

            switch (statType) {
                case "TotalLeads":
                    if(role === 1 || role === 2){
                        fields = [
                            {
                                label: "Agent Name",
                                value: (row: any) => (row.agent && row.agent.user) ? row.agent.user.firstName + ' ' + row.agent.user.lastName : ''
                            },
                            {
                                label: "ilia Product Type",
                                value: (row: any) => row.strategy ? row.strategy.name : ''
                            },
                            {
                                label: "Carrier",
                                value: (row: any) => row.carrier ? row.carrier.carrierName : ''
                            },
                            {
                                label: "Date Received",
                                value: (row: any, field: any) =>row.createdAt ? moment(row.createdAt).format("MM-DD-YYYY"): ''
                            },
                            {
                                label: "Prospect Email",
                                value: "email"
                            },
                            {
                                label: "Prospect Age",
                                value: "age"
                            },
                            {
                                label: "Prospect Household Income",
                                value: "householdIncome"
                            },
                            {
                                label: "Prospect Health",
                                value: "healthType"
                            }
                        ]
                    }
                    break;
                case "LeadsClosed":
                    if(role === 1 || role === 2){
                        fields = [
                            {
                                label: "Client Name",
                                value: (row: any) => row.firstName + ' ' + row.lastName
                            },
                            {
                                label: "Client Email",
                                value: "email"
                            },
                            {
                                label: "Agent Name",
                                value: (row: any) => (row.agent && row.agent.user) ? row.agent.user.firstName + ' ' + row.agent.user.lastName : ''
                            },
                            {
                                label: "ilia Product Type",
                                value: (row: any) => row.strategy ? row.strategy.name : ''
                            },
                            {
                                label: "Carrier",
                                value: (row: any) => row.carrier ? row.carrier.carrierName : ''
                            },
                            {
                                label: "Date Closed",
                                value: (row: any) =>row.updatedAt ? moment(row.updatedAt).format("MM-DD-YYYY"): ''
                            },
                        ]
                    }
                    if(role === 4){
                        fields = [
                            {
                                label: "Client Name",
                                value: (row: any) => row.firstName + ' ' + row.lastName
                            },
                            {
                                label: "Email",
                                value: "email"
                            },
                            {
                                label: "ilia Product Type",
                                value: (row: any) => row.strategy ? row.strategy.name : ''
                            },
                            {
                                label: "Carrier",
                                value: (row: any) => row.carrier ? row.carrier.carrierName : ''
                            },
                            {
                                label: "Date Closed",
                                value: (row: any) => formatDate(row.updatedAt)
                            },
                        ]
                    }
                    break;
                default: {
                }
            }

            if (leads.length === 0) {
                leads = [{
                    firstName : '',
                    lastName : '',
                    'agent.user.firstName' : '',
                    'agent.user.lastName' : '',
                    email: '',
                    'strategy.name': '',
                    'carrier.carrierName': '',
                    createdAt: '',
                    updatedAt: '',
                    age: '',
                    gender: '',
                    householdIncome: '',
                    health: ''
                }];
            }

            const [fn, csv] = [statType, parse(leads, {fields})];

            downloadAsCSV(fn, csv);

            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "Leads exported successfully", statusCode: 200},
            });
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};


export const listMarketingEmails = (payload: { searchText:string, page:number, rowsPerPage:number }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get('marketingemail', {}, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const addMarketingEmail = (data: Lead) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post('marketingemail', data, {});
        return true;
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        return false;
    }
};

export const deleteMarketingEmail = (id: number) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const res = await api.delete(`marketingemail/${id}`, {}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Deleted Successfully.",
                statusCode: 200
            }
        });
        return  res;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const exportMarketingEmailCSV = (searchText = '', type:string="csv") => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const url = `${API_PATH_AAR}/export?searchText=${searchText}`;
        let result = await api.get(url);

        if (type === "json") {
            return result;
        } else {

            const fields: { label:string, value: any }[] = [
                {
                    label: "Email",
                    value: "email"
                },
                {
                    label: "Assigned Agent",
                    value: (row: any) => row["agentLink.agent.agentName"] || (("agentLink.agent.agentName" in row) && 'Cold Lead') || ""
                },
                {
                    label: "Date Received",
                    value: (row: any) =>row.createdAt ? moment(row.createdAt).format("MM-DD-YYYY"): ''
                },
                {
                    label: "Lead ilia Product Type",
                    value: "strategy.name"
                },
            ];

            if (result.length === 0) {
                result = [{
                    email: '',
                    createdAt: '',
                    'strategy.name': ''
                }];
            }

            const [fileName, csvRes] = [`MarketingEmails`, parse(result, {fields})];
            downloadAsCSV(fileName, csvRes);

            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "Marketing Emails exported successfully", statusCode: 200},
            });
        }
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const setLeadAppointment = (data: {
    email : string,
    id : number,
    specificDate: string,
    specificTime: string
}) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        let res = await api.put(`${prefix}/setappointment`, data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Appointment set Successfully.",
                statusCode: 200
            }
        });
        return res;
    } catch(err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
};

export const deleteLeadAppointment = (data: {
    email : string,
    id : number
}) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        let res = await api.post(`${prefix}/deleteappointment`, data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Deleted Successfully.",
                statusCode: 200
            }
        });
        return res;
    } catch(err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
};


