import * as React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import { history } from "../../configureStore";

function AssociationClientNavigation() {
	return (
		<React.Fragment>
			<List className="mt20 mb20">
				<ListItem className="active" button onClick={() => history.push("/participant")}>
					<ListItemIcon>
						<PeopleIcon/>
					</ListItemIcon>
					<ListItemText primary="Participants"/>
				</ListItem>
			</List>
		</React.Fragment>
	);
}

export default AssociationClientNavigation;