import * as React from "react";
import {FormHelperText, Grid, Typography} from "@material-ui/core";
import {Field} from "redux-form";
import {Label, PhoneNumberField, SelectField, TextField} from "./formInputs";
import {email, required, validatePhoneNumber, zipCodeValidation} from "../utils/validation";
import {imoClassDropDown, imoRegionDropDown, stateDropDown} from "../constants";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            error: {
                color: "#FF1744",
                fontSize:"12px"
            }
        }),
);
export default function AggregatorDetails(props: any) {
    const {
        handleChangeState,
        aggregatorEmailExist,
        setAggregatorEmailExist
    } = props;
    const classes = useStyles();
    return (
            <React.Fragment>
                <Grid item xs={12} sm={12} className="pb0">
                    <Typography component="strong" variant="subtitle1" className="w100 colorBlue">
                        AGGREGATOR Details
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label={<Label label={"Aggregator Name"} required={true}/>}
                            name="aggregator.aggregatorName"
                            component={TextField}
                            validate={[required]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label={<Label label={"Aggregator Email"} required={true}/>}
                            name="aggregator.aggregatorEmail"
                            component={TextField}
                            validate={[email, required]}
                            onChange={()=>{
                                if(typeof setAggregatorEmailExist === 'function'){
                                    setAggregatorEmailExist(false);
                                }
                            }}
                    />
                    {
                            aggregatorEmailExist &&
                            <FormHelperText style={{ margin: 0, fontSize: 10 }}>
                                <span className={classes.error}>Email address already exist</span>
                            </FormHelperText>
                    }
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label="Aggregator Address"
                            name="aggregator.aggregatorAddress"
                            component={TextField}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label="City"
                            name="aggregator.city"
                            component={TextField}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label="State"
                            name="aggregator.state"
                            component={SelectField}
                            options={stateDropDown}
                            handleClick={handleChangeState}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label="Zip"
                            name="aggregator.zipCode"
                            component={TextField}
                            validate={[zipCodeValidation]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            label={<Label label={"Office Phone"} required={true}/>}
                            name="aggregator.phoneNumber"
                            component={PhoneNumberField}
                            validate={[required, validatePhoneNumber]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Field
                            fullWidth
                            name="aggregator.region"
                            label="Region"
                            component={SelectField}
                            options={imoRegionDropDown}
                    />
                </Grid>
            </React.Fragment>
    );
}
