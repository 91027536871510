import React from "react";
import { Typography } from "@material-ui/core";
import { Dialog as GenericDialog } from "../../../../../../components/dialog";

type NonUsPopUpProps = {
    nonUSPopUp: boolean,
    setNonUSPopUp: Function
};

const NonUsPopUp: React.FC<NonUsPopUpProps> = (props) => {
    const { nonUSPopUp, setNonUSPopUp } = props;

    const content = (
            <>
                <Typography variant="subtitle1" component="strong" className="mb0 floatLeft w100">IDENTITY DOCUMENTS</Typography>
                <ul className="listStyle mt0 mb20 floatLeft w100 pl16">
                    <li><Typography className="mb0"  variant="body1">* Driver's license or passport</Typography></li>
                </ul>

                <Typography variant="subtitle1" component="strong" className="mb0 floatLeft w100">TAX DOCUMENTS</Typography>
                <ul className="listStyle floatLeft w100 pl16  mt0 mb20">
                    <li><Typography className="mb0"  variant="body1">* W2's</Typography></li>
                    <li><Typography className="mb0"  variant="body1">* Tax Returns</Typography></li>
                    <li><Typography className="mb0"  variant="body1">* 1099's</Typography></li>
                </ul>

                <Typography variant="subtitle1" className="mb0 floatLeft w100"><strong>RESIDENCY DOCUMENTS</strong> (Choose which applies to you) </Typography>
                <ul className="listStyle floatLeft w100 pl16  mt0 mb20">
                    <li><Typography className="mb0"  variant="body1">* Green Card </Typography></li>
                    <li><Typography className="mb0"  variant="body1">* Notice of Action</Typography></li>
                    <li><Typography className="mb0"  variant="body1">* Visa</Typography></li>
                    <li><Typography className="mb0"  variant="body1">* Additional Documents (if applicable)</Typography></li>
                    <li><Typography className="mb0"  variant="body1">* Utility bill with current address</Typography></li>
                </ul>

                <Typography variant="body1" className="mb0">
                    Please email info@niwcorp.com or call 800 294-9940 Ext. 216 for enrollment questions
                </Typography>
            </>
    );
    return (
            <GenericDialog
                    aria-describedby="nonus-dialog"
                    content={content}
                    fullWidth
                    keepMounted
                    maxWidth="sm"
                    onClose={() => setNonUSPopUp(false)}
                    open={nonUSPopUp}
                    title="Please be prepared to upload the following:"
            />
    );
};

export default NonUsPopUp;