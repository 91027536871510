import { Action, ActionType } from "../model";
import { Enrollment } from "../model/enrollment";
import createReducer from "./createReducer";

export const enrollment = createReducer([], {
	[ActionType.CREATE_ENROLLMENT](state: Account[], action: Action<Enrollment>) {
		return action.payload;
	},
	[ActionType.UPDATE_ENROLLMENT](state: Account[], action: Action<Enrollment>) {
		return state;
	},
	[ActionType.GET_ENROLLMENT](state: Account[], action: Action<Enrollment>) {
		return action.payload;
	}
});
