import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { Field, FieldArray, FieldArrayRenderProps } from "formik";
import { Button, Label } from "../../components/formInputs";
import { FormikWrapper, PhoneNumberField, SelectField, TextField } from "../../components/formikFormInputs";
import { doNone } from "../agent/AgentProfile/AgentGroupsTab";
import { Dialog, DialogContent, GenericDialogProps } from "../../components/dialog";
import { ApprovedTabsEnum, ApproveImoDialog, FormInputs, RejectImoDialog } from "./ApprovedAgentsPage";
import { useActions } from "../../actions";
import { RootState } from "../../reducers";
import * as ApprovedAgentActions from "../../actions/approvedAgents";
import { ImoClass, imoRegionDropDown, stateDropDown } from "../../constants";
import { getOptions, noop, pez, unmaskPhoneNumber } from "../../utils/commonUtils";
import { DialogImo } from "../../components/DialogImo";
import { getImos, initialImo } from "../agent/AgentProfile/AgentProfileTab";


type ApprovedDetailsProps = {
    checkIfIMOApproved: any;
    handleClose: any;
    onApproveAgent: any;
    onDirectRejectAgent: any;
    onRejectAgent: any;
    refresh: any;
    status: any;
};

const ApprovedDetails: React.FC<ApprovedDetailsProps> = (props) => {
    const {checkIfIMOApproved, handleClose, onApproveAgent, onDirectRejectAgent, onRejectAgent, refresh, status} = props;
    const requestDetail = useSelector((state: RootState) => state.approvedDetail);
    const primaryContact = [
        {
            component: TextField,
            disabled: true,
            label: <Label label={'Your First Name'} />,
            name: 'firstName',
            size: 6,
        },
        {
            component: TextField,
            disabled: true,
            label: <Label label={'Your Last Name'} />,
            name: 'lastName',
            size: 6,

        },
        {
            component: TextField,
            disabled: true,
            label: <Label label={'Your Email'} />,
            name: 'email',
            size: 12,
            variant: 'outlined',
        },
    ];
    const addressAndAdditionalFields = [
        {
            component: TextField,
            disabled: true,
            label: <Label label={'Your Business Address 1'} />,
            name: 'businessAddress1',
            size: 12,
            variant: 'outlined',
        },
        {
            component: TextField,
            disabled: true,
            label: <Label label={'Your Business Address 2'} />,
            name: 'businessAddress2',
            size: 12,
            variant: 'outlined',
        },
        {
            component: TextField,
            disabled: true,
            label: <Label label={'City'} />,
            name: 'city',
            size: 8,
            variant: 'outlined',
        },
        {
            component: SelectField,
            disabled: true,
            label: <Label label={'State'} />,
            name: 'state',
            onSelectChange: noop,
            options: stateDropDown.map(x => ({...x, id: x.value})),
            size: 4,
            variant: 'outlined',
        },
        {
            component: TextField,
            disabled: true,
            label: <Label label={'Zip'} />,
            name: 'zip',
            size: 4,
            variant: 'outlined',
        },
        {
            component: PhoneNumberField,
            disabled: true,
            label: <Label label={'Work Phone'} />,
            name: 'officeNumber',
            size: 4,
            variant: 'outlined'
        },
        {
            component: PhoneNumberField,
            disabled: true,
            label: <Label label={'Mobile Phone'} />,
            name: 'mobileNumber',
            size: 4,
            variant: 'outlined'
        },
        {
            component: TextField,
            disabled: true,
            label: <Label label={'Who Were You Referred By?'} />,
            name: 'whoRefered',
            size: 12,
            variant: 'outlined',
        },
    ];
    const approvedAgentActions = useActions(ApprovedAgentActions);
    const [imoToApprove, setImoToApprove] = React.useState<any | null>(null);
    const [imoToReject, setImoToReject] = React.useState<any | null>(null);
    const [showAddNewIMODialog, setShowAddNewIMODialog] = React.useState(false);
    const initialDialog = {
        type: { title: "Add / Edit IMO-Carrier Details", buttonLabel: "Save" },
        data: initialImo,
    };
    const onNewIMOSubmit = async (faProps: FieldArrayRenderProps, data: any) => {
        const {carriers, contactFirstName, contactLastName, id, imoEmail, name, phone, region, state} = data;
        const payload: any = {
            agentUserId: requestDetail.agent.userId,
            carriers: carriers.map(c => ({id: c.id, code: pez(c.code)})),
            contactFirstName,
            contactLastName,
            id,
            imoEmail,
            name,
            phone: unmaskPhoneNumber(phone),
            region,
            state,
        };

        // save IMO & move agent status to pending
        await approvedAgentActions.addIMOMoveAgentToPending(payload);
        await refresh();
        return handleClose();
    };
    const approveImo = async () => {
        const imoRequest = await approvedAgentActions.getImoRequestByImoId(imoToApprove.id);
        await approvedAgentActions.approveImo(imoRequest.id);
        setImoToApprove(null);
        await refresh();
        return handleClose();
    };
    const rejectImo = async (values) => {
        const imoRequest = await approvedAgentActions.getImoRequestByImoId(imoToReject.id);
        await approvedAgentActions.rejectImo(imoRequest.id, values.reason);
        setImoToReject(null);
        await onDirectRejectAgent(values);
        await refresh();
        return handleClose();
    };
    const agent = requestDetail.agent;
    const user = agent.user;
    const initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,

        businessAddress1: agent.companyStreet,
        businessAddress2: agent.businessAddress2,
        city: agent.companyCity,
        state: agent.companyState,
        zip: agent.companyZipCode,
        officeNumber: agent.officeNumber,
        mobileNumber: user.phoneNumber,

        whoRefered: '',
        agentImos: agent.agentImos,
    };
    const renderAgentIMOsFA = (faProps: FieldArrayRenderProps, agentIMOs: any[]) => {
        return (
                <>
                    {
                        agentIMOs.map((agentImo, index) => {
                            return (
                                    <Grid container key={index} spacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Field
                                                    component={TextField}
                                                    disabled
                                                    label={<Label label={'IMO Name'} />}
                                                    name={`agentImos[${index}].imo.groupName`}
                                                    value={agentImo.imo.groupName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Field
                                                    component={TextField}
                                                    disabled
                                                    label={<Label label={'IMO Status'} />}
                                                    name={`agentImos[${index}].imo.imoClass`}
                                                    value={agentImo.imo.imoClass}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Field
                                                    component={SelectField}
                                                    disabled
                                                    label={<Label label={'IMO State'} />}
                                                    name={`agentImos[${index}].imo.state`}
                                                    options={getOptions(stateDropDown)}
                                                    value={agentImo.imo.state}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Field
                                                    component={SelectField}
                                                    disabled
                                                    label={<Label label={'IMO Region'} />}
                                                    name={`agentImos[${index}].imo.region`}
                                                    options={getOptions(imoRegionDropDown)}
                                                    value={agentImo.imo.region}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Field
                                                    component={TextField}
                                                    disabled
                                                    label={<Label label={'Contact First Name'} />}
                                                    name={`agentImos[${index}].imo.user.firstName`}
                                                    value={agentImo.imo.user && agentImo.imo.user.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Field
                                                    component={TextField}
                                                    disabled
                                                    label={<Label label={'Contact Last Name'} />}
                                                    name={`agentImos[${index}].imo.user.lastName`}
                                                    value={agentImo.imo.user && agentImo.imo.user.lastName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Field
                                                    component={TextField}
                                                    disabled
                                                    label={<Label label={'IMO Email'} />}
                                                    name={`agentImos[${index}].imo.email`}
                                                    value={agentImo.imo.user && agentImo.imo.user.email}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Field
                                                    component={PhoneNumberField}
                                                    disabled
                                                    label={<Label label={'Primary Contact #'} />}
                                                    name={`agentImos[${index}].imo.officeNumber`}
                                                    value={agentImo.imo.officeNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <Field
                                                    component={TextField}
                                                    disabled
                                                    label={<Label label={'Carrier(s)'} />}
                                                    name={`agentImos[${index}].imo.agentImoCarriers`}
                                                    value={agentImo.agentImoCarriers.map(x => x.carrier.carrierName).join(', ')}
                                            />
                                        </Grid>
                                        {
                                            agentImo.agentImoCarriers.map((imoCarrier, imoCarrierIndex) => (
                                                    <Grid key={imoCarrierIndex} item xs={12} md={6} lg={6}>
                                                        <Field
                                                                component={TextField}
                                                                disabled
                                                                label={<Label label={imoCarrier.carrier.carrierName + ' Agent Number'} />}
                                                                name={`agentImos[${index}].imo.agentImoCarriers[${imoCarrierIndex}].carrierName`}
                                                                value={pez(imoCarrier.code)}
                                                        />
                                                    </Grid>
                                            ))
                                        }
                                        {
                                                agentImo.imo.imoClass === ImoClass.UNVERIFIED &&
                                                <Grid item xs={12}>
                                                    <Button
                                                            className="floatRight mt30 mb20 ml15"
                                                            variant="contained"
                                                            color="primary"
                                                            label="Approve IMO"
                                                            onClick={() => setImoToApprove(agentImo)}
                                                    />
                                                    <Button
                                                            label="Delete IMO"
                                                            className="floatRight mt30 mb20 bgRed"
                                                            variant="contained"
                                                            onClick={() => setImoToReject(agentImo)}
                                                    />
                                                    {
                                                            imoToApprove &&
                                                            <ApproveImoDialog
                                                                    imoToApprove={imoToApprove}
                                                                    onApprove={approveImo}
                                                                    onClose={() => setImoToApprove(null)}
                                                            />
                                                    }
                                                    {
                                                            imoToReject &&
                                                            <RejectImoDialog
                                                                    imoToReject={imoToReject}
                                                                    onReject={rejectImo}
                                                                    onClose={() => setImoToReject(null)}
                                                            />
                                                    }
                                                </Grid>
                                        }
                                        <Grid item xs={12}>
                                            {
                                                    showAddNewIMODialog &&
                                                    <DialogImo
                                                            handleClose={() => setShowAddNewIMODialog(false)}
                                                            imos={getImos(agentIMOs)}
                                                            initialImo={initialDialog.data}
                                                            onSubmit={(imo: any) => onNewIMOSubmit(faProps, imo)}
                                                            type={initialDialog.type}
                                                            showForAdmin={false}
                                                    />
                                            }
                                            {
                                                    agentIMOs.length - 1 === index &&
                                                    // TODO NIWC-2050 if need to add check to restrict visibility of button below
                                                    <Button
                                                            label={initialDialog.type.title}
                                                            color="primary"
                                                            className="floatRight"
                                                            variant="contained"
                                                            onClick={() => setShowAddNewIMODialog(true)}
                                                    />
                                            }
                                            <span className="hLine" />
                                        </Grid>
                                    </Grid>
                            );
                        })
                    }
                </>
        );
    };
    const ApprovedDetailChildren = ({errors, handleChange, handleSubmit, touched, values}) => {
        return (
                <form onSubmit={handleSubmit}>
                    <DialogContent dividers={false}>
                        <Typography component="strong" variant="subtitle2" className="floatLeft w100 mb10">
                            Personal Details
                        </Typography>
                        <Grid container spacing={2}>
                            <FormInputs
                                    fields={primaryContact}
                                    values={values}
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                            />
                        </Grid>
                        <Typography component="strong" variant="subtitle2" className="floatLeft w100 mt30 mb10">
                            Address and Additional Fields
                        </Typography>
                        <Grid container spacing={2}>
                            <FormInputs
                                    errors={errors}
                                    fields={addressAndAdditionalFields}
                                    handleChange={handleChange}
                                    touched={touched}
                                    values={values}
                            />
                        </Grid>
                        <Typography component="strong" variant="subtitle2" className="floatLeft w100 mt30 mb10">
                            IMOs and Carriers
                        </Typography>
                        <FieldArray name="agentImos" render={(r) => renderAgentIMOsFA(r, values.agentImos)} />

                        <Button
                                className="floatLeft floatRightSM mt20 mb30"
                                color="secondary"
                                label="Close"
                                onClick={handleClose}
                                variant="contained"
                        />
                        {
                                canApprove && (status === ApprovedTabsEnum.Pending || status === ApprovedTabsEnum.Deleted) &&
                                <Button
                                        className="floatRight mt20 mb30 ml15"
                                        color="primary"
                                        label="Approve Agent"
                                        onClick={() => onApproveAgent()}
                                        variant="contained"
                                />
                        }
                        {
                                (status === ApprovedTabsEnum.Pending) &&
                                <Button
                                        className="floatRight mt20 mb30 bgRed"
                                        label="Delete Agent"
                                        onClick={() => onRejectAgent()}
                                        variant="contained"
                                />
                        }
                    </DialogContent>
                </form>
        );
    };
    const formProps = {
        children: ApprovedDetailChildren,
        initialValues: initialValues,
        onSubmit: doNone,
        enableReinitialize: true
    };
    const canApprove = checkIfIMOApproved(requestDetail);
    const dialogProps: GenericDialogProps = {
        'aria-labelledby': 'customized-dialog-title',
        content: (
                <FormikWrapper {...formProps} />
        ),
        fullWidth: true,
        maxWidth: 'md',
        onClose: handleClose,
        open: true,
        title: 'Registration Information',
    };

    return (
            <Dialog {...dialogProps} />
    );
};

export default ApprovedDetails;
