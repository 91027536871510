import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ErrorCommon, NumberTextFieldWithCb, TextFieldCommon } from "../components/formikFormInputs";
import { Button as FormikButton, Label } from "../components/formInputs";
import { required } from "../utils/validation";
import { useActions } from "../actions";
import * as AccountActions from "../actions/account";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

interface OverRideFormProps {
    splitPercentage: number|null
    commissionableTarget: number|null,
    excess: number|null
}

const initValues = {
    splitPercentage: null,
    commissionableTarget: null,
    excess: null
}

const validate = (value: OverRideFormProps) => {
    let errors:any = {};
    return errors;
}

const fieldObj = [{
    id: "splitPercentage",
    name: "splitPercentage",
    component: NumberTextFieldWithCb,
    allowMax100: false,
    validate: required,
    thousandSeparator: false,
    placeholder: "% Split",
    label: <Label label={"% Split"} required={true}/>,
    rowSize: 6
}, {
    id: "commissionableTarget",
    name: "commissionableTarget",
    component: NumberTextFieldWithCb,
    allowMax100: false,
    validate: required,
    thousandSeparator: false,
    placeholder: "Commissionable % of Target",
    label: <Label label={"Commissionable % of Target"} required={true}/>,
    rowSize: 6
}, {
    id: "excess",
    name: "excess",
    component: NumberTextFieldWithCb,
    allowMax100: false,
    validate: required,
    thousandSeparator: false,
    placeholder: "% Excess",
    label: <Label label={"% Excess"} required={true}/>,
    rowSize: 6
}];


function OverRidesform(props: { role:number }) {
    const accountActions = useActions(AccountActions);
    const account:any = useSelector((state: RootState) => state.account);
    const [initialData, setInitialData] = React.useState<any>(initValues);
    const [title, setTitle] = React.useState("");
    const { role } = props;
    const { id } = useParams();
    let userType = "Agent";
    if(role === 3)
        userType = "Imo";

    const setTheTitle = (initialData) => {
        let embedText:string =  !!(initialData && initialData.splitPercentage) &&
        !! (initialData && initialData.splitPercentage) &&
        !! (initialData && initialData.splitPercentage) ? "" : `- ${userType} currently has no overrides`;
        console.log('set-title', `${userType} Overrides ${embedText}`);
        setTitle(`${userType} Overrides ${embedText}`);
    }

    const getAgentDeatils = async () => {
        await accountActions.getAccount(id, role);
    }

    React.useEffect(() => {
        getAgentDeatils();
        if(role === 4) {
            if (account && account.agent) {
                let payLoad = {
                    splitPercentage: (account && account.agent && account && account.agent.splitPercentage) || null,
                    commissionableTarget: (account && account.agent && account && account.agent.commissionableTarget) || null,
                    excess: (account && account.agent && account && account.agent.excess) || null
                }
                setInitialData(payLoad);
                setTheTitle(payLoad);
            }
        } else if(role === 3){
            if (account && account.imo) {
                let payload = {
                    splitPercentage: (account && account.imo && account && account.imo.splitPercentage) || null,
                    commissionableTarget: (account && account.imo && account && account.imo.commissionableTarget) || null,
                    excess: (account && account.imo && account && account.imo.excess) || null
                };
                setInitialData(payload)
                setTheTitle(payload);
            }
        } else {
            setInitialData(initValues)
        }
    });
    
    return (
        <>
            <Typography variant={"body1"}>{title}</Typography>
            <Formik
                    initialValues={initialData}
                    enableReinitialize={true}
                    validate={values => validate(values)}
                    validateOnChange={true}
                    onSubmit={async (values: any, { setSubmitting, setValues }) => {
                        await accountActions.updateOverRides(values, {role, userId: id});
                        await getAgentDeatils()
                        setTheTitle(values);
                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      dirty,
                  }) => {
                        return (
                        <form className="floatLeft w100" onSubmit={handleSubmit}>
                            <Grid container spacing={2} className="mb20">
                                {fieldObj.map((field, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={12} key={index}>
                                            <TextFieldCommon
                                                {...field}
                                                values={values}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched}/>
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            <FormikButton
                                fullWidth={false}
                                type="submit"
                                label="Save Changes"
                                variant="contained"
                                className="floatRight"
                                color="primary"
                                disabled={(Object.keys(errors).length !== 0) || isSubmitting}
                                loading={isSubmitting}
                            />
                        </form>
                    );
                }}
            </Formik>
        </>
    )
}

export default OverRidesform;