import React from "react";
import { Label } from "../../../../../../../components/formInputs";
import {
	PhoneNumberField,
	TextField,
} from "../../../../../../../components/formikFormInputs";
import { NOTA as _NOTA } from "../../../../../../../constants";

const commonOptions = {
	type: "radio",
	default: false,
	onYes: false,
	additionalRemarks: true,
	showRemarks: false,
	notShowHrLine: true
};

const commonProps = {
	placeholder: "Please Provide Details",
	label: "Please Provide Details",
	showDetailsBox: false,
	detailBoxType: "text",
};

export const NAME_IDENTIFIER = "FinancialQuestionsX";

export const NOTA = _NOTA;

const additionalFieldOptionsList = (index: number) => {
	const objectInitiator = {
		0: [
			{
				...commonProps,
				value: "Income Replacement",
				text: "Income Replacement",
			},
			{
				...commonProps,
				value: "Retirement Income",
				text: "Retirement Income",
			},
			{
				...commonProps,
				value: "Estate Preservation",
				text: "Estate Preservation",
			},
			{
				...commonProps,
				value: "Final Expenses",
				text: "Final Expenses",
			},
			{
				...commonProps,
				value: "Charitable Giving-Provide annual donation amount",
				text: "Charitable Giving-Provide annual donation amount",
				showDetailsBox: true,
				detailBoxType: "amount",
				label: "Annual donation amount",
				placeholder: "Annual donation amount",
			},
			{
				...commonProps,
				value: "Mortgage Protection-Provide mortgage amount",
				text: "Mortgage Protection-Provide mortgage amount",
				showDetailsBox: true,
				detailBoxType: "amount",
				label: "Provide mortgage amount",
				placeholder: "Provide mortgage amount",
			},
			{
				...commonProps,
				value: "Debt repayment/loan protection- Loan amount",
				text: "Debt repayment/loan protection- Loan amount",
				showDetailsBox: true,
				detailBoxType: "amount",
				label: "Loan amount",
				placeholder: "Loan amount",
			},
			{
				...commonProps,
				value: "Others",
				text: "Others",
				showDetailsBox: true,
			},
		],
	};
	return objectInitiator[index];
};


export const financialQuestions: any = () => {
	const financialQuestion: any[] = [
		{
			key: "1",
			name: "acceptFinancialCondition",
			label: "I understand that my answers to the financial questions on the form are part of my complete application for life insurance. I represent that all information and values provided on this form are true and accurate statements of my financial condition.",
			type: "radio",
			default: false,
			onYes: false,
		},
		// NIWC-2276
		{
			key: "2",
			name: "changesInIncome",
			label: "Do you expect any significant changes in income or net worth in the next year?",
			type: "radio",
			default: false,
			onYes: false,
			additionalRemarks: true,
			showRemarks: false,
			additionalFieldName: "changesInIncomeDesc",
			additionalFieldType: "textfield",
			additionalFieldRequired: true,
			additionalFieldLabel: "Please Provide Details",
			additionalFieldOptions: additionalFieldOptionsList(0),
			resetFields: [{
				name: "changesInIncomeDesc",
				value: "",
			}, {
				name: "FinancialQuestionsXC_2",
				value: [],
			}, {
				name: "FinancialQuestionsXTnewoh",
				value: "",
			}],
		},
		{
			key: "3",
			name: "declareBankruptcy",
			label: "Have you declared bankruptcy in the past 5 years or do you anticipate declaring bankruptcy in the future?",
			type: "radio",
			default: false,
			onYes: false,
			additionalRemarks: true,
			showRemarks: false,
			additionalFieldName: "Remark-3-Remarks",
			additionalFieldType: "radio",
			additionalFieldRequired: true,
			additionalFieldLabel: "Type of Bankruptcy",
			additionalFieldDate: true,
			additionalFieldDateLabel: "Date Field",
			additionalRemarks1: true,
			additionalField1Name: "Remark-3-Remark-1",
			additionalField1Type: "radio",
			additionalField1Required: true,
			additionalField1Label: "Current Status",
			additionalField1Date: true,
			additionalField1DateLabel: "Date of Discharge",
			additionalRemarks2: true,
			additionalFieldName2: "bankruptcyDetails",
			additionalFieldType2: "textfield",
			additionalFieldRequired2: true,
			additionalFieldLabel2: "Provide Additional Details",
		},
		{
			key: "4",
			name: "pendingLawsuits",
			label: "Are there any pending lawsuits or leins against you or any of your business interests?",
			type: "radio",
			default: false,
			onYes: false,
			additionalRemarks: true,
			showRemarks: false,
			additionalFieldName: "pendingLawsuitsDesc",
			additionalFieldType: "textfield",
			additionalFieldRequired: true,
			additionalFieldLabel: "Please Provide Details",
		},

	];
	return financialQuestion;
};


export const longFormFinancialQuestions = () => {
	const financialQuestion: any[] = [
		{
			...commonOptions,
			key: "1",
			name: `${NAME_IDENTIFIER}R_1`,
			label: "The lender has an assignment on the insurance policy, which limits certain rights, such as, but not limited to, taking withdrawals or loans from the policy. This assignment also gives the lender the ability to distribute their loan balance from the policy, which could result in the surrendering of the policy.",
			notShowHrLine: false,
			position: 1,
		},
		{
			...commonOptions,
			key: "2",
			name: `${NAME_IDENTIFIER}R_2`,
			label: "The lender loan interest rates may not be guaranteed and lender loans may be reviewed annually.",
			notShowHrLine: false,
			position: 2,
		},
		{
			...commonOptions,
			key: "3",
			name: `${NAME_IDENTIFIER}R_3`,
			label: "Policy illustrations are not intended to predict the actual performance of your policy.",
			notShowHrLine: false,
			position: 3,
		},
		{
			...commonOptions,
			key: "4",
			name: `${NAME_IDENTIFIER}R_4`,
			label: "Additional payments (out-of-pocket) may be required if the policy falls below the required level, or your policy fails to perform as originally illustrated in any given year.",
			notShowHrLine: false,
			position: 4,
		},
		{
			...commonOptions,
			key: "5",
			name: `${NAME_IDENTIFIER}R_5`,
			label: "The cash value of the policy is generally used to pay off the lender loan, if sufficient.",
			notShowHrLine: false,
			position: 5,
		},
		{
			...commonOptions,
			key: "6",
			name: `${NAME_IDENTIFIER}R_6`,
			label: "Using a life insurance policy loan to pay off the lender loan (without running inforce illustrations to demonstrate its impact) could result in a taxable event if the policy were to lapse with an outstanding loan.",
			notShowHrLine: false,
			position: 6,
		},
		{
			...commonOptions,
			key: "7",
			name: `${NAME_IDENTIFIER}R_7`,
			label: "You have the option to repay the lender loan using your other assets.",
			notShowHrLine: false,
			position: 7,
		},
		{
			...commonOptions,
			key: "8",
			name: `${NAME_IDENTIFIER}R_8`,
			label: "If you decease prior to repaying the lender, the death benefit will be used to repay the lender first and any outstanding policy loans, prior to being distributed to the beneficiary.",
			notShowHrLine: false,
			position: 8,
		},
		{
			...commonOptions,
			key: "9",
			name: `${NAME_IDENTIFIER}R_9`,
			label: "Changes to allocations may be restricted by the lender while your policy is assigned. Modifying these allocations may have long-term impacts to your plan design. If authorized, your Financial Professional(s) may initiate these requests.",
			notShowHrLine: false,
			position: 9,
		},
		{
			...commonOptions,
			key: "10",
			name: `${NAME_IDENTIFIER}R_10`,
			label: "Do you have a third party financial advisor (i.e. attorney, CPA, certified financial partner) or did an advisor help you prepare this Personal Financial Questionnaire? if `Yes` please provide the following details:",
			notShowHrLine: false,
			additionalFieldType: "textField",
			additionalFieldName: "FinancialQuestionsXT_10_rem",
			additionalRemarks: true,
			heading: "Third Party Financial Advisor Information",
			position: 25,
		},
	];
	return financialQuestion;
};

export const FQ10: string = NAME_IDENTIFIER + "T_10_";

export const advisorInformation3rdParty = (props: {
	clientSigned: boolean
}) => {
	const { clientSigned } = props;
	const fieldObj: any = [{
		id: `${FQ10}an`, //Advisor's name
		name: `${FQ10}an`,
		label: <Label label={"Advisor's Name"} required={true} />,
		placeholder: "Advisor's Name",
		component: TextField,
		variant: "outlined",
		fieldType: "advisorName",
		disabled: clientSigned,
		size: { xs: 12, sm: 4, md: 4, lg: 4, xl: 4 },
	}, {
		id: `${FQ10}es`, //Designation
		name: `${FQ10}es`,
		label: <Label label={"Designation"} required={true} />,
		placeholder: "Designation",
		component: TextField,
		variant: "outlined",
		fieldType: "designation",
		disabled: clientSigned,
		size: { xs: 12, sm: 4, md: 4, lg: 4, xl: 4 },
	}, {
		id: `${FQ10}ln`, //License
		name: `${FQ10}ln`,
		label: <Label label={"License #"} required={true} />,
		placeholder: "License #",
		component: TextField,
		variant: "outlined",
		fieldType: "string",
		disabled: clientSigned,
		size: { xs: 12, sm: 4, md: 4, lg: 4, xl: 4 },
	}, {
		id: `${FQ10}afn`, //Accounting Firm Name
		name: `${FQ10}afn`,
		helperFieldText: "",
		label: <Label label={"Accounting Firm Name"} required={true} />,
		placeholder: "Accounting Firm Name",
		disabled: clientSigned,
		fieldType: "firmName",
		component: TextField,
		variant: "outlined",
		size: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
		disableFuture: true,
	}, {
		id: `${FQ10}ass`, //Address
		name: `${FQ10}ass`,
		helperFieldText: "",
		label: <Label label={"Address"} required={true} />,
		placeholder: "Address",
		disabled: clientSigned,
		fieldType: "address",
		component: TextField,
		variant: "outlined",
		size: { xs: 12, sm: 3, md: 3, lg: 3, xl: 3 },
		disableFuture: true,
	}, {
		id: `${FQ10}iy`, //City
		name: `${FQ10}iy`,
		helperFieldText: "",
		label: <Label label={"City"} required={true} />,
		placeholder: "City",
		disabled: clientSigned,
		fieldType: "city",
		component: TextField,
		variant: "outlined",
		size: { xs: 12, sm: 3, md: 3, lg: 3, xl: 3 },
		disableFuture: true,
	}, {
		id: `${FQ10}se`, //State
		name: `${FQ10}se`,
		helperFieldText: "",
		label: <Label label={"State"} required={true} />,
		placeholder: "State",
		disabled: clientSigned,
		component: TextField,
		variant: "outlined",
		fieldType: "state",
		size: { xs: 12, sm: 3, md: 3, lg: 3, xl: 3 },
		disableFuture: true,
	}, {
		id: `${FQ10}zip`, //Zip
		name: `${FQ10}zip`,
		helperFieldText: "",
		label: <Label label={"Zip"} required={true} />,
		placeholder: "Zip",
		disabled: clientSigned,
		fieldType: "zipcode",
		component: TextField,
		variant: "outlined",
		size: { xs: 12, sm: 3, md: 3, lg: 3, xl: 3 },
		disableFuture: true,
	}, {
		id: `${FQ10}phn`, //Phone
		name: `${FQ10}phn`,
		helperFieldText: "",
		label: <Label label={"Phone"} required={true} />,
		placeholder: "Phone",
		disabled: clientSigned,
		fieldType: "numeric",
		component: PhoneNumberField,
		size: { xs: 12, sm: 3, md: 3, lg: 3, xl: 3 },
		variant: "outlined",
		disableFuture: true,
	}, {
		id: `${FQ10}hlb`, //How long they have been representing you?
		name: `${FQ10}hlb`,
		helperFieldText: "",
		label: <Label label={"How long they have been representing you?"} required={true} />,
		placeholder: "How long they have been representing you?",
		disabled: clientSigned,
		fieldType: "string",
		component: TextField,
		variant: "outlined",
		size: { xs: 12, sm: 9, md: 9, lg: 9, xl: 9 },
		disableFuture: true,
	}, {
		id: `${FQ10}iwh`, //In what capacity have they represented you?
		name: `${FQ10}iwh`,
		helperFieldText: "",
		label: <Label label={"In what capacity have they represented you?"} required={true} />,
		placeholder: "In what capacity have they represented you?",
		disabled: clientSigned,
		fieldType: "string",
		component: TextField,
		variant: "outlined",
		size: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
		disableFuture: true,
	}];
	return fieldObj;
};