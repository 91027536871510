import React, { useState } from "react";
import {Formik} from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import {createStyles, Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Label} from "../../formInputs";
import {ErrorCommon, NumberTextField, TextFieldCommon} from "../../formikFormInputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import numeral from "numeral";
import {
    submitEstimationFormik
} from "../../../pages/client/Estimation/V1/Components/EstimationFormikHandler";
import {useActions} from "../../../actions";
import * as Actions from "../../../actions/estimation";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers";
import { boolean } from "yup";
import {useCheckAtlantic} from "../../../hooks/useAuth";
import StepFour from "../../../pages/client/Estimation/V1/Components/StepFour";
import { useCreateAllocationPayLoad } from "../../../pages/client/Estimation/Hooks/Hooks";
import { checkSuffixPlus, isALGCarrier } from "../../../pages/client/Estimation/utils/utils";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        margin: 0,
    },
    container: {
        maxHeight: 600,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

const field1: any = [
    {
        id: "desireLifeTimeBenefits",
        name: "desireLifeTimeBenefits",
        label: <Label label={"Total Lifetime Benefits You Want"} required={true}/>,
        component: NumberTextField,
        variant: "outlined",
        className: "fullWidthcustomFields",
        InputProps: {
            startAdornment: <InputAdornment
                    className="margin0 dollerIcon"
                    position="start">$</InputAdornment>,
        },
        helperText: "Minimum $500,000 required",
    }];
export default function EditLifeTimeDeathBenefitAmount(props: { open: boolean, handleClose: Function, setIsEstimationDataLoading: Function, setRequestId: Function, estimatorData: any,
    isEstimationDataLoading: boolean, setTestingProgress: Function, setEditLifeTimeDeathBenefitAmount?:Function, userId: string | number | undefined, userObj: any, callingFrom:string | undefined
    responseData: any
}) {
    const atlantic = useCheckAtlantic();
    const classes = useStyles();
    const formikRef: any = React.useRef(null);
    const {
        open,
        handleClose,
        setIsEstimationDataLoading,
        setRequestId,
        estimatorData,
        isEstimationDataLoading,
        setTestingProgress,
        setEditLifeTimeDeathBenefitAmount,
        userId,
        userObj,
        callingFrom,
        responseData
    } = props;
    const estimationActions = useActions(Actions);
    const clientDetailsWhileEstimate = useSelector((state: RootState) => state.clientDetailsWhileEstimate); // prefilled values of estimation
    const {issueAge, userName, agentName} = clientDetailsWhileEstimate;
    const { common } = responseData || "";
    const createAllocationPayLoad = useCreateAllocationPayLoad(estimatorData && estimatorData.id)
    const totalDeathBenefit = (common && common.totaldeathbenefit) || ""
    let {  user } =  useSelector((state: RootState) => state.auth);
    const carrierId =  user.client && user.client.carrier ? user.client.carrier.id : 2;
    const [estimatedData, setEstimatedData] = useState<any>()
    const getEstimationDetail = async ()=>{
        let estimationDetails: any = await estimationActions.getEstimationDetailsByUserId(userId, callingFrom);
        if(estimationDetails){
            setEstimatedData(estimationDetails)
        }
    }
    React.useEffect(()=>{
        getEstimationDetail().then()
    },[])
    React.useEffect(() => {
        if (isEstimationDataLoading) {
            handleClose();
            setTestingProgress(true);
        }
    }, [isEstimationDataLoading]);

    const initialValues = {
        desireLifeTimeBenefits: totalDeathBenefit,
        InvestmentType:  estimatedData && checkSuffixPlus( estimatedData.InvestmentType) ? estimatedData.InvestmentType.substring(0,estimatedData.InvestmentType.length - 4) : estimatedData && estimatedData.InvestmentType,
        plus : !!(estimatedData && checkSuffixPlus( estimatedData.InvestmentType))
    }

    const submitDeathBenefitFormik = (values, setSubmitting, resetForm) => {
        submitEstimationFormik({values: {...clientDetailsWhileEstimate, ...values}, user: userObj, role: userObj.role, setIsEstimationDataLoading, userId, setRequestId,
                userName, agentName, issueAge, estimatorData, estimationActions, setEditLifeTimeDeathBenefitAmount, callingFrom, atlantic, createAllocationPayLoad}, true, "LIFE_TIME_DEATH_BENEFIT").then(() => {
        });
    }

    const validate = ((value: any) => {
        const errors: any = {};
        if (!value.desireLifeTimeBenefits) {
            errors.desireLifeTimeBenefits = "Required";
        } else if (numeral(value.desireLifeTimeBenefits).value() < 500000) {
            errors.desireLifeTimeBenefits = "Min Amount should be $500,000";
        }
        if(!isALGCarrier(carrierId) && !value.InvestmentType)
            errors.InvestmentType = "Required";
        if (!value.confirmInvestment) {
            errors.confirmInvestment = "Required";
        }
        return errors;
    })
    return <React.Fragment>
        <Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={() => {
                    handleClose()
                }}
                aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="customized-dialog-title">
                Edit LifeTime Death Benefit Amount
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                    handleClose();
                }}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div>
                    <Typography align="left" className="mb0">
                        Goal: Maximize My Death Benefit Protection
                    </Typography>
                    <Formik
                            innerRef={formikRef}
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validate={values => validate(values)}
                            //validationSchema={validationSchema}
                            validateOnChange={true}
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                submitDeathBenefitFormik(values, setSubmitting, resetForm);
                            }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              setFieldValue,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              dirty,
                              setFieldTouched,
                          }) => (
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit(e);
                                }}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <Grid item xs={12}>
                                            {field1.map((field, index) => {
                                                return (
                                                        <Grid item xs={12} key={index}
                                                              className="mt25">
                                                            <TextFieldCommon
                                                                    {...field}
                                                                    name={field.name}
                                                                    values={values}
                                                                    onChange={handleChange}
                                                                    //onBlur={(e) => handleBlur(e, field.name)}
                                                            />
                                                            <Typography
                                                                    variant="caption"
                                                                    className="f12 colorLightGray">{field.helperFieldText}</Typography>
                                                            <ErrorCommon errors={errors}
                                                                         name={field.name}
                                                                         touched={touched} />
                                                        </Grid>
                                                );
                                            })}
                                        </Grid>
                                        <StepFour callingFrom={""} />
                                        <span className="textRed f12 mt10 floatLeft w100">Required<sup>*</sup></span>
                                        <Grid item xs={12} className="mt20">
                                            <Button size="small" className="floatLeft" variant="contained"
                                                    onClick={() => handleClose()}
                                                    color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button size="small" type="submit"
                                                    className="floatRight floatLeftSm mt20Small"
                                                    variant="contained" color="primary"
                                                    disabled={isSubmitting || !dirty}
                                                    onClick={() => console.log("Re-calculate Results")}>
                                                Re-calculate Results
                                            </Button>
                                        </Grid>
                                    </FormControl>
                                </form>)}
                    </Formik>
                </div>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}