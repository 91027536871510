import * as React from "react";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from "@material-ui/icons/Create";
import { IconButton, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { AccountHeadRow } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import { AccountTypeByFilter, PermissionTypes } from "../../constants";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import {maskPhoneNumber} from "../../utils/commonUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { can } from "../navigation/AdminNavigation";

const headRows: AccountHeadRow[] = [
    { id: "trustee.trusteeName", label: "Trustee Name" },
    { id: "phoneNumber", label: "Primary Contact" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
];

function TrusteeTable(props: any) {
    const user = useSelector((state: RootState) => state.auth.user);
    const { accountList, order, orderBy, handleRequestSort, handleClickOpen } = props;
    const [inviteTokenId, setInviteTokenId] = React.useState<string>("");
    const accountActions = useActions(AccountActions);

    function valHandler(accountId: string) {
        setInviteTokenId(accountId);
        accountActions.copyInvitationLink("Invite Link Has Been Copied to Your Clipboard");
    }

    return (
            <React.Fragment>
                <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headRows={headRows}
                />
                <TableBody>
                    {
                        (!accountList.rows || accountList.rows.length === 0)
                        && <TableRow>
                            <TableCell align="left" colSpan={9}>
                                <Typography variant="caption" align='left' className="floatLeft">No Trustee Accounts</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    {accountList.rows && accountList.rows.map((account: any) => {
                        return (
                                <TableRow hover key={account.id}>
                                    <TableCell padding="default">{account["trustee.trusteeName"]}</TableCell>
                                    <TableCell padding="default">{maskPhoneNumber(account["phoneNumber"])}</TableCell>
                                    <TableCell padding="default">{account.firstName}</TableCell>
                                    <TableCell padding="default">{account.lastName}</TableCell>
                                    <TableCell padding="default">{account.email}</TableCell>
                                    <TableCell padding="default">
                                        {
                                            account.inviteLink &&
                                            <Tooltip placement="left-start" title={(account.id === inviteTokenId)
                                                    ?
                                                    <React.Fragment>Invite Link Has Been <br/> Copied to Your Clipboard</React.Fragment>
                                                    : "Copy Invite Link"}>
                                                <CopyToClipboard text={account.inviteLink}
                                                                 onCopy={() => valHandler(account.id)}>
                                                    <IconButton color="default">
                                                        <LinkIcon/>
                                                    </IconButton>
                                                </CopyToClipboard>
                                            </Tooltip>
                                        }
                                        <Tooltip title="Edit">
                                            <IconButton
                                                    color="default"
                                                    onClick={() => props.history.push("/accounts/" + AccountTypeByFilter[account.role] + "/edit/" + account.id)}
                                            >
                                                <CreateIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        {user && (user.role === 1 || can(user, PermissionTypes.DELETE_USER)) &&
                                        <Tooltip title="Delete">
                                            <IconButton
                                                    color="default"
                                                    onClick={() => handleClickOpen(account.id)}
                                            >
                                                <DeleteOutlineIcon className="colorRed"/>
                                            </IconButton>
                                        </Tooltip>
                                        }

                                    </TableCell>
                                </TableRow>
                        );
                    })
                    }
                </TableBody>

            </React.Fragment>
    );
}

export default TrusteeTable;