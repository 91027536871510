import React from "react";
import LoaderGif from "../styles/images/loader_nw.gif";
import { Typography } from "@material-ui/core";


const CommonLoader = (props: { margin?: string, showText?: boolean }) => {
    const { margin, showText } = props;

    return (
            <div className="textCenter">
                {/*{!showText && <img src={LoaderGif} alt="Loading..." style={{ margin: margin || "13% 45%" }}/>}*/}
                <img src={LoaderGif} alt="Loading..." style={{ margin: margin || "0% 38%" }}/>
                {showText && <Typography className="floatLeft w100" variant="body2">Please wait we are processing your
                    request.</Typography> }
            </div>
    );
};

export default CommonLoader;