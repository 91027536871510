const getSourceOfFundsQuestions = () => {
	const sourceOfFundsQuestions: any[] = [
		// NIWC-2276
		{
			key: "1",
			id:"liquidAssets",
			name: "liquidAssets",
			label: "Do you feel that sufficient liquid assets are available for living expenses and emergencies in addition to the money allocated to pay the life insurance premiums?",
			type: "radio",
			default: false,
			onYes: false,
			additionalRemarks: false,
			showRemarks: false,
			additionalFieldType: "checkbox",
			additionalFieldRequired: false,
			notShowHrLine: true,
		},
	];
	return sourceOfFundsQuestions;
};

export default getSourceOfFundsQuestions;