import React from 'react';
import {Box, IconButton, Tooltip, Typography} from "@material-ui/core";
import {trustFeeText} from "../../../constants";
import NumberFormat from "react-number-format";
import InfoIcon from "@material-ui/icons/Info";

export default function EstimationResultToolTip(props: {children: any}) {
    return <Tooltip arrow
                    title={
                        <React.Fragment>
                            <Typography
                                    variant="caption"
                                    className="mb0">{props.children}</Typography>
                        </React.Fragment>
                    }
    >
        <IconButton aria-label="Info" color="primary">
            <InfoIcon/>
        </IconButton>
    </Tooltip>
}