import { Action, ActionType } from "../model";
import {
	Imo,
	ImoList
} from "../model/account";
import createReducer from "./createReducer";

export const imos = createReducer([], {
	[ActionType.LIST_IMOS](state: Imo[], action: Action<ImoList>) {
		return action.payload;
	}
});
