import React, { FunctionComponent } from "react";
import {
	DialogContent,
	DialogTitle as MuiDialogTitle,
	Grid,
	Typography,
	DialogActions,
	Button,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { AddEditPhysician as styles } from "../../Style";

interface OwnProps {
	open: boolean,
	setOpen: Function
}

type Props = OwnProps;

interface DialogTitleProps {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
	const classes = styles();
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
		</MuiDialogTitle>
	);
};

const WarningModal: FunctionComponent<Props> = (props) => {
	const { open, setOpen } = props;

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog onClose={handleClose} fullWidth={true} maxWidth={"sm"} aria-labelledby="customized-dialog-title"
				open={open}>
			<DialogTitle id="customized-dialog-title" onClose={handleClose}>
				Primary Beneficiary Required
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Typography variant="body1">
							Please add at least one primary beneficiary before adding any contingent beneficiaries.
							All states require at least one primary beneficiary.
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					size={"small"}
					color={"primary"}
					variant={"contained"}
					onClick={handleClose}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default WarningModal;
