import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmToUploadPolicyDoc(props: { confirmationBox: boolean, setConfirmationBox: Function, handleYes: any }) {
	const { confirmationBox, setConfirmationBox, handleYes } = props;
	const handleClose = () => {
		setConfirmationBox(false);
	};

	return (
		<div>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={confirmationBox}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">{"Confirmation"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to replace current doc?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleYes} color="primary" autoFocus className={"bgRed"} variant="contained">
						Yes
					</Button>
					<Button autoFocus onClick={handleClose} color="secondary" variant="contained">
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
