import React from "react";
import {
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
  Link
} from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from "@material-ui/icons/Create";
import { dateUtils } from "../../utils";
import { getAdminTypeName } from "../../constants";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {Link as RouterLink} from 'react-router-dom';
import { fullName } from "../../pages/client/ClientProfile/component/tabs/ClientProfileTab";

function AdminTableRow(props: any) {
	const { account, setInviteTokenId, inviteTokenId, handleClickOpen, handleViewPermissions, deletedUser } = props;
	const accountActions = useActions(AccountActions);
	const user = useSelector((state: RootState) => state.auth.user);
  const isIliaAdmin = account.role === 2;
	let { index } = props;

	function valHandler(accountId: string) {
		setInviteTokenId(accountId);
		accountActions.copyInvitationLink(
			"Invite Link Has Been Copied to Your Clipboard"
		);
    }
    return(
        <TableRow hover key={account.id}>
            <TableCell>{index}</TableCell>
            <TableCell>
              <Link 
                component={RouterLink}
                to={'/new-admin/detail/'+ account.id}
              >{fullName(account)}
              </Link>
            </TableCell>            
            <TableCell padding="default">{account.email}</TableCell>
            <TableCell>{getAdminTypeName(account["admin.adminType"])}</TableCell>
            <TableCell>{
              isIliaAdmin? 
                <Link onClick={() => handleViewPermissions(account.id)}>View Permissions</Link>: 
                'Master admin'
              }
            </TableCell>
            <TableCell>{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
            <TableCell padding="default" align="right" className="w230">

              {/* {account.inviteStatus <= 1 && <Button size="small" className="btnSmall" onClick={ () => handleClickReInvite(account.id)}>Re-Invite</Button>} */}
              <Tooltip title="Edit">
                <IconButton
                    color="default"
                    onClick={ () => props.history.push('/new-admin/detail/'+ account.id)}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              {account.inviteLink &&
                <Tooltip placement="left-start" title={ (account.id === inviteTokenId)
                    ?  <React.Fragment>Invite Link Has Been <br/> Copied to Your Clipboard</React.Fragment>
                    : "Copy Invite Link"}>
                  <CopyToClipboard text={account.inviteLink}
                                    onCopy={()=>valHandler(account.id)}>
                    <IconButton color="default">
                      <LinkIcon />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              }
              {
                user && user.role === 1 &&
                <Tooltip title="Delete">
                  <IconButton
                    color="default"
                    onClick={() => {
                        if(account.role === 1)
                        return handleClickOpen(account.id);
                        deletedUser(account.id);
                    }}
                  >
                    <DeleteOutlineIcon className="colorRed" />
                  </IconButton>
                </Tooltip>
              }

            </TableCell>
          </TableRow>
    )
}

export default AdminTableRow; 