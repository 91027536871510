import {EnrollmentQuestionProps} from "../../../../../../model/enrollment";
import {Grid} from "@material-ui/core";
import {Field, useFormikContext} from "formik";
import {Label} from "../../../../../../components/formInputs";
import {NumberTextField, TextField, PhoneNumberField} from "../../../../../../components/formikFormInputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import {getFloatString, unmaskPhoneNumber} from "../../../../../../utils/commonUtils";
import {selfAnnualIncomeMinValue} from "../../../../../../constants";
import numeral from "numeral";
import AdditionalFieldCheckBox from "./AdditionalFieldCheckBox";

type EnrollmentSubFieldProps = {
    question: EnrollmentQuestionProps,
    clientSigned: boolean,
    isFromClientDetail: boolean,
    checkIsError: Function,
    offerType: string
}

const EnrollmentSubField = (props: EnrollmentSubFieldProps) => {
    const {clientSigned, question, isFromClientDetail, checkIsError, offerType} = props;
    const {values, errors, handleChange, handleBlur}: any = useFormikContext();
    const minAmountValidate = (val) => {
        if (val && (Number(getFloatString(val + "")) < selfAnnualIncomeMinValue)) {
            return "Must have an annual income of over " + numeral(selfAnnualIncomeMinValue).format("$0,0") + ". If under, please contact your agent";
        }
    };
    return (<Grid item xs={12}>
        {(() => {
            switch (question.additionalFieldType) {
                case "checkbox":
                    return <AdditionalFieldCheckBox {...props} />;
                case "multiIncome":
                    return (<div className="mt30">
                        {question.additionalFields && question.additionalFields.map((value, index: any) => {
                            return (<Field fullWidth name={value}
                                           label={question.additionalFieldLabel && question.additionalFieldLabel.replace(":count", index + 1)}
                                           validate={question.additionalFieldMinValue && [minAmountValidate]}
                                           component={NumberTextField}
                                           InputProps={{
                                               startAdornment: <InputAdornment
                                                       position="start"><strong>$</strong></InputAdornment>,
                                           }}
                                           placeholder={question.additionalFieldLabel && question.additionalFieldLabel.replace(":count", index + 1)}
                            />);

                        })
                        }
                    </div>);
                case "physicianDetails":
                    return (<Grid container spacing={2}>
                        {question.additionalFields && question.additionalFields.map((value:any, index: any) => {
                            return (<Grid item xs={12} md={4}
                                          key={index + value.name} className={"mt30"}><Field fullWidth name={value.name}
                                                                                             label={value.label}
                                                                                             id={value.name}
                                                                                             component={(value.type && value.type === "phone") ?  PhoneNumberField : TextField}
                                                                                             value={values[(question && question.additionalFields[index].name) || ""]}
                                                                                             placeholder={value.label}
                                                                                             onChange={handleChange}
                                                                                             onBlur={handleBlur}
                                                                                             error={isFromClientDetail ? false : !!errors[(question && question.additionalFields[index].name) || ""]}
                                                                                             helperText={isFromClientDetail ? "" : errors[(question && question.additionalFields[index].name) || ""] ? errors[(question && question.additionalFields[index].name) || ""] : ""}
                                                                                             disabled={clientSigned}
                                                                                             variant="outlined"
                                                                                             validate={(val: string) => {
                                                                                                 if (isFromClientDetail) {
                                                                                                     return "";
                                                                                                 }
                                                                                                 if (!val && values[question.name] === "Yes") {
                                                                                                     return "Required Field";
                                                                                                 }
                                                                                                 if(value && value.type === "phone"){
                                                                                                     if(val && unmaskPhoneNumber(val).length !== 10){
                                                                                                         return "Phone number must be of 10 digits";
                                                                                                     }
                                                                                                 }
                                                                                                 return "";
                                                                                             }}
                            /></Grid>);
                        })
                        }
                    </Grid>);
                case "checkboxAndPhysicianDetails":
                    return <AdditionalFieldCheckBox {...props}/>;
                default:
                    return (<div className={`mt30 ${!!question.subQuestionLi ? "ml15" : ""}`}>
                        <div className="floatLeft w100 f12">
                            <Label label={(question && question.additionalFieldLabel) || ""} required={true}/>
                        </div>
                        <Field
                                fullWidth
                                name={question.additionalFieldName}
                                component={TextField}
                                value={values[(question && question.additionalFieldName) || ""]}
                                error={isFromClientDetail ? false : !!errors[(question && question.additionalFieldName) || ""]}
                                helperText={isFromClientDetail ? "" : errors[(question && question.additionalFieldName) || ""] ? errors[(question && question.additionalFieldName) || ""] : ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={question.additionalFieldRequired && question.additionalFieldLabel ? question.additionalFieldLabel : "Provide Details"}
                                disabled={clientSigned}
                                variant="outlined"
                                validate={(value: string) => {
                                    if (isFromClientDetail) {
                                        return "";
                                    }
                                    if (!value && values[question.name] === "Yes" && question.name !== "Proposed_Insured_Questions-10") {
                                        return "Required Field";
                                    }
                                    if (!value && values[question.name] === "No" && question.name === "Proposed_Insured_Questions-10") {
                                        return "Required Field";
                                    }
                                    return "";
                                }}
                        />
                    </div>);
            }
        })()}
    </Grid>);
}

export default EnrollmentSubField;