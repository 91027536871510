import * as React from "react";
import { useState } from "react";
import { change, Field } from "redux-form";
import {
    Button, Dialog, DialogContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useSelector } from "react-redux";
import { useActions } from "../actions";
import * as AggregatorActions from "../actions/aggregator";
import * as AccountActions from "../actions/account";
import { FormInputProps } from "../model";
import { RootState } from "../reducers";
import { DialogAddAggregatorDivision, DialogAggregationDivisionType } from "./aggregator/DialogAddAggregatorDivision";
import { DialogAddAggregatorImo, DialogAggregationImoType } from "./aggregator/DialogAddAggregatorImo";
import PrimaryContactDetail from "./PrimaryContactDetail";
import { stateDropDown } from "../constants";
import AggregatorDetails from "./AggregatorDetails";
import { AggregatorImosTable } from "./AggregatorImosTable";
import { useStyles } from "../pages/agent/dashboard/TableQuickView";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { DialogTitle } from "./table/AdminTable";

const hidden = (field: FormInputProps) => {
    return <input {...field.input} type="hidden"/>;
};

export type Division = {
    id?: number | string
    divisionName: string
}

export type DivisionArr = Division[];
export type ImoDivision = {
    imoId: number | null | string
    divisionId?: number | null | string
    divisionName: string,
    imoName: string
}
export type ImoDivisionArr = ImoDivision[]

let initialDivision: Division = {
    id: "",
    divisionName: "",
};

export let initialImoDivision = {
    imoId: "",
    divisionId: "",
    divisionName: "",
    imoName: "",
};
//Delete Division
const ConfirmDelete = (props: { cb: Function, setDeleteDivisionPopUp: Function, deleteDivisionPopUp: boolean }) => {
    const { cb, setDeleteDivisionPopUp, deleteDivisionPopUp } = props;
    const title = "Delete Confirmation";
    const text = `Are you sure want to delete this division permanently?`;
    const onCancel = () => {
        setDeleteDivisionPopUp(false);
    };
    const onOk = () => {
        cb();
        setDeleteDivisionPopUp(false);
    };
    return (<Dialog
                    onClose={onCancel}
                    aria-labelledby="customized-dialog-title"
                    open={deleteDivisionPopUp}
            >
                <DialogTitle id="customized-dialog-title" onClose={onCancel}>
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        {text}
                    </Typography>
                    <Button className="floatLeft mt20 mb20" variant="contained" onClick={onCancel}>Cancel</Button>
                    <Button className="floatRight mt20 mb20" variant="contained" color="primary"
                            onClick={onOk}>Yes</Button>
                </DialogContent>
            </Dialog>
    );
};

export const ConfirmImoDivisionDelete = (props: { cbImo, setDeleteImoDivisionPopUp, deleteImoDivisionPopUp }) => {
    const { cbImo, setDeleteImoDivisionPopUp, deleteImoDivisionPopUp } = props;
    const title = "Delete Confirmation";
    const text = `Are you sure want to delete this Aggregator IMO permanently?`;
    const onCancel = () => {
        setDeleteImoDivisionPopUp(false);
    };
    const onOk = () => {
        cbImo();
        setDeleteImoDivisionPopUp(false);
    };
    return (<Dialog
                    onClose={onCancel}
                    aria-labelledby="customized-dialog-title"
                    open={deleteImoDivisionPopUp}
            >
                <DialogTitle id="customized-dialog-title" onClose={onCancel}>
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        {text}
                    </Typography>
                    <Button className="floatLeft mt20 mb20" variant="contained" onClick={onCancel}>Cancel</Button>
                    <Button className="floatRight mt20 mb20" variant="contained" color="primary"
                            onClick={onOk}>Yes</Button>
                </DialogContent>
            </Dialog>
    );
};


const DivisionRow = (props: { row: any, setDeleteDivisionPopUp: Function, setDivisionToDelete: Function }) => {
    const { row, setDeleteDivisionPopUp, setDivisionToDelete } = props;
    return (
            <TableRow>
                <TableCell>
                    {row.divisionName}
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => {
                        setDeleteDivisionPopUp(true);
                        setDivisionToDelete(row.divisionName);
                    }}>
                        <DeleteIcon className="colorRed"/>
                    </IconButton>
                </TableCell>
            </TableRow>
    );
};
const DivisionsTable = (props: { divisions: any, setDeleteDivisionPopUp: Function, setDivisionToDelete: Function }) => {
    const { divisions, setDeleteDivisionPopUp, setDivisionToDelete } = props;
    const classes = useStyles();
    return (
            <TableContainer>
                <Table className={classes.table} aria-label="caption table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell>Aggregator Division Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                divisions && divisions.length === 0 && <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                        <Typography variant="caption" align="left" className="floatLeft">No Division
                                            Found.</Typography>
                                    </TableCell>
                                </TableRow>
                        }
                        {divisions && divisions.length > 0 && divisions.map((row, index) => (
                                <DivisionRow key={`${row.divisionName}-${index}`} row={row}
                                             setDeleteDivisionPopUp={setDeleteDivisionPopUp}
                                             setDivisionToDelete={setDivisionToDelete}
                                />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    );
};
export default function AddAggregator(props: any) {
    const aggregatorActions = useActions(AggregatorActions);
    const accountActions = useActions(AccountActions);
    const formName = props.page;
    const dispatch = props.dispatch;

    const { emailExist, setEmailExist, setEmailRef, aggregatorEmailExist, setAggregatorEmailExist } = props;
    const account = useSelector((state: RootState) => state.account);
    const [allImo, setAllImo] = useState<any[]>([]);

    //Start : Division related states
    const [divisions, setDivisions] = useState<DivisionArr | []>([]);
    const [divisionDialog, setDivisionDialog] = React.useState<{ title?: DialogAggregationDivisionType.ADD, data: Division }>({
        data: initialDivision,
    });
    const [deleteDivisionPopUp, setDeleteDivisionPopUp] = useState(false);
    const [divisionToDelete, setDivisionToDelete] = useState("");
    //End: Division related states

    // start: Imo division related states
    const [imoDivisions, setImoDivisions] = useState<ImoDivisionArr | []>([]);
    const [imoDivisionDialog, setImoDivisionDialog] = React.useState<{ title?: DialogAggregationImoType.ADD, data: ImoDivision }>({
        data: initialImoDivision,
    });
    const [deleteImoDivisionPopUp, setDeleteImoDivisionPopUp] = useState(false);
    const [imoDivisionToDelete, setImoDivisionToDelete] = useState("");
    // End: Imo Division related states
    const handleChangeState: any = async (state: string) => {
        const stateOption = stateDropDown.find(x => x.value === state);
        dispatch(change(formName, "aggregator.state", stateOption && stateOption.region));
    };

    const getImosAll = async (search?: string) => {
        const res = await aggregatorActions.getImoAll(search); //get all imo that are not belongs to any aggregator.

        if (res && res.length > 0) {
            const imoArr = res.map((item) => {
                if (!item.imo) {
                    return null;
                }
                return {
                    ...item,
                    id: item.imoId,
                    key: item.imoId,
                    value: item.imoId,
                    name: item.imo && item.imo.groupName,
                    title: item.imo && item.imo.groupName,
                };
            });
            setAllImo(imoArr);
        }
    };

    React.useEffect(() => {
        dispatch(change(formName, "aggregator.divisions", divisions));
        dispatch(change(formName, "aggregator.imoDivisions", imoDivisions));
        getImosAll().then(() => {
        });
        return () => {
            setDivisions([]);
            setImoDivisions([]);
        };
    }, []);

    React.useEffect(() => {
        if (account && account.id && formName === "EditAccountPage") {
            const divisionArr = account && account.aggregator && account.aggregator.divisions || [];
            setDivisions(divisionArr);
            const imoArr = account && account.aggregator && account.aggregator.imos && account.aggregator.imos.length > 0 && account.aggregator.imos.map((item: any) => {
                return {
                    imoId: item.id,
                    divisionId: item.divisionId,
                    divisionName: item.divisionName,
                    imoName: item.groupName,
                };
            }) || [];
            setImoDivisions(imoArr);
        }
    }, [account && account.aggregator && account.aggregator.divisions]);

    React.useEffect(() => {
        dispatch(change(formName, "aggregator.divisions", divisions));
    }, [divisions && divisions.length]);

    React.useEffect(() => {
        dispatch(change(formName, "aggregator.imoDivisions", imoDivisions));
    }, [imoDivisions && imoDivisions.length]);

    const cb = () => {
        if (imoDivisions.length > 0 && imoDivisions.find((item) => {
            return item.divisionName === divisionToDelete;
        })) {
            accountActions.alertPopup("Can Not Delete Division! As Division Associated To IMO");
            return;
        }
        const restDivisionsArr = divisions.filter((item) => {
            return item.divisionName !== divisionToDelete; // Note: filter should be on the base of id
        });
        setDivisions(restDivisionsArr);
    };

    const cbImo = () => {
        const restImoDivisionArr = imoDivisions.filter((item) => {
            return item.imoId !== imoDivisionToDelete;
        });
        setImoDivisions(restImoDivisionArr);
    };

    return (
            <React.Fragment>
                <AggregatorDetails
                        showImoClass={formName === "AddAccount"}
                        hidden={hidden}
                        handleChangeState={handleChangeState}
                        aggregatorEmailExist={aggregatorEmailExist}
                        setAggregatorEmailExist={setAggregatorEmailExist}
                />


                <Grid item xs={12} sm={12} className="pb0">
                    <Typography variant="subtitle1" className="floatLeft colorBlue mb0" component="strong">
                        AGGREGATOR Primary Contact
                    </Typography>
                </Grid>
                <PrimaryContactDetail
                        setEmailRef={setEmailRef}
                        emailExist={emailExist}
                        setEmailExist={setEmailExist}
                        disabledEmail={false}
                        disabledPhone={false}
                />
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={12} md={6} className="pb0">
                            <Typography component="strong" variant="subtitle1" className="floatLeft colorBlue mb0 mt20Small">
                                AGGREGATOR DIVISIONS
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className="pb0">
                            <Button className="floatRight floatLeftSm mb20 mt20Small" color="primary"
                                    startIcon={<AddCircleIcon/>}
                                    onClick={() => setDivisionDialog({
                                        title: DialogAggregationDivisionType.ADD,
                                        data: initialDivision,
                                    })}
                            >
                                Add Divisions to the Aggregator Account
                            </Button>
                            {
                                    !!divisionDialog.title &&
                                    <DialogAddAggregatorDivision
                                            open={!!divisionDialog.title}
                                            handleClose={() => setDivisionDialog({ data: initialDivision })}
                                            onSubmit={division => setDivisions([...divisions, division])}
                                            initialDivision={divisionDialog.data}
                                            title={divisionDialog.title}
                                            divisions={divisions}
                                    />
                            }
                            <Field name="aggregator.divisions" component={hidden}/>
                        </Grid>
                    </Grid>
                </Grid>
                <DivisionsTable
                        divisions={divisions}
                        setDeleteDivisionPopUp={setDeleteDivisionPopUp}
                        setDivisionToDelete={setDivisionToDelete}

                />
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography component="strong" variant="subtitle1" className="floatLeft colorBlue mb0 mt20Small">
                                AGGREGATOR IMOs
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button className="floatRight floatLeftSm mb20 mt20Small" color="primary"
                                    startIcon={<AddCircleIcon/>}
                                    onClick={() => {
                                        setImoDivisionDialog({
                                            title: DialogAggregationImoType.ADD,
                                            data: initialImoDivision,
                                        });
                                    }}
                            >
                                Add Imos to the Aggregator Account
                            </Button>
                            {
                                    !!imoDivisionDialog.title &&
                                    <DialogAddAggregatorImo
                                            open={!!imoDivisionDialog.title}
                                            handleClose={() => {
                                                setImoDivisionDialog({ data: initialImoDivision });
                                                return getImosAll();
                                            }}
                                            onSubmit={imoDivision => {
                                                setImoDivisions([...imoDivisions, imoDivision]);
                                            }}
                                            initialImoDivision={imoDivisionDialog.data}
                                            title={imoDivisionDialog.title}
                                            divisionsOptions={divisions}
                                            allImoOptions={allImo}
                                            imoDivisions={imoDivisions}
                                            getImosAll={getImosAll}
                                    />
                            }
                            <Field name="aggregator.imoDivisions" component={hidden}/>
                        </Grid>
                    </Grid>
                    <AggregatorImosTable
                            rows={imoDivisions}
                            setDeleteImoDivisionPopUp={setDeleteImoDivisionPopUp}
                            setImoDivisionToDelete={setImoDivisionToDelete}
                    />
                    {
                            deleteDivisionPopUp &&
                            <ConfirmDelete cb={cb} setDeleteDivisionPopUp={setDeleteDivisionPopUp}
                                           deleteDivisionPopUp={deleteDivisionPopUp}/>
                    }
                    {
                            deleteImoDivisionPopUp &&
                            <ConfirmImoDivisionDelete cbImo={cbImo}
                                                      setDeleteImoDivisionPopUp={setDeleteImoDivisionPopUp}
                                                      deleteImoDivisionPopUp={deleteImoDivisionPopUp}
                            />
                    }
                </Grid>
            </React.Fragment>
    );
}