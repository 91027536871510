import React from "react";
import RadioGroupCommon from "./RadioGroupCommon";

function ParentsAreIncomeEarner(props: any) {
	const { conditionalRequired, disabled, values, touched, errors, classes, setFieldValue } = props;
	const radioOptionArr: any = [
		{
			label: "Yes",
			value: "1",
		}, {
			label: "No",
			value: "0",
		},
	];
	const callBack = (e: any) => {
		if (e && e.target) {
			setFieldValue("parentsAreIncomeEarner", e.target.value);
		}
	};
	return (
		<RadioGroupCommon
			cb={callBack}
			classes={classes}
			conditionalRequired={conditionalRequired}
			disabled={disabled}
			errors={errors}
			fieldName="parentsAreIncomeEarner"
			label="Is this primary income earner your parent / grandparent?"
			radioOptionArr={radioOptionArr}
			touched={touched}
			values={values}
		/>
	);
}

export default ParentsAreIncomeEarner;