
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    AppBar,
    Breadcrumbs,
    CardContent,
    Grid,
    Link,
    List,
    MuiThemeProvider,
    Tabs,
    Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { AgentDocuments } from "./";
import { useActions } from "../../../actions";
import * as AccountActions from "../../../actions/account";
import * as LeadActions from "../../../actions/lead";
import kaiZenTheme, { KaiZenThemeGlobalCss } from "../../../styles/theme/kaiZenTheme";
import LinksTable from "../AgentLinks/LinksTable";
import QuickView from "../../agent/dashboard/QuickView";
import { AdminActions } from "./AdminActions";
import { AgentClientsTab } from "./AgentClientsTab";
import { AgentGroupsTab } from "./AgentGroupsTab";
import { AgentLinksStats } from "../AgentLinks/AgentLinksStats";
import { AgentProfileTabReduxForm } from "./AgentProfileTab";
import Overrides from "../../Overrides";
import { can } from "../../../components/navigation/AdminNavigation";
import { CardSnapshot } from "../../client/ClientProfile/component/ClientSnapshot";
import { ClientQuickView, QuickViewSection } from "../dashboard/AgentDashboard";
import { fullName } from "../../client/ClientProfile/component/tabs/ClientProfileTab";
import { LinkTab, useTabWithLocation } from "../../imo/ImoProfile/ImoProfile";
import { PageTitle } from "../../../components/formInputs";
import { cssPaths, PermissionTypes, getAgentTypeName } from "../../../constants";
import { RootState } from "../../../reducers";
import { TabPanel, useStyles } from "../../client/ClientProfile/component/TabsMenu";
import { dateUtils } from "../../../utils";


const AgentProfile = (props: any) => {
    const accountActions = useActions(AccountActions);
    const accountDetail = useSelector((state: RootState) => state.account);
    const auth = useSelector((state: RootState) => state.auth);

    const { match } = props;
    const userId: string = match.params.id;
    const role = 4;
    const leadActions = useActions(LeadActions);

    const [linkStats, setLinkStats] = React.useState({
        TotalLeadsCount: 0,
        LeadsInvitedCount: 0,
        LeadsRejectedCount: 0,
        AccountsCreatedCount: 0,
        LeadsClosedCount: 0,
        ClosingRatio: 0,
        TotalLinkViews: 0,
    });

    React.useEffect(() => {
        accountActions.getAccount(userId, role);
    }, [userId, role]);

    const getAgentDetails = async (agentUserId) => {
        if (!agentUserId) {
            return;
        }

        const data = await leadActions.leadStatsFromAdmin("AGENT_LINK", agentUserId);
        if (data) {
            setLinkStats(data.payload);
        }
    };

    React.useEffect(() => {
        getAgentDetails(userId).then();
    }, [userId]);

    return (
            <DetailLayout title="Agent Detail">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={9}>
                        <BackButton history={props.history} />
                        <DetailBreadcrumb
                                of="Agent"
                                name={fullName(accountDetail)}
                                to={auth.user.role === 3 ? "/imo/agent" : "/accounts/agent"}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={8} xl={9}>
                        <Typography variant="h2">
                            Agent Details - Agent {(accountDetail && accountDetail.firstName) + " " + (accountDetail && accountDetail.lastName)}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TabsMenu userId={userId} role={role} linkStats={linkStats} />
                    </Grid>
                </Grid>
            </DetailLayout>
    );
};

export const DetailBreadcrumb = ({ to, of, name }) => {
    return (
            <Breadcrumbs aria-label="breadcrumb" className="Breadcrumbs floatLeft w100 mb20 breadcrumbStyle-">
                <Link>{of}</Link>
                <Link component={RouterLink} to={to}>
                    {of} Details
                </Link>
                <Typography variant="subtitle2" className="mb0" color="textPrimary">
                    {name}
                </Typography>
            </Breadcrumbs>
    );
};

export const DetailLayout = ({ title, children }) => {
    const currentTheme = kaiZenTheme;
    let GlobalCss = KaiZenThemeGlobalCss;

    return (
            <MuiThemeProvider theme={currentTheme}>
                <GlobalCss />
                <PageTitle title={title} />
                <link rel="stylesheet" href={`${process.env.PUBLIC_URL + cssPaths.clientProfile}`}/>
                {children}
            </MuiThemeProvider>
    );
};

export const BackButton = ({ history }) => (
        <Link className="backBtn floatLeft w100" color="primary" onClick={() => history.goBack()}>
            <ArrowBackIosIcon />
            Back
        </Link>
);


const TabsMenu = ({ userId, role, linkStats }) => {
    const account = useSelector((state: RootState) => state.account);
    const auth = useSelector((state: RootState) => state.auth);

    const agentTabs = ["profile", "clients", "groups", "quick-view", "agent-links", "overrides", "documents"];
    const { value, setValue } = useTabWithLocation(agentTabs)

    const accountActions = useActions(AccountActions);

    const [totalInvites, setTotalInvites] = React.useState<number>(0);
    const [totalAccountCreated, setTotalAccountCreated] = React.useState<number>(0);
    const [enrolling, setEnrolling] = React.useState<number>(0);
    const [enrolled, setEnrolled] = React.useState<number>(0);
    const [activeUsers, setActiveUsers] = React.useState<number>(0);
    const [inActiveUsers, setInActiveUsers] = React.useState<number>(0);
    const [totalGroups, setTotalGroups] = React.useState<number>(0);
    const [totalAgentLinkViews, setTotalAgentLinkViews] = React.useState<number>(0);
    const [totalAgentLinkLeads, setTotalAgentLinkLeads] = React.useState<number>(0);
    const [lastLoggedIn, setLastLoggedIn] = React.useState<number>(0);
    const [dateJoinedIlia, setDateJoinedIlia] = React.useState<number>(0);

    let agentId = account.agent && account.agent.id;
    const handleExport = payload => accountActions.getAgentDashboardLists({ ...payload, agentUserId: userId });

    const getAgentDetails = async (agentId) => {
        if (!agentId) {
            return;
        }

        const data = await accountActions.getAgentStatistics(agentId);
        if (data) {
            setTotalInvites(data && data.totalInvites);
            setTotalAccountCreated(data && data.accountCreated);
            setEnrolling(data && data.currentlyEnrolling);
            setEnrolled(data && data.enrolled);
            setActiveUsers(data && data.activeUsers);
            setInActiveUsers(data && data.inActiveUsers);

            setTotalGroups(data && data.totalGroups);
            setTotalAgentLinkViews(data && data.totalAgentLinkViews);
            setTotalAgentLinkLeads(data && data.totalAgentLinkLeads);
            setLastLoggedIn(data && data.lastLoggedIn);
            setDateJoinedIlia(data && data.dateJoinedIlia);
        }
    };

    React.useEffect(() => {
        getAgentDetails(agentId).then(() => {
        });
    }, [agentId]);

    const classes = useStyles();

    const handleChange = async (event: React.ChangeEvent<{}>, newValue: string) => {
        if ((newValue === "profile" || newValue == "0") && role === 4) { //get agent detail again on tab change
            accountActions.getAccount(userId, role);
        }
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="scrollable force tabs example"
                        >
                            <LinkTab label="AGENT PROFILE" value={agentTabs[0]} />
                            <LinkTab label="AGENT CLIENTS" value={agentTabs[1]} />
                            <LinkTab label="AGENT GROUPS" value={agentTabs[2]} />
                            <LinkTab label="AGENT QUICK VIEW" value={agentTabs[3]} />
                            <LinkTab label="AGENT LINKS" value={agentTabs[4]} />
                            {(auth.user.role === 1 || auth.user.role === 2) &&
                                <LinkTab label={role === 4 ? "AGENT OVERRIDES" : role === 5 ?  "IMO OVERRIDES" : ""} value={agentTabs[5]} />
                            }
                            <LinkTab label="AGENT DOCUMENTS" value={agentTabs[6]} />
                        </Tabs>
                    </AppBar>
                </Grid>
                <Grid item xs={12} md={12} lg={8} >
                    <TabPanel value={value} index={agentTabs[0]}>
                        <AgentProfileTabReduxForm initialValues={account} userId={userId} />
                    </TabPanel>
                    <TabPanel value={value} index={agentTabs[1]}>
                        <AgentClientsTab agentUserId={userId} />
                    </TabPanel>
                    <TabPanel value={value} index={agentTabs[2]}>
                        <AgentGroupsTab agentUserId={userId} />
                    </TabPanel>
                    <TabPanel value={value} index={agentTabs[3]}>
                        <QuickViewSection title="QUICK VIEW">
                            <ClientQuickView as={QuickView}
                                        handleExport={handleExport} 
                                        totalInvites={totalInvites}
                                        totalAccountCreated={totalAccountCreated}
                                        enrolling={enrolling}
                                        enrolled={enrolled}
                                        activeUsers={activeUsers}
                                        inActiveUsers={inActiveUsers} />
                        </QuickViewSection>
                        <QuickViewSection title="LINK QUICK VIEW">
                            <AgentLinksStats stats={linkStats} user={{id: userId, role}}/>
                        </QuickViewSection>                        
                    </TabPanel>
                    <TabPanel value={value} index={agentTabs[4]}>
                        <LinksTable userId={userId}/>
                    </TabPanel>
                    {(auth.user.role === 1 || auth.user.role === 2) &&
                        <TabPanel value={value} index={agentTabs[5]}>
                            <Overrides userRole={4} />
                        </TabPanel>
                    }
                    <TabPanel value={value} index={agentTabs[6]}>
                        <AgentDocuments />
                    </TabPanel>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    {
                        auth.user && (can(auth.user, PermissionTypes.ACCOUNT_MANAGEMENT) && (auth.user.role === 1 || auth.user.role === 2))  &&    
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <AdminActions account={account} userId={userId} role={4}/>
                            </Grid>
                        </Grid>
                    }
                    <AgentSnapshot totalInvites={totalInvites}
                                   totalAccountCreated={totalAccountCreated}
                                   enrolling={enrolling}
                                   enrolled={enrolled}
                                   totalGroups={totalGroups}
                                   totalAgentLinkViews={totalAgentLinkViews}
                                   totalAgentLinkLeads={totalAgentLinkLeads}
                                   lastLoggedIn={lastLoggedIn}
                                   dateJoinedIlia={dateJoinedIlia}
                                   imoId={account && account.agent && account.agent.imoId}
                                   agentType={account && account.agent && getAgentTypeName(account.agent.agentType)}
                    />
                </Grid>
            </Grid>
        </div>
    );
};


const AgentSnapshot = ({
                           totalInvites, totalAccountCreated, enrolling, enrolled, totalGroups, totalAgentLinkViews, totalAgentLinkLeads, imoId,
                           lastLoggedIn, dateJoinedIlia, agentType
                       }) => {
    // TODO Commented unused
    // const imos = useSelector((state: RootState) => state.imos);
    // let imo = imos && imos.data && imos.data.find((item: any) => (item.id == imoId));

    return (
            <CardSnapshot title="Agent Snapshot">
                <CardContent className="floatLeft w100">
                    <List className="ClientDetailsList">
                        <li>
                            <label>Agent Type</label>
                            <span className="labelValue">{agentType}</span>
                        </li>
                        <li>
                            <label>Date Joined ilia</label>
                            <span className="labelValue">{dateJoinedIlia ? dateUtils.asDbDateFormat(dateJoinedIlia) : "NA"}</span>
                        </li>
                        <li>
                            <label>Last Logged In</label>
                            <span className="labelValue">{lastLoggedIn ? dateUtils.asDbDateFormat(lastLoggedIn) : "NA"}</span>
                        </li>

                        {/* <li>
                        <label>Current IMO</label> 
                        <span className="labelValue">
                            {
                                imo &&
                                <Link
                                    component={RouterLink}
                                    to={'/new-imo/detail/'+ imo.userId}
                                >
                                    {imo.groupName}
                                </Link>
                            }
                        </span>
                    </li>
                    <li>
                        <label>Total Invites Sent</label> 
                        <span className="labelValue">{totalInvites}</span>
                    </li>
                    <li>
                        <label>Total Accounts Created from Invites</label> 
                        <span className="labelValue">{totalAccountCreated}</span>
                    </li>
                    <li>
                        <label>Total Clients Currently Enrolling</label> 
                        <span className="labelValue">{enrolling}</span>
                    </li>
                    <li>
                        <label>Total Enrollments Completed</label> 
                        <span className="labelValue">{enrolled}</span>
                    </li>
                    <li>
                        <label>Total Agent Link Views</label> 
                        <span className="labelValue">{totalAgentLinkViews}</span>
                    </li>
                    <li>
                        <label>Total Agent Link Leads</label> 
                        <span className="labelValue">{totalAgentLinkLeads}</span>
                    </li>
                    <li>
                        <label>Total Groups</label> 
                        <span className="labelValue">{totalGroups}</span>
                    </li> */}
                    </List>
                </CardContent>
            </CardSnapshot>
    );
};

export default AgentProfile;