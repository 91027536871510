import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import { Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { EnrollmentQuestionProps } from "../../../../../../../model/enrollment";
import Info from "@material-ui/icons/Info";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import { Field, useFormikContext } from "formik";
import FocusError from "../../../../../../../../src/components/FocusError";
import { TextField } from "../../../../../../../../src/components/formikFormInputs";
import { Label } from "../../../../../../../../src/components/formInputs";
import debounce from "lodash/debounce";

interface SourceFundsFormProps {
    autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void;
    checkBoxObj: any,
    isWealthTransfer?: boolean
    clientSigned: boolean
    handleChangeCheckBox: Function
    enableSaveButton: Function
    sendSaveRequest?: Function
    checkBoxObjError: boolean
    isFromClientDetail: boolean
    questionList: any
    showComponent: Function
    sourceOfFundRef: any
    formType: string
    setFormType: Function
    setSaveSourceOfFundsForm: Function,
    setAnswerChanged: Function,
    answerChanged: boolean
}

function ErrorMessage(props: { name?: string }) {
    const { name } = props;
    return (
        <p style={{ margin: 0 }}>
            <span style={{ color: "#FF1744", fontSize: 12 }}> {name ? name : "Required Field "} </span>
        </p>
    );
}

function RenderChildField(props: { name: string, field: any, clientSigned: boolean }) {
    const { name, field, clientSigned} = props;
    const { values, setFieldValue }: any = useFormikContext();
    const setValueForDesc = debounce((name, value) => setFieldValue(name, value), 500);
    const handleChangeCheckBoxDesc = ({ target }) => {
        setValueForDesc(`${name}`, target.value);
    };
    return (
            <>
                <Field
                        className="mt10 ml15 mb15"
                        fullWidth
                        name={name}
                        component={TextField}
                        placeholder={field.placeholder}
                        label={<Label label={field.label} required={name === 'premiumFinancingDesc' ? false : true} />}
                        onChange={handleChangeCheckBoxDesc}
                        defaultValue={values[name]}
                        variant="outlined"
                        disabled={clientSigned}
                />
                {!values[name] && name !== 'premiumFinancingDesc' &&
                    <ErrorMessage />
                }
            </>
    );
}

function PremiumFinancing(props: { clientSigned: boolean }) {
    const { clientSigned } = props;
    const { handleSubmit, values, setFieldValue, errors }: any = useFormikContext();
    const setValueForDesc = debounce((name, value) => setFieldValue(name, value), 500);
    const handleChangeDesc = ({ target }) => {
        setValueForDesc(`lenderFundsDesc`, target.value);
    };

    const loanRadio = [
        {
            label: "Recourse",
            value: "Recourse",
        }, {
            label: "Non-recourse",
            value: "Non-recourse",
        }
    ];

    const clientObligated = [
        {
            label: "Yes",
            value: "Yes",
        }, {
            label: "No",
            value: "No",
        }
    ];

    return (
                <form className="floatLeft w100" onSubmit={handleSubmit}>
                    <Field
                            className="mt10 ml15 mb15"
                            fullWidth
                            name={`lenderFundsDesc`}
                            component={TextField}
                            placeholder={"Name of the company who is administering the premium finance"}
                            label={<Label label={"Name of the company who is administering the premium finance"}
                                          required={true} />}
                            onChange={handleChangeDesc}
                            defaultValue={values[`lenderFundsDesc`]}
                            variant="outlined"
                            disabled={true}
                    />
                    {errors[`lenderFundsDesc`] &&
                            <ErrorMessage />
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2" component="strong"
                                        className="floatLeft mt30 w100">
                                <Label label={"What type of loan?"} required={false} /></Typography>
                        </Grid>
                        {
                            loanRadio.map((item, index) => {
                                return (
                                        <Grid item xs={12} lg={4} xl={3}>
                                            <FormControlLabel className="formikRadio ml0" key={index}
                                                              control={<Field className="formikRadio ml0" type="radio"
                                                                              name={"loanRadio"}
                                                                              value={item && item.value} />}
                                                              label={<><Typography
                                                                      variant="caption">{item && item.label}</Typography></>}
                                                              disabled={true}
                                            />
                                        </Grid>);
                            })
                        }
                        {errors[`loanRadio`] &&
                                <Grid item xs={12}>
                                    <ErrorMessage />
                                </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography variant="body2" component="strong"
                                        className="floatLeft mt30 w100">
                                <Label label={"Is the client obligated to repay the loan?"}
                                       required={false} /></Typography>
                        </Grid>
                        {
                            clientObligated.map((item, index) => {
                                return (
                                        <Grid item xs={12} lg={4} xl={3} key={index}>
                                            <FormControlLabel className="formikRadio ml0"
                                                              control={<Field className="formikRadio ml0" type="radio"
                                                                              name={"clientObligated"}
                                                                              value={item && item.value} />}
                                                              label={<><Typography
                                                                      variant="caption">{item && item.label}</Typography></>}
                                                              disabled={true}
                                            />
                                        </Grid>
                                );
                            })
                        }
                        {errors[`clientObligated`] &&
                                <Grid item xs={12}>
                                    <ErrorMessage />
                                </Grid>
                        }
                    </Grid>
                </form>
    );
}

function SourceFundsForm(props: SourceFundsFormProps) {
    const {
        answerChanged,
        autoSaveTrigger,
        checkBoxObj,
        checkBoxObjError,
        clientSigned,
        enableSaveButton,
        formType,
        handleChangeCheckBox,
        isFromClientDetail,
        isWealthTransfer,
        questionList,
        sendSaveRequest,
        setAnswerChanged,
        setFormType,
        setSaveSourceOfFundsForm,
        showComponent,
        sourceOfFundRef,
    } = props;

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        handleChange,
        handleBlur,
        dirty,
    }: any = useFormikContext();

    React.useEffect(() => {
        let deBounceFn;
        if (dirty && touched) {
            if (!!formType && typeof setFormType === "function") {
                setFormType(formType);
            }
            const errArray: any = Object.values(errors);
            const errKeyVal = errArray.find((err) => err.toLowerCase() !== "required field");
            if (!(!!errKeyVal)) {
                deBounceFn = setTimeout(() => {
                    if (typeof setSaveSourceOfFundsForm === "function") {
                        setSaveSourceOfFundsForm(values);
                    }
                }, 1000);
            }
        }
        return () => {
            if (deBounceFn) {
                clearTimeout(deBounceFn);
            }
        };
    }, [values, errors]);
    return (

                <form id={"sourceOfFunds"} className="floatLeft w100 pb100" onBlur={autoSaveTrigger} onSubmit={handleSubmit}>
                    <FocusError offset={true}/>
                    <FormGroup row className="mt30 mb30">
                        <Grid container spacing={2}>
                            {
                                checkBoxObj.map((item, index) => {
                                            let wt: boolean | undefined = ((item && item.name === "wealthGiftTransfer") && isWealthTransfer);  // if it is the wealth transfer case than the value "Wealth-Gift Transfer is auto-selected and disabled NIWC-2038
                                    let disabled = !!(clientSigned || wt || (item.name === 'premiumFinancing'));
                                            return (<>
                                                <Grid item xs={12} sm={12} md={12}
                                                      lg={(values && values[`${item.name}`]) && (item.showDetailsBox === true) ? 12 : (values && values[`${item.name}`] && (item.name === 'premiumFinancing') ) && (item.showDetailsBox === false) ? 12 : 6}
                                                      xl={(values && values[`${item.name}`]) && (item.showDetailsBox === true) ? 12 : (values && values[`${item.name}`] && (item.name === 'premiumFinancing') ) && (item.showDetailsBox === false) ? 12 : 4}
                                                      key={index}>
                                                    <FormControlLabel
                                                            className="checkGroupMb0"
                                                            control={<Checkbox className="padding0"
                                                                               checked={(item.name === 'premiumFinancing') ? true : values && values[`${item.name}`] ? true : false}
                                                                   disabled={disabled}
                                                                               onChange={(e) => {
                                                                                   setFieldValue(`${item.name}`, e.target.checked);
                                                                                   handleChangeCheckBox(e, values);
                                                                                   enableSaveButton();
                                                                                   if (typeof sendSaveRequest === "function") {
                                                                                       sendSaveRequest(Math.random());
                                                                                   }
                                                                                   if((values && values[`${item.name}`]) && (item.showDetailsBox === true)){
                                                                                       setFieldValue(`${item.name}Desc`, "");
                                                                                   }
                                                                                   if((values[`${item.name}`]) && (item.name === 'premiumFinancing') ){
                                                                                       setFieldValue("lenderFundsDesc", "");
                                                                                       setFieldValue("loanRadio", "");
                                                                                       setFieldValue("clientObligated", "");
                                                                                   }

                                                                               }}

                                                                               color={"primary"}
                                                                               value={values[`${item.name}`] ? true : false}
                                                                               name={`${item.name}`}/>}
                                                            label={`${item.label}`}
                                                    />
                                                    {
                                                            ((values && values[`${item.name}`]) && (item.showDetailsBox === true)) &&
                                                            <RenderChildField name={`${item.name}Desc`} field={item}
                                                                              clientSigned={clientSigned}/>
                                                    }
                                                    {
                                                            (values && values[`${item.name}`] && item.name === "premiumFinancing") &&
                                                            <PremiumFinancing clientSigned={clientSigned}/>
                                                    }
                                                </Grid></>);
                                        },
                                )
                            }
                            {checkBoxObjError && !isFromClientDetail &&
                                    <ErrorMessage />
                            }
                        </Grid>
                    </FormGroup>
                    {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                            <Grid item xs={12} sm={12} md={12} key={question.key}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={9}>
                                        <Typography component="strong" variant="body1"
                                                    className="mb0 floatLeft">{question.label}</Typography>
                                        {question.infoTooltip &&
                                                <Tooltip title={<Typography
                                                        className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                                    <IconButton>
                                                        <Info color="primary"/>
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={3}>
                                        <ToggleButtonGroup
                                                className="toggleButtonGroupQust floatRight"
                                                size="medium"
                                                color={"primary"}
                                                exclusive
                                                id={question.name}
                                                value={values[question.name] === "Yes" ? "Yes" : values[question.name] == undefined ? "" : "No"}
                                                onChange={(ev: any, value: any) => {
                                                    if (value === "Yes") {
                                                        setFieldValue(question.name, value);
                                                    }
                                                    if (value === "No") {
                                                        setFieldValue(question.name, value);
                                                    }
                                                    setAnswerChanged(!answerChanged);
                                                    enableSaveButton();
                                                    if (typeof sendSaveRequest === "function") {
                                                        sendSaveRequest(Math.random());
                                                    }
                                                }}
                                        >
                                            <ToggleButton value={"Yes"}
                                                          disabled={clientSigned}
                                                          className={values[question.name] === "Yes" ? "active" : ""}>
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton value={"No"}
                                                          disabled={clientSigned}
                                                          className={values[question.name] === "No" ? "active" : ""}>
                                                No
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                    {errors[question.name] &&
                                        <ErrorMessage />
                                    }
                                </Grid>
                                
                                {question && question.additionalRemarks &&
                                        <Grid item xs={12}>
                                            <div
                                                    key={`${idx}-${question.key}-remarks`}
                                            >
                                                {
                                                    showComponent(question, values, touched, errors, setFieldTouched,
                                                            setFieldValue, handleChange, handleBlur, clientSigned)
                                                }

                                            </div>
                                        </Grid>
                                }
                                {(!(question && question.notShowHrLine)) && <span className="hLine"/>}
                            </Grid>
                            
                    ))}

                    <Button
                            variant="contained"
                            color="primary"
                            className="detailPageSubmitBtn"
                            type={"submit"}
                            ref={sourceOfFundRef}
                            style={{
                                display: "none",
                            }}
                    >
                    </Button>
                </form>


    );
}

export default SourceFundsForm;