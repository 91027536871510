import React from "react";
import { createStyles, makeStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, IconButton, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { dateUtils } from "../../../utils";
import { maskCurrency } from "../../../utils/commonUtils";
import TableWrap from "../../../components/TableWrap/TableWrap";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            Paper: {
                minWidth: 767,
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: 20,
                color: theme.palette.grey[800],
            },
        });


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
    KpiModalRow: {
        textDecoration: "none",
    }
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function LeadsModal(props: any) {
    const { open, handleClose, modalValue, modalTitle, kpiData, exportList } = props;
    const classes = useStyles();
    return (
            <div>
                <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {modalTitle} ({kpiData.length})
                    </DialogTitle>
                    <DialogContent className="pd0">
                        <TableWrap>
                                <TableHead className={classes.tableHead}>
                                    {modalValue === "LeadsClosed" ?
                                        <TableRow>
                                            <TableCell align="left">Client Name</TableCell>
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left">ilia Product Type</TableCell>
                                            <TableCell align="left">Carrier</TableCell>
                                            <TableCell align="left">Date Closed</TableCell>
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left">ilia Product Type</TableCell>
                                            <TableCell align="left">Carrier</TableCell>
                                            <TableCell align="left">Date</TableCell>
                                            <TableCell align="left">Age</TableCell>
                                            <TableCell align="left">Gender</TableCell>
                                            <TableCell align="left">Household Income</TableCell>
                                            <TableCell align="left">Health</TableCell>
                                        </TableRow>
                                    }
                                </TableHead>
                                <TableBody>
                                    {
                                        kpiData.map((v, i) => {
                                            if(modalValue === "LeadsClosed") {
                                                return (
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                {`${v.firstName} ${v.lastName}`}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {v.email}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {v.strategy && v.strategy.name}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {v.carrier && v.carrier.carrierName}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {dateUtils.asDbDateFormat(v.createdAt)}
                                                            </TableCell>
                                                        </TableRow>
                                                );
                                            }
                                            return (
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            {v.email}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {v.strategy && v.strategy.name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {v.carrier && v.carrier.carrierName}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {dateUtils.asDbDateFormat(v.createdAt)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {v.age}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {v.gender === 1 ? "Male" : "Female"}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {maskCurrency(v.householdIncome)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {v.healthType}
                                                        </TableCell>
                                                    </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                        </TableWrap>
                    </DialogContent>
                    <DialogActions className="DialogActions textCenterSm">
                        <Button autoFocus className={"floatLeft floatNone mt20 mb15"} onClick={handleClose} color="secondary"
                                variant="contained">
                            Cancel
                        </Button>
                        <Button autoFocus className={"floatRight floatNone mt20 mt5"} onClick={() => exportList(modalValue)}
                                color="primary" variant="contained">
                            Export List
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
    );
}