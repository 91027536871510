import { ActionType } from "../model";

export const listMessages = ({ chatRoomId, page }) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get(`chat/${chatRoomId}`, {}, { page });
        return result;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const sendMessage = ({ content, type, chatRoomId }) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		await api.post(`chat/${chatRoomId}/message`, { content, type }, {});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const connectToChatRoom = ({ chatRoomId }) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		await api.post(`chat/${chatRoomId}/connection`, { }, {});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const listLastConnections = ({ chatRoomId }) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get(`chat/${chatRoomId}/connection`, {}, { });
        return result;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};
