import * as React from "react";
// @ts-ignore
import InputMask from  "react-input-mask";
import TextField from '@material-ui/core/TextField';
import { FormInputProps } from '../../model';

const phoneNumberField = ({
							  input,
								disabled,
							  meta: { touched, invalid, error },
							  ...custom
						  }: FormInputProps) => (
	<InputMask
		disabled={disabled}
		mask="(999) 999-9999"
		{...input}
		{...custom}
	>
		{(inputProps:any) =>
			<TextField
				{...inputProps}
				error={touched && invalid}
				helperText={touched && error}
				type="tel"
				disabled={disabled}
                variant="outlined"
                inputProps={{inputMode: 'numeric'}}
			/>
		}
	</InputMask>
);

export default phoneNumberField;


