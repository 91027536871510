import * as React from "react";
import { List, ListItem, ListItemText, Typography, Box, withStyles, Tooltip } from "@material-ui/core";
import NumberFormat from "react-number-format";
import Info from "@material-ui/icons/Info";

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: "#2f2f2f",
        maxWidth: "767px",
        minWidth: "100px",
        color:'#ffccc',
        lineHeight:'18px',
        fontWeight: "normal",
        padding: "20px",
        margin:"0",
        boxShadow:'0 0 15px 0px #8c8c8c'
    },
}))(Tooltip);
export default function EstimationResult(props: any) {
	return (
		<List className="listView">
			<ListItem alignItems="flex-start">
				<ListItemText
					primary={<Typography variant="h6">{props.title} </Typography>}
				/>
				<ListItemText
					primary={<NumberFormat value={props.annual_income} displayType={"text"}
										   thousandSeparator={true}
										   prefix={"$"}
										   renderText={value => <Box component="strong" m={1}><strong>{value}
											   <HtmlTooltip
                                                       PopperProps={{ style :{ zIndex:9999 } } }
												   title={
													   <React.Fragment>
														   <Typography className="f14 txtWhite mb0">This is an estimation of policy distributions taken
															   annually.
															   {(props.index === 2) &&
															   ` It is assumed that investments are held until the money is taken out,
															   so there are no trade costs or interim taxes,
															   which would reduce this number further.
															   The self investment fund is $0 at end of age 90.`
															   }
															   {
																   (props.index === 3) &&
																   ` The estimator assumes the pretax equivalent of the value you put for client contribution. 
																	For example if you put $20,000 in for ${props.strategyName}, the value for tax deferred would be $31,746 ($20,000/ 0.63). 
																	It is then taxed at ordinary income rates when the money is taken out, as
																	in the case of deferred compensation.`
															   }
														   </Typography>
													   </React.Fragment>
												   }
											   >
												  <span className="f12 icon pl10"><Info
													  className="" color="primary"/> </span>
											   </HtmlTooltip>
										   </strong></Box>}/>}
					secondary={
						<React.Fragment>
							<Typography
								component="span"
								variant="body2"
								color="textPrimary"
							>
								Potential Annual<br/> Distributions Age {props.initAge}-90
							</Typography>
						</React.Fragment>
					}
				/>
				<ListItemText
					primary={<NumberFormat value={props.total_income} displayType={"text"} thousandSeparator={true}
										   prefix={"$"}
										   renderText={value => <Box component="strong" m={1}><strong>{value}
											   <HtmlTooltip
                                                       PopperProps={{ style :{ zIndex:9999 } } }
												   title={
													   <React.Fragment>
                                                           <Typography className="f14 txtWhite mb0">
                                                               {(props.index === 0 || props.index === 1) &&
                                                                    "This as the cumulative total of the planned income distributions (policy loans) illustrated at the start of the plan.  You are not stuck with this schedule and can vary it as needed through the plan’s life, nor are the amounts guaranteed as it will be based on performance over time."
                                                               }
                                                               {(props.index === 2 || props.index  === 3) &&
                                                               "This is the total amount of distributions taken through age 90."
                                                               }
                                                           </Typography>
													   </React.Fragment>
												   }
											   >
												  <span className="f12 icon pl10"><Info
													  className="" color="primary"/> </span>
											   </HtmlTooltip>
										   </strong></Box>}/>}
					secondary={
						<React.Fragment>
							<Typography
								component="span"
								variant="body2"
								color="textPrimary"
							>
								Potential Total Distributions
							</Typography>
						</React.Fragment>
					}
				/>
				<ListItemText
					primary={<NumberFormat value={props.irr} displayType={"text"} thousandSeparator={false}
										   suffix={"%"}
										   renderText={value => <Box component="strong"
																	 m={1}><strong>{value}
											   <HtmlTooltip
                                                       PopperProps={{ style :{ zIndex:9999 } } }
												   title={
													   <React.Fragment>
                                                           <Typography className="f14 txtWhite mb0">
                                                               {(props.index === 0 || props.index === 1) &&
                                                                "IRR (internal rate of return) is the return on your investment net of taxes and costs useful for comparing investments of the same risk profile.  Stock portfolio would be a higher risk with greater chance of loss whereas bonds would be a lower risk. IRR includes residual death benefit assuming a death at age 90."
                                                               }
                                                               {(props.index === 2 || props.index === 3) &&
                                                               "IRR (internal rate of return) is the return on your investment net of taxes and costs useful for comparing investments of the same risk profile.  Stock portfolio would be a higher risk with greater chance of loss whereas bonds would be a lower risk."
                                                               }
                                                           </Typography>
													   </React.Fragment>
												   }
											   >
												  <span className="f12 icon pl10"><Info
													  className="" color="primary"/> </span>
											   </HtmlTooltip>
										   </strong></Box>}/>}
					secondary={
						<React.Fragment>
							<Typography
								component="span"
								variant="body2"
								color="textPrimary"
							>
								IRR
							</Typography>
						</React.Fragment>
					}
				/>
			</ListItem>
			<ListItem alignItems="flex-start">
				<ListItemText
					primary={<React.Fragment>
						<NumberFormat className="floatLeft" value={props.initial_death_benefit} displayType={"text"}
									  thousandSeparator={true} prefix={"$"}
									  renderText={value => <Box component="strong"
																m={1}>
										  <strong>{value}
											  {props.showToolTip && <HtmlTooltip
                                                      PopperProps={{ style :{ zIndex:9999 } } }
												  title={
													  <React.Fragment>
                                                          <Typography className="f14 txtWhite mb0">
                                                              {(props.index === 0 || props.index === 1) &&
                                                                  "This is the death benefit initially applied for with the life insurance company.  It is needed as this is the amount the life insurance company is underwriting you for.  This is different from the death benefit you will likely receive, because it does not take into account the bank loan or any changes over time due to policy performance or reductions resulting from policy loans you take in the form of income or the policy loan used to repay the third party lender."
                                                              }
                                                          </Typography>
													  </React.Fragment>
												  }
											  >
												  <span className="f12 icon pl10"><Info
													  className="" color="primary"/> </span>
											  </HtmlTooltip>
											  }
										  </strong>
									  </Box>}/>
					</React.Fragment>
					}

					secondary={
						<React.Fragment>
							<Typography
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{props.initial_death_benefit === 0 ? "No Death Benefit" : "Potential Initial Death Benefit"}
							</Typography>

						</React.Fragment>
					}
				/>
				<ListItemText
					primary={<NumberFormat value={props.death_benifit_yr90} displayType={"text"}
										   thousandSeparator={true} prefix={"$"}
										   renderText={value => <Box component="strong" m={1}><strong>{value}
											   {props.showToolTip && <HtmlTooltip
                                                       PopperProps={{ style :{ zIndex:9999 } } }
												   title={
													   <React.Fragment>
                                                           <Typography className="f14 txtWhite mb0">
                                                               {(props.index === 0) &&
                                                                "The amount paid to a beneficiary upon the death of an insured person.  Death benefits start out higher depending on your age and heath.  The amount shown here is the remaining amount at age 90 after the policy distributions."
                                                               }
                                                               {(props.index === 1) &&
                                                               "The amount paid to a beneficiary upon the death of an insured person.  Death benefits start out higher depending on your age and heath.  The amount shown here is the remaining amount at age 90 after the policy distributions and net of the split dollar employer loan repayment."
                                                               }
                                                           </Typography>
													   </React.Fragment>
												   }
											   >
												   <span className="f12 icon pl10"><Info className="" color="primary"/> </span>
											   </HtmlTooltip>
											   }
										   </strong></Box>}/>}
					secondary={
						<React.Fragment>
							<Typography
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{props.death_benifit_yr90 === 0 ? "No Death Benefit" :
									<React.Fragment>Potential Death Benefit<br/> Remaining After
										Distributions<br/> Through Age 90</React.Fragment>}
							</Typography>
						</React.Fragment>
					}
				/>
			</ListItem>
		</List>
	);
}