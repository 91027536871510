import React from "react";
import { Typography } from "@material-ui/core";

type CCLProps = { require: boolean, name: string }

const CommonCustomLabel = ({ require, name }: CCLProps) => {
    return (
            <Typography variant={"body2"} component="strong" className="floatLeft w100">
                {name}
                {
                        require &&
                        <span className="requiredHighlight">*</span>
                }
            </Typography>
    );
};

export default CommonCustomLabel;