import * as React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
interface FieldProps {
    field: {
        name: string,
        onChange: Function,
    },
    form:{
        setFieldValue: Function,
        values: any
    }
    placeholder: string,
    label: string,
    value: string,
    helperText?: string,
    cb?: Function
}
const Number = (props: FieldProps) => {
    const { field: { name }, form:{ setFieldValue, values}, placeholder, label, value, cb, ...custom } = props;
    return <TextField
        fullWidth
        name={name}
        placeholder={placeholder}
        label={label}
        value={value}
        type="number"
        {...custom}
        variant="outlined"
        InputProps={{
            startAdornment: <InputAdornment className="dollerIcon"
                position="start">$</InputAdornment>,
        }}
        onBlur={(e) => {
            if (typeof cb === "function") {
                cb(e, value ,setFieldValue, values);
            }
        }}
    />;
};

export default Number;