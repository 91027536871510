import React from "react";
import { Field, useFormikContext } from "formik";
import debounce from "lodash/debounce";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid, Typography } from "@material-ui/core";
import { TextField } from "../../../../../../../components/formikFormInputs";
import { Label } from "../../../../../../../components/formInputs";
import { ErrorHelper } from "../../../../../../../components/formikFormInputs/ErrorCommon";

/**
 * @param props
 * name:string
 * field:any
 * values:any
 * setFieldValue:Function
 * clientSigned:boolean
 * @constructor
 * return Component used to render a TextField component
 * associated with a specific radio button option.
 */
const RenderChildField = (props:{name:string, field:any, values:any, setFieldValue:Function, clientSigned:boolean}) => {
    const {name, field, setFieldValue, values, clientSigned} = props;

    const setValueForRadioDesc = debounce(value => setFieldValue(name, {value: values[name].value, desc: value}), 500);

    const handleChangeCheckBoxDesc = ({ target }) => setValueForRadioDesc(target.value);

    const setDefaultValueForDesc = () => {
        return  values[name].desc || "";
    }

    return <Field
            fullWidth
            name={`${name}_desc`}
            component={TextField}
            placeholder={field.placeholder}
            label={<Label label={field.label} required={true}/>}
            onChange={handleChangeCheckBoxDesc}
            defaultValue={setDefaultValueForDesc()}
            variant="outlined"
            disabled={clientSigned}
    />
}

/***
 * This component is designed to handle a radio
 * button group with dynamic options and additional
 * fields for selected options
 * @param clientSigned
 * @param question
 * @constructor
 */
const InsuredPageRadioButton = ({ clientSigned, question }: { question: any, clientSigned: boolean }) => {
    const { values, setFieldValue, errors }: any = useFormikContext();

    const name:string = question && question.name;

    const handleRadioClick = (e:any) => {
        setFieldValue(name, {value: e.target.value, desc:""})
    }

    const isChecked = (values, key, fieldValue) => {
        let accessArray = key.split(".");
        if (accessArray.length > 1) {
            let accessValue = values;
            for (let k of accessArray) {
                accessValue = accessValue[k] || "";
            }
            return ((accessValue && accessValue.value) === fieldValue);
        } else {
            return ((values && values[key] && values[key].value) === fieldValue);
        }
    }


    return (
            <>
                    {question && question.additionalFieldOptions && question.additionalFieldOptions.map((field:any, index:number) => {
                        return (
                                <Grid item xs={12} lg={6} xl={6}>
                                    <FormControlLabel className="formikRadio mt5 ml15" key={index}
                                                      control={<Field onChange={(e) => handleRadioClick(e)}
                                                                      checked={(values && isChecked(values, name, field.value))}
                                                                       className="formikRadio ml0" type="radio" name={name} value={field.value} />}
                                                      label={<Typography variant="body1" className="mb0">{field.text}</Typography>}
                                                                     
                                                      disabled={clientSigned}
                                    />
                                    {
                                            ((values && values[name] && values[name].value === field.value) && field.showDetailsBox) &&
                                            <div className={"mt10"}>
                                                <RenderChildField
                                                        clientSigned={clientSigned}
                                                        field={field}
                                                        name={name}
                                                        setFieldValue={setFieldValue}
                                                        values={values}
                                                />
                                                <ErrorHelper errors={errors} name={`${name}_desc`} />
                                            </div>
                                    }
                                </Grid>
                        )
                    })}

                    <ErrorHelper errors={errors} name={name} />
            </>
    );
};

export default InsuredPageRadioButton;

