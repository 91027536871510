import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import StrategyCarrierImoAdvisor from "./StrategyCarrierImoAdvisor";
import GreatWealthTransferDetails from "../GreatWealthTransferDetails";
import { Button } from "../../../../../components/formInputs";
import { FormikValues } from "../../../../../components/formikFormInputs/FormWrapper";
import { RootState } from "../../../../../reducers";
import { Account } from "../../../../../model/account";
import { GWT_GEN2_STRATEGY } from "../../../../../constants";


const GWTForm: React.FunctionComponent = ({ dirty, gen1User, handleSubmit, isSubmitting, setGen1User }: FormikValues) => {
	const detail: Account = useSelector((state: RootState) => state.clientAccount);
	const strategyId = detail && detail.client && detail.client.strategyId;
	const agentSigned = detail && detail.enrollment && detail.enrollment.agentSigned;
	const isGWTGen2 = (strategyId === GWT_GEN2_STRATEGY);
	const stickyButtonStyle = { display: dirty ? "block" : "none", transition: dirty ? "height 2s ease-in-out 2s" : "none" };

	return (
		<form onSubmit={handleSubmit}>
			<Typography variant="body1" className="mt40">
				<strong> Ilia Product Type, Agent, IMO and Carrier </strong>
			</Typography>

			<StrategyCarrierImoAdvisor />

            {
                    isGWTGen2 &&
                    <GreatWealthTransferDetails
                            agentSigned={!!agentSigned}
                            gen1User={gen1User}
                            setGen1User={setGen1User}
                    />
            }

			<Grid item xs={12}>
				<div className="stickyFooter" id={"stickyFooter"} style={stickyButtonStyle}>
					<Typography className="mb0" variant="button">
						Make sure to save your changes.
						<Button
							className="ml20"
							color="primary"
							disabled={isSubmitting}
							label="Save Changes"
							size="large"
							type="submit"
							variant="contained"
						/>
					</Typography>
				</div>
			</Grid>
		</form>
	);
};

export default GWTForm;
