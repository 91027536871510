import {EnrollmentQuestionProps} from "../../../../../../../model/enrollment";
import {isIliaApp, isLongForm} from "../../Utilities";

const getSourceOfFundsQuestions = (props: { offerType: string }) => {
    const {offerType} = props;
    const sourceOfFundsQuestions: any[] = [
         // NIWC-2276
        {
            key: "1",
            name: "liquidAssets",
            label: "Do you feel that sufficient liquid assets are available for living expenses and emergencies in addition to the money allocated to pay the life insurance premiums?",
            type: "radio",
            default: false,
            onYes: false,
            additionalRemarks: false,
            showRemarks: false,
            additionalFieldType: 'checkbox',
            additionalFieldRequired: false,
            show: isLongForm(offerType),
            notShowHrLine: true
        }
    ]
    return sourceOfFundsQuestions;
}

export default getSourceOfFundsQuestions;