import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { dateUtils } from "../utils";

const styles: any = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "10px",
        top: "16px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle: any = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="body1" component="strong">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

type PropsType = {
    notificationBox: boolean,
    setNotificationBox: Function,
    individualNotification: any
};

export default function IliaNotificationPopUp(props: PropsType) {
    const { setNotificationBox, notificationBox, individualNotification } = props;
    const handleClose = () => {
        setNotificationBox(false);
    };

    function urlify(text) {
        let urlRegex = /( https?:\/\/[^\s]+)/g;
        // text = text.replace(/(<p>https?:\/\/[^\s]+)/g, function(url) {
        //     // url = url.replace("<p>", '');
        //     let textLength = url.split("<");
        //     console.log("****textLength***", textLength);
        //     return '<a target="_blank" href="' + url + '">' + url + '</a>';
        // })
        if(text) {
            return text.replace(urlRegex, function (url) {
                url = url.replace("</p>", '');
                return '<a target="_blank" href="' + url + '">' + url + '</a>';
            })
        }
        return "";
    }

    return (
            <div>
                <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={notificationBox}>
                    <DialogTitle className="closeSvg" id="customized-dialog-title" onClose={handleClose}>
                        Notification Detail
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1" className="f18" align="left">
                            Complete Notification Detail
                        </Typography>
                        {
                            individualNotification &&
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography component="strong" variant="body1" className="floatLeft w100 mb0">
                                        {/* <PeopleAltIcon className="floatLeft mr5"/> Upgrades to Groups */}
                                        {individualNotification.title}
                                    </Typography>
                                    <div className="iliaNotificationPopUp" dangerouslySetInnerHTML={{ __html: urlify(individualNotification.text) }}>
                                    </div>
                                    <Typography  variant="body2" align="left" className="floatLeft w100 mb0">
                                        {dateUtils.asDbDateFormat(individualNotification.createdAt)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        }

                    </DialogContent>
                </Dialog>
            </div>
    );
}