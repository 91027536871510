
import React, { useState } from "react";
import { useActions } from '../../../actions';
// import Button from "@material-ui/core/Button";
import ClientDocments from "../ClientDocumentsTable";
import * as Aws from "../../../actions/aws";
import * as AccountActions from "../../../actions/account";
import { CircularProgress } from "@material-ui/core";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
export interface reportsInterFace {
    path:string,
    name:string,
    bucket:string
} 

const delStyle = {cursor: "pointer", verticalAlign: "bottom"};
// reportsLoading is refer to files creating process running 
export const UploadedDocumentTable = (props:{ reports:reportsInterFace[] | undefined  , reportsLoading ?: boolean  , onfetchFiles?:Function , onRemove?:Function}) =>{
   
    const { reports} = props
    const [dataLoading, setDataLoading] = useState(false);
    const awsActions = useActions(Aws);
    
    const getSignedUrl = async (objData:any) => {
        const {path, name} = objData;
        let file = name.split('.');
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ('text/pdf'):("image/jpg, image/jpeg, image/png, application/pdf");
        let queryObj = {
            objectName: name,
            contentType: contentType,
        };
        let pdfFile = await awsActions.getSecureUrl(path, queryObj, true);
        if(!!pdfFile) {
            showPdfFile(pdfFile);
        }
    }


    const showPdfFile = (pdfurl:string) => {
            window.open(pdfurl);
    }
    if(props.reportsLoading || dataLoading){
       return <CircularProgress />
    }
  

    return (
        <ClientDocments 
            columns={['Name', 'Date Created', 'Delete']}
            reportData={reports} 
            onClickDocument={(_: number, data) => {
                getSignedUrl(data).then(() => { })
            }}
            icon={(<DeleteForeverIcon color="error" style={delStyle}/>)}
            showDownloadOption={true}
            onClickDownload={(_: number, data) => {
                props.onRemove && props.onRemove(_, data)
                // getSignedUrl(data).then(() => { })
            }}
        />
    )
}