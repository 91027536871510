import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "../../../../../components/formInputs";

function Alert(props) {
    return <MuiAlert className="w100 floatLeft" elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop:15,
        marginBottom:20,
        float:'left',
    },
    pd10:{
        padding:10
    }
}));

type PropsType = {
    setUploadMoreDocs: Function,
    setUploadCoverLetter: Function,
    setUploadAdditionalTaxDocs: Function
}
export default function CoverLetterSnackBar(props: PropsType) {
    const classes = useStyles();
    const {setUploadMoreDocs, setUploadCoverLetter, setUploadAdditionalTaxDocs} = props;
    return (
            <div className={classes.root}>
                <Alert  action={
                    <Button
                            type="button"
                            variant="contained"
                            label="Upload Cover Letter"
                            color="primary"
                            onClick={() => {
                                setUploadCoverLetter(true)
                                setUploadMoreDocs(true);
                                setUploadAdditionalTaxDocs(false);
                            }}/>
                } className={classes.pd10 + " w100 floatLeft "} severity="error" variant="outlined">This case requires a cover letter. Please update a cover
                    letter.
                </Alert>
            </div>
    );
}
