import * as React from "react";
import OverRidesform from "./OverRidesForm";
import { Grid } from "@material-ui/core";

interface OverRideProps {
    userRole: number
}

function Overrides(props:OverRideProps) {
    const { userRole } = props;
    return (
            <Grid container spacing={2} className="mb20">
                <Grid item xs={6}>
                    <OverRidesform role={userRole} />
                </Grid>
            </Grid>
    )
}

export default Overrides;