import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";
import UploadDocuments from "../modals/UploadDocuments";
import { Button as CustomButton} from "../../../../../components/formInputs";
import Confirmation from "./Confirmation";
export default function ConfirmUploadDialog(props: { document: any, clientDetail: any, confirmDialog: boolean, setConfirmDialog: Function, handleRefresh: Function }) {
    const { confirmDialog, setConfirmDialog, clientDetail, handleRefresh, document } = props;
    const [uploadDoc, setUploadDoc] = React.useState(false);
    const handleClose = () => {
        setConfirmDialog(false);
    };

    return (
            <div>
                {
                    !uploadDoc &&
                    <Confirmation 
                        confirmDialog={confirmDialog}
                        onCancel={{
                            onClick:() => {
                                     handleClose();
                                }
                        }}
                        onConfirm={{
                            onClick:() => {
                                        setUploadDoc(true);
                                    }
                        }}
                    />
                }
                {
                    uploadDoc &&
                    <Dialog
                            fullWidth
                            maxWidth={"sm"}
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                    >
                        <Typography variant="h6" className="mt10 mb15 ml15"> Document Upload </Typography>
                        <DialogContent dividers>
                            <UploadDocuments document={document} clientDetail={clientDetail} setUploadMoreDocs={setUploadDoc}
                                             handleRefresh={handleRefresh} handleClose={handleClose}/>
                            <CustomButton
                                    size="small"
                                    className="floatRight mt20"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    label="Close"
                                    onClick={() => {setUploadDoc(false); handleClose()}}
                            >
                                
                            </CustomButton>
                        </DialogContent>
                    </Dialog>
                }
            </div>
    );
}
