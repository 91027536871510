import createReducer from "./createReducer";
import {Action, ActionType} from "../model";
import {Training} from "../model/training";

export const training = createReducer([], {
    [ActionType.FETCH_TRAINING](state: Training, action: Action<Training>) {
        return action.payload;
    },

    [ActionType.FLUSH_TRAINING](state: Function, action: Action<void>) {
        return action.payload;
    }
});


