import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import * as Yup from "yup";
import GWTForm from "../forms/GWTForm";
import FormWrapper, { FormikValues } from "../../../../../components/formikFormInputs/FormWrapper";
import { BS, NS } from "../../../../../model/misc";
import { RootState } from "../../../../../reducers";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import * as ClientActions from "../../../../../actions/client";
import { isFunc, joinAsFullName, pez, sleep } from "../../../../../utils/commonUtils";
import { GWT_GEN2_STRATEGY, REQUIRED_FIELD } from "../../../../../constants";
import { getQueryStringValue } from "../../../../../utils/queryString";


interface CaseDetailAgentTabProps {
    setActionItem: Function;
}

interface GWTFormInitialProps {
    advisor: string;
    carrierName: string;
    currentKaiZenPolicy?: BS;
    gen1Email?: string,
    gen1FirstName?: string,
    gen1LastName?: string,
    gen1PolicyNumber?: string,
    gen1TranceNo?: string,
    gen1UserId?: string,
    hasCurrentInsuranceCoverage?: boolean;
    householdAnnualIncome: string;
    imo: string;
    offerType: string;
    otherCompanyName?: string;
    otherDeathBenefitAmount?: string;
    otherPolicyNumber?: string;
    otherType?: NS;
    policyNumber: string;
    projectFundingDeadline: string;
    relationToGen2Client?: string,
    otherRelation?:string,
    strategyName: string;
    tranceNo: string
    trancheFundingDeadline: string;
    traunchApplicationDeadline: string;
    taxDocument?: string;
}

const CaseDetailAgentTab = (props: CaseDetailAgentTabProps) => {
    const detail: any = useSelector((state: RootState) => state.clientAccount);
    const clientDetails: any = (detail && detail.client) || {};
    const accountActions = useActions(Actions);
    const { getClient } = useActions(ClientActions);
    const params: any = useParams();
    const tabValue = parseInt(pez(getQueryStringValue("tab"), "-1"));

	const { setActionItem } = props;
    const isGWTGen2 = (clientDetails && clientDetails.strategyId === GWT_GEN2_STRATEGY);


    const formVal: GWTFormInitialProps = !!clientDetails ? {
        carrierName: clientDetails.carrier && clientDetails.carrier.carrierName,
        strategyName: clientDetails.strategy && clientDetails.strategy.displayName || "",
        imo: clientDetails.imo && clientDetails.imo.groupName || "",
        householdAnnualIncome: clientDetails.householdAnnualIncome && Number(clientDetails.householdAnnualIncome).toString() || "",
        offerType: clientDetails.underWritingOfferType || "",
        advisor: clientDetails.agent && clientDetails.agent.user && joinAsFullName(clientDetails.agent.user) || "",
        policyNumber: clientDetails.policyNumber || "",
        tranceNo: clientDetails.tranche && clientDetails.tranche.trancheNumber || "N/A",
        traunchApplicationDeadline: clientDetails.tranche && clientDetails.tranche.deadlineDate ? moment(clientDetails.tranche && clientDetails.tranche.deadlineDate).format("MM/DD/YYYY") : "N/A",
        trancheFundingDeadline: clientDetails.tranche && clientDetails.tranche.fundsDeadLineDate ? moment( clientDetails.tranche && clientDetails.tranche.fundsDeadLineDate).format("MM/DD/YYYY") : "N/A",
        projectFundingDeadline: clientDetails.tranche && clientDetails.tranche.projectFundingDate ? moment(clientDetails.tranche && clientDetails.tranche.projectFundingDate).format("MM/DD/YYYY") : "N/A",
    } : {} as GWTFormInitialProps;

    const gwtClients = clientDetails.gwtClients;

    Object.assign(formVal, !!gwtClients ? {
        gen1FirstName: pez(gwtClients.firstName),
        gen1LastName: pez(gwtClients.lastName),
        gen1Email: pez(gwtClients.email),
        gen1UserId: pez(gwtClients.gen1UserId),
        gen1TranceNo: pez(gwtClients.tranceNo),
        gen1PolicyNumber: pez(gwtClients.policyNumber),
        relationToGen2Client: pez(gwtClients.relation),
        otherRelation: pez(gwtClients.otherRelation || ''),
        currentKaiZenPolicy: gwtClients.currentKaiZenPolicy === 0 ? "0" : (gwtClients.currentKaiZenPolicy === 1 ? "1" : ""),
        otherCompanyName: pez(gwtClients.otherCompanyName),
        otherType: gwtClients.otherType,
        otherDeathBenefitAmount: pez(gwtClients.otherDeathBenefitAmount),
        otherPolicyNumber: pez(gwtClients.otherPolicyNumber),
        taxDocument: pez(gwtClients.taxDocument),
        hasCurrentInsuranceCoverage: (
                !!pez(gwtClients.otherCompanyName) &&
                !!gwtClients.otherType &&
                !!pez(gwtClients.otherDeathBenefitAmount) &&
                !!pez(gwtClients.otherPolicyNumber)
        ) ? "1" : "0",
    } : {});

    const [initialValues, setInitialValues] = React.useState<GWTFormInitialProps>(formVal);
    const [gen1User, setGen1User] = React.useState<any>(null);

    const onSubmit = async (values: FormikValues, { setSubmitting }: FormikValues) => {
		try {
			setInitialValues(values as GWTFormInitialProps);
            if (isGWTGen2) {
                values["otherType"] = values["otherType"] === "" ? null : values["otherType"];
                values["formType"] = "gwt";
                values["gen1UserId"] = pez(gen1User && gen1User.id);
            }
			values["carrierId"] = clientDetails.carrier && detail.client.carrier.id;
			values["strategyId"] = clientDetails.strategyId || 0;

			await accountActions.updateUserBasicDetails(values, clientDetails.userId || 0);

			if (isFunc(setActionItem)) {
				setActionItem();
			}
		} catch (e) {
            console.error(e);
		}

        sleep(400).then(() => setSubmitting(false));
	};


	const formProps = {
        children: GWTForm,
		enableReinitialize: true,
        gen1User,
		initialValues,
		onSubmit,
        setGen1User,
		validateOnChange: true,
		validationSchema: Yup.object({
			gen1Email: Yup.string().max(150).required(REQUIRED_FIELD),
			gen1FirstName: Yup.string().max(150).required(REQUIRED_FIELD),
			gen1LastName: Yup.string().max(150).required(REQUIRED_FIELD),
			relationToGen2Client: Yup.string().max(15).required(REQUIRED_FIELD),
            currentKaiZenPolicy: Yup.number().required(REQUIRED_FIELD),
            otherRelation: Yup.string().when("relationToGen2Client", {
                is: (value) => value === 'Other',
                then: Yup.string().max(150).required(REQUIRED_FIELD),
                otherwise: Yup.string(),
            }),
            gen1TranceNo: Yup.string().when("currentKaiZenPolicy", {
                is: 1,
                then: Yup.string().max(150).required(REQUIRED_FIELD).nullable(),
                otherwise: Yup.string().nullable(),
            }),
            gen1PolicyNumber: Yup.string().when("currentKaiZenPolicy", {
                is: 1,
                then: Yup.string().max(150).required(REQUIRED_FIELD),
                otherwise: Yup.string(),
            }),
            hasCurrentInsuranceCoverage: Yup.number().when("currentKaiZenPolicy", {
                is: 0,
                then: Yup.number().max(150).required(REQUIRED_FIELD),
                otherwise: Yup.number(),
            }),
            otherCompanyName: Yup.string().when("hasCurrentInsuranceCoverage", {
                is: 1,
                then: Yup.string().max(150).required(REQUIRED_FIELD),
                otherwise: Yup.string(),
            }),
            otherType: Yup.string().when("hasCurrentInsuranceCoverage", {
                is: 1,
                then: Yup.string().max(15).required(REQUIRED_FIELD).nullable(),
                otherwise: Yup.string().nullable(),
            }),
            otherDeathBenefitAmount: Yup.string().when("hasCurrentInsuranceCoverage", {
                is: 1,
                then: Yup.string().max(150).required(REQUIRED_FIELD),
                otherwise: Yup.string(),
            }),
            otherPolicyNumber: Yup.string().when("hasCurrentInsuranceCoverage", {
                is: 1,
                then: Yup.string().max(150).required(REQUIRED_FIELD),
                otherwise: Yup.string(),
            }),
		}),
	};

    const initialValuesEffect = () => {
        if (detail && detail.client) {
            setInitialValues(formVal);
        }
    };

    const fetchClientInfoEffect = () => {
        if (params.clientId && !detail) {
            getClient(params.clientId).then();
        }
    };

    React.useEffect(initialValuesEffect, [detail && detail.client]);

    React.useEffect(fetchClientInfoEffect, [params.clientId]);

    return (
		<FormWrapper {...formProps} />
    );
};
export default CaseDetailAgentTab;