import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import simplifiedQuestions from "../../../../../../components/client/SimplifiedQuestions";
import SiQuestions from "../../../../../../components/client/SiQuestions";
import SiToFullQuestions from "../../../../../../components/client/SiToFullQuestions";
import GiQuestions from "../../../../../../components/client/GiQuestions";
import GiToFullQuestions from "../../../../../../components/client/GiToFullQuestions";
import fullQuestionsList from "../../../../../../components/client/FullQuestions";
import allianzQuestions from "../../../../../../components/client/AllianzQuestions";
import iliaAppMedicalQuestions from "../../../../../../components/client/IliaAppMedicalQuestions";
import { EnrollmentQuestionProps } from "../../../../../../model/enrollment";
import * as Actions from "../../../../../../actions/enrollment";
import { useActions } from "../../../../../../actions";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../reducers";
import { unmaskPhoneNumber } from "../../../../../../utils/commonUtils";
import KickOut from "../../../component/EnrollmentHome/KickOut";
import * as AccountActions from "../../../../../../actions/account";
import ApplicationQuestionnaireFormContent from './ApplicationQuestionnaireFormContent';
import { useOfferType } from "../../../../../../hooks/useOfferType";

const ApplicationQuestionnaireForm = (props: {
    setNextStep: Function, setPrevStep: Function, carrierName: string, client: any, isFromClientDetail: boolean,
    enrollment?: any, setActionItem?: Function, setQuestionnaireRef?: Function, setIsQuestionFormDirty?: Function, carrierValAlert?: boolean, user? : any
    errorInPhysicianAndMedicalDetails?: boolean
}) => {
    let {
        setNextStep,
        carrierName,
        client,
        isFromClientDetail,
        enrollment,
        setActionItem,
        setQuestionnaireRef,
        setIsQuestionFormDirty,
        carrierValAlert,
        user,
        errorInPhysicianAndMedicalDetails
    } = props;
    let offerType = useOfferType();
    const enrollmentActions = useActions(Actions);
    const { updateClientFields, getFreshDataToRefreshReduxState } = useActions(AccountActions);
    const [enrollmentId, setEnrollmentId] = useState("");
    const [saveApplicationQuestions, setSaveApplicationQuestions] = React.useState<object>({});

    if(offerType === "consent"){
        offerType = "gi";
    }

    const [selectedOfferType, setSelectedOfferType] = useState(offerType);
    const [openKickOutPopup, setOpenKickOutPopup] = useState(false);
    const applicationQuestionSubmit = React.useRef<any>();
    const applicationQuestions:any = React.useRef<any>(null);
    let { user: { role, participant } } = useSelector((state: RootState) => state.auth);
    const participantId:number = (participant && participant.id) || 0;
    let fullQuestions = simplifiedQuestions;

    let clientSigned: boolean = (enrollment && (enrollment.clientSigned || enrollment.envelopeId)) || false;
    //Allianz Case
    let allianzQuestion:any = allianzQuestions({ offerType });
    if (carrierName === "Allianz Life") {
        fullQuestions = allianzQuestion;
    } else if (selectedOfferType === "ilia_app") { //National Life - ilia_app
        fullQuestions = iliaAppMedicalQuestions;
    } else if (selectedOfferType === "full") { //National Life - Full
        fullQuestions = fullQuestionsList;
    } else if (selectedOfferType === "si") { //National Life - si
        fullQuestions = SiQuestions;
    } else if (selectedOfferType === "si-to-full") { //National Life - si-to-full
        fullQuestions = SiToFullQuestions;
    } else if (selectedOfferType === "gi") { //National Life - gi
        fullQuestions = GiQuestions;
    } else if (selectedOfferType === "gi-to-full") { //National Life - gi-to-full
        fullQuestions = GiToFullQuestions;
    }

    const getInitialValues = (freshFullQuestions) => {
        let initialValues: any = {};
        if (freshFullQuestions && freshFullQuestions.length > 0) {
            for (let i = 0; i < freshFullQuestions.length; i++) {
                initialValues[freshFullQuestions[i].name] = "";
                if (freshFullQuestions[i].additionalFieldName && (freshFullQuestions[i].additionalFieldType === "checkbox" || freshFullQuestions[i].additionalFieldType === "checkboxAndPhysicianDetails")) {
                    if (freshFullQuestions[i].additionalFieldType === "checkboxAndPhysicianDetails") {
                        initialValues[freshFullQuestions[i].additionalFieldName] = ["other"];
                    } else {
                        initialValues[freshFullQuestions[i].additionalFieldName] = [];
                    }
                }
                if (freshFullQuestions[i].additionalFieldType === "physicianDetails") {
                    for (let j=0; j < freshFullQuestions[i].additionalFields.length; j++) {
                        initialValues[freshFullQuestions[i].additionalFields[j].name] = "";
                    }
                } else if (freshFullQuestions[i].additionalFieldType === "checkboxAndPhysicianDetails") {
                    for (let j=0; j < freshFullQuestions[i].additionalFieldOptions.length; j++) {
                        for (let k=0; k < freshFullQuestions[i].additionalFieldOptions[j].additionalFields.length; k++) {
                            initialValues[freshFullQuestions[i].additionalFieldOptions[j].additionalFields[k].name] = "";
                        }
                    }
                }
            }
            initialValues["Proposed_Insured_Questions-1"] = client && client.usCitizen ? "Yes" : "No";
            if (carrierName === "Allianz Life") {
                initialValues["Proposed_Insured_Questions-55"] = client && client.selfEmployed ? "Yes" : "No";
            }
        }
        return initialValues;
    }

    /* SET Final Questions List */
    const [questionList, setQuestionList] = useState(fullQuestions);
    const initAns: any = React.useMemo(() => getInitialValues(fullQuestions), [fullQuestions]);
    /* SET Questions Initial Answer */
    const [initialAnswer, setInitialAnswer] = useState(initAns);
    const validationAdded = (freshQuestionsList) => {
        /* Validations of fields set */
        let keysValidation = {};
        freshQuestionsList.forEach((field) => {
            if (!isFromClientDetail) {
                keysValidation[field.name] = Yup.string().trim().required("Required Field");
            }
            if (field.additionalFieldName && field.additionalFieldRequired) {
                let fieldName = field.additionalFieldName;
                if (isFromClientDetail) {
                    keysValidation[fieldName] = Yup.string().when(field.name, {
                        is: "Yes",
                        then: Yup.string().trim(),
                    });

                    /*In case of Question 10 - Validation will work when selected  "No" */
                    if (field.key === "10") {
                        keysValidation[fieldName] = Yup.string().when(field.name, {
                            is: "No",
                            then: Yup.string().trim(),
                        });
                    }
                    /*In case of CheckBox - Show Validation if not selected at least one */
                } else {

                    keysValidation[fieldName] = Yup.string().when(field.name, {
                        is: "Yes",
                        then: Yup.string().trim().required("Required Field"),
                    });

                    /*In case of Question 10 - Validation will work when selected  "No" */
                    if (field.key === "10") {
                        keysValidation[fieldName] = Yup.string().when(field.name, {
                            is: "No",
                            then: Yup.string().trim().required("Required Field"),
                        });
                    }
                    /*In case of CheckBox - Show Validation if not selected at least one */
                }
            }
            if (field.additionalFieldName && !field.additionalFieldRequired && field.additionalFieldOptions.length > 1) {
                let fieldName = field.additionalFieldName;
                let fieldOptions = field.additionalFieldOptions
                for (let i=0; i < fieldOptions.length; i++) {
                    for (let j=0; j < fieldOptions[i].additionalFields.length; j++) {
                        keysValidation[fieldOptions[i].additionalFields[j].name] = Yup.string().when(field.name,{
                            is: "Yes",
                            then: Yup.string().when(fieldName, (selectedDiseases, schema) => {
                                if (selectedDiseases && selectedDiseases.length > 0) {
                                    return selectedDiseases.includes(fieldOptions[i].value) && fieldOptions[i].additionalFields[j].type === "text" ?
                                    Yup.string().trim('Above text cannot include leading and trailing spaces.').required("Required Field") : // add required validation
                                    selectedDiseases.includes(fieldOptions[i].value) && fieldOptions[i].additionalFields[j].type === "phone" ?
                                        Yup.string().test(
                                                'Is valid phone?',
                                                'Phone number must be of 10 digits',
                                                (value) => {
                                                    if(value && unmaskPhoneNumber(value).length === 10) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                }
                                            ).required("Required Field"):schema;
                                } else {
                                    return schema;
                                }
                            })
                        })
                    }
                }
            }
        });
        return keysValidation;
    }

    const [keyValidationSchema, setKeyValidationSchema] = useState(validationAdded(fullQuestions));
    const [initValues, setInitValues] = useState({});

    React.useEffect(() => {
        if (applicationQuestionSubmit.current && typeof setQuestionnaireRef === "function") {
            setQuestionnaireRef(applicationQuestionSubmit);
        }
    }, [applicationQuestionSubmit.current]);

    React.useEffect(() => {
        async function getEnrollmentDetails() {
            let initialValues: any = {};
            let result;
            if (isFromClientDetail) {
                result = {
                    payload: enrollment,
                };
            } else {
                result = await enrollmentActions.getEnrollment();
            }
            if (result && result.payload) {
                setEnrollmentId(result.payload.id);
                let formValues = result.payload.answers ? JSON.parse(result.payload.answers) : null;
                if(offerType !== selectedOfferType && selectedOfferType === "si" && result.payload.oldAnswers){
                    formValues = JSON.parse(result.payload.oldAnswers);
                }
                clientSigned = result.payload.clientSigned ? true : false;
                if (formValues) {
                    questionList.map(x => {
                        initialValues[x.name] = formValues[x.name] || "";
                        if (formValues[x.name] === "Yes") {
                            if (x.key === "10") {
                                x.showRemarks = false;
                            } else {
                                x.showRemarks = true;
                                x.additionalFieldRequired = !(x.additionalFieldName && formValues[x.additionalFieldName] !== "");
                                if (x.additionalFieldName) {
                                    initialValues[x.additionalFieldName] = formValues[x.additionalFieldName];
                                }
                                if (x.additionalSubFieldName) {
                                    initialValues[x.additionalSubFieldName] = formValues[x.additionalSubFieldName];
                                }
                            }
                        }
                        if (formValues[x.name] === "No") {
                            if (x.key === "10") {
                                x.showRemarks = true;
                                x.additionalFieldRequired = !(x.additionalFieldName && formValues[x.additionalFieldName] ! === "");
                                if (x.additionalFieldName) {
                                    initialValues[x.additionalFieldName] = formValues[x.additionalFieldName];
                                }
                            } else {
                                x.showRemarks = false;
                            }
                        }
                        if (x.additionalFieldName && (x.additionalFieldType === "checkbox" || x.additionalFieldType === "checkboxAndPhysicianDetails")) {
                            if (formValues[x.name] === "Yes" && x.additionalFieldType === "checkboxAndPhysicianDetails") {
                                initialValues[x.additionalFieldName] = formValues[x.additionalFieldName] && Array.isArray(formValues[x.additionalFieldName]) && formValues[x.additionalFieldName].length > 0 ? formValues[x.additionalFieldName] : ["other"];
                            } else {
                                initialValues[x.additionalFieldName] = formValues[x.additionalFieldName] && Array.isArray(formValues[x.additionalFieldName]) && formValues[x.additionalFieldName].length > 0 ? formValues[x.additionalFieldName] : [];
                            }
                        }
                        if ((x.additionalFieldType === "physicianDetails") && x.additionalFields) {
                            for (let j=0; j < x.additionalFields.length; j++) {
                                initialValues[x.additionalFields[j].name] = formValues[x.additionalFields[j].name];
                            }
                        }
                        if (x.additionalFieldType === "checkboxAndPhysicianDetails" && x.additionalFieldOptions) {
                            for (let j=0; j < x.additionalFieldOptions.length; j++) {
                                for (let i=0; i < x.additionalFieldOptions[j].additionalFields.length; i++) {
                                    initialValues[x.additionalFieldOptions[j].additionalFields[i].name] = formValues[x.additionalFieldOptions[j].additionalFields[i].name];
                                }
                            }
                        }

                        return x;
                    });
                    setQuestionList([...questionList]);
                    setKeyValidationSchema( validationAdded([...questionList]) );
                    initialValues["Proposed_Insured_Questions-1"] = client && client.usCitizen ? "Yes" : "No";
                    if (carrierName === "Allianz Life") {
                        initialValues["Proposed_Insured_Questions-55"] = client && client.selfEmployed ? "Yes" : "No";
                    }
                    setInitialAnswer(initialValues);
                } else {
                    setInitialAnswer(initAns);
                }
            }
        }

        getEnrollmentDetails();
    }, []);

    React.useEffect(() => {
        if(selectedOfferType === "gi-to-full" || selectedOfferType === "si-to-full"){
            setQuestionList(fullQuestions);
            setKeyValidationSchema( validationAdded(fullQuestions) );
            setTimeout(()=> {
                setInitialAnswer({...initAns, ...initValues});
            }, 500);
        }
    }, [selectedOfferType]);

    React.useEffect(() => {
        saveMe(saveApplicationQuestions);
    },[saveApplicationQuestions]);

    const changeOfferType = async (expectedOfferType:string, values:any, oldValues:any=null) => {
        setSelectedOfferType(expectedOfferType);
        setInitValues(values);
        const result = await enrollmentActions.updateEnrollment({
            ...values,
            id: enrollmentId,
            userId: enrollment && enrollment.userId ? enrollment.userId : client.userId,
            questions: (role === 1 || role === 2 || role === 4) && !carrierValAlert ? true : false,
        });
        await updateClientFields({
            underWritingOfferTypeFinal : expectedOfferType
        }, (client && client.id) || "", isFromClientDetail);

        let enrollmentPayload:any = {
            id: enrollmentId,
            enrollmentStatus : 1,
            enrollmentSubStatus: 1,
            version: "v2",
        }
        if(expectedOfferType === "si-to-full" && oldValues){
            enrollmentPayload.oldAnswers = oldValues;
        }
        await enrollmentActions.updateEnrollment(enrollmentPayload);
        getFreshDataToRefreshReduxState();
    }

    const validationSchema = Yup.object().shape(keyValidationSchema);

    const checkIsError = (subFieldValue, fieldValue, value) => {
        if (value === "Yes" && fieldValue && fieldValue.length > 0 && !subFieldValue) {
            return true;
        }
        return false;
    };

    const onChangeOperationForQuestionAns = (value:any, values, question:EnrollmentQuestionProps) => {
        if(offerType === "gi"){
            if((question.name === "Proposed_Insured_Questions-10" && value === "No") || (question.name === "Proposed_Insured_Questions-26" && value === "Yes")){
                // setOpenKickOutPopup(true);
                return false;
            }
        }
        if(offerType === "si"){
            if(Number(question.key) !== 10 && Number(question.key) >= 8 && Number(question.key) <= 25 && value === "Yes"){
                // setOpenKickOutPopup(true);
                return false;
            }
            if(Number(question.key) === 10 && value === "No"){
                // setOpenKickOutPopup(true);
                return false;
            }
        }

        //When gi-to-full replace with gi and si-to-full replace with si in initial stage
        if(offerType !== selectedOfferType){
            if(question.name === "Proposed_Insured_Questions-10"){
                if (value === "No") {
                    changeOfferType(offerType, {...values, [question.name] : value}, values);
                }
            } else if (value === "Yes"){
                changeOfferType(offerType, {...values, [question.name] : value}, values);
            }
        } else if(offerType === "gi-to-full" && offerType === selectedOfferType){
            if(question.name === "Proposed_Insured_Questions-10" && value === "Yes" && values["Proposed_Insured_Questions-26"] === "No"){
                changeOfferType("gi", {...values, [question.name] : value}, values);
            }
            if(question.name === "Proposed_Insured_Questions-26" && value === "No" && values["Proposed_Insured_Questions-10"] === "Yes"){
                changeOfferType("gi", {...values, [question.name] : value}, values);
            }
        } else if(offerType === "si-to-full" && offerType === selectedOfferType){
            let revertFullToSi:boolean = true;
            for(let qKey = 8; qKey <= 25; qKey++){
                if(Number(question.key) === qKey){
                    continue;
                }
                if(qKey !== 10 && values["Proposed_Insured_Questions-"+qKey] === "Yes"){
                    revertFullToSi = false;
                    return false;
                }
                if(qKey === 10 && values["Proposed_Insured_Questions-"+qKey] === "No"){
                    revertFullToSi = false;
                    return false;
                }
            }
            if(Number(question.key) !== 10 && Number(question.key) >= 8 && Number(question.key) <= 25 && value === "Yes"){
                revertFullToSi = false;
                return false;
            }
            if(Number(question.key) === 10 && value === "No"){
                revertFullToSi = false;
                return false;
            }
            if(Number(question.key) >= 8 && Number(question.key) <= 25 && revertFullToSi){
                changeOfferType("si", {...values, [question.name] : value}, values);
            }
        }
    }

    let kickOutDetail = {
        groupAdminEmail: client && client.agent && client.agent.user && client.agent.user.email || "",
        groupAdminPhone : client && client.agent && client.agent.user && client.agent.user.phoneNumber || "",
        groupAdminFullName : client && client.agent && client.agent.user && client.agent.user.firstName + ' ' + client.agent.user.lastName || "",
        strategyName : client && client.strategy && client.strategy.name || ""
    }

    if(role === 6){
        kickOutDetail = {
            groupAdminEmail: client && client.company && client.company.email || "",
            groupAdminPhone : client && client.company && client.company.phone || "",
            groupAdminFullName : client && client.company && client.company.companyName || "",
            strategyName : client && client.strategy && client.strategy.name || ""
        }
    }

    const applicationQuestionPayload = async (values:any, showMessage:boolean = true) => {
        if(!!enrollmentId) {
            let phoneArrKey:any = {};
            for (const key in values) {
                let arrVal:any = key.split("-");
                if( arrVal.length > 0 ) {
                    if(arrVal[0].toLowerCase() === "physician_phone" ) {
                        phoneArrKey[`${key}`] = values && values[key] && unmaskPhoneNumber(values[key]);
                    }
                }
            }

            let payload: any = {
                ...values,
                ...phoneArrKey,
                id: enrollmentId,
                userId: enrollment && enrollment.userId ? enrollment.userId : client.userId,
                questions: (role === 1 || role === 2 || role === 4) && !carrierValAlert ? true : false,
            }
            const result = await enrollmentActions.updateEnrollment(payload, showMessage);
            if (result && result.payload && result.payload.id && isFromClientDetail) {
                setEnrollmentId(result.payload.id);
            }
            if (typeof setActionItem === "function" && isFromClientDetail) {
                setActionItem((role === 1 || role === 2 || role === 4) ? true
                        :
                        (user && user.enrollment && user.enrollment.clientSigned && user.client && user.client.usCitizen ? true : false));
            }
            return true;
        }
    }

    const saveMe = (values:any) => {
        if(offerType === "gi"){
            if(values["Proposed_Insured_Questions-10"] === "No" || values["Proposed_Insured_Questions-26"] === "Yes"){
                setOpenKickOutPopup(true);
                return false;
            }
        }
        if(offerType === "si"){
            for(let qKey = 8; qKey <= 25; qKey++){
                if(qKey !== 10 && values["Proposed_Insured_Questions-"+qKey] === "Yes"){
                    setOpenKickOutPopup(true);
                    return false;
                }
                if(qKey === 10 && values["Proposed_Insured_Questions-"+qKey] === "No"){
                    setOpenKickOutPopup(true);
                    return false;
                }
            }
        }
        applicationQuestionPayload(values, false).then(()=>{});
    }

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <KickOut participantId={participantId} isFromClientDetail={isFromClientDetail} openKickOut={openKickOutPopup} setOpenKickOut={() => {
                        setOpenKickOutPopup(!openKickOutPopup)
                    }} {...kickOutDetail} />
                    <ApplicationQuestionnaireFormContent
                            initialAnswer={initialAnswer}
                            validationSchema={validationSchema}
                            offerType={offerType}
                            setOpenKickOutPopup={setOpenKickOutPopup}
                            questionList={questionList}
                            isFromClientDetail={isFromClientDetail}
                            setNextStep={setNextStep}
                            setInitialAnswer={setInitialAnswer}
                            enrollmentActions={enrollmentActions}
                            setIsQuestionFormDirty={setIsQuestionFormDirty}
                            role={role}
                            applicationQuestionSubmit={applicationQuestionSubmit}
                            clientSigned={clientSigned}
                            enrollmentId={enrollmentId} enrollment={enrollment} client={client}
                            checkIsError={checkIsError} carrierValAlert={carrierValAlert}
                            setEnrollmentId={setEnrollmentId}
                            setActionItem={setActionItem}
                            onChangeOperationForQuestionAns={onChangeOperationForQuestionAns}
                            user={user}
                            applicationQuestions={applicationQuestions}
                            applicationQuestionPayload={applicationQuestionPayload}
                            setSaveApplicationQuestions={setSaveApplicationQuestions}
                            errorInPhysicianAndMedicalDetails={errorInPhysicianAndMedicalDetails}
                    />
                </Grid>
            </Grid>
    );
};

export default ApplicationQuestionnaireForm;