import React from "react";
import { Label } from "../../../../../components/formInputs";
import {
    ErrorCommon,
    SelectField,
    TextField,
    PhoneNumberField,
    TextFieldCommon,
    NumberTextField
} from "../../../../../components/formikFormInputs";
import {AccountType, genderDropDown} from "../../../../../constants";
import { Grid } from "@material-ui/core";
import {validatePhoneNumber} from "../../../../../utils/validation";
import InputAdornment from "@material-ui/core/InputAdornment";
import { isAllianzApp } from "../../../Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

const genderDropDownOpn = genderDropDown.map((gender:any)=>({
    id : gender.id,
    key : gender.id,
    name: gender.name,
    value: gender.value
}));

const ProfileInformation = (props: any) => {
    const {values, handleChange, errors, touched, role, clientSigned, hasEnvelopeId} = props;
    const offerType: any = values['offerType'];
    const fieldObj = [
        {
            id: "firstName",
            name: "firstName",
            label: <Label label={"First Name"} required={ role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier ? false : true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: (clientSigned || hasEnvelopeId || role === 3 || role === 12 || role === 15  || role === AccountType.Carrier) ? true : false //disabled for imo and trustee i.e role 3 , 12
        },
        {
            id: "middleName",
            name: "middleName",
            label: "Middle Name",
            component: TextField,
            variant: "outlined",
            disabled: (clientSigned || hasEnvelopeId || role === 3 || role === 12 || role === 15 || role === AccountType.Carrier ) ? true : false
        },
        {
            id: "lastName",
            name: "lastName",
            label: <Label label={"Last Name"} required={ role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier ? false : true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: (clientSigned || hasEnvelopeId || role === 3 || role === 12 || role === 15  || role === AccountType.Carrier) ? true : false
        },
        {
            name: "homePhone",
            label: <Label label={"Home Phone"} required={role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier ? false : true}/>,
            isRequired: true,
            placeholder: "Home Phone",
            id: "homePhone",
            component: PhoneNumberField,
            validate: validatePhoneNumber,
            type: "text",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15  || role === AccountType.Carrier) ? true : false
        },
        {
            name: "cellPhone",
            label: <Label label={"Cell Phone"} required={role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier ? false : true}/>,
            isRequired: true,
            placeholder: "Cell Phone",
            id: "cellPhone",
            component: PhoneNumberField,
            validate: validatePhoneNumber,
            type: "text",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15  || role === AccountType.Carrier) ? true : false
        },
        {
            name: "phoneNumber",
            label: <Label label={"Work Phone"} required={role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier ? false : true}/>,
            isRequired: true,
            placeholder: "Work Phone",
            id: "phoneNumber",
            component: PhoneNumberField,
            validate: validatePhoneNumber,
            type: "text",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15 || role === AccountType.Carrier) ? true : false
        },
        {
            id: "email",
            name: "email",
            label: <Label label={"Email"} required={role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier? false : true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: true
        },
        {
            name: "age",
            label: <Label label={"Age"} required={role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier ? false : true}/>,
            id: "age",
            isRequired: true,
            placeholder: "Age",
            component: TextField,
            disabled: true
        },
        {
            id: "gender",
            name: "gender",
            label: <Label label={"Gender"}/>,
            isRequired: true,
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: genderDropDownOpn,
            onSelectChange: (val: number) => {
            },
            disabled: (clientSigned || hasEnvelopeId ||role === 3 || role === 12 || role === 15 || role === AccountType.Carrier) ? true : false
        },
        {
            id: "occupation",
            name: "occupation",
            label: <Label label={"Occupation"} required={role ===  1 || role ===  2 || role ===  4  || role === AccountType.Carrier ? false : true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15 || role === AccountType.Carrier) ? true : false
        },
        {
            id: isAllianzApp(offerType) ? "indIncomeLastYearTotal" : "householdNetWorth",
            name: isAllianzApp(offerType) ? "indIncomeLastYearTotal" : "householdNetWorth",
            label: <Label label={"Household Income"} required={role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier ? false : true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            dollarSign: true,
            className: "TextFieldWidth",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15 || role === AccountType.Carrier) ? true : false
        },
        {
            id: "netWorth",
            name: "netWorth",
            label: <Label label={"Net Worth"} required={role ===  1 || role ===  2 || role ===  4 || role === AccountType.Carrier ? false : true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            dollarSign: true,
            className: "TextFieldWidth",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15 || role === AccountType.Carrier) ? true : false
        },
        {
            id: "currentEmployer",
            name: "currentEmployer",
            label: <Label label={"Current Employer"} required={role ===  1 || role ===  2 || role ===  4 ? false : true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15) ? true : false
        },
        {
            id: "currentNetWorth",
            name: "currentNetWorth",
            label: <Label label={"Current Net Worth"} required={role ===  1 || role ===  2 || role ===  4 ? false : true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            dollarSign: true,
            className: "TextFieldWidth",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15) ? true : false
        },
        {
            id: "currentAnnualIncome",
            name: "currentAnnualIncome",
            label: <Label label={"Current Annual Income"} required={role ===  1 || role ===  2 || role ===  4 ? false : true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            dollarSign: true,
            className: "TextFieldWidth",
            disabled: (clientSigned || role === 3 || role === 12 || role === 15) ? true : false
        },
    ];

    return (
            <Grid container spacing={3}>
                {fieldObj.map((field, index) => {
                    let InputProps: any = {};
                    if (field.dollarSign) {
                        InputProps = {
                            startAdornment: <InputAdornment disableTypography={true}
                                                            position="start">$</InputAdornment>,
                        };
                    }
                    return (
                            <Grid item xs={12} sm={6} md={4} lg={6} xl={4}
                                  key={index} className="mb15 fullWidthcustomFields">
                                <TextFieldCommon
                                        {...field}
                                        values={values}
                                        onChange={handleChange}
                                        InputProps={InputProps}
                                        InputLabelProps={{
                                            disabled : false
                                        }}
                                />
                                <ErrorCommon errors={errors} name={field.name}
                                             touched={touched}/>
                            </Grid>
                    );
                })
                }
            </Grid>
    );

};
export default ProfileInformation;