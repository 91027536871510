import React, {useRef} from "react";
import {Grid, IconButton, Typography} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import {Button, EnrollmentFileUploader as FileUploader} from "../../../../../components/formInputs";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as ClientActions from "../../../../../actions/client";
import * as AccountActions from "../../../../../actions/account";
import * as AwsActions from "../../../../../actions/aws";
import {useActions} from "../../../../../actions";
import {asDbDateFormat, DATE_FORMAT_ACC_DB} from "../../../../../utils/dateUtils";
import ConfirmToUploadPolicyDoc from "../ConfirmToUploadPolicyDoc";
import StrategyName from "../../../../../components/client/StrategyName";

const useStyles = makeStyles({
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
});
const DocArr = ["Carrier_Annual_Statement", "Carrier_Interest_Crediting_Statement"]
const HiddenFileUploader = (props: {
    uploadRef: any, fileType: string, statementType: string, clientId: number, policyYear: number, setInterestCreditingStatementFileName: Function,
    setAnnualStatementFileName: Function, setAnnualStatementDate: Function, setInterestCreditingStatementDate: Function
}) => {
    const clientAction = useActions(ClientActions);
    const AccountAction = useActions(AccountActions);
    const {
        uploadRef,
        fileType,
        statementType,
        clientId,
        policyYear,
        setAnnualStatementFileName,
        setInterestCreditingStatementFileName,
        setAnnualStatementDate,
        setInterestCreditingStatementDate
    } = props;
    const checkInvalidFile = () => {
        AccountAction.alertPopup("Documents must be .pdf")
    }
    return (
            <FileUploader
                    uploadRef={uploadRef}
                    dirName={clientId}
                    dirType={"documents"}
                    fileType={fileType}
                    content={"Uploads"}
                    checkInvalidFile={checkInvalidFile}
                    onFinish={async (publicUrl: string, name: string) => {
                        switch (statementType) {
                            case DocArr[0]:
                                setAnnualStatementFileName(name);
                                setAnnualStatementDate(moment().format(DATE_FORMAT_ACC_DB));
                                break;
                            case  DocArr[1]:
                                setInterestCreditingStatementFileName(name);
                                setInterestCreditingStatementDate(moment().format(DATE_FORMAT_ACC_DB));
                                break;
                        }
                        await clientAction.updatePolicyDocument({docType: statementType, name, clientId, policyYear});
                    }}
            />
    );
};

export default function PolicyYearDocTable(props: { yearPolicyBreakup: any, caseDetails: any }) {
    const classes = useStyles();
    const awsActions = useActions(AwsActions);
    const {yearPolicyBreakup, caseDetails} = props;
    const strategyName = caseDetails && caseDetails.strategy && (caseDetails.strategy.text || caseDetails.strategy.name) || "Kai-Zen";
    const uploadRef = useRef<any>(null);
    const [statementType, setStatementType] = React.useState("");
    const fileType: string = "application/pdf";
    const [annualStatementFileName, setAnnualStatementFileName] = React.useState("");
    const [annualStatementDate, setAnnualStatementDate] = React.useState("");
    const [interestCreditingStatementFileName, setInterestCreditingStatementFileName] = React.useState("");
    const [interestCreditingStatementDate, setInterestCreditingStatementDate] = React.useState("");
    const [confirmationBox, setConfirmationBox] = React.useState(false);

    React.useEffect(() => {
        if (yearPolicyBreakup) {
            setInterestCreditingStatementFileName(yearPolicyBreakup.interestCreditingStatementPath);
            setInterestCreditingStatementDate(asDbDateFormat(yearPolicyBreakup.interestCreditingStatementUploadedDate));
            setAnnualStatementFileName(yearPolicyBreakup.annualStatementPath);
            setAnnualStatementDate(asDbDateFormat(yearPolicyBreakup.annualStatementUploadedDate));
        }
    }, [yearPolicyBreakup])

    const handleDocument = async (document: any) => {
        const contentType = "text/pdf";
        let queryObj = {
            objectName: document,
            contentType: contentType,
        };
        const s3url = await awsActions.getSecureUrlAndDownload(yearPolicyBreakup.clientId, queryObj, true);
        let blob = new Blob([new Buffer(s3url.Body, "binary")]
                , { type: s3url.ContentType });
        const url = window.URL.createObjectURL(blob);
        const link = window.document.createElement("a");
        link.href = url;
        link.setAttribute("download", document);
        window.document.body.appendChild(link);
        link.click();
    }

    const handleYes = () => {
        if (uploadRef && uploadRef.current) {
            uploadRef.current.click();
            setConfirmationBox(false);
        }
    }

    return (
            <Grid item xs={12}>
                <span className="hLine"/>
                <Typography variant="body1" component="strong" className="mb20 floatLeft w100">Documents&nbsp;
                </Typography>
                <Typography variant="body1" component="strong" className="mb20 floatLeft w100">

                </Typography>
                <TableContainer>
                    <Table className={classes.table} aria-label="caption table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell>Document Name</TableCell>
                                <TableCell>Upload Date</TableCell>
                                <TableCell>File Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <span className="floatLeft">Carrier Annual Statement</span>
                                    <div className="floatRight btnwrap">
                                        <Button size="small" className="floatLeft" type="button" variant="contained"
                                                color="primary"
                                                disabled={false}
                                                label={`${annualStatementFileName ? "Upload New Pdf" : "Upload Pdf"}`}
                                                onClick={() => {
                                                    setStatementType(DocArr[0]);
                                                    if (annualStatementFileName) {
                                                        setConfirmationBox(true);
                                                    }
                                                    if (!annualStatementFileName && uploadRef && uploadRef.current) {
                                                        uploadRef.current.click();
                                                    }
                                                }}/>
                                        {
                                            annualStatementFileName &&
                                            <IconButton className="floatLeft ml20 mt5" aria-label="delete"
                                                        size="small"
                                                        onClick={() => handleDocument(annualStatementFileName)}>
                                                <GetAppIcon/>
                                            </IconButton>
                                        }
                                    </div>
                                </TableCell>
                                {annualStatementFileName ?
                                        <>
                                            <TableCell>{annualStatementDate}</TableCell>
                                            <TableCell>{annualStatementFileName}</TableCell>
                                        </> : <TableCell colSpan={2} className="colorLightBlack">currently no uploaded
                                            doc</TableCell>
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <span className="floatLeft"><StrategyName strategyName={strategyName} tooltip={true}/> Annual Review</span>
                                    <div className="floatRight btnwrap">
                                        <Button size="small" type="button" variant="contained" color="primary"
                                                disabled={false}
                                                label={`${interestCreditingStatementFileName ? "Upload New Pdf" : "Upload Pdf"}`}
                                                className="floatLeft"
                                                onClick={() => {
                                                    setStatementType(DocArr[1]);
                                                    if (interestCreditingStatementFileName) {
                                                        setConfirmationBox(true);
                                                    }
                                                    if (!interestCreditingStatementFileName && uploadRef && uploadRef.current) {
                                                        uploadRef.current.click();
                                                    }
                                                }}/>
                                        {
                                            interestCreditingStatementFileName &&
                                            <IconButton className="floatLeft ml20 mt5" aria-label="delete"
                                                        size="small"
                                                        onClick={() => handleDocument(interestCreditingStatementFileName)}>
                                                <GetAppIcon/>
                                            </IconButton>
                                        }

                                    </div>
                                </TableCell>
                                {
                                    interestCreditingStatementFileName ? <>
                                                <TableCell>{interestCreditingStatementDate}</TableCell>
                                                <TableCell>{interestCreditingStatementFileName}</TableCell>
                                            </>
                                            : <TableCell colSpan={2} className="colorLightBlack">currently no uploaded
                                                doc</TableCell>
                                }
                            </TableRow>
                        </TableBody>
                        <div className="fileUpload" style={{display: "none"}}>
                            <HiddenFileUploader uploadRef={uploadRef} fileType={fileType}
                                                statementType={statementType} clientId={yearPolicyBreakup.clientId}
                                                policyYear={yearPolicyBreakup.policyYear}
                                                setAnnualStatementFileName={setAnnualStatementFileName}
                                                setInterestCreditingStatementFileName={setInterestCreditingStatementFileName}
                                                setAnnualStatementDate={setAnnualStatementDate}
                                                setInterestCreditingStatementDate={setInterestCreditingStatementDate}
                            />
                        </div>
                        <ConfirmToUploadPolicyDoc confirmationBox={confirmationBox} setConfirmationBox={setConfirmationBox}
                            handleYes={handleYes}
                        />
                    </Table>
                </TableContainer>
            </Grid>
    );
}