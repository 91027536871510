import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../components/dialog";


type ActiveCaseForCoverLetterContentProps = {
    /**
     * Name of Agent
     */
    agentName: string;

    /**
     * Name of Client
     */
    clientName: string;


    /**
     * Is Gen2 GWT case
     */
    isGWTGen2?: boolean;

    /**
     * handle action
     */
    onAction: any;
}
interface ActiveCaseForCoverLetterProps extends ActiveCaseForCoverLetterContentProps {
    /**
     * When true shows the popup, when false nothing happens
     */
    open: boolean;

    /**
     * handle open/close of popup using this
     */
    setCoverLetterActiveCasePopUp: Function;
}

const ACFCLContent = ({ agentName, clientName, isGWTGen2, onAction }: ActiveCaseForCoverLetterContentProps) => {
    return (
            <>
                <Typography gutterBottom>
                    Hello <strong>{agentName}</strong>,
                </Typography>

                <Typography gutterBottom>
                    You have an active {`${isGWTGen2 ? 'Great Wealth Transfer' : ''}`} case for {clientName} that requires
                    {`${isGWTGen2 ? ' some actions' : ' a cover letter before you can sign the application'}.`}
                </Typography>

                {
                        !isGWTGen2 &&
                        <Typography gutterBottom>
                            Please upload a cover letter for this case. Once uploaded you can proceed to signing the
                            application and agent report.
                        </Typography>
                }

                <Link className="mb15 mt15 floatLeft w100" onClick={onAction}>
                    {isGWTGen2 ? 'Click Here to complete action items.' : 'Upload Cover Letter Here.'}
                </Link>
            </>
    );
};

/**
 * Component for showing Active_Case_For_CoverLetter popup dialog
 * Also used for showing Active_Case_For_GWT
 * @param props
 * @constructor
 */
export default function ActiveCaseForCoverLetter(props: ActiveCaseForCoverLetterProps) {
    const { open, setCoverLetterActiveCasePopUp, ...otherProps } = props;
    const handleClose = () => {
        setCoverLetterActiveCasePopUp(false);
    };

    const genericDialogProps: GenericDialogProps = {
        "aria-labelledby": "active-case-for-cover-letter-dialog",
        content: (
                <ACFCLContent {...otherProps} />
        ),
        dividers: true,
        onClose: handleClose,
        open: open,
        title: `Active Case For ${otherProps.isGWTGen2 ? `${otherProps.clientName} needs GWT actions` : "Cover Letter"}`,
        titleVariant: "h6",
    };

    return (
            <>
                <GenericDialog {...genericDialogProps} />
            </>
    );
}
