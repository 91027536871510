import React from "react";
import { Label } from "../../../../../../components/formInputs";
import { NOTA as _NOTA } from "../../../../../../constants";

export const PIQ = "Proposed_Insured_Questions";
export const NOTA = _NOTA;
const commonOptions = {
	type: "radio",
	default: false,
	onYes: false,
	additionalRemarks: true,
	showRemarks: false,
	notShowHrLine: true,
};

const additionalFieldOptionsListWithSimilarValues = {
	60: [
		{
			value: "Been offered “free insurance”, a cash payment, or some other promised benefit as an incentive",
			text: "Been offered “free insurance”, a cash payment, or some other promised benefit as an incentive",

		},
		{
			value: "Discussed selling this life insurance policy",
			text: "Discussed selling this life insurance policy",
		},
		{
			value: "Had an evaluation to determine the insured’s life expectancy (how long the insured will live)",
			text: "Had an evaluation to determine the insured’s life expectancy (how long the insured will live)",
		},
		{
			value: "Discussed changing ownership or beneficiaries once this policy is issued",
			text: "Discussed changing ownership or beneficiaries once this policy is issued",
		},
		{
			value: NOTA,
			text: NOTA,
		},
	],
};

const additionalFieldOptionsListForStagingAndDev = {
	32: [
		{ value: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", text: "Aviation" },
		{ value: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", text: "Foreign Travel" },
		{ value: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", text: "Avocation" },
	],
	...additionalFieldOptionsListWithSimilarValues,
};

const additionalFieldOptionsList = {
	development: additionalFieldOptionsListForStagingAndDev,
	production: {
		32: [
			{ value: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", text: "Aviation" },
			{ value: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", text: "Foreign Travel" },
			{ value: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", text: "Avocation" },
		],
		...additionalFieldOptionsListWithSimilarValues,
	},
};

const environment = process.env.REACT_APP_ENV === "production" ? "production" : "development";

const additionalFieldOptions = additionalFieldOptionsList[environment];

export const getInsuredQuestions = () => {
	const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "88",
            name: "Proposed_Insured_Questions-2",
            label: "Will this insurance replace any existing annuities and/or life insurance?",
            notShowHrLine: false
        },
		{
			...commonOptions,
			key: "1",
			name: "existPolicies",
			label: "Any existing Kai-Zen Plan policies with NIW?",
			label2: "Any existing Kai-Zen Plan policies with NIW?",
			notShowHrLine: false,
			additionalFieldName: "noOfPolicies",
			additionalFieldType: "selectBox",
			additionalFieldRequired: true,
			additionalFieldLabel: "Number of Policies",
			additionalHelperText: "How many policies do you have with us?",
			position: 1,
		},
		{
			...commonOptions,
			key: "2",
			name: "Insured_Questions-2",
			label: "What is the purpose of the Life Insurance / Death Benefit coverage?",
			type: "multi-radio",
			notShowHrLine: false,
			additionalFieldType: "multi-radio",
			additionalFieldName: "coverage",
			position: 3,
		},
		{
			...commonOptions,
			key: "6",
			name: "Insured_Questions-6",
			label: "Amount of life insurance currently applied for, other than the amount being applied for on this application?",
			type: "textField",
			notShowHrLine: false,
			additionalFieldType: "numberTextField",
			additionalFieldName: "liAmount",
			additionalCheckBoxName: "liAmountNone",
			position: 8,
		},

		{
			...commonOptions,
			key: "7",
			name: "isInforceLife",
			label: "Is the Proposed Insured or Owner considering using funds from an inforce life or annuity contract to fund the policy or rider being applied for? (If yes, replacement forms must be provided)",
			notShowHrLine: false,
			additionalFieldType: "textField",
			additionalFieldName: "inforceLifeDesc",
			position: 7,
		},
		{
			...commonOptions,
			key: "8",
			name: "inforcePolicy",
			label: "Do you have any in-force life insurance or annuity contracts including long term care insurance or riders? (If yes, provide details)",
			notShowHrLine: false,
			additionalFieldName: "noOfInforcePolicies",
			additionalFieldType: "selectBox",
			additionalFieldRequired: true,
			additionalFieldLabel: "Number of Policies",
			additionalHelperLabel: "How many policies do you have with us?",
			position: 31,
		},
		{
			...commonOptions,
			key: "9",
			name: "Insured_Questions-9",
			label: "Amount of life insurance currently in force?",
			type: "textField",
			additionalFieldType: "numberTextField",
			additionalFieldName: "inforceAmount",
			additionalCheckBoxName: "inforceAmountNone",
			position: 8,
			notShowHrLine: false,
		},
		{
			...commonOptions,
			key: "55",
			name: "Proposed_Insured_Questions-55",
			label: "Have you ever been charged an extra premium, been declined for coverage, or had coverage canceled for a life insurance policy with another company?",
			notShowHrLine: false,
			additionalFieldType: "textField",
			additionalFieldName: "Remark-55",
			position: 2,
		},
		{
			...commonOptions,
			key: "56",
			name: "Proposed_Insured_Questions-56",
			label: "Do both the proposed insured and the proposed owner(s) believe that this life insurance policy being applied for will meet the insurance needs and objectives of each person?",
			notShowHrLine: false,
			position: 4,
		},
		{
			...commonOptions,
			key: "57",
			name: "Proposed_Insured_Questions-57",
			label: "Did the agent discuss with both the proposed owner(s) and the proposed insured the current life insurance policies and other assets of each person prior to the decision to purchase this life insurance policy?",
			notShowHrLine: false,
			position: 5,
		},
		{
			...commonOptions,
			key: "58",
			name: "Proposed_Insured_Questions-58",
			label: "Does the proposed owner(s) feel that sufficient liquid assets are available to them for living expenses and emergencies in addition to the money allocated to pay the life insurance premiums?",
			notShowHrLine: false,
			position: 6,
		},
		{
			...commonOptions,
			key: "60",
			name: "Proposed_Insured_Questions-60XC",
			label: "Please indicate which of the following discussions have been had with the proposed insured or the proposed owner(s) of this life insurance policy. Check ALL that apply.",
			notShowHrLine: false,
			type: "checkBoxGroup",
			additionalFieldType: "checkBoxGroup",
			additionalFieldRequired: true,
			additionalFieldLabel: "",
			additionalHelperLabel: "",
			additionalSubFieldLabel: "Provide details for any discussions indicated above",
			additionalSubFieldType: "textField",
			additionalSubFieldName: "Proposed_Insured_Questions-60XT_desc",
			additionalFieldOptions: additionalFieldOptions[60],
			position: 9,
		}
	];
	return insuredQuestions;
};

export const proposedInsuredQuestion77: any = () => {
	let additionalField: any = {
		0: [
			{
				value: "Stock",
				text: "Stock",
			},
			{
				value: "Street touring",
				text: "Street touring",
			},
			{
				value: "Street prepared",
				text: "Street prepared",
			},
			{
				value: "Modified",
				text: "Modified",
			},
			{
				value: "Other",
				text: "Other",
				showDetailsBox: true,
				label: "Please Specify",
				placeholder: "Please Specify",
			},
		],
	};
	const insuredQuestions: any[] = [
		{
			...commonOptions,
			name: "Proposed_Insured_Questions-77XCveh",
			label: <Label label={"What class/category/division have you participated in? (Check ALL that apply)"}
						  required={true} />,
			notShowHrLine: false,
			additionalFieldType: "checkBoxGroup",
			additionalFieldOptions: additionalField[0]
		}];
	return insuredQuestions;
};

export enum ExerciseEnum {
	YOGA,
	LIC,
	MIC,
	HIC,
	WL
}
