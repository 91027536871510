import * as React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import LogoBlue from "../styles/images/logo-ilia.png";
import SentEmail from "../styles/images/sent-mail.png";
import One from "../styles/images/1.jpeg";
import Two from "../styles/images/2.jpeg";
import Three from "../styles/images/3.jpeg";
import Four from "../styles/images/4.jpeg";
import Five from "../styles/images/5.jpeg";
import Six from "../styles/images/6.jpeg";
import Seven from "../styles/images/7.jpeg";
import Eight from "../styles/images/8.jpeg";
import { history } from "../configureStore";
import LoaderGif from "../styles/images/mainloader_ilia_new.gif";

type Props = {}

function LandingPage(_props: Props) {
    const [progress, setProgress] = React.useState<boolean>(true);
	const sliderSettings = {
		autoplay: true,
		autoplaySpeed: 8000,
		dots: false,
		infinite: false,
		fade: true,
		arrows: false,
	};

    React.useEffect(() => {
        const updateRootClasses = () => {
            document.body.classList.remove("adminClasses");
            let frontendCss = document.getElementById('frontendCss');
            frontendCss && frontendCss.setAttribute("href", "/css/landingOLD.css");
            setTimeout(()=> setProgress( false ), 1000);
        };

        updateRootClasses();
    }, []);
    const myStyle = {
        position: "absolute" as "absolute",
        "z-index": "999999",
        width: "100%",
        height: "100%",
        background: "#ffffff",
    };
    const imgStyle = {
        position: "absolute" as "absolute",
        left: "0",
        right: "0",
        margin:'0 auto',
        top:'30%'
    };
	return (
		<div id="home-slider-container">
            {progress &&
            <div style={myStyle}>
                <img style={imgStyle} src={LoaderGif} alt='Loading...'/>
            </div>}
			<img alt="" className="logoImage" src={LogoBlue}/>
			<span className="overlay"></span>
			<button type="button" onClick={() => history.push('/login')}>Login Here <img alt="" src={SentEmail}/></button>
			<Slider {...sliderSettings} className="home-slider">
				<div className="slider-item">
					<img alt="" src={One}/>
					<div className="slider-content">
						<div className="container">
							<p><span className="quotes">"</span>Shouldn’t the rest of your life be the best of your life<span
								className="quotes">"</span>

							</p>
						</div>
					</div>
				</div>
				<div className="slider-item">
					<img alt="" src={Two}/>
					<div className="slider-content">
						<div className="container">
							<p><span className="quotes">"</span>Shouldn’t the rest of your life be the best of your life<span
								className="quotes">"</span>

							</p>
						</div>
					</div>
				</div>
				<div className="slider-item">
					<img alt="" src={Three}/>
					<div className="slider-content">
						<div className="container">
							<p><span className="quotes">"</span>Shouldn’t the rest of your life be the best of your life<span
								className="quotes">"</span>

							</p>

						</div>
					</div>
				</div>
				<div className="slider-item">
					<img alt="" src={Four}/>
					<div className="slider-content">
						<div className="container">
							<p><span className="quotes">"</span>Shouldn’t the rest of your life be the best of your life<span
								className="quotes">"</span>

							</p>

						</div>
					</div>
				</div>
				<div className="slider-item">
					<img alt="" src={Five}/>
					<div className="slider-content">
						<div className="container">
							<p><span className="quotes">"</span>Shouldn’t the rest of your life be the best of your life<span
								className="quotes">"</span>

							</p>
						</div>
					</div>
				</div>
				<div className="slider-item">
					<img alt="" src={Six}/>
					<div className="slider-content">
						<div className="container">
							<p><span className="quotes">"</span>Shouldn’t the rest of your life be the best of your life<span
								className="quotes">"</span>

							</p>
						</div>
					</div>
				</div>
				<div className="slider-item">
					<img alt="" src={Seven}/>
					<div className="slider-content">
						<div className="container">
							<p><span className="quotes">"</span>Shouldn’t the rest of your life be the best of your life<span
								className="quotes">"</span>

							</p>
						</div>
					</div>
				</div>
				<div className="slider-item">
					<img alt="" src={Eight}/>
					<div className="slider-content">
						<div className="container">
							<p><span className="quotes">"</span>Shouldn’t the rest of your life be the best of your life<span
								className="quotes">"</span>

							</p>
						</div>
					</div>
				</div>
			</Slider>
		</div>
	);
}

export default LandingPage;
