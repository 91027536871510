import { ActionType, PagingParams } from "../model";
import publicIp from "public-ip";

export const companyClientList = (query: PagingParams) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('account/company/clients', {}, query);
		return  resp;
		// return dispatch({
		// 	type: ActionType.COMPANY_CLIENT_LIST,
		// 	payload: resp
		// });
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const getCompanyDetails = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp = await api.get(`account/company/details/${id}`, {}, {});
		return  resp;
		// return dispatch({
		// 	type: ActionType.COMPANY_CLIENT_LIST,
		// 	payload: resp
		// });
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};



export const updateCompanyDetails = (payload: any, queryPayload) => async (dispatch: Function, getState: Function, api: { put: Function })  => {
    try {
        await api.put('account/company/update', payload, queryPayload);
        return dispatch({type: ActionType.ALERT, payload: {message: "Updated Successfully.", statusCode: 200}});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

/**
 * Activate/De-activate group participant
 * @param id
 * @param notes
 */
export const updateGroupParticipant = (id:number, notes:string, showMessage:boolean = true) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let clientIp: any = await publicIp.v4();
        await api.put(`account/group-participant/active-deactivate/${id}`, {notes, clientIp}, {});
        if(!showMessage){
            return true;
        }
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: notes ? "Participant Disabled Successfully" : "Participant Activated Successfully",
                statusCode: 200
            },
        });
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const createSigner = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post('account/group/signer', payload, {});
        return dispatch({
                type: ActionType.ALERT,
                payload: { message: "Signer created successfully.", statusCode: 200 }
            });
        } catch(err) {
            return dispatch({
                type: ActionType.ALERT,
                payload: err
            });
        }
}


export const listGroupSigners = (query: PagingParams) =>  async (dispatch: Function, getState: Function, api: any) => {
	try {
		let response =  await api.get(`account/group/signer`, {}, query);
		return {status :true , data : response}
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err

		});
		return {status :false , data : []}
	}
};


export const getGroupDocuments = (query: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response =  await api.get(`companydoc`, {}, query);
        return response;
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const saveGroupDocuments = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post(`companydoc`, payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Saved successfully", statusCode: 200},
        });
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const deleteGroupDocuments = (docId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.delete(`companydoc/${docId}`, {}, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Deleted successfully", statusCode: 200},
        });
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};
export const saveGroupNotes = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post(`account/company/note`, payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Saved successfully", statusCode: 200},
        });
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const getGroupNotes = (query: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const result = await api.get(`account/company/note`, {}, query);
        return result;
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}

export const deleteGroupSigners = (id: number, notes: string) =>  async (dispatch: Function, getState: Function, api: any) => {
	try {
		await api.delete(`account/group/signer/${id}`, {notes});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Deleted successfully", statusCode: 200},
        });
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err

		});
		return {status :false , data : []}
	}
};

export const updateCompanyByKey = (payload:any, id:number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let res:any = await api.put('account/company/update/keyValuePair/' + id, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Updated successfully", statusCode: 200},
        });
        return res;
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}