import LoaderGif from "../styles/images/loader_nw.gif";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";

const DocusignLoader = () => {

     return (
         <div className="commonLoaderWrap textCenter">
             <img src={LoaderGif} alt='Loading...'/>
             <br/>
             <Typography variant="caption" className="w100">
                 Documents are being updated. This could take some time so please be patient and don't close this window.
             </Typography>
         </div>
     )
}

export default DocusignLoader;