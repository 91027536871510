import { Button, Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { AutoComplete, SelectField } from "../formikFormInputs";
import { FormInputs } from "../../pages/approvedAgents/ApprovedAgentsPage";
import { DialogTitle } from "../ConfirmEnrollment";
import { Label } from "../formInputs";
import { noop } from "../../utils/commonUtils";

export enum DialogAggregationImoType {
    ADD = "Add Aggregator IMO(s)",
    EDIT = "Edit Aggregator IMO(s)"
}

type DialogImoAggregatorProps = {
    open: boolean;
    handleClose: () => void;
    onSubmit: (values: any) => void;
    initialImoDivision: any;
    title: DialogAggregationImoType | null | undefined;
    divisionsOptions: any,
    allImoOptions: any,
    getImosAll: any,
    imoDivisions: any
}

export const DialogAddAggregatorImo = ({
                                           open,
                                           handleClose,
                                           onSubmit,
                                           initialImoDivision,
                                           title,
                                           divisionsOptions,
                                           allImoOptions,
                                           getImosAll,
                                           imoDivisions
                                       }: DialogImoAggregatorProps) => {
    const [loadingIMOs, setLoadingIMOs] = useState(false);
    const [selIMO, setSelIMO] = useState<any>(null);
    const [aggregatorDivOpt, setAggregatorDivOpt] = useState<any[]>([]);
    const getImos = async (_searchVal: string) => {
        setLoadingIMOs(true);
        await getImosAll(_searchVal);
        setLoadingIMOs(false);
    };
    const submit = (values: any, {setSubmitting, resetForm}: any) => {
        const imoDetail = allImoOptions.find((item)=>{
            return item.id == values.imoId
        })
        if (values.divisionName && !isNaN(values.divisionName)) { // if division name is id of the division
            const selectedDivision = divisionsOptions.find((item) => {
                return item.id === values.divisionName
            })
            values.divisionName = selectedDivision.divisionName;
            values.divisionId = selectedDivision.id;
        }
        values.imoName = imoDetail.name;
        onSubmit(values);
        handleClose();
    }

    useEffect(() => {
        const objArr = divisionsOptions.map((item: any) => {
            if (item.id && !isNaN(item.id)) { // set id as value if division has id
                return {
                    id: item.id,
                    key: item.id,
                    value: item.id,
                    name: item.divisionName
                }
            }
            return {
                id: item.divisionName,
                key: item.divisionName,
                value: item.divisionName,
                name: item.divisionName,
            }
        });

        if (objArr && !objArr.length && !!selIMO) {
            objArr.push({
                key: 0,
                value: {},
                name: "No Division Available",
            })
        }

        setAggregatorDivOpt(objArr);
    }, [divisionsOptions, selIMO]);

    const formRef: any = useRef();

    const imoFields = [
        {
            cb: getImos,
            component: AutoComplete,
            contentList: allImoOptions,
            disabled: false,
            label: <Label label={"Select an IMO"}/>,
            loading: loadingIMOs,
            name: "imoId",
            onSelectChange: (imoId: number) => setSelIMO(imoId),
            rowSize: 12,
        },
        {
            component: SelectField,
            label: <Label label={"Add a Aggregator Division to this IMO"}/>,
            multiple: false,
            name: "divisionName",
            onSelectChange: noop,
            options: aggregatorDivOpt,
            rowSize: 12,
        },
    ]
    const validateField = (values) => {
        let errors: any = {};
        if (!values.imoId) {
            errors.imoId = "IMO is required."
        }
        if (imoDivisions.find((item) => item.imoId === values.imoId)) {
            errors.imoId = "IMO already added."
        }
        return errors;
    }

    return (
            <Dialog open={open} fullWidth maxWidth="sm">
                <DialogTitle onClose={handleClose} id="dialog-imo">
                    {title}
                </DialogTitle>
                <Formik
                        innerRef={formRef}
                        initialValues={initialImoDivision}
                        onSubmit={submit}
                        validationSchema={Yup.object({
                            imoId: Yup.number().required("IMO is required.")
                        })}
                        validate={(values)=> validateField(values)}
                >
                    {({errors, values, touched, handleChange,
                          handleBlur, handleSubmit, dirty, isSubmitting}) => (
                            <form onSubmit={handleSubmit}>
                                <DialogContent>
                                    <Grid container spacing={3}>
                                        <FormInputs
                                                fields={imoFields}
                                                {...{errors, values, touched, handleChange, handleBlur}}
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container justify="space-between" style={{padding: "1rem"}}>
                                        <Button
                                                size="small"
                                                className="floatLeft mt20 mb20"
                                                type="button"
                                                variant="contained"
                                                onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                                size="small"
                                                className="floatRight mt20 mb20"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={!dirty || isSubmitting}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </DialogActions>
                            </form>
                    )}
                </Formik>
            </Dialog>
    )
}