import request, {AxiosError} from 'axios';

export interface IRequestParams {
    method: string;
    url: string;
    queryParameters?: { [key: string]: string | boolean | number | Date | undefined };
    body?: Object
}

type ConfigType = {
    method: string,
    headers?: { 'Content-Type': string },
    url: string,
    params?: { [key: string]: string | boolean | number | Date | undefined },
    data?: Object
}

const isDebug = (debugKey = 'debug') => {
    const debug = new URLSearchParams(window.location.search).get(debugKey);
    return debug && (debug === 'true' || debug === 'dev' || debug === '1');
};

export abstract class ApiService {
    errorDebugger = (error: AxiosError) => {
        const qsDebugger = isDebug();
        if (!qsDebugger) {
            return;
        }
        if (error.response) {
            console.info('The request was made and the server responded with a status code that falls out of the range of 2xx');
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            console.info('The request was made but no response was received')
            console.log(error.request);
        } else {
            console.info('Something happened in setting up the request that triggered an Error')
            console.log('Error', error.message);
        }
        console.log(error.config);
        console.info(navigator);
    };

    protected async executeRequest<T>(params: IRequestParams) {
        return new Promise<T>((resolve, reject) => {
            let config: ConfigType = {
                method: params.method,
                headers: {'Content-Type': 'application/json'},
                url: params.url,
            };

            const queryParameters = params.queryParameters;

            if (queryParameters) {
                config.params = queryParameters;
            }

            if (params.body) {
                config.data = params.body;
            }

            request(config as unknown as string)
                .then(function (response: { data: PromiseLike<T> }) {
                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    this.errorDebugger(error);
                    if (error.response && error.response.data) {
                        reject(Object.assign({}, error.response.data, {code: Number(error.response.status)}));
                    } else {
                        reject(error.isAxiosError ? error.toJSON() : error);
                    }
                });
        });
    }

    protected async executeFormDataRequest<T>(params: IRequestParams) {
        return new Promise<T>((resolve, reject) => {
            let config: ConfigType = {
                method: params.method,
                url: params.url,
            };
            const queryParameters = params.queryParameters;
            if (queryParameters) {
                config.params = queryParameters;
            }

            if (params.body) {
                config.data = params.body;
            }

      request(config as unknown as string)
          .then(function (response: { data: PromiseLike<T> }) {
            resolve(response.data);
          })
          .catch((error: { response: { data: unknown, status: string } }) => {
              this.errorDebugger(error as any);
            reject(Object.assign({}, error.response.data, { code: Number(error.response.status) }));
          });
    });
  }
}


export class Api extends ApiService {
    baseApiUrl: string;

    constructor(url: string) {
        super()
        this.baseApiUrl = url;
    }

    public async get(url: string, data: Object = {}, query: {
        [key: string]: string | boolean | number | Date | undefined
    } = {}) {
        const requestParams: IRequestParams = {
            method: 'GET',
            url: `${this.baseApiUrl}/${url}`,
            body: data,
            queryParameters: query
        };
        return this.executeRequest(requestParams);
    }

    public async post(url: string, data: Object = {}, query: {
        [key: string]: string | boolean | number | Date | undefined
    } = {}) {
        const requestParams: IRequestParams = {
            method: 'POST',
            url: `${this.baseApiUrl}/${url}`,
            body: data,
            queryParameters: query
        };
        return this.executeRequest(requestParams);
    }

    public async put(url: string, data: Object = {}, query: {
        [key: string]: string | boolean | number | Date | undefined
    } = {}) {
        const requestParams: IRequestParams = {
            method: 'PUT',
            url: `${this.baseApiUrl}/${url}`,
            body: data,
            queryParameters: query
        };
        return this.executeRequest(requestParams);
    }

    public async patch(url: string, data: Object = {}, query: {
        [key: string]: string | boolean | number | Date | undefined
    } = {}) {
        const requestParams: IRequestParams = {
            method: 'PATCH',
            url: `${this.baseApiUrl}/${url}`,
            body: data,
            queryParameters: query
        };
        return this.executeRequest(requestParams);
    }

    public async delete(url: string, data: { [key: string]: string | boolean | number | Date | undefined }) {
        const requestParams: IRequestParams = {
            method: 'DELETE',
            url: `${this.baseApiUrl}/${url}`,
            body: data
        };
        return this.executeRequest(requestParams);
    }

    public postFormData(url: string, data: Object, query: {
        [key: string]: string | boolean | number | Date | undefined
    }) {
        const requestParams: IRequestParams = {
            method: 'POST',
            url: `${this.baseApiUrl}/${url}`,
            body: data,
            queryParameters: query
        };
        return this.executeFormDataRequest(requestParams);
    }
}
