import {carouselVideo} from "./model/carouselVideo";
import moment from "moment";
import {AccountStatus} from "./model";
import {pez, upperCase} from "./utils/commonUtils";

const version = 3;

export const agentTypeDropDown = [
    {
        text: "Ghost",
        value: 2,
    },
    {
        text: "Preferred",
        value: 1,
    },
    {
        text: "Approved",
        value: 0,
    }
];

export const forNonUsCitizen = [
    {
        text: "SSN",
        value: 1,
    },
    {
        text: "ITIN",
        value: 0,
    }
];

export const identityDocument = [
    {
        text: "Driver's License",
        value: "drivingLicence",
    },
    {
        text: "Passport",
        value: "passport",
    }
]

export const residencyDocumentsList = [
    {
        text: "Green Card",
        value: "greenCard",
    },
    {
        text: "Notice of Action",
        value: "noticeOfAction",
    },
    {
        text: "Visa",
        value: "visa",
    },
    {
        text: "Other",
        value: "other",
    }
]

export enum ImoClass {
    APPROVED = 'm-approved',
    INVITED = 'invited',
    PREFERRED = 'm-preferred',
    REJECTED = 'rejected',
    UNVERIFIED = 'unverified',
    GHOST = 'm-ghost'
}

export const imoClassDropDown = [
	{
		id: 1,
		value: 'm-approved',
		name: 'Approved'
	}, {
		id: 2,
		value: 'm-preferred',
		name: 'Preferred'
	},
    {
        id: 3,
        value: 'm-ghost',
        name: 'Ghost'
    }
];

export const allOption = {
    id: 0,
    value: 'all',
    name: 'All'
}

export const approvedImoTypeDropDown = [
    allOption,
    ...imoClassDropDown.filter(x => x.name !== 'Other'),
    {
        id: 4,
        value: 'invited',
        name: 'Invited'
    }, {
		id: 5,
		value: 'unverified',
		name: 'Unverified'
	}
]

export const getImoNameByValue = value => {
    const imo = imoClassDropDown.find(x => x.value === value)
    return imo && imo.name
}

export const imoRegionDropDown = [
    {
        id: 1,
        value: 'west',
        name: 'West'
    }, {
        id: 2,
        value: 'east',
        name: 'East'
    }, {
        id: 3,
        value: 'house',
        name: 'House'
    }, {
        id: 4,
        value: 'central',
        name: 'Central'
    },
    {
        id: 5,
        value: 'special_accounts_brad',
        name: 'Special Accounts - Brad'
    }
];


export const regionDropDown: object[]  = [
    {
        id: 'ALL',
        value: 'All',
        name: 'All'
    },
    {
        id: 'west',
        value: 'west',
        name: 'West'
    }, {
        id: 'east',
        value: 'east',
        name: 'East'
    }, {
        id: 'house',
        value: 'house',
        name: 'House'
    }, {
        id: 'central',
        value: 'central',
        name: 'Central'
    },
    {
        id: 'special_accounts_brad',
        value: 'special_accounts_brad',
        name: 'Special Accounts - Brad'
    }
];

export const getImoRegion = value => {
    const imoRegion = imoRegionDropDown.find( x => x.value === value)
    return imoRegion && imoRegion.name
}

export const imoMemberTypesDropDown = [
	{
		id: 1,
		value: 'm-casemanager',
		name: 'Case Manager'
	}, {
		id: 2,
		value: 'm-marketer',
		name: 'Marketer'
	}, {
		id: 3,
		value: 'm-regional',
		name: 'Regional'
	}, {
		id: 4,
		value: 'm-executive',
		name: 'Executive'
	}
];

type G = { name: string; id: number; value: number };
export const genderDropDown: G[] = [
    {
		id: 1,
		value: 1,
		name: 'Male'
	}, {
		id: 2,
		value: 2,
		name: 'Female'
	}
];

const relations = ['Spouse', 'Son', 'Daughter', 'Mother', 'Father', 'Brother', 'Sister', 'Nephew', 'Niece', 'Trust', 'Estate', 'Other'];
export const relationshipDropDown: object[] = relations.map(r => ({key: upperCase(r), name: r, value: upperCase(r)}));

export const stateDropDown = [
	{ id: 1, name: 'Alabama', value: 'AL', isCommunityState : false, region: "east" },
	{ id: 2, name: 'Alaska', value: 'AK', isCommunityState : false, region: "west" },
	{ id: 3, name: 'Arizona', value: 'AZ', isCommunityState : true, region: "west" },
	{ id: 4, name: 'Arkansas', value: 'AR', isCommunityState : false, region: "central" },
	{ id: 5, name: 'California', value: 'CA', isCommunityState : true, region: "west" },
	{ id: 6, name: 'Colorado', value: 'CO', isCommunityState : false, region: "west" },
	{ id: 7, name: 'Connecticut', value: 'CT', isCommunityState : false, region: "east" },
	{ id: 8, name: 'Delaware', value: 'DE', isCommunityState : false, region: "east" },
	{ id: 9, name: 'Florida', value: 'FL', isCommunityState : false, region: "east" },
	{ id: 10, name: 'Georgia', value: 'GA', isCommunityState : false, region: "east" },
	{ id: 11, name: 'Hawaii', value: 'HI', isCommunityState : false, region: "west" },
	{ id: 12, name: 'Idaho', value: 'ID', isCommunityState : true, region: "west" },
	{ id: 13, name: 'Illinois', value: 'IL', isCommunityState : false, region: "central" },
	{ id: 14, name: 'Indiana', value: 'IN', isCommunityState : false, region: "east" },
	{ id: 15, name: 'Iowa', value: 'IA', isCommunityState : false, region: "central" },
	{ id: 16, name: 'Kansas', value: 'KS', isCommunityState : false, region: "central" },
	{ id: 17, name: 'Kentucky', value: 'KY', isCommunityState : false, region: "east" },
	{ id: 18, name: 'Louisiana', value: 'LA', isCommunityState : true, region: "east" },
	{ id: 19, name: 'Maine', value: 'ME', isCommunityState : false, region: "east" },
	{ id: 20, name: 'Maryland', value: 'MD', isCommunityState : false, region: "east" },
	{ id: 21, name: 'Massachusetts', value: 'MA', isCommunityState : false, region: "east" },
	{ id: 22, name: 'Michigan', value: 'MI', isCommunityState : false, region: "central" },
	{ id: 23, name: 'Minnesota', value: 'MN', isCommunityState : false, region: "central" },
	{ id: 24, name: 'Mississippi', value: 'MS', isCommunityState : false, region: "east" },
	{ id: 25, name: 'Missouri', value: 'MO', isCommunityState : false, region: "central" },
	{ id: 26, name: 'Montana', value: 'MT', isCommunityState : false, region: "west" },
	{ id: 27, name: 'Nebraska', value: 'NE', isCommunityState : false, region: "central" },
	{ id: 28, name: 'Nevada', value: 'NV', isCommunityState : true, region: "west" },
	{ id: 29, name: 'New Hampshire', value: 'NH', isCommunityState : false, region: "east" },
	{ id: 30, name: 'New Jersey', value: 'NJ', isCommunityState : false, region: "east" },
	{ id: 31, name: 'New Mexico', value: 'NM', isCommunityState : true, region: "west" },
	{ id: 32, name: 'New York', value: 'NY', isCommunityState : false, region: "east" },
	{ id: 33, name: 'North Carolina', value: 'NC', isCommunityState : false, region: "east" },
	{ id: 34, name: 'North Dakota', value: 'ND', isCommunityState : false, region: "central" },
	{ id: 35, name: 'Ohio', value: 'OH', isCommunityState : false, region: "east" },
	{ id: 36, name: 'Oklahoma', value: 'OK', isCommunityState : false, region: "central" },
	{ id: 37, name: 'Oregon', value: 'OR', isCommunityState : false, region: "west" },
	{ id: 38, name: 'Pennsylvania', value: 'PA', isCommunityState : false, region: "east" },
	{ id: 39, name: 'Rhode Island', value: 'RI', isCommunityState : false, region: "east" },
	{ id: 40, name: 'South Carolina', value: 'SC', isCommunityState : false, region: "east" },
	{ id: 41, name: 'South Dakota', value: 'SD', isCommunityState : false, region: "central" },
	{ id: 42, name: 'Tennessee', value: 'TN', isCommunityState : false, region: "east" },
	{ id: 43, name: 'Texas', value: 'TX', isCommunityState : true, region: "central" },
	{ id: 44, name: 'Utah', value: 'UT', isCommunityState : false, region: "west" },
	{ id: 45, name: 'Vermont', value: 'VT', isCommunityState : false, region: "east" },
	{ id: 46, name: 'Virginia', value: 'VA', isCommunityState : false, region: "east" },
	{ id: 47, name: 'Washington', value: 'WA', isCommunityState : true, region: "west" },
	{ id: 48, name: 'West Virginia', value: 'WV', isCommunityState : false, region: "east" },
	{ id: 49, name: 'Wisconsin', value: 'WI', isCommunityState : true, region: "central" },
	{ id: 50, name: 'Wyoming', value: 'WY', isCommunityState : false, region: "west" },
    { id: 51, name: 'District Of Columbia', value: 'DC', isCommunityState: false, region: "east"},
    { id: 52, name: 'Guam', value: 'GU', isCommunityState : true, region: "west" },
    { id: 53, name: 'Puerto Rico', value: 'PR', isCommunityState : true, region: "east" },
    { id: 54, name: 'American Samoa', value: 'AS', isCommunityState : false, region: "west" },
    { id: 55, name: 'Armed Forces Americas (except Canada)', value: 'AA', isCommunityState : false, region: "central" },
    { id: 56, name: 'Armed Forces Canada, Africa, Europe, Middle East', value: 'AE', isCommunityState : false, region: "east" },
    { id: 57, name: 'Guantanamo Bay (US Naval Base) Cuba', value: 'GB', isCommunityState : false, region: "east" },
    { id: 58, name: 'Northern Mariana Islands', value: 'MP', isCommunityState : true, region: "west" },
    { id: 59, name: 'US Armed Forces Pacific', value: 'AP', isCommunityState : false, region: "west" },
    { id: 60, name: 'Virgin Islands', value: 'VI', isCommunityState : true , region: "east" },
    { id: 61, name: 'Federated States Of Micronesia', value: 'FS', isCommunityState : false , region: "west" },
    { id: 62, name: 'Marshall Islands', value: 'MH', isCommunityState : false , region: "west" },
    { id: 63, name: 'Palau Island', value: 'PW', isCommunityState : false , region: "west" }
];

export const countryDropDown: object[] = [
	{
		id: 1,
		value: 1,
		name: 'United Kingdom'
	}, {
		id: 2,
		value: 2,
		name: 'United States'
	}, {
		id: 3,
		value: 3,
		name: 'India'
	}
];

export const USA = "United States of America";

export const countryDD: object[] = [
    {
        code: "OLI_NATION_USA",
        description: USA
    },
    {
        code: "OLI_NATION_AFGHANISTAN",
        description: "Afghanistan"
    },
    {
        code: "OLI_NATION_ALBANIA",
        description: "Albania"
    },
    {
        code: "OLI_NATION_ALGERIA",
        description: "Algeria"
    },
    {
        code: "OLI_NATION_AMERICANSAMOA",
        description: "American Samoa"
    },
    {
        code: "OLI_NATION_ANDORRA",
        description: "Andorra"
    },
    {
        code: "OLI_NATION_ANGOLA",
        description: "Angola"
    },
    {
        code: "OLI_NATION_ANGUILLA",
        description: "Anguilla"
    },
    {
        code: "OLI_NATION_ANTARCTICA",
        description: "Antarctica"
    },
    {
        code: "OLI_NATION_ANTIGUABARBUDA",
        description: "Antigua And Barbuda"
    },
    {
        code: "OLI_NATION_ARGENTINA",
        description: "Argentina"
    },
    {
        code: "OLI_NATION_ARMENIA",
        description: "Armenia"
    },
    {
        code: "OLI_NATION_ARUBA",
        description: "Aruba"
    },
    {
        code: "OLI_NATION_AUSTRALIA",
        description: "Australia"
    },
    {
        code: "OLI_NATION_AUSTRIA",
        description: "Austria"
    },
    {
        code: "OLI_NATION_AZERBAIJAN",
        description: "Azerbaijan"
    },
    {
        code: "OLI_NATION_BAHAMAS",
        description: "Bahamas"
    },
    {
        code: "OLI_NATION_BAHRAIN",
        description: "Bahrain"
    },
    {
        code: "OLI_NATION_BANGLADESH",
        description: "Bangladesh"
    },
    {
        code: "OLI_NATION_BARBADOS",
        description: "Barbados"
    },
    {
        code: "OLI_NATION_BELARUS",
        description: "Belarus"
    },
    {
        code: "OLI_NATION_BELGIUM",
        description: "Belgium"
    },
    {
        code: "OLI_NATION_BELIZE",
        description: "Belize"
    },
    {
        code: "OLI_NATION_BENIN",
        description: "Benin"
    },
    {
        code: "OLI_NATION_BERMUDA",
        description: "Bermuda"
    },
    {
        code: "OLI_NATION_BHUTAN",
        description: "Bhutan"
    },
    {
        code: "OLI_NATION_BOLIVIA",
        description: "Bolivia"
    },
    {
        code: "OLI_NATION_BOSNIAHERZEGOVINA",
        description: "Bosnia And Herzegovina"
    },
    {
        code: "OLI_NATION_BOTSWANA",
        description: "Botswana"
    },
    {
        code: "OLI_NATION_BOUVETISLAND",
        description: "Bouvet Island"
    },
    {
        code: "OLI_NATION_BRAZIL",
        description: "Brazil"
    },
    {
        code: "OLI_NATION_BRITISHINDIANOCNTERR",
        description: "British Indian Ocean Territory"
    },
    {
        code: "OLI_NATION_BRUNEI",
        description: "Brunei"
    },
    {
        code: "OLI_NATION_BULGARIA",
        description: "Bulgaria"
    },
    {
        code: "OLI_NATION_BURKINAFASO",
        description: "Burkina Faso"
    },
    {
        code: "OLI_NATION_BURMA",
        description: "Burma"
    },
    {
        code: "OLI_NATION_BURUNDI",
        description: "Burundi"
    },
    {
        code: "OLI_NATION_CAMBODIA",
        description: "Cambodia"
    },
    {
        code: "OLI_NATION_CAMEROON",
        description: "Cameroon"
    },
    {
        code: "OLI_NATION_CANADA",
        description: "Canada"
    },
    {
        code: "OLI_NATION_CAPEVERDEISLAND",
        description: "Cape Verde"
    },
    {
        code: "OLI_NATION_CAYMANIS",
        description: "Cayman Islands"
    },
    {
        code: "OLI_NATION_CENTRALAFRICANREP",
        description: "Central African Rep."
    },
    {
        code: "OLI_NATION_CHAD",
        description: "Chad"
    },
    {
        code: "OLI_NATION_CHILE",
        description: "Chile"
    },
    {
        code: "OLI_NATION_CHINA",
        description: "China"
    },
    {
        code: "OLI_NATION_CHRISTMASISLANDS",
        description: "Christmas Island"
    },
    {
        code: "OLI_NATION_COCOS",
        description: "Cocos island"
    },
    {
        code: "OLI_NATION_COLUMBIA",
        description: "Colombia"
    },
    {
        code: "OLI_NATION_COMOROS",
        description: "Comoros"
    },
    {
        code: "OLI_NATION_CONGO",
        description: "Congo"
    },
    {
        code: "OLI_NATION_CONGODEMREP",
        description: "Congo Dem. Rep."
    },
    {
        code: "OLI_NATION_COOKISLANDS",
        description: "Cook Islands"
    },
    {
        code: "OLI_NATION_COSTARICA",
        description: "Costa Rica"
    },
    {
        code: "OLI_NATION_COTEDIVORIE",
        description: "Cote d’Ivoire"
    },
    {
        code: "OLI_NATION_CROATIA",
        description: "Croatia"
    },
    {
        code: "OLI_NATION_CUBA",
        description: "Cuba"
    },
    {
        code: "OLI_NATION_CYPRUS",
        description: "Cyprus"
    },
    {
        code: "OLI_NATION_CZECHREPUBLIC",
        description: "Czech Republic"
    },
    {
        code: "OLI_NATION_DENMARK",
        description: "Denmark"
    },
    {
        code: "OLI_NATION_DJIBOUTI",
        description: "Djibouti"
    },
    {
        code: "OLI_NATION_DOMINICA",
        description: "Dominica"
    },
    {
        code: "OLI_NATION_DOMINICANREPUBLIC",
        description: "Dominican Republic"
    },
    {
        code: "OLI_NATION_ECUADOR",
        description: "Ecuador"
    },
    {
        code: "OLI_NATION_EGYPT",
        description: "Egypt"
    },
    {
        code: "OLI_NATION_ELSALVADOR",
        description: "El Salvador"
    },
    {
        code: "OLI_NATION_EQUATORIALGUINEA",
        description: "Equatorial Guinea"
    },
    {
        code: "OLI_NATION_ERITREA",
        description: "Eritrea"
    },
    {
        code: "OLI_NATION_ESTONIA",
        description: "Estonia"
    },
    {
        code: "OLI_NATION_ETHIOPIA",
        description: "Ethiopia"
    },
    {
        code: "OLI_NATION_FALKLANDISLANDS",
        description: "Falkland Islands"
    },
    {
        code: "OLI_NATION_FAEROEISLANDS",
        description: "Faroe Islands"
    },
    {
        code: "OLI_NATION_FIJI",
        description: "Fiji"
    },
    {
        code: "OLI_NATION_FINLAND",
        description: "Finland"
    },
    {
        code: "OLI_NATION_FRANCE",
        description: "France"
    },
    {
        code: "OLI_NATION_FRENCHGUIANA",
        description: "French Guiana"
    },
    {
        code: "OLI_NATION_FRENCHPOLYNESIA",
        description: "French Polynesia"
    },
    {
        code: "OLI_NATION_FRENCHSOUTHERNTERRITIOIES",
        description: "French Southern Territories"
    },
    {
        code: "OLI_NATION_GABON",
        description: "Gabon"
    },
    {
        code: "OLI_NATION_GAMBIA",
        description: "Gambia"
    },
    {
        code: "OLI_NATION_GEORGIA",
        description: "Georgia"
    },
    {
        code: "OLI_NATION_GERMANY",
        description: "Germany"
    },
    {
        code: "OLI_NATION_GHANA",
        description: "Ghana"
    },
    {
        code: "OLI_NATION_GIBRALTAR",
        description: "Gibraltar"
    },
    {
        code: "OLI_NATION_GREECE",
        description: "Greece"
    },
    {
        code: "OLI_NATION_GREENLAND",
        description: "Greenland"
    },
    {
        code: "OLI_NATION_CRENADA",
        description: "Grenada"
    },
    {
        code: "OLI_NATION_GUADALOUPE",
        description: "Guadeloupe"
    },
    {
        code: "OLI_NATION_GUAM",
        description: "Guam"
    },
    {
        code: "OLI_NATION_GUATEMALA",
        description: "Guatemala"
    },
    {
        code: "OLI_NATION_GUERNSEY",
        description: "Guernsey"
    },
    {
        code: "OLI_NATION_GUINEA",
        description: "Guinea"
    },
    {
        code: "OLI_NATION_GUINEABISSAU",
        description: "Guinea Bissau"
    },
    {
        code: "OLI_NATION_GUYANA",
        description: "Guyana"
    },
    {
        code: "OLI_NATION_HAITI",
        description: "Haiti"
    },
    {
        code: "OLI_NATION_HEARDISLISLAND",
        description: "Heard Island"
    },
    {
        code: "OLI_NATION_HOLYSEE",
        description: "Holy See (Vatican City State)"
    },
    {
        code: "OLI_NATION_HONDURAS",
        description: "Honduras"
    },
    {
        code: "OLI_NATION_HONGKONG",
        description: "Hong Kong"
    },
    {
        code: "OLI_NATION_HUNGARY",
        description: "Hungary"
    },
    {
        code: "OLI_NATION_ICELAND",
        description: "Iceland"
    },
    {
        code: "OLI_NATION_INDIA",
        description: "India"
    },
    {
        code: "OLI_NATION_INDONESIA",
        description: "Indonesia"
    },
    {
        code: "OLI_NATION_IRAN",
        description: "Iran"
    },
    {
        code: "OLI_NATION_IRAQ",
        description: "Iraq"
    },
    {
        code: "OLI_NATION_IRELAND",
        description: "Ireland"
    },
    {
        code: "OLI_NATION_ISLEOFMAN",
        description: "Isle of Man"
    },
    {
        code: "OLI_NATION_ISRAEL",
        description: "Israel"
    },
    {
        code: "OLI_NATION_ITALY",
        description: "Italy"
    },
    {
        code: "OLI_NATION_JAMAICA",
        description: "Jamaica"
    },
    {
        code: "OLI_NATION_JAPAN",
        description: "Japan"
    },
    {
        code: "OLI_NATION_JERSEY",
        description: "Jersey"
    },
    {
        code: "OLI_NATION_JORDAN",
        description: "Jordan"
    },
    {
        code: "OLI_NATION_KAZAKHSTAN",
        description: "Kazakhstan"
    },
    {
        code: "OLI_NATION_KENYA",
        description: "Kenya"
    },
    {
        code: "OLI_NATION_KIRIBATI",
        description: "Kiribati"
    },
    {
        code: "OLI_NATION_KOREADEMPEOPLEREP",
        description: "Korea, Democratic Peoples Republic of"
    },
    {
        code: "OLI_NATION_KOREAREPUBLIC",
        description: "Korea, Republic of"
    },
    {
        code: "OLI_NATION_KOSOVO",
        description: "Kosovo"
    },
    {
        code: "OLI_NATION_KUWAIT",
        description: "Kuwait"
    },
    {
        code: "OLI_NATION_KYRGYZSTAN",
        description: "Kyrgyzstan"
    },
    {
        code: "OLI_NATION_LAOS",
        description: "Laos"
    },
    {
        code: "OLI_NATION_LATVIA",
        description: "Latvia"
    },
    {
        code: "OLI_NATION_LEBANON",
        description: "Lebanon"
    },
    {
        code: "OLI_NATION_LESOTHO",
        description: "Lesotho"
    },
    {
        code: "OLI_NATION_LIBERIA",
        description: "Liberia"
    },
    {
        code: "OLI_NATION_LIBYA",
        description: "Libya"
    },
    {
        code: "OLI_NATION_LIECHTENSTEIN",
        description: "Liechtenstein"
    },
    {
        code: "OLI_NATION_LITHUANIA",
        description: "Lithuania"
    },
    {
        code: "OLI_NATION_LUXEMBOURG",
        description: "Luxembourg"
    },
    {
        code: "OLI_NATION_MACAO",
        description: "Macao"
    },
    {
        code: "OLI_NATION_MACEDONIA",
        description: "Macedonia (FYROM)"
    },
    {
        code: "OLI_NATION_MADAGASCAR",
        description: "Madagascar"
    },
    {
        code: "OLI_NATION_MALAWI",
        description: "Malawi"
    },
    {
        code: "OLI_NATION_MALAYSIA",
        description: "Malaysia"
    },
    {
        code: "OLI_NATION_MALDIVES",
        description: "Maldives"
    },
    {
        code: "OLI_NATION_MALI",
        description: "Mali"
    },
    {
        code: "OLI_NATION_MALTA",
        description: "Malta"
    },
    {
        code: "OLI_NATION_MARSHALLISLANDS",
        description: "Marshall Islands"
    },
    {
        code: "OLI_NATION_MARTINIQUE",
        description: "Martinique"
    },
    {
        code: "OLI_NATION_MAURITANIA",
        description: "Mauritania"
    },
    {
        code: "OLI_NATION_MAURITIUS",
        description: "Mauritius"
    },
    {
        code: "OLI_NATION_MAYOTTEISLAND",
        description: "Mayotte"
    },
    {
        code: "OLI_NATION_MEXICO",
        description: "Mexico"
    },
    {
        code: "OLI_NATION_MICRONESIA",
        description: "Micronesia, Federated States of"
    },
    {
        code: "OLI_NATION_MOLDOVA",
        description: "Moldova"
    },
    {
        code: "OLI_NATION_MONACO",
        description: "Monaco"
    },
    {
        code: "OLI_NATION_MONGOLIA",
        description: "Mongolia"
    },
    {
        code: "OLI_NATION_MONTENEGRO",
        description: "Montenegro"
    },
    {
        code: "OLI_NATION_MONTSERRAT",
        description: "Montserrat"
    },
    {
        code: "OLI_NATION_MOROCCO",
        description: "Morocco"
    },
    {
        code: "OLI_NATION_MOZAMBIQUE",
        description: "Mozambique"
    },
    {
        code: "OLI_NATION_MYANMAR",
        description: "Myanmar"
    },
    {
        code: "OLI_NATION_NAMIBIA",
        description: "Namibia"
    },
    {
        code: "OLI_NATION_NAURU",
        description: "Nauru"
    },
    {
        code: "OLI_NATION_NEPAL",
        description: "Nepal"
    },
    {
        code: "OLI_NATION_NETHERLANDS",
        description: "Netherlands"
    },
    {
        code: "OLI_NATION_NETHERLANDSANTILLES",
        description: "Netherlands Antilles"
    },
    {
        code: "OLI_NATION_NEWCALEDONIA",
        description: "New Caledonia"
    },
    {
        code: "OLI_NATION_NEWZEALAND",
        description: "New Zealand"
    },
    {
        code: "OLI_NATION_NICARAGUA",
        description: "Nicaragua"
    },
    {
        code: "OLI_NATION_NIGER",
        description: "Niger"
    },
    {
        code: "OLI_NATION_NIGERIA",
        description: "Nigeria"
    },
    {
        code: "OLI_NATION_NIUE",
        description: "Niue"
    },
    {
        code: "OLI_NATION_NORFOLKISLAND",
        description: "Norfolk Island"
    },
    {
        code: "OLI_NATION_NORTHYEMEN",
        description: "North Yemen"
    },
    {
        code: "OLI_NATION_NORTHMARIANAISLANDS",
        description: "Northern Mariana Islands"
    },
    {
        code: "OLI_NATION_NORWAY",
        description: "Norway"
    },
    {
        code: "OLI_NATION_OMAN",
        description: "Oman"
    },
    {
        code: "OLI_NATION_PAKISTAN",
        description: "Pakistan"
    },
    {
        code: "OLI_NATION_PALAU",
        description: "Palau"
    },
    {
        code: "OLI_NATION_PALESTINE",
        description: "Palestinian Territory, Occupied"
    },
    {
        code: "OLI_NATION_PANAMA",
        description: "Panama"
    },
    {
        code: "OLI_NATION_PAPUANEWGUINEA",
        description: "Papua New Guinea"
    },
    {
        code: "OLI_NATION_PARAGUAY",
        description: "Paraguay"
    },
    {
        code: "OLI_NATION_PERU",
        description: "Peru"
    },
    {
        code: "OLI_NATION_PHILIPPINES",
        description: "Philippines"
    },
    {
        code: "OLI_NATION_PITCARINISLANDS",
        description: "Pitcairn"
    },
    {
        code: "OLI_NATION_POLAND",
        description: "Poland"
    },
    {
        code: "OLI_NATION_PORTUGAL",
        description: "Portugal"
    },
    {
        code: "OLI_NATION_PUERTORICO",
        description: "Puerto Rico"
    },
    {
        code: "OLI_NATION_QATAR",
        description: "Qatar"
    },
    {
        code: "OLI_NATION_REUNIONISLAND",
        description: "Reunion"
    },
    {
        code: "OLI_NATION_ROMANIA",
        description: "Romania"
    },
    {
        code: "OLI_NATION_RUSSIA",
        description: "Russia"
    },
    {
        code: "OLI_NATION_RWANDA",
        description: "Rwanda"
    },
    {
        code: "OLI_NATION_SAMOA",
        description: "Samoa"
    },
    {
        code: "OLI_NATION_SANMARINO",
        description: "San Marino"
    },
    {
        code: "OLI_NATION_SAOTOME",
        description: "Sao Tome And Principe"
    },
    {
        code: "OLI_NATION_SAUDIARABIA",
        description: "Saudi Arabia"
    },
    {
        code: "OLI_NATION_SENEGAL",
        description: "Senegal"
    },
    {
        code: "OLI_NATION_SERBIA",
        description: "Serbia"
    },
    {
        code: "OLI_NATION_SEYCHELLES",
        description: "Seychelles And Dependencies"
    },
    {
        code: "OLI_NATION_SIERRALEONE",
        description: "Sierra Leone"
    },
    {
        code: "OLI_NATION_SINGAPORE",
        description: "Singapore"
    },
    {
        code: "OLI_NATION_SLOVAKIA",
        description: "Slovakia"
    },
    {
        code: "OLI_NATION_SLOVENIA",
        description: "Slovenia"
    },
    {
        code: "OLI_NATION_SOLOMONISLANDS",
        description: "Solomon Islands"
    },
    {
        code: "OLI_NATION_SOMALIA",
        description: "Somalia"
    },
    {
        code: "OLI_NATION_SOUTHAFRICA",
        description: "South Africa"
    },
    {
        code: "OLI_NATION_SOUTHGEORGIASANDWICH",
        description: "South Georgia"
    },
    {
        code: "OLI_NATION_SOUTH_SUDAN",
        description: "South Sudan"
    },
    {
        code: "OLI_NATION_SOUTHYEMEN",
        description: "South Yemen"
    },
    {
        code: "OLI_NATION_SPAIN",
        description: "Spain"
    },
    {
        code: "OLI_NATION_SRILANKA",
        description: "Sri Lanka"
    },
    {
        code: "OLI_NATION_STPIERRE",
        description: "St Pierre And Miquelon"
    },
    {
        code: "OLI_NATION_STHELENA",
        description: "St. Helena And Dependencies"
    },
    {
        code: "OLI_NATION_STKITTSNEVIS",
        description: "St. Kitts And Nevis"
    },
    {
        code: "OLI_NATION_STLUCIA",
        description: "St. Lucia"
    },
    {
        code: "OLI_NATION_STVINCENT",
        description: "St. Vincent"
    },
    {
        code: "OLI_NATION_SUDAN",
        description: "Sudan"
    },
    {
        code: "OLI_NATION_SURINAME",
        description: "Suriname"
    },
    {
        code: "OLI_NATION_SVALBARDISLAND",
        description: "Svalbard and Jan Mayen"
    },
    {
        code: "OLI_NATION_SWAZILAND",
        description: "Swaziland"
    },
    {
        code: "OLI_NATION_SWEDEN",
        description: "Sweden"
    },
    {
        code: "OLI_NATION_SWITZERLAND",
        description: "Switzerland"
    },
    {
        code: "OLI_NATION_SYRIA",
        description: "Syria"
    },
    {
        code: "OLI_NATION_TAIWAN",
        description: "Taiwan"
    },
    {
        code: "OLI_NATION_TAJIKISTAN",
        description: "Tajikistan"
    },
    {
        code: "OLI_NATION_TANZANIA",
        description: "Tanzania"
    },
    {
        code: "OLI_NATION_THAILAND",
        description: "Thailand"
    },
    {
        code: "OLI_NATION_EASTTIMOR",
        description: "Timor-Leste"
    },
    {
        code: "OLI_NATION_TOGO",
        description: "Togo"
    },
    {
        code: "OLI_NATION_TOKELAU",
        description: "Tokelau"
    },
    {
        code: "OLI_NATION_TONGAISLANDS",
        description: "Tonga"
    },
    {
        code: "OLI_NATION_TRINIDADTOBAGO",
        description: "Trinidad And Tobago"
    },
    {
        code: "OLI_NATION_TUNISIA",
        description: "Tunisia"
    },
    {
        code: "OLI_NATION_TURKEY",
        description: "Turkey"
    },
    {
        code: "OLI_NATION_TURKMENISTAN",
        description: "Turkmenistan"
    },
    {
        code: "OLI_NATION_TURKSCAICOSIS",
        description: "Turks And Caicos Islands"
    },
    {
        code: "OLI_NATION_TUVALU",
        description: "Tuvalu"
    },
    {
        code: "OLI_NATION_UGANDA",
        description: "Uganda"
    },
    {
        code: "OLI_NATION_UKRAINE",
        description: "Ukraine"
    },
    {
        code: "OLI_NATION_USSR",
        description: "Union of Soviet Socialist Republics"
    },
    {
        code: "OLI_NATION_UNITEDARABEMIRATES",
        description: "United Arab Emirates"
    },
    {
        code: "OLI_NATION_UK",
        description: "United Kingdom"
    },
    {
        code: "OLI_NATION_USMINOROUTLYINGISLANDS",
        description: "United States Minor Outlying Islands"
    },
    {
        code: "OLI_NATION_URUGUAY",
        description: "Uruguay"
    },
    {
        code: "OLI_NATION_VIRGINISLANDSUS",
        description: "US Virgin Islands"
    },
    {
        code: "OLI_NATION_UZBEKISTAN",
        description: "Uzbekistan"
    },
    {
        code: "OLI_NATION_VANUATU",
        description: "Vanuatu"
    },
    {
        code: "OLI_NATION_VENEZUELA",
        description: "Venezuela"
    },
    {
        code: "OLI_NATION_VIETNAM",
        description: "Vietnam"
    },
    {
        code: "OLI_NATION_BRITISHVIRGINIS",
        description: "Virgin Islands, British"
    },
    {
        code: "OLI_NATION_WALLISISLANDS",
        description: "Wallis And Futuna Islands"
    },
    {
        code: "OLI_NATION_WESTERNSARAHA",
        description: "Western Sahara"
    },
    {
        code: "OLI_NATION_WESTERNSAMOA",
        description: "Western Samoa"
    },
    {
        code: "OLI_NATION_YEMEN",
        description: "Yemen"
    },
    {
        code: "OLI_NATION_YUGOSLAVIA",
        description: "Yugoslavia"
    },
    {
        code: "OLI_NATION_ZAMBIA",
        description: "Zambia"
    },
    {
        code: "OLI_NATION_ZIMBABWE",
        description: "Zimbabwe"
    },
    {
        code: "OLI_OTHER",
        description: "Other"
    }
];

export const strategies: object[] = [
    {
		id: 1,
		value: "kai_zen",
		name: "Kai-Zen",
	}, {
		id: 2,
		value: "tri_zen",
		name: "Tri-Zen",
	},
];

export const healthDropdown: object[] = [
    {
		id: 1,
		value: "Standard",
		name: "Average Health",
	}, {
		id: 2,
		value: "Preferred",
		name: "Excellent Health",
	},
];

export const clientTypes: object[] = [
    {
        key: 0,
        value: 0,
        name: "Individual"
    },
    {
		key: 1,
		value: 1,
		name: "Multi-Life",
	},
	// {
	// 	key: 2,
	// 	value: 2,
	// 	name: "Association",
	// },
	{
		key: 3,
		value: 3,
		name: "Group-Link",
	}
];

export const bugsCategoryDropDown: object[] = [
    {
		id: 1,
		value: 1,
		name: "Feedback",
	},
	{
		id: 2,
		value: 2,
		name: "Report a Bug",
	},
];

type Item = {
    id: number;
    name: string;
}



export const accountTypeDropDown: Item[] = [
	{
		id: 2,
		name: "Admin",
	},
    {
        id: 18,
        name: "Aggregator"
    },
    {
		id: 3,
		name: "IMOs",
	}, {
		id: 4,
		name: "Agents",
	},
	{
		id: 5,
		name: "Individuals",
	},
	{
		id: 8,
		name: "Groups",
	},
	{
		id: 7,
		name: "Carriers",
	},
	// {
	// 	id: 9,
	// 	name: "Association",
	// },
	{
		id: 10,
		name: "Group-Links",
	},
	{
		id: 12,
		name: "Trustees"
	},
	{
		id: 13,
		name: "Banks"
	},
	{
		id: 14,
		name: "Client-Lists"
	},
	{
		id: 16,
		name: "All Participants"
	}
];

export const imoTypeDropDown: object[] = [
	{
		id: 4,
		name: "Agents",
	},
	{
		id: 5,
		name: "Individuals",
	},
	{
		id: 8,
		name: "Groups",
	},
	{
		id: 14,
		name: "Client-Lists"
	},
	{
		id: 15,
		name: "Team Member Accounts",
	},
	{
		id: 16,
		name: "All Participants",
	}
];

export const AccountTypeByName: any = {
	master_admin :1,
	admin: 2,
	imo: 3,
	agent: 4,
	individual: 5,
	groups: 8,
	//'multi-life': 8,
	carrier: 7,
	'group-link': 10,
	trustee:12,
	bank:13,
	'client-list' : 14,
	'imo_member' : 15,
	'participants' : 16,
    'aggregator' : 18
};
export enum AccountType {
	Anonymous,
	MasterAdmin,
	Admin,
	IMO,
	Agent,
	Individual,
	Multilife,
	Carrier,
	Company,
	Association,
	GroupLink,
	GroupLinkParticipant,
    Trustee,
    Bank,
    ClientList,
    ImoMember,

    Aggregator = 18,
    AggregatorMember = 19,
}

export const ADMIN_TYPES = [
    {
        value: 'MasterAdmin',
        id: 1,
        name: 'Master admin'
    },
    {
        value: 'IliaAdmin',
        id: 2,
        name: 'ilia admin'
    },
    {
        value: 'Sales',
        id: 3,
        name: 'Sales'
    },
    {
        value: 'Processing',
        id: 4,
        name: 'Processing'
    }
]

export enum AgentType {
	Approved,
	Preferred,
	Ghost
}

export const AGENT_TYPES = [
    {
        value: 1,
        id: 1,
        name: 'Preferred'
    },
    {
        value: 2,
        id: 2,
        name: 'Ghost'
    }
]

export const getAgentTypeName = type => {
    const agentType = AGENT_TYPES.find(x => x.value === type);
    return (agentType && agentType.name) || "Approved";
}

export const getAdminTypeName = type => {
    const adminType = ADMIN_TYPES.find(x => x.value === type);
    return (adminType && adminType.name) || "N/A";
}

export enum PermissionTypes {
    ACCOUNT_MANAGEMENT = 1,
    NIW_ACTION_ITEMS = 2,
    CASE_DETAILS = 3,
    TRANCHE_MANAGEMENT = 4,
    ESTIMATOR_MANAGEMENT = 5,
    STRATEGY_MANAGEMENT = 6,
    ADMIN_ACTIVITY_LOG = 7,
    LEAD_MANAGEMENT = 8,
    LINK_REPORTING = 9,
    CARRIER_REQUESTS = 10,
    TICKETS = 11,
    POWER_BI_REPORTS = 12,
    CMS_EDIT_ABILITY_NIW_CORP = 13,
    ILIA_TRAINING_CMS = 14,
    DELETE_USER
}

export const ROLES:any = {
	1: "master_admin",
	2: "admin",
	3: "imo",
	4: "agent",
	5: "individual",
	6: "multilife",
	7: "carrier",
	8: "group",
	9: "association",
	10: "grouplink",
	11: "grouplink-user",
	12: "trustee",
	13: "bank",
	14: "client-list",
	15: "imo_member",
    16: "group_signer",
    18: "aggregator",
    19: "aggregator_member"
};

export const docusignRoles:any = {
    1: "NIW Admin",
    2: "Admin",
    12: "Trustee"
};

export const AccountTypeByFilter: any = {
	2: 'admin',
	3: 'imo',
	4: 'agent',
	5: 'individual',
	8: 'groups',
	7: 'carrier',
	10: 'group-link',
	12: 'trustee',
	13: 'bank',
	14: 'client-list',
	15: 'imo_member',
	16: 'participants',
    18: 'aggregator'
};

export const NIWCorpSource = "niwcorp.com";

export const leadSourcesDropDown: object[] = [
	{
		key: 1,
		value: 1,
		name: 'Existing Client'
	},
	{
		key: 2,
		value: 2,
		name: 'Client referral'
	},
	{
		key: 3,
		value: 3,
		name: 'Bought list'
	},
	{
		key: 4,
		value: 4,
		name: 'Seminar event',
	},
	{
		key: 5,
		value: 5,
		name: 'Webinar event'
	},
	{
		key: 6,
		value: 6,
		name: 'IMO referral'
	},
	{
		key: 7,
		value: 7,
		name: 'NIW training'
	},
	{
		key: 8,
		value: 8,
		name: 'NIW webinar'
	},
    {
        key: 17,
        value: 17,
        name: NIWCorpSource
    },
	{
		key: 9,
		value: 9,
		name: 'Personal cold call'
	},
	{
		key: 10,
		value: 10,
		name: 'Conference'
	},
	{
		key: 11,
		value: 11,
		name: 'Social Media'
	},
	{
		key: 12,
		value: 12,
		name: 'Article/ white paper'
	},
	{
		key: 13,
		value: 13,
		name: 'Agent (CPA etc) referral'
	},
	{
		key: 14,
		value: 14,
		name: 'Advert'
	},
	{
		key: 15,
		value: 15,
		name: 'OTHER'
	}
];

export const defaultNIWCorpSourceId = () => {
    const leadSourceNIWCorp: any = leadSourcesDropDown.find((a: any) => a.name === NIWCorpSource);
    return leadSourceNIWCorp.key || leadSourceNIWCorp.value;
}

export const groupType: object[] = [
    {
        id: 1,
        value: "Association",
        key: "Association",
        name: "Association",
    }, {
        id: 2,
        value: "Corporation",
        key: "Corporation",
        name: "Corporation",
    }]
interface clientFolderOption {
	[key: string]: string
}

const additionalFieldOptionsList = {
    development: {
        13: [{value: "4ea64538-1e0f-4fa8-8fc0-cd103f73febd", text: "Stroke or transient ischemic attack"}, {value: "e5973d9e-1704-43ee-9000-c73d4569620f", text: "Cardiac issue"}],
        14: [{value: "f5b89e61-1505-44e5-9001-1b016a9aabe1", text: "Pain"}, {value: "f48da4d8-ca58-4f86-aa85-4e51f93d9bbe", text: "Arthritis"}],
        18: [
            {value: "bd0e5303-d61a-41b0-9e94-5e7ec4139826", text: "Gastro intestinal"},
            // {value: "be62a200-38d1-48f3-b6b1-c28c2fb43713", text: "Genitourinary (bladder & kidney)"},
            {value: "0035990c-bed3-46d4-b5de-64b5af0271ae", text: "Hepatitis & liver disorder"}
            ],
        30: [{value: "7ac040d6-e791-4a15-9c07-3a96ea05c8c8", text: "If felon or misdemeanor convictions"}, {value: "3647ce6f-56d0-4855-b4da-b527cfd0326e", text: "If DUI or suspended license"}]
    },
    stage: {
        13: [{value: "4ea64538-1e0f-4fa8-8fc0-cd103f73febd", text: "Stroke or transient ischemic attack"}, {value: "e5973d9e-1704-43ee-9000-c73d4569620f", text: "Cardiac issue"}],
        14: [{value: "f5b89e61-1505-44e5-9001-1b016a9aabe1", text: "Pain"}, {value: "f48da4d8-ca58-4f86-aa85-4e51f93d9bbe", text: "Arthritis"}],
        18: [
            {value: "bd0e5303-d61a-41b0-9e94-5e7ec4139826", text: "Gastro intestinal"},
            // {value: "be62a200-38d1-48f3-b6b1-c28c2fb43713", text: "Genitourinary (bladder & kidney)"},
            {value: "0035990c-bed3-46d4-b5de-64b5af0271ae", text: "Hepatitis & liver disorder"}
        ],
        30: [{value: "7ac040d6-e791-4a15-9c07-3a96ea05c8c8", text: "If felon or misdemeanor convictions"}, {value: "3647ce6f-56d0-4855-b4da-b527cfd0326e", text: "If DUI or suspended license"}]
    },
    staging: {
        13: [{value: "4ea64538-1e0f-4fa8-8fc0-cd103f73febd", text: "Stroke or transient ischemic attack"}, {value: "e5973d9e-1704-43ee-9000-c73d4569620f", text: "Cardiac issue"}],
        14: [{value: "f5b89e61-1505-44e5-9001-1b016a9aabe1", text: "Pain"}, {value: "f48da4d8-ca58-4f86-aa85-4e51f93d9bbe", text: "Arthritis"}],
        18: [
            {value: "bd0e5303-d61a-41b0-9e94-5e7ec4139826", text: "Gastro intestinal"},
            // {value: "be62a200-38d1-48f3-b6b1-c28c2fb43713", text: "Genitourinary (bladder & kidney)"},
            {value: "0035990c-bed3-46d4-b5de-64b5af0271ae", text: "Hepatitis & liver disorder"}
            ],
        30: [{value: "7ac040d6-e791-4a15-9c07-3a96ea05c8c8", text: "If felon or misdemeanor convictions"}, {value: "3647ce6f-56d0-4855-b4da-b527cfd0326e", text: "If DUI or suspended license"}]
    },
    production: {
        13: [{value: "4b01539a-d8b1-4f81-9180-9744c1ad853f", text: "Stroke or transient ischemic attack"}, {value: "94178841-62ce-403f-956b-2df46674f06c", text: "Cardiac issue"}],
        14: [{value: "81adf254-95bc-4d39-920f-34e54fba04f4", text: "Pain"}, {value: "71af074c-5cd9-4a28-9ea5-b42195fb2070", text: "Arthritis"}],
        18: [
            {value: "be9432fa-b0de-46dd-84fa-51c337c1a202", text: "Gastro intestinal"},
            // {value: "c722ea0b-b8a6-44ea-b73b-459abff57cf0", text: "Genitourinary (bladder & kidney)"},
            {value: "993a1fc9-1101-4ae8-8acc-7294553ada2e", text: "Hepatitis & liver disorder"}
            ],
        30: [{value: "81b334ac-043f-4f98-a54b-6ade2f571b27", text: "If felon or misdemeanor convictions"}, {value: "433353ae-35d7-4d80-bff6-f775b8ceb1bc", text: "If DUI or suspended license"}]
    }
};

export const additionalFieldOptions = additionalFieldOptionsList[process.env.NODE_ENV];


export const clientFolder: clientFolderOption = { "Kai-Zen": "kaizen", "Tri-Zen": 'trizen' }

export const API_URL: string = process.env.REACT_APP_API_HOST || "";
export const ESTIMATION_WEB_HOOK_URL: string = process.env.REACT_APP_WEB_HOOK_URL || "";

export const WEBSOCKET_URL: string = process.env.REACT_APP_WEBSOCKET_URL || "";
export const ASSETS_URL: string = "https://assets.myilia.com";
export const GroupLinkEnableUserId: string = process.env.REACT_APP_GROUP_LINK_USER_ID || "";
export const EMAIL_VALIDATE_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;
export const PASSWORD_VALIDATE_REGEX = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\]\[\)\(]).{8,99}$/);
export const LOGO_BASE_URL = process.env.REACT_APP_LOGO_URL || "";
export const PWD_ONE_LETTER_REGEX = /^(?=.*[a-z])/;
export const PWD_ONE_CAPITAL_LETTER_REGEX = /^(?=.*[A-Z])/;
export const PWD_ONE_NUMBER_REGEX = /^(?=.*[0-9])/i;
export const PWD_MIN_EIGHT_CHAR_REGEX = /^(?=.{8,99})/i;
export const PWD_ONE_SPECIAL_CHAR = /^(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\]\[\)\(])/;
export const ZIP_CODE_ALPHA_NUMERIC_CHAR = /^\d{5}$/;
export const ZIP_CODE_CHAR = /^\d{5}$/;
export const ALPHABET_ONLY_REGEX = /^[A-Za-z\s]*$/;
export const ALPHABET_WITH_REQUIRED_SYMBOLS_REGEX = /^[A-Za-z0-9\s\-.,/()'`& _+=;]*$/;
export const ALPHABET_WITH_HYPHEN_DOT_REGEX = /^[A-Za-z\s-.]*$/;
export const ALPHABET_WITH_NUMBER_HYPHEN_DOT_REGEX = /^[a-zA-Z0-9\s.-]*$/;
export const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const underWritingOfferType = [];
export const SITE_URL = process.env.REACT_APP_URL;
export const NIW_CORP_URL = process.env.REACT_APP_MARKETING_HOST || "";
export const MARKETING_SITE_URL = process.env.REACT_APP_AGENTLINK || "";

export const NY_AGENT = process.env.REACT_APP_AGENT_FOR_NY || "";

//Allianz Offer Types

export const defaultOfferTypes = [];

export const defaultStrategies = [];
export const defaultCarrier = [];
export const AccountActivities: any = {
    "HARD_DELETED_CLIENT": 'HARD_DELETED_CLIENT',
    "BLOCKED_USERS": 'BLOCKED_USERS',
    "UN_BLOCKED_USERS": 'UN_BLOCKED_USERS',
    "RESET_ENROLLMENT": 'RESET_ENROLLMENT',
    "RESET_PROPOSAL": 'RESET_PROPOSAL',
    "HARD_DELETED_AGENT": 'HARD_DELETED_AGENT',
    "HARD_DELETED_ADMIN": 'HARD_DELETED_ADMIN',
    "HARD_DELETED_IMO": 'HARD_DELETED_IMO',
    "HARD_DELETED_ESTIMATOR": 'HARD_DELETED_ESTIMATOR',
    "LEAD_REJECT" : "LEAD_REJECT",
    "HARD_DELETED_GROUP": 'HARD_DELETED_GROUP',
    "GROUP_PARTICIPANT_DISABLED": 'GROUP_PARTICIPANT_DISABLED',
    "GROUP_PARTICIPANT_ACTIVATED": 'GROUP_PARTICIPANT_ACTIVATED'
};

export const adminActivityTypeDropDown = [
    { id: 1, name: "Hard Deleted Client", value: AccountActivities.HARD_DELETED_CLIENT},
    {id: 2, name:"Blocked Users", value : AccountActivities.BLOCKED_USERS},
    {id: 3, name:"Un-Blocked Users", value :  AccountActivities.UN_BLOCKED_USERS},
    {id: 4, name:"Reset-Enrollment Users", value :  AccountActivities.RESET_ENROLLMENT},
    {id: 5, name:"Hard Deleted Agent", value :  AccountActivities.HARD_DELETED_AGENT},
    {id: 6, name:"Hard Deleted IMO", value :  AccountActivities.HARD_DELETED_IMO},
    {id: 7, name:"Hard Deleted Estimator", value :  AccountActivities.HARD_DELETED_ESTIMATOR},
    {id: 8, name:"Lead Rejected", value :  AccountActivities.LEAD_REJECT},
    {id: 9, name:"Hard Delete Group", value :  AccountActivities.HARD_DELETED_GROUP},
    {id: 10, name:"Group Participant Disabled", value :  AccountActivities.GROUP_PARTICIPANT_DISABLED},
    {id: 11, name:"Group Participant Activated", value :  AccountActivities.GROUP_PARTICIPANT_ACTIVATED},
    {id: 12, name:"Hard Deleted Admin", value :  AccountActivities.HARD_DELETED_ADMIN},
    {id: 13, name:"Reset-Proposal Users", value :  AccountActivities.RESET_PROPOSAL},
];

export const minContributionAmount = 20000;
export const selfAnnualIncomeMinValue = 150000
export const trustFee = 1350;
export const trustFeeText = (client: any) => {
    if (client && client.combinedClientId && client.isCombineTrust) {
        return "$675";
    }
    return "$1,350";
};
export const fiveYearTrustFee = 1350 * 5;
export const annualIncomeAmount = 100000;
export const annualIncomeAmountAllianzLife = 150000;
export const agentDemoContributionAmount = 30350;
export const defaultHealthType = "Standard";
export const demoModeEmployerContribution = 20000;
export const defaultRowsPerPage = 25;

export const ClientDocumentType: any = {
    "ADMIN_PROPOSAL": 'ADMIN_PROPOSAL',
    "MORE_DOCUMENTS": 'MORE_DOCUMENTS',
    "ADDITIONAL_DOCUMENTS": 'ADDITIONAL_DOCUMENTS',
    "IDENTITY_DOCUMENT": 'IDENTITY_DOCUMENT',
    "TAX_DOCUMENT": 'TAX_DOCUMENT',
    "UTILITY_BILL": 'UTILITY_BILL',
    "RESIDENCE_DOCUMENT": 'RESIDENCE_DOCUMENT',
    "COVER_LETTER" : 'COVER_LETTER',
    "CARRIER_ILLUSTRATION_INPUTS": 'CARRIER_ILLUSTRATION_INPUTS',
    "TAX_DOCUMENT_INCOME_EARNER": "TAX_DOCUMENT_INCOME_EARNER",
    "TAX_DOCUMENT_SPOUSE": "TAX_DOCUMENT_SPOUSE",
    "TAX_DOCUMENT_WEALTH_TRANSFER": "TAX_DOCUMENT_WEALTH_TRANSFER",
    "GWT_COVER_LETTER": "GWT_COVER_LETTER",
    "GWT_DISCLOSURE_LETTER": "GWT_DISCLOSURE_LETTER",
    "GWT_DRIVERS_LICENSE": "GWT_DRIVERS_LICENSE",
    "GWT_DRIVERS_LICENSE_BACK": "GWT_DRIVERS_LICENSE_BACK",
    "GWT_FINANCIAL_STATEMENT": "GWT_FINANCIAL_STATEMENT",
    "GWT_TAX_DOCUMENT_YEAR_ONE": "GWT_TAX_DOCUMENT_YEAR_ONE",
    "GWT_TAX_DOCUMENT_YEAR_TWO": "GWT_TAX_DOCUMENT_YEAR_TWO"
};


export const ActionItems  = {
    "SIGN_AGENT" : {
        actionType: 'SIGN_AGENT',
        actionRequired: 'Sign Agent Report',
        actionButton: 'View and Sign'
    },
    "VIEW_APPROVE" : {
        actionType: 'VIEW_APPROVE',
        actionRequired: 'Approve Proposal',
        actionButton: 'View and Approve'
    },

    "UPLOAD_PROPOSAL" : {
        actionType: 'UPLOAD_PROPOSAL',
        actionRequired: 'Send Proposal to Agent',
        actionRequired2: 'Change requested to proposal',
        actionButton: 'Upload & Send Proposal'
    },
    "APPROVE_DOCUSIGN" : {
        actionType: 'APPROVE_DOCUSIGN',
        actionRequired: 'Approve Client Application / Agent Report',
        actionButton: 'Approve Documents'
    },

    "MAKE_PAYMENT_RECEIVED" : {
        actionType: 'MAKE_PAYMENT_RECEIVED',
        actionRequired: 'Mark Payment Received',
        actionButton: 'Mark Payment Received'
    },
    "MARK_PAYMENT_PROCESSED" : {
        actionType: 'MARK_PAYMENT_PROCESSED',
        actionRequired: 'Mark Payment Processed',
        actionButton: 'Mark Payment Processed'
    },
    "UPLOAD_TRUSTEE_FINAL_DOCS" : {
        actionType: 'UPLOAD_TRUSTEE_FINAL_DOCS',
        actionRequired: 'Send Trustee Signature Docs',
        actionButton: 'Upload Trustee Final Docs'
    },
    "SIGN_TRUSTEE_DOCUMENTS" : {
        actionType: 'SIGN_TRUSTEE_DOCUMENTS',
        actionRequired: 'Sign Trustee Documents',
        actionButton: 'View and Sign'
    },
    "SIGN_TRUSTEE_ILLUSTRATION" : {
        actionType: 'SIGN_TRUSTEE_ILLUSTRATION',
        actionRequired: 'Sign Trustee Illustration',
        actionButton: 'View and Sign'
    },
    "DOWNLOAD_TRUSTEE_DOCUMENTS" : {
        actionType: 'DOWNLOAD_TRUSTEE_DOCUMENTS',
        actionRequired: 'Download Trustee Documents',
        actionButton: 'Download Final Docs'
    },
    "MARK_DOCUMENTS_APPROVED": {
        actionType: 'MARK_DOCUMENTS_APPROVED',
        actionRequired: "Mark Documents Approved",
        actionButton: "Mark Documents Approved",
        text: "Documents Rejected By Admin"
    },
    "CLEAR_ACTION_ITEM": {
        actionType: 'CLEAR_ACTION_ITEM',
        actionRequired: "Clear Action Item",
        actionButton: "Clear Action Item",
        text: "All documents have been approved."
    },
    "COVER_LETTER_REQUIRED": {
        actionType: 'COVER_LETTER_REQUIRED',
        actionRequired: "Case Requires Cover Letter",
        actionButton: "Upload Cover Letter"
    },
    "AGENT_SELLER_AGREEMENT": {
        actionType: 'AGENT_SELLER_AGREEMENT',
        actionRequired: "Approved Seller Agreement",
        actionButton: "Approved Seller Agreement"
    },
    "TAX_DOCUMENT_INCOME_EARNER": {
        actionType: 'TAX_DOCUMENT_INCOME_EARNER',
        actionRequired: "Tax Document Income Earner",
        actionButton: "Upload Tax Document Income Earner"
    },
    "TAX_DOCUMENT_SPOUSE": {
        actionType: 'TAX_DOCUMENT_SPOUSE',
        actionRequired: "Tax Document Spouse",
        actionButton: "Upload Tax document for spouse"
    },
    "TAX_DOCUMENT_WEALTH_TRANSFER": {
        actionType: 'TAX_DOCUMENT_WEALTH_TRANSFER',
        actionRequired: "Tax Document Wealth Transfer",
        actionButton: "Upload Tax Document Wealth Transfer"
    },
    "GREAT_WEALTH_TRANSFER": {
        actionType: 'GREAT_WEALTH_TRANSFER',
        actionRequired: "Great Wealth Transfer",
        actionButton: "Go to Case Details"
    },
}


export const EnrollmentSubject: object[] = [
    { id:0 , value: "Trust Document Request" },
    { id:1 , value: "Proposal Request" },
    { id:2 , value: "Agent Report / Application Request" },
    { id:3 , value: "Enrollment Declined" },
    { id:4 , value: "New Enrollment Application Request" },
    { id:5 , value: "NIW Message to client" }
];

export const EnrollmentNotesVisibility = [
    {id: 1, name: "NIW", value: "NIW"},
    {id: 2, name: "Agent", value : "AGENT"},
    {id: 3, name: "IMO", value : "IMO"},
    {id: 4, name: "Trustee", value : "TRUSTEE"}
];
export const leadStats = [
    {
        type: "TotalLeads",
        title: "Total Leads",
        countClass: "btnGrey",
    },
    {
        type: "LeadsInvited",
        title: "Leads Invited",
        countClass: "btnGreen",
    },
    {
        type: "LeadsRejected",
        title: "Leads Rejected",
        countClass: "btnRed",
    },
    {
        type: "AccountsCreated",
        title: "Accounts Created",
        countClass: "btnGrey",
    },
    {
        type: "LeadsClosed",
        title: "Leads Closed",
        countClass: "btnGreen",
    },
    {
        type: "ClosingRatio",
        title: "Closing Ratio",
        countClass: "btnGreen",
    }
]

export const DocumentsHaveType = [
    ["Drivers License", "Passport"], //RequestedDoc.IDENTITY_DOCUMENT index
    ["W2’s", "Tax Returns", "1099’s", "TAX DOCUMENT YEAR 1", "TAX DOCUMENT YEAR 2"], //RequestedDoc.TAX_DOCUMENT index
    ["Utility Bill"], //RequestedDoc.UTILITY_BILL
    ["Visa", "Green Card", "Permanent Residency Card"], // RequestedDoc.RESIDENCE_DOCUMENT
    ["Other"] //RequestedDoc.MORE_DOCUMENTS
]

export const RequestedDoc = [ // RequestedDoc have mapping with DocumentsHaveType
    "IDENTITY_DOCUMENT",
    "TAX_DOCUMENT",
    "UTILITY_BILL",
    "RESIDENCE_DOCUMENT",
    "MORE_DOCUMENTS"
]

export enum PolicyStatusEnum {
    Deceased = 'DECEASED',
    InForce = 'IN-FORCE',
    InForceOverride = 'IN-FORCE-OVERRIDE',
    InForceOverrideRevert = 'IN-FORCE-OVERRIDE-REVERT',
    Surrendered = 'SURRENDERED',
}

export const policyStatus: object[] = [
    {
        id: PolicyStatusEnum.InForce,
        value: PolicyStatusEnum.InForce,
        name: 'Policy Issued'
    }, {
        id: PolicyStatusEnum.Surrendered,
        value: PolicyStatusEnum.Surrendered,
        name: 'Surrendered'
    }, {
        id: PolicyStatusEnum.Deceased,
        value: PolicyStatusEnum.Deceased,
        name: 'Deceased'
    }, {
        id: PolicyStatusEnum.InForceOverride,
        value: PolicyStatusEnum.InForceOverride,
        name: 'Policy Issued (Override)'
    }
];
/* revertPolicyStatus option is just to revert the Policy Issued (Override) process */
export const revertPolicyStatus: object[] = [
    {
        id: PolicyStatusEnum.InForceOverrideRevert,
        value: PolicyStatusEnum.InForceOverrideRevert,
        name: 'Revert Policy Issued (Override)'
    }
]

export const paymentMethods: object[] = [
    {
        id: 'personal_check',
        value: 'personal_check',
        name: 'Personal Check'
    }, {
        id: 'wire_transfer',
        value: 'wire_transfer',
        name: 'Wire Transfer'
    },{
        id: 'refund',
        value: 'refund',
        name: 'Refund'
    }

];

export const leadTimeSlotsDropDown = [
    {
        id: 1,
        value: '9AM - 11AM',
        name: '9AM - 11AM'
    },{
        id: 2,
        value: '10AM - 12PM',
        name: '10AM - 12PM'
    },{
        id: 3,
        value: '11AM - 1PM',
        name: '11AM - 1PM'
    },{
        id: 4,
        value: '12PM - 2PM',
        name: '12PM - 2PM'
    },
    {
        id: 5,
        value: '1PM - 3PM',
        name: '1PM - 3PM'
    },
    {
        id: 6,
        value: '2PM - 4PM',
        name: '2PM - 4PM'
    },
    {
        id: 7,
        value: '3PM - 5PM',
        name: '3PM - 5PM'
    },
    {
        id: 8,
        value: '4PM - 6PM',
        name: '4PM - 6PM'
    },
];
export const livingBenefitsAllianz: object[] = [
    {
        id: 1,
        key: 'CHRONIC-ILLNESS',
        value: 'CHRONIC-ILLNESS',
        name: 'Chronic Illness'
    }, {
        id: 2,
        key: 'TERMINAL-ILLNESS',
        value: 'TERMINAL-ILLNESS',
        name: 'Terminal Illness'
    }
];

export const livingBenefitsNationalLife: object[] = [
    {
        id: 1,
        key: "CHRONIC-ILLNESS",
        value: 'CHRONIC-ILLNESS',
        name: 'Chronic Illness'
    },{
        id: 2,
        key: 'CRITICAL-ILLNESS',
        value: 'CRITICAL-ILLNESS',
        name: 'Critical Illness'
    }, {
        id: 3,
        key: 'CRITICAL-INJURY',
        value: 'CRITICAL-INJURY',
        name: 'Critical Injury'
    }, {
        id: 4,
        key: 'TERMINAL-ILLNESS',
        value: 'TERMINAL-ILLNESS',
        name: 'Terminal Illness'
    },
];

export const leadGenerationDropDown: object[] = [
    {
        id: 1,
        value: '1',
        name: 'Lead Gen Landing Page'
    }
];

export const DocApprovalStatus: {[key: string]: {approvalStatus: string, operationPerformed: string}} = {
    "APPROVED": {
        approvalStatus: "APPROVED",
        operationPerformed: "APPROVED"
    },
    "REJECTED": {
        approvalStatus: "REJECTED",
        operationPerformed: "REJECTED"
    },
    "DOCUMENT_REQUESTED": {
        approvalStatus: "DOCUMENT-REQUESTED",
        operationPerformed: "DOCUMENT-REQUESTED"
    },
    "UPLOADED": { // this status will performed by client on document upload rest(including UPLOADED) will performed by Admin
        approvalStatus: "UPLOADED",
        operationPerformed: "UPLOADED"
    },
    "MARK_RESOLVED": {
        approvalStatus: "APPROVED",
        operationPerformed: "MARK-RESOLVED"
    },
};

export enum DocRequestedFromTab {
    ENROLLMENT_TAB,
    DOCUMENTS_TAB
}

export const DocumentFullName: { [key: string]: { name: string } } = {
    "RESIDENCE_DOCUMENT_BACK": {
        name: "RESIDENCE DOCUMENT BACK"
    },
    "RESIDENCE_DOCUMENT_FRONT": {
        name: "RESIDENCE DOCUMENT FRONT"
    },
    "PASSPORT_FRONT": {
        name: "PASSPORT FRONT"
    },
    "PASSPORT_BACK": {
        name: "PASSPORT BACK"
    },
    "DRIVER_LICENSE_FRONT": {
        name: "DRIVERS LICENSE FRONT"
    },
    "DRIVER_LICENSE_BACK": {
        name: "DRIVERS LICENSE BACK"
    },
    "TAX_DOCUMENT_YEAR_1": {
        name: "TAX DOCUMENT YEAR 1"
    },
    "TAX_DOCUMENT_YEAR_2": {
        name: "TAX DOCUMENT YEAR 2"
    }
}
export const marketingMaterialsClientTestimonialsVideos: carouselVideo[] = [
    {
        title: 'Carey Finn Kai-Zen Testimonial - ILIA',
        videoUrl: "https://player.vimeo.com/video/360842534"
    },
    {
        title: 'Pat Kunz - Kai-Zen Testimonial',
        videoUrl: "https://player.vimeo.com/video/360886953"
    },
    {
        title: 'David Bistritz Kai-Zen Testimonial - ILIA',
        videoUrl: "https://player.vimeo.com/video/362156467"
    },
    {
        title: 'Saima Iqbal & Sal Mallick Kai-Zen Testimonial - ILIA',
        videoUrl: "https://player.vimeo.com/video/364177068"
    },
    {
        title: 'Aaron Zimmer Kai-Zen Testimonial - ILIA',
        videoUrl: "https://player.vimeo.com/video/380362579"
    },
    {
        title: 'Xuhong Hao Kai-Zen Testimonial - ILIA',
        videoUrl: "https://player.vimeo.com/video/380363031"
    },
    {
        title: 'Jay Jensen Kai-Zen Testimonial',
        videoUrl: "https://player.vimeo.com/video/389348882"
    },
    {
        title: 'Barry Goldstein - CPA',
        videoUrl: "https://player.vimeo.com/video/460734246"
    },
    {
        title: 'Angela Ashley - Catching up in 2020',
        videoUrl: "https://player.vimeo.com/video/466251602"
    },
    {
        title: 'Dave Greene Kai-Zen Testimonial',
        videoUrl: "https://player.vimeo.com/video/473082201"
    },
    {
        title: 'Travis Fratini - Don\'t Wait',
        videoUrl: "https://player.vimeo.com/video/473082497"
    },
    {
        title: 'Angela Ashley Enhanced Split Dollar',
        videoUrl: "https://player.vimeo.com/video/473084368"
    },
    {
        title: 'Angela Ashley Tri-zen Testimonial 2017',
        videoUrl: "https://player.vimeo.com/video/475672320"
    },

    {
        title: 'Xiaobing Wang, Ph.D - Kai-Zen Client Testimonial',
        videoUrl: "https://player.vimeo.com/video/481446542"
    },
    {
        title: 'Carey Finn Kai-Zen Testimonial - Agent Locator on Vimeo - Agent Locator on Vimeo',
        videoUrl: "https://player.vimeo.com/video/488704454"
    },
    {
        title: 'Iqbal & Mallick Kai-Zen Testimonial - Agent Locator',
        videoUrl: "https://player.vimeo.com/video/488722665"
    },
    {
        title: 'Aaron Zimmer Kai-Zen Testimonial - Agent Locator',
        videoUrl: "https://player.vimeo.com/video/488722758"
    },
    {
        title: 'Xuhong Hao Kai-Zen Testimonial - Agent Locator',
        videoUrl: "https://player.vimeo.com/video/488722857"
    },
    {
        title: 'David Bistritz Kai-Zen Testimonial - Agent Locator',
        videoUrl: "https://player.vimeo.com/video/488722629",
    },
];

export const marketingMaterialsFiresideVideos: carouselVideo[] = [
    {
        title: 'Why John Lane Loves Kai-Zen - FireSide Chat #002',
        videoUrl: "https://player.vimeo.com/video/525703540"
    },
    {
        title: 'Discussing the need for Kai-Zen with Igor Zey - FireSide Chat #001',
        videoUrl: "https://player.vimeo.com/video/522944903"
    },
    {
        title: 'Summarizing the difference between an index fund and an index universal life With Todd Petit - FireSide Chat #004',
        videoUrl: "https://player.vimeo.com/video/539201782"
    },
    {
        title: 'Understanding the basics of Index Universal Life Insurance with Todd Petit - FireSide Chat #003',
        videoUrl: "https://player.vimeo.com/video/534927991"
    },
];

export const marketingMaterialsWebinarsVideos: carouselVideo[] = [
    {
        title: '01-17-20-The Most Exciting Strategy In Key Employee Benefits - Grace Barnard',
        videoUrl: "https://player.vimeo.com/video/386254421"
    },
    {
        title: '10-09-20-What You Need To Know About Kai-Zen & Tools To Sell -Jeremy Bickler',
        videoUrl: "https://player.vimeo.com/video/467869236"
    },
    {
        title: 'Kai-Zen Training - Webinar_ ‘Why is Kai-Zen Different than Traditional Premium Finance_’ Host_ Jeremy Bickler',
        videoUrl: "https://player.vimeo.com/video/538697973"
    },
    {
        title: 'Kai-Zen Webinar_ ‘The MEC Rules Just Changed and They Will Have a Massive Impact on IUL Performance’ Host_ Daen Wombwell',
        videoUrl: "https://player.vimeo.com/video/535903890"
    },
    {
        title: 'Kai-Zen Webinar_ ‘Everyone Wants To Invest Like The Wealthy & You Can!” Host_ Grace Barnard.mp4',
        videoUrl: "https://player.vimeo.com/video/529513456"
    },
    {
        title: 'Kai-Zen Webinar_ ‘Kai-Zen - A Multi-Trillion dollar opportunity with a fixed income alternative” Host_ Travis Fratini',
        videoUrl: "https://player.vimeo.com/video/527340490"
    },
    {
        title: 'Kai-Zen Training - Webinar “Why AG49A should stop you from Selling Illustrations!” Host_ David Greene.mp4',
        videoUrl: "https://player.vimeo.com/video/523841378"
    },
    {
        title: 'Kai-Zen Training - Webinar ‘Understanding Why Kai-Zen is Not Too Good to be True’ Host_ Jeremy Bickler.mp4',
        videoUrl: "https://player.vimeo.com/video/521503090"
    },
    {
        title: 'Kai-Zen Training - Webinar_ “Kai-Zen_ Key Phases & Back to Basics” Host_ David Greene',
        videoUrl: "https://player.vimeo.com/video/541824153"
    },
    {
        title: 'Kai-Zen Training - Webinar_ “Kai-Zen-ILIA Enrollment 2.0 Where we are today and where we are going…’ Host_ Travis Fratini .mp4',
        videoUrl: "https://player.vimeo.com/video/547971821"
    },
    {
        title: 'Kai-Zen Training - Webinar_ “The Mentality Behind the Buying Decision of your Clients’ Host_ Grace Barnard.mp4',
        videoUrl: "https://player.vimeo.com/video/547971890"
    },
    {
        title: 'Kai-Zen Training - Webinar_ “Kai-Zen as an Alternative Asset Class’ Host_ Daen Wombwell',
        videoUrl: "https://player.vimeo.com/video/551515301"
    }
];

export const marketingMaterialsClientsVideos: carouselVideo[] = [
    {
        title: 'Kai-Zen - Mortgage Analogy',
        videoUrl: "https://player.vimeo.com/video/510869062"
    }];

export const clientSource = {
    "INDIVIDUAL_INVITE": "Individual Invite",
    "CLIENT_LIST": "Client List",
    "GROUP_PARTICIPANT_ASSOCIATION": "Group Participant (Association)",
    "GROUP_PARTICIPANT_CORPORATION": "Group Participant (Corporation)",
    "AGENT_LINK": "Agent Link",
    "ASSIGNED_LEAD": "Assigned lead",
    "niwcorp.com": "niwcorp.com"
}
export const cashStreamSlug: string = "cashstream";

export const contributionType: object[] = [
    {
        id: 1,
        value: "EMPLOYER_ONLY",
        key: "EMPLOYER_ONLY",
        name: "Employer Only"
    }, {
        id: 2,
        value: "PARTICIPANT_ONLY",
        key: "PARTICIPANT_ONLY",
        name: "Participant Only"
    }, {
        id: 3,
        value: "COMBINE_CONTRIBUTION",
        key: "COMBINE_CONTRIBUTION",
        name: "Combine Contribution"
    }];

export const ActionItemsFilter: object[]  = [
    {
        id: 'ALL',
        name: 'All'
    },
    {
        id: 'SIGN_AGENT',
        name: 'View and Sign'
    },
    {
        id: 'VIEW_APPROVE',
        name: 'View and Approve'
    },
    {
        id: 'UPLOAD_PROPOSAL',
        name: 'Upload & Send Proposal'
    },
    {
        id: 'APPROVE_DOCUSIGN',
        name: 'Approve Documents'
    },
    {
        id: 'MAKE_PAYMENT_RECEIVED',
        name: 'Mark Payment Received'
    },
    {
        id: 'MARK_PAYMENT_PROCESSED',
        name: 'Mark Payment Processed'
    },
    {
        id: 'UPLOAD_TRUSTEE_FINAL_DOCS',
        name: 'Upload Trustee Final Docs'
    },
    {
        id: 'SIGN_TRUSTEE_DOCUMENTS',
        name: 'Sign Trustee Documents'
    },
    {
        id: 'DOWNLOAD_TRUSTEE_DOCUMENTS',
        name: 'Download Final Docs'
    },
    {
        id: 'MARK_DOCUMENTS_APPROVED',
        name: "Mark Documents Approved"
    },
    {
        id: 'CLEAR_ACTION_ITEM',
        name: "Clear Action Item"
    },
    {
        id: 'TICKETS',
        name:"Tickets"
    }
];

export const AgentActionItemsFilter: object[]  = [
    {
        id: 'ALL',
        name: 'All'
    },
    {
        id: 'SIGN_AGENT',
        name: 'View and Sign'
    },
    {
        id: 'VIEW_APPROVE',
        name: 'View and Approve'
    }
];

export const kaiZen = 1;
export const kaiZenRebrand = 4;
export const kaiZenPhysicianRebrand = 5;
export const kaiZenPhysician = 6;

export const segmentationDropDown: object[] = [
    {
        id: 1,
        value: 'administration',
        name: 'Administration'
    },{
        id: 2,
        value: 'advertising',
        name: 'Advertising'
    },{
        id: 3,
        value: 'agricultural',
        name: 'Agricultural'
    },{
        id: 4,
        value: 'construction',
        name: 'Construction'
    },{
        id: 5,
        value: 'consulting',
        name: 'Consulting'
    },{
        id: 6,
        value: 'design&Architecture',
        name: 'Design & Architecture'
    },{
        id: 7,
        value: 'education',
        name: 'Education'
    },{
        id: 8,
        value: 'engineering',
        name: 'Engineering'
    },{
        id: 9,
        value: 'financialServices',
        name: 'Financial Services'
    },{
        id: 10,
        value: 'government&Defense',
        name: 'Government & Defense'
    },{
        id: 11,
        value: 'hospitality&Tourism',
        name: 'Hospitality & Tourism'
    },{
        id: 12,
        value: 'humanResources&Recruitment',
        name: 'Human Resources & Recruitment'
    },{
        id: 13,
        value: 'information&Technology',
        name: 'Information & Technology'
    },{
        id: 14,
        value: 'insurance',
        name: 'Insurance'
    },{
        id: 15,
        value: 'legal',
        name: 'Legal'
    },{
        id: 16,
        value: 'manufacturing',
        name: 'Manufacturing'
    },{
        id: 17,
        value: 'marketing&Communications',
        name: 'Marketing & Communications'
    },{
        id: 18,
        value: 'medical',
        name: 'Medical'
    },{
        id: 19,
        value: 'realEstate',
        name: 'Real Estate'
    },{
        id: 20,
        value: 'retail',
        name: 'Retail'
    },{
        id: 21,
        value: 'sales',
        name: 'Sales'
    },{
        id: 22,
        value: 'science&Technology',
        name: 'Science & Technology'
    },{
        id: 23,
        value: 'sport&Recreation',
        name: 'Sport & Recreation'
    },{
        id: 24,
        value: 'transport&Logistics',
        name: 'Transport & Logistics'
    },{
        id: 25,
        value: 'other',
        name: 'Other'
    },
];

export const getSetImoPreviousVal = (function getSetImoPreviousValue (): { setAgentVal: Function, getAgentVal: Function } {
    let agentId = 0;
    function setAgentVal (val: number) {
        agentId = val;
    }
    function getAgentVal () {
        return agentId;
    }
    return { setAgentVal, getAgentVal };
})();

// program to check leap year
const checkDaysInYear = () => {
    const year: number = Number(moment().format("YYYY"));
    //three conditions to find out the leap year
    if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
        return 366;
    }
    return 365;
}

export const canReviewAgainAfterDays = checkDaysInYear();

export const isServicingAccessible = (user: any) => user.inviteStatus >= AccountStatus.TrusteeSignAndEnrollmentClosed && user.inviteStatus !== AccountStatus.ApplicationReceivedByCarrier && user.client && user.client.currentPolicyStatus && (user.client.currentPolicyStatus === PolicyStatusEnum.InForce || user.client.currentPolicyStatus === PolicyStatusEnum.InForceOverride) && user.client && user.client.tranche && user.client.tranche.trancheNumber >= TRANCHE_SERVICING_ACCESS;


export const premiumPaidForTotalYears:number = 5;
export const policyStatusArray:any = [
    {
        status: "INVITED",
        options: [
            {
                id: 'INVITED',
                value: 'INVITED',
                name: 'Invited'
            },
            {
                id: PolicyStatusEnum.InForce,
                value: PolicyStatusEnum.InForce,
                name: 'Policy Issued'
            },
            {
                id: PolicyStatusEnum.InForceOverride,
                value: PolicyStatusEnum.InForceOverride,
                name: 'Policy Issued (Override)'
            }
        ]
    },
    {
        status: "ENROLLING",
        options: [
            {
                id: 'ENROLLING',
                value: 'ENROLLING',
                name: 'Enrolling'
            },
            {
                id: PolicyStatusEnum.InForce,
                value: PolicyStatusEnum.InForce,
                name: 'Policy Issued'
            },
            {
                id: PolicyStatusEnum.InForceOverride,
                value: PolicyStatusEnum.InForceOverride,
                name: 'Policy Issued (Override)'
            }
        ]
    },
    {
        status: "PROPOSED",
        options: [
            {
                id: 'PROPOSED',
                value: 'PROPOSED',
                name: 'Proposed'
            },
            {
                id: PolicyStatusEnum.InForce,
                value: PolicyStatusEnum.InForce,
                name: 'Policy Issued'
            },
            {
                id: PolicyStatusEnum.InForceOverride,
                value: PolicyStatusEnum.InForceOverride,
                name: 'Policy Issued (Override)'
            }
        ]
    },
    {
        status: "DESIGN",
        options: [
            {
                id: 'DESIGN',
                value: 'DESIGN',
                name: 'Design'
            },
            {
                id: PolicyStatusEnum.InForceOverride,
                value: PolicyStatusEnum.InForceOverride,
                name: 'Policy Issued (Override)'
            }
        ]
    },
    {
        status: "QUOTED",
        options: [
            {
                id: 'QUOTED',
                value: 'QUOTED',
                name: 'Quoted'
            },
            {
                id: PolicyStatusEnum.InForce,
                value: PolicyStatusEnum.InForce,
                name: 'Policy Issued'
            },
            {
                id: PolicyStatusEnum.InForceOverride,
                value: PolicyStatusEnum.InForceOverride,
                name: 'Policy Issued (Override)'
            }
        ]
    },
    {
        status: "PAYMENTRECEIVED",
        options: [
            {
                id: 'PAYMENTRECEIVED',
                value: 'PAYMENTRECEIVED',
                name: 'Payment Received'
            },
            {
                id: PolicyStatusEnum.InForce,
                value: PolicyStatusEnum.InForce,
                name: 'Policy Issued'
            },
            {
                id: PolicyStatusEnum.InForceOverride,
                value: PolicyStatusEnum.InForceOverride,
                name: 'Policy Issued (Override)'
            }
        ]
    },
    {
        status: "CASESUBMITTED",
        options: policyStatus
    }
];

export const inForcedFieldOptions:object[] = [
    {
        id: 'cash-out',
        value: 'cash-out',
        name: 'Cash Out'
    }, {
        id: 'living-benefits',
        value: 'living-benefits',
        name: 'Living Benefits'
    }, {
        id: 'not-taken',
        value: 'not-taken',
        name: 'Not Taken'
    }
];

export const notTakenOptions: object[] = [
{
    id: 'InsufficientFunds',
    value: 'InsufficientFunds',
    name: 'Insufficient Funds'
}, {
    id: 'Medical',
    value: 'Medical',
    name: 'Medical (Carrier Declined)'
}];

export const DocNameAndType: {[key: string]: {docName: string, docType: string}} = {
    "Drivers License": {
        docName: "Drivers License",
        docType: "IDENTITY_DOCUMENT",
    },
    "Passport": {
        docName: "Passport",
        docType: "IDENTITY_DOCUMENT",
    },
    "W2’s": {
        docName: "W2’s",
        docType: "TAX_DOCUMENT",
    },
    "Tax Returns": {
        docName: "Tax Returns",
        docType: "TAX_DOCUMENT",
    },
    "1099’s": {
        docName: "1099’s",
        docType: "TAX_DOCUMENT",
    },
    "Utility Bill": {
        docName: "Utility Bill",
        docType: "UTILITY_BILL",
    },
    "Visa": {
        docName: "Visa",
        docType: "RESIDENCE_DOCUMENT",
    },
    "Green Card": {
        docName: "Green Card",
        docType: "RESIDENCE_DOCUMENT",
    },
    "Permanent Residency Card": {
        docName: "Permanent Residency Card",
        docType: "RESIDENCE_DOCUMENT",
    },
    "Other": {
        docName: "Other",
        docType: "MORE_DOCUMENTS",
    },
    "Cover Letter": {
        docName: "Cover Letter",
        docType: "COVER_LETTER",
    },
    "Carrier Illustration Inputs": {
        docName: "Carrier Illustration Inputs",
        docType: "CARRIER_ILLUSTRATION_INPUTS",
    },
    "W2’s (Income Earner)": {
        docName: "W2’s Income Earner",
        docType: "TAX_DOCUMENT_INCOME_EARNER",
    },
    "Tax Returns (Income Earner)": {
        docName: "Tax Returns Income Earner",
        docType: "TAX_DOCUMENT_INCOME_EARNER",
    },
    "1099’s (Income Earner)": {
        docName: "1099’s Income Earner",
        docType: "TAX_DOCUMENT_INCOME_EARNER",
    },

    "W2’s (Spouse)": {
        docName: "W2’s Spouse",
        docType: "TAX_DOCUMENT_SPOUSE",
    },
    "Tax Returns (Spouse)": {
        docName: "Tax Returns Income Earner",
        docType: "TAX_DOCUMENT_SPOUSE",
    },
    "1099’s (Spouse)": {
        docName: "1099’s Income Earner",
        docType: "TAX_DOCUMENT_SPOUSE",
    },

    "W2’s (Wealth Transfer)": {
        docName: "W2’s Spouse",
        docType: "TAX_DOCUMENT_WEALTH_TRANSFER",
    },
    "Tax Returns (Wealth Transfer)": {
        docName: "Tax Returns Income Earner",
        docType: "TAX_DOCUMENT_WEALTH_TRANSFER",
    },
    "1099’s (Wealth Transfer)": {
        docName: "1099’s Income Earner",
        docType: "TAX_DOCUMENT_WEALTH_TRANSFER",
    },
};

export const ESTIMATION_API_URL: string = process.env.REACT_APP_ESTIMATION_API_HOST || "";

export const commonDocNameArr: string[] = ["Drivers License", "Passport", "W2’s", "Tax Returns", "1099’s", "Utility Bill", "Visa",
    "Green Card", "Permanent Residency Card", "Other", "Cover Letter", "Carrier Illustration Inputs"];
export const incomeEarnerDocArr: string[] = ["W2’s (Income Earner)", "Tax Returns (Income Earner)", "1099’s (Income Earner)"];
export const spouseDocArr: string[] = ["W2’s (Spouse)", "Tax Returns (Spouse)", "1099’s (Spouse)"];
export const wealthTransferDocArr: string[] = ["W2’s (Wealth Transfer)", "Tax Returns (Wealth Transfer)", "1099’s (Wealth Transfer)"];



export const cssPaths = {
    layout: "/css/layout.css?v=" + version,
    landingPage: "/css/landingPageV2.css?v=" + version,
    clientLayout: "/css/clientLayout.css?v=" + version,
    clientProfile: "/css/clientProfileCss.css?v=" + version,
    clientNavigation: "/css/clientNavigation.css?v=" + version,
    enrollment: "/css/enrollmentCss.css?v=" + version
};

export enum IrrStatus {
    APPROVED = "APPROVED",
    PENDING = "PENDING",
    REJECTED = "REJECTED"
}

export enum JsEvents {
    OrientationChange = 'orientationchange',
    Resize = 'resize',
    Load = 'load',
    Scroll = 'scroll',
    OnLine = 'online',
    OffLine = 'offline',
    SelectStart = 'selectstart',
}

export const NationalLifeCarrierDetail:{id:number, key:number, name:string, value:number} = {
    id: 2,
    key: 2,
    name: "National Life - (LSW)",
    value: 2
};

export const AllianzLifeCarrierDetail:{id:number, key:number, name:string, value:number} = {
    id: 3,
    key: 3,
    name: "Allianz Life",
    value: 3
};

export const defaultNIWPhoneNumber = "800-294-9940";

export const timPhone = '(469) 569-5605';

export const timEmail = 'tnewell@locktonaffinity.com';

export const addRootClasses = () => {
    let mainWrapDiv: any = document.getElementById("mainWrapDiv");
    mainWrapDiv && mainWrapDiv.classList.add("mainWrapDashboard");
    let frontendCss = document.getElementById("frontendCss");
    frontendCss && frontendCss.setAttribute("href", cssPaths.landingPage);
};

export const removeRootClasses = () => {
    let mainWrapDiv: any = document.getElementById("mainWrapDiv");
    mainWrapDiv && mainWrapDiv.classList.remove("mainWrapDashboard");
    let frontendCss = document.getElementById("frontendCss");
    frontendCss && frontendCss.setAttribute("href", cssPaths.clientLayout);
};

export const otherIndustryId = 21;
export const otherOccupationId = 1433;

export const desireLifeTimeBenefits = [
    {
        "issueAge": 18,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 19,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 20,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 21,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 22,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 23,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 24,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 25,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 26,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 27,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 28,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 29,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 30,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 31,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 32,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 33,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 34,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 35,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 36,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 37,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 38,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 39,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 40,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 41,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 42,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 43,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 44,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 45,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 46,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 47,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 48,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 49,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 50,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 51,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 52,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 53,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 54,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 55,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 56,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 57,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 58,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 59,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 60,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 61,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 62,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 63,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 64,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 65,
        "gender": "Female",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 18,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 19,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 20,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 21,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 22,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 23,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 24,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 25,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 26,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 27,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 28,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 29,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 30,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 31,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 32,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 33,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 34,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 35,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 36,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 37,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 38,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 39,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 40,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 41,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 42,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 43,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 44,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 45,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 46,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 47,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 48,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 49,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 50,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 51,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 52,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 53,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 54,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 55,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 56,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 57,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 58,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 59,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 60,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 61,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 62,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 63,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 64,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 65,
        "gender": "Female",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 18,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 19,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 20,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 21,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 22,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 23,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 24,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 25,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 26,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 27,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 28,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 29,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 30,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 31,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 32,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 33,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 34,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 35,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 36,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 37,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 38,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 39,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 40,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 41,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 42,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 43,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 44,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 45,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 46,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 47,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 48,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 49,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 50,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 51,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 52,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 53,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 54,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 55,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 56,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 57,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 58,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 59,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 60,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 61,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 62,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 63,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 64,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 65,
        "gender": "Female",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 18,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 19,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 20,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 21,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 22,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 23,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 24,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 25,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 26,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 27,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 28,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 29,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 30,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 31,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 32,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 33,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 34,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 35,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 36,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 37,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 38,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 39,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 40,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 41,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 42,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 43,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 44,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 45,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 46,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 47,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 48,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 49,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 50,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 51,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 52,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 53,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 54,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 55,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 56,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 57,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 58,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 59,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 60,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 61,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 62,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 63,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 64,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 65,
        "gender": "Female",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 18,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 19,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 20,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 21,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 22,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 23,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 24,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 25,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 26,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 27,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 28,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 29,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 30,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 31,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 32,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 33,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 34,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 35,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 36,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 37,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 38,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 39,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 40,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 41,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 42,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 43,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 44,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 45,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 46,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 47,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 48,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 49,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 50,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 51,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 52,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 53,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 54,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 55,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 56,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 57,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 58,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 59,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 60,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 61,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 62,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 63,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 64,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 65,
        "gender": "Male",
        "healthType": "Standard_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 18,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 19,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 20,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 21,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 22,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 23,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 24,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 25,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 26,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 27,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 28,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 29,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 30,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 31,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 32,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 33,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 34,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 35,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 36,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 37,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 38,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 39,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 40,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 41,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 42,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 43,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 44,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 45,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 46,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 47,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 48,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 49,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 50,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 51,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 52,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 53,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 54,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 55,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 56,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 57,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 58,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 59,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 60,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 61,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 62,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 63,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 64,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 65,
        "gender": "Male",
        "healthType": "Standard_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 18,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 19,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 20,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 21,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 22,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 23,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 24,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 25,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 26,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 27,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 28,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 29,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 30,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 31,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 32,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 33,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 34,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 35,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 36,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 37,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 38,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 39,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 40,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 41,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 42,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 43,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 44,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 45,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 1000000
    },
    {
        "issueAge": 46,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 47,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 48,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 49,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 50,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 51,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 52,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 53,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 54,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 55,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 56,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 57,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 58,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 59,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 60,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 61,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 62,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 63,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 64,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 65,
        "gender": "Male",
        "healthType": "Preferred_Tobacco",
        "minContribution": 500000
    },
    {
        "issueAge": 18,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 19,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 20,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 21,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 22,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 23,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 24,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 25,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 26,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 27,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 28,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 29,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 30,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 31,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 32,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 33,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 34,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 35,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 36,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 37,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 38,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 39,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 40,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 41,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 42,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 43,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 44,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 45,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 46,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 47,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 48,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 49,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 50,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 1000000
    },
    {
        "issueAge": 51,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 52,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 53,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 54,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 55,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 56,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 57,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 58,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 59,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 60,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 61,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 62,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 63,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 64,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    },
    {
        "issueAge": 65,
        "gender": "Male",
        "healthType": "Preferred_NT",
        "minContribution": 500000
    }
];

export const GWT_GEN2_STRATEGY = parseInt(pez(process.env.REACT_APP_GWT_STRATEGY), 10) || 8;

export const ATLANTIC_HEALTH = process.env.REACT_APP_ATLANTIC_HEALTH || "";

export const ATLANTIC_HEALTH_AGENT_NAME = "Christine Lins";
export const ATLANTIC_HEALTH_AGENT_PHONE = "9528070763";
export const ATLANTIC_HEALT_AGENTH_EMAIL = "christine.lins@aon.com";
export const ATLANTIC_HEALTH_AGENT_PROFILE_IMAGE = "https://assets.myilia.com/christine.jpeg";

export const INCORRECT_FORMAT: string = 'Incorrect Format';
export const REQUIRED_FIELD: string = 'Required Field';
export const ALPHABET_ONLY: string = 'Should be in Alphabet Only';
export const ALPHABET_RESTRICTED: string = 'Special characters are restricted';
export const NUMERIC_ONLY: string = 'Numeric Only';
export const ALPHABET_NUMERIC_ONLY: string = 'Should be in Alphanumeric Only';
export const ADDITIONAL_TEXT_FIELD_TYPE: string = 'textField';
export const OTHER_VALUE: string = 'Other';
export const NOTA: string = 'NONE OF THESE';

export const CallingFromObject = {
    'CLIENT_ILLUSTRATION' : "CLIENT_ILLUSTRATION",
}

export enum YesNoEnum {
    Yes = 'Yes',
    No = 'No',
}

export const getMaskedNumber = (number: string) => {
    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, number.length)}`;
};

export const ClientProposal: string = 'Client Proposal';
export const allRoutes: any = {
    [AccountType.MasterAdmin]: [
        "/accounts",
        "/carrierRequests",
        "/admin/notifications",
        "/tranche",
        "/document",
        "/estimator",
        "/strategies",
        "/action-items",
        "/admins/ILIATrainingPage",
        "/admins/marketing-material",
        "/admins/news-social",
        "/admins/activitylogs",
        "/lead-management",
        "/agent-link-reporting",
        "/marketing-emails",
        "/ilia-tickets",
        "/blacklist",
        "/approved-agents",
        "/reports",
        "/tranche-dates",
        "/import-ghost-client",
        "/group-detail",
        "/new-agent/detail",
        "/docu-sign-documents/list",
        "/docu-sign-documents/add",
        "/docu-sign-documents/edit",
        "/reset-enrollment/created-account/not-exist-in-enrollment-table",
        "/resend-invitation/to/users"
    ],
    [AccountType.Admin]: [
        "/accounts",
        "/carrierRequests",
        "/admin/notifications",
        "/tranche",
        "/document",
        "/estimator",
        "/strategies",
        "/action-items",
        "/admins/ILIATrainingPage",
        "/admins/marketing-material",
        "/admins/news-social",
        "/admins/activitylogs",
        "/lead-management",
        "/agent-link-reporting",
        "/marketing-emails",
        "/ilia-tickets",
        "/blacklist",
        "/approved-agents",
        "/reports",
        "/tranche-dates",
        "/import-ghost-client",
        "/group-detail",
        "/new-agent/detail",
        "/reset-enrollment/created-account/not-exist-in-enrollment-table"
    ],
    [AccountType.IMO]: [
        "/imo-dashboard",
        "/imo",
        "/imos/ILIATrainingPage",
        "/ilia-notifications",
        "/imos/marketing-material",
        "/imos/news-social",
        "/tranche-dates"
    ],
    [AccountType.ImoMember]: [
        "/imo-dashboard",
        "/imo",
        "/imos/ILIATrainingPage",
        "/ilia-notifications",
        "/imos/marketing-material",
        "/imos/news-social",
        "/tranche-dates"
    ],
    [AccountType.Agent]: [
        "/client",
        "/agent-dashboard",
        "/agent-links",
        "/action-items",
        "/ilia-notifications",
        "/agents/events",
        "/agents/ILIATrainingPage",
        "/agents/marketing-material",
        "/agents/news-social",
        "/agent-leads",
        "/client-payments",
        "/tranche-dates",
        "/new-client/detail",
        "/agent-groups"
    ],
    [AccountType.Individual]: [
        "/new-enrollment",
        "/dashboard",
        "/intro",
        "/client/estimationV1",
        "/financialconcerns",
        "/howitwork",
        "/product",
        "/leverage",
        "/minimumrisk",
        "/plandetails",
        "/testimonials",
        "/news-social",
        "/faq",
        "/SplitDollar",
        "/participants",
        "/enrollnow",
        "/participant",
        "/new-enrollment/thanks",
        "/new-enrollment/start",
        "/client/estimation-new",
        "/client/estimation",
        "/testimonials-aon",
        "/enrollmentServicingComing",
        "/enrollment/start",
        "/client/dashboard",
        "/client/payments",
        "/client/plandocuments",
        "/client/annualreview",
        "/client/mydetails",
        "/client/claims",
        "/client/questions",
        "/client/referralfeedback",
        "/client/contactus"
    ],
    [AccountType.Multilife]: [
        "/new-enrollment",
        "/dashboard",
        "/intro",
        "/client/estimationV1",
        "/financialconcerns",
        "/howitwork",
        "/product",
        "/leverage",
        "/minimumrisk",
        "/plandetails",
        "/testimonials",
        "/news-social",
        "/faq",
        "/SplitDollar",
        "/participants",
        "/enrollnow",
        "/participant",
        "/new-enrollment/thanks",
        "/new-enrollment/start",
        "/client/estimation-new",
        "/client/estimation",
        "/testimonials-aon",
        "/enrollmentServicingComing",
        "/enrollment/start",
        "/client/dashboard",
        "/client/payments",
        "/client/plandocuments",
        "/client/annualreview",
        "/client/mydetails",
        "/client/claims",
        "/client/questions",
        "/client/referralfeedback",
        "/client/contactus"
    ],
    [AccountType.Company]: [
        "/group-dashboard",
        "/group-participants",
        "/group-action-items",
        "/group-documents",
        "/group-notes"
    ],
    [AccountType.Association]: [
        "/client/dashboard",
        "/client/payments",
        "/client/plandocuments",
        "/client/annualreview",
        "/client/mydetails",
        "/client/claims",
        "/client/questions",
        "/client/referralfeedback",
        "/client/surrenderplan",
        "/client/contactus"
    ],
    [AccountType.Trustee]: [
        "/accounts/clients/individuals",
        "/action-items"
    ],
    [AccountType.Aggregator]: [
        "/aggregator-dashboard",
        "/aggregator/imo",
        "/ilia-notifications",
        "/aggregator/marketing-material",
        "/news-social",
        "/tranche-dates"
    ],
    [AccountType.AggregatorMember]: [
        "/aggregator-dashboard",
        "/aggregator/imo",
        "/ilia-notifications",
        "/aggregator/marketing-material",
        "/news-social",
        "/tranche-dates"
    ]
};

export const TRANCHE_SERVICING_ACCESS = 53;

export const ESTIMATE_MIN_AGE: number = 18;
export const ESTIMATE_MAX_AGE: number = 65;
//
// export const countryDD: object[] = [
//     {
//         code: "OLI_NATION_USA",
//         description: USA
//     },
//     {
//         code: "OLI_NATION_AFGHANISTAN",
//         description: "Afghanistan"
//     },
//     {
//         code: "OLI_NATION_ALBANIA",
//         description: "Albania"
//     },
//     {
//         code: "OLI_NATION_ALGERIA",
//         description: "Algeria"
//     },
//     {
//         code: "OLI_NATION_AMERICANSAMOA",
//         description: "American Samoa"
//     },
//     {
//         code: "OLI_NATION_ANDORRA",
//         description: "Andorra"
//     },
//     {
//         code: "OLI_NATION_ANGOLA",
//         description: "Angola"
//     },
//     {
//         code: "OLI_NATION_ANGUILLA",
//         description: "Anguilla"
//     },
//     {
//         code: "OLI_NATION_ANTARCTICA",
//         description: "Antarctica"
//     },
//     {
//         code: "OLI_NATION_ANTIGUABARBUDA",
//         description: "Antigua And Barbuda"
//     },
//     {
//         code: "OLI_NATION_ARGENTINA",
//         description: "Argentina"
//     },
//     {
//         code: "OLI_NATION_ARMENIA",
//         description: "Armenia"
//     },
//     {
//         code: "OLI_NATION_ARUBA",
//         description: "Aruba"
//     },
//     {
//         code: "OLI_NATION_AUSTRALIA",
//         description: "Australia"
//     },
//     {
//         code: "OLI_NATION_AUSTRIA",
//         description: "Austria"
//     },
//     {
//         code: "OLI_NATION_AZERBAIJAN",
//         description: "Azerbaijan"
//     },
//     {
//         code: "OLI_NATION_BAHAMAS",
//         description: "Bahamas"
//     },
//     {
//         code: "OLI_NATION_BAHRAIN",
//         description: "Bahrain"
//     },
//     {
//         code: "OLI_NATION_BANGLADESH",
//         description: "Bangladesh"
//     },
//     {
//         code: "OLI_NATION_BARBADOS",
//         description: "Barbados"
//     },
//     {
//         code: "OLI_NATION_BELARUS",
//         description: "Belarus"
//     },
//     {
//         code: "OLI_NATION_BELGIUM",
//         description: "Belgium"
//     },
//     {
//         code: "OLI_NATION_BELIZE",
//         description: "Belize"
//     },
//     {
//         code: "OLI_NATION_BENIN",
//         description: "Benin"
//     },
//     {
//         code: "OLI_NATION_BERMUDA",
//         description: "Bermuda"
//     },
//     {
//         code: "OLI_NATION_BHUTAN",
//         description: "Bhutan"
//     },
//     {
//         code: "OLI_NATION_BOLIVIA",
//         description: "Bolivia"
//     },
//     {
//         code: "OLI_NATION_BOSNIAHERZEGOVINA",
//         description: "Bosnia And Herzegovina"
//     },
//     {
//         code: "OLI_NATION_BOTSWANA",
//         description: "Botswana"
//     },
//     {
//         code: "OLI_NATION_BOUVETISLAND",
//         description: "Bouvet Island"
//     },
//     {
//         code: "OLI_NATION_BRAZIL",
//         description: "Brazil"
//     },
//     {
//         code: "OLI_NATION_BRITISHINDIANOCNTERR",
//         description: "British Indian Ocean Territory"
//     },
//     {
//         code: "OLI_NATION_BRUNEI",
//         description: "Brunei"
//     },
//     {
//         code: "OLI_NATION_BULGARIA",
//         description: "Bulgaria"
//     },
//     {
//         code: "OLI_NATION_BURKINAFASO",
//         description: "Burkina Faso"
//     },
//     {
//         code: "OLI_NATION_BURMA",
//         description: "Burma"
//     },
//     {
//         code: "OLI_NATION_BURUNDI",
//         description: "Burundi"
//     },
//     {
//         code: "OLI_NATION_CAMBODIA",
//         description: "Cambodia"
//     },
//     {
//         code: "OLI_NATION_CAMEROON",
//         description: "Cameroon"
//     },
//     {
//         code: "OLI_NATION_CANADA",
//         description: "Canada"
//     },
//     {
//         code: "OLI_NATION_CAPEVERDEISLAND",
//         description: "Cape Verde"
//     },
//     {
//         code: "OLI_NATION_CAYMANIS",
//         description: "Cayman Islands"
//     },
//     {
//         code: "OLI_NATION_CENTRALAFRICANREP",
//         description: "Central African Rep."
//     },
//     {
//         code: "OLI_NATION_CHAD",
//         description: "Chad"
//     },
//     {
//         code: "OLI_NATION_CHILE",
//         description: "Chile"
//     },
//     {
//         code: "OLI_NATION_CHINA",
//         description: "China"
//     },
//     {
//         code: "OLI_NATION_CHRISTMASISLANDS",
//         description: "Christmas Island"
//     },
//     {
//         code: "OLI_NATION_COCOS",
//         description: "Cocos island"
//     },
//     {
//         code: "OLI_NATION_COLUMBIA",
//         description: "Colombia"
//     },
//     {
//         code: "OLI_NATION_COMOROS",
//         description: "Comoros"
//     },
//     {
//         code: "OLI_NATION_CONGO",
//         description: "Congo"
//     },
//     {
//         code: "OLI_NATION_CONGODEMREP",
//         description: "Congo Dem. Rep."
//     },
//     {
//         code: "OLI_NATION_COOKISLANDS",
//         description: "Cook Islands"
//     },
//     {
//         code: "OLI_NATION_COSTARICA",
//         description: "Costa Rica"
//     },
//     {
//         code: "OLI_NATION_COTEDIVORIE",
//         description: "Cote d’Ivoire"
//     },
//     {
//         code: "OLI_NATION_CROATIA",
//         description: "Croatia"
//     },
//     {
//         code: "OLI_NATION_CUBA",
//         description: "Cuba"
//     },
//     {
//         code: "OLI_NATION_CYPRUS",
//         description: "Cyprus"
//     },
//     {
//         code: "OLI_NATION_CZECHREPUBLIC",
//         description: "Czech Republic"
//     },
//     {
//         code: "OLI_NATION_DENMARK",
//         description: "Denmark"
//     },
//     {
//         code: "OLI_NATION_DJIBOUTI",
//         description: "Djibouti"
//     },
//     {
//         code: "OLI_NATION_DOMINICA",
//         description: "Dominica"
//     },
//     {
//         code: "OLI_NATION_DOMINICANREPUBLIC",
//         description: "Dominican Republic"
//     },
//     {
//         code: "OLI_NATION_ECUADOR",
//         description: "Ecuador"
//     },
//     {
//         code: "OLI_NATION_EGYPT",
//         description: "Egypt"
//     },
//     {
//         code: "OLI_NATION_ELSALVADOR",
//         description: "El Salvador"
//     },
//     {
//         code: "OLI_NATION_EQUATORIALGUINEA",
//         description: "Equatorial Guinea"
//     },
//     {
//         code: "OLI_NATION_ERITREA",
//         description: "Eritrea"
//     },
//     {
//         code: "OLI_NATION_ESTONIA",
//         description: "Estonia"
//     },
//     {
//         code: "OLI_NATION_ETHIOPIA",
//         description: "Ethiopia"
//     },
//     {
//         code: "OLI_NATION_FALKLANDISLANDS",
//         description: "Falkland Islands"
//     },
//     {
//         code: "OLI_NATION_FAEROEISLANDS",
//         description: "Faroe Islands"
//     },
//     {
//         code: "OLI_NATION_FIJI",
//         description: "Fiji"
//     },
//     {
//         code: "OLI_NATION_FINLAND",
//         description: "Finland"
//     },
//     {
//         code: "OLI_NATION_FRANCE",
//         description: "France"
//     },
//     {
//         code: "OLI_NATION_FRENCHGUIANA",
//         description: "French Guiana"
//     },
//     {
//         code: "OLI_NATION_FRENCHPOLYNESIA",
//         description: "French Polynesia"
//     },
//     {
//         code: "OLI_NATION_FRENCHSOUTHERNTERRITIOIES",
//         description: "French Southern Territories"
//     },
//     {
//         code: "OLI_NATION_GABON",
//         description: "Gabon"
//     },
//     {
//         code: "OLI_NATION_GAMBIA",
//         description: "Gambia"
//     },
//     {
//         code: "OLI_NATION_GEORGIA",
//         description: "Georgia"
//     },
//     {
//         code: "OLI_NATION_GERMANY",
//         description: "Germany"
//     },
//     {
//         code: "OLI_NATION_GHANA",
//         description: "Ghana"
//     },
//     {
//         code: "OLI_NATION_GIBRALTAR",
//         description: "Gibraltar"
//     },
//     {
//         code: "OLI_NATION_GREECE",
//         description: "Greece"
//     },
//     {
//         code: "OLI_NATION_GREENLAND",
//         description: "Greenland"
//     },
//     {
//         code: "OLI_NATION_CRENADA",
//         description: "Grenada"
//     },
//     {
//         code: "OLI_NATION_GUADALOUPE",
//         description: "Guadeloupe"
//     },
//     {
//         code: "OLI_NATION_GUAM",
//         description: "Guam"
//     },
//     {
//         code: "OLI_NATION_GUATEMALA",
//         description: "Guatemala"
//     },
//     {
//         code: "OLI_NATION_GUERNSEY",
//         description: "Guernsey"
//     },
//     {
//         code: "OLI_NATION_GUINEA",
//         description: "Guinea"
//     },
//     {
//         code: "OLI_NATION_GUINEABISSAU",
//         description: "Guinea Bissau"
//     },
//     {
//         code: "OLI_NATION_GUYANA",
//         description: "Guyana"
//     },
//     {
//         code: "OLI_NATION_HAITI",
//         description: "Haiti"
//     },
//     {
//         code: "OLI_NATION_HEARDISLISLAND",
//         description: "Heard Island"
//     },
//     {
//         code: "OLI_NATION_HOLYSEE",
//         description: "Holy See (Vatican City State)"
//     },
//     {
//         code: "OLI_NATION_HONDURAS",
//         description: "Honduras"
//     },
//     {
//         code: "OLI_NATION_HONGKONG",
//         description: "Hong Kong"
//     },
//     {
//         code: "OLI_NATION_HUNGARY",
//         description: "Hungary"
//     },
//     {
//         code: "OLI_NATION_ICELAND",
//         description: "Iceland"
//     },
//     {
//         code: "OLI_NATION_INDIA",
//         description: "India"
//     },
//     {
//         code: "OLI_NATION_INDONESIA",
//         description: "Indonesia"
//     },
//     {
//         code: "OLI_NATION_IRAN",
//         description: "Iran"
//     },
//     {
//         code: "OLI_NATION_IRAQ",
//         description: "Iraq"
//     },
//     {
//         code: "OLI_NATION_IRELAND",
//         description: "Ireland"
//     },
//     {
//         code: "OLI_NATION_ISLEOFMAN",
//         description: "Isle of Man"
//     },
//     {
//         code: "OLI_NATION_ISRAEL",
//         description: "Israel"
//     },
//     {
//         code: "OLI_NATION_ITALY",
//         description: "Italy"
//     },
//     {
//         code: "OLI_NATION_JAMAICA",
//         description: "Jamaica"
//     },
//     {
//         code: "OLI_NATION_JAPAN",
//         description: "Japan"
//     },
//     {
//         code: "OLI_NATION_JERSEY",
//         description: "Jersey"
//     },
//     {
//         code: "OLI_NATION_JORDAN",
//         description: "Jordan"
//     },
//     {
//         code: "OLI_NATION_KAZAKHSTAN",
//         description: "Kazakhstan"
//     },
//     {
//         code: "OLI_NATION_KENYA",
//         description: "Kenya"
//     },
//     {
//         code: "OLI_NATION_KIRIBATI",
//         description: "Kiribati"
//     },
//     {
//         code: "OLI_NATION_KOREADEMPEOPLEREP",
//         description: "Korea, Democratic Peoples Republic of"
//     },
//     {
//         code: "OLI_NATION_KOREAREPUBLIC",
//         description: "Korea, Republic of"
//     },
//     {
//         code: "OLI_NATION_KOSOVO",
//         description: "Kosovo"
//     },
//     {
//         code: "OLI_NATION_KUWAIT",
//         description: "Kuwait"
//     },
//     {
//         code: "OLI_NATION_KYRGYZSTAN",
//         description: "Kyrgyzstan"
//     },
//     {
//         code: "OLI_NATION_LAOS",
//         description: "Laos"
//     },
//     {
//         code: "OLI_NATION_LATVIA",
//         description: "Latvia"
//     },
//     {
//         code: "OLI_NATION_LEBANON",
//         description: "Lebanon"
//     },
//     {
//         code: "OLI_NATION_LESOTHO",
//         description: "Lesotho"
//     },
//     {
//         code: "OLI_NATION_LIBERIA",
//         description: "Liberia"
//     },
//     {
//         code: "OLI_NATION_LIBYA",
//         description: "Libya"
//     },
//     {
//         code: "OLI_NATION_LIECHTENSTEIN",
//         description: "Liechtenstein"
//     },
//     {
//         code: "OLI_NATION_LITHUANIA",
//         description: "Lithuania"
//     },
//     {
//         code: "OLI_NATION_LUXEMBOURG",
//         description: "Luxembourg"
//     },
//     {
//         code: "OLI_NATION_MACAO",
//         description: "Macao"
//     },
//     {
//         code: "OLI_NATION_MACEDONIA",
//         description: "Macedonia (FYROM)"
//     },
//     {
//         code: "OLI_NATION_MADAGASCAR",
//         description: "Madagascar"
//     },
//     {
//         code: "OLI_NATION_MALAWI",
//         description: "Malawi"
//     },
//     {
//         code: "OLI_NATION_MALAYSIA",
//         description: "Malaysia"
//     },
//     {
//         code: "OLI_NATION_MALDIVES",
//         description: "Maldives"
//     },
//     {
//         code: "OLI_NATION_MALI",
//         description: "Mali"
//     },
//     {
//         code: "OLI_NATION_MALTA",
//         description: "Malta"
//     },
//     {
//         code: "OLI_NATION_MARSHALLISLANDS",
//         description: "Marshall Islands"
//     },
//     {
//         code: "OLI_NATION_MARTINIQUE",
//         description: "Martinique"
//     },
//     {
//         code: "OLI_NATION_MAURITANIA",
//         description: "Mauritania"
//     },
//     {
//         code: "OLI_NATION_MAURITIUS",
//         description: "Mauritius"
//     },
//     {
//         code: "OLI_NATION_MAYOTTEISLAND",
//         description: "Mayotte"
//     },
//     {
//         code: "OLI_NATION_MEXICO",
//         description: "Mexico"
//     },
//     {
//         code: "OLI_NATION_MICRONESIA",
//         description: "Micronesia, Federated States of"
//     },
//     {
//         code: "OLI_NATION_MOLDOVA",
//         description: "Moldova"
//     },
//     {
//         code: "OLI_NATION_MONACO",
//         description: "Monaco"
//     },
//     {
//         code: "OLI_NATION_MONGOLIA",
//         description: "Mongolia"
//     },
//     {
//         code: "OLI_NATION_MONTENEGRO",
//         description: "Montenegro"
//     },
//     {
//         code: "OLI_NATION_MONTSERRAT",
//         description: "Montserrat"
//     },
//     {
//         code: "OLI_NATION_MOROCCO",
//         description: "Morocco"
//     },
//     {
//         code: "OLI_NATION_MOZAMBIQUE",
//         description: "Mozambique"
//     },
//     {
//         code: "OLI_NATION_MYANMAR",
//         description: "Myanmar"
//     },
//     {
//         code: "OLI_NATION_NAMIBIA",
//         description: "Namibia"
//     },
//     {
//         code: "OLI_NATION_NAURU",
//         description: "Nauru"
//     },
//     {
//         code: "OLI_NATION_NEPAL",
//         description: "Nepal"
//     },
//     {
//         code: "OLI_NATION_NETHERLANDS",
//         description: "Netherlands"
//     },
//     {
//         code: "OLI_NATION_NETHERLANDSANTILLES",
//         description: "Netherlands Antilles"
//     },
//     {
//         code: "OLI_NATION_NEWCALEDONIA",
//         description: "New Caledonia"
//     },
//     {
//         code: "OLI_NATION_NEWZEALAND",
//         description: "New Zealand"
//     },
//     {
//         code: "OLI_NATION_NICARAGUA",
//         description: "Nicaragua"
//     },
//     {
//         code: "OLI_NATION_NIGER",
//         description: "Niger"
//     },
//     {
//         code: "OLI_NATION_NIGERIA",
//         description: "Nigeria"
//     },
//     {
//         code: "OLI_NATION_NIUE",
//         description: "Niue"
//     },
//     {
//         code: "OLI_NATION_NORFOLKISLAND",
//         description: "Norfolk Island"
//     },
//     {
//         code: "OLI_NATION_NORTHYEMEN",
//         description: "North Yemen"
//     },
//     {
//         code: "OLI_NATION_NORTHMARIANAISLANDS",
//         description: "Northern Mariana Islands"
//     },
//     {
//         code: "OLI_NATION_NORWAY",
//         description: "Norway"
//     },
//     {
//         code: "OLI_NATION_OMAN",
//         description: "Oman"
//     },
//     {
//         code: "OLI_NATION_PAKISTAN",
//         description: "Pakistan"
//     },
//     {
//         code: "OLI_NATION_PALAU",
//         description: "Palau"
//     },
//     {
//         code: "OLI_NATION_PALESTINE",
//         description: "Palestinian Territory, Occupied"
//     },
//     {
//         code: "OLI_NATION_PANAMA",
//         description: "Panama"
//     },
//     {
//         code: "OLI_NATION_PAPUANEWGUINEA",
//         description: "Papua New Guinea"
//     },
//     {
//         code: "OLI_NATION_PARAGUAY",
//         description: "Paraguay"
//     },
//     {
//         code: "OLI_NATION_PERU",
//         description: "Peru"
//     },
//     {
//         code: "OLI_NATION_PHILIPPINES",
//         description: "Philippines"
//     },
//     {
//         code: "OLI_NATION_PITCARINISLANDS",
//         description: "Pitcairn"
//     },
//     {
//         code: "OLI_NATION_POLAND",
//         description: "Poland"
//     },
//     {
//         code: "OLI_NATION_PORTUGAL",
//         description: "Portugal"
//     },
//     {
//         code: "OLI_NATION_PUERTORICO",
//         description: "Puerto Rico"
//     },
//     {
//         code: "OLI_NATION_QATAR",
//         description: "Qatar"
//     },
//     {
//         code: "OLI_NATION_REUNIONISLAND",
//         description: "Reunion"
//     },
//     {
//         code: "OLI_NATION_ROMANIA",
//         description: "Romania"
//     },
//     {
//         code: "OLI_NATION_RUSSIA",
//         description: "Russia"
//     },
//     {
//         code: "OLI_NATION_RWANDA",
//         description: "Rwanda"
//     },
//     {
//         code: "OLI_NATION_SAMOA",
//         description: "Samoa"
//     },
//     {
//         code: "OLI_NATION_SANMARINO",
//         description: "San Marino"
//     },
//     {
//         code: "OLI_NATION_SAOTOME",
//         description: "Sao Tome And Principe"
//     },
//     {
//         code: "OLI_NATION_SAUDIARABIA",
//         description: "Saudi Arabia"
//     },
//     {
//         code: "OLI_NATION_SENEGAL",
//         description: "Senegal"
//     },
//     {
//         code: "OLI_NATION_SERBIA",
//         description: "Serbia"
//     },
//     {
//         code: "OLI_NATION_SEYCHELLES",
//         description: "Seychelles And Dependencies"
//     },
//     {
//         code: "OLI_NATION_SIERRALEONE",
//         description: "Sierra Leone"
//     },
//     {
//         code: "OLI_NATION_SINGAPORE",
//         description: "Singapore"
//     },
//     {
//         code: "OLI_NATION_SLOVAKIA",
//         description: "Slovakia"
//     },
//     {
//         code: "OLI_NATION_SLOVENIA",
//         description: "Slovenia"
//     },
//     {
//         code: "OLI_NATION_SOLOMONISLANDS",
//         description: "Solomon Islands"
//     },
//     {
//         code: "OLI_NATION_SOMALIA",
//         description: "Somalia"
//     },
//     {
//         code: "OLI_NATION_SOUTHAFRICA",
//         description: "South Africa"
//     },
//     {
//         code: "OLI_NATION_SOUTHGEORGIASANDWICH",
//         description: "South Georgia"
//     },
//     {
//         code: "OLI_NATION_SOUTH_SUDAN",
//         description: "South Sudan"
//     },
//     {
//         code: "OLI_NATION_SOUTHYEMEN",
//         description: "South Yemen"
//     },
//     {
//         code: "OLI_NATION_SPAIN",
//         description: "Spain"
//     },
//     {
//         code: "OLI_NATION_SRILANKA",
//         description: "Sri Lanka"
//     },
//     {
//         code: "OLI_NATION_STPIERRE",
//         description: "St Pierre And Miquelon"
//     },
//     {
//         code: "OLI_NATION_STHELENA",
//         description: "St. Helena And Dependencies"
//     },
//     {
//         code: "OLI_NATION_STKITTSNEVIS",
//         description: "St. Kitts And Nevis"
//     },
//     {
//         code: "OLI_NATION_STLUCIA",
//         description: "St. Lucia"
//     },
//     {
//         code: "OLI_NATION_STVINCENT",
//         description: "St. Vincent"
//     },
//     {
//         code: "OLI_NATION_SUDAN",
//         description: "Sudan"
//     },
//     {
//         code: "OLI_NATION_SURINAME",
//         description: "Suriname"
//     },
//     {
//         code: "OLI_NATION_SVALBARDISLAND",
//         description: "Svalbard and Jan Mayen"
//     },
//     {
//         code: "OLI_NATION_SWAZILAND",
//         description: "Swaziland"
//     },
//     {
//         code: "OLI_NATION_SWEDEN",
//         description: "Sweden"
//     },
//     {
//         code: "OLI_NATION_SWITZERLAND",
//         description: "Switzerland"
//     },
//     {
//         code: "OLI_NATION_SYRIA",
//         description: "Syria"
//     },
//     {
//         code: "OLI_NATION_TAIWAN",
//         description: "Taiwan"
//     },
//     {
//         code: "OLI_NATION_TAJIKISTAN",
//         description: "Tajikistan"
//     },
//     {
//         code: "OLI_NATION_TANZANIA",
//         description: "Tanzania"
//     },
//     {
//         code: "OLI_NATION_THAILAND",
//         description: "Thailand"
//     },
//     {
//         code: "OLI_NATION_EASTTIMOR",
//         description: "Timor-Leste"
//     },
//     {
//         code: "OLI_NATION_TOGO",
//         description: "Togo"
//     },
//     {
//         code: "OLI_NATION_TOKELAU",
//         description: "Tokelau"
//     },
//     {
//         code: "OLI_NATION_TONGAISLANDS",
//         description: "Tonga"
//     },
//     {
//         code: "OLI_NATION_TRINIDADTOBAGO",
//         description: "Trinidad And Tobago"
//     },
//     {
//         code: "OLI_NATION_TUNISIA",
//         description: "Tunisia"
//     },
//     {
//         code: "OLI_NATION_TURKEY",
//         description: "Turkey"
//     },
//     {
//         code: "OLI_NATION_TURKMENISTAN",
//         description: "Turkmenistan"
//     },
//     {
//         code: "OLI_NATION_TURKSCAICOSIS",
//         description: "Turks And Caicos Islands"
//     },
//     {
//         code: "OLI_NATION_TUVALU",
//         description: "Tuvalu"
//     },
//     {
//         code: "OLI_NATION_UGANDA",
//         description: "Uganda"
//     },
//     {
//         code: "OLI_NATION_UKRAINE",
//         description: "Ukraine"
//     },
//     {
//         code: "OLI_NATION_USSR",
//         description: "Union of Soviet Socialist Republics"
//     },
//     {
//         code: "OLI_NATION_UNITEDARABEMIRATES",
//         description: "United Arab Emirates"
//     },
//     {
//         code: "OLI_NATION_UK",
//         description: "United Kingdom"
//     },
//     {
//         code: "OLI_NATION_USMINOROUTLYINGISLANDS",
//         description: "United States Minor Outlying Islands"
//     },
//     {
//         code: "OLI_NATION_URUGUAY",
//         description: "Uruguay"
//     },
//     {
//         code: "OLI_NATION_VIRGINISLANDSUS",
//         description: "US Virgin Islands"
//     },
//     {
//         code: "OLI_NATION_UZBEKISTAN",
//         description: "Uzbekistan"
//     },
//     {
//         code: "OLI_NATION_VANUATU",
//         description: "Vanuatu"
//     },
//     {
//         code: "OLI_NATION_VENEZUELA",
//         description: "Venezuela"
//     },
//     {
//         code: "OLI_NATION_VIETNAM",
//         description: "Vietnam"
//     },
//     {
//         code: "OLI_NATION_BRITISHVIRGINIS",
//         description: "Virgin Islands, British"
//     },
//     {
//         code: "OLI_NATION_WALLISISLANDS",
//         description: "Wallis And Futuna Islands"
//     },
//     {
//         code: "OLI_NATION_WESTERNSARAHA",
//         description: "Western Sahara"
//     },
//     {
//         code: "OLI_NATION_WESTERNSAMOA",
//         description: "Western Samoa"
//     },
//     {
//         code: "OLI_NATION_YEMEN",
//         description: "Yemen"
//     },
//     {
//         code: "OLI_NATION_YUGOSLAVIA",
//         description: "Yugoslavia"
//     },
//     {
//         code: "OLI_NATION_ZAMBIA",
//         description: "Zambia"
//     },
//     {
//         code: "OLI_NATION_ZIMBABWE",
//         description: "Zimbabwe"
//     },
//     {
//         code: "OLI_OTHER",
//         description: "Other"
//     }
// ];
