import React  from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, List, ListItem, ListItemText } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

interface PasswordRequiredProps {
    oneLetter: boolean,
    oneNumber: boolean,
    oneCapitalLetter: boolean,
    eightCharacter: boolean,
    oneSpecialChar: boolean
}

interface RequirementMapTypes {
    has: boolean,
    innerHtml: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        red: {
            color: '#ec0502'
        },
        green: {
            color: '#45ae37'
        }
    })
);




export default function PasswordRequired({
    oneLetter,
    oneNumber,
    oneCapitalLetter,
    eightCharacter,
    oneSpecialChar
}: PasswordRequiredProps) {
    const classes = useStyles();
    const requirements = [
        { has: oneLetter, innerHtml: "At least one lowercase letter" },
        { has: oneNumber, innerHtml: "At least one number" },
        { has: oneCapitalLetter, innerHtml: "At least one uppercase letter" },
        { has: eightCharacter, innerHtml: "Be at least 8 characters" },
        { has: oneSpecialChar, innerHtml: "At least one special character" },
    ]

    function iconType(valid: boolean) {
        return (
            valid ? <CheckCircleIcon className={classes.green}/> : <CancelIcon className={classes.red}/>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <p className="txtLeft mb10 mt0">Password must meet the following requirements:</p>
                <List className="pwdInstructions">
                    {
                        requirements.map((row: RequirementMapTypes, index: number) => (
                             <ListItem key={index}>
                                {iconType(row.has)}
                                <ListItemText className={row.has ? classes.green : classes.red}>{row.innerHtml}</ListItemText>
                            </ListItem>
                        ))
                    }
                </List>
            </Grid>
        </Grid>
    );
};