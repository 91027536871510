import {
    Button,
    Card,
    CardContent,
    Grid,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
    IconButton,
    Table,
    makeStyles,
    Theme,
    createStyles,
    ListSubheader,
    FormControl,
    InputLabel,
    Typography,
    Checkbox,
    ListItemText,
    Box,
} from "@material-ui/core";
import * as React from "react";
import { AccountTypeByFilter, AccountTypeByName, accountTypeDropDown } from "../../constants";
import SearchIcon from "@material-ui/icons/Search";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import {
    IndividualTable,
    MultiLifeTable,
    AssociationTable,
    GroupLinkTable,
    Pagination,
    ClientListParticipants,
} from "../../components/table";
import { DeleteModel, PageTitle } from "../../components/formInputs";
import { ucFirst } from "../../utils/commonUtils";
import { AccountStatusArr } from "../../model";
import { AccountSortList } from "../../model/account";
import useQueryString from "../../hooks/useQueryString";
import ClientListTable from "../../components/table/ClientListTable";
import ClientListDetail from "../../components/ClientListDetail";
import Stats from "../../components/Stats";
import AllParticipantTable from "../../components/table/AllParticipantTable";
import TableContainer from "@material-ui/core/TableContainer";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import QuickView from "./dashboard/QuickView";
import { DashboardStyle as Styles } from "../agent/dashboard/dashboardStyle";
import TableWrap from "../../components/TableWrap/TableWrap";

function ClientPage(props: any) {
    const styles = Styles();
    const [page, setPage] = useQueryString("page", 0),
            [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", 25),
            [search, setSearch] = useQueryString("search", ""),
            [filter, setFilter] = React.useState(5),
            [id, setId] = React.useState(""),
            [orderBy, setOrderBy] = useQueryString("orderBy", "createdAt"),
            [refresh, setRefresh] = React.useState(0),
            [order, setOrder] = useQueryString("order", "desc"),
            accountActions = useActions(AccountActions),
            [statusTypes, setStatusTypes] = React.useState<string[]>([]),
            [selected, setSelected] = React.useState<any[]>([]),
            [clientType, setClientType] = React.useState("individual");
    const [pageTitle, setPageTitle] = React.useState("Client Management");

    const [totalInvites, setTotalInvites] = React.useState<number>(0);
    const [totalAccountCreated, setTotalAccountCreated] = React.useState<number>(0);
    const [enrolling, setEnrolling] = React.useState<number>(0);
    const [enrolled, setEnrolled] = React.useState<number>(0);
    const [activeUsers, setActiveUsers] = React.useState<number>(0);
    const [inActiveUsers, setInActiveUsers] = React.useState<number>(0);

    const clientList: any = useSelector((state: RootState) => state.accounts);
    const auth: any = useSelector((state: RootState) => state.auth);
    let agentId: number = auth && auth.user && auth.user.agent && auth.user.agent.id;

    React.useEffect(() => {
        let queryObj: any = {
            page,
            rowsPerPage,
            searchText: search,
            sortBy: orderBy,
            order,
            filter,
            statusTypes: statusTypes.join(","),
        };

        const lastParam: string = props.match.path.substring(props.match.path.lastIndexOf("/") + 1);
        if (["client", "individual", "groups", "group-link", "list", "participants", "client-list"].find(p => p === lastParam)) {
            queryObj.filter = lastParam === "client" ? AccountTypeByName["individual"] : AccountTypeByName[lastParam];
            setFilter(AccountTypeByName[lastParam]);
            if (props.match.params.companyId) {
                queryObj.filter = 6;
                queryObj.companyId = props.match.params.companyId;
                setFilter(6);
            } else if (props.match.params.clientListId) {
                queryObj.filter = 14;
                queryObj.clientListId = props.match.params.clientListId;
                setFilter(14);
            } else {
                setClientType(lastParam);
            }
            if (["client", "individual"].indexOf(lastParam) > -1) {
                setFilter(5);
            }
            if (lastParam === "client-list") {
                setFilter(14);
            }
        }
        if (lastParam === "client") {
            queryObj.sortBy = "createdAt";
        }
        if (auth.user.role === 4 && props.history.location.pathname === "/client/groups") {
            setPageTitle("My Groups");
        } else {
            setPageTitle("Client Management");
        }
        accountActions.agentClientList(queryObj);
    }, [page, rowsPerPage, search, filter, statusTypes, clientType, props.match.params, refresh, orderBy, order, props.history.location.pathname]);


    React.useEffect(() => {
        setSelected([]);
    }, [statusTypes, clientType]);

    React.useEffect(() => {
        agentDetails();
    }, [agentId]);

    const agentDetails = async () => {
        let data = await accountActions.getAgentDashboardDetails();
        if (data) {
            setTotalInvites(data && data.totalInvites);
            setTotalAccountCreated(data && data.accountCreated);
            setEnrolling(data && data.currentlyEnrolling);
            setEnrolled(data && data.enrolled);
            setActiveUsers(data && data.activeUsers);
            setInActiveUsers(data && data.inActiveUsers);
        }
    };

    const handleExport = accountActions.getAgentDashboardLists;

    const deleteUser = async () => {
        return false;
    };

    const goToClientDetail = (id: string) => {
        props.history.push("/new-client/detail/" + id);
    };

    const goToParticipantList = (id: string) => {
        props.history.push("/participant/list/" + id);
    };

    const goToClientList = (id: number) => {
        props.history.push("/client/list/" + id);
    };

    const getDeletedUserName = (id: string, clientListPage: boolean) => {
        let fullName: any = [];
        clientListPage ?
                clientList.rows.map((val: any) => (id === val.id) && fullName.push(val.clientListName))
                : clientList.rows.map((val: any) => (id === val.id) && fullName.push(val.firstName + " " + val.lastName));
        return fullName[0];
    };

    const handleReInvite = async (id: string) => {
        await accountActions.resendInvitation(id);
        setRefresh(Math.random());
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    const handleRefresh = () => {
        setRefresh(Math.random());
    };

    const [isVisibleDeleteModal, setIsVisibleDeleteModal] = React.useState(false);

    const handleApply = (action) => {
        if (action == "delete") {
            setIsVisibleDeleteModal(true);
        }

        if (action === "export") {
            accountActions.exportClients(selected);
        }
    };

    return (
            <React.Fragment>
                <PageTitle title={pageTitle}/>
                <Typography className="mb20 w100 mt20" variant="h3" align="left">Client Management</Typography>
                <Typography variant="body1" className="w100">Select from the list of clients below to view their
                    details,enrollment status, and more.</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="caption" component="strong" align="left"
                                    className={styles.textLight + " " + styles.mb15 + " " + styles.floatLeft + " " + styles.w100}>
                            QUICK VIEW
                        </Typography>
                        <Box display="flex" flexWrap="wrap"
                             className={styles.flexBox + " " + styles.floatLeft + " " + styles.w100}>
                            <QuickView
                                    handleExport={handleExport}
                                    isHeading={false}
                                    heading={"All"}
                                    data={[
                                        {
                                            tooltipText: "Number includes all individual invites, group invites, and client lists.",
                                            title: { name: "Total Invites", value: totalInvites },
                                            className: styles.btnBlue,
                                            kpiModal: "TOTAL_INVITES",
                                            kpiModalTitle: "Total Invites",
                                        },
                                        {
                                            tooltipText: null,
                                            title: { name: "Total Accounts Created", value: totalAccountCreated },
                                            className: styles.btnGreen,
                                            kpiModal: "TOTAL_ACCOUNT_CREATED",
                                            kpiModalTitle: "Total Accounts Created",
                                        },
                                    ]}
                                    csvKey={"ALL"}
                            />

                            <QuickView
                                    handleExport={handleExport}
                                    isHeading={true}
                                    heading={"Currently Enrolling"}
                                    data={[
                                        {
                                            tooltipText: null,
                                            title: { name: "Client currently in enrollment", value: enrolling },
                                            className: styles.btnGreen,
                                            kpiModal: "ENROLLING",
                                            kpiModalTitle: "Currently Enrolling",
                                        },
                                    ]}
                                    csvKey={"ENROLLING"}
                            />

                            <QuickView
                                    handleExport={handleExport}
                                    isHeading={true}
                                    heading={"Enrolled"}
                                    data={[
                                        {
                                            tooltipText: null,
                                            title: { name: "Client that have already enrolled", value: enrolled },
                                            className: styles.btnGreen,
                                            kpiModal: "ENROLLED",
                                            kpiModalTitle: "Enrolled",
                                        },
                                    ]}
                                    csvKey={"ENROLLED"}
                            />

                            <QuickView
                                    handleExport={handleExport}
                                    isHeading={true}
                                    heading={"Currently Active"}
                                    data={[
                                        {
                                            tooltipText: null,
                                            title: {
                                                name: "Has an account and active within 30 days.",
                                                value: activeUsers,
                                            },
                                            className: styles.btnGreen,
                                            kpiModal: "CURRENTLY_ACTIVE",
                                            kpiModalTitle: "Currently Active",
                                        },
                                    ]}
                                    csvKey={"CURRENTLY_ACTIVE"}
                            />

                            <QuickView
                                    handleExport={handleExport}
                                    isHeading={true}
                                    heading={"Currently Inactive"}
                                    data={[
                                        {
                                            tooltipText: null,
                                            title: {
                                                name: "Has account but no activity within 30 days.",
                                                value: inActiveUsers,
                                            },
                                            className: styles.btnRed,
                                            kpiModal: "CURRENTLY_IN_ACTIVE",
                                            kpiModalTitle: "Currently Inactive",
                                        },
                                    ]}
                                    csvKey={"CURRENTLY_IN_ACTIVE"}
                                    selectable
                            />
                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        {
                                filter === 14 && props.match.params.clientListId && clientList && clientList.data &&
                                <ClientListDetail detail={clientList.data} auth={auth}/>
                        }
                        <Card className="muiCard mt0">
                            <CardContent className="muiCardContent">
                                <Grid container direction="row" spacing={3} justify="space-between">
                                    <Grid item lg={4} xs={12}>
                                        {
                                                selected.length > 0 && <BulkActions onApply={handleApply}/>
                                        }
                                    </Grid>
                                    <Grid item lg={8} container spacing={3} justify="flex-end">
                                        <Grid item lg={4} xs={12}>
                                            <FormControl variant="outlined" fullWidth={true}>
                                                <InputLabel id="participant-types-select">Participant Types</InputLabel>
                                                <Select
                                                        labelId="participant-types-select"
                                                        className="muiSelect"
                                                        value={filter}
                                                        renderValue={(selected) => {
                                                            const item = accountTypeDropDown.find(o => o.id === selected);
                                                            return item ? item.name : "";
                                                        }}
                                                        onChange={e => {
                                                            let val: any = e.target.value;
                                                            setFilter(val);
                                                            setStatusTypes([]);
                                                            setPage(0);
                                                            setRowsPerPage(25);
                                                            setSearch("");
                                                            setId("");
                                                            setOrderBy("createdAt");
                                                            setOrder("desc");
                                                            props.history.push("/client/" + AccountTypeByFilter[val]);
                                                        }}
                                                        fullWidth
                                                >
                                                    {accountTypeDropDown.length &&
                                                            accountTypeDropDown.map((o: any) => [5, 8, 9, 14, 16].find(p => p === o.id) &&
                                                                    <MenuItem key={o.id} value={o.id}>
                                                                        <Checkbox checked={filter === o.id}/>
                                                                        <ListItemText primary={o.name}/>
                                                                    </MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {
                                                (filter === 5 || filter === 16) &&
                                                <Grid item lg={4} xs={12}>
                                                    <StatusTypes value={statusTypes} onChange={setStatusTypes}/>
                                                </Grid>
                                        }
                                        <Grid item lg={4} xs={12}>


                                            <SearchTextField
                                                    search={search}
                                                    label={"Search Clients"}
                                                    onChange={e => {
                                                        setPage(0);
                                                        setSearch(e.target.value);
                                                    }}
                                            />

                                        </Grid>
                                    </Grid>

                                </Grid>

                                {filter === 14 && !props.match.params.clientListId &&
                                        <Grid item xs={12}>
                                            <Stats/>
                                        </Grid>
                                }

                                <Grid item xs={12}>


                                    {filter === 14 && props.match.params.clientListId &&
                                            <Stats clientListId={props.match.params.clientListId}/>
                                    }


                                    <TableWrap>
                                        {filter === 14 && props.match.params.clientListId &&
                                                <ClientListParticipants
                                                        accountList={clientList}
                                                        history={props.history}
                                                        handleRequestSort={handleRequestSort}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        page={page}
                                                        rowsPerPage={rowsPerPage}
                                                />
                                        }
                                        {
                                                filter === 14 && !props.match.params.clientListId &&
                                                <ClientListTable accountList={clientList}
                                                                 order={order}
                                                                 orderBy={orderBy}
                                                                 handleRequestSort={handleRequestSort}
                                                                 handleClickOpen={(i: string) => setId(i)}
                                                                 history={props.history}
                                                                 page={page}
                                                                 rowsPerPage={rowsPerPage}

                                                />
                                        }
                                        {(filter === 5 || filter === 6) &&
                                                <IndividualTable
                                                        accountList={clientList}
                                                        callingFrom="clientManagementPage"
                                                        listType={props.match.params.companyId ? "" : "main"}
                                                        filter={filter}
                                                        handleClickOpen={(i: string) => setId(i)}
                                                        handleClickEdit={(i: string, role: number) => goToClientDetail(i)}
                                                        history={props.history}
                                                        callFrom="clientManagement"
                                                        handleClickReInvite={(id: string) => handleReInvite(id)}
                                                        handleRequestSort={handleRequestSort}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        handleRefresh={handleRefresh}
                                                        page={page}
                                                        rowsPerPage={rowsPerPage}

                                                        selected={selected}
                                                        setSelected={setSelected}
                                                />
                                        }
                                        {filter === 16 &&
                                                <AllParticipantTable
                                                        accountList={clientList}
                                                        callingFrom="clientManagementPage"
                                                        handleClickOpen={(i: string) => setId(i)}
                                                        handleClickEdit={(i: string, role: number) => goToClientDetail(i)}
                                                        history={props.history}
                                                        handleClickReInvite={(id: string) => handleReInvite(id)}
                                                        handleRequestSort={handleRequestSort}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        handleRefresh={handleRefresh}
                                                        page={page}
                                                        rowsPerPage={rowsPerPage}
                                                />
                                        }
                                        {
                                                filter === 8 &&
                                                <MultiLifeTable
                                                        accountList={clientList}
                                                        callingFrom="clientManagementPage"
                                                        handleClickOpen={(i: string) => setId(i)}
                                                        goToClientList={(id: number) => goToClientList(id)}
                                                        history={props.history}
                                                        handleRequestSort={handleRequestSort}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        handleRefresh={handleRefresh}
                                                        page={page}
                                                        rowsPerPage={rowsPerPage}
                                                        loggedInUserRole={auth.user.role}
                                                />
                                        }
                                        {
                                                filter === 9 &&
                                                <AssociationTable
                                                        accountList={clientList}
                                                        handleClickOpen={(i: string) => setId(i)}
                                                        goToClientList={(id: string) => goToParticipantList(id)}
                                                />
                                        }
                                        {
                                                filter === 10 &&
                                                <GroupLinkTable
                                                        handleClickOpen={(i: string) => setId(i)}
                                                        accountList={clientList}
                                                        history={props.history}
                                                        handleRequestSort={handleRequestSort}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        page={page}
                                                        rowsPerPage={rowsPerPage}
                                                />
                                        }

                                    </TableWrap>
                                    <Pagination page={page} rowsPerPage={rowsPerPage} setPage={setPage}
                                                setRowsPerPage={setRowsPerPage}
                                                accountList={clientList}/>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {
                        id &&
                        <DeleteModel
                                open={true}
                                handleClose={() => setId("")}
                                handleYes={deleteUser}
                                accountType={ucFirst(AccountTypeByFilter[filter])}
                                title={filter === 14 ? getDeletedUserName(id, true) : getDeletedUserName(id, false)}
                                content={`Delete ${ucFirst(AccountTypeByFilter[filter])} Currently Not Available`}
                                disableDelete={true}
                        />
                }
                <DeleteModel
                        open={isVisibleDeleteModal}
                        handleClose={() => setIsVisibleDeleteModal(false)}
                        handleYes={async () => {
                            setIsVisibleDeleteModal(false);
                            await accountActions.deleteAccounts(selected);
                            setSelected([]);
                            handleRefresh();
                        }}
                        accountType={ucFirst(AccountTypeByFilter[filter])}
                        title={`Confirm delete of ${selected.length} user/s`}
                        content={`Are you sure you want to delete ${selected.length} users permanently from ilia ?`}
                />
            </React.Fragment>
    );
}


const bulkActions = {
    none: "Select action",
    export: "Export selected",
    delete: "Delete selected",
};

export const BulkActions = ({ onApply }) => {
    const classes = useStyles();
    const [selectedAction, setSelectedAction] = React.useState("none");

    return (
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="bulk-actions" shrink>Bulk actions</InputLabel>
                        <Select
                                labelId="bulk-actions"
                                renderValue={selected => bulkActions[selectedAction]}
                                value={selectedAction}
                                onChange={e => {
                                    setSelectedAction(e.target.value as string);
                                }}
                                displayEmpty={true}
                        >
                            <MenuItem value="export">
                                <GetAppIcon/>
                                <span style={{ paddingLeft: "8px" }}>{bulkActions.export}</span>
                            </MenuItem>
                            <MenuItem value="delete" className={classes.warning}>
                                <DeleteOutlineIcon className="colorRed"/>
                                <span style={{ paddingLeft: "8px" }}>{bulkActions.delete}</span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={4}>
                    <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.applyBtn}
                            onClick={() => onApply(selectedAction)}>Apply</Button>
                </Grid>
            </Grid>
    );
};

const statusTypes = {
    "Invitations": [0, 1, 2],
    "Enrollment": [4, 15, 11, 12, 13, 16, 17, 18, 19, 20, 21, 22, 27],
};

type StatusTypesProps = {
    value: string[]
    onChange(newValue: string[]): void;
}

const StatusTypes = (props: StatusTypesProps) => {
    const { value, onChange } = props;

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as string[]);
    };

    const getMenuItems = (subheader) => {
        return [
            <ListSubheader disableSticky={true}>{subheader}</ListSubheader>,
            ...statusTypes[subheader].map(statusTypeIdx => (
                    <MenuItem value={statusTypeIdx} key={statusTypeIdx}>
                        <Checkbox checked={value.indexOf(statusTypeIdx) > -1}/>
                        <ListItemText primary={AccountStatusArr[statusTypeIdx]}/>
                    </MenuItem>
            )),
        ];
    };

    const displayStatus = (selected) => {
        if (selected.length === 0)
            return "All Status Types";

        return selected.map(statusTypeIdx => AccountStatusArr[statusTypeIdx]).join(", ");
    };

    return (
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel htmlFor="status-types" shrink>Status Types</InputLabel>
                <Select
                        multiple
                        labelId="status-types"
                        renderValue={displayStatus}
                        onChange={handleChange}
                        value={value}
                        displayEmpty={true}
                >
                    {
                        Object.keys(statusTypes).map(subheader => getMenuItems(subheader))
                    }
                </Select>
            </FormControl>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        minWidth: 800,
    },
    container: {
        maxHeight: 600,
    },
    warning: {
        color: "#ec1459",
    },
    applyBtn: {
        minWidth: 0,
        padding: "6px 18px",
    },
}));

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
    };
}

export const SearchTextField = ({ search, label, placeholder = "", onChange }) => (
        <TextField
                className="muiSelect mb10"
                variant="outlined"
                value={search}
                label={label}
                placeholder={placeholder}
                onChange={onChange}
                fullWidth
                InputProps={{
                    endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                        className="iconBtn"
                                        aria-label="search account"
                                >
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                    ),
                }}
        />
);


export default connect(mapStateToProps)(ClientPage);
