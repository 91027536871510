import React from "react";
import { useSelector } from "react-redux";
import RadioGroupCommon from "./RadioGroupCommon";
import { isLongForm } from "../../EnrollmentApplication/Utilities";
import { RootState } from "../../../../../../reducers";
import { isValidObject } from "../../../../../../utils/commonUtils";
import { GWT_GEN2_STRATEGY } from "../../../../../../constants";

type MaritalStatusProps = {
    disabled: boolean,
    values: any,
    conditionalRequired: boolean,
    touched: any,
    errors: any,
    classes: any,
    setFieldValue: Function,
    setIsMarried: Function,
    offerType:string
}
const householdIncomeArr = [
    "houseHoldIncomeLastYearAnnualSalary",
    "houseHoldIncomeLastYearDividends",
    "houseHoldIncomeLastYearOtherIncome",
    "houseHoldIncomeLastYearTotal",

    "houseHoldIncome1YearsPriorAnnualSalary",
    "houseHoldIncome1YearsPriorDividends",
    "houseHoldIncome1YearsPriorOtherIncome",
    "houseHoldIncome1YearsPriorTotal",

    "houseHoldIncome2YearsPriorAnnualSalary",
    "houseHoldIncome2YearsPriorDividends",
    "houseHoldIncome2YearsPriorOtherIncome",
    "houseHoldIncome2YearsPriorTotal",

    "houseHoldOtherIncomeDetail"];

function MaritalStatus(props: MaritalStatusProps) {
    const {conditionalRequired, disabled, values, touched, errors, classes, setFieldValue, setIsMarried, offerType} = props;
    const newLabel: string = isLongForm(offerType) ? "Are you married or have a domestic partner / civil union partner?" : "Are you married?" ;
    const radioOptionArr = [
        {
            label: "Yes",
            value: "Married",
        }, {label: "No", value: "Single"}
    ];
    const user: any = useSelector((state: RootState) => isValidObject(state.profile) ? state.profile : state.clientAccount);
    const isGWTGen2: boolean = !!(user && user.client && user.client.strategyId === GWT_GEN2_STRATEGY);

    const callBack = (e: any) => {
        if (e && e.target) {
            setFieldValue("marriageStatus", e.target.value);
            setIsMarried(e.target.value === "Married"); // this setstate func is used for validation purpose
            setFieldValue("usingSpouseIncome", isGWTGen2 ? "0" : "");
            setFieldValue("spouseIncome", "");

            if (!isGWTGen2) {
                setFieldValue("currentlyEmployed", "");
                setFieldValue("parentsAreIncomeEarner", "");
            }

            householdIncomeArr.forEach((item) => {
                setFieldValue(`${item}`, "");
            });
        }
    };
    return (
            <RadioGroupCommon
                    cb={callBack}
                    classes={classes}
                    conditionalRequired={conditionalRequired}
                    disabled={disabled}
                    errors={errors}
                    fieldName="marriageStatus"
                    label={newLabel}
                    radioOptionArr={radioOptionArr}
                    touched={touched}
                    values={values}
            />
    )
}

export default MaritalStatus;