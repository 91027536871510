import React, { useState } from "react";
import { FormHelperText, Grid } from "@material-ui/core";
import { TextField, NumberTextField } from "../../../../../components/formikFormInputs";
import { Field } from "formik";
import _ from "lodash";
import simplifiedQuestions from "../../../../../components/client/SimplifiedQuestions";
import SiQuestions from "../../../../../components/client/SiQuestions";
import SiToFullQuestions from "../../../../../components/client/SiToFullQuestions";
import GiQuestions from "../../../../../components/client/GiQuestions";
import GiToFullQuestions from "../../../../../components/client/GiToFullQuestions";
import fullQuestionsList from "../../../../../components/client/FullQuestions";
import allianzQuestions from "../../../../../components/client/AllianzQuestions";
import { EnrollmentQuestionProps } from "../../../../../model/enrollment";
import * as Actions from "../../../../../actions/enrollment";
import { useActions } from "../../../../../actions";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { CheckBoxGroup, Label } from "../../../../../components/formInputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import { arrayContainUndefined, getFloatString, numArray, pez } from "../../../../../utils/commonUtils";
import { REQUIRED_FIELD, selfAnnualIncomeMinValue } from "../../../../../constants";
import numeral from "numeral";
import KickOut from "../../component/EnrollmentHome/KickOut";
import * as AccountActions from "../../../../../actions/account";
import ApplicationQuestionnaireFormV2Content from './ApplicationQuestionnaireFormV2Content';
import {
    MedFieldType,
    medicalAlcoholQuestions,
    medicalMarijuanaQuestions,
    medicalNarcoticsQuestions,
    medicalQuestions,
    medicalTobaccoQuestions,
    NAME_IDENTIFIER,
    NOTA,
} from "./MedicalAndFinancial/component/MedicalPageQuestions";
import {isLongForm} from "./Utilities";
import { PIQ } from "./InsuredQuestions/InsuredQuestions";
import { useOfferType } from "../../../../../hooks/useOfferType";

const ApplicationQuestionnaireFormV2 = (props: {
    setNextStep: Function, setPrevStep: Function, carrierName: string, client: any, isFromClientDetail: boolean,
    enrollment?: any, setActionItem?: Function, setQuestionnaireRef?: Function, setIsQuestionFormDirty?: Function, carrierValAlert?: boolean, user? : any
    setIsSubmittedQuestions?:Function, errorInPhysicianAndMedicalDetails?: boolean
}) => {
    let {
        setNextStep,
        carrierName,
        client,
        isFromClientDetail,
        enrollment,
        setActionItem,
        setQuestionnaireRef,
        setIsQuestionFormDirty,
        carrierValAlert,
        user,
        setIsSubmittedQuestions, errorInPhysicianAndMedicalDetails
    } = props;
    let offerType = useOfferType();
    const enrollmentActions = useActions(Actions);
    const { updateClientFields, getFreshDataToRefreshReduxState } = useActions(AccountActions);
    const [enrollmentId, setEnrollmentId] = useState("");

    if(offerType === "consent"){
        offerType = "gi";
    }

    const [selectedOfferType, setSelectedOfferType] = useState(offerType);
    const [openKickOutPopup, setOpenKickOutPopup] = useState(false);
    const [saveRequest, sendSaveRequest] = React.useState<any>(Math.random());
    const applicationQuestionSubmit = React.useRef<any>();
    const applicationQuestions:any = React.useRef<any>(null);
    let { user: { role, participant } } = useSelector((state: RootState) => state.auth);
    const participantId:number = (participant && participant.id) || 0;
    let fullQuestions = simplifiedQuestions;

    let clientSigned: boolean = (enrollment && (enrollment.clientSigned || enrollment.envelopeId)) || false;
    let allianzQuestion:any = allianzQuestions({ offerType });
    const foundAq = allianzQuestion.filter((obj: any) => {
        return obj.show;
    });

    //Allianz Case
    if (carrierName === "Allianz Life") {
        fullQuestions = foundAq;
    } else if (selectedOfferType === "full") { //National Life - Full
        fullQuestions = fullQuestionsList;
    } else if (selectedOfferType === "si") { //National Life - si
        fullQuestions = SiQuestions;
    } else if (selectedOfferType === "si-to-full") { //National Life - si-to-full
        fullQuestions = SiToFullQuestions;
    } else if (selectedOfferType === "gi") { //National Life - gi
        fullQuestions = GiQuestions;
    } else if (selectedOfferType === "gi-to-full") { //National Life - gi-to-full
        fullQuestions = GiToFullQuestions;
    }

    const getInitialValues = (freshFullQuestions) => {
        let initialValues: any = {};
        if (freshFullQuestions && freshFullQuestions.length > 0) {
            for (let i = 0; i < freshFullQuestions.length; i++) {
                initialValues[freshFullQuestions[i].name] = "";
                if (freshFullQuestions[i].additionalFieldName && freshFullQuestions[i].additionalFieldType === "checkbox") {
                    initialValues[freshFullQuestions[i].additionalFieldName || ""] = [];
                }
            }
            initialValues["Proposed_Insured_Questions-1"] = client && client.usCitizen ? "Yes" : "No";
        }
        return initialValues;
    }

    /* SET Final Questions List */
    const [questionList, setQuestionList] = useState(fullQuestions);

    /* SET Questions Initial Answer */
    const [initialAnswer, setInitialAnswer] = useState(getInitialValues(fullQuestions));

    const validationAdded = (freshQuestionsList) => {
        /* Validations of fields set */
        let keysValidation = {};

        freshQuestionsList.forEach((field) => {
            if (!isFromClientDetail) {
                keysValidation[field.name] = Yup.string().trim().required("Required Field");
            }
            if (field.additionalFieldName && field.additionalFieldRequired) {
                let fieldName = (field && field.additionalFieldName) || "";

                if (isFromClientDetail) {
                    keysValidation[fieldName] = Yup.string().when(field.name, {
                        is: "Yes",
                        then: Yup.string().trim(),
                    });

                    /*In case of Question 10 - Validation will work when selected  "No" */
                    if (field.key === "10") {
                        keysValidation[fieldName] = Yup.string().when(field.name, {
                            is: "No",
                            then: Yup.string().trim(),
                        });
                    }
                    /*In case of CheckBox - Show Validation if not selected at least one */
                } else {
                    keysValidation[fieldName] = Yup.string().when(field.name, {
                        is: "Yes",
                        then: Yup.string().trim().required("Required Field"),
                    });

                    /*In case of Question 10 - Validation will work when selected  "No" */
                    if (field.key === "10") {
                        keysValidation[fieldName] = Yup.string().when(field.name, {
                            is: "No",
                            then: Yup.string().trim().required("Required Field"),
                        });
                    }
                    /*In case of CheckBox - Show Validation if not selected at least one */
                }
            }
        });
        return keysValidation;
    }

    const validationAddedAllianz = (questions:any) => {
        /* Validations of fields set */
        const {
            medicalQuestions,
            medicalTobaccoQuestions,
            medicalAlcoholQuestions,
            medicalMarijuanaQuestions,
            medicalNarcoticsQuestions,
        } = questions;
        let keysValidation:any = {};

        const isRadioDescriptionRequired = (arrayOption:any) => {
            let descReq:any = [];
            if(arrayOption && arrayOption.length > 0 ){
                descReq = _.filter(arrayOption, function(o:any) { return o.showDetailsBox });
            }
            return descReq;
        }

        const isCheckBoxDescriptionRequired = (arrayOption:any) => {
            let checkBoxHavingDescReq:any[] = [];
            arrayOption && arrayOption.length > 0 && arrayOption.map((v) => {
                if(v.showDetailsBox) {
                    checkBoxHavingDescReq.push(v.value)
                }
            });
            return checkBoxHavingDescReq;
        }

        const creteRequiredTestRenMedDetail = (fieldName: string, parentFieldName: string) => {
            return Yup.string().ensure().test(fieldName, REQUIRED_FIELD, function(v) {
                const parentVal = this.parent[parentFieldName] || [];
                const checkForRequired = (
                    parentVal &&
                    !!parentVal.length &&
                    parentVal.find((pv: any) => pv.value !== NOTA)
                );

                return (!!v && checkForRequired) || !checkForRequired;
            });
        }

        const getTypeNameAndAdditionalFields = (field: MedFieldType | any, useKey: boolean, index?: number) => {
            const type: string = field && field.type === "RADIOGROUP" ? "R" : "C";
            const identifier = useKey ? field.key : index;
            const name: string = `${NAME_IDENTIFIER}${type}_${identifier}`;
            const resArray: any[] = isRadioDescriptionRequired(field && field.additionalFieldOptions);

            return { name, resArray };
        };

        medicalQuestions.forEach((field: any, index: number) => {
            const { name, resArray } = getTypeNameAndAdditionalFields(field, false, index);

            keysValidation[name] = Yup.array().test(name, REQUIRED_FIELD, function(va) {
                let checkedArray:any = (va || []).map((v) => v.value)
                const hasAllElems = checkedArray.every(elem => resArray.includes(elem));
                if(checkedArray.length > 0) {
                    if (hasAllElems) {
                        const selectedDesc: any[] = this.parent[name].map((p) => p.desc)
                        if (arrayContainUndefined(selectedDesc)) {
                            return false
                        } else {
                            return true
                        }
                    } else {
                        return true
                    }
                } else {
                    return false;
                }
            });

            // validation for Render_Medical_Detail_fields_under_medical_questions_checkboxes
            numArray(2).forEach(fieldIndex => {
                const nameMedicalDetail =  `${NAME_IDENTIFIER}T_${index}_${fieldIndex}`;
                keysValidation[nameMedicalDetail] = creteRequiredTestRenMedDetail(nameMedicalDetail, name);
            });
        });

        medicalTobaccoQuestions.forEach((field: any) => {
            const { name, resArray } = getTypeNameAndAdditionalFields(field, true);

            if(field && field.required) {
                keysValidation[name] = Yup.object().test(name, REQUIRED_FIELD, function(va) {
                    const res = _.find(resArray, function(o:any) { return o.value === (va && va.value) })
                    /* if(Object.keys(res).length > 0 || Object.keys(va).length > 0) {
                        return true;
                    }*/
                    if ((va && va.value) === (res && res.value) && this.parent[`${PIQ}-99`] === "Yes") {
                        return this.parent[name].desc !== undefined;
                    }
                    return true;
                    //return !(((va && va.value) === (res && res.value)) && this.parent[name].desc === undefined);
                })
            } else {
                keysValidation[name] = Yup.object().when(`${PIQ}-99`, {
                    is: "Yes",
                    then: Yup.object().shape({
                        desc: Yup.string(),
                        value: Yup.string()
                    })
                });
            }
        });

        // common function for validation for allianz_questions_alcohol_marijuana_narcotics NIWC-2630
        const azMedicalValidation = (field: MedFieldType) => {
            const { name, resArray } = getTypeNameAndAdditionalFields(field, true);

            if(field && field.required) {
                keysValidation[name] = Yup.object().test(name, REQUIRED_FIELD, function(va) {
                    const res = _.find(resArray, function(o: any) {
                        return o.value === (va && va.value);
                    });

                    if ((va && va.value) === (res && res.value)) {
                            return this.parent[name] && this.parent[name].desc !== undefined;
                    }

                    return true;
                })
            }
        };

        medicalAlcoholQuestions.forEach(azMedicalValidation);

        medicalMarijuanaQuestions.forEach(azMedicalValidation);

        medicalNarcoticsQuestions.forEach(azMedicalValidation);

        // validation for top_other_allianz_questions
        if (isLongForm(offerType)) {
            foundAq.forEach((f: any) => {
                const fieldParentName: string = f.name;

                keysValidation[fieldParentName] = Yup.string().ensure().required(REQUIRED_FIELD);

                // validation for Render_Medical_Detail_fields_under_other_allianz_questions
                numArray(2).forEach(fieldIndex => {
                    const fieldParentNameDesc = `${fieldParentName}XT_${fieldIndex}`;

                    keysValidation[fieldParentNameDesc] = Yup.string().test(fieldParentNameDesc, REQUIRED_FIELD, function(val) {
                        const parentVal = this.parent[fieldParentName];
                        const checkIsRequired = parentVal && parentVal === 'Yes';
                        return (checkIsRequired && !!val) || !checkIsRequired;
                    });
                });
            });
        }

        keysValidation[`${PIQ}-99`] = Yup.string().required(REQUIRED_FIELD);

        return keysValidation;
    }

    const [keyValidationSchema, setKeyValidationSchema] = useState(validationAdded(fullQuestions));

    const [initValues, setInitValues] = useState({});

    React.useEffect(() => {
        if (applicationQuestionSubmit.current && typeof setQuestionnaireRef === "function") {
            setQuestionnaireRef(applicationQuestionSubmit);
        }
    }, [applicationQuestionSubmit.current]);

    React.useEffect(() => {
        async function getEnrollmentDetails() {
            let initialValues: any = {};
            let result;
            if (isFromClientDetail) {
                result = {
                    payload: enrollment,
                };
            } else {
                result = await enrollmentActions.getEnrollment();
            }
            if (result && result.payload) {
                setEnrollmentId(result.payload.id);
                let formValues = result.payload.answers ? JSON.parse(result.payload.answers) : null;
                if(offerType !== selectedOfferType && selectedOfferType === "si" && result.payload.oldAnswers){
                    formValues = JSON.parse(result.payload.oldAnswers);
                }
                clientSigned = result.payload.clientSigned ? true : false;
                if (formValues) {
                    questionList.map(x => {
                        initialValues[x.name] = formValues[x.name];
                        if (formValues[x.name] === "Yes") {
                            if (x.key === "10") {
                                x.showRemarks = false;
                            } else {
                                x.showRemarks = true;
                                x.additionalFieldRequired = x.additionalFieldName && formValues[x.additionalFieldName] ! === "" ? false : true;
                                if (x.additionalFieldName) {
                                    initialValues[x.additionalFieldName] = formValues[x.additionalFieldName];
                                }
                                if (x.additionalSubFieldName) {
                                    initialValues[x.additionalSubFieldName] = formValues[x.additionalSubFieldName];
                                }
                            }
                        }
                        if (formValues[x.name] === "No") {
                            if (x.key === "10") {
                                x.showRemarks = true;
                                x.additionalFieldRequired = x.additionalFieldName && formValues[x.additionalFieldName] ! === "" ? false : true;
                                if (x.additionalFieldName) {
                                    initialValues[x.additionalFieldName] = formValues[x.additionalFieldName];
                                }
                            } else {
                                x.showRemarks = false;
                            }
                        }
                        if (x.additionalFieldName && x.additionalFieldType === "checkbox") {
                            initialValues[x.additionalFieldName || ""] = formValues[x.additionalFieldName] && Array.isArray(formValues[x.additionalFieldName]) ? formValues[x.additionalFieldName] : [];
                        }
                        if (isLongForm(offerType)) {

                            // initial_value for Render_Medical_Detail_fields_under_other_allianz_questions
                            numArray(2).forEach(fieldIndex => {
                                const fieldNameDesc = `${x.name}XT_${fieldIndex}`;
                                initialValues[fieldNameDesc] = formValues[fieldNameDesc]
                            });
                        }
                        return x;
                    });
                    setQuestionList([...questionList]);
                    setKeyValidationSchema( validationAdded([...questionList]) );
                    initialValues["Proposed_Insured_Questions-1"] = client && client.usCitizen ? "Yes" : "No";
                    if (carrierName === "Allianz Life") {
                        // common function for setting initial_values
                        const medicalFieldsInitialValues = (field: any, useKey: boolean, index?: number) => {
                            const type: string = field && field.type === "RADIOGROUP" ? "R" : "C";
                            const defaultVal: any = field && field.type === "RADIOGROUP" ? {} : [];
                            const identifier = useKey ? field.key : index;
                            const name: string = `${NAME_IDENTIFIER}${type}_${identifier}`;

                            initialValues[name] = formValues[name] || defaultVal;
                        };

                        medicalQuestions.forEach((field: any, index: number) => {
                            medicalFieldsInitialValues(field, false, index);

                            numArray(2).forEach(fa => {
                                const nameMedicalDetail =  `${NAME_IDENTIFIER}T_${index}_${fa}`;
                                initialValues[nameMedicalDetail] = pez(formValues[nameMedicalDetail]);
                            });
                        });

                        initialValues["Proposed_Insured_Questions-99"] = formValues["Proposed_Insured_Questions-99"];
                        initialValues["Proposed_Insured_Questions-2"] = formValues["Proposed_Insured_Questions-2"];

                        medicalTobaccoQuestions.forEach((field: any) => {
                            medicalFieldsInitialValues(field, true);
                        });

                        medicalAlcoholQuestions.forEach((field: MedFieldType) => {
                            medicalFieldsInitialValues(field, true);
                        });

                        medicalMarijuanaQuestions.forEach((field: MedFieldType) => {
                            medicalFieldsInitialValues(field, true);
                        });

                        medicalNarcoticsQuestions.forEach((field: MedFieldType) => {
                            medicalFieldsInitialValues(field, true);
                        });

                        /*Task NIWC-2335 */
                    }
                    setInitialAnswer(initialValues);
                }
            }
        }
        getEnrollmentDetails();
    }, []);

    React.useEffect(() => {
        if(selectedOfferType === "gi-to-full" || selectedOfferType === "si-to-full"){
            setQuestionList(fullQuestions);
            setKeyValidationSchema( validationAdded(fullQuestions) );
            setTimeout(()=> {
                setInitialAnswer({...getInitialValues(fullQuestions), ...initValues});
            }, 500);
        }
    }, [selectedOfferType]);

    React.useEffect(() => {
        if (applicationQuestions && applicationQuestions.current && applicationQuestions.current.dirty) {
            saveMe(applicationQuestions && applicationQuestions.current && applicationQuestions.current.values);
        }
    }, [saveRequest]);

    const changeOfferType = async (expectedOfferType:string, values:any, oldValues:any=null) => {
        setSelectedOfferType(expectedOfferType);
        setInitValues(values);
        const result = await enrollmentActions.updateEnrollment({
            ...values,
            id: enrollmentId,
            userId: enrollment && enrollment.userId ? enrollment.userId : client.userId,
            questions: (role === 1 || role === 2 || role === 4) && !carrierValAlert ? true : false,
        });
        await updateClientFields({
            underWritingOfferTypeFinal : expectedOfferType
        }, (client && client.id) || "", isFromClientDetail);

        let enrollmentPayload:any = {
            id: enrollmentId,
            enrollmentStatus : 1,
            enrollmentSubStatus: 1,
            version: "v2",
        }
        if(expectedOfferType === "si-to-full" && oldValues){
            enrollmentPayload.oldAnswers = oldValues;
        }
        await enrollmentActions.updateEnrollment(enrollmentPayload);
        getFreshDataToRefreshReduxState();
    }

    const minAmountValidate = (val) => {
        if (val && (Number(getFloatString(val + "")) < selfAnnualIncomeMinValue)) {
            return "Must have an annual income of over " + numeral(selfAnnualIncomeMinValue).format("$0,0") + ". If under, please contact your agent";
        }
    };

    const validationSchema = Yup.object().shape(keyValidationSchema);


    const checkIsError = (subFieldValue, fieldValue, value) => {
        if (value === "Yes" && fieldValue && fieldValue.length > 0 && !subFieldValue) {
            return true;
        }
        return false;
    };

    const onChangeOperationForQuestionAns = (value:any, values, question:EnrollmentQuestionProps) => {
        if (offerType === "ilia_app") { // NIWC-2274 : in case of underWritingOfferType: ilia_app (Note: ilia_app has changed to full)
            return false;
        }
        if(offerType === "gi"){
            if((question.name === "Proposed_Insured_Questions-10" && value === "No") || (question.name === "Proposed_Insured_Questions-26" && value === "Yes")){
               // setOpenKickOutPopup(true);
                return false;
            }
        }
        if(offerType === "si"){
            if(Number(question.key) !== 10 && Number(question.key) >= 8 && Number(question.key) <= 25 && value === "Yes"){
               // setOpenKickOutPopup(true);
                return false;
            }
            if(Number(question.key) === 10 && value === "No"){
               // setOpenKickOutPopup(true);
                return false;
            }
        }

        //When gi-to-full replace with gi and si-to-full replace with si in initial stage
        if(offerType !== selectedOfferType){
            if(question.name === "Proposed_Insured_Questions-10"){
                if (value === "No") {
                    changeOfferType(offerType, {...values, [question.name] : value}, values);
                }
            } else if (value === "Yes"){
                changeOfferType(offerType, {...values, [question.name] : value}, values);
            }
        } else if(offerType === "gi-to-full" && offerType === selectedOfferType){
            if(question.name === "Proposed_Insured_Questions-10" && value === "Yes" && values["Proposed_Insured_Questions-26"] === "No"){
                changeOfferType("gi", {...values, [question.name] : value}, values);
            }
            if(question.name === "Proposed_Insured_Questions-26" && value === "No" && values["Proposed_Insured_Questions-10"] === "Yes"){
                changeOfferType("gi", {...values, [question.name] : value}, values);
            }
        } else if(offerType === "si-to-full" && offerType === selectedOfferType){
            let revertFullToSi:boolean = true;
            for(let qKey = 8; qKey <= 25; qKey++){
                if(Number(question.key) === qKey){
                    continue;
                }
                if(qKey !== 10 && values["Proposed_Insured_Questions-"+qKey] === "Yes"){
                    revertFullToSi = false;
                    return false;
                }
                if(qKey === 10 && values["Proposed_Insured_Questions-"+qKey] === "No"){
                    revertFullToSi = false;
                    return false;
                }
            }
            if(Number(question.key) !== 10 && Number(question.key) >= 8 && Number(question.key) <= 25 && value === "Yes"){
                revertFullToSi = false;
                return false;
            }
            if(Number(question.key) === 10 && value === "No"){
                revertFullToSi = false;
                return false;
            }
            if(Number(question.key) >= 8 && Number(question.key) <= 25 && revertFullToSi){
                changeOfferType("si", {...values, [question.name] : value}, values);
            }
        }
    }

    /*To render Sub Fields*/
    const showComponent = (question: EnrollmentQuestionProps, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned) => {
        //In case of Question no 10 - Sub field will Show only if selected "NO"
        if (question.key === "10" && (values[question.name] === "Yes" || values[question.name] == undefined || values[question.name] == "")) {
            return false;
        }
        if (question.key !== "10" && (values[question.name] === "No" || values[question.name] == undefined || values[question.name] == "")) {
            return false;
        }
        switch (question.additionalFieldType) {
            case "checkbox":
                return (
                        <Grid item xs={12}>
                            <div className="mt30">
                                <Field fullWidth name={question.additionalFieldName}
                                       label={question.additionalFieldLabel}
                                       component={CheckBoxGroup}
                                       disabled={clientSigned ? true : false}
                                       meta={{
                                           error: isFromClientDetail ? false : (errors[(question && question.additionalFieldName) || ""] ? true : false),
                                           touched: isFromClientDetail ? false : (touched[(question && question.additionalFieldName) || ""] ? true : false),
                                       }}
                                       input={{
                                           name: question.additionalFieldName,
                                           value: values[(question && question.additionalFieldName) || ""],
                                           onChange: (value: any) => {
                                               setFieldTouched(question.additionalFieldName);
                                               setFieldValue(question.additionalFieldName, value);
                                           },
                                           style: { marginBottom: 0, display: "block" },
                                       }}
                                       options={question.additionalFieldOptions}/>

                                {!isFromClientDetail && values[(question && question.additionalFieldName) || ""] && values[(question && question.additionalFieldName) || ""].length == 0 &&
                                <FormHelperText className={"Mui-error"}> At least one checkbox required </FormHelperText>
                                }
                                {question.additionalSubFieldType && question.additionalSubFieldName && values[(question && question.additionalFieldName) || ""] && values[(question && question.additionalFieldName) || ""].length > 0 &&
                                <>
                                    <div className="floatLeft w100">
                                        <Label label={question && question.additionalFieldLabel || ""}/>
                                    </div>
                                    <Field
                                            fullWidth
                                            name={question.additionalSubFieldName}
                                            component={TextField}
                                            value={values[question && question.additionalSubFieldName || ""]}
                                            error={isFromClientDetail ? false : (errors[(question && question.additionalSubFieldName) || ""] ? true : checkIsError(values[question.additionalSubFieldName], values[(question && question.additionalFieldName) || ""], values[(question && question.name) || ""]))}
                                            helperText={isFromClientDetail ? "" : (errors[(question && question.additionalSubFieldName) || ""] ? errors[(question && question.additionalSubFieldName) || ""] : (checkIsError(values[question.additionalSubFieldName], values[(question && question.additionalFieldName) || ""], values[(question && question.name) || ""]) ? "Required" : ""))}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={question.additionalSubFieldLabel}
                                            disabled={clientSigned ? true : false}
                                            variant="outlined"
                                    />
                                </>
                                }
                            </div>
                        </Grid>
                );
            case "multiIncome":
                return <Grid item xs={12}>
                    <div className="mt30">
                    {question.additionalFields && question.additionalFields.map((value, index: any) => {
                        return (<Field fullWidth name={value}
                                       label={question.additionalFieldLabel && question.additionalFieldLabel.replace(":count", index + 1)}
                                       validate={question.additionalFieldMinValue && [minAmountValidate]}
                                       component={NumberTextField}
                                       InputProps={{
                                           startAdornment: <InputAdornment
                                                   position="start"><strong>$</strong></InputAdornment>,
                                       }}
                                       placeholder={question.additionalFieldLabel && question.additionalFieldLabel.replace(":count", index + 1)}
                        />);

                    })
                    }
                    </div>
                </Grid>
            default:
                return (<Grid item xs={12}>
                    <div className={`mt30 ${!!question.subQuestionLi ? "ml15" : ""}`}>
                    <div className="floatLeft w100 f12">
                        <Label label={(question && question.additionalFieldLabel) || ""} required={true}/>
                    </div>
                    <Field
                            fullWidth
                            name={question.additionalFieldName}
                            component={TextField}
                            value={values[(question && question.additionalFieldName) || ""]}
                            error={isFromClientDetail ? false : errors[(question && question.additionalFieldName) || ""] ? true : false}
                            helperText={isFromClientDetail ? "" : errors[(question && question.additionalFieldName) || ""] ? errors[(question && question.additionalFieldName) || ""] : ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={question.additionalFieldRequired && question.additionalFieldLabel ? question.additionalFieldLabel : "Provide Details"}
                            disabled={clientSigned ? true : false}
                            variant="outlined"
                            validate={(value:string)=>{
                                if (isFromClientDetail){
                                    return "";
                                }
                                if(!value && values[question.name] === "Yes" && question.name !== "Proposed_Insured_Questions-10"){
                                    return "Required Field";
                                }
                                if(!value && values[question.name] === "No" && question.name === "Proposed_Insured_Questions-10"){
                                    return "Required Field";
                                }
                                return "";
                            }}
                    />
                    </div></Grid>);
        }
    };

    let kickOutDetail = {
        groupAdminEmail: client && client.agent && client.agent.user && client.agent.user.email || "",
        groupAdminPhone : client && client.agent && client.agent.user && client.agent.user.phoneNumber || "",
        groupAdminFullName : client && client.agent && client.agent.user && client.agent.user.firstName + ' ' + client.agent.user.lastName || "",
        strategyName : client && client.strategy && client.strategy.name || ""
    }

    if(role === 6){
        kickOutDetail = {
            groupAdminEmail: client && client.company && client.company.email || "",
            groupAdminPhone : client && client.company && client.company.phone || "",
            groupAdminFullName : client && client.company && client.company.companyName || "",
            strategyName : client && client.strategy && client.strategy.name || ""
        }
    }

    const applicationQuestionPayload = async (values:any, showMessage:boolean = true) => {
        if(!!enrollmentId) {
            let payload: any = {
                ...values,
                id: enrollmentId,
                userId: enrollment && enrollment.userId ? enrollment.userId : client.userId,
                questions: (role === 1 || role === 2 || role === 4) && !carrierValAlert ? true : false,
            }
            const result = await enrollmentActions.updateEnrollment(payload, showMessage);
            if (result && result.payload && result.payload.id && isFromClientDetail) {
                setEnrollmentId(result.payload.id);
            }
            if (typeof setActionItem === "function" && isFromClientDetail) {
                setActionItem((role === 1 || role === 2 || role === 4) ? true
                        :
                        (user && user.enrollment && user.enrollment.clientSigned && user.client && user.client.usCitizen ? true : false));
            }
            if(typeof setIsSubmittedQuestions === 'function'){
                setIsSubmittedQuestions(false);
            }
            return true;
        }
    }

    const saveMe = (values:any) => {
            if(offerType === "gi"){
                if(values["Proposed_Insured_Questions-10"] === "No" || values["Proposed_Insured_Questions-26"] === "Yes"){
                    setOpenKickOutPopup(true);
                    return false;
                }
            }
            if(offerType === "si"){
                for(let qKey = 8; qKey <= 25; qKey++){
                    if(qKey !== 10 && values["Proposed_Insured_Questions-"+qKey] === "Yes"){
                        setOpenKickOutPopup(true);
                        return false;
                    }
                    if(qKey === 10 && values["Proposed_Insured_Questions-"+qKey] === "No"){
                        setOpenKickOutPopup(true);
                        return false;
                    }
                }
            }
            setInitialAnswer(values);
            applicationQuestionPayload(values, false).then(()=>{});
    }

    const validationSchemaAllianz = Yup.object().shape(
            validationAddedAllianz({
                medicalQuestions,
                medicalTobaccoQuestions,
                medicalAlcoholQuestions,
                medicalMarijuanaQuestions,
                medicalNarcoticsQuestions,
            }),
    );
    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <KickOut participantId={participantId} isFromClientDetail={isFromClientDetail} openKickOut={openKickOutPopup} setOpenKickOut={() => {
                        setOpenKickOutPopup(!openKickOutPopup)
                    }} {...kickOutDetail} />
                    <ApplicationQuestionnaireFormV2Content
                            initialAnswer={initialAnswer}
                            validationSchema={isLongForm(offerType) ? validationSchemaAllianz: validationSchema}
                            offerType={offerType}
                            setOpenKickOutPopup={setOpenKickOutPopup}
                            questionList={questionList}
                            isFromClientDetail={isFromClientDetail}
                            setNextStep={setNextStep}
                            setInitialAnswer={setInitialAnswer}
                            enrollmentActions={enrollmentActions}
                            setIsQuestionFormDirty={setIsQuestionFormDirty}
                            role={role}
                            applicationQuestionSubmit={applicationQuestionSubmit}
                            clientSigned={clientSigned}
                            enrollmentId={enrollmentId} enrollment={enrollment} client={client}
                            checkIsError={checkIsError} showComponent={showComponent} carrierValAlert={carrierValAlert}
                            setEnrollmentId={setEnrollmentId}
                            setActionItem={setActionItem}
                            onChangeOperationForQuestionAns={onChangeOperationForQuestionAns}
                            user={user}
                            applicationQuestions={applicationQuestions}
                            applicationQuestionPayload={applicationQuestionPayload}
                            sendSaveRequest={sendSaveRequest}
                            carrierName={carrierName}
                            errorInPhysicianAndMedicalDetails={errorInPhysicianAndMedicalDetails}
                    />
                </Grid>
            </Grid>
    );
};

export default ApplicationQuestionnaireFormV2;