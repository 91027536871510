import React, {useState} from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import {useActions} from '../../../actions';
import ClientDocments from "../ClientDocumentsTable";
import * as Aws from "../../../actions/aws";
import * as AccountActions from "../../../actions/account";
import {repeatCaller} from "../../../utils/commonFunc";
import {va} from "../../../utils/commonUtils";

interface UploadedDocumentTableProps {
    enrollmentId: string,
    onfetchFiles?: Function,
    setHasReports: Function,
    reportsLoading: boolean,
    userId: string | number,
}

let isReportsLoadingRunOnce = false;

export const UploadedDocumentTable = (props: UploadedDocumentTableProps) => {
    const {enrollmentId, onfetchFiles, setHasReports, reportsLoading, userId} = props;
    
    const [reportData, setReportData] = React.useState<any>([]);
    const [dataLoading, setDataLoading] = useState(false);
    
    const awsActions = useActions(Aws);
    const accountActions = useActions(AccountActions);
    
    const isNoReportExist = !reportData.rows || reportData.rows.length === 0;
    const [DOCS_RELOAD_MILLI_SECONDS, DOCS_RELOAD_RETRYS] = [5000, 3];

    const fetchDocumentsProcess = async () => {
        setDataLoading(true);
        const reports = await accountActions.getClientDocumentByTypes(userId, ['ADMIN_PROPOSAL']);

        setReportData(reports);
        setHasReports(!!va(reports.rows));
        onfetchFiles && onfetchFiles(reports.rows);
        setDataLoading(false);
    };

    // To repeat process 
    const fetchDocumentsRepeating = repeatCaller(
            fetchDocumentsProcess,
            DOCS_RELOAD_MILLI_SECONDS,
            DOCS_RELOAD_RETRYS,
            () => {
                setDataLoading(false);
            }
    );
    // To repeat process 
    const getEstimationFiles = async () => {
        fetchDocumentsProcess().then();
    };
    const getSignedUrl = async (objData: any) => {
        const {path, bucket, name} = objData;
        let directory = enrollmentId;
        let fileName = path;
        let file = fileName.split('.');
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ('text/pdf') : ("image/jpg, image/jpeg, image/png, application/pdf");
        let queryObj = {
            objectName: fileName,
            contentType: contentType,
            bucketName: bucket
        };
        let pdfFile = await awsActions.getSecureUrl(directory, queryObj, true);
        if (!!pdfFile) {
            showPdfFile(pdfFile);
        }
    };

    React.useEffect(() => {
        if (isReportsLoadingRunOnce && !reportsLoading) {
            // on complete creating report process
            fetchDocumentsRepeating();
            isReportsLoadingRunOnce = false;
        } else if (reportsLoading) {
            // on start creating report process
            isReportsLoadingRunOnce = true;
        } else {
            // all other cases
            getEstimationFiles().then();
        }

    }, [reportsLoading]);

    const showPdfFile = (pdfurl: string) => {
        window.open(pdfurl);
    };

    if (isNoReportExist) {
        return <></>
    }
    
    return (
            <Grid container spacing={2} className="mt10">
                {
                    (reportsLoading || dataLoading) &&
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                }
                <Grid item xs={12}>
                    <ClientDocments
                            onClickDocument={(_: number, data: any) => getSignedUrl(data)}
                            onClickDownload={(_: number, data: any) => getSignedUrl(data)}
                            reportData={reportData && reportData.rows}
                            showDownloadOption={true}
                    />
                </Grid>
            </Grid>
    )
}

export default UploadedDocumentTable;