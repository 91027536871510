import React from 'react';
import { Button, Grid } from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";

function ResolvedFor(props:{setEditLifeTimeDeathBenefitAmount:Function, setEditCalculationInputs:Function, resolveFor:string|null, resolveForBtn:string, callingFrom?: string})  {
    const {setEditLifeTimeDeathBenefitAmount, setEditCalculationInputs, resolveFor, resolveForBtn, callingFrom} = props;
    return <Grid item xs={12} md={callingFrom === "ESTIMATOR" ? 6 : 12}>
        <Button
            color="primary"
            size="small"
            variant="outlined"
            startIcon={<BorderColorIcon/>}
            onClick={() => {
                if (resolveFor === "Contribution_Amount") {
                    setEditLifeTimeDeathBenefitAmount(true);
                } else if (resolveFor === "Death_Benefit") {
                    setEditCalculationInputs(true);
                } else {
                    setEditLifeTimeDeathBenefitAmount(false);
                    setEditCalculationInputs(false);
                }
            }}
        >
            {resolveForBtn}
        </Button>
    </Grid>

}

export default ResolvedFor;