import * as React from "react";
import { Formik} from "formik";
import { Grid, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import numeral from "numeral";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import EstimationFormContext from "../Context/EstimationFormContext";
import StepperButton from "./StepperButtons";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/estimation";
import * as EstimatorActions from "../../../../../actions/estimator";
import * as TrancheActions from "../../../../../actions/tranche";
import { getDateDiffInYear } from "../../../../../utils/dateUtils";
import { ContributionAmount as contributionPayload } from "../ContributionAmount";
import { DeathBenefit as deathBenefitPayload } from "../DeathBenefit";
import { RootState } from "../../../../../reducers";
import {
    webHookURL,
    numberToThousandSeparator,
    getMinimumDesireLifeTimeBenefits
} from "../Steppers";
import { maskCurrency, getCarrierCode, isDebug } from "../../../../../utils/commonUtils";
import {
    AllianzLifeCarrierDetail,
    ESTIMATE_MIN_AGE,
    ESTIMATE_MAX_AGE,
} from "../../../../../constants";
import FormikTokenUpdate from "./FormikTokenUpdate";
import {useCheckAtlantic} from "../../../../../hooks/useAuth";
import {
    addPlus,
    allocationMapper, ALZInterestPayload, calculateGrowthRate,
    InvestmentValues, isALGCarrier,
    stressAllocationForALZ,
    stressAllocationsData,
} from "../../utils/utils";
import { useState } from "react";
import { useCreateAllocationPayLoad } from "../../Hooks/Hooks";
import { MONTHLY } from "./ContributionFrequency";
import { showEmployerContribution } from "./StepTwo";

let values = {
    dob: null,
    gender: "",
    healthType: "",
    tobacco: "",
    primaryFocusPlan: "",
    annualContribution: "",
    deathBenefitCalculationOptions: "",
    desireLifeTimeBenefits: "",
    totalContribution: "",
    beginDistributions: "65",
    endDistributions: "90",
    issueAge: "",
    trancheNo: "",
    firstName: "",
    lastName: "",
    contributionFrequency: "Annual",
    carrier: "NLG",
    carrierId: 2,
    totalCombinedContribution: "",
    employerContribution: ""
};

type FormValuesType = Partial<{
    id: string,
    clientType: string,
    annualIncome: string,
    primaryCarrier: number,
    strategyId: number,
    underWritingOfferType: string,
    firstName: string,
    lastName: string,
    dob: any,
    gender: number,
    agentName: string
}>

interface SubmitEstimationFormikProps {
    values: any,
    user: any,
    role: number | undefined,
    setIsEstimationDataLoading: Function,
    userId: string | number | undefined,
    setRequestId: Function,
    userName: string,
    agentName: string,
    issueAge: number | null,
    estimatorData: any,
    estimationActions: any,
    setEditLifeTimeDeathBenefitAmount?:Function,
    callingFrom:string|undefined,
    atlantic: boolean
    createAllocationPayLoad?: Function
}



const sendUpdatedEstimationRequest = async (props:any, type:string, userId: string | number | undefined) => {
    const { estimationActions, user, values, setRequestId, setEditLifeTimeDeathBenefitAmount, setIsEstimationDataLoading, callingFrom, createAllocationPayLoad } = props;
    let role:number|undefined|null = user && user.role;
    const carrierId = user.accessType === "client" ? user.demoCarrierId : user.client.carrier.id;
    if(userId) {
        let estimationDetails: any = await estimationActions.getEstimationDetailsByUserId(userId, callingFrom);
        let webHookUrl:string = `${webHookURL}/${userId}?callingFrom=${callingFrom}`;
        if (estimationDetails && estimationDetails.id) {
            let requestPayload: any = JSON.parse(estimationDetails && estimationDetails.stressRequest);
            const uuid = uuidv4();
            setRequestId(uuid);
            requestPayload["id"] = uuid;
            requestPayload["webhook"] = webHookUrl;
            requestPayload["InvestmentType"] = addPlus(values["plus"],values["InvestmentType"]);
            let contributionAmount = numeral(values && values.annualContribution).value();
            if(!!values.totalCombinedContribution){
                contributionAmount = values.totalCombinedContribution
            }else{
                contributionAmount = values["contributionFrequency"] === "Monthly" ? contributionAmount * 12 : contributionAmount;
            }
            switch (type) {
                case "LIFE_TIME_DEATH_BENEFIT":
                    requestPayload["common"]["totaldeathbenefit"] = numeral(values && values.desireLifeTimeBenefits).value();
                    break
                case "ANNUAL_CONTRIBUTION":
                    requestPayload["common"]["incomeagefrom"] = values && values.beginDistributions;
                    requestPayload["common"]["incomeageto"] = values && values.endDistributions;
                    requestPayload["common"]["contributionamount"] = contributionAmount;
                    requestPayload["common"]["paymentfrequency"] = values && values.contributionFrequency || "Annual";
                    requestPayload["common"]["paymentfrequencyemployer"] = "Annual";
                    requestPayload["common"]["contributionamountemployer"] = !!values.employerContribution ? numeral(values.employerContribution).value() : 0;
                    break
                default:
                    break;
            }
            // need to change this condition in upddate as well
            if(createAllocationPayLoad){
                const allocationData = await createAllocationPayLoad(addPlus(values["plus"], values["InvestmentType"]))
                const allocations = allocationData[0]["allocations"]
                isDebug() && console.log(allocations, "=== calculateGrowthRate")
                const growthRate =  calculateGrowthRate(allocations);
                if(growthRate){
                    requestPayload["common"]["growthrate"] = growthRate/100;
                    requestPayload["common"]["distributionloanrate"] = growthRate - 0.50;
                }
                requestPayload["illustration"]["interestrates"] =  allocationData;
                requestPayload["comparison"]["interestrates"] =  allocationData;
                requestPayload["stress"]["interestrates"] = isALGCarrier(carrierId) ? stressAllocationForALZ : stressAllocationsData;
            }
            await estimationActions.getEstimatesFromCloud(requestPayload, role, {}, userId, callingFrom);
            if(typeof setIsEstimationDataLoading === 'function'){setIsEstimationDataLoading(true)}
        }
    }
    if(type === "LIFE_TIME_DEATH_BENEFIT" && typeof setEditLifeTimeDeathBenefitAmount === 'function'){
        setEditLifeTimeDeathBenefitAmount(false);
    }
}

export const submitEstimationFormik = async (
        props: SubmitEstimationFormikProps, update = false, formType:string = ""
) => {

    /*
    when we have death benefit, send 0 for contributionamount, and run.solvefor = Contribution_Amount
    When we have contribution amount, send 1000000 for totaldeathbenefit and run.solvefor = Death_Benefit
    */
    const { values, role, setIsEstimationDataLoading, userId, setRequestId, userName, agentName, issueAge, estimatorData, estimationActions, callingFrom, user, atlantic, createAllocationPayLoad} = props;

    const carrierId = user.accessType === "client" ? user.demoCarrierId : user.client.carrier.id;
    const carrierCode = getCarrierCode(carrierId);
    console.log("<===== Carrier Code ====>", carrierCode);
    if(update) {
        await sendUpdatedEstimationRequest({ ...props }, formType, userId);
        return;
    }
    const fullName = (callingFrom === "CUSTOM_DESIGN" ? values.firstName + " " + values.lastName : userName);
    const uuid = uuidv4();
    setIsEstimationDataLoading(true);
    let webHookUrl:string = `${webHookURL}/${userId}?callingFrom=${callingFrom}`;
    setRequestId(uuid);
    let healthType: string = values && values.healthType || "Standard";
    let tobaccoCons: string = values && values.tobacco === "used" ? "Tobacco" : "NT";
    let rateClass: string = `${healthType}_${tobaccoCons}`;
    let payload: any = {};
    if (values && values.primaryFocusPlan === "deathBenefit") {
        if (values && values.deathBenefitCalculationOptions === "lifeTimeDeathBenefit") {
            payload = { ...contributionPayload };
            payload["common"]["contributionamount"] = 0;
            payload["common"]["totaldeathbenefit"] = numeral(values && values.desireLifeTimeBenefits).value();
        } else if (values && values.deathBenefitCalculationOptions === "amountToContribute") {
            let contributionAmount = !!values.totalCombinedContribution ? numeral(values && values.totalCombinedContribution).value() : numeral(values && values.totalContribution).value();
            if (values["contributionFrequency"] === "Monthly" && !atlantic)  {
                contributionAmount = contributionAmount*12;
            }

            payload = { ...deathBenefitPayload };
            payload['run']['deathbenefitonly'] = true;
            // if(values && values.issueAge && numeral(values.issueAge).value() > 50){
            //     payload["common"]["incomeagefrom"] = numeral(values.issueAge).value() + 15;
            // }
            payload["common"]["contributionamount"] = contributionAmount;
            payload["common"]["contributionamountemployer"] = !!values.employerContribution ? numeral(values.employerContribution).value() : 0;
            payload["common"]["totaldeathbenefit"] = 1000000;
        }
    }

    if (values && values.primaryFocusPlan === "contributionAmount") {
        let contributionAmount = !!values.totalCombinedContribution ? numeral(values && values.totalCombinedContribution).value() : numeral(values && values.annualContribution).value();
        //Handling atlantic special case
        if (values["contributionFrequency"] === "Monthly" && !atlantic)  {
            contributionAmount = contributionAmount*12;
        }
        payload = {...deathBenefitPayload};
        payload['run']['deathbenefitonly'] = false;
        payload["common"]["incomeagefrom"] = values && values.beginDistributions;
        payload["common"]["incomeageto"] = values && values.endDistributions;
        payload["common"]["contributionamount"] = contributionAmount;
        payload["common"]["totaldeathbenefit"] = 1000000;
        payload["common"]["contributionamountemployer"] = !!values.employerContribution ? numeral(values.employerContribution).value() : 0;
    }
    payload["id"] = uuid;
    payload["InvestmentType"] = addPlus(values["plus"],values["InvestmentType"]);
    payload["webhook"] = webHookUrl;
    payload["common"]["fullname"] = fullName;
    payload["common"]["gender"] = (values && values.gender === 1) ? "Male" : "Female";
    payload["common"]["agent"]["fullname"] = agentName;
    payload["common"]["issueage"] = issueAge;
    payload["common"]["rateclass"] = rateClass; // Possible Values 'Elite_NT' | 'Preferred_NT' | 'Select_NT' | 'Standard_NT' | 'Express_Standard_NT1' | 'Express_Standard_NT2' | 'Preferred_Tobacco' | 'Standard_Tobacco' | 'Express_Standard_Tobacco';
    payload["common"]["dateofbirth"] = moment(values.dob).format("MM/DD/YYYY");
    /* Update estimator data on payload. Note: Estimator Data is provided by db */
    if (estimatorData) {
        let commonGrowthRate = estimatorData["growthRate"] || payload["common"]["growthrate"];
        let growthRate = (commonGrowthRate * 100).toFixed(2);
        payload["stress"]["loanassumptions"]["assumptions"] = estimatorData["stressLoanRates"].split(",") || payload["stress"]["loanassumptions"]["assumptions"];
        payload["illustration"]["loanassumptions"]["assumptions"] = estimatorData["illustrationLoanRates"].split(",") || payload["illustration"]["loanassumptions"]["assumptions"];
        payload["common"]["growthrate"] = commonGrowthRate;
        payload["common"]["capitalgainstaxrate"] = estimatorData["capitalGainRate"] || payload["common"]["capitalgainstaxrate"];
        payload["common"]["managementfee"] = estimatorData["managementFee"] || payload["common"]["managementfee"];
        payload["common"]["statetaxrate"] = estimatorData["averageStateIncomeTaxRate"] || payload["common"]["statetaxrate"];
        payload["common"]["distributionloanrate"] = estimatorData["distributionLoanRate"] || payload["common"]["distributionloanrate"];
        payload["common"]["afrrate"] = estimatorData["afrRate"] || payload["common"]["afrrate"];
        payload["common"]["arrangementfee"] = estimatorData["arrangementFee"] || payload["common"]["arrangementfee"];
        payload["stress"]["loanassumptions"]["discountcashvaluelowpoint"] = estimatorData["stressTestCashValueLowPoint"] ||  payload["stress"]["loanassumptions"]["discountcashvaluelowpoint"];
        payload["illustration"]["loanassumptions"]["discountcashvaluelowpoint"] = estimatorData["illustrationTestCashValueLowPoint"] ||  payload["illustration"]["loanassumptions"]["discountcashvaluelowpoint"];
        // if(isALGCarrier(carrierId)){
        //     const allocations = ALZInterestPayload[0]["allocations"]
        //     const growthALZ = calculateGrowthRate(allocations)
        //     payload["common"]["growthrate"] = growthALZ/100;
        //     payload["common"]["distributionloanrate"] = growthALZ - 0.50;
        //     payload["illustration"]["interestrates"] =  ALZInterestPayload;
        //     payload["comparison"]["interestrates"] =  ALZInterestPayload;
        //     payload["stress"]["interestrates"] =  stressAllocationForALZ;
        // }else{
            if(createAllocationPayLoad){
                const allocationData = await createAllocationPayLoad(addPlus(values["plus"], values["InvestmentType"]));
                const allocations = allocationData[0]["allocations"]
                const growthRate =  calculateGrowthRate(allocations);
                if(growthRate){
                    payload["common"]["growthrate"] = growthRate/100;
                    payload["common"]["distributionloanrate"] = growthRate - 0.50;
                }
                payload["illustration"]["interestrates"] =  allocationData;
                payload["comparison"]["interestrates"] =  allocationData;
                payload["stress"]["interestrates"] = isALGCarrier(carrierId) ? stressAllocationForALZ : stressAllocationsData;
            }
        // }
        payload["stress"]["loanassumptions"]["bankdiscountfirstyear"] = estimatorData["stressBankDiscountFirstYear"] || payload["stress"]["loanassumptions"]["bankdiscountfirstyear"];
        // payload["stress"]["interestrates"][1].creditsuisserate = growthRate;
        // payload["stress"]["interestrates"][1].uspacesetterrate = growthRate;
        // payload["stress"]["interestrates"][3].creditsuisserate = growthRate;
        // payload["stress"]["interestrates"][3].uspacesetterrate = growthRate;
        // payload["stress"]["interestrates"][5].creditsuisserate = growthRate;
        // payload["stress"]["interestrates"][5].uspacesetterrate = growthRate;
        payload["illustration"]["loanassumptions"]["bankdiscountfirstyear"] = estimatorData["illustrationBankDiscountFirstYear"] || payload["illustration"]["loanassumptions"]["bankdiscountfirstyear"];
        payload["common"]["blendpercentage"] = estimatorData["estimatorDataBlend"] || payload["common"]["blendpercentage"];
    }
    payload["common"]["monthlypayclientextra"] = 0.05;
    payload["common"]["paymentfrequency"] = values["contributionFrequency"] || "Annual";
    payload["common"]["paymentfrequencyemployer"] = "Annual";
    payload["common"]["carrier"] = carrierCode;
    await estimationActions.getEstimatesFromCloud(payload, role, {...values, issueAge, agentName, userName: fullName}, userId, callingFrom);
};

export default function EstimationFormikHandler(props: { activeStep: number, handleBack: Function, handleNext: Function, handleResult: Function, totalSteps: number
    setIsEstimationDataLoading: Function, setRequestId: Function, children: any, handleReset: Function, formValues?: FormValuesType,
    getEstimatorData?: Function, callingFrom?: string, handleClose?: Function, isCalculatedByProfileData:boolean, clearEstimationData?:Function
}) {
    const atlantic = useCheckAtlantic();
    const formikRef: any = React.useRef(null);
    const estimationActions = useActions(Actions);
    const estimatorActions = useActions(EstimatorActions);
    const trancheAction =  useActions(TrancheActions);
    const {user, user: {client, role, accessType}} = useSelector((state: RootState) => state.auth);
    const carrierId = user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.carrier ? user.client.carrier.id : 2);
    const profileInfo = useSelector((state: RootState) => state.profile);
    const minCon = useSelector((state: RootState) => state.minimumContribution) || 21350;
    const [allocationQuestion, setAllocationQuestion] = useState({});
    const isRebrand = client && client.imo && client.imo.reBrand === 1;

    const {
        activeStep,
        handleBack,
        handleNext,
        handleResult,
        totalSteps,
        setIsEstimationDataLoading,
        setRequestId,
        formValues,
        getEstimatorData,
        callingFrom,
        handleClose,
        isCalculatedByProfileData,
        clearEstimationData
    } = props;
    const [initialValues, setInitialValues] = React.useState<any>({...values, carrierId});
    const [issueAge, setIssueAge] = React.useState<number | null>(null); // estimatorDisabled
    const [trancheNo, setTrancheNo] = React.useState<number | null>(null);
    const [estimatorDisabled, setEstimatorDisabled] = React.useState<boolean>(false);
    const [estimatorData, setEstimatorData] = React.useState<any | null>(null);
    let userId: any = ((role === 4 || role === 1 || role === 2) && callingFrom === "ENROLLMENT_TAB") ? formValues && formValues.id : user && user.id;
    let userID:any = (user && user.id) || "";
    let userName: any = (((role === 4 || role === 1 || role === 2) && callingFrom === "ENROLLMENT_TAB") || ((role === 4) && callingFrom === "INVITATION")) ?
            formValues && `${formValues.firstName} ${formValues.lastName}` : `${user && user.firstName} ${user && user.lastName}` || "";

    let agentName: string = (role === 4 || role === 1 || role === 2 || role === 3) ? `${user && user.firstName} ${user && user.lastName}` :
            ((role === 1 || role === 2) && callingFrom === "ENROLLMENT_TAB") ? formValues && formValues.agentName || "":
            `${user && user.client && user.client.agent && user.client.agent.user && user.client.agent.user.firstName} ${user && user.client && user.client.agent && user.client.agent.user && user.client.agent.user.lastName}`;
    const getEstimatorDataFromApi = async (query: Partial<{ strategyId: number, carrierId: number }>) => {
        if (query.carrierId && query.strategyId) {
            const res = await estimatorActions.getEstimatorDetails(query);
            if (!!res) {
                setEstimatorData(res);
            }else{
                setEstimatorDisabled(true)
            }
        }else{
            // in case carrierId or strategyId does not Exist
            estimatorActions.showNotAbleToEstimator();
            setEstimatorDisabled(true)
        }
    }
    const createAllocationPayLoad = useCreateAllocationPayLoad(estimatorData && estimatorData.id)
    React.useEffect(() => {
        let query: Partial<{ strategyId: number, carrierId: number }> = {};
        if(accessType === "client"){ //Load the estimation data for demo mode
            query = {
                strategyId: user && user.demoStrategy && user.demoStrategy.id,
                carrierId: user && user.demoCarrier && user.demoCarrier.id
            }
        } else {
            query = {
                strategyId: user && user.client && user.client.strategy && user.client.strategy.id || 1,
                carrierId: user && user.client && user.client.carrier && user.client.carrier.id
            }
        }
        getEstimatorDataFromApi(query).then(() => {});
        getCurrentTranche().then(()=>{});
    }, []);

    React.useEffect(() => {
        (async () => {
            if (estimatorData && estimatorData.id) {
                const res = await estimationActions.getAllocationQuestions({ estimatorId: estimatorData.id });
                if (res) {
                    const values = {};
                    res.forEach((fields) => {
                        values[fields.code] = fields;
                    });
                    setAllocationQuestion(values);
                }
            }
        })();

    }, [estimatorData && estimatorData.id]);


    React.useEffect(() => {
        if (issueAge && issueAge > 50 && formikRef && formikRef.current && formikRef.current.values) {
            setInitialValues({...formikRef.current.values, beginDistributions: (issueAge + 15), issueAge:issueAge})
        } else if(issueAge && formikRef && formikRef.current && formikRef.current.values) {
           setInitialValues({...formikRef.current.values, beginDistributions: 65, issueAge:issueAge})
        }
    }, [issueAge]);

    React.useEffect(() => {
        if(trancheNo) {
            setInitialValues({...initialValues, trancheNumber: trancheNo})
        }
    }, [trancheNo]);



    React.useEffect(() => {
        const dob:string|undefined|null = (profileInfo && profileInfo.client && profileInfo.client.dob) || (user && user.client && user.client.dob) || null;
        let userID:any = (user && user.id) || 0;
        let payload:any={};
        let selectedDate:any = moment(dob).format("MM/DD/YYYY");
        let gender:number|undefined|null = (profileInfo && profileInfo.client && profileInfo.client.gender) || (user && user.client && user.client.gender) || null;
        let healthType: string | null = (profileInfo && profileInfo.client && profileInfo.client.healthType) || (user && user.client && user.client.healthType) || null;
        calculateIssueAge(dob, carrierId).then(()=>{})
        if(((!!dob) && isCalculatedByProfileData) || callingFrom === "ENROLLMENT" || callingFrom === "ESTIMATOR") {
            let selectedDate:any = moment(dob).format("MM/DD/YYYY");
            setInitialValues({
                ...initialValues,
                dob:selectedDate,
                InvestmentType: isALGCarrier(carrierId) ? (isRebrand ? InvestmentValues.SP500ALZ : InvestmentValues.Conservative) : InvestmentValues.SP500,
                gender,
                healthType,
            });

            if(typeof clearEstimationData === 'function' && isCalculatedByProfileData){
                clearEstimationData();
            }
        }

        if(!!(userID)) {
            let stepOneJSON: any = localStorage.getItem(`stepOne_${userID}`);
            let stepOneLocalData: { dob?: string, gender?: number, heathType?: string, tobacco?: string } = JSON.parse(stepOneJSON);
            payload = {
                ...stepOneLocalData,
                dob: selectedDate,
                gender
            }
            localStorage.setItem(`stepOne_${userID}`, JSON.stringify(payload));
        }
    }, [isCalculatedByProfileData, callingFrom]);

    const resetBeginAgeToDefault = (setFieldValue: Function): void => { //setBeginAgeToDefault on back button click
        let ageIncrement: number = 15;
        if (carrierId === AllianzLifeCarrierDetail.value) {
            ageIncrement = 16;
        }
        setFieldValue("beginDistributions", issueAge && issueAge > 50 ? issueAge + ageIncrement : 65);
    }

    const getCurrentTranche = async () => {
        const res = await trancheAction.trancheCurrent();
        setTrancheNo(res && res.trancheNumber || null);
    }

    const validate = ((value: any) => {
        const errors: {
            dob?: string, primaryFocusPlan?: string, gender?: string,
            healthType?: string, tobacco?: string, annualContribution?: string,
            deathBenefitCalculationOptions?: string, desireLifeTimeBenefits?: string
            totalContribution?: string, endDistributions?: string, beginDistributions?: string,
            firstName?:string, lastName?:string, InvestmentType?:string, confirmInvestment?:string,
            issueAge?: string,
        } = {};
        if (activeStep === 0) {
            const age  = getDateDiffInYear(value.dob)
            console.log(value, "=== age")
            const date:any = moment(value.dob);
            if (!value.dob) {
                errors.dob = "Required DOB";
            }

            //this condition code commented as Par QA comment in task 30Sep\
                // else if(age < ESTIMATE_MIN_AGE || age > ESTIMATE_MAX_AGE){
                //     errors.dob = "Age must be between 18-65.23232";
                // }

            else if(!(date.isValid())) {
                errors.dob = "";
            }

            if(age < ESTIMATE_MIN_AGE || age > ESTIMATE_MAX_AGE) {
                errors.issueAge = "Issue Age must be between 18-65";
            }

            if (!value.gender) {
                errors.gender = "Required";
            }
            if (!value.healthType) {
                errors.healthType = "Required";
            }
            if (!value.tobacco) {
                errors.tobacco = "Required";
            }
            if(accessType === "client") {
                if (!value.firstName) {
                    errors.firstName = "Required";
                }
                if (!value.lastName) {
                    errors.lastName = "Required";
                }
            }
        }
        if (activeStep === 1) {
            let contributionAmountField = 'annualContribution';
            if (showEmployerContribution(accessType, value.contributionFrequency, atlantic)){
                contributionAmountField = "totalCombinedContribution"
            }
            if (!value.primaryFocusPlan) {
                errors.primaryFocusPlan = "Required";
            }
            if (value.primaryFocusPlan === "contributionAmount" && value.annualContribution === "") {
                errors.annualContribution = "Required";
            } else if (value.primaryFocusPlan === "contributionAmount" && numeral(value[contributionAmountField]).value() < minCon) {
                errors[contributionAmountField] = `Min Amount should be ${maskCurrency(minCon +'')}`;
            }
        }
        if (activeStep === 2) {
            if (value.primaryFocusPlan === "deathBenefit") {
                let minDesireLifeTimeBenefitsCont:number = getMinimumDesireLifeTimeBenefits(value);
                const minContribution:string = numberToThousandSeparator(minDesireLifeTimeBenefitsCont);
                if (!value.deathBenefitCalculationOptions) {
                    errors.deathBenefitCalculationOptions = "Required";
                }
                if (value.deathBenefitCalculationOptions === "lifeTimeDeathBenefit" && value.desireLifeTimeBenefits === "") {
                    errors.desireLifeTimeBenefits = "Required";
                } else if (value.deathBenefitCalculationOptions === "lifeTimeDeathBenefit" && numeral(value.desireLifeTimeBenefits).value() < minDesireLifeTimeBenefitsCont) {
                    errors.desireLifeTimeBenefits = `Min Amount should be $${minContribution}`;
                } else if (value.deathBenefitCalculationOptions === "lifeTimeDeathBenefit" && numeral(value.desireLifeTimeBenefits).value() > 999999999) {
                    errors.desireLifeTimeBenefits = "Max Amount should be $999,999,999";
                }
                if (value.deathBenefitCalculationOptions === "amountToContribute" && value.totalContribution === "") {
                    errors.totalContribution = "Required";
                } else if (value.deathBenefitCalculationOptions === "amountToContribute" && numeral(value.totalContribution).value() < minCon) {
                    errors.totalContribution = `Min Amount should be ${maskCurrency(minCon+'')}`;
                }
            }
            if (value.primaryFocusPlan === "contributionAmount") {
                if (!value.beginDistributions) {
                    errors.beginDistributions = "Required";
                }
                if (!value.endDistributions) {
                    errors.endDistributions = "Required";
                }
            }
        }
        if (activeStep === 3) {
            if (!value.InvestmentType) {
                errors.InvestmentType = "Required";

            }
            if (!value.confirmInvestment) {
                errors.confirmInvestment = "Required";
            }
        }
        return errors;
    });

    const getMinContribution = async (query: any) => {
        await estimationActions.getMinimumContribution(query);
    }

    const calculateIssueAge = async (dob, carrierId) => {
        const response: any = await estimationActions.getIssueAge(dob, carrierId);
        setIssueAge(response && response.issueAge);
    };

    return (
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                enableReinitialize={true}
                validate={values => validate(values)}
                validateOnChange={true}
                initialTouched={{ totalCombinedContribution:true }}
                onSubmit={(values) => {
                    submitEstimationFormik({
                        values,
                        user,
                        role,
                        setIsEstimationDataLoading,
                        userId,
                        setRequestId,
                        userName,
                        agentName,
                        issueAge,
                        estimatorData,
                        estimationActions,
                        callingFrom,
                        atlantic,
                        createAllocationPayLoad
                    }).then(()=>{});
                }}
                >
                {({
                    errors,
                    touched,
                    setFieldValue,
                    handleSubmit,
                    dirty
                    }) => {
                    return (
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e);
                            }}>

                                <EstimationFormContext.Provider value={{
                                    issueAge,
                                    setIssueAge,
                                    allocationQuestion,
                                    estimatorId :estimatorData && estimatorData.id
                                }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            {props.children}
                                        </Grid>
                                    </Grid>
                                </EstimationFormContext.Provider>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" className="colorRed floatLeft ml15"> Required
                                            field<sup>*</sup>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StepperButton
                                            activeStep={activeStep}
                                            handleBack={() => {
                                                resetBeginAgeToDefault(setFieldValue);
                                                handleBack();
                                            }}
                                            handleNext={handleNext}
                                            handleResult={handleResult}
                                            totalSteps={totalSteps}
                                            errors={errors}
                                            formikRef={formikRef}
                                            touched={touched}
                                            dirty={dirty}
                                            disabled={estimatorDisabled}
                                            callingFrom={callingFrom}
                                            handleClose={handleClose}

                                        />
                                    </Grid>
                                </Grid>
                                <FormikTokenUpdate getMinContribution={getMinContribution} />
                            </form>
                    );
                }}
            </Formik>
    );
}
