import React from "react";
import { Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import Info from "@material-ui/icons/Info";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import FocusError from "../../../../../../../components/FocusError";
import { EnrollmentFinancialAgreementProps } from "../../../../../../../model/enrollment";
import { ErrorCommon } from "../../../../../../../components/formikFormInputs";
import { Label } from "../../../../../../../components/formInputs";
import IncomeCheckboxFinancialPage from "./IncomeCheckboxFinancialPage";

interface FinancialAgreement {
    autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void;
    fieldObj1: any
    getComp: Function
    questionList: any
    clientDetail: any
    allianzId: number
    enableSaveButton: Function
    clientSigned: boolean | number
    showComponent: Function
    showComponent1: Function
    showComponent2: Function;
    fieldObj4: any
    fieldObj5: any
    financialAgreementRef: any
    setSaveFinancialAgreementForm: Function,
    formType: string
    setFormType: Function
}

function FinancialAgreement(props: FinancialAgreement) {
    const {
        allianzId,
        autoSaveTrigger,
        clientDetail,
        clientSigned,
        enableSaveButton,
        fieldObj1,
        fieldObj4,
        fieldObj5,
        financialAgreementRef,
        getComp,
        questionList,
        showComponent,
        showComponent1,
        showComponent2,
    } = props;

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
    }: any = useFormikContext();

    return (
            <form className="floatLeft w100" onBlur={autoSaveTrigger} onSubmit={handleSubmit}>
                <FocusError offset={true} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="caption" component="strong"
                                    className="floatLeft mt30 w100">
                            <Label label={"Financial Agreement (agree to all and date)"} required={true} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <Typography variant="caption" className=" mb30">
                            The following financial disclosures are made for the purpose
                            of establishing insurability and best interest in connection
                            with the pending insurance application on my life. They are
                            furnished as a true and accurate statement of my financial
                            condition on:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        {fieldObj1.map((field, index) => {
                            return getComp(field, values, errors, touched);
                        })
                        }
                    </Grid>
                    <span className="hLine" />
                    <Grid item xs={12}>
                        <Typography variant="caption" component="strong"
                                    className="mb40 floatLeft w100">
                            Caution: Review your answers below carefully; if your
                            answers are incorrect or untrue, the Insurance Carrier may
                            have the right to deny benefits or rescind your policy,
                            subject to the incontestability provision in the policy. A
                            copy of this supplement to the insurance application will
                            be attached to and made part of any life insurance policy
                            issued.
                        </Typography>
                    </Grid>
                    {/* questions portion start*/}
                    {questionList.map((question: EnrollmentFinancialAgreementProps, idx: number) => { // this is for listing
                        if (question.name === "declareBankruptcy" && clientDetail && clientDetail.carrierId !== allianzId) {
                            return (<React.Fragment></React.Fragment>);
                        }
                        return (
                                <Grid item xs={12} sm={12} md={12} key={question.key}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={9}>
                                            <Typography variant="body1" component="strong"
                                                        className="mb0 floatLeft">{question.label}
                                            </Typography>
                                            {question.infoTooltip &&
                                                    <Tooltip title={<Typography
                                                            className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                                        <IconButton>
                                                            <Info color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <ToggleButtonGroup
                                                            className="toggleButtonGroupQust floatRight"
                                                            size="medium"
                                                            color={"primary"}
                                                            exclusive
                                                            id={question.name}
                                                            value={values[question.name] === "Yes" ? "Yes" : values[question.name] == "No" ? "No" : null}
                                                            onChange={async (ev: any, value: any) => {
                                                                enableSaveButton();
                                                                if (value === "Yes") {
                                                                    await setFieldValue(question.name, value);
                                                                }
                                                                if (value === "No") {
                                                                    await setFieldValue(question.name, value);
                                                                    if (question && question.resetFields && question.resetFields.length > 0) {
                                                                        for (let rf in question.resetFields) {
                                                                            await setFieldValue(question.resetFields[rf].name, question.resetFields[rf].value);
                                                                        }
                                                                    }
                                                                    if (question.name === "declareBankruptcy") {
                                                                        setFieldValue("bankruptcyDetails", "");
                                                                    }
                                                                }
                                                                if (value === null) {
                                                                    await setFieldValue(question.name, null);
                                                                }
                                                            }}
                                                    >
                                                        <ToggleButton value={"Yes"}
                                                                      disabled={!!clientSigned}
                                                                      className={values[question.name] === "Yes" ? "active" : ""}>
                                                            Yes
                                                        </ToggleButton>
                                                        <ToggleButton value={"No"}
                                                                      disabled={!!clientSigned}
                                                                      className={values[question.name] === "No" ? "active" : ""}>
                                                            No
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <span className="floatRight">
                                                        {errors[question.name] && (
                                                                <ErrorCommon errors={errors}
                                                                             name={question.name}
                                                                             touched={touched} />

                                                        )}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {question && question.additionalRemarks &&
                                                showComponent(question, values, touched, errors,
                                                        setFieldTouched, setFieldValue, handleChange,
                                                        handleBlur, clientSigned)
                                        }
                                        {
                                                question && values[question.name] === "Yes" && question.additionalFieldDate &&
                                                <Grid item xs={12} md={3}>
                                                    <div
                                                            className={"mt30"}
                                                            key={`${idx}-${question.key}-date`}
                                                    >   {
                                                        fieldObj4.map((field, index) => {
                                                            return getComp(field, values, errors, touched);
                                                        })
                                                    }
                                                    </div>
                                                </Grid>
                                        }
                                        {question && question.additionalRemarks1 &&
                                                showComponent1(question, values, touched,
                                                        errors, setFieldTouched, setFieldValue,
                                                        handleChange, handleBlur, clientSigned)

                                        }
                                        {
                                                question && values[question.name] === "Yes" && question.additionalField1Date && values["currentBankruptcyStatus"] === "Closed" &&
                                                <Grid item xs={12} md={3}>
                                                    <div
                                                            className={"mt30"}
                                                            key={`${idx}-${question.key}-date2`}
                                                    >
                                                        {
                                                            fieldObj5.map((field, index) => {
                                                                return getComp(field, values, errors, touched);
                                                            })
                                                        }
                                                    </div>
                                                </Grid>
                                        }
                                        {
                                                question && question.additionalRemarks2 &&
                                                showComponent2(question, errors, handleBlur, handleChange, values)
                                        }
                                        {
                                                (question && question.name === "changesInIncome") && (values["changesInIncome"] === "Yes") &&
                                                <IncomeCheckboxFinancialPage question={question}
                                                                             isFromClientDetail={false}
                                                                             clientSigned={!!clientSigned} />
                                        }
                                        <Button
                                                variant="contained"
                                                color="primary"
                                                className="detailPageSubmitBtn"
                                                type={"submit"}
                                                ref={financialAgreementRef}
                                                style={{
                                                    display: "none",
                                                }}
                                        >
                                        </Button>
                                        <span className="hLine" />
                                    </Grid>
                                </Grid>
                        );
                    })}
                </Grid>
            </form>
    );
}

export default FinancialAgreement;