import React from 'react';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Box, Grid} from "@material-ui/core";
import {TextField, TextFieldCommon, ErrorCommon} from "../../../../../components/formikFormInputs";
import {Formik} from "formik";
import {Label} from "../../../../../components/formInputs";
import {useActions} from "../../../../../actions";
import * as AccountActions from "../../../../../actions/account";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: 'absolute',
                right: "10px",
                top: '16px',
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="body1" component="strong">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function RequestModification(props:any) {
    const accountActions = useActions(AccountActions);
    const { handleClose, open, enrollmentId } = props;
    const initialValues = {
        message : ''
    };

    const validate = ((value: any) => {
        let errors: any = {};

        if(!value.message){
            errors.message = 'Required';
        }

        return errors;
    });

    const fieldObj = [
        {
            name: "message",
            defaultValue : '',
            autoFocus:true,
            label: <Label label={"Message"} required={true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            placeholder:'Write your detailed request here making sure to specify the document you are requesting changes to.',
            multiline:true,
            rows:10,
            InputLabelProps: {
                shrink: true,
            }
        }
    ];

    return (
            <div>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Request Modifications
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography
                                component="span"
                                variant="body1"
                        >
                            If something doesn’t look right you can request modifications to the agent report or client application. We will review your request, make any necessary changes and re-send you the documents to review.
                        </Typography>

                        <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validate={values => validate(values)}
                                validateOnChange={true}
                                onSubmit={async (values, { setSubmitting }) => {
                                    const payload = {
                                        enrollmentId,
                                        actionType: 'UPLOAD_PROPOSAL',
                                        changeRequest : 1,
                                        message : values.message
                                    }
                                    await accountActions.updateAdminActionItemsStatus(payload)
                                    handleClose();
                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 400);
                                }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty
                              }) => (<form onSubmit={handleSubmit}>
                                {fieldObj.map((field, index) => {
                                    return (
                                            <Box m={1} key={index}>
                                                <div className="mt30 customFields">
                                                    <Grid item xs={12}  md={12}
                                                          key={index}>
                                                        <TextFieldCommon
                                                                {...field}
                                                                values={values}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                        />
                                                        <ErrorCommon errors={errors} name={field.name}
                                                             touched={touched}/>
                                                    </Grid>
                                                </div>
                                            </Box>
                                    );
                                })
                                }
                                <Button variant="contained" className="floatRight"  color="primary" type={'submit'} disabled={!dirty || isSubmitting} >
                                    Send
                                </Button>
                            </form>)}
                        </Formik>

                    </DialogContent>
                </Dialog>
            </div>
    );
}
