import React from "react";
import {Grid, LinearProgress, Typography} from "@material-ui/core";
import StepTitle from "./StepTitle";

interface StepHeading {
    step:string,
    offerType:string
}

const StepHeading = (props:StepHeading) => {
    let stepTitle, title, description, progress;
    const {step, offerType} = props;
    if(step === "1.1" && offerType === "ilia_app"){
        stepTitle = "Application 1/6: Medical Part 1";
        title = "Medical Questionnaire";
        description = "Please answer the below medical questions from your carrier.";
        progress = 20;
    } else if(step === "1.2" && offerType === "ilia_app") {
        stepTitle = "Application 2/6: Medical Part 2";
        title = "Medical Questions";
        description = "Please answer the below medical questions from your carrier.";
        progress = 40;
    } else if(step === "1.3" && offerType === "ilia_app") {
        stepTitle = "Application 3/6: Insured Questions";
        title = "Insured Questions";
        description = "Please answer the below insurance specific questions from your carrier.";
        progress = 60;
    } else if(step === "1.4" && offerType === "ilia_app") {
        stepTitle = "Application 4/6: Aviation, Avocation & Foreign Travel";
        title = "Aviation, Avocation & Foreign Travel";
        description = "";
        progress = 80;
    } else if(step === "1.5" && offerType === "ilia_app") {
        stepTitle = "Application 5/6: Financials Questions";
        title = "Financial Questions";
        description = "";
        progress = 90;
    } else if(step === "1.51" && offerType === "ilia_app") {
        stepTitle = "Application 6/6: Beneficiaries";
        title = "Beneficiaries";
        description = "";
        progress = 100;
    } else if(step === "1.3" && offerType === "full") {
        stepTitle = "Application 3/5: Aviation, Avocation & Foreign Travel";
        title = "Aviation, Avocation & Foreign Travel";
        description = "";
        progress = 60;
    } else if(step === "1.4" && offerType === "full") {
        stepTitle = "Application 4/5: Financials Questions";
        title = "Financial Questions";
        description = "";
        progress = 90;
    } else if(step === "1.5" && offerType === "full") {
        stepTitle = "Application 5/5: Beneficiaries";
        title = "Beneficiaries";
        description = "";
        progress = 100;
    }

    return (<Grid container>
        <Grid item xs={12} md={11} lg={9} xl={9} className="textLeft">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StepTitle title={stepTitle} />
                    <LinearProgress className="landingLinearProgress" variant="determinate" value={progress}/>
                    <Typography variant="h3" className="mt50 mt20Small">
                        {title}
                    </Typography>
                    {!!description && <Typography variant="body1">
                        {description}
                    </Typography>}
                </Grid>
            </Grid>
        </Grid>
    </Grid>)
}

export default StepHeading;



