import * as React from "react";
import { Container, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { PlayCircleFilled } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import { kaiZenPhysicianRebrand, kaiZenPhysician, kaiZenRebrand, kaiZen } from "../../constants";
import StrategyName from "../client/StrategyName";
import LeadGenerationForm from "./LeadGenerationForm";
import { Dialog as GenericDialog, GenericDialogProps } from "../dialog";


const useStyles = makeStyles((theme) =>
        createStyles({
            getStarted: {
                textAlign: "left",
                position: "relative",
                overflow: "visible",
                maxWidth: "100%",
                marginLeft: "40px",
                float: "left",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "0",
                },
                [theme.breakpoints.down("sm")]: {
                    maxWidth: "100%",
                    width: "100%",
                },
            },
        }),
);
const LeadSectionOne = (props: any) => {
    const classes = useStyles();
    const { startEstimation } = props;
    const [showStickyBtn, setShowStickyBtn] = React.useState(false);
    const strategyId: string = props.strategyData && props.strategyData.id || 1;
    const strategySlug: string = props.strategyData && props.strategyData.slug || "";
    const strategyName: string = props.strategyData && props.strategyData.strategyName || "";
    const currentScrollTop = 0;
    const [showVideo, setShowVideo] = React.useState(false);
    const strategy = (
            <StrategyName
                    tooltip={true}
                    includeThe={false}
                    strategyName={strategyName}
                    registrationMarkLandingPage={true}
                    parentComponentStrategyId={Number(strategyId)}
            />
    );

    let bgImage = "hero-bg-image.jpg";
    if (props.strategyData.id === kaiZenPhysicianRebrand || props.strategyData.id === kaiZenPhysician) {
        bgImage = "lsp-p.jpg";
    }

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll, true);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        });
    }, []);

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        return (() => {
            window.removeEventListener("scroll", handleResize);
        });
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, currentScrollTop);
    }, [currentScrollTop]);


    const handleResize = () => {
    };

    const handleScroll = () => {
        if (!showStickyBtn && window.scrollY > 120) {
            setShowStickyBtn(true);
        }
    };

    const getHeading  = () => {
        if(strategySlug === 'esd'){
            return "Get Started With Enhanced Split Dollar";
        }
        if(strategySlug === 'trizen'){
            return "Get Started With Tri-Zen";
        }
        return "Don’t Simply Retire. Have Something to Retire to.";
    }

    const getDescription  = () => {
        if(strategySlug === 'esd'){
            return "Enhanced Split Dollar is a pre-tax benefit strategy that uses employer contributions and bank leverage to triple premiums into your cash accumulation life insurance policy (IUL), using the policy as the sole collateral for the bank loan. ";
        }
        if(strategySlug === 'trizen'){
            return "Tri-Zen is a pre-tax benefit strategy that uses employer contributions and bank leverage to triple premiums into your cash accumulation life insurance policy (IUL), using the policy as the sole collateral for the bank loan. ";
        }
        return (
                <>
                    {strategy} offers you up to an additional 3 times more money to fund a unique cash accumulating life insurance policy using leverage.
                </>
        )
    }

    const getVideoURL = () => {

        if (strategySlug === 'esd') {
            if (props.agent && props.agent.imo && props.agent.imo.reBrand) {
                return "https://player.vimeo.com/731874277";
            }
            return "https://player.vimeo.com/video/390792058";
        }
        if(strategySlug === 'trizen'){
            return "https://player.vimeo.com/video/379864685";
        }
        if ( props.strategyData.id === kaiZenPhysician) { // kai-zen phy case
            return "https://player.vimeo.com/video/470331789";
        }
        if (props.strategyData.id === kaiZen) {
            return  "https://vimeo.com/680515029"
        }
        if (props.strategyData && props.strategyData.reBrand && props.strategyData.slug === 'kaizen') {
            if (props.strategyData.id === kaiZenPhysicianRebrand) { // kai-zen phy aon case
                return "https://vimeo.com/731861798";
            }
            if (props.strategyData.id === kaiZenRebrand) { // kai-zen aon case
                return  "https://vimeo.com/731874233";
            }
        }
        return "https://player.vimeo.com/video/573096184";

    }

    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "customized-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (
                <ReactPlayer
                        controls={true}
                        playing={false}
                        loop={true}
                        autoPlay={true}
                        volume={1}
                        muted={false}
                        url={getVideoURL()}
                        width="100%"
                        playsinline={true}
                />),
        dividers: false,
        onClose: () => setShowVideo(false),
        open: showVideo,
        title: "",
        onBackdropClick: () => setShowVideo(false),
    };
    const agentPath: string = window.location.pathname;
    const isAgentpath = agentPath.includes("agent-link");
    return (
            <>
                <div className="heroFirst" style={{
                    backgroundImage: `url("${process.env.PUBLIC_URL}/images/landing_page_v2/${bgImage}")`,
                    marginTop: isAgentpath ? 80 : 114
                }}>
                    <Container maxWidth="lg">
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <Typography variant="caption" align="center" className="w100 textShadow colorOrg "
                                            data-aos="fade-up" data-aos-duration="1500">
                                    THE {strategy} STRATEGY
                                </Typography>
                                <Typography variant="h2" className="colorWhite textShadow"
                                            data-aos="fade-up"
                                            data-aos-duration="1500">

                                    {getHeading()}
                                </Typography>
                                <Typography className="colorWhite mb20" variant="subtitle1" data-aos="fade-up"
                                            data-aos-duration="1000">
                                    {getDescription()}
                                </Typography>
                                {
                                        strategySlug !== "" &&
                                        <ReactPlayer
                                                className="kaiZenHero"
                                                controls={true}
                                                url={getVideoURL()}
                                                width="100%"
                                                height="100%"
                                                playsinline={true}
                                        />
                                }
                                <span
                                        className="videoHeroOne"
                                        onClick={() => setShowVideo(true)}
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                >
                                    <PlayCircleFilled className="f70 mr15 textWhite" />
                                    <div>
                                        <Typography className="textHighLight f16">2 Minute Video</Typography>
                                        <Typography className="textWhite f18">Learn about Kai-Zen</Typography>
                                    </div>
                                </span>
                            </Grid>
                            {
                                    showVideo &&
                                    <GenericDialog dialogClassName="kaiZenMobile" {...dialogProps} />
                            }
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <Card className={classes.getStarted}>
                                    <CardContent className="heroCard">
                                        <div id="base">
                                            <Typography variant="subtitle2" className="textHighLight mb0 ">
                                                60 - 100%
                                            </Typography>
                                            <Typography variant="body2" className="textWhite mb0">
                                                More for Retirement
                                            </Typography>
                                            <div id="chevron">&nbsp;</div>
                                        </div>

                                        <Typography
                                                align="left"
                                                className="textHighLight floatLeft w100 mt100"
                                                component="strong"
                                                variant="body1"
                                        >
                                            GET STARTED
                                        </Typography>
                                        {
                                                startEstimation === 2 &&
                                                <LeadGenerationForm {...props} />
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="heroContent">
                    <Grid container justify="center">
                        <Grid item xs={12} md={8}>
                            <Typography variant="subtitle1" className="mt60 mb60" align="center">
                                The success of your retirement depends on the amount you save, not on your rate of
                                return. A unique cash accumulating life insurance policy using leverage offers an
                                opportunity to earn interest and eliminate the risk of market declines, while providing
                                you and your family protection. The policy secures the loan, providing you the <strong>potential
                                for an additional 60-100% more for your retirement without the typical risks associated
                                with leverage.</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <span className="clearFix"/>
            </>
    );
};
export default LeadSectionOne;
