import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";
import { Theme } from "@material-ui/core/styles";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import * as Clients from "../../../../../actions/client";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import GeneralNotes from "./GeneralNotes";
import ProcessingNotes from "./ProcessingNotes";
import {Grid, InputAdornment, TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {Button} from "../../../../../components/formInputs";
import useQueryString from "../../../../../hooks/useQueryString";
import AddNotes from "../modals/AddNotes";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function NotesTab(props: { role: number | undefined, clientDetail: any }) {
    const { role, clientDetail } = props;
    const accountActions = useActions(Actions);
    const clientActions = useActions(Clients);
    const classes = useStyles();
    const [enrollmentClientNotes, setEnrollmentClientNotes] = React.useState<any>([]);
    const [isProgress, setIsProgress] = React.useState(false);
    const [openNotes, setOpenNotes] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [refresh, setRefresh] = React.useState<any>(Math.random());
    const [page, setPage] = useQueryString("page", 0);
    const [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", 25);
    const [orderBy] = useQueryString("orderBy", "createdAt");
    const [order, setOrder] = useQueryString("order", "desc");
    const [search, setSearch] = useQueryString("search", "");

    useEffect(() => {
        if (clientDetail && clientDetail.client && clientDetail.client.id) {
            getClientNotes();
        }
    }, [page, rowsPerPage, order, orderBy, search, refresh, tabValue, clientDetail && clientDetail.client && clientDetail.client.id]);

    const getClientNotes = async () => {
        let queryObj: any = { page, rowsPerPage, searchNote: search, sortBy: orderBy, order };
        let notesType:string = tabValue === 0 ? "GENERAL" : "PROCESSING";
        queryObj = {...queryObj, notesType}
        const result = await clientActions.getClientNotes(clientDetail && clientDetail.client && clientDetail.client.id, queryObj);
        setEnrollmentClientNotes(result);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        setPage(0);
        setRowsPerPage(25);
        setSearch("");
    };

    const exportNotes = async () => {
        setIsProgress(true);
        let notesType:string = tabValue === 0 ? "GENERAL" : "PROCESSING";
        await accountActions.exportClientNotes((clientDetail && clientDetail.client && clientDetail.client.userId) || "", notesType);
        setIsProgress(false);
    };

    const handleNotes = () => {
        setOpenNotes(true);
    }

    const handleRefresh = () => {
        setRefresh(Math.random());
    };

    const handleClose = () => {
        setOpenNotes(false);
    };

    const deleteEnrollmentNotes = async (notesId:number) => {
        await accountActions.deleteEnrollmentNotes(notesId, "PROCESSING");
        await getClientNotes();
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
                <div
                        role="tabpanel"
                        color={'secondary'}
                        hidden={value !== index}
                        id={`simple-tabpanel-${index}`}
                        aria-labelledby={`simple-tab-${index}`}
                        {...other}
                >
                    {value === index && (
                            <Box p={2}>
                                <Typography>{children}</Typography>
                            </Box>
                    )}
                </div>
        );
    }

    return (
            <React.Fragment>
                <div className={classes.root}>
                    <Grid container spacing={2} justify="space-between" className="mb15">
                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                value={search}
                                label={"Search"}
                                placeholder={"Search"}
                                variant="outlined"
                                onChange={e => {
                                    setPage(0);
                                    setSearch(e.target.value);
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className="iconBtn"
                                                aria-label="search account"
                                            >
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {(enrollmentClientNotes && enrollmentClientNotes.count > 0) &&
                                <Button type="button" variant="contained" color="primary" disabled={isProgress}
                                    label="Export Notes"
                                    className="floatRight mt10" onClick={() => {
                                    exportNotes();
                                }}/>
                            }
                            {(role === 1 || role === 2) &&
                                <Button type="button" variant="contained" color="primary" disabled={isProgress}
                                    label="Add Notes"
                                    className="floatRight mr10 mt10" onClick={() => {
                                    handleNotes();
                                }}/>
                            }
                        </Grid>
                    </Grid>

                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                    >
                        <Tab label="General Notes" value={0}/>
                        {(role === 1 || role === 2) &&
                            <Tab label="Processing Notes" value={1}/>
                        }
                    </Tabs>

                    <TabPanel value={tabValue} index={0}>
                        <GeneralNotes role={role} clientDetail={clientDetail} enrollmentClientNotes={enrollmentClientNotes}
                                      page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage}
                        />
                    </TabPanel>
                    {(role === 1 || role === 2) &&
                        <TabPanel value={tabValue} index={1}>
                            <ProcessingNotes role={role} clientDetail={clientDetail}
                                          enrollmentClientNotes={enrollmentClientNotes}
                                          page={page} rowsPerPage={rowsPerPage} setPage={setPage}
                                          setRowsPerPage={setRowsPerPage}
                                            deleteEnrollmentNotes={deleteEnrollmentNotes}
                                             getClientNotes={getClientNotes}
                            />
                        </TabPanel>
                    }
                </div>
                <AddNotes handleNotes={handleNotes} handleClose={handleClose} open={openNotes}
                          clientId={clientDetail.client && clientDetail.client.id} handleRefresh={handleRefresh}
                          tabValue={tabValue}
                />
            </React.Fragment>
    );
}
