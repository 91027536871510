import {
    Grid,
    Typography,
    Link,
} from "@material-ui/core";
import * as React from "react";
import { SelectField, Label, Button, PageTitle } from "../../components/formInputs";
import PrimaryContactDetail from "../../components/PrimaryContactDetail";
import { required } from "../../utils/validation";
import { imoMemberTypesDropDown } from "../../constants";
import { connect } from "react-redux";
import { reduxForm, getFormValues, Field } from "redux-form";
import { RootState } from "../../reducers";
import { unmaskPhoneNumber } from "../../utils/commonUtils";
import { useActions } from "../../actions";
import * as Actions from "../../actions/account";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useParams } from "react-router-dom";

export function TeamMemberAdd(props: any) {
    let { imoId } = useParams<{ imoId: string }>();

    return (
            <React.Fragment>
                <PageTitle title="Account Management"/>
                <Link className="backBtn" color="primary" onClick={() => {
                    props.history.goBack();
                }}>
                    <ArrowBackIosIcon/>Back
                </Link>
                <Grid item container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} className="pb0">
                        <Typography
                                className="subHead"
                                align="left"
                                variant="h3">
                            Account Management
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <ReduxCardAdminActions
                                imoId={imoId}
                                onSubmitFinish={() => {
                                    props.history.goBack();
                                }}/>
                    </Grid>
                </Grid>
            </React.Fragment>
    );
}

const CardAdminActions = (props) => {
    const { handleSubmit, pristine, submitting, imoId, onSubmitFinish, invalid } = props;

    const accountActions = useActions(Actions);

    const addImoMember = async (data: any) => {
        if (data.phoneNumber) {
            data.phoneNumber = unmaskPhoneNumber(data.phoneNumber || "");
        }
        let response = await accountActions.addImoMember({ ...data, imoId });
        if (response.status) {
            onSubmitFinish();
        }
    };

    return (
            <form onSubmit={handleSubmit(addImoMember)}>
                <Grid item container spacing={2}>
                    {
                        !props.hasTitle &&
                        <Grid item xs={12} sm={12} md={12} className="pb0">
                            <Typography
                                    align="left"
                                    variant="subtitle2">
                                Add IMO Team Member
                            </Typography>
                            <hr/>
                        </Grid>
                    }

                    <Grid item xs={12} sm={12} md={12}>
                        <Field
                                fullWidth
                                name={`memberType`}
                                validate={[required]}
                                label={<Label label={"Select IMO Team Member Type"} required={true}/>}
                                placeholder="enter member type"
                                component={SelectField}
                                options={imoMemberTypesDropDown}
                        />
                    </Grid>

                    <PrimaryContactDetail disabledEmail={false} isFromImoMemberForm={true}/>

                    <Grid item xs={12} sm={12} md={12}>
                        <Button
                                label=" ADD TEAM MEMBER "
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="btnPrimaryBig floatLeft ml10 mt15 mb10"
                                disabled={pristine || submitting || invalid}
                                loading={submitting}
                        />
                    </Grid>
                </Grid>
            </form>
    );
};


function mapStateToProps(state: RootState) {
    return {
        account: state.account,
        formValues: getFormValues("AddImoTeamMember")(state),
    };
}

const reduxAddAccountPage = reduxForm({
    form: "AddImoTeamMember",
})(CardAdminActions);

export const ReduxCardAdminActions: any = connect(mapStateToProps)(reduxAddAccountPage);

export default TeamMemberAdd;
