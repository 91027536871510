import React from "react";
import {Formik, useFormikContext} from "formik";
import {scroller} from "react-scroll";
import {Button, Checkbox, Grid, Typography} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {avocationType, checkAllApplied, isIliaApp, participationArr} from "../Utilities";
import InsuredAviationSubQuestions from "./InsuredAviationSubQuestions";
import InsuredForeignTravelSubQuestions from "./InsuredForeignTravelSubQuestions";
import InsuredAvocationSubQuestions from "./InsuredAvocationSubQuestions";
import {EnrollmentQuestionProps} from "../../../../../../model/enrollment";
import EnrollmentField from "./EnrollmentField";
import FocusError from "../../../../../../components/FocusError";
import {useActions} from "../../../../../../actions";
import * as AccountActions from "../../../../../../actions/account";
import AviationAvocationForeignTravelForm from "./AviationAvocationForeignTravelForm";
import * as Yup from "yup";

type TypeProps = {
    initialValues: any,
    clientSigned: boolean,
    enableSaveButton: Function
    isFromClientDetail: boolean,
    offerType: string,
    role: number,
    textFieldCommon: any
    setSaveAviationAvocationQuestions: Function,
    setDisableSaveButton: Function,
    buttonRef: any,
    user: any,
    pageChecked: boolean,
    setNextStep: Function,
    saveMe:Function
}

const questionList = [{
    key: "",
    name: "Proposed_Insured_Questions-32",
    label: "Do you currently participate in any racing, scuba diving, aerial sports, piloting an aircraft, mountain climbing, BASE or bungee jumping, cave exploration, or do you plan on traveling or living outside of the United States in the next 2 years?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: false,
    notShowHrLine: true,
    notShowUpperHrLine: true
}]

function AviationAvocationForeignTravelContent(props: TypeProps) {
    const {
        initialValues,
        clientSigned,
        textFieldCommon,
        role,
        setSaveAviationAvocationQuestions,
        isFromClientDetail,
        enableSaveButton,
        offerType,
        setDisableSaveButton,
        buttonRef,
        user,
        pageChecked,
        setNextStep,
        saveMe
    } = props;

    const { updateClientFields } = useActions(AccountActions);
    const REQUIRED_MESSAGE = "Required Field";

    let insuredAviation = {
        "Insured_AviationXR6": Yup.string().when("InsuredAviationAvocation", (insuredAvo, schema) => {
            return insuredAvo.includes('Insured_Aviation') ?
                    Yup.string().required(REQUIRED_MESSAGE).nullable() : Yup.string().nullable() // don't change anything
        }),
        "Insured_AviationXR11": Yup.string().when("InsuredAviationAvocation", (insuredAvo, schema) => {
            return insuredAvo.includes('Insured_Aviation') ?
                    Yup.string().required(REQUIRED_MESSAGE).nullable() : Yup.string().nullable() // don't change anything
        }),
        "Insured_AviationXR12": Yup.string().when("InsuredAviationAvocation", (insuredAvo, schema) => {
            return insuredAvo.includes('Insured_Aviation') ?
                    Yup.string().required(REQUIRED_MESSAGE).nullable() : Yup.string().nullable() // don't change anything
        }),
        "Insured_AviationXR16": Yup.string().when("InsuredAviationAvocation", (insuredAvo, schema) => {
            return insuredAvo.includes('Insured_Aviation') ?
                    Yup.string().required(REQUIRED_MESSAGE).nullable() : Yup.string().nullable() // don't change anything
        }),
        "Insured_AviationXR17": Yup.string().when("InsuredAviationAvocation", (insuredAvo, schema) => {
            return insuredAvo.includes('Insured_Aviation') ?
                    Yup.string().required(REQUIRED_MESSAGE).nullable() : Yup.string().nullable() // don't change anything
        }),
        "Insured_AviationXR40": Yup.string().when("InsuredAviationAvocation", (insuredAvo, schema) => {
            return insuredAvo.includes('Insured_Aviation') ?
                    Yup.string().required(REQUIRED_MESSAGE).nullable() : Yup.string().nullable() // don't change anything
        }),
        "Insured_AviationXR7": Yup.string().when(["InsuredAviationAvocation", "Insured_AviationXR6"],{
            is: (insuredAvo, insuredAviR6) => {
                if (insuredAvo.includes('Insured_Aviation') && insuredAviR6 && (insuredAviR6.trim() == 'Yes')) {
                    return true // validate and go to then function
                } else return false
                
            },
            then: Yup.string().required(REQUIRED_MESSAGE).nullable(),
            otherwise: Yup.string().nullable()
        })
    }

    let insuredForeignTravel = {
        "Insured_Foreign_TravelXR6": Yup.string().when("InsuredAviationAvocation", (insuredAvo, schema) => {
            return insuredAvo.includes('Insured_ForeignTravel') ?
                    Yup.string().required(REQUIRED_MESSAGE).nullable() : Yup.string().nullable() // don't change anything
        }),
    }

    const validateAvocation = (fieldName) => {
        return Yup.string().when(["InsuredAviationAvocation", fieldName], {
            is: (insuredAvo: string, val: boolean) => {
                if (insuredAvo.includes('Insured_Avocation') && val) {
                    return true // validate and go to then function
                } else return false // for all other cases is not required
            },
            then: Yup.string().required(REQUIRED_MESSAGE).nullable(),
            otherwise: Yup.string().nullable()
        });
    }

    let avocation = {
        "Insured_AvocationXR11": validateAvocation("Insured_AvocationXC50"),
        "Insured_AvocationXR22": validateAvocation("Insured_AvocationXC51"),
        "Insured_AvocationXR23": validateAvocation("Insured_AvocationXC51"),
        "Insured_AvocationXR37": validateAvocation("Insured_AvocationXC52"),
        "Insured_AvocationXT49": validateAvocation("Insured_AvocationXC53")
    }

    let schema:any = {
       "Proposed_Insured_Questions-32": Yup.string().required(REQUIRED_MESSAGE).nullable(),
       "InsuredAviationAvocation" : Yup.array().when("Proposed_Insured_Questions-32", {
           is : "Yes",
           then : Yup.array().of(Yup.string()).required(REQUIRED_MESSAGE)
       }),
        ...insuredAviation,
        ...insuredForeignTravel,
        ...avocation
   }


    const aviationAvocationSchema = Yup.object().shape(schema);

    return <>
        <Formik
                //innerRef=""
                initialValues={initialValues}
                validationSchema={aviationAvocationSchema}
                enableReinitialize={true}
                // validationSchema={() => {
                // }}
                validateOnChange={true}
                onSubmit={async (values, {setSubmitting}) => {
                    console.log("values -=-=-=- in line 56", values);
                    let payload:any = {};
                    if (!pageChecked && !isFromClientDetail && offerType !== "gi") {
                        return false;
                    }
                    saveMe(values)

                    if(!isFromClientDetail){
                        setNextStep();
                    }
                }}
        >
            {
                ({
                     values,
                     errors,
                     touched,
                     handleChange,
                     setFieldTouched,
                     setFieldValue,
                     handleBlur,
                     handleSubmit,
                     setErrors,
                     isSubmitting,
                     dirty,
                 }) => {
                    console.log("errrr line 187", errors)
                    return (<form className="floatLeft w100 mt60" onSubmit={handleSubmit}>
                        <FocusError offset={true}/>
                        <AviationAvocationForeignTravelForm questionList={questionList} clientSigned={clientSigned} isFromClientDetail={isFromClientDetail}
                                                            setDisableSaveButton={setDisableSaveButton} role={role} offerType={offerType}
                                                            setFieldValue={setFieldValue} enableSaveButton={enableSaveButton} touched={touched}
                                                            handleBlur={handleBlur} handleChange={handleChange} setSaveAviationAvocationQuestions={setSaveAviationAvocationQuestions}
                                                            textFieldCommon={textFieldCommon}
                        />
                        <Button
                                variant="contained"
                                color="primary"
                                className="detailPageSubmitBtn"
                                type={"submit"}
                                onClick={()=>{
                                    //setIsSubmittedQuestions(true);
                                }}
                                ref={buttonRef}
                                style={{
                                    display: "none",
                                }}
                        >
                        </Button>
                    </form>)
                }}
        </Formik>
    </>
}

export default AviationAvocationForeignTravelContent;