import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    IconButton,
    Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { asDbDateFormat } from "../../utils/dateUtils";
import { maskPhoneNumber } from "../../utils/commonUtils";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeIcon: {
                float: "right",
                position: "absolute" as "absolute",
                right: 10,
                top: 15,
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root}>
                <Typography variant="h6">{children} {onClose ? (
                        <IconButton aria-label="close" className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}</Typography>

            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function LeadConfirmation(props: any) {
    const { formData, close, agentDetail } = props;
    const clientName = `${formData.firstName} ${formData.lastName}`;
    const strategyName: string = props.strategyName;

    const handleClose = () => {
        close();
    };

    return (
            <div>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Request Confirmation
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="subtitle1" component="strong">
                            Hello {clientName}
                        </Typography>
                        <Typography variant="body1" align="left" className="mb20 mt20">
                            {agentDetail &&
                                <span>
                                    We've received your request for more information regrading  {strategyName}
                                    {agentDetail.firstName}  {agentDetail.lastName} will be in contact with you soon. Also please feel
                                    free to contact {agentDetail.firstName} at {maskPhoneNumber(agentDetail.phoneNumber)} or email {agentDetail.email}.
                                </span>
                            }
                            {!agentDetail &&
                            <span>
                                We've received your request for more information regrading {strategyName} and
                                one of our licensed professionals will be in contact with you soon. If you
                                don't hear from someone please contact us at 800-294-9940 ext-216
                                or email enrollment@myilia.com.
                            </span>
                            }
                        </Typography>
                        <Typography variant="body1" className="mb0"><strong>Your Name:</strong> <span
                                className="textBlackLight">{clientName}</span></Typography>
                        <Typography variant="body1" className="mb0"><strong>Your Email:</strong> <span
                                className="textBlackLight">{formData.email}</span></Typography>
                        <Typography variant="body1" className="mb0"><strong>Your Phone:</strong> <span
                                className="textBlackLight">{maskPhoneNumber(formData.phone)}</span>
                        </Typography>
                        <Typography variant="body1" className="mb0"><strong>Requested Time:</strong> <span className="textBlackLight">{
                            formData.specificDate ?
                                    `${asDbDateFormat(formData.specificDate)} ${formData.specificTime}` :
                                    "Next 2 business days"
                        }</span></Typography>
                    </DialogContent>
                </Dialog>
            </div>
    );
}
