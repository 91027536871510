import { Button, Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { Formik } from "formik";
import React, { useRef } from "react";
import { TextField } from "../formikFormInputs";
import { FormInputs } from "../../pages/approvedAgents/ApprovedAgentsPage";
import { DialogTitle } from "../ConfirmEnrollment";
import { Label } from "../formInputs";

export enum DialogAggregationDivisionType {
    ADD = "Add Aggregator Division",
    EDIT = "Edit Aggregator Division"
}

type DialogImoProps = any

export const DialogAddAggregatorDivision = ({open, handleClose, onSubmit, initialDivision, title, divisions}: DialogImoProps) => {
    const submit = (values: any, {setSubmitting, resetForm}: any) => {
        onSubmit(values)
        handleClose();
    }

    const formRef: any = useRef();

    const divisionFields = [
        {
            name: "divisionName",
            label: <Label label={"Enter Division Name"}/>,
            component: TextField,
            disabled: false
        }
    ]
    const validateField = (values) => {
        let errors: any = {};
        if(!values.divisionName){
            errors.divisionName = "Division Name is required."
        }
        if(divisions.find((item)=>item.divisionName.trim() === values.divisionName.trim())){
            errors.divisionName = "Division Name already added."
        }
        return errors;
    }
    return (
            <Dialog open={open} fullWidth maxWidth="sm">
                <DialogTitle onClose={handleClose} id="dialog-imo">
                    {title}
                </DialogTitle>
                <Formik
                        innerRef={formRef}
                        initialValues={initialDivision}
                        onSubmit={submit}
                        validate={(values)=> validateField(values)}
                >
                    {({errors, values, touched,
                          handleChange, handleBlur, handleSubmit, dirty, isSubmitting}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <DialogContent>
                                    <Grid container spacing={3}>
                                        <FormInputs
                                                fields={divisionFields}
                                                {...{errors, values, touched, handleChange, handleBlur}}
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Grid container justify="space-between" style={{padding: "1rem"}}>
                                        <Button
                                                size="small"
                                                className="floatLeft mt20 mb20"
                                                type="button"
                                                variant="contained"
                                                onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                                size="small"
                                                className="floatRight mt20 mb20"
                                                type="submit"
                                                variant="contained"
                                                disabled={!dirty || isSubmitting}
                                                color="primary"
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </DialogActions>
                            </form>
                    )}}
                </Formik>
            </Dialog>
    )
}