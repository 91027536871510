import React from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { CheckBoxGroup, ErrorCommon, TextField, TextFieldCommon } from "../../../../../../components/formikFormInputs";
import { Label } from "../../../../../../components/formInputs";
import { EnrollmentQuestionProps } from "../../../../../../model/enrollment";
import EnrollmentField from "./EnrollmentField";

type PropsType = {
    values: any,
    isFromClientDetail: boolean,
    clientSigned: boolean,
    errors: any,
    touched: any,
    handleChange: Function,
    handleBlur: Function,
    setFieldValue: Function,
    role: number,
    setSaveAviationAvocationQuestions: Function,
    offerType: string

}

const textFieldArr = [{
    name: "Insured_Foreign_TravelXT1",
    title: "To what foreign country (or countries) do you intend to travel?",
}, {
    name: "Insured_Foreign_TravelXT2",
    title: "How long do you plan to remain?",
}, {
    name: "Insured_Foreign_TravelXT3",
    title: "For what purpose is the trip made?",
}];

function getCheckBoxArr(isFromClientDetail: boolean, clientSigned: boolean) {
    return [{
        id: "foreignTravel_StayingInCity",
        name: "foreignTravel_StayingInCity",
        helperFieldText: "",
        label: "",
        fullWidth: true,
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        ischeckbox: "true",
        component: CheckBoxGroup,
        options: [{
            name: "Insured_Foreign_TravelXC4",
            text: "Staying in city",
        }],
    }, {
        id: "foreignTravel_TravelingAboutCountry",
        name: "foreignTravel_TravelingAboutCountry",
        helperFieldText: "",
        label: "",
        fullWidth: true,
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        ischeckbox: "true",
        component: CheckBoxGroup,
        options: [{
            name: "Insured_Foreign_TravelXC5",
            text: "Traveling about country",
        }],
    }];
}

const questionList = [{
    key: "",
    name: "Insured_Foreign_TravelXR6",
    label: "Have you traveled abroad before?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: true,
    additionalFieldName: "Insured_Foreign_TravelXT7",
    additionalFieldType: "textfield",
    additionalFieldRequired: true,
    additionalFieldLabel: "When and to what countries?",
}];

function InsuredForeignTravelSubQuestions(props: PropsType) {
    const {
        values,
        isFromClientDetail,
        clientSigned,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        role,
        setSaveAviationAvocationQuestions,
        offerType,
    } = props;
    const checkboxArr = getCheckBoxArr(isFromClientDetail, clientSigned);
    let filedProps = { onChange: handleChange, onBlur: handleBlur };
    filedProps["meta"] = { touched };
    filedProps["meta"] = { errors };

    const commonProps = {
        helperFieldText: "",
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt20",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    };
    return (
        values["InsuredAviationAvocation"].includes("Insured_ForeignTravel") &&
        <div className="pl16 pr16 innerContentWrap">
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1" component="strong" className="mt20 floatLeft w100">Foreign
                        Travel</Typography>

                    {
                            textFieldArr.length > 0 && textFieldArr.map((element, _index) =>
                                    <>
                                        <TextFieldCommon
                                                id={element.name}
                                                name={element.name}
                                                label={<Label label={`${element.title}`}/>}
                                                values={values}
                                                {...commonProps}
                                                {...filedProps}
                                        />
                                        <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                                    </>,
                            )
                    }
                </Grid>
                <span className="hLine"/>
                <Grid item xs={12}>
                    <Typography variant="body2" component="strong" className="floatLeft w100 mb15">Will you be
                        located in one or more of the larger cities, or will
                        you travel about the country?</Typography>
                    <Grid container>
                        {
                            checkboxArr.map((item, index) => {
                                        filedProps["input"] = {
                                            name: item.name,
                                            value: values[item.name],
                                            style: { display: "block" },
                                            onChange: (value, name) => {
                                                setFieldValue(name, value);
                                            },
                                        };
                                        return (<Grid item xs={12} md={6}><TextFieldCommon
                                                {...item}
                                                values={values}
                                                {...filedProps}
                                        />
                                            <ErrorCommon errors={errors} name={item.name} touched={touched}/>
                                        </Grid>);
                                    },
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                            <EnrollmentField
                                    question={question}
                                    clientSigned={clientSigned}
                                    isFromClientDetail={isFromClientDetail}
                                    checkIsError={() => {
                                    }}
                                    onChangeFn={() => {
                                    }}
                                    role={role}
                                    offerType={offerType}
                                    setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                            />
                    ))}
                </Grid>
            </Grid>
        </div>
    )
}

export default InsuredForeignTravelSubQuestions;