import * as React from "react";
import {
	Grid,
	CircularProgress,
	List, ListItem, ListItemText,
} from "@material-ui/core";
import { useActions } from "../actions";
import * as AccountActions from "../actions/account";

interface accountStatsType {
	pendingParticipants: number,
	cretaedParticipants: number,
	startedEnrollments: number,
	completedEnrollments: number,
	conversionRate: number
}

function Stats(props: any) {
	const accountActions: any = useActions(AccountActions);
	const [accountStats, setAccountStats] = React.useState<accountStatsType>({
		pendingParticipants: 0,
		cretaedParticipants: 0,
		startedEnrollments: 0,
		completedEnrollments: 0,
		conversionRate: 0,
	});
	const [loading, setLoading] = React.useState<boolean>(true);

	React.useEffect(() => {
		getAccountStats();
	}, [props.clientListId]);

	const getAccountStats = async () => {
		let queryObj: any = {};
		if (props.clientListId) {
			queryObj.clientListId = props.clientListId;
		}
		let accountStatsData: any = await accountActions.getAccountStats(queryObj);
		if (accountStatsData) {
			setAccountStats(accountStatsData);
			setLoading(false);
		}
	};

	return (
		<>
			{loading &&
			<Grid
				container
				spacing={2}
			>
				<CircularProgress/>
			</Grid>
			}

			{!loading &&
			<Grid
				container
				spacing={2}
			>
				<Grid item xs={12}>
					<List aria-label="contacts" className="statsList">
						<ListItem className="ListHead">
							<strong> Client List Total Stats:</strong>
						</ListItem>
						<ListItem>
							<ListItemText primary="Clients Invited:"/>
							<strong> {accountStats.pendingParticipants}</strong>
						</ListItem>

						<ListItem>
							<ListItemText primary="Accounts Created:"/>
							<strong> {accountStats.cretaedParticipants}</strong>
						</ListItem>
						<ListItem>
							<ListItemText primary="Started Enrollment:"/>
							<strong> {accountStats.startedEnrollments}</strong>
						</ListItem>
						<ListItem>
							<ListItemText primary="Completed Enrollments:"/>
							<strong> {accountStats.completedEnrollments} </strong>
						</ListItem>

						<ListItem>
							<ListItemText primary="Conversion Rate:"/>
							<strong> {accountStats.conversionRate}% </strong>
						</ListItem>
					</List>
				</Grid>
			</Grid>
			}
		</>
	);
}


export default Stats;
