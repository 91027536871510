import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Typography, Grid, Card, CardContent } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { RootState } from "../reducers/index";
import { Strategy } from "../model/account";
import { useActions } from "../actions";
import * as Actions from "../actions/verify";
import * as AuthActions from "../actions/auth";
import { SetPassword } from "../model";
import login from "../styles/images/login.png";
import loginlogo from "../styles/images/logo-ilia.png";
import InvitationForm from "../components/InvitationForm";
import { PageTitle } from "../components/formInputs";

type TParams = { inviteToken: string }
type Props = ReturnType<typeof mapStateToProps> & RouteComponentProps<TParams>;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    progress: {
        margin: theme.spacing(2),
    },

    logInWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        height: '100%',
        width: '100%',
        "&::after": {
            content: "\"\"",
            position: "absolute",
            background: "#3971EC",
            top: 0,
            bottom: 0,
            right: 0,
            width: "42%",
            [theme.breakpoints.down("sm")]: {
                position: "inherit",
                background: "transparent",
                width:'auto',
            },
        },

    },
    textWhite: {
        color: "#FFFFFF",
    },
    textCenter: {
        textAlign: "center",
    },
    mt10: {
        marginTop: 10,
    },
    mr30: {
        marginRight: 30,
    },
    loginCard: {
        margin: "auto",
        width: 865,
        boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
        background: "transparent !important",
        left: 0,
        right: 0,
        zIndex: 999,
        border: 0,
        padding: "2%",
        position:'relative',
        [theme.breakpoints.down("sm")]: {
            background: "#ffffff !important",
            width: "100%",
            margin:'0 15px',
        },
    },
    muiCardContent: {
        padding: 15,
        float: "left",
        width: "100%",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: "20px 16px 0",
        },
    },
    loginForm: {
        padding: "60px 15px 50px",
        float: "left",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            padding: "30px 0 15px",
        },
    },
    margin0: {
        margin: 0,
    },
    titleMain: {
        fontSize: 26,
        lineHeight: "34px",
        textAlign: "center",
        margin: 0,
        float: "right",
    },
    dpNone: {
        [theme.breakpoints.down(992)]: {
            display: "none",
        },
    },
    floatRight: {
        float: "right",
    },
    imgResponsive: {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        marginTop: "25%",
    },
    clickableLink: {
        display: "inline-flex",
        alignItems: "center",
        verticalAlign: "middle",
    },
    clickableLinksvg: {
        float: "left",
    },
    mr10: {
        marginRight: 10,
    },
    mt15: {
        marginTop: 15,
    },
}));


const InvitationPage: React.FC<Props> = (props: ReturnType<(state: RootState) => { firstName: string | undefined; lastName?: string; role: number | undefined; verifiedInvite: boolean | undefined; checked: boolean | undefined; strategy: Strategy | undefined; updated: boolean | undefined }> & RouteComponentProps<TParams> & { children?: React.ReactNode }) => {
    const classes = useStyles();
    const { firstName, lastName, role, strategy, history } = props;
    const inviteToken: string = props.match.params.inviteToken;
    const verifyActions = useActions(Actions);
    const authActions = useActions(AuthActions);
    const [progress, setProgress] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    React.useEffect(() => {
        async function verifyAccount() {
            const res = await verifyActions.verifyAccount(inviteToken);
            if (res.payload.message === "Link is expired") {
                props.history.push("/login");
            }
        }

        verifyAccount();
    }, []);

    React.useEffect(() => {
        if (props.checked && !props.verifiedInvite) {
            setProgress(false);
            setChecked(true);
        } else if (!props.checked) {
            setProgress(true);
            setChecked(false);
        } else if (props.verifiedInvite) {
            setProgress(false);
            setChecked(false);
        }
    }, [props]);

    const onSetPassword = async (data: { password: string, confirmPassword: string }, dispatch: Function) => {
        const payload: SetPassword = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            inviteToken: inviteToken,
        };
        const result = await verifyActions.setPassword(payload, true);
        if (role === 5) {
            history.push("/accounts");
            return;
        }
        if (result.statusCode === "200") {
            dispatch(reset("InvitationForm"));
            history.push("/login");
        } else if (result.payload && result.payload.user) {
            dispatch(reset("InvitationForm"));
            authActions.getAccountDetails();
        } else {
            history.push("/login");
        }
    };
    if (progress) {
        return (
                <div className={classes.root}>
                    <CircularProgress className={classes.progress}/>
                    <Typography>Verifying Link</Typography>
                </div>
        );
    }

    if (checked) {
        return (
                <div className={classes.root}>
                    <Typography component="h2">Please contact site administrator. Link is invalid or
                        expired</Typography>
                </div>
        );
    }


    return (
            <div className={classes.logInWrap}>
                <PageTitle title="Login Page"/>
                        <Card className={classes.loginCard}>
                            <CardContent className={classes.muiCardContent}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                                        <img alt="loginLogo" src={loginlogo}/>
                                        <Typography variant="h5">
                                            <p>Hello {firstName} {(role === 5) && lastName}</p>
                                            {(role === 5) ?
                                                    <span className={classes.mt10}>Please create a password to log in to ilia.</span>
                                                    :
                                                    <span className={classes.mt10}>Welcome to ilia please create a password.</span>
                                            }
                                            {
                                                (role === 6) && strategy &&
                                                <p>{(strategy && strategy.displayName) || ""}</p>
                                            }
                                        </Typography>
                                        <InvitationForm onSetPassword={onSetPassword} role={role}/>
                                    </Grid>
                                    <Grid item xs={12} md={5} lg={5} className={classes.dpNone}>
                                        <Typography variant="h3" align="right"
                                                    className={classes.textWhite + " " + classes.mr30 + " " + classes.titleMain}>
                                            {(role === 5) ?
                                                    "Create a Password"
                                                    :
                                                    "Set Password"
                                            }
                                        </Typography>
                                        <img className={classes.floatRight + " " + classes.imgResponsive} src={login}
                                             alt="login"/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
            </div>
    );

};

function mapStateToProps(state: RootState) {
    return {
        verifiedInvite: state.verify.verifiedInvite,
        checked: state.verify.checked,
        updated: state.verify.updated,
        firstName: state.verify.firstName,
        lastName: state.verify.lastName,
        role: state.verify.role,
        strategy: state.verify.strategy,
    };
}

export default connect(mapStateToProps)(InvitationPage);





