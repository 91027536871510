import * as React from "react";
import {
	Dialog,
	DialogContent,
	DialogContentText,
	Typography,
	IconButton	
} from "@material-ui/core";
import {Link} from 'react-router-dom';
import MuiDialogTitle  from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
  }

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
	  <MuiDialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
		  <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
		  </IconButton>
		) : null}
	  </MuiDialogTitle>
	);
  });

export default function ConfirmEnrollment(props: any) {
	const { open, setOpen, user} = props;
	return (
		<Dialog
			open={open}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClose={() => setOpen(false)}
		>
			<DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
				{/* Title */}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Hello <b>{user.firstName}</b>, To Enroll Online, you must have your profile completed.
					Please visit your profile page here to provide us with the required information.

					<span style={{ margin: 10 }}>
						<Link to={'/profile'} onClick={() => setOpen(false)}>
							Go to Profile
						</Link>
					</span>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	)
}
