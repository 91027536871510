import React from "react";
import AOS from "aos";
import { Container, Grid, Typography, Card, CardContent, Link } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import { GroupRebrandForm } from "./index";
import { PlayCircleFilled } from "@material-ui/icons";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../../components/dialog";
import "aos/dist/aos.css";
import { ATLANTIC_HEALTH } from "../../../../../constants";
import UnAuthenticationForm from "../trizen/UnAuthenticationForm";
import Chatbot from "./chatBubble";
import GroupEstimationForm from "../../../../../components/client/Estimation/Group/GroupEstimation";

// You can also pass an optional settings object
// below listed default settings
AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
    initClassName: "aos-init", // class applied after initialization
    animatedClassName: "aos-animate", // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation

});
const useStyles = makeStyles((theme) =>
        createStyles({
            getStarted: {
                textAlign: "left",
                position: "relative",
                overflow: "visible",
                maxWidth: "100%",
                marginLeft: "40px",
                float: "left",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "0",
                },
                [theme.breakpoints.down("sm")]: {
                    maxWidth: "100%",
                    width: "100%",
                },
            },
        }),
);

function GroupRebrand(props: any) {
    const classes = useStyles();
    const { strategyVideoUrl, strategy, notAuthenticat } = props;
    const strategyName = strategy.name.toUpperCase();
    const [showVideo, setShowVideo] = React.useState(false);
    let searchParams: string = props && props.location.search;
    const UrlClass = new URLSearchParams(searchParams)
    const landing = UrlClass.get("landing");
    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "customized-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (
                <ReactPlayer
                        controls={true}
                        playing={false}
                        loop={true}
                        autoPlay={true}
                        volume={1}
                        muted={false}
                        url={strategyVideoUrl}
                        width="100%"
                        playsinline={true}
                />),
        dividers: false,
        onClose: () => setShowVideo(false),
        open: showVideo,
        title: "",
        onBackdropClick: () => setShowVideo(false),
    };

    let  companyId: string = props.groupDetail ? props.groupDetail.companyLinkUrl : "0";

    const groupCondition = (props: {companyId:string }) => {
        let result: any;
        const { companyId, } = props;
        if(ATLANTIC_HEALTH.includes(companyId)){
            result = {
                heading: "Atlantic Health Unveils Exclusive 50% Match Benefit Package for Physicians",
                body: " Your retirement will cost you an average of 6 times more than your house. Most people use financing to live in a better house, so why aren’t you using financing to help you fund a better retirement?",
            }
        } else {
            result = {
                heading: "Don’t Simply Retire. Have Something to Retire To.",
                body: " Your retirement will cost you an average of 6 times more than your house. Most people use financing to live in a better house, so why aren’t you using financing to help you fund a better retirement?",
            }
        }
        return result;
    };
    let group = groupCondition({ companyId });

    return (
            <Container maxWidth="lg">
                <Grid container spacing={4} alignItems={ !notAuthenticat ? "center" : "flex-start"}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography variant="body2" className="colorWhite">THE {strategyName}<sup>&reg;</sup> STRATEGY</Typography>
                        <Typography variant="h2" className="colorWhite textShadow" data-aos="fade-up" data-aos-duration="1500">
                            {group.heading}
                        </Typography>
                        <Typography className="colorWhite mb30 floatLeft w100" variant="subtitle1" data-aos="fade-up" data-aos-duration="1000">
                            {group.body}
                        </Typography>
                        <ReactPlayer
                                data-aos="fade-up"
                                data-aos-duration="1300"
                                className="kaiZenHero"
                                controls={true}
                                playing={false}
                                loop={true}
                                autoPlay={true}
                                volume={1}
                                muted={false}
                                url={strategyVideoUrl}
                                width="100%"
                                height="100%"
                                playsinline={true}
                        />
                        <span
                                className="videoHeroOne"
                                onClick={() => setShowVideo(true)}
                                data-aos="fade-up"
                                data-aos-duration="1000"
                        >
                            <PlayCircleFilled className="f70 mr15 textWhite" />
                            <div>
                                <Typography className="textHighLight f16">2 Minute Video</Typography>
                                <Typography className="textWhite f18">Learn about Kai-Zen</Typography>
                            </div>
                      </span>
                        {
                                showVideo &&
                                <GenericDialog dialogClassName="kaiZenMobile" {...dialogProps} />
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6} className={ !notAuthenticat ?  "" : "unAuthenticatGroup"}>
                        <Card className={!notAuthenticat ? classes.getStarted : "unAuthenticatGroupCard"}>
                            <CardContent className={(notAuthenticat || Number(landing) == 0) ? "UnheroCard" : "heroCard"}>
                                <div id="base" className={notAuthenticat ? "displayNone" : "displayUnset"}>
                                    <Typography variant="subtitle2" className="textHighLight mb0 ">
                                        60 - 100%
                                    </Typography>
                                    <Typography variant="body2" className="textWhite mb0">
                                        More for Retirement
                                    </Typography>
                                    <div id="chevron">&nbsp;</div>
                                </div>
                                {notAuthenticat ?
                                        <>
                                            <UnAuthenticationForm {...props} notAuthenticat={notAuthenticat} />
                                            <Chatbot/>
                                        </>
                                        :
                                        <Steps {...props} />
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
    );
}

export default GroupRebrand;

const Steps= (props) =>{
    const [activeForm, setActiveForm] = React.useState<any>(0)
    let searchParams: string = props && props.location.search;
    let param = props.match.params;
    const isAtlanticHealth = ATLANTIC_HEALTH.indexOf(param.id) !== -1;
    const UrlClass = new URLSearchParams(searchParams)
    const landing = UrlClass.get("landing");
    React.useEffect(()=>{
        setActiveForm(Number(landing) || 0)
    },[props.location])
    const getsteps = () =>{
        switch (activeForm){
            case 0:
             return <>
                 <Typography
                         align="left"
                         className="textHighLight floatLeft w100 mb0 mt20"
                         component="strong"
                         id="get-started"
                         variant="body1"
                 >
                     GET STARTED
                 </Typography>
                 <Typography
                         variant="h3"
                         className="floatLeft w100 mb15">
                     See what your Kai-Zen looks like.
                 </Typography>
                 <GroupEstimationForm isAtlanticHealth={isAtlanticHealth} onSubmit ={()=>{
                     props.history.push({
                         pathname: `${props.location.pathname}`,
                         search: `?${new URLSearchParams({ landing: "0.1" })}`,
                     });
                 }} /></>
            default:
                return <>
                    <GroupRebrandForm {...props} />
                </>

        }
    }

     return <>{getsteps()}</>

}