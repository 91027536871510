import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../../components/dialog";
import CreateCaseAlertDialogContent from "./CreateCaseAlertDialogContent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";

export default (props: { policyNumber: number, handleUpdateAlert: Function }) => {
    const { policyNumber } = props;
    const [createCaseAlert, setCreateCaseAlert] = React.useState(false);
    const [removeCaseAlert, setRemoveCaseAlert] = React.useState(false);
    const detail: any = useSelector((state: RootState) => state.clientAccount);
    const clientCaseAlert: any = detail && detail.client && detail.client.ClientCaseAlerts && detail.client.ClientCaseAlerts.id;
    const onCloseBtn = () => {
        setCreateCaseAlert(false);
        setRemoveCaseAlert(false);
    }
    const onRemoveBtn = () => {
        setRemoveCaseAlert(true);
        setCreateCaseAlert(true);
    }
    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "Create Case Alert",
        fullWidth: true,
        maxWidth: "sm",
        content: (<CreateCaseAlertDialogContent onClose={() => onCloseBtn()} policyNumber={policyNumber}
                                                description={detail && detail.client && detail.client.ClientCaseAlerts && detail.client.ClientCaseAlerts.description}
                                                removeCaseAlert={removeCaseAlert} handleUpdateAlert={props.handleUpdateAlert} />),
        dividers: true,
        onClose: onCloseBtn,
        open: createCaseAlert,
        title: `${removeCaseAlert ? `Delete` : clientCaseAlert ? `Update` : `Create`} Case Alert for ${detail && detail.firstName} ${detail && detail.lastName}`,
        onBackdropClick: onCloseBtn,
    };
    return (
            <Grid item xs={12}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <WarningIcon className="colorOrg mr10 floatLeft" /> {(clientCaseAlert) ? <> Active Case
                        Alert </> : <> Case Alerts: No Current Alert </>}
                    </Box>
                    <Box>
                        {(clientCaseAlert) ?
                                <>  <Button onClick={() => setCreateCaseAlert(true)}
                                            color="primary"> Edit Alert </Button> <Button
                                        onClick={() => onRemoveBtn()} color="primary"> Remove
                                    Alert </Button> </> :
                                <>
                                    <Button onClick={() => setCreateCaseAlert(true)}  color="primary"> Create Case
                                        Alert </Button>
                                </>
                        }
                    </Box>
                </Box>
                {
                        createCaseAlert &&
                        <GenericDialog dialogClassName="kaiZenMobile" {...dialogProps} />
                }
            </Grid>
    );
};