import React from 'react';
import { IllustrationButton } from "../EstimationTabs";

function RenderIllustrationButton(props:{callingFrom?:string, role:Number, isReportDataLoading:boolean, setIsReportRequestCompleted:Function, classes:any})  {
    const {callingFrom, role, isReportDataLoading, setIsReportRequestCompleted, classes} = props;
    if (!(!!callingFrom)) {
        return (<></>);
    }

    switch (callingFrom) {
        case "ESTIMATOR":
        case "CUSTOM_DESIGN":
            return (
                    <IllustrationButton
                            isReportDataLoading={isReportDataLoading}
                            setIsReportRequestCompleted={setIsReportRequestCompleted}
                            classes={classes}
                            callingFrom={callingFrom}
                    />
            );
            break;
        default:
            return <></>;
    }
}

export default RenderIllustrationButton;