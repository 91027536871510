import React from "react";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../components/dialog";
import { IconButton, Link, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { tr } from "date-fns/locale";


export default function ConfirmContributionFrequency(props: any) {
    const { paymentFrequency } = props;
    const [ConfirmContributionFrequency, setConfirmContributionFrequency] = React.useState(true);
    const ConfirmFrequencyyDialogProps: GenericDialogProps = {
        "aria-labelledby": "terms-and-conditions-dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <pre></pre>
                    <Typography variant="body1" className="mt15 mb15">
                        Please confirm with your client that they want to make
                        a {paymentFrequency === "annual" ? "annual" : "monthly"} contribution towards their Kai-Zen
                        policy.
                    </Typography>
                    <Typography variant="body1" className="mb15">
                        If your client wants to switch
                        to {paymentFrequency === "annual" ? "an annual" : "a monthly "} contribution frequency, please
                        email info@kaizenplan.com or call 972-755 1582 (Ext 216)
                    </Typography>
                    <Link variant="caption" className="mb20 floatLeft cursor w100"
                          onClick={() => setConfirmContributionFrequency(false)}> Click Here to complete action
                        items.</Link>
                </>
        ),
        dividers: true,
        onClose: () => setConfirmContributionFrequency(false),
        open: ConfirmContributionFrequency,
        title: `Confirm your client's ${paymentFrequency === "annual" ? "annual" : "monthly"} contribution frequency.`};

    return (

            <>
                {ConfirmContributionFrequency &&
                        <GenericDialog {...ConfirmFrequencyyDialogProps} />
                }

            </>

    );
}