import {parse} from 'json2csv';
import moment from "moment";
import {ActionType} from "../model";
import {downloadAsCSV} from "../utils/commonUtils";
const prefix = 'agent-links';

export const reportingList = (payload: { page:number, rowsPerPage:number, searchText : string, startDate : string, endDate:string }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get(`${prefix}/reporting`, {}, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const reportingInviteList = (payload: { page:number, rowsPerPage:number, searchText : string, startDate : string, endDate:string }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get(`${prefix}/reporting/invites`, {}, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const generateAgentLinks = (agentId:number) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        let resp = await api.post(`${prefix}/generate`, {agentId}, {});
        if(resp && resp.length){
            dispatch({
                type: ActionType.ALERT,
                payload: {
                    message: "Links generated successfully.",
                    statusCode: 200
                }
            });
        } else{
            dispatch({
                type: ActionType.ALERT,
                payload: {
                    message: "Sorry we can not generate links because this agent have no carrier!",
                    statusCode: 400
                }
            });
        }
        return
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getAgentLinks = (userId:string) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get(`${prefix}`, {}, {userId});
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getAgentLinkDetailByGuid = (guid:string) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        let resp = await api.get(`${prefix}/${guid}`, {});
        dispatch({ type: ActionType.VERIFY_INVITATION, payload: resp });
        return resp;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getInviteStats = () => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get(`account/admin/invite-stats`);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const exportInviteStats = (statType:string, type:string="csv") => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const url = `account/admin/invite-stats/export?statType=${statType}`;
                let result = await api.get(url);

                if (type === "json") {
                    return result;
                } else {

                    let fields: { label:string, value: any }[] = [
                        {
                            label: "Client Name",
                            value: (row: any) => row.firstName + ' ' + row.lastName
                        },
                        {
                            label: "Client Email",
                            value: "email"
                        },
                        {
                            label: "Client Phone",
                            value: "phoneNumber"
                        },
                        {
                            label: "Agent Name",
                            value: (row: any) => (row.client && row.client.agent && row.client.agent.user) ? row.client.agent.user.firstName + ' ' + row.client.agent.user.lastName : ''
                        },
                        {
                            label: "ilia Product Type",
                            value: (row: any) => row.client.strategy ? row.client.strategy.name : ''
                        },
                        {
                            label: "Carrier",
                            value: (row: any) => row.client.carrier ? row.client.carrier.carrierName : ''
                        },
                        {
                            label: "Date Created",
                            value: (row: any) =>row.createdAt ? moment(row.createdAt).format("MM-DD-YYYY"): ''
                        },
                    ];

                    if (result.length === 0) {
                        result = [{
                            email: '',
                            createdAt: '',
                            'strategy.name': ''
                        }];
                    }

                    const [fileName, csvRes] = [`totalInvites`, parse(result, {fields})];
                    downloadAsCSV(fileName, csvRes);

                    return dispatch({
                        type: ActionType.ALERT,
                        payload: {message: "Exported successfully", statusCode: 200},
                    });
                }
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};