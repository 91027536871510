import {useFormikContext} from "formik";
import React from "react";
import {
    TextFieldCommon
} from "../../../../../../../components/formikFormInputs";
import {Grid} from "@material-ui/core";
import {ErrorHelper} from "../../../../../../../components/formikFormInputs/ErrorCommon";
import {advisorInformation3rdParty} from "../FinancialAgreement/FinancialQuestions";

const FinancialQuestions10 = (props:{clientSigned:boolean, offerType:string }) => {
    const { values, handleChange, handleBlur, errors }:any = useFormikContext();
    const { clientSigned, offerType } = props;
    const fieldObj:any[] = advisorInformation3rdParty({clientSigned, offerType})

        return <React.Fragment>
            {fieldObj.map((field, index) => {
                let size:any = field.size;
                return (
                        <Grid item
                              xs={size.xs === 3 ? 3 : size.xs === 4 ? 4: size.xs === 6 ? 6 : size.xs === 9 ? 9 : 12}
                              sm={size.sm === 3 ? 3 : size.sm === 4 ? 4: size.sm === 6 ? 6 : size.sm === 9 ? 9 : 12}
                              md={size.md === 3 ? 3 : size.md === 4 ? 4: size.md === 6 ? 6 : size.md === 9 ? 9 : 12}
                              lg={size.lg === 3 ? 3 : size.lg === 4 ? 4: size.lg === 6 ? 6 : size.lg === 9 ? 9 : 12}
                              xl={size.xl === 3 ? 3 : size.xl === 4 ? 4: size.xl === 6 ? 6 : size.xl === 9 ? 9 : 12}>
                            <TextFieldCommon
                                    {...field}
                                    values={values}
                                    onChange={(e: any) => handleChange(e) }
                                    onBlur={handleBlur}
                            />
                            <ErrorHelper errors={errors} name={field.name} />
                        </Grid>
                )})}
        </React.Fragment>
}

export default FinancialQuestions10;