import {
    Grid,
    Typography,
    Divider, ButtonGroup, Button, makeStyles, Theme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { change, Field } from "redux-form";
import { TextField, PhoneNumberField, SelectField, Label, CheckBox, NumberTextField } from "./formInputs";
import PrimaryContactDetail from "./PrimaryContactDetail";
import { email, isEmpty, required, zipCodeValidation, validatePhoneNumber } from "../utils/validation";
import { Strategy } from "../model/account";
import { useSelector, connect } from "react-redux";
import { RootState } from "../reducers";
import { stateDropDown } from "../constants";
import { useActions } from "../actions";
import * as Actions from "../actions/account";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme: Theme) => ({
    carrierColor: {
        backgroundColor: "#dbe6f3",
        color: "#000",
    },
}));

function AddCarrier(props: any) {
    const {emailExist, setEmailExist, setEmailRef} = props;
    const accountActions = useActions(Actions);
    const classes = useStyles();
    const carrierAccount = useSelector((state: RootState) => state.account);
    const strategyList = useSelector((state: RootState) => state.strategies);
    const auth = useSelector((state: RootState) => state.auth);
    const [selectedStrategy, setSelectedStrategy] = React.useState<Strategy[]>([]);

    const dispatch = props.dispatch;
    const formName = props.page;
    const isOnBoard = props.isOnBoard;
    React.useEffect(() => {
        let strategyListArr: Strategy[] = [];

        if (carrierAccount.carrier && carrierAccount.carrier.carrierStrategies && strategyList.data) {
            carrierAccount.carrier.carrierStrategies.forEach((s: any) => {
                if (s.strategies) {
                    const strategy = strategyList.data.map((p) => p.id).find(p => p === s.strategies.id);
                    if (strategy) {
                        strategyListArr.push({
                            carrierStrategyId: s.id,
                            id: s.strategies.id,
                            name: s.strategies.name, //from api displayName is fetched as name
                        });
                    }
                }
            });
            setSelectedStrategy(strategyListArr);
            dispatch(change(formName, "carrier.carrierStrategies", strategyListArr));
            dispatch(change(formName, "carrier.carrierStrategy", {}));
        }
    }, [carrierAccount, strategyList]);

    React.useEffect(() => {
        return (() => {
            accountActions.removeAccountFromStore();
        });
    }, []);

    // Strategy
    const getStrategyDropDown = () => {
        let strategyListArr: object[] = [];
        strategyList.data && strategyList.data.forEach(s => {
            const strategy = selectedStrategy.find(p => p.id === s.id);
            if (!strategy) {
                const pushTo: object = {
                    key: s.id,
                    value: s,
                    name: s.displayName,
                };
                strategyListArr.push(pushTo);
            }
        });
        return strategyListArr;
    };

    const selectedCarrierStrategy = () => selectedStrategy.map(item =>
            <div key={item.id} className="buttonGroupGrid">
                <ButtonGroup
                        variant="contained"
                        aria-label="split button"
                        className="muiButtonGroup">
                    <Button className={classes.carrierColor}>
                        {item.name}
                        <span>
									<CloseIcon onClick={() => handleDeleteSelectedStrategy(item.id || 0)}/>
								</span>
                    </Button>
                </ButtonGroup>
            </div>);

    const handleDeleteSelectedStrategy = (id: number) => {
        const index = selectedStrategy.findIndex(p => p.id === id);
        if (index >= 0) {
            selectedStrategy.splice(index, 1);
            setSelectedStrategy(() => ([...[], ...selectedStrategy]));
            deleteStrategy(id);
        }
        dispatch(change(formName, "carrier.carrierStrategies", selectedStrategy));
        dispatch(change(formName, "carrier.carrierStrategy", {}));
    };

    const handleChangeStrategy = async (event: React.FormEvent<HTMLSelectElement>) => {
        const newSelectedStrategy = selectedStrategy;
        const strategy = event as Strategy;
        if (strategy && strategy.id) {
            let strategyObject = {
                carrierStrategyId: strategy.id,
                name: strategy.displayName,
                id: strategy.id,
            };
            if (formName == "EditAccountPage") {
                let response = await addStrategy(strategy.id);
                if (response && response.id) {
                    newSelectedStrategy.push(Object.assign({}, strategyObject));
                }
            } else { //case of add account
                newSelectedStrategy.push(Object.assign({}, strategyObject));
            }
            setSelectedStrategy(() => ([...[], ...newSelectedStrategy]));
        }
        dispatch(change(formName, "carrier.carrierStrategies", newSelectedStrategy));
        dispatch(change(formName, "carrier.carrierStrategy", {}));
    };

    const addStrategy = async (strategyId: number) => {
        let carrierId = (carrierAccount.carrier && carrierAccount.carrier.id) || 0;
        if (carrierId) {
            return await accountActions.addCarrierStrategy(carrierId, strategyId);
        }
    };

    const deleteStrategy = (strategyId: number) => {
        let carrierId = (carrierAccount.carrier && carrierAccount.carrier.id) || 0;
        if (carrierId) {
            accountActions.deleteCarrierStrategy(carrierId, strategyId);
        }
    };

    const validateCarrierGrowth = React.useCallback((value: number) => {
        if (!isEmpty(value) && parseFloat(value.toString()) <= 0) {
            return "Not valid carrier growth rate"
        }
    }, []);
    return (
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <Typography component="strong" variant="subtitle1" className="mb20 w100 colorBlue">
					{carrierAccount.carrier && carrierAccount.carrier.carrierName} Details <div
                            className="floatRight pd0 carrierCheck"> <Field
                            label={<Label label={"On-Board"}/>}
                            name="carrier.onBoard"
                            component={CheckBox}
                            parse={(value: unknown) => Number(value)}
                            onClick={(value: unknown) => console.log("value", value)}
                            disabled={isOnBoard ? true : false}
                    /></div>
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label={<Label label={"Carrier Name"} required={true}/>}
                            name="carrier.carrierName"
                            placeholder="enter carrier name"
                            component={TextField}
                            validate={[required]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label={<Label label={"Carrier Product Name"} required={true}/>}
                            name="carrier.carrierProductName"
                            placeholder="enter carrier product name"
                            component={TextField}
                            validate={[required]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label={<Label label={"Carrier Email"} required={true}/>}
                            name="carrier.carrierEmail"
                            placeholder="email@domain.com"
                            component={TextField}
                            validate={[email, required]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label="Carrier Phone Number"
                            name="carrier.carrierPhone"
                            placeholder="enter carrier phone number"
                            component={PhoneNumberField}
                            validate={[validatePhoneNumber]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            name='carrier.carrierStrategy'
                            label={<Label label={"Select ilia Product Type"} required={true}/>}
                            component={SelectField}
                            onChange={handleChangeStrategy}
                            options={getStrategyDropDown()}
                            validate={selectedStrategy.length === 0 ? [required] : []}
                    />
                    <span className="txtPlaceholder">Select Multiple ilia Product Type</span>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    {selectedCarrierStrategy()}
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label={<Label label={"Carrier Growth Rate"} required={true}/>}
                            name="carrier.growthRate"
                            placeholder="enter carrier growth rate"
                            component={NumberTextField}
                            validate={[required, validateCarrierGrowth]}
                            InputProps={{
                                endAdornment: <InputAdornment className="margin0"
                                                                position="start">%</InputAdornment>,
                            }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label={<Label label={"Email for docs to go"} required={true}/>}
                            name="carrier.emailForDocs"
                            placeholder="email@domain.com"
                            component={TextField}
                            validate={[required, email]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label={<Label label={"Email 2 for docs to go"} required={true}/>}
                            name="carrier.emailForDocs2"
                            placeholder="email@domain.com"
                            component={TextField}
                            validate={[required, email]}
                    />
                </Grid>
                <Divider/>
                <Grid item xs={12} sm={12}>
                    <Typography component="strong" variant="subtitle1" className="floatLeft mb10 colorBlue">
                        Carrier Address
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label="Carrier Street"
                            name="carrier.carrierStreet"
                            placeholder="carrier street"
                            component={TextField}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label="Carrier City"
                            name="carrier.carrierCity"
                            placeholder="carrier city"
                            component={TextField}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label="Carrier State"
                            name="carrier.carrierState"
                            component={SelectField}
                            options={stateDropDown}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="mb10">
                    <Field
                            fullWidth
                            label="Carrier ZipCode"
                            name="carrier.carrierZipCode"
                            placeholder="carrier zipCode"
                            component={TextField}
                            validate={[zipCodeValidation]}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography component="strong" variant="subtitle1" className="floatLeft mb10 colorBlue">
                        Carrier Primary Contact
                    </Typography>
                </Grid>
                <PrimaryContactDetail
                        setEmailRef={setEmailRef}
                        emailExist={emailExist}
                        setEmailExist={setEmailExist}
                        disabledEmail={auth.user.role === 2 && formName === "EditAccountPage" ? true : false}
                        disabledPhone={auth.user.role === 2 && formName === "EditAccountPage" ? true : false}
                />
            </React.Fragment>
    );
}

function mapStateToProps(state: RootState) {
    return {
        isOnBoard: state.account && state.account.carrier ? state.account.carrier.onBoard : 0,
    };
}

export default connect(mapStateToProps)(AddCarrier);
