import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogTitleProps } from "./ViewDocument";
import { blue } from "@material-ui/core/colors";
import { Label, SelectField, TextField } from "../../../../../components/formInputs";
import { Field, reduxForm } from "redux-form";
import { RootState } from "../../../../../reducers";
import { connect } from "react-redux";
import { required } from "../../../../../utils/validation";
import { useActions } from "../../../../../actions";
import * as EnrolmentActions from "../../../../../actions/enrollment";
import { DocApprovalStatus, DocRequestedFromTab } from "../../../../../constants";

const defaultContent = "";
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            wrapper: {
                position: "relative",
            },
            buttonProgress: {
                color: blue[500],
                position: 'absolute',
                top: '10px',
                left: '50%',
                marginTop: '0px',
                width: '100%'
            },
        })
);
const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: '18px',
                color: theme.palette.grey[500],
            },
        });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, classes, onClose, ...other} = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon className="f20"/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});
type Props = any;

const documentDrop = [
    {
        key: 1,
        value: "Drivers License",
        name: "Drivers License",
    },
    {
        key: 2,
        value: "Passport",
        name: "Passport",
    },
    {
        key: 3,
        value: "W2’s",
        name: "W2’s",
    },
    {
        key: 4,
        value: "Tax Returns",
        name: "Tax Returns",
    },
    {
        key: 5,
        value: "1099’s",
        name: "1099’s",
    },
    {
        key: 6,
        value: "Utility Bill",
        name: "Utility Bill",
    },
    {
        key: 7,
        value: "Visa",
        name: "Visa",
    },
    {
        key: 8,
        value: "Green Card",
        name: "Green Card",
    },
    {
        key: 9,
        value: "Permanent Residency Card",
        name: "Permanent Residency Card",
    },
    {
        key: 10,
        value: "Other",
        name: "Other",
    }
]

function RequestClientDocModal(props: { user: any, open: boolean, handleClose: any, content: string, handleSubmit: Function, clientDetail: any, pristine: boolean,
    document?:any, handleRefresh?: Function
}) {
    const {
        user,
        open,
        handleClose,
        content = defaultContent,
        handleSubmit,
        clientDetail,
        pristine,
        document,// Note: document in props means this component is calling from Document tab.
        handleRefresh
    } = props;
    const classes = useStyles();
    const enrollmentActions = useActions(EnrolmentActions);
    const [selectedDoc, setSelectedDoc] = React.useState("");
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const [userId, setUserId] = React.useState("");
    const [clientId, setClientId] = React.useState(0);
    const [dropDownSelect, setDropDownSelect] = React.useState<any>([]);

    React.useEffect(() => {
        if (user && user.client && user.client.userId) {
            setUserId(user.client.userId);
            setClientId(user.client.id);
        }
    }, [user])

    React.useEffect(() => {
        if (!document && clientDetail && clientDetail.client) {
            let list = documentDrop.filter((item, index) => {
                if (clientDetail.client.identityType === "drivingLicence") {
                    return item.value !== "Passport";
                } else {
                    return item.value !== "Drivers License";
                }
            });
            list = list.filter((item, index) => {
                if (clientDetail.client.isDocumentAddress || clientDetail.client.identityType === "drivingLicence") {
                    return item.value !== "Utility Bill";
                } else {
                    return item;
                }
            });
            list = list.filter((item, index) => {
                if (clientDetail.client.usCitizen)
                {
                    return item.value !==  "Visa" && item.value !== "Green Card" && item.value !== "Permanent Residency Card" ;
                } else {
                    return item;
                }
            });
            setDropDownSelect(list);
        }
        if(document && document.id){
            setDropDownSelect([{
                key: document.id,
                value: document.documentName,
                name: document.name,
            }])
        }
    }, [clientDetail]);
    const handleDocSelect = (e: string) => {
        setSelectedDoc(e);
    }

    const handleSendRequest = async (data: any) => {
        data.userId = userId;
        data.clientId = clientId;
        setButtonLoader(true);
        if (selectedDoc !== "Other") {
            delete data.otherDocName
        }
        if (document && document.id) { //Note: name(with front and back text) and docName means this component is calling from Document tab.
            data.docRequestedFrom = DocRequestedFromTab.DOCUMENTS_TAB;
            data.documentId = document.id;
            data.docAction = DocApprovalStatus.DOCUMENT_REQUESTED;
            data.docFrontOrBack = document.frontOrBack;
            data.documentType = document.documentType;
        } else {
            data.docRequestedFrom = DocRequestedFromTab.ENROLLMENT_TAB;
        }
        await enrollmentActions.requestClientDocuments(data);
        setButtonLoader(false);
        handleClose();
        if (document && typeof handleRefresh === "function") {
            handleRefresh();
        }
    };
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {content}
                </DialogTitle>
                <form onSubmit={handleSubmit(handleSendRequest)}>
                    <DialogContent dividers>
                        <div id="alert-dialog-description">
                            <Typography variant="body1" className="mb0">You can request documents from this client. They
                                will receive an email with the message you enter below directing them to log in and
                                upload
                                from their portal.</Typography>
                        </div>
                        <Grid className="mt20" container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Field
                                        name="documentName"
                                        label={<Label label={"Document DropDown List"} required={true}/>}
                                        component={SelectField}
                                        options={dropDownSelect}
                                        handleClick={handleDocSelect}
                                        validate={required}
                                />
                            </Grid>
                            {
                                selectedDoc === "Other" &&
                                <Grid item xs={12} sm={12} md={6}>
                                    <Field
                                            fullWidth
                                            name="otherDocName"
                                            component={TextField}
                                            label="Document Name"
                                            validate={required}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Field
                                        placeholder="Write a detailed description message to the client of what document(s)
                                 they need to upload and why."
                                        fullWidth
                                        name="message"
                                        label={<Label label={"Message"} required={true}/>}
                                        multiline
                                        rows={4}
                                        component={TextField}
                                        validate={required}
                                />
                            </Grid>
                        </Grid>
                        <span className="colorRed"><sup>*</sup>Required Fields</span>
                    </DialogContent>
                    <DialogActions>
                        <Button
                                className=" floatLeft"
                                type="submit"
                                size="small"
                                variant="contained"
                                color="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                                className="bgBlue"
                                type="submit"
                                size="small"
                                variant="contained"
                                disabled={pristine || buttonLoader}
                                color="primary" autoFocus>
                            Send Request
                            {buttonLoader &&
                            <CircularProgress className={classes.buttonProgress}
                                              size={24}/>}
                        </Button>
                        <span className="clearFix"/>

                    </DialogActions>
                </form>


            </Dialog>
    );
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        initialValues: {message: "", documentName: state.docForRequest},
    };
}

const reduxConnect = reduxForm<{}, Props>({
    form: "RequestClientDocModal",  // a unique identifier for this form
    enableReinitialize: true,
})(RequestClientDocModal);

export default connect(mapStateToProps)(reduxConnect);