import React from "react";
import {Field, useFormikContext} from "formik";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FormHelperText, Grid, Typography} from "@material-ui/core";
import {TextField, DatePicker} from "../../../../../../../components/formikFormInputs";
import { DetailBoxType, NAME_IDENTIFIER } from "./MedicalPageQuestions";
import {Label} from "../../../../../../../components/formInputs";
import moment from "moment";
import { isFunc } from "../../../../../../../utils/commonUtils";


type RCFProps = {
    clientSigned: boolean,
    field: any,
    index: number | string,
    name: string,
    sendSaveRequest?: Function
}

const RenderTextField = (props: RCFProps) => {
    const { clientSigned, field, index, name, sendSaveRequest } = props;
    const { values, setFieldValue}: any = useFormikContext();
    const handleBlurRadioBoxDesc = () => {
        if (sendSaveRequest && isFunc(sendSaveRequest)) {
            sendSaveRequest(Math.random());
        }
    };
    const handleChangeRadioBoxDesc = ({ target }) => setFieldValue(name, {value: values[name].value, desc: target.value, index: index});
    const setDefaultValueForDesc = () => {
        return  values[name].desc || "";
    };

    return (
            <Field
                    className="mt10 ml15 mb15"
                    fullWidth
                    name={`${name}_${index}_desc`}
                    component={TextField}
                    placeholder={field.placeholder}
                    label={<Label label={field.label} required={true}/>}
                    onBlur={handleBlurRadioBoxDesc}
                    onChange={handleChangeRadioBoxDesc}
                    defaultValue={setDefaultValueForDesc()}
                    variant="outlined"
                    disabled={clientSigned}
            />
    )
}

const RenderDateField = (props: RCFProps) => {
    const { clientSigned, field, index, name, sendSaveRequest } = props;

    const { values, setFieldValue}: any = useFormikContext();

    const setValueForRadioDesc = (value: any) => setFieldValue(name, {value: values[name].value, desc: value, index: index});

    const handleChangeRadioBoxDesc = (date: any) => {
        setFieldValue(name, {value: values[name].value, desc: date, index: index});
        if (sendSaveRequest && isFunc(sendSaveRequest) && moment(date).isValid()) {
			sendSaveRequest(Math.random());
		}
    };

    return (
            <Field
                    className="chooseDateField mt10"
                    fullWidth
                    id={`${name}_${index}_desc`}
                    label={<Label label={field.label} required={true}/>}
                    placeholder="Date of birth"
                    component={DatePicker}
                    disabled={clientSigned}
                    disableFuture={true}
                    field={{
                        value: values[name].desc,
                        name: `${name}_${index}_desc`,
                        index
                    }}
                    cb={handleChangeRadioBoxDesc}
                    saveFormat="USER"
            />
    )
}

export const RenderChildField = (props: RCFProps) => {
    const { clientSigned, field, index, name, sendSaveRequest } = props;
    switch (field && field.detailBoxType) {
        case "text":
            return (
                    <RenderTextField
                            clientSigned={clientSigned}
                            field={field}
                            index={field.showStaticName ? DetailBoxType.Text : index}
                            name={name}
                            sendSaveRequest={sendSaveRequest}
                    />
            )
        break;
        case "date":
            return (
                    <RenderDateField
                            clientSigned={clientSigned}
                            field={field}
                            index={field.showStaticName ? DetailBoxType.Date : index}
                            name={name}
                            sendSaveRequest={sendSaveRequest}
                    />
            )
        default:
            return <></>
    }
}

type MPRBProps = {
    clientSigned: boolean;
    fieldOptions: any;
    mainIndex: number;
    sendSaveRequest?: Function;
}
const MedicalPageRadioButton = (props: MPRBProps) => {
    const { fieldOptions, mainIndex, clientSigned, sendSaveRequest} = props;
    const { values, setFieldValue, errors}: any = useFormikContext();

    const name: string = `${NAME_IDENTIFIER}R_${mainIndex}`;

    const handleRadioClick = (e:any, field:any, index:number) => {
        const indexVal = field.showStaticName ? field.detailBoxType : index;
        if (field && field.detailBoxType === "date") {
            setFieldValue(name, {value: e.target.value, index: indexVal, desc: moment().format('MM/DD/yyyy')})
        } else {
            setFieldValue(name, {value: e.target.value, index: indexVal, desc: ""})
        }
        if (sendSaveRequest && isFunc(sendSaveRequest)) {
            sendSaveRequest(Math.random());
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {
                    fieldOptions.map((field:any, index:number) => {
                        return (
                                <Grid item xs={6} key={index}>
                                    <FormControlLabel
                                            control={
                                                (
                                                        <Field
                                                                checked={((values && values[name] && values[name].value) === field.value)}
                                                                name={name}
                                                                onChange={(e: any) => handleRadioClick(e, field, index)}
                                                                type="radio"
                                                                value={field.value}
                                                        />
                                                )
                                            }
                                            className="formikRadio ml0 f16"
                                            disabled={clientSigned}
                                            key={index}
                                            label={(<Typography variant="caption" className="mb0"> {field.text} </Typography>)}
                                    />
                                    {
                                            ((values && values[name] && values[name].value === field.value) && (field.showDetailsBox === true)) &&
                                            <RenderChildField
                                                    clientSigned={clientSigned}
                                                    field={field}
                                                    index={index}
                                                    name={name}
                                                    sendSaveRequest={sendSaveRequest}
                                            />
                                    }
                                </Grid>
                        )
                    })
                }
                {
                        errors && errors[name] &&
                        <Grid item xs={12}>
                            <FormHelperText  style={{margin: '0', fontSize: '10px', color: "#FF1744"}}>
                                <span className={""}>{errors && errors[name]}</span>
                            </FormHelperText>
                        </Grid>
                }
            </Grid>
        </React.Fragment>
    );
};

export default MedicalPageRadioButton;
