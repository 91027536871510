import React from "react";
import { Grid } from "@material-ui/core";
import ParentsAreIncomeEarner from "./ProfileFormFields/ParentsAreIncomeEarner";
import RadioGroupCommon from "./ProfileFormFields/RadioGroupCommon";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { GWT_GEN2_STRATEGY, OTHER_VALUE } from "../../../../../constants";
import { isValidObject } from "../../../../../utils/commonUtils";

let currentEmployeeRadioOptions = [
    {
        label: "Yes, full-time (more than 30 hours per week)",
        value: "FULL_TIME",
    },
    {
        label: "Yes, part-time (30 hours per week or less)",
        value: "PART_TIME",
    },
];

let notEmployeeRadioOptions = {
    label: "Not currently employed - Provide previous employment details below",
    value: "NO",
}

let SelfEmployee = {
    label: "Self-Employed",
    value: "SELF_EMPLOYED",
}

const selfEmployedRadioOptions = [
    {
        label: "Full time",
        value: "FULL_TIME",
    },
    {
        label: "Part time",
        value: "PART_TIME",
    },
];

const GWTRadioOptions = [
    {
        label: "Homemaker",
        value: "HOME_MAKER",
    },
    {
        label: "Student (Legal adult)",
        value: "STUDENT",
    },
    {
        label: "Retired - (Provide previous employment details below)",
        value: "RETIRED",
    },
    {
        label: `I'm not the qualifying income earner for this product`,
        value: "Other"
    }
];

const CurrentEmployeeRadio = (props: any) => {
    const [employeeOptions, setEmployeeOptions] = React.useState([...currentEmployeeRadioOptions, notEmployeeRadioOptions]);
	const {
		classes,
		clientSigned,
		conditionalRequired,
		errors,
		isAllianzLife,
		isParentEarner,
		sendSaveRequest,
		setCurrentlyEmployed,
		setFieldValue,
		setFullTimeEmployee,
		setFullTimeSelected,
		setIsEmployed,
		setIsParentEarner,
		setSelfEmployed,
		touched,
		values,
	} = props;

    const user: any = useSelector((state: RootState) => isValidObject(state.profile) ? state.profile : state.clientAccount);
    const isGWTGen2: boolean = !!(user && user.client && user.client.strategyId === GWT_GEN2_STRATEGY);
    const disabled: boolean = !!(clientSigned || isGWTGen2);

    React.useEffect(() => {
        if (values && (values.marriageStatus === "Married" && values.usingSpouseIncome === "1")) {
            setEmployeeOptions([...currentEmployeeRadioOptions, notEmployeeRadioOptions, SelfEmployee]);
            setIsParentEarner(false);
        } else if (isGWTGen2) {
            setEmployeeOptions([...currentEmployeeRadioOptions, ...GWTRadioOptions, notEmployeeRadioOptions, SelfEmployee]);
            setIsParentEarner(true);
        } else {
            setEmployeeOptions([...currentEmployeeRadioOptions, notEmployeeRadioOptions, SelfEmployee]);
            setIsParentEarner(true);
        }
    }, [values]);

	const callBack = (e: any) => {
		setSelfEmployed(e.target.value === "SELF_EMPLOYED" || false);
		setCurrentlyEmployed(e.target.value);
		setFullTimeSelected(e.target.value === "FULL_TIME" ? 1 : 0);
		setIsEmployed((e.target.value === "FULL_TIME" || e.target.value === "PART_TIME" || e.target.value === "SELF_EMPLOYED" || e.target.value === "NO" || e.target.value === "RETIRED"));
		setFieldValue("selfEmployed", e.target.value === "SELF_EMPLOYED" || false);
		setFieldValue("currentlyEmployed", e.target.value);
		setFieldValue("annualIncome1", "");
		setFieldValue("annualIncome2", "");
		//below fields are to show and hide company details
        setFieldValue("fullTimeEmployee", (e.target.value === "FULL_TIME" || e.target.value === "PART_TIME" || e.target.value === "SELF_EMPLOYED" || e.target.value === "NO" || e.target.value === "RETIRED") ? 1 : 0);
		setFullTimeEmployee((e.target.value === "FULL_TIME" || e.target.value === "PART_TIME" || e.target.value === "SELF_EMPLOYED" || e.target.value === "NO" || e.target.value === "RETIRED") ? 1 : 0);

        if (e.target.value === "HOME_MAKER" || e.target.value === "STUDENT" || e.target.value === OTHER_VALUE) {
            setFieldValue("employer", "");
            setFieldValue("employedTime", "");
            setFieldValue("USBasedCompany", null);
            setFieldValue("workInIndustries", null);
        }
        if(values && (values.marriageStatus === 'Single')){
            setFieldValue("spouseIncome", "0");
        }
	};

	return (
		<>
			<RadioGroupCommon
				cb={callBack}
				classes={classes}
				conditionalRequired={conditionalRequired}
				disabled={disabled}
				errors={errors}
				fieldName="currentlyEmployed"
				label="Are you currently employed? Select one."
				radioOptionArr={employeeOptions}
				sendSaveRequest={sendSaveRequest}
				touched={touched}
				values={values}
			/>
            {
                    values && values.currentlyEmployed === "SELF_EMPLOYED" &&
                    <Grid item xs={12} md={6} className="mt15 ">
                        <RadioGroupCommon
                                cb={
                                        (e: any) => {
                                            setFieldValue("currentlyEmployedSelf", e.target.value)
                                            setFieldValue("fullTimeEmployee", (e.target.value === "FULL_TIME" || e.target.value === "PART_TIME") ? 1 : 0);
                                        }
                                    }
                                classes={classes}
                                disabled={disabled}
                                conditionalRequired={false}
                                errors={errors}
                                fieldName="currentlyEmployedSelf"
                                label=""
                                radioOptionArr={selfEmployedRadioOptions}
                                touched={touched}
                                values={values}
                        />
                    </Grid>
            }
			{
				values && values.currentlyEmployed === "Other" && isParentEarner && isGWTGen2 &&
				<Grid container spacing={2}>
					<Grid item xs={12} md={6} className={classes.radioGroup}>
						<ParentsAreIncomeEarner {...props} disabled={disabled} />
					</Grid>
				</Grid>
			}
		</>
	);
};
export default CurrentEmployeeRadio;