import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import loginlogo from "../styles/images/logo-ilia.png";
import { Button } from "./formInputs";
import { useActions } from "../actions";
import * as Actions from "../actions/auth";
import { unmaskPhoneNumber } from "../utils/commonUtils";

import login from "../styles/images/login.png";
import {
    PASSWORD_VALIDATE_REGEX,
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_SPECIAL_CHAR,
} from "../constants";
import PasswordRequired from "./PasswordRequired";
import { Formik } from "formik";
import { TextFieldCommon, TextField, ErrorCommon } from "./formikFormInputs";
import { createStyles, makeStyles } from "@material-ui/core/styles";

interface eventType {
    target: {
        value: string
    }
}

type Props = {
    children: { groupAndGroupParticipantDetail: any, history: { push: (val: string) => void } }
}
const useStyles = makeStyles((theme) =>
        createStyles({

            logInWrap: {
                "&::after": {
                    content: "\"\"",
                    position: "absolute",
                    background: "#3971EC",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: "42%",
                    [theme.breakpoints.down("sm")]: {
                        position: "inherit",
                        background: "transparent",
                    },
                },

            },
            textWhite: {
                color: "#FFFFFF",
            },
            textCenter: {
                textAlign: "center",
            },
            mt10: {
                marginTop: 10,
            },
            mr30: {
                marginRight: 30,
            },
            loginCard: {
                margin: "auto",
                width: 865,
                boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
                background: "transparent !important",
                position: "absolute",
                left: 0,
                right: 0,
                zIndex: 999,
                border: 0,
                top: "20%",
                padding: "2%",
                [theme.breakpoints.down("sm")]: {
                    marginBottom: 40,
                    background: "#ffffff !important",
                    width: "92%",
                },
            },
            muiCardContent: {
                padding: 15,
                float: "left",
                width: "100%",
                position: "relative",
            },
            loginForm: {
                padding: "60px 15px 50px",
                float: "left",
                width: "100%",
            },
            margin0: {
                margin: 0,
            },
            titleMain: {
                fontSize: 26,
                lineHeight: "34px",
                textAlign: "center",
                margin: 0,
                float: "right",
            },
            dpNone: {
                [theme.breakpoints.down(992)]: {
                    display: "none",
                },
            },
            floatRight: {
                float: "right",
            },
            imgResponsive: {
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginTop: "25%",
            },
            clickableLink: {
                display: "inline-flex",
                alignItems: "center",
                verticalAlign: "middle",
            },

            clickableLinksvg: {
                float: "left",
            },
            mr10: {
                marginRight: 10,
            },
        }),
);
function SetGroupParticipantPassword(props: Props) {
    const classes = useStyles();
    const { children } = props;
    const groupParticipantDetail = children.groupAndGroupParticipantDetail.groupParticipantDetail;
    const authActions = useActions(Actions);
    const [oneLetter, setOneLetter] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const onPassWordChange = (e: eventType): void => {
        const val = e.target.value;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
    };

    const submitFormik = async (values: any, setSubmitting: Function, resetForm: Function) => {
        const data = Object.assign(children.groupAndGroupParticipantDetail, {
            password: values.password,
            phoneNumber: unmaskPhoneNumber(values.phoneNumber),
        });
        const resp: any = await authActions.setGroupParticipantPassword(data);
        if (resp.statusCode === "200") {
            children.history.push("/login");
        } else {
            children.history.push("/login");
        }
    };

    const intitialValues = {
        email: groupParticipantDetail.email,
        phoneNumber: unmaskPhoneNumber(groupParticipantDetail.phone),
        password: "",
        confirmPassword: "",
    };

    const validate = ((value: any) => {
        const errors: {
            password?: string | boolean,
            confirmPassword?: string
        } = {};
        if (!value.password)
            errors.password = "Required";
        if (!value.confirmPassword) {
            errors.confirmPassword = "Required";
        } else if (value.confirmPassword !== value.password) {
            errors.confirmPassword = "Password mismatched";
        }
        if (value.password && !PASSWORD_VALIDATE_REGEX.test(value.password)) {
            errors.password = true;
        }
        return errors;
    });

    const fieldObj = [{
        name: "password",
        placeholder: "Password",
        id: "password",
        component: TextField,
        type: "password",
    }, {
        name: "confirmPassword",
        placeholder: "confirmPassword",
        id: "confirmPassword",
        component: TextField,
        type: "password",
    }];

    return (
            <div className={classes.logInWrap}>
                <Grid container>
                    <Grid item xs={12}>
                        <Card className={classes.loginCard}>
                            <CardContent className={classes.muiCardContent}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                                        <img alt="loginLogo" src={loginlogo}/>
                                            <Typography variant="h5" className="mt15 mb15">
                                                <p>Authentication Successful!</p>
                                                <span>Please create a password to log in to ilia</span>
                                            </Typography>
                                            <Formik
                                                    initialValues={intitialValues}
                                                    validate={values => validate(values)}
                                                    onSubmit={(values, {
                                                        setSubmitting,
                                                        setErrors,
                                                        setStatus,
                                                        resetForm,
                                                    }) => submitFormik(values, setSubmitting, resetForm)}
                                            >
                                                {({
                                                      values,
                                                      errors,
                                                      touched,
                                                      handleChange,
                                                      handleBlur,
                                                      handleSubmit,
                                                      isSubmitting,
                                                      dirty,
                                                      setFieldValue,
                                                  }) =>
                                                        (
                                                                <form className={classes.loginForm + " " + classes.margin0} onSubmit={handleSubmit}>
                                                                    {
                                                                        fieldObj.map((field, index) => {
                                                                            return (
                                                                                    <React.Fragment key={index}>
                                                                                        <div className="mt15">
                                                                                            <TextFieldCommon
                                                                                                    {...field}
                                                                                                    values={values}
                                                                                                    onChange={(e: any) => {
                                                                                                        handleChange(e);
                                                                                                        if (field.name == "password") onPassWordChange(e);
                                                                                                    }}
                                                                                                    onBlur={handleBlur}
                                                                                            />
                                                                                            <ErrorCommon errors={errors}
                                                                                                         name={field.name}
                                                                                                         touched={touched}/>
                                                                                        </div>
                                                                                    </React.Fragment>);
                                                                        })
                                                                    }
                                                                    <Button
                                                                            type="submit"
                                                                            label="Create Password"
                                                                            fullWidth
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className="btnPrimary mt15"
                                                                            disabled={(Object.keys(errors).length !== 0) || !dirty || isSubmitting}
                                                                            loading={isSubmitting}
                                                                    />
                                                                </form>
                                                        )}
                                            </Formik>
                                            <PasswordRequired oneLetter={oneLetter} oneNumber={oneNumber}
                                                              oneCapitalLetter={oneCapitalLetter}
                                                              eightCharacter={eightCharacter}
                                                              oneSpecialChar={oneSpecialChar}/>
                                        </Grid>
                                    <Grid item xs={12} sm={6} md={5} lg={5} className={classes.dpNone}>
                                        <Typography variant="h3" align="right"
                                                    className={classes.textWhite + " " + classes.mr30 + " " + classes.titleMain}>
                                            Create <br />New Password
                                        </Typography>
                                        <img alt="login" className={classes.floatRight + " " + classes.imgResponsive} src={login} />
                                    </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
    );
}

export default SetGroupParticipantPassword;