import * as React from "react";
import { useActions } from "../actions";
import * as Actions from "../actions/account";
import {
    Typography,
    Card,
    CardContent,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    List,
    Button,
    CircularProgress
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: 800,
                transform: ' translate(-50%, -0%)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                [theme.breakpoints.down("sm")]: {
                    width:'calc(100% - 25px)',
                    marginBottom:'50px',
                },
            },
            logo: {
                margin: "20px auto",
                textAlign:'center'
            },
        }),
);

const EmailUnSubscribed = (props: any) => {
    const classes = useStyles();
    const { match, userId } = props;
    const accountActions = useActions(Actions);
    const [subscribe_marketing, setSubScribe_marketing] = React.useState(false);
    const [subscribe_advisors, setSubscribe_advisors] = React.useState(false);
    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    const [submited, setSubmited] = React.useState(false);
    const [agentName, setAgentName] = React.useState("");
    const [progress, setProgress] = React.useState(true);

    let illiaKaizenLogo:string = 'https://niw-staging.s3.amazonaws.com/email/ilia_kai-zen_combined.png';

    const [partnerLogo, setPartnerLogo] = React.useState(illiaKaizenLogo);

    React.useEffect(() => {
        const getThisUserData = async () => {
            let id:string = userId || (match && match.params.userId) || "";
            let userData = await accountActions.getInvitedUserDetailById( id );
            if (userData) {
                setSubScribe_marketing( userData.subscribe_marketing ? true : false  );
                setSubscribe_advisors( userData.subscribe_advisors ? true : false );

                setAgentName(`${userData["client.agent.user.firstName"]} ${userData["client.agent.user.lastName"]}`);

                let partnerLogo: string = userData["client.imo.reBrand"] ? "https://ilia-production.s3.amazonaws.com/email/partner-strategy-logo2.png" : illiaKaizenLogo;
                setPartnerLogo(partnerLogo);
            }
            setTimeout( () => setProgress(false), 500 );
        };
        getThisUserData();
    }, []);

    const updateUserEmailPreferences = async () => {
        setButtonDisabled(true);
        setSubmited(true);

        let id:string = userId || (match && match.params.userId) || "";
        await accountActions.unsubscribedUser(id, {
            subscribe_all_emails: 1,
            subscribe_marketing: subscribe_marketing ? 1 : 0,
            subscribe_advisors: subscribe_advisors ? 1 : 0,
        });
    };

    return (
            <div className={match && match.params.userId ? classes.root : ''}>
                {match && match.params.userId &&
                <div className={classes.logo}>
                    <img width={160} src={partnerLogo} alt={"Partner logo"}/>
                </div>
                }
                <Card className={`muiCardUnSubscribe w100 ${match && match.params.userId ? '' : 'muiCard'} `}>
                    <span className="borderTop"/>
                    {progress ?
                        <CircularProgress style={{margin: match && match.params.userId ? '23% 45% 0' : '23% 45%'}}  />
                    :
                    submited ?
                            <CardContent className="muiCardContentThankyou">
                                <Typography variant="h3" align="center">Thank you</Typography>
                                {!subscribe_marketing && !subscribe_advisors ?
                                        <Typography variant="body1" align="center"> Your have
                                            unsubscribed. </Typography>
                                        :
                                        <Typography variant="body1" align="center"> Your email preferences
                                            have been saved. </Typography>
                                }
                            </CardContent>
                            :
                            <CardContent className="muiCardContentUnSubscribe">
                                {match && match.params.userId &&
                                <Typography variant="h3">Email Preferences</Typography>
                                }
                                <Typography variant="body1"
                                            className={` ${match && match.params.userId ? '' : 'mb10'} `}>
                                    What types of emails would you like to receive?
                                </Typography>
                                <List className="UnSubscribeList">
                                    <ListItem>
                                        <ListItemIcon>
                                            <Checkbox
                                                    checked={subscribe_marketing}
                                                    edge="start"
                                                    color="primary"
                                                    onChange={() => {
                                                        setButtonDisabled(false);
                                                        setSubScribe_marketing(!subscribe_marketing);
                                                    }}

                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={"Marketing emails, news, and offers."}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Checkbox
                                                    checked={subscribe_advisors}
                                                    edge="start"
                                                    color="primary"
                                                    onChange={() => {
                                                        setButtonDisabled(false);
                                                        setSubscribe_advisors(!subscribe_advisors);
                                                    }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                                primary={`Important notifications from your agent, ${agentName}.`}/>
                                    </ListItem>

                                </List>
                                <Button className="mt15" onClick={() => updateUserEmailPreferences()}
                                        disabled={buttonDisabled}
                                        variant="contained" color="primary"> Save Preferences </Button>
                            </CardContent>

                    }
                </Card>
            </div>
    );
};

export default  EmailUnSubscribed;