import moment from "moment";
import numeral from "numeral";
import {scroller} from "react-scroll";
import {
    AccountType,
    AllianzLifeCarrierDetail,
    ATLANTIC_HEALTH,
    ClientDocumentType,
    PermissionTypes,
    PolicyStatusEnum,
    YesNoEnum
} from "../constants";
import {can, isAdmin} from "../components/navigation/AdminNavigation";
import {Account} from "../model/account";
import {NS, NSN} from "../model/misc";


export const isDev = process.env.NODE_ENV === "development";

export const unmaskPhoneNumber = (phone: string) =>phone && phone.replace(/\D+/g, '');

export const unmaskCurrency = (num: any = "") => {
    if(typeof num == "string"){
        return num.replace(/[^0-9.-]+/g, '');
    }
    return num
}

export const maskCurrency = (num: string = "") => {
    return numeral(num).format('$0,0');
}

export const getFloatString = (num: string = "") => {
	num = (num.match(/\d/g) || []).join("");
	return num;
};

export const arrayContainUndefined = (arr : any) => {
    return arr.includes(undefined);
}

/**
 * if condition matched givenvalue show the text
 * @param getFieldVal
 * @param status
 * @param trueVal
 * @param falseVal
 */

export const showLabelTextByStatus = (getFieldVal: string, status: string, trueVal: string, falseVal: string): string => {
    return (getFieldVal === status ? trueVal : falseVal);
}

/**
 * simulate server latency (default is 1000 ms)
 * @param ms
 */
export const sleep = (ms: number = 1000) => new Promise(resolve => setTimeout(resolve, ms));

export const ucFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
export const ccFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
export const upperCase = (s: string) => s.toUpperCase();
export const lowerCase = (s: string) => s.toLowerCase();
export const getRandomString = () => {
	const randomString = (new Date().getTime()).toString(36).toUpperCase();
	return Math.random().toString(36).slice(11).toUpperCase() + randomString.substring(2, 8);
}
export const removeDuplicateWords = (s: string) => {
	const str = s.split(" ");
	const result: any = [];
	for(let i = 0; i < str.length ; i++) {
		const resultLowercase = result.map((k: string) => k.toLowerCase());
		if(resultLowercase.indexOf(str[i].toLowerCase()) === -1){
			result.push(str[i]);
		}
	}
	return result.join(" ");
};

/**
 * turns Words With Spaces to words-with-spaces
 * @param words
 */
export const wordsToHyphen = (words: string) => words.split(' ').map(a => a.toLowerCase()).join('-');

/**
 * turns Words With Spaces to words_with_out_spaces_with_under_score
 * @param words
 */
export const wordsToUnderScore = (words: string) => words.split(' ').join('_');

/**
 * Parse If Empty String, Replace with Optional Second Parameter
 * @param str
 * @param replacer
 */
export const parseEmptyString = (str: string|null|undefined, replacer: string = '') => {
	return typeof str === 'string' && str ? str : replacer;
}
export const pez = parseEmptyString;

export const checkImageExists = (url: string, cb: Function) => {
	let imageData = new Image();
	imageData.src = url;
	imageData.onerror = function(){
		cb(false);
	}
	imageData.onload = function(){
		cb(url);
	}
}

export const makeQueryString = (data:any) => {
    return Object.keys(data)
        .map(key => {
                return(`${key}=${data[key]}`)
            })
        .join('&');
}

export const maskPhoneNumber = (phone: string) => {
    if (phone && phone.length === 10) {
        let usNumber: RegExpMatchArray | null | string = phone.match(/(\d{3})(\d{3})(\d{4})/);
        usNumber = usNumber && "(" + usNumber[1] + ") " + usNumber[2] + "-" + usNumber[3];
        return usNumber;
    }
    return phone;
}
export const hidePartOfSSN = (number: string) => {
    return "XXX-XX-" + number.substr(number.length - 4);
};
export const checkProofExpiry = (date) => {
    const expireDate = moment(date);
    const currentDate = moment(new Date());
    const daysLeft = expireDate.diff(currentDate, "days");
    if (daysLeft <= 0) {
        return true;
    } else {
        return false;
    }
}


const checkIsDocumentExist: Function = (clientDocuments: any, documentType: string) => {
    if (!clientDocuments || clientDocuments.length === 0) {
        return false;
    }
    /*let findDocument = clientDocuments.find((document: any) => {
        return document.documentType === documentType;
    });

    if (!findDocument) {
        return false;
    }
    if (!findDocument.path) {
        return false;
    }

    if (!findDocument.backPath && (documentType === ClientDocumentType.IDENTITY_DOCUMENT || documentType === ClientDocumentType.RESIDENCE_DOCUMENT)) {
        return false;
    }*/
    let findDocument = clientDocuments.find((document: any) => {
        return document.documentType === documentType;
    })
    switch (documentType) {
        case ClientDocumentType.IDENTITY_DOCUMENT:
        case ClientDocumentType.RESIDENCE_DOCUMENT:
            if (!findDocument) {
                return false;
            }
            if(findDocument && !findDocument.path){
                return false;
            }
            if (findDocument && !findDocument.backPath) {
                return false;
            }
            break;
        case ClientDocumentType.TAX_DOCUMENT:
            const taxDocuments = clientDocuments.filter((document: any) => {
                return document.documentType === documentType;
            });
            if (taxDocuments.length < 2) {
                return false;
            }
            let taxDocPath = true;
            taxDocuments.forEach((taxDocument: any)=>{
                if(taxDocument && !taxDocument.path){
                    taxDocPath = false;
                }
            })
            return taxDocPath;
            break;
        default:
            if (!findDocument) {
                return false;
            }
            if (findDocument && !findDocument.path) {
                return false;
            }
            break;
    }
    return true;
};

//Check Client Documents are uploaded aor not?
export const checkIsDocUploaded = (profileDetail: any) => {
    //Policy Override Case
    if ((profileDetail && profileDetail.currentPolicyStatus) === PolicyStatusEnum.InForceOverride) {
        return true;
    }
    let yesDocUploaded = false;
    if (profileDetail) {
        if ((profileDetail.clientDocuments && profileDetail.clientDocuments.length) === 0) {
            yesDocUploaded = false; //docs not uploaded in pre enrollment step
        } else if (profileDetail.clientDocuments && profileDetail.clientDocuments.length > 0) {
            yesDocUploaded = true;
            if (
                (profileDetail.underWritingOfferType !== "allianz_app") &&
                (!profileDetail.isDocumentAddress || profileDetail.identityType !== "drivingLicence") &&
                !checkIsDocumentExist(profileDetail.clientDocuments, ClientDocumentType.UTILITY_BILL)
            ) {
                yesDocUploaded = false;
            }
            if (
                !profileDetail.usCitizen &&
                !checkIsDocumentExist(profileDetail.clientDocuments, ClientDocumentType.RESIDENCE_DOCUMENT)
            ) {
                yesDocUploaded = false;
            }

            if (
                !checkIsDocumentExist(profileDetail.clientDocuments, ClientDocumentType.IDENTITY_DOCUMENT)
            ) {
                yesDocUploaded = false;
            }
            if(profileDetail && (profileDetail.underWritingOfferTypeFinal === "gi" || profileDetail.underWritingOfferTypeFinal === "consent") && profileDetail.company &&
                profileDetail.company.groupType && profileDetail.company.groupType === "Corporation") {
                //we do not have to check documents exist condition for gi and Corporation
            } else if (ATLANTIC_HEALTH.includes(profileDetail && profileDetail.company && profileDetail.company.companyLinkUrl)) {
                //Special Case ATLANTIC HEALTH Don't need tax documents
            } else {
                if(!checkIsDocumentExist(profileDetail.clientDocuments, ClientDocumentType.TAX_DOCUMENT)){
                    yesDocUploaded = false;
                }
            }
        }
    }
    return yesDocUploaded;
};

export const getFileExtension: Function = (fileName: string) => {
    if (!fileName) return;
    return fileName.split('.').pop();
}
export const formatBytes = (bytes, decimals = 0) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getGroupNumber = (trancheNumber: any) => {
    if(!trancheNumber) {
        return;
    }
    let groupNumber: string = "0040001";
    groupNumber = groupNumber.substring(0,(groupNumber.length - trancheNumber.length)) + trancheNumber;
    return groupNumber;
}

export const getTicks = ({
     kaizen_total_income = 0,
     kaizen_death_benefit_yr90 = 0,
     lsw_comparison_total_income = 0,
     lsw_comparison_death_benefit_yr90 = 0,
     bonus_comp_total = 0,
     def_comp_total = 0
}) => {
    let maxValue:number = Math.max(...[kaizen_total_income, kaizen_death_benefit_yr90, lsw_comparison_total_income, lsw_comparison_death_benefit_yr90, bonus_comp_total, def_comp_total]);
    let avgValue = maxValue / 10;
    let arr:number[] = [];
    for(let i=1; i<=10; i++){
        if(i === 1){
            arr.push(0);
        } else {
            arr.push(arr[i - 2] + Number(avgValue));
        }
        if(i === 10) {
            arr.push(arr[i - 1] + Number(avgValue));
            arr.push(arr[i] + Number(avgValue));
            arr.push(arr[11] + Number(avgValue));
        }
    }
    return arr && arr.length > 0 ? arr : [0, 5000000, 15000000, 30000000, 45000000, 60000000, 75000000, 90000000];
}

export const scrollerUtil = (id: string, offsetTop: number) => scroller.scrollTo(id, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -offsetTop
});
/**
 * Check If Array is Valid With At Least One Valid Element
 * @param arr
 * @returns number
 */
const validArray = (arr: any) => {
    return arr && Array.isArray(arr) && arr.length;
};
export const va = validArray;

export const  isUrlValid = (userInput: string) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return false;
    else
        return true;
}

/**
 * Do nothing
 */
export const noop = () => {};

/**
 * Detects a Big screen for setting Left Nav open or close
 */
export const isBigScreen = (e?: any) => {
    const MIN_SCREEN_SIZE = 1025; // Don't change without consulting design team
    return (!!e ? e.target.innerWidth : window.innerWidth) <= MIN_SCREEN_SIZE;
}


export const checkIfTim = (email: string) => {
    const timEmailToCheck = 'tim@retireme-taxfree.com';
    return email.toLowerCase() === timEmailToCheck;
};

export const getOptions = (options: any[]) => {
    return options.map((x: any) => ({ name: x.name, key: x.value }));
};

/**
 * object is valid
 * @param obj
 */
export const validObj = (obj: object | undefined) => !!(obj && Object.keys(obj).length);

/**user IsAgent **/
export const isAgent = user => user.role === AccountType.Agent;

/**
 * Join firstName, lastName of user and returns full_name
 * @param user
 */
export const joinAsFullName = (user: {firstName: string, lastName: string}) => {
    return user && (pez(user.firstName) + ' ' + pez(user.lastName));
};

/**
 * Download File As CSV
 * @param fileNameWithoutExt
 * @param fileData
 */
export const downloadAsCSV = (fileNameWithoutExt: string, fileData: string) => {
    const fileNameWithExt = fileNameWithoutExt ? `${fileNameWithoutExt}.csv` : 'export.csv';
    const blob = new Blob([fileData], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileNameWithExt);
    } else {
        const link = document.createElement('a');
        if ((link.download !== undefined)) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileNameWithExt);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

/**
 * Format Phone Number as US Number
 * @param phoneNumber
 */
export const formatPhoneNumber = (phoneNumber: number | string) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
};

/**
 * Check if function
 * @param _function
 */
export const isFunc = (_function: any | Function) => {
    return _function && typeof _function === 'function';
};

/*
 * Check if big string, return isBig and trimmed text as obj
 * @param txt
 * @param minLength
 * @param addDots
 */
export const checkIfTextIsBig = (txt: string, minLength: number, addDots: boolean = false) => {
    const isBig = txt.length >= minLength;
    const retVal = isBig  ? txt.substring(0, minLength) : txt;

    return {isBig, text: addDots ? `${retVal}...` : retVal};
};

/**
 * Check If Rebrand
 * @param props
 */
export const checkIfRebrand = (props: any) => {
    let isRebrand: boolean = false;
    let partnerName: string = "ilia";
    if (props.strategyData && props.strategyData.reBrand === 1 && props.agent && props.agent.imo ) {
        partnerName = props.agent.imo.groupName;
        isRebrand = true;
    }
    if (props.strategyData && props.strategyData.reBrand === 2 && props.agent && props.agent.imo && props.agent.imo.reBrand) {
        partnerName = props.agent.imo.groupName;
        isRebrand = true;
    }
    if (props.groupDetail && props.groupDetail.imo && props.groupDetail.imo.reBrand) {
        isRebrand = true;
    }
    return { partnerName, isRebrand };
}

/**
 * IMO edit/delete allowed - Master_Admin, Admin with account management permission, IMO
 * @param user
 */
export const checkIMOEditAllowedAdminIMO = (user: Account) => {
    return (isAdmin(user) && can(user, PermissionTypes.ACCOUNT_MANAGEMENT)) || ([AccountType.IMO, AccountType.ImoMember].includes(user.role as number));
};
/*
 *Trim if bigLabel in mobile
 * @param Label
 * @param minLength
 * @param addDots
 */
export const checkIfbigLabelInMobile = (txt: string, minLength: number = 6) => {
    const retVal = window.innerWidth <= 1156 ? txt.substring(0, minLength) : txt;

    return `${retVal}...`;
};


export const isTouchScreen = () => typeof window !== "undefined" && window.innerWidth <= 1157;

/**
 * Fix Document List Structure
 * @param document
 */
export const getDocumentNameAndType = (document: any) => {
    let documentUri: string = "";
    let fileType: string = "";
    if (document && document.id > 0) {
        fileType = document && document.fileType;
        if (document.documentType === ClientDocumentType.ADMIN_PROPOSAL) {
            if (document.path) {
                documentUri = document.path;
            } else {
                documentUri = document.backPath;
            }
        } else if (document.documentType === ClientDocumentType.MORE_DOCUMENTS) {
            if (document.path) {
                documentUri = document.path;
            } else {
                documentUri = document.backPath;
            }
        } else {
            if (document.path) {
                documentUri = document.path;
            } else {
                documentUri = document.backPath;
            }
        }
        return ({ "fileType": fileType, "documentUri": documentUri });
    }
}

/**
 * Download Document
 * @param document
 * @param dirName
 * @param awsActions
 */
export const downloadDocument = async (document: any, dirName: string, awsActions: any) => {
    if (document && document.id > 0) {
        const docNameAndType: any = getDocumentNameAndType(document);
        if (docNameAndType.documentUri && docNameAndType.fileType) {
            const documentUri = docNameAndType.documentUri;
            let file = documentUri.split(".");
            let fileType = file[file.length - 1].toUpperCase();
            const contentType = (fileType == "PDF" || fileType == "pdf") ? ("application/pdf") : ("image/*");
            const queryObj = {
                objectName: documentUri,
                contentType: contentType,
            };
            const s3url = await awsActions.getSecureUrlAndDownload(dirName, queryObj, true);
            const blob = new Blob([new Buffer(s3url.Body, "binary")]
                , { type: s3url.ContentType });
            const url = window.URL.createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = url;
            link.setAttribute("download", documentUri);
            window.document.body.appendChild(link);
            link.click();
        }
    }
};

/**
 * prepare document list
 * @param rows
 */
export const prepareDocumentList = (rows: any) => {
    let list: any = [];
    for (let key in rows) {
        let documentName: string = rows[key].name;
        if (rows[key].path) {
            rows[key].name =
                documentName + (rows[key].backPath ? " Front" : "");
            //  rows[key].size = getFileSize(LOGO_BASE_URL + rows[key].path);
            let path = rows[key].path.split(".");
            rows[key].fileType = path[path.length - 1].toUpperCase();
            rows[key].frontOrBack = "Front";
            list.push({ ...rows[key], documentName: documentName });
        }
        if (rows[key].backPath) {
            let path = rows[key].backPath.split(".");
            rows[key].fileType = path[path.length - 1].toUpperCase();
            rows[key].frontOrBack = "Back";
            list.push({
                ...rows[key],
                name: documentName + " Back",
                path: "",
                documentName: documentName,
            });
        }
    }
    return list;
}

export const getDocumentSignedAndSecuredURL = async (document, dirName, awsActions, setSecureFilePath, setFileExt) => {
    const file = document.split(".");
    const fileType = file[file.length - 1].toUpperCase();
    const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
    const queryObj = {
        objectName: document,
        contentType: contentType,
    };
    const s3url_backpath = await awsActions.getSecureUrl(dirName, queryObj, true);
    setSecureFilePath(s3url_backpath);
    setFileExt(fileType);
};


/**
 * Generates an array of numbers given size with its indexes as values
 * @param arrSize
 */
export const numArray: (arrSize: number) => number[] = (arrSize: number) =>
    new Array(arrSize).join().split(',').map((_item: string, index: number) => (index));


/**
 * Filter the Related keys among the OBJECT
 * @param _key
 * @param answersJson
 * return Keys of the object
 */
export const getRelatedKeysFromObject = (_key:string, answersJson:any) => {
    const valueOfQuestion:any = Object.keys(answersJson)
        .filter((key) => key.includes(_key))
        .reduce((obj, key) => {
            return Object.assign(obj, {
                [key]: answersJson[key]
            });
        }, {});
    return {...valueOfQuestion}
}

/**
 * check object must be valid
 * @param obj
 */
export const isObject = (obj: object | undefined) => {
	const value = obj && Object.keys(obj).length
	return Boolean(value);
};
export const isValidObject = (obj: object | undefined) => {
    return isObject(obj) && obj && Object.keys(obj).length >= 1;
};

/**
 * Get Yes No
 * @param colKeyVal
 */
export const getYesNo = (colKeyVal: NS): NSN => {
    return colKeyVal === 0 ? YesNoEnum.No :
        (colKeyVal === 1 ? YesNoEnum.Yes : (colKeyVal || null));
};

export const getCarrierCode = (carrierId: number) => {
    let carrierCode = "NLG";
    if (carrierId === AllianzLifeCarrierDetail.value) {
        carrierCode = "ALZ";
    }
    return carrierCode;
}

/**
 * Finds debug key in query string and checks value to be one of true, 1 or dev; then returns true
 * Use case logging in production
 * @param debugKey
 */
export const isDebug = (debugKey = 'debug') => {
    const debug = new URLSearchParams(window.location.search).get(debugKey);
    return debug && (debug === 'true' || debug === 'dev' || debug === '1');
};

/**
 * Enum to array of string or number values (enum values)
 * Not to be used with keys true in case of enum with string values
 * @param Foo
 * @param keys
 */
export const enumAsArray = (Foo: any, keys: boolean = false): Array<string | number> =>
	Object.keys(Foo).filter((k: any) => typeof Foo[k] === (keys ? "number" : "string"));

/**
 * Convert YesNo To Boolean
 * @param str
 */
export const convertYesNoToBoolean = (str: string) => {
    return str === YesNoEnum.Yes ? true : (str === YesNoEnum.No ? false : str);
};

/**
 * Shorten Text to default 100
 * @param textToShorten
 * @param maxAllowed
 * @param addDots
 */
export const shortenText = (textToShorten: string, maxAllowed: number = 100, addDots: boolean = true) => {
    const {isBig, text} = checkIfTextIsBig(textToShorten, maxAllowed, addDots);
    return isBig ? text : textToShorten;
};

/**
 * Convert Boolean (or Boolean string) to YesNo
 * @param bool
 */
export const convertBooleanToYesNo = (bool: boolean | string) => {
    if (typeof bool === 'string') {
        bool = bool.toLowerCase() === 'true' || bool.toLowerCase() === YesNoEnum.Yes.toLowerCase();
    }
    return bool ? YesNoEnum.Yes : YesNoEnum.No;
};

/**
 * Trim If String
 * @param val
 */
export const trimIfString = (val: any) => {
    return val && typeof val === 'string' ? val.trim() : val;
};

/**
 * Trim If String (mini version)
 */
export const tis = trimIfString;

export const targetBlank = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
    return;
};