import React from "react";
import {useFormikContext} from "formik";
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import {Label} from "../../../../../components/formInputs";
import {
    DatePicker,
    ErrorCommon as EC,
    PhoneNumberField,
    SelectField,
    TextField
} from "../../../../../components/formikFormInputs";
import TextFieldCommon from "../../../../../components/formikFormInputs/TextFieldCommon";
import {countryDropDownArr, stateDropDownArr} from "../basicInfo/PersonalDetailUtils";
import {PRIMARY_PHYSICIAN_DISCLOSURE_CODE, SearchBox, ToggleButton} from "../../../../../components/dynamicForms";
import {ParsedListItem, Physician} from "../../../../../model/application";
import {BS} from "../../../../../model/misc";
import {useActions} from "../../../../../actions";
import * as ApplicationActions from "../../../../../actions/application";
import {enumAsArray, isObject, noop, sleep} from "../../../../../utils/commonUtils";
import {USA, YesNoEnum} from "../../../../../constants";
import {asDbDateFormat} from "../../../../../utils/dateUtils";


type PhysicianProfessionalProps = {
    disclosureCode?: string;
    enrollmentId?: string;
    hasPhysicians?: BS;
    label?: string;
    setHasPhysicians?: Function;
    setIsCountryUSA?: Function;
};

export default function PhysicianProfessional(props: PhysicianProfessionalProps) {
    const {
        errors,
        handleBlur,
        handleChange,
        resetForm,
        setFieldTouched,
        setFieldValue,
        touched,
        values
    }: any = useFormikContext();
    const {disclosureCode, enrollmentId, hasPhysicians, label, setIsCountryUSA, setHasPhysicians} = props;
    const applicationActions = useActions(ApplicationActions as any);
    const [physiciansList, setPhysiciansList] = React.useState<string[]>([]);
    const [physicians, setPhysicians] = React.useState<Physician[]>([] as Physician[]);
    const [selectedPhysicians, setSelectedPhysicians] = React.useState<Physician>({} as Physician);
    const [lastVisitUnknown, setLastVisitUnknown] = React.useState<boolean>(true);
    const isPrimary = disclosureCode === PRIMARY_PHYSICIAN_DISCLOSURE_CODE;
    const whenUsa = values && values["country"] === USA;
    const LastVisitField = 'lastVisit';
    const lastVisitReasons = [
        {"code": "ANNUAL_EXAM", "description": "Annual exam"},
        {"code": "WELL_WOMAN_EXAM", "description": "Well Woman Exam"},
        {"code": "EMPLOYMENT_EXAM", "description": "Employment exam"},
        {"code": "SICK_EMERGENCY_VISIT", "description": "Sick/Emergency Visit"},
        {"code": "FOLLOW_UP_CONSULTATION", "description": "Follow-up Consultation"}
    ];
    const commonDisabledProps = {disabled: isObject(selectedPhysicians)};
    const commonVariantProps = {variant: "outlined"};
    const physicianNameLabel = `Name of ${isPrimary ? 'Primary ' : ''}Physician`;
    const physicianFields = [
        {
            component: TextField,
            label: <Label label={physicianNameLabel} required={true}/>,
            name: "name",
            placeholder: physicianNameLabel,
        },
        {
            component: TextField,
            label: <Label label={"Speciality"} required={false}/>,
            name: "speciality",
            placeholder: "Speciality...",
        },
        {
            component: TextField,
            label: <Label label="Address Line 1"/>,
            name: "addressLine1",
            placeholder: "Enter Address",
        },
        {
            component: TextField,
            label: <Label label="Address Line 2"/>,
            name: "addressLine2",
            placeholder: "Enter Address",
        },
        {
            component: SelectField,
            label: <Label label="Country" required={true}/>,
            name: "country",
            onSelectChange: noop,
            options: countryDropDownArr,
        },
        {
            component: whenUsa ? SelectField : TextField,
            label: <Label label="State" required={whenUsa}/>,
            name: "state",
            onSelectChange: whenUsa ? noop : "NA",
            options: whenUsa ? stateDropDownArr : "NA",
        },
        {
            component: TextField,
            label: <Label label="City" required={true}/>,
            name: "city",
            placeholder: "Enter City",
        },
        {
            component: TextField,
            inputProps: {maxLength: 10},
            label: <Label label="Zip" required={false}/>,
            name: "zip",
            placeholder: "Enter Zip",
        },
        {
            component: PhoneNumberField,
            id: "phone",
            label: <Label label="Phone" required={false}/>,
            name: "phone",
            placeholder: "Enter Physician Phone",
        },
        {
            component: TextField,
            label: <Label label="Fax" required={false}/>,
            name: "fax",
            placeholder: "Enter Physician Fax",
        },
        {
            component: TextField,
            label: <Label label="Email" required={false}/>,
            name: "email",
            placeholder: "Enter Physician Email",
            type: 'email'
        },
    ];
    const lastVisitFieldsObj = [
        {
            component: DatePicker,
            InputLabelProps: {shrink: true},
            label: <Label label="When did you last visit this physician?"/>,
            name: LastVisitField,
            type: 'month',
            variant: "outlined",
            view: ['month', 'year'],
            disableFuture: true,
            cb: (date: string) => {
                setFieldValue(LastVisitField, asDbDateFormat(date, "YYYY-MM"))
            }
        },
        isPrimary ? {
            component: SelectField,
            label: <Label label="What was the reason for that visit?" required={true}/>,
            name: "lastVisitReason",
            onSelectChange: noop,
            options: lastVisitReasons.map(({code, description}) => ({key: code, name: description})),
        } : undefined,
    ]
    const toggleItems: ParsedListItem[] = (enumAsArray(YesNoEnum) as string[])
            .map(v => ({text: v, value: YesNoEnum.Yes === v ? 'true' : 'false'}));

    const onToggleLastVisitUnknown = () => {
        if (!lastVisitUnknown) {
            setFieldValue(LastVisitField, '');
        }
        setLastVisitUnknown && setLastVisitUnknown(!lastVisitUnknown);
    };

    const lastVisitControl = (
            <Checkbox
                    checked={lastVisitUnknown}
                    className="pt0"
                    onChange={onToggleLastVisitUnknown}
            />
    );
    const clearForm = async () => {
        await sleep(300);
        physicianFields.map(p => setFieldTouched(p.name, true));
        lastVisitFieldsObj.map(p => p && p.name && setFieldTouched(p.name, true));
        return resetForm();
    };

    const onTogglePhysicianDetails = (checked) => {
        setSelectedPhysicians({} as Physician);
        setHasPhysicians && setHasPhysicians(JSON.parse(checked));
        setFieldValue('hasPhysicians', JSON.stringify(JSON.parse(checked)));
        return clearForm();
    };

    const handleInputChange = (e, val, reason) => {
        if (val && reason === "reset") {
            const found = (physicians || []).find(e => e.name.includes(val));
            if (isObject(found)) {
                setSelectedPhysicians(found as Physician);
                return;
            }
        }
        if (reason === "clear") {
            // clear fields
            setSelectedPhysicians({} as Physician);
            setFieldValue('phone', '');
            return clearForm();
        }
    };

    /**
     * load existing physician list
     */
    const fetchPhysician = async () => {
        let result: any[] = [];
        if (!isPrimary) {
            result = await applicationActions.fetchPhysicians({enrollmentId});
        }
        setPhysicians(result);
        setPhysiciansList(result.map(x => x.name));
    };

    React.useEffect(() => {
        if (isObject(selectedPhysicians)) {
            return;
        }
        if (whenUsa) {
            setIsCountryUSA && setIsCountryUSA(true);
            setFieldValue('state', undefined);
        } else {
            setIsCountryUSA && setIsCountryUSA(false);
            setFieldValue('state', hasPhysicians ? "NA" : undefined);
        }
    }, [values && values["country"]]);

    React.useEffect(() => {
        if (lastVisitUnknown && values && values[LastVisitField]) {
            onToggleLastVisitUnknown();
        }
    }, [values && values[LastVisitField]]);

    React.useEffect(() => {
        if (isObject(selectedPhysicians)) {
            sleep(50)
                    .then(clearForm)
                    .then(() => {
                        physicianFields.map(p => {
                            const fieldVal = selectedPhysicians[p.name];
                            if (p.name === 'country' || p.name === 'state') {
                                const value = fieldVal && fieldVal.description;
                                setFieldTouched(p.name, !!value);
                                setFieldValue(p.name, value);
                                return;
                            }
                            setFieldTouched(p.name, !!fieldVal);
                            setFieldValue(p.name, fieldVal);
                        });
                        setFieldValue(LastVisitField, selectedPhysicians.lastVisit || '');
                        setFieldValue('uuid', selectedPhysicians.uuid || '');
                    });
        }
    }, [selectedPhysicians]);

    React.useEffect(() => {
        sleep(400)
                .then(fetchPhysician)
                .then(() => setFieldValue('hasPhysicians', (hasPhysicians as any) === null ? null : JSON.stringify(hasPhysicians)));

        return () => {
            setSelectedPhysicians({} as Physician);
            setHasPhysicians && setHasPhysicians(true);
            setIsCountryUSA && setIsCountryUSA(true);
        }
    }, []);

    return (
            <>
                {
                        !isPrimary &&
                        <ToggleButton
                                defaultValue={JSON.stringify(hasPhysicians)}
                                errors={errors}
                                isSpecialCase={true}
                                label={`Did you see a Physician for you ${disclosureCode && `(${disclosureCode})`}?`}
                                listItems={toggleItems}
                                name="hasPhysicians"
                                onToggle={onTogglePhysicianDetails}
                                setFieldValue={setFieldValue}
                                values={values}
                        />
                }
                {
                        !!hasPhysicians &&
                        !isPrimary &&
                        <Grid item xs={12}>
                            <SearchBox
                                    errors={errors}
                                    label=""
                                    multiple={false}
                                    name="hasExistingPhysician"
                                    onSearch={handleInputChange}
                                    options={physiciansList}
                                    setFieldValue={setFieldValue}
                                    values={values}
                            />
                        </Grid>
                }
                {
                        !!hasPhysicians &&
                        physicianFields.map((field: any, index) => {
                            return (
                                    <Grid item xs={12} sm={6} lg={4} key={index}>
                                        <TextFieldCommon
                                                {...commonDisabledProps}
                                                {...commonVariantProps}
                                                {...field}
                                                autoComplete="off"
                                                InputLabelProps={isObject(selectedPhysicians) && {shrink: true}}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                values={values}
                                        />
                                        <EC errors={errors} name={field.name} touched={touched}/>
                                    </Grid>
                            );
                        })
                }
                {
                        !!hasPhysicians &&
                        lastVisitFieldsObj.filter(x => !!x).map((field: any, index) => {
                            return (
                                    <Grid item xs={12} sm={12} key={index}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography className="mb0" variant="body1">
                                                    {field.label}
                                                </Typography>
                                            </Grid>
                                            {
                                                    field &&
                                                    <Grid item xs={12} sm={6}>
                                                        <TextFieldCommon
                                                                {...commonVariantProps}
                                                                {...field}
                                                                autoComplete="off"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                values={values}
                                                        />
                                                    </Grid>
                                            }
                                            {
                                                    field && field.name === LastVisitField &&
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControlLabel
                                                                className="mt15"
                                                                control={lastVisitControl}
                                                                label="Unknown"
                                                        />
                                                    </Grid>
                                            }
                                        </Grid>
                                        <EC errors={errors} name={field.name} touched={touched}/>
                                    </Grid>
                            );
                        })
                }
            </>
    );
};
