import React, { useEffect } from "react";
import { isDebug, unmaskPhoneNumber } from "../../../../../../../utils/commonUtils";
import { Button as MuiButton, Card, CardContent, Checkbox, Grid, Link, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Formik, useFormikContext } from "formik";
import { REQUIRED_FIELD } from "../../../../../../../constants";
import { isAdmin } from "../../../../../../../components/navigation/AdminNavigation";

type PropsType = {
	initialValues: any,
	validationSchema: any,
	activeStep: number,
	step1Values: any,
	beneficiaryType: string,
	user: any,
	checked: boolean,
	percent: number,
	saveBeneficiary: Function,
	handleClose: Function,
	record: any,
	setStep1Values: Function,
	setInitialValues: Function,
	form2InitialValues: any,
	handleNext: Function,
	isCommunityState: boolean,
	isClientSigned: boolean,
	getStepContent: Function,
	handleBack: Function,
	index: number,
	downloadForm: Function,
	setIsSelected: Function,
	steps: any,
    trustDate: boolean,
    setExternalValue: any
}

function AddEditPhysicianForm(props: PropsType) {
	const {
		initialValues,
		validationSchema,
		activeStep,
		step1Values,
		beneficiaryType,
		user,
		checked,
		percent,
		saveBeneficiary,
		handleClose,
		record,
		setStep1Values,
		setInitialValues,
		form2InitialValues,
		handleNext,
		isCommunityState, steps,
		getStepContent, handleBack,
        setExternalValue,
		index, setIsSelected, downloadForm, isClientSigned,
        trustDate
	} = props;
    const beneficiaryFormUri: string = "beneficiary-form.pdf";
	return (
		<>
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				validationSchema={validationSchema}
				validateOnChange={true}
				onSubmit={async (values: any, { setSubmitting, setErrors,setFieldError }) => {
					try {
						//When User submit Last Form
						if (activeStep > 0) {
							let finalValues: any = { ...values, ...step1Values };
							/* NOW here call api to save final values*/
							finalValues.deathBenefitPercentage = Number(finalValues.deathBenefitPercentage) || 0;
							finalValues.phone = (finalValues.phone) ? unmaskPhoneNumber(finalValues.phone + "") : (finalValues.phone || "");
							finalValues.type = beneficiaryType;
							finalValues.clientId = (user && user.client && user.client.id) || 0;
							finalValues.isClientAddress = checked ? 1 : 0;
							/* Trim data before sending to server */
							finalValues.address = (finalValues && finalValues.address && finalValues.address.trim()) || "";
							finalValues.city = (finalValues && finalValues.city && finalValues.city.trim()) || "";
							finalValues.email = (finalValues && finalValues.email && finalValues.email.trim()) || "";
							finalValues.firstName = (finalValues && finalValues.firstName && finalValues.firstName.trim()) || "";
							finalValues.lastName = (finalValues && finalValues.lastName && finalValues.lastName.trim()) || "";
							finalValues.mi = (finalValues && finalValues.mi && finalValues.mi.trim()) || "";
							finalValues.otherRelationship = (finalValues && finalValues.otherRelationship && finalValues.otherRelationship.trim()) || "";
							finalValues.zip = finalValues && finalValues.zip && finalValues.zip.trim();

							/* Trim data before sending to server */
							if (record && record.id > 0) {
								finalValues.id = record.id;
							}
							let resp = await saveBeneficiary(finalValues);
							if (!!resp) {
								handleClose();
							}
							setTimeout(() => {
								setSubmitting(false);
							}, 400);
							return false;
						}


						/*SET Error For New Record*/
						if ((Number(percent) + Number(values.deathBenefitPercentage)) > 100 && !record) {
							setErrors({ deathBenefitPercentage: `Max value can enter ${100 - Number(percent)}` });
							return false;
						}
						/*SET Error For Existing Record*/
						if (record && (
							((Number(percent) - Number(record.deathBenefitPercentage)) + Number(values.deathBenefitPercentage)) > 100
						)) {
							setErrors({ deathBenefitPercentage: `Max value can enter ${100 - ((Number(percent) - Number(record.deathBenefitPercentage)))}` });
							return false;
						}

						/*When User submit First Form */
						setStep1Values(values); /*To Remain Form 1 values after submit step 1*/
						/*Get Client Data*/
						let isSpouseSelected: boolean = values.relationship === "SPOUSE" || false; // If user selected "Spouse" as a relationship
						let clientData: any = (user && user.client && user.client) || undefined;

						let addressData:any = {
							address: (record && record.address) || "",
							city: (record && record.city) || "",
							state: (record && record.state) || "",
							zip: (record && record.zip) || "",
                            dob: ""
						};

                        if ((checked || isSpouseSelected) && clientData) {
                            addressData = {
                                //FOR SPECIAL CASE ONLY BENEFICIARY EDIT
                                address: activeStep > 1 && addressData.address ? addressData.address : (clientData.street || ""),
                                city: activeStep > 1 && addressData.city ? addressData.city : clientData.city || "",
                                state: activeStep > 1 && addressData.state ? addressData.state :clientData.state || "",
                                zip: activeStep > 1 && addressData.zip ? addressData.zip : clientData.zipCode || "",
                            };
                        }
                        addressData.dob = record && record.dob
                        if(values.relationship === "ESTATE") {
                            addressData.relationship = "ESTATE"
                            addressData.dob = (record && record.dob) || ""
                        }
						setInitialValues({ ...form2InitialValues, ...addressData });

						handleNext();
						setTimeout(() => {
							setSubmitting(false);
						}, 400);

					} catch (e) {
						return false;
					}
				}}
			>
				{
					(formikProps: any) => {
						return (
							<form className="floatLeft w100"
								  onSubmit={formikProps.handleSubmit}>
								{
									formikProps.values.relationship === "SPOUSE" && activeStep === 0 && isCommunityState && beneficiaryType === "Primary" &&
									<Card className="alertMessageWrap success stepperWrap alert "
										  style={{ border: "1px solid #3DDB93" }}>

                                        <UpdateValues setExternalValue={setExternalValue} />
										<CardContent
											className="alertMessageWrapCardContent ">
											<div className="imgSec floatLeft textHighLight">
												<InfoOutlinedIcon />
											</div>
											<div className="textSec floatLeft">
												<Typography variant="body1"
															className="mb20">
													In a community property state if
													the client is married at the
													time of death the <strong>spouse
													is entitled to receive at least
													50% up to 100% of the death
													benefit</strong> unless there is
													a valid spousal waiver on file
													at the time of death. In
													community property states if
													someone other than the spouse is
													named as more than 50% primary
													beneficiary, the client will
													need to have the consent of the
													spouse. Community property
													states include, but are not
													limited to <strong>AZ, CA, ID
													LA, NV, NM, TX, WA and
													WI.</strong> Fax this
                                                    <Link onClick={() => downloadForm(beneficiaryFormUri)}> notarized form </Link>
                                                    to 972-755-1585 or call 800-294-9940 for questions.
												</Typography>
											</div>
										</CardContent>
									</Card>
								}
								{step1Values.relationship === "SPOUSE" && activeStep === 1 &&
									<Card className="alertMessageWrap success stepperWrap alert"
										  style={{
											  border: "1px solid #3DDB93",
											  minHeight: "auto",
										  }}>
										<CardContent
											className="alertMessageWrapCardContent ">
											<div className="imgSec floatLeft textHighLight">
												<InfoOutlinedIcon />
											</div>
											<div className="textSec floatLeft">
												<Typography variant="body1"
															className="mb0">
													If this address is not the same
													as your spouse, please provide
													the correct address.
												</Typography>
											</div>
										</CardContent>
									</Card>
								}
								{getStepContent(index, formikProps)}
								{formikProps.values.relationship === "SPOUSE" && activeStep === 0 && formikProps.touched.deathBenefitPercentage && Number(formikProps.values.deathBenefitPercentage) < 50 && isCommunityState && beneficiaryType === "Primary" &&
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<div style={{ display: "flex" }}
												 className="mt30">
												<Checkbox style={{
													height: "28px",
													marginRight: "10px",
												}}
														  className="floatLeft pd0 mr10"
														  color={"primary"}
														  onChange={(e, v) => {
															  setIsSelected(v);
															  formikProps.setErrors({ deathBenefitPercentage: v ? "" : "50% or more required for spouse" });
														  }}
														  inputProps={{ "aria-label": "uncontrolled-checkbox" }} />
												<Typography variant="caption">
                                                    <span>
                                                        The spouse will be waiving their primary beneficiary 50-100
                                                        percent entitlement. Please have the spouse fill out, notorize,
                                                        and fax
                                                        <Link onClick={() => downloadForm(beneficiaryFormUri)}>this waiver form</Link>
                                                        to 972-755-1585 or call 800-294-9940 for questions.
                                                    </span>
												</Typography>
											</div>
										</Grid>
									</Grid>
								}
                                <div className="mt30 floatLeft w100">
                                    {activeStep > 0 &&
                                            <Link className="floatLeft mr15 f18 mt10 textBlack backLink"
                                                  onClick={() => handleBack()}>
                                                <ArrowBackIosIcon
                                                        style={{ fontSize: "18px" }} /> Back
                                            </Link>
                                    }
                                    <MuiButton size="small"
											   disabled={(isClientSigned && !isAdmin) || formikProps.isSubmitting || trustDate}
                                               type={"submit"}
                                               variant="contained"
                                               color="primary"
                                               className={"ml15 floatRightSM mb15"}
                                    >
                                        {activeStep === steps.length - 1 ? `${!record ? "Add" : "Update"} Beneficiary` : "Next"}
                                    </MuiButton>
                                    {isDebug() &&
                                        <pre>{JSON.stringify(formikProps.errors, null, 2)}</pre>}
                                </div>
                            </form>
                        );
                    }}
            </Formik>
        </>
    );
}

export default AddEditPhysicianForm;

function UpdateValues({setExternalValue}:any) {
    const { values, errors }  = useFormikContext();
    console.log('errors', errors)
    useEffect(() => {
        setExternalValue(values);
    }, [values, setExternalValue]);
    return <></>;
}