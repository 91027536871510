
import * as React from "react";
import TextField from '@material-ui/core/TextField';
// @ts-ignore
import InputMask from  "react-input-mask";
const DateInputField = (props: object) => {
    return <InputMask
            mask="99/99/9999"
            {...props}
    >
        {(inputProps: object) =>
                <TextField
                        {...inputProps}
                        type="text"
                        variant="outlined"

                />
        }
    </InputMask>;
};

export default DateInputField;
