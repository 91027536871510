import {ActionType} from "../model";
import {Client} from "../model/client";
import {ccFirst} from "../utils/commonUtils";
import {DocApprovalStatus} from "../constants";
import {setAlert} from "./alert";
export const addClient = (model: Client) => async (dispatch: Function, getState: Function, api: { post: Function })  => {
	try {
		let response = await api.post('account/agent/client', model, {});
		dispatch({ type: ActionType.ALERT, payload: {
				message: "Invitation sent successfully.",
				statusCode: 200
			}});
		return dispatch({ type: ActionType.ADD_CLIENT, payload: response });
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const updateClient = (model: Client, userId: string) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
	try {
		const payload = {
			firstName: model.firstName,
			lastName: model.lastName,
			phoneNumber: model.phoneNumber,
			strategyId: model.strategyId,
			leadSourceId: model.leadSourceId,
			userId: userId,
			otherLeadSource: model.otherLeadSource
		};
		await api.put('account/agent/client/' + model.id, payload, {});
		return dispatch({ type: ActionType.ALERT, payload: { message: "Account Updated Successfully.", statusCode: 200 } });
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};


export const updateClientDetail = (model: {firstName:string, lastName:string, phoneNumber:string, strategyId:number, leadSourceId:number, userId:string, otherLeadSource:any, carrierId : string }, id:number) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        const payload = {
            firstName: model.firstName,
            lastName: model.lastName,
            phoneNumber: model.phoneNumber,
            strategyId: model.strategyId,
            leadSourceId: model.leadSourceId,
            userId: model.userId,
            otherLeadSource: model.otherLeadSource,
            carrierId : model.carrierId
        };
        await api.put('account/agent/client/' + id, payload, {});
        return dispatch({ type: ActionType.ALERT, payload: { message: "Carrier has been updated.", statusCode: 200 } });
    } catch(err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
};

export const getClient = (id: number, bloat:boolean = true) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
	try {
		let resp = await api.get(`account/agent/client/${id}`, {}, {bloat});
        dispatch({ type: ActionType.GET_CLIENT, payload: resp });
		return { type: ActionType.GET_CLIENT, payload: resp }
	} catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err })
		return err;
	}
};

export const createCaseAlert = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post('client/case-alert', payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Case Alert Created",
                statusCode: 200
            }
        });
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const updateCaseAlert = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('client/case-alert', payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Case Alert Updated",
                statusCode: 200
            }
        });
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const deleteCaseAlert = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.delete('client/case-alert', payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Case Alert Deleted",
                statusCode: 200
            }
        });
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const getGroupDetails = (id: number) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
	try {
		let resp = await api.get(`account/group/details/${id}`, {}, {});
		return dispatch({ type: ActionType.GET_CLIENT, payload: resp });
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const getCarrierAndStrategy = (query: { id: string }) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
	try {
		let resp = await api.get('account/carrierAndStrategy', {}, query);
		return dispatch({
			type: ActionType.LIST_CARRIERS_STRATEGY,
			payload: resp
		});
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const acceptAgreement = () => async (dispatch: Function, getState: Function, api: { put: Function }) => {
	try {
		let resp = await api.put(`account/client/acceptagreement`, {}, {});
		return dispatch({
			type: ActionType.USER_DETAILS,
			payload: resp
		});
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const uploadCensus = (payload: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		await api.post(`account/client/census`, payload, {});
		return dispatch({ type: ActionType.ALERT, payload: { message: "Census Uploaded Successfully.", statusCode: 200 } });
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const uploadGroupLinkCensus = (payload: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		await api.post(`account/client/grouplinkcensus`, payload, {});
		return dispatch({ type: ActionType.ALERT, payload: { message: "Census Uploaded Successfully.", statusCode: 200 } });
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const sendEnrollmentRequest = () => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		const resp = await api.post('account/client/enrollment', {}, {});
		dispatch({ type: ActionType.ALERT, payload: { message: "Enrolled Request Sent Successfully.", statusCode: 200 } });
		return dispatch({ type: ActionType.USER_DETAILS, payload: resp });
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const clientSupport = (payload: any) => async (dispatch: Function, getState: Function, api: { postFormData: Function }) => {
	try {
		await api.postFormData('account/client/support', payload, {});
		return dispatch({ type: ActionType.ALERT, payload: { statusCode: 200 } });
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const updateClientStatus = (payload: any) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
	try {
		await api.put('account/client/status', payload, {});
		return dispatch({ type: ActionType.ALERT, payload: { message: "Status Updated Successfully.", statusCode: 200 } });
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const getOfferByCarrierId = (id: string, filter: boolean = true) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
	try {
		let resp = await api.get(`carrier/offer_type/${id}`, {}, {filter});
		return dispatch({ type: ActionType.GET_CARRIER_OFFERTYPE_BY_ID, payload: resp });
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const getOfferByGroupId = (id: string) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
    try {
        let resp = await api.get(`group/offer_type/${id}`,);
        return dispatch({ type: ActionType.GET_CARRIER_OFFERTYPE_BY_ID, payload: resp });
    } catch(err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
};

/*** Update the Entries in User Table ***/
export const updateUserTableByKeyValPair =  (payload: any, id:string, message:boolean = true, messageText:string="Updated Successfully.", statusCode:number = 200) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        await api.put('account/user/update/' + id, payload, {});
        if(message) {
            return dispatch({type: ActionType.ALERT, payload: {message: messageText, statusCode: statusCode}});
        } else {
            return dispatch({type: ActionType.ALERT, payload: {message: "", statusCode: statusCode}});
        }
    } catch(err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
}

/*** update the entries in client table  ***/
 export const updateClientTableByKeyValuePair =  (payload: any, id:string, message: boolean = true) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
     console.log("updateClientTableByKeyValuePair ")
    try {
        await api.put('account/client/update/' + id, payload, {});
        if(message) {
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "Updated Successfully.", statusCode: 200}
            });
        } else {
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "", statusCode: 200}
            });
        }
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        throw new Error(err);
    }
}

export const getClientNotes = (clientId: string, query: any) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        const notes  = await api.get(`account/client/notes/${clientId}`, {}, query);
        return notes;
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        throw new Error(err);
    }
}


export const getClientDocuments = (clientId: string, query: any) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        const documents  = await api.get(`account/client/documents/${clientId}`, {}, query);
        return documents;
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        throw new Error(err);
    }
}

export const updateDocApprovalStatus = (payload: { clientId: number, docId: number, docFrontOrBack: string, docAction: { approvalStatus: string, operationPerformed: string } }) =>
    async (dispatch: Function, getState: Function, api: { put: Function }) => {
        try {
            await api.put(`account/client/documents`, payload, {});
            let message = `Document ${ccFirst(payload.docAction.operationPerformed)} Successfully.`;
            let statusCode = 200;
            if(payload.docAction.operationPerformed === DocApprovalStatus.REJECTED.operationPerformed){
                message = 'Document Rejected';
                statusCode = 400;
            }
            if(payload.docAction.operationPerformed === DocApprovalStatus.MARK_RESOLVED.operationPerformed){
                message = "Document Mark Resolved Successfully";
            }
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: message, statusCode: statusCode}
            });
        } catch(err) {
            dispatch({ type: ActionType.ALERT, payload: err });
            throw new Error(err);
        }
    }

export const approveAllDocsAction = (payload: { clientId: number, docAction: { approvalStatus: string, operationPerformed: string } }) =>
    async (dispatch: Function, getState: Function, api: { put: Function }) => {
        try {
            await api.put(`account/client/documents/approve`, payload, {});
            let message = `All Documents ${ccFirst(payload.docAction.operationPerformed)} Successfully.`;
            let statusCode = 200;
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: message, statusCode: statusCode}
            });
        } catch(err) {
            dispatch({ type: ActionType.ALERT, payload: err });
            throw new Error(err);
        }
}
export const updateDocForRequest = (payload:{documentName: string}) => (dispatch: Function, getState: Function, api: { put: Function }) => {
    dispatch({
        type: ActionType.DOC_FOR_REQUEST,
        payload: payload.documentName
    });
}

export const updatePolicyDocument = (payload:{docType: string, name: string, clientId: number, policyYear: number})=> async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        await api.post(`account/client/policy-doc`, payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Document Uploaded Successfully.", statusCode: 200}
        });
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        throw new Error(err);
    }
}
export const getClientCaseDetails = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.get(`account/get-client-case-details`, {}, {});
        dispatch({ type: ActionType.GET_CLIENT_CASE_DETAILS, payload: response });
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}
export const clientRequest = (payload: {requestType?: string, request?: string}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`client/request`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Thanks for sending us your request. A team member will contact you shortly.", statusCode: 200}
        });
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err

        });
    }
}

export const changeBeneficiaryRequest = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let response = await api.post(`client/change_beneficiary_request`, {}, {});
        return response;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
}


export const getDocuments = (queryObj) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
    try {
        const documents  = await api.get(`client/documents`, {}, queryObj);
        return documents;
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        throw new Error(err);
    }
}

export const clientReferral = (payload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        const response = await api.post(`client/referral`, payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Referral Submitted Successfully", statusCode: 200}
        });
        return response;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw new Error(err);
    }
}
/**
 Client will send Email change request
 @payload: email
 */
export const sendEmailUpdateRequest = (payload: { email: string }) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        await api.post('account/client/email-update-request', payload, {});
        return dispatch({
            type: ActionType.ALERT,
            payload: {message: "Email Change Request Sent Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const verifyGroupAccount = (payload: { inviteToken: string }) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
    try {
        await api.post('account/verify/group', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Email Verified Successfully.", statusCode: 200}
        });
        return {statusCode: 200}
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const updateClientData =  (payload: any, id:string, message: boolean = true) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        await api.put(`account/client/${id}/update`, payload, {});
        if(message) {
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "Updated Successfully.", statusCode: 200}
            });
        } else {
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "", statusCode: 200}
            });
        }
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        throw new Error(err);
    }
}

export const updateClientTableTermAndConditions = (payload: any, id:string, message: boolean = true) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        await api.put(`account/client/${id}/update/tc`, payload, {});
        if(message) {
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "Updated Successfully.", statusCode: 200}
            });
        } else {
            return dispatch({
                type: ActionType.ALERT,
                payload: {message: "", statusCode: 200}
            });
        }
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err });
        throw new Error(err);
    }
}

type FC = (email: string) => (dispatch: Function, getState: Function, api: { get: Function }) => Promise<any>;
/**
 * find client by email
 * @param email
 */
export const findClient: FC = (email: string) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
    try {
        const resp = await api.get('client/find', {}, {email});
        dispatch({ type: ActionType.FIND_CLIENT_BY_EMAIL, payload: resp });
        if(resp.data){
            dispatch(setAlert(resp.message, 'success'));
        }
        return resp.data;
    } catch(err) {
        dispatch({ type: ActionType.ALERT, payload: err })
        return err;
    }
};