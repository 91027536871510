import { ActionType } from "../model";

type DST = (sellerAgreementId: number, actionId: number) => (dispatch: Function, getState: Function, api: any) => Promise<any>;

export const getEnvelope = (enrollmentId?: string, pageUrl?:string) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('docusign/envelope', {}, {enrollmentId, pageUrl});
		dispatch({
			type: ActionType.ALERT,
			payload: {}
		});
		return dispatch({
			type: ActionType.GET_ENVELOPE,
			payload: resp
		});
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const getSellerAgreement = (agentId: number, pageUrl: string) => async (dispatch: Function, store: Function, api: any) => {
    try {
        const path = 'docusign/seller/envelope';
        const resp = await api.get(path, {}, {agentId, pageUrl});
        dispatch({type: ActionType.SELLER_AGREEMENT, payload: resp.data});
        return resp.data;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const addBeneficiary = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('docusign/beneficiary', payload, {});
		dispatch({
			type: ActionType.ALERT,
			payload: { message: "Successfully Added.", statusCode: 200 }
		});
		return dispatch({
			type: ActionType.USER_DETAILS,
			payload: resp
		});
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
}

/**
 * Set ApprovedBy Admin in SellerDocument & Mark Action Item Completed
 * @param sellerAgreementId
 * @param actionId
 */
export const sellerDocumentSetApprovedBy: DST = (sellerAgreementId, actionId) => async (dispatch, getState, api) => {
	try {
        return api.post('docusign/seller/admin-agreement-sign', {actionId, sellerAgreementId});
	} catch(err) {
        return dispatch({payload: err, type: ActionType.ALERT});
	}
}