import React from "react";
import AOS from "aos";
import * as QueryString from "query-string";
import { LeadHeader, LeadBody, LeadFinalStep } from "../../components/lead";
import StrategyName from "../../components/client/StrategyName";
import { useActions } from "../../actions";
import * as Actions from "../../actions/account";
import * as AgentLinksAction from "../../actions/agentLinks";
import Chat from "../client/Invitation/v2/Chat";
import { makeQueryString } from "../../utils/commonUtils";
import "aos/dist/aos.css";

export default function LeadPage(props: any) {
    const [startEstimation, setStartEstimation] = React.useState(2);
    const [strategy, setStrategy] = React.useState({
        id  : 1,
        name : "Kai-Zen",
        displayName : "Kai-Zen",
        slug : "",
        reBrand: 0,
        reBrandLogo: ""
    });
    const [agentLinkDetail, setAgentLinkDetail] = React.useState<any>(null);
    const params = QueryString.parse(props.location.search);
    const accountActions = useActions(Actions);
    const agentLinksActions = useActions(AgentLinksAction);

    const getStrategy = async (slug: string) => {
        const res = await accountActions.getStrategyBySlug(slug);
        setStrategy(res.payload);
    };

    let guid:string = props.match.params.guid || null;

    const getAgentLinkDetail = async (guid: string) => {
        const res = await agentLinksActions.getAgentLinkDetailByGuid(guid);
        if(res && res.strategyId){
            setAgentLinkDetail(res);
            setStrategy(res.strategy);
        } else{
            props.history.push("/");
        }
    }

    React.useEffect(() => {
        if(props.match.path.toLowerCase() === '/kaizen') {
            getStrategy('kaizen');
        } else {
            getAgentLinkDetail(guid);
        }
        
        const ele = document.getElementById("mainWrapDiv");
        if(ele) {
            ele.remove();
        }

        if (params && params.landing) {
            switch (params.landing) {
                case "1" :
                    // setStartEstimation(1);
                    // break;
                case "2" :
                    setStartEstimation(2);
                    break;
                case "3" :
                    setStartEstimation(3);
                    break;
                default :
                    let urlObject: any = {};
                    let queryString: string = "";
                    if (params) {
                        delete params["landing"];
                        let page: any = {
                            landing: 1,
                        };
                        urlObject = { ...page, ...params };
                        queryString = makeQueryString(urlObject);
                    }
                    props.history.push({
                        pathname: window.location.pathname,
                        search: queryString,
                    });
                    break;
            }
        }
        setTimeout(() => {
            // You can also pass an optional settingupdateRootClassess object
            // below listed default settings
            AOS.init({
                // Global settings:
                disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
                initClassName: "aos-init", // class applied after initialization
                animatedClassName: "aos-animate", // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
                disableMutationObserver: false, // disables automatic mutations' detections (advanced)
                debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
                throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


                // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
                offset: 120, // offset (in px) from the original trigger point
                delay: 0, // values from 0 to 3000, with step 50ms
                duration: 400, // values from 0 to 3000, with step 50ms
                easing: "ease", // default easing for AOS animations
                once: false, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation

            });
        }, 5000);
    }, []);

    const getStrategyName = (strategyName: string, registrationMark: boolean | undefined, strategyId:number) => {
        return <StrategyName strategyName={strategyName} registrationMarkLandingPage={registrationMark} parentComponentStrategyId={Number(strategyId)}/>;
    };
    const nextPage = (step: number) => {
        let urlObject: any = {};
        let queryString: string = "";
        setStartEstimation(step);
        if (params) {
            delete params["landing"];
            let page: any = {
                landing: step,
            };
            urlObject = { ...page, ...params };
            queryString = makeQueryString(urlObject);
        }
        props.history.push({
            pathname: window.location.pathname,
            search: queryString,
        });
    };

    let strategyData: any = {
        id : strategy.id,
        name: getStrategyName(strategy.name, true, strategy.id),
        strategyName: strategy.name,
        text: strategy.displayName,
        slug: strategy.slug,
        companyText : "",
        registrationMark: 1,
        reBrand: strategy.reBrand,
        reBrandLogo: strategy.reBrandLogo
    };

    const customProps: any = {
        ...props,
        strategy: strategy,
        strategyData,
        startEstimation,
        setStartEstimation: (step: number) => nextPage(step),
        carrier : (agentLinkDetail && agentLinkDetail.carrier) || undefined,
        agentLinkDetail,
        agent : (agentLinkDetail && agentLinkDetail.agent) || undefined
    };

    return (
            <div>
                <LeadHeader {...customProps}/>
                {
                    startEstimation === 2 &&
                    <LeadBody {...customProps} />
                }
                {
                    startEstimation === 3 &&
                    <LeadFinalStep {...customProps} />
                }
                {
                    customProps.agent &&
                    <Chat {...customProps} />
                }
            </div>
    );
}