import {
    AppBar,
    CardContent,
    Grid,
    List,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
    Card,
    TableCell,
    TableContainer,
    Table,
    TableRow,
    TableBody,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useActions } from "../../../actions";
import * as AccountActions from "../../../actions/account";
import { getAdminTypeName } from "../../../constants";
import { AccountStatusArr } from "../../../model";
import { RootState } from "../../../reducers";
import { dateUtils } from "../../../utils";
import { GreenRadio, MoreInfo, RedRadio } from "../../AddAccountPage";
import { BackButton, DetailBreadcrumb, DetailLayout } from "../../agent/AgentProfile/AgentProfile";
import { CardSnapshot } from "../../client/ClientProfile/component/ClientSnapshot";
import { fullName } from "../../client/ClientProfile/component/tabs/ClientProfileTab";
import { a11yProps, TabPanel, useStyles } from "../../client/ClientProfile/component/TabsMenu";
import { AdminProfileTabReduxForm } from "./AdminProfileTab";
import TableWrap from "../../../components/TableWrap/TableWrap";
import CommonLoader from "../../../components/CommonLoader";
import {makeStyles} from "@material-ui/core/styles";
import {imoRegionDropDown} from "../../../constants";

const AdminProfile = (props: any) => {
    const useStyles = makeStyles({
        root: {
            height: "100%",
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
        }
    });
    const classes = useStyles();
    const accountActions = useActions(AccountActions);

    const user = useSelector((state: RootState) => state.auth.user);
    const account = useSelector((state: RootState) => state.account);
    const [progress, setProgress] = React.useState(false);

    const { match } = props;
    const userId: string = match.params.id;
    const role = user.role;

    React.useEffect(() => {
        if (role && ![1, 2].includes(role))
            throw "Error role must be admin or super admin";

        getAccountDetails(userId, role)
    }, [userId, role]);

    const getAccountDetails = async (userId, role) => {
        setProgress(true);
        await accountActions.getAccount(userId, role);
        setProgress(false);
    }

    if(progress){
        return (<div className={classes.root}>
            <CommonLoader />
        </div>);
    }

    return (
            <DetailLayout title="Admin Detail">
                <Grid item xs={12} md={12} lg={9}>
                    <BackButton history={props.history}/>
                    <DetailBreadcrumb of="Admin" to="/accounts/admin" name={fullName(account)}/>
                </Grid>
                <Grid item xs={12} md={12} lg={8} xl={9}>
                    <Typography variant="h2">
                        {fullName(account)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TabsMenu userId={userId}/>
                </Grid>
            </DetailLayout>
    );
};


const TabsMenu = ({ userId }) => {
    const account = useSelector((state: RootState) => state.account);
    const auth = useSelector((state: RootState) => state.auth);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const isIliaAdmin = account.role === 2;
    let _region = account && account.admin && account.admin.region || "All";

    if(_region !== "All"){
        let region = imoRegionDropDown.find((o)=> o.value === _region);
        _region = region && region.name;
    }
    console.log("regionDb", _region);
    return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AppBar position="static" color="default">
                            <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="on"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="scrollable force tabs example"
                            >
                                <Tab label="ADMIN DETAILS" {...a11yProps(0)} />
                                {
                                    isIliaAdmin &&
                                    <Tab label="ADMIN PERMISSIONS" {...a11yProps(1)} />
                                }
                            </Tabs>
                        </AppBar>
                    </Grid>
                    <Grid item xs={12} md={12} lg={8}>
                        <TabPanel value={value} index={0}>
                            <AdminProfileTabReduxForm initialValues={account} userId={userId}/>
                        </TabPanel>
                        {
                            isIliaAdmin &&
                            <TabPanel value={value} index={1}>
                                <h4>Admin Permissions</h4>
                                <SelectAdminPermissions
                                        disabled={auth.user.role === 2}
                                        accountId={account.id}
                                />
                            </TabPanel>
                        }
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <AdminSnapshot lastLoggedIn={account.lastLogin}
                                       currentStatus={account.inviteStatus}
                                       adminType={getAdminTypeName(account && account.admin && account.admin.adminType)}
                                       region={_region}/>
                    </Grid>
                </Grid>
            </div>
    );
};

export const SelectAdminPermissions = ({ accountId, disabled = false }: any) => {
    const actions = useActions(AccountActions);
    const [permissions, setPermissions] = React.useState<any[]>([]);

    const updatePermission = async (permissionId, isEnabled, accountId) => {
        await actions.updatePermission(permissionId, isEnabled, accountId);
        await getPermissions();
    };

    const getPermissions = async () => {
        const data = await actions.getPermissions(accountId);
        setPermissions(data);
    };

    React.useEffect(() => {
        getPermissions();
    }, [accountId]);

    const handleChange = (event, permission) => {
        const isEnabled = event.target.value === "on";
        updatePermission(permission.id, isEnabled, accountId);
    };

    return (
            <Card>
                <CardContent style={{ padding: 5 }}>
                    <TableWrap>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left" className="width150xs"/>
                                    <TableCell align="left"><span style={{ padding: '5px 10px', fontWeight: 700 }}>On</span>
                                        <span
                                                style={{ padding: '5px 10px', fontWeight: 700 }}>Off</span></TableCell>
                                </TableRow>
                                {
                                    permissions.map((field) => {
                                        let value:boolean = field && field.authPermission && field && field.authPermission && field.authPermission[0] && field.authPermission[0].isEnabled;
                                        return (<TableRow key={field.id}>
                                            <TableCell align="left">{field.name}
                                                <MoreInfo value={field.moreInfo}/> </TableCell>

                                            <TableCell align="left">
                                                <RadioGroup row
                                                            value={value ? "on":"off"}
                                                            onChange={e => handleChange(e, field)} className="floatRight">
                                                    <GreenRadio
                                                            style={{ padding: '5px 10px' }}
                                                            value="on"
                                                            inputProps={{ "aria-label": "on" }}
                                                            disabled={disabled}
                                                    />
                                                    <RedRadio
                                                            style={{ padding: '5px 10px' }}
                                                            value="off"
                                                            inputProps={{ "aria-label": "off" }}
                                                            disabled={disabled}
                                                    />
                                                </RadioGroup>
                                            </TableCell>
                                        </TableRow>);
                                    })
                                }
                            </TableBody>
                    </TableWrap>
                    {/*<div>*/}
                    {/*    {*/}
                    {/*        permissions.map((field) => {*/}
                    {/*            return (<div key={field.id} style={{*/}
                    {/*                display: "flex",*/}
                    {/*                justifyContent: "space-between",*/}
                    {/*                alignItems: "center",*/}
                    {/*            }}>*/}
                    {/*			<span>*/}
                    {/*				*/}
                    {/*			</span>*/}
                    {/*                */}
                    {/*            </div>);*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                </CardContent>
            </Card>
    );
};

const AdminSnapshot = ({ lastLoggedIn, currentStatus, adminType, region }) => {
    return (
            <CardSnapshot title="Admin Snapshot">
                <CardContent className="floatLeft w100">
                    <List className="ClientDetailsList">
                        <li>
                            <label>Last Logged In</label>
                            <span className="labelValue">{lastLoggedIn ? dateUtils.asDbDateFormat(lastLoggedIn) : "NA"}</span>
                        </li>
                        <li>
                            <label>Current Status</label>
                            <span className="labelValue">{AccountStatusArr[currentStatus || 0]}</span>
                        </li>
                        <li>
                            <label>Admin Type</label>
                            <span className="labelValue">{adminType}</span>
                        </li>
                        <li>
                            <label>Region</label>
                            <span className="labelValue">{region}</span>
                        </li>
                    </List>

                </CardContent>
            </CardSnapshot>
    );
};

export default AdminProfile;