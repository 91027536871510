import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { useFormikContext } from "formik";
import { YesNoEnum } from "../../../../../../../constants";
import { isFunc } from "../../../../../../../utils/commonUtils";
import { FormikContextType } from "formik/dist/types";


type InsuredToggleButtonGroupProps = {
	childButtonLabel?: string[];
	className?: string;
	clientSigned?: boolean;
	color?: string;
	exclusive?: boolean;
	id: string;
	onChange?: Function;
	onChangeNo?: Function;
	onChangeNull?: Function;
	onChangeYes?: Function;
	size?: "small" | "medium" | "large";
	value?: any;
};

const InsuredToggleButtonGroup = (props: InsuredToggleButtonGroupProps) => {
	const { setFieldValue, setFieldTouched, values }: FormikContextType<any> = useFormikContext();
	const { childButtonLabel = [YesNoEnum.Yes, YesNoEnum.No] } = props;
	const [YES_LABEL, NO_LABEL] = [childButtonLabel[0], childButtonLabel[1]];
	const {
		className = "toggleButtonGroupQust floatRight",
		clientSigned,
		color = "primary",
		exclusive = true,
		id,
		onChange,
		onChangeNo,
		onChangeNull,
		onChangeYes,
		size = "medium",
		value = [YES_LABEL, NO_LABEL, undefined, null].includes(values[id]) ? (!!values[id] && values[id]) : "",
	} = props;
	const internalOnChange = (ev: any, value: any) => {
		if (value === YES_LABEL) {
			setFieldValue(id, value);

			if (onChangeYes && isFunc(onChangeYes)) {
				onChangeYes(ev, value);
			}
		}

		if (value === NO_LABEL) {
			setFieldValue(id, value);

			if (onChangeNo && isFunc(onChangeNo)) {
				onChangeNo(ev, value);
			}
		}

		if (value === null) {
			setFieldValue(id, null);
			setFieldTouched(id, true);

			if (onChangeNull && isFunc(onChangeNull)) {
				onChangeNull(ev, value);
			}
		}

		if (onChange && isFunc(onChange)) {
			onChange(ev, value);
		}
	};
	const toggleBGProps: ToggleButtonGroupProps = {
		className,
		color,
		exclusive,
		id,
		onChange: internalOnChange,
		size,
		value
	};

	return (
		<ToggleButtonGroup {...toggleBGProps}>
			{
				childButtonLabel.map((label, index) => {
					const tglButtonProps = {
						className: values[id] === YES_LABEL || values[id] === NO_LABEL ? "active" : "",
						disabled: !!clientSigned,
						value: label,
					};
					return (
						<ToggleButton key={index} {...tglButtonProps} >
							{label}
						</ToggleButton>
					);
				})
			}
		</ToggleButtonGroup>
	);
};

export default InsuredToggleButtonGroup;
