import { Action, ActionType } from "../model";
import {
	ProfileDetail,
	Account,
	AgentCarriers,
	Designation,
	DesignationList,
	AdditionalCarrier,
	AdditionalCarrierList,
} from "../model/account";
import createReducer from "./createReducer";

export const profile = createReducer([], {
	[ActionType.GET_PROFILE_DETAILS](state: ProfileDetail, action: Action<Account>) {
		return action.payload;
	},
	[ActionType.ADD_AGENT_CARRIER](state: ProfileDetail, action: Action<AgentCarriers>) {
		if (state.agent && state.agent.carriers) {
			const { agent: { carriers } } = state;
			state.agent.carriers = carriers.concat(action.payload)
		}
		return state;
	},
	[ActionType.UPDATE_AGENT_CARRIER](state: ProfileDetail, action: Action<AgentCarriers>) {
		if (state.agent && state.agent.carriers) {
			const { agent: { carriers } } = state;
			carriers.map((v: any) => {
						if (action.payload.id === v.id) {
							v.agentCode = action.payload.agentCode;
							v.isPrimary = action.payload.isPrimary;
						}
						if(action.payload.id !== v.id && action.payload.isPrimary){
							v.isPrimary = false;
						}
						return v;
				})
		}
		return state;
	},
	[ActionType.DELETE_AGENT_CARRIER](state: ProfileDetail, action: Action<Account>) {
		if (state.agent && state.agent.carriers) {
			const { agent: { carriers } } = state;
			state.agent.carriers = carriers.filter((v: any) => v.id !== action.payload);
		}
		return state;
	}
});

export const designationList = createReducer({}, {
	[ActionType.GET_DESIGNATION_LIST](state: Designation[], action: Action<DesignationList>) {
		return action.payload;
	}
});

export const additionalCarrier = createReducer({}, {
	[ActionType.GET_ADDITIONAL_CARRIER](state: AdditionalCarrier[], action: Action<AdditionalCarrierList>) {
		return action.payload;
	}
});

export const profileImage = createReducer({},{
    [ActionType.ProfileImage](state: string, action: Action<Account>) {
        return {image: action.payload};
    }
})