import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton } from "@material-ui/core"
import { DialogTitle } from "../../components/table/AdminTable"
import { Field, Formik } from "formik";
import { TextField } from "../../components/formikFormInputs";
import * as Yup from "yup";
import { FileAdornment, getUploadedFiles, SecuredLink } from "./TicketPage";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { useActions } from "../../actions";
import * as AwsActions from "../../actions/aws";
import CloseIcon from '@material-ui/icons/Close';

export const CreateTicketDialog = ({ isDialogOpen, onCancel, onOk }) => {
	const user = useSelector((state: RootState) => state.auth.user);
    const [files, setFiles] = useState<any[]>([]);

    const dirName = user.id + "-ticket";
    const isSecuredSignedUrl = true;
    const awsActions = useActions(AwsActions);

    const createTicket = (values, { setSubmitting, resetForm }) => {
        onOk({
            detail: values.detail,
            files
        })
    }

    const uploadFiles = async (fileList) => {
        let uploadedFiles = await getUploadedFiles(fileList, dirName, isSecuredSignedUrl, awsActions)
        setFiles(uploadedFiles);
    }

    const handleUploadFile = async ({ target }) => {
		await uploadFiles(target.files)
	}

    const handlePaste = (e: ClipboardEvent) => {
		const fileList = e.clipboardData && e.clipboardData.files;
		if(fileList) {
			const files = Array.from(fileList);
			if(files.length > 0) {
				uploadFiles(fileList)
			}
		}
	}

    const deleteFile = () => {
        setFiles([]);
    }
    
    return (
        <Dialog
            onClose={onCancel}
            aria-labelledby="customized-dialog-title"
            open={isDialogOpen}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="customized-dialog-title" onClose={onCancel}>
                Create a ticket for client
            </DialogTitle>
            <Formik
                initialValues={{ detail: "" }}
                onSubmit={createTicket}
                validationSchema={Yup.object({
                    detail: Yup.string().required()
                })}
                validateOnMount
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    isValid
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent dividers>
                            <Field
                                name="detail"
                                component={TextField}
                                value={values.detail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onPaste={handlePaste}
                                placeholder="Describe the ticket"
                                InputProps={{
                                    endAdornment: 
                                        <FileAdornment
                                            onUploadFile={handleUploadFile}
                                        />
                                }}
                            />

                            {files.map(file => <>
                                <Box>
                                    <SecuredLink file={file} directory={dirName} />
                                    <IconButton aria-label="close" onClick={deleteFile}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </>
                                
                            )}
                            <Button className="floatRight mt20 ml5" variant="contained" onClick={onCancel}>
                                Cancel
                            </Button>
                            <Button
                                    className="floatRight mt20"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                            >
                                Create ticket
                            </Button>
                        </DialogContent>
                    </form>
                )}
            </Formik>
        </Dialog>
    )
}