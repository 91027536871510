import * as React from "react";
import {List} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import {
    Description as DescriptionIcon
} from "@material-ui/icons";
import NiwListItem from "../NiwListItem";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";

interface listItemType {
    url: string,
    title: string,
    listIcon: any
}

function CarrierNavigation(props:{ handleNavClick: Function }) {
    const {handleNavClick} = props;
    const [routeName, setRouteName] = React.useState(window.location.pathname);
    const { user } = useSelector((state: RootState) => state.auth)

    const navigationsList = [
        {
            url: '/accounts/carrier/clients',
            title: 'Case Management',
            listIcon: <DescriptionIcon/>
        },
        {
            url: '/carrier/analytics',
            title: 'Carrier Analytics',
            listIcon: <ListIcon/>
        }
    ];

    React.useEffect(() => {
        if (window.location.pathname === '/accounts') {
            setRouteName('/accounts/carrier/clients');
        } else {
            setRouteName(window.location.pathname);
        }

    }, [routeName]);

    return (
            <List className="leftNav">
                {
                    navigationsList.map((listItem: listItemType) => {
                                return (
                                        <NiwListItem
                                                key={listItem.url}
                                                url={listItem.url}
                                                title={listItem.title}
                                                listIcon={listItem.listIcon}
                                                routeName={routeName}
                                                setRouteName={setRouteName}
                                                user={user}
                                                handleNavClick={handleNavClick}
                                        />
                                )
                            }
                    )
                }
            </List>
    );
}

export default CarrierNavigation;