import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import { ErrorCommon, TextField, TextFieldCommon } from "../../../../../../components/formikFormInputs";

const activityDescriptionArr = {
    "Employer-owned": [{
        name: "Insured_AviationXT24",
        title: "Next 12 months hours",
    }, {
        name: "Insured_AviationXT25",
        title: "Past 12 months hours",
    }, {
        name: "Insured_AviationXT26",
        title: "1 to 2 years ago hours",
    }],
    "Charter flying or instructing": [{
        name: "Insured_AviationXT27",
        title: "Next 12 months hours",
    }, {
        name: "Insured_AviationXT28",
        title: "Past 12 months hours",
    }, {
        name: "Insured_AviationXT29",
        title: "1 to 2 years ago hours",
    }],
    "Non-commercial pilot or student": [{
        name: "Insured_AviationXT30",
        title: "Next 12 months hours",
    }, {
        name: "Insured_AviationXT31",
        title: "Past 12 months hours",
    }, {
        name: "Insured_AviationXT32",
        title: "1 to 2 years ago hours",
    }],
    "Military": [{
        name: "Insured_AviationXT33",
        title: "Next 12 months hours",
    }, {
        name: "Insured_AviationXT34",
        title: "Past 12 months hours",
    }, {
        name: "Insured_AviationXT35",
        title: "1 to 2 years ago hours",
    }],
    "Other": [{
        name: "Insured_AviationXT36",
        title: "Provide Details",
    }, {
        name: "Insured_AviationXT37",
        title: "Next 12 months hours",
    }, {
        name: "Insured_AviationXT38",
        title: "Past 12 months hours",
    }, {
        name: "Insured_AviationXT39",
        title: "1 to 2 years ago hours",
    }],
};

type Props = {
    isFromClientDetail: boolean,
    clientSigned: boolean,
    values: any, touched: any, errors: any,
    handleBlur: Function,
    handleChange: Function
}

function DescribeAnnualFlyingActivity(props: Props) {
    const { isFromClientDetail, clientSigned, values, touched, errors, handleBlur, handleChange } = props;

    let filedProps = { onChange: handleChange, onBlur: handleBlur };
    const commonProps = {
        helperFieldText: "",
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt20",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
    };
    return <>
        <Grid item xs={12}>
            <Typography variant="subtitle1" component="strong" className="mb10 floatLeft w100">
                Describe your annual flying activity:</Typography>
        </Grid>
        {
                Object.keys(activityDescriptionArr).length > 0 && Object.keys(activityDescriptionArr).map((iteam: string, _index) => {
                    return <>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong">{iteam}</Typography>
                            {activityDescriptionArr[iteam] && activityDescriptionArr[iteam].map((element, index) =>
                                    <>
                                        <TextFieldCommon
                                                id={element.name}
                                                name={element.name}
                                                label={<Label label={`${element.title}`}/>}
                                                values={values}
                                                {...commonProps}
                                                {...filedProps}
                                        />
                                        <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                                    </>,
                            )
                            }
                            <span className="hLine"/>
                        </Grid>
                    </>;
                })

        }
    </>;
}

export default DescribeAnnualFlyingActivity;