//account list is array of rows
//id is user's userId that is selected
export const getUserDetailForDeletePopUp = (accountList, id) => {
    let invitationStatus: number = 0;
    let message: string = "";
    let title: string = "";
    let userDetails: Object[] = [];
    userDetails = accountList.rows.filter((val: any) => (id === val.userId));
    if (userDetails.length === 0) {
        userDetails = accountList.rows.filter((val: any) => (id === val.id));
    }
    if (userDetails.length > 0) {
        invitationStatus = userDetails[0]["user.inviteStatus"];
        if (invitationStatus >= 4) {
            message = "This user has started or completed their enrollment do you still want to delete their account? If yes it will be permanently deleted from ilia ?";
        } else {
            message = "Are you sure you want to delete this user permanently from ilia ?";
        }
        title = userDetails[0]["firstName"] + " " + userDetails[0]["lastName"];
    }
    return {title, message};
}

export const deleteUser = async (setButtonLoader, accountActions, isDeleteModel, userType, notesValue, setIsDeleteModel, setNotesValue, refreshTable) => {
    setButtonLoader(true);
    await accountActions.deleteAccount(isDeleteModel, userType, notesValue);
    setIsDeleteModel("");
    setNotesValue("");
    setButtonLoader(false);
    refreshTable();
}