import { makeStyles } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";

export const AddEditPhysicianDialog = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(1),
			top: "24px",
			color: theme.palette.grey[500],
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
		},
		resetContainer: {
			padding: theme.spacing(3),
		},
	}),
);

export const AddEditPhysician = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
		},
		resetContainer: {
			padding: theme.spacing(3),
		},
	}),
);
