import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
    Dialog,
    IconButton,
    Typography
} from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { ErrorCommon, TextField, TextFieldCommon } from "../formikFormInputs";
import { Button, FileUploader } from "../formInputs";
import { getFileExtension } from "../../utils/commonUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            Paper: {
                minWidth: 767,
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: 18,
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function UploadDocumentModal(props: any) {
    const [uploadedFileName, setUploadedFileName] = React.useState<string>("");
    const [originalFileName, setOriginalFileName] = React.useState<string>("");
    const [fileSize, setFileSize] = React.useState(0);
    const auth = useSelector((state: RootState) => state.auth);
    const intitialValues = {
        documentName: ""
    };

    const { onSave, onClose } = props;
    const handleClose = () => {
        onClose();
    }

    const validate = ((value: any) => {
        const errors: {
            documentName?: string
        } = {};
        if (!value.documentName) {
            errors.documentName = "Required";
        }
        return errors;
    });
    const submitFormik = async (values: any, setSubmitting: Function, resetForm: Function) => {
        const fileType: string = getFileExtension(uploadedFileName);

        onSave(Object.assign(values, {
            fileName: uploadedFileName,
            size: fileSize,
            type: fileType
        }));
    };

    return (
            <div>
                <Dialog fullWidth={true} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={true}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Document Upload
                    </DialogTitle>
                    <DialogContent dividers className="pd0">
                        <Formik
                                initialValues={intitialValues}
                                validate={values => validate(values)}
                                onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => submitFormik(values, setSubmitting, resetForm)}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty,
                                  setFieldValue
                              }) =>
                                    (
                                            <form style={{padding:'20px'}} onSubmit={handleSubmit}>
                                                <TextFieldCommon
                                                        name="documentName"
                                                        label={"Document Name"}
                                                        placeholder="Document Name"
                                                        id="documentName"
                                                        component={TextField}
                                                        values={values}
                                                        onChange={handleChange}
                                                />
                                                <ErrorCommon errors={errors} name={'note'} touched={touched} />

                                                <FileUploader
                                                        isCsv={false}
                                                        isSecuredSignedUrl={true}
                                                        exactFileName={''}
                                                        dirName={auth.user.id}
                                                        fileType={"image/jpeg,image/gif,image/png,application/pdf,.pdf"}
                                                        content={"Upload Documents"}
                                                        getFileSize={(fileSize: number) => {
                                                            setFileSize(fileSize);
                                                        }}
                                                        getFileName={(fileName: string) => {
                                                            setUploadedFileName(fileName);
                                                        }}
                                                        getOrigionalFileName={(fileName: string) => {
                                                            setOriginalFileName(fileName);
                                                        }}
                                                />
                                                {
                                                    originalFileName &&
                                                    <Typography variant="body1">{originalFileName} uploaded successfully</Typography>
                                                }
                                                <Button
                                                        size="medium"
                                                        type="submit"
                                                        label="Save"
                                                        variant="contained"
                                                        color="primary"
                                                        className="floatRight mb20 mt20"
                                                        disabled={!uploadedFileName || !dirty || isSubmitting}
                                                        loading={isSubmitting}
                                                />
                                            </form>
                                    )
                            }
                        </Formik>
                    </DialogContent>
                </Dialog>
            </div>
    )
}
