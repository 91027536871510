import {
    Box, Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import * as React from "react";
import { useContext, useState } from "react";
import { EstimationErrorCommon, TextFieldCommon } from "../../../../../components/formikFormInputs";
import EstimationFormContext from "../Context/EstimationFormContext";
import * as EstimationActions from "../../../../../actions/estimation";
import { useActions } from "../../../../../actions";
import { useFormikContext } from "formik";
import * as TrancheActions from "../../../../../actions/tranche";
import FormControl from "@material-ui/core/FormControl";
import TableWrap from "../../../../../components/TableWrap/TableWrap";
import * as EstimatorActions from "../../../../../actions/estimator";
import {
    addPlus,
    allocationMapper,
    InvestmentValues,
    overRideLabels,
    InvestmentValuesLabel,
    isSuffixExist,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { getCarrierCode } from "../../../../../utils/commonUtils";
import InfoIcon from "@material-ui/icons/Info";
import NumberFormat from "react-number-format";
import { AccountType } from "../../../../../constants";
import { StepFourPopUp } from "./StepFourPopUp";

type userDetailsInterFace = {
    carrierId:any
    strategyId:any
}
export default function StepFour(props: { callingFrom: string, userDetails?: userDetailsInterFace  }) {
    // const {  userDetails } = props;
    // let {  user } =  useSelector((state: RootState) => state.auth);
    // const carrierId = (userDetails && userDetails.carrierId) || (user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.carrier ? user.client.carrier.id : 2));
    // if(getCarrierCode(carrierId) == "ALZ"){
    //    return <></>
    // }else{
        return <NLGStepFour {...props} />
    // }

}

const NLGStepFour = (props: { callingFrom: string, userDetails?: userDetailsInterFace  }) =>{
    const { callingFrom, userDetails } = props;
    const { values, errors, touched, handleChange, setFieldValue }: any = useFormikContext();
    const estimationActions = useActions(EstimationActions);
    const estimatorActions = useActions(EstimatorActions);
    const { estimatorId } = useContext<any>(EstimationFormContext);
    let { user: { client, accessType }, user } =  useSelector((state: RootState) => state.auth);
    const carrierId = (userDetails && userDetails.carrierId) || (user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.carrier ? user.client.carrier.id : 2));
    const carrierName = (userDetails && userDetails.carrierId) || (user.accessType === "client" ? user.demoCarrier && user.demoCarrier.carrierName : (user.client && user.client.carrier ? user.client.carrier.carrierName : 2));
    const [estimatorDataId, setEstimatorDataId] = React.useState<any | null>(estimatorId);
    const strategyId = (userDetails && userDetails.strategyId) || (user.accessType === "client" ? user && user.demoStrategy && user.demoStrategy.id : user && user.client && user.client.strategy && user.client.strategy.id || 1);
    const [allocationQuestion, setAllocationQuestion] = useState({});
    const isAlZ =  getCarrierCode(carrierId) == "ALZ"
    const [open, setOpen] = React.useState(false)
    const getEstimatorDataFromApi = async (query: Partial<{ strategyId: number, carrierId: number }>) => {
        if (query.carrierId && query.strategyId) {
            const res = await estimatorActions.getEstimatorDetails(query);
            if (!!res) {
                setEstimatorDataId(res.id);
            }
        }
    }
    React.useEffect(() => {
        if(!estimatorId){
            let query: Partial<{ strategyId: number, carrierId: number }> = {};
            query = {
                strategyId: strategyId,
                carrierId: carrierId,
            };
            getEstimatorDataFromApi(query).then(() => {
            });
        }
    }, [strategyId, carrierId]);

    React.useEffect(() => {
        (async () => {
            if (estimatorDataId) {
                const res = await estimationActions.getAllocationQuestions({ estimatorId: estimatorDataId, carrierId:carrierId });
                if (res) {
                    const values = {};
                    res.forEach((fields) => {
                        values[fields.code] = fields;
                    });
                    setAllocationQuestion(values);
                }
            }
        })();

    }, [estimatorDataId]);


    const InvestmentMapper = {
        [InvestmentValues.SP500]: {
            desc: <Typography variant="caption">This option will measure the change only (no dividends) in the S&P index
                during the policy
                year and will credit any positive return up to the cap on policy anniversary (not during the year). It
                measures change only but cannot have a return below 0% (the floor).</Typography>,
            question: allocationMapper[InvestmentValues.SP500],
        },
        [InvestmentValues.SP500ALZ]: {
            desc: <Typography variant="caption">The S&P 500 Index represents a broad cross-section of common stocks
                traded on every major U.S. stock exchange. The index is a selection of 500 leading companies from 100
                distinct industry groups found in 10 leading American industrial market sectors.
            </Typography>,
            question: allocationMapper[InvestmentValues.SP500ALZ],
        },
        [InvestmentValues.lowVolatility]: {
            desc: <Typography variant="caption">Low volatility option is designed to have a more predictable return
                removing as much index volatility as possible. These options have no upper cap and typically have higher
                participation rates typically 1.6X+ of the index return (not including dividends).</Typography>,
            question: allocationMapper[InvestmentValues.lowVolatility],
        },
        [InvestmentValues.lowVolatilityALZ]: {
            desc: <Typography variant="caption">The Bloomberg US Dynamic Balance II ER Index is designed to help
                stabilize risk within your insurance policy over time. It seeks to achieve this by dynamically
                allocating daily between the Bloomberg US Equity Custom Futures ER Index and the Bloomberg US Aggregate
                Custom RBI Unfunded Index.
            </Typography>,
            question: allocationMapper[InvestmentValues.lowVolatilityALZ],
        },
        [InvestmentValues.Conservative]: {
            desc: <Typography variant="caption">The conservative strategy seeks to eliminate a zero credit by leveraging the Classic bonus and the guaranteed 1% bonus credited to the policy's annual accumulation value. It focuses avoiding a zero credit, but lower interest potential.
                <InfoIcon color={"primary"} onClick={()=>{setOpen(true)}}  />
            </Typography>,
            question: allocationMapper[InvestmentValues.Conservative],
            prefix : 100/allocationMapper[InvestmentValues.Conservative].length
        },
        [InvestmentValues.ConservativePlus]: {
            desc: <Typography variant="caption">The conservative PLUS strategy seeks to guaranteed return each year to help you avoid receiving a zero credit in any year by leveraging the fixed allocation and the Classic bonus with the guaranteed 1% bonus credited to the policy's annual value. It focuses avoiding a zero credit, but lower interest potential.
                <InfoIcon color={"primary"} onClick={()=>{setOpen(true)}}  />
            </Typography>,
            question: allocationMapper[InvestmentValues.ConservativePlus],
            prefix : 100/allocationMapper[InvestmentValues.ConservativePlus].length
        },
        [InvestmentValues.Moderate]: {
            desc: <Typography variant="caption">The moderate strategy helps limit zero credits in a given year; you need to be comfortable that you may have a zero return year,
                but are able to capture the 15% multiplier bonused opportunity.
                <InfoIcon color={"primary"} onClick={()=>{setOpen(true)}}  />
            </Typography>,
            question: allocationMapper[InvestmentValues.Moderate],
            prefix : 100/allocationMapper[InvestmentValues.Moderate].length
        },
        [InvestmentValues.ModeratePlus]: {
            desc: <Typography variant="caption">The moderate PLUS strategy helps eliminate zero credits in a given year by leveraging the fixed allocation together with the Bonused allocations.  This will guarantee a return each year and you are still able to capture the 15% multiplier bonused opportunity.
                <InfoIcon color={"primary"} onClick={()=>{setOpen(true)}}  />
            </Typography>,
            question: allocationMapper[InvestmentValues.ModeratePlus],
            prefix : 100/allocationMapper[InvestmentValues.ModeratePlus].length
        },
        [InvestmentValues.Aggressive]: {
            desc: <Typography variant="caption">The aggressive mix is designed to help provide the potential for higher interest credits with the Select down years.
                 You take the added risk with the 1% annual asset charge; however, you have greater accumulation potential with a 40% annual interest bonus.
                You need to be comfortable with the possibility of more zeros, but you have the opportunity for higher annual returns.
                <InfoIcon color={"primary"} onClick={()=>{setOpen(true)}}  />
            </Typography>,
            question: allocationMapper[InvestmentValues.Aggressive],
            prefix : 100/allocationMapper[InvestmentValues.Aggressive].length
        },
        [InvestmentValues.AggressivePlus]: {
            desc: <Typography variant="caption">The aggressive PLUS mix is designed to help provide the potential for higher interest credits with the Select down years and eliminate zero credits  by including the fixed allocation. You take the added risk with the 1% annual asset charge; however, you have greater accumulation potential with a 40% annual interest bonus.
                <InfoIcon color={"primary"} onClick={()=>{setOpen(true)}}  />
            </Typography>,
            question: allocationMapper[InvestmentValues.AggressivePlus],
            prefix : 100/allocationMapper[InvestmentValues.AggressivePlus].length
        },
    };
    const selectOption=() => {
        switch (isAlZ){
            case true:
                return [
                    { text: "Conservative", value: InvestmentValues.Conservative },
                    { text: "Moderate", value: InvestmentValues.Moderate },
                    { text: "Aggressive", value: InvestmentValues.Aggressive },
                    { text: "S&P Futures", value: InvestmentValues.SP500ALZ },
                ]
            default:
                return [
                    { text: "S&P 500", value: InvestmentValues.SP500 },
                    { text: "Low Volatility", value: InvestmentValues.lowVolatility },
                ]
        }
    };
    const renderFields = () => {
        let fields: any [];
        const object = {
            type: "radio",
            name: "InvestmentType",
            className: "",
            options: selectOption(),
            color: "primary",
            label: "",
        };

        fields = [object];
        return fields;
    };

    const getPrefix = () =>{
        return InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])] && InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])].prefix ? InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])].prefix+"%" : ""
    }
    return (
            <Grid container spacing={2}>
                {isAlZ && <StepFourPopUp open={open} setOpen={setOpen} allocationQuestion={allocationQuestion}  title={InvestmentValuesLabel[addPlus(values["plus"],values["InvestmentType"])]} />}
                <Grid item xs={12}>
                    <Typography className="mt30 mb0 floatLeft w-100" variant="body1" component="strong">Investment
                        Options</Typography>
                </Grid>
                <EstimationFormContext.Consumer>
                    {
                        (estimatorContextvalue: any) => {
                            const grpInfo: any = renderFields();

                            return (
                                    <>
                                        {grpInfo.map((field, index) => {
                                            switch (field.type) {
                                                case "radio":
                                                    return <>
                                                        <Grid item xs={12} sm={12}
                                                              md={field.name == "healthType" && callingFrom === "ESTIMATOR" ? 12 : 12}
                                                              key={index} className="mb15">
                                                            <FormControl>
                                                                <RadioGroup
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "unset",
                                                                        }}
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        {...field}
                                                                        key={values[field.name] && "InvestmentType"}
                                                                        defaultValue={values[field.name]}
                                                                        onChange={(e)=>{
                                                                            if(!isSuffixExist(e.target.value)){
                                                                                setFieldValue("plus", false)
                                                                            }
                                                                            handleChange(e)
                                                                        }}
                                                                >
                                                                    {
                                                                        field.options.map((opn: any) => {
                                                                            return <FormControlLabel className="mb0"
                                                                                                     value={opn.value}
                                                                                                     control={<Radio
                                                                                                             className="floatLeft pt0" />}
                                                                                                     label={opn.text} />;
                                                                        })
                                                                    }
                                                                </RadioGroup>
                                                                <EstimationErrorCommon errors={errors} name={field.name}
                                                                                       touched={touched} />
                                                                <span>
                                                                    {field && field.helperFieldText}
                                                                </span>
                                                            </FormControl>
                                                        </Grid>
                                                    </>;
                                                default:
                                                    return (
                                                            <Grid item xs={12} sm={6}
                                                                  md={field.name == "healthType" && callingFrom === "ESTIMATOR" ? 12 : 6}
                                                                  key={index} className="mb15">
                                                                <TextFieldCommon
                                                                        {...field}
                                                                        values={values}
                                                                        onChange={handleChange}
                                                                />
                                                                <span>
                                                                    {field && field.helperFieldText}
                                                                </span>

                                                                <EstimationErrorCommon errors={errors} name={field.name}
                                                                                       touched={touched} />
                                                            </Grid>
                                                    );
                                            }
                                        })}
                                        {isAlZ && isSuffixExist(values["InvestmentType"]) && <FormControlLabel
                                                control={
                                                    <Checkbox
                                                            key={values["plus"]}
                                                            className="pt0 pl15 pb0"
                                                            checked={values["plus"]}
                                                            onChange={handleChange}
                                                            name="plus"
                                                            color="primary"
                                                    />
                                                }
                                                label="PLUS(adds Fixed Account to the selected premix)"
                                        />}
                                        {values["InvestmentType"] && <>
                                            <Typography variant="caption" component="strong"
                                                        className="floatLeft w100">For additional
                                                options, contact your agent</Typography>
                                            <TableWrap>
                                                <TableHead style={{ background: "#cfcfcf" }}>
                                                    <TableRow>
                                                        <TableCell padding="default">
                                                            <Typography
                                                                    variant="caption"
                                                                    component="strong"
                                                                    className="floatLeft w100 mb0">
                                                                Description
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell padding="default">
                                                            <Typography
                                                                    variant="caption"
                                                                    component="strong"
                                                                    className="floatLeft w100 mb0">
                                                                Included Indices
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell padding="default">
                                                            <Typography
                                                                    variant="caption"
                                                                    component="strong"
                                                                    className="floatLeft w100 mb0">
                                                                Illustrated Growth Rate
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell padding="default" style={{ whiteSpace: "pre-wrap", minWidth: 360 }}>
                                                            {InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])] && InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])].desc}
                                                        </TableCell>
                                                        <TableCell padding="default">
                                                            {InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])] && InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])].question.map((code) => {
                                                                return allocationQuestion && allocationQuestion[code] &&
                                                                        <Typography variant="caption" component="strong"
                                                                                    className="floatLeft w100 mb15">
                                                                            { (overRideLabels[addPlus(values["plus"],values["InvestmentType"])] && overRideLabels[addPlus(values["plus"],values["InvestmentType"])][code]) ||  `${getPrefix()} ${allocationQuestion[code].label}`}
                                                                        </Typography>;
                                                            })}
                                                        </TableCell>
                                                        <TableCell padding="default" align="center" style={{ whiteSpace: "pre-wrap" }}>
                                                            {
                                                                    InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])]  && InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])].question.reduce(( total ,code)=>{
                                                                        return Number(allocationQuestion[code] && allocationQuestion[code].percentage) + total
                                                                    },0)/InvestmentMapper[addPlus(values["plus"],values["InvestmentType"])].question.length+"%"
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </TableWrap>
                                        </>}
                                    </>
                            );

                        }
                    }
                </EstimationFormContext.Consumer>
                {(callingFrom == "ESTIMATOR" || callingFrom == "CUSTOM_DESIGN" || callingFrom == "ENROLLMENT") &&
                        <Grid xs={12} item>
                    <Typography
                            variant="caption"
                            component="strong"
                            className="floatLeft w100 mb0 mt20">
                        Disclosure:
                    </Typography>
                    <Typography variant="caption">
                        All option strategies are designed for long term performance average
                        returns and not to be the best every year.
                        The client, with the advice of their licensed agent, chooses the
                        index options for their
                        policy. NIW neither necessitates nor advises on index options. For
                        more information about the options available
                        through <>{carrierName}</> and to determine which options are
                        best for you, contact your agent. Option choices can be changed
                        annually before or on policy anniversary and take effect on the next
                        anniversary. Option returns are not guaranteed, and historical
                        performance is no guarantee of future returns. Options have no value
                        until they mature, typically on policy anniversary date or the date
                        they were purchased (typically called a sweep date). NIW bares no
                        responsibility for option performance.
                    </Typography>
                </Grid>}
                {(!(user.role === AccountType.MasterAdmin || user.role === AccountType.Admin) || accessType === "client")  &&
                    <Grid item xs={12} md={12}>
                        <div style={{ display: "flex" }}>
                            <Checkbox name="confirmInvestment" style={{ height: "28px", marginRight: "10px" }}
                                      className="floatLeft pd0 mr10" color={"primary"}
                                      inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                                      onChange={handleChange}
                            />
                            <Typography variant="caption">
                                I confirm the allocation I have selected is {InvestmentValuesLabel[values["InvestmentType"]]}. My advisor has explained the allocation options to me.
                            </Typography>

                        </div>
                    </Grid>
                }

            </Grid>

    );
}
