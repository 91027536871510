import React from "react";
import { Grid } from "@material-ui/core";

const LicenceExpiry = (props: any) => {
	const {
		fieldObj7,
		proofExpired,
		CommonField,
		values,
		errors,
		touched,
		setFieldValue,
		handleChange,
		handleBlur,
	} = props;

	return (
		<React.Fragment>
			{fieldObj7.map((field, index) => {
				return (
					<Grid item xs={12} md={4} xl={2} key={index + field["name"]}>
						<>
							{
								CommonField(
									field,
									{
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										setFieldValue,
									},
								)
							}
						</>
					</Grid>
				);
			})
			}
			<>{proofExpired ?
				<span className="f12 colorRed floatLeft w100 ml15">Drivers license has been expired.</span> : ""}
			</>
		</React.Fragment>
	);
};

export default LicenceExpiry;