import React from 'react';
import {CloudUpload} from "@material-ui/icons";
import {Button, Typography} from "@material-ui/core";

export default function DisabledFileUpload(){
    return <div className="fileUpload mt5">
        <div className="floatLeft">
            <CloudUpload className="floatLeft" />
            <Typography align="left" className="floatLeft te">Drag and Drop File<br /> Here to Upload</Typography>
        </div>
        <div className="floatRight">
            <Button
                    disabled
                    className="btnPrimary"
                    type="submit"
                    variant="contained"
                    color="primary"
            >
                Browse Files
            </Button>
        </div>
    </div>
}