import React, { useEffect } from "react";
import { Checkbox, Typography } from "@material-ui/core";
import { useActions } from "../../../../../../actions";
import * as ClientActions from "../../../../../../actions/client";

const AttestationOfTruth = (props: {
	checked: boolean,
	setPageChecked: Function,
	setAttestRef?: Function,
	userData?: any,
	name?: string
}) => {
	const { setPageChecked, setAttestRef, userData, name } = props;
	const [checked, setChecked] = React.useState<boolean>(props.checked || false);
	const attestRef = React.useRef<any>();
	let userId: string = userData && userData.client && userData.client.userId;
	const clientActions = useActions(ClientActions);

	useEffect(() => {
		if (attestRef.current && typeof setAttestRef === "function") {
			setAttestRef(attestRef);
		}
	}, [attestRef.current]);

	React.useEffect(() => {
		if (name === "MEDICAL_QUESTIONS") {
			let agreeOnMedicalQuestion: boolean = !!(userData && userData.client && userData.client.agreeOnMedicalQuestion);
			setChecked(agreeOnMedicalQuestion);
			setPageChecked(agreeOnMedicalQuestion);
		} else if (name === "INSURED_QUESTIONS") {
			let agreeOnInsuredQuestion: boolean = !!(userData && userData.client && userData.client.agreeOnInsuredQuestion);
			setChecked(agreeOnInsuredQuestion);
			setPageChecked(agreeOnInsuredQuestion);
		} else if (name === "FINANCIAL_LIABILITIES_QUESTION") {
			let agreeOnFinancialQuestion: boolean = !!(userData && userData.client && userData.client.agreeOnFinancialQuestion);
			setChecked(agreeOnFinancialQuestion);
			setPageChecked(agreeOnFinancialQuestion);
		} else if (name === "FINANCIAL_LIABILITIES_QUESTION_LONG_FORM") {
			setChecked(false);
			setPageChecked(false);
		} else if (name === "AVIATION_AVOCATION_FOREIGN_TRAVEL") {
			let agreeOnAviationAvocation: boolean = !!(userData && userData.client && userData.client.agreeOnAviationAvocation);
			setChecked(agreeOnAviationAvocation);
			setPageChecked(agreeOnAviationAvocation);
		} else {
			setChecked(false);
			setPageChecked(false);
		}
	}, [userData]);

	return (
		<div className="attestationBox mt60-">
			<Typography className="textBlueDark mb0"><strong>Attestation of Truth:</strong> The responses that I
				will
				provide to the questions below will be complete, accurate, and truthful to the best of my
				knowledge and belief. I acknowledge that any inaccurate or misleading statements could result in the
				denial of benefits or rescission of
				the policy.
				<div style={{ display: "flex" }} className="mt15" id="attestOfTruth" ref={attestRef}>
					<Checkbox style={{ height: "28px", width: "16px", marginRight: "10px" }}
							  className="floatLeft pd0 mr10" color={"primary"}
							  value={checked}
							  checked={checked}
							  onChange={async (e, v) => {
								  setChecked(e.target.checked);
								  setPageChecked(e.target.checked);
								  let payload: any = {};
								  if (name === "MEDICAL_QUESTIONS") {
									  payload = { agreeOnMedicalQuestion: e.target.checked };
								  } else if (name === "INSURED_QUESTIONS") {
									  payload = { agreeOnInsuredQuestion: e.target.checked };
								  } else if ((name === "FINANCIAL_LIABILITIES_QUESTION") || (name === "FINANCIAL_LIABILITIES_QUESTION_LONG_FORM")) {
									  payload = { agreeOnFinancialQuestion: e.target.checked };
								  } else if (name === "AVIATION_AVOCATION_FOREIGN_TRAVEL") {
									  payload = { agreeOnAviationAvocation: e.target.checked };
								  }
								  if (!!userId && Object.keys(payload).length > 0) {
									  await clientActions.updateClientTableTermAndConditions(payload, userId, false);
								  }
							  }}
							  inputProps={{ "aria-label": "uncontrolled-checkbox" }} />
					<Typography variant="caption" style={{ marginTop: 2, marginLeft: 5 }}>
						<span> I Agree</span>
					</Typography>
				</div>
				{!checked &&
					<p style={{ margin: 0 }}>
						<span style={{ color: "#FF1744", fontSize: 12 }}> Required Field </span>
					</p>
				}
			</Typography>
		</div>

	);
};
export default AttestationOfTruth;