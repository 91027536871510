import React from "react";
import { useFormikContext } from "formik";
import { Label } from "../../../../../../../components/formInputs";
import {
    DatePicker,
    ErrorCommon,
    NumberTextFieldWithCb,
    TextFieldCommon,
} from "../../../../../../../components/formikFormInputs";
import { Grid, Typography } from "@material-ui/core";
import { AdventureSportsPIQ69, BungeeJumping, FormControlChkBox, PIQ } from "../InsuredQuestions";

type BungeeJumpingType = {
    component: any;
    disabled: boolean;
    disableFuture?: boolean;
    filedType: string;
    helperFieldText? : string;
    id: string;
    label: JSX.Element;
    name: string;
    placeholder: string;
    variant: string;
    saveFormat?: string;
    thousandSeparator?: boolean;
};

function RenderField(props:{name:string, title:string, clientSigned:boolean, index: number}) {
    const { values, handleChange, handleBlur, errors, touched }:any = useFormikContext();
    const { name, title, clientSigned, index } = props;
    const fieldObj: BungeeJumpingType[]  = [
        {
            id: `${name}${BungeeJumping.TimesParticipatedLast12Months}`,
            name: `${name}${BungeeJumping.TimesParticipatedLast12Months}`,
            label: <Label label={"# of times participated last 12 months"} />,
            placeholder: "# of times participated last 12 months",
            component: NumberTextFieldWithCb,
            thousandSeparator:false,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
        },
        {
            id: `${name}${BungeeJumping.TimesPlanParticipateNext12Months}`,
            name: `${name}${BungeeJumping.TimesPlanParticipateNext12Months}`,
            label: <Label label={"# of times plan to participate in the next 12 months"} />,
            placeholder: "# of times plan to participate in the next 12 months",
            component: NumberTextFieldWithCb,
            thousandSeparator:false,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
        },
        {
            id: `${name}${BungeeJumping.DateOfLastParticipation}`,
            name: `${name}${BungeeJumping.DateOfLastParticipation}`,
            label: <Label label={"Date of last participation"} />,
            helperFieldText: "",
            placeholder: "Date of last participation",
            disabled: clientSigned,
            filedType: "string",
            component: DatePicker,
            disableFuture: true,
            variant: "outlined",
            saveFormat: "USER"
        },
    ];
    return (
        <Grid container spacing={2} className="mb10">
            <Grid item xs={12}>
                <Typography className="floatLeft mb0 mt15 w100" component="strong">
                    <FormControlChkBox
                            controlClassName="mr10"
                            controlName={`${PIQ}-69XC_${index}`}
                            disabled={clientSigned}
                            fieldControlLabel={""}
                            fieldControlLabelClassName="formikRadio mr0"
                    />
                    {title}
                </Typography>
            </Grid>
            {
                    values && values[`${PIQ}-69XC_${index}`] &&
                    fieldObj.map((field: BungeeJumpingType, fieldObjIndex: number) => {
                        return (
                                <Grid item xs={12} md={6} xl={6} key={fieldObjIndex}>
                                    <TextFieldCommon
                                            {...field} values={values}
                                            onChange={(e: any) => handleChange(e)}
                                            onBlur={handleBlur}
                                    />
                                    <ErrorCommon errors={errors} name={field.name} touched={touched} />
                                </Grid>
                        );
                    })
            }
        </Grid>
    )
}

const ProposedInsuredQuestion69 = (props:{clientSigned:boolean, showComponent:Function, setErrors:Function, isFromClientDetail:boolean, offerType:string}) => {
    const { clientSigned } = props;

    return (
        <React.Fragment>
            {
                AdventureSportsPIQ69.map(({name, title}, key) => (
                        <RenderField key={key} clientSigned={clientSigned} name={name} title={title} index={key} />
                ))
            }
        </React.Fragment>
    )
}

export default ProposedInsuredQuestion69;