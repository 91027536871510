import { allocationMapper } from "../utils/utils";
import { useActions } from "../../../../actions";
import * as Actions from "../../../../actions/estimation";
import * as React from "react";
import { useState } from "react";
import { isDebug } from "../../../../utils/commonUtils";
import * as EstimatorActions from "../../../../actions/estimator";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";


// estimatorId is null during the invoke of hoot then need to pass run time parameter in returning function
export function useCreateAllocationPayLoad(estimatorId : null| string, externalCarrierId?:any){
    const estimationActions = useActions(Actions);
    const estimatorActions = useActions(EstimatorActions);
    const [allocationQuestion, setAllocationQuestion] = useState({});
    let { user: { client, accessType }, user } =  useSelector((state: RootState) => state.auth);
    const carrierId = user.accessType === "client" ? user.demoCarrierId : user.client && user.client.carrier && user.client.carrier.id;
    const strategyId = user.accessType === "client" ? user && user.demoStrategy && user.demoStrategy.id : user && user.client && user.client.strategy && user.client.strategy.id

    React.useEffect(()=>{
       (async ()=>{
           if(estimatorId){
               const res = await estimationActions.getAllocationQuestions({ estimatorId: estimatorId, carrierId: externalCarrierId ||  carrierId });
               const data = {};
               if (res && res.length > 0) {
                   res.forEach((fields) => {
                       data[fields.code] = fields;
                   });
               }
               setAllocationQuestion(data)
           }
       })()
   },[estimatorId])
    // need to pass query to fetch estimator settings then to fetch allocations
    // one of query or (InitEstimatorId, runTimeCarrierId) needed if  estimatorId id is not define
   //  InitEstimatorId is estimator admin setting id
   return async (InvestmentType, options:{query?:any, InitEstimatorId?: any, runTimeCarrierId?:any} = {query:false, InitEstimatorId:null, runTimeCarrierId:null} ) =>{
       let allocationQuestionList = {...allocationQuestion };
       let { query = false , InitEstimatorId, runTimeCarrierId  } = options
            if(!estimatorId){
                let res
               if(!InitEstimatorId) {
                    if (!query) {
                        query = {
                            strategyId: strategyId,
                            carrierId: carrierId,
                        };
                    }
                    // if admin estimator setting does not exist need to fetch
                    const estimatorSettings = await estimatorActions.getEstimatorDetails(query);
                    res = await estimationActions.getAllocationQuestions({ estimatorId: estimatorSettings.id, carrierId: runTimeCarrierId || carrierId });
                }else{
                   res = await estimationActions.getAllocationQuestions({ estimatorId: InitEstimatorId, carrierId: runTimeCarrierId || carrierId });
               }
              isDebug() && console.log(res, " === res")
              const data = {};
              if (res && res.length > 0) {
                  res.forEach((fields) => {
                      data[fields.code] = fields;
                  });
              }
              allocationQuestionList = data
          }
        if(!allocationMapper[InvestmentType]) return [];
        const allocationweight= 100/allocationMapper[InvestmentType].length
        const allocationPayload = allocationMapper[InvestmentType].map((code)=>{
            return {
                "allocationname": code,
                "allocationrate": allocationQuestionList[code] && allocationQuestionList[code].percentage,
                "allocationweight": allocationweight,
            }
        })
        return [{
            "allocations":allocationPayload,
            "from": "1",
            "through": "M"
        }]
    }
}