import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Grid, IconButton, Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBusiness from "./AddBusiness";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import NumberFormat from "react-number-format";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import { Label } from "../../../../../../../components/formInputs";
import TableWrap from "../../../../../../../components/TableWrap/TableWrap";

const useStyles = makeStyles({
	table: {
		minWidth: 767,
	},
	tableHead: {
		backgroundColor: "#EDF3F8",
	},
});

function Row(props: {
	row: any,
	setRecord: Function,
	setIsShowBusinessModal: Function,
	deleteAccount: Function,
	clientSigned: boolean | number
}) {
	const { row, setRecord, setIsShowBusinessModal, deleteAccount, clientSigned } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<TableRow>
				<TableCell component="th" scope="row">
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					{`${row.nameOfBusiness}`}
				</TableCell>
				<TableCell component="th" scope="row">
					{`${row.typeOfBusiness}`}
				</TableCell>
				<TableCell component="th" scope="row">
					{`${row.yearAcquired}`}
				</TableCell>

				<TableCell component="th" scope="row">
					<IconButton aria-label="Info" color="primary">
						<EditIcon onClick={() => {
							setRecord(row);
							setIsShowBusinessModal(true);
						}} />
					</IconButton>
					<IconButton aria-label="Info" color="primary"
								onClick={(e) => {
									if (clientSigned) {
										e.preventDefault();
										return;
									}
									deleteAccount(row.id);
								}}>
						<DeleteOutlineIcon className="colorRed" />
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell><strong>How was Value Determined</strong></TableCell>
										<TableCell><strong>Ownership</strong></TableCell>
										<TableCell align="right"><strong>Fair Market Value</strong></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>
											{row.valueDetermined}
										</TableCell>
										<TableCell>{`${row.percentOwed ? row.percentOwed + "%" : ""}`}</TableCell>
										<TableCell align="right">
											<NumberFormat
												value={row.fairMarketValue || 0}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$"}
											/>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}


const BusinessEquity = (props: any) => {
	const { user, isFromClientDetail, setIsError, setBusinessEquityVerifyRef, setBusinessEquityVerified } = props;
	const clientSigned = user && user.enrollment && user.enrollment.clientSigned ? 1 : 0;
	const classes = useStyles();
	const [isShowBusinessModal, setIsShowBusinessModal] = React.useState<Boolean>(false);
	const { updateClientFields } = useActions(AccountActions);
	const [isConfirmCheckbox, setIsConfirmCheckbox] = React.useState(user && user.client && user.client.verifyBusinessEquity ? true : false);
	const businessEquityVerifyRef = React.useRef<any>();
	const { getBusinessList, deleteBusinessAccount } = useActions(AccountActions);
	const [record, setRecord] = React.useState(undefined);
	const [totalHoldings, setTotalHoldings] = React.useState(0);
	const [data, setData] = React.useState({
		count: 0,
		rows: [],
	});

	useEffect(() => {
		if (businessEquityVerifyRef.current && typeof setBusinessEquityVerifyRef === "function") {
			setBusinessEquityVerifyRef(businessEquityVerifyRef);
		}
	}, [businessEquityVerifyRef.current]);
	useEffect(() => {
		setBusinessEquityVerified(isConfirmCheckbox);
	}, [isConfirmCheckbox]);
	React.useEffect(() => {
		getBusiness();
	}, [isShowBusinessModal]);

	const getBusiness = async () => {
		let resp = await getBusinessList(user && user.client && user.client.id || 0);
		if (resp && resp.data && resp.data.count > 0) {
			setData(resp.data);
			let currentMarketValue: number = 0;
			for (let index in resp.data.rows) {
				currentMarketValue = Number(resp.data.rows[index].fairMarketValue) + currentMarketValue;
			}
			setTotalHoldings(currentMarketValue);
		} else {
			setData({
				count: 0,
				rows: [],
			});
			setTotalHoldings(0);
		}
	};

	const deleteAccount = async (id: number) => {
		await deleteBusinessAccount({ id });
		getBusiness();
	};

	return (
		<Grid container spacing={2} justify="space-between" className="mb15">
			<Grid item xs={12}>
				<span className="hLine" />
				<Typography variant="body1" component="strong" className="mb15 mt30 floatLeft w100">
					<Label label={"Business Equity"} required={!isFromClientDetail} />
					<Button startIcon={<AddCircleIcon className="floatLeft mr15" />} disabled={data && data.count >= 3}
                            color="primary"
                            className="floatRight mt25Small"
                            style={{ lineHeight: "27px" }} onClick={(e) => {
						if (clientSigned) {
							e.preventDefault();
							return;
						}
						setRecord(undefined);
						setIsShowBusinessModal(true);
					}}
					>
                        Add another business
					</Button>
				</Typography>

                        <Typography variant="caption"
                                    className="textLight mb0 floatLeft w100">
                            Please provide details of your business equity(ies) below.
                        </Typography>
                {
                        data && data.count > 0 &&
                        <>
                        <Grid item xs={12}>
                            <TableWrap>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell align="left">Name of Business</TableCell>
                                <TableCell align="left">Business Type</TableCell>
                                <TableCell align="left">Year Acquired</TableCell>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.count > 0 &&
                                    data.rows.map((row: any, index) => (
                                            <Row key={index} row={row} setRecord={(row) => setRecord(row)}
                                                 setIsShowBusinessModal={(flag: boolean) => setIsShowBusinessModal(flag)}
                                                 deleteAccount={(id) => deleteAccount(id)}
                                                 clientSigned={clientSigned} />
                                    ))
                            }
                        </TableBody>
                    </TableWrap>
                            <Typography variant="subtitle1" component="strong">
                            <span className="floatRight mt20"> Total Equity: {totalHoldings > 0 ? <NumberFormat
                                    value={totalHoldings || 0}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                            /> : "N/A"}</span>
                    </Typography>
                        </Grid>
                            {!isFromClientDetail &&
                            <Grid item xs={12}>
                                <div style={{ display: "flex" }} className="mt20" ref={businessEquityVerifyRef} id="businessEquityVerifyRef">
                                    <Checkbox style={{ height: "28px", marginRight: "10px" }}
                                              className="floatLeft pd0 mr10" color={"primary"}
                                              value={isConfirmCheckbox}
                                              checked={isConfirmCheckbox}
                                              onChange={(e, v) => {
                                                  setIsError(!v);
                                                  setIsConfirmCheckbox(v);
                                                  updateClientFields({
                                                      verifyBusinessEquity: v ? 1 : 0,
                                                      apiType: "BusinessEquity",
                                                  }, user && user.client && user.client.id || "", isFromClientDetail);
                                              }}
                                              inputProps={{ "aria-label": "uncontrolled-checkbox" }} />
                                    <Typography variant="caption">
                                        <span>I verify that my total business equity is </span>
                                        <NumberFormat
                                                value={totalHoldings || 0}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                        />.
                                        <span className="requiredHighlight">*</span>
                                    </Typography>
                                </div>
                                {
                                        !isConfirmCheckbox &&
                                        <p style={{ margin: 0 }}>
                                            <span style={{ color: "#FF1744", fontSize: 12 }}> Required Field </span>
                                        </p>
                                }

                                <span className="hLine" />
                            </Grid>
                            }
                        </>
                }
			<AddBusiness isFromClientDetail={isFromClientDetail} record={record} user={user}
						 open={isShowBusinessModal}
						 setOpen={setIsShowBusinessModal} clientSigned={clientSigned} />
		</Grid>
        </Grid>
	);
};

export default BusinessEquity;