import { EnrollmentQuestionProps } from "../../model/enrollment";

import { additionalFieldOptions } from "../../constants";

const fullQuestions: EnrollmentQuestionProps[] = [
    {
        key: "3",
        name: "Proposed_Insured_Questions-3",
        label: "Have you ever applied for life, health, or disability insurance or reinstatement of same, which was declined, postponed, rated or modified in any way?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-101',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details'
    },
    {
        key: "4",
        name: "Proposed_Insured_Questions-4",
        label: "Within the past 12 months have you applied for or do you have any applications pending for life or disability insurance?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-102',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details'
    },
    {
        key: "5",
        name: "Proposed_Insured_Questions-5",
        label: "Is the policy or rider being applied for replacing any inforce life insurance or annuity contract(s) including long term care insurance, disability income insurance or riders?",
        label1: "Replacement activity includes any of the following actions that have occurred or are being considered:",
        label2: "Lapse, forfeit, surrender (partial or full) or termination",
        label3: "Reduction in coverage, premium, policy value or period of coverage (including reduced paid-up and extended term)",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-103',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details'
    },
    // {
    //     key: "10",
    //     name: "Proposed_Insured_Questions-10",
    //     label: "Are you actively at work at the customary workplace, doing the usual duties and functions required by the position during the normal work hours and weekly period?",
    //     type: "radio",
    //     default: false,
    //     onYes: false,
    //     additionalRemarks: true,
    //     showRemarks: false,
    //     additionalFieldName: 'Remark-10-Remarks',
    //     additionalFieldType: 'textfield',
    //     additionalFieldRequired: true,
    //     additionalFieldLabel: 'Provide Details'
    // },
    {
        key: "12",
        name: "Proposed_Insured_Questions-12",
        label: "Within the past 5 years have you worked less than full time, received or applied for disability or worker's compensation?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-110',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details'
    },
    {
        key: "20",
        name: "Proposed_Insured_Questions-20",
        label: "Within the past 10 years, have you been convicted of, or are you currently charged with, a felony or misdemeanor, or are you currently on parole or probation?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-105',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details"
    },
    {
        key: "21",
        name: "Proposed_Insured_Questions-21",
        label: "In the past 10 years have you used marijuana, cocaine, heroin, or any other illicit drug or controlled substance, been advised by a physician to discontinue or reduce alcohol or drug intake, used drugs not prescribed by a physician, been self-admitted to a drug or alcohol treatment facility, or been a member of a support group such as NA or AA?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-130',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details, Dates, Results'
    },
    {
        key: "22",
        name: "Proposed_Insured_Questions-22",
        mainLabel: "Do you currently:",
        subQuestionLi: "a. ",
        label: "Use or require the use of any mechanical or medical devices such as: wheelchair, walker, multi-prong cane, hospital bed, dialysis machine, respirator oxygen, motorized cart or stair lift?",
        type: "radio",
        notShowHrLine: true,
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-135',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "23",
        name: "Proposed_Insured_Questions-23",
        subQuestionLi: "b. ",
        label: "Need help, assistance or supervision for: bathing, eating, dressing, toileting, walking, transferring, or maintaining continence?",
        type: "radio",
        notShowHrLine: true,
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-136',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "24",
        name: "Proposed_Insured_Questions-24",
        subQuestionLi: "c. ",
        label: "Need help, assistance or supervision in: taking medication, doing housework, laundry, shopping, or meal preparation?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-137',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    // {
    // 	key: "28",
    // 	name: "Proposed_Insured_Questions-28",
    // 	label: "During the past 5 years have you been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: memory loss, confusion, amnesia",
    // 	type: "radio",
    // 	default: false,
    // 	onYes: true,
    // 	additionalRemarks: true,
    // 	additionalFieldName: 'Remark-28-Doc_Reason',
    // 	additionalFieldType: 'textfield',
    // 	additionalFieldRequired: true,
    // 	additionalFieldLabel: "Provide Details, Dates, Results"
    // },
    {
        key: "30",
        name: "Proposed_Insured_Questions-30",
        label: "During the last 5 years have you plead guilty to or been convicted of any moving vehicle violations or DUI or have you had a suspended license?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Multi_Form-30',
        additionalFieldType: "checkbox",
        additionalFieldRequired: true,
        additionalFieldLabel: "",
        additionalFieldOptions: additionalFieldOptions[30],
        additionalSubFieldName: 'Remark-104',
        additionalSubFieldType: 'textfield',
        additionalSubFieldRequired: true,
        additionalSubFieldLabel: 'Provide Details'

    },
    {
        key: "31",
        name: "Proposed_Insured_Questions-31",
        label: "Have you been or are you currently involved in any bankruptcy proceedings that have not been discharged?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-106',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Type and Date Discharged"
    },
    {
        key: "33",
        name: "Proposed_Insured_Questions-33",
        label: "Do you participate in any aviation activity other than as a fare paying passenger?",
        type: "radio",
        default: false,
        onYes: true,
    },
    {
        key: "34",
        name: "Proposed_Insured_Questions-34",
        label: "During the next 2 years, do you intend to live or travel outside of the United States? (If yes, complete form 1480)",
        type: "radio",
        default: false,
        onYes: true,
    },
    {
        key: "35",
        name: "Proposed_Insured_Questions-35",
        label: "Have you been offered any cash incentive or other consideration (such as free insurance) as an inducement to apply for or become an insured under this life insurance policy?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-107',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details"
    },
    {
        key: "36",
        name: "Proposed_Insured_Questions-36",
        label: "Have you been involved in any discussions about the possible sale or transfer of this policy to an unrelated third party, such as (but not limited to) a life settlement company or investor group?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-108',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details"
    },
    {
        key: "37",
        name: "Proposed_Insured_Questions-37",
        label: "Are you currently taking, or have you taken within the last 12 months, any prescription medications or over the counter drugs, including aspirin and/or herbal supplements? (If yes, provide name of medication and reason/diagnosis.)",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-109',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "List type, dose, frequency and reason/diagnosis"
    },
    // {
    // 	key: "42",
    // 	name: "Proposed_Insured_Questions-42",
    // 	label: "For the past 5 years only: any shortness of breath, dizzy spells, unconsciousness, headaches or memory loss?",
    // 	type: "radio",
    // 	default: false,
    // 	onYes: true
    // },
    {
        key: "43",
        name: "Proposed_Insured_Questions-43",
        mainLabel: "Within the past 5 years have you:",
        subQuestionLi: "a. ",
        label: "Consulted with a physician other than your personal physician or had x-rays, electrocardiograms, heart catheterization, mammograms, ultrasounds, biopsy, or any other medical tests and/or procedures, except those related to the Human Immunodeficiency Virus (AIDS Virus)?",
        type: "radio",
        notShowHrLine: true,
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-131',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "44",
        name: "Proposed_Insured_Questions-44",
        subQuestionLi: "b. ",
        label: "Been admitted to a hospital, seen in an Emergency Department or been advised by a member of the medical profession to enter a hospital for observation, operation or treatment of any kind?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-132',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "45",
        name: "Proposed_Insured_Questions-45",
        label: "Do you have any pending appointments with any health care provider or medical facility? (If yes, provide date, physician/facility name and address, and reason for visit.)",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-133',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide date, physician/facility name and address, and reason for visit."
    },
    {
        key: "46",
        name: "Proposed_Insured_Questions-46",
        label: "Has a biological parent or sibling been diagnosed or treated by a health professional for cancer, heart disease, Huntington's Disease, Lou Gehrig's Disease (ALS), or polycystic kidney disease?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-134',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    // {
    // 	key: "47",
    // 	name: "Proposed_Insured_Questions-47",
    // 	label: "During the past 5 years have you been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for:  Falls, Paralysis, Numbness, Tremors, Imbalance, or any condition which causes limited motion?",
    // 	type: "radio",
    // 	default: false,
    // 	onYes: true,
    // 	additionalRemarks: true,
    // 	additionalFieldName: 'Remark-47-Doc_Reason',
    // 	additionalFieldType: 'textfield',
    // 	additionalFieldRequired: true,
    // 	additionalFieldLabel: "Provide Details, Dates, Results"
    // },
    {
        key: "13",
        name: "Proposed_Insured_Questions-13",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the heart, including irregular heartbeat, murmur, rheumatic fever, coronary artery disease, heart attack, chest pain, angina, high blood pressure, or high cholesterol?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Multi_Form-13',
        additionalFieldType: 'checkbox',
        additionalFieldRequired: true,
        additionalFieldLabel: '',
        additionalFieldOptions: additionalFieldOptions[13],
        additionalSubFieldName: 'Remark-111',
        additionalSubFieldType: 'textfield',
        additionalSubFieldRequired: true,
        additionalSubFieldLabel: 'Provide Details'
    },
    {
        key: "101",
        name: "Proposed_Insured_Questions-101",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the circulatory or vascular system, including aneurysm, transient ischemic attack, stroke, carotid artery or arterial disease?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-112',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "38",
        name: "Proposed_Insured_Questions-38",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the lungs or respiratory system, including sleep apnea, shortness of breath, asthma, bronchitis, emphysema, chronic obstructive pulmonary disease, tuberculosis, or allergies?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-113',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "18",
        name: "Proposed_Insured_Questions-18",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any digestive system disorder, including ulcer, chronic indigestion, hepatitis, cirrhosis, jaundice, or abnormal condition of the liver, stomach, intestine or pancreas, esophagus, gallbladder, or colon?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Multi_Form-18',
        additionalFieldType: 'checkbox',
        additionalFieldRequired: true,
        additionalFieldLabel: '',
        additionalFieldOptions: additionalFieldOptions[18],
        additionalSubFieldName: 'Remark-114',
        additionalSubFieldType: 'textfield',
        additionalSubFieldRequired: true,
        additionalSubFieldLabel: 'Provide Details'
    },
    {
        key: "102",
        name: "Proposed_Insured_Questions-102",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the brain or nervous system, including seizures/epilepsy, tremors, falls or imbalance, fainting, dizzy spells, headaches or migraines, loss of consciousness, confusion or memory loss, paralysis, numbness, or any condition which causes limited motion?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-115',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details"
    },
    {
        key: "103",
        name: "Proposed_Insured_Questions-103",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the eyes, ears, nose, throat, or sinuses?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-116',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "104",
        name: "Proposed_Insured_Questions-104",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the endocrine system, including thyroid, pituitary, adrenal or other gland?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-117',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "14",
        name: "Proposed_Insured_Questions-14",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the spine, hip, knee, shoulder, back, joints, bones, muscles, arthritis, rheumatism or gout?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: "Multi_Form-14",
        additionalFieldType: "checkbox",
        additionalFieldRequired: true,
        additionalFieldLabel: "",
        additionalFieldOptions: additionalFieldOptions[14],
        additionalSubFieldName: 'Remark-118',
        additionalSubFieldType: 'textfield',
        additionalSubFieldRequired: true,
        additionalSubFieldLabel: 'Provide Details'
    },
    {
        key: "27",
        name: "Proposed_Insured_Questions-27",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the urinary system, including bladder, kidney, or urinary abnormalities such as protein, sugar or blood in urine?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-119',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "105",
        name: "Proposed_Insured_Questions-105",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the genital system, including prostate, testicles, pelvic organs, ovaries, cervix, uterus, or breast?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-120',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "106",
        name: "Proposed_Insured_Questions-106",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any disorder or abnormal condition of the skin, including psoriasis, eczema, non-healing wounds, melanoma, nevi or moles?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-121',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "25",
        name: "Proposed_Insured_Questions-25",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any depression, anxiety, bipolar, schizophrenia, Attention Deficit Disorder (ADD), autism, Down Syndrome or any other developmental or psychological condition including Alzheimer's, dementia, or Post Traumatic Stress Disorder (PTSD)?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-122',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "40",
        name: "Proposed_Insured_Questions-40",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any anemia, hemophilia or disorders of the blood other than Acquired Immune Deficiency Syndrome (AIDS), Human Immunodeficiency Virus (HIV)?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-123',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "49",
        name: "Proposed_Insured_Questions-49",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Human Immunodeficiency Virus (HIV), Acquired Immune Deficiency Syndrome (AIDS), or have you tested positive for exposure to or been diagnosed with HIV or AIDS?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-124',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "15",
        name: "Proposed_Insured_Questions-15",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Any cancer, tumor, polyp, lump, nodule, cyst, lymphoma or any disorder of the lymph nodes?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-125',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details, Dates, Results'
    },
    {
        key: "19",
        name: "Proposed_Insured_Questions-19",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: diabetes, high blood sugar, pre-diabetes, impaired glucose tolerance, impaired fasting glucose, insulin deficiency, hyperglycemia, or diabetes associated with pregnancy?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-126',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details, Dates, Results'
    },
    {
        key: "41",
        name: "Proposed_Insured_Questions-41",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Amputation due to disease or other medical condition?",
        type: "radio",
        default: false,
        onYes: true,
        additionalRemarks: true,
        additionalFieldName: 'Remark-127',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    },
    {
        key: "16",
        name: "Proposed_Insured_Questions-16",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Ataxia, transverse myelitis, myasthenia gravis, autoimmune disorder such as lupus, blindness, or post-polio syndrome?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-128',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details, Dates, Results'
    },
    {
        key: "17",
        name: "Proposed_Insured_Questions-17",
        label: "In the past 10 years have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: Parkinson's disease, muscular dystrophy, Huntington's chorea, motor neuron disease, Lou Gehrig's Disease (ALS), or multiple sclerosis?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-129',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details, Dates, Results'
    }
];


export default fullQuestions;

