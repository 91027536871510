import React from "react";
import {
    MedFieldType,
    medicalAlcoholQuestions,
    medicalMarijuanaQuestions,
    medicalNarcoticsQuestions,
} from "./component/MedicalPageQuestions";
import RenderMedicalQuestions from "./component/RenderMedicalQuestions";
import { va } from "../../../../../../utils/commonUtils";


const AlcoholMarijuanaNarcoticsField = ({ question, index, sendSaveRequest, clientSigned }) => {
    const hLineSpan = (
            <span className="hLine" />
    );
    return (
            <React.Fragment key={index}>
                {
                        question && question.showUpperHrLine &&
                        hLineSpan
                }
                <RenderMedicalQuestions
                        index={question && question.key || index}
                        question={question}
                        sendSaveRequest={sendSaveRequest}
                        clientSigned={clientSigned}
                />
                {
                        question && question.showBottomHrLine &&
                        hLineSpan
                }
            </React.Fragment>
    );
};

type PMAMNQsProps = { sendSaveRequest?: Function, clientSigned: boolean }
const PhysicalAndMedicalAlcoholMarijuanaNarcoticsQuestions: React.FC<PMAMNQsProps> = ({ sendSaveRequest, clientSigned }) => {
    const medicalFields: MedFieldType[][] = [medicalAlcoholQuestions, medicalMarijuanaQuestions, medicalNarcoticsQuestions];
    return (
            <React.Fragment>
                {
                        va(medicalFields) && medicalFields.map((mFields) => {
                            return (
                                    <>
                                        {
                                            mFields.map((question: any, index: number) => {
                                                return (
                                                        <AlcoholMarijuanaNarcoticsField
                                                                index={index}
                                                                question={question}
                                                                sendSaveRequest={sendSaveRequest}
                                                                clientSigned={clientSigned}
                                                        />
                                                );
                                            })
                                        }
                                    </>
                            );
                        })
                }
            </React.Fragment>
    );
};

export default PhysicalAndMedicalAlcoholMarijuanaNarcoticsQuestions;
