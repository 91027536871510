import React from "react";
import { useFormikContext } from "formik";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import { EnrollmentFinancialAgreementProps } from "../../../../../../../model/enrollment";
import {
    DatePicker,
    ErrorCommon,
    NumberTextFieldWithCb,
    TextField,
    TextFieldCommon,
} from "../../../../../../../components/formikFormInputs";
import {
    PIQ,
    proposedInsuredQuestion67TopQues,
    SkyDivingParticipationSuffix,
    SkyDivingSuffix,
} from "../InsuredQuestions";
import InsuredPageRadioButton from "../component/InsuredPageRadioButton";
import InsuredPageCheckBox from "../component/InsuredPageCheckBox";
import InsuredToggleButtonGroup from "../component/InsuredToggleButtonGroup";

/**
 *  this component rendering a set of text fields
 *  related to skydiving participation and
 *  checkbox group related to the purpose of diving.
 * @param props
 * name: string
 * clientSigned: boolean
 * @constructor
 */
function ProposedInsuredQuestions67XRnp(props: { name: string, clientSigned: boolean }) {

    const { name, clientSigned } = props;
    const defaultOptionsFelonyMisdemeanor: any = [
        {
            text: "Static Line",
            value: "Static Line",
        }, {
            text: "Halo",
            value: "Halo",
        }, {
            text: "Both",
            value: "Both",
        },
    ];

    const cls: any = {
        key: "5",
        name: "Proposed_Insured_Questions-66XCsl",
        label: <Label label={"What is the purpose of your diving? (Check ALL that apply)"} required={true} />,
        notShowHrLine: false,
        additionalFieldType: "checkBoxGroup",
        additionalFieldOptions: defaultOptionsFelonyMisdemeanor,
    };
    const { values, handleChange, handleBlur, errors, touched }: any = useFormikContext();

    const fieldCommonProps = {
        onBlur: handleBlur,
        onChange: (e: any) => handleChange(e),
        values: values,
        disabled: clientSigned
    };
    const fields = [
        {
            ...fieldCommonProps,
            component: DatePicker,
            id: `${name}${SkyDivingParticipationSuffix.DateOfLastJump}`,
            label: "Date of Last Jump",
            name: `${name}${SkyDivingParticipationSuffix.DateOfLastJump}`,
            placeholder: "Date of Last Jump",
            saveFormat: "USER"
        },
        {
            ...fieldCommonProps,
            component: TextField,
            id: `${name}${SkyDivingParticipationSuffix.CurrentJumpStatus}`,
            label: "Current Jump Status",
            name: `${name}${SkyDivingParticipationSuffix.CurrentJumpStatus}`,
            placeholder: "Current Jump Status",
        },
        {
            ...fieldCommonProps,
            component: TextField,
            id: `${name}${SkyDivingParticipationSuffix.BranchOfMilitary}`,
            label: "Branch of Military",
            name: `${name}${SkyDivingParticipationSuffix.BranchOfMilitary}`,
            placeholder: "Branch of Military",
        },
        {
            ...fieldCommonProps,
            component: TextField,
            id: `${name}${SkyDivingParticipationSuffix.UnitAssigned}`,
            label: "Unit Assigned",
            multiline: true,
            name: `${name}${SkyDivingParticipationSuffix.UnitAssigned}`,
            placeholder: "Unit Assigned",
        },
    ];

    return (
            <Grid container spacing={1}>
                {
                    fields.map((f, key) => {
                        const textFieldProps = {
                            ...f,
                            disableFuture:true,
                            label: (<Label label={f.label} required={true} />),
                        };
                        return (
                                <Grid key={key} item xs={12} md={6} xl={4}>
                                    <TextFieldCommon {...textFieldProps} />
                                    <ErrorCommon errors={errors} name={f.name} touched={touched} />
                                </Grid>
                        );
                    })
                }
                <Grid item xs={12}>
                    Static Line or Halo?
                    <InsuredPageCheckBox question={cls} clientSigned={clientSigned} />
                </Grid>
            </Grid>
    );
}

/**
 * Component is responsible for rendering a
 * set of questions along with radio buttons
 * for each question.
 * @param props
 * clientSigned: boolean
 * proposedInsuredQuestion67TopQues: any
 * @constructor
 */
const ProposedInsuredQuestion67TopQues = (props: { clientSigned: boolean, proposedInsuredQuestion67TopQues: any }) => {
    const { clientSigned, proposedInsuredQuestion67TopQues } = props;
    const { values }: any = useFormikContext();
    return (
            <>
                {
                    proposedInsuredQuestion67TopQues.map((question: any, idx: number) => {
                        const [PIQ67NatureOfParticipationKey, matcherKey] = [`${PIQ}-67XRnp`, 'Military'];
                        const showMilitaryDetails = question && question.name === PIQ67NatureOfParticipationKey &&
                                (values && values[PIQ67NatureOfParticipationKey] && values[PIQ67NatureOfParticipationKey].value && values[PIQ67NatureOfParticipationKey].value === matcherKey);
                        return (
                                <React.Fragment key={idx}>
                                    <Typography variant="body2" component="strong" gutterBottom className="mt20 mb10">
                                        {question && question.label}
                                    </Typography>

                                    <InsuredPageRadioButton question={question} clientSigned={clientSigned} />

                                    {
                                            showMilitaryDetails &&
                                            <Grid xs={12} md={12}>
                                                <div style={{ backgroundColor: "#edf3f8" }} className="mt20 mb10 padding16">
                                                    <Typography variant="body1" component="strong">
                                                        Please Provide Details
                                                    </Typography>
                                                    <span className="hLine" />
                                                    <ProposedInsuredQuestions67XRnp
                                                            name={"Proposed_Insured_Questions-67X"}
                                                            clientSigned={clientSigned}
                                                    />
                                                </div>
                                            </Grid>
                                    }
                                </React.Fragment>
                        );
                    })
                }
            </>
    );
};

/**
 * Component is rendering a set of text fields related to skydiving information,
 * such as the number of jumps in the last 12 months,
 * the number of jumps planned in the next 12 months,
 * and the date of the last jump.
 * @param props
 * name:string
 * @constructor
 */
function ProposedInsuredQuestions80(props: { name: string, clientSigned:boolean }) {
    const { name, clientSigned } = props;
    const { values, handleChange, handleBlur, errors, touched }: any = useFormikContext();

    const fieldObj = [
        {
            name: `${name}${SkyDivingSuffix.NumberOfJumpsLast12}`,
            id: `${name}${SkyDivingSuffix.NumberOfJumpsLast12}`,
            placeholder: "Number of jumps in the last 12 months",
            label: "Number of jumps in the last 12 months",
            component: NumberTextFieldWithCb,
            thousandSeparator: false,
            type: "text",
            disabled: clientSigned
        }, {
            name: `${name}${SkyDivingSuffix.NumberOfJumpsNext12}`,
            id: `${name}${SkyDivingSuffix.NumberOfJumpsNext12}`,
            placeholder: "Number of jumps planned in next 12 months",
            label: "Number of jumps planned in next 12 months",
            component: NumberTextFieldWithCb,
            thousandSeparator: false,
            type: "text",
            disabled: clientSigned
        }, {
            name: `${name}${SkyDivingSuffix.DateOfLastJump}`,
            id: `${name}${SkyDivingSuffix.DateOfLastJump}`,
            label: "Date of last jump",
            component: DatePicker,
            type: "text",
            disableFuture: true,
            saveFormat: "USER",
            disabled: clientSigned
        },
    ];

    return (
            <Grid container spacing={3} className="mt20 mb10">
                {fieldObj.map((field, index) => {
                    return (
                            <Grid item xs={4} md={6} xl={4} key={index}>
                                <TextFieldCommon
                                        {...field} values={values}
                                        onChange={(e: any) => handleChange(e)}
                                        onBlur={handleBlur}
                                />
                                <ErrorCommon errors={errors} name={field.name} touched={touched} />
                            </Grid>
                    );
                })}
            </Grid>
    );
}

/**
 * Component rendering a set of questions related to the proposed insured,
 * including radio buttons, tooltips, and additional remarks
 * @param props
 * clientSigned: boolean,
 * showComponent: Function,
 * setErrors: Function,
 * isFromClientDetail: boolean,
 * insuredQuestion: any[],
 * offerType: string
 * @constructor
 */
const ProposedInsuredQuestion67 = (props: {
    clientSigned: boolean,
    showComponent: Function,
    setErrors: Function,
    isFromClientDetail: boolean,
    insuredQuestion: any[],
    offerType: string
}) => {
    const { clientSigned, showComponent, setErrors, isFromClientDetail, insuredQuestion, offerType } = props;
    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        errors,
        touched,
    }: any = useFormikContext();

    return <>
        <ProposedInsuredQuestion67TopQues
                clientSigned={clientSigned}
                proposedInsuredQuestion67TopQues={proposedInsuredQuestion67TopQues(offerType)}
        />
        {
            insuredQuestion.map((question: EnrollmentFinancialAgreementProps) => {
                return (
                        <>
                            <Grid item xs={12} sm={12} md={12} key={question.key}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={8} lg={9}>
                                        <Typography
                                                variant="body1" className="mb15 floatLeft " component="strong"
                                        >
                                            <Label
                                                    label={`${question.label}`} required={!isFromClientDetail}
                                            />
                                        </Typography>
                                        {question.infoTooltip &&
                                                <Tooltip
                                                        title={<Typography
                                                                className="f16 txtWhite mb0"
                                                        > {question.infoTooltip} </Typography>}
                                                >
                                                    <IconButton>
                                                        <Info color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </Grid>
                                    {
                                        question.type === "radio" &&
                                        <Grid item xs={12} sm={12} md={4} lg={3}>
                                            <InsuredToggleButtonGroup id={question.name} clientSigned={clientSigned}/>
                                            <span style={{ float: "right", clear: "both" }}>
                                                <ErrorCommon touched={touched} errors={errors} name={question.name} />
                                            </span>
                                        </Grid>
                                    }
                                    {
                                        question && question.additionalRemarks &&
                                        showComponent(question, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors)
                                    }
                                </Grid>
                            </Grid>
                        </>
                );
            })
        }
        <Grid item xs={12} sm={12}>
            <ProposedInsuredQuestions80 name={"Proposed_Insured_Questions-80X"} clientSigned={clientSigned}/>
        </Grid>
    </>;
};

export default ProposedInsuredQuestion67;