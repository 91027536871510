import * as React from "react";
import iliaMini from "../../../../../components/client/landingPage/v2/images/ilia_small.png";
import {
    Grid,
    Link,
    Typography,
} from "@material-ui/core";
import LandingContactForm from "./LandingContactForm";
import { LOGO_BASE_URL } from "../../../../../constants";
import { checkIfRebrand } from "../../../../../utils/commonUtils";

const LandingFooter = (props: any) => {
    const strategy: string = props.strategyData.name;
    const [open, setOpen] = React.useState(false);
    const startegyLogo: string = props.strategyData.logo;
    let clientLogo: string = props.groupDetail && props.groupDetail.user && props.groupDetail.user.image ? LOGO_BASE_URL + props.groupDetail.user.image : (props.agent && props.agent.companyLogo ? LOGO_BASE_URL + props.agent.companyLogo : "");

    const { isRebrand, partnerName } = checkIfRebrand(props);

    let partnerLogo: string = iliaMini;
    if (props.strategyData && props.strategyData.reBrand === 1 && props.strategyData.reBrandLogo) {
        let isExist: boolean = props.strategyData.reBrandLogo.includes("s3.amazonaws.com");
        if (isExist) {
            partnerLogo = props.strategyData.reBrandLogo;
        } else {
            partnerLogo = LOGO_BASE_URL + props.strategyData.reBrandLogo;
        }
    }
    if (props.strategyData && props.strategyData.reBrand === 2 && props.agent && props.agent.imo && props.agent.imo.reBrand && props.strategyData.reBrandLogo) {
        let isExist: boolean = props.strategyData.reBrandLogo.includes("s3.amazonaws.com");
        if (isExist) {
            partnerLogo = props.strategyData.reBrandLogo;
        } else {
            partnerLogo = LOGO_BASE_URL + props.strategyData.reBrandLogo;
        }
    }

    let advisorName: string = props.agent && props.agent.user ? props.agent.user.firstName + " " + props.agent.user.lastName : "Agent";

    return (
            <footer className="footer trizenEsd">
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} sm={6} md={6}>
                        <div className="footerImg">
                            <Typography component="span"><img alt={"partnerLogo"} src={partnerLogo}
                                                              className="logo"/></Typography>
                            {startegyLogo ?
                                    <Typography component="span"><img alt={"strategylogo"}
                                                                      className={`logo`}
                                                                      src={startegyLogo}/></Typography> :
                                    <Typography color="primary" component="strong"
                                                className="f22 ml15 mt5 floatLeft mb0">
                                        {props.strategyData.name}
                                    </Typography>
                            }
                            {clientLogo &&
                            <div className="Vdivider">
                                <img alt={"clientLogo"} className={`logo ml15`}
                                     src={clientLogo}/>
                            </div>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Link className="floatRightMd" onClick={() => setOpen(true)}>
                            <Typography variant="subtitle2" color="primary" className="mb0">
                                Contact {advisorName}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        {!isRebrand &&
                        <Typography variant="caption" component="span" className="floatLeft w100 mb0">
                            &copy; {(new Date().getFullYear())}. {partnerName} and {strategy} are registered
                            trademarks
                            of NIW Companies Inc.
                        </Typography>
                        }
                        {isRebrand &&
                        <Typography component="span" variant="caption" className="floatLeft w100 mb0">
                            &copy; {(new Date().getFullYear())}. {strategy} offered under license from NIW Companies Inc.
                        </Typography>
                        }

                    </Grid>
                </Grid>
                {open &&
                <LandingContactForm setOpen={(isOpen: boolean) => setOpen(isOpen)} open={open} {...props}/>
                }
            </footer>
    );
};
export default LandingFooter;
