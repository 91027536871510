import { EnrollmentQuestionProps } from "../../model/enrollment";

import { additionalFieldOptions } from "../../constants";

const simplifiedQuestions: EnrollmentQuestionProps[] = [
    // {
	// 	key: "1",
	// 	name: "Proposed_Insured_Questions-1",
	// 	label: "Are you a US Citizen?",
	// 	type: "radio",
	// 	additionalFieldName:"",
	// 	default: true,
	// 	onYes: false,
	// },
	{
		key: "2",
		name: "Proposed_Insured_Questions-2",
		label: "Do you have any inforce life insurance or annuity contracts including long term care insurance or riders? (If yes, provide details)",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-2-Remarks',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details'
	},
	{
		key: "3",
		name: "Proposed_Insured_Questions-3",
		label: "Have you ever applied for life, health, or disability insurance or reinstatement of same, which was declined, postponed, rated or modified in any way?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-101',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details'
	},
	{
		key: "4",
		name: "Proposed_Insured_Questions-4",
		label: "Within the past 12 months have you applied for or do you have any applications pending for life or disability insurance?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-102',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details'
	},
	{
		key: "5",
		name: "Proposed_Insured_Questions-5",
		label: "Is the policy or rider being applied for intended to replace any inforce life insurance or annuity contract(s) including long term care insurance or riders? Replacement includes surrender, lapse, reissue, conversion, reduction in coverage, premium or period of coverage of any life, disability income or annuity contract. (If yes, replacement forms must be provided)",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-103',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details'
	},
	{
		key: "8",
		name: "Proposed_Insured_Questions-8",
		label: "Within the past 90 days, have you been advised by a member of the medical profession, to have any hospitalization, surgery or medical test (other than related to HIV/AIDS) that has not yet been completed or have any of these tests or procedures been completed and you are waiting for the results?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-8-Remarks',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details'

	},
	{
		key: "9",
		name: "Proposed_Insured_Questions-9",
		label: "In the past 2 years have you been treated for or advised by a member of the medical profession to seek treatment for heart problems (including angina), stroke, or cancer or been treated for or diagnosed as having Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex (ARC)?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-9-Remarks',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details'

	},
	{
		key: "10",
		name: "Proposed_Insured_Questions-10",
		label: "Are you actively at work at the customary workplace, doing the usual duties and functions required by the position during the normal work hours and weekly period?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-10-Remarks',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details'
	},
	{
		key: "11",
		name: "Proposed_Insured_Questions-11",
		label: "Have you gained or lost weight during the last 12 months?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-11-Weight',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details',
		infoTooltip: "Answer Yes if you have lost or gained more than 10 pounds."
	},
	{
		key: "12",
		name: "Proposed_Insured_Questions-12",
		label: "Within the past 5 years have you worked less than full time, received or applied for disability or worker's compensation?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-110',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details'
	},
	{
		key: "13",
		name: "Proposed_Insured_Questions-13",
		label: "In the past 10 years have you ever been diagnosed, treated or taken medication for: Any disease or abnormal condition of the heart, irregular heartbeat, coronary artery disease, chest pain, angina, transient ischemic attack or stroke?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Multi_Form-13',
		additionalFieldType: 'checkbox',
		additionalFieldRequired: true,
		additionalFieldLabel: '',
		additionalFieldOptions: additionalFieldOptions[13],
        additionalSubFieldName: 'Remark-111',
        additionalSubFieldType: 'textfield',
        additionalSubFieldRequired: true,
        additionalSubFieldLabel: 'Provide Details'
	},
	{
		key: "14",
		name: "Proposed_Insured_Questions-14",
		label: "In the past 10 years have you ever been diagnosed, treated or taken medication for: Any spine, hip, knee, shoulder, back, bones, muscles, arthritis, rheumatism, joints, or other gland disorder?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: "Multi_Form-14",
        additionalFieldType: "checkbox",
        additionalFieldRequired: true,
        additionalFieldLabel: "",
        additionalFieldOptions: additionalFieldOptions[14],
        additionalSubFieldName: 'Remark-118',
        additionalSubFieldType: 'textfield',
        additionalSubFieldRequired: true,
        additionalSubFieldLabel: 'Provide Details'
	},
	{
		key: "15",
		name: "Proposed_Insured_Questions-15",
		label: "In the past 10 years have you ever been diagnosed, treated or taken medication for: Any cancer or other tumors?",
		type: "radio",
		default: false,
		onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-125',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details, Dates, Results'
	},
	{
		key: "16",
		name: "Proposed_Insured_Questions-16",
		label: "In the past 10 years have you ever been diagnosed, treated or taken medication for:   Ataxia (muscle movement disorder), transverse Myelitis, Myasthenia Gravis, Autoimmune Disorder such as Lupus, Blindness, or Post-Polio Syndrome, Parkinson's disease, Muscular Dystrophy, Huntington's Chorea, Motor Neuron, Disease, Lou Gehrig's Disease (ALS), or Multiple Sclerosis?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-128',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details, Dates, Results'
	},
	{
		key: "17",
		name: "Proposed_Insured_Questions-17",
		label: "In the past 10 years have you ever been diagnosed, treated or taken medication for: Parkinson's disease, Muscular Dystrophy, Huntington's Chorea, Motor Neuron, Disease, Lou Gehrig's Disease (ALS), or Multiple Sclerosis?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-129',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: 'Provide Details, Dates, Results'
	},
	{
		key: "18",
		name: "Proposed_Insured_Questions-18",
		label: "In the past 10 years have you ever been diagnosed, treated or taken medication for: Kidney, liver or gastrointestinal disorder?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Multi_Form-18',
		additionalFieldType: 'checkbox',
		additionalFieldRequired: true,
		additionalFieldLabel: '',
		additionalFieldOptions: additionalFieldOptions[18],
        additionalSubFieldName: 'Remark-114',
        additionalSubFieldType: 'textfield',
        additionalSubFieldRequired: true,
        additionalSubFieldLabel: 'Provide Details'
	},
	{
		key: "19",
		name: "Proposed_Insured_Questions-19",
		label: "In the past 10 years have you ever been diagnosed, treated or taken medication for: Diabetes, pre-diabetes or high blood sugar?",
		type: "radio",
		default: false,
		onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-126',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details, Dates, Results'
	},
	{
		key: "20",
		name: "Proposed_Insured_Questions-20",
		label: "Have you ever been convicted of a Felony?",
		type: "radio",
		default: false,
		onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-105',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details"
	},
	{
		key: "21",
		name: "Proposed_Insured_Questions-21",
		label: "In the past 10 years have you used cocaine, heroin, or any other illicit drug or controlled substance, been advised by a physician to discontinue or reduce alcohol or drug intake, used drugs not prescribed by a physician, or been a member of a support group such as NA or AA?",
		type: "radio",
		default: false,
		onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-130',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details, Dates, Results'
	},
	{
		key: "22",
		name: "Proposed_Insured_Questions-22",
		label: "Do you currently:  Use or require the use of a wheelchair, walker, multi-prong cane, hospital bed, dialysis machine, respirator oxygen, motorized cart or stair lift?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-135',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: "Provide Details, Dates, Results"
	},
	{
		key: "23",
		name: "Proposed_Insured_Questions-23",
		label: "Do you currently:  Need help, assistance or supervision for: bathing, eating, dressing, toileting, walking, transferring, or maintaining continence?",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-136',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: "Provide Details, Dates, Results"
	},
	{
		key: "24",
		name: "Proposed_Insured_Questions-24",
		label: "Do you currently:  Need help, assistance or supervision in: taking medication, doing housework, laundry, shopping, or meal preparation",
		type: "radio",
		default: false,
		onYes: false,
		additionalRemarks: true,
		showRemarks: false,
		additionalFieldName: 'Remark-137',
		additionalFieldType: 'textfield',
		additionalFieldRequired: true,
		additionalFieldLabel: "Provide Details, Dates, Results"
	},
	{
		key: "25",
		name: "Proposed_Insured_Questions-25",
		label: "Have you ever been diagnosed, treated, tested positive for, or been given medical advice by a member of the medical profession for: (If yes, provide details including treating physician contact information.)g. Any depression, anxiety, bipolar, schizophrenia, attention deficit disorder (ADD), or any other developmental or psychological condition including Alzheimer's, Dementia, or Post Traumatic Stress Disorder (PTSD)?",
		type: "radio",
		default: false,
		onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-122',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: "Provide Details, Dates, Results"
    }
];

export default simplifiedQuestions;
