import React from "react";
import { useFormikContext } from "formik";
import { EnrollmentFinancialAgreementProps } from "../../../../../../../model/enrollment";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import {
    DatePicker,
    ErrorCommon,
    NumberTextField,
    TextField,
    TextFieldCommon,
} from "../../../../../../../components/formikFormInputs";
import { ClimbingSuffix, PIQ, proposedInsuredQuestion68TopQues, TypeOfClimbingSuffix } from "../InsuredQuestions";
import InsuredPageCheckBox from "../component/InsuredPageCheckBox";
import InsuredPageRadioButton from "../component/InsuredPageRadioButton";
import InsuredToggleButtonGroup from "../component/InsuredToggleButtonGroup";


/**
 * Component is responsible for
 * rendering a set of climbing-related
 * form fields based on the fieldObj array
 * @param props
 * name:string
 * clientSigned:boolean
 * @constructor
 */
function RenderFieldOptions(props:{name:string, clientSigned:boolean}) {
    const {name, clientSigned} = props;
    const { values, handleChange, handleBlur, errors, touched }:any = useFormikContext();
    const fieldObj:any = [
        {
            id: `${name}${TypeOfClimbingSuffix.NumberOfClimbsLifetime}`,
            name: `${name}${TypeOfClimbingSuffix.NumberOfClimbsLifetime}`,
            label: <Label label={"# of climbs made in your lifetime"} required={true}/>,
            placeholder: "# of climbs made in your lifetime",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
        },
        {
            id: `${name}${TypeOfClimbingSuffix.HighestAltitudeClimbed}`,
            name: `${name}${TypeOfClimbingSuffix.HighestAltitudeClimbed}`,
            label: <Label label={"Highest altitude you have climbed"} required={true}/>,
            placeholder: "# of climbs made in your lifetime",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
        },
        {
            id: `${name}${TypeOfClimbingSuffix.HighestAltitudeIntendToClimb}`,
            name: `${name}${TypeOfClimbingSuffix.HighestAltitudeIntendToClimb}`,
            label: <Label label={"Highest altitude you intend to climb"} required={true}/>,
            placeholder: " Highest altitude you intend to climb",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
        },
        {
            id: `${name}${TypeOfClimbingSuffix.DateOfLastClimb}`,
            name: `${name}${TypeOfClimbingSuffix.DateOfLastClimb}`,
            label: <Label label={"Date of last climb"} required={true}/>,
            helperFieldText: "",
            placeholder: "Date of last climb",
            disabled: clientSigned,
            filedType: "string",
            component: DatePicker,
            variant: "outlined",
            disableFuture: true,
            saveFormat: "USER"
        },
        {
            id: `${name}${TypeOfClimbingSuffix.DateAnticipateClimbing}`,
            name: `${name}${TypeOfClimbingSuffix.DateAnticipateClimbing}`,
            label: <Label label={"Date you anticipate climbing again"} required={true}/>,
            helperFieldText: "",
            placeholder: "Date you anticipate climbing again",
            disabled: clientSigned,
            filedType: "string",
            component: DatePicker,
            variant: "outlined",
            disablePast: true,
            saveFormat: "USER"
        },
        {
            id: `${name}${TypeOfClimbingSuffix.ClimbsInLast12Months}`,
            name: `${name}${TypeOfClimbingSuffix.ClimbsInLast12Months}`,
            label: <Label label={"# of climbs made in the last 12 months"} required={true}/>,
            placeholder: "# of climbs made in the last 12 months",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
        },
        {
            id: `${name}${TypeOfClimbingSuffix.ClimbsAnticipatedInNext12Months}`,
            name: `${name}${TypeOfClimbingSuffix.ClimbsAnticipatedInNext12Months}`,
            label: <Label label={"# of climbs anticipated in the next 12 months"} required={true}/>,
            placeholder: "# of climbs anticipated in the next 12 months",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
        },
    ];

    return <Grid container spacing={2}>
        {fieldObj.map((field, index) => {
            return (
                    <Grid item xs={12} md={6} key={index} className="mt10">
                        <TextFieldCommon
                                {...field}
                                values={values}
                                onChange={(e: any) => handleChange(e) }
                                onBlur={handleBlur}
                        />
                        <ErrorCommon errors={errors} name={field.name} touched={touched} />
                    </Grid>
            )})}
    </Grid>
}

/**
 * this component is rendering a set of climbing-related questions
 * and form fields based on the proposedInsuredQuestions68XClimb array.
 * @param props
 * clientSigned: boolean,
 * name: string,
 * markedCheckbox: string[]
 * @constructor
 */
function ProposedInsuredQuestions68XClimb(props: {
    clientSigned: boolean,
    name: string,
    markedCheckbox: string[]
}) {
    const { clientSigned, name, markedCheckbox } = props;
    const { values }: any = useFormikContext();
    const proposedInsuredQuestions68XClimb: any[] = values && values["Proposed_Insured_Questions-68XClimb"] || [];
    const [heading, setHeading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setHeading(false);
    }, [proposedInsuredQuestions68XClimb]);
    return (<>
                <div className={heading ? "mt10 padding16" : ""}
                     style={{ backgroundColor: heading ? "#edf3f8" : "#fff" }}>
                    {
                            heading &&
                            <>
                                <Typography variant="body1" className="mt20 mb10" component="strong">
                                    Please provide the following details for the type of climbing indicated above:
                                </Typography>
                                <span className="hLine" />
                            </>
                    }

                    <Grid container spacing={2}>
                        {proposedInsuredQuestions68XClimb.map((val: any, inx) => {
                                    let nameIndex: string = "";
                                    let nameIndexArray = (val && val.name).split("_");
                                    if (nameIndexArray.length > 0) {
                                        nameIndex = nameIndexArray[nameIndexArray.length - 1];
                                    }
                                    let result = markedCheckbox.includes(val.value);
                                    if (!result) {
                                        if (!heading) {
                                            setHeading(true);
                                        }
                                        return (
                                                <React.Fragment>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography variant="body2" component="strong" className="mb0">
                                                            {val && val.value || ""}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography variant="body1">
                                                            <RenderFieldOptions name={`${name}X${nameIndex}`}
                                                                                clientSigned={clientSigned} />
                                                        </Typography>
                                                    </Grid>
                                                    <span className="hLine" />
                                                </React.Fragment>
                                        );
                                    }
                                }
                        )}
                    </Grid>
                </div>
            </>
    );
}

/**
 *  function climbing-related questions based on
 *  whether there are multiple questions or a single
 *  question with a specific value.
 * @param quesListing
 * quesListing: any
 * value: string
 * @param value
 */
export function proposedInsuredQuestion68TopQuesHeading(quesListing: any, value: string) {
    return !(quesListing && quesListing.length === 1 && quesListing[0].value === value);
}

/**
 * this function helps to identify and collect the values of
 * checkboxes which is having ShowDetails in the quesListing array
 * @param quesListing
 */
function proposedInsuredQuestion68SelectedCheckbox(quesListing: any) {
    let checkBoxHavingShowDetail: any[] = [];
    if (quesListing) {
        (quesListing || []).forEach((field: { showDetail: any; value: any; }, index: number) => {
            !(field && field.showDetail) &&
            checkBoxHavingShowDetail.push(field.value);
        });
    }
    return checkBoxHavingShowDetail;
}

const ProposedInsuredQuestion68TopQues = (props: { clientSigned: boolean, proposedInsuredQuestion68TopQues: any }) => {
    const { values }: any = useFormikContext();
    const { clientSigned, proposedInsuredQuestion68TopQues } = props;
    return <>
        {
            proposedInsuredQuestion68TopQues.map((question: any, idx: number) => {
                return (
                        <React.Fragment key={idx}>
                            <Typography variant="body2" component="strong" className="mt20 mb10">
                                {question && question.label}
                            </Typography>
                            {
                                question && question.additionalFieldType === "checkBoxGroup" ?
                                        <InsuredPageCheckBox question={question} clientSigned={clientSigned} /> :
                                        <InsuredPageRadioButton question={question} clientSigned={clientSigned} />
                            }

                            {
                                question && question.name === "Proposed_Insured_Questions-68XClimb" &&
                                values && values.hasOwnProperty("Proposed_Insured_Questions-68XClimb") &&
                                values["Proposed_Insured_Questions-68XClimb"].length > 0 &&
                                <Grid item xs={12} sm={12} md={12} key={idx}>
                                    <ProposedInsuredQuestions68XClimb name={"Proposed_Insured_Questions-68"}
                                          clientSigned={clientSigned}
                                          markedCheckbox={proposedInsuredQuestion68SelectedCheckbox(question && question.additionalFieldOptions)} />
                                </Grid>
                            }
                        </React.Fragment>
                )
            })
        }
    </>
}

const ProposedInsuredQuestions82 = (props:{name:string, clientSigned:boolean}) => {
    const {name, clientSigned} = props;
    const { values,handleChange, handleBlur, errors, touched }:any = useFormikContext();
    const fieldObj = [{
        name: `${name}${ClimbingSuffix.AverageAltitudeClimbed}`,
        placeholder: "What is the average altitude that you have climbed?",
        id: `${name}${ClimbingSuffix.AverageAltitudeClimbed}`,
        label: <Label label={"What is the average altitude that you have climbed?"} required={true}/>,
        component: TextField,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}${ClimbingSuffix.ClimbedOrPlanTo}`,
        placeholder: "Where have you climbed or do you plan to climb",
        label: <Label label={"Where have you climbed or do you plan to climb"} required={true}/>,
        id: `${name}${ClimbingSuffix.ClimbedOrPlanTo}`,
        component: TextField,
        type: "text",
        helperText:"Please provide specific areas or Mountains ",
        disabled: clientSigned
    }];

    return (
        <React.Fragment>
        {fieldObj.map((field, index) => {
            return (
                    <Grid item xs={12} lg={6} key={index}>
                        <TextFieldCommon
                                {...field}
                                values={values}
                                onChange={(e: any) => handleChange(e) }
                                onBlur={handleBlur}
                        />
                        <ErrorCommon errors={errors} name={field.name} touched={touched} />
                    </Grid>
            )})}
        </React.Fragment>
    )
}

const ProposedInsuredQuestion68 = (props:{clientSigned:boolean, showComponent:Function, setErrors:Function, isFromClientDetail:boolean, insuredQuestion:any[], offerType:string}) => {
    const { clientSigned, showComponent, setErrors, isFromClientDetail, insuredQuestion, offerType } = props;
    const { values, setFieldValue, setFieldTouched, handleChange, handleBlur, errors, touched }:any = useFormikContext();

    return <>
        <ProposedInsuredQuestion68TopQues clientSigned={clientSigned} proposedInsuredQuestion68TopQues={proposedInsuredQuestion68TopQues(offerType)} />
        {
            insuredQuestion.map((question: EnrollmentFinancialAgreementProps, idx: number) => {
                return (
                        <>
                            <Grid item xs={12} sm={12} md={12} key={question.key}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={8} lg={9}>
                                        <Typography variant="body1"
                                                    className="mb15 floatLeft "
                                                    component="strong">
                                            <Label
                                                    label={`${question.label}`}
                                                    required={!isFromClientDetail}
                                            />
                                        </Typography>
                                        {question.infoTooltip &&
                                                <Tooltip title={<Typography
                                                        className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                                    <IconButton>
                                                        <Info color="primary"/>
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </Grid>
                                    {
                                        question.type === "radio" &&
                                        <Grid item xs={12} sm={12} md={4} lg={3}>
                                            <InsuredToggleButtonGroup id={question.name} clientSigned={clientSigned}/>
                                            <span style={{float:"right", clear:"both"}}>
                                                <ErrorCommon touched={touched} errors={errors} name={question.name}/>
                                            </span>
                                        </Grid>
                                    }
                                    {
                                        question && question.additionalRemarks &&
                                        showComponent(question, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors)
                                    }

                                    <ProposedInsuredQuestions82 name={`${PIQ}-82`} clientSigned={clientSigned}/>
                                </Grid>

                            </Grid>
                        </>
                );
            })
        }
    </>
}

export default ProposedInsuredQuestion68;