import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Link, LinkProps } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddEditPhysician from "./AddEditPhysician";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import { dateUtils } from "../../../../../../../utils";
import { Label } from "../../../../../../../components/formInputs";
import { maskPhoneNumber } from "../../../../../../../utils/commonUtils";
import DeleteConfirmation from "./DeleteConfirmation";
import TableWrap from "../../../../../../../components/TableWrap/TableWrap";
import { isLongForm } from "../../Utilities";

const useStyles = makeStyles({
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
});

// interface PhysicianProps {
//     isFromClientDetail: any;
//     nationalLifeId: any;
//     offerType: string;
//     setPhysiciansCount: any;
//     user: any;
// }

const Physician = (props: any) => {
	const { isFromClientDetail, nationalLifeId, offerType, setPhysiciansCount, user } = props;
    const classes = useStyles();
    const [isShowPhysicianModal, setIsShowPhysicianModal] = React.useState<Boolean>(false);
    const { getPhysicianList, deletePhysicianAccount } = useActions(AccountActions);
    const [record, setRecord] = React.useState(undefined);
    const [open, setOpen] = React.useState(false);
    const [deleteAccountId, setDeleteAccountId] = React.useState(0);
    const [data, setData] = React.useState({
        count: 0,
        rows: [],
    });
    const isClientSigned = (user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId)) || false;

    React.useEffect(() => {
        getBeneficiaries().then(() => {
        });
    }, [isShowPhysicianModal]);

    const getBeneficiaries = async () => {
        let resp = await getPhysicianList((user && user.client && user.client.id) || 0);
        if (!isFromClientDetail) {
            setPhysiciansCount(resp && resp.data && resp.data.count);
        }
        if (resp && resp.data && resp.data.count > 0) {
              setData(resp.data);
        } else {
            setData({
                count: 0,
                rows: [],
            });
        }
    };

    const deleteAccount = async (id: number) => {
        await deletePhysicianAccount({ id });
        getBeneficiaries();
    };

    const addPhysicianButtonProps: LinkProps = {
		className: "floatRight mt25Small",
		color: "primary",
		onClick: (e: React.SyntheticEvent) => {
			if (isClientSigned) {
				e.preventDefault();
				return;
			}
			setRecord(undefined);
			setIsShowPhysicianModal(true);
		},
		style: { lineHeight: "27px" },
    };

    return (
            <Grid id={"physcianList"} container spacing={2} justify="space-between" className="mb15">
                <Grid item xs={12}> {/*Grid xs12*/}
                    <Typography variant="subtitle1" component="strong" className="mb15">
                        <Label
                            label={user && user.client && nationalLifeId === user.client.carrierId ? "Name and address of medical care facility and/or health care provider" : `Physicians List`}
                            required={!isFromClientDetail}
                        />
                        {
							(
								(isLongForm(offerType) && data && data.count < 1) ||
								!isLongForm(offerType)
							) &&
							<Link {...addPhysicianButtonProps}>
								<AddCircleIcon className="floatLeft mr15" /> Add Physician
							</Link>
                        }
                    </Typography>
                    {!isFromClientDetail && data && data.count < 1 &&
                            <Typography variant="caption" className="textRed floatLeft w100">This section has incomplete
                                information. Please complete the required
                                fields before continuing to the next step.</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    <TableWrap>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Address</TableCell>
                                <TableCell align="left">Date Last Visited</TableCell>
                                {!isFromClientDetail &&
                                        <>
                                            <TableCell align="left" style={{ minWidth: "200px" }}>Phone</TableCell>
                                            <TableCell align="left">Reason for last visit</TableCell>
                                        </>
                                }
                                <TableCell align="center" style={{ minWidth: "150px" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.count > 0 &&
                                data.rows.map((row: any, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {`${row.firstName} ${row.lastName}`}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {`${row.address}`}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {`${dateUtils.asDbDateFormat(row.dateOfLastVisit)}`}
                                            </TableCell>
                                            {!isFromClientDetail && (
                                                <>
                                                    <TableCell component="th" scope="row">
                                                        {row.phone ? maskPhoneNumber(row.phone + "") : ""}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.reason}
                                                    </TableCell>
                                                </>
                                            )}
                                            <TableCell align="center" component="th" scope="row">
                                                <IconButton aria-label="Info" color="primary">
                                                    <EditIcon
                                                        onClick={() => {
                                                            if (isClientSigned) {
                                                                return;
                                                            }
                                                            setRecord(row);
                                                            setIsShowPhysicianModal(true);
                                                        }}
                                                    />
                                                </IconButton>
                                                {!isClientSigned && (
                                                    <IconButton
                                                        aria-label="Info"
                                                        color="primary"
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setDeleteAccountId(row.id);
                                                        }}
                                                    >
                                                        <DeleteOutlineIcon className="colorRed" />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </TableWrap>
                </Grid>
                <AddEditPhysician isFromClientDetail={isFromClientDetail} record={record} user={user}
                                  open={isShowPhysicianModal}
                                  setOpen={setIsShowPhysicianModal}/>
                {open &&
                        <DeleteConfirmation
                                success={() => {
                                    if (deleteAccountId > 0) {
                                        deleteAccount(deleteAccountId);
                                    }
                                    setDeleteAccountId(0);
                                    setOpen(false);
                                }}
                                close={() => {
                                    setOpen(false);
                                    setDeleteAccountId(0);
                                }}
                        />
                }
            </Grid>
    );
};

export default Physician;