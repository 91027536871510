import React from "react";
import {Grid, Link} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
const BackArrow = ({setPrevStep, envelopeId}: { setPrevStep: Function, envelopeId?: string }) => <Grid container justify="flex-start">
        <Grid item xs={12} md={12}>
            <Link variant="caption" onClick={() => {
                if (!!envelopeId) {
                    return;
                }
                setPrevStep()
            }} className="backBtn enrollment">
                <ArrowBackIosIcon/>Back
            </Link>
        </Grid>
    </Grid>;

export default BackArrow;