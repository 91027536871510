import * as React from "react";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ListIcon from "@material-ui/icons/List";
import { IconButton, TableBody, TableCell, TableRow, Tooltip, Typography, Link } from "@material-ui/core";
import { AccountHeadRow } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import { dateUtils, tableSort } from "../../utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

const headRowsAccountList: AccountHeadRow[] = [
	{ id: 'sNo', label: 'S.No' },
	{ id: "groupLink.groupName", label: "Group Name" },
	{ id: "phoneNumber", label: "Primary Contact" },
	{ id: "firstName", label: "First Name" },
	{ id: "lastName", label: "Last Name" },
	{ id: "email", label: "Email" },
	{ id: "groupLink.participants", label: "# of Participants" },
	{ id: "createdAt", label: "Date Created" },
];

function GroupLinkTable(props: any) {
	
  const { order, orderBy, accountList, callingFrom, handleClickOpen, handleRequestSort, page, rowsPerPage } = props;
	const auth: any = useSelector((state: RootState) => state.auth);

	const listUrl = (auth.user.role === 1 || auth.user.role === 2) ? "accounts" : "client";
    let sNo = page * rowsPerPage;
	return (
		<React.Fragment>
			<EnhancedTableHead
				order={order}
				orderBy={orderBy}
				onRequestSort={handleRequestSort}
				headRows={headRowsAccountList}
			/>
			<TableBody>
				{
					(!accountList.rows || accountList.rows.length === 0)
					&& <TableRow>
						<TableCell align="left" colSpan={(callingFrom === "accountList") ? 8 : 9}>
                            <Typography variant="caption" align="left" className="floatLeft">No Group-Link Found</Typography>
						</TableCell>
					</TableRow>
				}
				{
					accountList.rows && tableSort.stableSort(accountList.rows, tableSort.getSorting(order, orderBy))
						.map((account: any, index:number) => {
							return (
								<TableRow hover key={account.id}>
									<TableCell>{++sNo}</TableCell>
									<TableCell padding="default">{account["groupLink.groupName"]}</TableCell>
									<TableCell padding="default">{account.phoneNumber}</TableCell>
									<TableCell padding="default">{account.firstName}</TableCell>
									<TableCell padding="default">{account.lastName}</TableCell>
									<TableCell padding="default">{account.email}</TableCell>
									<TableCell padding="default">
										<Typography>
											<Link
												variant="body2"
												className={"clickableLink"}
												onClick={() => props.history.push("/accounts/group-link/" + account["groupLink.id"] + "/" + account["groupLink.groupName"] + "/individual/list")}
												//onClick={() => props.history.push(`/${listUrl}/group-link/${account["groupLink.id"]}/${account["groupLink.groupName"]}/participants`)}
											>
												{account["groupLink.participants"] || 0}
											</Link>
										</Typography>
									</TableCell>

									<TableCell
										padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
									<TableCell padding="default" align="center" className="w230">
										<Tooltip title="View">
											<IconButton color="default"
														onClick={() => props.history.push("/group-link/detail/" + account.id)}>
												<VisibilityIcon/>
											</IconButton>
										</Tooltip>

										<Tooltip title="List">
											<IconButton
												color="default"
												onClick={() => props.history.push(`/${listUrl}/group-link/${account["groupLink.id"]}/participants`)}
											>
												<ListIcon/>
											</IconButton>
										</Tooltip>
										{auth.user && auth.user.role === 1 &&
										<Tooltip title="Delete">
											<IconButton
												color="default"
												onClick={() => handleClickOpen(account.id)}
											>
												<DeleteOutlineIcon className="colorRed"/>
											</IconButton>
										</Tooltip>
										}
									</TableCell>
								</TableRow>
							);
						})
				}
			</TableBody>
		</React.Fragment>
	);
}

export default GroupLinkTable;
