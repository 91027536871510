import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import MaritalStatus from "./ProfileFormFields/MaritalStatus";
import SpouseIncomeStatus from "./ProfileFormFields/SpouseIncomeStatus";
import { Label } from "../../../../../components/formInputs";
import { NumberTextField } from "../../../../../components/formikFormInputs";
import { RootState } from "../../../../../reducers";
import { isValidObject } from "../../../../../utils/commonUtils";
import { GWT_GEN2_STRATEGY } from "../../../../../constants";

type PersonalIncomeProps = {
	clientSigned: boolean | number,
	values: any,
	conditionalRequired: boolean,
	touched: any,
	errors: any,
	classes: any,
	CommonField: Function,
	handleChange: Function,
	handleBlur: Function,
	setFieldValue: Function,
	setIsMarried: Function,
	sendSaveRequest?: Function
}

function PersonalIncomeDetailsContent(props: PersonalIncomeProps) {
	const {
		conditionalRequired,
		clientSigned,
		values,
		touched,
		errors,
		classes,
		CommonField,
		handleChange,
		handleBlur,
		setFieldValue,
		setIsMarried,
		sendSaveRequest
	} = props;
	const spouseIncome = [{
		id: "spouseIncome",
		name: "spouseIncome",
		label: <Label label={"Enter Spouse's Annual Income"} required={false} />,
		isRequired: false,
		component: NumberTextField,
		variant: "outlined",
		className: "TextFieldWidth dollerIcon",
		dollarSign: true,
	}];

    const user: any = useSelector((state: RootState) => isValidObject(state.profile) ? state.profile : state.clientAccount);
    const isGWTGen2: boolean = !!(user && user.client && user.client.strategyId === GWT_GEN2_STRATEGY);
	const disabledMaritalStatus: boolean = !!(clientSigned);
    const disabledSpouseIncomeStatus: boolean = !!(clientSigned || isGWTGen2);

	return (
		<>
			<MaritalStatus
				disabled={disabledMaritalStatus}
				values={values}
				conditionalRequired={conditionalRequired}
				touched={touched}
				errors={errors} classes={classes} setFieldValue={setFieldValue}
				setIsMarried={setIsMarried}
			/>
			{
				values["marriageStatus"] === "Married" &&
				<SpouseIncomeStatus
					disabled={disabledSpouseIncomeStatus}
					values={values}
					conditionalRequired={conditionalRequired}
					touched={touched}
					errors={errors} classes={classes}
					setFieldValue={setFieldValue}
					sendSaveRequest={sendSaveRequest}
				/>
			}
			{
				(Number(values["usingSpouseIncome"]) === 1 && (values["marriageStatus"] === "Married")) &&
				<>
					{
						spouseIncome.map((field, index) => {
							return (
								<Grid item xs={12} md={6} xl={4} key={index + field["name"]}>
									<div className="floatLeft w100">
										{
											CommonField(
												field,
												{
													values,
													errors,
													touched,
													handleChange,
													handleBlur,
													setFieldValue,
												},
											)
										}
									</div>
								</Grid>
							);
						})
					}
				</>
			}
		</>
	);
}

export default PersonalIncomeDetailsContent;