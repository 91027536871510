import React from 'react';
import {Grid, TextField, Typography} from "@material-ui/core";
import {FormikValues} from "../formikFormInputs";
import {
    CaseDataTypeEnum,
    CommonProps,
    DynamicFormFieldEnum,
    DynamicFormFieldType,
    FieldLabelEnum,
    HEIGHT_QUESTION_CODE,
    INCH_IN_FEET,
    PickList,
    SearchAddNewBox,
    SearchBox,
    SelectBox,
    SPECIAL_NULL,
    TextBox,
    ToggleButton,
} from "./index";
import {EditQA, ParsedListItem} from "../../model/application";
import {enumAsArray, isDebug, isObject, numArray, sleep, va} from "../../utils/commonUtils";
import {YesNoEnum} from "../../constants";
import PhysicianProfessional from "../../pages/client/Enrollment/allianzAppHome/component/PhysicianProfessional";
import {convertFeetInches, convertInchToFeet} from './utilities';
import {BS, NS} from "../../model/misc";


interface DynamicFormFieldProps extends DynamicFormFieldType, FormikValues {
    editAns?: EditQA;
    hasPhysicians?: BS;
    setHasPhysicians?: Function;
    setIsCountryUSA?: Function;
}

enum FieldTypes {
    Date = 'date',
    Month = 'month',
    Number = 'number',
    Text = 'text',
}

const Field: React.FC<DynamicFormFieldProps> = (props) => {
    const debug = isDebug();
    const {
        aliasName,
        answerValue,
        dataType,
        dateGranularity,
        disclosureSource,
        editAns,
        errors,
        forceNo,
        helperText,
        initialValues,
        label,
        listItems,
        loading,
        maxValue,
        name,
        onClearSearch,
        onSearch,
        options,
        picklistSearchEnabled,
        searching,
        searchOptions,
        setFieldTouched,
        setFieldValue,
        setForceNo,
        type,
        values,
    } = props;

    let [HFeet, HInch]: NS[] = ['', ''];

    if (name === HEIGHT_QUESTION_CODE) {
        const initHeight = initialValues[HEIGHT_QUESTION_CODE];
        [HFeet, HInch] = convertInchToFeet(initHeight);
    }

    const [foots, setFoots] = React.useState(HFeet && !isNaN(HFeet as any) ? (HFeet as number) : 0);
    const [inches, setInches] = React.useState(HInch && !isNaN(HInch as any) ? (HInch as number) : 0);

    const commonProps: CommonProps = {alias: aliasName, errors, helperText, label, name, setFieldValue, type, values};
    const isRadio = type === DynamicFormFieldEnum.Selection;

    const defaultAnsValues = initialValues[name];
    const ansValues = values[name];

    if (type === DynamicFormFieldEnum.CaseData && listItems && !!va(listItems)) {
        return (
                <ToggleButton {...commonProps} listItems={listItems}/>
        )
    }

    const getDefaultAnsValuesForSearchBox = (dav: any) => {
        return !!va(dav) ? dav : (dav && typeof dav === "string" && !['true', 'false'].includes(dav) ? dav.split(',') : undefined);
    };

    switch (type) {
        case DynamicFormFieldEnum.EnterDetails:
            const searchProps = {
                ...commonProps,
                ansValues: getDefaultAnsValuesForSearchBox(ansValues),
                defaultAnsValues: getDefaultAnsValuesForSearchBox(defaultAnsValues),
                multiple: false,
                onSearch,
                options,
                searching,
            };

             return (<SearchAddNewBox {...searchProps} />);

        case DynamicFormFieldEnum.Date:
        case DynamicFormFieldEnum.Number:
        case DynamicFormFieldEnum.Numeric:
        case DynamicFormFieldEnum.CaseData:
        case DynamicFormFieldEnum.Text:
            const allowNumbersOnly = (dataType === CaseDataTypeEnum.Numeric || type === DynamicFormFieldEnum.Number);
            const allowDateOnly = (type === DynamicFormFieldEnum.Date || dateGranularity === DynamicFormFieldEnum.Month);
            const fieldType = type === DynamicFormFieldEnum.Date ?
                    (dateGranularity === DynamicFormFieldEnum.Month ? FieldTypes.Month : FieldTypes.Date) :
                    (allowNumbersOnly ? FieldTypes.Number : FieldTypes.Text);
            const placeholder = allowDateOnly ? FieldLabelEnum.EnterMonth :
                    (allowNumbersOnly ? FieldLabelEnum.EnterNumber : FieldLabelEnum.EnterDetails);
            const max = (!!maxValue ? maxValue : undefined);
            const min = (allowNumbersOnly ? 0 : undefined);
            const inpProps: any = {max, min, inputMode: allowDateOnly || allowNumbersOnly ? "numeric" : "text"};
            const InpLabelProps = {shrink: true};
            const multi = fieldType === FieldTypes.Text;
            const hasRows = fieldType === FieldTypes.Text ? 5 : undefined;

            if (name === HEIGHT_QUESTION_CODE) {
                const MAX_FEET = 10;
                const feetOptions = numArray(MAX_FEET).filter(x => x !== 0); // feet can't be 0
                const incOptions = numArray(INCH_IN_FEET);

                const onFeetSelect = (value: number) => {
                    setFoots(value as number);
                    const feetInch = convertFeetInches(value, inches);
                    setFieldValue(name, feetInch);
                };
                const onIncSelect = (value: number) => {
                    setInches(value);
                    const feetInch = convertFeetInches(foots, value);
                    setFieldValue(name, feetInch);
                };

                return (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="body1">{label}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectBox
                                        {...commonProps}
                                        initVal={HFeet}
                                        label="Feet"
                                        onSelect={onFeetSelect}
                                        options={feetOptions}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectBox
                                        {...commonProps}
                                        initVal={HInch}
                                        label="Inches"
                                        onSelect={onIncSelect}
                                        options={incOptions}
                                />
                            </Grid>
                        </>
                )
            }

            return (
                    <TextBox
                            {...commonProps}
                            as={TextField}
                            className="mt10"
                            fullWidth
                            InputLabelProps={InpLabelProps}
                            inputProps={inpProps}
                            multiline={multi}
                            placeholder={placeholder}
                            rows={hasRows}
                            type={fieldType}
                            variant="outlined"
                    />
            );

        case DynamicFormFieldEnum.Picklist:
        case DynamicFormFieldEnum.Selection:
            return (
                    <>
                        <Grid item xs={12}>
                            <PickList {...commonProps} isRadio={isRadio} options={options as string[]}/>
                        </Grid>
                        {
                                disclosureSource === DynamicFormFieldEnum.Picklist && picklistSearchEnabled &&
                                <SearchAddNewBox
                                        {...commonProps}
                                        hideLabel={true}
                                        onSearch={onSearch}
                                        options={options}
                                        searching={searching}
                                />
                        }
                    </>
            );

        case DynamicFormFieldEnum.Boolean:
        case DynamicFormFieldEnum.YesNo:
            const listItems: ParsedListItem[] = (enumAsArray(YesNoEnum) as string[])
                    .map(v => ({text: v, value: YesNoEnum.Yes === v ? 'true' : 'false'}));
            const isSpecialCaseOne = options && !!va(options) && disclosureSource !== DynamicFormFieldEnum.Search &&
                    (!!values[name] || (answerValue && answerValue !== SPECIAL_NULL && !!answerValue));
            const isSpecialCaseTwo = disclosureSource === DynamicFormFieldEnum.Search;
            const isSpecialCaseThree = disclosureSource === DynamicFormFieldEnum.Picklist && picklistSearchEnabled;
            const isSpecialCase = isSpecialCaseOne || isSpecialCaseTwo || isSpecialCaseThree;
            const setForceClearOnNO = async (v: string) => {
                const bollVal = JSON.parse(v) as unknown as boolean;
                setForceNo(bollVal);
                await sleep(); // wait added for special cases
                setFieldTouched(name, true);
            };
            const moreSearchProps = {
                ansValues: getDefaultAnsValuesForSearchBox(ansValues),
                defaultAnsValues: getDefaultAnsValuesForSearchBox(defaultAnsValues),
                hideLabel: true,
                isSpecialCase,
                multiple: false,
                onSearch,
                options: searchOptions,
                searching: searching,
            };
            return (
                    <>
                        {
                                isDebug('extraDebug') &&
                                <Grid item xs={12} style={{display: debug ? 'display' : 'none'}}>
                                    <pre>
                                        {
                                            JSON.stringify({
                                                defaultAnsValues,
                                                forceNo,
                                                initialValues,
                                                answerValue,
                                                ansValues,
                                                isSpecialCase,
                                                isSpecialCaseOne,
                                                isSpecialCaseTwo,
                                                isSpecialCaseThree,
                                                canFly: isObject(editAns),
                                                hasSuperPowers: (forceNo && isObject(editAns))
                                            }, null, 2)
                                        }
                                    </pre>
                                </Grid>
                        }
                        <ToggleButton
                                {...commonProps}
                                defaultValue={answerValue}
                                isSpecialCase={isSpecialCase}
                                onClearSearch={onClearSearch}
                                onToggle={setForceClearOnNO}
                                listItems={listItems}
                        />
                        {
                                forceNo && isSpecialCaseOne &&
                                <Grid item xs={12}>
                                    <PickList
                                            {...commonProps}
                                            answerValue={getDefaultAnsValuesForSearchBox(ansValues)}
                                            isSpecialCase={isSpecialCase}
                                            label=""
                                            options={((!forceNo && isObject(editAns)) ? [] : options) as string[]}
                                    />
                                </Grid>
                        }
                        {
                                forceNo && isSpecialCaseTwo &&
                                <>
                                    <SearchAddNewBox
                                            {...commonProps}
                                            {...moreSearchProps}
                                            options={options || searchOptions}
                                    />
                                </>
                        }
                        {
                                forceNo && isSpecialCaseThree &&
                                <SearchAddNewBox {...commonProps} {...moreSearchProps} />
                        }
                    </>
            );

        case DynamicFormFieldEnum.Physician:
            return (
                    <PhysicianProfessional {...props} />
            );

        case DynamicFormFieldEnum.Heading:
        default:
            return (
                    <>
                        {
                                !loading &&
                                <>
                                    <Typography>{label}</Typography>
                                    {
                                            debug &&
                                            <Typography>Unknown type (<strong>{type}</strong>)</Typography>
                                    }
                                </>
                        }
                    </>
            );
    }
};


export default Field;
