import React, {useRef} from 'react';
import {DialogTitle} from "../ConfirmEnrollment";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button, Dialog, DialogActions, DialogContent, Grid} from "@material-ui/core";
import {FormInputs} from "../../pages/approvedAgents/ApprovedAgentsPage";
import {Label} from "../formInputs";
import {SelectField} from "../formikFormInputs";
import {useActions} from "../../actions";
import * as DivisionActions from "../../actions/division";
import * as AggregatorActions from "../../actions/aggregator";
import * as AccountActions from "../../actions/account";

/*
    userId: imo user id
    imoDetails: imo detail for aggregator user id to get the available divisions of the imo
 */
function AddAggregatorDivision(props: { open: boolean, handleClose: () => void, userId?: string, imoDetails: any }) {
    const {open, handleClose, userId, imoDetails} = props;
    const {aggregator} = imoDetails;
    const aggregatorUserId = aggregator.userId;
    const accountActions = useActions(AccountActions);
    const divisionActions = useActions(DivisionActions);
    const aggregatorActions = useActions(AggregatorActions);
    const [divisionDropDown, setDivisionDropDown] = React.useState([])
    const formRef: any = useRef();
    const initialDivision = {
        divisionId: ""
    }
    const divisionFields = [
        {
            name: "divisionId",
            label: <Label label={"Select Division"}/>,
            component: SelectField,
            disabled: false,
            options: divisionDropDown
        }
    ]
    React.useEffect(() => {
        getDivisions().then(() => {
        });
    }, []);

    const getDivisions = async () => {
        const res = await aggregatorActions.getAggregatorDivision(aggregatorUserId);
        let divisionArr: any = [];
        res && res.rows && res.rows.length > 0 && res.rows.forEach((item: any) => {
            divisionArr.push({
                id: item.id,
                name: item.divisionName,
                value: item.id,
                key: item.id
            })
        });
        setDivisionDropDown(divisionArr)
    }
    const submit = async (values: any, {setSubmitting, resetForm}: any) => {
        await divisionActions.assignDivision({divisionId: values.divisionId, imoUserId: userId})
        await accountActions.getAccount(userId, 3);
        handleClose();
    }
    return <><Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle onClose={handleClose} id="dialog-imo">
            Assign Division
        </DialogTitle>
        <Formik
                innerRef={formRef}
                initialValues={initialDivision}
                onSubmit={submit}
                validationSchema={Yup.object({
                    divisionId: Yup.string().required("Division is required"),
                })}
        >
            {({errors, values, touched, handleChange, handleBlur, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <FormInputs
                                        fields={divisionFields}
                                        {...{errors, values, touched, handleChange, handleBlur}}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container justify="space-between" style={{padding: "1rem"}}>
                                <Button
                                        size="small"
                                        className="floatLeft mt20 mb20"
                                        type="button"
                                        variant="contained"
                                        onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                        size="small"
                                        className="floatRight mt20 mb20"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </DialogActions>
                    </form>
            )}
        </Formik>
    </Dialog></>
}

export default AddAggregatorDivision;