import React from "react";
import {FieldArray, useFormikContext} from "formik";
import {DatePicker, TextFieldCommon} from "../../../../../../../components/formikFormInputs";
import {Label} from "../../../../../../../components/formInputs";
import {Button, FormHelperText, Grid, Typography} from "@material-ui/core";
import InsuredPageCheckBox from "../component/InsuredPageCheckBox";
import TextField from "@material-ui/core/TextField";
import InsuredPageRadioButton from "../component/InsuredPageRadioButton";

const ProposedInsuredQuestion70 = ( props:{clientSigned:boolean }) => {
    const { clientSigned } = props;
    const name:string = "Proposed_Insured_Questions-70Xfa";
    const { values, handleChange, handleBlur, errors, touched }:any = useFormikContext();
    const fre:any =  [
        {
            text: "Less than 2 weeks",
            value: "Less than 2 weeks"
        },
        {
            text: "6-12 months",
            value: "6-12 months"
        },
        {
            text: "2-4 weeks",
            value: "2-4 weeks"
        },
        {
            text: "1-2 years",
            value: "1-2 years"
        },
        {
            text: "1-3 months",
            value: "1-3 months"
        },
        {
            text: "More than 2 years",
            value: "More than 2 years"
        },
        {
            text: "3-6 months",
            value: "3-6 months"
        }
    ];

    const on:any = [
        {
            text: "Business",
            value: "Business"
        },{
            text: "Visit family/friends",
            value: "Visit family/friends"
        },{
            text: "Mission work",
            value: "Mission work"
        },{
            text: "Education",
            value: "Education"
        },{
            text: "Pleasure",
            value: "Pleasure"
        }
    ];

    let questionRadio:any = {
        notShowHrLine: false,
        additionalFieldType: 'radioBoxGroup',
        additionalFieldOptions: fre
    };

    let questionCheckBox:any = {
        notShowHrLine: false,
        additionalFieldType: 'radioBoxGroup',
        additionalFieldOptions: on
    };

    const disableProperty:any = {
        disabled: clientSigned
    }

    return (
        <React.Fragment>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant={"body1"} component="strong" className="mt15 floatLeft w100 mb0">
                    Please provide the following details for the travel plan below
                </Typography>
            </Grid>
            <span className="hLine"/>

                <FieldArray
                name={name}
                render={({ remove, push }) => {
                    const init70Details = { "Tplan": "", "Rlen": "", "Con": [], "Dan": null };
                    const addNew70Details = () => push(init70Details);
                    return (
                            <Grid container spacing={2}>
                                {
                                        values && values[name] && values[name].length > 0 &&
                                        values[name].map((friend, index) => {
                                            let valueAt70:any = (values && values[name] && values[name][index]) || {};
                                            let errorAt70:any = (errors && errors[name] && errors[name][index]) || {};
                                            let touchedAt70:any = (touched && touched[name] && touched[name][index]) || {};
                                            return (
                                                    <>
                                                        <Grid item xs={12} sm={12} md={12} className="mt20">
                                                            <Typography variant={"body1"} component="strong"
                                                                        className="mb0">
                                                                Travel&nbsp;{index + 1}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} key={index}>
                                                            <TextField
                                                                    fullWidth
                                                                    name={`${name}.${index}.Tplan`}
                                                                    placeholder={"Where you plan to travel"}
                                                                    variant="outlined"
                                                                    label={<Label label={"Where you plan to travel"}
                                                                                  required={true}/>}
                                                                    value={valueAt70["Tplan"]}
                                                                    onChange={(e: any) => handleChange(e)}
                                                                    onBlur={handleBlur}
                                                                    {...disableProperty}
                                                            />
                                                            {errorAt70["Tplan"] &&
                                                                    touchedAt70["Tplan"] && (
                                                                            <Grid item xs={12}>
                                                                                <FormHelperText  style={{margin: '0', fontSize: '10px', color: "#FF1744"}}>
                                                                                    <span>{errorAt70["Tplan"]}</span>
                                                                                </FormHelperText>
                                                                            </Grid>
                                                                    )}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6} key={index + 1}>
                                                            <TextFieldCommon
                                                                    name={`${name}.${index}.Dan`}
                                                                    id={`${name}.${index}.Dan`}
                                                                    placeholder={"Anticipated departure date"}
                                                                    component={DatePicker}
                                                                    values={valueAt70["Dan"] || ""}
                                                                    label={<Label label={"Anticipated departure date"}
                                                                                  required={true}/>}
                                                                    onChange={(e: any) => handleChange(e)}
                                                                    onBlur={handleBlur}
                                                                    disablePast={true}
                                                                    saveFormat={"USER"}
                                                                    {...disableProperty}
                                                            />
                                                            {errorAt70["Dan"] &&
                                                                    touchedAt70 && (
                                                                            <Grid item xs={12}>
                                                                                <FormHelperText  style={{margin: '0', fontSize: '10px', color: "#FF1744"}}>
                                                                                    <span>{errorAt70["Dan"]}</span>
                                                                                </FormHelperText>
                                                                            </Grid>
                                                                    )}
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography variant="body2" component="strong" gutterBottom>
                                                                <Label label={"Length of travel time"} required={true}/>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} className="mb15">
                                                            <InsuredPageRadioButton question={{
                                                                ...questionRadio,
                                                                name: `${name}.${index}.Rlen`,
                                                            }} clientSigned={clientSigned}/>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography variant="body2" component="strong" gutterBottom>
                                                                <Label label={"Reason for travel"} required={true}/>
                                                            </Typography>
                                                        </Grid>
                                                        <InsuredPageCheckBox
                                                                question={{ ...questionCheckBox, name: `${name}.${index}.Con` }}
                                                                clientSigned={clientSigned}
                                                        />

                                                        {!clientSigned &&
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <Button variant="text" style={{color: "red"}}
                                                                        color={"primary"} onClick={() => remove(index)}>Remove
                                                                    Travel</Button>
                                                            </Grid>
                                                        }
                                                        <span className="hLine"/>
                                                    </>
                                            );
                                        })
                                }
                                {
                                        !clientSigned && (values && values[name] && values[name].length < 4) &&
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Button
                                                    variant="text"
                                                    color={"primary"}
                                                    onClick={addNew70Details}
                                            >
                                                Add Travel
                                            </Button>
                                        </Grid>
                                }
                            </Grid>
                    )
                }}
            />
        </React.Fragment>
    )
}

export default ProposedInsuredQuestion70;