import React from "react";
import { Label } from "../../../../../components/formInputs";
import { Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ErrorCommon, TextFieldCommon } from "../../../../../components/formikFormInputs";

type PropsType = {
	filedProps: any,
	action: {
		handleChange?: Function,
		handleBlur: Function,
		values?: any,
		errors?: any,
		touched?: any,
		setFieldValue: any
	},
	conditionalRequired: boolean,
	role: number | undefined,
	setActionItem: Function,
	client: any,
	labelsActive: any,
	setLabelsActive: Function,
	clientSigned: any,
	getFieldFocusValue: Function
}
const CommonFieldFunction = (props: PropsType) => {
	const {
		action: { handleChange, handleBlur, values, errors, touched, setFieldValue },
		filedProps: { name, helperFieldText, label, dollarSign },
		conditionalRequired, role, setActionItem, client, labelsActive, setLabelsActive, clientSigned,
		getFieldFocusValue, filedProps,
	} = props;
	let fieldLabel = label;
	if (name === "employeeSSN") {
		fieldLabel =
                <Label label={values["nonUsCitizen"] === 1 ? "Enter Social Security Number" : "Enter ITIN Number"}
				   required={conditionalRequired} />;
	}
	if (name === "blotSSN") {
		fieldLabel =
			<Label label={values["usCitizen"] ? "SSN (last 4 digits)" : "ITIN (last 4 digits)"}
				   required={conditionalRequired} />;
	}
	const getHelperText = (name: string, values: any) => {

		if (name === "identityId") {
			return <span
				className="f12 textLight"> Must be a valid drivers license </span>;
		}

		if (name === "firstName" || name === "lastName") {
			return ( <span className="f12 textLight">Must match drivers license</span> );
		}

		if ((name === "employeeSSN" || name === "blotSSN") && values["nonUsCitizen"] === 1) {
			const ssnButton = () => {
				if (role !== 1 && role !== 2 && role !== 4) {
					return <></>;
				}
				return (
                        <Button size="small" className={"mt5"} onClick={() => {
                            setActionItem(!!client.employeeSSN);
                        }}
                                variant="contained" color="primary">
                            {name === "employeeSSN" ? "Hide" : "Show"} SSN
                        </Button>
				);
			};
			return (
				<>
                        <span className="f12 textLight">What's SSN.
                            <Tooltip
								arrow
								title={
									<React.Fragment>
										<Typography
											className="f14 mb0">In the United States, a Social Security number
											is a
											nine-digit number issued to U.S. citizens, permanent residents, and
											temporary residents.</Typography>
									</React.Fragment>
								}
							>
                              <IconButton aria-label="Info" className="pd0"
										  color="primary">
                                  <InfoIcon className="pd0" />
                              </IconButton>
                            </Tooltip>
                        </span>
					{name === "blotSSN" ?
						ssnButton()
						:
						(name === "employeeSSN" && values["employeeSSN"] && ssnButton())
					}
				</>
			);
		}

		if ((name === "employeeSSN" || name === "blotSSN") && values["nonUsCitizen"] === 0) {
			const ssnButton = () => {
				if (role !== 1 && role !== 2 && role !== 4) {
					return <></>;
				}
				return (
					<Button size="small" className={"mt5"} onClick={() => {
						setActionItem(!!client.employeeSSN);
					}}
							variant="contained" color="primary">
						{name === "employeeSSN" ? "Hide" : "Show"} ITIN
					</Button>
				);
			};
			return (
				<>
                    <span className="f12 textLight">What's ITIN.
                        <Tooltip
							arrow
							title={
								<React.Fragment>
									<Typography
										className="f14 mb0">An Individual Taxpayer Identification Number (ITIN)
										is a tax processing number issued by the Internal Revenue
										Service. </Typography>
								</React.Fragment>
							}
						>
                          <IconButton aria-label="Info" className="pd0"
									  color="primary">
                              <InfoIcon className="pd0" />
                          </IconButton>
                        </Tooltip>
                    </span>
					{name === "blotSSN" ?
						ssnButton()
						:
						(name === "employeeSSN" && values["employeeSSN"] && ssnButton())}
				</>
			);
		}

		return <span className="f12 textLight">{helperFieldText}</span>;
	};

	let InputProps: any = {};
	if (dollarSign) {
		InputProps = {
			startAdornment: <InputAdornment className="margin0"
											position="start">$</InputAdornment>,
		};
	}

	return (
		<>
			<TextFieldCommon
				{...filedProps}
				label={fieldLabel || ""}
				name={name}
				error={errors[name] && touched[name] && errors[name]}
				values={values}
				onChange={handleChange}
				onBlur={(e) => {
					handleBlur(e);
					if (labelsActive.length > 0) {
						let isExist = labelsActive.find((label) => label.name === name);
						if (isExist) {
							let updatedArr: any = labelsActive.map((label) => {
								if (label.name === name) {
									return { name, value: false };
								}
								return label;
							});
							setLabelsActive(updatedArr);
						} else {
							setLabelsActive([...labelsActive, { name, value: false }]);
						}
					} else {
						setLabelsActive([...labelsActive, { name, value: false }]);
					}
				}}
				onFocus={() => {
					if (labelsActive.length > 0) {
						let isExist = labelsActive.find((label) => label.name === name);
						if (isExist) {
							let updatedArr: any = labelsActive.map((label) => {
								if (label.name === name) {
									return { name, value: true };
								}
								return label;
							});
							setLabelsActive(updatedArr);
						} else {
							setLabelsActive([...labelsActive, { name, value: true }]);
						}
					} else {
						setLabelsActive([...labelsActive, { name, value: true }]);
					}
				}}
				disabled={!!(filedProps.disabled || clientSigned)}
				InputProps={InputProps}
				InputLabelProps={{
					shrink: !!(dollarSign || values[name] || getFieldFocusValue(name)),
					disabled: false,
				}}
			/>
			{getHelperText(name, values)}
			<ErrorCommon errors={errors} name={name} touched={touched} />

		</>
	);
};

export default CommonFieldFunction;