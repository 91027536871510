import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {DialogTitle} from "../table/AdminTable";
interface ConfirmationDialogProps {
    title: string;
    description: string;
    isConfirmed: boolean;
    setIsConfirmed: Function;
    action: Function;
    confirmBtnTxt?: string;
    cancelBtnTxt?: string;
}
const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    const {title, description, isConfirmed, setIsConfirmed, action, confirmBtnTxt = "Yes", cancelBtnTxt= "No"} = props;
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm" onClose={() => setIsConfirmed(false)} aria-labelledby="customized-dialog-title"
            open={isConfirmed}>
            <DialogTitle id="customized-dialog-title" onClose={() => setIsConfirmed(false)}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" className="mb0">{description}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    className="bgRed"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setIsConfirmed(false)}
                >
                    {cancelBtnTxt}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        action();
                        setIsConfirmed(false)
                    }}
                >
                    {confirmBtnTxt}
                </Button>
            </DialogActions>
        </Dialog>

    )
}
export default ConfirmationDialog;
