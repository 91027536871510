import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Grid, Typography, Card, CardContent, Link } from "@material-ui/core";
import { DashboardStyle as Styles } from "../../pages/agent/dashboard/dashboardStyle";
import GroupLinkCardImg from "../../pages/agent/dashboard/images/GroupLinkCardImg.jpg";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import copy from "copy-to-clipboard";
import {useActions} from "../../actions";
import * as AccountActions from "../../actions/account";

export default function GroupDashboard() {
    const classes = Styles();
    const auth: any = useSelector((state: RootState) => state.auth);
    const accountActions = useActions(AccountActions);
    const user: any = auth && auth.user;
    let groupUrl: string = "";
    let groupUrlLabel: string = "group";
    if (user && user.company) {
        if (!user.company.showMarketing) {
            groupUrlLabel = "groupwm";
        }
        groupUrl = `${window.location.origin}/${groupUrlLabel}/${user.company.companyLinkUrl}`;
    } else if (user && user.groupSigners && user.groupSigners.company && user.role === 16) {
        if (!user.groupSigners.company.showMarketing) {
            groupUrlLabel = "groupwm";
        }
        groupUrl = `${window.location.origin}/${groupUrlLabel}/${user.groupSigners.company.companyLinkUrl}`;
    }

    function handleCopyLink(){
        copy(groupUrl);
        accountActions.copyInvitationLink("Link URL Has Been Copied to Your Clipboard")
    }
    return (
            <div className={classes.agentHero + " " + classes.groupHero}>
                <Grid container spacing={2} justify="space-between" alignItems="center">
                    <Grid item xs={12} md={7} lg={7}>
                        {
                            user && user.company &&
                            <Typography variant="h2" align="left"
                                        className={classes.colorWhite + " " + classes.title}>
                                Welcome&nbsp; {user.company.companyName}
                            </Typography>
                        }
                        {
                            user &&  user.groupSigners &&
                            <Typography variant="h2" align="left"
                                        className={classes.colorWhite + " " + classes.title}>
                                Welcome&nbsp; {user.groupSigners.company.companyName}
                            </Typography>
                        }
                        <Typography variant="subtitle1"
                                    className={classes.colorWhite + " " + classes.mb0 + " " + classes.floatLeft}>This is
                            your group dashboard for viewing and managing your participants within ilia. Get started by
                            selecting an option below or from the main navigation.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} lg={4}>
                        <Card className={classes.GroupCard}>
                            <CardContent>
                                <Typography variant="body1">
                                    <img src={GroupLinkCardImg} alt="GroupLinkCardImg"/></Typography>
                                <span className="clearFix"/>
                                <Typography variant="subtitle1" component="strong" className={classes.w100}>Group
                                    Link</Typography>
                                <Typography variant="body1"
                                            className={classes.mb0 + " " + classes.floatLeft}>Copy this link to share
                                    with
                                    your participants so they can access their secure ilia portal.

                                </Typography>
                                <Typography variant="body1"
                                            className={classes.mt15 + " " + classes.floatLeft + " " + classes.w100}>
                                    <Link color="primary" style={{ wordBreak: "break-word" }}
                                          onClick={handleCopyLink}>{groupUrl}
                                        <FileCopyOutlinedIcon style={{ verticalAlign: "bottom", marginLeft: 15 }}/>
                                    </Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
    );
}