import React, {useEffect, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import {Grid,Typography} from "@material-ui/core";
import ProgressBar from "../../../pages/client/Estimation/V1/ProgressBar";
import Dialog from "@material-ui/core/Dialog";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers";

const stressTesting = "1. Stress Testing";

export default function TestingProcessDialog(props: { open: boolean, handleClose: Function, isEstimationDataLoading: boolean, getEstimationProgress: Function  }) {
    const {open, handleClose, isEstimationDataLoading, getEstimationProgress} = props;
    const executionArn = useSelector((state: RootState) => state.executionArn);
    const [testingName, setTestingName] = useState(stressTesting);
    const [progress, setProgress] = useState(5);

    useEffect(() => {
        if (!isEstimationDataLoading) {
            handleClose();
        }
    }, [isEstimationDataLoading])

    useEffect(() => {
        let interval = setInterval(async () => {
            if(!!executionArn) {
                const progress = await getEstimationProgress(executionArn);
                if (progress.status_stress && progress.status_illustration && !progress.status_success) {
                    setTestingName("3. Generating Documents");
                    setProgress((prevProgress) => (66));
                } else if (progress.status_stress && !progress.status_illustration && !progress.status_success) {
                    setTestingName("2. Estimating Benefits");
                    setProgress((prevProgress) => (33));
                } else if (progress.status_stress && progress.status_illustration && (progress.status_success || progress.status_reports)) {
                    setProgress((prevProgress) => (99));
                } else if(progress.status_failure == true){
                    handleClose();
                }
            }
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [executionArn]);

    useEffect(() => {
        let interval = setInterval(async () => {
            if (testingName === stressTesting) {
                setProgress((prevProgress) => (prevProgress + 1));
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [testingName])

    return (
            <>
                <Dialog
                        fullWidth={true}
                        maxWidth='sm'
                        open={open}
                        onClose={() => {
                            if (isEstimationDataLoading) {
                                return false;
                            }
                            handleClose()
                        }}
                        aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="customized-dialog-title">
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} className="textCenter">
                                {testingName}
                            </Grid>
                            <Grid item xs={9} className="textCenter">
                                <ProgressBar progressBar={progress} />
                            </Grid>
                            <Grid item xs={12} className="textCenter">
                               <Typography variant="caption" className="textBlackLight"> Please be patient, this could take up to 1 minute.</Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                </Dialog>
            </>
    )
}