import * as React from "react";
import { List } from "@material-ui/core";
import {
    AccountBox as AccountBoxIcon,
    Description as DescriptionIcon,
    DateRange as DateRangeIcon,
    List as ListIcon,
    Home as HomeIcon,
    Group as GroupIcon,
    NotificationsNone as NotificationsNoneIcon,
    OndemandVideo as OndemandVideoIcon,
    Comment as CommentIcon,
    ContactPhone as ContactPhoneIcon,
    Link as LinkIcon, Payment as PaymentIcon,
    LocalAtm as LocalAtmIcon
} from "@material-ui/icons";
import NiwListItem from "../NiwListItem";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { history } from "../../configureStore";
interface listItemType {
    url : string,
    title : string,
    listIcon : any
}

function AgentNavigation(props:any) {
    const {handleNavClick} = props;
	const [routeName, setRouteName] = React.useState(window.location.pathname);
    const { user } = useSelector((state: RootState) => state.auth);
	const navigationsList = [
		{
			url : '/agent-dashboard',
			title : 'Agent Dashboard',
			listIcon : <HomeIcon/>
		},
        {
			url : '/client',
			title : 'Client Management',
			listIcon : <DescriptionIcon/>
		},
        {
			url : '/agent-links',
			title : 'Agent Links',
			listIcon : <LinkIcon/>
		},
		{
			url : '/action-items',
			title : 'Action Items',
			listIcon : <ListIcon/>
		},
        {
            url : '/ilia-notifications',
            title : 'ilia Notifications',
            listIcon : <NotificationsNoneIcon/>
        },
		{
			url : '/agents/events',
			title : 'Events & Webinars',
			listIcon : <DateRangeIcon/>
		},
		{
			url : '/agents/ILIATrainingPage',
			title : 'ilia Training',
			listIcon : <AccountBoxIcon/>
		},
        {
            url : '/agents/marketing-material',
            title : 'Marketing Materials',
            listIcon : <OndemandVideoIcon/>
        },
        {
            url : '/agents/news-social',
            title : 'News & Social Posts',
            listIcon : <CommentIcon/>
        },
        {
            url : '/agent-leads',
            title : 'Lead Management',
            listIcon : <ContactPhoneIcon/>
        },
        {
            url : '/client-payments',
            title : 'Client Payment Options',
            listIcon : <PaymentIcon/>
        },
        {
            url : '/tranche-dates',
            title : 'Tranche Dates',
            listIcon : <LocalAtmIcon/>
        }
        //Not Needed Until Client Approves
        // {
        //     url : '/reports',
        //     title : 'Reporting',
        //     listIcon : <ReceiptIcon/>
        // }
	];

    if(user && user.agent && user.agent.companyCount) {
        navigationsList.splice(1, 0,
            {
                url: '/agent-groups',
                title: 'My Groups',
                listIcon: <GroupIcon />

            });
    }

	React.useEffect(() => {
		if(window.location.pathname === '/accounts'){
			setRouteName( '/agent-dashboard' );
		} else{
			setRouteName( window.location.pathname );
		}

	}, [routeName, history.location.pathname]);

	return (
		<List className="leftNav">
			{
				navigationsList.map( ( listItem : listItemType ) => {
					return (
						<NiwListItem
							key={listItem.url}
							url={listItem.url}
							title={listItem.title}
							listIcon={listItem.listIcon}
							routeName={routeName}
							setRouteName={setRouteName}
                            user={user}
                            handleNavClick={handleNavClick}
						/>
					)
				}
				)
			}
		</List>
	);
}

export default AgentNavigation;
