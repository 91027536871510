import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useActions } from "../../actions";
import * as Actions from "../../actions/account";
import { ErrorCommon, NumberTextField, TextFieldCommon } from "../formikFormInputs";
import { Formik } from "formik";
import { Label } from "../formInputs";
import { Button as FormikButton } from "../../components/formInputs";
import NumberFormat from "react-number-format";
import {ActionItems as CurrentActionsItems} from "../../constants";
import { unmaskCurrency } from "../../utils/commonUtils";

const styles: any = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: '10px',
        top: '20px',
        color: theme.palette.grey[500],
    },
});

const DialogTitle: any = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function PaymentProcessedPopUp(props: { open: boolean, setPaymentProcessedPopUp: Function, enrollmentId: string, clientId: number, agentId: number, clientUserId: string, client: any, refreshList: Function, setFilterType?: Function, role?: Number }) {
    const { open, setPaymentProcessedPopUp, enrollmentId, clientId, agentId, client, refreshList, setFilterType, role } = props;
    const accountActions = useActions(Actions);

    const handleClose = () => {
        setPaymentProcessedPopUp(false);
        refreshList();
    };

    const markPaymentProcessed = async ( values:any, setSubmitting:Function ) => {
        let processedAmount = Number(unmaskCurrency(values.processedAmount.toString()));
        await accountActions.markPaymentProcessed({
            agentId : agentId,
            clientId : clientId,
            enrollmentId : enrollmentId,
            actionType: CurrentActionsItems.UPLOAD_TRUSTEE_FINAL_DOCS.actionType,
            processedAmount: processedAmount
        });
        if(setFilterType && role !== 4) {
            setFilterType(CurrentActionsItems.UPLOAD_TRUSTEE_FINAL_DOCS.actionType);
            setPaymentProcessedPopUp(false);
        } else {
            handleClose();
        }
        setSubmitting(false);
    }

    const initialValues = {
        fundingType: client && client.payment_method,
        processedAmount: client && client.receivedAmount,
    };
    const validate = ((value: any) => {
        const errors: { processedAmount?: string } = {};
        if (!value.processedAmount || value.processedAmount < 1) {
            errors.processedAmount = "Required";
        }
        return errors;
    });

    const fieldObj = [
        {
            id: "processedAmount",
            name: "processedAmount",
            label: <Label label={"Enter Processed Amount"} required={true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            disabled: false,
        },
    ];
    return (
            <div>
                <Dialog fullWidth={true} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={open}>
                    <DialogTitle className="closeSvg" id="customized-dialog-title" onClose={handleClose}>
                        Enter Payment Processed Amount
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1" className="f18" align="center">
                            Enter the initial contribution amount that has been received for reporting purposes.
                        </Typography>
                        <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validate={values => validate(values)}
                                validateOnChange={true}
                                onSubmit={ (values, { setSubmitting }) => {
                                    markPaymentProcessed(values, setSubmitting);
                                }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  setFieldValue,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty,
                              }) => (<form onSubmit={handleSubmit}>
                                <Grid container spacing={2} justify="center" className="mt15 mb20">
                                    {fieldObj.map((field, index) => {
                                        return (
                                                <Grid item xs={12} sm={6}>
                                                    <TextFieldCommon
                                                            {...field}
                                                            values={values}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                    />
                                                    <ErrorCommon errors={errors} name={field.name}
                                                                 touched={touched}/>
                                                    <Typography variant='body1' align="left" className="f18 mt15 mb0">
                                                        Amount received:&nbsp;
                                                        <NumberFormat
                                                                value={(client && client.receivedAmount) || 0}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}/>
                                                    </Typography>
                                                    <Typography variant='body1' align="left" className="f18">
                                                        Amount due:&nbsp;
                                                        <NumberFormat
                                                                value={(client && client.issuedContribution) || 0}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}/>
                                                    </Typography>
                                                </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                                <FormikButton
                                        fullWidth={false}
                                        type="submit"
                                        label="Mark Payment Processed"
                                        variant="contained"
                                        className="floatRight"
                                        color="primary"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                />
                            </form>)}

                        </Formik>
                    </DialogContent>
                </Dialog>
            </div>
    );
}
