import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import { MultiLifeTable, Pagination } from "../../../components/table";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { useActions } from "../../../actions";
import * as AccountActions from "../../../actions/account";
import { AccountSortList } from "../../../model/account";
import { noop } from "../../../utils/commonUtils";
import TableWrap from "../../../components/TableWrap/TableWrap";

export const doNone = noop;

interface Props extends RouteComponentProps {
    agentUserId: number;
}

export const AgentGroupsTab = withRouter(({ agentUserId, history }: Props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [orderBy, setOrderBy] = useState("createdAt");
    const [order, setOrder] = useState("desc");

    const accountActions = useActions(AccountActions);
    const clientList = useSelector((state: RootState) => state.accounts);
    const auth = useSelector((state: RootState) => state.auth);

    React.useEffect(() => {
        let queryObj: any = { page, rowsPerPage, sortBy: orderBy, order, filter: 8, agentUserId };

        accountActions.agentClientList(queryObj);
    }, [page, rowsPerPage, orderBy, order]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    return (
        <Grid item xs={12}>
            <TableWrap>
                    <MultiLifeTable
                        accountList={clientList}
                        callingFrom='clientManagementPage'
                        history={history}
                        handleRequestSort={handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                        handleRefresh={doNone}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        loggedInUserRole={auth.user.role}
                    />
            </TableWrap>
            <Pagination page={page} rowsPerPage={rowsPerPage} setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                accountList={clientList}/>
        </Grid>
    )
})
