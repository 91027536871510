import React from "react";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "./reducers";
import kaiZenTheme, {KaiZenThemeGlobalCss} from './styles/theme/kaiZenTheme';
import TriZenTheme, {TriZenThemeGlobalCss} from './styles/theme/triZenTheme';
import NIWTheme, {NIWGlobalCss} from './styles/theme/NIWTheme';
import { history } from "./configureStore";

function withRoot<P extends object>(Component: React.ComponentType<P>) {
	function WithRoot(props: P) {
		let currentTheme = kaiZenTheme;
		let GlobalCss = KaiZenThemeGlobalCss;
		const { user } = useSelector((state: RootState) => state.auth);
		const companyInvite = useSelector((state: RootState) => state.companyInvite);
		const verifyInvite = useSelector((state: RootState) => state.verify);

        let loggedInimoReBrandStrategyESD:boolean = user && user.client && user.client.imo && user.client.imo.reBrand && user.client.strategy && ( user.client.strategy.slug == 'esd' || user.client.strategy.slug == 'trizen'  )  ? true : false;

		if (user && ((user.role === 5 || user.role === 6) || (user.accessType === "client" && (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 4 || user.role === 15)))) {
			currentTheme = kaiZenTheme;
			GlobalCss = KaiZenThemeGlobalCss;
			if ( loggedInimoReBrandStrategyESD || ( (user.client && user.client.strategy && (user.client.strategy.slug === "trizen" || user.client.strategy.slug === "esd")) ||
                    (user.demoStrategy && (user.demoStrategy.slug === "trizen" || user.demoStrategy.slug === "esd")) ) )  {
				currentTheme = TriZenTheme;
				GlobalCss = TriZenThemeGlobalCss;
			}

		}


        let isInvitationLayout:boolean = (history.location.pathname.search( '/accept/new-invitation/' ) > -1) || (history.location.pathname.search( '/email-unsubscribe/' ) > -1) || (history.location.pathname.search( '/kaizen' ) > -1) || (history.location.pathname.search( '/agent-link/' ) > -1) || false;
        let { strategy  } = verifyInvite;

        let isESDStartegy:boolean = strategy && ( strategy.slug === "esd" || strategy.slug === "trizen" ) ? true : false;

        if( isInvitationLayout ){
            currentTheme = kaiZenTheme;
            GlobalCss = KaiZenThemeGlobalCss;
            if( isESDStartegy ){
                currentTheme = TriZenTheme;
                GlobalCss = TriZenThemeGlobalCss;
            }
        }

        let indexStart:number = history.location.pathname.search( '/agent/' );
        let grpUrl:number = history.location.pathname.search("/group/");
        let isTrizenInvitationLayout:boolean = ( grpUrl === 0 ) || (indexStart === 0) || false;
        let reBrandStrategyLSP:boolean = (companyInvite.data && companyInvite.data.strategy && companyInvite.data.strategy.reBrand && companyInvite.data.strategy.slug == 'kaizen') ? true : false;
        let reBrandStrategyEsd:boolean = (companyInvite && companyInvite.data && companyInvite.data.agent && companyInvite.data.agent.imo && companyInvite.data.agent.imo.reBrand && companyInvite.data.strategy && companyInvite.data.strategy.slug == 'esd') ? true : false;

        let groupRebranded = (companyInvite && companyInvite.data && ((companyInvite.data.imo && companyInvite.data.imo.reBrand) ||
                (companyInvite.data.agent && companyInvite.data.agent.imo && companyInvite.data.agent.imo.reBrand)));

        if( isTrizenInvitationLayout ){
            currentTheme = kaiZenTheme;
            GlobalCss = KaiZenThemeGlobalCss;
            if ( loggedInimoReBrandStrategyESD || reBrandStrategyEsd ||
                    (
                        !reBrandStrategyLSP &&
                        (
                                companyInvite && companyInvite.data && companyInvite.data.strategies &&
                                (companyInvite.data.strategies.slug === "trizen" || companyInvite.data.strategies.slug === "esd")
                        )
                    )
            )  {
                currentTheme = TriZenTheme;
                GlobalCss = TriZenThemeGlobalCss;
            }

            if (groupRebranded) {
                currentTheme = NIWTheme;
                GlobalCss = NIWGlobalCss;
            }
        }
        // accept/new-lead-client-invitation/
        let leadInvitePage = (history.location.pathname.search( '/accept/new-lead-client-invitation/' ) > -1) || (history.location.pathname.search( '/kaizen' ) > -1) || (history.location.pathname.search( '/agent-link/' ) > -1)|| (history.location.pathname.search( '/group/' ) > -1);
        if(leadInvitePage){
            currentTheme = NIWTheme;
            GlobalCss = NIWGlobalCss;
        }
		return (
			<MuiThemeProvider theme={currentTheme}>
				<GlobalCss />
				<CssBaseline />
				<Component {...props} />
			</MuiThemeProvider>
		);
	}
	return WithRoot;
}

export default withRoot;
