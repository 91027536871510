import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { IconButton, IconButtonProps } from "@material-ui/core";
import { CloudDownloadOutlined } from "@material-ui/icons";
import { Viewer } from "../../../components";
import { CircularLoading, TableView } from "../../../components/Common";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../components/dialog";
import { useActions } from "../../../actions";
import * as AccActions from "../../../actions/account";
import * as ApprovedAgentActions from "../../../actions/approvedAgents";
import * as Aws from "../../../actions/aws";
import { RootState } from "../../../reducers";
import { joinAsFullName, pez } from "../../../utils/commonUtils";
import {AccountType} from '../../../constants';


interface AgentDocumentProps {
    userId?: string;
}

const LoadDocViewer = ({showDoc, setShowDoc}: any) => {
    const awsActions = useActions(Aws);
    const [loading, setLoading] = React.useState(false);
    const loadSignedUrl = async () => {
        setLoading(true);
        const queryObj = {
            contentType: 'application/' + pez(showDoc.documentType, 'pdf'),
            objectName: showDoc.documentPath
        };
        const signedUrl = await awsActions.getSecureUrl(showDoc.envelopeId, queryObj);
        setShowDoc({...showDoc, url: signedUrl});
        setLoading(false);
    };
    const loadEffect = () => {
        loadSignedUrl().then();
    };

    React.useEffect(loadEffect, []);

    return (
            <Viewer loading={loading} title={'Seller Agreement'} url={showDoc.url} />
    )
};

const Documents: React.FC<AgentDocumentProps> = ({userId}: any) => {
    const initDocDetails = {documentPath: '', documentType: '', envelopeId: '', url: ''};
    const routeParams = useParams<{ id: string }>();
    const [loading, setLoading] = React.useState(false);
    const [showDoc, setShowDoc] = React.useState(initDocDetails);
    const accActions = useActions(AccActions);
    const agentActions = useActions(ApprovedAgentActions);
    const _agentProfile: any = useSelector((state: RootState) => state.account) || {};
    const documents = useSelector((state: RootState) => state.documents)
    const gridProps = {
        headers: ['Name', 'Type', 'Approved By', 'Date Modified', 'Actions'],
        list: documents.map(({approvedByUser, adminSigned, documentPath, documentType, envelopeId, updatedAt}: any) => {
            const openDoc = (e: React.SyntheticEvent) => {
                e.preventDefault();
                if (adminSigned) {
                    setShowDoc({documentPath, documentType, envelopeId, url: ''});
                }
            };
            const icoProps: IconButtonProps = {
                'aria-label': 'download',
                className: 'pt0 pb0',
                onClick: openDoc
            };
            const linkProps = {
                className: 'cursor',
                onClick: openDoc,
                href: '',
            };
            const agreementLabel = `Seller Agreement ${adminSigned ? '' : '(Pending)'}`;
            return {
                name: adminSigned ? (
                        <a {...linkProps}>
                            {agreementLabel}
                        </a>
                ) : agreementLabel,
                type: pez(documentType, 'PDF'),
                approvedBy: joinAsFullName(approvedByUser),
                updatedAt: moment(updatedAt).format('MM-DD-YYYY'),
                action: (
                        <IconButton {...icoProps}>
                            <CloudDownloadOutlined />
                        </IconButton>
                )
            };
        })
    };
    const genericDialogProps: GenericDialogProps = {
        'aria-labelledby': 'agent-document-viewer-dialog',
        content: (
                <LoadDocViewer showDoc={showDoc} setShowDoc={setShowDoc} />
        ),
        fullWidth: true,
        maxWidth: 'lg',
        dividers: true,
        onClose: () => setShowDoc(initDocDetails),
        open: !!showDoc.envelopeId,
        title: 'Seller Agreement'
    };
    const pullAgentDocs = async () => {
        setLoading(true);
        if (_agentProfile && _agentProfile.agent) {
            await agentActions.getAgentDocs(_agentProfile.agent.id);
        }
        setLoading(false);
    };
    const firstLoad = () => {
        if (!(_agentProfile && _agentProfile.agent && _agentProfile.agent.id)) {
            const [_agentId, _role] = [!!userId ? userId : routeParams.id, AccountType.Agent];
            accActions.getAccount(_agentId, _role).then();
        }
    };
    const conditionalLoad = () => {
        pullAgentDocs()
                .then();

        return () => agentActions.flushAgentDocs();
    };

    React.useEffect(firstLoad, []);
    React.useEffect(conditionalLoad, [_agentProfile]);

    return (
          <>
              {
                  loading && <CircularLoading />
              }

              <TableView {...gridProps} />

              <GenericDialog {...genericDialogProps} />
          </>
  );
};


export default Documents;
