import * as React from "react";
import { Button, TextField } from "../components/formInputs";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Grid } from "@material-ui/core";
import { PWD_ONE_LETTER_REGEX, PWD_ONE_CAPITAL_LETTER_REGEX, PWD_ONE_NUMBER_REGEX, PWD_MIN_EIGHT_CHAR_REGEX, PWD_ONE_SPECIAL_CHAR } from "../constants";
import PasswordRequired from '../components/PasswordRequired';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface PasswordData {
	password: string,
	confirmPassword: string,
	verificationCode?: string,
	resetPasswordToken?: string
}

interface eventType {
	target: {
		value: string
	}
}

const validate = (values: { password: string, confirmPassword: string }) => {
	const errors: { password?: string, confirmPassword?: string } = {};
	const requiredFields = [
		"password",
		"confirmPassword",
	];
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = "Required";
		}
	});
	return errors;
};

interface Props {
	onResetPassword: (data: PasswordData) => void
}
const useStyles = makeStyles((theme: Theme) =>
        createStyles({

            textWhite: {
                color: "#FFFFFF",
            },
            textCenter: {
                textAlign: "center",
            },
            mt10: {
                marginTop: 10,
            },
            mr30: {
                marginRight: 30,
            },
            loginForm: {
                padding: "60px 15px 50px",
                float: "left",
                width: "100%",
            },
            margin0: {
                margin: 0,
            },
            titleMain: {
                fontSize: 26,
                lineHeight: "34px",
                textAlign: "center",
                margin: 0,
                float: "right",
            },
            mr10: {
                marginRight: 10,
            },
        }),
);
const ResetPassword: React.FC<Props & InjectedFormProps<PasswordData, Props>> = (props) => {
    const classes = useStyles();
    const { handleSubmit, pristine, submitting, onResetPassword, invalid } = props;
    const [oneLetter, setOneLetter] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const [wrongPassword, setWrongPassword] = React.useState(false);
    const onPassWordChange = (e: eventType): void => {
        const val = e.target.value;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);

        val ?
                ((!PWD_ONE_LETTER_REGEX.test(val) || !PWD_ONE_CAPITAL_LETTER_REGEX.test(val) || !PWD_ONE_NUMBER_REGEX.test(val) || !PWD_MIN_EIGHT_CHAR_REGEX.test(val) || !PWD_ONE_SPECIAL_CHAR.test(val))
                        ? setWrongPassword(true) :
                        setWrongPassword(false))
                :
                setWrongPassword(true);
    };

    return (
            <form className={classes.loginForm + " " + classes.margin0}
                  onSubmit={handleSubmit(onResetPassword)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field name="password" fullWidth={true} component={TextField}
                               placeholder="Create a Password"
                               type="password" onChange={(e: eventType) => onPassWordChange(e)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Field name="confirmPassword" fullWidth={true} component={TextField}
                               placeholder="Confirm Your Password"
                               type="password"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                                fullWidth={true}
                                type="submit"
                                label="Set Password"
                                variant="contained"
                                color="primary"
                                disabled={invalid || pristine || submitting || wrongPassword}
                                loading={submitting}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordRequired oneLetter={oneLetter} oneNumber={oneNumber}
                                          oneCapitalLetter={oneCapitalLetter} eightCharacter={eightCharacter}
                                          oneSpecialChar={oneSpecialChar}/>
                    </Grid>
			</Grid>
		</form>
);
}

export default reduxForm<PasswordData, Props>({
	form: "resetPasswordForm",
	validate
})(ResetPassword);
