import React from "react";
import { isIliaApp, isLongForm, isWorksheet } from "../Utilities";
import {Label} from "../../../../../../components/formInputs";
import { NOTA } from "../MedicalAndFinancial/component/MedicalPageQuestions";
import { FieldAttributes } from "formik/dist/Field";
import { Field } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const PIQ = 'Proposed_Insured_Questions';

const commonOptions = {
    type: "radio",
    default: false,
    onYes: false,
    additionalRemarks: true,
    showRemarks: false,
    notShowHrLine: true,
    show: true
}

const additionalFieldOptionsListWithSimilarValues = {
    60: [
        {
            value: "Been offered “free insurance”, a cash payment, or some other promised benefit as an incentive",
            text: "Been offered “free insurance”, a cash payment, or some other promised benefit as an incentive",

        },
        {
            value: "Discussed selling this life insurance policy",
            text: "Discussed selling this life insurance policy",
        },
        {
            value: "Had an evaluation to determine the insured’s life expectancy (how long the insured will live)",
            text: "Had an evaluation to determine the insured’s life expectancy (how long the insured will live)",
        },
        {
            value: "Discussed changing ownership or beneficiaries once this policy is issued",
            text: "Discussed changing ownership or beneficiaries once this policy is issued",
        },
        {
            value: NOTA,
            text: NOTA,
        },
    ],
    61: [
        {
            value: "I currently drive",
            text: "I currently drive",
        },
        {
            value: "I do not currently drive due to a driver’s license suspension or revocation",
            text: "I do not currently drive due to a driver’s license suspension or revocation",
        },
        {
            value: "I do not currently drive due to a personal choice",
            text: "I do not currently drive due to a personal choice",
        },
        {
            value: "I do not currently drive and have never driven",
            text: "I do not currently drive and have never driven",
        },
        {
            value: "I do not currently drive due to a medical condition or concern",
            text: "I do not currently drive due to a medical condition or concern",
        },
        {
            value: "I do not currently drive because",
            text: "I do not currently drive because",
            showDetailsBox: true,
            label: "Specify Reason",
            placeholder: "Specify Reason",
        },
    ],
    62: [
        {
            value: "I drive myself",
            text: "I drive myself",
        },
        {
            value: "Bus, train, or other public transportation",
            text: "Bus, train, or other public transportation",
        },
        {
            value: "Someone else drives me",
            text: "Someone else drives me",
        },
        {
            value: "I work out of my home or do not work outside of the home",
            text: "I work out of my home or do not work outside of the home",
        },
        {
            value: "Uber, Lyft, taxi, or other hired transportation",
            text: "Uber, Lyft, taxi, or other hired transportation",
        },
        {
            value: "Other",
            text: "Other",
            showDetailsBox: true,
            label: "Provide Detail",
            placeholder: "Provide Detail",
        },
    ],
    63: [
        {
            value: "Careless or reckless driving",
            text: "Careless or reckless driving",
        },
        {
            value: "License suspended or revoked",
            text: "License suspended or revoked",
        },
        {
            value: "Driving under the influence",
            text: "Driving under the influence",
        },
        {
            value: "Speeding",
            text: "Speeding",
        },
        {
            value: "Other moving/driving violations, please specify:",
            text: "Other moving/driving violations, please specify:",
            showDetailsBox: true,
            label: "Provide Detail",
            placeholder: "Provide Detail",
        },
        {
            value: NOTA,
            text: "I have not had any moving/driving violations",
        },
    ],
    // 64: [
    //     {
    //         value: "Morning",
    //         text: "Morning",
    //     },
    //     {
    //         value: "Afternoon",
    //         text: "Afternoon",
    //     },
    //     {
    //         value: "Evening",
    //         text: "Evening",
    //     }
    // ],
    // 65: [
    //     {
    //         value: "Hearing Impaired",
    //         text: "Hearing Impaired",
    //     },
    //     {
    //         value: "Interpreter Needed, Language:",
    //         text: "Interpreter Needed, Language:",
    //         showDetailsBox: true,
    //         label: "Provide Detail",
    //         placeholder: "Provide Detail",
    //     },
    //     {
    //         value: "Other",
    //         text: "Other",
    //         showDetailsBox: true,
    //         label: "Provide Detail",
    //         placeholder: "Provide Detail",
    //     }
    // ],
};

const additionalFieldOptionsListForStagingAndDev = {
    32: [
        {value: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", text: "Aviation"},
        {value: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", text: "Foreign Travel"},
        {value: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", text: "Avocation"}
    ],
    ...additionalFieldOptionsListWithSimilarValues
};

const additionalFieldOptionsList = {
    development: additionalFieldOptionsListForStagingAndDev,
    production: {
        32: [
            { value: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", text: "Aviation" },
            { value: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", text: "Foreign Travel" },
            { value: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", text: "Avocation" },
        ],
        ...additionalFieldOptionsListWithSimilarValues,
    }
};

const environment = process.env.REACT_APP_ENV === "production" ? "production" : "development";

const additionalFieldOptions = additionalFieldOptionsList[environment];

export const getInsuredQuestions = (params: { offerType: string, isCorporation: boolean, isAssociation: boolean, user: any }) => {
    const {offerType, isCorporation, isAssociation, user} = params;
    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "1",
            name: "existPolicies",
            label: "Any existing Kai-Zen Plan policies with NIW?",
            label2: "Any existing Kai-Zen Plan policies with NIW?",
            notShowHrLine: false,
            additionalFieldName: 'noOfPolicies',
            additionalFieldType: 'selectBox',
            additionalFieldRequired: true,
            additionalFieldLabel: 'Number of Policies',
            additionalHelperText: "How many policies do you have with us?",
            show: user && user.role === 6 && user.client && user.client.companyId ? false : true,
            position: 1
        },
        {
            ...commonOptions,
            key: "2",
            name: "Insured_Questions-2",
            label: "What is the purpose of the Life Insurance / Death Benefit coverage?",
            type: "multi-radio",
            notShowHrLine: false,
            additionalFieldType: 'multi-radio',
            additionalFieldName: "coverage",
            show: (((offerType === 'gi' || offerType === 'si') && isCorporation) || (offerType === 'gi' && !isAssociation)) ? false : true,
            position: 3
        },
        {
            ...commonOptions,
            key: "3",
            name: "Proposed_Insured_Questions-3",
            label: "Have you ever applied for life, health, or disability insurance or reinstatement of same, which was declined, postponed, rated or modified in any way?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-101",
            show: isIliaApp(offerType),
            position: 25
        },
        {
            ...commonOptions,
            key: "4",
            name: "Proposed_Insured_Questions-4",
            label: "Within the past 12 months have you applied for or do you have any applications pending for life or disability insurance?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-102",
            show: isIliaApp(offerType),
            position: 26
        },
        {
            ...commonOptions,
            key: "5",
            name: "Proposed_Insured_Questions-5",
            label: "Is the policy or rider being applied for intended to replace any inforce life insurance or annuity contract(s) including long term care insurance, disability income insurance or riders? Replacement includes surrender, lapse, reissue, conversion, reduction in coverage, premium or period of coverage of any life, disability income or annuity contract.",
            notShowHrLine: false,
            additionalRemarks: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-103",
            show: isIliaApp(offerType),
            position: 27
        },
        {
            ...commonOptions,
            key: "6",
            name: "Insured_Questions-6",
            label: "Amount of life insurance currently applied for, other than the amount being applied for on this application?",
            type: "textField",
            notShowHrLine: false,
            additionalFieldType: 'numberTextField',
            additionalFieldName: "liAmount",
            additionalCheckBoxName: "liAmountNone",
            show: (offerType === 'full' || (offerType === 'gi' && !isAssociation)) ? false : true,
            position: 8,
        },
        {
            ...commonOptions,
            key: "7",
            name: "isInforceLife",
            label: "Are you (insured or owner) considering using funds from an inforce life or annuity contract to fund the policy or rider being applied for?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "inforceLifeDesc",
            show: isIliaApp(offerType),
            position: 29
        },
        {
            ...commonOptions,
            key: "7",
            name: "isInforceLife",
            label: "Is the Proposed Insured or Owner considering using funds from an inforce life or annuity contract to fund the policy or rider being applied for? (If yes, replacement forms must be provided)",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "inforceLifeDesc",
            //show: (isIliaApp(offerType) || (offerType === 'gi' && !isAssociation)) ? false : true,
            show: (isIliaApp(offerType) || (offerType === 'gi' && !isAssociation)) ? false : true,
            position: 7
        },
        {
            ...commonOptions,
            key: "8",
            name: "inforcePolicy",
            label: "Do you have any in-force life insurance or annuity contracts including long term care insurance or riders? (If yes, provide details)",
            notShowHrLine: false,
            additionalFieldName: 'noOfInforcePolicies',
            additionalFieldType: 'selectBox',
            additionalFieldRequired: true,
            additionalFieldLabel: 'Number of Policies',
            additionalHelperLabel: "How many policies do you have with us?",
            show: (offerType === 'gi' && !isAssociation) || isLongForm(offerType) ? false : true,
            position: 31
        },
        {
            ...commonOptions,
            key: "10",
            name: "Proposed_Insured_Questions-10",
            label: "Are you actively at work at the customary workplace, doing the usual duties and functions required by the position during the normal work hours and weekly period?",
            additionalRemarks: false,
            // showRemarks: false,
            additionalFieldName: 'Remark-10-Remarks',
            additionalFieldType: 'textfield',
            additionalFieldRequired: true,
            additionalFieldLabel: 'Provide Details',
            show: isIliaApp(offerType),
            position: 32,
            notShowHrLine: false
        },
        {
            ...commonOptions,
            key: "12",
            name: "Proposed_Insured_Questions-12",
            label: "Within the past 5 years have you worked less than full time, received or applied for disability or worker's compensation?",
            additionalFieldName: 'Remark-110',
            additionalFieldType: 'textField',
            additionalFieldRequired: true,
            additionalFieldLabel: 'Provide Details',
            show: isIliaApp(offerType),
            position: 33,
            notShowHrLine: false
        },
        /*{
            ...commonOptions,
            key: "32",
            name: "Proposed_Insured_Questions-32",
            label: "Do you currently participate in any racing, scuba diving, aerial sports, piloting an aircraft, mountain climbing, BASE or bungee jumping, cave exploration, or do you plan on traveling or living outside of the United States in the next 2 years?",
            additionalRemarks: false,
            additionalFieldCaption: "Check if applicable",
            additionalFieldName: 'Multi_Form-32',
            additionalFieldType: 'checkbox',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalFieldOptions: additionalFieldOptions[32],
            show: isIliaApp(offerType) ? true : false
        },*/
        {
            ...commonOptions,
            key: "20",
            name: "Proposed_Insured_Questions-20",
            label: "Within the last 10 years, have you been convicted of, or are you currently charged with, a felony or misdemeanor, or are you currently on parole or probation?",
            additionalRemarks: false,
            additionalFieldName: 'Remark-105',
            additionalFieldType: 'textField',
            additionalFieldRequired: true,
            additionalFieldLabel: 'Provide Details',
            show: isIliaApp(offerType),
            position: 34,
            notShowHrLine: false
        },
        {
            ...commonOptions,
            key: "30",
            name: "Proposed_Insured_Questions-30",
            label: "During the last 5 years have you plead guilty or been convicted of any moving vehicle violations or DUI or have you had your driver's license suspended?",
            additionalRemarks: false,
            additionalFieldName: 'Remark-104',
            additionalFieldType: 'textField',
            additionalFieldRequired: true,
            additionalFieldLabel: 'Provide Details',
            show: isIliaApp(offerType),
            position: 35,
            notShowHrLine: false
        },
        {
            ...commonOptions,
            key: "21",
            name: "Proposed_Insured_Questions-21",
            label: "In the past 10 years have you used cocaine, heroin, or any other illicit drug or controlled substance, been advised by a physician to discontinue or reduce alcohol or drug intake, used drugs not prescribed by a physician, or been a member of a support group such as NA or AA?",
            additionalRemarks: false,
            additionalFieldName: 'Remark-130',
            additionalFieldType: 'textField',
            additionalFieldRequired: true,
            additionalFieldLabel: 'Provide Details',
            show: isIliaApp(offerType),
            position: 36,
            notShowHrLine: false
        },
        {
            ...commonOptions,
            key: "9",
            name: "Insured_Questions-9",
            label: "Amount of life insurance currently in force?",
            type: "textField",
            additionalFieldType: 'numberTextField',
            additionalFieldName: "inforceAmount",
            additionalCheckBoxName: "inforceAmountNone",
            show: (offerType === 'full' || isIliaApp(offerType) || (offerType === 'gi' && !isAssociation)) ? false : true,
            position: 8,
            notShowHrLine: false
        },
        /*{
            ...commonOptions,
            key: "21",
            name: "Proposed_Insured_Questions-21",
            label: "In the past 10 years have you used cocaine, heroin, or any other illicit drug or controlled substance, been advised by a physician to discontinue or reduce alcohol or drug intake, used drugs not prescribed by a physician, or been a member of a support group such as NA or AA?",
            additionalRemarks: false,
            additionalFieldName: 'Remark-130',
            additionalFieldType: 'textField',
            additionalFieldRequired: true,
            additionalFieldLabel: 'Provide Details',
            show: isIliaApp(offerType),
            position: 38
        },*/
            //Allianz NIWC-2335
        {
            ...commonOptions,
            key: "55",
            name: "Proposed_Insured_Questions-55",
            label: "Have you ever been charged an extra premium, been declined for coverage, or had coverage canceled for a life insurance policy with another company?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-55",
            show: isLongForm(offerType),
            position: 2
        },
        {
            ...commonOptions,
            key: "56",
            name: "Proposed_Insured_Questions-56",
            label: "Do both the proposed insured and the proposed owner(s) believe that this life insurance policy being applied for will meet the insurance needs and objectives of each person?",
            notShowHrLine: false,
            //additionalFieldType: 'textField',
            //additionalFieldName: "Proposed_Insured_Questions-55_desc",
            show: (isLongForm(offerType) || isWorksheet(offerType)),
            position: 4
        },
        {
            ...commonOptions,
            key: "57",
            name: "Proposed_Insured_Questions-57",
            label: "Did the agent discuss with both the proposed owner(s) and the proposed insured the current life insurance policies and other assets of each person prior to the decision to purchase this life insurance policy?",
            notShowHrLine: false,
            //additionalFieldType: 'textField',
            //additionalFieldName: "Proposed_Insured_Questions-55_desc",
            show: (isLongForm(offerType) || isWorksheet(offerType)),
            position: 5
        },
        {
            ...commonOptions,
            key: "58",
            name: "Proposed_Insured_Questions-58",
            label: "Does the proposed owner(s) feel that sufficient liquid assets are available to them for living expenses and emergencies in addition to the money allocated to pay the life insurance premiums?",
            notShowHrLine: false,
            //additionalFieldType: 'textField',
            //additionalFieldName: "Proposed_Insured_Questions-55_desc",
            show: (isLongForm(offerType) || isWorksheet(offerType)),
            position: 6
        },
        /*{
            ...commonOptions,
            key: "59",
            name: "Proposed_Insured_Questions-59",
            label: "Is the Proposed Insured or Owner considering using funds from an inforce life or annuity contract to fund the policy or rider being applied for? (If yes, replacement forms must be provided)",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Proposed_Insured_Questions-59_desc",
            show: isLongForm(offerType) ? true : false,
            position: 7
        },*/
        {
            ...commonOptions,
            key: "8",
            name: "inforcePolicy",
            label: "Do you have any in-force life insurance or annuity contracts including long term care insurance or riders? (If yes, provide details)",
            notShowHrLine: false,
            additionalFieldName: 'noOfInforcePolicies',
            additionalFieldType: 'selectBox',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            show: isLongForm(offerType),
            position: 8,
            resetFields: [{
                name: "inForcePolicies",
                value: []
            }, {
                name: "noOfInforcePolicies",
                value: ""
            }]
        },
        {
            ...commonOptions,
            key: "60",
            name: "Proposed_Insured_Questions-60XC",
            label: "Please indicate which of the following discussions have been had with the proposed insured or the proposed owner(s) of this life insurance policy. Check ALL that apply.",
            notShowHrLine: false,
            type: "checkBoxGroup",
            additionalFieldType: 'checkBoxGroup',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            additionalSubFieldLabel: "Provide details for any discussions indicated above",
            additionalSubFieldType: "textField",
            additionalSubFieldName: "Proposed_Insured_Questions-60XT_desc",
            show: (isLongForm(offerType) || isWorksheet(offerType)),
            additionalFieldOptions: additionalFieldOptions[60],
            position: 9
        },
        {
            ...commonOptions,
            key: "61",
            name: "Proposed_Insured_Questions-61XR",
            label: "Which of the following best describes your current driving habits?",
            notShowHrLine: false,
            type: "radioBoxGroup",
            additionalFieldType: 'radioBoxGroup',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            show: isLongForm(offerType),
            additionalFieldOptions: additionalFieldOptions[61],
            position: 10
        },
        {
            ...commonOptions,
            key: "62",
            name: "Proposed_Insured_Questions-62XR",
            label: "How do you get to work?",
            notShowHrLine: false,
            type: "radioBoxGroup",
            additionalFieldType: 'radioBoxGroup',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            show: isLongForm(offerType),
            additionalFieldOptions: additionalFieldOptions[62],
            position: 11
        },
        {
            ...commonOptions,
            key: "63",
            name: "Proposed_Insured_Questions-63XC",
            label: "Over the last 5 years, please indicate which of the following moving/driving violations you’ve plead guilty to or been convicted of (Check ALL that apply).",
            notShowHrLine: false,
            type: "checkBoxGroup",
            additionalFieldType: 'checkBoxGroup',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            show: isLongForm(offerType),
            additionalFieldOptions: additionalFieldOptions[63],
            position: 12
        },
        {
            ...commonOptions,
            key: "64",
            name: "Proposed_Insured_Questions-64",
            label: "Have you been charged with or convicted of any crimes?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 13,
            resetFields: [{
                name: "Proposed_Insured_Questions-71XRFM",
                value: null
            },{
                name: "Proposed_Insured_Questions-72XRFM",
                value: null
            },{
                name: "Proposed_Insured_Questions-73XRFM",
                value: null
            },{
                name: "Proposed_Insured_Questions-74XRFM",
                value: null
            }]
        },
        {
            ...commonOptions,
            key: "75",
            name: "Proposed_Insured_Questions-75",
            label: "Within the last 5 years have you piloted or been a student pilot, or within the next 2 years do you plan to pilot any type of aircraft?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 14
        },

        {
            ...commonOptions,
            key: "65",
            name: "Proposed_Insured_Questions-65",
            label: "Over the last 5 years have you participated in or do you have plans to participate in within the next 2 years, automobile racing, aircraft racing, dirt bike racing, dune buggy racing, kart racing, motor boat racing, motorcycle racing, powered vehicle racing or snowmobile racing?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 15
        },
        {
            ...commonOptions,
            key: "66",
            name: "Proposed_Insured_Questions-66",
            label: "Over the last 5 years have you participated in or do you have plans to participate in within the next 2 years, skin or SCUBA diving?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 16
        },
        {
            ...commonOptions,
            key: "67",
            name: "Proposed_Insured_Questions-67",
            label: "Over the last 5 years have you participated in or do you have plans to participate in within the next 2 years, sky diving or sky surfing?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 17
        },
        {
            ...commonOptions,
            key: "68",
            name: "Proposed_Insured_Questions-68",
            label: "Over the last 5 years have you participated in or do you have plans to participate in within the next 2 years, mountain climbing or rock climbing (excluding indoor rock climbing)?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 18
        },
        {
            ...commonOptions,
            key: "69",
            name: "Proposed_Insured_Questions-69",
            label: "Over the last 5 years have you participated in or do you have plans to participate in within the next 2 years, BASE jumping, street luging, zorbing, canyoneering, white water rafting, ultimate fighting, cage fighting, hang gliding, parasailing, rodeos, or bungee jumping?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 19
        },
        {
            ...commonOptions,
            key: "70",
            name: "Proposed_Insured_Questions-70",
            label: "Do you have plans to travel outside of the U.S. or Canada in the next 2 years?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 20
        },
        {
            ...commonOptions,
            key: "83",
            name: "Proposed_Insured_Questions-83",
            label: "Which of the following best describes your exercise routine of the last 12 months?",
            notShowHrLine: false,
            additionalFieldName: "Remark-83",
            show: isLongForm(offerType),
            position: 21,
            type: "",
            additionalRemarks:false
        },
        // {
        //     ...commonOptions,
        //     key: "84",
        //     name: "Proposed_Insured_Questions-84XC",
        //     label: "Best Time to Call for Client Interview",
        //     notShowHrLine: false,
        //     type: "checkBoxGroup",
        //     additionalFieldType: 'checkBoxGroup',
        //     additionalFieldRequired: true,
        //     additionalFieldLabel: '',
        //     additionalHelperLabel: "",
        //     show: isWorksheet(offerType),
        //     additionalFieldOptions: additionalFieldOptions[64],
        //     position: 22
        // },
        // {
        //     ...commonOptions,
        //     key: "85",
        //     name: "Proposed_Insured_Questions-85XC",
        //     label: "Special Requests",
        //     notShowHrLine: false,
        //     type: "checkBoxGroup",
        //     additionalFieldType: 'checkBoxGroup',
        //     additionalFieldRequired: true,
        //     additionalFieldLabel: '',
        //     additionalHelperLabel: "",
        //     show: isWorksheet(offerType),
        //     additionalFieldOptions: additionalFieldOptions[65],
        //     position: 23
        // },
        // {
        //     ...commonOptions,
        //     key: "86",
        //     name: "Proposed_Insured_Questions-86",
        //     label: "If juvenile, provide name of the parent or legal guardian who will complete the client interview",
        //     notShowHrLine: true,
        //     type: "checkBoxGroup",
        //     additionalFieldType: 'checkBoxGroup',
        //     additionalFieldRequired: false,
        //     additionalFieldLabel: '',
        //     additionalHelperLabel: "",
        //     additionalSubFieldLabel: "Parent or Legal Guardian Name",
        //     additionalSubFieldType: "textField",
        //     additionalSubFieldName: "Proposed_Insured_Questions-86XT_desc",
        //     show: isWorksheet(offerType),
        //     position: 24
        // },
        // {
        //     ...commonOptions,
        //     key: "87",
        //     name: "Proposed_Insured_Questions-87",
        //     label: "",
        //     notShowHrLine: false,
        //     type: "checkBoxGroup",
        //     additionalFieldType: 'checkBoxGroup',
        //     additionalFieldRequired: false,
        //     additionalFieldLabel: '',
        //     additionalHelperLabel: "",
        //     additionalSubFieldLabel: "Relationship to Juvenile",
        //     additionalSubFieldType: "textField",
        //     additionalSubFieldName: "Proposed_Insured_Questions-87XT_desc",
        //     show: isWorksheet(offerType),
        //     position: 25
        // }
    ]
    return insuredQuestions;
}

export const proposedInsuredQuestion64:any = (params: { offerType: string }) => {
    const {offerType} = params;
    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "71",
            name: "Proposed_Insured_Questions-71XRFM",
            label: "Any criminal charges pending?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 1,
            resetFields: [{
                name: "Proposed_Insured_Questions-71XRfmq",
                value: {}
            },{
                name: "Proposed_Insured_Questions-71XTinv",
                value: ""
            },{
                name: "Proposed_Insured_Questions-71XTpp",
                value: ""
            },{
                name: "Proposed_Insured_Questions-71XTsi",
                value: ""
            }]
        },
        {
            ...commonOptions,
            key: "72",
            name: "Proposed_Insured_Questions-72XRFM",
            label: "History of criminal conviction(s)?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 2,
            resetFields: [{
                name: "Proposed_Insured_Questions-72XRfmq",
                value: {}
            },{
                name: "Proposed_Insured_Questions-72XTinv",
                value: ""
            },{
                name: "Proposed_Insured_Questions-72XTpp",
                value: ""
            },{
                name: "Proposed_Insured_Questions-72XTsi",
                value: ""
            }]
        },
        {
            ...commonOptions,
            key: "73",
            name: "Proposed_Insured_Questions-73XRFM",
            label: "Currently on probation, parole, or incarceration?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 3,
            resetFields: [{
                name: "Proposed_Insured_Questions-73XRfmq",
                value: {}
            },{
                name: "Proposed_Insured_Questions-73XTinv",
                value: ""
            },{
                name: "Proposed_Insured_Questions-73XTpp",
                value: ""
            },{
                name: "Proposed_Insured_Questions-73XTsi",
                value: ""
            }]
        },
        {
            ...commonOptions,
            key: "74",
            name: "Proposed_Insured_Questions-74XRFM",
            label: "Less than one year since released from probation?",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 4,
            resetFields: [{
                name: "Proposed_Insured_Questions-74XRfmq",
                value: {}
            },{
                name: "Proposed_Insured_Questions-74XTinv",
                value: ""
            },{
                name: "Proposed_Insured_Questions-74XTpp",
                value: ""
            },{
                name: "Proposed_Insured_Questions-74XTsi",
                value: ""
            }]
        }
    ]
    return insuredQuestions;
}

export const proposedInsuredQuestion75:any = (params: { offerType: string }) => {
    const { offerType } = params;
    let additionalField:any = {
        0:[
            {
                value: "Commercial pilot",
                text: "Commercial pilot"
            },
            {
                value: "Airline transport",
                text: "Airline transport"
            },
            {
                value: "Private pilot",
                text: "Private pilot"
            },
            {
                value: "Visual Flight Rules (VFR) Rating",
                text: "Visual Flight Rules (VFR) Rating"
            },
            {
                value: "Student pilot",
                text: "Student pilot"
            },
            {
                value: "Instrument Flight Rules (IFR) Rating",
                text: "Instrument Flight Rules (IFR) Rating"
            },
            {
                value: "Recreational",
                text: "Recreational"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        1:[
            {
                value: "Private/pleasure",
                text: "Private/pleasure"
            },
            {
                value: "Crop spraying",
                text: "Crop spraying"
            },
            {
                value: "Commercial",
                text: "Commercial"
            },
            {
                value: "Aerial photography",
                text: "Aerial photography"
            },
            {
                value: "Military",
                text: "Military"
            },
            {
                value: "Stunt flying",
                text: "Stunt flying"
            },
            {
                value: "Test pilot",
                text: "Test pilot"
            },
            {
                value: "Aerobatic",
                text: "Aerobatic"
            },
            {
                value: "Survey work",
                text: "Survey work"
            },
            {
                value: "Flight instruction",
                text: "Flight instruction"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        2:[
            {

                value: "Single-Engine",
                text: "Single-Engine"
            },
            {

                value: "Kitbuilts or Homebuilts",
                text: "Kitbuilts or Homebuilts"
            },
            {

                value: "Business Jets",
                text: "Business Jets"
            },
            {

                value: "Powered Parachutes",
                text: "Powered Parachutes"
            },
            {

                value: "Amphibians",
                text: "Amphibians"
            },
            {
                value: "Balloons",
                text: "Balloons"
            },
            {
                value: "Light Sport Aircraft",
                text: "Light Sport Aircraft"
            },
            {
                value: "Commercial Aircraft",
                text: "Commercial Aircraft"
            },
            {
                value: "Multi-Engine",
                text: "Multi-Engine"
            },{
                value: "Military Aircraft",
                text: "Military Aircraft"
            },
            {
                value: "Turboprops",
                text: "Turboprops"
            },
            {
                value: "Antique/Military/High Performance",
                text: "Antique/Military/High Performance"
            },
            {
                value: "Helicopter",
                text: "Helicopter"
            },
            {
                value: "Airships (Blimps & Dirigibles)",
                text: "Airships (Blimps & Dirigibles)"
            },
            {
                value: "Ultralights",
                text: "Ultralights"
            },
            {
                value: "Floatplanes or Seaplanes",
                text: "Floatplanes or Seaplanes"
            },
            {
                value: "Gliders",
                text: "Gliders"
            },
            {
                value: "Gyroplanes",
                text: "Gyroplanes"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        3:[
            {
                value: "Aviation violation",
                text: "Aviation violation"
            },
            {
                value: "Aviation accident",
                text: "Aviation accident"
            },
            {
                value: "License suspension or revocation",
                text: "License suspension or revocation"
            },
            {
                value: "Been grounded",
                text: "Been grounded"
            },
            {
                value: NOTA,
                text: NOTA
            }
        ]
    }

    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-75XCdh",
            label: <Label label={"Type of certification held (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[0],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-75XCpf",
            label: <Label label={"Purpose(s) of flying (Check ALL that apply):"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[1],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-75XCaf",
            label: <Label label={"Type of aircraft(s) flown (Check ALL that apply):"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[2],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-75XCav",
            label: <Label label={"Which of the following have you experienced? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[3],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            key: "76",
            name: "Proposed_Insured_Questions-76",
            label: "Do you anticipate that your future participation will be different?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-76",
            show: isLongForm(offerType),
            position: 6
        }
    ]
    return insuredQuestions;
}

export const proposedInsuredQuestion65Cpa:any = (params: { offerType: string }) => {
    const { offerType } = params;
    let additionalField:any = {
        0: [{
                value: "Crash / Demolition",
                text: "Crash / Demolition"
            },
            {
                value: "Championship/Indy car",
                text: "Championship/Indy car"
            },
            {
                value: "Derby",
                text: "Derby"
            },
            {
                value: "Drag",
                text: "Drag"
            },
            {
                value: "Dune/Sand",
                text: "Dune/Sand"
            },
            {
                value: "Stock",
                text: "Stock"
            },
            {
                value: "Sports car",
                text: "Sports car"
            },
            {
                value: "Solo events",
                text: "Solo events"
            }],
        1: [{
                value: "Kart",
                text: "Kart:"
            },
            {
                value: "FKE",
                text: "FKE"
            },
            {
                value: "Enduro and Sprint",
                text: "Enduro and Sprint"
            }],
        2:  [{
                value: "Midget",
                text: "Midget:"
            },
            {
                value: "Full",
                text: "Full"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }],
        3:  [{
                value: "Modified",
                text: "Modified:"
            },
            {
                value: "Modified, Super and Limited",
                text: "Modified, Super and Limited"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }],
        4:  [{
                value: "Sprint",
                text: "Sprint:"
            },
            {
                value: "Modified, Super",
                text: "Modified, Super"
            },
            {
                value: "Compact",
                text: "Compact"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }]
    }
    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCpa1",
            label: <Label label={"What type of racing do you participate in? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[0],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCpa2",
            label: <Label label={""} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[1],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCpa3",
            label: <Label label={""} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[2],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCpa4",
            label: <Label label={""} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[3],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCpa5",
            label: <Label label={""} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[4],
            show: isLongForm(offerType),
        }
    ];
    return insuredQuestions;
}

export const proposedInsuredQuestion65TopQues:any = (params: { offerType: string }) => {
    const { offerType } = params;

    let additionalField:any = {
        0: [
            {
                value: "Automobile",
                text: "Automobile"
            },
            {
                value: "Motorcycle",
                text: "Motorcycle"
            },
            {
                value: "Kart",
                text: "Kart"
            },
            {
                value: "Motor boat",
                text: "Motor boat"
            },
            {
                value: "Aircraft",
                text: "Aircraft"
            },
            {
                value: "Snowmobile",
                text: "Snowmobile"
            },
            {
                value: "Dune buggy",
                text: "Dune buggy"
            },

            {
                value: "Dirt bike",
                text: "Dirt bike"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        2: [
            {
                value: "Craftsman Truck Series",
                text: "Craftsman Truck Series"
            },
            {
                value: "NASCAR",
                text: "NASCAR"
            },
            {
                value: "World of Outlaws",
                text: "World of Outlaws"
            },
            {
                value: "Baby Grand National",
                text: "Baby Grand National"
            },
            {
                value: "Nationwide Series",
                text: "Nationwide Series"
            },
            {
                value: "Sprint Cup",
                text: "Sprint Cup"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        3: [
            {
                value: "Drag strip",
                text: "Drag strip"
            },
            {
                value: "Dirt",
                text: "Dirt"
            },
            {
                value: "Paved track",
                text: "Paved track"
            },
            {
                value: "Ice",
                text: "Ice"
            },
            {
                value: "Off road/desert",
                text: "Off road/desert"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        4: [
            {
                value: "ARCA (Automobile Racing Club of America)",
                text: "ARCA (Automobile Racing Club of America)"
            },
            {
                value: "IMSA (International Motor Sports Association)",
                text: "IMSA (International Motor Sports Association)"
            },
            {
                value: "SCCA (Sports Car Club of America)",
                text: "SCCA (Sports Car Club of America)"
            },
            {
                value: "SVRA (Sportscar Vintage Association)",
                text: "SVRA (Sportscar Vintage Association)"
            },
            {
                value: "ASA (American Speed Association)",
                text: "ASA (American Speed Association)"
            },
            {
                value: "NASCAR (National Association of Stock Car Auto Racing)",
                text: "NASCAR (National Association of Stock Car Auto Racing)"
            },
            {
                value: "NHRA (National Hot Rod Association)",
                text: "NHRA (National Hot Rod Association)"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            },
            {
                value: NOTA,
                text: "I am not affiliated with any racing organizations"
            },
        ]
    }

    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCveh",
            label: <Label label={"What type of vehicle(s) is driven? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[0],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCev",
            label: <Label label={"What type of event(s) do you participate in? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[2],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCav",
            label: <Label label={"What type of course(s) do you race on? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[3],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-65XCaff",
            label: <Label label={"Are you affiliated with any of the following racing organizations? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[4],
            show: isLongForm(offerType),
        },
    ]
    return insuredQuestions;
}

export const proposedInsuredQuestion66TopQues:any = (params: { offerType: string }) => {
    const { offerType } = params;

    let additionalField:any = {
        0: [
            {
                value: "Basic",
                text: "Basic"
            },
            {
                value: "Dive instructor",
                text: "Dive instructor"
            },
            {
                value: "Rescue diver",
                text: "Rescue diver"
            },
            {
                value: "Master diver",
                text: "Master diver"
            },
            {
                value: "Open water",
                text: "Open water"
            },
            {
                value: "Dive guide",
                text: "Dive guide"
            },
            {
                value: "Advanced open water",
                text: "Advanced open water"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        1: [
            {
                value: "PADI - Professional Association of Diving Instructors",
                text: "PADI - Professional Association of Diving Instructors"
            },
            {
                value: "SSI – Scuba Schools International",
                text: "SSI – Scuba Schools International"
            },
            {
                value: "NAUI – National Association of Underwater Instructors",
                text: "NAUI – National Association of Underwater Instructors"
            },
            {
                value: "CMAS – Confederation of Mondiale des Activites Subaquatiques/The World Underwater Federation",
                text: "CMAS – Confederation of Mondiale des Activites Subaquatiques/The World Underwater Federation"
            },
            {
                value: "BSAC – British Sub Aqua Club",
                text: "BSAC – British Sub Aqua Club"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        2: [
            {
                value: "Recreation",
                text: "Recreation"
            },
            {
                value: "Wreck/salvage/retrieval",
                text: "Wreck/salvage/retrieval"
            },
            {
                value: "Hunting",
                text: "Hunting"
            },
            {
                value: "Photography",
                text: "Photography"
            },
            {
                value: "Instruction",
                text: "Instruction"
            },
            {
                value: "Commercial",
                text: "Commercial"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            },
        ],
        3: [
            {
                value: "Cave",
                text: "Cave"
            },
            {
                value: "Deep",
                text: "Deep"
            },
            {
                value: "Wreck",
                text: "Wreck"
            },
            {
                value: "Depth record attempts",
                text: "Depth record attempts"
            },
            {
                value: "Nitrox",
                text: "Nitrox"
            },
            {
                value: "Free diving",
                text: "Free diving"
            },
            {
                value: "Ice",
                text: "Ice"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            },
            {
                value: NOTA,
                text: "I do not participate in specialty/technical diving."
            },
        ],
        4: [
            {
                value: "With someone",
                text: "With someone"
            },
            {
                value: "Both",
                text: "Both"
            },
            {
                value: "Alone",
                text: "Alone"
            }
        ],
        5: [
            {
                value: "Lakes",
                text: "Lakes"
            },
            {
                value: "Coastal waters",
                text: "Coastal waters"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            },
        ],
        6: [
            {
                value: "Less than or equal to 75 ft.",
                text: "Less than or equal to 75 ft."
            },
            {
                value: "76-100 ft.",
                text: "76-100 ft."
            },
            {
                value: "101-130 ft.",
                text: "101-130 ft."
            },
            {
                value: "131-150 ft.",
                text: "131-150 ft."
            },
            {
                value: "Greater than 150 ft.",
                text: "Greater than 150 ft."
            }
        ],
        7: [
            {
                value: "Decompression Illness",
                text: "Decompression Illness"
            },
            {
                value: "Decompression Sickness (CDs)",
                text: "Decompression Sickness (CDs)"
            },
            {
                value: "Arterial Gas Embolism (AGE)/air embolism",
                text: "Arterial Gas Embolism (AGE)/air embolism"
            },
            {
                value: "Bends",
                text: "Bends"
            },
            {
                value: NOTA,
                text: NOTA
            },
            {
                value: "Diving Accident",
                text: "Diving Accident"
            }
        ]
    }

    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "1",
            name: "Proposed_Insured_Questions-66XRlevel",
            label: <Label label={"Highest level of certification"} required={true}/>,
            notShowHrLine: false,
            type: "radioBoxGroup",
            additionalFieldType: 'radioBoxGroup',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            show: isLongForm(offerType),
            additionalFieldOptions: additionalField[0],
            position: 10
        },
        {
            ...commonOptions,
            key: "2",
            name: "Proposed_Insured_Questions-66XRo",
            label: <Label label={"Organization that provided your last certification"} required={true}/>,
            notShowHrLine: false,
            type: "radioBoxGroup",
            additionalFieldType: 'radioBoxGroup',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            show: isLongForm(offerType),
            additionalFieldOptions: additionalField[1],
            position: 10
        },
        {
            key: "3",
            ...commonOptions,
            name: "Proposed_Insured_Questions-66XCp",
            label: <Label label={"What is the purpose of your diving? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[2],
            show: isLongForm(offerType),
        },
        {
            key: "4",
            ...commonOptions,
            name: "Proposed_Insured_Questions-66XCfs",
            label: <Label label={"Which of the following specialty/technical diving do you do? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[3],
            show: isLongForm(offerType),
        },
        {
            key: "5",
            ...commonOptions,
            name: "Proposed_Insured_Questions-66XRsoa",
            label: <Label label={"Do you dive with someone or alone?"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'radioBoxGroup',
            additionalFieldOptions: additionalField[4],
            show: isLongForm(offerType),
        },
        {
            key: "6",
            ...commonOptions,
            name: "Proposed_Insured_Questions-66XCfo",
            label: <Label label={"In which of the following do you dive? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[5],
            show: isLongForm(offerType),
        },
        {
            key: "7",
            ...commonOptions,
            name: "Proposed_Insured_Questions-66XRav",
            label: <Label label={"Average depth of your dives:"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'radioBoxGroup',
            additionalFieldOptions: additionalField[6],
            show: isLongForm(offerType),
        },
        {
            key: "8",
            ...commonOptions,
            name: "Proposed_Insured_Questions-66XCex",
            label: <Label label={"Which of the following have you experienced? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[7],
            show: isLongForm(offerType),
        },
    ]
    return insuredQuestions;
}

export const proposedInsuredQuestion77:any = (params: { offerType: string }) => {
    const {offerType} = params;
    let additionalField:any = {
        0: [
            {
                value: "Stock",
                text: "Stock"
            },
            {
                value: "Street touring",
                text: "Street touring"
            },
            {
                value: "Street prepared",
                text: "Street prepared"
            },
            {
                value: "Modified",
                text: "Modified"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            },
        ]
    }
    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            name: "Proposed_Insured_Questions-77XCveh",
            label: <Label label={"What class/category/division have you participated in? (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[0],
            show: isLongForm(offerType),
        }];
    return insuredQuestions
}

export const proposedInsuredQuestion65:any = (params: { offerType: string }) => {
    const {offerType} = params;
    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "77",
            name: "Proposed_Insured_Questions-77",
            label: "Have you attended a competition driver’s school or hold a competition driver’s license?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-77",
            show: isLongForm(offerType),
            position: 1
        },
        {
            ...commonOptions,
            key: "78",
            name: "Proposed_Insured_Questions-78",
            label: "Do you anticipate that your future participation will be different?",
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-78",
            notShowHrLine: false,
            show: isLongForm(offerType),
            position: 1
        },
    ]
    return insuredQuestions;
}

export const proposedInsuredQuestion66:any = (params: { offerType: string }) => {
    const {offerType} = params;
    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "79",
            name: "Proposed_Insured_Questions-79",
            label: "Do you anticipate that your future participation will be different?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-79",
            show: isLongForm(offerType),
            position: 1
        },
    ];

    return insuredQuestions;
}

export const proposedInsuredQuestion67TopQues:any = (params: { offerType: string }) => {
    const { offerType } = params;

    let additionalField:any = {
        0: [
            {
                value: "Amateur",
                text: "Amateur"
            },
            {
                value: "Jump Master",
                text: "Jump Master"
            },
            {
                value: "Student",
                text: "Student"
            },
            {
                value: "Military",
                text: "Military"
            },
            {
                value: "Professional",
                text: "Professional"
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ]
    }

    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "1",
            name: "Proposed_Insured_Questions-67XRnp",
            label: <Label label={"What is the nature of your participation?"} required={true}/>,
            notShowHrLine: false,
            type: "radioBoxGroup",
            additionalFieldType: 'radioBoxGroup',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            show: isLongForm(offerType),
            additionalFieldOptions: additionalField[0],
        },
    ]
    return insuredQuestions;
}

export const proposedInsuredQuestion67:any = (params: { offerType: string }) => {
    const {offerType} = params;
    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "80",
            name: "Proposed_Insured_Questions-80",
            label: "Are you affiliated with a club?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-80",
            show: isLongForm(offerType),
            position: 1
        },
        {
            ...commonOptions,
            key: "81",
            name: "Proposed_Insured_Questions-81",
            label: "Do you anticipate that your future participation will be different?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-81",
            show: isLongForm(offerType),
            position: 1
        },
    ];

    return insuredQuestions;
}

export const proposedInsuredQuestion68TopQues:any = (params: { offerType: string }) => {
    const {offerType} = params;
    let additionalField:any = {
        0: [
            {
                value: "Trail",
                text: "Trail",
                showDetail: true
            },
            {
                value: "Hiking",
                text: "Hiking",
                showDetail: true
            },
            {
                value: "Snow & Ice",
                text: "Snow & Ice",
                showDetail: true
            },
            {
                value: "Technical/Secured Climbing",
                text: "Technical/Secured Climbing"
            },
            {
                value: "Rock",
                text: "Rock",
                showDetail: true
            },
            {
                value: "Free-Climbing",
                text: "Free-Climbing"
            },
            {
                value: "Bouldering",
                text: "Bouldering"
            },
            {
                value: "Mountain",
                text: "Mountain",
                showDetail: true
            },
            {
                value: "Other",
                text: "Other",
                showDetailsBox: true,
                showDetail: true,
                label: "Please Specify",
                placeholder: "Please Specify"
            }
        ],
        1: [
            {
                value: "Amateur",
                text: "Amateur"
            },
            {
                value: "Competitive Amateur",
                text: "Competitive Amateur"
            },
            {
                value: "Professional",
                text: "Professional"
            }
        ],
    }
    const insuredQuestions: any[] = [
        {
            key: "1",
            ...commonOptions,
            name: "Proposed_Insured_Questions-68XClimb",
            label: <Label label={"Type of climbing (Check ALL that apply)"} required={true}/>,
            notShowHrLine: false,
            additionalFieldType: 'checkBoxGroup',
            additionalFieldOptions: additionalField[0],
            show: isLongForm(offerType),
        },
        {
            ...commonOptions,
            key: "2",
            name: "Proposed_Insured_Questions-68XRsl",
            label: <Label label={"What is your climbing skill level?"} required={true}/>,
            notShowHrLine: false,
            type: "radioBoxGroup",
            additionalFieldType: 'radioBoxGroup',
            additionalFieldRequired: true,
            additionalFieldLabel: '',
            additionalHelperLabel: "",
            show: isLongForm(offerType),
            additionalFieldOptions: additionalField[1],
        },
    ];
    return insuredQuestions;
}

export const proposedInsuredQuestion68:any = (params: { offerType: string }) => {
    const {offerType} = params;
    const insuredQuestions: any[] = [
        {
            ...commonOptions,
            key: "82",
            name: "Proposed_Insured_Questions-82",
            label: "Have you received formal training by an instructor?",
            notShowHrLine: false,
            additionalFieldType: 'textField',
            additionalFieldName: "Remark-82",
            show: isLongForm(offerType),
            position: 1,
            additionalRemarks:false
        }
    ];

    return insuredQuestions;
}

export enum Diving66 {
    LastDive = 'Dyl',
    AnticipateDivingAgain = 'Tan',
    NumberOfDivesLast12Months = 'Tnm',
    NumberOfDivesNext12Months = 'Ta12',
    NumberOfLifetimeDives = 'Tlife'
}
export enum DivingExperience66 {
    DateOfEvent = 'Dg1',
    Treatment = 'Tg2',
    DoctorFacilityName = 'Tg1',
    PhoneNumber = 'Tg4',
    City = 'Tg5',
    State = 'Tg6'
}


export enum SkyDivingSuffix {
    NumberOfJumpsLast12 = 'Tnj12',
    NumberOfJumpsNext12 = 'Tnp12',
    DateOfLastJump = 'Dlj'
}
export enum SkyDivingParticipationSuffix {
    DateOfLastJump = 'Dlj',
    CurrentJumpStatus = 'Tjs',
    BranchOfMilitary = 'Tbm',
    UnitAssigned = 'Tua'
}
export enum ClimbingSuffix {
    AverageAltitudeClimbed = 'XTaa',
    ClimbedOrPlanTo = 'XTwhp',
}

export enum TypeOfClimbingSuffix {
    NumberOfClimbsLifetime = "Tmyl",
    HighestAltitudeClimbed = "Tha",
    HighestAltitudeIntendToClimb = "Thal",
    DateOfLastClimb = "Dl",
    DateAnticipateClimbing = "Dya",
    ClimbsInLast12Months = "Tm12",
    ClimbsAnticipatedInNext12Months = "Tan12",
}

export enum BungeeJumping {
    TimesParticipatedLast12Months = 'XTp12',
    TimesPlanParticipateNext12Months = 'XTpn12',
    DateOfLastParticipation = 'XDlp'
}

type AdvSpPIQ69Type = { name: string, title: string };
export const AdventureSportsPIQ69: AdvSpPIQ69Type[] = [
    { name: `${PIQ}-69bj`, title: "Base Jumping" },
    { name: `${PIQ}-69sl`, title: "Street Luging" },
    { name: `${PIQ}-69z`, title: "Zorbing" },
    { name: `${PIQ}-69yon`, title: "Canyoneering" },
    { name: `${PIQ}-69ww`, title: "White Water Rafting" },
    { name: `${PIQ}-69uf`, title: "Ultimate Fighting" },
    { name: `${PIQ}-69zfi`, title: "Cage Fighting" },
    { name: `${PIQ}-69hg`, title: "Hang Gliding" },
    { name: `${PIQ}-69pa`, title: "Parasailing" },
    { name: `${PIQ}-69zos`, title: "Rodeos" },
    { name: `${PIQ}-69zbuj`, title: "Bungee Jumping" },
];

export enum ExerciseEnum {
    YOGA,
    LIC,
    MIC,
    HIC,
    WL
}

type CBFType = { className?: string; disabled?:boolean, name: string; }

/**
 * CheckBoxField
 * @param className
 * @param name
 * @constructor
 */
const CheckBoxField = ({ className, name, disabled }: CBFType) => {
    const fcChkBoxStyle: React.CSSProperties = { cursor: "pointer", height: "18px", width: "18px" };
    const fieldProps: FieldAttributes<any> = {
        className: className,
        name: name,
        style: fcChkBoxStyle,
        type: "checkbox",
        disabled: disabled
    };
    return (
            <Field {...fieldProps} />
    );
};


type FCLProps = {
    controlClassName?: string,
    controlName: string,
    disabled: boolean,
    fieldControlLabelClassName?: string,
    fieldControlLabel: React.ReactNode,
}
/**
 * FormControl_ChkBox Input styled checkbox (formik field)
 * @param props
 * @constructor
 */
export const FormControlChkBox = (props: FCLProps) => {
    const {
        controlClassName,
        controlName,
        disabled,
        fieldControlLabelClassName,
        fieldControlLabel,
    } = props;
    const control = (<CheckBoxField name={controlName} className={controlClassName} disabled={disabled} />);
    const fclProps = { className: fieldControlLabelClassName, control, disabled, label: fieldControlLabel };

    return (
            <FormControlLabel {...fclProps} />
    );
};
