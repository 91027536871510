import {Action, ActionType} from "../model";
import createReducer from "./createReducer";
import {AggregatorImoList, Aggregators} from "../model/aggregator";

export const aggregatorImos = createReducer({}, {
    [ActionType.GET_AGGREGATOR_IMO_LIST](state: AggregatorImoList, action: Action<AggregatorImoList>) {
        return action.payload;
    }
});
export const aggregators = createReducer([], {
    [ActionType.GET_AGGREGATORS](state: Aggregators[], action: Action<Aggregators[]>) {
        return action.payload;
    },
    [ActionType.FLUSH_AGGREGATORS](state: Aggregators[], action: Action<Aggregators[]>) {
        return action.payload;
    }
});
