import React from 'react';
import {FormControl, TextField, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ErrorHelper, FormikValues} from "../formikFormInputs";
import {CommonProps, FieldLabelEnum, TRAVEL_QUESTION_CODE} from "./index";
import {InputChangeFunc} from "../../model/fields";
import {isFunc, sleep, va} from "../../utils/commonUtils";
import {NS} from "../../model/misc";


interface SearchProps extends CommonProps, FormikValues {
    ansValues?: any[];
    answerValues?: any[];
    defaultAnsValues?: any[];
    hideLabel?: boolean;
    isPhysician?: boolean;
    isSpecialCase?: boolean;
    multiple?: boolean;
    onChange?: (event: React.ChangeEvent<{}>, value: any | null) => void;
    onSearch?: ((event: React.ChangeEvent<{}>, value: string, reason: ("input" | "reset" | "clear")) => void) | undefined;
    options?: NS[] | undefined;
    searching?: boolean | undefined;
}


const SearchBox: React.FC<SearchProps> = (props) => {
    const {
        alias,
        answerValues,
        defaultAnsValues,
        errors,
        helperText,
        hideLabel,
        isPhysician,
        isSpecialCase,
        label,
        multiple,
        name,
        onSearch,
        options,
        searching,
        setFieldValue,
    } = props;

    const onInpChange: InputChangeFunc = (e, v, r) => {
        if (onSearch && isFunc(onSearch)) {
            onSearch(e, v, r);
        }
    };

    const renderInput = (params) => (
            <TextField
                    {...params}
                    label={isPhysician ? FieldLabelEnum.SearchPhysician : FieldLabelEnum.TypeToSearch}
                    variant="outlined"
            />
    );

    React.useEffect(() => {
        if (isSpecialCase && defaultAnsValues && !!va(defaultAnsValues)) {
            sleep(200).then(() => setFieldValue(name, defaultAnsValues));
        }
    }, []);

    return (
            <FormControl fullWidth={true} variant="outlined">
                {
                        alias && !hideLabel &&
                        <Typography className="mb10" variant="body1">
                            {alias}
                        </Typography>
                }
                {
                        label && !hideLabel &&
                        <Typography className="mb10" variant="body1">
                            {label}
                        </Typography>
                }
                <Autocomplete
                        defaultValue={defaultAnsValues}
                        filterOptions={opt => opt}
                        filterSelectedOptions={true}
                        freeSolo={true}
                        loading={searching}
                        loadingText="Searching..."
                        multiple={(multiple !== false) as any}
                        onChange={(e, value: string[]) => setFieldValue(name, !!va(value) ? value.map(v => v.trim()) : value)}
                        onInputChange={onInpChange}
                        options={options as any[]}
                        renderInput={renderInput}
                        value={answerValues}
                />
                {
                        helperText && !hideLabel &&
                        <Typography className="floatLeft w100 f16 textBlackLight" variant="caption">
                            {helperText}
                        </Typography>
                }
                {
                        !hideLabel &&
                        <ErrorHelper errors={errors} name={name}/>
                }
            </FormControl>
    );
};


export default SearchBox;
