import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    shape: {
        backgroundColor: theme.palette.primary.main,
        width: 20,
        height: 20,
    },
    shapeCircle: {
        borderRadius: '50%',
    },
}));

export default function BadgeOverlap() {
    const classes = useStyles();
    const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;

    return (
            <>
                <Badge color="primary" overlap="circle">
                    {circle}
                </Badge>
            </>
    );
}
