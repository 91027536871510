import { ActionType } from "../model";

export const createTicket = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		await api.post('ticket', payload, {});
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: "Ticket Created",
				statusCode: 200
			}
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const getCompletedTickets = ({ searchText, page, rowsPerPage }) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get('ticket/completed', {}, {searchText, page, rowsPerPage});
        return result;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const getCurrentTickets = ({ searchText,  page, rowsPerPage }) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get('ticket/current', {}, {searchText, page, rowsPerPage});
        return result;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};



export const getCompletedTicketsOfClient = ({ clientId, page, rowsPerPage }) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get(`ticket/completed/client/${clientId}`, {}, { page, rowsPerPage});
        return result;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const getCurrentTicketsOfClient = ({ clientId, page, rowsPerPage }) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const result = await api.get(`ticket/current/client/${clientId}`, {}, {page, rowsPerPage});
        return result;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const resolveTicket = (id: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		await api.put(`ticket/${id}`, {}, {});
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message: "Ticket Resolved",
				statusCode: 200
			}
		});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		throw err;
	}
};


export const getTicket = (id: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		return await api.get(`ticket/${id}`, {}, {});
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		throw err;
	}
};


