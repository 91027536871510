import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import { Typography } from "@material-ui/core";
import RectangleShape from "../client/landingPage/v2/images/RectangleShape.svg";
import { kaiZenPhysicianRebrand, kaiZenRebrand } from "../../constants";

const useStyles = makeStyles((theme) =>
        createStyles({
            bgDrakBlue: {
                background: "#01305F",
                float: "left",
                width: "100%",
                color: "#ffffff",
                paddingBottom: "5%",
                position: "relative",
                paddingTop: "8%",
                [theme.breakpoints.down("sm")]: {
                    padding: "8% 16px 5%",
                },
            },
        }),
);
const Player = (props: { videoUrl: string }) => {
    const { videoUrl } = props;
    return <>
        <ReactPlayer
                className="mt40 floatLeft w100 learnMoreVideo"
                controls={true}
                loop={true}
                url={videoUrl}
                width={"100%"}
                height={"100%"}
                playsinline={true}
        />
    </>;
};
export default function LearnMore(props: any) {
    const classes = useStyles();
    const strategy: string = props.strategyData.name;
    const [videoUrl, setVideoUrl] = React.useState("");

    React.useEffect(() => {
        let videoUrl: string = "https://vimeo.com/370399450";

        if (props.strategyData.slug === "esd") {
            videoUrl = "https://vimeo.com/394503173";
            if (props.agent && props.agent.imo && props.agent.imo.reBrand) {
                videoUrl = "https://vimeo.com/731874277";
            }
        }

        if (props.strategyData.slug === "kaizen") {
            videoUrl = "https://vimeo.com/370399450";
        }

        if (props.strategyData.slug === "trizen") {
            videoUrl = "https://vimeo.com/388591717";
        }

        if (props.strategyData && props.strategyData.reBrand && props.strategyData.slug === "kaizen") {
            videoUrl = "https://vimeo.com/372744266/8a7089a149";
        }

        if (props.strategyData && props.strategyData.slug === "kaizen" && props.strategyData.id === kaiZenRebrand) { // kai-zen aon case
            videoUrl = "https://vimeo.com/728933015/f8d44d0e1e";
        }

        if (props.strategyData && props.strategyData.slug === "kaizen" && props.strategyData.id === kaiZenPhysicianRebrand) { // kai-zen phy aon case
            videoUrl = "https://vimeo.com/728930863/c0eea21b79";
        }

        if (props.strategyData.slug === "") {
            videoUrl = "";
        }
        setVideoUrl(videoUrl);
    }, [props.strategyData.slug]);

    return (
            <div className={classes.bgDrakBlue + " textCenter mt40 mb40 "}>
                <img alt={"rectangleshape"} className="rectangleShapeBefore" src={RectangleShape} />
                <Typography variant="h2" className="mt40 colorWhite">
                    Learn more about {strategy}
                </Typography>
                <Typography variant="subtitle1" className="floatLeft w100 mt15">
                    Watch this short video to learn exactly how we make leverage work for you.
                </Typography>
                {videoUrl && <Player videoUrl={videoUrl} />}
            </div>
    );
}