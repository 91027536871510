
import * as React from "react";
import TextField from '@material-ui/core/TextField';
// @ts-ignore
import InputMask from  "react-input-mask";
const PhoneNumberField = (props: any) => {
    const disabled = props.disabled;
    return <InputMask
        mask="(999) 999-9999"
        {...props}
    >
        {(inputProps: object) =>
            <TextField
                name={props.field.name}
                {...inputProps}
                disabled={disabled}
                type="text"
                variant="outlined"
                inputProps={{inputMode: 'numeric'}}

            />
        }
    </InputMask>;
};

export default PhoneNumberField;
