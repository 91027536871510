import {ParsedListItem} from "../../model/application";
import {InputChangeFunc} from "../../model/fields";
import {NS} from "../../model/misc";
import {FormikValues} from "../formikFormInputs";
import {INCORRECT_FORMAT} from "../../constants";
import {va} from "../../utils/commonUtils";

export {default as DynamicChildrenForm} from './ChildrenForm';
export {default as DynamicFormField} from './Field';
export {default as FinalizeApplication} from './Finalize';
export {default as DynamicForm} from './WrapperMain';
export {default as PickList} from './PickList';
export {default as SearchAddNewBox} from './SearchAddNewBox';
export {default as SearchBox} from './SearchBox';
export {default as SelectBox} from './SelectBox';
export {default as TextBox} from './TextBox';
export {default as ToggleButton} from './Toggle';

export const INCH_IN_FEET = 12;
export const PRIMARY_PHYSICIAN_DISCLOSURE_CODE = 'PRIMARY_PHYSICIAN';
export const AVIATION_QUESTION_CODE = '2A_AVIATION';
export const AVOCATIONAL_QUESTION_CODE = '2A_AVOCATIONAL';
export const TRAVEL_QUESTION_CODE = '2A_TRAVEL_2';
export const HEIGHT_QUESTION_CODE = '2B_HEIGHT';
export const SPECIAL_NULL = '__NULL__';
export const NO_MORE_MSG = 'No more questions';
export const YupTestCases = {
    PhoneHalfDigits: {name: 'Phone number should not accepted half digits', message: INCORRECT_FORMAT},
    DateFuture: {name: 'Allow no future date', message: 'Can\'t be in future'},
    DateAncient: {name: 'Allow no old date', message: 'Can\'t be that old'},
};

export enum DynamicFormFieldEnum {
    Boolean = 'BOOLEAN',
    CaseData = 'CASE_DATA',
    Date = 'DATE',
    EnterDetails = 'ENTER_DETAILS',
    Heading = 'HEADING',
    Month = 'MONTH',
    Numeric = 'NUMERIC',
    Number = 'NUMBER',
    Picklist = 'PICKLIST',
    Selection = 'SELECTION',
    Search = 'SEARCH',
    Text = 'TEXT',
    YesNo = 'YES_NO',
    Physician = 'PHYSICIAN'
}

export enum FieldLabelEnum {
    EnterNumber = 'Enter Numbers Only',
    EnterMonth = 'Enter MM/YYYY',
    EnterDetails = 'Provide Details',
    SearchPhysician = 'Search existing physician',
    TypeToSearch = 'Type to Search',
}

export enum CaseDataTypeEnum {
    Numeric = 'NUMERIC',
    Selection = 'SELECTION',
}

export interface CommonProps extends FormikValues {
    alias?: string;
    errors: { [p: string]: string };
    helperText?: string;
    label: string;
    name: string;
    setFieldValue: any;
    type?: string;
    values: any;
}

export type DynamicFormFieldType = {
    aliasName?: string;
    answerValue?: string;
    dataType?: string;
    dateGranularity?: string;
    disclosureCode?: string;
    disclosureSource?: string;
    enrollmentId?: string;
    errors: { [key: string]: string };
    helperText?: string;
    label: string;
    listItems?: ParsedListItem[];
    loading?: boolean;
    maxValue?: number;
    name: string;
    onClearSearch?: Function;
    onSearch?: InputChangeFunc;
    options?: NS[];
    picklistSearchEnabled?: boolean;
    searching?: boolean;
    searchOptions?: string[];
    type?: string;
}


/**
 * Helper functions
 */


export const getOptions = (answerType, disclosureSource, searchOptions: string[] | undefined, fProps: any, picklistQuestionMeta) => {
    return answerType === (DynamicFormFieldEnum.EnterDetails || disclosureSource === DynamicFormFieldEnum.Search) ?
        searchOptions : (
            answerType === DynamicFormFieldEnum.Selection ?
                (!!va(fProps.options) ? fProps.options : []) : ( // TODO quick temporary fix ~~ https://chat.google.com/u/0/api/get_attachment_url?url_type=FIFE_URL&content_type=image%2Fpng&attachment_token=AOo0EEUhQejYQNTZgKyCmnrEf39msBOClPlsiVkGhQtIFWywEsdL744ofjETGpWr6SBXNQQYV0LTSKNCYnjiEDUK%2BYtXWwKPnyIDNOMAGR%2FszTxqOUWcnq6glS2jwv9lTUArK2dRETcdcmlkAwyTeVIEfo5uCA2EnNZ5AXRko0o3mGFxxbBkrgT7hHoEKJyDo%2BehikcR84sJh%2FAvYNx30kEE5DMABc3br63%2BD4FP0gisi0F1nMbrdGNRjKZb2QE0ADSbAb9Xp3NRfIk7V9c8FsJAcqAQzb6jVne7Hgh4CJMgdWux2TFU8QrOXQvdwf1ieQNt0W5aTRmkYeVAeheNmpweSYRBxQM8f5xKEbX%2FrjljaAkJZNGBhdPmwzYQi1AeS5M4V%2FrwaADh%2BtP0zIeIrwlcssBiN5YD1M%2FgLrdXy0pCgBG0cVMXdUMeBKLZSlgkb8uMebHX8v6nTJSVssRF9PTbAR2Xmb52r2qjHq4WnYhN%2B3fpuAedVnBKV7v%2BUrM5UmN8dZPbQBwzI0au%2Fpv7OLkg9Utla70rXAQAvlbdS6wdg1JVOntYUU1umcf%2F6cMY%2B%2B0zKQ%3D%3D&sz=w512
                    (answerType === DynamicFormFieldEnum.Picklist || disclosureSource === DynamicFormFieldEnum.Picklist) ?
                        picklistQuestionMeta && picklistQuestionMeta.picklistItems :
                        undefined
                )
        );
};