import React from "react";
import {Grid} from "@material-ui/core";
import ParentsAreIncomeEarner from "./ProfileFormFields/ParentsAreIncomeEarner";
import RadioGroupCommon from "./ProfileFormFields/RadioGroupCommon"
import { isLongForm, isWorksheet } from "../EnrollmentApplication/Utilities";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { GWT_GEN2_STRATEGY } from "../../../../../constants";
import { isValidObject } from "../../../../../utils/commonUtils";


let currentEmployeeRadioOptions = [
    {
        label: "Yes, full-time (more than 30 hours per week)",
        value: "FULL_TIME",
    },
    {
        label: "Yes, part-time (30 hours per week or less)",
        value: "PART_TIME",
    },
    {
        label: "Self-Employed",
        value: "SELF_EMPLOYED",
    },
    {
        label: "Not currently employed",
        value: "NO",
    }
];

const selfEmployedRadioOptions = [
    {
        label: "Full time",
        value: "FULL_TIME",
    },
    {
        label: "Part time",
        value: "PART_TIME",
    },
];


const CurrentEmployeeRadio = (props: any) => {
    const [employeeOptions, setEmployeeOptions] = React.useState(currentEmployeeRadioOptions);
    const {
        classes,
        clientSigned,
        conditionalRequired,
        errors,
        isAllianzLife,
        isParentEarner,
        offerType,
        sendSaveRequest,
        setCurrentlyEmployed,
        setFieldValue,
        setFullTimeEmployee,
        setFullTimeSelected,
        setIsEmployed,
        setIsParentEarner,
        setSelfEmployed,
        touched,
        values,
    } = props;

    const user: any = useSelector((state: RootState) => isValidObject(state.profile) ? state.profile : state.clientAccount);
    const otherOptionLabel: string = (isLongForm(offerType) || isWorksheet(offerType)) ? (`No, homemaker, student, or retired`) : (`I'm not the qualifying income earner for this product`);
    const isGWTGen2: boolean = !!(user && user.client && user.client.strategyId === GWT_GEN2_STRATEGY);
    const disabled: boolean = !!(clientSigned || isGWTGen2);

    React.useEffect(() => {
        if (values && (values.marriageStatus === "Married" && values.usingSpouseIncome === "1")) {
            setEmployeeOptions(currentEmployeeRadioOptions);
            setIsParentEarner(false);
        } else {
            let otherOption: any = {
                label: otherOptionLabel,
                value: "Other",
            };
            setEmployeeOptions([...currentEmployeeRadioOptions, otherOption]);
            setIsParentEarner(true);
        }
    }, [values]);
    const callBack = (e: any) => {
        setSelfEmployed(e.target.value === "SELF_EMPLOYED" || false);
        setCurrentlyEmployed(e.target.value);
        setFullTimeSelected(e.target.value === "FULL_TIME" ? 1 : 0);
        setIsEmployed((e.target.value === "FULL_TIME" || e.target.value === "PART_TIME"));
        setFieldValue("selfEmployed", e.target.value === "SELF_EMPLOYED" || false);
        setFieldValue("currentlyEmployed", e.target.value);
        setFieldValue("annualIncome1", "");
        setFieldValue("annualIncome2", "");
        //below fields are to show and hide company details
        setFieldValue("fullTimeEmployee", (e.target.value === "FULL_TIME" || e.target.value === "PART_TIME") ? 1 : 0);
        setFullTimeEmployee((e.target.value === "FULL_TIME" || e.target.value === "PART_TIME") ? 1 : 0);

        if((e.target.value === 'FULL_TIME') || (e.target.value === 'PART_TIME')){
        } else {
            setFieldValue("employer", "");
            setFieldValue("employedTime", "");
        }
    };

    return (
            <>
                <RadioGroupCommon
                        cb={callBack}
                        classes={classes}
                        conditionalRequired={conditionalRequired}
                        disabled={disabled}
                        errors={errors}
                        fieldName="currentlyEmployed"
                        label="Are you currently employed? Select one."
                        radioOptionArr={employeeOptions}
                        sendSaveRequest={sendSaveRequest}
                        touched={touched}
                        values={values}
                />
        {
                (isLongForm(offerType) || isWorksheet(offerType)) &&
                values && values.currentlyEmployed === "SELF_EMPLOYED" &&
                <RadioGroupCommon
                        cb={(e: any) => setFieldValue("currentlyEmployedSelf", e.target.value)}
                        classes={classes}
                        disabled={disabled}
                        conditionalRequired={false}
                        errors={errors}
                        fieldName="currentlyEmployedSelf"
                        label=""
                        radioOptionArr={selfEmployedRadioOptions}
                        touched={touched}
                        values={values}
                />
        }
        {
                values && values.currentlyEmployed === "Other" && isParentEarner && !isAllianzLife &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} className={classes.radioGroup}>
                                <ParentsAreIncomeEarner {...props} disabled={disabled} />
                            </Grid>
                        </Grid>
                }
            </>
    )
}
export default CurrentEmployeeRadio;