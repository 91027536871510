import React from "react";
import {useLocation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../reducers";
import {Client} from "../model/account";
import {useActions} from "../actions";
import * as accountActions from "../actions/account";
import * as clientActions from "../actions/client";
import {pez} from "../utils/commonUtils";
import {getQS} from "../utils/queryString";

const getClientOfferType = (client?: Client) => {
    return pez(client && (client.underWritingOfferTypeFinal || client.underWritingOfferType));
};

type OTT = () => string;

export const useOfferType: OTT = () => {
    const enrolmentPath: string = '/new-enrollment';
    const clientDetailPath: string = '/new-client/detail/';

    const loc = useLocation();
    const {id} = useParams();
    const aActions = useActions(accountActions);
    const cActions = useActions(clientActions);
    const qs = getQS();
    const isDebug = qs && !!qs.debug;

    // For client case
    const isEnrollmentPath = enrolmentPath === loc.pathname;

    // For other role's case
    const isClientDetailPath = loc.pathname.indexOf(clientDetailPath) >= 0;

    const auth = useSelector((state: RootState) => state.auth);
    const clientDetail = useSelector((state: RootState) => state.clientAccount); // other users case
    const client = useSelector((state: RootState) => state.profile); // client case

    const offerTypeAuth = getClientOfferType(auth && auth.user && auth.user.client);
    const offerTypeClientAccount = getClientOfferType(clientDetail && clientDetail.client);
    const offerTypeClientProfile = getClientOfferType(client && client.client);


    const offerType = offerTypeAuth ?
        offerTypeAuth :
        (offerTypeClientAccount ? offerTypeClientAccount : offerTypeClientProfile);

    isDebug && console.log({offerType});

    React.useEffect(() => {
        if (isEnrollmentPath && (!offerTypeClientProfile || !offerTypeAuth)) {
            // api call for state.profile
            isDebug && console.log({offerType, isEnrollmentPath});
            aActions.getProfileDetails().then();
        }
    }, [isEnrollmentPath, offerType]);


    React.useEffect(() => {
        if (id && isClientDetailPath && !offerTypeClientAccount) {
            // api call for state.clientAccount
            isDebug && console.log({offerType, isClientDetailPath});
            cActions.getClient(id).then();
        }
    }, [isClientDetailPath, offerType]);


    return offerType;
};