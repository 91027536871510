import { Box, Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { reduxForm, getFormValues, Field } from "redux-form";
import { useActions } from "../../../actions";
import * as Actions from "../../../actions/account";
import * as AlertActions from "../../../actions/alert";
import PrimaryCompanyDetail from "../../../components/PrimaryCompanyDetail";
import PrimaryContactDetail from "../../../components/PrimaryContactDetail";
import { User } from "../../../model/user";
import { RootState } from "../../../reducers";
import { checkIMOEditAllowedAdminIMO, sleep, unmaskPhoneNumber, va } from "../../../utils/commonUtils";
import { UpdateButton } from "../../EditAccountPage";
import AgentProfileImage from "./AgentProfileImage";
import ImoCATable from "./ImoCATable";
import { DeleteModel, FileUpload } from "../../../components/formInputs";
import { change } from "redux-form";
import { LOGO_BASE_URL, stateDropDown } from "../../../constants";
import { hidden } from "../../../components/profiles/AgentProfile";
import { DialogImo, DialogImoType, prefixAgentNumber } from "../../../components/DialogImo";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConfirmationDialog } from "../../ticket/TicketPage";
import AddIcon from '@material-ui/icons/Add';
import {isAdmin} from '../../../components/navigation/AdminNavigation';
import DisabledFileUpload from "../../../components/formInputs/DisabledFileUpload";

type AgentProfileTabProps = {
    userId: number
}

type AgentFormData = {}

export const getImos = (agentImos: any) => {
    return (agentImos || []).map((agentImo: any) => {
        const imo = agentImo.imo;
        const state = stateDropDown.find(x => x.value === imo.state)

        const carrierNumbers = va(agentImo.agentImoCarriers) &&
                agentImo.agentImoCarriers
                        .map((agentImoCarrier: any) => (agentImoCarrier.carrier && {[prefixAgentNumber + agentImoCarrier.carrier.id]: agentImoCarrier.code}))
                        // to object
                        .reduce((a: any, v: any) => ({...a, ...v}));

        return {
            ...imo,
            id: imo.id,
            name: imo.groupName,
            state: state ? state.value : null,
            phone: imo.officeNumber,
            contactFirstName: imo.user.firstName,
            contactLastName: imo.user.lastName,
            imoEmail: imo.user.email,
            carriers: agentImo.agentImoCarriers.map((agentImoCarrier: any) => ({
                ...agentImoCarrier.carrier,
                code: agentImoCarrier.code
            })),
            ...carrierNumbers
        }
    })
}
const checkDisable = (role) => {
    const disableRoleArr = [3, 15, 18 ,19];
    if(disableRoleArr.indexOf(role) > -1){
        return true;
    }
    return false;
}
const AgentProfileTab = (props: any) => {
    const dispatch = useDispatch();
    const accountActions = useActions(Actions);
    const [imageName, setImageName] = useState<string>("");
    const [isDelIcon, setDelIcon] = useState<boolean>(false);
    const formName = props.page;
	const [isDeleteCompanyLogo, setIsDeleteCompanyLogo] = useState<boolean>(false);
    const [companyLogo, setCompanyLogo] = useState({ url: "" });
    const auth: User = useSelector((state: RootState) => state.auth);
    const role = auth.user.role;
    const { handleSubmit, pristine, submitting, userId, formValues } = props;
    // @ts-ignore
    const [isToggle, setIsToggle] = React.useState<boolean>(false);

    const { updateAgentCompanyLogo } = accountActions;

    const updateLogo = async (publicUrl: any, fileName: string) => {
        dispatch(change(formName, "agent.companyLogo", fileName));
        setCompanyLogo({ url: LOGO_BASE_URL + fileName });
        await updateAgentCompanyLogo(formValues.id, { agent: { companyLogo: fileName } });
    };

    const deleteCompanyLogo = async() => {
		dispatch(change(formName, "agent.companyLogo", ""));
	    await updateAgentCompanyLogo(formValues.id, { agent: { companyLogo: "" } });
		setCompanyLogo({ url: "" });
    	setIsDeleteCompanyLogo(false);
	}

    useEffect(() => {
        if (formValues && formValues.agent && formValues.agent.companyLogo) {
			setCompanyLogo({ url: LOGO_BASE_URL + formValues.agent.companyLogo });
		}
	}, [formValues]);

    const updateAccount = async (data: any, _imos: any[]) => {
        delete data.id;
        if (data.phoneNumber) {
            data.phoneNumber = unmaskPhoneNumber(data.phoneNumber);
        }

        const _data = Object.assign(data, va(_imos) ? {imos: _imos} : {imos});

        if (auth.user && role === 3) {
            await accountActions.updateImoAgentDetails(data, userId);
        } 
        else if (auth.user && role === 4) {
            await accountActions.updateUserAgentDetails(_data, false );
        }
        else {
            await accountActions.updateAdminAgentDetails(_data, userId);
        }
    };

    const designationList = useSelector((state: RootState) => state.designationList);
    const [ownDesignation, setOwnDesignation] = useState<boolean>(false);

	useEffect(() => {
        if(formValues){
            setOwnDesignation(formValues.agent && formValues.agent.designationId === 999);
        }
    }, [formValues]);

    useEffect(() => {
      setImageName(formValues ? formValues.image : '');
    }, [formValues]);

    const [imos, setImos] = useState([]);
    const [isImosUpdated, setIsImosUpdated] = useState(false);
    
    useEffect(() => {
        if(formValues && formValues.agent) {
            setImos(getImos(formValues.agent.agentImos || []))
        }
    }, [formValues])

    const updateImos = async (imos) => {
        if (!isToggle) {
            setIsToggle(true);
        }
        setImos(imos);
        setIsImosUpdated(true);

        // Update agent profile on saving changes to IMO dialog
        await updateAccount(formValues, imos);

        setIsImosUpdated(false);
    }

    return (
            <Box>
                <Card className="muiCard">
                    <CardContent className="muiCardContent">
                        <form onSubmit={handleSubmit(updateAccount)}>
                            <Typography component="strong" className="mb10 mt15 floatLeft w100">
                                Personal Details
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={5} md={4} lg={4} xl={3}>
                                    <AgentProfileImage
                                        accountId={formValues && formValues.id}
                                        imageName={imageName}
                                        setImageName={setImageName} disabledEditImage={checkDisable(role)}/>
                                </Grid>
                                <Grid item container spacing={2} xs={12} sm={12} md={8} lg={8} xl={9}>
                                    <PrimaryContactDetail
                                            role={4}
                                            size={12}
                                            disabledFirstName={checkDisable(role)}
                                            disabledLastName={checkDisable(role)}
                                            disabledEmail={checkDisable(role)}
                                            disabledPhone={checkDisable(role)}
                                    />
                                </Grid>
                            </Grid>
                            <Typography className="mt30 mb15 floatLeft w100" component="strong">
                                Your Company / Agency Information
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    {
                                        (role === 18 || role === 19 || role === 3 || role === 15) ? <DisabledFileUpload/> :
                                                <FileUpload
                                                        dirName={"companyLogo"}
                                                        fileType="image/jpg, image/jpeg, image/png"
                                                        width="250"
                                                        height="70"
                                                        onFinish={(publicUrl: string, name: string) => updateLogo(publicUrl, name)}
                                                />
                                    }
                					<Field name="agent.companyLogo" component={hidden}/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className="textCenter">
                                {
                                        companyLogo.url &&
                                        <Typography
                                                className="floatLeft w100 position_R mb0"
                                                variant="caption"
                                                color="primary"
                                                onMouseLeave={() => setDelIcon(false)}
                                                onMouseEnter={() => setDelIcon(true)}
                                        >
                                            <img
                                                    alt="agent_company_logo_url"
                                                    className="floatLeft"
                                                    src={`${companyLogo.url}?${Date.now()}`}
                                            />
                                            {
                                                isDelIcon && !checkDisable(role) &&
                                                    <DeleteIcon
                                                            className="cursor floatRight"
                                                            color="error"
                                                            onClick={() => setIsDeleteCompanyLogo(true)}
                                                            style={{ position: "absolute", right: 15 }}
                                                    />
                                            }
                                        </Typography>
                                }
                                </Grid>
                                {
                                    isDeleteCompanyLogo &&
                                    <DeleteModel
                                        open={true}
                                        content="Are you sure want to delete logo ?"
                                        handleClose={() => setIsDeleteCompanyLogo(false)}
                                        handleYes={deleteCompanyLogo}
                                        accountType="image"
                                    />
                                }
                                <PrimaryCompanyDetail
                                        ownDesignation={ownDesignation}
                                        designationList={designationList}
                                        disabledLastName={checkDisable(role)}
                                        disabledPhone={checkDisable(role)}
                                        disabledDesignation={checkDisable(role)}/>
                            </Grid>
                            <AgentImosSection
                                imos={imos}
                                addDialogImoType={dialogImoTypes.add}
                                setImos={updateImos}
                            />
                            <Grid>
                                <UpdateButton
                                        label="Update Profile"
                                        isUpdated={false}
                                        pristine={pristine && !isImosUpdated}
                                        submitting={submitting}
                                        setIsToggle={setIsToggle}
                                />
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Box>
    );
};

export const dialogImoTypes = {
    add: {
      emptyLabel: "Add new IMO here",
      title: "Add your IMO here",
      adminTitle: "Add/Edit IMO(s) & Carrier(s)",
      buttonLabel: "Save"
    },
    edit: {
      title: "Edit IMO and Carriers",
      buttonLabel: "Save"
    }
  }  

export const initialImo = {
	imo: {key: null, title: ''},
	state: '',
	phone: '',
	contactFirstName: '',
	contactLastName: '',
	imoEmail: '',
	carriers: [],
	index: null,
    region: ''
  }
export type ImoForm = typeof initialImo

export const AgentImosSection = ({ imos, addDialogImoType, setImos }: any) => {
    const alertActions = useActions(AlertActions);
    const initialDialog = {type: null, data: initialImo};
    const [imoDialog, setImoDialog] = React.useState<{ type: DialogImoType | null; data: ImoForm & { index: number | null } }>(initialDialog);
    const authUser = useSelector((state: RootState) => state.auth.user);

    const submitImo = (imo: any) => {
        if (imoDialog.type === dialogImoTypes.edit)
            setImos(imos.map((e, index) => {
                if(index === imo.index) {
                    return imo;
                }

                return e;
            }));

        if (imoDialog.type === addDialogImoType)
            setImos([...imos, imo]);
    }

    const handleEdit = (imoRow: any) => {
        setImoDialog({
            type: dialogImoTypes.edit,
            data: {
                ...imoRow,
                carriers: imoRow.carriers.map((x: any) => x.id),
                imo: {key: imoRow.id, title: imoRow.name}
            }
        });
    }

    const [imoToDelete, setImoToDelete] = useState(null);

    const handleDeleteImo = async () => {
        if (imos.length === 1) {
            setImoToDelete(null);
            alertActions.setAlert('Can\'t delete! At least one IMO needed.', 'warning', 418);
            return;
        }
        const _imos = imos.filter((e) => e !== imoToDelete);
        await sleep(50);
        setImos(_imos);
        setImoToDelete(null);
    }
    
    return (
            <>
                <Box display="flex" justifyContent="space-between" className="mt30 floatLeft w100">
                    <Typography component="strong">
                        IMO's, Carriers, and Agent Numbers
                    </Typography>
                    {
                        isAdmin(authUser) &&
                        <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                className="mb15"
                                startIcon={<AddIcon />}
                                onClick={() => setImoDialog({ type: addDialogImoType, data: initialImo })}
                        >
                            {isAdmin(authUser) ? addDialogImoType.adminTitle : addDialogImoType.title}
                        </Button>
                    }
                    {
                        imoDialog.type &&
                        <DialogImo
                                handleClose={() => setImoDialog(initialDialog)}
                                imos={imos}
                                initialImo={imoDialog.data}
                                onSubmit={imo => submitImo(imo)}
                                type={imoDialog.type}
                                showForAdmin={checkIMOEditAllowedAdminIMO(authUser)}
                          />
                    }
                </Box>
                <ImoCATable
                        rows={imos}
                        onAddNewImo={() => setImoDialog({type: addDialogImoType, data: initialImo})}
                        onDelete={row => setImoToDelete(row)}
                        onEdit={handleEdit}
                        label={dialogImoTypes.add.emptyLabel}
                />
                {
				    imoToDelete && 
                    <ConfirmationDialog
                        onCancel={() => setImoToDelete(null)}
                        onOk={handleDeleteImo}
                        text="Are you sure want to delete this imo permanently?"
                        title="Delete confirmation"
                    />
                }
            </>

    );
};

function mapStateToProps(state: RootState) {
    return {
        formValues: getFormValues("AgentProfileTab")(state),
    };
}

const reduxConnect = reduxForm<AgentFormData, AgentProfileTabProps>({
    form: "AgentProfileTab",
    enableReinitialize: true,
})(AgentProfileTab);

export let AgentProfileTabReduxForm = connect(mapStateToProps)(reduxConnect);

