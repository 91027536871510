import * as React from "react";
import {
    Grid, Link,
    Typography,
} from "@material-ui/core";
import iliaMini from "../client/landingPage/v2/images/ilia_small.png";
import { LOGO_BASE_URL } from "../../constants";

const LeadFooter = (props: any) => {
    const strategy: string = props.strategyData.name;
    const strategyLogo: string = props.strategy && props.strategy.landingPageLogo ? LOGO_BASE_URL + props.strategy.landingPageLogo : "";
    const partnerName: string = "ilia";

    let partnerLogo: string = iliaMini;
    if (props.strategyData && props.strategyData.reBrand === 1 && props.strategyData.reBrandLogo) {
        let isExist: boolean = props.strategyData.reBrandLogo.includes("s3.amazonaws.com");
        if (isExist) {
            partnerLogo = props.strategyData.reBrandLogo;
        } else {
            partnerLogo = LOGO_BASE_URL + props.strategyData.reBrandLogo;
        }
    }

    if (props.strategyData && props.strategyData.reBrand === 2 && props.agent && props.agent.imo && props.agent.imo.reBrand && props.strategyData.reBrandLogo) {
        let isExist: boolean = props.strategyData.reBrandLogo.includes("s3.amazonaws.com");
        if (isExist) {
            partnerLogo = props.strategyData.reBrandLogo;
        } else {
            partnerLogo = LOGO_BASE_URL + props.strategyData.reBrandLogo;
        }
    }
    return (
            <footer className="footer leads">
                <Grid container justify="center">
                    <Grid item xs={12} md={12} className="textLeft">
                        <Grid container spacing={2} style={{ paddingLeft: "40px" }}>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="footerImg">
                                    <Typography component="span">
                                        <img alt={"partner-logo"} src={partnerLogo} className="logo" />
                                    </Typography>
                                    {
                                        strategyLogo ?
                                                <img alt={"strategy-logo"} className="logo" src={strategyLogo}/> :
                                                <Typography color="primary" component="strong" className="f22 ml15 mt5">
                                                    {props.strategyData.name}
                                                </Typography>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="span" className="floatLeft w100 f12">
                                    &copy; {(new Date().getFullYear())}. {partnerName} and {strategy} are registered
                                    trademarks
                                    of NIW Companies Inc.
                                    <Link
                                        variant="caption"
                                        className="ml15 f12"
                                        onClick={() => props.setShowPrivacyPolicy(true)}
                                    >
                                    Privacy Policy
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </footer>
    );
};
export default LeadFooter;
