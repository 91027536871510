import { EnrollmentQuestionProps } from "../../model/enrollment";

const GiQuestions: EnrollmentQuestionProps[] = [
    {
        key: "26",
        name: "Proposed_Insured_Questions-26",
        label: "Have you been admitted to or been advised to be admitted to a hospital or medical facility in the past 90 days?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-26-Remarks',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details'
    },
    {
        key: "10",
        name: "Proposed_Insured_Questions-10",
        label: "Are you actively at work at the customary workplace, doing the usual duties and functions required by the position during the normal work hours and weekly period?",
        type: "radio",
        default: false,
        onYes: false,
        additionalRemarks: true,
        showRemarks: false,
        additionalFieldName: 'Remark-10-Remarks',
        additionalFieldType: 'textfield',
        additionalFieldRequired: true,
        additionalFieldLabel: 'Provide Details'
    }
];

export default GiQuestions;
