import React from "react";
import {EnrollmentFinancialAgreementProps, ResetFieldsProps} from "../../../../../../model/enrollment";
import { avocationType, checkAllApplied } from "../Utilities";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import {
    getInsuredQuestions,
    proposedInsuredQuestion64,
    proposedInsuredQuestion65,
    proposedInsuredQuestion66,
    proposedInsuredQuestion67,
    proposedInsuredQuestion68,
} from "./InsuredQuestions";
import { useFormikContext } from "formik";
import { ErrorHelper } from "../../../../../../components/formikFormInputs";
import ProposedInsuredQuestion64, {resetProposedInsuredQuestion64} from "./questions/ProposedInsuredQuestion64";
import ProposedInsuredQuestion65 from "./questions/ProposedInsuredQuestion65";
import ProposedInsuredQuestion66 from "./questions/ProposedInsuredQuestion66";
import ProposedInsuredQuestion67 from "./questions/ProposedInsuredQuestion67";
import ProposedInsuredQuestion68 from "./questions/ProposedInsuredQuestion68";
import ProposedInsuredQuestion69 from "./questions/ProposedInsuredQuestion69";
import ProposedInsuredQuestion70 from "./questions/ProposedInsuredQuestion70";
import ProposedInsuredQuestion75 from "./questions/ProposedInsuredQuestion75";
import ProposedInsuredQuestion83 from "./questions/ProposedInsuredQuestion83";
import { ProposedInsuredQuestionsKeys, showIfApplicable } from "./insuranceValidations";
import InsuredToggleButtonGroup from "./component/InsuredToggleButtonGroup";


type PropsType = {
    offerType: string,
    isAssociation: boolean,
    isCorporation: boolean,
    user: any,
    isFromClientDetail: boolean,
    nationalLifeId: number,
    values: any,
    setFieldValue: Function,
    setOpenInforceLifeAlertPopup: Function,
    enableSaveButton: any,
    clientSigned: boolean,
    showComponent: Function,
    touched: any,
    errors: any,
    setFieldTouched: Function,
    handleChange: Function,
    handleBlur: Function,
    setErrors: Function,
    getPolicyGrids: Function,
    setSaveApplicationQuestions?: Function
    // callingAutoSave: Function
}

function QuestionsListing(props: PropsType) {
    const {
        offerType,
        isAssociation,
        isCorporation,
        user,
        isFromClientDetail,
        nationalLifeId,
        setOpenInforceLifeAlertPopup,
        enableSaveButton,
        clientSigned,
        showComponent,
        touched,
        setFieldTouched,
        handleChange,
        handleBlur,
        setErrors,
        getPolicyGrids,
        setSaveApplicationQuestions,
        // callingAutoSave,
    } = props;

    const questionList = getInsuredQuestions({ offerType, isCorporation, isAssociation, user });

    const insuredQuestion64: any[] = proposedInsuredQuestion64(offerType);
    const insuredQuestion65: any[] = proposedInsuredQuestion65(offerType);
    const insuredQuestion66: any[] = proposedInsuredQuestion66(offerType);
    const insuredQuestion67: any[] = proposedInsuredQuestion67(offerType);
    const insuredQuestion68: any[] = proposedInsuredQuestion68(offerType);

    if (offerType === "longform") {
        questionList.sort(function(a, b) {
            return a.position - b.position;
        });
    }

    const { values, errors, setFieldValue }: any = useFormikContext();
    const resetFields = async (name:string, rfs:ResetFieldsProps[]) => {
        switch (name) {
            case "Proposed_Insured_Questions-64":
                const _insuredQuestion64Keys: string[] = proposedInsuredQuestion64(offerType).map(s => s.name);
                resetProposedInsuredQuestion64(_insuredQuestion64Keys, setFieldValue)
                break;
            default:
                if( rfs.length > 0 ) {
                    for (let rf in rfs) {
                        await setFieldValue(rfs[rf].name, rfs[rf].value)
                    }
                }
        }
    }

    /*React.useEffect(() => {
        console.log("Values===>", values);
        let deBounceFn;
        if (values) {
            const otherThenRequiredErrors: any = [];
            if (Object.keys(errors).length > 0) {
                //Fix this for AutoSave
                if (errors.hasOwnProperty("inForcePolicies")) {
                    return;
                } else {
                    for (var key of Object.keys(errors)) {
                        if ((errors[key]).toLowerCase() !== "required field") {
                            otherThenRequiredErrors.push(key);
                        }
                    }

                    otherThenRequiredErrors.forEach((keyField: any) => {
                        delete values[keyField];
                    });
                }
            }
            deBounceFn = setTimeout(() => {
                if (typeof setSaveApplicationQuestions === "function") {
                    setSaveApplicationQuestions(values);
                }
            }, 1000);
            return () => clearTimeout(deBounceFn);
        }

        return () => {
            if (deBounceFn) {
                clearTimeout(deBounceFn);
            }
        };
    }, [errors, values]);*/

    return <>{
        questionList.map((question: EnrollmentFinancialAgreementProps, idx: number) => {
            const _isShow = (name: string, value: string = "Yes") => {
                return showIfApplicable(values, question.name, name, value);
            };
            if (question.show) { // Note: Please comment this and uncomment below if condition, if we have any issue to show questions
                return (
                        <>
                            <Grid item xs={12} sm={12} md={12} key={question.key || idx}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={question.type === "radio" ? 8 : 12}
                                        lg={question.type === "radio" ? 9 : 12}
                                    >
                                        <Typography variant="body1" className="mb15 floatLeft" component="strong">
                                            {question.label ? <Label label={`${question.label}`} required={!isFromClientDetail}/> : <br/>}
                                        </Typography>
                                        {
                                            question.infoTooltip &&
                                            <Tooltip
                                                    title={<Typography
                                                            className="f16 txtWhite mb0"
                                                    > {question.infoTooltip} </Typography>}
                                            >
                                                <IconButton>
                                                    <Info color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Grid>
                                    {
                                        question.type === "radio" &&
                                        <Grid item xs={12} sm={12} md={4} lg={3}>
                                                <InsuredToggleButtonGroup
                                                    id={question.name}
                                                        onChangeNo={() => {
                                                            //Need to Fix this
                                                            // if (question.name === "existPolicies") {
                                                            //     setFieldValue('noOfPolicies', "");
                                                            // }

                                                            // if (question.name === "inforcePolicy") {
                                                            //     setFieldValue("inForcePolicies", []);
                                                            //     setFieldValue("noOfInforcePolicies", "");
                                                            // }

                                                            if (question.name === "Proposed_Insured_Questions-32") {
                                                                setFieldValue("Insured_ParticipationDetails", []);
                                                                avocationType.forEach((ele: any) => {
                                                                    setFieldValue(`${ele.name}`, null);
                                                                });
                                                                checkAllApplied.forEach((ele: any) => {
                                                                    setFieldValue(`${ele.name}`, null);
                                                                });
                                                            }

                                                            if (question && question.resetFields && question.resetFields.length > 0) {
                                                                resetFields(question.name, question.resetFields).then();
                                                            }
                                                        }}
                                                        onChangeYes={() => {
                                                            if (question.name === "isInforceLife") {
                                                                // Show Popup for info
                                                                setOpenInforceLifeAlertPopup(true);
                                                        }
                                                        }}
                                                        onChangeNull={() => console.info('onChange for Null value')}
                                                        onChange={() => {
                                                        enableSaveButton();
                                                            // callingAutoSave();
                                                        if (question.name === "inforcePolicy") {
                                                            setFieldValue("inForcePolicies", []);
                                                            setFieldValue("noOfInforcePolicies", "");
                                                        }
                                                    }}
                                                   clientSigned={clientSigned}
                                                />
                                            <span style={{ float: "right", clear: "both" }}>
                                                <ErrorHelper errors={errors} name={question.name} />
                                            </span>
                                        </Grid>
                                    }
                                    {
                                            question && question.additionalRemarks &&
                                            showComponent(question, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors)
                                    }
                                    {/*{
                                        question.name !== "Proposed_Insured_Questions-32" &&
                                        <span className="hLine"/>
                                }
                                {
                                        question.name === "Proposed_Insured_Questions-32" && values["Proposed_Insured_Questions-32"] !== "Yes" &&
                                        <span className="hLine"/>
                                }*/}
                                </Grid>
                            </Grid>
                            {
                                    question.name === "inforcePolicy" && Number(values["noOfInforcePolicies"]) > 0 && values["inforcePolicy"] === "Yes" &&
                                    <>
                                        {getPolicyGrids(values, handleChange, handleBlur, errors, touched, setFieldValue)}
                                    </>
                            }
                            {
                                    _isShow(ProposedInsuredQuestionsKeys.PIQ64) &&
                                    <Grid container spacing={2} className={"mt20 mb10"}>
                                        <ProposedInsuredQuestion64
                                                clientSigned={clientSigned}
                                                showComponent={showComponent}
                                                setErrors={setErrors}
                                                isFromClientDetail={isFromClientDetail}
                                                insuredQuestion={insuredQuestion64}
                                                offerType={offerType}
                                        />
                                    </Grid>
                            }
                            {
                                    _isShow(ProposedInsuredQuestionsKeys.PIQ75) &&
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <ProposedInsuredQuestion75
                                                    clientSigned={clientSigned}
                                                    offerType={offerType}
                                                    showComponent={showComponent}
                                                    setErrors={setErrors}
                                            />
                                        </Grid>
                                    </Grid>
                            }
                            {
                                    _isShow(ProposedInsuredQuestionsKeys.PIQ65) &&
                                    <Grid container spacing={2}>
                                        <ProposedInsuredQuestion65
                                                clientSigned={clientSigned}
                                                showComponent={showComponent}
                                                setErrors={setErrors}
                                                isFromClientDetail={isFromClientDetail}
                                                insuredQuestion={insuredQuestion65}
                                                offerType={offerType}
                                        />
                                    </Grid>
                            }
                            {
                                    _isShow(ProposedInsuredQuestionsKeys.PIQ66) &&
                                    <Grid container spacing={2}>
                                        <ProposedInsuredQuestion66
                                                clientSigned={clientSigned}
                                                showComponent={showComponent}
                                                setErrors={setErrors}
                                                isFromClientDetail={isFromClientDetail}
                                                insuredQuestion={insuredQuestion66}
                                                offerType={offerType}
                                        />
                                    </Grid>
                            }
                            {
                                    _isShow(ProposedInsuredQuestionsKeys.PIQ67) &&
                                    <Grid container spacing={2}>
                                        <ProposedInsuredQuestion67
                                                clientSigned={clientSigned}
                                                showComponent={showComponent}
                                                setErrors={setErrors}
                                                isFromClientDetail={isFromClientDetail}
                                                insuredQuestion={insuredQuestion67}
                                                offerType={offerType}
                                        />
                                    </Grid>
                            }
                            {

                                    _isShow(ProposedInsuredQuestionsKeys.PIQ68) &&
                                    <Grid container spacing={2}>
                                        <ProposedInsuredQuestion68
                                                clientSigned={clientSigned}
                                                showComponent={showComponent}
                                                setErrors={setErrors}
                                                isFromClientDetail={isFromClientDetail}
                                                insuredQuestion={insuredQuestion68}
                                                offerType={offerType}
                                        />
                                    </Grid>
                            }
                            {
                                    _isShow(ProposedInsuredQuestionsKeys.PIQ69) &&
                                    <Grid container spacing={2}>
                                        <ProposedInsuredQuestion69
                                                clientSigned={clientSigned}
                                                showComponent={showComponent}
                                                setErrors={setErrors}
                                                isFromClientDetail={isFromClientDetail}
                                                offerType={offerType}
                                        />
                                    </Grid>
                            }
                            {

                                    _isShow(ProposedInsuredQuestionsKeys.PIQ70) &&
                                    <div style={{ backgroundColor: "#edf3f8", padding: "10px 20px", margin: "20px 0" }}>
                                        <ProposedInsuredQuestion70
                                                clientSigned={clientSigned}
                                        />
                                    </div>
                            }
                            {
                                    (question.name === "Proposed_Insured_Questions-83") &&
                                    <Grid container spacing={2}>
                                        <ProposedInsuredQuestion83
                                                clientSigned={clientSigned}
                                        />
                                    </Grid>
                            }
                            {
                                    (!(question && question.notShowHrLine)) &&
                                    <span className="hLine" />
                            }
                        </>
                );
            }
        })
    }
    </>;
}

export default React.memo(QuestionsListing);