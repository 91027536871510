import 'date-fns';
import React from 'react';
import MomentUtils from '@date-io/moment';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers';
import { FormInputProps } from '../../model';


export default function DatePicker({
																		 input,
																		 meta: { touched, error },
																		 ...custom
																	 } : FormInputProps) {
    const [emptyLabel, setEmptyLabel] = React.useState("Select Date");
	return (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<KeyboardDatePicker
						margin="normal"
						format="MM/DD/YYYY"
						error={touched && error}
						helperText={touched && error}
						value={input.value || null }
						onChange={input.onChange}
						{ ...custom }
                        inputVariant="outlined"
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
                        emptyLabel={emptyLabel}
                        onClick={(e) => {
                            const event: any = Object.assign({}, e);
                            if (emptyLabel === "Select Date" && !input.value) {
                                setTimeout(() => {
                                    event.target.selectionStart = 0;
                                    event.target.selectionEnd = 0;
                                }, 100);
                            }
                            setEmptyLabel("__/__/____");
                        }}
                        inputProps={{ inputMode: "numeric" }}
				/>
			</MuiPickersUtilsProvider>
	);
}