import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {
    Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
} from "@material-ui/core";
import participantStatusInvitation from "../../images/participant_status_invitation.svg";
import visaGreencard from "../../images/svg/visa-greencard.svg";
import participantStatusEnrollment from "../../images/participant_status_enrollment.svg";
import {dateUtils} from "../../../../../utils";
import ViewPopUp from "../../../../../components/client/ViewPopUp";
import {useActions} from "../../../../../actions";
import * as EnrollmentActions from "../../../../../actions/enrollment";
import * as AccountActions from "../../../../../actions/account";
import {ClientDocumentType, PolicyStatusEnum} from "../../../../../constants";
import CloseIcon from "@material-ui/icons/Close";
import StrategyName from "../../../../../components/client/StrategyName";
import DocusignLoader from "../../../../../components/DocusignLoader";
import * as Aws from "../../../../../actions/aws";
import {AccountStatus, AccountStatusArr} from "../../../../../model";
import { noop, pez } from "../../../../../utils/commonUtils";
import ProposalTabs from "../../../Enrollment/component/ProposalTabs";
import { Proposal } from "../../../../../model/proposal";
import { ClientDocsInterface } from "../../../../../model/documents";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                flexGrow: 1,
            },
            paper: {
                padding: theme.spacing(2),
                textAlign: "center",
                color: theme.palette.text.secondary,
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        }),
);
type PorpsType = {
    clientDetail: any,
    role: number | undefined,
    setActionItem: Function
}

export default function CenteredGrid(props: PorpsType) {
    const classes = useStyles();
    const awsActions = useActions(Aws);
    const accountActions = useActions(AccountActions);
    const enrollmentActions = useActions(EnrollmentActions);
    const { getClientDocumentByType } = useActions(AccountActions);
    const { clientDetail, role } = props;
    const [strategyName, setStrategyName] = React.useState("Kai-Zen");
    const [strategySlug, setStrategySlug] = React.useState("kaizen");
    const [viewType, setViewType] = React.useState<string>("");
    const [documentUrl, setDocumentUrl] = React.useState("");
    const [openPdf, setOpenPdf] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(true);
    const enrollmentId = clientDetail && clientDetail.enrollment && clientDetail.enrollment.id || "";
    const enrollment = clientDetail && clientDetail.enrollment && clientDetail.enrollment || null;
    const [openProposalPdf, setOpenProposalPdf] = React.useState<Proposal[]>([] as Proposal[]);
    const [clientActivities, setClientActivities] = React.useState([]);
    const [documentCompleted, setDocumentCompleted] = React.useState(false);
    const [showOverrideText, setShowOverrideText] = React.useState(false); // policy override by admin
    const [adminName, setAdminName] = React.useState<string>("");
    const [activeProposalTab, setActiveProposalTab] = React.useState(0);

    const getAdminName = async (clientId: number) => {
        const res = await enrollmentActions.policyStatusChangedByUserName(clientId);
        if (res)
            setAdminName(res.firstName + " " + res.lastName);
    }

    React.useEffect(() => {
        const getData = async () =>  {
            if (clientDetail && clientDetail.client && clientDetail.client.strategy) {
               let resp = await accountActions.getClientActivities( clientDetail.client.id );
                setClientActivities( resp );
            }
        }
        getData();
    }, []);

    React.useEffect(() => {
        if (clientDetail && clientDetail.client && clientDetail.client.strategy) {
            setStrategyName(clientDetail.client.strategy.name);
            setStrategySlug(clientDetail.client.strategy.slug);
        }
        if(clientDetail && clientDetail.client){
            setDocumentCompleted(clientDetail.client.documentsCompleted)
        }
    }, [clientDetail]);

    React.useEffect(() => {
        if (showOverrideText && clientDetail && clientDetail.client && clientDetail.client.id) {
            getAdminName(clientDetail.client.id).then(()=>{});
        }
    }, [showOverrideText])

    let status: { text: string | JSX.Element, date: string }[] = [];
    Object.keys(AccountStatusArr).map((activityStatus) => {
        status[activityStatus] = {
            text: (enrollment && enrollment.version === 1 && activityStatus > "14") ? "N/A for Legacy Enrollment" : "Incomplete",
            date: "",
        };
    });

    enrollment && clientActivities.length > 0 && clientActivities.map( ( activity:any ) => {
        if (enrollment.version === 1 && activity.activityStatus > 14) {
            status[activity.activityStatus] = {
                text: "N/A for Legacy Enrollment",
                date: "",
            };
        } else {
            status[activity.activityStatus] = {
                text: dateUtils.asDbDateFormat(activity.updatedAt) ? activity.override ? <><span className="textBlack"><sup>*</sup></span> Completed</> : "Completed"  : "Incomplete",
                date: dateUtils.asDbDateFormat(activity.updatedAt) ? dateUtils.asDbDateFormat(activity.updatedAt) : "",
            };
        }
        if (!showOverrideText && activity.override) {
            setShowOverrideText(true);
        }
    });

    const handleClose = () => {
        setViewType("");
    };

    const showPdfDialog = () => {

        return (
                <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={openPdf}
                        aria-labelledby="Enrollment Home"
                        aria-describedby="Enrollment process"
                >
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                        setDocumentUrl("");
                        setOpenPdf(false);
                    }}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle className="dialogHead">
                        <Typography variant="h5" align='center'>This is your completed application that has been
                            submitted. You can download for your records.
                        </Typography>
                    </DialogTitle>
                    <DialogContent className="pr30 pl30">
                        <div id="alert-dialog-description" className="textCenter" style={{ height: "80vh" }}>
                            {inProgress ?
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} md={12} className="textCenter">
                                            <DocusignLoader/>
                                        </Grid>
                                    </Grid>
                                    :
                                    <iframe
                                        className={"enrollmentIframe"}
                                        src={`${documentUrl}`}
                                        onLoad={noop}
                                        style={{width: "100%", height: "80vh"}}
                                    />
                            }
                        </div>
                    </DialogContent>
                    <DialogActions style={{ display: "none" }}>
                        <Button style={{ display: "none" }} id={"closebtn"} color="primary" variant="contained"
                                className="mb20 mr30 mt15"
                                onClick={() => {
                                    setDocumentUrl("");
                                    setOpenPdf(false);
                                }}>
                            Close
                        </Button>
                        <Button style={{ display: "none" }} id={"adminclosebtn"} color="primary" variant="contained"
                                className="mb20 mr30 mt15"
                                onClick={() => {
                                    setDocumentUrl("");
                                    setOpenPdf(false);
                                }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    };

    const showProposalPdfDialog = () => {

        return (
                <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={openProposalPdf.length > 0}
                        aria-labelledby="Enrollment Home"
                        aria-describedby="Enrollment process"
                >
                    <DialogTitle id="customized-dialog-title">
                        Client Proposal
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid container justify="center" spacing={2}>
                            <ProposalTabs
                                    activeProposalTab={activeProposalTab}
                                    callBack={() => {
                                    }}
                                    proposalObjArr={openProposalPdf}
                                    setActiveProposalTab={setActiveProposalTab}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" variant="contained" className="mb20 mr30 mt15"
                                onClick={() => {
                                    setOpenProposalPdf([]);
                                }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    };

    const getStep2Class = () => {
        if(strategySlug !== "cashstream" && (status[AccountStatus.CompletedOnlineQuestionnaire].date && status[AccountStatus.CompletedApplication].date && status[AccountStatus.AgentSigned].date && status[AccountStatus.ApplicationSubmittedToCarrier].date)){
            return "mb0 mt20";
        }
        if(strategySlug === "cashstream" && (status[AccountStatus.CompletedOnlineQuestionnaire].date && status[AccountStatus.CompletedApplication].date && status[AccountStatus.AgentSigned].date)){
            return "mb0 mt20";
        }
        return "textLight mb0 mt20";
    }

    const getStep3Class = () => {
        if(strategySlug !== "cashstream" && (status[AccountStatus.ProposalSubmittedToAdvisor].date && status[AccountStatus.ProposalApprovedByAdvisor].date && status[AccountStatus.ProposalSentToClient].date && status[AccountStatus.ProposalSignedByClient].date)){
            return "mb0 mt20";
        }
        if(strategySlug === "cashstream" && (status[AccountStatus.CompletedOnlineQuestionnaire].date && status[AccountStatus.CompletedApplication].date && status[AccountStatus.AgentSigned].date && status[AccountStatus.ApplicationSubmittedToCarrier].date)){
            return "mb0 mt20";
        }
        return "textLight mb0 mt20";
    }

    const getEnrollmentAdminProposalUrl = async (eId: string, adminProposal: string) => {
        let file = adminProposal.split(".");
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
        let queryObj = {
            objectName: adminProposal,
            contentType: contentType,
        };
        return awsActions.getSecureUrl(eId, queryObj, true);
    };

    return (
            <>
            {showOverrideText && adminName ? <div className="textRed mb5" style={{fontStyle: "italic"}}>*This case have been marked completed by {adminName} via system override.</div> : null}
            <div className={classes.root}>
                {showPdfDialog()}
                {showProposalPdfDialog()}
                <Card>
                    <CardContent className="pd40">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                        className="floatLeft mt15 forMobile"
                                        variant="subtitle1"
                                ><strong>Invitation and Account Creation</strong></Typography>
                                <img className="floatRight" src={participantStatusInvitation} alt={"Account Creation"}/>
                            </Grid>

                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">Invited</Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[AccountStatus.Invited].date ? "textHighLight" : ""}`}>{status[AccountStatus.Invited].date && status[AccountStatus.Invited].date}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.Invited].date ? "textHighLight" : ""}`}>{status[AccountStatus.Invited].date && status[AccountStatus.Invited].text}</Typography>
                            </Grid>
                            {/*Opened Email Invite show only if client is individual*/}
                            {clientDetail && clientDetail.role == 5 && clientDetail.client && !clientDetail.client.clientListId &&
                            <>
                                <Grid item xs={6} md={8}>
                                    <Typography variant="body1" className="floatLeft mb0">Opened Email
                                        Invite</Typography>
                                    {!status[AccountStatus.Opened].date && status[AccountStatus.CreatedAccount].date ?
                                            <Typography variant="body1"
                                                        className={`floatRight forMobile mb0 ${status[AccountStatus.CreatedAccount].date ? "textHighLight" : ""}`}> {status[AccountStatus.CreatedAccount].date && status[AccountStatus.CreatedAccount].date}</Typography>
                                            : <Typography variant="body1"
                                                          className={`floatRight forMobile mb0 ${status[AccountStatus.Opened].date ? "textHighLight" : ""}`}> {status[AccountStatus.Opened].date && status[AccountStatus.Opened].date}</Typography>
                                    }
                                </Grid>

                                <Grid item xs={6} md={4}>
                                    {!status[AccountStatus.Opened].date && status[AccountStatus.CreatedAccount].date ?
                                            <Typography variant="body1"
                                                        className={`floatRight mb0 ${status[AccountStatus.CreatedAccount].date ? "textHighLight" : ""}`}>{status[AccountStatus.CreatedAccount].text}</Typography> :
                                            <Typography variant="body1"
                                                        className={`floatRight mb0 ${status[AccountStatus.Opened].date ? "textHighLight" : ""}`}>{status[AccountStatus.Opened].text}</Typography>
                                    }
                                </Grid>
                            </>
                            }

                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">Created Account</Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[AccountStatus.CreatedAccount].date ? "textHighLight" : ""}`}>{status[AccountStatus.CreatedAccount].date && status[AccountStatus.CreatedAccount].date}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.CreatedAccount].date ? "textHighLight" : ""}`}>{status[AccountStatus.CreatedAccount].text}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <span className="hLine"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                        className="floatLeft mt15 forMobile"
                                        variant="subtitle1"
                                ><strong><StrategyName
                                        strategyName={strategyName}/>&nbsp;Enrollment Process</strong></Typography>
                                <img className="floatRight" src={participantStatusEnrollment} alt={"Enrollment"}/>
                            </Grid>

                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">Started Enrollment</Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[AccountStatus.EnrollmentStarted].date ? "textHighLight" : ""}`}> {status[AccountStatus.EnrollmentStarted].date && status[AccountStatus.EnrollmentStarted].date}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.EnrollmentStarted].date ? "textHighLight" : ""}`}>{status[AccountStatus.EnrollmentStarted].text}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1" component="strong"
                                            className={status[AccountStatus.CompletedBasicInfo].date ? "mb0 mt20" : "textLight mb0 mt20"}>STEP
                                    1: BASIC INFO </Typography>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">Basic info, contribution, Required Doc (DL, Tax Returns, etc.)</Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0  ${status[AccountStatus.CompletedBasicInfo].date ? "textHighLight" : ""}`}> {status[AccountStatus.CompletedBasicInfo].date && status[AccountStatus.CompletedBasicInfo].date}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.CompletedBasicInfo].date ? "textHighLight" : ""}`}>{status[AccountStatus.CompletedBasicInfo].text}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                        variant="body1"
                                        component="strong"
                                        className={getStep2Class()}
                                >STEP
                                    2: APPLICATION </Typography>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">Questionnaire
                                    Completed</Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[AccountStatus.CompletedOnlineQuestionnaire].date ? "textHighLight" : ""}`}> {status[AccountStatus.CompletedOnlineQuestionnaire].date && status[AccountStatus.CompletedOnlineQuestionnaire].date}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.CompletedOnlineQuestionnaire].date ? "textHighLight" : ""}`}>{status[AccountStatus.CompletedOnlineQuestionnaire].text}</Typography>
                            </Grid>

                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">Client Application
                                    Signed</Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[AccountStatus.CompletedApplication].date ? "textHighLight" : ""}`}> {status[AccountStatus.CompletedApplication].date && status[AccountStatus.CompletedApplication].date}</Typography>
                                {/*{enrollmentId && (role == 1 || role == 2 || (role == 4 && documentCompleted)) && status[AccountStatus.CompletedApplication].date &&*/}
                                {/*<>*/}
                                {/*    <br/>*/}
                                {/*    <Link variant="body1" className="floatLeft mb0 linksBtn"*/}
                                {/*          onClick={() => {*/}
                                {/*              getEnvelope(enrollmentId);*/}
                                {/*              setOpenPdf(true);*/}
                                {/*          }}*/}
                                {/*    >View Application</Link>*/}
                                {/*</>*/}
                                {/*}*/}
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight  mb0 ${status[AccountStatus.CompletedApplication].date ? "textHighLight" : ""}`}>{status[AccountStatus.CompletedApplication].text}</Typography>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">Agent Report
                                    Signed</Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile  mb0 ${status[AccountStatus.AgentSigned].date ? "textHighLight" : ""}`}>{status[AccountStatus.AgentSigned].date && status[AccountStatus.AgentSigned].date}</Typography>
                                {/*{enrollmentId && (role == 1 || role == 2 || role == 4) && status[AccountStatus.AgentSigned].date &&*/}
                                {/*<>*/}
                                {/*    <br/>*/}
                                {/*    <Link variant="body1" className="floatLeft mb0 linksBtn"*/}
                                {/*          onClick={() => {*/}
                                {/*              getEnvelope(enrollmentId);*/}
                                {/*              setOpenPdf(true);*/}
                                {/*          }}*/}
                                {/*    >View Report</Link>*/}
                                {/*</>*/}
                                {/*}*/}
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.AgentSigned].date ? "textHighLight" : ""}`}>{status[AccountStatus.AgentSigned].text}</Typography>
                            </Grid>
                            {strategySlug !== "cashstream" &&
                            <React.Fragment>
                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">
                                    Application Submitted to Carrier
                                </Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[AccountStatus.ApplicationSubmittedToCarrier].date ? "textHighLight" : ""}`}>
                                    {status[AccountStatus.ApplicationSubmittedToCarrier].date && status[AccountStatus.ApplicationSubmittedToCarrier].date}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.ApplicationSubmittedToCarrier].date ? "textHighLight" : ""}`}>
                                    {status[AccountStatus.ApplicationSubmittedToCarrier].text}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">
                                    Application Received By Carrier
                                    { status[AccountStatus.ApplicationReceivedByCarrier].date ?  <Typography variant="body2" display="block"> GUID: {enrollmentId} </Typography> : "" }
                                </Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[AccountStatus.ApplicationReceivedByCarrier].date ? "textHighLight" : ""}`}>
                                    {status[AccountStatus.ApplicationReceivedByCarrier].date && status[AccountStatus.ApplicationReceivedByCarrier].date}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.ApplicationReceivedByCarrier].date ? "textHighLight" : ""}`}>
                                    {status[AccountStatus.ApplicationReceivedByCarrier].text}
                                </Typography>
                            </Grid>
                            </React.Fragment>
                            }
                            <Grid item xs={12}>
                                <Typography variant="body1" component="strong"
                                            className={getStep3Class()}>STEP
                                    3: PROPOSAL</Typography>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0"> Submitted to Agent</Typography>

                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[AccountStatus.ProposalSubmittedToAdvisor].date ? "textHighLight" : ""}`}>{status[AccountStatus.ProposalSubmittedToAdvisor].date && status[AccountStatus.ProposalSubmittedToAdvisor].date}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${status[AccountStatus.ProposalSubmittedToAdvisor].date ? "textHighLight" : ""}`}>{status[AccountStatus.ProposalSubmittedToAdvisor].text}</Typography>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0"> Approved by
                                    Agent {enrollment && ((enrollment.enrollmentStatus + "." + enrollment.enrollmentSubStatus) !== "1.6" && (enrollment.enrollmentStatus + "." + enrollment.enrollmentSubStatus) !== "1.7") && status[AccountStatus.ProposalSentToClient].date && "(sent to client)"}</Typography>
                                {enrollment && ((enrollment.enrollmentStatus + "." + enrollment.enrollmentSubStatus) === "1.6") ?
                                        <Typography
                                                variant="body1"
                                                className={`floatRight forMobile mb0 textRed`}
                                        >
                                            {dateUtils.asDbDateFormat(enrollment.updatedAt)}
                                        </Typography>
                                        :
                                        <Typography variant="body1"
                                                    className={`floatRight forMobile mb0 ${status[AccountStatus.ProposalApprovedByAdvisor].date ? "textHighLight" : ""}`}>{status[AccountStatus.ProposalApprovedByAdvisor].date && status[AccountStatus.ProposalApprovedByAdvisor].date}</Typography>
                                }
                            </Grid>
                            <Grid item xs={6} md={4}>
                                {enrollment && ((enrollment.enrollmentStatus + "." + enrollment.enrollmentSubStatus) === "1.6") ?
                                        <Typography variant="body1" className={`floatRight mb0 textRed`}>
                                            Change Requested
                                        </Typography>
                                        :
                                        <Typography variant="body1"
                                                    className={`floatRight mb0 ${status[AccountStatus.ProposalApprovedByAdvisor].date ? "textHighLight" : ""}`}>
                                            {status[AccountStatus.ProposalApprovedByAdvisor].text
                                            }
                                        </Typography>
                                }
                            </Grid>

                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">
                                    Approved / Signed by Client
                                </Typography>
                                {enrollment && ((enrollment.enrollmentStatus + "." + enrollment.enrollmentSubStatus) === "1.7") ?
                                        <Typography
                                                variant="body1"
                                                className={`floatRight forMobile mb0 textRed`}
                                        >
                                            {dateUtils.asDbDateFormat(enrollment.updatedAt)}
                                        </Typography>
                                        :
                                        <>
                                            <Typography variant="body1"
                                                        className={`floatRight forMobile mb0 ${status[AccountStatus.ProposalSignedByClient].date ? "textHighLight" : ""}`}>{status[AccountStatus.ProposalSignedByClient].date && status[AccountStatus.ProposalSignedByClient].date}
                                            </Typography>
                                            {(role == 1 || role == 2 || role == 4) && status[AccountStatus.ProposalSignedByClient].date &&
                                            <>
                                                <br/>
                                                <Link variant="body1" className="floatLeft mb0 linksBtn"
                                                      onClick={async () => {
                                                         let documents:any[] = await getClientDocumentByType(clientDetail && clientDetail.client && clientDetail.client.id || 0, ClientDocumentType.ADMIN_PROPOSAL);
                                                          if (documents.length > 0) {
                                                              const proposalArr: Proposal[] = [];
                                                              for (let i = 0; i < documents.length; i++) {
                                                             const proposalDocument = documents[i] as ClientDocsInterface;
                                                             const url = await getEnrollmentAdminProposalUrl(enrollmentId, pez(proposalDocument.path));
                                                                  proposalArr.push({
                                                                      name: proposalDocument.name,
                                                                      url: url,
                                                                  });
                                                              }
                                                              setActiveProposalTab(0);
                                                              setOpenProposalPdf(proposalArr);
                                                         } else {
                                                              setOpenProposalPdf([]);
                                                         }
                                                      }}
                                                >
                                                    View proposal
                                                </Link>
                                            </>
                                            }
                                        </>
                                }
                            </Grid>
                            <Grid item xs={6} md={4}>
                                {enrollment && ((enrollment.enrollmentStatus + "." + enrollment.enrollmentSubStatus) === "1.7") ?
                                        <Typography variant="body1" className={`floatRight mb0 textRed`}>
                                            Change Requested
                                        </Typography>
                                        :
                                        <Typography variant="body1"
                                                    className={`floatRight mb0 ${status[AccountStatus.ProposalSignedByClient].date ? "textHighLight" : ""}`}>
                                            {status[AccountStatus.ProposalSignedByClient].text
                                            }
                                        </Typography>
                                }
                            </Grid>
                            {strategySlug === "cashstream" &&
                            <React.Fragment>
                                <Grid item xs={6} md={8}>
                                    <Typography variant="body1" className="floatLeft mb0">
                                        Application Submitted to Carrier
                                    </Typography>
                                    <Typography variant="body1"
                                                className={`floatRight forMobile mb0 ${status[AccountStatus.ApplicationSubmittedToCarrier].date ? "textHighLight" : ""}`}>
                                        {status[AccountStatus.ApplicationSubmittedToCarrier].date && status[AccountStatus.ApplicationSubmittedToCarrier].date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="body1"
                                                className={`floatRight mb0 ${status[AccountStatus.ApplicationSubmittedToCarrier].date ? "textHighLight" : ""}`}>
                                        {status[AccountStatus.ApplicationSubmittedToCarrier].text}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography variant="body1" className="floatLeft mb0">
                                        Application Received By Carrier
                                        { status[AccountStatus.ApplicationReceivedByCarrier].date ?  <Typography variant="body2" display="block"> GUID: {enrollmentId} </Typography> : "" }
                                    </Typography>
                                    <Typography variant="body1"
                                                className={`floatRight forMobile mb0 ${status[AccountStatus.ApplicationReceivedByCarrier].date ? "textHighLight" : ""}`}>
                                        {status[AccountStatus.ApplicationReceivedByCarrier].date && status[AccountStatus.ApplicationReceivedByCarrier].date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="body1"
                                                className={`floatRight mb0 ${status[AccountStatus.ApplicationReceivedByCarrier].date ? "textHighLight" : ""}`}>
                                        {status[AccountStatus.ApplicationReceivedByCarrier].text}
                                    </Typography>
                                </Grid>
                            </React.Fragment>
                            }

                            {strategySlug !== "cashstream" &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" component="strong"
                                                    className={(status[AccountStatus.FundingReceived].date && status[AccountStatus.TrusteeSignAndEnrollmentClosed].date) ? "mb0 mt20" : "textLight mb0 mt20"}>STEP
                                            4: TRUSTEE SIGNS & FINAL DOCS COMPLETED</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="body1" className="floatLeft mb0">Client Payment Received</Typography>
                                        <Typography variant="body1"
                                        className={`floatRight forMobile mb0 ${status[AccountStatus.FundingReceived].date ? "textHighLight" : ""}`}>{status[AccountStatus.FundingReceived].date && status[AccountStatus.FundingReceived].date} </Typography>

                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography variant="body1"
                                        className={`floatRight mb0 ${status[AccountStatus.FundingReceived].date ? "textHighLight" : ""}`}>{status[AccountStatus.FundingReceived].text}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="body1" className="floatLeft mb0">Trustee Signed</Typography>
                                        <Typography variant="body1"
                                        className={`floatRight forMobile mb0 ${status[AccountStatus.TrusteeSignAndEnrollmentClosed].date ? "textHighLight" : ""}`}>{status[AccountStatus.TrusteeSignAndEnrollmentClosed].date && status[AccountStatus.TrusteeSignAndEnrollmentClosed].date} </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography variant="body1"
                                        className={`floatRight mb0 ${status[AccountStatus.TrusteeSignAndEnrollmentClosed].date ? "textHighLight" : ""}`}>{status[AccountStatus.TrusteeSignAndEnrollmentClosed].text}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="body1" className="floatLeft mb0">Final Docs Sent To Carrier</Typography>
                                        <Typography variant="body1"
                                                    className={`floatRight forMobile mb0 ${status[AccountStatus.FinalDocSentToCarrier].date ? "textHighLight" : ""}`}>{status[AccountStatus.FinalDocSentToCarrier].date && status[AccountStatus.FinalDocSentToCarrier].date} </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography variant="body1"
                                                    className={`floatRight mb0 ${status[AccountStatus.FinalDocSentToCarrier].date ? "textHighLight" : ""}`}>{status[AccountStatus.FinalDocSentToCarrier].text}</Typography>
                                    </Grid>
                                </React.Fragment>
                            }

                            {strategySlug === "cashstream" &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" component="strong"
                                                    className={(status[AccountStatus.FundingReceived].date ) ? "mb0 mt20" : "textLight mb0 mt20"}>STEP
                                            4: FUNDING </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography variant="body1" className="floatLeft mb0">Client Payment Received</Typography>
                                        <Typography variant="body1"
                                        className={`floatRight forMobile mb0 ${status[AccountStatus.FundingReceived].date ? "textHighLight" : ""}`}>{status[AccountStatus.FundingReceived].date && status[AccountStatus.FundingReceived].date} </Typography>

                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography variant="body1"
                                        className={`floatRight mb0 ${status[AccountStatus.FundingReceived].date ? "textHighLight" : ""}`}>{status[AccountStatus.FundingReceived].text}</Typography>
                                    </Grid>
                                </React.Fragment>
                            }

                            <Grid item xs={12}>
                                <span className="hLine"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                        className="floatLeft mt15 forMobile"
                                        variant="subtitle1"
                                ><strong>Policy Servicing</strong></Typography>
                                <img width="80" className="floatRight" src={visaGreencard} alt={"Policy"}/>
                            </Grid>

                            <Grid item xs={6} md={8}>
                                <Typography variant="body1" className="floatLeft mb0">Current Policy Status</Typography>
                                <Typography variant="body1"
                                            className={`floatRight forMobile mb0 ${status[23].date || status[24].date || status[25].date ? "textHighLight" : ""}`}>{status[23].date || status[24].date || status[25].date}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="body1"
                                            className={`floatRight mb0 ${clientDetail && clientDetail.client && (clientDetail.client.currentPolicyStatus === PolicyStatusEnum.InForce || clientDetail.client.currentPolicyStatus === PolicyStatusEnum.InForceOverride) ? "textHighLight" : clientDetail && clientDetail.client && clientDetail.client.currentPolicyStatus === PolicyStatusEnum.Deceased || clientDetail.client && clientDetail.client.currentPolicyStatus === PolicyStatusEnum.Surrendered ? "colorRed" : ""}`}>
                                    {clientDetail && clientDetail.client && (clientDetail.client.currentPolicyStatus === PolicyStatusEnum.InForce || clientDetail.client.currentPolicyStatus === PolicyStatusEnum.InForceOverride) ? clientDetail.client.currentPolicyStatus === PolicyStatusEnum.InForceOverride ? <><span className="textBlack"><sup>*</sup></span> Policy Issued</> : "Policy Issued" : clientDetail.client.currentPolicyStatus === PolicyStatusEnum.Surrendered ? 'Surrendered' : clientDetail.client.currentPolicyStatus === PolicyStatusEnum.Deceased ? 'Deceased' : 'N/A'  }
                                </Typography>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
                {viewType && <ViewPopUp clientDetail={clientDetail} viewType={viewType} open={!!viewType}
                                        handleClose={handleClose}/>}
            </div>
            </>
    );
}