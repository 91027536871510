import React from "react";
import { Formik } from "formik";
import { payloadDataHandler } from "../index";
import FinancialAgreement from "./FinancialAgreement";
import { useActions } from "../../../../../../../actions";
import * as Actions from "../../../../../../../actions/enrollment";
import { getRelatedKeysFromObject } from "../../../../../../../utils/commonUtils";

type PropsType = {
	autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void,
	initialValState: any,
	validationSchema: any,
	updateClientFields: Function,
	disableSaveButton: Function,
	user: any,
	isFromClientDetail: boolean,
	errorInForm: boolean,
	setErrorInForm: Function,
	questionList: any,
	fieldObj1: any,
	enableSaveButton: Function,
	showComponent: Function,
	clientSigned: boolean | number,
	fieldObj4: any,
	getComp: Function,
	showComponent1: Function,
	showComponent2: Function,
	fieldObj5: any,
	financialAgreementRef: any,
	clientDetail: any,
	allianzId: number,
	setFinancialAndLiabilitiesArr: Function,
	financialAndLiabilitiesArr: any,
	financialAgreementFormRef: any,
	setSaveFinancialAgreementForm: Function
	formType: string
	setFormType: Function
}

function FinancialAgreementForm(props: PropsType) {
	const {
		autoSaveTrigger,
		initialValState,
		validationSchema,
		disableSaveButton,
		questionList,
		fieldObj1,
		enableSaveButton,
		showComponent,
		clientSigned,
		fieldObj4,
		getComp,
		showComponent1,
		showComponent2,
		fieldObj5,
		financialAgreementRef,
		clientDetail,
		allianzId,
		setFinancialAndLiabilitiesArr,
		financialAndLiabilitiesArr,
		financialAgreementFormRef,
		setSaveFinancialAgreementForm,
		formType,
		setFormType,
        user
	} = props;

    const enrollmentActions = useActions(Actions);

    const saveErollmentAnswers = async (values:any) => {
        const payload:any = getRelatedKeysFromObject("FinancialQuestionsX", values)

        let enrollmentPayload: any = {
            ...payload,
            id: (user && user.enrollment && user.enrollment.id),
            userId: ((user && user.enrollment && user.enrollment.userId) || (clientDetail && clientDetail.userId)),
        }
        await enrollmentActions.updateEnrollment(enrollmentPayload, false);
    }

	return (
		<>
			<Formik
				innerRef={financialAgreementFormRef}
				initialValues={initialValState}
				enableReinitialize={true}
                validateOnMount={true}
				validationSchema={validationSchema}
				validateOnChange={true}
				onSubmit={async (values, { setSubmitting }) => {
					let payload: any = payloadDataHandler(values, "FINANCIAL_AGREEMENT", false);
					const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "FINANCIAL_AGREEMENT");

					if (index > -1) {
						financialAndLiabilitiesArr.splice(index, 1);
					}
					setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
						...payload,
					}]);

                    saveErollmentAnswers(values);
					disableSaveButton();
				}}
			>
				{
					({
						 errors,
					 }) => {
						return (
							<FinancialAgreement
								autoSaveTrigger={autoSaveTrigger}
								fieldObj1={fieldObj1}
								getComp={getComp}
								questionList={questionList}
								clientDetail={clientDetail}
								allianzId={allianzId}
								enableSaveButton={enableSaveButton}
								clientSigned={clientSigned}
								showComponent={showComponent}
								showComponent1={showComponent1}
								showComponent2={showComponent2}
								fieldObj4={fieldObj4}
								financialAgreementRef={financialAgreementRef}
								fieldObj5={fieldObj5}
								setSaveFinancialAgreementForm={setSaveFinancialAgreementForm}
								formType={formType}
								setFormType={setFormType}
							/>
						);
					}}
			</Formik>
		</>
	);
}

export default FinancialAgreementForm;