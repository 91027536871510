import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import StrategyName from "../../../../../components/client/StrategyName";
import {maskPhoneNumber} from "../../../../../utils/commonUtils";
import {useActions} from "../../../../../actions";
import * as CompanyActions from "../../../../../actions/company";
import * as AuthActions from "../../../../../actions/auth";
import {history} from "../../../../../configureStore";

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: 18,
        color: theme.palette.grey[500],
    }
}));
type kickOutProps = {
    participantId:number,
    isFromClientDetail: boolean,
    openKickOut: boolean,
    setOpenKickOut: Function,
    groupAdminEmail: string,
    groupAdminPhone: string,
    groupAdminFullName: string,
    strategyName: string
}
const KickOut = (props: kickOutProps) => {
    const {openKickOut, setOpenKickOut, groupAdminEmail, groupAdminPhone, groupAdminFullName, strategyName, participantId, isFromClientDetail} = props;
    const classes = useStyles();
    const companyActions = useActions(CompanyActions);
    const authActions = useActions(AuthActions);

    React.useEffect(()=> {
        if(!isFromClientDetail && openKickOut && participantId){
            companyActions.updateGroupParticipant(participantId, "Client trying to full app.", false);
        }
    }, [openKickOut === true])

    const handleClose = async () => {
        if(!isFromClientDetail && participantId){
            await authActions.LogOut();
            history.replace("/accounts");
            return false;
        }
        setOpenKickOut(false);
    }

    return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openKickOut}
                    aria-labelledby="Enrollment Home"
                    aria-describedby="Enrollment process"
            >
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                    handleClose();
                }}>
                    <CloseIcon/>
                </IconButton>
                <DialogTitle className="dialogHead">
                    <Typography variant="subtitle1" className="mb0">Requirements Not Met
                    </Typography>
                </DialogTitle>
                <DialogContent  dividers className="borderBottom0 pd0 mb20">
                    <div id="alert-dialog-description" className="textCenter-" >
                         <Typography variant="body1" className="mb0"> We are unable to advance your application based on <StrategyName strategyName={strategyName}/> requirements.
                             Please contact {groupAdminFullName} at {maskPhoneNumber(groupAdminPhone)} or email at {groupAdminEmail}.</Typography>
                    </div>
                </DialogContent>
            </Dialog>
    );
};

export default KickOut;