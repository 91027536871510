import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import * as Estimations from "../../../../../actions/estimation";
import {
    GWT_GEN2_STRATEGY,
    minContributionAmount,
    otherIndustryId,
    AllianzLifeCarrierDetail, USA, stateDropDown, OTHER_VALUE,
} from "../../../../../constants";
import moment from "moment";
import AllianzAlert from "../../component/AllianzAlert";
import IncomeAlert from "../../component/IncomeAlert";
import { asDbDateTimeFormat, DATE_FORMAT_DOB_SAVE, getCurrentYear } from "../../../../../utils/dateUtils";
import numeral from "numeral";
import {
	PhysicianAndMedicalDetailsStyle,
} from "../EnrollmentApplication/Style";
import { scroller } from "react-scroll";
import KickOut from "../../component/EnrollmentHome/KickOut";
import PersonalDetailFormikContent from "./PersonalDetailFormikContent";
import PersonalDetailValidate from "./PersonalDetailValidate";
import CommonFieldFunction from "./CommonFieldFunction";
import { formatSSN } from "./PersonalDetailUtils";
import PersonalDetailFieldObj from "./PersonalDetailFieldObj";
import UpdateEmailReqPopUp from "./UpdateEmailReqPopUp";
import { incomeDetailsColArr } from "./PersonalDetailFormikContent";
import { convertToNumber } from "../EnrollmentApplication/Utilities";

const initVal = {
	proofExpiryYear: 0,
	proofExpiryMonth: 0,
	gender: "",
	immigrationStatus: "",
	residenceInHomeCountry: "",
	currentlyEmployed: "",
	currentlyEmployedSelf: "",
	marriageStatus: "",
	employedTime: 0,
	industryId: 0,
	occupationId: 0,
};

const PersonalDetailFormV2 = (props: {
	setNextStep: Function,
	setActionItem: Function,
	isFromClientDetail?: boolean,
	clientDetail?: any,
	setPersonalDetailRef?: Function,
	setIsPersonalFormDirty?: Function,
	setIsFormSubmitting?: Function,
	setCarrierValAlert?: Function,
	setValidationError?: Function,
	setIsDocumentAddress?: Function,
	getClientDetail?: Function,
	urlClientId?: string,
	ssnFlag?: boolean
}) => {
	const classes = PhysicianAndMedicalDetailsStyle();
	const {
		setNextStep,
		isFromClientDetail,
		setPersonalDetailRef,
		setIsPersonalFormDirty,
		setIsFormSubmitting,
		setCarrierValAlert,
		setActionItem,
		setValidationError,
		getClientDetail,
        urlClientId,
        ssnFlag,
	} = props;
	const accountActions = useActions(Actions);
	const estimationActions = useActions(Estimations);
	const { setIsDocumentAddress } = props;

	let userData = useSelector((state: RootState) => state.profile);
	let { user: { role, participant } } = useSelector((state: RootState) => state.auth);
	let user = props.clientDetail || userData;
	const { client } = user;

	const conditionalRequired = !(role === 1 || role === 2 || role === 4);
	const [selfEmployed, setSelfEmployed] = React.useState(false);
	const [currentlyEmployed, setCurrentlyEmployed] = React.useState<string>("");
	const [fullTimeEmployee, setFullTimeEmployee] = React.useState<number>(1);
	const [carrierId, setCarrierId] = React.useState<number | null>(null);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [allianzId, setAllianzId] = React.useState<number | null>(null);
	const [carrierName, setCarrierName] = React.useState<string | undefined>("");
	const [minVal, setMinVal] = React.useState(minContributionAmount);
	const [ageSelected, setAgeSelected] = React.useState(false);
	const [incomeAlertOpen, setIncomeAlertOpen] = React.useState(false);
	const [proofExpired, setProofExpired] = React.useState(false);
	const [nationalLifeId, setNationalLifeId] = React.useState<number | null>(null);
	const [isNationalLife, setIsNationalLife] = React.useState<boolean>(false);
	const [isAllianzLife, setIsAllianzLife] = React.useState<boolean>(false);
	const [minAge, setMinAge] = React.useState<number>(25);
	const [strategySlug, setStrategySlug] = React.useState("");
	const [labelsActive, setLabelsActive] = React.useState<any[]>([]);

	const currentYear = getCurrentYear();
	const personalDetailSubmit = React.useRef<any>();
	const proofExpireRef: any = useRef(null);
	const personalDetailForm: any = React.useRef<any>(null);
	const [isFormSubmit, setIsFormSubmit] = React.useState<boolean>(false);
	const [openKickOutPopup, setOpenKickOutPopup] = useState(false);
	const [fullTimeSelected, setFullTimeSelected] = useState(0);
	const [isMarried, setIsMarried] = useState(false);
	const [isEmployed, setIsEmployed] = useState(false);
	const [provideCorrectEmailPopUp, setProvideCorrectEmailPopUp] = useState(false);
	const [isParentEarner, setIsParentEarner] = React.useState<boolean>(false);
	const [saveRequest, sendSaveRequest] = React.useState<any>(Math.random());
	const [formValues, setFormValues] = React.useState<any>({});
	const [isIndustryOther, setIsIndustryOther] = React.useState(false);
	const [serverError, setServerError] = React.useState(false);
	const clientSigned = (user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId)) ? 1 : 0; // check envelopId generated too
    const checkEmployeeIdStateValue = obj => obj.name === client.employeeIdState;
    const theEmployeeIdStateValue = stateDropDown.some(checkEmployeeIdStateValue) ? client.employeeIdState : "";
    const [employeeIdStateValue, setEmployeeIdStateValue] = React.useState<string>(theEmployeeIdStateValue);
    const [birthCountryValue, setBirthCountryValue] = React.useState<string>(client.birthCountry);
    const checkBirthStateValue = obj => obj.name === client.birthState;
    const theBirthStateValue = stateDropDown.some(checkBirthStateValue) ? client.birthState : "";
    const [birthStateValue, setBirthStateValue] = React.useState<string>(theBirthStateValue);
    const checkStateValue = obj => obj.name === client.state;
    const theStateValue = stateDropDown.some(checkStateValue) ? client.state : "";
    const [stateValue, setStateValue] = React.useState<string>(theStateValue);
    const [currentResidenceCountryValue, setCurrentResidenceCountryValue] = React.useState<string>(null || USA);

	const getAllianzCarrierDetails = async () => {
		const allianzData = await accountActions.getAllianzDetails();
		if (allianzData && allianzData.allianzLife && allianzData.allianzLife.id) {
			setAllianzId(allianzData.allianzLife.id);
		}
	};
	const getNatioanlLifeCarrierDetails = async () => {
		const nationalLifeDate = await accountActions.getNationalLifeDetails();
		if (nationalLifeDate && nationalLifeDate.nationalLife) {
			setNationalLifeId(nationalLifeDate.nationalLife.id);
		}
	};

	const scrollUp = async (id) => {
		setIsFormSubmit(false);
		scroller.scrollTo(id, {
			duration: 800,
			delay: 0,
			smooth: "easeInOutQuart",
			offset: -200,
		});
		return await accountActions.setSnackBarMessage(false, `Drivers license has been expired`);
	};

	const [initialValues, setInitialValues] = React.useState<any>(initVal);
	let dob = user && user.client && user.client.dob;
    const isGWTGen2 = (user && user.client && user.client.strategyId === GWT_GEN2_STRATEGY);

	useEffect(() => {
		let proofExpireDate = client && client.employeeIdExpireDate;
		let proofExpiryYear = 0;
		let proofExpiryMonth = 0;
		if (proofExpireDate) {
			let proofExpireDateArr = proofExpireDate.split("-");
			proofExpiryYear = Number(proofExpireDateArr[0]);
			proofExpiryMonth = Number(proofExpireDateArr[1]);
		}
		let formVal: any = {
			proofExpiryYear: proofExpiryYear,
			proofExpiryMonth: proofExpiryMonth,
		};

		if (client) {
			formVal = {
				...formVal,
				firstName: user.firstName || "",
				middleName: user.middleName || "",
				lastName: user.lastName || "",
				cellPhone: user.phoneNumber || "",
				email: user.email || "",
				usCitizen: !!client.usCitizen,
                nonUsCitizen: client.nonUsCitizen,
                applicationState: client.applicationState ? client.applicationState : 'Nevada',
				identityId: client.employeeIdNumber || "",
				employeeSSN: formatSSN(client.employeeSSN || ""),
				blotSSN: client.blotSSN || "",
                birthState: (client.birthCountry === USA) ? {
                    key: theBirthStateValue,
                    name: theBirthStateValue,
                    title: theBirthStateValue,
                } : theBirthStateValue || "",
                birthCountry: (client.birthCountry) ? {
                    key: client.birthCountry,
                    name: client.birthCountry,
                    title: client.birthCountry,
                } : client.birthCountry || "",
				workPhoneNumber: client.workPhoneNumber || "",
				homePhone: client.homePhone || "",
				gender: client.gender || "",
				dob: asDbDateTimeFormat(client.dob, DATE_FORMAT_DOB_SAVE),
				age: moment().diff(asDbDateTimeFormat(client.dob, DATE_FORMAT_DOB_SAVE), "years") || client.age,
				street: client.street || "",
				city: client.city || "",
                state: {
                    key: theStateValue,
                    name: theStateValue,
                    title: theStateValue,
                },
				zipCode: client.zipCode || "",
				isDocumentAddress: !!client.isDocumentAddress,
				selfEmployed: (client.selfEmployed || client.currentlyEmployed === "SELF_EMPLOYED"),
                currentlyEmployed: isGWTGen2 ? "Other" : client.currentlyEmployed || "FULL_TIME",
				currentlyEmployedSelf: client.currentlyEmployedSelf || "FULL_TIME",
				fullTimeEmployee: client.fullTimeEmployee,
				howLongInUS: client.howLongInUS || "",
				countryCitizenship: client.countryCitizenship || "",
				immigrationStatus: client.immigrationStatus || "",
				greenCardNo: client.greenCardNo || "",
				typeOfVisaPlanHeld: client.typeOfVisaPlanHeld || "",
				typeOfVisaHeld: client.typeOfVisaHeld || "",
				immigrationStatusOther: client.immigrationStatusOther || "",
				residenceInHomeCountry: (client.residenceInHomeCountry && client.residenceInHomeCountry.toString()) || "0",
				residenceInHomeCountryExplain: client.residenceInHomeCountryExplain || "",
				employer: client.employer ? client.employer : (client.company && client.company.groupType === "Corporation") ? client.company.companyName : "",
				employedTime: client.employedTime || "",
				annualIncome: (client.annualIncome && numeral(client.annualIncome).value() > 0 && Number(client.annualIncome).toString()) || "",
				annualIncome1: (client.annualIncome1 && Number(client.annualIncome1).toString()) || "",
				annualIncome2: (client.annualIncome2 && Number(client.annualIncome2).toString()) || "",
				householdAnnualIncome: (client.householdAnnualIncome && Number(client.householdAnnualIncome).toString()) || "", //spouse annual income
				householdNetWorth: (client.householdNetWorth && Number(client.householdNetWorth).toString()) || (client.netWorth && Number(client.netWorth).toString()) || "",
                spouseIncome: ((client.marriageStatus === "Single") && (client.spouseIncome === "" || client.spouseIncome === null)) ? "0" : (client.spouseIncome && Number(client.spouseIncome).toString()) || "",
				householdLiquidAssets: (client.householdLiquidAssets && Number(client.householdLiquidAssets).toString()) || "",
				householdAnnualExpenses: (client.householdAnnualExpenses && Number(client.householdAnnualExpenses).toString()) || "",
				healthType: client.healthType || "",
                employeeIdState: {
                    key: theEmployeeIdStateValue,
                    name: theEmployeeIdStateValue,
                    title: theEmployeeIdStateValue,
                },
				netWorth: (client.netWorth && numeral(client.netWorth).value() > 0 && Number(client.netWorth).toString()) || "",
				employerContribution: client.employerContribution || 0,
				contributionAmount: client.issuedContribution || client.contributionAmount || "",
				marriageStatus: (client && client.marriageStatus) || "", // income details new columns starts here
                usingSpouseIncome: isGWTGen2 ? "0" : client && (typeof client.usingSpouseIncome === "number") ? client.usingSpouseIncome + "" : "0",
				//spouseIncome: (client.spouseIncome && Number(client.spouseIncome).toString()) || "",
				indIncomeLastYearAnnualSalary: (client.annualIncome && numeral(client.annualIncome).value() > 0 && Number(client.annualIncome).toString()) || "",
				indIncomeLastYearDividends: (client.indIncomeLastYearDividends && numeral(client.indIncomeLastYearDividends).value() > 0 && Number(client.indIncomeLastYearDividends).toString()) || "",
				indIncomeLastYearOtherIncome: (client.indIncomeLastYearOtherIncome && numeral(client.indIncomeLastYearOtherIncome).value() > 0 && Number(client.indIncomeLastYearOtherIncome).toString()) || "0",
				indIncomeLastYearTotal: (client.indIncomeLastYearTotal && numeral(client.indIncomeLastYearTotal).value() > 0 && Number(client.indIncomeLastYearTotal).toString()) || "",
				indIncome1YearsPriorAnnualSalary: (client.annualIncome1 && numeral(client.annualIncome1).value() > 0 && Number(client.annualIncome1).toString()) || "",
				indIncome1YearsPriorDividends: (client.indIncome1YearsPriorDividends && numeral(client.indIncome1YearsPriorDividends).value() > 0 && Number(client.indIncome1YearsPriorDividends).toString()) || "",
				indIncome1YearsPriorOtherIncome: (client.indIncome1YearsPriorOtherIncome && numeral(client.indIncome1YearsPriorOtherIncome).value() > 0 && Number(client.indIncome1YearsPriorOtherIncome).toString()) || "",
				indIncome1YearsPriorTotal: (client.indIncome1YearsPriorTotal && numeral(client.indIncome1YearsPriorTotal).value() > 0 && Number(client.indIncome1YearsPriorTotal).toString()) || "",
				indIncome2YearsPriorAnnualSalary: (client.annualIncome2 && numeral(client.annualIncome2).value() > 0 && Number(client.annualIncome2).toString()) || "",
				indIncome2YearsPriorDividends: (client.indIncome2YearsPriorDividends && numeral(client.indIncome2YearsPriorDividends).value() > 0 && Number(client.indIncome2YearsPriorDividends).toString()) || "",
				indIncome2YearsPriorOtherIncome: (client.indIncome2YearsPriorOtherIncome && numeral(client.indIncome2YearsPriorOtherIncome).value() > 0 && Number(client.indIncome2YearsPriorOtherIncome).toString()) || "",
				indIncome2YearsPriorTotal: (client && client.indIncome2YearsPriorTotal && numeral(client.indIncome2YearsPriorTotal).value() > 0 && Number(client.indIncome2YearsPriorTotal).toString()) || "",
				indOtherIncomeDetail: client.indOtherIncomeDetail || "",
				houseHoldIncomeLastYearAnnualSalary: (client.householdAnnualIncome && numeral(client.householdAnnualIncome).value() > 0 && Number(client.householdAnnualIncome).toString()) || "",
				houseHoldIncomeLastYearDividends: (client.houseHoldIncomeLastYearDividends && numeral(client.houseHoldIncomeLastYearDividends).value() > 0 && Number(client.houseHoldIncomeLastYearDividends).toString()) || "",
				houseHoldIncomeLastYearOtherIncome: (client.houseHoldIncomeLastYearOtherIncome && numeral(client.houseHoldIncomeLastYearOtherIncome).value() > 0 && Number(client.houseHoldIncomeLastYearOtherIncome).toString()) || "",
				houseHoldIncomeLastYearTotal: (client.houseHoldIncomeLastYearTotal && numeral(client.houseHoldIncomeLastYearTotal).value() > 0 && Number(client.houseHoldIncomeLastYearTotal).toString()) || "",
				houseHoldIncome1YearsPriorAnnualSalary: (client.houseHoldIncome1YearsPriorAnnualSalary && numeral(client.houseHoldIncome1YearsPriorAnnualSalary).value() > 0 && Number(client.houseHoldIncome1YearsPriorAnnualSalary).toString()) || "",
				houseHoldIncome1YearsPriorDividends: (client.houseHoldIncome1YearsPriorDividends && numeral(client.houseHoldIncome1YearsPriorDividends).value() > 0 && Number(client.houseHoldIncome1YearsPriorDividends).toString()) || "",
				houseHoldIncome1YearsPriorOtherIncome: (client.houseHoldIncome1YearsPriorOtherIncome && numeral(client.houseHoldIncome1YearsPriorOtherIncome).value() > 0 && Number(client.houseHoldIncome1YearsPriorOtherIncome).toString()) || "",
				houseHoldIncome1YearsPriorTotal: (client.houseHoldIncome1YearsPriorTotal && numeral(client.houseHoldIncome1YearsPriorTotal).value() > 0 && Number(client.houseHoldIncome1YearsPriorTotal).toString()) || "",
				houseHoldIncome2YearsPriorAnnualSalary: (client.houseHoldIncome2YearsPriorAnnualSalary && numeral(client.houseHoldIncome2YearsPriorAnnualSalary).value() > 0 && Number(client.houseHoldIncome2YearsPriorAnnualSalary).toString()) || "",
				houseHoldIncome2YearsPriorDividends: (client.houseHoldIncome2YearsPriorDividends && numeral(client.houseHoldIncome2YearsPriorDividends).value() > 0 && Number(client.houseHoldIncome2YearsPriorDividends).toString()) || "",
				houseHoldIncome2YearsPriorOtherIncome: (client.houseHoldIncome2YearsPriorOtherIncome && numeral(client.houseHoldIncome2YearsPriorOtherIncome).value() > 0 && Number(client.houseHoldIncome2YearsPriorOtherIncome).toString()) || "",
				houseHoldIncome2YearsPriorTotal: (client.houseHoldIncome2YearsPriorTotal && numeral(client.houseHoldIncome2YearsPriorTotal).value() > 0 && Number(client.houseHoldIncome2YearsPriorTotal).toString()) || "",
				houseHoldOtherIncomeDetail: client.houseHoldOtherIncomeDetail || "",
                parentsAreIncomeEarner: isGWTGen2 ? "1" : client.parentsAreIncomeEarner == "0" ? "0" : "1",
				occupation: client.occupation || "",
				industryId: client.industryId || "",
				occupationId: client.occupationId || "",
				otherOccupation: client.otherOccupation || "",
				occupationDetail: client.occupationDetail || "",
				employerAddress: client.employerAddress || "",
				highRanking: client.clientSourceOfFund && client.clientSourceOfFund.highRanking,
				individualHighRanking: client.clientSourceOfFund && client.clientSourceOfFund.individualHighRanking,
                USBasedCompany: client.USBasedCompany,
				workInIndustries: client.clientSourceOfFund && client.clientSourceOfFund.workInIndustries,
				countryOfOrigin: client.clientSourceOfFund && client.clientSourceOfFund.countryOfOrigin || "",
				sourceOfFunds: client.clientSourceOfFund && client.clientSourceOfFund.sourceOfFunds || "",
				countrySourceOfWealth: client.clientSourceOfFund && client.clientSourceOfFund.countrySourceOfWealth || "",
				whereTheyProceed: client.clientSourceOfFund && client.clientSourceOfFund.whereTheyProceed || "",
				otherIndustry: client.otherIndustry || "",
                currentResidenceCountry: { key: USA, name: USA, title: USA },
				otherCitizenship: client.otherCitizenship || "",
			};
		}
		if (client) {
			setInitialValues(formVal);
		}
	}, [client, user]);

	React.useEffect(() => {
		if (proofExpired && isFormSubmit && proofExpireRef && proofExpireRef.current) {
			scrollUp(proofExpireRef.current.id);
		}
	}, [proofExpired, isFormSubmit]);

	React.useEffect(() => {
		if (isNationalLife) {
			setMinAge(18); //for National Life Carrier MIN AGE is 18;
		}
	}, [isNationalLife]);

	React.useEffect(() => {
		if (isAllianzLife) {
			setMinAge(18); //for National Life Carrier MIN AGE is 18;
		}
	}, [isAllianzLife]);

	React.useEffect(() => {
		if (nationalLifeId && carrierId && nationalLifeId === carrierId && strategySlug === "kaizen") {
			setIsNationalLife(true);
		}
	}, [nationalLifeId, carrierId]);

	React.useEffect(() => {
		if (allianzId && carrierId && allianzId === carrierId && strategySlug === "kaizen") { //if Allianz Life and strategy is Kaizen
			setIsAllianzLife(true);
		}
	}, [allianzId, carrierId]);

	React.useEffect(() => {
		if (typeof setCarrierValAlert === "function") {
			setCarrierValAlert(openAlert);
		}
	}, [openAlert]);

	useEffect(() => {
		if (personalDetailSubmit.current && typeof setPersonalDetailRef === "function") {
			setPersonalDetailRef(personalDetailSubmit);
		}
	}, [personalDetailSubmit.current]);

	useEffect(() => {
		if (!isFromClientDetail) {
			accountActions.getProfileDetails();
		}
		getAllianzCarrierDetails().then(() => {
		});
		getNatioanlLifeCarrierDetails().then(() => {
		});
	}, []);

	React.useEffect(() => {
		getEstimation().then(() => {
		});
	}, []);

	useEffect(() => {
		if (client) {
			setSelfEmployed(!!client.selfEmployed);
			setCurrentlyEmployed(client.currentlyEmployed || "");
			setFullTimeEmployee(client.fullTimeEmployee);
			setFullTimeSelected(client.currentlyEmployed === "FULL_TIME" ? 1 : 0);
			setIsMarried(client.marriageStatus === "Married");
			setIsEmployed((client.currentlyEmployed === "FULL_TIME" || client.currentlyEmployed === "PART_TIME" || client.currentlyEmployed === "SELF_EMPLOYED" || client.currentlyEmployed === "NO" || client.currentlyEmployed === "RETIRED"));
			setIsIndustryOther(client.industryId === otherIndustryId);
		}
		if (client && client.carrier && client.carrier.id) {
			setCarrierId(client.carrier.id);
			setCarrierName(client.carrier.carrierName);
		}
		if (client && client.strategy && client.strategy.slug) {
			setStrategySlug(client.strategy.slug);
		}

		if (client && client.employeeIdExpireDate) {
			const expireDate = moment(new Date(client.employeeIdExpireDate));
			const currentDate = moment(new Date());
			const daysLeft = expireDate.diff(currentDate, "days");
			if (daysLeft <= 0) {
				setProofExpired(true);
			}
		}
	}, [client]);

    useEffect(() => {
        if(client.birthCountry === undefined){
            setBirthCountryValue(USA);
        } else if(client.birthCountry && birthCountryValue === undefined){
            setBirthCountryValue(client.birthCountry);
        } else if(birthCountryValue !== USA){
            setBirthStateValue("");
        }
    }, [client, birthCountryValue]);

	React.useEffect(() => {
		if (personalDetailForm && personalDetailForm.current && personalDetailForm.current.dirty) {
			let values: any = personalDetailForm && personalDetailForm.current && personalDetailForm.current.values;
			if (
				((Number(values["age"]) || Number(values["age"]) === 0) && (Number(values["age"]) < minAge ||
					Number(values["age"]) > 65))
				|| (Number(values["age"]) < 0)
			) {
				values.age = 0;
			}
			if (values.dob === undefined) {
				values.dob = dob;
			}
            setBirthCountryValue(typeof values.birthCountry === "object" ? values.birthCountry && values.birthCountry.name : values.birthCountry);
			saveMe(values);
			setFormValues(values);
		}
	}, [personalDetailForm && personalDetailForm.current && personalDetailForm.current.values, saveRequest, stateValue]);

	const getEstimation = async () => {
		if (client) {
			getContributionAmount({
				contributionAmount: 0,
				age: client.age,
				rating: client.healthType,
				gender: client.gender,
			});
		}
	};

	const getContributionAmount = (data: any) => {
		setTimeout(async () => {
			let isMinRequiredError = client && client.company && client.company.groupType === "Corporation";
			let employerContributionValue: any = 0;
			if (client && client.employerContribution && numeral(client.employerContribution).value() > 0 && props.clientDetail && props.clientDetail.role === 6) {
				employerContributionValue = client.employerContribution;
			}
			let estimatorData: any = await estimationActions.getInvitedClientEstimates({
				age: data.age || minAge,
				gender: data.gender === 1 ? "Male" : "Female",
				rating: (data && data.rating) || "Standard",
				contribution: numeral(data.contributionAmount).value() + numeral(employerContributionValue).value() + "",
				strategySlug: (client && client.strategy && client.strategy.slug) || "",
				carrierName: (client && client.carrier && client.carrier.carrierName) || "",
				isMinRequiredError,
			});
			if (estimatorData && estimatorData.payload && estimatorData.payload.minContributionAmount) {
				setMinVal(estimatorData && estimatorData.payload && estimatorData.payload.minContributionAmount);
			}
			if (!isMinRequiredError) {
				setMinVal(0);
			}
		}, 500);
	};

	let contributionType: string | undefined = (client && client.company && client.company.contributionType) || undefined;
	let showEmployerContribution: boolean = true;
	let showClientContribution: boolean = true;

	if ((contributionType === "EMPLOYER_ONLY")) {
		showEmployerContribution = true;
		showClientContribution = false;
	} else if ((contributionType === "PARTICIPANT_ONLY")) {
		showEmployerContribution = false;
		showClientContribution = true;
	} else if ((contributionType === "COMBINE_CONTRIBUTION")) {
		showEmployerContribution = true;
		showClientContribution = true;
	}

	const isCorporation: boolean = !!(client && client.company && client.company.groupType === "Corporation");

    let fieldObj = PersonalDetailFieldObj.fieldObj(conditionalRequired, setBirthCountryValue, birthCountryValue, clientSigned);
    const fieldObj12 = PersonalDetailFieldObj.fieldObj12(conditionalRequired, clientSigned, setBirthStateValue, birthStateValue);

    if (birthCountryValue === USA) {
        fieldObj = [...fieldObj, ...fieldObj12];
    }

	const fieldObj1 = PersonalDetailFieldObj.fieldObj1(conditionalRequired, getContributionAmount, setProvideCorrectEmailPopUp, isFromClientDetail);

	const setAnticipatedAge = (date, setValue, formValues) => {
		const age = moment().diff(date, "years");
		setAgeSelected(age && (age >= minAge && age <= 65) ? true : false);
		getContributionAmount({
			contributionAmount: 0,
			age: age,
			rating: formValues && formValues.healthType,
			gender: formValues && formValues.gender,
		});
		setValue("age", age);
	};

	const fieldObj4 = PersonalDetailFieldObj.fieldObj4(conditionalRequired, setAnticipatedAge, sendSaveRequest);
    const fieldObj7 = PersonalDetailFieldObj.fieldObj7(conditionalRequired, setProofExpired, setEmployeeIdStateValue, employeeIdStateValue, clientSigned);
	let fieldObj2 = PersonalDetailFieldObj.fieldObj2(conditionalRequired, setStateValue, stateValue, clientSigned);
	let fieldObj8: any = PersonalDetailFieldObj.fieldObj8(conditionalRequired, carrierName);
	const fieldObj10: any = PersonalDetailFieldObj.fieldObj10(conditionalRequired, setCurrentResidenceCountryValue, currentResidenceCountryValue, clientSigned);
	const fieldObj11: any = PersonalDetailFieldObj.fieldObj11();
	if (carrierName === AllianzLifeCarrierDetail.name) {
		fieldObj2 = [...fieldObj2, ...fieldObj10];
		fieldObj8 = [...fieldObj8, ...fieldObj11];
	}
	const fieldObj9 = PersonalDetailFieldObj.fieldObj9(conditionalRequired);
	let isGiCorpIncomeCond: boolean = !!fullTimeEmployee;
    const fieldObj3 = PersonalDetailFieldObj.fieldObj3(isGiCorpIncomeCond, conditionalRequired, currentYear, strategySlug, nationalLifeId, carrierId, sendSaveRequest, currentlyEmployed);
	const fieldObj6 = PersonalDetailFieldObj.fieldObj6(conditionalRequired, client, ageSelected, getContributionAmount);

	const getFieldFocusValue = (name: string) => {
		let findResult: any = labelsActive.find((label: any) => {
			return label.name === name;
		});
		if (!findResult) {
			return false;
		}
		return findResult.value;
	};

	const CommonField = (
		filedProps,
		action: {
			handleChange?: Function,
			handleBlur: Function,
			values?: any,
			errors?: any,
			touched?: any,
			setFieldValue: any
		},
	) => {
		return (
			<CommonFieldFunction filedProps={filedProps}
								 action={action}
								 conditionalRequired={conditionalRequired}
								 role={role}
								 setActionItem={setActionItem}
								 client={client}
								 labelsActive={labelsActive}
								 setLabelsActive={setLabelsActive}
								 clientSigned={clientSigned}
								 getFieldFocusValue={getFieldFocusValue}

			/>);
	};

	/* Validations of fields set */
	let keysValidation = {};
    let fieldobj9 = [
        {
            name: "indIncomeLastYearAnnualSalary",
            isRequired: true,
            type: "number",
        },
        {
            name: "indIncomeLastYearOtherIncome",
            isRequired: true,
            type: "number",
        },
        {
            name: "indOtherIncomeDetail",
            isRequired: true,
            type: "string",
        },
        {
            name: "netWorth",
            isRequired: true,
            type: "number",
        },
    ];
    let fieldobj10 = [
    {
    	name: "householdNetWorth",
    	isRequired: true,
    	type: "number",
    },
    {
    	name: "spouseIncome",
    	isRequired: true,
    	type: "number",
    },
    {
    	name: "householdLiquidAssets",
    	isRequired: true,
    	type: "number",
    },
    {
    	name: "householdAnnualExpenses",
    	isRequired: true,
    	type: "number",
    },
    ];

	let validationObj = [
		...fieldObj1,
        ...fieldObj4,
        ...fieldObj,
		{
			name: "currentlyEmployed",
			isRequired: conditionalRequired && !(isCorporation),
			type: "string",
		},
		{
			name: "marriageStatus",
			isRequired: conditionalRequired && !isMarried,
			type: "string",
		},
		{
			name: "usingSpouseIncome",
			isRequired: conditionalRequired && isMarried,
			type: "string",
		},
		{
			name: "employer",
			isRequired: conditionalRequired && isEmployed,
			type: "string",
		}, {
			name: "employedTime",
			isRequired: conditionalRequired && isEmployed,
			type: "string",
		}, {
			name: "occupation",
			isRequired: conditionalRequired && isEmployed && isIndustryOther,
			type: "string",
		},
		{
			name: "industryId",
			isRequired: conditionalRequired && isEmployed,
			type: "number",
		}, {
			name: "otherIndustry",
			isRequired: conditionalRequired && isEmployed && isIndustryOther,
			type: "string",
		}, {
			name: "occupationId",
			isRequired: conditionalRequired && isEmployed && !isIndustryOther,
			type: "number",
		}, {
			name: "otherOccupation",
			isRequired: conditionalRequired && isEmployed && isIndustryOther,
			type: "string",
		}, {
			name: "occupationDetail",
			isRequired: conditionalRequired && isEmployed,
			type: "string",
        }, {
            name: "employerAddress",
            isRequired: conditionalRequired && isEmployed,
            type: "string",
        },
		{
			name: "parentsAreIncomeEarner",
			isRequired: (conditionalRequired && currentlyEmployed && currentlyEmployed === "Other" && isParentEarner && !isAllianzLife),
			type: "string",
		}];

    validationObj = [...validationObj, ...fieldobj9, ...fieldobj10];

	validationObj.push(
        {
            name: "USBasedCompany",
            isRequired: conditionalRequired,
            type: "number",
        },
		{
			name: "highRanking",
			isRequired: conditionalRequired,
			type: "number",
		},
		{
			name: "individualHighRanking",
			isRequired: conditionalRequired,
			type: "number",
		},
		{
			name: "workInIndustries",
			isRequired: conditionalRequired,
			type: "number",
		}
	);

	keysValidation = PersonalDetailValidate(validationObj, keysValidation, isFromClientDetail, minAge, fieldObj9, fieldObj8,
		fieldObj2, strategySlug, client, ageSelected, minVal,
		showClientContribution, fieldObj6, fieldObj7, props, fieldObj11);

	const personalDetailSchema = Yup.object().shape(keysValidation);

	let kickOutDetail = {
		groupAdminEmail: (client && client.agent && client.agent.user && client.agent.user.email) || "",
		groupAdminPhone: (client && client.agent && client.agent.user && client.agent.user.phoneNumber) || "",
		groupAdminFullName: (client && client.agent && client.agent.user && client.agent.user.firstName + " " + client.agent.user.lastName) || "",
		strategyName: (client && client.strategy && client.strategy.name) || "",
	};
	if (role === 6) {
		kickOutDetail = {
			groupAdminEmail: (client && client.company && client.company.email) || "",
			groupAdminPhone: (client && client.company && client.company.phone) || "",
			groupAdminFullName: (client && client.company && client.company.companyName) || "",
			strategyName: (client && client.strategy && client.strategy.name) || "",
		};
	}

	const participantId: number = (participant && participant.id) || 0;

	const makePersonalDetailPayload = (values: any, setInitialValues: any, autoSave = false) => {
		if (isCorporation && !values["fullTimeEmployee"] && !isFromClientDetail) {
			setOpenKickOutPopup(true);
			return false;
		}
		delete values["employerContribution"];
		setIsFormSubmit(true);
		if (!isFromClientDetail && proofExpired) {
			return false;
		}

		if (setInitialValues && typeof setInitialValues === "function") {
			setInitialValues(values);
		}

		if (role === 5 || role === 6) {
			delete values["contributionAmount"];
			delete values["healthType"];
		} else {
			values["contributionAmount"] = values["contributionAmount"] ? values["contributionAmount"].split(",").join("") : 0;
		}
		if (!isFromClientDetail && values["age"] === 0 && !autoSave) {
			return false;
		}

		values["employedTime"] = values["employedTime"] ? values["employedTime"] : 0;
		values["employer"] = values["employer"] ? values["employer"] : "";
		values["annualIncome"] = values["indIncomeLastYearAnnualSalary"] && Number(values["indIncomeLastYearAnnualSalary"]) !== 0 ? values["indIncomeLastYearAnnualSalary"].split(",").join("") : null;
		values["annualIncome1"] = values["indIncome1YearsPriorAnnualSalary"] && Number(values["indIncome1YearsPriorAnnualSalary"]) !== 0 ? values["indIncome1YearsPriorAnnualSalary"].split(",").join("") : null;
		values["annualIncome2"] = values["indIncome2YearsPriorAnnualSalary"] && Number(values["indIncome2YearsPriorAnnualSalary"]) !== 0 ? values["indIncome2YearsPriorAnnualSalary"].split(",").join("") : null;
		values["householdAnnualIncome"] = convertToNumber(values["houseHoldIncomeLastYearAnnualSalary"]);
		values["householdNetWorth"] = convertToNumber(values["householdNetWorth"]);
		//values["spouseDUAIncome"] = convertToNumber(values["spouseDUAIncome"]);
		values["householdLiquidAssets"] = convertToNumber(values["householdLiquidAssets"]);
		values["householdAnnualExpenses"] = convertToNumber(values["householdAnnualExpenses"]);
		values["dob"] = asDbDateTimeFormat(values["dob"], DATE_FORMAT_DOB_SAVE);
		//income details field starts
		values["spouseIncome"] = convertToNumber(values["spouseIncome"]);
		incomeDetailsColArr.forEach((item) => {
			values[`${item}`] = values[`${item}`] ? values[`${item}`].split(",").join("") : null;
		}); //income details field end
		values["netWorth"] = convertToNumber(values["netWorth"]);
        values["cellPhone"] = convertToNumber(values["cellPhone"]);
        values["workPhoneNumber"] = convertToNumber(values["workPhoneNumber"]);
		values["homePhone"] = convertToNumber(values["homePhone"]);
		values["immigrationStatus"] = values["immigrationStatus"] ? values["immigrationStatus"] : "";
		values["state"] = stateValue;
        values["birthState"] = birthStateValue;
        values["employeeIdState"] = employeeIdStateValue;
        values["birthCountry"] = birthCountryValue;
        values["currentResidenceCountry"] = currentResidenceCountryValue;
		return values;
	};

	const saveMe = async (values: any) => {
		const payload: any = makePersonalDetailPayload(values, null, true);
		try {
			if (payload) {
				payload.age = Number(payload.age);
                if (payload.currentlyEmployed === "HOME_MAKER" || payload.currentlyEmployed === "STUDENT" || payload.currentlyEmployed === OTHER_VALUE) {
                    payload.employer = "";
                    payload.employedTime = 0;
                    payload.occupationDetail = "";
                    payload.employerAddress = "";
                }
                payload.spouseIncome = ((payload.marriageStatus === "Single") && (payload.spouseIncome === "" || payload.spouseIncome === null)) ? "0" : payload.spouseIncome;
				await accountActions.updateUserBasicDetails(payload, ((client && client.userId) || 0), "");
                if ((values.birthCountry === USA) && (client.birthCountry !== USA) || (values.birthCountry !== USA) && (client.birthCountry === USA)) {
                    await accountActions.getProfileDetails();
                }
			}
		} catch (e) {
			if (e && e.message == 422 && (typeof getClientDetail === "function")) {
				getClientDetail(urlClientId || 0, ssnFlag);
				setServerError(true);
			}
			return false;
		}
	};

	return (
		<>
			{isGiCorpIncomeCond &&
				<IncomeAlert incomeAlertOpen={incomeAlertOpen} />
			}
			<KickOut participantId={participantId} isFromClientDetail={isFromClientDetail || false}
					 openKickOut={openKickOutPopup} setOpenKickOut={() => {
				setOpenKickOutPopup(!openKickOutPopup);
			}} {...kickOutDetail} />

			<PersonalDetailFormikContent
				initialValues={initialValues}
				personalDetailSchema={personalDetailSchema}
				isCorporation={isCorporation}
				setOpenKickOutPopup={setOpenKickOutPopup}
				setIsFormSubmit={setIsFormSubmit}
				isFromClientDetail={isFromClientDetail}
				proofExpired={proofExpired}
				isNationalLife={isNationalLife}
				selfEmployed={selfEmployed}
				minAge={minAge}
				setInitialValues={setInitialValues}
				setNextStep={setNextStep}
				client={client}
				setActionItem={setActionItem}
				role={role}
				setIsPersonalFormDirty={setIsPersonalFormDirty}
				setIsFormSubmitting={setIsFormSubmitting} setFullTimeEmployee={setFullTimeEmployee}
				fullTimeEmployee={fullTimeEmployee}
				setIncomeAlertOpen={setIncomeAlertOpen} setCurrentlyEmployed={setCurrentlyEmployed}
				setValidationError={setValidationError} setSelfEmployed={setSelfEmployed}
				accountActions={accountActions}
				setOpenAlert={setOpenAlert}
				user={user}
				fieldObj={fieldObj}
				clientSigned={clientSigned}
				conditionalRequired={conditionalRequired}
				CommonField={CommonField}
				fieldObj1={fieldObj1} fieldObj8={fieldObj8} classes={classes} fieldObj2={fieldObj2}
				proofExpireRef={proofExpireRef} fieldObj4={fieldObj4} fieldObj7={fieldObj7}
				fieldObj9={fieldObj9}
				strategySlug={strategySlug} fieldObj3={fieldObj3} fieldObj6={fieldObj6}
				clientDetail={props.clientDetail} showClientContribution={showClientContribution}
				showEmployerContribution={showEmployerContribution} minVal={minVal}
				personalDetailSubmit={personalDetailSubmit}
				setFullTimeSelected={setFullTimeSelected}
				fullTimeSelected={fullTimeSelected}
				setIsDocumentAddress={setIsDocumentAddress}
				setIsMarried={setIsMarried}
				setIsEmployed={setIsEmployed}
				isAllianzLife={isAllianzLife}
				setIsParentEarner={setIsParentEarner}
				isParentEarner={isParentEarner}
				personalDetailForm={personalDetailForm}
				makePersonalDetailPayload={makePersonalDetailPayload}
				sendSaveRequest={sendSaveRequest}
				getClientDetail={getClientDetail}
				urlClientId={urlClientId}
				ssnFlag={ssnFlag}
				setServerError={setServerError}
				serverError={serverError}
				setAnticipatedAge={setAnticipatedAge}
			/>
			<AllianzAlert setOpenAlert={setOpenAlert} open={openAlert} carrierName={carrierName}
						  isNationalLife={isNationalLife} />
			{
				provideCorrectEmailPopUp &&
				<UpdateEmailReqPopUp open={provideCorrectEmailPopUp}
									 setProvideCorrectEmailPopUp={setProvideCorrectEmailPopUp} />
			}
		</>);
};

export default PersonalDetailFormV2;