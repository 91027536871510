import React from "react";
import { Grid, Typography, Card, CardContent, Link } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import numeral from "numeral";
import * as QueryString from "query-string";
import { Button } from "../formInputs";
import LeadDeleteConfirmation from "./LeadDeleteConfirmation";
import { useActions } from "../../actions";
import { alertNotification } from "../../actions/auth";
import * as LeadActions from "../../actions/lead";
import { defaultNIWPhoneNumber } from "../../constants";


const useStyles = makeStyles((theme) =>
        createStyles({
            cardRoot: {
                display: "flex",
                boxShadow: "0 21px 50px 0 rgba(0,0,0,0.28)",
                minHeight: 400,
                position: "relative",
                bottom: "-150px",
                zIndex: 99,

                [theme.breakpoints.down("sm")]: {
                    bottom: "0",
                    margin:'0 16px'
                },
            },
            details: {
                display: "flex",
                flexDirection: "column",
                width: "100%",
            },
            content: {
                flex: "1 0 auto",
                padding: 40,
                [theme.breakpoints.down("sm")]: {
                    padding: 15,
                },
            },
            cover: {
                width: 600,
                backgroundPosition: "bottom left",
                [theme.breakpoints.down("sm")]: {
                    display: "none",
                },
            },
            cursor: {
                cursor: "pointer",
            },
        }),
);

enum ContactTimeEnum {
    Morning = 1,
    AfterNoon,
    Evening
}

const AppointmentSection = (props: any) => {
    const classes = useStyles();
    const leadActions: any = useActions(LeadActions);
    const params: any = QueryString.parse(props.location.search);
    const id = params.id;
    const email = decodeURIComponent((params.email || "") as string);
    const [appointmentConfirmation, setAppointmentConfirmation] = React.useState<boolean>(false);
    const [deleteConfirmation, setDeleteConfirmation] = React.useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<number>(0);
    const fieldObjDateTime = [
        {
            caption: "Morning (9:00 AM - Afternoon)",
            selected: ContactTimeEnum.Morning === selected,
            value: ContactTimeEnum.Morning,
        },
        {
            caption: "Afternoon (Noon - 5:00 PM)",
            selected: ContactTimeEnum.AfterNoon === selected,
            value: ContactTimeEnum.AfterNoon,
        },
        {
            caption: "Evening (5:00 PM - 8:00 PM)",
            selected: ContactTimeEnum.Evening === selected,
            value: ContactTimeEnum.Evening,
        },
    ];
    const _selectedOption = fieldObjDateTime.find(a => a.value === selected);

    const setAppointment = async () => {
        if (!selected) {
            const [message, statusCode] = ["Select contact time preference.", 400];
            return alertNotification({ message, statusCode });
        }


        if (!_selectedOption) {
            return;
        }

        const payload = { email, id: numeral(id).value(), specificDate: null, specificTime: _selectedOption.caption };
        await leadActions.setLeadAppointment(payload)
                .then(() => setAppointmentConfirmation(true));
    };

    const handleRemoveLead = () => {
        leadActions.deleteLeadAppointment({ email, id })
                .then(() => {
                    setDeleteConfirmation(true);
                    setOpenConfirmation(false);
                });
    };

    React.useEffect(() => {
        if (selected) {
            setAppointment().then();
        }
    }, [selected]);

    return (
            <Grid container spacing={2} justify="center">
                {
                        openConfirmation &&
                        <LeadDeleteConfirmation success={handleRemoveLead} close={() => setOpenConfirmation(false)} />
                }

                <Grid item xs={12} md={12} lg={9} xl={8}>
                    <Card className={classes.cardRoot}>
                        <div className={classes.details}>
                            <CardContent className={classes.content + " textCenter "}>
                                <Grid container spacing={2}>
                                    {
                                            !appointmentConfirmation && !deleteConfirmation &&
                                            <React.Fragment>
                                                <Grid item xs={12}>
                                                    <div className="second-step">
                                                        <Typography variant="h2" color="primary" className="positionR">
                                                            <div className="arrow arrowAppoint" />
                                                            Next Steps: We’ll Be in Touch!
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="primary"
                                                                    className="mb20">
                                                            There is a lot more to Kai-Zen<sup>&reg;</sup> than we can
                                                            show here so one of our<br /> specialists will reach out to
                                                            answer any questions you may have.
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="subtitle2" component="strong" color="primary"
                                                                className="mb20 floatLeft w100">
                                                        Do you have a contact time preference?
                                                    </Typography>
                                                </Grid>
                                                <Grid container spacing={3} justify="center">
                                                    {
                                                        fieldObjDateTime.map((field, index) => {
                                                            return (
                                                                    <div className="mr15 mr0Small" key={index}>
                                                                        <Button
                                                                                color="primary"
                                                                                className={field.selected ? "active mb15" : "mb15"}
                                                                                label={field.caption}
                                                                                onClick={() => setSelected(field.value)}
                                                                                size="large"
                                                                                value={field.value}
                                                                                variant="outlined"
                                                                        />
                                                                    </div>
                                                            );
                                                        })
                                                    }
                                                    <Grid item xs={12}>
                                                        <Typography
                                                                color="primary"
                                                                className="w100 itemHover mt20"
                                                                onClick={() => setOpenConfirmation(true)}
                                                                style={{ cursor: "pointer" }}
                                                                variant={"body1"}
                                                        >
                                                            I’m no longer interested
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                    }

                                    {
                                            appointmentConfirmation &&
                                            <Grid item xs={12}>
                                                <Typography color="primary" variant="h2">
                                                    Thank You!
                                                </Typography>
                                                <Typography variant={"body1"} color="primary"
                                                            className="textLight w100 mt40">
                                                    You are scheduled to be contacted in
                                                    the <strong>{_selectedOption ? _selectedOption.caption : ""}</strong> by
                                                    an NIW specialist.<br /> We look forward to answering any questions
                                                    you may have.
                                                </Typography>
                                            </Grid>
                                    }

                                    {
                                            deleteConfirmation &&
                                            <Grid item xs={12}>
                                                <Typography variant="h2">
                                                    You've Opted Out
                                                </Typography>
                                                <Typography variant={"body1"} className="textLight w100 mt40">
                                                    We won't contact you with information regarding this strategy. If
                                                    this was a mistake please contact us at
                                                    <Link href={`tel:${defaultNIWPhoneNumber}`}>{defaultNIWPhoneNumber}</Link>
                                                    ext-216 for assistance.
                                                </Typography>
                                            </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </div>
                    </Card>
                </Grid>
            </Grid>
    );
};

export default AppointmentSection;