import React from "react";
import {useFormikContext} from "formik";
import {EnrollmentFinancialAgreementProps} from "../../../../../../../model/enrollment";
import {Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import {Label} from "../../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import {ErrorCommon, TextField, TextFieldCommon} from "../../../../../../../components/formikFormInputs";
import {longFormFinancialQuestions} from "../FinancialAgreement/FinancialQuestions";
import FinancialQuestions10 from "../questions/FinancialQuestions10";
import {FQ10} from "../FinancialAgreement/FinancialQuestions";

const PremiumFinancingQuestions = (props:{user:any, isFromClientDetail:boolean, offerType:string, sendSaveRequest:Function}) => {
    const { user, isFromClientDetail, offerType, sendSaveRequest } = props;
    const financialQuestion:any = longFormFinancialQuestions({offerType})
    const financialQuestionToShow = financialQuestion.filter(el => el.show);
    const { values, setFieldValue, setFieldTouched, errors, touched, handleBlur, handleChange }:any = useFormikContext();
    const clientSigned = user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? 1 : 0;

    return <>{
        financialQuestionToShow.map((question: any, idx: number) => {
            return (
                <>
                    <Grid item xs={12} sm={12} md={12} key={question.key}>
                        <Grid container>
                            {!!(question && question.heading) && <Grid item xs={12}>
                                <Typography variant="body1" component="strong"
                                            className="floatLeft w100 mb10">
                                    {question.heading}
                                </Typography>
                            </Grid>
                            }
                            <Grid item xs={12} sm={12} md={8} lg={9}>
                                <Typography variant="body1"
                                            className="mb15 floatLeft "
                                            component="strong">
                                    <Label
                                            label={`${question.label}`}
                                            required={!isFromClientDetail}/>
                                </Typography>
                                {question.infoTooltip &&
                                <Tooltip title={<Typography
                                        className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                    <IconButton>
                                        <Info color="primary"/>
                                    </IconButton>
                                </Tooltip>
                                }
                            </Grid>
                            {
                                question.type === "radio" &&
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <ToggleButtonGroup
                                        className="toggleButtonGroupQust floatRight"
                                        size="medium"
                                        color={"primary"}
                                        exclusive
                                        id={question.name}
                                        value={values[question.name] === "Yes" ? "Yes" : (values[question.name] == undefined || values[question.name] === null) ? "" : "No"}
                                        onChange={(ev: any, value: any) => {
                                            if (value === "Yes") {
                                                setFieldValue(question.name, value);
                                            }
                                            if (value === "No") {
                                                setFieldValue(question.name, value);
                                            }
                                            if (value === null) {
                                                setFieldTouched(question.name, true);
                                                setFieldValue(question.name, null);
                                            }
                                            sendSaveRequest(Math.random())
                                        }}
                                    >
                                        <ToggleButton value={"Yes"}
                                            disabled={clientSigned ? true : false}
                                            className={values[question.name] === "Yes" ? "active" : ""}>Yes
                                        </ToggleButton>
                                        <ToggleButton value={"No"}
                                            disabled={clientSigned ? true : false}
                                            className={values[question.name] === "No" ? "active" : ""}>No
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <span style={{float:"right", clear:"both"}}>
                                    <ErrorCommon touched={touched} errors={errors} name={question.name}/>
                                </span>
                                </Grid>
                            }
                        </Grid>

                        {((values && values["FinancialQuestionsXR_10"] === "Yes") && (question.name === "FinancialQuestionsXR_10")) &&
                                <Grid item xs={12} className={"mt20"}>
                                    <Grid container spacing={2}>
                                        <FinancialQuestions10 clientSigned={clientSigned === 1 ? true : false} offerType={offerType} />
                                    </Grid>
                                </Grid>
                        }
                    </Grid>

                    {(!(question && question.notShowHrLine)) && <span className="hLine"/> }

                </>
            );
        })
    }
    </>
}

export default PremiumFinancingQuestions;