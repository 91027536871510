import * as React from "react";
import {
    Dialog,
    DialogContent,
    Button,
    Typography, Grid, TextField, DialogActions,
} from "@material-ui/core";
import { useEffect } from "react";

interface PrimaryProps {
    open: boolean,
    handleConfirm: Function,
    handleClosePopup: Function,
    content: Function,
    addNotes?: boolean,
    handleNotes?: Function,
    notes?: string
}

export default function CustomPopup(props: PrimaryProps) {
    const [open, setOpen] = React.useState(false);
    const [notesArea, setNotesArea] = React.useState(false);
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
        props.handleClosePopup();
        setOpen(false);
    };

    const handleConfirm = () => {
        props.handleConfirm();
        setOpen(false);
    };
    const handleNoteSection = (val: boolean) => {
        setNotesArea(val);
    };

    return (
            <>
                <Dialog
                        fullWidth={true}
                        maxWidth={"xs"}
                        className="customModal"
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >

                    <DialogContent dividers>
                        <div id="alert-dialog-description">
                            <Typography className="f14">{props.content()}</Typography>
                        </div>
                        {notesArea &&
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                            fullWidth={true}
                                            id={"notes"}
                                            variant="outlined"
                                            name={"notes"}
                                            value={props.notes}
                                            onChange={(e) => {
                                                if (typeof props.handleNotes === "function") {
                                                    props.handleNotes(e.target.value);
                                                }
                                            }} rows={6} placeholder="Add notes here"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                            className="floatRight"
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            type="submit" onClick={() => handleNoteSection(false)}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                                size="small"
                                type="button"
                                variant="contained"
                                color="primary" onClick={handleConfirm}>
                            Confirm
                        </Button>
                        <Button
                                size="small"
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={handleClose}>
                            Cancel
                        </Button>
                        {props.addNotes && !notesArea &&
                        <Button size="small" onClick={() => handleNoteSection(!notesArea)}
                                variant="contained"
                                color="primary">{props.notes === "" ? "Add" : "Edit"} Notes</Button>}
                    </DialogActions>

                </Dialog>
            </>
    );
}