import {ActionType} from "../model";

export const setAlert = (message: string, severity: string = '', statusCode: number = 200) => async (dispatch: Function) => {
    dispatch({payload: {message, severity, statusCode}, type: ActionType.ALERT});
};

export const setErrorAlert = (message: string, statusCode: number = 400) => async (dispatch: Function) => {
    dispatch(setAlert(message, 'error', statusCode));
};

export const setWarning = (message: string, statusCode: number = 400) => async (dispatch: Function) => {
    dispatch(setAlert(message, 'info', statusCode));
};