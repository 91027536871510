import FocusError from "../../../../../../components/FocusError";
import {Button, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tooltip, Typography} from "@material-ui/core";
import React from "react";
import {useFormikContext} from "formik";
import {Label} from "../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import {ErrorCommon} from "../../../../../../components/formikFormInputs";
import { isLongForm, isWorksheet } from "../Utilities";
import Physician from "./Physician";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../reducers";
import WeightChange from "./WeightChange";
import { isFunc } from "../../../../../../utils/commonUtils";


interface PropsType {
    offerType: string
    isFromClientDetail: boolean
    CommonField: Function
    enableSaveButton: Function
    getSiblingDetail: Function
    isClientSigned: boolean
    setPhysiciansCount: Function
    setIsError: Function
    setErrorInPhysicianAndMedicalDetails?:Function
    classes: any,
    user:any
    nationalLifeId: number
    fieldObj: any
    fieldObj1: any
    fieldObj2: any
    fieldObj3: any
    fieldObj4: any
    fieldObj5: any
    fieldObj6: any
    fieldObj7: any
    fieldObj8: any
    fieldObj10: any
    fieldObj11: any
    fieldObj12: any
    fieldObj13: any
    fieldObj14: any
    fieldObj15: any
    setInitiateSaveMe?: Function;
}

export default function PhysicianAndMedicalDetailsV1FormContent(props: PropsType) {
    const callingAutoSave = () => {
        if (props.setInitiateSaveMe && isFunc(props.setInitiateSaveMe)) {
            props.setInitiateSaveMe(Math.random());
        }
    };

    const {offerType, isFromClientDetail, nationalLifeId, user, classes, setIsError, isClientSigned, setErrorInPhysicianAndMedicalDetails, enableSaveButton, getSiblingDetail, setPhysiciansCount, fieldObj, CommonField, fieldObj1, fieldObj2,fieldObj3,fieldObj4,fieldObj5,fieldObj6,fieldObj7,fieldObj8, fieldObj15, fieldObj10, fieldObj11, fieldObj12, fieldObj13, fieldObj14} = props;

    const {values, errors, touched, handleChange, handleBlur, dirty, handleSubmit, setFieldValue, isSubmitting}:any = useFormikContext();

    const loggedInUser:any = useSelector((state: RootState) => state.auth);

    let role:number|null = (loggedInUser && loggedInUser.user && loggedInUser.user.role) || null;

    React.useEffect(() => {
        if (Object.keys(errors).length > 0 && !isFromClientDetail) {
            if(typeof setIsError === 'function') {
                setIsError(true);
            }
        } else {
            if(typeof setIsError === 'function') {
                setIsError(false)
            }
        }

        if(Object.keys(errors).length > 0){
            if(typeof setErrorInPhysicianAndMedicalDetails === 'function') {
                setErrorInPhysicianAndMedicalDetails(true)
            }
        } else {
            if(typeof setErrorInPhysicianAndMedicalDetails === 'function') {
                setErrorInPhysicianAndMedicalDetails(false)
            }
        }

    }, [errors]);

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        handleChange(e);
        enableSaveButton({...values, [fieldName] : e.target.value});
        callingAutoSave();
    };


    const resetFMDetails = (fm:string="father") => {
        setFieldValue(`${fm}Age`, '')
        setFieldValue(`${fm}AgeAtDeath`, '')
        setFieldValue(`${fm}CauseOfDeath`, '')
    }

    return (
            <form className="mt20 floatLeft w100" onSubmit={handleSubmit}>
                <FocusError offset={true}/>
                <Grid container>
                    {/*START GI CONDITION*/}
                    {offerType !== 'gi' &&
                        <React.Fragment>
                            {!isFromClientDetail &&
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="body1" component="strong"
                                                    className="floatLeft mt15">
                                            Medical Questions
                                        </Typography>
                                    </Grid>
                            }

                            <Grid item xs={12} md={12} xl={4}>
                                <Typography variant="body1" component="strong"
                                            className="floatLeft mt15">
                                    <Label label={`${isFromClientDetail ? "What is your weight and height?" : "What is your weight and height?"}`}
                                           required={!isFromClientDetail}/>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} xl={8}>
                                <Grid container spacing={1} justify="flex-end">
                                    {fieldObj.map((field, index) => {
                                        return (
                                                    <Grid item xs={12} md={4} lg={4} key={index + field["name"]}>
                                                    {
                                                        CommonField(
                                                                field, {
                                                                    values,
                                                                    errors,
                                                                    touched,
                                                                    handleChange,
                                                                    handleBlur,
                                                                    dirty,
                                                                },
                                                        )
                                                    }
                                                </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <span className="hLine"/>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={9}>
                                        <Typography component="strong" variant="body1"
                                                    className="mb0 floatLeft"><Label label={"Have you gained or lost weight during the last 12 months?"} required={true}/></Typography>
                                        <Tooltip title={<Typography
                                                className="f16 txtWhite mb0"> Answer Yes if you have lost or gained more than 10 pounds. </Typography>}>
                                            <IconButton>
                                                <Info color="primary"/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={3}>
                                        <ToggleButtonGroup
                                                className="toggleButtonGroupQust floatRight"
                                                size="medium"
                                                color={"primary"}
                                                exclusive
                                                id={"weightLoss"}
                                                value={values["weightLoss"] === null ? "" : values["weightLoss"] === 1 ? 1 : 0}
                                                onChange={async (ev: any, value: any) => {
                                                    if (value === 1) {
                                                        await setFieldValue("weightLoss", value);
                                                    }
                                                    if (value === 0) {
                                                        await setFieldValue("weightLoss", value);

                                                        await setFieldValue("weightChangeQuantity", null)
                                                        await setFieldValue("changedWeightAmount", null)
                                                        await setFieldValue("reasonWeightChange", null)
                                                        await setFieldValue("doctorKnowWeightChange", null)
                                                        await setFieldValue("reasonWeightChangeOther", "")

                                                    }
                                                    if (value === null) {
                                                        await setFieldValue("weightLoss", null);

                                                        await setFieldValue("weightChangeQuantity", null)
                                                        await setFieldValue("changedWeightAmount", null)
                                                        await setFieldValue("reasonWeightChange", null)
                                                        await setFieldValue("doctorKnowWeightChange", null)
                                                        await setFieldValue("reasonWeightChangeOther", "")
                                                    }
                                                    enableSaveButton({...values, ["weightLoss"] : value});
                                                }}
                                        >
                                            <ToggleButton value={1}
                                                          disabled={(role && role <= 4) || isClientSigned ? true : false}
                                                          className={values["weightLoss"] === 1 ? "active" : ""}>
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton value={0}
                                                          disabled={(role && role <= 4) || isClientSigned ? true : false}
                                                          className={values["weightLoss"] === 0 ? "active" : ""}>
                                                No
                                            </ToggleButton>
                                        </ToggleButtonGroup>

                                        <div className="clearFix"/>
                                        <div style={{float: "right",margin: '0', fontSize: '10px', color: "#FF1744", textAlign: 'right'}}>
                                            {
                                                touched["weightLoss"] && values["weightLoss"] === null ? (
                                                        <span>Required Field</span>
                                                ) : null
                                            }
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>

                            {values["weightLoss"] === 1 && isLongForm(offerType) &&
                                <WeightChange
                                    fieldObj10={fieldObj10}
                                    fieldObj11={fieldObj11}
                                    fieldObj12={fieldObj12}
                                    fieldObj13={fieldObj13}
                                    fieldObj14={fieldObj14}
                                    isFromClientDetail={isFromClientDetail}
                                    CommonField={CommonField}
                                    classes={classes}
                                    offerType={offerType}
                                    handleRadioChange={handleRadioChange}
                                />
                            }
                            <Grid item xs={12}>
                                <span className="hLine"/>
                            </Grid>

                            {isLongForm(offerType) &&
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={9}>
                                                    <Typography component="strong" variant="body1"
                                                                className="mb0 floatLeft"><Label label={"Do you have a regular physician, doctor, or healthcare provider?"} required={true}/></Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={3}>
                                                    <ToggleButtonGroup
                                                            className="toggleButtonGroupQust floatRight"
                                                            size="medium"
                                                            color={"primary"}
                                                            exclusive
                                                            id={"healthcare"}
                                                            value={values["healthcare"] === null ? "" : values["healthcare"] === 1 ? 1 : 0}
                                                            onChange={(ev: any, value: any) => {
                                                                if (value === 1) {
                                                                    setFieldValue("healthcare", value);
                                                                }
                                                                if (value === 0) {
                                                                    setFieldValue("healthcare", value);
                                                                }
                                                                if (value === null) {
                                                                    setFieldValue("healthcare", null);
                                                                }
                                                                enableSaveButton({...values, ["healthcare"] : value});
                                                            }}
                                                    >
                                                        <ToggleButton value={1}
                                                                      disabled={(role && role <= 4) || isClientSigned ? true : false}
                                                                      className={values["healthcare"] === 1 ? "active" : ""}>
                                                            Yes
                                                        </ToggleButton>
                                                        <ToggleButton value={0}
                                                                      disabled={(role && role <= 4) || isClientSigned ? true : false}
                                                                      className={values["healthcare"] === 0 ? "active" : ""}>
                                                            No
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>

                                                    <div className="clearFix"/>
                                                    <div style={{float: "right",margin: '0', fontSize: '10px', color: "#FF1744", textAlign: 'right'}}>
                                                        {
                                                            touched["healthcare"] && values["healthcare"] === null ? (
                                                                    <span>Required Field</span>
                                                            ) : null
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span className="hLine"/>
                                        </Grid>
                                    </>
                            }
                            {isWorksheet(offerType) &&
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={9}>
                                                    <Typography component="strong" variant="body1"
                                                                className="mb0 floatLeft"><Label
                                                            label={"Within the past 12 months, has the proposed insured received treatment or advice from a member of the medical profession for heart disease, Type 1 diabetes, stroke or cancer?"}
                                                            required={true} /></Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={3}>
                                                    <ToggleButtonGroup
                                                            className="toggleButtonGroupQust floatRight"
                                                            size="medium"
                                                            color={"primary"}
                                                            exclusive
                                                            id={"heartDisease"}
                                                            value={values["heartDisease"] === null ? "" : values["heartDisease"] === 1 ? 1 : 0}
                                                            onChange={async (ev: any, value: any) => {
                                                                if (value === 1) {
                                                                    setFieldValue("heartDisease", value);
                                                                }
                                                                if (value === 0) {
                                                                    setFieldValue("heartDisease", value);
                                                                }
                                                                if (value === null) {
                                                                    setFieldValue("heartDisease", null);
                                                                }
                                                                enableSaveButton({
                                                                    ...values,
                                                                    ["heartDisease"]: value,
                                                                });
                                                            }}
                                                    >
                                                        <ToggleButton value={1}
                                                                      disabled={(role && role <= 4) || isClientSigned ? true : false}
                                                                      className={values["heartDisease"] === 1 ? "active" : ""}>
                                                            Yes
                                                        </ToggleButton>
                                                        <ToggleButton value={0}
                                                                      disabled={(role && role <= 4) || isClientSigned ? true : false}
                                                                      className={values["heartDisease"] === 0 ? "active" : ""}>
                                                            No
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>

                                                    <div className="clearFix" />
                                                    <div style={{
                                                        float: "right",
                                                        margin: "0",
                                                        fontSize: "10px",
                                                        color: "#FF1744",
                                                        textAlign: "right",
                                                    }}>
                                                        {
                                                            touched["heartDisease"] && values["heartDisease"] === null ? (
                                                                    <span>Required Field</span>
                                                            ) : null
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span className="hLine" />
                                        </Grid>
                                    </>
                            }
                            {!isFromClientDetail && ((values["healthcare"] === 1 && isLongForm(offerType)) || !isLongForm(offerType)) &&
                                    <>
                                        <Physician
                                                isFromClientDetail={isFromClientDetail}
                                                nationalLifeId={nationalLifeId}
                                                offerType={offerType}
                                                setPhysiciansCount={(count: number) => setPhysiciansCount(count)}
                                                user={user}
                                        />
                                        <Grid item xs={12}>
                                            <span className="hLine" />
                                        </Grid>
                                    </>
                            }
                        </React.Fragment>
                    }
                    {/*End GI CONDITION*/}
                    {!isLongForm(offerType) &&
                            <>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="body1" component="strong"
                                                className="floatLeft mt15">
                                        {user && user.client && user.client.carrierId === nationalLifeId ?
                                                <Label label={<>Within the last 5 years have you used any product
                                                    containing tobacco or nicotine, including but not limited to
                                                    cigarettes, <span
                                                            style={{ display: "inline-flex" }}> e-cigarettes,</span> vape
                                                    pens, cigars, pipes, chewing tobacco, snuff, nicotine gum and/or
                                                    nicotine patch? (If yes, provide type of product used, frequency,
                                                    and date of last use.)</>}
                                                       required={!isFromClientDetail} />
                                                :
                                                <Label label={`${isFromClientDetail ? "Has the client's used any type of product containing tobacco or nicotine within the last five years?" : "Have you used any type of product containing tobacco or nicotine within the last five years?"}`}
                                                       required={!isFromClientDetail} />
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    {fieldObj1.map((field, index) => {
                                        return (
                                                <div className={classes.radioGroup + " floatRight "} key={index + field["name"]}>
                                                    <RadioGroup
                                                            className="mb0 checkGroup checkGroupEnrollment"
                                                            name={field.name}
                                                            onChange={(e) => {
                                                                handleRadioChange(e, field.name)
                                                                if((field.name === 'isDrugTakenLast5Yr') && (values["isDrugTakenLast5Yr"] === "Yes")){
                                                                    setFieldValue("productType", "");
                                                                    setFieldValue("frequency", "");
                                                                }
                                                            }}
                                                            value={values[field.name]}
                                                            style={{ display: "inline" }}>
                                                        {[{ label: "Yes", value: "Yes" }, {
                                                            label: "No",
                                                            value: "No",
                                                        }].map((option: any, idx: number) => (
                                                                <FormControlLabel className="mb0"
                                                                                  disabled={field.disabled || (role && role <= 4) || false}
                                                                                  value={option.value}
                                                                                  control={<Radio
                                                                                          className="mb0"
                                                                                          color="primary" />}
                                                                                  label={option.label} />
                                                        ))}
                                                    </RadioGroup>
                                                    {touched[field.name] && errors[field.name] && (
                                                            <ErrorCommon errors={errors}
                                                                         name={field.name}
                                                                         touched={touched} />
                                                    )}
                                                </div>
                                        );
                                    })
                                    }
                                </Grid>
                                <Grid container spacing={2}>
                                    {values["isDrugTakenLast5Yr"] === "Yes" && fieldObj2.map((field, index) => {
                                        return (
                                                <Grid item xs={12} md={4}
                                                      key={index + field["name"]}
                                                      className="mt10">
                                                    {
                                                        CommonField(
                                                                field, {
                                                                    values,
                                                                    errors,
                                                                    touched,
                                                                    handleChange,
                                                                    handleBlur,
                                                                    dirty,
                                                                },
                                                        )
                                                    }
                                                </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <span className="hLine" />
                                </Grid>
                            </>
                    }
                    {/*START GI CONDITION*/}
                    {offerType !== 'gi' &&
                            <React.Fragment>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="body1" component="strong"
                                                className="floatLeft mt15 ">
                                        <Label label={`Family History`}
                                               required={!isFromClientDetail}/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {fieldObj3.map((field, index) => {
                                        return (
                                                <React.Fragment key={index}>
                                                    <RadioGroup color="primary"
                                                                className="checkGroup checkGroupEnrollment "
                                                                name={field.name}
                                                                onChange={e => {
                                                                    if(e.target.value === 'unknown'){
                                                                        resetFMDetails(field.name)
                                                                    }
                                                                    handleRadioChange(e, field.name)
                                                                }}
                                                                value={values[field.name]}
                                                    >
                                                        {field.options.map((option: any) => (
                                                                <FormControlLabel
                                                                        value={option.value}
                                                                        disabled={field.disabled || false}
                                                                        control={<Radio
                                                                                color="primary"/>}
                                                                        label={option.label}/>
                                                        ))}
                                                    </RadioGroup>
                                                    {touched[field.name] && errors[field.name] && (
                                                            <ErrorCommon errors={errors}
                                                                         name={field.name}
                                                                         touched={touched}/>
                                                    )}
                                                </React.Fragment>

                                        );
                                    })
                                    }

                                </Grid>
                                <Grid item xs={12} md={8} >
                                    <Grid className="mt15" container spacing={2}>
                                        {values["father"] === "fatherAlive" ?
                                                fieldObj4.map((field, index) => {
                                                    return (
                                                            <Grid item xs={12} xl={6} key={index}>
                                                                {CommonField(
                                                                        field, {
                                                                            values,
                                                                            errors,
                                                                            touched,
                                                                            handleChange,
                                                                            handleBlur,
                                                                            dirty,
                                                                        },
                                                                )
                                                                }
                                                            </Grid>
                                                    );
                                                })
                                                :
                                                (values["father"] === "fatherDeceased" && fieldObj5.map((field, index) => {
                                                            return (
                                                                    <Grid item xs={12} xl={6} key={index}>
                                                                        {CommonField(
                                                                                field, {
                                                                                    values,
                                                                                    errors,
                                                                                    touched,
                                                                                    handleChange,
                                                                                    handleBlur,
                                                                                    dirty,
                                                                                },
                                                                        )
                                                                        }
                                                                    </Grid>
                                                            );
                                                        })
                                                )
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {fieldObj6.map((field, index) => {
                                        return (
                                                <React.Fragment key={index}>
                                                    <RadioGroup
                                                            className="checkGroup mt10 checkGroupEnrollment"
                                                            name={field.name}
                                                            onChange={e => {
                                                                if(e.target.value === 'unknown'){
                                                                    resetFMDetails(field.name)
                                                                }
                                                                handleRadioChange(e, field.name)
                                                            }}
                                                            value={values[field.name]}
                                                    >
                                                        {field.options.map((option: any) => (
                                                                <FormControlLabel
                                                                        value={option.value}
                                                                        disabled={field.disabled || false}
                                                                        control={<Radio
                                                                                color="primary"/>}
                                                                        label={option.label}/>
                                                        ))}
                                                    </RadioGroup>
                                                    {touched[field.name] && errors[field.name] && (
                                                            <ErrorCommon errors={errors}
                                                                         name={field.name}
                                                                         touched={touched}/>
                                                    )}
                                                </React.Fragment>
                                        );
                                    })
                                    }
                                </Grid>
                                <Grid item xs={12} md={8} >
                                    <Grid className="mt15" container spacing={2}>
                                        {
                                            values["mother"] === "motherAlive" ?
                                                    fieldObj7.map((field, index) => {
                                                        return (
                                                                <Grid item xs={12} xl={6} key={index}>
                                                                    {
                                                                        CommonField(
                                                                                field, {
                                                                                    values,
                                                                                    errors,
                                                                                    touched,
                                                                                    handleChange,
                                                                                    handleBlur,
                                                                                    dirty,
                                                                                },
                                                                        )
                                                                    }
                                                                </Grid>
                                                        );
                                                    })
                                                    :
                                                    (values["mother"] === "motherDeceased" &&
                                                            fieldObj8.map((field, index) => {
                                                                return (
                                                                        <Grid item xs={12} xl={6} key={index}>
                                                                            {
                                                                                CommonField(
                                                                                        field, {
                                                                                            values,
                                                                                            errors,
                                                                                            touched,
                                                                                            handleChange,
                                                                                            handleBlur,
                                                                                            dirty,
                                                                                        },
                                                                                )
                                                                            }
                                                                        </Grid>
                                                                );
                                                            })
                                                    )
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={8} lg={4} >
                                    <Grid container spacing={2} className="mt15">
                                        {offerType !== 'full' && fieldObj15.map((field, index) => {
                                            return (
                                                    <Grid item xs={12} key={index}>
                                                        {CommonField(
                                                                field, {
                                                                    values,
                                                                    errors,
                                                                    touched,
                                                                    handleChange,
                                                                    handleBlur,
                                                                    dirty,
                                                                },
                                                        )
                                                        }
                                                    </Grid>
                                            );
                                        })
                                        }
                                    </Grid>
                                </Grid>

                                {values.noOfSiblings > 0 && offerType !== 'full' &&
                                        getSiblingDetail(values.noOfSiblings, values, handleChange, handleBlur, touched, errors, dirty)
                                }

                                <Grid item xs={12}>
                                    <span className="hLine"/>
                                </Grid>
                            </React.Fragment>
                    }
                </Grid>

                <div style={{ display: "none" }}>
                    <Button
                            type="submit"
                            className={"detailPageSubmitBtn"}
                            disabled={isSubmitting}
                            onClick={() => {
                                let stickyFooter: any = document.getElementById("stickyFooter");
                                if (stickyFooter) {
                                    stickyFooter.style.display = "none";
                                }
                            }}
                    />
                </div>

            </form>
    )
}