import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Box, Grid} from "@material-ui/core";
import {TextField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import {Formik} from "formik";
import {Label} from "../../../../../components/formInputs";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function ResetPayment(props:any) {
    const {handleClose, open} = props;
    const initialValues = {

    };
    const validate = ((value: any) => {
        let errors: any = {};

        return errors;
    });
    const fieldObj = [
        {
            name: "message",
            label: <Label label={"Message"} required={true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            multiline:true,
            rows:10
        }
    ];

    return (
            <div>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Reset Client Payment Details
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography
                                component="span"
                                variant="body1"
                        >You can reset the client payment steps with enrollment. The client and agent will be emailed with your message and the client can re-submit their payment details</Typography>
                        <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validate={values => validate(values)}
                                validateOnChange={true}
                                onSubmit={async (values, { setSubmitting }) => {
                                    console.log("ClientProfileTabDataSubmitted", values);
                                    /*await accountActions.updateUserBasicDetails(values, client && client.userId || 0);
                                    setTimeout(() => {
                                        setNextStep();
                                        setSubmitting(false);
                                    }, 400);*/
                                }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  setFieldValue,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                              }) => (<form onSubmit={handleSubmit}>
                                {fieldObj.map((field, index) => {
                                    return (
                                            <Box m={1} key={index}>
                                                <div className="mt30 customFields">
                                                    <Grid item xs={12} sm={4} md={4}
                                                          key={index}>
                                                        <TextFieldCommon
                                                                {...field}
                                                                values={1}
                                                                //onChange={handleChange}
                                                        />
                                                        {/*<ErrorCommon errors={errors} name={field.name}
                                                             touched={touched}/>*/}
                                                    </Grid>
                                                </div>
                                            </Box>
                                    );
                                })
                                }
                            </form>)}
                        </Formik>
                        <Button variant="contained" className="floatRight" onClick={handleClose} color="primary">
                            Send
                        </Button>
                    </DialogContent>
                </Dialog>
            </div>
    );
}
