import React from "react";
import {
	RadioGroup as RadioGroups,
	FormControlLabel,
	Radio,
	FormControl,
	FormHelperText,
	Tooltip, FormLabel,
} from "@material-ui/core";
import { FormInputProps } from "../../model";

const defaultOptions: object[] = [
	{
		text: "Yes",
		value: "Yes"
	}, {
		text: "No",
		value: "No"
	}
];

interface RadioGroupProps extends FormInputProps {
	label: string,
	toolTipLabel: string,
	options: any[]
}

interface oProps {
	value: string | number,
	text: string
}

export default function RadioGroup({

											required,
											fullWidth,
											disabled,
											label,
											toolTipLabel,
											options = defaultOptions,
											meta: { touched, error },
											input,
											...custom
										}: RadioGroupProps) {
											
	return (
			<FormControl
					required={required}
					fullWidth={fullWidth}
					disabled={disabled}
					error={touched && error}
			>
				{
					label &&
		  		<FormLabel>{label}</FormLabel>
				}
				<RadioGroups
						row
						{...input}
						{...custom}
				>
					{
						options.map((o: oProps, idx: number) =>
								<FormControlLabel
										className="radioGroup mb0"
										key={`${idx}-${o.text}`}
										checked={input && input.value === o.value}
										value={o.value}
										control={<Tooltip title={'Select ' + (toolTipLabel || o.text)}><Radio color="primary"/></Tooltip>}
										label={o.text}

								/>)
					}
				</RadioGroups>
				{
					(touched && error) &&
		  		<FormHelperText>{touched && error}</FormHelperText>
				}
			</FormControl>
	);
}