import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {
    Button,
    Checkbox,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    TableCell,
    TextField,
    Typography,
} from "@material-ui/core";
import { AccountStatusArr } from "../../../../model";
import SearchIcon from "@material-ui/icons/Search";
import { ParticipantTable } from "../../../../components/group/ParticipantList";
import { useActions } from "../../../../actions";
import * as AccountActions from "../../../../actions/account";
import { TableQuickView } from "../TableQuickView";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            Paper: {
                minWidth: 767,
            },
            closeButton: {
                position: "absolute",
                right: 5,
                top: 10,
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const statusTypes = {
    "Invitations": [0, 1, 2],
    "Enrollment": [4, 15, 11, 12, 13, 16, 17, 18, 19, 20, 21, 22, 27],
};
type StatusTypesProps = {
    value: string[]
    onChange(newValue: string[]): void;
}
const StatusTypes = (props: StatusTypesProps) => {
    const { value, onChange } = props;

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as string[]);
    };

    const getMenuItems = (subheader) => {
        return [
            <ListSubheader disableSticky={true}>{subheader}</ListSubheader>,
            ...statusTypes[subheader].map(statusTypeIdx => (
                    <MenuItem value={statusTypeIdx} key={statusTypeIdx}>
                        <Checkbox checked={value.indexOf(statusTypeIdx) > -1}/>
                        <ListItemText primary={AccountStatusArr[statusTypeIdx]}/>
                    </MenuItem>
            )),
        ];
    };

    const displayStatus = (selected) => {
        if (selected.length === 0)
            return "All Status Types";

        return selected.map(statusTypeIdx => AccountStatusArr[statusTypeIdx]).join(", ");
    };

    return (
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel htmlFor="status-types" shrink>Status Types</InputLabel>
                <Select
                        multiple
                        labelId="status-types"
                        renderValue={displayStatus}
                        onChange={handleChange}
                        value={value}
                        displayEmpty={true}
                >
                    {
                        Object.keys(statusTypes).map(subheader => getMenuItems(subheader))
                    }
                </Select>
            </FormControl>
    );
};

export default function KpiModal(props: any) {
    const {
        open,
        handleClose,
        modalValue,
        modalTitle,
        exportList,
        children,
        isExportListVisible = true,
        imoUserId = null,
        agentUserId = null,
        selectable,
    } = props;
    const [statusTypes, setStatusTypes] = React.useState<string[]>([]),
            [search, setSearch] = React.useState(""),
            [advanceSearch, setAdvanceSearch] = React.useState(false),
            [kpiAdvData, setKpiAdvData] = React.useState([]);
    const clientViewArr = ["ENROLLED", "CURRENTLY_IN_ACTIVE", "CURRENTLY_ACTIVE", "ENROLLING", "TOTAL_INVITES", "TOTAL_ACCOUNT_CREATED"];
    const accountActions = useActions(AccountActions);
    let showFilters = false;
    if (clientViewArr.includes(modalValue)) {
        showFilters = true;
    }
    let title = "";
    let placeholder = title = "FirstName, LastName, Email";
    const participantTableComponent: any = (kpiAdvData: any = []) => <ParticipantTable
            page={0}
            rowsPerPage={kpiAdvData.length}
            accountList={{ rows: kpiAdvData }}
            order={"NONE"}
            orderBy={"NONE"}
            handleRequestSort={() => null}
            isContributionVisible={account => true}
            isEmployerContributionNotVisible={true}
    >
        {(v) =>
                <TableCell padding="default" align="right">
                    -
                </TableCell>}
    </ParticipantTable>;
    const agentClientTableComponent = (kpiData: any = []) => (
            <TableQuickView
                    kpiData={kpiData}
                    selectable={selectable}
                    onRefresh={() => {
                        getComponentContent();
                    }}/>
    );

    const getComponentContent = async () => {
        let resp = imoUserId ? await accountActions.getImoDashboardLists({
            listType: `CLIENT_${modalValue}`,
            listDataType: "json",
            imoUserId,
            statusTypes: statusTypes.join(","),
            searchText: search,
        }) : await accountActions.getAgentDashboardLists({
            listType: modalValue,
            listDataType: "json",
            statusTypes: statusTypes.join(","),
            searchText: search,
        });

        if (resp && resp.length > 0) {
            setKpiAdvData(resp);
        } else if (resp && resp.length === 0) {
            setKpiAdvData(resp);
        }
    };
    React.useEffect(() => {
        setSearch("");
        setStatusTypes([]);
        setAdvanceSearch(false);
    }, [open]);
    React.useEffect(() => {
        if (search != "" || statusTypes.length > 0) { //advance search
            getComponentContent().then(() => {
            });
            setAdvanceSearch(true);
        } else {
            setAdvanceSearch(false);
        }
    }, [statusTypes, search]);

    const handleTextSearchChange = (e: any) => {
        const textSearch = e.target.value;
        setSearch(textSearch);
    };
    return (
            <div>
                <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {modalTitle}
                    </DialogTitle>
                    <DialogContent dividers>
                        {
                                showFilters && (imoUserId || agentUserId) &&
                                <Grid container spacing={2} justify="flex-end" className="mt10">
                                    <Grid item xs={12} md={6} lg={4}>
                                        <StatusTypes value={statusTypes} onChange={setStatusTypes}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <TextField
                                                value={search}
                                                variant="outlined"
                                                placeholder={"Search by " + title}
                                                label={"Search by " + placeholder}
                                                onChange={handleTextSearchChange}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                        className="iconBtn"
                                                                        aria-label="search account"
                                                                >
                                                                    <SearchIcon/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                    ),
                                                }}
                                        />
                                    </Grid>
                                </Grid>
                        }
                        {!advanceSearch ? children : imoUserId ? participantTableComponent(kpiAdvData) : agentClientTableComponent(kpiAdvData)}


                        <Button autoFocus className={"floatLeft floatRightSM mb15 mt30"} onClick={handleClose}
                                color="secondary"
                                variant="contained">
                            Cancel
                        </Button>
                        {isExportListVisible &&
                                <Button autoFocus className={"floatRight mt30"}
                                        onClick={() => exportList(modalValue, statusTypes.join(","), search)}
                                        color="primary" variant="contained">
                                    Export List
                                </Button>
                        }

                    </DialogContent>
                </Dialog>
            </div>
    );
}