import React from "react";
import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";
import {
    AppBar,
    IconButton,
    Toolbar,
    MenuItem,
    Menu,
    Link,
    Typography
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import iliaMini from "../../../../../components/client/landingPage/v2/images/ilia_small.png";
import { LOGO_BASE_URL } from "../../../../../constants";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            grow: {
                flexGrow: 1,
            },
            menuButton: {
                marginRight: theme.spacing(2),
            },
            title: {
                display: "none",
                [theme.breakpoints.up("sm")]: {
                    display: "block",
                },
            },
            search: {
                position: "relative",
                borderRadius: theme.shape.borderRadius,
                backgroundColor: fade(theme.palette.common.white, 0.15),
                "&:hover": {
                    backgroundColor: fade(theme.palette.common.white, 0.25),
                },
                marginRight: theme.spacing(2),
                marginLeft: 0,
                width: "100%",
                [theme.breakpoints.up("sm")]: {
                    marginLeft: theme.spacing(3),
                    width: "auto",
                },
            },
            searchIcon: {
                padding: theme.spacing(0, 2),
                height: "100%",
                position: "absolute",
                pointerEvents: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            inputRoot: {
                color: "inherit",
            },
            inputInput: {
                padding: theme.spacing(1, 1, 1, 0),
                // vertical padding + font size from searchIcon
                paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
                transition: theme.transitions.create("width"),
                width: "100%",
                [theme.breakpoints.up("md")]: {
                    width: "20ch",
                },
            },
            sectionDesktop: {
                display: "none",
                [theme.breakpoints.up("md")]: {
                    display: "flex",
                },
            },
            sectionMobile: {
                display: "flex",
                [theme.breakpoints.up("md")]: {
                    display: "block",
                },
            },
            logo: {
                maxHeight: 60,
                margin: 10,
            },
        }),
);

export default function PrimarySearchAppBar(props: any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [ scrollClass, setScrollClass ] = React.useState<string>('');
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const startegyLogo: string = props.strategyData.logo;
    let clientLogo: string = props.groupDetail && props.groupDetail.user && props.groupDetail.user.image ? LOGO_BASE_URL + props.groupDetail.user.image : (props.agent && props.agent.companyLogo ? LOGO_BASE_URL + props.agent.companyLogo : "");

    let partnerLogo: string = iliaMini;
    if (props.strategyData && props.strategyData.reBrand === 1 && props.strategyData.reBrandLogo) {
        let isExist: boolean = props.strategyData.reBrandLogo.includes("s3.amazonaws.com");
        if (isExist) {
            partnerLogo = props.strategyData.reBrandLogo;
        } else {
            partnerLogo = LOGO_BASE_URL + props.strategyData.reBrandLogo;
        }
    }
    if (props.strategyData && props.strategyData.reBrand === 2 && props.agent && props.agent.imo && props.agent.imo.reBrand && props.strategyData.reBrandLogo) {
        let isExist: boolean = props.strategyData.reBrandLogo.includes("s3.amazonaws.com");
        if (isExist) {
            partnerLogo = props.strategyData.reBrandLogo;
        } else {
            partnerLogo = LOGO_BASE_URL + props.strategyData.reBrandLogo;
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            let activeClass: string = 'scroll-normal';
            if (window.scrollY === 0) {
                activeClass = 'scroll-top';
            }
            setScrollClass(activeClass);
        });
    }, [])


    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = "primary-search-account-menu";
    let redirectUrl:string = props && props.match && props.match.url;
    let searchParams:string = props && props.location.search;
    let encodeUrl:string = "";
    let url:string = "";
    encodeUrl = btoa(redirectUrl);
    if(searchParams) {
        encodeUrl = btoa(redirectUrl + searchParams);
    }
    if(redirectUrl) {
        url = `/login?token=${encodeUrl}`;
    }
    const renderMenu = (
            <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    id={menuId}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
            >
                {props.startEstimation < 3 &&
                <MenuItem>
                    <Link variant="caption" onClick={() => {
                        window.location.href = url;
                    }}>Have an ilia account? Login.</Link>
                </MenuItem>
                }

            </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
            <Menu
                    anchorEl={mobileMoreAnchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    id={mobileMenuId}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMobileMenuOpen}
                    onClose={handleMobileMenuClose}
            >
                {props.startEstimation < 3 &&
                <MenuItem>
                    <Link variant="caption" onClick={() => {
                        window.location.href = url;
                    }}>Have an ilia account? Login.</Link>
                </MenuItem>
                }

            </Menu>
    );

    return (
            <div className={classes.grow}>
                <AppBar position="fixed" color="secondary" className={`header ${scrollClass}`}>
                    <Toolbar>
                        <div className="footerImg floatLeft">
                            <img alt={"partnerlogo"} src={partnerLogo} className="logo"/>
                            {startegyLogo ?
                                    <img alt={"strategylogo"} className="logo" src={startegyLogo}/> :
                                    <Typography color="primary" component="strong" className="f22 ml15 mt5">
                                        {props.strategyData.name}
                                    </Typography>
                            }
                            {clientLogo && <div className="Vdivider">
                                <img alt={"clientlogo"} className="logo ml15" src={clientLogo}/>
                            </div>
                            }
                        </div>

                        <div className={classes.grow}/>
                        <div className={classes.sectionDesktop}>
                            {props.startEstimation < 3 &&
                            <MenuItem className="mb0">
                                <Link variant="caption" onClick={() => {
                                    window.location.href = url;
                                }}>Have an ilia account? Login.</Link>
                            </MenuItem>
                            }

                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                            >
                                <MoreIcon/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </div>
    );
}
