import React from "react";
import {Button, Grid, LinearProgress, Link, Typography} from "@material-ui/core";
import BackArrow from "./BackArrow";
import StepHeading from "./StepHeading";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../reducers";
import EnrollmentHelp from "../../basicInfo/EnrollmentHelp";
import AttestationOfTruth from "../FinancialAndLiabilities/AttestationOfTruth";
import {scroller} from "react-scroll";
import AviationAvocationForeignTravelContent from "./AviationAvocationForeignTravelContent";
import {Label} from "../../../../../../components/formInputs";
import {TextField} from "../../../../../../components/formikFormInputs";
import {isIliaApp, participationArr} from "../Utilities";
import {useActions} from "../../../../../../actions";
import * as AccountActions from "../../../../../../actions/account";

type PropsType = {
    isFromClientDetail: boolean,
    setPrevStep: Function,
    setNextStep: Function,
    offerType: string,
    basicInfoStep: string,
    clientDetail?: any // only for client case detail
}

function getTextFieldCommon(isFromClientDetail: boolean, clientSigned: boolean) {
    return {
        helperFieldText: "",
        label: <Label label={"Please Explain"} required={!isFromClientDetail}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }
};

function AviationAvocationForeignTravel(props: PropsType) {
    const {isFromClientDetail, setPrevStep, setNextStep, offerType, basicInfoStep} = props;
    const [attestRef, setAttestRef] = React.useState<any>(null);
    const [isSubmittedQuestions, setIsSubmittedQuestions] = React.useState<boolean>(false);
    const [saveAviationAvocationQuestions, setSaveAviationAvocationQuestions] = React.useState<object>({});
    const { updateClientFields } = useActions(AccountActions);

    const buttonRef = React.useRef<any>();
    let userData = useSelector((state: RootState) => state.profile);
    let user = props.clientDetail || userData;
    let enrollment: any = (user && user.enrollment) || undefined;
    let envelopeId = enrollment && enrollment.envelopeId || "";
    let isChecked: boolean = false;
    if (enrollment) {
        isChecked = `${enrollment.enrollmentStatus}.${enrollment.enrollmentSubStatus}` > "1.4";
    }
    let clientSigned: boolean = (enrollment && (enrollment.clientSigned || enrollment.envelopeId)) || false;

    const [pageChecked, setPageChecked] = React.useState<boolean>(isChecked);
    const textFieldCommon = getTextFieldCommon(isFromClientDetail, clientSigned);
    const [disableSaveButton, setDisableSaveButton] = React.useState(true);
    const answersJson = enrollment && enrollment.answers ? JSON.parse(enrollment.answers) : [];
    let initialValues = {};

    if (isIliaApp(offerType) || offerType === 'full') {
        participationArr.forEach((iteam) => { // set initial values from enrollment answers JSON
            initialValues[`${iteam}`] = answersJson[`${iteam}`] ? answersJson[`${iteam}`] : null;
        });
        initialValues["InsuredAviationAvocation"] = answersJson["InsuredAviationAvocation"] && answersJson["InsuredAviationAvocation"].length > 0 ? answersJson["InsuredAviationAvocation"]: [];
    }

    React.useEffect(() => {
        if (answersJson && (answersJson["Proposed_Insured_Questions-32"] === "Yes" || answersJson["Proposed_Insured_Questions-32"] === "No")) {
            setDisableSaveButton(false);
        }
    }, [answersJson])

    React.useEffect(() => {
        if (!pageChecked && offerType !== "gi" && isSubmittedQuestions && attestRef && attestRef.current) {
            setIsSubmittedQuestions(false);
            scroller.scrollTo(attestRef.current.id, {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: -200,
            });
        }
    }, [isSubmittedQuestions === true, pageChecked]);

    React.useEffect(() => {
        if (Object.keys(saveAviationAvocationQuestions).length > 0) {
            saveMe(saveAviationAvocationQuestions).then(() => {
            }); // auto save
        }
    }, [saveAviationAvocationQuestions]);

    const saveMe = async (values: any) => {
        let payload: any = {};
        if(isIliaApp(offerType) || offerType === 'full') {
            let insuredQuestionObj = {}
            participationArr.forEach((iteam) => { // set values from enrollment answers JSON
                insuredQuestionObj[`${iteam}`] = !!values[`${iteam}`] ? values[`${iteam}`] : null;
            });
            insuredQuestionObj["InsuredAviationAvocation"] = values["InsuredAviationAvocation"]
            payload.insuredQuestions = insuredQuestionObj;
        }
        console.log("in line 109", payload)
        console.log("isIliaApp(offerType)", isIliaApp(offerType))
        await updateClientFields({
            ...payload,
            formType: "INSURED_QUESTIONS", //Note: It was the part of insured questions
        }, (user && user.client && user.client.id) || "", isFromClientDetail);
    };
console.log("initialValues line no 119", initialValues)
    return <>
        {
                !isFromClientDetail &&
                <BackArrow setPrevStep={setPrevStep} envelopeId={envelopeId}/>
        }
        <div className="enrollmentContent inner mt30">
            {
                    !isFromClientDetail &&
                    <StepHeading step={basicInfoStep} offerType={offerType}/>

            }
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={!isFromClientDetail ? 9:12}>
                    {!isFromClientDetail &&
                            <Grid container spacing={2}>
                                <AttestationOfTruth
                                        checked={(user && user.client && user.client.agreeOnAviationAvocation) ? true : false}
                                        setPageChecked={setPageChecked}
                                        setAttestRef={setAttestRef}
                                        name={"AVIATION_AVOCATION_FOREIGN_TRAVEL"}
                                        userData={user}
                                />

                            </Grid>
                    }

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <AviationAvocationForeignTravelContent initialValues={initialValues}
                                                                   clientSigned={clientSigned}
                                                                   enableSaveButton={() => {
                                                                   }} // note : check enable save button
                                                                   isFromClientDetail={isFromClientDetail}
                                                                   offerType={offerType} role={user.role}
                                                                   textFieldCommon={textFieldCommon}
                                                                   setSaveAviationAvocationQuestions={setSaveAviationAvocationQuestions}
                                                                   setDisableSaveButton={setDisableSaveButton}
                                                                   buttonRef={buttonRef}
                                                                   user={user}
                                                                   pageChecked={pageChecked}
                                                                   setNextStep={setNextStep}
                                                                   saveMe={saveMe}
                            />
                        </Grid>
                    </Grid>
                    {!isFromClientDetail &&
                            <Grid item xs={12} md={12}>
                                <Button
                                        variant="contained"
                                        color="primary"
                                        className="floatRight mb30 mt20"
                                        onClick={() => {
                                            //console.log("agreeRef", agreeRef)
                                            if (buttonRef.current) {
                                                buttonRef.current.click();
                                            }
                                        }}
                                        disabled={disableSaveButton}
                                        type={"submit"}
                                >
                                    Save and Continue
                                </Button>
                            </Grid>
                    }
                </Grid>
                {
                        !isFromClientDetail &&
                        <Grid item xs={12} md={12} lg={3} xl={3}>
                            <EnrollmentHelp/>
                        </Grid>
                }
            </Grid>
        </div>
    </>
}

export default AviationAvocationForeignTravel;