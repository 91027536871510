import React, {useEffect, useState} from "react";
import {Label} from "../../../../../components/formInputs";
import {
    DatePicker,
    ErrorCommon,
    SelectField,
    SSNNumberField,
    TextField,
    TextFieldCommon,
} from "../../../../../components/formikFormInputs";
import {
    defaultOfferTypes,
    defaultStrategies,
    livingBenefitsAllianz,
    livingBenefitsNationalLife,
    policyStatus,
    PolicyStatusEnum,
} from "../../../../../constants";
import {Grid} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import {useActions} from "../../../../../actions";
import * as ClientActions from "../../../../../actions/client";
import * as AccountActions from "../../../../../actions/account";
import * as Actions from "../../../../../actions/account";
import PolicyStatus from "../modals/PolicyStatus";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {getGroupNumber} from "../../../../../utils/commonUtils";
import BirthDate from "../../../Enrollment/EnrollmentHome/basicInfo/ProfileFormFields/BirthDate";
import PersonalDetailFieldObj from "../../../Enrollment/EnrollmentHome/basicInfo/PersonalDetailFieldObj";
import moment from "moment";
import {lastDayOfMonth} from "../../../Enrollment/EnrollmentHome/basicInfo/PersonalDetailUtils";
import CommonFieldFunction from "../../../Enrollment/EnrollmentHome/basicInfo/CommonFieldFunction";
import * as CarrierActions from "../../../../../actions/carrier";


const applicationType: object[] = [
    {
        key: "ILIA",
        value: "ILIA",
        name: "ILIA",
    }, {
        key: "NIW",
        value: "NIW",
        name: "NIW",
    }, {
        key: "PAPER",
        value: "PAPER",
        name: "PAPER",
    },
];
interface caseDetailsProps {
    values: any,
    handleChange: Function,
    errors: any,
    touched: any,
    clientId: number,
    caseDetails: any,
    caseProcessorList: any,
    caseManagerList: any,
    fetchCaseDetail: Function,
    ssnFlag: boolean,
    disabled: boolean,
    setFieldValue?: Function,
    handleBlur?: Function,
    setActionItem: Function,
    setMinAge: Function,
    minAge: number,
    policyStatusDisabled: boolean
}

const CaseDetails = (props: caseDetailsProps) => {
    const accountActions = useActions(Actions);
    const carrierActions = useActions(CarrierActions);
    const { getOfferByCarrierId } = useActions(ClientActions);
    const { getAgentImoStrategies, getLivingBenefitsList } = useActions(AccountActions);
    const detail: any = useSelector((state: RootState) => state.clientAccount);
    const clientCarrier = detail && detail.client && detail.client.carrier;
    const {
        values,
        handleChange,
        errors,
        touched,
        clientId,
        caseDetails,
        caseProcessorList,
        caseManagerList,
        fetchCaseDetail,
        ssnFlag,
        disabled = false,
        setFieldValue,
        handleBlur,
        setActionItem,
        setMinAge,
        minAge,
        policyStatusDisabled
    } = props;
    let { user: { role, participant } } = useSelector((state: RootState) => state.auth);
    let formValues = values;
    let userData = useSelector((state: RootState) => state.profile);
    let user = detail && detail.client || userData;
    const clientSigned = user && user.enrollment && user.enrollment.clientSigned ? 1 : 0;
    const [offerTypes, setOfferTypes] = useState<any>(defaultOfferTypes);
    const [strategyDropDownOptions, setStrategyDropDownOptions] = useState<any>(defaultStrategies);
    const carrierOfferTypes: any = useSelector((state: RootState) => state.carrierOfferTypes);
    const [policyStatusPopup, setPolicyStatusPopup] = React.useState(false);
    const [livingBenefits, setLivingBenefits] = React.useState<object[]>([]);

    const [carrierId, setCarrierId] = React.useState<number | null>(null);
    const [nationalLifeId, setNationalLifeId] = React.useState<number | null>(null);
    const [isNationalLife, setIsNationalLife] = React.useState<boolean>(false);
    const [isAllianzLife, setIsAllianzLife] = React.useState<boolean>(false);
    const [allianzId, setAllianzId] = React.useState<number | null>(null);
    const [strategySlug, setStrategySlug] = React.useState("");
    const [labelsActive, setLabelsActive] = React.useState<any[]>([]);
    const [actualHealthRatingTitle, setActualHealthRatingTitle] = React.useState([]);
    const [days, setDays] = React.useState([
        {
            id: "",
            key: "",
            name: "",
        },
    ]);
    const [isIssueAgeDisabled, setIsIssueAgeDisabled] = React.useState<boolean>(false);

    const getAllianzCarrierDetails = async () => {
        const allianzData = await accountActions.getAllianzDetails();
        setAllianzId(allianzData.allianzLife.id);
    };
    const getNationalLifeCarrierDetails = async () => {
        const nationalLifeDate = await accountActions.getNationalLifeDetails();
        if (nationalLifeDate && nationalLifeDate.nationalLife) {
            setNationalLifeId(nationalLifeDate.nationalLife.id);
        }
    };

    useEffect(() => {
        getAllianzCarrierDetails();
        getNationalLifeCarrierDetails();
    }, []);
    useEffect(() => {
        if (isNationalLife) {
            setMinAge(18); //for National Life Carrier MIN AGE is 18;
        }
    }, [isNationalLife]);

    useEffect(() => {
        if (isAllianzLife) {
            setMinAge(18); //for National Life Carrier MIN AGE is 18;
        }
    }, [isAllianzLife]);

    useEffect(() => {
        if (nationalLifeId && carrierId && nationalLifeId === carrierId && strategySlug === "kaizen") {
            setIsNationalLife(true);
        }
    }, [nationalLifeId, carrierId]);

    useEffect(() => {
        if (allianzId && carrierId && allianzId === carrierId && strategySlug === "kaizen") { //if Allianz Life and strategy is Kaizen
            setIsAllianzLife(true);
        }
    }, [allianzId, carrierId]);

    useEffect(() => {
        setOfferTypes(defaultOfferTypes);
        if (carrierOfferTypes.length > 0) {
            let carrierOfferTypesobj = carrierOfferTypes.map((offer) => {
                return {
                    id: offer.value,
                    name: offer.name,
                    value: offer.value,
                };
            });
            setOfferTypes(carrierOfferTypesobj);
        }
    }, [strategyDropDownOptions, carrierOfferTypes]);

    useEffect(() => {
        let ClientCarrierId = detail && detail.client && detail.client.carrier && detail.client.carrier.id;
        if (ClientCarrierId > 0) {
            getAgentStrategies(ClientCarrierId);
            getOfferByCarrierId(ClientCarrierId, false);
            if(caseDetails && caseDetails.carriersDetails){
                if(caseDetails.carriersDetails && caseDetails.carriersDetails.allianzLife && caseDetails.carriersDetails.allianzLife.value === caseDetails.carrierId){
                    setIsIssueAgeDisabled(true);
                } else if(caseDetails.carriersDetails && caseDetails.carriersDetails.nationalLife && caseDetails.carriersDetails.nationalLife.value === caseDetails.carrierId){
                    setIsIssueAgeDisabled(true);
                } else {
                    setIsIssueAgeDisabled(false);
                }
            }
        }
        if (detail.client && detail.client.strategy && detail.client.strategy.slug) {
            setStrategySlug(detail.client.strategy.slug);
        }
        if (detail.client && detail.client.carrierId) {
            setCarrierId(detail.client.carrierId);
        }



    }, [detail]);

    useEffect(() => {
        if (carrierId) {
            carrierActions.getCarrierActualHealthTitle(carrierId).then((res) => {
                const actualHealthArr = res && res.length && res.map((item, index) => {
                    return {
                        key: item.title,
                        value: item.title,
                        name: item.title,
                    };
                }) || [];
                setActualHealthRatingTitle(actualHealthArr);
            });
        }
    }, [carrierId]);

    useEffect(() => {
        if (caseDetails && caseDetails.carrierId) {
            if (caseDetails && caseDetails.carriersDetails) {
                if (caseDetails.carriersDetails && caseDetails.carriersDetails.allianzLife && caseDetails.carriersDetails.allianzLife.value === caseDetails.carrierId) {
                    livingBenefitsDropDown(livingBenefitsAllianz);
                } else if (caseDetails.carriersDetails && caseDetails.carriersDetails.nationalLife && caseDetails.carriersDetails.nationalLife.value === caseDetails.carrierId) {
                    livingBenefitsDropDown(livingBenefitsNationalLife);
                } else {
                    livingBenefitsDropDown(livingBenefitsNationalLife);
                }
            }
        }
        getDays();
    }, [caseDetails]);

    const livingBenefitsDropDown = async (benefits: any) => {
        let livingBenefitsArr: object[] = [];

        const result = await getLivingBenefitsList();
        if (result && result.length > 0) {
            result.forEach((item: any) => {
                if (benefits.find(p => p.name === item.name)) {
                    const pushTo: object = {
                        key: item.id,
                        value: item.id,
                        name: item.name,
                    };
                    livingBenefitsArr.push(pushTo);
                }
            });
        }
        setLivingBenefits(livingBenefitsArr);
    };

    const getAgentStrategies = async (carrierId: number) => {
        const [agentId, imoId]: [number, number] = [detail.client.agent && detail.client.agent.id || 0, detail.client.imo && detail.client.imo.id || 0];
        let agentImoStrategies = await getAgentImoStrategies({
            agentId,
            carrierId,
            imoId,
            type: "INDIVIDUAL",
        });

        strategyDropDown(agentImoStrategies);
    };

    const strategyDropDown = strategies => {
        let strategyListArr: object[] = [];

        if (strategies && strategies.data && strategies.data.length > 0) {
            strategies.data.forEach((cs: any, subIdx: number) => {
                const pushTo = {
                    key: (cs && cs.strategyId) || 0,
                    value: (cs && cs.strategyId) || 0,
                    name: cs["strategies.name"] || "",
                };
                strategyListArr.push(pushTo);
            });
        }
        setStrategyDropDownOptions(strategyListArr);
        return strategyListArr;
    };

    const carrierDropDown = () => {
        let carrierListArr: object[] = [];
        const pushTo = {
            key: clientCarrier.id,
            value: clientCarrier.id,
            name: clientCarrier.carrierName,
        };
        carrierListArr.push(pushTo);
        return carrierListArr;
    };

    const handleClickOpen = () => {
        setPolicyStatusPopup(true);
    };

    const handleClose = () => {
        setPolicyStatusPopup(false);
    };
    const purposeDisabled = (formValues['policyStatus'] === PolicyStatusEnum.InForce);

    const fieldObj = [
        {
            name: "policyNumber",
            label: <Label label={"Policy Number"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled,
        },
        {
            id: "policyStatus",
            name: "policyStatus",
            label: <Label label={"Current Policy Status"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            inputProps: {
                style: { cursor: "pointer" },
            },
            InputProps: {
                endAdornment: <InputAdornment
                        className="margin0 dollerIcon cursor" position="start"
                ><ArrowDropDownIcon /></InputAdornment>,
                readOnly: true,
                style: { cursor: "pointer" },
            },
            onClick: () => {
                if (policyStatusDisabled) {
                    return true;
                }
                setPolicyStatusPopup(true);
            },
            disabled:policyStatusDisabled,
        },
        {
            id: "caseProcessorId",
            name: "caseProcessorId",
            label: <Label label={"Case Processor"} />,
            isRequired: false,
            component: SelectField,
            className: "fullWidthcustomFields",
            variant: "outlined",
            options: caseProcessorList,
            disabled
        },
        {
            id: "caseManagerId",
            name: "caseManagerId",
            label: <Label label={"Case Manager"} />,
            isRequired: false,
            component: SelectField,
            className: "fullWidthcustomFields",
            variant: "outlined",
            options: caseManagerList,
            disabled
        },
        {
            id: "purpose",
            name: "purpose",
            label: <Label label={"Application Type"} />,
            isRequired: true,
            component: SelectField,
            className: "fullWidthcustomFields",
            variant: "outlined",
            options: applicationType,
            // onSelectChange: (val: number) => {
            // },
            disabled: purposeDisabled,
        },
        {
            name: "employeeSSN",
            id: "employeeSSN",
            label: <Label label={caseDetails && caseDetails.usCitizen === 1 ? "Social Security Number" : "ITIN"} />,
            component: SSNNumberField,
            filedType: "string",
            variant: "outlined",
            disabled,
            className: "fullWidthcustomFields",
            formatChars: {
                "9": "[0-9]",
            },
        },
        // {
        //     name: "dob",
        //     label: <Label label={"DOB"}/>,
        //     isRequired: true,
        //     placeholder: "DOB",
        //     id: "dob",
        //     variant: "outlined",
        //     disableFuture: true,
        //     component: DatePicker,
        //     className: "fullWidthcustomFields",
        //     disabled
        // },

        {
            id: "healthRating",
            name: "healthRating",
            label: <Label label={"Applied Health Rating"} />,
            isRequired: true,
            component: SelectField,
            className: "fullWidthcustomFields",
            variant: "outlined",
            options: actualHealthRatingTitle,
            onSelectChange: (val: number) => {
            },
            disabled,
        },
        {
            name: "actualHealthRating",
            label: <Label label={"Actual Health Rating"} />,
            isRequired: true,
            component: SelectField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            options: actualHealthRatingTitle,
            onSelectChange: (val: number) => {
            },
            disabled,
        },
        {
            name: "actualHealthRatingOther",
            label: <Label label={"Actual Health Rating Other"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled,
        },
        {
            name: "strategyName",
            label: <Label label={"ilia Product Type"} />,
            id: "strategyName",
            isRequired: true,
            placeholder: "ilia Product Type",
            component: TextField,
            disabled: true,
            className: "fullWidthcustomFields",
        },
        {
            label: <Label label={"Carrier"} />,
            id: "carrierId",
            name: "carrierId",
            placeholder: "Select Carrier",
            component: SelectField,
            options: carrierDropDown(),
            onSelectChange: (val: number | string) => {
                getOfferByCarrierId(val, false);
                getAgentStrategies(val as number);
            },
            disabled: true,
            className: "fullWidthcustomFields",
        },
        {
            name: "productName",
            label: <Label label={"Carrier Product Type"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled,
        },
        {
            label: <Label label={"Offer Type"} />,
            id: "offerType",
            name: "offerType",
            placeholder: "Select Offer Type",
            component: SelectField,
            options: offerTypes,
            onSelectChange: (val: number | string) => {

            },
            disabled: true,
            className: "fullWidthcustomFields",
        },
        {
            name: "agent",
            label: <Label label={"Agent"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            disabled: true,
            className: "fullWidthcustomFields",
        },
        {
            name: "imo",
            label: <Label label={"IMO"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled: true,
        },
        {
            name: "salesRegion",
            label: <Label label={"Sales Region"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled: true,
        },
        {
            name: "issueDate",
            label: <Label label={"Issue Date"} />,
            isRequired: true,
            placeholder: "Issue Date",
            id: "issueDate",
            component: DatePicker,
            disabled: true,
            className: "fullWidthcustomFields",
        },
        {
            id: "trancheStarted",
            name: "trancheStarted",
            label: <Label label={"Tranche Started"} />,
            placeholder: "Tranche Started",
            component: TextField,
            filedType: "number",
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled: true
        },
        {
            id: "trancheClosed",
            name: "trancheClosed",
            label: <Label label={"Tranche Closed"} />,
            placeholder: "Tranche Closed",
            component: TextField,
            filedType: "number",
            variant: "outlined",
            disabled: true,
            className: "fullWidthcustomFields",
        },
        {
            id: "livingBenefit",
            name: "livingBenefit",
            label: <Label label={"Living Benefits"} />,
            isRequired: true,
            component: SelectField,
            className: "fullWidthcustomFields",
            variant: "outlined",
            options: livingBenefits,
            multiple: true,
            value:values.livingBenefit,
            onSelectChange: (val: number) => {
            },
            disabled,
        },
    ];

    const issueAge = [
        {
            id: "issueAge",
            name: "issueAge",
            label: <Label label={"Issue Age"}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled: isIssueAgeDisabled
        }
    ];
    const setAnticipatedAge = (date, setValue, formValues) => {
        const age = moment().diff(date, "years");
        setValue("age", age);
    };
    const getDays = (year: number = 2020, month: number = 1) => {
        let lastDay: number = lastDayOfMonth(year, month);
        let day: any = [];
        for (let index = 1; index <= lastDay; index++) {
            day.push({
                id: index,
                key: index,
                name: index,
            });
        }
        setDays(day);
        return day;
    };
    const fieldObj4 = PersonalDetailFieldObj.fieldObj4(false, setAnticipatedAge, getDays, days);

    if (detail && detail.client && detail.client.tranche) {
        fieldObj.push({
            name: "groupNumber",
            label: <Label label={"Group Number"} />,
            id: "groupNumber",
            isRequired: false,
            placeholder: "Group Number",
            component: TextField,
            disabled: true,
            className: "fullWidthcustomFields",
        });
        const groupNumber = getGroupNumber(`${detail.client.tranche.trancheNumber}`);
        formValues = Object.assign(values, { groupNumber });
    }
    fieldObj.push({
        name: "anniversaryDate",
        label: <Label label={"Anniversary Date"} />,
        isRequired: true,
        placeholder: "Anniversary Date",
        id: "anniversaryDate",
        component: DatePicker,
        disabled,
        className: "fullWidthcustomFields",
    });
    const getFieldFocusValue = (name: string) => {
        let findResult: any = labelsActive.find((label: any) => {
            return label.name === name;
        });
        if (!findResult) {
            return false;
        }

        return findResult.value;
    };
    const CommonField = (
            filedProps,
            action: {
                handleChange?: Function,
                handleBlur: Function,
                values?: any,
                errors?: any,
                touched?: any,
                setFieldValue: any
            },
    ) => {
        return (
                <CommonFieldFunction
                        filedProps={filedProps}
                        action={action}
                        conditionalRequired={false}
                        role={role}
                        setActionItem={setActionItem}
                        client={detail.client}
                        labelsActive={labelsActive}
                        setLabelsActive={setLabelsActive}
                        clientSigned={clientSigned}
                        getFieldFocusValue={getFieldFocusValue}

                />);
    };

    return (
            <React.Fragment>
                <Grid container spacing={3}>
                    {fieldObj.map((field, index) => {
                        if (formValues["actualHealthRating"] !== "Other" && field.name === "actualHealthRatingOther") {
                            return null;
                        }
                        return (
                                <Grid
                                        item xs={12} md={6} lg={6} xl={4} key={index} className="mb15"
                                >
                                    <TextFieldCommon
                                            {...field} values={formValues} onChange={handleChange}
                                    />
                                    <ErrorCommon
                                            errors={errors} name={field.name} touched={touched}
                                    />
                                </Grid>
                        );
                    })
                }
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {
                        <BirthDate
                            fieldObj4={fieldObj4}
                            minAge={minAge}
                            CommonField={CommonField}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                        />
                        }

                            {detail && detail.client && detail.client.tranche && detail.client.tranche.id && issueAge.map((field, index) => {
                                return (
                                        <Grid item xs={12} md={3} lg={2} xl={2}
                                              key={index} className="mb15">
                                            <TextFieldCommon
                                                    {...field}
                                                    values={formValues}
                                                    onChange={handleChange}
                                            />
                                            <ErrorCommon errors={errors} name={field.name}
                                                         touched={touched}/>
                                        </Grid>
                                );
                            })
                            }
                    </Grid>
                </Grid>
                </Grid>
                {
                    policyStatusPopup &&
                    <PolicyStatus
                            policyStatusPopup={policyStatusPopup}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            clientId={clientId}
                            caseDetails={caseDetails}
                            fetchCaseDetail={fetchCaseDetail}
                            ssnFlag={ssnFlag}
                            setPolicyStatusPopup={setPolicyStatusPopup}
                    />
                }
            </React.Fragment>
    );

};
export default CaseDetails;