import React from "react";
import clsx from "clsx";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import {green} from "@material-ui/core/colors";
import {Alert} from "../model";
import {useSelector} from "react-redux";
import {RootState} from "../reducers";
import {useActions} from "../actions";
import * as Actions from "../actions/auth";

const variantIcon = {
    warning: WarningIcon,
    success: CheckCircleIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            success: {
                backgroundColor: green[600],
                color: "#FFFFFF",
            },
            error: {
                backgroundColor: theme.palette.error.dark,
                color: "#FFFFFF",
            },
            warning: {
                backgroundColor: theme.palette.warning.dark,
                color: "#FFFFFF",
            },
            info: {
                backgroundColor: theme.palette.warning.dark,
                color: "#FFFFFF",
            },
            icon: {
                fontSize: 20,
            },
            iconVariant: {
                opacity: 0.9,
                marginRight: theme.spacing(1),
            },
            message: {
                display: "flex",
                alignItems: "center",
                fontSize: 14,
            },

            closeIcon: {
                position: "absolute",
                top: "-12px",
                right: "-18px",
                fontSize: "14px",
                background: "rgba(0, 0, 0, 0.60)",
                width: "20px",
                height: "20px",
                padding:2,
                "&:hover": {
                    background: "rgba(0, 0, 0, 0.80)",
                },
                [theme.breakpoints.down("sm")]: {
                    top: "-6px",
                    right: "-10px",
                    width: 20,
                    height: 20,
                    fontSize: 12,
                },
            },
        }),
);
export default function AlertContainer() {
    const authActions = useActions(Actions);
    const props: Alert = useSelector((state: RootState) => state.alert);
    const { message, severity, statusCode } = props;

    const openState: any = !!message;

    const classes = useStyles();
    const [alert, setAlert] = React.useState<boolean>(openState);

    React.useEffect(() => {
        setAlert(openState);
    }, [openState]);

    let variant: keyof typeof variantIcon = "error";
    if (statusCode === 200) {
        variant = "success";
    }
    if (severity && severity === "info") {
        variant = "info";
    }
    if (severity && severity === "warning") {
        variant = "warning";
    }

    if (!statusCode) {
        return <React.Fragment/>;
    }

    const className = (severity && severity === "info") ?
            classes.info : (
                    severity && severity === "warning" ?
                            classes.warning : (statusCode === 200 ? classes.success : classes.error)
            );

    const Icon = variantIcon[variant];

    function handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
        authActions.closeAlert();
        if (reason === "clickaway") {
            return;
        }
        setAlert(false);
    }

    return (
            <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    key={"top,center"}
                    ContentProps={{
                        "aria-describedby": "message-id",
                    }}
                    open={alert}
                    autoHideDuration={5000}
                    onClose={handleClose}
            >
                <SnackbarContent
                        className={className}
                        message={<span id="message-id" className={classes.message}><Icon
                                className={clsx(classes.icon, classes.iconVariant)}/>{message}</span>}
                        action={[<IconButton className={classes.closeIcon + " forSvgIcon "}
                                             key="close"
                                             aria-label="close"
                                             color="inherit"
                                             onClick={handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>]}
                />
            </Snackbar>
    );
}
