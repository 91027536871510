import * as React from "react";
import { Grid } from "@material-ui/core";
import SectionOne from "./SectionOne";
import LandingFooter from "./LandingFooter";

const LandingBody = (props: any) => {


    return (
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <SectionOne {...props}  />
                </Grid>
                <Grid item xs={12} md={12} className="pdTB15">
                    <LandingFooter {...props} />
                </Grid>
            </Grid>
    );
};

export default LandingBody;
