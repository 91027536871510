import React from 'react';
import {Grid, Tooltip, Typography} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import LaunchIcon from '@material-ui/icons/Launch';
import LoaderGif from '../styles/images/loader_nw.gif';
import {isFunc, sleep} from "../utils/commonUtils";
import {PDFProps, PDFViewer} from './Tools';

interface ViewerProps {
    className?: string;
    id?: string;
    force?: Function;
    loading?: boolean;
    loadingMessage?: string;
    setLoading?: Function;
    title?: string;
    url: string;
}

interface ViewerLoaderProps {
    loading?: boolean;
    message?: string;
    reloadFrame?: Function;
    url?: string;
}

interface ViewerReloadProps {
    reloadFrame: Function;
    showOpenAsNewTabButton?: boolean;
    url?: string;
}

/**
 * Fail-safe show reload icon in milliseconds
 */
const IFRAME_LOADER_FAIL_SAFE_MS: number = 500;


const ViewerReload = ({reloadFrame, showOpenAsNewTabButton, url}: ViewerReloadProps) => {
    return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                    It may take some time to load the file.
                    If the file is not loading please click below to reload or view file in new tab.
                    <div className="iconPosition">
                        <Tooltip title="Reload">
                            <RefreshIcon onClick={() => reloadFrame()}/>
                        </Tooltip>
                        {
                                showOpenAsNewTabButton && !!url &&
                                <Tooltip title="Open in a new tab">
                                    <a className="ml5" href={url} target="_blank">
                                        <LaunchIcon/>
                                    </a>
                                </Tooltip>
                        }
                    </div>
                </Grid>
            </Grid>
    );
};

const ViewerLoader = ({message}: ViewerLoaderProps) => {
    return (
            <div className="mt30 textCenter">
                <img alt="Loading..." className="mb15" src={LoaderGif}/>
                {
                        !!message &&
                        <br/>
                }
                {
                        !!message &&
                        <Typography variant="caption" className="w100">
                            {message}
                        </Typography>
                }
            </div>
    )
};

const Viewer = (props: ViewerProps) => {
    const {
        className,
        force,
        id,
        loading,
        loadingMessage,
        setLoading,
        url,
    } = props;

    const [error, setError] = React.useState(false);
    const [src, setSrc] = React.useState('');


    /**
     * check and set force value, if exist
     * @param forceVariable
     */
    const checkForce = (forceVariable: number) => {
        if (force && isFunc(force)) {
            force(forceVariable);
        }
    };

    /**
     * set loading complete
     */
    const checkLoadingAndSetFalse = () => {
        if (setLoading && isFunc(setLoading)) {
            setLoading(false);
        }
    };

    /**
     * load complete event
     */
    const handleLoad = () => {
        checkLoadingAndSetFalse();
    };

    /**
     * error capture event
     */
    const handleError = () => {
        checkLoadingAndSetFalse();
        setError(true);
    };

    /**
     * Function that tries to reload iframe
     */
    const reloadFrame = async () => {
        // set iframe src as blank
        setSrc('');

        // only works if set-loading is defined
        if (setLoading && isFunc(setLoading)) {
            // show loading
            setLoading(true);
            // updates refresh value if exists to initial
            checkForce(0);
            // wait to induce server latency
            await sleep(IFRAME_LOADER_FAIL_SAFE_MS);
            // updates refresh value if exists to new value
            checkForce(Math.random());
            // update src
            setSrc(url);
        }
    };

    const viewerProps: PDFProps = {
        className,
        id,
        loading: (<ViewerLoader message={''}/>),
        onError: handleError,
        onLoad: handleLoad,
        url: src,
    };

    const eff = () => {
        if (!!url) {
            setSrc(url);
        }
    };

    React.useEffect(eff, [url]);


    return (
            <>
                {
                        loading &&
                        <ViewerLoader message={loadingMessage}/>
                }

                {
                        error &&
                        <ViewerReload reloadFrame={reloadFrame}/>
                }

                {
                        !!url &&
                        <PDFViewer {...viewerProps} />
                }
            </>
    );
};

export default Viewer;
