import * as React from "react";
import { useEffect } from "react";
import {
    Grid,
    Typography,
} from "@material-ui/core";
import numeral from "numeral";
import Assumptions from "../../pages/client/Invitation/v2/estimateReturns/Assumptions" ;
import EstimationTabContentTwo from "../../pages/client/kaizen/EstimationTabContentTwo";
import { getQueryStringValue } from "../../utils/queryString";

const LeadContent = (props: any) => {
    const { estimatedDetail, totalContributionValue } = props;
    const [estimatedData, setEstimatedData] = React.useState(estimatedDetail);
    const [showStickyBar, setShowStickyBar] = React.useState(false);
    const age = getQueryStringValue("age");
    const strategyName: string = props.strategyData.name;
    const strategySlug: string = props.strategyData.slug || "kaizen";
    const registrationMark: boolean | undefined = props.strategyData.registrationMark;
    let totalContribution: number = numeral(totalContributionValue || 0).value();

    useEffect(() => {
        setEstimatedData(estimatedDetail);
    }, [estimatedDetail ? estimatedDetail.kaizen_initial_death_benefit : 0, totalContribution]);

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll, true);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        });
    }, []);

    const handleScroll = (e: any) => {
        if (!showStickyBar && window.scrollY > 100) {
            setShowStickyBar(true);
        }
    };

    return (
            <>
                <Grid id={"step3_section1"} container spacing={2} justify="center">
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={8}>
                            <EstimationTabContentTwo
                                    estimationData={estimatedData}
                                    totalContribution={totalContribution}
                                    strategyName={strategyName}
                                    age={age}
                                    strategySlug={strategySlug}
                                    isLead={true}
                            />

                            <Typography className="mt20 floatLeft w100 txtGray f12">
                                <Assumptions estimationData={estimatedData} strategySlug={strategySlug} registrationMark={registrationMark}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
    );
};

export default LeadContent;
