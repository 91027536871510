import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useStyles } from "../pages/agent/dashboard/TableQuickView";
import { NiwSelect } from "../pages/imo/ImoTab";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutlined";
import { can } from "./navigation/AdminNavigation";
import { PermissionTypes } from "../constants";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

export type CarrierStrategyRow = {
    id?: number,
    carrier: any,
    imoStrategies: {
        strategies: { name: string }
    }[]
    offerTypes: { name: string }[],
}

type ImosTableProps = {
    rows: CarrierStrategyRow[];
    onSelect: (selectedOption: string, imoCarrier: any) => void;
    disable?: boolean
}


export const CarrierStrategiesTable = ({ rows, onSelect, disable = false }: ImosTableProps) => {
    const classes = useStyles();

    const options = [
		{
			id: 1,
			name: "View / Edit",
			icon: () => (
				<VisibilityOutlinedIcon className="textHighLight floatLeft mr10" />
			),
		},
		{
			id: 2,
			name: "Delete",
			icon: () => (
				<DeleteOutlineIcon className="textHighLight floatLeft mr10" />
			),
		},
	];

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="caption table">
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell>Carriers</TableCell>
                        <TableCell>Ilia Product Type</TableCell>
                        <TableCell>Offer Types</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <CarrierStrategyRow  key={row.carrier.name} row={row} options={options} onSelect={onSelect} disable={disable}/>
                    ))}                  
                </TableBody>                   
            </Table>
        </TableContainer>
    )
}

const CarrierStrategyRow = ({ row, options, onSelect, disable = false }) => {
    const [selectedOption, setSelectedOption] = React.useState<any>("");
    const auth = useSelector((state: RootState) => state.auth);

	const handleSelect = (e, imoCarrier) => {
		const selectedOpt = e.target.value;
		setSelectedOption(selectedOpt);
		onSelect(selectedOpt, imoCarrier);
	};

    return (
        <TableRow>
            <TableCell>
                {row.carrier.name}
            </TableCell>
            <TableCell>{row.imoStrategies.map(imoStrategy => imoStrategy.strategies.name).join(", ")}</TableCell>
            <TableCell>
                {row.offerTypes && row.offerTypes.length > 0 && row.offerTypes.map(offerType => offerType.name).join(", ")}
            </TableCell>
            <TableCell>
                {
                    auth.user && (can(auth.user, PermissionTypes.ACCOUNT_MANAGEMENT) && (auth.user.role === 1 || auth.user.role === 2)) &&
                        <NiwSelect
                            onChange={(e) => handleSelect(e, row)}
                            options={options}
                            selectedOption={selectedOption}
                            disable={disable}
                        />
                }
            </TableCell>
        </TableRow>
    )
}