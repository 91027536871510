import React, {useState, useEffect} from 'react';
import LoaderGif from "../styles/images/mainloader_ilia_new.gif";
import {Redirect, RouteComponentProps} from "react-router-dom";
import {useActions} from "../actions";
import * as ClientActions from "../actions/client";

const myStyle: any = {
    position: "fixed" as "fixed",
    zIndex: "9999999",
    width: "100%",
    height: "100%",
    background: "#ffffff",
};
const imgStyle = {
    position: "absolute" as "absolute",
    left: "0",
    right: "0",
    margin: '0 auto',
    top: '30%'
};

interface ParamsProps {
    match: {
        params: {
            token: string
        }
    }
}

interface Props extends RouteComponentProps<void> {
}

export default function VerifyGroupAccount(props: Props & ParamsProps) {
    const clientActions = useActions(ClientActions);
    const [isLoading, setIsLoading] = useState(true);

    const {token} = props.match.params;

    const verifyToken = async (token) => {
        const res = clientActions.verifyGroupAccount({inviteToken: token})
        if (res && res.statusCode == 200) {
            setIsLoading(false);
        } else {
            setTimeout(() => {
                setIsLoading(false)
            }, 1000)
        }
    }

    useEffect(() => {
        console.log("token", token)
        verifyToken(token).then(() => {
        });
    }, []);

    if (!isLoading) {
        return <Redirect to='/login'/>;
    }
    return (<>
        {isLoading &&
                <div style={myStyle}>
                    <img style={imgStyle} src={LoaderGif} alt='Loading...'/>
                </div>
        }
    </>)
}