import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import {ErrorHelper, FormikValues} from "../formikFormInputs";
import {CommonProps, SPECIAL_NULL} from "./index";
import {ParsedListItem} from "../../model/application";
import {isFunc, va} from "../../utils/commonUtils";


interface TBProps extends CommonProps, FormikValues {
    defaultValue?: string;
    isSpecialCase?: boolean;
    listItems: ParsedListItem[];
    onClearSearch?: Function;
    onToggle?: Function;
    size?: 'small' | 'medium' | 'large';
}


const Toggle: React.FC<TBProps> = (props) => {
    const {
        alias,
        defaultValue,
        errors,
        helperText,
        label,
        listItems,
        name,
        onClearSearch,
        onToggle,
        setFieldValue,
        size,
        values
    } = props;
    const defaultAnsValue = (!!defaultValue && defaultValue !== SPECIAL_NULL) && ['true', 'false'].includes(defaultValue) ? defaultValue : (!!va(defaultValue) ? 'true' : '');
    const val = values && name && values[name] || "";
    const [selectedToggleButton, setSelectedToggleButton] = React.useState<string>(defaultAnsValue || val);

    const getClass = (item: ParsedListItem) => {
        return selectedToggleButton === item.value ? "active" : (item.className || "")
    }
    const handleToggleChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
        setSelectedToggleButton(value);

        if (setFieldValue && isFunc(setFieldValue)) {
            setFieldValue(name, value);
            if (value === null) {
                setFieldValue(name, "");
            }
            if (value !== true && onClearSearch && isFunc(onClearSearch)) {
                onClearSearch();
            }
        }

        if (onToggle && isFunc(onToggle)) {
            onToggle(value);
        }
    };

    return (
            <>
                <Grid item xs={12} md={8}>
                    <Typography className="mb0 floatLeft" variant="body1">
                        {!!label && label}{alias && <> (<strong>{alias}</strong>) </>}
                    </Typography>
                    {
                            helperText &&
                            <Typography className="floatLeft w100 f16 textBlackLight" variant="caption">
                                {helperText}
                            </Typography>
                    }
                    {
                            name && errors &&
                            <ErrorHelper errors={errors} name={name as string}/>
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <ToggleButtonGroup
                            className="floatRight toggleButtonGroupQust"
                            color="primary"
                            defaultValue={selectedToggleButton}
                            exclusive
                            id={name}
                            onChange={handleToggleChange}
                            size={size || "medium"}
                            value={selectedToggleButton || val}
                    >
                        {
                                !!va(listItems) && listItems.map((item, index) => (
                                        <ToggleButton
                                                className={getClass(item)}
                                                // disabled={selectedToggleButton === item.value}
                                                key={index}
                                                value={item.value}
                                        >
                                            {item.text}
                                        </ToggleButton>
                                ))
                        }
                    </ToggleButtonGroup>
                </Grid>
            </>
    )
};


export default Toggle;
