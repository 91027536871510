import {ActionType, CleanResponse} from "../model";
import {EAType, PhyType} from "../model/application";
import {setAlert, setErrorAlert} from "./alert";
import {va} from "../utils/commonUtils";

type AFType = () => (dispatch: Function, getState: Function, api: any) => Promise<any>;
type GAType = (payload: { enrollmentId: string }) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type SAType = (payload: EAType) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type GNQType = (payload: { enrollmentId: string }) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type GQType = (payload: {
    enrollmentId: string;
    questionId: string
}) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type SyncAnswersType = (payload: {
    baseQuestionCode: string;
    enrollmentId: string;
}) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type SubmitAnswerType = (payload: {
    answerValue: string;
    enrollmentId: string;
    questionId: string
}) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type SubAType = (payload: { enrollmentId: string }) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type FetchPhyType = (query: {
    enrollmentId: string
}) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type SubmitPhysicianType = (payload: PhyType) => (dispatch: Function, _store: Function, api: any) => Promise<any>;
type RPType = (payload: {
    desc: string;
    userId: string;
}) => (dispatch: Function, getState: Function, api: any) => Promise<any>;
type ResetPaymentType = (payload: {
    policyNumber: string;
    userId: string;
}) => (dispatch: Function, getState: Function, api: any) => Promise<any>;


const EAApiPath = 'enrollment/application';


/**
 * Get Authorization Form
 */
export const getAndInitiateDocusign: AFType = (enrollmentId?: string, reqType?: string) => async (dispatch, getState, api) => {
    try {
        const res = await api.get(`${EAApiPath}/auth-form`, {}, {enrollmentId, reqType});
        dispatch({type: ActionType.APPLICATION_GET_FORM, payload: res.data});
        return res.data.body;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        return false;
    }
};

/**
 * Get Application, all questions and answers
 * @param query
 */
export const getApplication: GAType = (query) => async (dispatch, _store, api) => {
    try {
        const res = await api.get(EAApiPath, {}, query);
        dispatch({payload: res.data, type: ActionType.APPLICATION_ENROLLMENT_GET_ALL});
        return res.data;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Start Application
 * @param payload
 */
export const startApplication: SAType = (payload) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.post(`${EAApiPath}/start`, payload);
        dispatch({payload: res.data.body, type: ActionType.APPLICATION_ENROLLMENT_START});
        let returnVal = false;
        if (res.data.body) {
            returnVal = !!res.data.body.applicationId;
            if (!!res.data.body.applicationId) {
                dispatch(setAlert(res.message, 'success'));
            } else {
                dispatch(setErrorAlert("Error starting application, Please contact site administrator ", 400));
            }
        }
        return returnVal;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Get Next Question
 * @param payload
 */
export const getNextQuestion: GNQType = (payload) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.post(`${EAApiPath}/get-next-question`, payload);
        dispatch({payload: res.data.body, type: ActionType.APPLICATION_ENROLLMENT_GET_NEXT_QUESTION});
        return res.data.body;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Get Question details
 * @param payload
 */
export const getQuestion: GQType = (payload) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.post(EAApiPath, payload);
        dispatch({payload: res.data.body, type: ActionType.APPLICATION_ENROLLMENT_GET_QUESTION_BY_ID});
        return res.data.body;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Search Disclosures
 * @param payload
 */
export const fetchSearchOptions = (payload) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.post(`${EAApiPath}/search`, payload);
        dispatch({payload: res.data.body, type: ActionType.APPLICATION_ENROLLMENT_SEARCH_OPTIONS});
        if (res.data.body.applicationId && res.data.body.results && !va(res.data.body.results)) {
            console.warn('No results, Please contact site administrator');
            // dispatch(setErrorAlert('No results, Please contact site administrator', 400));
        }
        return res.data.body.results || [];
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Save answer
 * @param payload
 */
export const submitAnswer: SubmitAnswerType = (payload) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.put(EAApiPath, payload);
        dispatch({payload: res.data.body, type: ActionType.APPLICATION_ENROLLMENT_SAVE_ANSWER});
        if (res.data.body.applicationId && res.data.body.updatedQuestion) {
            dispatch(setAlert(res.message, 'success'));
            return true;
        } else {
            console.info(res.data);
            dispatch(setErrorAlert('Error saving answer, Please contact site administrator', 400));
            return false;
        }
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Save Physicians
 * @param payload
 */
export const submitPhysician: SubmitPhysicianType = (payload) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.post(`${EAApiPath}/physician`, payload);
        dispatch({payload: res.data.body, type: ActionType.APPLICATION_ENROLLMENT_SAVE_PHYSICIAN});
        if (res.data.body.applicationId) {
            dispatch(setAlert(res.message, 'success'));
            return true;
        } else {
            console.info(res.data);
            dispatch(setErrorAlert('Error saving physician, Please contact site administrator', 400));
            return false;
        }
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Fetch Physicians
 */
export const fetchPhysicians: FetchPhyType = (query) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.get(`${EAApiPath}/physician`, {}, query);
        dispatch({payload: res.data.body, type: ActionType.APPLICATION_ENROLLMENT_FETCH_PHYSICIAN});
        return res.data.body.physicians || [];
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Submit Application (Final)
 * @param payload
 */
export const submitApplication: SubAType = (payload) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.post(`${EAApiPath}/submit`, payload);
        dispatch({payload: res.data, type: ActionType.APPLICATION_ENROLLMENT_SUBMIT});
        dispatch(setAlert(res.message, 'success'));
        return;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**Get Hipaa ID */
export const getHipaaId: AFType = () => async (dispatch, getState, api) => {
    try {
        const res = await api.get(`${EAApiPath}/get-hipaa-id`);
        dispatch({type: ActionType.GET_HIPAA_ID, payload: res.data});
        return res.data;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        return false;
    }
};

/**
 * Accept hippa agreement
 */
export const hipaaAgreement: AFType = () => async (dispatch, getState, api) => {
    try {
        const res = await api.post(`${EAApiPath}/hipaa-agreement`);
        dispatch({type: ActionType.HIPAA_AGREEMENT, payload: res.data});
        return res.data;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        return false;
    }
};

/**
 * sync answers
 * @param payload
 */
export const syncAnswers: SyncAnswersType = (payload) => async (dispatch, _store, api) => {
    try {
        const res: CleanResponse = await api.put(`${EAApiPath}/sync`, payload);
        dispatch({payload: res.data.body, type: ActionType.APPLICATION_ENROLLMENT_SYNC});
        console.info(res.data.body)
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Reset Application Proposal
 * @param payload
 */
export const resetProposal: RPType = (payload) => async (dispatch, getState, api) => {
    try {
        const res: CleanResponse = await api.post(`${EAApiPath}/resetProposal`, payload);
        dispatch({payload: res.data, type: ActionType.APPLICATION_PROPOSAL_RESET});
        dispatch(setAlert(res.message, 'success'));
        return;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Reset Payment
 * @param payload
 */
export const resetPayment: ResetPaymentType = (payload) => async (dispatch, getState, api) => {
    try {
        const res: CleanResponse = await api.post(`payment/resetPayment`, payload);
        dispatch({payload: res.data, type: ActionType.RESET_PAYMENT});
        dispatch(setAlert('Payment Reset.', 'success'));
        return;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};

/**
 * Reset Enrollment Application
 * @param payload
 */
export const resetEnrollmentApplication: RPType = (payload) => async (dispatch, getState, api) => {
    try {
        const res: CleanResponse = await api.post(`${EAApiPath}/resetEnrollment`, payload, {});
        dispatch({payload: res.data, type: ActionType.APPLICATION_ENROLLMENT_RESET});
        dispatch(setAlert(res.message, 'success'));
        return;
    } catch (err) {
        return dispatch(setErrorAlert(err.message, err.statusCode));
    }
};