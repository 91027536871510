import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
    Dialog,
    IconButton,
    Typography,
} from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { ErrorCommon, TextField, TextFieldCommon } from "../formikFormInputs";
import { Button, Label } from "../formInputs";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            Paper: {
                minWidth: 767,
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: 18,
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function DisableParticipantNoteModal(props: any) {
    const intitialValues = {
        note: "",
    };

    const { onSave, onClose, isDisableParticipant } = props;
    const handleClose = () => {
        onClose();
    };

    const validate = ((value: any) => {
        const errors: {
            note?: string
        } = {};
        if (!value.note) {
            errors.note = "Required";
        }
        return errors;
    });
    const fieldObj = [
        {
            name: "note",
            label: <Label label={"Note"} required={true}/>,
            placeholder:"Enter Note",
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            multiline: true,
            rows: 5,
        },
    ];
    const submitFormik = async (values: any, setSubmitting: Function, resetForm: Function) => {
        onSave(values);
    };

    return (
            <div>
                <Dialog fullWidth={true} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={true}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {isDisableParticipant ? "Disable" : "Delete"} Participant
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant={"caption"}>
                            Please leave a short note why you are {isDisableParticipant ? "disabling" : "deleting"} this
                            participant.
                        </Typography>
                        <Formik
                                initialValues={intitialValues}
                                validate={values => validate(values)}
                                onSubmit={(values, {
                                    setSubmitting,
                                    setErrors,
                                    setStatus,
                                    resetForm,
                                }) => submitFormik(values, setSubmitting, resetForm)}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty,
                                  setFieldValue,
                              }) =>
                                    (
                                            <form onSubmit={handleSubmit}>
                                                {
                                                    fieldObj.map((field, index) => {
                                                        return (
                                                                <div className="mt10" key={index}>
                                                                    <TextFieldCommon
                                                                            {...field}
                                                                            label={"Note"}
                                                                            values={values}
                                                                            onChange={handleChange}
                                                                    />
                                                                    <ErrorCommon errors={errors} name={field.name}
                                                                                 touched={touched}/>
                                                                </div>
                                                        );
                                                    })
                                                }

                                                <Button
                                                        size="medium"
                                                        type="submit"
                                                        label={isDisableParticipant ? "Disable" : "Delete"}
                                                        variant="contained"
                                                        color="primary"
                                                        className="floatRight mt20 mb20 btnContainedRed"
                                                        disabled={!dirty || isSubmitting}
                                                        loading={isSubmitting}
                                                />
                                            </form>
                                    )
                            }
                        </Formik>
                    </DialogContent>
                </Dialog>
            </div>
    );
}
