import React from "react";
import { Card, CardContent, Grid, Link, Typography } from "@material-ui/core";
import { DashboardStyle as Styles } from "../../pages/agent/dashboard/dashboardStyle";
import clientManagement from "../../pages/agent/dashboard/images/ico-client-management.svg";
import clientActionItems from "../../pages/agent/dashboard/images/ico-action-items.svg";
import clientEvents from "../../pages/agent/dashboard/images/ico-events.svg";
import * as AccountActions from "../../actions/account";
import { history } from "../../configureStore";
import { useActions } from "../../actions";

interface PortalPagesProps {
    mainTitle: string;
    content: string;
    image: string;
    redirectTo: string;
}

const PortalPagesData: Array<PortalPagesProps> = [
    {
        mainTitle: "Participant Management",
        content: "Your group member list for viewing and managing your participants.",
        image: clientManagement,
        redirectTo: "/group-participants",
    },
    {
        mainTitle: "Action Items",
        content: "List of your action items to complete on behalf of your  group participants.",
        image: clientActionItems,
        redirectTo: "/group-action-items",
    },
    {
        mainTitle: "Documents",
        content: "View and download important group documents.",
        image: clientEvents,
        redirectTo: "/group-documents",
    },
];

export default function PortalPages(props: any) {
    const classes = Styles();
    const accountActions = useActions(AccountActions);
    const redirectTo = async (url: string) => {
        history.push(url);
        await accountActions.refreshApplicationState();
    };

    return (
            <>
                {PortalPagesData.map((o, idx) => (
                        <Grid item xs={12} md={6} lg={6} xl={3}>
                            <Link onClick={() => redirectTo(o.redirectTo)} className={classes.textLinkNone}>
                                <Card className={classes.radius8 + " " + classes.textCenter + " " + classes.cardShadow + " " + classes.minHeight}>
                                    <div>
                                        <img src={o.image} alt="#" className="mt30"/>
                                    </div>
                                    <CardContent>
                                        <Typography variant="subtitle1" className={classes.mb0}>
                                            <strong>{o.mainTitle}</strong>
                                        </Typography>
                                        <Typography variant="caption" className={classes.textLight}>
                                            {o.content}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                ))
                }
            </>
    );
}

