import { Action, ActionType } from "../model";
import { Tranche, TrancheList } from "../model/tranche";
import createReducer from "./createReducer";

export const tranche = createReducer({}, {
	[ActionType.ADD_TRANCHE](state: Tranche, action: Action<Tranche>) {
		return action.payload;
	}
});

export const tranches = createReducer({}, {
	[ActionType.LIST_TRANCHES](state: TrancheList, action: Action<TrancheList>) {
		return action.payload;
	}
});


