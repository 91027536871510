import React from "react";
import { Button as MuButton, ButtonProps as BP, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

export interface ButtonProps extends BP {
    label: any;
    loading?: boolean;
    loaderSize?: number;
    children?: React.ReactNode | any;
}

const useStyles = makeStyles(() =>
        createStyles({
            wrapper: {
                position: "relative",
            },
            buttonProgress: {
                color: blue[500],
                position: "absolute",
                top: "10px",
                left: "50%",
                marginTop: "0px",
                width: "100%",
            },
        }),
);

export default function Button({ children, label, loading, loaderSize, ...custom }: ButtonProps) {
    const classes = useStyles();
    const size = loaderSize || 24;
    return (
            <div className={classes.wrapper}>
                {
                        children && children
                }
                <MuButton {...custom}>
                    {label}
                    {loading && <CircularProgress size={size} className={classes.buttonProgress}/>}
                </MuButton>
            </div>
    );
};
