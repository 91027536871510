import React from 'react';
import GroupAuth from '../components/GroupAuth';
import { useActions } from "../actions";
import * as AccountActions from "../actions/account";
import { RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps<void> {}

export default function GroupLinkPage(props: Props) {
	const params: any = props.match.params;
	const [groupDetail, setGroupDetail] = React.useState(undefined);
	const accountActions = useActions(AccountActions);
	const getGroup = async () => {
        const resp = await accountActions.getGroup(params.id);
		setGroupDetail(resp.data);
	}
	React.useEffect( () => {
		getGroup();
	}, []);

	return (
		<GroupAuth>
			{{
				groupDetail,
				history: props.history
			}}
		</GroupAuth>
	);
}

