import React from 'react';
import { Button, Grid } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";


function NewCustomDesignButton(props:{clearEstimationData:Function}) {
    const { clearEstimationData } = props;
    return <Grid item xs={12} md={6}>
        <Button
            color="primary"
            size="small"
            variant="outlined"
            startIcon={<CachedIcon/>}
            onClick={() => {
                if (typeof clearEstimationData === "function") {
                    clearEstimationData();
                }
            }}
        >
            New Custom Design
        </Button>
    </Grid>

}

export default NewCustomDesignButton;