import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useActions } from "../../../../actions";
import * as TrancheActions from "../../../../actions/tranche";
import { Button as FormikButton, Label } from "../../../../components/formInputs";
import { AutoComplete, ErrorCommon, TextFieldCommon } from "../../../../components/formikFormInputs";
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import moment from "moment";

const styles: any = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "10px",
        top: "16px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle: any = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="body1" component="strong">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

type PropsType = {
    trancheSelectBox: boolean,
    setTrancheSelectBox: Function,
    clientDetail: any,
    refreshDetails: Function | undefined
}
let trancheDropDownArr: any[] = [{
    title: "",
    key: "",
    name: "",
    value: "",
}];
export default function TrancheNumberPopUp(props: PropsType) {
    const { trancheSelectBox, setTrancheSelectBox, clientDetail, refreshDetails } = props;
    const trancheActions = useActions(TrancheActions);
    const [trancheList, setTrancheList] = React.useState(trancheDropDownArr);
    const [trancheCompleteDetail, setTrancheCompleteDetail] = React.useState([]);
    const [selectedTranche, setSelectedTranche] = React.useState<any>("");
    const handleClose = () => {
        setTrancheSelectBox(false);
    };
    const getTranche = async () => {
        //fetch tranche whose deadline for Application is greater than current date
        const result = await trancheActions.getTrancheListForClient({ clientId: clientDetail && clientDetail.client && clientDetail.client.id });
        if (result && result.rows && result.rows.length > 0) {
            const resultArr: any[] = [];
            const arr = result.rows;
            setTrancheCompleteDetail(arr);
            arr.forEach(value => {
                resultArr.push({
                    title: value.trancheNumber + "",
                    key: value.trancheNumber + "",
                    name: value.trancheNumber + "",
                    value: value.trancheNumber + "",
                });
            });
            setTrancheList(resultArr);
        }
    };
    React.useEffect(() => {
        if (trancheSelectBox) {
            getTranche().then();
        }
        return () => {
            setTrancheList(trancheDropDownArr);
            setSelectedTranche("");
            setTrancheCompleteDetail([]);
        };
    }, [trancheSelectBox]);

    const fieldObj = [{
        id: "trancheNumber",
        name: "trancheNumber",
        label: <Label label={"Tranche Number"} required={true}/>,
        isRequired: true,
        placeholder: "search tranche number",
        cb: (value) => {
        },
        component: AutoComplete,
        className: "SelectFieldWidth",
        variant: "outlined",
        contentList: trancheList,
        onSelectChange: (val: string) => {
            const tranche: any = trancheCompleteDetail.find((item: any) => item.trancheNumber === Number(val));
            if (tranche && tranche.id) {
                setSelectedTranche(tranche);
            }
        },
    }];
    let formVal: any = {
        trancheNumber: "",
    };
    const validate = ((value: { trancheNumber: string }) => {
        const errors: { trancheNumber?: string } = {};
        if (!value.trancheNumber) {
            errors.trancheNumber = "Required";
        }
        return errors;
    });

    return (
            <div>
                <Dialog fullWidth={true} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title"
                        open={trancheSelectBox}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Select Tranche Number
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1">
                            Select Tranche Number For
                            Client: {clientDetail && clientDetail.firstName + " " + clientDetail.lastName}
                        </Typography>
                        <Formik
                                initialValues={formVal}
                                enableReinitialize={true}
                                validate={values => validate(values)}
                                validateOnChange={true}
                                onSubmit={async (values: any, { setSubmitting, setValues }) => {
                                    await trancheActions.saveTrancheNumberForClient({
                                        trancheNumber: values.trancheNumber,
                                        clientId: clientDetail && clientDetail.client && clientDetail.client.id,
                                    });
                                    if (typeof refreshDetails === "function") {
                                        refreshDetails();
                                    }
                                    setTimeout(() => handleClose(), 500);
                                }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  setFieldValue,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty,
                              }) => {
                                return (
                                        <form className="floatLeft w100" onSubmit={handleSubmit}>
                                            <Grid container spacing={2} className="mb20">
                                                {fieldObj.map((field, index) => {
                                                    return (
                                                            <Grid item xs={12} sm={12} md={12} key={index}>
                                                                <TextFieldCommon
                                                                        {...field}
                                                                        values={values}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                />
                                                                <ErrorCommon errors={errors} name={field.name}
                                                                             touched={touched}/>
                                                            </Grid>
                                                    );
                                                })}
                                            </Grid>
                                            {
                                                selectedTranche && selectedTranche.id &&
                                                <Grid container spacing={2} className="mt15 mb20">
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography component={"strong"} variant="body1"
                                                                    className="mb20">Selected
                                                            Tranche
                                                            Number have these details</Typography>

                                                        <Typography className="mb0" variant="body1">Deadline for
                                                            Application: <span
                                                                    className="textBlackLight">{selectedTranche.deadlineDate ?
                                                                    moment(selectedTranche.deadlineDate).format("MM/DD/YYYY") : "NA"}</span></Typography>

                                                        <Typography className="mb0" variant="body1">Deadline for
                                                            Funds: <span
                                                                    className="textBlackLight">{selectedTranche.fundsDeadLineDate ?
                                                                    moment(selectedTranche.fundsDeadLineDate).format("MM/DD/YYYY") : "NA"}</span></Typography>

                                                        <Typography className="mb0" variant="body1">Projected Funding
                                                            Date:<span
                                                                    className="textBlackLight"> {selectedTranche.projectFundingDate ?
                                                                    moment(selectedTranche.projectFundingDate).format("MM/DD/YYYY") : "NA"}</span></Typography>

                                                        <Typography className="mb0" variant="body1">Master Tranche
                                                            Date: <span
                                                                    className="textBlackLight">{selectedTranche.masterTrancheDate ?
                                                                    moment(selectedTranche.masterTrancheDate).format("MM/DD/YYYY") : "NA"}</span></Typography>
                                                        <Typography className="mb0" variant="body1">Issue
                                                        Date: <span
                                                            className="textBlackLight">{selectedTranche.issueDate ?
                                                            moment(selectedTranche.issueDate).format("MM/DD/YYYY") : "NA"}</span></Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <FormikButton
                                                    fullWidth={false}
                                                    type="submit"
                                                    label="Save Tranche Number"
                                                    variant="contained"
                                                    className="floatRight"
                                                    color="primary"
                                                    disabled={(Object.keys(errors).length !== 0) ||
                                                    values.trancheNumber === "" || isSubmitting}
                                                    loading={isSubmitting}
                                            />
                                        </form>);
                            }}
                        </Formik>

                    </DialogContent>
                </Dialog>
            </div>
    );
}
