import * as React from "react";
import { Card, CardContent, Grid, Typography, Container } from "@material-ui/core";
import ReactPlayer from "react-player";
import { ATLANTIC_HEALTH, kaiZenPhysician, kaiZenPhysicianRebrand, kaiZenRebrand } from "../../../../constants";
import EstmateSectionOne from "./estimateReturns/EstmateSectionOne";
import EstmateSectionTwo from "./estimateReturns/EstmateSectionTwo";
import EstmateSectionTwoAuthOnly from "./trizen/AuthenticationForm";
import UnAuthenticationForm from "./trizen/UnAuthenticationForm";
import { GroupRebrand } from "./groupRebrand";
import EstmateSectionThree from "./estimateReturns/EstmateSectionThree";
import ico from "../../../../styles/images/light-bulb.svg";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { PlayCircleFilled } from "@material-ui/icons";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../components/dialog";

const useStyles = makeStyles((theme) =>
        createStyles({
            getStarted: {
                textAlign: "left",
                position: "relative",
                overflow: "visible",
                maxWidth: "100%",
                marginLeft: "40px",
                float: "left",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "0",
                },
                [theme.breakpoints.down("sm")]: {
                    maxWidth: "100%",
                    width: "100%",
                },
            },
        }),
);

const HeroContent = () => {
    return <>
        <div className="heroContent">
            <Grid container justify="center">
                <Grid item xs={12} md={8}>
                    <Typography variant="subtitle1" className="mt60 mb60" align="center"        >
                        The success of your retirement depends on the amount you save, not on your rate of
                        return. A unique cash accumulating life insurance policy using leverage offers an
                        opportunity to earn interest and eliminate the risk of market declines, while providing
                        you and your family protection. The policy secures the loan, providing you the <strong>potential
                        for an additional 60-100% more for your retirement without the typical risks associated
                        with leverage.</strong>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    </>;
};


const DidYouKnow = (props:any) => {
    let  companyId: string = props.groupDetail ? props.groupDetail.companyLinkUrl : "0";
    const groupCondition = (props: {companyId:string }) => {
        let result: any;
        const { companyId, } = props;
        if(ATLANTIC_HEALTH.includes(companyId)){
            result = {
                body: "Boosting Your Savings Potential: Atlantic Health Matches Your Contribution by 50% Up to $15,000! It's Free Money in Your Pocket, Plus, Lenders Can Triple the Combined Amount -An Incredible Benefit!",
            }
        } else {
            result = {
                body: "The success of your retirement depends on the amount you save, not on your rate of return. A unique cash accumulating life insurance policy using leverage offers an opportunity to earn interest and eliminate the risk of market declines, while providing you and your family protection. The policy secures the loan, providing you the potential for an additional 60-100% more for your retirement without the typical risks associated with leverage.",
            }
        }
        return result;
    };

    let group = groupCondition({ companyId });

    return <div className="colorBg"><Container maxWidth="lg" className="sectionPDMini">
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4} lg={2} className="textCenter textLeftSm">
                <img alt="Light Bulb" src={ico} width={144} height={144}/>
            </Grid>
            <Grid item xs={12} md={8} lg={10}>
                <Typography variant="h3" color="primary">Did You Know</Typography>
                <Typography variant="body1" color="primary">{group.body}</Typography>
        </Grid>
        </Grid>
    </Container>
    </div>;
};
const LandingSectionOne = (props: any) => {
    const { showGrReLanPage } = props;
    const classes = useStyles();
    const [showStickyBtn, setShowStickyBtn] = React.useState(false);

    const [estimationData, setEstimationData] = React.useState(null);
    const [totalContribution, setTotalContribution] = React.useState(0);


    let bgImage = "hero-bg-image.jpg";
    if (props.strategyData.id === kaiZenPhysicianRebrand || props.strategyData.id === kaiZenPhysician) {
        bgImage = "lsp-p.jpg";
    }

    let strategyVideoUrl = "https://player.vimeo.com/video/680515029";
    // case of AON carrier and LSP strategy, strategy is rebranded and slug is kaizen
    if (props.strategyData && props.strategyData.slug === "kaizen") {
        if (props.strategyData.id === kaiZenPhysician) {
            strategyVideoUrl = "https://player.vimeo.com/video/470331789";
        }
        if (props.strategyData.id === kaiZenPhysicianRebrand) { // kai-zen phy aon case
            strategyVideoUrl = "https://player.vimeo.com/video/731861798";
        }
        if (props.strategyData.id === kaiZenRebrand) { // kai-zen aon case
            strategyVideoUrl = "https://player.vimeo.com/video/731874233";
        }
    }

    if (props.groupDetail) {
        if (props.groupDetail.imo && props.groupDetail.imo.reBrand && !props.groupDetail.landingPageVideo) {
            // case of group having rebrand landing page
            strategyVideoUrl = "https://player.vimeo.com/video/796317714?h=3e9c311b6b";
        }
         else if (props.groupDetail.landingPageVideo) { // case of group having landing page video
            strategyVideoUrl = props.groupDetail.landingPageVideo;
        }
    }
    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll, true);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        });
    }, []);

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        return (() => {
            window.removeEventListener("scroll", handleResize);
        });
    }, []);

    const handleResize = (e: any) => {
    };

    const handleScroll = (e: any) => {
        if (!showStickyBtn && window.scrollY > 120) {
            setShowStickyBtn(true);
        }
    };
    const [showVideo, setShowVideo] = React.useState(false);
    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "customized-dialog-title",
        fullWidth: true,
        maxWidth: "xl",
        content: (
                <ReactPlayer
                        controls={true}
                        playing={false}
                        loop={true}
                        autoPlay={true}
                        volume={1}
                        muted={false}
                        url={strategyVideoUrl}
                        width="100%"
                        playsinline={true}
                />),
        dividers: false,
        onClose: () => setShowVideo(false),
        open: showVideo,
        title: "",
        onBackdropClick: () => setShowVideo(false),
    };
    const notAuthenticat = !!(props && props.groupDetail && props.groupDetail.notAuthenticated);
    return (
            <>
                <div className="heroFirst" style={{
                    backgroundImage: `url("${process.env.PUBLIC_URL}/images/landing_page_v2/${bgImage}")`,
                }}>
                    <div className="overlay">
                        {
                            (showGrReLanPage) ?
                                    <GroupRebrand strategyVideoUrl={strategyVideoUrl} notAuthenticat={notAuthenticat} {...props} /> :
                                    <Container maxWidth="lg">
                                        <Grid container spacing={2}  alignItems={ !notAuthenticat ? "center" : "flex-start"}>
                                            <Grid item xs={12} md={6} lg={6} xl={6}>
                                                <Typography
                                                        className="colorWhite textShadow"
                                                        data-aos-duration="1500"
                                                        data-aos="fade-up"
                                                        variant="h2"
                                                >
                                                    Don’t Simply Retire. Have Something to Retire To.
                                                </Typography>
                                                <Typography
                                                        className="colorWhite"
                                                        data-aos-duration="1000"
                                                        data-aos="fade-up"
                                                        variant="subtitle1"
                                                >
                                                    Your retirement will cost you an average of 6 times more than your
                                                    house.
                                                    Most people use financing to live in a better house, so why aren’t
                                                    you using financing to help you fund a better retirement?
                                                </Typography>
                                                <ReactPlayer
                                                        className="kaiZenHero"
                                                        controls={true}
                                                        loop={true}
                                                        muted={false}
                                                        playing={false}
                                                        playsinline={true}
                                                        url={strategyVideoUrl}
                                                        volume={1}
                                                        width="100%"
                                                />
                                                <span
                                                        className="videoHeroOne mt20"
                                                        onClick={() => setShowVideo(true)}
                                                        data-aos="fade-up"
                                                        data-aos-duration="1000"
                                                >
                                                                            <PlayCircleFilled className="f70 mr15 textWhite"/>
                                                                                <div>
                                                                                    <Typography className="textHighLight f16 mb0">2 Minute Video</Typography>
                                                                                    <Typography className="textWhite f18 mb0">Learn about Kai-Zen</Typography>
                                                                                </div>
                                                </span>
                                                {
                                                        showVideo &&
                                                        <GenericDialog
                                                                dialogClassName="kaiZenMobile" {...dialogProps} />
                                                }

                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} xl={6} className={ !notAuthenticat ?  "" : "unAuthenticatGroup"}>
                                                <Card className={!notAuthenticat ? classes.getStarted : "unAuthenticatGroupCard"}>
                                                    <CardContent className={notAuthenticat ? "UnheroCard" : "heroCard"}>
                                                        <div className={notAuthenticat ? "displayNone" : "displayUnset"} id="base">
                                                            <Typography variant="subtitle2"
                                                                        className="textHighLight mb0 ">
                                                                60 - 100%
                                                            </Typography>
                                                            <Typography variant="body2" className="textWhite mb0">
                                                                More for Retirement
                                                            </Typography>
                                                            <div id="chevron">&nbsp;</div>
                                                        </div>
                                                        {
                                                            notAuthenticat ?
                                                                    <UnAuthenticationForm {...props} notAuthenticat={notAuthenticat} /> :
                                                                    <React.Fragment>
                                                                        {
                                                                                !(props.strategyData.slug === "kaizen" && props.accountType === props.ALLOWED_ACCOUNT_TYPE.GROUP) &&
                                                                                props.startEstimation === 1 &&
                                                                                <EstmateSectionOne
                                                                                        {...props}
                                                                                        setEstimationData={(data: any) => setEstimationData(data)}
                                                                                        setTotalContribution={(amount: number) => setTotalContribution(amount)}
                                                                                />
                                                                        }
                                                                        {
                                                                                props.strategyData.slug === "kaizen" &&
                                                                                props.accountType === props.ALLOWED_ACCOUNT_TYPE.GROUP &&
                                                                                props.startEstimation === 1 &&
                                                                                props.groupDetail &&
                                                                                props.groupDetail.imo &&
                                                                                !props.groupDetail.imo.reBrand &&
                                                                                <EstmateSectionTwoAuthOnly {...props} />
                                                                        }
                                                                        {
                                                                                props.startEstimation === 2 &&
                                                                                <EstmateSectionTwo {...props} />
                                                                        }
                                                                        {
                                                                                props.startEstimation === 3 &&
                                                                                <EstmateSectionThree
                                                                                        {...props}
                                                                                        estimationData={estimationData}
                                                                                        totalContribution={totalContribution}
                                                                                />
                                                                        }
                                                                    </React.Fragment>
                                                        }
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Container>
                        }

                    </div>
                </div>
                {
                    !showGrReLanPage ?
                            <HeroContent/> : <DidYouKnow {...props}/>
                }
                <span className="clearFix"/>
            </>
    );
};

export default LandingSectionOne;
