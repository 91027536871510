import React from "react";
import { Formik } from "formik";
import { payloadDataHandler } from "../index";
import SourceFundForm from "./SourceFundForm";

type PropsType = {
	autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void;
	initialValues: any,
	validationSchema: any,
	updateClientFields: Function,
	user: any,
	disableSaveButton: Function,
	isFromClientDetail: boolean,
	errorInForm: boolean,
	setErrorInForm: Function,
	checkBoxObj: any,
	questionList: any,
	enableSaveButton: Function,
	showComponent: Function,
	clientSigned: boolean,
	sourceOfFundRef: any,
	handleChangeCheckBox: Function,
	checkBoxObjError: boolean,
	setFinancialAndLiabilitiesArr: Function,
	financialAndLiabilitiesArr: any,
	sourceOfFundsRefs: any,
	isWealthTransfer?: boolean,
	sendSaveRequest?: Function
	formType: string
	setFormType: Function
	setSaveSourceOfFundsForm: Function
}

function SourceOfFundsForm(props: PropsType) {
	const {
		autoSaveTrigger,
		initialValues,
		validationSchema,
		disableSaveButton,
		isFromClientDetail,
		checkBoxObj,
		questionList,
		enableSaveButton,
		showComponent,
		clientSigned,
		sourceOfFundRef,
		handleChangeCheckBox, checkBoxObjError, setFinancialAndLiabilitiesArr, financialAndLiabilitiesArr,
		sourceOfFundsRefs,
		sendSaveRequest,
		formType,
		setFormType,
		setSaveSourceOfFundsForm
	} = props;

	const [answerChanged, setAnswerChanged] = React.useState(false);
	React.useEffect(() => {
		if (sourceOfFundsRefs && sourceOfFundsRefs.current && !isFromClientDetail) {
			sourceOfFundsRefs.current.validateForm();
		}
	}, [answerChanged]);

	return (
		<>
			<Formik
				innerRef={sourceOfFundsRefs}
				initialValues={initialValues}
				enableReinitialize={true}
				validationSchema={validationSchema}
				validateOnChange={true}
				onSubmit={async (values, { setSubmitting }) => {
					let payload: any = payloadDataHandler(values, "SOURCE_OF_FUNDS", false);

					const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "SOURCE_OF_FUNDS");
					if (index > -1) {
						financialAndLiabilitiesArr.splice(index, 1);
					}
					setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
						...payload,
					}]);

					disableSaveButton();
				}}
			>
				{
					({
						 errors,
					 }) => {
						return (
							<SourceFundForm
								autoSaveTrigger={autoSaveTrigger}
								checkBoxObj={checkBoxObj}
								isWealthTransfer={checkBoxObj}
								clientSigned={clientSigned}
								handleChangeCheckBox={handleChangeCheckBox}
								enableSaveButton={enableSaveButton}
								sendSaveRequest={sendSaveRequest}
								checkBoxObjError={checkBoxObjError}
								isFromClientDetail={isFromClientDetail}
								questionList={questionList}
								showComponent={showComponent}
								sourceOfFundRef={sourceOfFundRef}
								formType={formType}
								setFormType={setFormType}
								setSaveSourceOfFundsForm={setSaveSourceOfFundsForm}
								setAnswerChanged={setAnswerChanged}
								answerChanged={answerChanged}
							/>
						);
					}}
			</Formik>
		</>
	);
}

export default SourceOfFundsForm;