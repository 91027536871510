import { EnrollmentQuestionProps } from "../../../../../../model/enrollment";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import Info from "@material-ui/icons/Info";
import EnrollmentSubField from "./EnrollmentSubField";
import EnrollmentToggleButton from "./EnrollmentToggleButton";

interface EnrollmentFieldProps {
    question: EnrollmentQuestionProps,
    clientSigned: boolean,
    isFromClientDetail: boolean,
    checkIsError: Function,
    onChangeFn: Function,
    role: number,
    offerType: string
    setSaveApplicationQuestions?: Function
    setErrorInApplicationQuestionnaireForm?: Function
}

const EnrollmentField = (props: EnrollmentFieldProps) => {
    const {
        question,
        clientSigned,
        isFromClientDetail,
        checkIsError,
        onChangeFn,
        role,
        offerType,
        setSaveApplicationQuestions
    } = props;
    const { values, errors, dirty, setFieldValue }: any = useFormikContext();

    React.useEffect(() => {
        if (dirty) {
            const errArray: any = Object.values(errors);
            const errKeyVal = errArray.find((err, key) => err !== "Required Field");
            if (!(!!errKeyVal)) {
                const deBounceFn = setTimeout(() => {
                    if (typeof setSaveApplicationQuestions === "function") {
                        setSaveApplicationQuestions(values);
                    }
                }, 1000);
                return () => clearTimeout(deBounceFn);
            }
        }
    }, [values, errors, dirty]);

    React.useEffect(() => {
        if (question.additionalFieldName && (question.additionalFieldType === "checkbox" || question.additionalFieldType === "checkboxAndPhysicianDetails")) {
            if (values[question.name] === "Yes" && question.additionalFieldType === "checkboxAndPhysicianDetails") {
                let val = values[question.additionalFieldName] && Array.isArray(values[question.additionalFieldName]) && values[question.additionalFieldName].length > 0 ? values[question.additionalFieldName] : ["other"];
                setFieldValue(question.additionalFieldName, val);
            } else {
                let val = values[question.additionalFieldName] && Array.isArray(values[question.additionalFieldName]) && values[question.additionalFieldName].length > 0 ? values[question.additionalFieldName] : [];
                setFieldValue(question.additionalFieldName, val);
            }
        }
    }, [values[question.name]]);

    //In case of Question no 10 - Sub field will Show only if selected "NO"
    let renderSubField = true;
    if (question.key === "10" && values && (values[question.name] === "Yes" || values[question.name] == undefined || values[question.name] == "")) {
        renderSubField = false;
    }
    if (question.key !== "10" && values && (values[question.name] === "No" || values[question.name] == undefined || values[question.name] == "")) {
        renderSubField = false;
    }

    return (
            <Grid item xs={12} sm={12} md={12} key={question.key}>
            <Grid container>
                {!!question.fieldCaption &&
                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle1" component="strong"
                                        className="floatLeft mt15">
                                {question.fieldCaption}
                            </Typography>
                        </Grid>
                }
                {!!question.mainLabel &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography component="strong" variant="body1"
                                        className="mb0- floatLeft">{question.mainLabel}</Typography>
                        </Grid>
                }
                {!question.notShowUpperHrLine && <span className="hLine"/>}
                <Grid item xs={12} sm={12} md={9}>

                    <Typography style={{ display: "flex" }} component="strong" variant="body1" className="mb0 floatLeft mt10">
                        <React.Fragment>
                            {!!question.subQuestionLi &&
                                    <span style={{ marginRight: 3 }}>{question.subQuestionLi} </span>
                            }
                            <span>{question.label}</span>
                        </React.Fragment>
                    </Typography>
                    {question.infoTooltip &&
                            <Tooltip title={<Typography
                                    className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                <IconButton>
                                    <Info color="primary"/>
                                </IconButton>
                            </Tooltip>
                    }
                    {!!question.label1 &&
                            <Typography component="strong" style={{ display: "flex" }} variant="body1"
                                        className="mb0 floatLeft mt10">{question.label1}</Typography>
                    }
                    {!!question.label2 &&
                            <Typography component="strong" style={{ display: "flex" }} variant="body1"
                                        className="mb0 floatLeft ml15"><span style={{ marginRight: 3 }}>a.</span>
                                <span> {question.label2}</span></Typography>
                    }
                    {!!question.label3 &&
                            <Typography component="strong" style={{ display: "flex" }} variant="body1"
                                        className="mb0 floatLeft ml15"><span style={{ marginRight: 3 }}>b.</span>
                                <span> {question.label3}</span></Typography>
                    }
                </Grid>
                    <EnrollmentToggleButton question={question} role={role} clientSigned={clientSigned}
                                            onChangeFn={onChangeFn}/>
                    {question && question.additionalRemarks && renderSubField && <EnrollmentSubField
                            question={question}
                            clientSigned={clientSigned}
                            isFromClientDetail={isFromClientDetail}
                            checkIsError={checkIsError}
                            offerType={offerType}
                    />}
            </Grid>
            {!question.notShowHrLine && <span className="hLine"/>}
            {!!question.notShowHrLine && <p className="mt5 mb10"/>}
    </Grid>);

};

export default EnrollmentField;