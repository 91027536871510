import * as React from "react";
import {
	AppBar,
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import { FormInputProps } from "../../model";
import { Label, PageTitle } from "../formInputs";
import { a11yProps, TabPanel } from "../../pages/client/ClientProfile/component/TabsMenu";
import { Formik } from "formik";
import { FormInputs } from "../../pages/approvedAgents/ApprovedAgentsPage";
import * as Yup from 'yup';
import { initialValuesPasswordInputs, PasswordInputs, validationPasswordInputs } from "../Common/PasswordInputs";
import { TextField } from "../formikFormInputs";
import { useActions } from "../../actions";
import * as Actions from "../../actions/account";
import { AgentProfileTabReduxForm } from "../../pages/agent/AgentProfile/AgentProfileTab";
import { AgentDocuments } from "../../pages/agent/AgentProfile";

export const hidden = (field: FormInputProps) => {
    return <input {...field.input} type="hidden"/>;
};

const AgentProfile = ({ profile }) => {
	const [value, setValue] = React.useState(0);
	const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	  };
	return (
		<>
			<PageTitle title='My Profile'/>
			<Grid container spacing={2}>
				<Banner title="My Profile" description="Manage your profile and view your agent documents." />
				<Grid item xs={12} md={12} lg={12}>
					<AppBar position="static" color="default">
						<Tabs
							value={value} onChange={handleChange}
						>
							<Tab label="PROFILE DETAILS" {...a11yProps(0)} />
							<Tab label="MY AGENT DOCUMENTS" {...a11yProps(1)} />
						</Tabs>
					</AppBar>
					<TabPanel value={value} index={0}>
						<ProfileDetailsTab profile={profile} />
					</TabPanel>
					<TabPanel value={value} index={1}>
                        <AgentDocuments userId={profile.id} />
					</TabPanel>
				</Grid>
			</Grid>
		</>
	)
}

export const Banner = ({title, description}: { title: string, description: string }) => (
	<Grid item xs={12} md={12} lg={12}>
	  <Typography variant="h3" className="floatLeft w100 mt20Small">
		{title}
	  </Typography>
	  <Typography variant="body1" className="floatLeft w100 mb30">
		{description}
	  </Typography>
	</Grid>
  );


const ProfileDetailsTab = ({ profile }: any) => {
	const accountActions = useActions(Actions);
	const currentPasswordField = [
	  {
		name: 'currentPassword',
		label: <Label label={'Current Password'} />,
		type: 'password',
		required: true,
		component: TextField,
		className: '',
		rowSize: 12
	  }
	];
	const [emailForm, setEmailForm] = React.useState({
	  subscribe_marketing: false,
	  subscribe_advisors: false
	});
	
	const updateEmailPreferences = async (e: any) => {
	  type EmailForm = typeof emailForm
	  type Fields = keyof EmailForm
	  const field: Fields = e.target.name
  
	  const newEmailForm = {
		...emailForm,
		[ field ]: Number(!emailForm[ field ])
	  };
  
	  await accountActions.unsubscribedUser(profile.id, {
		...newEmailForm,
		subscribe_all_emails: profile.subscribe_all_emails
	  })
  
	  setEmailForm(newEmailForm)
	};

	const changePassword = async (values: any, {setSubmitting, resetForm}: any) => {
	  try {
		await accountActions.changePassword(values);
		resetForm()
	  } finally {
		setSubmitting(false);
	  }
	};


	React.useEffect(() => {
	  if (profile) {
		setEmailForm({
		  subscribe_marketing: profile.subscribe_marketing,
		  subscribe_advisors: profile.subscribe_advisors
		});
	  }
	}, [profile])
  
	return (
	  <Grid container spacing={3}>
		<Grid item xs={12} md={8}>
		  <Card className="mt45">
		  	<AgentProfileTabReduxForm initialValues={profile} userId={profile.id} />
		  </Card>
		</Grid>
		<Grid item xs={12} md={4}>
		  <Card className="mt45 floatLeft w100">
			<CardContent>
			  <Typography variant="caption" component="strong" className="floatLeft w100">
				Change Your Password
			  </Typography>
			  <Formik
				initialValues={{
				  currentPassword: '',
				  ...initialValuesPasswordInputs
				}} 
				onSubmit={changePassword} 
				validationSchema={
					Yup.object({
						currentPassword: Yup.string().required('Current password is a required field'),
						...validationPasswordInputs
					}
				)}
                                                                id="edit-agent-profile"
			  >
				{({isSubmitting, errors, values, touched, handleChange, handleBlur, handleSubmit}) => (
				  <form onSubmit={handleSubmit} >
					<Grid container spacing={2}>
					  <FormInputs
						fields={currentPasswordField}
						{...{errors, values, touched, handleChange, handleBlur}} />
					  <PasswordInputs
						{...{errors, values, touched, handleChange, handleBlur}} />
					</Grid>
  
					<Grid container justify="space-between" alignItems="center" style={{marginTop: '10px'}}>
					  <Button
						size="medium"
						className=""
						type="submit"
						variant="contained"
						color="primary"
						disabled={isSubmitting}
					  >
						Change Password
					  </Button>
					</Grid>
				  </form>
				)}
			  </Formik>
			</CardContent>
		  </Card>
  
		  <Card className="mt30 floatLeft w100">
			<CardContent>
			  <Typography variant="caption" component="strong" className="floatLeft w100 mb15">
				Email Preferences
			  </Typography>
  
			  {
				profile && (
				  <FormControl component="fieldset" className="floatLeft w100">
					<Typography variant="caption" color="primary" className="mb15 floatLeft w100">What type of
					  emails would
					  you like to receive?</Typography>
					<FormGroup>
					  <FormControlLabel
						color="primary" className="mb15 textPrimary" label="Marketing emails, news, and offers." control={
						<Checkbox
						  className="cardPadding0 mr10"
						  color="primary"
						  name="subscribe_marketing"
						  checked={emailForm.subscribe_marketing}
						  onChange={updateEmailPreferences}
						/>}
					  />
					  <FormControlLabel
						color="primary"
						className="mb15 textPrimary"
						label={`Important notifications from your agent, ${profile.firstName}`}
						control={
						  <Checkbox
							className="cardPadding0 mr10"
							color="primary"
							name="subscribe_advisors"
							checked={emailForm.subscribe_advisors}
							onChange={updateEmailPreferences}
						  />}
					  />
					</FormGroup>
				  </FormControl>
				)
			  }
			</CardContent>
		  </Card>
		</Grid>
	  </Grid>
	)
  };
  

export default AgentProfile
