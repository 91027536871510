import * as React from "react";
import { connect } from "react-redux";
import numeral from "numeral";
import { Grid } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { genderDropDown } from "../../constants";
import { SelectField, Label, Button, TextField } from "../../components/formInputs";
import { required } from "../../utils/validation";
import { Form, Field, getFormValues, reduxForm, isInvalid, isDirty, isPristine } from "redux-form";
import { Slider } from "../Common";
import LeadContactUS from "./LeadContactUS";
import { RootState } from "../../reducers";
import * as AccountActions from "../../actions/account";
import * as EstimationActions from "../../actions/estimation";
import { useActions } from "../../actions";
import { EstimationPayload } from "../../model/estimation";
import { fiveYearTrustFee } from "../../constants";
import { makeQueryString, pez } from "../../utils/commonUtils";
import { getQS } from "../../utils/queryString";

const LeadEstimationForm = (props: any) => {
    const { estimation, formValues, handleSubmit, setEstimationInputValues, token } = props;
    const [initMinAge, initMaxAge] = [18, 65];
    const [MIN_CONTRIBUTION_AMOUNT, DEFAULT_CONTRIBUTION_AMOUNT, MAX_CONTRIBUTION_AMOUNT] = [25000, 35000, 200000];
    const strategySlug: string = props.strategy && props.strategy.slug ? props.strategy.slug : "kaizen";
    let carrierName: string = (props.carrier && props.carrier.carrierName) ? props.carrier.carrierName : "National Life - (LSW)";
    let total_5yr_Contribution: number = (formValues && formValues.contribution) ?
            numeral((formValues && formValues.contribution) || 0).value() * 5 :
            0;
    const searchParams = getQS(props.location.search);
    const accountActions = useActions(AccountActions);
    const estimationActions = useActions(EstimationActions);
    const [minAge, setMinAge] = React.useState(initMinAge);
    const [openDialog, setOpenDialog] = React.useState(false);

    const agentDetail  = (props.agentLinkDetail && props.agentLinkDetail.agent && props.agentLinkDetail.agent.user) || "";

    if (total_5yr_Contribution > 0) {
        total_5yr_Contribution += fiveYearTrustFee;
    }
    let totalContribution: string = total_5yr_Contribution.toFixed(2);
    let estimationData: any = {
        "def_comp_beginning_age": 0,
        "def_comp_ending_age": 0,
        "def_comp_total": 0,
        "def_comp_annual": 0,
        "def_comp_irr": "0",
        "kaizen_beginning_age": 0,
        "kaizen_ending_age": 0,
        "kaizen_annual_income": 0,
        "kaizen_total_income": 0,
        "kaizen_initial_death_benefit": 0,
        "kaizen_death_benefit_yr1": 0,
        "kaizen_death_benefit_yr90": 0,
        "kaizen_levd_irr": "0",
        "kaizen_unlevd_irr": "0",
        "kaizen_1980s_stress_test": 0,
        "kaizen_great_depression_test": 0,
        "lsw_beginning_age": 0,
        "lsw_ending_age": 0,
        "lsw_comparison_annual_income": 0,
        "lsw_comparison_total_income": 0,
        "lsw_comparison_initial_death_benefit": 0,
        "lsw_comparison_death_benefit_yr1": 0,
        "lsw_comparison_death_benefit_yr90": 0,
        "lsw_comparison_unlevd_irr": "0",
        "bonus_irr": "0",
        "bonus_beginning_age": 0,
        "bonus_ending_age": 0,
        "bonus_comp_total": 0,
        "bonus_comp_annual": 0,
    };
    if (estimation && estimation.data && estimation.data.result) {
        estimationData = estimation.data.result;
        props.sendEstimationOnParent(estimationData);
        props.setTotalContribution(totalContribution);
    }

    React.useEffect(() => {
        if (carrierName && (carrierName === "National Life - (LSW)" || carrierName === "Allianz Life") && strategySlug === "kaizen") {
            setMinAge(initMinAge); // 18
        }
    }, [carrierName]);

    const getEstimatorData = async (data: any) => {
        if (data.age < minAge || data.age > initMaxAge) {
            setOpenDialog(true);
            return;
        }

        if (data && data.age >= minAge && data.age <= initMaxAge) {
            getEstimates({
                contribution: data.contribution,
                age: data.age,
                gender: data.gender,
                healthType: data.healthType || "Standard",
                strategySlug: strategySlug,
                carrierName: carrierName,
            }).then(() => {
                props.sendEstimationOnParent(estimationData);
                props.setTotalContribution(totalContribution);
                if (data && data.contribution)
                    props.setAnnualContribution(data.contribution);
            });
        }

        return false;
    };

    const getEstimates = async (data: any) => {
        let payload: EstimationPayload = {
            age: data.age,
            carrierName: data.carrierName,
            contribution: numeral(data.contribution).value() + "",
            gender: data.gender === "1" ? "Male" : "Female",
            id: numeral(searchParams.id).value() as number,
            rating: data.healthType || "Standard",
            strategySlug: data.strategySlug,
        };

        if ((payload.contribution && Number(payload.contribution) >= 0)) {
            // _getInvitedClientEstimates & update lead
            const estimatorData: any = await estimationActions.getInvitedClientEstimates(payload, token);

            if (estimatorData.data && estimatorData.data.result) {
                let profileData: any = {
                    age: data.age || searchParams.age,
                    contributionAmount: numeral(data.contribution || 38350).value() + "",
                    gender: data.gender || searchParams.gender,
                    healthType:  searchParams.healthType || "Standard",
                };
                if (props.history.location.pathname.search("/kaizen") > -1 || props.history.location.pathname.search("/agent-link/") > -1) {
                    props.sendEstimationOnParent(estimationData);
                    props.setTotalContribution(totalContribution);
                    setEstimationInputValues(profileData);
                    const urlObject = {
                        ...searchParams,
                        age: profileData.age,
                        email: encodeURIComponent(pez(searchParams.email as string)),
                        gender: profileData.gender,
                    };
                    const queryString = makeQueryString(urlObject);
                    props.history.push({ pathname: window.location.pathname, search: queryString });
                    return;
                }
                await accountActions.updateInvitedUserClientDetails(profileData);
            }
        }
    };

    const validateAgeField = (value: any) => {
        const val = typeof value === "string" ? parseInt(value, 10) : value;
        if (val < 0) {
            return "Negative value are not allowed";
        } else if (val >= 0 && val < initMinAge) {
            return `Age must be greater than or equal to ${initMinAge}`;
        } else if (val > initMaxAge) {
            return `Age must be less than or equal to ${initMaxAge}`;
        }
    };

    return (
            <>
                {
                    openDialog &&
                    <LeadContactUS agentDetail={agentDetail} {...props} close={() => setOpenDialog(false)} />
                }
                <Form className="estimateReturnsForm mt20" onSubmit={handleSubmit(getEstimatorData)}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={10} lg={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Field
                                            id="outlined-number1"
                                            fullWidth
                                            className="estimateReturnsField"
                                            color="primary"
                                            name="age"
                                            placeholder="Enter Age"
                                            type="number"
                                            InputProps={{ inputProps: { inputMode: "numeric", maxLength: 2 } }}
                                            component={TextField}
                                            validate={[required, validateAgeField]}
                                            label={<Label label={'Your Age'} required={true} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                            id="outlined-number2"
                                            className="estimateReturnsField textLeft"
                                            fullWidth
                                            color="primary"
                                            variant="outlined"
                                            name="gender"
                                            component={SelectField}
                                            options={genderDropDown}
                                            validate={[required]}
                                            label={<Label label={'Your Gender'} required={true} />}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Slider
                                            className="mt20 mb40 floatLeft mb60small"
                                            defaultValue={DEFAULT_CONTRIBUTION_AMOUNT}
                                            handleChange={(contribution: number) => props.change('contribution', contribution)}
                                            mainTitle={<>Annual Contribution Amount <sup className="colorRed">*</sup> </>}
                                            max={MAX_CONTRIBUTION_AMOUNT}
                                            min={MIN_CONTRIBUTION_AMOUNT}
                                            name={"contribution"}
                                            sliderClassName={"distributionOuterSpan"}
                                    />
                                </Grid>
                                <Grid item xs={12} className="positionR textCenter">
                                    <Button
                                            className="customBtn customBtn glow mt20 floatRight"
                                            color="primary"
                                            disabled={props.invalid && !props.isDirty && props.isPristine}
                                            endIcon={<ArrowForwardIcon/>}
                                            label={"View Estimate"}
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </>
    );

};

function mapStateToProps(state: RootState, ownProps: any) {
    let urlProps: any = {};
    let params: any = getQS(ownProps.location.search);
    const _formValues:any = getFormValues("LandingLeadEstimatorForm")(state) || {};
    if (ownProps.location && ownProps.location.search && params && params.age) {
        urlProps = {
            age : params.age || pez(_formValues.age),
            gender : params.gender || pez(_formValues.gender),
            householdIncome : params.householdIncome
        }
    }
    urlProps["healthType"] = params ? params.healthType : "Standard";
    urlProps.contribution = "38350";
    return {
        initialValues: { ...urlProps },
        formValues: _formValues,
        estimation: state.estimation,
        invalid: isInvalid("LandingLeadEstimatorForm")(state),
        isDirty: isDirty("LandingLeadEstimatorForm")(state),
        isPristine: isPristine("LandingLeadEstimatorForm")(state),
    };
}

const reduxEnrollmentPage = reduxForm({
    form: "LandingLeadEstimatorForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
    touchOnChange: true,
})(LeadEstimationForm);

export default connect(mapStateToProps)(reduxEnrollmentPage);