import React from "react";
import { Grid, Typography, Card, CardContent, Chip } from "@material-ui/core";
import { DashboardStyle as Styles } from "../../pages/agent/dashboard/dashboardStyle";
import KpiModal from "./KpiModal";

export interface QuickProps {
    totalInvites: number,
    totalAccountCreated: number,
    enrolling: number,
    enrolled: number,
    companyId: number,
    companyStrategySlug: string,
    contributionType?: string
}

export interface QuickStatsProps {
    title: string,
    subTitle: string,
    count: number,
    type: string
}


export default function QuickView(props: QuickProps) {
    const {
        totalInvites,
        totalAccountCreated,
        enrolling,
        enrolled,
        companyId,
        companyStrategySlug,
        contributionType
    } = props;
    const classes = Styles();

    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState(undefined);
    const [title, setTitle] = React.useState(undefined);

    const quickData: Array<QuickStatsProps> = [
        {
            title: "Total Participants",
            subTitle: "Total number of group participants listed on the census.",
            count: totalInvites,
            type: "ALL",
        },
        {
            title: "Total Accounts Created",
            subTitle: "Total number of group participants who have created an account.",
            count: totalAccountCreated,
            type: "ACCOUNT_CREATED",
        },
        {
            title: "Total Currently Enrolling",
            subTitle: "Number of group participants currently enrolling.",
            count: enrolling,
            type: "ENROLLING",
        },
        {
            title: "Total Enrollments Completed",
            subTitle: "Total number of group participants who have completed the enrollment process.",
            count: enrolled,
            type: "ENROLLED",
        },
    ];

    const handleKpiModel = (statsProps) => {
        setTitle(statsProps.title);
        setType(statsProps.type);
        setOpen(true);
    };

    return (
            <>
                {
                    open && <KpiModal
                            handleClose={() => setOpen(false)}
                            type={type}
                            modalTitle={title}
                            companyId={companyId}
                            companyStrategySlug={companyStrategySlug}
                            contributionType={contributionType}
                    />
                }
                    {
                        quickData.map((item: QuickStatsProps, idx: number) =>
                                <Grid item xs={12} md={6} lg={6} xl={3} key={idx}>
                                    <Card className={classes.cardRoot + " " + classes.cardShadow + " " + classes.radius8 + " " + classes.minHeight180}>
                                        <div className={classes.details}>
                                            <CardContent className={classes.content}>
                                                <Typography component="strong" variant="body1"
                                                            className={classes.disPlay + " " + classes.mb0}>
                                                    {item.title}
                                                </Typography>
                                                <Typography variant="caption"
                                                            className={classes.disPlay}>
                                                    {item.subTitle}
                                                </Typography>

                                                <Typography color="primary" component="a" variant="caption"
                                                        className={classes.disPlay + " " + classes.mt15}
                                                        onClick={() => handleKpiModel(item)}>
                                                    View Details
                                                </Typography>
                                            </CardContent>
                                        </div>
                                        <div className={classes.btnSection}>
                                            <Chip classes={{root: classes.btnGreen}}
                                                    label={item.count}/>
                                        </div>
                                    </Card>
                                </Grid>,
                        )
                    }
            </>
    );
}