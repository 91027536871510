import { Action, ActionType } from "../model";
import {Verify} from '../model/account';
import createReducer from "./createReducer";

export const verify = createReducer<Verify>({verifiedInvite: false, checked: false}, {
  [ActionType.VERIFY_INVITATION](state: boolean, action: Action<Verify>) {
    return action.payload;
  },
  [ActionType.SET_PASSWORD](state: boolean, action: Action<Verify>) {
    return action.payload;
  }
});