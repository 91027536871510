import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

function GroupAssumptions(props: any) {
    const {common} = props;

    //let growthRate:number = ((common && common.growthrate)*100) || 0;
    let incometaxrate:number = ((common && common.incometaxrate)*100) || 0;
    let capitalgainstaxrate:number = ((common && common.capitalgainstaxrate)*100) || 0;
    //let afrrate:number = ((common && common.afrrate)*100) || 0;
    return (<Grid container spacing={3} className="mt20">
        <Grid item xs={12} md={8}>
            <Typography component="div" className="txtGray f12 floatLeft w100">
                <Typography component="div" className="txtGray f12 mb0" style={{lineHeight: '20px'}} ><b>Assumptions Used
                    for Alternative Choices:</b>
                    <Tooltip
                            color="primary"
                            title="All assumptions are subject to change and are provided for illustrative purposes only. Actual results may be more or less favorable. This presentation is not valid unless accompanied by a complete insurance company illustration. Please see the full illustration for guaranteed values and other important information."
                            arrow>
                        <IconButton className="pl0" aria-label="Info">
                            <InfoIcon className="f18"/>
                        </IconButton>
                    </Tooltip>
                    Investments allocated to S&P Point to Point Cap Focus (Illustrated Growth Rate: 6%)
                </Typography>
                <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>IUL insurance policy
                    distributions begin at the latter of age 65 or after the lender loan has been repaid
                    (typically in the 15th policy year) and continue through age 90.</Typography>
                <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>Long Term Capital Gains Rate of {capitalgainstaxrate}%.</Typography>
                <Typography className="txtGray f12 mb0" style={{lineHeight: '20px'}}>Income tax rate of {incometaxrate}%.</Typography>
                <Typography className="txtGray f12 mb0" style={{ lineHeight: "20px" }}>
                    The results are hypothetical and are not valid without a carrier illustration. The results will
                    vary depending on health, allocation choices and other variations. These results are based
                    on averages. Your actual illustration could be higher or lower. For accurate illustrations
                    create your account and go to the custom illustration inside of ilia.</Typography>
            </Typography>
        </Grid>
    </Grid>);

}

export default GroupAssumptions;