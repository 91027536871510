import React from "react";
import {useFormikContext} from "formik";
import { scroller } from 'react-scroll'
import {useActions} from "../actions";
import * as Actions from "../actions/account";


const FocusError = (props:any) => {
    const {offset} = props;
    const { errors, isSubmitting, isValidating }: any = useFormikContext();
    const accountActions = useActions(Actions);
    React.useEffect(() => {
        setTimeout(() => {
        if (isSubmitting && !isValidating) {
            console.log("Errors: ===>", errors);
            let keys = Object.keys(errors);
            if(keys && keys[0] && keys[0].includes("Question")){
                keys.sort(function(a:any,b:any){
                    let aElement = a.split('-')[1];
                    let bElement = b.split('-')[1];
                    return Number(aElement) > Number(bElement) ? 1 : -1;
                })
            }
            let id:string = "";
            if (keys.length > 0) {
                const selector = `[name*=${keys[0]}]`;
                id = keys[0];
                const errorElement = document.querySelector(selector) as HTMLElement;
                const errorElementById = document.getElementById(id) as HTMLElement;
                 if(typeof errors[keys[0]] === "string") {
                     if (errorElement) {
                         errorElement.focus();
                         scroller.scrollTo(keys[0], {
                             duration: 800,
                             delay: 0,
                             smooth: "easeInOutQuart",
                             offset: offset ? -200 : 0,
                         });
                     } else if (errorElementById) {
                         errorElementById.focus();
                         scroller.scrollTo(keys[0], {
                             duration: 800,
                             delay: 0,
                             smooth: "easeInOutQuart",
                             offset: offset ? -200 : 0,
                         });
                     }
                     accountActions.setSnackBarMessage(false, errors && errors[keys[0]]);
                     return;
                } else if (typeof errors[keys[0]] === "object"){
                    let errObj = errors[keys[0]][0];
                     for (let i = 0; i <= Number(errors[keys[0]].length-1); i++) {
                         if(errors[keys[0]][i] !== undefined){
                             const errorKey = Object.keys(errors[keys[0]][i])[0];
                             //const selector = `[name*='${keys[0]}[${i}].${errorKey}']`;
                             let scrollKey = `${keys[0]}[${i}].${errorKey}`;
                             //const errorElement = document.querySelector(selector) as HTMLElement;
                             //if (errorElement){
                                 //errorElement.focus();
                                 scroller.scrollTo(scrollKey, {
                                     duration: 800,
                                     delay: 0,
                                     smooth: 'easeInOutQuart',
                                     offset: offset ? -200 : 0
                                 })
                             //}
                         }
                     }
                    if (errObj) {
                     let nestedKeys = Object.keys(errObj);
                     accountActions.setSnackBarMessage(false, errObj && errObj[nestedKeys[0]]);
                     return;
                    }
                 }
            }
        }},0)
    }, [errors, isSubmitting, isValidating]);
    return <></>;
};

export default FocusError;