import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    Typography,
    IconButton,
    Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeIcon: {
                float: "right",
                position: "absolute" as "absolute",
                right: 10,
                top: 18,
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root}>
                <Typography variant="h6">{children} {onClose ? (
                        <IconButton aria-label="close" className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}</Typography>

            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function DeleteConfirmation(props: any) {
    const { success, close } = props;

    const handleClose = () => {
        close();
    };
    const handleDelete = () => {
        success();
    };

    return (
            <div>
                <Dialog onClose={handleClose} fullWidth={true} maxWidth={"sm"} aria-labelledby="customized-dialog-title"
                        open={true}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Delete Confirmation
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1" align="left" className="mb0">
                            Are you sure to delete?
                        </Typography>
                        <Button size="small" variant="contained" onClick={handleClose} color="secondary" className="floatRight mt20">
                            Cancel
                        </Button>
                        <Button size="small" variant="contained" className="btnContainedRed floatRight mt20 mr10" onClick={handleDelete}>
                            Delete
                        </Button>

                    </DialogContent>
                </Dialog>
            </div>
    );
}
