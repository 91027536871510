import { ActionType, Aws } from "../model";

export const SignedUrlGet = (query: Aws, isSecuredSignedUrl:boolean = false) => async (dispatch: Function, getState: Function, api: any) => {
	console.log("Called Signed URL");
	try {
		let resp;
		if (isSecuredSignedUrl) {
			resp = await api.get(`aws/securedSignedUrl`, {}, query);
		} else {
			resp = await api.get(`aws/signUrl`, {}, query);
		}
		dispatch({
			type: ActionType.ALERT,
			payload: {}
		});
		return dispatch({
			type: ActionType.SIGNED_URL,
			payload: resp
		});
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const getSecureUrl = (dirName: string, query: Aws) => async (dispatch: Function, getState: Function, api: any) => {
	
	try {
		let resp = await api.get(`aws/secureUrl/${dirName}`, {}, query);
		dispatch({
			type: ActionType.ALERT,
			payload: {}
		});
		return resp;
	} catch(err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const getSecureUrlAndDownload = (dirName: string, query: Aws) => async (dispatch: Function, getState: Function, api: any) => {

    try {
        let resp = await api.get(`aws/secureUrl/${dirName}/download`, {}, query);
        dispatch({
            type: ActionType.ALERT,
            payload: {}
        });
        return resp;
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};


