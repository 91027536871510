import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {createStyles, Dialog, DialogContent, DialogTitle, makeStyles, Theme} from "@material-ui/core";
import ClientEstimationResult from "./ClientEstimationResult";
const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        maxHeight: 600,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

interface ContributionConfirmationDialogProps {
    open: boolean,
    handleClose: Function,
    responseData?:any,
    estimationConfirmation?: Function
}

export default function ContributionConfirmationDialog(props: ContributionConfirmationDialogProps) {
    const classes = useStyles();
    const {open, handleClose, responseData, estimationConfirmation} = props;
    return (<>
        <Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={() => {
                    handleClose()
                }}
                aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="customized-dialog-title">
                Enrollment Contribution Confirmation
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                    handleClose();
                }}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <ClientEstimationResult responseData={responseData} handleClose={handleClose} isEditable={false} estimationConfirmation={estimationConfirmation} />
            </DialogContent>
        </Dialog>
    </>)
}