import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    IconButton,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
interface ViewFilesListProps {
    setRecalculate:Function,
    recalculate:boolean,
    handleReset:Function,
    setIsCalculatedByProfileData:Function
}

export default function Recalculate(props:ViewFilesListProps) {
    const { setRecalculate, recalculate, handleReset, setIsCalculatedByProfileData } = props

    const handleClose = () => {
        setRecalculate(false);
    };


    return (
            <div>
                <Dialog
                    onClose={handleClose}
                    fullWidth={true}
                    maxWidth={"sm"}
                    aria-labelledby="customized-dialog-title"
                    open={recalculate}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>{''}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            The current estimation does not match your Date of Birth & Gender. Please recalculate using your personal details.
                        </Typography>
                        <Button
                            className="mb15 mt15 floatRight"
                            variant="contained"
                            color={"primary"}
                            size="small"
                            onClick={() => {
                                if(typeof handleReset === 'function') {
                                    handleReset();
                                }
                                if(typeof setIsCalculatedByProfileData === 'function'){
                                    setIsCalculatedByProfileData(true)
                                }
                                handleClose();
                            }}
                        >
                            Recalculate
                        </Button>
                    </DialogContent>
                </Dialog>
            </div>
    );
}
