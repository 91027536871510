import React from "react";
import { Button, CircularProgress, Dialog, DialogContent, Typography } from "@material-ui/core";
import AddNotesPage from "../../../../../components/formInputs/AddNotes";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogTitleProps } from "./ViewDocument";
import { blue } from "@material-ui/core/colors";

const defaultTitle = "";
const defaultContent = "";
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            wrapper: {
                position: 'relative',
            },
            buttonProgress: {
                color: blue[500],
                position: 'absolute',
                top: '10px',
                left: '50%',
                marginTop:'0px',
                width:'100%'
            },
        })
);
const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: '18px',
                color: theme.palette.grey[500],
            },
        });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon className="f20"/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});
export default function ResetEnrollmentModal({
                                                 open,
                                                 handleClose,
                                                 accountType = "",
                                                 title = defaultTitle,
                                                 content = defaultContent,
                                                 disableDelete = false,
                                                 handleYes,
                                                 setNotes,
                                                 notes,
                                                 buttonLoader = false,
                                             }: any) {
    const [notesArea, setNotesArea] = React.useState(false);
    const [buttonText, setbuttonText] = React.useState("Add");
    const classes = useStyles();
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {content}
                </DialogTitle>
                <DialogContent dividers>
                    <div id="alert-dialog-description">
                        <Typography variant="body1" className="mb0">Are you sure you want to reset enrollment for the
                            client? This will clear out all client data and they will need to restart the enrollment
                            process. The client and agent will receive an email that enrollment has been reset with a
                            message for the client to contact the agent for questions.</Typography>
                    </div>
                    {notesArea && <AddNotesPage setNotesArea={setNotesArea} notes={notes} sendNotes={(val: any) => {
                        const text = (val === "") ? "Add" : "Edit";
                        setNotes(val);
                        setbuttonText(text);
                    }}/>}

                    <Button  size="small"
                            className="floatLeft mt20 floatRightSM"
                            type="submit"
                            variant="contained"
                            color="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <span className="clearFix"/>
                    {!disableDelete && <Button  size="small"
                            className="bgRed floatLeft  floatRightSM mt20 ml15 mlSm0 mr5"
                            type="submit"
                            variant="contained"
                            disabled={buttonLoader}
                            color="primary" onClick={handleYes} autoFocus>
                        Reset Enrollment
                        {buttonLoader &&
                        <CircularProgress className={classes.buttonProgress}
                                          size={24}/> }
                    </Button>}
                    <span className="clearFix"/>
                    <Button className="floatRight mt20 floatRightSM" color="primary"
                            size="small"
                            onClick={() => {
                                setNotesArea(true);
                            }} variant="outlined">
                        {buttonText} Notes
                    </Button>
                    <span className="clearFix"/>
                </DialogContent>

            </Dialog>
    );
}
