import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useActions } from "../actions";
import * as Actions from "../actions/auth";
import * as Verify from "../actions/verify";
import { PageTitle } from "../components/formInputs";
import { getQueryStringValue } from "../utils/queryString";
import ResetPassword from "../components/ResetPassword";
import loginlogo from "../styles/images/logo-ilia.png";
import login from "../styles/images/login.png";

type PasswordData = {
    password: string,
    confirmPassword: string,
    verificationCode?: string,
    resetPasswordToken?: string
}
const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    progress: {
        margin: theme.spacing(2),
    },

    logInWrap: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        verticalAlign: "middle",
        height: "100%",
        width: "100%",
        "&::after": {
            content: "\"\"",
            position: "absolute",
            background: "#3971EC",
            top: 0,
            bottom: 0,
            right: 0,
            width: "42%",
            [theme.breakpoints.down("sm")]: {
                position: "inherit",
                background: "transparent",
                width: "auto",
            },
        },

    },
    textWhite: {
        color: "#FFFFFF",
    },
    textCenter: {
        textAlign: "center",
    },
    mt10: {
        marginTop: 10,
    },
    mr30: {
        marginRight: 30,
    },
    loginCard: {
        margin: "auto",
        width: 865,
        boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
        background: "transparent !important",
        left: 0,
        right: 0,
        zIndex: 999,
        border: 0,
        padding: "2%",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            background: "#ffffff !important",
            width: "100%",
            margin: "0 15px",
        },
    },
    muiCardContent: {
        padding: 15,
        float: "left",
        width: "100%",
        position: "relative",
    },
    loginForm: {
        padding: "60px 15px 50px",
        float: "left",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            padding: "30px 15px 50px",
        },
    },
    margin0: {
        margin: 0,
    },
    titleMain: {
        fontSize: 26,
        lineHeight: "34px",
        textAlign: "center",
        margin: 0,
        float: "right",
    },
    dpNone: {
        [theme.breakpoints.down(992)]: {
            display: "none",
        },
    },
    floatRight: {
        float: "right",
    },
    imgResponsive: {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        marginTop: "25%",
    },
    clickableLink: {
        display: "inline-flex",
        alignItems: "center",
        verticalAlign: "middle",
    },

    clickableLinksvg: {
        float: "left",
    },
    mr10: {
        marginRight: 10,
    },
    mt15: {
        marginTop: 15,
    },
}));

type TParams = { resetPasswordToken: string }

const ResetPasswordPage: React.FC<RouteComponentProps<TParams>> = (props: RouteComponentProps<TParams> & { children?: React.ReactNode }) => {
    const classes = useStyles();
    const authActions = useActions(Actions);
    const verifyActions = useActions(Verify);
    const resetPasswordToken: string = props.match.params.resetPasswordToken;
    const [progress, setProgress] = React.useState(false);
    const [verificationCode, setVerificationCode] = React.useState("");

    const onResetPassword = async (data: PasswordData) => {
        const resetPayload: PasswordData = {
            verificationCode: verificationCode,
            password: data.password,
            confirmPassword: data.confirmPassword,
            resetPasswordToken: resetPasswordToken,
        };
        const resetSuccess: string = await authActions.resetPassword(resetPayload);
        if (resetSuccess) {
            props.history.push("/login");
        }
    };
    React.useEffect(() => {
        async function verifyLink() {
            setProgress(true);
            const res = await verifyActions.verifyPasswordResetLink(resetPasswordToken);
            if (res.payload.message === "Link is expired") {
                setProgress(false);
                props.history.push("/login");
            } else {
                setProgress(false);
            }
        }

        verifyLink();

        let search = window.location.search;
        if (search) {
            //let params = new URLSearchParams();
            const vCode = getQueryStringValue("verificationCode") || "";
            setVerificationCode(vCode.toString());
        }
    }, []);

    if (progress) {
        return (
                <div className={classes.root}>
                    <CircularProgress className={classes.progress}/>
                    <Typography>Verifying Link...</Typography>
                </div>
        );
    }

    return (
            <div className={classes.logInWrap}>
                <PageTitle title="Login Page"/>
                <Card className={classes.loginCard}>
                    <CardContent className={classes.muiCardContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                                <img alt="loginLogo" src={loginlogo}/>
                                <ResetPassword onResetPassword={onResetPassword}/>
                            </Grid>
                            <Grid item xs={12} md={5} lg={5} className={classes.dpNone}>
                                <Typography variant="h3" align="right"
                                            className={classes.textWhite + " " + classes.mr30 + " " + classes.titleMain}>
                                    Set Password
                                </Typography>
                                <img className={classes.floatRight + " " + classes.imgResponsive} src={login}
                                     alt="login"/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>

    );
};

export default ResetPasswordPage;