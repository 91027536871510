import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const drawerWidth = 315;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {display:"flex"},
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            background: "#ffffff",
            color: "#000000",
            [theme.breakpoints.down("xs")]: {
                display: "block !important",
                float: "right",
            },
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9) + 1,
            },
        },

        profileImage: {
            "max-width": "50px",
            "border-radius": "100px",
        },

        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        sectionDesktop: {
            display: "block",
            [theme.breakpoints.up("xs")]: {
                display: "flex",
                position: "absolute",
                right: "0",
            },
            [theme.breakpoints.up("sm")]: {
                display: "flex",
                position: "absolute",
                right: "0",
                top: "10px",
            },
        },
    }),
);