import {
	Grid,
	Typography,
	Card,
	CardContent,
	Button,
} from "@material-ui/core";
import * as React from "react";
import copy from "copy-to-clipboard";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { dateUtils } from "../utils";


export default function ClientListDetail(props: any) {
	const { detail } = props;
	const clientListUrl = `${window.location.origin}/agent/${detail.clientListUrl}`;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={10} md={8} lg={6}>
				<Card className="muiCard mb20">
					<CardContent className="muiCardContent">
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={6}>
								<Typography variant="h3" className="subHead">
									Client-List Information
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<Button
									variant="outlined"
                                    color="primary"
									onClick={() => copy(clientListUrl)}
									startIcon={<AttachmentIcon/>}
								>
									Copy Client List URL
								</Button>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<label>Client-List Name</label>
								<Typography component="span" className="ml15 w100 f14 floatLeft">
									{detail.clientListName}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<label>Agent</label>
								<Typography component="span" className="ml15 w100 f14 floatLeft">
									{detail.agent && detail.agent.user && detail.agent.user.name}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<label>IMO</label>
								<Typography component="span" className="ml15 w100 f14 floatLeft">
									{detail.agent && detail.agent.imo && detail.agent.imo.groupName}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<label>Date Created</label>
								<Typography component="span" className="ml15 w100 f14 floatLeft">
									{dateUtils.asDbDateFormat(detail.createdAt)}
								</Typography>
							</Grid>
						</Grid>

					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
