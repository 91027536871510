import React from "react";
import {
    TableCell,
    Button, TextField, Grid, Typography, DialogContent, Dialog
} from "@material-ui/core";
import {AccountStatusArr} from "../model";
import WarningIcon from '@material-ui/icons/Warning';
import * as yup from "yup";
import {Formik} from "formik";
import * as AccountActions from "../actions/account";
import {useActions} from "../actions";

const GetInviteStatusAllParticipants = (props: any) => {
    const {account, role} = props;
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const accountActions = useActions(AccountActions);

    const showDialog = (account:any) => {
        return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"xs"}
                    className="customModal"
                    open={openDialog}
                    onClose={()=>setOpenDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <Formik
                        initialValues={{ email: "" }}
                        enableReinitialize
                        onSubmit={async (values: any, actions: any) => {
                            await new Promise(resolve => setTimeout(resolve, 500));
                            accountActions.bouncedEmailResendInvitation(account.userId, values.email, true);

                            actions.setSubmitting(false);
                            actions.setValues({ email: "" });
                            setOpenDialog(false);
                            setTimeout( () => {
                                props.refreshTable();
                            }, 500 );
                        }}
                        validationSchema={yup.object().shape({
                            email: yup.string().email().required("Valid Email Required"),
                        })}
                >
                    {(props: any) => {
                        const {
                            values,
                            errors,
                            isSubmitting,
                            dirty,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        } = props;
                        return (
                                <form onSubmit={handleSubmit} >
                                    <div id="alert-dialog-title" className="pt20 pr15 pl15">
                                        <Typography className="floatLeft w100 f14"> <WarningIcon className="warningIcon"/><strong className="floatLeft mt15 ">This email has bounced.</strong></Typography>
                                        <Typography className="floatLeft f14"> <span className="textBlack">Please enter another email for us to send the
                                    invitation to.</span></Typography>
                                    </div>

                                    <DialogContent className="customModalContent txtCenter floatLeft w100">
                                        <div id="alert-dialog-description">
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" className="mt0 mb0"> </Typography>
                                                    <Typography variant="h5" className="mt0 mb0"> </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                            fullWidth={true}
                                                            id={'email'}
                                                            variant="outlined"
                                                            name={'email'}
                                                            placeholder={'enter new email here'}
                                                            value={values.email}
                                                            error={errors.email ? true : false}
                                                            helperText={errors.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </DialogContent>
                                    <div className="customModalFooter txtCenter pb20 pr40 pl40">
                                        <Button
                                                className="btnPrimary "
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting || !dirty}
                                        >
                                            Send
                                            new invite
                                        </Button>
                                        <Button
                                                className="btnPrimary ml10"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setOpenDialog(false) }
                                        >
                                            Cancel
                                        </Button>

                                    </div>
                                </form>
                        )}
                    }
                </Formik>
            </Dialog>
        )
    }

    return (
        <>
            {showDialog(account)}
            <TableCell padding="default">
                { [1,2,4].indexOf( role ) > -1 && account['user.inviteStatus'] === -1 ? (
                        <Button
                                className="btnSmall "
                                type="button"
                                variant="contained"
                                color={"primary"}
                                style={{background: '#F40000'}}
                                onClick={() => {
                                    setOpenDialog(true);
                                }}
                        >
                            Bounced Email
                        </Button>

                ) : (
                        (account['user.role'] === 5 || account['user.role'] === 6) && account['user.subscribe_marketing'] === 0 && account['user.subscribe_advisors'] === 0 ? 'Unsubscribed' :  AccountStatusArr[account['user.inviteStatus'] || 0]

                )}
            </TableCell>
        </>
    )
}

export default GetInviteStatusAllParticipants;