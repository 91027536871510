import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import EditIcon from "@material-ui/icons/Edit";
import { Label } from "../formInputs";
import { ErrorCommon, TextFieldCommon, DatePicker } from "../formikFormInputs";
import * as CompanyActions from "../../actions/company";
import {useActions} from "../../actions";
import {updateCompanyByKey} from "../../actions/company";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: 18,
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

let values = {
    linkExpiry: null
};

const fields = [
    {
        id: "linkExpiry",
        name: "linkExpiry",
        label: <Label label={"Set an expiration date for this group link"}/>,
        isRequired: true,
        component: DatePicker,
        disablePast: true,
        variant: "outlined",
        className: "fullWidthcustomFields",
        size: 6,
    }];


export default function SetGroupExpiryDate(props:any) {
    const {getGroupDetails, companyId, linkExpiryDate} = props;
    const companyActions = useActions(CompanyActions)
    const [open, setOpen] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState<any>(values);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let keysValidation = {};
    fields.forEach((field: any) => {
        keysValidation[field.name] = Yup.string().required("Required Field").nullable(true);
    });

    React.useEffect(()=>{
        if(!!linkExpiryDate)
        setInitialValues({linkExpiry:linkExpiryDate})
    }, [!!linkExpiryDate, open]);

    const validationSchema = Yup.object().shape(keysValidation);
    return (
            <>
                {/*<Button variant="contained" color="primary" className="floatRight" type={"button"} onClick={handleClickOpen}>
                    Set new expiration
                </Button>*/}
                <EditIcon onClick={handleClickOpen} color="primary" className="floatRight f16"/>
                <Dialog onClose={handleClose} fullWidth={true} maxWidth="sm" aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Set new expiration date
                    </DialogTitle>
                    <DialogContent dividers>
                        <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                //validate={values => validate(values)}
                                validationSchema={validationSchema}
                                validateOnChange={true}
                                onSubmit={async (values, { setSubmitting }) => {
                                    let resp:any = await companyActions.updateCompanyByKey(values, Number(companyId));
                                    if(resp){
                                        await getGroupDetails();
                                        handleClose();
                                    }
                                }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  setFieldValue,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  dirty,
                              }) => (<form onSubmit={handleSubmit}>
                                <Grid container spacing={4}>
                                    {fields.map((field, index) => {
                                        return (
                                                <Grid item xs={12} md={12} lg={12} xl={12} key={index}
                                                      className="mb15">
                                                    <TextFieldCommon
                                                            {...field}
                                                            values={values}
                                                            onChange={handleChange}
                                                    />
                                                    <ErrorCommon errors={errors} name={field.name}
                                                                 touched={touched}/>
                                                </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <Button size="small" autoFocus variant="contained" onClick={handleClose}
                                                color="secondary" className="mb20">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button size="small" className="floatRight mb20" autoFocus type="submit" variant="contained" color="primary"
                                                disabled={(isSubmitting ? true : false)}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>)}
                        </Formik>
                    </DialogContent>
                </Dialog>
            </>
    );
}
