import React from "react";
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import TextFieldCommon from "../../../../../../../components/formikFormInputs/TextFieldCommon";
import {ErrorCommon} from "../../../../../../../components/formikFormInputs";
import {Label} from "../../../../../../../components/formInputs";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {useFormikContext} from "formik";

const GetFormContent = (props) => {
    const {isFromClientDetail, enableOnChangeSubmit, isSubmitting, submitForm, fieldObj, /*setFieldValue, handleChange, handleBlur, values, errors, touched,*/ isSelfEmployed, setSaveFormContent, formType, setFormType} = props;

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        dirty
    }: any = useFormikContext();

    React.useEffect(() => {
        let deBounceFn;

        if(dirty && touched) {
            if(!!formType && typeof setFormType === 'function') {
                setFormType(formType);
            }

            const errArray: any = Object.values(errors);
            const errKeyVal = errArray.find((err) => err.toLowerCase() !== "required field");
            if (!(!!errKeyVal)) {
                deBounceFn = setTimeout(() => {
                    if (typeof setSaveFormContent === 'function') {
                        setSaveFormContent(values)
                    }
                }, 1500);
            }
        }

        return () => {
            if(deBounceFn) {
                clearTimeout(deBounceFn)
            }
        };

    }, [values, errors]);


    const enableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "block";
        }
    }
    const disableSaveButton = () => {
        let stickyFooter: any = document.getElementById("stickyFooter");
        if (stickyFooter) {
            stickyFooter.style.display = "none";
        }
    }

    return (
            <Grid container spacing={2}>
                {isSelfEmployed &&
                <Grid item xs={12} md={6} xl={4}>
                    <Typography variant="caption" className="floatLeft">
                        <Label label={"Is the Client Self Employed?"}/>
                    </Typography>
                    <ToggleButtonGroup
                            className="toggleButtonGroup"
                            size="large"
                            color={"primary"}
                            exclusive
                            id={"usCitizen"}
                            value={!!values["selfEmployed"]}
                            onChange={(e: any, val: boolean) => {
                                setFieldValue("selfEmployed", val);
                                enableSaveButton();
                                if (enableOnChangeSubmit) {
                                    if(!isFromClientDetail){
                                        submitForm();
                                    }
                                }
                            }}
                    >
                        <ToggleButton value={true} color={"primary"}
                                      className={values["selfEmployed"] ? "active" : ""}>
                            Yes
                        </ToggleButton>
                        <ToggleButton value={false}
                                      className={values["selfEmployed"] ? "" : "active"}>
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                }
                {fieldObj.map((field, index) => {
                    let customProps = {};
                    if (field.fieldType === "datpicker") {
                        customProps["field"] = {name: field.name, value: values[field.name]};
                        customProps["form"] = {setFieldValue};
                    }
                    if (field.isHide) {
                        return (<></>);
                    }
                    return (
                        <>
                        {
                            field["show"] === true &&
                                <Grid item xs={12}
                                      sm={field.size.sm === 2 ? 2 : (field.size.sm === 6 ? 6 : (field.size.sm === 12 ? 12 : (field.size.sm === 3 ? 3 : (field.size.sm === 4 ? 4 : 12))))}
                                      md={field.size.md === 2 ? 2 : (field.size.md === 6 ? 6 : (field.size.md === 12 ? 12 : (field.size.md === 3 ? 3 : (field.size.md === 4 ? 4 : 6))))}
                                      lg={field.size.lg === 2 ? 2 : (field.size.lg === 6 ? 6 : (field.size.lg === 12 ? 12 : (field.size.lg === 3 ? 3 : (field.size.lg === 4 ? 4 : 6))))}
                                      xl={field.size.xl === 2 ? 2 : (field.size.xl === 6 ? 6 : (field.size.xl === 12 ? 12 : (field.size.xl === 3 ? 3 : (field.size.xl === 4 ? 4 : 6))))}
                                      className={field.isRadio ? "" : "customFields"}
                                      key={index + field.name}>
                                    {
                                        field.isRadio ?
                                        <div className="ml35">
                                            {field.parentLabel !== "" &&
                                                <Typography variant="subtitle1" component="strong"
                                                            className="mb15 floatLeft w100">  {field.parentLabel} </Typography>}
                                            <RadioGroup className="mb0 checkGroup checkGroupEnrollment"
                                                        name={field.name}
                                                        onChange={(e) => {
                                                            enableSaveButton();
                                                            setFieldValue(field.name, e.target.value);
                                                            field.onSelectChange(e.target.value);
                                                            if (enableOnChangeSubmit) {
                                                                let stickyFooter: any = document.getElementById("stickyFooter");
                                                                if (stickyFooter) {
                                                                    stickyFooter.style.display = 'block';
                                                                }
                                                                if (!isFromClientDetail) {
                                                                    submitForm();
                                                                }
                                                            }
                                                        }}
                                                        value={values[field.name]}
                                                        style={{ display: "inline" }}>
                                                {[{ label: "Single", value: "Single" }, {
                                                    label: "Married",
                                                    value: "Married",
                                                }].map((option: any, idx: number) => (
                                                    <FormControlLabel className="mb0"
                                                                      disabled={field.disabled || false}
                                                                      value={option.value}
                                                                      control={<Radio
                                                                          className="mb0"
                                                                          color="primary" />}
                                                                      label={option.label} />
                                                ))}
                                            </RadioGroup>
                                            {touched[field.name] && errors[field.name] && (
                                                <ErrorCommon errors={errors}
                                                             name={field.name}
                                                             touched={touched} />
                                            )}
                                        </div>
                                        :
                                        <>
                                            {field.parentLabel !== "" &&
                                                <Typography variant="subtitle1" component="strong" className="mb20 floatLeft">{field.parentLabel} </Typography>}
                                            <TextFieldCommon
                                                {...field}
                                                values={values}
                                                disabled={field.disabled || false}
                                                onChange={(e: any) => {
                                                    enableSaveButton();
                                                    if (field && field.filedType && field.filedType === "string") {
                                                        setFieldValue(field.name, e.target.value);
                                                    } else {
                                                        handleChange(e);
                                                    }
                                                    if (enableOnChangeSubmit) {
                                                        let stickyFooter: any = document.getElementById("stickyFooter");
                                                        if (stickyFooter) {
                                                            stickyFooter.style.display = "block";
                                                        }
                                                        if (!isFromClientDetail) {
                                                            submitForm();
                                                        }
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                {...customProps}
                                            />
                                            <ErrorCommon errors={errors} name={field.name}
                                                         touched={touched} />
                                        </>
                                    }

                                </Grid>
                        }
                        </>
                    );
                })
                }

                <div style={{display: "none"}}>
                    <Button
                            type="submit"
                            className={"detailPageSubmitBtn"}
                            disabled={isSubmitting}
                            onClick={()=>{
                                submitForm();
                                disableSaveButton();
                            }}
                    />
                </div>
            </Grid>
    );
};

export default GetFormContent;