import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import {DeleteModel, FileUpload, Label} from "../formInputs";
import {ErrorCommon, SelectField, TextFieldCommon} from "../formikFormInputs";
import {Grid, Tooltip} from "@material-ui/core";
import {Formik} from "formik";
import {useActions} from "../../actions";
import * as AccountActions from "../../actions/account";
import * as CarrierAction from "../../actions/carrier";
import * as ClientActions from "../../actions/client";
import configureStore from "../../configureStore";
import * as Actions from "../../actions/auth";
import {Button as FormButton} from "../../components/formInputs";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {DeleteRounded} from "@material-ui/icons";
import {AccountType, LOGO_BASE_URL} from '../../constants';
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";

const { persistor } = configureStore();


const carrierListArrInitial: object[] = [];

const strategyListArrInitial: object[] = [];

type DemoProps = {
    setShowDemoSelectionPopup: Function,
    showDemoSelectionPopup: boolean,
    role: number | undefined
}
let fetchedCarrier: object[] = [];
export default function DemoModeSelectionPopup(props: DemoProps) {
    const { setShowDemoSelectionPopup, showDemoSelectionPopup, role } = props;
    const accountActions = useActions(AccountActions);
    const carrierAction = useActions(CarrierAction);
    const clientActions = useActions(ClientActions);

    const [strategyArr, setStrategyArr] = React.useState(strategyListArrInitial);
    const [carrierArr, setCarrierArr] = React.useState(carrierListArrInitial);
    const [progress, setProgress] = React.useState<boolean>(false);
    const authActions = useActions(Actions);
    const auth = useSelector((state: RootState) => state.auth);
    const [demoLogo, setDemoLogo] = React.useState<string>("");
    const [IsDeleteDemoLogo, setIsDeleteDemoLogo] = React.useState(false);


    const userId = auth && auth.user && auth.user.id;
    React.useEffect(() => {
        getCarrier();
    }, [showDemoSelectionPopup]);

    React.useEffect(() =>{
        setDemoLogo((auth && auth.user && auth.user.demoLogo) || "")
    }, [auth]);

    const getCarrier = async () => {
        setProgress(true);
        const carriers = await carrierAction.getCarrierByUserId();
        if (role && (role === 1 || role === 2 || role === AccountType.Aggregator || role === AccountType.AggregatorMember) && carriers.length > 0) {
            fetchedCarrier = [...carriers];
        }
        if (role && (role === 3 || role === 4 || role === 15) && carriers.length > 0) {
            fetchedCarrier = [...carriers];
        }
        setCarrierArr([...carrierListArrInitial, ...fetchedCarrier]);
        setProgress(false);
    };
    const handleClose = () => {
        setShowDemoSelectionPopup(false);
    };

    const intitialValues = {
        strategy: "",
        carrier: "",
    };

    const getStrategyByCarrierSelected = async (carrierId) => { // get strategies according to the carrier selected
        const strategies = await accountActions.getStrategyByUserId(carrierId);
        const cashStreamIndex = strategies.findIndex((item) => {
            if(item.strategies && typeof item.strategies === 'object') {
                return item.strategies && item.strategies.slug === 'cashstream'
            } else if (item["strategies.slug"]){
                return item["strategies.slug"] === 'cashstream'
            }
            return -1;
        });
        if (cashStreamIndex > -1) {
            strategies.splice(cashStreamIndex, 1); // exclude cashstream strategy
        }
        if (role && (role === 1 || role === 2 || role === AccountType.Aggregator || role === AccountType.AggregatorMember)) {
            setStrategyArr([...strategyListArrInitial, ...strategies]);
        }
        if (role && (role === 3 || role === 4 || role === 15)) {
            if (strategies.length > 0) {
                let agentStrategies = strategies.map((strategy) => {
                    return {
                        id: strategy["strategies.id"],
                        value: strategy["strategies.id"],
                        key: strategy["strategies.id"],
                        name: strategy["strategies.displayName"],
                    };
                });
                setStrategyArr([...strategyListArrInitial, ...agentStrategies]);
            } else {
                setStrategyArr([...strategyListArrInitial]);
            }
        }
    };

    const fieldObject: any = [{
        label: <Label label={"Select Carrier"} required={true}/>,
        id: "carrier",
        name: "carrier",
        placeholder: "Select Carrier",
        component: SelectField,
        options: carrierArr || carrierListArrInitial,
        onSelectChange: (val: number) => {
            getStrategyByCarrierSelected(val);
        },
    }, {
        label: <Label label={"ilia Product Type"} required={true}/>,
        id: "strategy",
        name: "strategy",
        placeholder: "ilia Product Type",
        component: SelectField,
        options: strategyArr || strategyListArrInitial,
        // onSelectChange: (val: number | string) => {
        // },
    }];
    const submitFormik = async (values: any, setSubmitting: Function, resetForm: Function) => {
        setProgress(true);
        let strategyId = values.strategy;
        await authActions.demoClient(strategyId, values.carrier);
        handleClose();
        persistor.flush()
                .then(response => {
                    window.location.replace("/accounts");
                })
                .catch(err => {
                    window.location.replace("/intro");
                });
    };
    const validate = ((value: any) => {
        const errors: { strategy?: string, carrier?: string } = {};
        if (!value.strategy) {
            errors.strategy = "Required";
        }
        if (!value.carrier) {
            errors.carrier = "Required";
        }
        if (!value.carrier && (value.strategy !== "" || value.strategy !== 0)) {
            errors.strategy = "Please Select Carrier First";
        }
        return errors;
    });
    const styles: any = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        margin: {
            margin: theme.spacing(1),
        },
    });
    const DialogTitle = withStyles(styles)((props:any) => {
        const { children, classes, onClose, ...other } = props;
        return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                                <CloseIcon/>
                            </IconButton>
                    ) : null}
                </MuiDialogTitle>
        );
    });

    const updateDemoLogo = async (publicUrl: any, fileName: string) => {
        setDemoLogo(fileName);
        let payload: any = {
            demoLogo: fileName
        }
        await clientActions.updateUserTableByKeyValPair(payload, userId, true, 'Updated Successfully.', 200);
    };

    const deleteDemoLogo = async () => {
        setDemoLogo("");
        setIsDeleteDemoLogo(false);
        let payload: any = {
            demoLogo: null
        }
        await clientActions.updateUserTableByKeyValPair(payload, userId, true, 'Updated Successfully.', 200);
    }

    return (

            <React.Fragment>
                {progress ?
                        <CircularProgress/>
                        :
                        <Dialog
                                fullWidth={true}
                                maxWidth={"sm"}
                                open={showDemoSelectionPopup}
                                onClose={handleClose}
                                aria-labelledby="max-width-dialog-title"
                        >
                            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                Select Option
                            </DialogTitle>
                            <DialogContent dividers>
                                <DialogContentText>
                                    <Typography>You can set ilia Product Type and carrier for demo mode view.</Typography>
                                </DialogContentText>
                                <Formik
                                        initialValues={intitialValues}
                                        validate={values => validate(values)}
                                        onSubmit={(values, {
                                            setSubmitting,
                                            setErrors,
                                            setStatus,
                                            resetForm,
                                        }) => submitFormik(values, setSubmitting, resetForm)}
                                >

                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          dirty,
                                          setFieldValue,
                                      }) =>
                                            (<form onSubmit={handleSubmit}>
                                                <Grid container spacing={2}>
                                                    {
                                                        fieldObject.map((field, index) => {
                                                            return (
                                                                    <Grid item xs={12} sm={6} md={6}
                                                                          key={index}>
                                                                        <TextFieldCommon
                                                                                {...field}
                                                                                values={values}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                onClose={() => {
                                                                                    if (field.name === "carrier") {
                                                                                        setFieldValue("strategy", "");
                                                                                    }
                                                                                }}
                                                                                options={field.options}
                                                                        />
                                                                        <ErrorCommon errors={errors}
                                                                                     name={field.name}
                                                                                     touched={touched}/>
                                                                    </Grid>
                                                            );
                                                        })
                                                    }
                                                    {(role && (role === 1 || role === 2)) &&
                                                    <React.Fragment>
                                                        {demoLogo === "" &&
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" component="strong"
                                                                        className="colorBlue mb10 floatLeft">
                                                                Client Logo
                                                            </Typography>

                                                            <FileUpload
                                                                    dirName={userId}
                                                                    dirType={'documents'}
                                                                    fileType='image/jpg, image/jpeg, image/png'
                                                                    onFinish={(publicUrl: string, name: string) => updateDemoLogo(publicUrl, name)}
                                                            />
                                                        </Grid>
                                                        }
                                                        <Grid item xs={12}>
                                                            {
                                                                demoLogo &&
                                                                <>
                                                                    <Typography component="strong" variant="subtitle1"
                                                                                className="w100 colorBlue mb10 floatLeft">
                                                                        Client Logo
                                                                        <Tooltip title="Delete" className="floatRight " arrow>
                                                                            <IconButton
                                                                                    key="delete"
                                                                                    aria-label="delete"
                                                                                    color="inherit"
                                                                                    className="pt0 pb0"
                                                                                    onClick={() => setIsDeleteDemoLogo(true)}
                                                                            >
                                                                                <DeleteRounded/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Typography>
                                                                    <img alt="" className="floatLeft"
                                                                         src={LOGO_BASE_URL + `${demoLogo}?${Date.now()}`}/>
                                                                </>
                                                            }
                                                        </Grid>
                                                    </React.Fragment>
                                                    }
                                                    <Grid item xs={12}>
                                                        <FormButton className=" floatRight mb10"
                                                                    type="submit"
                                                                    variant="contained"
                                                                    disabled={!dirty || isSubmitting}
                                                                    loading={isSubmitting}
                                                                    label="Start Demo"
                                                                    color="primary"/>
                                                    </Grid>
                                                </Grid>
                                            </form>)
                                    }
                                </Formik>
                            </DialogContent>
                        </Dialog>
                }
                {
                    IsDeleteDemoLogo &&
                    <DeleteModel
                            open={true}
                            handleClose={() => setIsDeleteDemoLogo(false)}
                            handleYes={deleteDemoLogo}
                            accountType="image"
                    />
                }
            </React.Fragment>

    );
}
