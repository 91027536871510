import { Action, ActionType } from "../model";
import {
    LeadStats
} from "../model/lead";
import createReducer from "./createReducer";

export const leadStats = createReducer({}, {
    [ActionType.LEADSTATS](state: LeadStats, action: Action<LeadStats>) {
        return action.payload;
    }
});
