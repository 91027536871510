import React from 'react';
import {useActions} from "../../../../actions";
import * as AccountActions from "../../../../actions/account";
import {RouteComponentProps} from "react-router-dom";
import LandingPage from "../v2/LandingPage";
import TrizenLandingPage from "../v2/trizen/LandingPage";
import CommonLoader from "../../../../components/CommonLoader";

interface Props extends RouteComponentProps<void> {}

type landingPageProps = {
    accountType:string,
    verifiedInvite: boolean | undefined;
    checked: boolean,
    updated: boolean,
    firstName: string,
    phoneNumber: string,
    email: string,
    role: number | undefined;
    strategy: any | undefined;
    agent:any,
    client:any,
    authenticType:string,
    companyId : number,
    groupDetail:any,
    carrier: any,
    nationalLife?: any
}

export default function ClientListInvitationPage(props: Props) {
    const params: any = props.match.params;
    const [loading, setLoading] = React.useState(true);
    const [landingpageData, setLandingpageData] = React.useState( { accountType:'GROUP', agent : {imo:{ reBrand : 0, reBrandStrategy : '' }}, strategy:{id:0,slug:'', reBrand:0}  } );
    const accountActions = useActions(AccountActions);

    const getClientList = async () => {
        const response = await accountActions.getClientList(params.id);
        const resp:any = response.data;
        let landingPageProps : landingPageProps = {
            accountType : 'CLIENTLIST',
            verifiedInvite: false,
            checked: false,
            updated: false,
            firstName: '',
            phoneNumber: '',
            email: '',
            role: 5,
            strategy: resp && resp.strategies ? resp.strategies : null,
            client: null,
            agent: resp && resp.agent ? resp.agent : null,
            authenticType: (resp && resp.authenticType) || '',
            companyId: (resp && resp.id) || 0,
            groupDetail: resp,
            carrier : resp && resp.carrier,
            nationalLife : (response && response.nationalLife) || null
        }
        setLandingpageData(landingPageProps);
        setLoading( false );
    }
    React.useEffect( () => {
        getClientList();
    }, []);

    if( loading ){
        return (
                <CommonLoader />
        );
    }

    let isStrategyESDTrizen:boolean = landingpageData && landingpageData.strategy && (landingpageData.strategy.slug === 'trizen' || landingpageData.strategy.slug === 'esd') ? true : false;

    if(isStrategyESDTrizen ){
        return (
                <TrizenLandingPage {...props} {...landingpageData} />
        );
    }

    return (
        <LandingPage {...props} {...landingpageData} />
    );


}