import * as React from "react";
import {createStyles, makeStyles} from "@material-ui/styles";
import {FormHelperText, Grid} from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        error: {
            color: "#FF1744",
            fontSize:"12px"
        },
    }),
);

const Error = (props: any) => {
    const classes = useStyles();
    const { name, errors, touched, isMultiLevel } = props;

    if (isMultiLevel) {
        return (
                <FormHelperText style={{ margin: "0", fontSize: "10px" }}>
                    <span className={classes.error}> {errors} </span>
                </FormHelperText>
        );
    }

    return (
        <>
           {
                   errors[name] && touched[name] && errors[name] &&
                   <FormHelperText  style={{margin: '0', fontSize: '10px'}}>
                       <span className={classes.error}> {errors[name] } </span>
                   </FormHelperText>
           }
        </>
    )
};

type ErrorHelperProps = {errors: any, name: string};

export const ErrorHelper = ({ errors, name }: ErrorHelperProps) => {
    const errorHelperStyle: React.CSSProperties = { color: "#FF1744", fontSize: "10px", margin: "0" };
    const hasError = (errors && errors[name]);
    const accessArray = name.split(".");
    const hasObjectError = hasError && typeof hasError === 'object' && accessArray.length > 1;
    const showError = (hasError && errors[name].length) > 0;
    let errorMessage = hasError || (hasError && errors[name].value) || (hasError && errors[name].desc);
    if (hasObjectError) {
        errorMessage = accessArray.reduce((acc, key) => (acc[key] || ""), errorMessage);
    }

    return (
            <>
                {
                        showError &&
                        <Grid item xs={12}>
                            <FormHelperText style={errorHelperStyle}>
                                <span className="w100 floatLeft">{errorMessage}</span>
                            </FormHelperText>
                        </Grid>
                }
            </>
    );
};

export default Error;