import React, { useEffect } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { EnrollmentFileUploader as FileUploader } from "../../../../../components/formInputs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import { ccFirst, lowerCase } from "../../../../../utils/commonUtils";
import { ClientDocumentType } from "../../../../../constants";
import moment from "moment";

const UploadDocuments = (props: { document?: any, clientDetail?: any, setUploadMoreDocs: Function, handleRefresh: Function, handleClose?: Function }) => {
    const { setUploadMoreDocs, handleRefresh, document, handleClose } = props;
    const accountActions = useActions(Actions);
    const userData = useSelector((state: RootState) => state.profile);
    const user = props.clientDetail || userData;
    const { client, enrollment } = user;
    const userId: string = client && client.userId || "";
    const enrollmentId: string = enrollment && enrollment.id || "";
    const [documentName, setDocumentName] = React.useState("");
    const [exactFileName, setExactFileName] = React.useState("");
    useEffect(() => {
        accountActions.getProfileDetails();
    }, []);

    useEffect(() => {
        if (document) { //document means admin is overwriting the document.
            setDocumentName(document.name);
            switch (document.documentType) {
                case ClientDocumentType.TAX_DOCUMENT :
                    (document.name === "TAX DOCUMENT YEAR 1") ?
                            setExactFileName(`Tax-Document-Year-1-${moment().format("DD-MM-YYYY HH:mm:ss")}`)
                            :
                            setExactFileName(`Tax-Document-Year-2-${moment().format("DD-MM-YYYY HH:mm:ss")}`);
                    break;
                case ClientDocumentType.IDENTITY_DOCUMENT :
                    (lowerCase(document.frontOrBack) === "front") ?
                            setExactFileName(client.identityType === "drivingLicence" ? "Driver License Front" : "Passport Front") :
                            setExactFileName(client.identityType === "drivingLicence" ? "Driver License Back" : "Passport Back");

                    break;
                case ClientDocumentType.RESIDENCE_DOCUMENT :
                    (lowerCase(document.frontOrBack) === "front") ?
                            setExactFileName("Residency Card Front") : setExactFileName("Residency Card Back");
                    break;
                case ClientDocumentType.UTILITY_BILL :
                    setExactFileName("Utility Bill");
                    break;
                case ClientDocumentType.MORE_DOCUMENTS:
                    setExactFileName("Other Document");
                    break;

            }
        }
    }, [document]);


    const closeConfirmDialog = () => {
        if (document && typeof handleClose === "function") { //document means admin is overwriting the document.
            handleClose();
        }
    };


    const updateDocument = async (value: string, field: string) => {
        let payload: { formType?: string, additionalDocumentName?: string } = {
            formType: "documents",
            additionalDocumentName: documentName,
        };
        if (document) { //document means admin is overwriting the document. and not the case of additional document upload
            delete payload.additionalDocumentName;
        }
        if (document && (field === ClientDocumentType.IDENTITY_DOCUMENT || field === ClientDocumentType.TAX_DOCUMENT
                || field === ClientDocumentType.RESIDENCE_DOCUMENT || field === ClientDocumentType.UTILITY_BILL
                || field === ClientDocumentType.MORE_DOCUMENTS || field === ClientDocumentType.ADMIN_PROPOSAL
                || field === ClientDocumentType.ADDITIONAL_DOCUMENTS || ClientDocumentType.COVER_LETTER || field === ClientDocumentType.CARRIER_ILLUSTRATION_INPUTS || ClientDocumentType.TAX_DOCUMENT_INCOME_EARNER

        )) { // document uploading from document tab through upload icon in action row of the documents table
            switch (field) {
                case ClientDocumentType.IDENTITY_DOCUMENT :
                    payload["documentSubType"] = lowerCase(document.frontOrBack);
                    payload["identityFrontPhoto"] = lowerCase(document.frontOrBack) === "front" ? value : "";
                    payload["identityBackPhoto"] = lowerCase(document.frontOrBack) === "back" ? value : "";
                    payload["newUploaded"] = lowerCase(document.frontOrBack) === "front"
                            ? document.approvalStatusFront ? true : false
                            : document.approvalStatusBack ? true : false;
                    break;
                case ClientDocumentType.RESIDENCE_DOCUMENT :
                    payload["documentSubType"] = lowerCase(document.frontOrBack);
                    payload["residenceFrontPhoto"] = lowerCase(document.frontOrBack) === "front" ? value : "";
                    payload["residenceBackPhoto"] = lowerCase(document.frontOrBack) === "back" ? value : "";
                    payload["newUploaded"] = lowerCase(document.frontOrBack) === "front"
                            ? document.approvalStatusFront ? true : false
                            : document.approvalStatusBack ? true : false;
                    break;
                case ClientDocumentType.TAX_DOCUMENT :
                    payload["documentSubType"] = document.name === "TAX DOCUMENT YEAR 1" ? "YEAR_ONE" : "YEAR_TWO";
                    payload["taxDocumentYear1"] = document.name === "TAX DOCUMENT YEAR 1" ? value : "";
                    payload["taxDocumentYear2"] = document.name === "TAX DOCUMENT YEAR 2" ? value : "";
                    payload["newUploaded"] = document.approvalStatusFront ? true : false;
                    break;
                case ClientDocumentType.UTILITY_BILL :
                    payload["utilityBillPhoto"] = value;
                    payload["newUploaded"] = document.approvalStatusFront ? true : false;
                    break;
                case ClientDocumentType.MORE_DOCUMENTS:
                    payload["otherDocument"] = value;
                    payload["otherDocName"] = documentName;
                    payload["newUploaded"] = document.approvalStatusFront ? true : false;
                    break;
                case ClientDocumentType.ADDITIONAL_DOCUMENTS:
                    payload["additionalDocument"] = value;
                    payload["newUploaded"] = document.approvalStatusFront ? true : false;
                    break;
                case ClientDocumentType.COVER_LETTER:
                    payload["coverLetter"] = value;
                    payload["newUploaded"] = document.approvalStatusFront ? true : false;
                    break;
                case ClientDocumentType.CARRIER_ILLUSTRATION_INPUTS:
                    payload["carrierIllusrationInputs"] = value;
                    payload["newUploaded"] = document.approvalStatusFront ? true : false;
                    break;
                case ClientDocumentType.TAX_DOCUMENT_INCOME_EARNER :
                    payload["documentSubType"] = document.name === "TAX DOCUMENT INCOME EARNER YEAR 1" ? "YEAR_ONE" : "YEAR_TWO";
                    payload["taxDocumentIncomeEarnerYear1"] = document.name === "TAX DOCUMENT INCOME EARNER YEAR 1" ? value : "";
                    payload["taxDocumentIncomeEarnerYear2"] = document.name === "TAX DOCUMENT INCOME EARNER YEAR 2" ? value : "";
                    payload["newUploaded"] = document.approvalStatusFront ? true : false;
                    break;

                case ClientDocumentType.GWT_COVER_LETTER:
                case ClientDocumentType.GWT_DISCLOSURE_LETTER:
                case ClientDocumentType.GWT_DRIVERS_LICENSE:
                case ClientDocumentType.GWT_DRIVERS_LICENSE_BACK:
                case ClientDocumentType.GWT_TAX_DOCUMENT_YEAR_ONE:
                case ClientDocumentType.GWT_TAX_DOCUMENT_YEAR_TWO:
                    break;

                case ClientDocumentType.ADMIN_PROPOSAL:
                    return false;
                    break;
            }
        }
        payload["name"] = documentName;
        payload["docName"] = documentName;
        payload["documentType"] = field;
        payload["operation"] = "Uploaded";
        payload["path"] = value;
        if (userId) {
            try {
                console.log("payload -=-=-=-=-", payload);
                await accountActions.updateUserBasicDetails(payload, userId);
            } catch (err) {
                console.log("err", err);
                return false;
            }
        }
    };

    const handleChange = (event) => {
        setDocumentName(event.target.value);
    };

    const fileType: string = "image/jpg, image/jpeg, image/png, application/pdf";

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                    fullWidth={true}
                                    id={"docs"}
                                    variant="outlined"
                                    name={"docs"}
                                    placeholder={document ? document.name : "Document Name"}
                                    onChange={handleChange}
                                    disabled={document ? true : false}
                            />
                        </Grid>
                        {
                            documentName &&
                                <div className="content mt20 ">
                                    <Typography variant="h6"
                                                className="mb5">{document ? `Upload:- ${ccFirst(document.name)}` : "Upload Additional Document"}</Typography>
                                    <div className="fileUpload">
                                        <FileUploader
                                                exactFileName={exactFileName}
                                                dirName={enrollmentId}
                                                dirType={"documents"}
                                                fileType={fileType}
                                                content={document ? `Upload:- ${ccFirst(document.name)}` : "Upload More Documents"}
                                                width={15000}
                                                height={15000}
                                                onFinish={async (publicUrl: string, name: string) => {
                                                    console.log("publicUrl", publicUrl, name);
                                                    await updateDocument(name, document ? document.documentType : "ADDITIONAL_DOCUMENTS");
                                                    closeConfirmDialog();
                                                    setUploadMoreDocs(false);
                                                    setTimeout(() => handleRefresh(), 500);
                                                }}
                                        />
                                    </div>
                                </div>
                        }
                    </Grid>
                </Grid>
            </>
    );
};

export default UploadDocuments;