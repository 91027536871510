import * as React from "react";
import qs from "query-string";
import {
    Grid,
    Typography,
    Link,
    CircularProgress,
    TextField,
} from "@material-ui/core";
import { Button, Label } from "../../../../../components/formInputs";
import MiTextField from "@material-ui/core/TextField";
import { useActions } from "../../../../../actions";
import * as VerifyActions from "../../../../../actions/verify";
import {
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_SPECIAL_CHAR,
} from "../../../../../constants";
import PasswordRequired from "./PasswordRequired";
import LandingContactForm from "./LandingContactForm";
import SystemError from "../../../../../components/client/landingPage/v2/SystemError";
const AuthenticationForm = (props: any) => {
    const inviteToken: string =  props.match &&  props.match.params &&  props.match.params.inviteToken || '';
    const [lastName, setLastName] = React.useState<string>("");
    const [lastNameError, setLastNameError] = React.useState("");
    const [email, setEmail] = React.useState(props.email);
    const [emailError, setEmailError] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [newPasswordError, setNewPasswordError] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
    const verifyActions = useActions(VerifyActions);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [showPasswordField, setShowPasswordField] = React.useState( [props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL].indexOf(props.accountType) > -1 );

    const [oneLetter, setOneLetter] = React.useState(false);
    const [showPasswordRequirementSection, setShowPasswordRequirementSection] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const [systemError, setSystemError] = React.useState<boolean>(false);
    const [accountExist, setAccountExist] = React.useState<boolean>(false);
    let searchParams:string = props && props.location.search;
    const strategy: string = props.strategyData.name;
    let companyName:string = props.groupDetail ? props.groupDetail.companyName : 'Group'
    if( props.accountType === props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST ){
        companyName = props.groupDetail.clientListName || '';
    }

    const onPassWordChange = (e: any): void => {
        const val = e.target.value;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
        setNewPassword(e.target.value);

        val ?
                ((!PWD_ONE_LETTER_REGEX.test(val) || !PWD_ONE_CAPITAL_LETTER_REGEX.test(val) || !PWD_ONE_NUMBER_REGEX.test(val) || !PWD_MIN_EIGHT_CHAR_REGEX.test(val) || !PWD_ONE_SPECIAL_CHAR.test(val)) ? setNewPasswordError("Enter valid password") : setNewPasswordError(""))
                :
                setNewPasswordError("Required");
    };

    const onConfirmPasswordChange = (e: any): void => {
        const val = e.target.value;
        setConfirmPassword(val);
        val ? val === newPassword ? setConfirmPasswordError("") : setConfirmPasswordError("Password mismatched")
                : setConfirmPasswordError("Required");
    };

    React.useEffect(() => {
        let search = window.location.search;
        const searchParams = qs.parse(search);
        if(searchParams && searchParams.email && searchParams.lastname && Object.keys(searchParams).length > 0){
            setShowPasswordField(true);
            setEmail(searchParams.email);
            setLastName(searchParams.lastname as string);
        }
        window.scrollTo(0, 0);
    }, []);

    const submitForm = async () => {
        let error: boolean = false;

        if (!lastName) {
            error = true;
            setLastNameError("Required");
        }
        if (!email) {
            error = true;
            setEmailError("Required");
        }

        if (error) {
            return false;
        }

        setLoading(true);
        let result: any = await verifyActions.checkNewInvitationAuth({
            authType: props.authenticType,
            accountType: props.accountType,
            lastName,
            email,
            companyId: props.companyId,
            clientListId: props.companyId,
        });

        setLoading(false);
        if (result && result.payload && result.payload.statusCode >= 400) {
            if (result.payload.message === "Account is already register") {
                setAccountExist(true);
            } else {
                setAccountExist(false);
            }
            setSystemError(true);
            return false;
        }
        if (result && result.payload) {
            setShowPasswordField(true);
            setSystemError(false);
            props.history.push({
                pathname: window.location.pathname,
                search: "?landing=1&email="+email+"&lastname="+lastName
            });
        }
    }

    const createPassword = async () => {
        let error: boolean = false;

        if (!newPassword) {
            error = true;
            setNewPasswordError("Required");
        }

        if (!confirmPassword) {
            error = true;
            setConfirmPasswordError("Required");
        }

        if(newPassword && confirmPassword && (newPassword !== confirmPassword)){
            error = true;
            setConfirmPasswordError("Password mismatched");
        }

        if (error) {
            return false;
        }

        if (
                oneLetter && oneNumber && oneCapitalLetter && eightCharacter && oneSpecialChar
        ) {
            setLoading(true);

            let result: any;
            switch (props.accountType) {
                case props.ALLOWED_ACCOUNT_TYPE.GROUP :
                    result = await verifyActions.checkAuthAndCreatePassword({
                        password: newPassword,
                        confirmPassword: newPassword,
                        authType: props.authenticType,
                        accountType: props.accountType,
                        lastName: lastName || searchParams && qs.parse(window.location.search).lastname,
                        email: email || searchParams && qs.parse(window.location.search).email,
                        companyId: props.companyId,
                        groupDetail: props.groupDetail,
                    });
                    break;
                case props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST :
                    result = await verifyActions.checkClientListAuthAndCreatePassword({
                        password: newPassword,
                        confirmPassword: newPassword,
                        lastName: lastName || searchParams && qs.parse(window.location.search).lastname,
                        email: email || searchParams && qs.parse(window.location.search).email,
                        clientListId: props.companyId,
                        clientListDetail: props.groupDetail,
                    });
                    break;
                default :
                    result = await verifyActions.createPassword({
                        password: newPassword,
                        confirmPassword: newPassword,
                        inviteToken: inviteToken,
                    });

            }

            if (result && result.payload && result.payload.statusCode >= 400) {
                setLoading(false);
                return false;
            }
            if (result && result.payload) {
                setTimeout(()=>{
                    window.location.href = '/dashboard';
                },0);
            }
        }
    };

    const validateEmail = (email: string) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    let redirectUrl:string = props && props.match && props.match.url;
    let encodeUrl:string = "";
    let url:string = "";
    encodeUrl = btoa(redirectUrl);
    if(searchParams) {
        encodeUrl = btoa(redirectUrl + searchParams);
    }
    if(redirectUrl) {
        url = `/login?token=${encodeUrl}`;
    }

    return (
            <>
                    <Typography variant="subtitle2" color="primary" className="floatLeft w100 mb15 textCenter">{showPasswordField ? <>Thanks! Now Create a Password</>:''}</Typography>
                    <Typography variant="h3" color="primary" className="floatLeft w100 mb0">Get Started</Typography>
                    {props.accountType === props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL ?
                            <Typography variant="body1" className="mb15 floatLeft w100">
                                Create a password to access your <br/> {strategy} details.
                            </Typography>
                            :
                            (
                                    showPasswordField ?
                                        <Typography  variant="body1" className="floatLeft w100 mb20">
                                            Create a password to access your {strategy} details.
                                        </Typography>
                                    :
                                        props.accountType === props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST ?
                                            <Typography variant="body1" className="floatLeft w100 mb20">
                                             Please enter your information below to see how it works.
                                            </Typography>
                                        :
                                            <Typography variant="body1" className="floatLeft w100 mb20">Verify you are a member
                                                of {companyName} to
                                                see how it
                                                works.
                                            </Typography>
                            )

                    }

                    <Grid container spacing={2} justify="center">
                        {!showPasswordField && [props.ALLOWED_ACCOUNT_TYPE.GROUP, props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST].indexOf(props.accountType) > -1 &&
                        <Grid item xs={12}>
                            <MiTextField
                                    id="outlined-number01"
                                    label={<span> Last Name </span>}
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    name="lastName"
                                    onFocus={(e) => {
                                        lastName ? setLastNameError("") : setLastNameError("Required");
                                    }}
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                        e.target.value ? setLastNameError("") : setLastNameError("Required");
                                    }}
                                    error={!!lastNameError}
                                    helperText={lastNameError ? "Required" : ""}
                            />
                        </Grid>
                        }
                        {((!showPasswordField && [props.ALLOWED_ACCOUNT_TYPE.GROUP, props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST].indexOf(props.accountType) > -1) || props.accountType === props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL) &&
                            <Grid item xs={12}>
                                <MiTextField
                                        label={[props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL].indexOf(props.accountType) > -1 ?
                                            <span style={{ color: "black" }}> Your email </span> : "Email"}
                                        disabled={[props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL].indexOf(props.accountType) > -1 ? true : false}
                                        id="outlined-basic"
                                        variant="outlined"
                                        fullWidth
                                        color="primary"
                                        type="email"
                                        name="email"
                                        defaultValue={email || searchParams && qs.parse(window.location.search).email}
                                        onFocus={(e) => {
                                            setSystemError(false);
                                            email ? setEmailError("") : setEmailError("Required");
                                        }}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            e.target.value ?
                                                    (!validateEmail(e.target.value) ? setEmailError("Please enter valid email") : setEmailError(""))
                                                    :
                                                    setEmailError("Required");
                                        }}
                                        error={emailError ? true : (email ? !validateEmail(email) : false)}
                                        helperText={emailError}
                                />
                            </Grid>
                        }

                        {showPasswordField &&
                        <>
                            <Grid item xs={12}>
                                <TextField
                                        id="outlined-number2-password"
                                        label={<Label label={"Create a password"} required={true}/>}
                                        fullWidth
                                        type={"password"}
                                        color="primary"
                                        variant="outlined"
                                        onFocus={(e) => {
                                            newPassword ? setNewPasswordError("") : setNewPasswordError("Required");
                                            setShowPasswordRequirementSection(true);
                                        }}
                                        onChange={(e) => {
                                            onPassWordChange(e);
                                        }}
                                        error={!!newPasswordError}
                                        helperText={newPasswordError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                        id="outlined-number2-password"
                                        label={<Label label={"Confirm Your Password"} required={true}/>}
                                        fullWidth
                                        type={"password"}
                                        color="primary"
                                        variant="outlined"
                                        onFocus={(e) => {
                                        }}
                                        onChange={(e) => {
                                            onConfirmPasswordChange(e);
                                        }}
                                        error={!!confirmPasswordError}
                                        helperText={confirmPasswordError}
                                />
                            </Grid>
                        </>
                        }
                        { [props.ALLOWED_ACCOUNT_TYPE.GROUP, props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST].indexOf(props.accountType) > -1 &&
                        systemError && <SystemError accountType={props.accountType} groupDetails={props.groupDetail} accountExist={accountExist}/>
                        }
                        <Grid item xs={12} className="textCenter mt20">
                            <Button
                                    size="medium"
                                    label={loading ?
                                            <CircularProgress size={18}/> : showPasswordField ? props.accountType === props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL ? "Access Plan Details" : 'Access Portal' : "Continue"}
                                    type="submit"
                                    onClick={() => {
                                        if( showPasswordField ){
                                            createPassword();
                                            return false;
                                        }
                                        submitForm();
                                    }}
                                    disabled={loading}
                                    className="customBtn glow"
                                    variant="contained"
                                    color="primary"
                                    children={<div className="arrowAnimation"/>}
                            />
                        </Grid>
                        <Grid item xs={12} className="textCenter">
                            <Typography variant="caption" align="center">
                                Already have a account? <Link color="primary"
                                                              onClick={() => window.location.href = url}>
                                Log in</Link>. If you need assistance please <Link color="primary"
                                                                                   onClick={() => setOpen(true)}>
                                contact us</Link>.
                            </Typography>
                        </Grid>
                        {showPasswordRequirementSection &&
                        <PasswordRequired oneLetter={oneLetter} oneNumber={oneNumber}
                                          oneCapitalLetter={oneCapitalLetter}
                                          eightCharacter={eightCharacter}
                                          oneSpecialChar={oneSpecialChar}/>
                        }
                    </Grid>
                <LandingContactForm setOpen={(isOpen: boolean) => setOpen(isOpen)} open={open} {...props}/>
            </>
    );
};

export default AuthenticationForm;
