import React, { useEffect } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import licensePassport from "../../images/svg/license-passport-upload.svg";
import utilityBill from "../../images/svg/utility-bill.svg";
import taxDocuments from "../../images/svg/tax-documents.svg";
import visaGreencard from "../../images/svg/visa-greencard.svg";
import InfoIcon from "@material-ui/icons/Info";
import { EnrollmentFileUploader as FileUploader, Label } from "../../../../../components/formInputs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import {ATLANTIC_HEALTH, ClientDocumentType, DocNameAndType} from "../../../../../constants";
import ViewAndDelete from "./ViewAndDelete";
import * as Aws from "../../../../../actions/aws";
import moment from 'moment';
import OtherDocument from "../../component/OtherDocument";
import {checkIsDocUploaded} from "../../../../../utils/commonUtils";
import SelectDocTypeField from "./SelectDocTypeField";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";


const UtilityBill = (props: {isDocumentAddress: boolean | undefined, isFromClientDetail: boolean | undefined, utilityBillPrevPhoto: string,
    setOnProgress: Function, directory: string, fileType: string, updateDocument: any, setUtilityBillPrevPhoto: Function,
    checkValidation: Function, onProgress: boolean, clientSigned: boolean, userId: string, setIdentityFrontPrevPhoto: Function,
    setResidenceBackPrevPhoto: Function, setTaxDocumentYearOne: Function, setIdentityBackPrevPhoto: Function, setTaxDocumentYearTwo: Function,
    setResidenceFrontPrevPhoto: Function, utilityBillDocSubType: string, setUtilityBillDocSubType: Function, client: any, docusignStatus: number
}) =>{
    const {
        isDocumentAddress,
        isFromClientDetail,
        checkValidation,
        utilityBillPrevPhoto,
        setOnProgress,
        onProgress,
        directory,
        fileType,
        updateDocument,
        setUtilityBillPrevPhoto,
        clientSigned,
        userId,
        setIdentityFrontPrevPhoto,
        setResidenceBackPrevPhoto,
        setTaxDocumentYearOne,
        setTaxDocumentYearTwo,
        setIdentityBackPrevPhoto,
        setResidenceFrontPrevPhoto,
        setUtilityBillDocSubType, utilityBillDocSubType, client, docusignStatus
    } = props;
    const utilityDocDropDown = ["Water Bill", "Gas Bill", "Electric Bill", "Bank Statement", "Notarized Bill"];
    if (typeof isDocumentAddress !== "undefined" && isDocumentAddress && isFromClientDetail) {
        return null;
    }
    return <Grid item xs={12}>
        <div className="DocumentsWrap mt40">
            {!isFromClientDetail &&
                    <div>
                        <img src={utilityBill} alt="#" className="floatLeft"/>
                    </div>
            }
            <div className="content">
                <Typography variant="subtitle1" component="strong"><Label label={"Recent Utility Bill"}
                                                                          required={checkValidation() ? true : false}/></Typography>
                <Typography>{client.identityType === 'passport' ? "(Using a passport as ID requires a utility bill for address verification.)"
                        : !client.isDocumentAddress ? "(Your drivers license address does not match your home address please upload a recent utility to verify your address.)" : ""
                }</Typography>
                <Typography variant="body1">A water, electric or gas bill or a bank statement (less than 60 days old)
                    with the insured name and address (for address verification). If we need anything additional, we
                    will contact you.
                </Typography>
                <SelectDocTypeField label={"Select Doc Type for Address Verification"}
                                    handleChange={async (e) => {
                                        if (e && e.target && e.target.value) {
                                            setUtilityBillDocSubType(e.target.value);
                                        }
                                        await updateDocument("utilityBillPhoto", "", ClientDocumentType.UTILITY_BILL, "" , e.target.value, true); // update docSubType
                                    }} items={utilityDocDropDown} value={utilityBillDocSubType} name="utilityBill" clientSigned={clientSigned}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={utilityBillPrevPhoto ? 3 : 6}
                          lg={utilityBillPrevPhoto ? 3 : 6} className="textLeft">
                        {!utilityBillPrevPhoto && utilityBillDocSubType &&
                                <div className="fileUpload mt15">
                                    <FileUploader
                                            setOnProgress={setOnProgress}
                                            onProgress={onProgress}
                                            exactFileName={'Utility Bill'}
                                            dirName={directory}
                                            dirType={"documents"}
                                            fileType={fileType}
                                            content={`Upload ${utilityBillDocSubType}`}
                                            width={15000}
                                            height={15000}
                                            onFinish={async (publicUrl: string, name: string) => {
                                                await updateDocument("utilityBillPhoto", name, ClientDocumentType.UTILITY_BILL, "" , utilityBillDocSubType);
                                                setUtilityBillPrevPhoto(name);
                                                setOnProgress(false);
                                            }}
                                    />
                                </div>
                        }
                        {
                            (utilityBillPrevPhoto
                                    &&
                                    <ViewAndDelete
                                            identityPhoto={utilityBillPrevPhoto}
                                            clientSigned={clientSigned}
                                            title="Identity License"
                                            action={
                                                {
                                                    key: "utilityBillPhoto",
                                                    type: ClientDocumentType.UTILITY_BILL,
                                                    side: "",
                                                }
                                            }
                                            userId={userId}
                                            docusignStatus={docusignStatus}
                                            directory={directory}
                                            setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                            setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                            setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                            setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                            setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                            setTaxDocumentYearOne={setTaxDocumentYearOne}
                                            setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                    />
                            )
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    </Grid>
}

const UploadDocumentsForm = (props: { setNextStep: Function, setPrevStep: Function, isFromClientDetail?: boolean, clientDetail?: any, isFromEnrollmentHome?: boolean,
    isDocumentAddress?: boolean
}) => {
    const accountActions = useActions(Actions);
    const awsActions = useActions(Aws);
    let userData = useSelector((state: RootState) => state.profile);
    let user = props.clientDetail || userData;
    const { client } = user;
    const { setNextStep, isFromClientDetail, isFromEnrollmentHome, isDocumentAddress } = props;
    let clientSigned: boolean = isFromClientDetail && user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? true : false;
    let userId: string = client && client.userId || "";
    let directory: string = user && user.enrollment && user.enrollment.id;
    const identityType = client && client.identityType || "";
    const docusignStatus = user && user.enrollment && user.enrollment.docusignStatus;

    const [identityFrontPrevPhoto, setIdentityFrontPrevPhoto] = React.useState("");
    const [identityBackPrevPhoto, setIdentityBackPrevPhoto] = React.useState("");

    const [utilityBillPrevPhoto, setUtilityBillPrevPhoto] = React.useState("");
    const [residenceFrontPrevPhoto, setResidenceFrontPrevPhoto] = React.useState("");

    const [residenceBackPrevPhoto, setResidenceBackPrevPhoto] = React.useState("");
    const [otherDocPrevPhoto, setOtherDocPrevPhoto] = React.useState("");
    const [otherDocName, setOtherDocName] = React.useState("");

    const [taxDocumentYearOne, setTaxDocumentYearOne] = React.useState("");
    const [taxDocumentYearTwo, setTaxDocumentYearTwo] = React.useState("");

    const [showTaxDocuments, setShowTaxDocuments] = React.useState(true);

    const [taxDocDropDown, setTaxDocDropDown] = React.useState<any>([]);
    const [taxDocSubType, setTaxDocSubType] = React.useState<string>("");
    const [utilityBillDocSubType, setUtilityBillDocSubType] = React.useState<string>("");

    const [onProgress, setOnProgress] = React.useState(false);
    const checkValidation = () => {
        return !checkIsDocUploaded(client);
    };
    useEffect(() => {
        if (!isFromClientDetail) {
            accountActions.getProfileDetails();
        }
    }, []);

    const handleDocument = async (document: any) => {
        if (document && document.id > 0) {
            let documentType = document.documentType;
            let path = document.path;
            let backPath = document.backPath;
            let documentName =  document.name;
            if (documentType === ClientDocumentType.IDENTITY_DOCUMENT) {
                if (path) {
                    setIdentityFrontPrevPhoto(path);
                }
                if (backPath) {
                    setIdentityBackPrevPhoto(backPath);
                }
            } else if (documentType === ClientDocumentType.UTILITY_BILL) {
                setUtilityBillPrevPhoto(path);
            } else if (documentType === ClientDocumentType.RESIDENCE_DOCUMENT) {
                if (path) {
                    setResidenceFrontPrevPhoto(path);
                }
                if (backPath) {
                    setResidenceBackPrevPhoto(backPath);
                }
            } else if (documentType === ClientDocumentType.MORE_DOCUMENTS && documentName == "Other"){
                setOtherDocPrevPhoto(path);
                setOtherDocName(document.otherDocName);
            }
        }
    };

    useEffect(() => {
        if (client && client.clientDocuments) {
            for (let key in client.clientDocuments) {
                handleDocument(client.clientDocuments[key]);
                if (client.clientDocuments[key].documentType === ClientDocumentType.TAX_DOCUMENT) {
                    if(client.clientDocuments[key].name === 'TAX DOCUMENT YEAR 1'){
                        setTaxDocumentYearOne(client.clientDocuments[key].path)
                    }
                    if(client.clientDocuments[key].name === 'TAX DOCUMENT YEAR 2'){
                        setTaxDocumentYearTwo(client.clientDocuments[key].path)
                    }
                    if (!taxDocSubType) {
                        setTaxDocSubType(client.clientDocuments[key].documentSubType ? client.clientDocuments[key].documentSubType : "Tax Returns");
                    }
                }
                if ((client.clientDocuments[key].documentType === ClientDocumentType.UTILITY_BILL) && !utilityBillDocSubType) {
                    setUtilityBillDocSubType(client.clientDocuments[key].documentSubType ? client.clientDocuments[key].documentSubType : "Electric Bill");
                }
            }
        }
        if (client && (client.underWritingOfferTypeFinal === "gi" || client.underWritingOfferTypeFinal === "consent") && client.company &&
                client.company.groupType && client.company.groupType === "Corporation") {
            setShowTaxDocuments(false);
        }

        //Special Case Atlantic Health Condition hide tax documents
        if(ATLANTIC_HEALTH.includes(client && client.company && client.company.companyLinkUrl)){
            setShowTaxDocuments(false);
        }
        const arr = Object.keys(DocNameAndType);
        const taxDocArr = arr.filter((item: string, _) => {
            return DocNameAndType[`${item}`].docType === "TAX_DOCUMENT"
        });
        setTaxDocDropDown(taxDocArr);
    }, [client]);

    const updateDocument = async (key: string, value: string | string[], field: string, documentSubType: string = "", docSubName: string = "", updateOnlySubType:boolean = false) => {
        let payload: { formType?: string, identityFrontPhoto?: string, identityBackPhoto?: string, residenceBackPhoto?: string, residenceFrontPhoto?: string, utilityBillPhoto?: string, taxDocumentYear1?: string, taxDocumentYear2?: string } = {
            formType: "documents",
            identityFrontPhoto: identityFrontPrevPhoto,
            identityBackPhoto: identityBackPrevPhoto,
            residenceBackPhoto: residenceBackPrevPhoto,
            residenceFrontPhoto: residenceFrontPrevPhoto,
            utilityBillPhoto: utilityBillPrevPhoto,
            taxDocumentYear1: taxDocumentYearOne,
            taxDocumentYear2: taxDocumentYearTwo,
        };
        payload[key] = value;
        payload["documentType"] = field;
        payload["documentSubType"] = documentSubType;
        payload["documentKey"] = key;
        payload["docSubName"] = docSubName;
        payload["operation"] = payload[payload["documentKey"]] ? "Uploaded" : "Deleted";
        payload["updateOnlySubType"] = updateOnlySubType; // update subtype only
        // if (key === "taxDocument") {
        //     payload["operation"] = taxDocument.length < value.length ? "Uploaded" : "Deleted";
        // }
        if ( userId && directory ) {
            try {
                await accountActions.updateUserBasicDetails(payload, userId);
                accountActions.getProfileDetails();
            } catch (err) {
                console.log("err", err);
                return false;
            }
        }

    };

    const fileType: string = "image/jpg, image/jpeg, image/png, application/pdf";

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {!isFromClientDetail &&
                        <div className="greenBox">
                            <Typography variant="body2" className="mb15"><InfoIcon
                                    className="floatLeft mr10 textHighLight"/>
                                <strong className="floatLeft">Did You
                                    Know…</strong></Typography>
                            <Typography variant="caption" className="mb10 floatLeft">You can search for
                                “free scanner apps” on your phone or tablet app store and download a
                                free app from which you can take photos of your documents and create a
                                PDF to upload or email to yourself for uploading. <strong><i>Adobe Scan is
                                    a good,
                                    free choice.</i></strong></Typography>
                        </div>
                        }
                        <div className="DocumentsWrap">
                            {!isFromClientDetail &&
                            <div>
                                <img src={licensePassport} alt="#" className="floatLeft"/>
                            </div>
                            }
                            <div className="content">
                                <Typography variant="subtitle1" component="strong">
                                    {identityType === "drivingLicence" ? <Label label={"Drivers License"}
                                                                                required={checkValidation() ? true : false}/> :
                                            <Label label={"Passport"} required={checkValidation() ? true : false}/>}
                                </Typography>
                                <Typography variant="body1">
                                    {identityType === "drivingLicence" ?
                                            <span>
                                                    Front and back of a current, {identityFrontPrevPhoto && identityBackPrevPhoto ?
                                                    <strong>non-expired</strong> : "non-expired"} drivers license
                                                </span>
                                            :
                                            <span>
                                                     Photo and signature page of a current {identityFrontPrevPhoto && identityBackPrevPhoto ?
                                                    <strong>non-expired</strong> : "non-expired"} passport to verify your identity
                                                </span>
                                    }
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={identityFrontPrevPhoto ? 3 : 6}
                                          lg={identityFrontPrevPhoto ? 3 : 6}
                                          className="textLeft">
                                        {!identityFrontPrevPhoto ?
                                                <div className="fileUpload">
                                                    <FileUploader
                                                            setOnProgress={setOnProgress}
                                                            onProgress={onProgress}
                                                            exactFileName={'Driver License Front'}
                                                            dirName={directory}
                                                            dirType={"documents"}
                                                            fileType={fileType}
                                                            width={15000}
                                                            height={15000}
                                                            content={identityType === "drivingLicence" ? "Upload drivers license front" : "Upload passport photo page"}
                                                            onFinish={async (publicUrl: string, name: string) => {
                                                                console.log(publicUrl, '***DOCUMENT***', name );
                                                                await updateDocument("identityFrontPhoto", name, ClientDocumentType.IDENTITY_DOCUMENT, "front");
                                                                setIdentityFrontPrevPhoto(name);
                                                                setOnProgress(false);
                                                            }}

                                                    />
                                                </div>
                                                :
                                                <ViewAndDelete
                                                        identityPhoto={identityFrontPrevPhoto}
                                                        clientSigned={clientSigned}
                                                        title="Identity License"
                                                        action={
                                                            {
                                                                key: "identityFrontPhoto",
                                                                type: ClientDocumentType.IDENTITY_DOCUMENT,
                                                                side: "front",
                                                            }
                                                        }
                                                        userId={userId}
                                                        docusignStatus={docusignStatus}
                                                        directory={directory}
                                                        setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                        setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                        setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                        setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                        setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                        setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                        setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                />
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={identityBackPrevPhoto ? 3 : 6}
                                          lg={identityBackPrevPhoto ? 3 : 6}
                                          className="textLeft">
                                        {!identityBackPrevPhoto ? <div className="fileUpload">
                                                    <FileUploader
                                                            setOnProgress={setOnProgress}
                                                            onProgress={onProgress}
                                                            exactFileName={'Driver License Back'}
                                                            dirName={directory}
                                                            dirType={"documents"}
                                                            fileType={fileType}
                                                            content={identityType === "drivingLicence" ? "Upload drivers license back" : "Upload passport signature page"}
                                                            width={15000}
                                                            height={15000}
                                                            onFinish={async (publicUrl: string, name: string) => {
                                                                await updateDocument("identityBackPhoto", name, ClientDocumentType.IDENTITY_DOCUMENT, "back");
                                                                setIdentityBackPrevPhoto(name);
                                                                setOnProgress(false);
                                                            }}
                                                    />
                                                </div>
                                                :
                                                <ViewAndDelete
                                                        identityPhoto={identityBackPrevPhoto}
                                                        clientSigned={clientSigned}
                                                        title="Identity License"
                                                        action={
                                                            {
                                                                key: "identityBackPhoto",
                                                                type: ClientDocumentType.IDENTITY_DOCUMENT,
                                                                side: "back",
                                                            }
                                                        }
                                                        userId={userId}
                                                        docusignStatus={docusignStatus}
                                                        directory={directory}
                                                        setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                        setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                        setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                        setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                        setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                        setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                        setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                />
                                        }
                                    </Grid>

                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    {showTaxDocuments && <Grid item xs={12}>
                        <div className="DocumentsWrap mt40">
                            {!isFromClientDetail &&
                            <div>
                                <img src={taxDocuments} alt="#" className="floatLeft"/>
                            </div>
                            }
                            <div className="content">
                                <Typography variant="subtitle1" component="strong"><Label label={"Tax Documents"}
                                                                                          required={checkValidation() ? true : false}/></Typography>
                                <Typography variant="body1">Which Tax Documents would you like to verify income with?</Typography>
                                <SelectDocTypeField label={"Verification Document Type"}
                                                    handleChange={async (e) => {
                                                        if (e && e.target && e.target.value) {
                                                            setTaxDocSubType(e.target.value);
                                                        }
                                                        await updateDocument("taxDocumentYear1", "", ClientDocumentType.TAX_DOCUMENT, "" , e.target.value, true); // update docSubType
                                                    }} items={taxDocDropDown} value={taxDocSubType} name="taxDocument" clientSigned={clientSigned}/>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Card className="alertMessageWrap success alert "
                                              style={{ border: "1px solid #3DDB93" }}>
                                            <CardContent className="alertMessageWrapCardContent ">
                                                <div className="imgSec floatLeft textHighLight">
                                                    <InfoOutlinedIcon />
                                                </div>
                                                <div className="textSec floatLeft">
                                                    <Typography variant="body1" className="mb20">
                                                        Only upload one form of Tax Document: W2 or Tax
                                                        Returns or 1099.
                                                        Please do not upload placeholder documents or
                                                        references to
                                                        other ilia accounts. We cannot access previously
                                                        uploaded
                                                        documents from the Carrier or other ilia
                                                        accounts.
                                                    </Typography>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} className="textLeft">
                                        <Grid container spacing={2}>

                                                    {!taxDocumentYearOne && taxDocSubType?
                                                            <Grid item xs={12}  md={taxDocumentYearOne ? 3 : 6}
                                                                  lg={taxDocumentYearOne ? 3 : 6} className="textLeft">
                                                                <div className="mt15 floatLeft w100">
                                                    <div className="fileUpload">
                                                        <FileUploader
                                                                setOnProgress={setOnProgress}
                                                                onProgress={onProgress}
                                                                exactFileName={'Tax-Document-Year-1-' + moment().format("DD-MM-YYYY HH:mm:ss")}
                                                                dirName={directory}
                                                                dirType={"documents"}
                                                                fileType={fileType}
                                                                width={15000}
                                                                height={15000}
                                                                content={"Upload Year 1"}
                                                                onFinish={async (publicUrl: string, name: string) => {
                                                                    console.log(publicUrl, '***DOCUMENT***', name );
                                                                    setTaxDocumentYearOne(name);
                                                                    await updateDocument("taxDocumentYear1", name, ClientDocumentType.TAX_DOCUMENT, "YEAR_ONE", taxDocSubType);
                                                                    setOnProgress(false);
                                                                }}
                                                        />
                                                    </div>
                                                                </div>
                                                            </Grid>
                                                    :
                                                            taxDocSubType &&
                                                            <Grid item xs={12}  md={taxDocumentYearOne ? 3 : 6}
                                                                  lg={taxDocumentYearOne ? 3 : 6} className="textLeft">
                                                                <div className="mt15 floatLeft w100">
                                                            <ViewAndDelete
                                                            identityPhoto={taxDocumentYearOne}
                                                            clientSigned={clientSigned}
                                                            title="Tax Document Year 1"
                                                            action={
                                                                {
                                                                    key: "TAX_DOCUMENT",
                                                                    type: ClientDocumentType.TAX_DOCUMENT,
                                                                    side: "YEAR_ONE",
                                                                }
                                                            }
                                                            userId={userId}
                                                            docusignStatus={docusignStatus}
                                                            directory={directory}
                                                            setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                            setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                            setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                            setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                            setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}

                                                            setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                            setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                    />
                                                                </div>
                                                            </Grid>
                                                    }
                                                    {!taxDocumentYearTwo && taxDocSubType ?
                                                            <Grid item xs={12}  md={taxDocumentYearTwo ? 3 : 6}
                                                                  lg={taxDocumentYearTwo ? 3 : 6} className="textLeft">
                                                                <div className="mt15 floatLeft w100">
                                                    <div className="fileUpload">
                                                        <FileUploader
                                                                setOnProgress={setOnProgress}
                                                                onProgress={onProgress}
                                                                exactFileName={'Tax-Document-Year-2-' + moment().format("DD-MM-YYYY HH:mm:ss")}
                                                                dirName={directory}
                                                                dirType={"documents"}
                                                                fileType={fileType}
                                                                width={15000}
                                                                height={15000}
                                                                content={"Upload Year 2"}
                                                                onFinish={async (publicUrl: string, name: string) => {
                                                                    console.log(publicUrl, '***DOCUMENT***', name );
                                                                    setTaxDocumentYearTwo(name);
                                                                    await updateDocument("taxDocumentYear2", name, ClientDocumentType.TAX_DOCUMENT, "YEAR_TWO", taxDocSubType);
                                                                    setOnProgress(false);
                                                                }}
                                                        />
                                                    </div>
                                                                </div>
                                                            </Grid>
                                                    :
                                                            taxDocSubType &&
                                                            <Grid item xs={12}  md={taxDocumentYearTwo ? 3 : 6}
                                                                  lg={taxDocumentYearTwo ? 3 : 6} className="textLeft">
                                                                <div className="mt15 floatLeft w100">
                                                            <ViewAndDelete
                                                            identityPhoto={taxDocumentYearTwo}
                                                            clientSigned={clientSigned}
                                                            title="Tax Document Year 2"
                                                            action={
                                                                {
                                                                    key: "TAX_DOCUMENT",
                                                                    type: ClientDocumentType.TAX_DOCUMENT,
                                                                    side: "YEAR_TWO",
                                                                }
                                                            }
                                                            userId={userId}
                                                            docusignStatus={docusignStatus}
                                                            directory={directory}
                                                            setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                            setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                            setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                            setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                            setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}

                                                            setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                            setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                    />
                                                                </div>
                                                            </Grid>
                                                    }
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>}
                    {((client && !client.isDocumentAddress) || (client && client.identityType && client.identityType !== "drivingLicence")
                            || (!isDocumentAddress && isFromClientDetail)) &&
                        <UtilityBill
                                isDocumentAddress={isDocumentAddress}
                                isFromClientDetail={isFromClientDetail}
                                checkValidation={checkValidation}
                                utilityBillPrevPhoto={utilityBillPrevPhoto}
                                setOnProgress={setOnProgress}
                                onProgress={onProgress}
                                directory={directory}
                                fileType={fileType}
                                updateDocument={updateDocument}
                                setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                clientSigned={clientSigned}
                                userId={userId}
                                setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                setTaxDocumentYearOne={setTaxDocumentYearOne}
                                setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                setUtilityBillDocSubType={setUtilityBillDocSubType}
                                utilityBillDocSubType={utilityBillDocSubType}
                                client={client}
                                docusignStatus={docusignStatus}
                        />
                    }
                    {client && !client.usCitizen &&
                    <Grid item xs={12}>
                        <div className="DocumentsWrap mt40">
                            {!isFromClientDetail &&
                            <div>
                                <img src={visaGreencard} alt="#" className="floatLeft"/>
                            </div>
                            }
                            <div className="content">
                                <Typography variant="subtitle1" component="strong"><Label
                                        label={"Visa, Green Card, or Permanent Residency Card"}
                                        required={checkValidation() ? true : false}/>
                                </Typography>
                                <Typography variant="body1">Please upload front and back of a visa, green
                                    card, or permanent residency card to verify legal U.S. residency status.
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={residenceFrontPrevPhoto ? 3 : 6}
                                          lg={residenceFrontPrevPhoto ? 3 : 6}
                                          className="textLeft">
                                        {!residenceFrontPrevPhoto ?
                                                <div className="fileUpload">
                                                    <FileUploader
                                                            setOnProgress={setOnProgress}
                                                            onProgress={onProgress}
                                                            exactFileName={'Residency Card Front'}
                                                            dirName={directory}
                                                            dirType={"documents"}
                                                            fileType={fileType}
                                                            content={"Upload document front"}
                                                            width={15000}
                                                            height={15000}
                                                            onFinish={async (publicUrl: string, name: string) => {
                                                                await updateDocument("residenceFrontPhoto", name, ClientDocumentType.RESIDENCE_DOCUMENT, "front");
                                                                setResidenceFrontPrevPhoto(name);
                                                                setOnProgress(false);
                                                            }}
                                                    />
                                                </div>
                                                :
                                                <ViewAndDelete
                                                        identityPhoto={residenceFrontPrevPhoto}
                                                        clientSigned={clientSigned}
                                                        title="Identity License"
                                                        action={
                                                            {
                                                                key: "residenceFrontPhoto",
                                                                type: ClientDocumentType.RESIDENCE_DOCUMENT,
                                                                side: "front",
                                                            }
                                                        }
                                                        userId={userId}
                                                        docusignStatus={docusignStatus}
                                                        directory={directory}
                                                        setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                        setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                        setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                        setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                        setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                        setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                        setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                />
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={residenceBackPrevPhoto ? 3 : 6}
                                          lg={residenceBackPrevPhoto ? 3 : 6}
                                          className="textLeft">
                                        {!residenceBackPrevPhoto ?
                                                <div className="fileUpload">
                                                    <FileUploader
                                                            setOnProgress={setOnProgress}
                                                            onProgress={onProgress}
                                                            exactFileName={'Residency Card Back'}
                                                            dirName={directory}
                                                            dirType={"documents"}
                                                            fileType={fileType}
                                                            content={"Upload document back"}
                                                            width={15000}
                                                            height={15000}
                                                            onFinish={async (publicUrl: string, name: string) => {
                                                                await updateDocument("residenceBackPhoto", name, ClientDocumentType.RESIDENCE_DOCUMENT, "back");

                                                                let file = name.split(".");
                                                                let fileType = file[file.length - 1].toUpperCase();
                                                                const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
                                                                let queryObj = {
                                                                    objectName: name,
                                                                    contentType: contentType,
                                                                };
                                                                await awsActions.getSecureUrl(directory, queryObj, true);
                                                                setResidenceBackPrevPhoto(name);
                                                                setOnProgress(false);
                                                            }}
                                                    />
                                                </div>
                                                :
                                                <ViewAndDelete
                                                        identityPhoto={residenceBackPrevPhoto}
                                                        clientSigned={clientSigned}
                                                        title="Identity License"
                                                        action={
                                                            {
                                                                key: "residenceBackPhoto",
                                                                type: ClientDocumentType.RESIDENCE_DOCUMENT,
                                                                side: "back",
                                                            }
                                                        }
                                                        userId={userId}
                                                        docusignStatus={docusignStatus}
                                                        directory={directory}
                                                        setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                        setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                        setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                        setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                        setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                        setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                        setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                />
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    }
                    {
                        otherDocPrevPhoto &&
                        <OtherDocument
                                directory={directory} fileType={fileType} updateDocument={updateDocument}
                                clientSigned={clientSigned} userId={userId}
                                setOtherDocPrevPhoto={setOtherDocPrevPhoto}
                                otherDocPrevPhoto={otherDocPrevPhoto}
                                otherDocName={otherDocName}
                                isFromClientDetail={isFromClientDetail}
                        />
                    }
                    {(!isFromClientDetail && !isFromEnrollmentHome) &&
                    <Grid item xs={12} sm={12} md={12}>
                        <span className="hLine mt60 mb30"/>
                        {
                            checkValidation() &&
                            <span className="colorRed">Required Fields <sup>*</sup></span>
                        }
                        <Button variant="contained" color="primary" disabled={checkValidation() ? true : false}
                                className="floatRight mt20 mb30"
                                onClick={async () => {
                                    accountActions.updateDocCompleteStatus();
                                    setNextStep();
                                }}
                        >
                            Save and Continue
                        </Button>
                    </Grid>
                    }
                </Grid>
            </>
    );
};
export default UploadDocumentsForm;