import {ActionType} from "../model";
const prefix = 'vimeo';

export const getShowcaseDetails = (showcaseId: number, manual?: boolean) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        return api.get(`${prefix}/showcases/${showcaseId}`, {}, {manual});
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};