import React from "react";
import { makeStyles, createStyles, withStyles, Theme } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

export const BorderLinearProgress = withStyles((theme: Theme) =>
        createStyles({
            root: {
                height: 15,
                borderRadius: 15,
            },
            colorPrimary: {
                backgroundColor: "#EEEEEE",
            },
            bar: {
                borderRadius: 15,
                background: "linear-gradient(270deg, #3DDB93 0%, #2196F3 100%);",
            },

        }),
)(LinearProgress);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    mb5: {
        marginBottom: 5,
    },
});

export default function ProgressBar(props: { progressBar: number }) {
    const classes = useStyles();
    const { progressBar } = props;
    return (
            <div className={classes.root + '' + classes.mb5}>
                <BorderLinearProgress variant="determinate" value={progressBar}/>
            </div>
    );
}
