import React from 'react';
import { Button, Grid } from "@material-ui/core";

type NextNavData = {
    title:string,
    visibility:boolean,
    setTabValueNext?:Function,
    tabValNext?:string
}

type PrevNavData = {
    title:string,
    visibility:boolean,
    setTabValuePrev?:Function,
    tabValPrev?:string
}

interface TabNavigationProps {
    next:NextNavData,
    prev:PrevNavData
}

function TabNavigation(props: TabNavigationProps) {
    const {next, prev} = props;
    const {setTabValueNext, tabValNext} = next;
    const {setTabValuePrev, tabValPrev} = prev;
    return <Grid item xs={12}>
        {
            prev && prev.visibility &&
            <Button
                    className="floatLeft mt15"
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                        if(typeof setTabValuePrev === 'function'){
                            setTabValuePrev(tabValPrev)
                        }
                    }}
            >
                {prev && prev.title}
            </Button>
        }
        { next && next.visibility &&
            <Button
                    className=" floatRight floatLeftSm ml15 mt15"
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                        if(typeof setTabValueNext === 'function'){
                            setTabValueNext(tabValNext)
                        }
                    }}
            >
                {next && next.title}
            </Button>
        }
        </Grid>
}

export default TabNavigation;