import {ActionType} from "../model";
const prefix = 'estimator';

const estimatorErrorDispatch = {
    type: ActionType.ALERT,
    payload:
        {
            message: "You are not able to use Estimator. Please contact adminstrator",
            statusCode: 400
        }
}

export const listEstimator = (payload: { page : number, rowsPerPage: number }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get(`${prefix}`, {}, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};


export const addEstimator = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.post(`${prefix}`, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const deleteEstimator = (id: number, notes:string) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.delete(`${prefix}/${id}`, { notes });
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

/**
 * Save Estimator Details
 * @payload data to save
 */
export const saveEstimatorData = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post(`${prefix}/data`, payload);
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Estimation Data Save Successfully",
                statusCode: 200
            }
        });
        return true;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

/**
 * Get Estimator Details
 * @query carrierId
 * @query strategyId
 */
export const getEstimatorDetails = (query: {carrierId: number, strategyId: number}) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get(`${prefix}/get`, {}, query);
        if(!(!!resp)){
            dispatch(estimatorErrorDispatch);
        }
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const showNotAbleToEstimator = () => async (dispatch: Function) => {
    dispatch(estimatorErrorDispatch);
}


/**
 * Get List Estimator Data
 * @payload page
 * @payload rowsPerPage
 */
export const listEstimatorData = (payload: { page : number, rowsPerPage: number }) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get(`${prefix}/data`, {}, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

/**
 * Get update Estimator Data
 * @payload data include id
 */
export const updateEstimatorData = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {

        await api.put(`${prefix}/data`, payload);
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Estimation Data Updated Successfully",
                statusCode: 200
            }
        });
        return true;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

/**
 * Delete Estimator Data
 * @query Id
 */
export const deleteEstimatorData = (id: number) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.delete(`${prefix}/data/${id}`, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Estimation Deleted Successfully",
                statusCode: 200
            }
        });
        return true;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};