import {Action, ActionType} from "../model";
import createReducer from "./createReducer";
import {Division, DivisionList} from "../model/division";

export const division = createReducer({}, {
    [ActionType.ADD_DIVISION](state: Division, action: Action<Division>) {
        return action.payload;
    }
});


export const divisions = createReducer({}, {
    [ActionType.LIST_DIVISIONS](state: DivisionList, action: Action<DivisionList>) {
        return action.payload;
    }
});