import React from 'react';
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import {AutoComplete, ErrorCommon, TextFieldCommon} from "../../../../../components/formikFormInputs";
import {Label} from "../../../../../components/formInputs";
import {useActions} from "../../../../../actions";
import * as AccountActions from "../../../../../actions/account";

type PropsType = {
    caseDetails: any,
    userLoggedIn: any,
    values: any,
    handleChange: Function,
    setFieldValue: Function,
    handleBlur: Function,
    errors: any,
    touched: any
}
export default function AddCombineTrust(props: PropsType) {
    const {caseDetails, userLoggedIn, values, handleChange, setFieldValue, handleBlur, touched, errors} = props;
    const [showParticipantSearch, setShowParticipantsSearch] = React.useState(false);
    const [participantsList, setParticipantsList] = React.useState([]);
    const accountActions = useActions(AccountActions);
    const getParticipants = async (searchText: string) => {
        if (searchText && searchText.length > 1) {
            //get participant list with same tranche no for combine to trust
            const participants = await accountActions.searchCombineParticipantsList({
                clientId: caseDetails.id,
                searchText
            });
            let list: any = [];
            participants.map((row: any) => {
                list.push({
                    title: `${row.firstName} ${row.lastName}`,
                    key: Number(row.client.id),
                    client: row.client,
                    participantId: row.client.id,
                });
            });
            setParticipantsList(list);
        }
    };
    const fieldObject = [{
        label: <Label label={"Search Participants"} required={true}/>,
        id: "participantId",
        name: "participantId",
        placeholder: "search participants",
        component: AutoComplete,
        cb: getParticipants,
        contentList: participantsList,
        // onSelectChange: (id: number) => {
        // },
        browserSuggestions: true
    }]
    if (userLoggedIn.role >= 3) {
        return <></>;
    }
    return (<>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormControlLabel
                        value={values["isCombineTrust"] ? true : false}
                        control={
                            <Checkbox
                                    checked={values["isCombineTrust"] ? true : false}
                                    name="isCombineTrust"
                                    className="pt0 pb0"
                                    color="primary"
                                    onChange={(e) => {
                                        setFieldValue("isCombineTrust", e.target.checked);
                                        setShowParticipantsSearch(e.target.checked ? true : false)
                                        if (!e.target.checked) {
                                            setFieldValue("participantId", null);
                                        }
                                    }}
                            />
                        }
                        className="CheckboxSpace mb15"
                        label={<Typography variant="caption" className="mt15 f12 mb0">Select if
                            combine
                            trust</Typography>}
                />
                {
                    (showParticipantSearch || values["isCombineTrust"]) && fieldObject.map((field, index) => {
                        return (
                                <Grid item xs={12} sm={6} md={6}
                                      key={index}>
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                    />
                                    <ErrorCommon errors={errors}
                                                 name={field.name}
                                                 touched={touched}/>
                                </Grid>
                        );
                    })
                }
            </Grid>
        </Grid>

    </>)
}
