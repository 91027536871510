import * as React from "react";
import { Grid } from "@material-ui/core";
import LandingSectionOne from "./LandingSectionOne";
import LandingSectionTwo from "./LandingSectionTwo";
import LandingSectionThree from "./LandingSectionThree";
import LandingSectionFour from "./LandingSectionFour";
import LandingSectionFive from "./LandingSectionFive";
import LandingFooter from "./LandingFooter";
// import Chat from "./Chat";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import GroupRebrandSection from "./GroupRebrandSection";

const LandingBody = (props: any) => {
    const [currentScrollTop, setCurrentScrollTop] = React.useState(0);
    const [blueScrollArrowsClass, setBlueScrollArrows] = React.useState("hide");
    const [pathHeight, setPathHeight] = React.useState(2000);
    const encircleRefEl: any = React.useRef(null);
    const inputEl: any = React.useRef(null);
    const showGrReLanPage = props.strategyData.slug === "kaizen" &&
            props.accountType === props.ALLOWED_ACCOUNT_TYPE.GROUP &&
            props.startEstimation === 1 &&
            (props.groupDetail &&
                    props.groupDetail.imo &&
                    props.groupDetail.imo.reBrand || props.groupDetail &&
                    props.groupDetail.agent &&
                    props.groupDetail.agent.imo &&
                    props.groupDetail.agent.imo.reBrand) || false;
    let pathLength = 0;
    const isAcceptInvitation = props.history.location.pathname.search("/accept/new-invitation/") > -1;


    const getScrollPercent = () => {
        return (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
    };

    const recalculatePathSize = () => {
        let sectionFirst: number = getElementDistanceFromTop(document.getElementById("firstPoint"));
        let sectionLast: number = getElementDistanceFromTop(document.getElementById("lastPoint"));
        let calculatedHeight: number = sectionLast - sectionFirst;
        setPathHeight(calculatedHeight + 20);
    };

    const handleScroll = (event) => {
        if (inputEl && inputEl.current) {
            let sectionFirst: number = getElementDistanceFromTop(document.getElementById("section1"));
            let scrollPercentage = getScrollPercent();
            pathLength = inputEl && inputEl.current ? inputEl.current.getTotalLength() : 0;
            if (window.scrollY > (sectionFirst - 230)) {
                inputEl.current.style.strokeDashoffset = pathLength - (window.scrollY - (sectionFirst - 230));
            } else {
                inputEl.current.style.strokeDashoffset = pathLength;
            }
            if (scrollPercentage >= 0.99) {
                inputEl.current.style.strokeDasharray = "none";
            } else {
                inputEl.current.style.strokeDasharray = pathLength + " " + pathLength;
            }
            recalculatePathSize();
        }
    };

    const handleBlueArrows = (event) => {
        let scrollPosition = window.scrollY;
        if (scrollPosition >= 1000) {
            setBlueScrollArrows("show");
        }
        if (scrollPosition <= 999) {
            setBlueScrollArrows("hide");
        }
        if (scrollPosition > 4000) {
            setBlueScrollArrows("hide");
        }
    };

    const getElementDistanceFromTop = (elem) => {
        let box = elem ? elem.getBoundingClientRect() : 0;
        return box.top + window.pageYOffset;
    };

    React.useEffect(() => {
        if (inputEl && inputEl.current) {
            window.addEventListener("scroll", handleScroll, true);
            window.addEventListener("scroll", handleBlueArrows, true);
            window.addEventListener("resize", recalculatePathSize);
            pathLength = inputEl && inputEl.current ? inputEl.current.getTotalLength() : 0;
            inputEl.current.style.strokeDasharray = pathLength + " " + pathLength;
            inputEl.current.style.strokeDashoffset = pathLength;
            recalculatePathSize();
        }
        if (localStorage.hasOwnProperty("showPasswordScreen")) {
            localStorage.removeItem("showPasswordScreen");
        }
        if (localStorage.hasOwnProperty("virificationToken")) {
            localStorage.removeItem("virificationToken");
        }
        return (() => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("scroll", handleBlueArrows);
        });
    }, []);

    React.useEffect(() => {
        window.scrollTo(0, currentScrollTop);
    }, [currentScrollTop]);


    return (
            <>

                <LandingSectionOne showGrReLanPage={showGrReLanPage} {...props} />
                {
                !showGrReLanPage ?
                    <div id={"scroll-sections"} className="scroll-sections">
                        <div ref={encircleRefEl} className="encircle"  onClick={() => {
                            setCurrentScrollTop(document.documentElement.scrollTop + 200);
                        }}>
                            <ArrowDownwardIcon className="arrow bounce"/>
                        </div>
                        <Grid container justify="center">
                            <Grid item xs={12} md={11} lg={10} xl={9} className="textLeft">
                                <div id={"scrolling-line-arrow"} className="scrolling-line-arrow"
                                     style={{ height: pathHeight }}>
                                    <span className="first-marker" id="firstPoint"/>
                                    <span className="pulsing-shadow"/>
                                    <svg id={"scroll-line"} height="100%" viewBox={`0 0 8 ${pathHeight}`} width="8"
                                         xmlns="http://www.w3.org/2000/svg"
                                         className="line-background">
                                        <path id="linePath" d={`m4 4v${pathHeight}`} fill="none" stroke="#2196f3"
                                              stroke-linecap="round"
                                              stroke-width="8" ref={inputEl}/>
                                    </svg>
                                    <svg width="38" height="46" xmlns="http://www.w3.org/2000/svg"
                                         className={`blue-scroll-arrows ${blueScrollArrowsClass} `}>
                                        <g fill="#2196F3" fill-rule="evenodd">
                                            <g opacity=".5">
                                                <path d="M8.413 3.808l12.993 12.869a3.881 3.881 0 0 1 0 5.515 3.956 3.956 0 0 1-5.569 0L2.844 9.323a3.881 3.881 0 0 1 0-5.515 3.956 3.956 0 0 1 5.569 0z"/>
                                                <path d="M22.163 22.192L35.156 9.323a3.881 3.881 0 0 0 0-5.515 3.956 3.956 0 0 0-5.569 0L16.594 16.677a3.881 3.881 0 0 0 0 5.515 3.956 3.956 0 0 0 5.569 0z"/>
                                            </g>
                                            <g transform="translate(0 20)">
                                                <path d="M8.413 3.808l12.993 12.869a3.881 3.881 0 0 1 0 5.515 3.956 3.956 0 0 1-5.569 0L2.844 9.323a3.881 3.881 0 0 1 0-5.515 3.956 3.956 0 0 1 5.569 0z"/>
                                                <path d="M22.163 22.192L35.156 9.323a3.881 3.881 0 0 0 0-5.515 3.956 3.956 0 0 0-5.569 0L16.594 16.677a3.881 3.881 0 0 0 0 5.515 3.956 3.956 0 0 0 5.569 0z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LandingSectionTwo {...props} />
                        </Grid>
                        <Grid item xs={12}>
                            <LandingSectionThree {...props} />
                        </Grid>
                        <Grid item xs={12}>
                            <LandingSectionFour {...props} />
                        </Grid>
                        <Grid item xs={12}>
                            <LandingSectionFive {...props} />
                        </Grid>
                        {
                                !isAcceptInvitation &&
                                <Grid item xs={12} md={12} className="pdTB15">
                                    <LandingFooter {...props} bannerRef={encircleRefEl} />
                                </Grid>
                        }
                    </div> :
                    <GroupRebrandSection {...props}/>
                }
                {/*<Chat {...props} />*/}
            </>
    );
};

export default LandingBody;
