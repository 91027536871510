import { FormHelperText, Grid, GridSize } from "@material-ui/core";
import * as React from "react";
import {TextField, PhoneNumberField, Label, SelectField} from "./formInputs";
import {change, Field} from "redux-form";
import { email, required, validatePhoneNumber } from "../utils/validation";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useRef } from "react";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            error: {
                color: "#FF1744",
                fontSize:"12px"
            }
        }),
);
interface PrimaryProps {
    isLeadInvitation? : boolean,
	callingFrom? : string,
	disabledEmail? : boolean,
	disabledPhone? : boolean,
	emailError? : boolean,
	isFromImoMemberForm? : boolean,
	role?: number,
	updateProfile?: Function,
	handleSubmit?: Function,
    emailExist?: boolean,
    setEmailExist?: Function,
    setEmailRef?: Function,
    disabledFirstName?: boolean,
    disabledLastName?: boolean,
    disabledMiddleName?: boolean,
    size?: GridSize,
    disabled?: boolean
}

export default function PrimaryContactDetail(props: PrimaryProps) {
    const { isLeadInvitation, emailExist, setEmailExist, setEmailRef, size = 6, disabled = false } = props;
    const emailRef: any = useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (emailRef && emailRef.current && typeof setEmailRef === 'function') {
            setEmailRef(emailRef);
        }
    }, [emailRef.current]);
	return (
		<>
			<Grid item xs={12} sm={6}
                  md={isLeadInvitation ? 4 : size}
                  lg={isLeadInvitation ? 4 : size}
                  xl={isLeadInvitation ? 4 : size}>
				<Field
					fullWidth
					label={<Label label={"First Name"} required={true}/>}
					name="firstName"
					placeholder="enter first name"
					component={TextField}
					validate={[required]}
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
                    disabled={props.disabledFirstName || disabled ||false}
				/>
			</Grid>
			{
				props.callingFrom === "clientProfile" &&
				<Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : 6}
                      lg={isLeadInvitation ? 4 : 6}
                      xl={isLeadInvitation ? 4 : 6}>
					<Field
						fullWidth
						label={<Label label={"Middle Name"} />}
						name="middleName"
						placeholder="enter middle name"
						component={TextField}
						onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
                        disabled={props.disabledMiddleName || disabled || false}
					/>
				</Grid>
			}
			<Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : size}
                  lg={isLeadInvitation ? 4 : size}
                  xl={isLeadInvitation ? 4 : size}>
				<Field
					fullWidth
					label={<Label label={"Last Name"} required={true}/>}
					name="lastName"
					placeholder="enter last name"
					component={TextField}
					validate={[required]}
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
                    disabled={props.disabledLastName || disabled || false}
				/>
			</Grid>
			<Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : size}
                  lg={isLeadInvitation ? 4 : size}
                  xl={isLeadInvitation ? 4 : size}>
				<Field
					fullWidth
					label={<Label label="Cell Number" required={true}/>}
					name="phoneNumber"
					placeholder="enter cell number"
					component={PhoneNumberField}
					validate={[required, validatePhoneNumber]}
					disabled={props.disabledPhone || disabled}
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
				/>
			</Grid>
			<Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : size}
                  lg={isLeadInvitation ? 4 : size}
                  xl={isLeadInvitation ? 4 : size} ref={emailRef} id="primaryEmail">
				{ (!props.emailError || props.isFromImoMemberForm)?
                        <>
                            <Field
                                    fullWidth
                                    label={<Label label="Email Address" required={true} />}
                                    name="email"
                                    placeholder="email@domain.com"
                                    component={TextField}
                                    validate={[required, email]}
                                    disabled={props.disabledEmail || isLeadInvitation || disabled}
                                    onChange={() => {
                                        if(typeof setEmailExist === 'function'){
                                            setEmailExist(false);
                                        }
                                    }}
                            />
                            {
                                emailExist &&
                                <FormHelperText style={{ margin: 0, fontSize: 10 }}>
                                    <span className={classes.error}>Email address already exist</span>
                                </FormHelperText>
                            }
                        </>
					:
                        <>
                            <Field
                                    fullWidth
                                    label={<Label label="Email Address" required={true} />}
                                    name="email"
                                    placeholder="email@domain.com"
                                    component={TextField}
                                    validate={[required, email]}
                                    disabled={props.disabledEmail || disabled}
                                    error={props.emailError}
                                    onChange={() => {
                                        if(typeof setEmailExist === 'function'){
                                            setEmailExist(false);
                                        }
                                    }}
                            />
                            {
                                emailExist &&
                                <FormHelperText style={{ margin: 0, fontSize: 10 }}>
                                    <span className={classes.error}>Email address already exist</span>
                                </FormHelperText>
                            }
                        </>
				}

			</Grid>

		</>
	);
}

