import React from "react";
import {DialogContent, Grid, Typography} from "@material-ui/core";
import {Button, Label} from "../../../../../../../components/formInputs";
import * as Yup from "yup";
import {Formik} from "formik";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import moment from 'moment';
import TextFieldCommon from "../../../../../../../components/formikFormInputs/TextFieldCommon";
import {
    DatePicker,
    ErrorCommon,
    PhoneNumberField,
    SelectField,
    TextField
} from "../../../../../../../components/formikFormInputs";
import {AddEditPhysician as styles} from '../medicalStyle';
import {stateDropDown} from "../../../../../../../constants";
import {useActions} from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import {unmaskPhoneNumber} from "../../../../../../../utils/commonUtils";

type placeObj = { id: number, key?: string, name: string, value: string };
let stateDropDownArr: placeObj[] = stateDropDown.map((state: any) => {
    return {
        id: state.id,
        key: state.value,
        name: state.name,
        value: state.value,
    };
});

interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
    const classes = styles();
    const {children, onClose, ...other} = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
};

const AddEditPhysician = (props: any) => {
    const {record, open, setOpen, user, isFromClientDetail} = props;
    const {savePhysician} = useActions(AccountActions);
    const isClientSigned = (user && user.enrollment && user.enrollment.clientSigned) || false;

    const formVal = {
        firstName: (record && record.firstName) || "",
        mi: (record && record.mi) || "",
        lastName: (record && record.lastName) || "",
        address: (record && record.address) || "",
        city: (record && record.city) || "",
        state: (record && record.state) || "",
        zip: (record && record.zip) || "",
         dateOfLastVisit: record && record.dateOfLastVisit ? moment(record && record.dateOfLastVisit).format('MM/DD/yyyy') : moment().format('MM/DD/yyyy'),
        phone: (record && record.phone) || "",
        reason: (record && record.reason) || "",
    };
    const initialValues = formVal;

    const fieldObj = [
        {
            id: "firstName",
            name: "firstName",
            helperFieldText: "",
            label: <Label label={"First Name"} required={true}/>,
            placeholder: "First Name",
            isRequired: true,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 5,
                lg: 5,
            },
        },
        {
            id: "mi",
            name: "mi",
            helperFieldText: "",
            label: <Label label={"MI"} required={false}/>,
            placeholder: "MI",
            isRequired: false,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 2,
                lg: 2,
            },
        },
        {
            id: "lastName",
            name: "lastName",
            helperFieldText: "",
            label: <Label label={"Last Name"} required={true}/>,
            placeholder: "Last Name",
            isRequired: true,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 5,
                lg: 5,
            },
        },
        {
            id: "address",
            name: "address",
            helperFieldText: "",
            label: <Label label={"Address"} required={!isFromClientDetail}/>,
            placeholder: "Address",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 3,
                lg: 3,
            },
        },
        {
            id: "city",
            name: "city",
            helperFieldText: "",
            label: <Label label={"City"} required={!isFromClientDetail}/>,
            placeholder: "City",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 3,
                lg: 3,
            },
        },
        {
            id: "state",
            name: "state",
            helperFieldText: "",
            label: <Label label={"State"} required={!isFromClientDetail}/>,
            placeholder: "State",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: SelectField,
            options: stateDropDownArr,
            onSelectChange: (val: number) => {
            },
            variant: "outlined",
            size: {
                md: 3,
                lg: 3,
            },
        },
        {
            id: "zip",
            name: "zip",
            helperFieldText: "",
            label: <Label label={"Zip"} required={!isFromClientDetail}/>,
            placeholder: "Zip",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 3,
                lg: 3,
            },
        },
        {
            id: "dateOfLastVisit",
            name: "dateOfLastVisit",
            helperFieldText: "",
            label: <Label label={"Date of Last Visit"} required={!isFromClientDetail}/>,
            placeholder: "Date of Last Visit",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: DatePicker,
            disableFuture: true,
            variant: "outlined",
            size: {
                md: 3,
                lg: 3,
            },
        },
        {
            id: "phone",
            name: "phone",
            helperFieldText: "",
            label: <Label label={"Phone"} required={!isFromClientDetail}/>,
            placeholder: "Phone",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: PhoneNumberField,
            variant: "outlined",
            size: {
                md: 3,
                lg: 3,
            },
        },
        {
            id: "reason",
            name: "reason",
            helperFieldText: "",
            label: <Label label={"Reason Consulted and Outcome for Last Visit"} required={!isFromClientDetail}/>,
            placeholder: "Last Visit",
            isRequired: !isFromClientDetail,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            multiline: true,
            rows: 2,
            size: {
                md: 12,
                lg: 12,
            },
        },
    ];


    /* Validations of fields set */
    let keysValidation = {};

    fieldObj.forEach((field) => {
        if (field.isRequired) {
            keysValidation[field.name] = field.filedType && field.filedType === "number" ?
                    Yup.number().required("Required Field") : Yup.string().trim().required("Required Field").nullable();

            if (field.name === "zip") {
                keysValidation[field.name] = Yup.string().matches(/^[a-zA-Z0-9]+$/, "Special characters are not allowed").test('len', 'Please enter 5 characters', (val = []) => ((val.length || 0) === 5)).required("Required Field");
            }
            if (field.name === "phone") {
                keysValidation[field.name] = Yup.string().transform((o, v) => {
                    let value = v + "";
                    if (value) {
                        value = value.replace(/\D+/g, "")
                    }
                    return value;
                }).length(10, "Enter Valid Number").required("Required Field");
            }
        } else {
            keysValidation[field.name] = field.filedType &&
            field.filedType === "number" ? Yup.number() : Yup.string().trim();

            if (field.name === "zip") {
                keysValidation[field.name] = Yup.string().matches(/^[a-zA-Z0-9]+$/, "Special characters are not allowed").test('len', 'Please enter 5 characters', (val = []) => ((val.length || 0) === 5))
            }

            if (field.name === "phone") {
                keysValidation[field.name] = Yup.string().transform((o, v) => {
                    let value = v + "";
                    if (value) {
                        value = value.replace(/\D+/g, "")
                    }
                    return value;
                }).length(10, "Enter Valid Number");
            }
        }
    });

    const validationSchema = Yup.object().shape(keysValidation);

    const handleClose = () => {
        setOpen(false);
    };

    return (
            <Dialog onClose={handleClose} fullWidth={true} maxWidth={"md"} aria-labelledby="customized-dialog-title"
                    open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {!record ? 'Add ' : 'Edit '} Physician
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Formik
                                    initialValues={initialValues}
                                    enableReinitialize={true}
                                    validationSchema={validationSchema}
                                    validateOnChange={true}
                                    onSubmit={async (values: any, {setSubmitting}) => {
                                        setSubmitting(true);
                                        try {
                                            let finalValues: any = {...values};
                                            finalValues.clientId = (user && user.client && user.client.id) || 0;
                                            finalValues.phone = ((typeof finalValues.phone === "string") && finalValues.phone) ? unmaskPhoneNumber(finalValues.phone) : (finalValues.phone || "");
                                            finalValues.dateOfLastVisit = moment(finalValues.dateOfLastVisit).format("YYYY-MM-DD");
                                            if (record && record.id > 0) {
                                                finalValues.id = record.id;
                                            }
                                            let resp = await savePhysician(finalValues);
                                            if (resp) {
                                                handleClose();
                                            }
                                            setTimeout(() => {
                                                setSubmitting(false);
                                            }, 400);
                                        } catch (e) {
                                            return false;
                                        }
                                    }}
                            >
                                {
                                    ({
                                         values,
                                         errors,
                                         touched,
                                         handleChange,
                                         handleBlur,
                                         handleSubmit,
                                         isSubmitting
                                     }) => {
                                        return (
                                                <form className="floatLeft w100" onSubmit={handleSubmit}>
                                                    <Grid container spacing={2}>
                                                        {fieldObj.map((field, index) => {
                                                            let customProps = {}
                                                            if (field.name === 'reason') {
                                                                customProps['field'] = {
                                                                    name: field.name,
                                                                    value: values[field.name]
                                                                };
                                                            }
                                                            return (
                                                                    <Grid item xs={12}
                                                                          md={field.size.md == 2 ? 2 : (field.size.md == 5 ? 5 : (field.size.md == 12 ? 12 : 3))}
                                                                          lg={field.size.lg == 2 ? 2 : (field.size.lg == 5 ? 5 : (field.size.md == 12 ? 12 : 3))}
                                                                          key={index}>
                                                                        <TextFieldCommon
                                                                                {...field}
                                                                                values={values}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                {...customProps}
                                                                        />
                                                                        <ErrorCommon errors={errors} name={field.name}
                                                                                     touched={touched}/>
                                                                    </Grid>
                                                            );
                                                        })
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <Button
                                                                className={'floatRight mt10'}
                                                                type={'submit'}
                                                                variant={'contained'}
                                                                color={'primary'}
                                                                disabled={isClientSigned || isSubmitting}
                                                                label={`${!record ? 'Add' : 'Update'} Physician`}
                                                        />
                                                    </Grid>
                                                </form>
                                        );
                                    }}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
    );
};

export default AddEditPhysician;