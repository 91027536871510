import React from "react";
import { DialogContent, Grid, Typography } from "@material-ui/core";
import { Button, Label } from "../../../../../../../components/formInputs";
import * as Yup from "yup";
import { Formik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { DatePicker, NumberTextField, TextField } from "../../../../../../../components/formikFormInputs";
import { AddEditPhysician as styles } from "../../Style";
import InputAdornment from "@material-ui/core/InputAdornment";
import GetFormContent from "../../Beneficiaries/BeneficiariesList/GetFormContent";
import numeral from "numeral";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import { REQUIRED_FIELD } from "../../../../../../../constants";

interface DialogTitleProps {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
	const classes = styles();
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

const AddRealState = (props: any) => {
	const { record, user, open, setOpen, isFromClientDetail, clientSigned } = props;
	const { saveRealEstate } = useActions(AccountActions);

	const formVal = {
		propertyAddress: record && record.propertyAddress || "",
		purchasePrice: record && record.purchasePrice || "",
		currentMarketValue: record && record.currentMarketValue || "",
		datePurchased: record && record.datePurchased ? moment(record.datePurchased).format("MM/DD/yyyy") : moment().format("MM/DD/yyyy"),
		valueDetermined: record && record.valueDetermined || "",
		ownership: record && record.ownership || "",
		outstandingMortgageAmount: record && record.outstandingMortgageAmount || "",
	};
	const initialValues = formVal;

	const fieldObj = [
		{
			id: "propertyAddress",
			name: "propertyAddress",
			helperFieldText: "",
			label: <Label label={"Property Address"} required={true} />,
			placeholder: "Property Address",
			isRequired: true,
			className: "mt10",
			filedType: "string",
			component: TextField,
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "purchasePrice",
			name: "purchasePrice",
			helperFieldText: "",
			label: <Label label={"Purchase Price"} required={!isFromClientDetail} />,
			placeholder: "Purchase Price",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "datePurchased",
			name: "datePurchased",
			helperFieldText: "",
			label: <Label label={"Date Purchased"} required={!isFromClientDetail} />,
			placeholder: "Date Purchased",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: DatePicker,
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned,
            disableFuture:true
		},
		{
			id: "currentMarketValue",
			name: "currentMarketValue",
			helperFieldText: "",
			label: <Label label={"Current Market Value"} required={!isFromClientDetail} />,
			placeholder: "Current Market Value",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "valueDetermined",
			name: "valueDetermined",
			helperFieldText: "",
			label: <Label label={"How was value determined?"} required={!isFromClientDetail} />,
			placeholder: "How was value determined?",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: TextField,
			onSelectChange: (val: number) => {
			},
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "ownership",
			name: "ownership",
			helperFieldText: "",
			label: <Label label={"Ownership"} required={!isFromClientDetail} />,
			placeholder: "Ownership",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: TextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">%</InputAdornment>,
			},
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "outstandingMortgageAmount",
			name: "outstandingMortgageAmount",
			helperFieldText: "",
			label: <Label label={"Outstanding Mortgage Amount"} required={!isFromClientDetail} />,
			placeholder: "Outstanding Mortgage Amount",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
	];


	/* Validations of fields set */
	let keysValidation = {};

	fieldObj.forEach((field) => {
		if (field.isRequired) {
			keysValidation[field.name] = field.filedType && field.filedType === "number" ?
				Yup.number().required(REQUIRED_FIELD) : Yup.string().trim().required(REQUIRED_FIELD).nullable();
			if (field.name === "ownership") {
				keysValidation[field.name] = Yup.number().typeError("Must be a number").min(1, "Please enter between 1-100").max(100, "Please enter between 1-100").integer("Decimal value is not allowed").required(REQUIRED_FIELD).nullable();
			}
		} else {
			keysValidation[field.name] = field.filedType &&
			field.filedType === "number" ? Yup.number() : Yup.string().trim();
			if (field.name === "ownership") {
				keysValidation[field.name] = Yup.number().typeError("Must be a number").min(1, "Please enter between 1-100").max(100, "Please enter between 1-100").integer("Decimal value is not allowed");
			}
		}
	});

	const validationSchema = Yup.object().shape(keysValidation);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog onClose={handleClose} fullWidth={true} maxWidth={"md"} aria-labelledby="customized-dialog-title"
				open={open}>
			<DialogTitle id="customized-dialog-title" onClose={handleClose}>
				{!record ? "Add " : "Edit "} Real Estate
			</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Formik
							initialValues={initialValues}
							enableReinitialize={true}
							validationSchema={validationSchema}
							validateOnChange={true}
							onSubmit={async (values: any, { setSubmitting }) => {
								try {
									let finalValues: any = { ...values };
									finalValues.clientId = user && user.client && user.client.id || 0;
									finalValues.purchasePrice = numeral(finalValues.purchasePrice).value();
									finalValues.currentMarketValue = numeral(finalValues.currentMarketValue).value();
									finalValues.outstandingMortgageAmount = numeral(finalValues.outstandingMortgageAmount).value();
									finalValues.datePurchased = moment(finalValues.datePurchased).format("YYYY-MM-DD 00:00:00");
									if (record && record.id > 0) {
										finalValues.id = record.id;
									}
									let resp = await saveRealEstate(finalValues);
									if (resp) {
										handleClose();
									}
									setTimeout(() => {
										setSubmitting(false);
									}, 400);
								} catch (e) {
									return false;
								}
							}}
						>
							{
								(formikProps) => {
									return (
										<form className="floatLeft w100" onSubmit={formikProps.handleSubmit}>
											<Grid container spacing={2}>
												<GetFormContent isFromClientDetail={isFromClientDetail}
																fieldObj={fieldObj} {...formikProps} />
											</Grid>

											<Grid item xs={12} md={12}>
												<Button
													className={"floatRight mt30"}
													type={"submit"}
													variant={"contained"}
													color={"primary"}
													disabled={formikProps.isSubmitting || clientSigned}
													label={`${!record ? "Add" : "Update"} Real Estate`}
												/>
											</Grid>
										</form>
									);
								}}
						</Formik>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default AddRealState;