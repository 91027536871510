import React from 'react';
import {TextField, TextFieldProps} from '@material-ui/core';


const Search: React.FC<TextFieldProps> = (searchProps) => {
    return (
            <TextField {...searchProps} />
    );
};

export default Search;
