import React, { useEffect, useState } from "react";
import { Grid, Link } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { usePolicyContext } from "./PolicyContext";
import NumberFormat from "react-number-format";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import { useActions } from "../../../actions";
import * as PaymentActions from "../../../actions/payment";
import { Dialog as GenericDialog, GenericDialogProps } from "../../dialog";
import Button from "@material-ui/core/Button";
import PaymentHistoryDetailsData from "./PaymentHistoryDetails";

function Actions(props: any) {
    const { recordKey, setIsConfirmed, setDeletePaymentId } = props;
    return (<IconButton disabled={!recordKey} className={"floatRight"} onClick={() => {
        setIsConfirmed(true);
        setDeletePaymentId(recordKey);
    }}>
        <DeleteIcon className={!recordKey ? "colorGrey" : "colorRed"} />
    </IconButton>);
}

export default function PaymentsHistory(props: any) {
    const [payments, setPayments] = useState([]);
    const [orderBy, setOrderBy] = useState<string>("name");
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        const sortedArray = [...payments];
        sortedArray.sort((a, b) => {
            if (order === "asc") {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return a[property] < b[property] ? 1 : -1;
            }
        });
        setPayments(sortedArray);

    };
    const { policy: { clientPayments, policyNumber }, updatePolicy } = usePolicyContext();
    const { deleteManualPayment } = useActions(PaymentActions);
    const { onCaseDetails } = props;
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [deletePaymentId, setDeletePaymentId] = useState<string>("");
    let tableHeaders = [
        {
            label: "Cont. Type",
            name: "contributionType",
            align: "center",
            sort: true,
        },
        {
            label: "Date",
            name: "date",
            align: "center",
            sort: true,
        },
        {
            label: "Amount",
            name: "amount",
            align: "center",
            sort: true,
        },
        {
            label: "Method",
            name: "method",
            align: "center",
            sort: true,
        },
        {
            label: "Details",
            name: "details",
            align: "center",
            sort: true,
        },
    ];
    if (onCaseDetails) {
        tableHeaders.push({ label: "Processed By", name: "processedBy", align: "center", sort: true });
        tableHeaders.push({ label: "Actions", name: "actions", align: "center", sort: false });
    }

    const deletePayment = async () => {
        if (deletePaymentId) {
            const policyDetails = await deleteManualPayment({
                recordKey: deletePaymentId,
                policyNumber: policyNumber,
            });
            setDeletePaymentId("");
            updatePolicy(policyDetails);
        }
    };
    useEffect(() => {
        if (clientPayments && clientPayments.length > 0) {
            const payments = clientPayments.map((payment: any) => {
                if (payment.amount && payment.amount.length > 0) {
                    let totalAmount = payment.amount.reduce((acc, item) => acc + (item.amount || 0), 0);
                    return {
                        contributionType: payment.amount[0].type,
                        date: payment.date,
                        amount: totalAmount,
                        method: payment.type,
                        details: payment.details,
                        processedBy: payment.processedBy,
                        recordKey: payment.recordKey,
                        amountDetails: { amount: payment.amount, date: payment.date }
                    };
                }
            });
            setPayments(payments);
        }
    }, [clientPayments]);


    const [paymentHistoryDetails, setPaymentHistoryDetails]:any = React.useState({ });
    const handleClose = () => {
        setPaymentHistoryDetails({});
    };
    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "customized-dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <PaymentHistoryDetailsData paymentHistoryDetails={paymentHistoryDetails}/>
                    <Button color="primary" size="medium" variant="contained" onClick={handleClose} className="floatRight mt20">Close</Button>
                </>
        ),
        dividers: true,
        onClose: handleClose,
        open: paymentHistoryDetails && paymentHistoryDetails.amount && paymentHistoryDetails.amount.length > 0,
        title: "Payment Details",
    };


    return (
            <>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table aria-label="caption table">
                            <TableHead style={{ backgroundColor: "#EDF3F8" }}>
                                <TableRow>
                                    {tableHeaders.map((head: any) => {
                                        return <TableCell align={head.align}
                                                          style={{ background: "#EDF3F8" }}>{head.sort ?
                                                <TableSortLabel active={orderBy === head.name}
                                                                direction={order}
                                                                onClick={() => handleSort(head.name)}>{head.label}</TableSortLabel> : head.label}</TableCell>;
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    payments.length > 0 ? payments.map((payment: any, _index: number) => {
                                        return (<TableRow>
                                            <TableCell scope="row">{payment.contributionType}</TableCell>
                                            <TableCell scope="row">{payment.date}</TableCell>
                                            <TableCell>
                                                <Link onClick={() => setPaymentHistoryDetails(payment.amountDetails)}>
                                                    <NumberFormat
                                                            value={payment.amount}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            prefix={"$"}
                                                            decimalSeparator={"."}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true} />
                                                </Link>
                                            </TableCell>
                                            <TableCell>{payment.method}</TableCell>
                                            <TableCell>{payment.details}</TableCell>
                                            {
                                                onCaseDetails && <>
                                                    <TableCell>{payment.processedBy}</TableCell>
                                                    <TableCell>
                                                        <Actions recordKey={payment.recordKey}
                                                            setIsConfirmed={setIsConfirmed}
                                                            setDeletePaymentId={setDeletePaymentId} />
                                                    </TableCell>
                                                    </>
                                            }
                                        </TableRow>);
                                    }) : <TableRow>
                                        <TableCell scope="row" colSpan={6}>No Payments</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {isConfirmed &&
                        <ConfirmationDialog
                                title={"Remove Payment"}
                                description={"Are you sure you want to remove this payment?"}
                                isConfirmed={isConfirmed}
                                setIsConfirmed={setIsConfirmed}
                                action={deletePayment}
                        />
                }


                {paymentHistoryDetails &&
                        <GenericDialog {...dialogProps} />
                }

            </>
    );
}