import React from 'react';
import {Checkbox, Grid} from "@material-ui/core";
import {avocationType} from "../Utilities";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InsuredAutomobileRacingQuestions from "./InsuredAutomobileRacingQuestions";
import InsuredParachutingSkyDiving from "./InsuredParachutingSkyDiving";
import InsuredUnderwaterDiving from "./InsuredUnderwaterDiving";
import InsuredOtherHazardousSportsAvocations from "./InsuredOtherHazardousSportsAvocations";

type PropsType = {
    values: any,
    clientSigned: boolean,
    setFieldValue: Function,
    enableSaveButton: Function,
    textFieldCommon: any,
    handleChange: Function,
    handleBlur: Function,
    errors: any,
    touched: any,
    isFromClientDetail: boolean,
    role: number,
    setSaveAviationAvocationQuestions: Function,
    offerType: string
}

function InsuredAvocationSubQuestions(props: PropsType) {
    const {
        values,
        clientSigned,
        setFieldValue,
        enableSaveButton,
        textFieldCommon,
        handleChange,
        handleBlur,
        errors,
        touched,
        isFromClientDetail,
        role,
        setSaveAviationAvocationQuestions,
        offerType
    } = props;
    return (
        values["InsuredAviationAvocation"].includes("Insured_Avocation") &&
        <div className="pl16 pr16 innerContentWrap">
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    {
                        <Grid item xs={12} key={avocationType[0].name} className="mt15">
                            <FormControlLabel
                                    className="checkGroupMb0"
                                    control={<Checkbox className="padding0"
                                                       checked={values[`${avocationType[0].name}`] ? true : false}
                                                       disabled={clientSigned ? true : false}
                                                       onChange={(e) => {
                                                           setFieldValue(`${avocationType[0].name}`, e.target.checked);
                                                           if (avocationType[0].name === "Insured_Other_Hazardous_Sports_or_Avocations" && !e.target.checked) {
                                                               setFieldValue("Insured_ParticipationDetails", null);
                                                           }
                                                           //handleChangeCheckBox(e, values);
                                                           enableSaveButton();
                                                       }}
                                                       color={"primary"}
                                                       value={values[`${avocationType[0].name}`] ? true : false}
                                                       name={`${avocationType[0].name}`}/>}
                                    label={`${avocationType[0].label}`}
                            />
                            <InsuredAutomobileRacingQuestions values={values} clientSigned={clientSigned}
                                                              isFromClientDetail={isFromClientDetail}
                                                              handleChange={handleChange}
                                                              handleBlur={handleBlur}
                                                              errors={errors}
                                                              touched={touched}
                                                              setFieldValue={setFieldValue}
                                                              role={role}
                                                              setSaveAviationAvocationQuestions={setSaveAviationAvocationQuestions}
                                                              offerType={offerType}

                            />
                        </Grid>
                    }

                    {
                        <Grid item xs={12} key={avocationType[1].name}>
                            <FormControlLabel
                                    className="checkGroupMb0"
                                    control={<Checkbox className="padding0"
                                                       checked={values[`${avocationType[1].name}`] ? true : false}
                                                       disabled={clientSigned ? true : false}
                                                       onChange={(e) => {
                                                           setFieldValue(`${avocationType[1].name}`, e.target.checked);
                                                           if (avocationType[1].name === "Insured_Other_Hazardous_Sports_or_Avocations" && !e.target.checked) {
                                                               setFieldValue("Insured_ParticipationDetails", null);
                                                           }
                                                           //handleChangeCheckBox(e, values);
                                                           enableSaveButton();
                                                       }}
                                                       color={"primary"}
                                                       value={values[`${avocationType[1].name}`] ? true : false}
                                                       name={`${avocationType[1].name}`}/>}
                                    label={`${avocationType[1].label}`}
                            />

                            <InsuredParachutingSkyDiving values={values}
                                                         setSaveAviationAvocationQuestions={setSaveAviationAvocationQuestions}
                                                         role={role}
                                                         offerType={offerType}
                                                         clientSigned={clientSigned}
                                                         isFromClientDetail={isFromClientDetail}
                                                         errors={errors}
                                                         handleChange={handleChange}
                                                         handleBlur={handleBlur}
                                                         touched={touched}

                            />
                        </Grid>
                    }
                    {
                        <Grid item xs={12} key={avocationType[2].name}>
                            <FormControlLabel
                                    className="checkGroupMb0"
                                    control={<Checkbox className="padding0"
                                                       checked={values[`${avocationType[2].name}`] ? true : false}
                                                       disabled={clientSigned ? true : false}
                                                       onChange={(e) => {
                                                           setFieldValue(`${avocationType[2].name}`, e.target.checked);
                                                           if (avocationType[2].name === "Insured_Other_Hazardous_Sports_or_Avocations" && !e.target.checked) {
                                                               setFieldValue("Insured_ParticipationDetails", null);
                                                           }
                                                           //handleChangeCheckBox(e, values);
                                                           enableSaveButton();
                                                       }}
                                                       color={"primary"}
                                                       value={values[`${avocationType[2].name}`] ? true : false}
                                                       name={`${avocationType[2].name}`}/>}
                                    label={`${avocationType[2].label}`}
                            />
                            <InsuredUnderwaterDiving values={values}
                                                     setSaveAviationAvocationQuestions={setSaveAviationAvocationQuestions}
                                                     role={role}
                                                     offerType={offerType}
                                                     clientSigned={clientSigned}
                                                     isFromClientDetail={isFromClientDetail}
                                                     errors={errors}
                                                     touched={touched}
                                                     handleChange={handleChange}
                                                     handleBlur={handleBlur}
                            />
                        </Grid>
                    }
                    {
                        <Grid item xs={12} key={avocationType[3].name}>
                            <FormControlLabel
                                    className="checkGroupMb0"
                                    control={<Checkbox className="padding0"
                                                       checked={values[`${avocationType[3].name}`] ? true : false}
                                                       disabled={clientSigned ? true : false}
                                                       onChange={(e) => {
                                                           setFieldValue(`${avocationType[3].name}`, e.target.checked);
                                                           if (avocationType[3].name === "Insured_Other_Hazardous_Sports_or_Avocations" && !e.target.checked) {
                                                               setFieldValue("Insured_ParticipationDetails", null);
                                                           }
                                                           //handleChangeCheckBox(e, values);
                                                           enableSaveButton();
                                                       }}
                                                       color={"primary"}
                                                       value={values[`${avocationType[3].name}`] ? true : false}
                                                       name={`${avocationType[3].name}`}/>}
                                    label={`${avocationType[3].label}`}
                            />
                            <InsuredOtherHazardousSportsAvocations values={values}
                                                                   handleChange={handleChange}
                                                                   enableSaveButton={enableSaveButton}
                                                                   handleBlur={handleBlur}
                                                                   touched={touched} errors={errors}
                                                                   textFieldCommon={textFieldCommon}/>
                        </Grid>

                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default InsuredAvocationSubQuestions;