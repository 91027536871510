import * as React from "react";
import { List } from "@material-ui/core";
import NiwListItem from "../NiwListItem";
import {
    Home as HomeIcon,
    NotificationsNone as NotificationsNoneIcon,
    People as PeopleIcon,
    DateRange as DateRangeIcon,
    OndemandVideo as OndemandVideoIcon,
    Comment as CommentIcon, LocalAtm as LocalAtmIcon
} from "@material-ui/icons";

import { history } from "../../configureStore";
interface listItemType {
    url : string,
    title : string,
    listIcon : any
}

function AggregatorNavigation(props:{ handleNavClick: Function }) {
    const {handleNavClick} = props;
    const [routeName, setRouteName] = React.useState(window.location.pathname);

    const navigationsList = [
        {
            url: '/aggregator-dashboard',
            title: 'Aggregator Dashboard',
            listIcon: <HomeIcon />
        },
        {
            url: '/events',
            title: 'Events & Webinars',
            listIcon: <DateRangeIcon />
        },
        {
            url: '/aggregator/imo',
            title: 'IMO Management',
            listIcon: <PeopleIcon />
        },
        {
            url: '/ilia-notifications',
            title: 'ilia Notifications',
            listIcon: <NotificationsNoneIcon />
        },
        {
            url: '/aggregator/marketing-material',
            title: 'Marketing Materials',
            listIcon: <OndemandVideoIcon />
        },
        {
            url: '/news-social',
            title: 'News & Social Posts',
            listIcon: <CommentIcon />
        },
        {
            url: '/tranche-dates',
            title: 'Tranche Dates',
            listIcon: <LocalAtmIcon />
        }
    ];

    React.useEffect(() => {
        if(window.location.pathname === '/accounts'){
            setRouteName( '/aggregator-dashboard' );
        } else{
            setRouteName( window.location.pathname );
        }

    }, [routeName, history.location.pathname]);

    return (
            <List className="mt20 mb20">
                {
                    navigationsList.map( ( listItem : listItemType ) => {
                                return (
                                        <NiwListItem
                                                key={listItem.url}
                                                url={listItem.url}
                                                title={listItem.title}
                                                listIcon={listItem.listIcon}
                                                routeName={routeName}
                                                setRouteName={setRouteName}
                                                user={null}
                                                handleNavClick={handleNavClick}
                                        />
                                )
                            }
                    )
                }
            </List>
    );
}

export default AggregatorNavigation;