import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import { useStyles } from "../pages/agent/dashboard/TableQuickView";
import { NiwSelect } from "../pages/imo/ImoTab";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutlined";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

type ImosTableProps = {
    rows: any;
    setDeleteImoDivisionPopUp: Function,
    setImoDivisionToDelete: Function
}


export const AggregatorImosTable = ({ rows, setDeleteImoDivisionPopUp, setImoDivisionToDelete }: ImosTableProps) => {
    const classes = useStyles();

    return (
            <TableContainer>
                <Table className={classes.table} aria-label="caption table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell>IMO Name</TableCell>
                            <TableCell>Aggregator Division Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                rows && rows.length === 0 && <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                        <Typography variant="caption" align="left" className="floatLeft">No Aggregator
                                            Found.</Typography>
                                    </TableCell>
                                </TableRow>
                        }
                        {rows.map((row, index) => (
                                <ImoDivisionRow  key={`${row.imoName}-${index}`} row={row}
                                                 setDeleteImoDivisionPopUp={setDeleteImoDivisionPopUp}
                                                 setImoDivisionToDelete={setImoDivisionToDelete}
                                />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    )
}

const ImoDivisionRow = (props: { row: any, setDeleteImoDivisionPopUp: Function, setImoDivisionToDelete: Function }) => {
    const {row, setDeleteImoDivisionPopUp, setImoDivisionToDelete} = props;
    return (
            <TableRow>
                <TableCell>
                    {row.imoName}
                </TableCell>
                <TableCell>{row.divisionName}</TableCell>
                <TableCell>
                    <IconButton onClick={() => {
                        setImoDivisionToDelete(row.imoId);
                        setDeleteImoDivisionPopUp(true);
                    }}>
                        <DeleteIcon className="colorRed"/>
                    </IconButton>
                </TableCell>
            </TableRow>
    )
}