import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { EnrollmentFileUploader as FileUploader } from "../../../../../components/formInputs";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import { LOGO_BASE_URL, ClientDocumentType } from "../../../../../constants";

const IncomeVerificationDocs = (props: any) => {
    const accountActions = useActions(Actions);
    const { clientDetail } = props;
    const user = clientDetail;
    const { client } = user;
    let userId: string = (client && client.userId) || "";
    const identityType = (client && client.identityType) || "";

    const [popupImageSrc, setPopupImageSrc] = React.useState("");

    const [identityFrontPrevPhoto, setIdentityFrontPrevPhoto] = React.useState("");
    const [identityFrontPhoto, setIdentityFrontPhoto] = React.useState("");

    const [identityBackPrevPhoto, setIdentityBackPrevPhoto] = React.useState("");
    const [identityBackPhoto, setIdentityBackPhoto] = React.useState("");

    const [taxDocument, setTaxDocument] = React.useState("");
    const [taxDocumentPrev, setTaxDocumentPrev] = React.useState("");

    const [utilityBillPrevPhoto, setUtilityBillPrevPhoto] = React.useState("");
    const [utilityBillPhoto, setUtilityBillPhoto] = React.useState("");

    const [residenceFrontPrevPhoto, setResidenceFrontPrevPhoto] = React.useState("");
    const [residenceFrontPhoto, setResidenceFrontPhoto] = React.useState("");

    const [residenceBackPrevPhoto, setResidenceBackPrevPhoto] = React.useState("");
    const [residenceBackPhoto, setResidenceBackPhoto] = React.useState("");


    useEffect(() => {
        accountActions.getProfileDetails();
    }, []);

    useEffect(() => {
        if (client && client.clientDocuments && client.clientDocuments.length > 0) {
            for (let key in client.clientDocuments) {
                let documentType = client.clientDocuments[key].documentType;
                let path = client.clientDocuments[key].path;
                let backPath = client.clientDocuments[key].backPath;
                if (documentType === ClientDocumentType.IDENTITY_DOCUMENT) {
                    setIdentityFrontPrevPhoto(path ? LOGO_BASE_URL + path : "");
                    setIdentityFrontPhoto(path ? path : "");

                    setIdentityBackPrevPhoto(backPath ? LOGO_BASE_URL + backPath : "");
                    setIdentityBackPhoto(backPath ? backPath : "");
                } else if (documentType === ClientDocumentType.TAX_DOCUMENT) {
                    setTaxDocumentPrev(path ? LOGO_BASE_URL + path : "");
                    setTaxDocument(path ? path : "");
                } else if (documentType === ClientDocumentType.UTILITY_BILL) {
                    setUtilityBillPrevPhoto(path ? LOGO_BASE_URL + path : "");
                    setUtilityBillPhoto(path ? path : "");
                } else if (documentType === ClientDocumentType.RESIDENCE_DOCUMENT) {
                    setResidenceFrontPrevPhoto(path ? LOGO_BASE_URL + path : "");
                    setResidenceFrontPhoto(path ? path : "");

                    setResidenceBackPrevPhoto(backPath ? LOGO_BASE_URL + backPath : "");
                    setResidenceBackPhoto(backPath ? backPath : "");
                }
            }

        }
    }, [client]);

    const updateDocument = async (key: string, value: string, field: string, documentSubType: string = "") => {
        let payload: { formType?: string, identityFrontPhoto?: string, identityBackPhoto?: string, residenceBackPhoto?: string, residenceFrontPhoto?: string, utilityBillPhoto?: string, taxDocument?: string } = {
            formType: "documents",
            identityFrontPhoto: identityFrontPhoto,
            identityBackPhoto: identityBackPhoto,
            residenceBackPhoto: residenceBackPhoto,
            residenceFrontPhoto: residenceFrontPhoto,
            utilityBillPhoto: utilityBillPhoto,
            taxDocument: taxDocument,
        };
        payload[key] = value;
        payload["documentType"] = field;
        payload["documentSubType"] = documentSubType;
        if (userId) {
            await accountActions.updateUserBasicDetails(payload, userId);
        }

    };

    const fileType: string = "image/*";

    const viewPhotoPopup = () => {

        return (
                <Dialog
                        maxWidth={"sm"}
                        open={popupImageSrc ? true : false}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                    <Typography variant="h6" className="mt20 mb0 "> View Document </Typography>
                    <DialogContent>
                        <div className={"imgWrap"}>
                            <img width="100%" alt="Uploaded Document"
                                 className="img-responsive"
                                 src={popupImageSrc}
                                 title="Identity License"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                                className="mb10 mr15"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setPopupImageSrc("");
                                }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    };


    return (
            <>
                {viewPhotoPopup()}
                <div className="enrollmentContent uploadDocuments">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="textLeft">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" className="mb10 floatLeft"
                                                style={{ display: "flex" }}><InfoIcon
                                            className="floatLeft mr10 textHighLight"/>
                                        You can upload on your client behalf. You cannot delete any client upload
                                        documents.
                                        All documents are also visible under the "document" tab.
                                    </Typography>
                                    <div className="DocumentsWrap mt40">
                                        <div className="content">
                                            <Typography variant="h6">
                                                {identityType === "drivingLicence" ? "Drivers License" : "Passport"}
                                            </Typography>
                                            <Typography variant="body1">
                                                {identityType === "drivingLicence" ?
                                                        <span>
                                                    Front and back of a current, {identityFrontPrevPhoto && identityBackPrevPhoto ?
                                                                <strong>non-expired</strong> : "non-expired"} drivers license
                                                </span>
                                                        :
                                                        <span>
                                                     Photo and signature page of a current {identityFrontPrevPhoto && identityBackPrevPhoto ?
                                                                <strong>non-expired</strong> : "non-expired"} passport to verify your identity
                                                </span>
                                                }
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={identityFrontPrevPhoto ? 5 : 6}
                                                      lg={identityFrontPrevPhoto ? 4 : 6}
                                                      className="textLeft">
                                                    {!identityFrontPrevPhoto ?
                                                            <div className="fileUpload">
                                                                <FileUploader
                                                                        dirName={userId}
                                                                        dirType={"documents"}
                                                                        fileType={fileType}
                                                                        width={15000}
                                                                        height={15000}
                                                                        content={identityType === "drivingLicence" ? "Upload drivers license front" : "Upload passport photo page"}
                                                                        onFinish={async (publicUrl: string, name: string) => {
                                                                            setIdentityFrontPrevPhoto(publicUrl);
                                                                            setIdentityFrontPhoto(userId + "/" + name);
                                                                            await updateDocument("identityFrontPhoto", userId + "/" + name, ClientDocumentType.IDENTITY_DOCUMENT, "front");
                                                                        }}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="imgWrap">
                                                                <img alt="Identity Licence"
                                                                     style={{ maxWidth: 150, maxHeight: 150 }}
                                                                     className="img-responsive floatLeft"
                                                                     src={identityFrontPrevPhoto}
                                                                     title="Identity Licence"/>
                                                                <Button
                                                                        color="primary"
                                                                        variant="contained"
                                                                        onClick={() => {
                                                                            setPopupImageSrc(identityFrontPrevPhoto);
                                                                        }}
                                                                >
                                                                    View
                                                                </Button>
                                                                <Button color="primary" onClick={async () => {
                                                                    setIdentityFrontPrevPhoto("");
                                                                    setIdentityFrontPhoto("");
                                                                    await updateDocument("identityFrontPhoto", "", ClientDocumentType.IDENTITY_DOCUMENT, "front");
                                                                }} variant="contained">Delete</Button>
                                                                <Typography variant="caption"
                                                                            className="floatLeft w100">Front</Typography>
                                                            </div>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md={identityBackPrevPhoto ? 5 : 6}
                                                      lg={identityBackPrevPhoto ? 3 : 6}
                                                      className="textLeft">
                                                    {!identityBackPrevPhoto ? <div className="fileUpload">
                                                                <FileUploader
                                                                        dirName={userId}
                                                                        dirType={"documents"}
                                                                        fileType={fileType}
                                                                        content={identityType === "drivingLicence" ? "Upload drivers license back" : "Upload passport signature page"}
                                                                        width={15000}
                                                                        height={15000}
                                                                        onFinish={async (publicUrl: string, name: string) => {
                                                                            setIdentityBackPrevPhoto(publicUrl);
                                                                            setIdentityBackPhoto(userId + "/" + name);
                                                                            await updateDocument("identityBackPhoto", userId + "/" + name, ClientDocumentType.IDENTITY_DOCUMENT, "back");
                                                                        }}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="imgWrap">
                                                                <img alt="Identity Licence"
                                                                     style={{ maxWidth: 150, maxHeight: 150 }}
                                                                     className="img-responsive floatLeft"
                                                                     src={identityBackPrevPhoto}
                                                                     title="Identity Licence"/>
                                                                <Button color="primary" variant="contained"
                                                                        onClick={() => {
                                                                            setPopupImageSrc(identityBackPrevPhoto);
                                                                        }}>View</Button>
                                                                <Button color="primary" onClick={async () => {
                                                                    setIdentityBackPrevPhoto("");
                                                                    setIdentityBackPhoto("");
                                                                    await updateDocument("identityBackPhoto", "", ClientDocumentType.IDENTITY_DOCUMENT, "back");
                                                                }} variant="contained">Delete</Button>
                                                                <Typography variant="caption"
                                                                            className="floatLeft w100">Back</Typography>
                                                            </div>
                                                    }
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="DocumentsWrap mt40">
                                        <div className="content">
                                            <Typography variant="h6">Tax Documents</Typography>
                                            <Typography variant="body1">Two years of w2’s, tax returns, or 1099’s for
                                                income verification.</Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="textLeft">
                                                    {
                                                        taxDocumentPrev &&
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={5}>
                                                                <div className="imgWrap">
                                                                    <img alt="Tax Document"
                                                                         style={{ maxWidth: 150, maxHeight: 160 }}
                                                                         className="img-responsive"
                                                                         src={taxDocumentPrev}
                                                                         title="Tax"
                                                                    />
                                                                    <Button color="primary" variant="contained"
                                                                            onClick={() => {
                                                                                setPopupImageSrc(taxDocumentPrev);
                                                                            }}>View</Button>
                                                                    <Button color="primary" onClick={async () => {
                                                                        setTaxDocumentPrev("");
                                                                        setTaxDocument("");
                                                                        await updateDocument("taxDocument", "", ClientDocumentType.TAX_DOCUMENT);
                                                                    }} variant="contained">Delete</Button>
                                                                </div>
                                                            </Grid>
                                                        </Grid>

                                                    }
                                                    {!taxDocumentPrev &&
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={5}>
                                                            <div className="fileUpload">
                                                                <FileUploader
                                                                        dirName={userId}
                                                                        dirType={"documents"}
                                                                        fileType={fileType}
                                                                        content={"Upload documents"}
                                                                        width={15000}
                                                                        height={15000}
                                                                        onFinish={async (publicUrl: string, name: string) => {
                                                                            setTaxDocumentPrev(publicUrl);
                                                                            setTaxDocument(userId + "/" + name);
                                                                            await updateDocument("taxDocument", userId + "/" + name, ClientDocumentType.TAX_DOCUMENT);
                                                                        }}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                                {/*{client && client.identityType === 'drivingLicence' && !client.isDocumentAddress &&*/}
                                <Grid item xs={12}>
                                    <div className="DocumentsWrap mt40">
                                        <div className="content">
                                            <Typography variant="h6">Recent Utility Bill</Typography>
                                            <Typography variant="body1">An electric bill, water bill or gas bill less
                                                than 60 days old to verify your current address.</Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="textLeft">
                                                    {!utilityBillPrevPhoto &&
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={5}>
                                                            <div className="fileUpload">
                                                                <FileUploader
                                                                        dirName={userId}
                                                                        dirType={"documents"}
                                                                        fileType={fileType}
                                                                        content={"Upload utility bill"}
                                                                        width={15000}
                                                                        height={15000}
                                                                        onFinish={async (publicUrl: string, name: string) => {
                                                                            setUtilityBillPrevPhoto(publicUrl);
                                                                            setUtilityBillPhoto(userId + "/" + name);
                                                                            await updateDocument("utilityBillPhoto", userId + "/" + name, ClientDocumentType.UTILITY_BILL);
                                                                        }}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    }
                                                    {
                                                        (utilityBillPrevPhoto
                                                                &&
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={5}>
                                                                        <div className="imgWrap">
                                                                            <img alt="Identity Licence"
                                                                                 style={{
                                                                                     maxWidth: 150,
                                                                                     maxHeight: 150,
                                                                                 }}
                                                                                 className="img-responsive"
                                                                                 src={utilityBillPrevPhoto}
                                                                                 title="Identity Licence"/>
                                                                            <Button color="primary" variant="contained"
                                                                                    onClick={() => {
                                                                                        setPopupImageSrc(utilityBillPrevPhoto);
                                                                                    }}
                                                                            >View</Button>
                                                                            <Button color="primary"
                                                                                    onClick={async () => {
                                                                                        setUtilityBillPrevPhoto("");
                                                                                        setUtilityBillPhoto("");
                                                                                        await updateDocument("utilityBillPhoto", "", ClientDocumentType.UTILITY_BILL);
                                                                                    }}
                                                                                    variant="contained">Delete</Button>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="DocumentsWrap mt40">
                                        <div className="content">
                                            <Typography variant="h6">Visa, Green Card, or Permanent Residency Card
                                            </Typography>
                                            <Typography variant="body1">Please upload front and back of a visa, green
                                                card, or permanent residency card to verify legal U.S. residency status.
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={residenceFrontPrevPhoto ? 5 : 6}
                                                      lg={residenceFrontPrevPhoto ? 4 : 6}
                                                      className="textLeft">
                                                    {!residenceFrontPrevPhoto ?
                                                            <div className="fileUpload">
                                                                <FileUploader
                                                                        dirName={userId}
                                                                        dirType={"documents"}
                                                                        fileType={fileType}
                                                                        content={"Upload document front"}
                                                                        width={15000}
                                                                        height={15000}
                                                                        onFinish={async (publicUrl: string, name: string) => {
                                                                            setResidenceFrontPrevPhoto(publicUrl);
                                                                            setResidenceFrontPhoto(userId + "/" + name);
                                                                            await updateDocument("residenceFrontPhoto", userId + "/" + name, ClientDocumentType.RESIDENCE_DOCUMENT, "front");
                                                                        }}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="imgWrap">
                                                                <img alt="Identity Licence"
                                                                     style={{ maxWidth: 150, maxHeight: 150 }}
                                                                     className="img-responsive"
                                                                     src={residenceFrontPrevPhoto}
                                                                     title="Identity Licence"/>
                                                                <Button color="primary" variant="contained"
                                                                        onClick={() => {
                                                                            setPopupImageSrc(residenceFrontPrevPhoto);
                                                                        }}
                                                                >View</Button>
                                                                <Button color="primary" onClick={async () => {
                                                                    setResidenceFrontPrevPhoto("");
                                                                    setResidenceFrontPhoto("");
                                                                    await updateDocument("residenceFrontPhoto", "", ClientDocumentType.RESIDENCE_DOCUMENT, "front");
                                                                }} variant="contained">Delete</Button>
                                                                <Typography variant="caption"
                                                                            className="floatLeft w100">Front</Typography>
                                                            </div>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md={residenceBackPrevPhoto ? 5 : 6}
                                                      lg={residenceBackPrevPhoto ? 4 : 6}
                                                      className="textLeft">
                                                    {!residenceBackPrevPhoto ?
                                                            <div className="fileUpload">
                                                                <FileUploader
                                                                        dirName={userId}
                                                                        dirType={"documents"}
                                                                        fileType={fileType}
                                                                        content={"Upload document back"}
                                                                        width={15000}
                                                                        height={15000}
                                                                        onFinish={async (publicUrl: string, name: string) => {
                                                                            setResidenceBackPrevPhoto(publicUrl);
                                                                            setResidenceBackPhoto(userId + "/" + name);
                                                                            await updateDocument("residenceBackPhoto", userId + "/" + name, ClientDocumentType.RESIDENCE_DOCUMENT, "back");
                                                                        }}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="imgWrap">
                                                                <img alt="Identity Licence"
                                                                     style={{ maxWidth: 150, maxHeight: 150 }}
                                                                     className="img-responsive"
                                                                     src={residenceBackPrevPhoto}
                                                                     title="Identity Licence"/>
                                                                <Button color="primary" variant="contained"
                                                                        onClick={() => {
                                                                            setPopupImageSrc(residenceBackPrevPhoto);
                                                                        }}
                                                                >View</Button>
                                                                <Button color="primary" onClick={async () => {
                                                                    setResidenceBackPrevPhoto("");
                                                                    setResidenceBackPhoto("");
                                                                    await updateDocument("residenceBackPhoto", "", ClientDocumentType.RESIDENCE_DOCUMENT, "back");
                                                                }} variant="contained">Delete</Button>
                                                                <Typography variant="caption"
                                                                            className="floatLeft w100">Back</Typography>
                                                            </div>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </>
    );
};

export default IncomeVerificationDocs;
