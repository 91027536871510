import React from "react";
import {
    Button,
    Dialog,
    DialogContent,
    Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

interface deleteModelProps {
    accountType?: string,
    title?: string,
    content?: string,
    open: boolean,
    disableDelete?: boolean,
    handleClose: () => void,
    handleYes: () => void
}

const defaultTitle = "Jamin Austin";
const defaultContent = "Are you sure want to delete permanently ?";
export default function DeleteModel({
                                        open,
                                        handleClose,
                                        accountType = "",
                                        title = defaultTitle,
                                        content = defaultContent,
                                        disableDelete = false,
                                        handleYes,
                                    }: deleteModelProps) {
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"xs"}
                    className="customModal"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                {
                    accountType !== "image" &&
                    <div id="alert-dialog-title" className="pt20 pr30 pl30 mb0">
                        <PersonIcon/> <Typography component="span" className="mb0"> <strong className="w100 floatLeft">{title}</strong>
                        <i className="f12 w100 floatLeft">{`${accountType} Account`}</i></Typography>
                    </div>
                }
                <DialogContent>
                    <Typography variant="body1" className="mb0">{content}</Typography>



                    <Button className="bgRed mt20 ml10 floatRight"
                            type="submit"
                            size="small"
                            variant="contained"
                            color="primary" onClick={handleClose}>
                        {!disableDelete ? "No" : "Cancel"}
                    </Button>
                    {!disableDelete && <Button
                            className="mt20 floatRight"
                            type="submit"
                            size="small"
                            variant="contained"
                            color="primary" onClick={handleYes} autoFocus>
                        Yes
                    </Button>}
                </DialogContent>
            </Dialog>
    );
}
