import * as React from "react";
import {
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    Button,
    Typography,
    Tooltip
} from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {NotificationHeadRow} from "../../model/notification";
import {dateUtils} from "../../utils";
import {accountTypeDropDown} from "../../constants";
import {useActions} from "../../actions";
import * as NotificationActions from "../../actions/notification";
import LeadDeleteConfirmation from "../lead/LeadDeleteConfirmation";
import CreateIcon from "@material-ui/icons/Create";


const headRows: NotificationHeadRow[] = [
    { id: "title", label: "Title" },
    { id: "text", label: "Text" },
    { id: "recipients", label: "Recipient" },
    { id: "status", label: "Status" },
    { id: "createdAt", label: "Date Created" },
    { id: "action", label: "Action" },
];

function NotificationTable(props: any) {
    const {editNotification, setFieldValue, recipients, setNotificationEditID} = props;
    const [isMore, setIsMore] = React.useState([]);
    const [popupId, setPopupId] = React.useState("");
    const { notificationList } = props;
    const notificationActions = useActions(NotificationActions);


    const getRolesString = (ids: any) => {
        let getroles = [{ id: 1, name: "All" }, ...accountTypeDropDown].filter((role: any) => {
            return ids.indexOf(role.id) > -1;
        })
        return getroles.map((role: any, index: any) => {
            if (getroles.length === (index + 1)) {
                return role.id === 5 ? 'Participants' : role.name;
            } else {
                return role.name + ", ";
            }
        });
    }


    const showToggle = (isTrue, id) => {
        let isMore: any = [];
        isMore[id] = isTrue;
        setIsMore(isMore);
    };

    const showMoreHtml = (isTrue, id) => {
        return <>
            <div className="clearFix"/>
            <Button className="btnSmall" variant={"text"} color={"primary"}
                         onClick={() => showToggle(isTrue, id)}> {isTrue ? "View more" : "View less"} </Button>

        </>;
    };

    const deleteNotification = async () => {
        let response = await notificationActions.deleteAdminNotification(popupId);
        setPopupId("");
        if (response.success) {
            props.getNotifications();
        }
    };

    function urlify(text) {
        let urlRegex = /( https?:\/\/[^\s]+)/g;
        // text = text.replace(/(<p>https?:\/\/[^\s]+)/g, function(url) {
        //     // url = url.replace("<p>", '');
        //     let textLength = url.split("<");
        //     console.log("****textLength***", textLength);
        //     return '<a target="_blank" href="' + url + '">' + url + '</a>';
        // })
        return text.replace(urlRegex, function(url) {
            url = url.replace("</p>", '');
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        })
    }

    return (
            <React.Fragment>
                <TableHead>
                    <TableRow>
                        {headRows.map((row: any, idx: number) => (
                                <TableCell
                                        key={`${idx}-${row.id}`}
                                        padding={"default"}
                                >
                                    {row.label}
                                </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (!notificationList.rows || notificationList.rows.length === 0)
                        && <TableRow>
                            <TableCell align="center" colSpan={7}>
                                <Typography align="left">No Notifications</Typography>
                            </TableCell>
                        </TableRow>
                    }

                    {notificationList.rows && notificationList.rows.map((account: any) => {
                        return (
                                <TableRow hover key={account.id}>
                                    <TableCell>{account.title}</TableCell>
                                    <TableCell>
                                        <div className="pages_container">
                                            {!isMore[account.id] && account.text !== "" && account.text.trim().length > 200 ?
                                                    <>
											        <span
                                                    dangerouslySetInnerHTML={{ __html: urlify(account.text.trim().substring(0, 200)) }}/>
                                                        {showMoreHtml(true, account.id)}
                                                    </>
                                                    :
                                                    !isMore[account.id] &&
                                                    <div dangerouslySetInnerHTML={{ __html: urlify(account.text) }}/>

                                            }
                                            {isMore[account.id] &&
                                            <>
                                                <span dangerouslySetInnerHTML={{ __html: urlify(account.text) }}/>
                                                {showMoreHtml(false, account.id)}
                                            </>
                                            }

                                        </div>
                                    </TableCell>
                                    <TableCell>{getRolesString(JSON.parse(account.recipients))}</TableCell>
                                    <TableCell>{account.status === 1 ? "Pending" : "Sent"}</TableCell>
                                    <TableCell>{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>
                                        <Tooltip title="Edit">
                                            <IconButton
                                                    color="default"
                                                    onClick={ () => {
                                                        editNotification(account)
                                                        setFieldValue('recipients', recipients)
                                                        setNotificationEditID(account.id)
                                                    }}
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <IconButton onClick={() => setPopupId(account.id)}>
                                            <DeleteOutlineIcon className="colorRed"/>
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                        );
                    })
                    }
                </TableBody>
                {!!popupId &&
                <LeadDeleteConfirmation
                        success={deleteNotification}
                        close={() => setPopupId("")}
                />
                }
            </React.Fragment>
    );
}

export default NotificationTable;
