import React from 'react';
import numeral from "numeral";
import {Checkbox, Grid, Typography} from "@material-ui/core";
import NumberFormat from "react-number-format";
import GetFormContent from "../../Beneficiaries/BeneficiariesList/GetFormContent";
import {Formik} from "formik";
import { payloadDataHandler } from "../index";

type PropsType= {
    autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void,
    initialValues: any,
    validationSchema: any,
    updateClientFields: Function
    isConfirmCheckbox: boolean,
    user: any,
    isFromClientDetail: boolean
    //setIsError: Function,
    fieldObj: any,
    setIsConfirmCheckbox: Function,
    liabilitiesVerifyRef: any,
    setFinancialAndLiabilitiesArr: Function,
    financialAndLiabilitiesArr: any,
    liabilitiesRef:any
    setSaveFormContent:Function
    formType:string
    setFormType:Function

}
function LiabilitiesForm(props: PropsType){
    const {
        //setIsError,
        autoSaveTrigger,
        fieldObj,
        financialAndLiabilitiesArr,
        formType,
        initialValues,
        isConfirmCheckbox,
        isFromClientDetail,
        liabilitiesRef,
        liabilitiesVerifyRef,
        setFinancialAndLiabilitiesArr,
        setFormType,
        setIsConfirmCheckbox,
        setSaveFormContent,
        validationSchema,
    } = props;
    return (
            <Formik
                    innerRef={liabilitiesRef}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    onSubmit={async (values: any, { setSubmitting }) => {
                        try {
                            let payload:any = payloadDataHandler(values, "LIABILITIES", isConfirmCheckbox);
                            // await updateClientFields({   // already commented before task NIWC-1830
                            //     ...payload,
                            //     verifyLiabilities: isConfirmCheckbox ? 1 : 0,
                            //     formType: "LIABILITIES",
                            // }, user && user.client && user.client.id || "", isFromClientDetail);
                            const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "LIABILITIES")
                            if(index > -1) {
                                financialAndLiabilitiesArr.splice(index, 1);
                            }
                            setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
                                ...payload
                            }]);
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 400);
                        } catch (e) {
                            return false;
                        }
                    }}
            >
                {
                    (formikProps) => {
                       /* if (Object.keys(formikProps.errors).length > 0) {
                            setTimeout(() => setIsError(true), 500);
                        } else {
                            if (formikProps.dirty) {
                                setTimeout(() => setIsError(false), 500);
                            }
                        }*/
                        let totalValues: number = 0;
                        Object.keys(formikProps.values).map((key) => {
                            if (key === "otherLiabilitiesDetails") {
                                return null;
                            }
                            const value = formikProps.values[key];
                            totalValues = totalValues + (numeral(value).value() || 0);
                            return true;
                        });
                        return (
                                <form className="floatLeft w100" onBlur={autoSaveTrigger} onSubmit={formikProps.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <span className="hLine"/>
                                            <Typography variant="body1" component="strong"
                                                        className="floatLeft w100 mb20">
                                                Liabilities
                                                <span className="floatRight">
                                                            Total Liabilities:
                                                            <NumberFormat
                                                                    value={totalValues || 0}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    prefix={"$"}
                                                            />
                                                        </span>
                                            </Typography>
                                            <Typography variant="caption"
                                                        className="textLight mb15 floatLeft w100">
                                                Fill out or verify any liabilities you have below making any
                                                necessary changes. If a particular field doesn’t apply you can
                                                leave it blank.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <GetFormContent isFromClientDetail={isFromClientDetail} fieldObj={fieldObj} {...formikProps}
                                                    enableOnChangeSubmit={false}
                                                    setSaveFormContent={setSaveFormContent}
                                                    formType={formType}
                                                    setFormType={setFormType}
                                    />
                                    {!isFromClientDetail &&
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div style={{ display: "flex", alignItems:'center' }} className="mt20" ref={liabilitiesVerifyRef} id="liabilitiesVerifyRef">
                                                <Checkbox style={{ height: "28px", marginRight: "10px" }}
                                                          className="floatLeft pd0 mr10" color={"primary"}
                                                          value={isConfirmCheckbox ? true : false}
                                                          checked={isConfirmCheckbox ? true : false}
                                                          onChange={(e, v) => {
                                                              //setIsError(!v);
                                                              setIsConfirmCheckbox(v);
                                                              if(!!formType && typeof setFormType === 'function') {
                                                                  setFormType(formType)
                                                              }
                                                              // updateClientFields({
                                                              //     verifyLiabilities: v ? 1 : 0,
                                                              //     apiType: "LIABILITIES",
                                                              // }, user && user.client && user.client.id || "", isFromClientDetail);
                                                          }}
                                                          inputProps={{ "aria-label": "uncontrolled-checkbox" }}/>
                                                <Typography variant="caption">
                                                    <span>I verify that my total Liabilities are </span>
                                                    <NumberFormat
                                                            value={totalValues || 0}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            prefix={"$"}
                                                    />.
                                                    <span className="requiredHighlight">*</span>
                                                </Typography>
                                            </div>
                                            {
                                                !isConfirmCheckbox &&
                                                <p style={{margin: 0}}>
                                                    <span style={{color: "#FF1744", fontSize: 12}}> Required Field </span>
                                                </p>
                                            }
                                        </Grid>
                                    </Grid>
                                    }
                                </form>
                        );
                    }}
            </Formik>
    )
}
export default LiabilitiesForm;