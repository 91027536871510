import React, { useEffect } from 'react';

export default function Chatbot() {
    useEffect(() => {
        // Create a new script element
        const script = document.createElement('script');

        // Set attributes for the script
        script.src = 'https://www.chatbase.co/embed.min.js';
        script.setAttribute('chatbotId', 'LoCjzmMXJgX_YkrO4wxhf');
        script.setAttribute('domain', 'www.chatbase.co');

        // Append the script to the document body
        document.body.appendChild(script);

        // Clean up function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
         <></>
    );
};
