import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { EnrollmentFileUploader as FileUploader, Label } from "../formInputs";
import { Grid } from "@material-ui/core";
import { useActions } from "../../actions";
import * as Actions from "../../actions/account";
import * as ClientActions from "../../actions/client";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ErrorCommon, NumberTextField, TextFieldCommon } from "../formikFormInputs";
import { Formik } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import { unmaskCurrency } from "../../utils/commonUtils";
import { cashStreamSlug } from "../../constants";
import moment from "moment";

const styles: any = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: '10px',
        top: '16px',
        color: theme.palette.grey[500],
    },
});

const DialogTitle: any = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="body1" component="strong">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const UploadProposalPopUp = (props: { open: boolean, setUploadProposalPopUp: Function, enrollmentId: string, clientId: number, agentId: number, clientUserId?: string, refreshList: Function, client : any, proposalUpdate?: boolean, currentDocument: any })=> {
    const { open, setUploadProposalPopUp, enrollmentId, clientId, agentId, refreshList, proposalUpdate, currentDocument  } = props;
    const { getClient } = useActions(ClientActions);
    const [fileName, setFileName] = React.useState(""); // client proposal
    const [fileName2, setFileName2] = React.useState(""); // client illustration
    const accountActions = useActions(Actions);
    const [cashStream, setCashStream] = React.useState(false); // cash stream strategy
    const [employerOnly, setEmployerOnly] = React.useState(false); // employer only
    const [issuedCon, setIssueCon] = React.useState(true);
    const {client} = props;

    React.useEffect(() => {
        if (client && client.strategy && client.strategy.slug === cashStreamSlug) {
            setCashStream(true);
        }
        if (client && client.company && client.company.contributionType &&
                client.company.contributionType === "EMPLOYER_ONLY") {
            setEmployerOnly(true);
        }
    }, [client]);

    React.useEffect(()=>{
        if(cashStream || employerOnly){
            setIssueCon(false);
        }
    },[cashStream, employerOnly])
    const handleClose = () => {
        setUploadProposalPopUp(false);
    };

    const handleUploadProposal = async (values:any, setSubmitting:Function) => {
        let issuedContribution = Number(unmaskCurrency(values.issuedContribution.toString()));
        let employerContribution = Number(unmaskCurrency(values.employerContribution.toString()));
        let deathBenefit = Number(unmaskCurrency(values.deathBenefit.toString()));
        if (cashStream || employerOnly) {
            issuedContribution = 0;
        }
        if(proposalUpdate){
            await accountActions.replaceProposalForAdvisor({
                issuedContribution: issuedContribution,
                employerContribution: employerContribution,
                deathBenefit: deathBenefit,
                fileName,
                name: currentDocument && currentDocument.name,
                clientId
            });
        } else {
            await accountActions.uploadProposalForAdvisor({
                issuedContribution: issuedContribution,
                employerContribution: employerContribution,
                deathBenefit: deathBenefit,
                "fileName": { "uploadProposal": fileName, "uploadIllustration": fileName2 },
                clientId,
                agentId,
                enrollmentId
            });
        }
        await getClient(clientId);
        setSubmitting(false);
        handleClose();
        if (typeof refreshList === "function") {
            refreshList();
        }
    };

    const initialValues = {
        issuedContribution: (client && client.contributionAmount) || '',
        deathBenefit : (client && client.deathBenefit) || '',
        employerContribution: (employerOnly && client && client.employerContribution) || ''
    };
    const validate = ((value: any) => {
        const errors: { issuedContribution?: string, deathBenefit?:string, employerContribution? : string } = {};
        if (issuedCon && (!value.issuedContribution || value.issuedContribution < 1)) {
            errors.issuedContribution = "Required";
        }
        if (employerOnly && (!value.employerContribution || value.employerContribution < 1)) {
            errors.employerContribution = "Required";
        }
        if (!value.deathBenefit || value.deathBenefit < 1) {
            errors.deathBenefit = "Required";
        }
        return errors;
    });

    const fieldObj = [
        {
            id:'issuedContribution',
            name: "issuedContribution",
            label: <Label label={"First Years Contribution Amount"} required={true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            disabled: false,
            InputProps:{
                startAdornment: <InputAdornment className="dollerIcon"
                                            position="start">$</InputAdornment>,
             }
        },
        {
            id:'employerContribution',
            name: "employerContribution",
            label: <Label label={"Enter Employer Contribution"} required={true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            disabled: false,
            InputProps:{
                startAdornment: <InputAdornment className="dollerIcon"
                                                position="start">$</InputAdornment>,
            }
        },
        {
            id:'deathBenefit',
            name: "deathBenefit",
            label: <Label label={"Death Benefit"} required={true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            disabled: false,
            InputProps:{
                startAdornment: <InputAdornment className="dollerIcon"
                                                position="start">$</InputAdornment>,
            }
        }
    ];

    return (
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle className="closeSvg" id="customized-dialog-title" onClose={handleClose}>
                    Upload New or Re-Send Proposal For Approval
                </DialogTitle>
                <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validate={values => validate(values)}
                        validateOnChange={true}
                        onSubmit={(values, { setSubmitting }) => {
                            handleUploadProposal(values, setSubmitting);
                        }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          dirty,
                      }) => (
                            <form onSubmit={handleSubmit}>
                                <DialogContent dividers>
                                    <>
                                        <Typography variant="body1" align="center" className="f18">
                                            Select a proposal you have already uploaded or upload a new one and send to
                                            the agent for
                                            approval. Additionally, you need to enter the first years contribution amount
                                            so the client
                                            understands what they need to send as payment.
                                        </Typography>
                                        <Grid container spacing={2} justify="center">
                                            {fieldObj.map((field, index) => {
                                            if (field.name === "issuedContribution" && (cashStream || employerOnly)) {
                                                return <></>;
                                            }
                                            if (field.name === "employerContribution" && !employerOnly){
                                                return <></>;
                                            }
                                                return (
                                                <Grid item xs={12} md={8} className="textCenter" key={index}>
                                                            <TextFieldCommon
                                                                    {...field}
                                                                    values={values}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                            />
                                                            <ErrorCommon errors={errors} name={field.name}
                                                                         touched={touched}/>
                                                        </Grid>
                                                );
                                            })
                                            }
                                            <Grid item xs={12} md={6} className="textCenter">
                                                <Typography variant="subtitle2" component="strong"><Label
                                                        label={"Upload Proposal"}
                                                        required={true}/></Typography>
                                                <FileUploader
                                                        exactFileName={"Client Proposal - " + moment().format("DD-MM-YYYY HH:mm:ss")}
                                                        dirName={enrollmentId}
                                                        dirType={"documents"}
                                                        fileType={"application/pdf, .pdf"}
                                                        content={"Drop files here or click to upload"}
                                                        onFinish={async (publicUrl: string, name: string) => {
                                                            setFileName(name);
                                                        }}
                                                        textWithIcon={"Upload Proposal"}
                                                        iconOnly={true}
                                                />
                                                {fileName !== '' &&
                                                <>
                                                    {fileName}
                                                    <DeleteForeverIcon
                                                            onClick={() => setFileName("")}
                                                            color="error"
                                                            style={{ verticalAlign: "bottom", cursor: "pointer" }}
                                                    />
                                                </>
                                                }
                                            </Grid>
                                            {!proposalUpdate && <Grid item xs={12} md={6} className="textCenter">
                                                <Typography variant="subtitle2" component="strong"><Label
                                                        label={"Upload Illustration"}
                                                        required={true}/></Typography>
                                                <FileUploader
                                                        exactFileName={"Client Illustration - " + moment().format("DD-MM-YYYY HH:mm:ss")}
                                                        dirName={enrollmentId}
                                                        dirType={"documents"}
                                                        fileType={"application/pdf, .pdf"}
                                                        content={"Drop files here or click to upload"}
                                                        onFinish={async (publicUrl: string, name: string) => {
                                                            setFileName2(name);
                                                        }}
                                                        textWithIcon={"Upload Illustration"}
                                                        iconOnly={true}
                                                />
                                                {fileName2 !== '' &&
                                                <>
                                                    {fileName2}
                                                    <DeleteForeverIcon
                                                            onClick={() => setFileName2("")}
                                                            color="error"
                                                            style={{ verticalAlign: "bottom", cursor: "pointer" }}
                                                    />
                                                </>
                                                }
                                            </Grid>}
                                        </Grid>
                                    </>
                                </DialogContent>
                                <DialogActions>
                                    <Grid item xs={12} className="textRight">
                                        {!proposalUpdate && <Button
                                            variant="contained"
                                            color="primary"
                                            className="floatRight"
                                            disabled={isSubmitting || (fileName == "" || fileName2 == "")}
                                            type={"submit"}
                                            >
                                            Send For Approval
                                            </Button>
                                        }
                                        {(proposalUpdate && fileName !== '') &&
                                            <Button
                                            variant="contained"
                                            color="primary"
                                            className="floatRight"
                                            disabled={isSubmitting}
                                            type={"submit"}
                                            >
                                            Replace Proposal
                                            </Button>
                                        }
                                    </Grid>
                                </DialogActions>
                            </form>
                    )}
                </Formik>
    </Dialog>
    );
}

export default UploadProposalPopUp;
