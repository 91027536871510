import React from "react";
import { Button, Typography } from "@material-ui/core";
import { TextField } from "../../../../../components/formikFormInputs";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useActions } from "../../../../../actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import * as ClientActions from "../../../../../actions/client";

export default (props: {
    policyNumber: number,
    description: string,
    onClose: Function,
    removeCaseAlert: boolean,
    handleUpdateAlert: Function
}) => {
    const { policyNumber, description, removeCaseAlert } = props;
    const clientActions = useActions(ClientActions);
    const detail: any = useSelector((state: RootState) => state.clientAccount);
    const clientCaseAlert: any = detail && detail.client && detail.client.ClientCaseAlerts && detail.client.ClientCaseAlerts.id;
    const onSuccess = (result: any) => {
        if (result && result.payload && result.payload.statusCode === 200) {
            props.onClose();
            props.handleUpdateAlert();
        }
    };
    const createCaseAlert = async (values) => {
        if (policyNumber) {
            let result = await clientActions.createCaseAlert({
                description: values && values.description,
                clientId: detail && detail.client && detail.client.id,
                policyNumber: policyNumber,
            });
            onSuccess(result);
        }
    };
    const updateCaseAlert = async (values) => {
        if (policyNumber) {
            let result = await clientActions.updateCaseAlert({
                description: values && values.description,
                clientId: detail && detail.client && detail.client.id,
                policyNumber: policyNumber,
            });
            onSuccess(result);
        }
    };
    const deleteCaseAlert = async (values) => {
        if (clientCaseAlert) {
            let result = await clientActions.deleteCaseAlert({
                id: clientCaseAlert,
            });
            onSuccess(result);
        }
    };
    return (<>
                {policyNumber &&
                        <Formik
                                initialValues={{ description: description }}
                                onSubmit={removeCaseAlert ? deleteCaseAlert : clientCaseAlert ? updateCaseAlert : createCaseAlert}
                                validationSchema={Yup.object({
                                    description: Yup.string().required(),
                                })}
                                validateOnMount
                        >
                            {({
                                  values,
                                  handleSubmit,
                                  handleChange,
                                  handleBlur,
                                  isSubmitting,
                                  isValid,
                              }) => (
                                    <form onSubmit={handleSubmit}>
                                        {removeCaseAlert ?
                                                <Typography variant="body1"> Are your sure want to delete it
                                                    ? </Typography> :
                                                <Field
                                                        name="description"
                                                        component={TextField}
                                                        placeholder="Write Case..."
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                />
                                        }
                                        <Button variant="contained" color="secondary"
                                                className="floatRight mt20 mb10 ml20"
                                                onClick={() => props.onClose()}>Cancel</Button>
                                        <Button variant="contained" color={removeCaseAlert ? "inherit" : "primary"}
                                                className={removeCaseAlert ? "bgRed floatRight mt20 mb10" : "floatRight mt20 mb10"}
                                                type="submit"> {removeCaseAlert ? "Delete Case Alert" : clientCaseAlert ? "Update Case Alert" : "Create Case Alert"} </Button>
                                    </form>
                            )}
                        </Formik>
                }
                {!policyNumber &&
                        <p style={{ margin: 0 }}>
                            <span style={{
                                color: "#FF1744",
                                fontSize: 16,
                            }}> The Policy Number is Missing for Client <strong>{detail && detail.firstName} {detail && detail.lastName}</strong></span>
                        </p>
                }
            </>
    );
}
