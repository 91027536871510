import React from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import BeneficiariesList from "./BeneficiariesList";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../reducers";
import CommunityStateSelection from "./CommunityStateSelection";
import StrategyName from "../../../../../../components/client/StrategyName";
import {isIliaApp} from "../Utilities";

const Page = (props: any) => {
    const {setNextStep, isFromClientDetail, setActionItem, stateValue, setCommunityStateVal, setStateValue, offerType} = props;
    let userData = useSelector((state: RootState) => state.profile);
    let auth = useSelector((state: RootState) => state.auth);
    let user = props.clientDetail || userData;
    const strategyName = (user.client && user.client.strategy && user.client.strategy.name) || "Kai-Zen";
    const [isCommunityState, setIsCommunityState] = React.useState(false);
    const [marriageStatusValue, setMarriageStatusValue] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isErrors, setIsErrors] = React.useState(true);
    const [percent, setPercent] = React.useState(0);
    const [primaryBeneficiaryCount, setPrimaryBeneficiaryCount] = React.useState(0);

    return (
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Typography variant="body1">
                        Primary beneficiary(ies) receives the <StrategyName strategyName={strategyName} withText={true} ucFirst={true}/> benefit (minus loan repayment) at the time of
                        the insured’s death. If there are no living primary beneficiaries at the time of the insured’s
                        death, the benefits will be paid to contingent beneficiary(ies). If insured’s policy has more
                        than one primary beneficiary, benefits will be divided equally among the living beneficiaries,
                        unless specific percentage is otherwise specified. <strong>The percentages for all the primary
                        beneficiaries must total 100%</strong>, and all contingent beneficiaries must total 100%. If a
                        beneficiary dies before the insured, the shares will be reallocated to the living beneficiaries
                        (prorated by percentage). If none of the listed beneficiaries are living at the time of the
                        insured’s death, the benefits will be paid to the insured’s Estate. We cannot accept any UTMAs.
                    </Typography>
                </Grid>
                <CommunityStateSelection
                        setActionItem={(flag:boolean)=>setActionItem(flag)}
                        setIsCommunityState={(flag) => setIsCommunityState(flag)}
                        marriageStatusValue={marriageStatusValue}
                        setMarriageStatus={(flag) => setMarriageStatusValue(flag)} user={user}
                        setIsSubmitting={(flag: boolean) => setIsSubmitting(flag)}
                        setIsErrors={(flag: boolean) => setIsErrors(flag)}
                        isFromClientDetail={isFromClientDetail}
                        auth={auth}
                        stateValue={stateValue}
                        setCommunityStateVal={setCommunityStateVal}
                        setStateValue={setStateValue}
                />
                <BeneficiariesList beneficiaryType={"Primary"} className={"mt30 mb0 floatLeft w100"} user={user}
                                   isFromClientDetail={isFromClientDetail} isErrors={isErrors} setPercent={(value:number)=> setPercent(value)}
                                   isCommunityState={isCommunityState} marriageStatusValue={marriageStatusValue} setPrimaryBeneficiaryCount={(count:number)=>setPrimaryBeneficiaryCount(count)}/>
                <BeneficiariesList beneficiaryType={"Contingent"} className={"mt60 mb0 floatLeft w100"} user={user}
                                   isFromClientDetail={isFromClientDetail} isErrors={isErrors} setPercent={(value:number)=> {}}
                                   isCommunityState={isCommunityState} marriageStatusValue={marriageStatusValue}/>
                {!isFromClientDetail &&
                <Grid item xs={12} md={12}>
                    <Button
                            variant="contained"
                            color="primary"
                            className="floatRight mb30 mt20"
                            disabled={isSubmitting || percent < 100}
                            onClick={() => {
                                if (!isSubmitting) {
                                    let detailPageSubmitBtn: any = document.getElementsByClassName("detailPageSubmitBtn");
                                    if (detailPageSubmitBtn && detailPageSubmitBtn.length && percent >= 100) {
                                        for (let index in detailPageSubmitBtn) {
                                            if (detailPageSubmitBtn[index] && detailPageSubmitBtn[index].click) {
                                                detailPageSubmitBtn[index].click();
                                            }
                                        }
                                        if (!isErrors && primaryBeneficiaryCount > 0) {
                                            setNextStep();
                                        }
                                    }
                                }
                            }}
                            type={"submit"}
                    >
                        {!!offerType && isIliaApp(offerType) ?  "Continue to Sign" : "Save and Continue"}
                    </Button>
                </Grid>
                }
            </Grid>
    );
};

export default Page;