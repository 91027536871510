import React from "react";
import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";
import {
    AppBar,
    IconButton,
    MenuItem,
    Menu,
    Link,
    Typography,
    Container, MenuList,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import iliaMini from "../client/landingPage/v2/images/ilia_small.png";
import { LOGO_BASE_URL } from "../../constants";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            grow: {
                flexGrow: 1,
            },
            floatRight: {
                float: "right",
            },
            topHeader: {
                background: "#203053",
                color: "#ffffff",
                float: "left",
                width: "100%",
                position: "fixed",
                top: 0,
                zIndex: 1300,
                padding:"5px 10px",

            },
            topHeaderItem: {
                float: "right",
                padding:"0 10px",
                fontSize: "16px !important",
                a: {
                    color: "#FFFFFF !important",
                    padding:0,
                },
            },
            topHeaderItemLink: {
                color: "#ffffff !important",
                display: "flex",
                alignItems: "center",
                textDecoration:'none',
                padding:0,
            },menuList:{
                float:'left',
                width:"100%",
                padding:0,
            },
            menuButton: {
                marginRight: theme.spacing(2),
            },
            title: {
                display: "none",
                [theme.breakpoints.up("sm")]: {
                    display: "block",
                },
            },
            search: {
                position: "relative",
                borderRadius: theme.shape.borderRadius,
                backgroundColor: fade(theme.palette.common.white, 0.15),
                "&:hover": {
                    backgroundColor: fade(theme.palette.common.white, 0.25),
                },
                marginRight: theme.spacing(2),
                marginLeft: 0,
                width: "100%",
                [theme.breakpoints.up("sm")]: {
                    marginLeft: theme.spacing(3),
                    width: "auto",
                },
            },
            searchIcon: {
                padding: theme.spacing(0, 2),
                height: "100%",
                position: "absolute",
                pointerEvents: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            inputRoot: {
                color: "inherit",
            },
            inputInput: {
                padding: theme.spacing(1, 1, 1, 0),
                // vertical padding + font size from searchIcon
                paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
                transition: theme.transitions.create("width"),
                width: "100%",
                [theme.breakpoints.up("md")]: {
                    width: "20ch",
                },
            },
            sectionDesktop: {
                display: "none",
                [theme.breakpoints.up("md")]: {
                    display: "flex",
                },
            },
            sectionMobile: {
                display: "flex",
                [theme.breakpoints.up("md")]: {
                    display: "none",
                },
            },
            logo: {
                maxHeight: 60,
                margin: 10,
            },
        }),
);

export default function PrimarySearchAppBar(props: any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [scrollClass, setScrollClass] = React.useState<string>("");
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

    const strategyLogo: string = props.strategy && props.strategy.landingPageLogo ? LOGO_BASE_URL + props.strategy.landingPageLogo : "";
    const strategySlug: string = (props.strategy && props.strategy.slug) || "";
    const strategyReBrand: number = (props.strategy && props.strategy.reBrand) || 0;

    const isAgentLink: boolean = (props.agentLinkDetail && props.agentLinkDetail.id) || false;

    const partnerLogo: string = iliaMini;

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            let activeClass: string = "scroll-normal";
            if (window.scrollY === 0) {
                activeClass = "scroll-top";
            }
            setScrollClass(activeClass);
        });
    }, []);


    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = "primary-search-account-menu";
    let url: string = `/login`;

    const renderMenu = (
            <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    id={menuId}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
            >
                <MenuItem>
                    {strategySlug === "kaizen" && strategyReBrand === 0 &&
                            <Link
                                    variant="caption"
                                    className="mr30"
                                    rel="noopener noreferrer"
                                    href="http://www.kaizenplan.com/agents"
                                    target="_blank">
                                KaiZen Plan Agent Login
                            </Link>
                    }
                    <Link variant="caption" onClick={() => {
                        window.location.href = url;
                    }}>ilia Login</Link>
                </MenuItem>
            </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
            <Menu
                    anchorEl={mobileMoreAnchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    id={mobileMenuId}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMobileMenuOpen}
                    onClose={handleMobileMenuClose}
            >
                <MenuItem>
                    {
                            strategySlug === "kaizen" && strategyReBrand === 0 &&
                            <Link
                                    variant="caption"
                                    className="mr30"
                                    rel="noopener noreferrer"
                                    href="http://www.kaizenplan.com/agents"
                                    target="_blank">
                                KaiZen Plan Agent Login
                            </Link>
                    }
                    <Link variant="caption" onClick={() => {
                        window.location.href = url;
                    }}>ilia Login</Link>
                </MenuItem>
            </Menu>
    );
    const defaultPhoneNumber = '800-294-9940';
    const menuItems = [
        {href: '/', label: 'Contact us'},
        {href: `tel:${defaultPhoneNumber}`, label: defaultPhoneNumber},
    ];
    return (
            <>
                { !isAgentLink &&
                <div className={classes.topHeader + " " + classes.floatRight}>
                    <Container maxWidth="lg">
                        <MenuList className={classes.menuList}>
                            {
                                menuItems.map(({ label, href }, idx) => (
                                        <MenuItem className={classes.topHeaderItem} key={idx}>
                                            <a href={href} className={classes.topHeaderItemLink}>
                                                {label}
                                            </a>
                                        </MenuItem>
                                ))
                            }
                        </MenuList>
                    </Container>
                </div>
                }
                <div className={classes.grow}/>
                    <AppBar position="fixed" color="secondary" className={`header ${scrollClass}`} style={{ top: isAgentLink ? 0 : 34}}>
                        <Container maxWidth="lg">
                            <div className="footerImg floatLeft">
                                {
                                        !isAgentLink &&
                                        <img alt={"partnerlogo"} src={partnerLogo} className="logo" />
                                }
                                {
                                    strategyLogo ?
                                            <img alt={"strategylogo"} className="logo" src={strategyLogo} /> :
                                            <Typography
                                                    color="primary"
                                                    component="strong"
                                                    className="f22 ml15 mt10 floatLeft"
                                            >
                                                {props.strategyData.name}
                                            </Typography>
                                }
                            </div>

                            <div className={classes.grow} />
                            {
                                    !isAgentLink &&
                                    <div className={classes.sectionDesktop + " " + " floatRight "} >
                                        <MenuItem className="floatRight">
                                            {
                                                    strategySlug === "kaizen" && strategyReBrand === 0 &&
                                                    <Link
                                                            variant="caption"
                                                            className="mr30"
                                                            rel="noopener noreferrer"
                                                            href="http://www.kaizenplan.com/agents"
                                                            target="_blank"
                                                    >
                                                        KaiZen Plan Agent Login
                                                    </Link>
                                            }
                                            <Link
                                                    variant="caption" onClick={() => {
                                                window.location.href = url;
                                            }}
                                            >ilia Login</Link>
                                        </MenuItem>
                                    </div>
                            }
                            {
                                    !isAgentLink &&
                                    <div className={classes.sectionMobile + " " + " floatRight "}>
                                        <IconButton
                                                aria-label="show more"
                                                aria-controls={mobileMenuId}
                                                aria-haspopup="true"
                                                onClick={handleMobileMenuOpen}
                                                color="inherit"
                                        >
                                            <MoreIcon />
                                        </IconButton>
                                    </div>
                            }
                        </Container>
                    </AppBar>
                    {!isAgentLink && renderMobileMenu}
                    {!isAgentLink && renderMenu}
            </>
    );
}
