import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import * as Estimations from "../../../../../actions/estimation";
import {
    AllianzLifeCarrierDetail,
    GWT_GEN2_STRATEGY,
    minContributionAmount,
    otherIndustryId,
    stateDropDown,
    USA,
} from "../../../../../constants";
import moment from "moment";
import AllianzAlert from "../../component/AllianzAlert";
import IncomeAlert from "../../component/IncomeAlert";
import { asDbDateTimeFormat, DATE_FORMAT_DOB_SAVE, getCurrentYear } from "../../../../../utils/dateUtils";
import numeral from "numeral";
import { PhysicianAndMedicalDetailsStyle } from "../EnrollmentApplication/MedicalAndFinancial/medicalStyle";
import { scroller } from "react-scroll";
import KickOut from "../../component/EnrollmentHome/KickOut";
import PersonalDetailFormikContent, { incomeDetailsColArr } from "./PersonalDetailFormikContent";
import PersonalDetailValidate from "./PersonalDetailValidate";
import CommonFieldFunction from "./CommonFieldFunction";
import { formatSSN } from "./PersonalDetailUtils";
import PersonalDetailFieldObj from "./PersonalDetailFieldObj";
import UpdateEmailReqPopUp from "./UpdateEmailReqPopUp";
import { convertToNumber, isLongForm, isWorksheet } from "../EnrollmentApplication/Utilities";
import { useOfferType } from "../../../../../hooks/useOfferType";

const initVal = {
    proofExpiryYear: 0,
    proofExpiryMonth: 0,
    gender: "",
    immigrationStatus: "",
    residenceInHomeCountry: "",
    currentlyEmployed: "",
    currentlyEmployedSelf: "",
    marriageStatus: "",
    employedTime: 0,
    industryId: 0,
    occupationId: 0
}

const PersonalDetailFormV2 = (props: { setNextStep: Function, setActionItem: Function, isFromClientDetail?: boolean,
    clientDetail?: any, setPersonalDetailRef?: Function, setIsPersonalFormDirty?: Function, setIsFormSubmitting?: Function,
    setCarrierValAlert?: Function, setValidationError?: Function, setStateValue?: Function, communityStateVal?: string,
    setCommunityStateVal?: Function, setIsDocumentAddress?: Function, getClientDetail?: Function, urlClientId?: string, ssnFlag?: boolean}) => {
    const classes = PhysicianAndMedicalDetailsStyle();
    const {
        setNextStep,
        isFromClientDetail,
        setPersonalDetailRef,
        setIsPersonalFormDirty,
        setIsFormSubmitting,
        setCarrierValAlert,
        setActionItem,
        setValidationError,
        setStateValue,
        communityStateVal,
        setCommunityStateVal,
        getClientDetail, urlClientId, ssnFlag
    } = props;

    const accountActions = useActions(Actions);
    const estimationActions = useActions(Estimations);
    const {setIsDocumentAddress}= props;
    let userData = useSelector((state: RootState) => state.profile);
    let { user: { role, participant } } = useSelector((state: RootState) => state.auth);
    let user = props.clientDetail || userData;
    const { client } = user;
    const autoCompleteValue = user && user.client;
    const conditionalRequired = !(role === 1 || role === 2 || role === 4);
    const [selfEmployed, setSelfEmployed] = React.useState(false);
    const [currentlyEmployed, setCurrentlyEmployed] = React.useState<string>("");
    const [fullTimeEmployee, setFullTimeEmployee] = React.useState<number>(1);
    const [carrierId, setCarrierId] = React.useState<number | null>(null);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [allianzId, setAllianzId] = React.useState<number | null>(null);
    const [carrierName, setCarrierName] = React.useState<string | undefined>("");
    const [minVal, setMinVal] = React.useState(minContributionAmount);
    const [ageSelected, setAgeSelected] = React.useState(false);
    const [incomeAlertOpen, setIncomeAlertOpen] = React.useState(false);
    const [proofExpired, setProofExpired] = React.useState(false);
    const [nationalLifeId, setNationalLifeId] = React.useState<number | null>(null);
    const [isNationalLife, setIsNationalLife] = React.useState<boolean>(false);
    const [isAllianzLife, setIsAllianzLife] = React.useState<boolean>(false);
    const [minAge, setMinAge] = React.useState<number>(25);
    const [strategySlug, setStrategySlug] = React.useState("");
    const [labelsActive, setLabelsActive] = React.useState<any[]>([]);
    const [refresh, setRefresh] = React.useState(0);
    const [stateObj, setStateObj] = React.useState<{} | string>("");
    const currentYear = getCurrentYear();
    const personalDetailSubmit = React.useRef<any>();
    const proofExpireRef: any = useRef(null);
    const personalDetailForm:any = React.useRef<any>(null);
    const [isFormSubmit, setIsFormSubmit] = React.useState<boolean>(false);
    const [identityType, setIdentityType] = React.useState<string>("");
    const [openKickOutPopup, setOpenKickOutPopup] = useState(false);
    const [fullTimeSelected, setFullTimeSelected] = useState(0);

    const [isMarried, setIsMarried] = useState(false);
    const [isEmployed, setIsEmployed] = useState(false);
    const [provideCorrectEmailPopUp, setProvideCorrectEmailPopUp] = useState(false);
    const [isParentEarner, setIsParentEarner] = React.useState<boolean>(false);
    //const [isDobRefresh, setIsDobRefresh] = React.useState<number>(0);
    const [saveRequest, sendSaveRequest] = React.useState<any>(Math.random());
    const [formValues, setFormValues] = React.useState<any>({});
    const [industryDropDown, setIndustryDropDown] = React.useState([]);
    const [isIndustryOther, setIsIndustryOther] = React.useState(false);
    const [industryId, setIndustryId] = React.useState(0);
    const [occupationDropDownArr, setOccupationDropDownArr] = React.useState([]);
    const [serverError, setServerError] = React.useState(false);
    const [birthCountryValue, setBirthCountryValue] = React.useState<string>(autoCompleteValue.birthCountry);
    const [birthStateValue, setBirthStateValue] = React.useState<string>(autoCompleteValue.birthState);
    const clientSigned = (user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId)) ? 1 : 0; // check envelopId generated too

    const getAllianzCarrierDetails = async () => {
        const allianzData = await accountActions.getAllianzDetails();
        if (allianzData && allianzData.allianzLife && allianzData.allianzLife.id) {
            setAllianzId(allianzData.allianzLife.id);
        }
    };
    console.log(birthStateValue)
    const getNatioanlLifeCarrierDetails = async () => {
        const nationalLifeDate = await accountActions.getNationalLifeDetails();
        if (nationalLifeDate && nationalLifeDate.nationalLife) {
            setNationalLifeId(nationalLifeDate.nationalLife.id);
        }
    };

    const scrollUp = async (id) => {
        setIsFormSubmit(false);
        scroller.scrollTo(id, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -200,
        });
        return await accountActions.setSnackBarMessage(false, `${identityType === "drivingLicence" ? "Drivers license" : "Passport"} has been expired`);
    }

    const getOccupationList = async (industryId: number) => {
        const res = await accountActions.getOccupationByIndustryId({industryId});
        const resArr = res.length > 0 && res.map((item) => {
            return {
                id: item.id,
                value: item.id,
                name: item.occupationDefinition
            }
        })
        setOccupationDropDownArr(resArr);
    }
    const [initialValues, setInitialValues] = React.useState<any>(initVal);
    let dob = user && user.client && user.client.dob;
    const isGWTGen2 = (user && user.client && user.client.strategyId === GWT_GEN2_STRATEGY)
    useEffect(() => {
        let proofExpireDate = client && client.employeeIdExpireDate;
        let proofExpiryYear = 0;
        let proofExpiryMonth = 0;
        if (proofExpireDate) {
            let proofExpireDateArr = proofExpireDate.split("-");
            proofExpiryYear = Number(proofExpireDateArr[0]);
            proofExpiryMonth = Number(proofExpireDateArr[1]);
        }
        let formVal:any = {
            proofExpiryYear: proofExpiryYear,
            proofExpiryMonth: proofExpiryMonth
        };

        if(client){
            setBirthStateValue(client.birthState)
            formVal = {
                ...formVal,
                firstName: user.firstName || "",
                middleName: user.middleName || "",
                lastName: user.lastName || "",
                cellPhone: user.phoneNumber || "",
                email: user.email || "",
                usCitizen: !!client.usCitizen,
                identityType: client.identityType || "drivingLicence",
                identityId: client.employeeIdNumber || "",
                employeeSSN: formatSSN(client.employeeSSN || ""),
                blotSSN: client.blotSSN || "",
                birthState: client.birthState || "",
                birthCountry: client.birthCountry || "",
                workPhoneNumber: client.workPhoneNumber || "",
                homePhone: client.homePhone || "",
                gender: client.gender || "",
                dob: asDbDateTimeFormat(client.dob, DATE_FORMAT_DOB_SAVE),
                age: moment().diff(asDbDateTimeFormat(client.dob, DATE_FORMAT_DOB_SAVE), "years") || client.age,
                street: client.street || "",
                city: client.city || "",
                state: stateObj || {key: "", name: "", title: ""},
                zipCode: client.zipCode || "",
                isDocumentAddress: !!client.isDocumentAddress,
                selfEmployed: (client.selfEmployed || client.currentlyEmployed === "SELF_EMPLOYED"),
                currentlyEmployed: isGWTGen2 ? "Other" : client.currentlyEmployed || "",
                currentlyEmployedSelf : client.currentlyEmployedSelf || "",
                fullTimeEmployee : client.fullTimeEmployee,
                howLongInUS : client.howLongInUS || "",
                countryCitizenship : client.countryCitizenship || "",
                immigrationStatus : client.immigrationStatus || "",
                greenCardNo : client.greenCardNo || "",
                typeOfVisaPlanHeld : client.typeOfVisaPlanHeld || "",
                typeOfVisaHeld : client.typeOfVisaHeld || "",
                immigrationStatusOther : client.immigrationStatusOther || "",
                residenceInHomeCountry : client.residenceInHomeCountry || "0",
                residenceInHomeCountryExplain : client.residenceInHomeCountryExplain || "",
                employer: client.employer ? client.employer : (client.company && client.company.groupType === "Corporation") ? client.company.companyName : "",
                employedTime: client.employedTime || "",
                annualIncome: (client.annualIncome && numeral(client.annualIncome).value() > 0 &&  Number(client.annualIncome).toString()) || "",
                annualIncome1: (client.annualIncome1 && Number(client.annualIncome1).toString()) || "",
                annualIncome2: (client.annualIncome2 && Number(client.annualIncome2).toString()) || "",
                householdAnnualIncome: (client.householdAnnualIncome && Number(client.householdAnnualIncome).toString()) || "", //spouse annual income
                householdNetWorth: (client.householdNetWorth && Number(client.householdNetWorth).toString()) || (client.netWorth && Number(client.netWorth).toString()) || "",
                spouseDUAIncome: (client.spouseDUAIncome && Number(client.spouseDUAIncome).toString()) || "",
                householdLiquidAssets: (client.householdLiquidAssets && Number(client.householdLiquidAssets).toString()) || "",
                householdAnnualExpenses: (client.householdAnnualExpenses && Number(client.householdAnnualExpenses).toString()) || "",
                healthType: client.healthType || "",
                USBasedCompany: !!client.USBasedCompany,
                employeeIdState: client.employeeIdState || "",
                netWorth: (client.netWorth && numeral(client.netWorth).value() > 0 && Number(client.netWorth).toString()) || "",
                employerContribution: client.employerContribution || 0,
                contributionAmount: client.issuedContribution || client.contributionAmount || "",

                marriageStatus: (client && client.marriageStatus) || "", // income details new columns starts here
                usingSpouseIncome: isGWTGen2 ? "0" : client && (typeof client.usingSpouseIncome === "number") ? client.usingSpouseIncome + "" : "",
                spouseIncome: (client.spouseIncome && Number(client.spouseIncome).toString()) || "",

                indIncomeLastYearAnnualSalary: (client.annualIncome && numeral(client.annualIncome).value() > 0 && Number(client.annualIncome).toString()) || "",
                indIncomeLastYearDividends: (client.indIncomeLastYearDividends && numeral(client.indIncomeLastYearDividends).value() > 0 && Number(client.indIncomeLastYearDividends).toString()) || "",
                indIncomeLastYearOtherIncome: (client.indIncomeLastYearOtherIncome && numeral(client.indIncomeLastYearOtherIncome).value() > 0 && Number(client.indIncomeLastYearOtherIncome).toString()) || "",
                indIncomeLastYearTotal: (client.indIncomeLastYearTotal && numeral(client.indIncomeLastYearTotal).value() > 0 && Number(client.indIncomeLastYearTotal).toString()) || "",

                indIncome1YearsPriorAnnualSalary: (client.annualIncome1 && numeral(client.annualIncome1).value() > 0 && Number(client.annualIncome1).toString()) || "",
                indIncome1YearsPriorDividends: (client.indIncome1YearsPriorDividends && numeral(client.indIncome1YearsPriorDividends).value() > 0 && Number(client.indIncome1YearsPriorDividends).toString()) || "",
                indIncome1YearsPriorOtherIncome: (client.indIncome1YearsPriorOtherIncome && numeral(client.indIncome1YearsPriorOtherIncome).value() > 0 && Number(client.indIncome1YearsPriorOtherIncome).toString()) || "",
                indIncome1YearsPriorTotal: (client.indIncome1YearsPriorTotal && numeral(client.indIncome1YearsPriorTotal).value() > 0 && Number(client.indIncome1YearsPriorTotal).toString()) || "",

                indIncome2YearsPriorAnnualSalary: (client.annualIncome2 && numeral(client.annualIncome2).value() > 0 && Number(client.annualIncome2).toString()) || "",
                indIncome2YearsPriorDividends: (client.indIncome2YearsPriorDividends && numeral(client.indIncome2YearsPriorDividends).value() > 0 && Number(client.indIncome2YearsPriorDividends).toString()) || "",
                indIncome2YearsPriorOtherIncome: (client.indIncome2YearsPriorOtherIncome && numeral(client.indIncome2YearsPriorOtherIncome).value() > 0 && Number(client.indIncome2YearsPriorOtherIncome).toString()) || "",
                indIncome2YearsPriorTotal: (client && client.indIncome2YearsPriorTotal && numeral(client.indIncome2YearsPriorTotal).value() > 0 && Number(client.indIncome2YearsPriorTotal).toString()) || "",

                indOtherIncomeDetail: client.indOtherIncomeDetail || "",

                houseHoldIncomeLastYearAnnualSalary: (client.householdAnnualIncome && numeral(client.householdAnnualIncome).value() > 0 && Number(client.householdAnnualIncome).toString()) || "",
                houseHoldIncomeLastYearDividends: (client.houseHoldIncomeLastYearDividends && numeral(client.houseHoldIncomeLastYearDividends).value() > 0 && Number(client.houseHoldIncomeLastYearDividends).toString()) || "",
                houseHoldIncomeLastYearOtherIncome: (client.houseHoldIncomeLastYearOtherIncome && numeral(client.houseHoldIncomeLastYearOtherIncome).value() > 0 && Number(client.houseHoldIncomeLastYearOtherIncome).toString()) || "",
                houseHoldIncomeLastYearTotal: (client.houseHoldIncomeLastYearTotal && numeral(client.houseHoldIncomeLastYearTotal).value() > 0 && Number(client.houseHoldIncomeLastYearTotal).toString()) || "",

                houseHoldIncome1YearsPriorAnnualSalary: (client.houseHoldIncome1YearsPriorAnnualSalary && numeral(client.houseHoldIncome1YearsPriorAnnualSalary).value() > 0 && Number(client.houseHoldIncome1YearsPriorAnnualSalary).toString()) || "",
                houseHoldIncome1YearsPriorDividends: (client.houseHoldIncome1YearsPriorDividends && numeral(client.houseHoldIncome1YearsPriorDividends).value() > 0 && Number(client.houseHoldIncome1YearsPriorDividends).toString()) || "",
                houseHoldIncome1YearsPriorOtherIncome: (client.houseHoldIncome1YearsPriorOtherIncome && numeral(client.houseHoldIncome1YearsPriorOtherIncome).value() > 0 && Number(client.houseHoldIncome1YearsPriorOtherIncome).toString()) || "",
                houseHoldIncome1YearsPriorTotal: (client.houseHoldIncome1YearsPriorTotal && numeral(client.houseHoldIncome1YearsPriorTotal).value() > 0 && Number(client.houseHoldIncome1YearsPriorTotal).toString()) || "",

                houseHoldIncome2YearsPriorAnnualSalary: (client.houseHoldIncome2YearsPriorAnnualSalary && numeral(client.houseHoldIncome2YearsPriorAnnualSalary).value() > 0 && Number(client.houseHoldIncome2YearsPriorAnnualSalary).toString()) || "",
                houseHoldIncome2YearsPriorDividends: (client.houseHoldIncome2YearsPriorDividends && numeral(client.houseHoldIncome2YearsPriorDividends).value() > 0 && Number(client.houseHoldIncome2YearsPriorDividends).toString()) || "",
                houseHoldIncome2YearsPriorOtherIncome: (client.houseHoldIncome2YearsPriorOtherIncome && numeral(client.houseHoldIncome2YearsPriorOtherIncome).value() > 0 && Number(client.houseHoldIncome2YearsPriorOtherIncome).toString()) || "",
                houseHoldIncome2YearsPriorTotal: (client.houseHoldIncome2YearsPriorTotal && numeral(client.houseHoldIncome2YearsPriorTotal).value() > 0 && Number(client.houseHoldIncome2YearsPriorTotal).toString()) || "",

                houseHoldOtherIncomeDetail: client.houseHoldOtherIncomeDetail || "",
                parentsAreIncomeEarner: isGWTGen2 ? "1" : client.parentsAreIncomeEarner == "0" ? "0" : "1",

                occupation: client.occupation || "",
                industryId: client.industryId || "",
                occupationId: client.occupationId || "",
                otherOccupation: client.otherOccupation || "",
                occupationDetail: client.occupationDetail || "",
                employerAddress: client.employerAddress || "",
                highRanking: client.clientSourceOfFund && client.clientSourceOfFund.highRanking,
                individualHighRanking: client.clientSourceOfFund && client.clientSourceOfFund.individualHighRanking,
                workInIndustries: client.clientSourceOfFund && client.clientSourceOfFund.workInIndustries,
                countryOfOrigin: client.clientSourceOfFund && client.clientSourceOfFund.countryOfOrigin || "",
                sourceOfFunds: client.clientSourceOfFund && client.clientSourceOfFund.sourceOfFunds || "",
                countrySourceOfWealth: client.clientSourceOfFund && client.clientSourceOfFund.countrySourceOfWealth || "",
                whereTheyProceed: isGWTGen2 ? "Parents work income and net worth income being used as gift to child.":
                        client.clientSourceOfFund && client.clientSourceOfFund.whereTheyProceed || "",
                otherIndustry: client.otherIndustry || "",
                currentResidenceCountry: client.currentResidenceCountry || "",
                otherCitizenship: client.otherCitizenship || ""
            }
        }
        if (client) {
            setInitialValues(formVal);
        }
    }, [client, user]);

    React.useEffect(() => {
        if (proofExpired && isFormSubmit && proofExpireRef && proofExpireRef.current) {
            scrollUp(proofExpireRef.current.id);
        }
    }, [proofExpired, isFormSubmit]);

    React.useEffect(() => {
        if (isNationalLife) {
            setMinAge(18); //for National Life Carrier MIN AGE is 18;
        }
    }, [isNationalLife]);

    React.useEffect(() => {
        if (isAllianzLife) {
            setMinAge(18); //for National Life Carrier MIN AGE is 18;
        }
    }, [isAllianzLife]);

    React.useEffect(() => {
        if (nationalLifeId && carrierId && nationalLifeId === carrierId && strategySlug === "kaizen") {
            setIsNationalLife(true);
        }
    }, [nationalLifeId, carrierId]);

    React.useEffect(() => {
        if (allianzId && carrierId && allianzId === carrierId && strategySlug === "kaizen") { //if Allianz Life and strategy is Kaizen
            setIsAllianzLife(true);
        }
    }, [allianzId, carrierId]);

    React.useEffect(() => {
        if (typeof setCarrierValAlert === "function") {
            setCarrierValAlert(openAlert);
        }
    }, [openAlert]);

    useEffect(() => {
        if (personalDetailSubmit.current && typeof setPersonalDetailRef === "function") {
            setPersonalDetailRef(personalDetailSubmit);
        }
    }, [personalDetailSubmit.current]);

    useEffect(() => {
        if (!isFromClientDetail) {
            accountActions.getProfileDetails();
        }
        getAllianzCarrierDetails().then(()=>{});
        getNatioanlLifeCarrierDetails().then(()=>{});
    }, []);

    useEffect(()=>{
        if(industryId){
            getOccupationList(industryId);
        }
    },[industryId])

    React.useEffect(() => {
        getEstimation().then(()=>{});
        getIndustriesList().then(() => {
        });
    }, []);

    useEffect(()=>{
        if(isFromClientDetail && communityStateVal){
            const stateList = JSON.parse(JSON.stringify(stateDropDown));
            const stateObjFound: any =  stateList.find((item:any,index)=>{
                return item.value === communityStateVal
            })
            if (stateObjFound) {
                setStateObj({
                    key: stateObjFound && stateObjFound.value,
                    name: stateObjFound && stateObjFound.name,
                    title: stateObjFound && stateObjFound.name
                });
                stateObjFound.key = stateObjFound && stateObjFound.value;
                stateObjFound.title = stateObjFound && stateObjFound.name;
                delete stateObjFound.isCommunityState;
                delete stateObjFound.id;
                delete stateObjFound.value;
                setTimeout(() => {
                    setInitialValues(Object.assign({}, {...initialValues, state: stateObjFound}))
                }, 500);
                setTimeout(() => {
                    if (typeof setCommunityStateVal === 'function') {
                        setCommunityStateVal("");
                    }
                }, 1000)
            }
        }

    },[communityStateVal])

    useEffect(() => {
        if (client) {
            setIdentityType((client && client.identityType) || "");
            setSelfEmployed(!!client.selfEmployed);
            setCurrentlyEmployed(client.currentlyEmployed || "");
            setFullTimeEmployee(client.fullTimeEmployee);
            setFullTimeSelected(client.currentlyEmployed === 'FULL_TIME' ? 1 : 0)

            setIsMarried(client.marriageStatus === "Married")
            // setIsMarried(client.marriageStatus == "Married" ? true : false)
            setIsEmployed((client.currentlyEmployed === "FULL_TIME" || client.currentlyEmployed === "PART_TIME"))
            setIsIndustryOther(client.industryId === otherIndustryId)
            setIndustryId(client.industryId)
            //getDays();
        }
        if (client && client.carrier && client.carrier.id) {
            setCarrierId(client.carrier.id);
            setCarrierName(client.carrier.carrierName);
        }
        if (client && client.strategy && client.strategy.slug) {
            setStrategySlug(client.strategy.slug);
        }

        if (client && client.employeeIdExpireDate) {
            const expireDate = moment(new Date(client.employeeIdExpireDate));
            const currentDate = moment(new Date());
            const daysLeft = expireDate.diff(currentDate, "days");
            if (daysLeft <= 0) {
                setProofExpired(true);
            }
        }
        if (client && client.state) {
            const stateObj: any =  stateDropDown.find((item:any,index)=>{
                return item.value === client.state
            })
            setStateObj({
                key: stateObj && stateObj.value,
                name: stateObj && stateObj.name,
                title: stateObj && stateObj.name
            });
            setRefresh(Math.random());
        }
    }, [client]);

    React.useEffect(()=>{
        if(personalDetailForm && personalDetailForm.current && personalDetailForm.current.dirty) {
            let values:any = personalDetailForm && personalDetailForm.current && personalDetailForm.current.values;
            if (
                    ((Number(values["age"]) || Number(values["age"]) === 0) && (Number(values["age"]) < minAge ||
                            Number(values["age"]) > 65))
                    || (Number(values["age"]) < 0)
            ) {
                values.age = 0;
            }
            if(values.dob === undefined){
                values.dob = dob;
            }
            setBirthCountryValue(typeof values.birthCountry === "object" ? values.birthCountry && values.birthCountry.name : values.birthCountry);
            saveMe(values);
            setFormValues(values)
        }
    }, [personalDetailForm && personalDetailForm.current && personalDetailForm.current.values, saveRequest])

    useEffect(() => {
        if(client.birthCountry === undefined){
            setBirthCountryValue(USA);
        } else if(client.birthCountry && birthCountryValue === undefined){
            setBirthCountryValue(client.birthCountry);
        } else if(birthCountryValue !== USA){
            if(personalDetailForm && personalDetailForm.current && personalDetailForm.current.dirty && personalDetailForm.current.values) {
                setInitialValues(()=> Object.assign({}, {...personalDetailForm.current.values, birthState: ""}))
            }
            setBirthStateValue("");
        }
    }, [client, birthCountryValue]);
    const getIndustriesList = async () => {
        const allIndustries = await accountActions.getAllIndustries();
        const industriesArr = allIndustries.length > 0 && allIndustries.map((item) => {
            return {
                id: item.id,
                value: item.id,
                name: item.sectorDefinition
            }
        })
        setIndustryDropDown(industriesArr);
    }

    const getEstimation = async () => {
        if (client) {
            getContributionAmount({
                contributionAmount: 0,
                age: client.age,
                rating: client.healthType,
                gender: client.gender,
            });
        }
    };

    const getContributionAmount = (data: any) => {
        setTimeout(async () => {
            let isMinRequiredError = !(client && client.company && client.company.groupType === "Corporation" && client.underWritingOfferTypeFinal === "gi");

            let employerContributionValue:any = 0;
            if (client && client.employerContribution && numeral(client.employerContribution).value() > 0 && props.clientDetail && props.clientDetail.role === 6) {
                employerContributionValue = client.employerContribution;
            }
            let estimatorData: any = await estimationActions.getInvitedClientEstimates({
                age: data.age || minAge,
                gender: data.gender === 1 ? "Male" : "Female",
                rating: (data && data.rating) || "Standard",
                contribution: numeral(data.contributionAmount).value() + numeral(employerContributionValue).value() + "",
                strategySlug: (client && client.strategy && client.strategy.slug) || "",
                carrierName: (client && client.carrier && client.carrier.carrierName) || "",
                isMinRequiredError
            });
            if (estimatorData && estimatorData.payload && estimatorData.payload.minContributionAmount) {
                setMinVal(estimatorData && estimatorData.payload && estimatorData.payload.minContributionAmount);
            }
            if(!isMinRequiredError){
                setMinVal(0);
            }
        }, 500);
    };

    let contributionType:string | undefined = (client && client.company && client.company.contributionType) || undefined;
    let showEmployerContribution: boolean = true;
    let showClientContribution: boolean = true;

    if((contributionType === 'EMPLOYER_ONLY')){
        showEmployerContribution = true;
        showClientContribution = false;
    } else if((contributionType === 'PARTICIPANT_ONLY')){
        showEmployerContribution = false;
        showClientContribution = true;
    } else if((contributionType === 'COMBINE_CONTRIBUTION')){
        showEmployerContribution = true;
        showClientContribution = true
    }


    const isCorporation:boolean = !!(client && client.company && client.company.groupType === "Corporation");
    let offerType = useOfferType();
    if(offerType === "consent"){
        offerType = "gi";
    }



    let fieldObj = PersonalDetailFieldObj.fieldObj(conditionalRequired,birthStateValue,setBirthStateValue, setBirthCountryValue, clientSigned, birthCountryValue);
    const fieldObj1 = PersonalDetailFieldObj.fieldObj1(conditionalRequired, getContributionAmount, setProvideCorrectEmailPopUp, isFromClientDetail);
    const fieldObj12 = PersonalDetailFieldObj.fieldObj12(conditionalRequired, clientSigned, setBirthStateValue, birthStateValue);
    const fieldObj13 = PersonalDetailFieldObj.fieldObj13(conditionalRequired);

    if (birthCountryValue === USA) {
        fieldObj = [...fieldObj, ...fieldObj12];
    }
    else {
        fieldObj = [...fieldObj, ...fieldObj13];
    }

    const setAnticipatedAge = (date, setValue, formValues) => {
        const age = moment().diff(date, "years");
        setAgeSelected(!!(age && (age >= minAge && age <= 65)));
        getContributionAmount({
            contributionAmount: 0,
            age: age,
            rating: formValues && formValues.healthType,
            gender: formValues && formValues.gender,
        });
        setValue("age", age);
    };

    const fieldObj4 = PersonalDetailFieldObj.fieldObj4(conditionalRequired, setAnticipatedAge, sendSaveRequest);

    const fieldObj7 = PersonalDetailFieldObj.fieldObj7(conditionalRequired, setProofExpired);
    let fieldObj2 = PersonalDetailFieldObj.fieldObj2(conditionalRequired, setStateValue, communityStateVal, clientSigned,birthCountryValue);

    let fieldObj8:any = PersonalDetailFieldObj.fieldObj8(conditionalRequired, carrierName);
    const fieldObj10:any = PersonalDetailFieldObj.fieldObj10(conditionalRequired);
    const fieldObj11:any = PersonalDetailFieldObj.fieldObj11();
    if(carrierName === AllianzLifeCarrierDetail.name) {
        fieldObj2 = [...fieldObj2, ...fieldObj10];
        fieldObj8 = [...fieldObj8, ...fieldObj11];
    }
    const fieldObj9 = PersonalDetailFieldObj.fieldObj9(conditionalRequired);
    let isGiCorpIncomeCond:boolean = !!fullTimeEmployee || (offerType === "gi-to-full" || offerType === "si-to-full");

    const fieldObj3 = PersonalDetailFieldObj.fieldObj3(isGiCorpIncomeCond, conditionalRequired, currentYear, offerType
            , strategySlug, nationalLifeId, carrierId, fullTimeSelected, industryDropDown, setIsIndustryOther, setIndustryId, occupationDropDownArr)
    const fieldObj5 = PersonalDetailFieldObj.fieldObj5(isGiCorpIncomeCond, conditionalRequired,isGWTGen2)

    const fieldObj6 = PersonalDetailFieldObj.fieldObj6(conditionalRequired, client, ageSelected, getContributionAmount)

    const getFieldFocusValue = (name: string) => {
        let findResult: any = labelsActive.find((label: any) => {
            return label.name === name;
        });
        if (!findResult) {
            return false;
        }

        return findResult.value;
    };

    const CommonField = (

            filedProps,
            action: {
                handleChange?: Function,
                handleBlur: Function,
                values?: any,
                errors?: any,
                touched?: any,
                setFieldValue: any
            },
    ) => {
        return (
                <CommonFieldFunction filedProps={filedProps}
                                     action={action}
                                     conditionalRequired={conditionalRequired}
                                     role={role}
                                     setActionItem={setActionItem}
                                     client={client}
                                     labelsActive={labelsActive}
                                     setLabelsActive={setLabelsActive}
                                     clientSigned={clientSigned}
                                     getFieldFocusValue={getFieldFocusValue}

                />)
    };

    /* Validations of fields set */
    let keysValidation = {};
    let fieldobj10 = [
        {
            name: "householdNetWorth",
            isRequired: true,
            type: "number",
        },
        {
            name: "spouseDUAIncome",
            isRequired: true,
            type: "number",
        },
        {
            name: "householdLiquidAssets",
            isRequired: true,
            type: "number",
        },
        {
            name: "householdAnnualExpenses",
            isRequired: true,
            type: "number",
        },
    ];

    let validationObj = [
        ...fieldObj,
        ...fieldObj1,
        ...fieldObj4,
        {
            name: "currentlyEmployed",
            isRequired: conditionalRequired && !( isCorporation && (offerType === "gi"  || offerType === "consent" || offerType === "si") ),
            type:"string"
        },
        {
            name: "marriageStatus",
            isRequired: conditionalRequired && !isMarried,
            type:"string"
        },
        {
            name: "usingSpouseIncome",
            isRequired: conditionalRequired && isMarried && !isGWTGen2,
            type:"string"
        },
        //isEmployed
        {
            name: "employer",
            isRequired: conditionalRequired && isEmployed,
            type:"string"
        },{
            name: "employedTime",
            isRequired: conditionalRequired && isEmployed,
            type:"string"
        },{
            name: "occupation",
            isRequired: conditionalRequired && isEmployed && isIndustryOther,
            type:"string"
        },
        {
            name: "industryId",
            isRequired: conditionalRequired && isEmployed,
            type:"number"
        },{
            name: "otherIndustry",
            isRequired: conditionalRequired && isEmployed && isIndustryOther,
            type:"string"
        },{
            name: "occupationId",
            isRequired: conditionalRequired && isEmployed && !isIndustryOther,
            type:"number"
        },{
            name: "otherOccupation",
            isRequired: conditionalRequired && isEmployed && isIndustryOther,
            type:"string"
        },{
            name: "occupationDetail",
            isRequired: conditionalRequired && isEmployed,
            type:"string"
        },
        {
            name: "parentsAreIncomeEarner",
            isRequired: (conditionalRequired && currentlyEmployed && currentlyEmployed === "Other" && isParentEarner && !isAllianzLife),
            type:"string"
        }];

    if ((isLongForm(offerType) || isWorksheet(offerType)) && isMarried) {
        validationObj = [...validationObj, ...fieldobj10];
    }

    if(isLongForm(offerType) || isWorksheet(offerType)){
        validationObj.push(
                {
                    name: "highRanking",
                    isRequired: conditionalRequired,
                    type:"number"
                },
                {
                    name: "individualHighRanking",
                    isRequired: conditionalRequired,
                    type:"number"
                },
                {
                    name: "workInIndustries",
                    isRequired: conditionalRequired,
                    type:"number"
                },
                {
                    name: "employerAddress",
                    isRequired: conditionalRequired,
                    type:"string"
                },
                {
                    name: "countryOfOrigin",
                    isRequired: conditionalRequired,
                    type:"string"
                },
                {
                    name: "sourceOfFunds",
                    isRequired: conditionalRequired,
                    type:"string"
                },
                {
                    name: "countrySourceOfWealth",
                    isRequired: conditionalRequired,
                    type:"string"
                },
                {
                    name: "whereTheyProceed",
                    isRequired: conditionalRequired,
                    type:"string"
                }
        );
    }

    keysValidation = PersonalDetailValidate(validationObj, keysValidation, isFromClientDetail, minAge, fieldObj9, fieldObj8,
            fieldObj2, fieldObj3, offerType, isGiCorpIncomeCond, strategySlug, client, ageSelected, minVal,
            showClientContribution, fieldObj6, fieldObj7, fullTimeSelected, props, formValues
    );

    const personalDetailSchema = Yup.object().shape(keysValidation);

    let kickOutDetail = {
        groupAdminEmail: (client && client.agent && client.agent.user && client.agent.user.email) || "",
        groupAdminPhone : (client && client.agent && client.agent.user && client.agent.user.phoneNumber) || "",
        groupAdminFullName : (client && client.agent && client.agent.user && client.agent.user.firstName + ' ' + client.agent.user.lastName) || "",
        strategyName : (client && client.strategy && client.strategy.name) || ""
    }
    if(role === 6){
        kickOutDetail = {
            groupAdminEmail: (client && client.company && client.company.email) || "",
            groupAdminPhone : (client && client.company && client.company.phone) || "",
            groupAdminFullName : (client && client.company && client.company.companyName) || "",
            strategyName : (client && client.strategy && client.strategy.name) || ""
        }
    }

    const participantId:number = (participant && participant.id) || 0;

    const makePersonalDetailPayload = (values:any, setInitialValues:any, autoSave= false) => {
        if (isCorporation && (offerType === "gi" || offerType === "si") && !values["fullTimeEmployee"] && !isFromClientDetail) {
            setOpenKickOutPopup(true);
            return false;
        }
        delete values["employerContribution"];
        setIsFormSubmit(true);
        if (!isFromClientDetail && proofExpired) {
            return false;
        }
        // if (isFromClientDetail && (values["age"] < minAge || values["age"] > 65) && values["birthDay"] && values["birthMonth"] && values["birthYear"]) {
        //     let { birthDay, birthMonth } = values;
        //     delete values.age;
        //     delete values.birthDay;
        //     delete values.birthMonth;
        //     delete values.birthYear;
        //     if(setInitialValues && typeof setInitialValues === 'function') {
        //         setInitialValues({
        //             ...values,
        //             age: initialValues.age,
        //             birthDay: birthDay,
        //             birthYear: initialValues.birthYear,
        //             birthMonth: birthMonth,
        //         });
        //     }
        // } else {
            if(setInitialValues && typeof setInitialValues === 'function') {
                setInitialValues(values);
            }
        //}

        if (role === 5 || role === 6) {
            delete values["contributionAmount"];
            delete values["healthType"];
        } else {
            values["contributionAmount"] = values["contributionAmount"] ? values["contributionAmount"].split(",").join("") : 0;
        }
        if (!isFromClientDetail && values["age"] === 0 && !autoSave) {
            return false;
        }

        //values["annualIncome1"] = values["annualIncome1"] ? values["annualIncome1"] : null;
        //values["annualIncome2"] = values["annualIncome2"] ? values["annualIncome2"] : null;
        values["employedTime"] = values["employedTime"] ? values["employedTime"] : 0;
        values["employer"] = values["employer"] ? values["employer"] : "";
        values["annualIncome"] = values["indIncomeLastYearAnnualSalary"] && Number(values["indIncomeLastYearAnnualSalary"]) !== 0 ? values["indIncomeLastYearAnnualSalary"].split(",").join("") : null;
        values["annualIncome1"] = values["indIncome1YearsPriorAnnualSalary"] && Number(values["indIncome1YearsPriorAnnualSalary"]) !== 0 ? values["indIncome1YearsPriorAnnualSalary"].split(",").join("") : null;
        values["annualIncome2"] = values["indIncome2YearsPriorAnnualSalary"] && Number(values["indIncome2YearsPriorAnnualSalary"]) !== 0 ? values["indIncome2YearsPriorAnnualSalary"].split(",").join("") : null;
        values["householdAnnualIncome"] = convertToNumber(values["houseHoldIncomeLastYearAnnualSalary"]);
        values["householdNetWorth"] = convertToNumber(values["householdNetWorth"]);
        values["spouseDUAIncome"] = convertToNumber(values["spouseDUAIncome"]);
        values["householdLiquidAssets"] = convertToNumber(values["householdLiquidAssets"]);
        values["householdAnnualExpenses"] = convertToNumber(values["householdAnnualExpenses"]);
        values["dob"] = asDbDateTimeFormat(values["dob"], DATE_FORMAT_DOB_SAVE);
                //income details field starts
        values["spouseIncome"] = convertToNumber(values["spouseIncome"]);
        incomeDetailsColArr.forEach((item) => {
            values[`${item}`] = values[`${item}`] ? values[`${item}`].split(",").join("") : null;
        }); //income details field end
        values["netWorth"] = convertToNumber(values["netWorth"]);
        values["homePhone"] = convertToNumber(values["homePhone"]);
        values["immigrationStatus"] = values["immigrationStatus"] ? values["immigrationStatus"] : "";
        values["state"] = typeof values["state"] === "object" ? values["state"] && values["state"].key : values["state"];
        return values;
    }

    const saveMe = async (values:any) => {
            const payload:any = makePersonalDetailPayload(values,null, true);
            try {
                if (payload) {
                    payload.age = Number(payload.age);
                    await accountActions.updateUserBasicDetails(payload, ((client && client.userId) || 0), "");
                }
            } catch (e) {
                if (e && e.message == 422 && (typeof getClientDetail === 'function')) {
                    getClientDetail(urlClientId || 0, ssnFlag);
                    setServerError(true);
                }
                return false;
            }
    }
    //const { values, submitForm } = useFormikContext();
    return (
            <>
                {isGiCorpIncomeCond &&
                        <IncomeAlert incomeAlertOpen={incomeAlertOpen} />
                }
                <KickOut participantId={participantId} isFromClientDetail={isFromClientDetail || false}
                         openKickOut={openKickOutPopup} setOpenKickOut={() => {
                    setOpenKickOutPopup(!openKickOutPopup)
                }} {...kickOutDetail} />
                <PersonalDetailFormikContent
                        initialValues={initialValues}
                        personalDetailSchema={personalDetailSchema}
                        isCorporation={isCorporation}
                        offerType={offerType}
                        setOpenKickOutPopup={setOpenKickOutPopup}
                        setIsFormSubmit={setIsFormSubmit}
                        isFromClientDetail={isFromClientDetail}
                        proofExpired={proofExpired}
                        isNationalLife={isNationalLife}
                        selfEmployed={selfEmployed}
                        minAge={minAge}
                        setInitialValues={setInitialValues}
                        setNextStep={setNextStep}
                        client={client}
                        setActionItem={setActionItem}
                        role={role}
                        setIsPersonalFormDirty={setIsPersonalFormDirty}
                        setIsFormSubmitting={setIsFormSubmitting} setFullTimeEmployee={setFullTimeEmployee}
                        fullTimeEmployee={fullTimeEmployee}
                        setIncomeAlertOpen={setIncomeAlertOpen} setCurrentlyEmployed={setCurrentlyEmployed}
                        setValidationError={setValidationError} setSelfEmployed={setSelfEmployed}
                        accountActions={accountActions}
                        setOpenAlert={setOpenAlert}
                        user={user}
                        fieldObj={fieldObj}
                        clientSigned={clientSigned}
                        conditionalRequired={conditionalRequired} setProofExpired={setProofExpired}
                        CommonField={CommonField}
                        fieldObj1={fieldObj1} fieldObj8={fieldObj8} classes={classes} fieldObj2={fieldObj2}
                        proofExpireRef={proofExpireRef} fieldObj4={fieldObj4} fieldObj7={fieldObj7}
                        fieldObj9={fieldObj9}
                        strategySlug={strategySlug} fieldObj3={fieldObj3} fieldObj5={fieldObj5} fieldObj6={fieldObj6}
                        clientDetail={props.clientDetail} showClientContribution={showClientContribution}
                        showEmployerContribution={showEmployerContribution} minVal={minVal}
                        personalDetailSubmit={personalDetailSubmit}
                        setFullTimeSelected={setFullTimeSelected}
                        fullTimeSelected={fullTimeSelected}
                        setIsDocumentAddress={setIsDocumentAddress}
                        setIsMarried={setIsMarried}
                        setIsEmployed={setIsEmployed}
                        isAllianzLife={isAllianzLife}
                        setIsParentEarner={setIsParentEarner}
                        isParentEarner={isParentEarner}
                        personalDetailForm={personalDetailForm}
                        makePersonalDetailPayload={makePersonalDetailPayload}
                        sendSaveRequest={sendSaveRequest}
                        getClientDetail={getClientDetail}
                        urlClientId={urlClientId}
                        ssnFlag={ssnFlag}
                        setServerError={setServerError}
                        serverError={serverError}
                        setAnticipatedAge={setAnticipatedAge}
                        birthCountryValue={birthCountryValue}
                />

                <AllianzAlert setOpenAlert={setOpenAlert} open={openAlert} carrierName={carrierName}
                              isNationalLife={isNationalLife} />
                {
                        provideCorrectEmailPopUp &&
                        <UpdateEmailReqPopUp open={provideCorrectEmailPopUp}
                                             setProvideCorrectEmailPopUp={setProvideCorrectEmailPopUp} />
                }
            </>);
};

export default PersonalDetailFormV2;