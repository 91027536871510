import React, { useState } from "react";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ConfirmationDialog } from "../../pages/ticket/TicketPage";
import * as Actions from "../../actions/auth";
import { useActions } from "../../actions";
import configureStore from "../../configureStore";

const { persistor } = configureStore();

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            iconPadding: {
                padding: "0px !important",
            }
        }),
);

export default function ViewServicing(props:any) {
    const {clientName, strategyId, carrierId, clientId, btnText} = props;
    const classes = useStyles();
    const [showServicing, setShowServicing] = useState(false);
    const authActions = useActions(Actions);
    const viewServicing = async () => {
        await authActions.demoClient(strategyId, carrierId, clientId);
        setShowServicing(false);
        persistor.flush()
                .then(response => {
                    window.location.replace("/accounts");
                })
                .catch(err => {
                    window.location.replace("/accounts");
                });
    };

    return (<>
        {
                showServicing &&
                <ConfirmationDialog
                        onCancel={() => setShowServicing(false)}
                        onOk={viewServicing}
                        text={`Are you sure you want to view ${clientName}’s Servicing screen?`}
                        title="Confirmation"
                />
        }
        {btnText ? <Button color="primary" onClick={() => setShowServicing(true)}>{btnText}</Button> : <Tooltip title="View Servicing">
            <IconButton color="primary" className={classes.iconPadding} onClick={() => setShowServicing(true)}>
                <VisibilityOutlinedIcon />
            </IconButton>
        </Tooltip>}
    </>);
}