import {
    AppBar,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    Tab,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    Tooltip,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useActions } from "../../actions";
import { PageTitle } from "../../components/formInputs";
import { dateUtils } from "../../utils";
import { SearchTextField } from "../agent/ClientPage";
import { useStyles } from "../client/ClientProfile/component/tabs/ActionItemsTab";
import * as ChatActions from "../../actions/chat";
import * as TicketActions from "../../actions/ticket";
import * as AwsActions from "../../actions/aws";
import { fullName } from "../client/ClientProfile/component/tabs/ClientProfileTab";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Link } from "react-router-dom";
import { Pagination } from "../../components/table";
import { DialogTitle } from "../../components/table/AdminTable";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import { Field, Formik } from "formik";
import { TextField } from "../../components/formikFormInputs";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import sortBy from "lodash/sortBy";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import api from "superagent";
import { awsResp } from "../../components/formInputs/EnrollmentFileUploader";
import { parseEmptyString } from "../../utils/commonUtils";
import url from "url";
import { IliaAvatar } from "../../components/LeftPanel";
import pdfIcon from "../../styles/images/pdfIcon.png";
import groupBy from "lodash/groupBy";
import filesize from "filesize";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import * as Yup from "yup";
import { a11yProps, TabPanel } from "../client/ClientProfile/component/TabsMenu";
import TableWrap from "../../components/TableWrap/TableWrap";

export default () => {
    const [search, setSearch] = useState("");
    const ticketActions = useActions(TicketActions);

    const [currentTickets, setCurrentTickets] = useState<any>({
        count: 0,
        rows: [],
    });

    const [completedTickets, setCompletedTickets] = useState<any>({
        count: 0,
        rows: [],
    });

    const [completedTicketsPagination, setCompletedTicketsPagination] =
            useState({ page: 0, rowsPerPage: 25 });

    const [currentTicketsPagination, setCurrentTicketsPagination] =
            useState({ page: 0, rowsPerPage: 25 });

    const getCurrentTickets = async (search) => {
        const currentTickets = await ticketActions.getCurrentTickets({
            searchText: search,
            ...currentTicketsPagination,
        });
        setCurrentTickets(currentTickets);
    };

    const getCompletedTickets = async (search) => {
        const completedTickets = await ticketActions.getCompletedTickets({
            searchText: search,
            ...completedTicketsPagination,
        });
        setCompletedTickets(completedTickets);
    };

    useEffect(() => {
        getCurrentTickets(search).then(() => {
        });
    }, [search, currentTicketsPagination]);

    useEffect(() => {
        getCompletedTickets(search).then(() => {
        });
    }, [search, completedTicketsPagination]);


    const resolveTicket = async (ticket) => {
        await ticketActions.resolveTicket(ticket.id);
        await getCurrentTickets(search);
        await getCompletedTickets(search);
    };

    return (
            <Grid container spacing={2} justify="flex-end">
                <PageTitle title="ilia Tickets"/>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h3" align="left">
                        ilia Tickets
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} container direction="row-reverse">

                    <SearchTextField
                            search={search}
                            label={"Search Tickets (client/admin name)"}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={12}>
                    <TicketsPanels
                            currentTickets={currentTickets}
                            currentTicketsPagination={currentTicketsPagination}
                            setCurrentTicketsPagination={
                                setCurrentTicketsPagination
                            }
                            completedTickets={completedTickets}
                            completedTicketsPagination={completedTicketsPagination}
                            setCompletedTicketsPagination={
                                setCompletedTicketsPagination
                            }
                            onResolveTicket={resolveTicket}
                            isGoToClientVisible
                    />
                </Grid>
            </Grid>
    );
};

export const TicketsPanels = ({
                                  currentTickets,
                                  completedTickets,
                                  currentTicketsPagination,
                                  setCurrentTicketsPagination,
                                  completedTicketsPagination,
                                  setCompletedTicketsPagination,
                                  onResolveTicket,
                                  isGoToClientVisible,
                              }) => {
    const [tab, setTab] = React.useState(0);

    const handleChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };
    return (
            <>
                <AppBar position="static" color="default">
                    <Tabs
                            value={tab}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable force tabs example"
                    >
                        <Tab label="Current tickets" {...a11yProps(0)} />
                        <Tab label="Completed tickets" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0}>
                    <TicketTable
                            tickets={currentTickets.rows}
                            onResolveTicket={onResolveTicket}
                            isGoToClientVisible={isGoToClientVisible}
                    />
                    <Pagination
                            setPage={(page) =>
                                    setCurrentTicketsPagination((prev) => ({
                                        ...prev,
                                        page,
                                    }))
                            }
                            setRowsPerPage={(rowsPerPage) =>
                                    setCurrentTicketsPagination((prev) => ({
                                        ...prev,
                                        rowsPerPage,
                                    }))
                            }
                            accountList={currentTickets}
                            page={currentTicketsPagination.page}
                            rowsPerPage={currentTicketsPagination.rowsPerPage}
                    ></Pagination>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <TicketTable
                            tickets={completedTickets.rows}
                            onResolveTicket={onResolveTicket}
                            isCompleted
                            isGoToClientVisible={isGoToClientVisible}
                    />
                    <Pagination
                            setPage={(page) =>
                                    setCompletedTicketsPagination((prev) => ({
                                        ...prev,
                                        page,
                                    }))
                            }
                            setRowsPerPage={(rowsPerPage) =>
                                    setCompletedTicketsPagination((prev) => ({
                                        ...prev,
                                        rowsPerPage,
                                    }))
                            }
                            accountList={completedTickets}
                            page={completedTicketsPagination.page}
                            rowsPerPage={completedTicketsPagination.rowsPerPage}
                    ></Pagination>

                </TabPanel>
            </>
    );
};

type TicketTableProps = {
    tickets: any[];
    onResolveTicket?: (ticket: any) => Promise<any>;
    isCompleted?: boolean;
    isGoToClientVisible?: boolean;
};

const TicketTooltip = ({ children }) => (
        <Tooltip title={"View ticket"}>
            <div style={{ cursor: "pointer" }}>
                {children}
            </div>
        </Tooltip>
);

const TicketTable = ({
                         tickets,
                         onResolveTicket,
                         isCompleted = false,
                         isGoToClientVisible,
                     }: TicketTableProps) => {
    const [ticketSelected, setTicketSelected] = React.useState<any>(null);


    const handleClick = async (event, ticket) => {
        setTicketSelected(ticket);
    };

    const handleClose = () => {
        setTicketSelected(null);
    };

    const handleResolveTicket = async (ticket) => {
        if (onResolveTicket) {
            try {
                await onResolveTicket(ticket);
                handleClose();
            } catch (e) {
                console.warn(e);
            }
        }

    };

    return (
            <>
                {ticketSelected && (
                        <TicketDetailDialog
                                onClose={handleClose}
                                ticket={ticketSelected}
                                onResolveTicket={handleResolveTicket}
                                isCompleted={isCompleted}
                        />
                )}
                <TableWrap>
                    <TableBody>
                        {tickets && tickets.length === 0 ? (
                                <TableRow key={1 + "noAction"}>
                                    <TableCell>
                                        <Typography align="left" variant="caption" className="floatLeft mb15 w100">No
                                            tickets</Typography>
                                    </TableCell>
                                </TableRow>
                        ) : (
                                tickets &&
                                tickets.length > 0 &&
                                tickets.map((ticket, index) => {
                                    return (
                                            <TableRow
                                                    key={index}
                                                    hover
                                                    onClick={(event) =>
                                                            handleClick(event, ticket)
                                                    }
                                            >
                                                <TableCell scope="row">
                                                    <TicketTooltip>
                                                        Ticket for{" "}
                                                        {fullName(
                                                                ticket && ticket.client && ticket.client.user,
                                                        )}
                                                    </TicketTooltip>
                                                </TableCell>
                                                <TableCell scope="row">
                                                    <TicketTooltip>
                                                        Created by {fullName(ticket.createdBy)}
                                                    </TicketTooltip>
                                                </TableCell>
                                                <TableCell scope="row">
                                                    <TicketTooltip>
                                                        {dateUtils.asDbDateFormat(
                                                                ticket.createdAt,
                                                        )}
                                                    </TicketTooltip>
                                                </TableCell>
                                                {isCompleted && (
                                                        <TableCell scope="row">
                                                            <TicketTooltip>
                                                                Resolved by{" "}
                                                                {fullName(ticket.resolvedBy)}
                                                            </TicketTooltip>
                                                        </TableCell>
                                                )}
                                                {
                                                        isGoToClientVisible &&
                                                        <TableCell scope="row">
                                                            <Tooltip title={"Go to Client Details"}>
                                                                <IconButton
                                                                        aria-label="ticket"
                                                                        color="primary"
                                                                        component={Link}
                                                                        to={`/new-client/detail/${ticket && ticket.client && ticket.client.id}`}
                                                                >
                                                                    <VisibilityOutlinedIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                }
                                            </TableRow>
                                    );
                                })
                        )}
                    </TableBody>
                </TableWrap>
            </>
    );
};

export const TicketDetailDialog = ({
                                       onClose: handleClose,
                                       ticket,
                                       onResolveTicket,
                                       isCompleted,
                                   }) => {
    const classes = useStyles();
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

    const resolveTicket = async (ticket) => {
        try {
            await onResolveTicket(ticket);
            setIsConfirmationVisible(false);
        } catch (e) {
            console.warn("in TicketDetailDialog");
            console.warn(e);
        }
    };

    return (
            <Dialog
                    maxWidth={"sm"}
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={!!ticket}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {isCompleted ? "Completed" : "Current"} Ticket

                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="subtitle2" component="strong">
                        Created by {fullName(ticket.createdBy)} -{" "} {dateUtils.asDbDateFormat(ticket.createdAt)}
                    </Typography>
                    <Typography>
                        Ticket Details for {fullName(ticket.client.user)}:
                        <br/>
                        {ticket.detail}
                        <br/>
                        {
                                ticket.filename &&
                                <SecuredLink file={ticket} directory={ticket.createdBy.id + "-ticket"}/>
                        }
                    </Typography>
                    <Chat
                            chatRoomId={ticket.chatRoomId}
                            disabled={isCompleted}
                    />
                </DialogContent>
                <DialogActions className={classes.buttonDialog}>
                    <Button variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                    {!isCompleted && (
                            <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setIsConfirmationVisible(true)}
                            >
                                Mark Resolved
                            </Button>
                    )}
                </DialogActions>
                {
                        isConfirmationVisible &&
                        <ConfirmationDialog
                                title="Resolve ticket confirmation"
                                text="Are you sure you want to mark this ticket as resolved?"
                                onOk={() => resolveTicket(ticket)}
                                onCancel={() => setIsConfirmationVisible(false)}
                        />
                }
            </Dialog>
    );
};

export const SecuredLink = ({ file, directory }) => {
    const { filename } = file;
	const {secureFilePath} = useSecuredUrl(file, directory);

    return (<a href={secureFilePath} target="_blank">{filename}</a>);
};

export const ConfirmationDialog = ({ title, text, onOk, onCancel }) => (
        <Dialog
                onClose={onCancel}
                aria-labelledby="customized-dialog-title"
                open
        >
            <DialogTitle id="customized-dialog-title" onClose={onCancel}>
                {title}
            </DialogTitle>
		<DialogContent dividers>
                <Typography>
                    {text}
                </Typography>
            <Button className="floatLeft mt20 mb20" variant="contained" onClick={onCancel}>Cancel</Button>
            <Button className="floatRight mt20 mb20" variant="contained" color="primary" onClick={onOk}>Yes</Button>
            </DialogContent>
        </Dialog>
);

const Chat = ({ chatRoomId, disabled = false }) => {

    const [page, setPage] = React.useState(0);
    const [messages, setMessages] = React.useState<any>({});
    const chatRef = React.useRef<any>(null);
    const user = useSelector((state: RootState) => state.auth.user);
    const chatActions = useActions(ChatActions);
    const [existMoreMessages, setExistMoreMessages] = useState(true);

    const [chatConnections, setChatConnections] = useState([]);

    const listMoreMessages = async () => {
        const messages = await getMessages();
        setMessages(prevMessages => ({ ...messages, ...prevMessages }));
    };

    const listLastMessages = async () => {
        const messages = await getMessages();
        setMessages(messages);
        scrollToBottom();
    };

    const getMessages = async () => {
        const responseMessages = await chatActions.listMessages({
            chatRoomId,
            page,
        });

        const rowsPerPage = 15;
        setExistMoreMessages(responseMessages.length === rowsPerPage);

        return formatAsChatMessages(responseMessages);
    };

    function scrollToBottom() {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }

    useEffect(() => {
        if (page !== 0) {
            listMoreMessages();
        }
    }, [page]);


    useEffect(() => {
        chatActions.listLastConnections({ chatRoomId }).then(connections => {
            setChatConnections(connections.filter(x => x.userId !== user.id));
        });

        chatActions.connectToChatRoom({ chatRoomId });
    }, [chatRoomId]);

    useEffect(() => {
        setPage(0);
        listLastMessages();
    }, [chatRoomId]);

    useEffect(() => {
        const handleScroll = () => {
            if (chatRef.current.scrollTop === 0 && existMoreMessages) {
                setPage(prevPage => prevPage + 1);
            }
        };

        if (chatRef && chatRef.current) {
            chatRef.current.addEventListener("scroll", handleScroll);

            return () => {
                chatRef.current.removeEventListener("scroll", handleScroll);
            };
        }
    });

    const sendMessage = async (values, { setSubmitting, resetForm }) => {
        await chatActions.sendMessage({
            content: values.message,
            chatRoomId: chatRoomId,
            type: "text",
        });
        await listLastMessages();
        setSubmitting(false);
        resetForm();
    };


    const dirName = user.id + "-chat";
    const isSecuredSignedUrl = true;
    const awsActions = useActions(AwsActions);

    const handleUploadFile = async ({ target }) => {
        await uploadFiles(target.files);
    };

    const uploadFiles = async (fileList: FileList) => {
        let uploadedFiles = await getUploadedFiles(fileList, dirName, isSecuredSignedUrl, awsActions);
        await chatActions.sendMessage({
            content: JSON.stringify(uploadedFiles),
            type: "file",
            chatRoomId: chatRoomId,
        });
        await listLastMessages();
    };

    const handlePaste = (e: ClipboardEvent) => {
        const fileList = e.clipboardData && e.clipboardData.files;
        if (fileList) {
            const files = Array.from(fileList);
            if (files.length > 0) {
                uploadFiles(fileList);
            }
        }
    };

    return (
            <>
                <ChatBox height={400} ref={chatRef}>
                    {Object.keys(messages).map((date) => (
                            <Box key={date}>
                                <Box justifyContent="center" display="flex">
                                    <Box bgcolor="grey.50" borderRadius={16} py={1} px={2}>{date}</Box>
                                </Box>
                                {
                                    messages[date].map(message =>
                                            <ChatMessage
                                                    key={message.id}
                                                    position={
                                                        user.id === message.fromUser.id ? "right" : "left"
                                                    }
                                                    fromUser={message.fromUser}
                                                    messages={message.messages}
                                                    latestConnections={chatConnections}
                                            />,
                                    )
                                }
                            </Box>
                    ))}
                </ChatBox>

                <Formik
                        initialValues={{ message: "" }}
                        onSubmit={sendMessage}
                        validationSchema={Yup.object({ message: Yup.string().required() })}
                        validateOnMount
                >
                    {({
                          values,
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          isSubmitting,
                          isValid,
                      }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <Field
                                                name="message"
                                                component={TextField}
                                                value={values.message}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onPaste={handlePaste}
                                                placeholder="Message"
                                                disabled={disabled}
                                                InputProps={{
                                                    endAdornment:
                                                            <FileAdornment
                                                                    onUploadFile={handleUploadFile}
                                                                    disabled={disabled}
                                                            />,
                                                }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Fab
                                                color="primary"
                                                aria-label="send"
                                                type="submit"
                                                disabled={isSubmitting || !isValid || disabled}
                                        >
                                            <SendIcon/>
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </form>
                    )}
                </Formik>
            </>
    );
};


export async function getUploadedFiles(fileList: FileList, dirName: string, isSecuredSignedUrl: boolean, awsActions: any) {
    const files = Array.from(fileList);
    const result = files.map(async (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise<any>((resolve, reject) => {
            reader.onload = async () => {
                const fileS3: any = await handleSendToS3(file as any, dirName, isSecuredSignedUrl, awsActions);
                resolve(fileS3);
            };
        });
    });

    let uploadedFiles = await Promise.all(result);
    return uploadedFiles;
}

const handleSendToS3 = async (file: any, dirName: string, isSecuredSignedUrl: boolean, awsActions: any) => {
    const queryObj = {
        objectName: file.name,
        contentType: file.type,
        dirName: dirName,
    };
    const { payload: { signedUrl, filename } } = await awsActions.SignedUrlGet(queryObj, isSecuredSignedUrl);
    console.log("***filename**", filename);
    if (!signedUrl) {
        console.log("Signed Url Not returned");
        return false;
    }

    return new Promise((resolve, reject) => {
        api
                .put(signedUrl)
                .send(file)
                .then((res: awsResp) => {
                    const parseUrl = parseEmptyString(res.req && res.req.url);

                    const parsedUrl = url.parse(parseUrl, true);
                    const fileUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.pathname}`;

                    resolve({ fileUrl, filename, fileType: file.type, fileSize: file.size });
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
    });
};

export const FileAdornment = ({ onUploadFile, disabled = false }) => {
    const onInputClick = (event) => {
        event.target.value = "";
    };

    return (
            <InputAdornment position="end">
                <input
                        accept="image/*,.pdf"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        onChange={onUploadFile}
                        type="file"
                        disabled={disabled}
                        onClick={onInputClick}
                />
                <label htmlFor="raised-button-file">
                    <IconButton component="span"
                                className="iconBtn"
                                aria-label="upload file"
                    >
                        <AttachFileIcon/>
                    </IconButton>
                </label>
            </InputAdornment>
    );
};

const ChatBox = styled(Box)`
    overflow-x: hidden;
    overflow-y: auto;
`;


const wasRead = (message, latestConnections) => {
    return latestConnections.some(connection => message.createdAt < new Date(connection.connectedAt));
};

const ChatMessage = ({ position, fromUser, messages, latestConnections }) => (
        <Box py={2}>
            <Grid container spacing={2}>
                <Grid item>
                    <HiddenAvatar/>
                </Grid>
                <Grid item>
                    <AvatarName>{fullName(fromUser)}</AvatarName>
                </Grid>
            </Grid>
            {messages.map((message, idx) => (
                    <Grid key={idx} container spacing={2}>
                        <Grid item>
                            {idx === 0 ? (
                                    <IliaAvatar topRightProfileImage={(fromUser && fromUser.image) || ""}/>
                            ) : (
                                    <HiddenAvatar/>
                            )}
                        </Grid>
                        <Grid item>
                            <ChatBubble primary={position === "right"}>
                                {
                                    message.type === "text" ?
                                            message.content :
                                            <FileMessages content={message.content} user={fromUser}/>
                                }
                                <Box component="span" fontSize="0.8rem">
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Box component="span" pr={1}>{dateUtils.asDbTimeFormat(message.createdAt)}</Box>
                                        {position === "right" &&
                                                <MessageReadIcon wasRead={wasRead(message, latestConnections)}/>
                                        }
                                    </div>
                                </Box>
                            </ChatBubble>
                        </Grid>
                    </Grid>
            ))}
        </Box>
);


const MessageReadIcon = ({ wasRead }) => (
        wasRead ?
                <DoneAllIcon fontSize="small"/> :
                <DoneIcon fontSize="small"/>
);

const FileMessages = ({ content, user }) => {
    const files = JSON.parse(content);

    return files.map(file =>
            <FileMessage key={file.fileUrl} file={file} user={user}/>,
    );
};

export const useSecuredUrl = (file, directory) => {
    const awsActions = useActions(AwsActions);
	const [secureFilePath, setSecureFilePath] = useState("");
    const [fileExt, setFileExt] = React.useState("");

    const getSignedAndSecuredURL = async (identityPhoto, directory) => {

        let file = identityPhoto.split(".");
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
        let queryObj = {
            objectName: identityPhoto,
            contentType: contentType,
        };

        const secureUrl = await awsActions.getSecureUrl(directory, queryObj, true);
        setSecureFilePath(secureUrl);
		setFileExt(fileType)
    };

    useEffect(() => {
		if(file) {
			getSignedAndSecuredURL(file.filename, directory);
		}
	}, [file])

	return {secureFilePath, fileExt};
};

const FileMessage = ({ file, user }) => {
	const {secureFilePath} = useSecuredUrl(file, user.id + "-chat");

    const ext: string | undefined = file.filename.split(".").pop();
    if (ext === "jpg" || ext === "png") {
        return (
                <a href={secureFilePath} target="_blank">
                    <img src={secureFilePath} style={{ maxWidth: 300 }}/>
                </a>
        );
    }

    return (
            <a href={secureFilePath} target="_blank" style={{ color: "inherit", display: "flex" }}>
                {
                        ext === "pdf" &&
                        <img alt={file.filename}
                             style={{ maxWidth: 50, maxHeight: 50 }}
                             className="img-responsive"
                             src={pdfIcon}/>
                }
                <div style={{ paddingLeft: "1rem" }}>
                    {file.filename}
                    <Typography style={{ fontSize: "0.8rem", marginBottom: "0rem" }}>
                        {filesize(file.fileSize, { base: 10 })}
                    </Typography>
                </div>
            </a>
    );
};

const HiddenAvatar = () => <div style={{ width: "40px", opacity: 0 }}></div>;

const ChatBubble = ({ primary, children }) => {
    const props = primary
            ? {
                color: "white",
                bgcolor: "primary.main",
            }
            : {
                bgcolor: "grey.50",
            };

    return (
            <Box {...props} borderRadius={16} py={1} px={2}>
                {children}
            </Box>
    );
};

const AvatarName = ({ children }) => (
        <Typography variant="caption" display="block" gutterBottom>
            {children}
        </Typography>
);

function formatAsChatMessages(responseMessages: any[]) {
    responseMessages = sortBy(
            responseMessages.map(x => ({ ...x, createdAt: new Date(x.createdAt) })),
            ["createdAt"]);

    const messagesByDate = groupBy(responseMessages, message => dateUtils.asDbDateFormat(message.createdAt));

    return Object.keys(messagesByDate).reduce((prev, date) => {
        prev[date] = groupByUser(messagesByDate[date]);
        return prev;
    }, {});
}

const groupByUser = (responseMessages: any[]) => {
    let messages: any[] = [];
    responseMessages.forEach((responseMessage) => {
        if (messages.length === 0) {
            messages.push({
                id: responseMessage.id,
                fromUser: responseMessage.fromUser,
                messages: [responseMessage],
            });
            return;
        }

        const lastAddedMessage = messages[messages.length - 1];
        if (lastAddedMessage.fromUser.id === responseMessage.fromUser.id) {
            lastAddedMessage.messages.push(responseMessage);
        } else {
            messages.push({
                id: responseMessage.id,
                fromUser: responseMessage.fromUser,
                messages: [responseMessage],
            });
        }
    });
    return messages;
};
