import React from "react";
import { Label } from "../../../../../components/formInputs";
import {
    ErrorCommon,
    TextField,
    TextFieldCommon,
    NumberTextField, EINNumberField
} from "../../../../../components/formikFormInputs";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

interface trustDetailsProps {
    values:any, handleChange:Function,
    errors:any, touched:any,
    spousesSharesTrustFee:boolean,
    setSpousesSharesTrustFee:Function,
    setIsPolicyDirty:Function,
    marriageStatus: string,
    disabled: boolean
}

const TrustDetails = (props: trustDetailsProps) => {
    const { values, handleChange, errors, touched, spousesSharesTrustFee, setSpousesSharesTrustFee, setIsPolicyDirty, marriageStatus, 
        disabled = false } = props;
    const fieldObj = [
        {
            id: "annualTrustFeeAmount",
            name: "annualTrustFeeAmount",
            label: <Label label={"Annual Trust Fee Amount"}/>,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                position="start">$</InputAdornment>,
            },
            disabled: true
        },
        {
            id: "spouse",
            name: "spouse",
            label: <Label label={"Spouse"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled: true
        },
        {
            id: "einNumber",
            name: "einNumber",
            label: <Label label={"EIN Number"}/>,
            component: EINNumberField,
            filedType: "string",
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled
        },
        {
            name: "trustName",
            label: <Label label={"The Insurance Trust"} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            disabled
        },
        {
            id: "trustFirstYearFee",
            name: "trustFirstYearFee",
            label: <Label label={"Trust First Year Fee"}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            disabled: true
        },
        {
            id: "trustAnnualFee",
            name: "trustAnnualFee",
            label: <Label label={"Trust Annual Fee"}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            disabled: true
        }
    ];

    return (
            <React.Fragment>
                <Typography variant="subtitle1" component="strong" className="mb10 mt30 floatLeft w100">Trust
                    Details</Typography>
                <Grid container spacing={3}>
                    {fieldObj.map((field, index) => {
                        if(field.name === "spouse" && marriageStatus !== "Married" ) return null;
                        return (
                                <Grid item xs={12} md={6} xl={4}
                                      key={index} className="mb15">
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={handleChange}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched}/>
                                </Grid>
                        );
                    })
                    }
                    {marriageStatus === "Married" ?
                    <Grid item xs={12}>
                        <List dense className="pt0 mb0 checkGroup">
                            <ListItem style={{ alignItems: "end" }}>
                                <ListItemAvatar style={{ minWidth: "15px" }}>
                                    <FormControlLabel
                                            className="checkGroup"
                                            control={<Checkbox color="primary"
                                                       checked={spousesSharesTrustFee}
                                                       onChange={() => {
                                                           setSpousesSharesTrustFee(!spousesSharesTrustFee);
                                                           setIsPolicyDirty(true);
                                                       }}/>}
                                            label=""/>
                                </ListItemAvatar>
                                <ListItemText primary="Spouse shares trust fees"
                                              secondary="Make sure to verify amount on spouse profile"/>
                            </ListItem>
                        </List>
                    </Grid>:""
                    }
                </Grid>
            </React.Fragment>
    );

};
export default TrustDetails;