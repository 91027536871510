import { ActionType, SetPassword } from "../model";

export const verifyAccount = (inviteToken: string) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let resp = await api.post('account/verify', {inviteToken}, {});
		dispatch({ type: ActionType.ALERT, payload: {} });
		return dispatch({ type: ActionType.VERIFY_INVITATION, payload: resp });
	} catch(err) {
    dispatch({ type: ActionType.VERIFY_INVITATION, payload: {verifiedInvite: false, checked: true, updated: false } });
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const setPassword = (payload: SetPassword, isCreatePassword:boolean = false) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const resp = await api.post('account/password', payload, {});
		if(resp && resp.authDetails){
		    if(isCreatePassword){
                setTimeout(()=> dispatch({ type: ActionType.LOGIN, payload: resp.authDetails }), 100);
		        return { type: ActionType.LOGIN, payload: resp.authDetails }
            }
			dispatch({ type: ActionType.SET_PASSWORD, payload: {verifiedInvite: true, updated: true, checked: true } });
			return dispatch({ type: ActionType.LOGIN, payload: resp.authDetails });
		}
		dispatch({ type: ActionType.ALERT, payload: { message: "Password updated successfully.", statusCode: 200 } });
		return resp;
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const createPassword = (payload: SetPassword) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const resp = await api.post('account/password/new-invitaion', payload, {});
		if(resp && resp.authDetails){
			dispatch({ type: ActionType.SET_PASSWORD, payload: {verifiedInvite: true, updated: true, checked: true } });
			return dispatch({ type: ActionType.LOGIN, payload: resp.authDetails });
		}
		dispatch({ type: ActionType.ALERT, payload: { message: "Password created successfully.", statusCode: 200 } });
		return resp;
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const checkAuthAndCreatePassword = (payload: SetPassword) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const resp = await api.post('account/password/new-invitaion-auth', payload, {});
		if(resp && resp.authDetails){
			dispatch({ type: ActionType.SET_PASSWORD, payload: {verifiedInvite: true, updated: true, checked: true } });
			return dispatch({ type: ActionType.LOGIN, payload: resp.authDetails });
		}
		dispatch({ type: ActionType.ALERT, payload: { message: "Password created successfully.", statusCode: 200 } });
		return resp;
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const checkNewInvitationAuth = (payload: SetPassword) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let reponse  = await api.post('account/new-invitation/auth', payload, {});
        return dispatch({ type: ActionType.ALERT, payload: { message: "You are validated successfully.", statusCode: 200, token: reponse.token ? reponse.token : ""} });
	} catch(err) {
		return { type: ActionType.ALERT, payload: err }
	}
}

export const checkClientListAuthAndCreatePassword = (payload: SetPassword) => async (dispatch: Function, getState: Function, api: any)  => {
    console.log("payload", payload);
    //return false;
	try {
		const resp = await api.post('account/clientlist/new-invitaion-auth/password', payload, {});
		if(resp && resp.authDetails){
			dispatch({ type: ActionType.SET_PASSWORD, payload: {verifiedInvite: true, updated: true, checked: true } });
			return dispatch({ type: ActionType.LOGIN, payload: resp.authDetails });
		}
		dispatch({ type: ActionType.ALERT, payload: { message: "Password created successfully.", statusCode: 200 } });
		return resp;
	} catch(err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const verifyPasswordResetLink = (resetPasswordToken: string) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let resp = await api.post('account/reset/password/verify', {resetPasswordToken}, {});
		dispatch({ type: ActionType.ALERT, payload: {} });
		return dispatch({ type: ActionType.VERIFY_RESET_PASSWORD_LINK, payload: resp });
	} catch(err) {
		dispatch({ type: ActionType.VERIFY_RESET_PASSWORD_LINK, payload: {verifiedInvite: false, checked: true, updated: false } });
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const verifyOtp = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const resp = await api.post('account/verify_otp', payload, {});
		dispatch({ type: ActionType.ALERT, payload: { message: "OTP verification done successfully.", statusCode: 200 } });
		return resp;
	} catch(error) {
		return dispatch({ type: ActionType.ALERT, payload: error });
	}
}

export const resendVerificationOtp = (payload: object) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		const resp = await api.post('account/resend_verification_otp', payload, {})
		dispatch({ type: ActionType.ALERT, payload: { message: "OTP send successfully.", statusCode: 200 } });
		return  resp;
	} catch(error) {
		return dispatch({ type: ActionType.ALERT, payload: error });
	}
}