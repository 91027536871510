import React from "react";
import { Container, Grid, Typography, List, ListItem, ListItemText } from "@material-ui/core";
import WhyKaiZenImg from "./Images/WhyKaiZen.jpg";

function WhyKaiZen(props: {strategy: string}) {
    const {strategy} = props;
    return <div className="sectionPD pdXsScreenTop0">
        <Container maxWidth="lg" className="pdXsScreenNone">
            <Grid container spacing={3} alignItems="center" className="colReverse colMarginNone">
                <Grid item xs={12} md={6} className="pdXsScreen20">

                    <Typography variant="h3" color="primary" className="title positionR">Why {strategy}</Typography>
                    <Typography variant="caption" color="primary">We have all used leverage to purchase a nicer house or
                        to buy a better car. {strategy} uses leverage for the potential to accumulate more growth & obtain more protection, while
                        providing the ability to maintain your current standard of living. That’s the smart way to use
                        leverage.</Typography>
                    <Typography variant="caption" component="strong" color="primary" className="floatLeft w100 mt20">Value Provided</Typography>
                    <List className="forTickStyle">
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption"> Potential for more accumulation.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption"> Maintain current lifestyle.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption"> More protection for you and your family.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption"> Diversify your assets.</Typography></>}/>
                        </ListItem>
                    </List>

                    <Typography variant="body1" component="strong" color="primary" className="floatLeft w100 mt20">Required Qualifications</Typography>
                    <List className="forTickStyle">
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">Your combined household income is at least 100k per year.</Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">You are between the ages of 18 - 65. </Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">You are in average or good health. </Typography></>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={<><Typography color="primary" variant="caption">You can contribute a minimum of $22k per year.</Typography></>}/>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6} className="pdXsScreenNone pdXsScreenBottom">
                    <img style={{maxWidth:667,width:'100%'}} src={WhyKaiZenImg} alt="WhyKaiZen"/>
                </Grid>


            </Grid>
        </Container>

        {/*<OvalBottom direction="right"/>*/}

    </div>;
}

export default WhyKaiZen;