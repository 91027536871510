import React from "react";
import {Typography} from "@material-ui/core";
import {ROLES} from "../../constants";
import {Dialog, GenericDialogProps} from "../dialog";
import {Button, ButtonProps} from "../formInputs";
import {BN, BS} from "../../model/misc";
import {isFunc, ucFirst} from "../../utils/commonUtils";



interface OnBoardingToServicingProps {
    confirmOnboarding: BS;
    onboardingAppPref: BN;
    onCancel?: Function;
    onConfirm: any | Function;
    role: number;
    setConfirmOnboarding: Function;
}

export enum OnBoardingTypeEnum {
	Approved,
	Preferred
}


const OnBoardingToServicing = ({confirmOnboarding, onboardingAppPref, onCancel, onConfirm, role, setConfirmOnboarding}: OnBoardingToServicingProps) => {
    const [sendingOnboarding, setSendingOnboarding] = React.useState<boolean>(false);
    const onCancelOnboarding = () => onCancel && isFunc(onCancel) ? onCancel() : setConfirmOnboarding(false);
    const onConfirmOnboarding = async () => {
        setSendingOnboarding(true);
        await onConfirm();
        setConfirmOnboarding(false);
        setSendingOnboarding(false);
    };

    const userType = ucFirst(ROLES[role]);
    const onboardingTitle = `Confirm ${OnBoardingTypeEnum[onboardingAppPref as number]} ${userType} Onboarding`;

    const [onboardingCancelButtonProps, onboardingConfirmButtonProps]: [ButtonProps, ButtonProps] = [{
        className: "floatLeft",
        label: "Cancel",
        onClick: onCancelOnboarding,
        variant: "contained",
    }, {
        className: "floatLeft",
        color: "primary",
        label: "Yes",
        loading: sendingOnboarding,
        onClick: onConfirmOnboarding,
        variant: "contained",
    }];
    const onboardingConfirmationDialogProps: GenericDialogProps = {
        actions: (
                <>
                    <Button {...onboardingCancelButtonProps} />
                    <Button {...onboardingConfirmButtonProps} />
                </>
        ),
        "aria-describedby": onboardingTitle,
        "aria-labelledby": onboardingTitle,
        content: (
                <Typography>
                    Are you sure you want to onboard & welcome this {userType} to Ilia Servicing
                </Typography>
        ),
        fullWidth: true,
        maxWidth: "md",
        onClose: onCancelOnboarding,
        open: !!confirmOnboarding,
        title: onboardingTitle,
    };

    return (
            <>
                {
                        confirmOnboarding &&
                        <Dialog {...onboardingConfirmationDialogProps} />
                }
            </>
    );
}

export default OnBoardingToServicing;