import {Box, Button, Grid, TableCell, Typography} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageTitle } from "../../../components/formInputs";
import { RootState } from "../../../reducers";
import { HeroBanner, QuickViewSection } from "../../agent/dashboard/AgentDashboard";
import { DashboardStyle } from "../../agent/dashboard/dashboardStyle";
import PortalPages, { PortalPagesProps } from "../../agent/dashboard/PortalPages";
import * as AccountActions from "../../../actions/account";
import { useActions } from "../../../actions";
import { ClientQuickView } from "../../agent/dashboard/AgentDashboard";
import clientManagement from "../../agent/dashboard/images/ico-client-management.svg";
import clientEvents from "../../agent/dashboard/images/ico-events.svg";
import iliaTraining from "../../agent/dashboard/images/ico-training.svg";
import marketingMaterial from "../../agent/dashboard/images/ico-events.svg";
import { GenericQuickView, QuickViewProps } from "../../agent/dashboard/QuickView";
import { ImoAgentTable, AgentLinkTable } from "./ImoAgentTable";
import { AccountOrder, ImoDashboardListType } from "../../../model/account";
import { MultiLifeTable } from "../../../components/table";
import { withRouter } from 'react-router-dom';
import { ParticipantTable } from "../../../components/group/ParticipantList";
import { Pagination } from "../../../components/table";
import DemoModeSelectionPopup from "../../../components/DemoMode/DemoModeSelectionPopup";

const pages: Array<PortalPagesProps> = [
    {
        mainTitle: "Agent Management",
        content: "Your agents and clients for follow up marketing and reporting.",
        image: clientManagement,
        redirectTo: "/imo",
    },
    {
        mainTitle: "Upcoming Events",
        content: "Register for events led by the myilia team.",
        image: clientEvents,
        redirectTo: "/imos/events",
    },
    {
        mainTitle: "ilia Training",
        content: "Preferred Agent training to learn myilia features and functionality.",
        image: iliaTraining,
        redirectTo: "/imos/ILIATrainingPage",
    },
    {
        mainTitle: "Marketing Materials",
        content: "Preferred Agent training to learn myilia features and functionality.",
        image: marketingMaterial,
        redirectTo: "/imos/marketing-material",
    },
]

const doNone = () => null

const GroupImoQuickView = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [kpiData, setKpiData] = React.useState<any[]>([]);
    
    return (
        <GenericQuickView {...props} setKpiData={setKpiData} kpiData={kpiData}>
            <MultiLifeTable
                accountList={{rows: kpiData}}
                order={"NONE"}
                orderBy={"NONE"}
                handleRequestSort={doNone}
                callingFrom='accountList'
                history={props.history}
                handleRefresh={doNone}
                page={page}
                rowsPerPage={rowsPerPage}
            />
        </GenericQuickView>
    )
}

const GroupImoQuickViewWithHistory = withRouter(GroupImoQuickView)


type ParticipantImoQuickViewProps = QuickViewProps & {isEmployerContributionNotVisible?: boolean};

const ParticipantImoQuickView = ({isEmployerContributionNotVisible = false, ...rest}: ParticipantImoQuickViewProps) => {    
    const [kpiData, setKpiData] = React.useState<any[]>([]);

    return (
        <GenericQuickView {...rest} setKpiData={setKpiData} kpiData={kpiData}>
            <ParticipantTable 
                page={0}
                rowsPerPage={kpiData.length}
                accountList={{rows: kpiData}}
                order={"NONE"}
                orderBy={"NONE"}
                handleRequestSort={() => null}
                isContributionVisible={account => true}
                isEmployerContributionNotVisible={isEmployerContributionNotVisible}
                getOfferType={
                    account => account.user? account.user.client.underWritingOfferTypeFinal || account.user.client.underWritingOfferType: account.underWritingOfferType}
            >
                {(v) => 
                    <TableCell padding="default" align="right">
                        -
                    </TableCell>}
            </ParticipantTable>
        </GenericQuickView>
    )
}


const AgentImoQuickView = (props) => {
    const [kpiData, setKpiData] = React.useState<any>([]);
    const { selectable, ...rest} = props;

    return (
        <GenericQuickView {...rest} setKpiData={setKpiData} kpiData={kpiData}>
            <ImoAgentTable kpiData={kpiData} selectable={selectable} />
        </GenericQuickView>
    )
}

const AgentLinkQuickView = ({initialOrderBy, ...rest}: any) => {
    const [isFirstTime, setIsFirstTime] = useState(true)
    
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState<AccountOrder>("desc");
    const [orderBy, setOrderBy] = useState(initialOrderBy);

    const [count, setCount] = useState(0);
    const [kpiData, setKpiData] = React.useState<any[]>([]);

    const handleRequestSort = async (event: React.MouseEvent<unknown>, property: string) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    async function refresh() {
        const kpi = rest.data[0].kpiModal
        let resp = await handleExport({ listType: kpi, listDataType: "json" });
        if (resp && resp.length > 0) {
            setKpiData(resp);
        }
    }

    useEffect(() => {
        if(isFirstTime)
            setIsFirstTime(false)    
        else
            refresh()
    }, [order, orderBy, page, rowsPerPage])

    const handleExport = async (payload) => {
        if(payload.listType !== 'TOTAL_AGENT_LINK_VIEWS'){
            const resp = await rest.handleExport({ ...payload, page, rowsPerPage, order, sortBy: orderBy  });
            setCount(resp.count)
            return resp.rows;
        }
    }

    return(
        <GenericQuickView
            isHeading={rest.isHeading}
            heading={rest.heading}
            data={rest.data}
            handleExport={handleExport}
            kpiData={kpiData}
            setKpiData={setKpiData}
            isExportListVisible={false}>
                <>
                    <AgentLinkTable 
                        order={order}
                        orderBy={orderBy}
                        handleRequestSort={handleRequestSort}
                        kpiData={kpiData}
                    />
                    <Pagination page={page} rowsPerPage={rowsPerPage} setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                        accountList={{count}}/>
                </>
        </GenericQuickView>
    )
}


const getImoName = (auth: any) => {
    const user = auth.user;
    if(user && user.role == 15){
        return (user.ImoAdditionalMembers && user.ImoAdditionalMembers.imo && user.ImoAdditionalMembers.imo.groupName) || "";
    }
    return (user && user.imo && user.imo.groupName) || "";
}

const ImoDashboard = (props) => {
    const classes = DashboardStyle();
    const auth = useSelector((state: RootState) => state.auth);
    let user:any = auth && auth.user;
    let name: string = getImoName(auth);
    const accountActions = useActions(AccountActions);
    const [showDemoSelectionPopup, setShowDemoSelectionPopup] = React.useState<boolean>(false);

    const handleNavigation = async (url: string) => {
        props.history.push(url);
        await accountActions.refreshApplicationState();
    };

    const imoUserId = auth.user.id

    const {
        totalAgentsInvited,
        totalAgentAccounts,
        totalActiveAgents,
        totalInActiveAgents,
        clientStatistics,
        leadsStatistics, 
        groupStatistics
    } = useImoStatistics(imoUserId)

    return (
        <div className={classes.containerWidth1700}>
            <PageTitle title="IMO Dashboard" />
            <HeroBanner name={name} 
                subheader={`This
                is your dashboard for viewing and managing your ilia agents and clients. 
                You can get started by selecting an option
                below or from the main navigation.`
            }>

                <Grid item xs={12}>
                    <Button
                            className="mt30 glow bgWhite"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={() => setShowDemoSelectionPopup(true)}
                    >
                        Client Presentation
                    </Button>
                </Grid>
            </HeroBanner>
            <Grid container spacing={2} className="mt40">
                <Grid item xs={12}>
                    <Typography variant="caption" component="strong" align="left"
                        className={classes.textLight + " " + classes.mb15 + " " + classes.floatLeft + " " + classes.w100}>
                        PORTAL PAGES </Typography>
                    <Box display="flex" flexWrap="wrap"
                        className={classes.flexBox + " " + classes.floatLeft + " " + classes.w100}>
                        <PortalPages pages={pages} handleNavigation={handleNavigation} />
                    </Box>
                </Grid>
            </Grid>
            <ImoKpis 
                imoUserId={imoUserId}
                totalAgentsInvited={totalAgentsInvited}
                totalAgentAccounts={totalAgentAccounts}
                totalActiveAgents={totalActiveAgents}
                totalInActiveAgents={totalInActiveAgents}
                clientStatistics={clientStatistics}
                leadsStatistics={leadsStatistics} 
                groupStatistics={groupStatistics}
            ></ImoKpis>
            {user && (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 15) && user.accessType !=="client" &&
                    <DemoModeSelectionPopup
                            showDemoSelectionPopup={showDemoSelectionPopup}
                            setShowDemoSelectionPopup={() => {
                                setShowDemoSelectionPopup(false);
                            }}
                            role={user && user.role}
                    />
            }
        </div>
    );
}

export const useImoStatistics = (imoUserId) => {
    const [totalAgentsInvited, setTotalAgentsInvited] = React.useState(0);
    const [totalAgentAccounts, setTotalAgentAccounts] = React.useState(0);
    const [totalActiveAgents, setTotalActiveAgents] = React.useState(0);
    const [totalInActiveAgents, setTotalInActiveAgents] = React.useState(0);
    const [clientStatistics, setClientStatistics] = React.useState({
        totalInvites: 0,
        totalAccountCreated: 0,
        enrolling: 0,
        enrolled: 0,
        activeUsers: 0, 
        inActiveUsers: 0
    });

    const [leadsStatistics, setLeadsStatistics] = React.useState({
        totalAgentLinkViews: 0,
        totalAgentLinkLeads: 0,
        totalAgentLinkLeadsClosed:0
    });

    const [groupStatistics, setGroupStatistics] = React.useState({
        totalGroups: 0,
        totalParticipants: 0,
        totalEnrollments: 0
    }); 

    const accountActions = useActions(AccountActions);

    const getImoDetails = async (imoUserId) => {
        let data = await accountActions.getImoDashboardDetails(imoUserId);
        if (data) {
            setTotalAgentsInvited(data && data.totalAgentsInvited);
            setTotalAgentAccounts(data && data.totalAgentAccounts);
            setTotalActiveAgents(data && data.totalActiveAgents);
            setTotalInActiveAgents(data && data.totalInActiveAgents);
            setClientStatistics(data && data.clientStatistics);            
            setGroupStatistics(data && data.groupStatistics);
            setLeadsStatistics(data && data.leadsStatistics);
        }
    };

    
    React.useEffect(() => {
        getImoDetails(imoUserId);
    }, [imoUserId]);

    return {
        totalAgentsInvited,
        totalAgentAccounts,
        totalActiveAgents,
        totalInActiveAgents,
        clientStatistics,
        leadsStatistics, 
        groupStatistics
    }
}

export const ImoKpis = ({
    imoUserId,
    totalAgentsInvited,
    totalAgentAccounts,
    totalActiveAgents,
    clientStatistics,
    leadsStatistics, 
    groupStatistics,
    totalInActiveAgents = 0,
}) => {
    const classes = DashboardStyle();
    const accountActions = useActions(AccountActions);
    
    const handleExport = payload => 
        accountActions.getImoDashboardLists({...payload, imoUserId })

    const handleClientExport = ({ listType, listDataType, statusTypes, searchText}) =>
        accountActions.getImoDashboardLists({ 
            listType: `CLIENT_${listType}`, 
            listDataType, 
            imoUserId,
            statusTypes,
            searchText
        })

    return (
        <>
            <QuickViewSection title="AGENT QUICK VIEW">
                <AgentImoQuickView
                    handleExport={handleExport}
                    isHeading={false}
                    heading={"All"}
                    data={[
                        {
                            tooltipText: null,
                            title:{name:"Total agents invited", value: totalAgentsInvited},
                            className:classes.btnBlue,
                            kpiModal: ImoDashboardListType.TotalAgentsInvited,
                            kpiModalTitle: "Total agents invited",
                        },
                        {
                            tooltipText: null,
                            title:{name: "Total agent accounts", value: totalAgentAccounts},
                            className: classes.btnGreen,
                            kpiModal: ImoDashboardListType.TotalAgentAccounts,
                            kpiModalTitle: "Total agent accounts",
                        }
                    ]}
                />
                <AgentImoQuickView
                    handleExport={handleExport}
                    isHeading={true}
                    heading={"Currently active agents"}
                    data={[
                        {
                            tooltipText: null,
                            title: { name: "Has an account and active within 30 days.", value: totalActiveAgents },
                            className: classes.btnGreen,
                            kpiModal: ImoDashboardListType.TotalActiveAgents,
                            kpiModalTitle: "Total active agents",
                        },
                    ]}
                />
                <AgentImoQuickView
                        handleExport={handleExport}
                        isHeading={true}
                        heading={"Inactive agents"}
                        data={[
                            {
                                tooltipText: null,
                                title: { name: "Agents that have not been active in ilia.", value: totalInActiveAgents },
                                className: classes.btnRed,
                                kpiModal: ImoDashboardListType.TotalInActiveAgents,
                                kpiModalTitle: "Total inactive agents",
                            },
                        ]}
                        selectable
                />
            </QuickViewSection>
            <QuickViewSection title="CLIENT QUICK VIEW">
                <ClientQuickView 
                    as={props => <ParticipantImoQuickView {...props} isEmployerContributionNotVisible={true}/>}
                    handleExport={handleClientExport}
                    imoUserId={imoUserId}
                    {...clientStatistics}
                />                
            </QuickViewSection>
            <QuickViewSection title="AGENT LINK QUICK VIEW">
                <AgentLinkQuickView
                    initialOrderBy="views"
                    handleExport={handleExport}
                    isHeading={true}                    
                    heading={"Total Links View"}
                    data={[
                        {
                            toolTipText: null,
                            title: { name: "Prospects that have visited landing pages from all links.", value: leadsStatistics && leadsStatistics.totalAgentLinkViews},
                            className: classes.btnGreen,
                            kpiModal: ImoDashboardListType.TotalAgentLinkViews,
                            kpiModalTitle:"Total Agent link Views"
                        }
                    ]}
                />
                <AgentLinkQuickView
                    initialOrderBy="emailLeads"
                    handleExport={handleExport}
                    isHeading={true}                    
                    heading={"Total Email Leads"}
                    data={[
                        {
                            toolTipText: null,
                            title: { name: "Total emails captured as leads from all links.", value: leadsStatistics && leadsStatistics.totalAgentLinkLeads},
                            className: classes.btnGreen,
                            kpiModal: ImoDashboardListType.TotalAgentLinkLeads,
                            kpiModalTitle: "Total Agent link Leads"
                        }
                    ]}
                />
                <AgentLinkQuickView
                    initialOrderBy="closedLeads"
                    handleExport={handleExport}
                    isHeading={true}                    
                    heading={"Total Closed Leads"}
                    data={[
                        {
                            toolTipText: null,
                            title: { name: "Number enrolled from all links.", value: leadsStatistics && leadsStatistics.totalAgentLinkLeadsClosed},
                            className: classes.btnGreen,
                            kpiModal: ImoDashboardListType.TotalAgentLinkLeadsClosed,
                            kpiModalTitle: "Total Agent Link Leads Closed"
                        }
                    ]}
                />

                
            </QuickViewSection>
            <QuickViewSection title="GROUP QUICK VIEW">
                <GroupImoQuickViewWithHistory
                    handleExport={handleExport}
                    isHeading={true}
                    heading={"Total Groups"}
                    data={[
                        {
                            tooltipText: null,
                            title:{name:"Number of Groups", value: groupStatistics && groupStatistics.totalGroups},
                            className:classes.btnBlue,
                            kpiModal: ImoDashboardListType.TotalGroups,
                            kpiModalTitle: "Total Groups",
                        },                       
                    ]}
                />
                <ParticipantImoQuickView
                    handleExport={handleExport}
                    isHeading={true}
                    heading={"Total Participant"}
                    data={[
                        {
                            tooltipText: null,
                            title:{name:"Accounts from Groups", value: groupStatistics && groupStatistics.totalParticipants},
                            className:classes.btnGreen,
                            kpiModal: ImoDashboardListType.TotalGroupParticipants,
                            kpiModalTitle: "Total Participant",
                        },                       
                    ]}
                />
                <ParticipantImoQuickView
                    handleExport={handleExport}
                    isHeading={true}
                    heading={"Total Enrollments"}
                    data={[
                        {
                            tooltipText: null,
                            title:{name:"Completed from Groups", value: groupStatistics && groupStatistics.totalEnrollments},
                            className:classes.btnGreen,
                            kpiModal: ImoDashboardListType.TotalGroupEnrollments,
                            kpiModalTitle: "Total Enrollments",
                        },                       
                    ]}
                />
            </QuickViewSection>
        </>
    )
}

export default ImoDashboard;