import React from "react";
import { Grid } from "@material-ui/core";
import Assets from "../FinancialAndLiabilities/Assets";
import Liabilities from "../FinancialAndLiabilities/Liabilities";
import UnearnedIncomePastYear from "./UnearnedIncomePastYear";
import EarnedIncome from "./EarnedIncome";
import AttestationOfTruth from "./AttestationOfTruth";
import FinancialAgreement from "./FinancialAgreement";
import SourceOfFunds from "./SourceOfFunds";
import RealStateHoldings from "./RealStateHoldings/index";
import BusinessEquity from "./BusinessEquity/index";
import { isIliaApp, isWorksheet } from "../Utilities";
import {isLongForm} from "../Utilities";
import PremiumFinancingQuestions from "./PremiumFinancing/PremiumFinancingQuestions";
import PremiumFinancing from "./PremiumFinancing";

const FinancialAndLiabilitiesContent = (props: any) => {
    const {clientDetail, isFromClientDetail, /*setIsError,*/ setFinancialAgreementRef, setPageChecked, setSourceOfFundsRef, setAttestRef,
        setAssetsVerifyRef, setAssetsVerified,
        setLiabilitiesVerifyRef, setLiabilitiesVerified,
        setEarnedVerifyRef, setEarnedVerified,
        setUnEarnedVerifyRef, setUnEarnedVerified,
        setIsSourceError, setFinancialAndLiabilitiesArr, financialAndLiabilitiesArr,
        financialAgreementFormRef,
        assetRef,
        liabilitiesRef,
        sourceOfFundsRefs,
        offerType,
        autoSaveTrigger,
        sendSaveRequest,
        setSaveFinancialAgreementForm,
        setSaveFormContent,
        setFormType,
        setSaveSourceOfFundsForm,
        earnedIncomeRef,
        unearnedIncomePastYearRef,
        premiumFinancingRefs
    } = props;

    return (
            <Grid container spacing={2}>
                {!isFromClientDetail &&
                <Grid item xs={12} md={12}>
                    <AttestationOfTruth
                            checked={(clientDetail && clientDetail.client && clientDetail.client.agreeOnFinancialQuestion) ? true : false}
                            setPageChecked={setPageChecked}
                            setAttestRef={setAttestRef}
                            name={isLongForm(offerType) ? "FINANCIAL_LIABILITIES_QUESTION_LONG_FORM":"FINANCIAL_LIABILITIES_QUESTION"}
                            userData={clientDetail}
                    />
                </Grid>
                }

                <Grid item xs={12} md={12}>
                    <FinancialAgreement
                            user={clientDetail}
                            isFromClientDetail={isFromClientDetail}
                            setFinancialAgreementRef={setFinancialAgreementRef}
                            setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                            financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                            financialAgreementFormRef={financialAgreementFormRef}
                            offerType={offerType}
                            setSaveFinancialAgreementForm={setSaveFinancialAgreementForm}
                            formType={"FINANCIAL_AGREEMENT"}
                            setFormType={setFormType}
                            autoSaveTrigger={autoSaveTrigger}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Assets
                        user={clientDetail}
                        isFromClientDetail={isFromClientDetail}
                        setAssetsVerifyRef={setAssetsVerifyRef}
                        setAssetsVerified={setAssetsVerified}
                        setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                        financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                        assetRef={assetRef}
                        setSaveFormContent={setSaveFormContent}
                        formType={"ASSETS"}
                        setFormType={setFormType}
                        offerType={offerType}
                        autoSaveTrigger={autoSaveTrigger}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Liabilities
                            user={clientDetail}
                            isFromClientDetail={isFromClientDetail}
                            setLiabilitiesVerifyRef={setLiabilitiesVerifyRef}
                            setLiabilitiesVerified={setLiabilitiesVerified}
                            setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                            financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                            liabilitiesRef={liabilitiesRef}
                            setSaveFormContent={setSaveFormContent}
                            formType={"LIABILITIES"}
                            setFormType={setFormType}
                            offerType={offerType}
                            autoSaveTrigger={autoSaveTrigger}
                    />
                </Grid>

                {(isLongForm(offerType) || isWorksheet(offerType)) &&
                    <React.Fragment>
                        <Grid item xs={12} md={12}>
                            <EarnedIncome
                                    user={clientDetail}
                                    isFromClientDetail={isFromClientDetail}
                                    setEarnedVerifyRef={setEarnedVerifyRef}
                                    setEarnedVerified={setEarnedVerified}
                                    setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                                    financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                                    earnedIncomeRef={earnedIncomeRef}
                                    setSaveFormContent={setSaveFormContent}
                                    formType={"EARNED"}
                                    setFormType={setFormType}
                                    offerType={offerType}
                                    autoSaveTrigger={autoSaveTrigger}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <UnearnedIncomePastYear
                                    user={clientDetail}
                                    isFromClientDetail={isFromClientDetail}
                                    setUnEarnedVerifyRef={setUnEarnedVerifyRef}
                                    setUnEarnedVerified={setUnEarnedVerified}
                                    setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                                    financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                                    unearnedIncomePastYearRef={unearnedIncomePastYearRef}
                                    setSaveFormContent={setSaveFormContent}
                                    formType={"UN_EARNED"}
                                    setFormType={setFormType}
                                    offerType={offerType}
                                    autoSaveTrigger={autoSaveTrigger}
                            />
                        </Grid>
                    </React.Fragment>
                }

                <Grid item xs={12} md={12}>
                    <SourceOfFunds
                            autoSaveTrigger={autoSaveTrigger}
                            user={clientDetail}
                            isFromClientDetail={isFromClientDetail}
                            setSourceOfFundsRef={setSourceOfFundsRef}
                            setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                            financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                            sourceOfFundsRefs={sourceOfFundsRefs}
                            formType={"SOURCE_OF_FUNDS"}
                            setFormType={setFormType}
                            setSaveSourceOfFundsForm={setSaveSourceOfFundsForm}
                            offerType={offerType}
                    />
                </Grid>

                {(isLongForm(offerType) || isWorksheet(offerType)) &&
                    <Grid item xs={12}>
                        <PremiumFinancing
                                autoSaveTrigger={autoSaveTrigger}
                                clientDetail={clientDetail}
                                isFromClientDetail={isFromClientDetail}
                                offerType={offerType}
                                premiumFinancingRefs={premiumFinancingRefs}
                                setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                                financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                                sendSaveRequest={sendSaveRequest}
                        />
                    </Grid>
                }

                {/*NIWC-2276*/}
                {/*{*/}
                {/*        isIliaApp(offerType) && <>*/}
                {/*            <Grid item xs={12} md={12}>*/}
                {/*                <RealStateHoldings user={clientDetail} isFromClientDetail={isFromClientDetail}*/}
                {/*                                   setRealEstateVerifyRef={setRealEstateVerifyRef}*/}
                {/*                                   setRealEstateVerified={setRealEstateVerified}*/}
                {/*                                   setIsError={(flag) => {*/}
                {/*                                       if (flag) {*/}
                {/*                                           setIsErrorCount(isErrorCount + 1);*/}
                {/*                                       } else {*/}
                {/*                                           setIsErrorCount(isErrorCount - 1);*/}
                {/*                                       }*/}
                {/*                                   }}/>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={12} md={12}>*/}
                {/*                <BusinessEquity user={clientDetail} isFromClientDetail={isFromClientDetail}*/}
                {/*                                setBusinessEquityVerifyRef={setBusinessEquityVerifyRef}*/}
                {/*                                setBusinessEquityVerified={setBusinessEquityVerified}*/}
                {/*                                setIsError={(flag) => {*/}
                {/*                                    if (flag) {*/}
                {/*                                        setIsErrorCount(isErrorCount + 1);*/}
                {/*                                    } else {*/}
                {/*                                        setIsErrorCount(isErrorCount - 1);*/}
                {/*                                    }*/}
                {/*                                }}/>*/}
                {/*            </Grid>*/}
                {/*        </>*/}
                {/*}*/}
            </Grid>
    );
};

export default FinancialAndLiabilitiesContent;