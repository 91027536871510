import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Typography } from "@material-ui/core";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../../components/dialog";
import PaymentBreakdownAccordion from "../../../../../components/clientServicing/Payments/PaymentBreakdownAccordion";
import EpayPaymentInformation from "../../../../../components/clientServicing/Payments/EpayPaymentInformation";

export default function PolicyOverrideConfirm (props: {
    openPopup: boolean;
    setConfirmPolicyOverride: Function;
    setSubmitDisable: Function;
    revertPolicyStatusOverride: boolean
}) {
    const {openPopup, setConfirmPolicyOverride, setSubmitDisable, revertPolicyStatusOverride} = props;

    const handleClose = () => {
        setConfirmPolicyOverride(false);
    };

    const handleConfirm = () => {
        setSubmitDisable(false);
        setConfirmPolicyOverride(false);
    };

    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "customized-dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <Typography variant="body1" id="alert-dialog-description">
                        {
                            !revertPolicyStatusOverride ? "Please confirm you are updating this case to policy issued override and case has been completed outside of ilia."
                                    : "Please confirm you want to revert policy issue override."
                        }
                    </Typography>

                    <Button onClick={handleClose} color="secondary" variant="contained" className="mt20 floatRight ml5 textBlack">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" className="mt20 floatRight bgRed">
                        Confirm
                    </Button>
                </>
        ),
        dividers: true,
        onClose: handleClose,
        open: openPopup,
        title: "Confirmation",
    };



    return (
            <div>
                { <GenericDialog {...dialogProps} />}
                {/*<Dialog*/}
                {/*        open={openPopup}*/}
                {/*        onClose={handleClose}*/}
                {/*        aria-labelledby="alert-dialog-title"*/}
                {/*        aria-describedby="alert-dialog-description"*/}
                {/*>*/}
                {/*    <DialogTitle id="alert-dialog-title" className="floatLeft w100">{"Confirmation"}*/}
                {/*        <IconButton edge="end" className="floatRight" color="inherit" onClick={(e) => handleClose()}*/}
                {/*                    aria-label="close">*/}
                {/*            <CloseIcon/>*/}
                {/*        </IconButton>*/}
                {/*    </DialogTitle>*/}
                {/*    <DialogContent>*/}
                {/*        <DialogContentText id="alert-dialog-description">*/}
                {/*            {*/}
                {/*                !revertPolicyStatusOverride ? "Please confirm you are updating this case to policy issued override and case has been completed outside of ilia."*/}
                {/*                : "Please confirm you want to revert policy issue override."*/}
                {/*            }*/}
                {/*        </DialogContentText>*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button onClick={handleClose} color="primary" variant="contained">*/}
                {/*            Cancel*/}
                {/*        </Button>*/}
                {/*        <Button onClick={handleConfirm} className="bgRed" variant="contained">*/}
                {/*            Confirm*/}
                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}
            </div>
    );
}
