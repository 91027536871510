import React from 'react';
import {Backdrop, CircularProgress, makeStyles} from '@material-ui/core';

type BProps = {
    backdropClasses? : string;
    size?: number;
};

type CProps = {
    className?: string;
    size?: number;
};

const CProgress: React.FC<CProps> = ({className, size}) => {
    const _styles: React.CSSProperties = {left: '0', margin: '0 auto', position: 'absolute', right: '0', top: '50%'};
    const _sizeProps = !!size ? {size} : {};
    const _classNameProps = !!className ? {className} : {};

    return (
            <CircularProgress {..._classNameProps} {..._sizeProps} style={_styles} />
    );
};

export const CPIncBackdrop: React.FC<BProps> = ({backdropClasses, size}) => {
    const useStyles: any = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(true);
    return (
            <Backdrop className={backdropClasses || classes.backdrop} open={isLoading} onClick={() => setIsLoading(false)}>
                <CProgress size={size} />
            </Backdrop>
    );
};

export default CProgress;
