import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BreakDownFormPanel1 from "../component/forms/BreakDownFormPanel1";
import {Formik} from "formik";
import * as Yup from "yup";
import { policyYearBreakDown } from './tabs/CaseDetailsInitialValues';

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: "100%",
                float: "left",
            },
            heading: {
                flexBasis: "20%",
                flexShrink: 0,
                marginBottom: 0,
            },
            label: {
                flexBasis: "25%",
                flexShrink: 0,
                marginBottom: 0,
            },
            secondaryHeading: {
                flexBasis: "30%",
                color: theme.palette.text.secondary,
                marginBottom: 0,
            },
            labelBtn: {
                padding: "2px 5px",
                fontSize: 12,
                borderRadius: 6,
                color:'#ffffff'
            },
            bgGreen: {
                background: "#3DDB93",
            },
            bgRed: {
                background: "#ff1744",
            },
            bgOrange: {
                background: "#FFA500",
            },
        }),
);

interface  PolicyBreakDownExpansionPanelProps {
    year:number, index:number, policyArr:any, setPolicyArr:Function, setRefresh:Function,
    yearPolicyBreakup:any, setPolicyFormDirty:Function, fetchCaseDetail:any, agePerYear:number, issueAge: number | undefined, setPolicyBreakDownExpanded: Function,
    caseDetails: any, policyStartYear: number, combineClientObj: {key: number, name: string, title: string}
    paidInFull?:boolean
}

const PolicyBreakDownExpansionPanel1 = (props: PolicyBreakDownExpansionPanelProps) => {
    const {year, index, policyArr, setPolicyArr, setRefresh, yearPolicyBreakup, setPolicyFormDirty, fetchCaseDetail, agePerYear, issueAge, setPolicyBreakDownExpanded
    , caseDetails, policyStartYear, combineClientObj} = props;

    const [initialValue, setInitialValue] = React.useState<any>(policyYearBreakDown)
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [outStandingBalance, setOutStandingBalance] = React.useState<number>(0);


    const paymentFrequency = caseDetails && caseDetails.clientPolicies.length > 0 && caseDetails.clientPolicies[0].paymentFrequency || "annual";

    React.useEffect(()=>{
        if(year === policyStartYear){
            setExpanded(`panel${year}`);
        }
    }, []);

    React.useEffect(() => {
        if(yearPolicyBreakup.clientContributionReceived && yearPolicyBreakup.clientContributionDue && Number(yearPolicyBreakup.clientContributionReceived) >= Number(yearPolicyBreakup.clientContributionDue)){
            setOutStandingBalance(0)
        } else {
            setOutStandingBalance((Number(yearPolicyBreakup.clientContributionDue) - Number(yearPolicyBreakup.clientContributionReceived)) || 0)
        }
    }, [yearPolicyBreakup, index]);

    React.useEffect(() => {
        let formValues = {
            /* Main */
            currentAge: agePerYear,
            deathBenefitReduction: (yearPolicyBreakup && yearPolicyBreakup.deathBenefitReduction) || "",
            indexAllocations: (yearPolicyBreakup && yearPolicyBreakup.indexAllocations && yearPolicyBreakup.indexAllocations.length > 0) ? yearPolicyBreakup.indexAllocations : [{id: "", indexAllocation: "", indexAllocationPercent: ""}],

            /* Illustrate Value */
            illustratedPremium: (yearPolicyBreakup && yearPolicyBreakup.illustratedPremium) || "",
            bankPremium: (yearPolicyBreakup && yearPolicyBreakup.bankPremium) || "",
            loanRate: (yearPolicyBreakup && yearPolicyBreakup.loanRate) || "",
            projectedInterest: (yearPolicyBreakup && yearPolicyBreakup.projectedInterest) || "",
            estLoanBalance: (yearPolicyBreakup && yearPolicyBreakup.estLoanBalance) || "",
            illustratedSurrenderValue: (yearPolicyBreakup && yearPolicyBreakup.illustratedSurrenderValue) || "",
            netCashValueIllustrated: (yearPolicyBreakup && yearPolicyBreakup.netCashValueIllustrated) || "",
            netLoanCashValueIllustrated: (yearPolicyBreakup && yearPolicyBreakup.netLoanCashValueIllustrated) || "",
            illustratedCurrentDeathBenefit: (yearPolicyBreakup && yearPolicyBreakup.illustratedCurrentDeathBenefit) || "",
            illustratedNetDeathBenefit: (yearPolicyBreakup && yearPolicyBreakup.illustratedNetDeathBenefit) || "",
            lowPoint: (yearPolicyBreakup && yearPolicyBreakup.lowPoint) || "",
            illustratedGrowthRate: (yearPolicyBreakup && yearPolicyBreakup.illustratedGrowthRate) || "",

            /* Actual Values */
            insurancePremiumActual: (yearPolicyBreakup && yearPolicyBreakup.insurancePremiumActual) || "",
            amountPaidToPolicy: (yearPolicyBreakup && yearPolicyBreakup.amountPaidToPolicy) || "",
            bankLoanPremiumDue: (yearPolicyBreakup && yearPolicyBreakup.bankLoanPremiumDue) || "",
            bankInterestRate: (yearPolicyBreakup && yearPolicyBreakup.bankInterestRate) || "",
            bankLoanAmount: (yearPolicyBreakup && yearPolicyBreakup.bankLoanAmount) || "",
            actualSurrenderValue: (yearPolicyBreakup && yearPolicyBreakup.actualSurrenderValue) || "",
            actualNetSurrenderValue: (yearPolicyBreakup && yearPolicyBreakup.actualNetSurrenderValue) || "",
            actualCurrentDeathBenefit: (yearPolicyBreakup && yearPolicyBreakup.actualCurrentDeathBenefit) || "",
            netDeathBenefitActual: (yearPolicyBreakup && yearPolicyBreakup.netDeathBenefitActual) || "",
            growthPercent: (yearPolicyBreakup && yearPolicyBreakup.growthPercent) || "",
            growthAmount: (yearPolicyBreakup && yearPolicyBreakup.growthAmount) || "",
            growthRateAverage: (yearPolicyBreakup && yearPolicyBreakup.growthRateAverage) || "",
            loanRateAverage: (yearPolicyBreakup && yearPolicyBreakup.loanRateAverage) || "",
            actualLowPoint: (yearPolicyBreakup && yearPolicyBreakup.actualLowPoint) || "",
            actualInterestAmount: (yearPolicyBreakup && yearPolicyBreakup.actualInterestAmount) || "",
            cumulativeBankLoanPremiumPaid: (yearPolicyBreakup && yearPolicyBreakup.cumulativeBankLoanPremiumPaid) || "",
            cumulativeEmployerContributionPaid: (yearPolicyBreakup && yearPolicyBreakup.cumulativeEmployerContributionPaid) || "",
            cumulativeContributionPaid: (yearPolicyBreakup && yearPolicyBreakup.cumulativeContributionPaid) || "",
            paymentFrequency: paymentFrequency.charAt(0).toUpperCase() + paymentFrequency.slice(1)
        };
        setInitialValue(formValues);
    }, [expanded, yearPolicyBreakup && yearPolicyBreakup.id, issueAge]);

    const handlePanelChange = (panel: string, year: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setPolicyBreakDownExpanded({panel, policyYear: year, clientId: caseDetails.id, isExpanded : isExpanded ? panel : false});
        setExpanded(isExpanded ? panel : false);
    };

    let keysValidation = {}

    const policyBreakDownSchema = Yup.object().shape(keysValidation);

    return (
            <React.Fragment>
                <ExpansionPanel expanded={expanded === `panel${year}`}
                                onChange={handlePanelChange(`panel${year}`, year)}>
                    <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                    >
                        <Typography
                                className={classes.heading}
                                variant="body1">{year} - {year+1}&nbsp;(Year {index + 1})</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Formik
                                initialValues={initialValue}
                                enableReinitialize={true}
                                validationSchema={policyBreakDownSchema}
                                validateOnChange={true}
                                onSubmit={async (values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 500);
                                }}
                        >
                            {({
                                  handleSubmit
                              }) => (<form className="floatLeft w100" onSubmit={handleSubmit}>
                                {
                                    expanded &&
                                    <BreakDownFormPanel1
                                            expanded={expanded}
                                            policyArrIndex={index}
                                            year={year}
                                            setPolicyArr={setPolicyArr}
                                            policyArr={policyArr}
                                            setRefresh={setRefresh}
                                            setPolicyFormDirty={setPolicyFormDirty}
                                            yearPolicyBreakup={yearPolicyBreakup}
                                            outStandingBalance={outStandingBalance}
                                            fetchCaseDetail={fetchCaseDetail}
                                            combineClientObj={combineClientObj}
                                            caseDetails={caseDetails}
                                    />
                                }
                            </form>)}
                        </Formik>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </React.Fragment>
    );
};

export default PolicyBreakDownExpansionPanel1;