import React from 'react';
import {Document, Page, pdfjs} from "react-pdf";
import useResizeObserver from "../../hooks/useResizeObserver";
import {numArray} from "../../utils/commonUtils";
import {getQS} from "../../utils/queryString";


export interface PDFProps {
    className?: string;
    id?: string;
    loading?: any;
    onError: Function;
    onLoad: Function;
    url: string;
}

interface DocProps {
    className?: string;
    file: string;
    id?: string;
    loading: string;
    onLoadError: Function;
    onLoadSuccess: (successPayload: any) => void;
}


enum RenderMode {
    Canvas = 'canvas',
    Svg = 'svg'
}

export function PDF (props: PDFProps) {
    const qs = getQS();
    const observer = React.useRef<any>();

    const isDebug = qs && !!qs.debug;
    const renderModeSVG = qs && !!qs.renderMode;
    const resizeObserverOptions = {};
    const maxWidth: number = 1200;
    const INIT_PAGES_TO_LOAD: number = 0;

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const { className, id, loading,  onError, onLoad, url} = props;

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(null);
    const [containerWidth, setContainerWidth] = React.useState<number>();
    const [numPages, setNumPages] = React.useState<number>(INIT_PAGES_TO_LOAD);
    const [visiblePages, setVisiblePages] = React.useState<number>(INIT_PAGES_TO_LOAD);

    const mainStyle: React.CSSProperties = {height: '70vh', overflowY: "auto"};

    const onDocumentLoadSuccess = (successPayload: any): void => {
        const {numPages: nextNumPages} = successPayload;
        onLoad(successPayload)
        setNumPages(nextNumPages);
        if (nextNumPages > 2) {
            setVisiblePages(10)
        } else {
            setVisiblePages(nextNumPages)
        }
    };

    const onResize = React.useCallback<any>((entries: any) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    const updateLatePageRef = (node: any) => {
        const hasMore = visiblePages + 2 < Number(numPages)
        if (observer.current) {
            observer.current.disconnect();
        }

        const obsCb: IntersectionObserverCallback = entries => {
            if (entries[0].isIntersecting) {
                if (hasMore) {
                    setVisiblePages(visiblePages + 1)
                } else {
                    setVisiblePages(numPages)
                }
            }
        };

        observer.current = new IntersectionObserver(obsCb, {rootMargin: '15px'});

        if (node) {
            observer.current.observe(node);
        }
    };

    const lastPageRef = React.useCallback(updateLatePageRef, [visiblePages]);


    const defaultPDFJsOptions = {rangeChunkSize: 16384};
    const docProps: DocProps | any = {
        className,
        file: url,
        id,
        loading: !!loading ? loading : '',
        onLoadError: onError,
        onLoadSuccess: onDocumentLoadSuccess,
        options: isDebug ? {...defaultPDFJsOptions, canvasMaxAreaInBytes: 10000, verbosity: 5} : defaultPDFJsOptions,
        renderAnnotationLayer: true,
        renderForms: true,
        renderMode: renderModeSVG ? RenderMode.Svg : RenderMode.Canvas,
    };

    const pages = numArray(numPages);

    return (
        <div ref={setContainerRef} style={mainStyle}>
            {
                isDebug && <pre>{JSON.stringify(pages, null, 2)}</pre>
            }
            <Document {...docProps}>
                {
                    pages.map(index => {
                        const pageNumber = index + 1;

                        return (
                                <span ref={pages.length === pageNumber ? lastPageRef : undefined}>
                                    {
                                        isDebug &&
                                        <p>
                                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                                        </p>
                                    }
                                    <Page
                                            key={`page_${pageNumber}`}
                                            pageNumber={pageNumber}
                                            width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                    />
                            </span>
                        );
                    })
                }
            </Document>
        </div>
    );
}

export default PDF;
