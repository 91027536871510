import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import EstimationForm from "./EstimationForm";


const EstmateSectionOne = (props: any) => {

    return (
            <Grid container justify="center" id={"estimateContainer"}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography
                            align="left"
                            className="textHighLight floatLeft w100 mb0 mt20"
                            component="strong"
                            id="get-started"
                            variant="body1"
                    >
                        GET STARTED
                    </Typography>
                    <Typography variant="h3" className="floatLeft w100 mb15">What Will Your
                        Retirement Look Like?</Typography>
                    <Typography variant="body1" className="floatLeft w100 mb20">
                        If you qualify you have the potential for a retirement that offers so much more than what you
                        could obtain on your own.
                    </Typography>
                    <EstimationForm
                            setTotalContribution={(totalContribution: number) => {
                                props.setTotalContribution(totalContribution);
                            }}
                            sendEstimationOnParent={(estimationData: any) => {
                                props.setEstimationData(estimationData);
                            }} {...props} />
                </Grid>
            </Grid>
    );
};

export default EstmateSectionOne;
