import utilityBill from "../../images/svg/utility-bill.svg";
import { Grid, Typography } from "@material-ui/core";
import { EnrollmentFileUploader as FileUploader, Label } from "../../../../../components/formInputs";
import { ClientDocumentType } from "../../../../../constants";
import ViewAndDelete from "../basicInfo/ViewAndDelete";
import React from "react";

type Props = {
	directory: string
	fileType: string
	updateDocument: Function,
	clientSigned: boolean
	userId: string
	requestedDocument?: any
	setOtherDocPrevPhoto: Function
	otherDocPrevPhoto: string
	otherDocName?: string,
	isFromClientDetail?: boolean,
	setOtherDocName?: Function
}
const OtherDocument = (props: Props) => {
	const {
		directory,
		fileType,
		updateDocument,
		clientSigned,
		userId,
		setOtherDocPrevPhoto,
		otherDocPrevPhoto,
		otherDocName,
		isFromClientDetail,
		requestedDocument,
		setOtherDocName,
	} = props;
	const [docName, setDocName] = React.useState<string>("Other");
	const [docAccName, seDocAccName] = React.useState<string>("Other");
	React.useEffect(() => {
		if (requestedDocument) {
			seDocAccName(requestedDocument.otherDocName);
			setDocName(requestedDocument.documentName);
			if (typeof setOtherDocName === "function") {
				setOtherDocName(requestedDocument.otherDocName);
			}
		}
	}, []);
	return (
		<Grid item xs={12}>
			<div className="DocumentsWrap mt40">
				{!isFromClientDetail &&
					<div>
						<img src={utilityBill} alt="#" className="floatLeft" />
					</div>
				}
				<div className="content">
					<Typography variant="subtitle1" component="strong"><Label label={"Other Document"}
																			  required={!isFromClientDetail ? true : false} /></Typography>
					<Typography variant="body1">{otherDocName ? otherDocName : docAccName} as Other Document.
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={otherDocPrevPhoto ? 3 : 6}
							  lg={otherDocPrevPhoto ? 3 : 6} className="textLeft">
							{!otherDocPrevPhoto &&
								<div className="fileUpload">
									<FileUploader
										exactFileName={"Other Document"}
										dirName={directory}
										dirType={"documents"}
										fileType={fileType}
										content={"Other Document"}
										width={15000}
										height={15000}
										onFinish={async (publicUrl: string, name: string) => {
											setOtherDocPrevPhoto(name);
											await updateDocument("otherDocument", name, ClientDocumentType.MORE_DOCUMENTS, "", docName);

										}}
									/>
								</div>
							}
							{
								(otherDocPrevPhoto
									&&
									<ViewAndDelete
										identityPhoto={otherDocPrevPhoto}
										clientSigned={clientSigned}
										title="Other Document"
										action={
											{
												key: "OtherDocument",
												type: ClientDocumentType.MORE_DOCUMENTS,
												side: "",
											}
										}
										userId={userId}
										directory={directory}
										setOtherDocPrevPhoto={setOtherDocPrevPhoto}
									/>
								)
							}
						</Grid>
					</Grid>
				</div>
			</div>
		</Grid>
	);
};

export default OtherDocument;