import React from "react";
import { Field as FormikField, useFormikContext } from "formik";
import {Grid, Typography} from "@material-ui/core";
import {ErrorHelper, FormikValues} from "../formikFormInputs";
import {CommonProps, DynamicFormFieldType} from "./index";


interface TxtProps extends CommonProps, FormikValues, DynamicFormFieldType {
    alias?: string;
    as: any;
    className?: string;
    defaultValue?: string;
    errors: { [key: string]: string };
    fullWidth?: boolean;
    helperText?: string;
    InputLabelProps?: { shrink: boolean };
    inputProps?: any;
    multiline?: boolean;
    placeholder: string;
    rows?: string | number;
    variant?: string;
}

const TextBox: React.FC<TxtProps> = (props) => {
    const {alias, errors, helperText, label, name, placeholder, setFieldValue, values, ...otherProps} = props;
    const { setValues, handleChange } = useFormikContext<any>()
    return (
            <>
                <Grid item xs={12}>
                    <Typography className="mb0" variant="body1">
                        {!!label && label}{alias && <> (<strong>{alias}</strong>) </>}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormikField
                            {...otherProps}
                            label={placeholder}
                            onChange={(e)=>{
                                if(/\./.test(name)){
                                    values[name] = e.target.value;
                                    setValues(values)
                                }else{
                                    handleChange(e)
                                }
                            }}
                            name={name}
                            placeholder={placeholder}
                    />
                    {
                            helperText &&
                            <Typography className="floatLeft w100 f16 textBlackLight" variant="caption">
                                {helperText}
                            </Typography>
                    }
                    <ErrorHelper errors={errors} name={name}/>
                </Grid>
            </>
    )
};


export default TextBox;
