import { Action, ActionType } from "../model";
import { Account, GetPreEstimation, AccountList, GetAdminActivityLogsList, GetCompanyInviteData } from "../model/account";
import createReducer from "./createReducer";

export const accounts = createReducer([], {
	[ActionType.ADD_ACCOUNT](state: Account[], action: Action<Account>) {
		return state;
	},
	[ActionType.UPDATE_ACCOUNT](state: Account[], action: Action<number>) {
		return action.payload; 
	},
	[ActionType.DELETE_ACCOUNT](state: Account[], action: Action<boolean>) {
		return state;
	},
	[ActionType.LIST_ACCOUNTS](state: Account[], action: Action<AccountList>) {
		return action.payload;
	},
	[ActionType.AGENT_CLIENT_LIST](state: Account[], action: Action<AccountList>) {
		return action.payload;
	},
	[ActionType.IMO_AGENTS_LIST](state: Account[], action: Action<AccountList>) {
		return action.payload;
	},
});

export const loadingAccounts = createReducer(false, {
    [ActionType.LOADING_ACCOUNTS](state: boolean, action: Action<boolean>) {
        return action.payload;
    }
});

export const trustees = createReducer([], {
	[ActionType.GET_ALL_TRUSTEES](state: Account[], action: Action<AccountList>) {
		return action.payload;
	},
});


export const banks = createReducer([], {
	[ActionType.GET_ALL_BANKS](state: Account[], action: Action<AccountList>) {
		return action.payload;
	},
});

export const account = createReducer({}, {
	[ActionType.GET_ACCOUNT](state: Account, action: Action<Account>) {
		return action.payload;
	}
});

export const clientAccount = createReducer({}, {
	[ActionType.GET_CLIENT](state: Account, action: Action<Account>) {
		return action.payload;
	}
});

export const preEstimationData = createReducer({}, {
	[ActionType.GET_PRE_ESTIMATION](state: GetPreEstimation, action: Action<Account>) {
		return action.payload;
	}
});

export const adminActivityLogs = createReducer({},{
    [ActionType.GET_ADMIN_ACTIVITY_LOGS](state: GetAdminActivityLogsList, action: Action<Account>) {
        return action.payload;
    }
})

export const companyInvite = createReducer({},{
    [ActionType.GET_COMPANY_INVITE_DATA](state: GetCompanyInviteData, action: Action<Account>) {
        return action.payload;
    }
})

export const leftPanelOpen = createReducer(false,{
    [ActionType.LEFTPANEL](state: boolean, action: Action<Account>) {
        return action.payload;
    }
})

export const docForRequest = createReducer({},{
    [ActionType.DOC_FOR_REQUEST](state: boolean, action: Action<Account>) {
        return action.payload;
    }
});

export const setAgentGroup = createReducer({},{
    [ActionType.SET_AGENT_GROUP](state: boolean, action: Action<Account>) {
        return action.payload;
    }
})

export const clientCaseDetails = createReducer({}, {
    [ActionType.GET_CLIENT_CASE_DETAILS](state: any, action: Action<Account>) {
        return action.payload;
    }
});

export const isRefresh = createReducer(0, {
    [ActionType.IS_REFRESH](state: any, action: Action<Account>) {
        return action.payload;
    }
});