import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Dialog, IconButton, Button, Grid} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Viewer} from "../../../../../components";


const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    documentUrl?: string
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, documentUrl, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                        <Typography variant="h6">{children}</Typography>
                        {onClose ? (
                                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                        ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function ViewDocument(props:any) {
    const { handleClose, openDocument, fileType, documentUrl, title, documentToView, downloadDocument} = props;
    return (
            <div>
                <Dialog fullWidth maxWidth={"lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDocument}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <DialogTitle id="customized-dialog-title" onClose={handleClose} documentUrl={documentUrl}>
                                {title}
                            </DialogTitle>
                        </Grid>
                        <Grid item xs={2}>
                            <Button className="btnDownLoad mt10"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if(typeof downloadDocument === 'function'){
                                        downloadDocument(documentToView);
                                    }
                                }}
                            >
                                Download
                            </Button>
                        </Grid>
                    </Grid>
                    <DialogContent dividers>
                        {
                            fileType == "PDF" || fileType == 'pdf' ?
                                    <Viewer className="enrollmentIframe" url={documentUrl}/>:
                                    <img alt="loginLogo" src={documentUrl}/>
                        }
                    </DialogContent>
                </Dialog>
            </div>
    );
}
