import React from "react";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

function HealthTypeToolTip() {
	return (
		<Tooltip arrow
				 title={
					 <React.Fragment>
						 <Typography variant="caption"
									 className="mb0">
							 Excellent health means you
							 are in excellent overall
							 health and don't participate
							 in any hazardous activities
							 or have a history of drug or
							 alcohol abuse. Average health
							 means you may have had some
							 minor health problems in the
							 past, or have a few current
							 lifestyle issues such as high
							 cholesterol obesity.
						 </Typography>
					 </React.Fragment>
				 }
		>
			<IconButton aria-label="Info" color="primary">
				<InfoIcon />
			</IconButton>
		</Tooltip>
	);
}

export default HealthTypeToolTip;