import * as Yup from "yup";
import { WhenOptions } from "yup";
import {
	ExerciseEnum,
	getInsuredQuestions,
	PIQ,
} from "./InsuredQuestions";
import { unmaskCurrency } from "../../../../../../utils/commonUtils";
import { BS } from "../../../../../../model/misc";
import {
	ADDITIONAL_TEXT_FIELD_TYPE as Additional_Text_Type,
	OTHER_VALUE as OtherVal,
	REQUIRED_FIELD,
	YesNoEnum,
} from "../../../../../../constants";

interface CVBProps {
	asBuilder?: boolean;
	fieldName: string;
	grandParentFieldName?: string;
	grandParentValue?: string;
	parentFieldName: string;
	parentValue?: string;
	valType: ValType;
}

enum ValType {
	Array,
	String,
}

export enum ProposedInsuredQuestionsKeys {
	PIQ55 = "Proposed_Insured_Questions-55",
	PIQ60 = "Proposed_Insured_Questions-60XC",
	PIQ61 = "Proposed_Insured_Questions-61XR",
	PIQ62 = "Proposed_Insured_Questions-62XR",
	PIQ63 = "Proposed_Insured_Questions-63XC"
}

const [ADDITIONAL_TEXT_FIELD_TYPE, OTHER_VALUE, YES_VAL, REQUIRED]: [string, string, string, string] = [Additional_Text_Type, OtherVal, YesNoEnum.Yes, REQUIRED_FIELD];

const _commonBuilder: WhenOptions<any> = {
	is: YES_VAL,
	then: Yup.string().required(REQUIRED),
	otherwise: Yup.string().nullable(),
};

const getOjProp = (object: any, key: string, notFound: any = undefined) => {
	return object && object.hasOwnProperty(key) && typeof object[key] === "object" ? object[key] : notFound;
};

/**
 * common fun yup.when() - only for yup.string()
 * @param parentName
 * @param parentMatcherValue
 * @param grandParentName
 * @param grandParentMatcherValue
 */
const makeFieldRequiredByWhen = (parentName: string, parentMatcherValue: BS = YES_VAL, grandParentName?: string, grandParentMatcherValue: BS = YES_VAL) => {
	const pBuilder = {
		..._commonBuilder,
		is: parentMatcherValue,
	};
	const pBuilderThen = Yup.string().ensure().when(parentName, pBuilder).nullable();
	const gpBuilder = {
		..._commonBuilder,
		is: grandParentMatcherValue,
		then: pBuilderThen,
	};
	return !!grandParentName ? Yup.string().ensure().when(grandParentName, gpBuilder).nullable() : pBuilderThen;
};

/**
 * common fun yup.test()
 * @param name
 * @param asArray
 * @param parentMatcherValue
 * @param matcherField
 * @param grandParentFieldName
 * @param grandParentValue
 * @param isNotDesc
 * @param grandGrandParentFieldName
 * @param grandGrandParentValue
 */
const makeFieldRequiredByTest = (name: string, asArray: boolean = false, parentMatcherValue: BS = YES_VAL, matcherField?: string, grandParentFieldName?: string, grandParentValue?: string, isNotDesc?: boolean, grandGrandParentFieldName?: string, grandGrandParentValue?: string) => {
	return Yup.string().ensure().trim().test(name, REQUIRED, function(value: any) {
		let hasRequiredField = false;

		if (asArray) {
			const parentVal = getOjProp(this.parent, name, []);
			const field = parentVal.find(v => v.name === matcherField && v.value === parentMatcherValue);
			const fieldMatch = !isNotDesc ? (field && !field.desc) && !value : !!field && !value;
			hasRequiredField = !grandGrandParentFieldName ? (
				!grandParentFieldName ?
					fieldMatch :
					fieldMatch && this.parent[`${grandParentFieldName}`] === (grandParentValue || parentMatcherValue)
			) : (fieldMatch && this.parent[`${grandParentFieldName}`] === (grandParentValue || parentMatcherValue) && this.parent[`${grandGrandParentFieldName}`] === (grandGrandParentValue || grandParentValue || parentMatcherValue));
		} else {
			hasRequiredField = !grandGrandParentFieldName ? (
				!grandParentFieldName ?
					this.parent[`${name}`] === parentMatcherValue && !value : // (value === undefined || (value && value.trim()) === "")
					this.parent[`${grandParentFieldName}`] === (grandParentValue || parentMatcherValue) && this.parent[`${name}`] === parentMatcherValue && !value
			) : (
				this.parent[`${grandGrandParentFieldName}`] === (grandGrandParentValue || grandParentValue || parentMatcherValue) &&
				this.parent[`${grandParentFieldName}`] === (grandParentValue || parentMatcherValue) &&
				this.parent[`${name}`] === parentMatcherValue && !value
			);
		}

		return !hasRequiredField;
	});
};

/**
 * common fun to get additional sub text field
 * @param field
 */
const getAFIfHasASubFT = (field: {
	additionalSubFieldName: string,
	additionalSubFieldType: string,
	name: string
}) => {
	const hasAdditionalField = !!field.additionalSubFieldName && field.additionalSubFieldType === ADDITIONAL_TEXT_FIELD_TYPE;
	return { additionalSubFieldName: field.additionalSubFieldName, hasAdditionalField, name: field.name };
};
/**
 * common fun to get additional text field
 * @param field
 */
const getAFIfHasAFT = (field: {
	additionalFieldName: string,
	additionalFieldType: string,
	name: string
}) => {
	const hasAdditionalField = !!field.additionalFieldName && field.additionalFieldType === ADDITIONAL_TEXT_FIELD_TYPE;
	return { additionalFieldName: field.additionalFieldName, hasAdditionalField, name: field.name };
};

/**
 * Returns true if question matches the name to show and value matches the desired value
 * @param values
 * @param questionName
 * @param name
 * @param value
 */
export const showIfApplicable = (values: any, questionName: string, name: string, value: string = YES_VAL) => {
	return (questionName === name && values[name] === value);
};

type GVType = (isFromClientDetail: boolean, user: any, formContext: any) => any;

/**
 * Get insurance page Validations Main () => {}
 * @param isFromClientDetail
 * @param user
 * @param formContext
 */
export const getValidations: GVType = (isFromClientDetail, user, formContext) => {
	try {
		let _customValidationBuilderKeysArr: CVBProps[] = [];
		let _validationKeys = getInsurancePageValidationsOld();
		const _questions = getInsuredQuestions();

        _questions.forEach((parentField) => {
			// validations for all parent questions - no dependency
			if (parentField.type === "radio") {
				_validationKeys[parentField.name] = Yup.string().ensure().required(REQUIRED);
                if(parentField.additionalFieldName && parentField.additionalFieldRequiredV1){
                    _validationKeys[parentField.additionalFieldName]  =  Yup.string().ensure().test(parentField.additionalFieldName, REQUIRED, function(value: any) {
                         return !(this.parent[parentField.name] === YES_VAL && !this.parent[parentField.additionalFieldName]);
                    });
                }
			}

			// validations for nested questions
			switch (parentField.name) {
				case ProposedInsuredQuestionsKeys.PIQ55:
					const _valSchema55 = _getValidationFor55(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema55 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ60:
					const _valSchema60 = _getValidationFor60(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema60 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ61:
					const _valSchema61 = _getValidationFor61(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema61 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ62:
					const _valSchema62 = _getValidationFor62(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema62 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ63:
					const _valSchema63 = _getValidationFor63(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema63 };
					break;
			}
		});

		// alternate method (if original is not working)
		_customValidationBuilderKeysArr.forEach(customValidationBuilderKeys => {
			const {
				asBuilder,
				fieldName,
				grandParentFieldName,
				grandParentValue,
				parentFieldName,
				parentValue,
				valType,
			} = customValidationBuilderKeys;
			_validationKeys[`${fieldName}`] =
				valType === ValType.Array ?
					Yup.array().when(parentFieldName, _commonBuilder).nullable() :
					asBuilder ?
						makeFieldRequiredByWhen(parentFieldName, parentValue, grandParentFieldName, grandParentValue) :
						makeFieldRequiredByTest(parentFieldName, false, parentValue, undefined, grandParentFieldName, grandParentValue);
		});

		// validating inForcePolicies willThisBeReplaced_desc (Surrender_Charge_Percentage) case
		const _inFPSchema = _getValidationForInForcePolicies();
		_validationKeys = { ..._validationKeys, ..._inFPSchema };

		// validating exercise routine of the last 12 months
		const _valSchema82 = _getValidationFor82();
		_validationKeys = { ..._validationKeys, ..._valSchema82 };

		return _validationKeys;
	} catch (e) {
		console.error(e);
	}
};

type GVOLDType = () => any;

/**
 * Old_getInsurancePageValidations existing - copied as it is from InsuredQuestionsContent.tsx
 * @param isAssociation
 * @param isCorporation
 * @param isFromClientDetail
 */
const getInsurancePageValidationsOld: GVOLDType = () => {
	const _keysValidation = {};
	let validateDataFields = [
		"noOfPolicies",
		"inforcePolicy",
		"noOfInforcePolicies",
		"inforceAmount",
		"liAmount",
		"inforceLifeDesc",
		"insuranceType",
		"businessInsurance",
		"personalInsurance",
	];

	validateDataFields.forEach((field: any) => {
		if (field === "noOfPolicies") {
			_keysValidation[field] = Yup.string().ensure().when("existPolicies", _commonBuilder);
		} else if (field === "noOfInforcePolicies") {
			_keysValidation[field] = Yup.string().ensure().when("inforcePolicy", _commonBuilder);
		} else if (field === "inforceAmount") {
			_keysValidation[field] = Yup.string().when("inforceAmountNone", {
				is: true,
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().test(
					"inforceAmount",
					"Min value should be 1 or more",
					function() {
						const inforceAmountValue = Number(unmaskCurrency(this.parent.inforceAmount));
						return (inforceAmountValue > 0);
					},
				).required(REQUIRED),
			});
		} else if (field === "liAmount") {
			_keysValidation[field] = Yup.string().when("liAmountNone", {
				is: true,
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().test(
					"liAmount",
					"Min value should be 1 or more",
					function() {
						const liAmountValue = Number(unmaskCurrency(this.parent.liAmount));
						return (liAmountValue > 0);
					},
				).required(REQUIRED),
			});
		} else if (field === "inforceLifeDesc") {
			_keysValidation[field] = Yup.string().when("isInforceLife", {
				is: "No",
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().required(REQUIRED),
			});
		} else if (field === "businessInsurance") {
			_keysValidation[field] = Yup.string().when("insuranceType", {
				is: "personalInsurance",
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().required(REQUIRED),
			}).nullable();
		} else if (field === "personalInsurance") {
			_keysValidation[field] = Yup.string().when("insuranceType", {
				is: "businessInsurance",
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().required(REQUIRED),
			}).nullable();
		} else {
			_keysValidation[field] = Yup.string().required(REQUIRED).nullable();
		}
	});

	return _keysValidation;
};

/**
 * GetValidationFor55
 * @param parentField
 */
const _getValidationFor55 = (parentField: any) => {
	const valSchema55 = {};

	// check if additional field
	const { additionalFieldName, hasAdditionalField, name } = getAFIfHasAFT(parentField);

	if (!hasAdditionalField) {
		return;
	}

	valSchema55[`${additionalFieldName}`] = makeFieldRequiredByTest(name);

	return valSchema55;
};

/**
 * GetValidationFor60
 * @param parentField
 */
const _getValidationFor60 = (parentField: any) => {
	const valSchema60 = {};
	valSchema60[`${parentField.name}`] = Yup.array().min(1, REQUIRED).required(REQUIRED);

	// check if additional subfield
	const asf = getAFIfHasASubFT(parentField);
	if (asf.hasAdditionalField) {
		valSchema60[`${asf.additionalSubFieldName}`] = Yup.string().ensure().trim().required(REQUIRED);
	}

	return valSchema60;
};

/**
 * GetValidationFor61
 * @param parentField
 */
const _getValidationFor61 = (parentField: any) => {
	const valSchema61 = {};
	valSchema61[`${parentField.name}`] = Yup.object().required(REQUIRED);
	return valSchema61;
};

/**
 * GetValidationFor62
 * @param parentField
 */
const _getValidationFor62 = (parentField: any) => {
	const valSchema62 = {};
	valSchema62[`${parentField.name}`] = Yup.object().required(REQUIRED);
	return valSchema62;
};

/**
 * GetValidationFor63
 * @param parentField
 */
const _getValidationFor63 = (parentField: any) => {
	const valSchema63 = {};
	valSchema63[`${parentField.name}`] = Yup.array().min(1, REQUIRED).required(REQUIRED);
	return valSchema63;
};

/**
 * GetValidationFor82
 */
const _getValidationFor82 = () => {
	const proposedInsuredQuestions83XCeeValidate = (name: string, fn: string) => {
		return Yup.string().ensure().test(name, REQUIRED, function(val) {
			const checkRequired = this.parent[`${PIQ}-83XCee`] === false && this.parent[fn] === true;
			return (checkRequired && !!val) || !checkRequired;
		});
	};

	const fieldName82X = `${PIQ}-82X`;
	const valSchema82Fields = [
		{ name: `${fieldName82X}Rypf`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.YOGA}` },
		{ name: `${fieldName82X}Ryps`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.YOGA}` },
		{ name: `${fieldName82X}Typsa`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.YOGA}` },
		{ name: `${fieldName82X}Rlif`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.LIC}` },
		{ name: `${fieldName82X}Rlis`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.LIC}` },
		{ name: `${fieldName82X}Tlisa`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.LIC}` },
		{ name: `${fieldName82X}Rmif`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.MIC}` },
		{ name: `${fieldName82X}Rmis`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.MIC}` },
		{ name: `${fieldName82X}Tmia`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.MIC}` },
		{ name: `${fieldName82X}Rhif`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.HIC}` },
		{ name: `${fieldName82X}Rhis`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.HIC}` },
		{ name: `${fieldName82X}Thia`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.HIC}` },
		{ name: `${fieldName82X}Rwlf`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.WL}` },
		{ name: `${fieldName82X}Rwls`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.WL}` },
		{ name: `${fieldName82X}Twla`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.WL}` },
	];
	const valSchema82 = {};

	valSchema82Fields.forEach(({ name, requiredFieldName }) => {
		valSchema82[name] = proposedInsuredQuestions83XCeeValidate(name, requiredFieldName);
	});

	return valSchema82;
};

/**
 * GetValidationForInForcePolicies
 */
const _getValidationForInForcePolicies = () => {
	const _keysValidation = {};
	const [inForcePolicy, inForcePolicies] = ["inforcePolicy", "inForcePolicies"];
	const _infPolicyShape = {
		coverageFaceAmount: Yup.string().required(REQUIRED).nullable(),
		nameOfCompany: Yup.string().trim().required(REQUIRED).nullable(),
		surrenderChargePercentage: Yup.string().when("willThisBeReplaced", _commonBuilder).nullable(),
		type: Yup.string().required(REQUIRED).nullable(),
		willThisBeReplaced: Yup.string().required(REQUIRED).nullable(),
		status: Yup.string().required(REQUIRED).nullable(),
		willBothPoliciesBeTaken: Yup.string().when("status", {
			is: "Applied For",
			then: Yup.string().required(REQUIRED),
			otherwise: Yup.string().nullable(),
		}).nullable(),
	};

	_keysValidation[inForcePolicies] = Yup.array().when(inForcePolicy, {
		..._commonBuilder,
		then: Yup.array().of(Yup.object().shape(_infPolicyShape)).required(REQUIRED),
		otherwise: Yup.array().nullable(),
	});

	return _keysValidation;
};
