import React from 'react';
import {AppBar, Grid, Tab, Tabs} from "@material-ui/core";
import {a11yProps, TabPanel} from "../../ClientProfile/component/TabsMenu";
import Viewer from "../../../../components/Viewer";
import {Proposal} from "../../../../model/proposal";
import {sleep, va} from "../../../../utils/commonUtils";
import {ClientProposal} from "../../../../constants";


interface ProposalTabsProps {
    /**
     * active tab
     */
    activeProposalTab: number;
    /**
     * Runs when both tabs are cliecked
     */
    callBack: Function;
    /**
     * proposal file url and names
     */
    proposalObjArr: Proposal[];
    /**
     * tab change function
     */
    setActiveProposalTab: Function;
}


/**
 * renders admin_proposal document as tabs viewer for agent (view and approve) and client (approve and sign)
 * @param props
 * @constructor
 */
const ProposalTabs: React.FunctionComponent<ProposalTabsProps> = (props) => {
    const {activeProposalTab, callBack, proposalObjArr, setActiveProposalTab} = props;
    const [clickCounter, setClickCounter] = React.useState(1);
    const [isLoading, setIsLoading] = React.useState(true);
    const [forceRefresh, setForceRefresh] = React.useState(0);
    const hasMultipleFiles = !!proposalObjArr.length;
    const sortedProposalArr = proposalObjArr.sort((a, b) => (a.name === ClientProposal ? -1 : b.name === ClientProposal ? 1 : 0));  // Client Proposal needs to appear first
    // Mapping Name For Admin Proposal
    const TabNameMapper = {
        [ClientProposal]: "Kai-Zen Proposal",
        "Carrier Illustration": "Carrier Illustration",
    };

    /**
     * handler for tab change
     * @param _ev
     * @param newValue
     */
    const handleChange = async (_ev: React.ChangeEvent<{}>, newValue: number) => {
        // setting initial refresh
        setForceRefresh(0);

        // setting loading
        setIsLoading(true);

        // inducing server latency
        await sleep();

        // setting counter to show actions
        const counter = clickCounter + 1;
        setActiveProposalTab(newValue);
        setClickCounter(counter);

        // counter is 2, both document tabs viewed
        if (counter === 2) {
            callBack();
        }

        // setting refresh
        setForceRefresh(Math.random());
    };

    // has proposal docs, show first tab
    const initialRefresh = () => {
        if (proposalObjArr && proposalObjArr.length) {
            setForceRefresh(Math.random());
        }
    };

    React.useEffect(initialRefresh, [proposalObjArr]);

    return (
            <>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Tabs
                                value={activeProposalTab}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="scrollable force tabs example"
                        >
                            {
                                    hasMultipleFiles && va(sortedProposalArr) &&
                                    sortedProposalArr.map((proposal: Proposal, index: number) => (
                                            <Tab key={index} label={TabNameMapper[proposal.name]} {...a11yProps(index)} />
                                    ))
                            }
                        </Tabs>
                    </AppBar>
                </Grid>

                {
                        hasMultipleFiles && va(sortedProposalArr) &&
                        sortedProposalArr.map((proposal: Proposal, index: number) => {
                            return (
                                    <Grid key={index} item xs={12}>
                                        <TabPanel index={index} value={activeProposalTab}>
                                            {
                                                    // forcefully refresh for iframe viewer
                                                    forceRefresh !== 0 &&
                                                    <Viewer
                                                            force={setForceRefresh}
                                                            loading={isLoading}
                                                            setLoading={setIsLoading}
                                                            title={proposal.name}
                                                            url={proposal.url}
                                                    />
                                            }
                                        </TabPanel>
                                    </Grid>
                            )
                        })
                }
            </>
    );
};

export default ProposalTabs;
