import React from "react";
import { useFormikContext } from "formik";
import { EnrollmentFinancialAgreementProps } from "../../../../../../../model/enrollment";
import { FormHelperText, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import { ErrorCommon } from "../../../../../../../components/formikFormInputs";
import { proposedInsuredQuestion65Cpa, proposedInsuredQuestion65TopQues } from "../InsuredQuestions";
import InsuredPageCheckBox from "../component/InsuredPageCheckBox";
import ProposedInsuredQuestion77 from "./ProposedInsuredQuestion77";
import InsuredToggleButtonGroup from "../component/InsuredToggleButtonGroup";


/**
 * This component is responsible for
 * rendering a set of checkboxes related
 * to types of racing participation.
 * @param props
 * clientSigned:boolean
 * offerType:string
 * @constructor
 */
const ProposedInsuredQuestions65XCpa = (props: {clientSigned:boolean, offerType:string}) => {
    const { clientSigned, offerType } = props;
    const proposedInsuredQuest65Cpa =  proposedInsuredQuestion65Cpa(offerType)
    const { errors }:any = useFormikContext();
    return (
            <React.Fragment>
                <Typography variant="body2" component="strong" gutterBottom className="mt20 mb10">
                    <Label label={"What type of racing do you participate in? (Check ALL that apply)"} required={true}/>
                </Typography>
                {proposedInsuredQuest65Cpa.map((qcc,idc) => {
                    return (
                        <Grid item xs={12} key={idc} className="mb20">
                                <InsuredPageCheckBox question={qcc} clientSigned={clientSigned}/>
                        </Grid>
                    )
                })}
                {errors["racingValidation"] &&
                    <Grid item xs={12}>
                        <FormHelperText  style={{margin: '0', fontSize: '10px'}}>
                            <span style={{color: "#FF1744",
                                fontSize:"12px"}}> {errors["racingValidation"]} </span>
                        </FormHelperText>
                    </Grid>
                }
            </React.Fragment>
    )
}

/**
 * this component is for rendering a set of questions and checkboxes.
 * It starts by rendering a component (ProposedInsuredQuestions65XCpa)
 * with checkboxes related to racing participation based on the offerType.
 * @param props
 * clientSigned:boolean
 * proposedInsuredQuestion65TopQues:any
 * offerType:string
 * @constructor
 */
const ProposedInsuredQuestion65TopQues = (props:{clientSigned:boolean, proposedInsuredQuestion65TopQues:any, offerType:string}) => {
    const {clientSigned, proposedInsuredQuestion65TopQues, offerType} = props;

    return <>
        <ProposedInsuredQuestions65XCpa clientSigned={clientSigned} offerType={offerType}/>
        {
            proposedInsuredQuestion65TopQues.map((question: any, idx: number) => {
                return (
                        <React.Fragment key={idx}>
                            <Typography variant="body2" component="strong" gutterBottom className="mt20 mb10">
                                {question && question.label}
                            </Typography>
                            <InsuredPageCheckBox question={question} clientSigned={clientSigned}/>
                            {question && question.name === "Proposed_Insured_Questions-65XCveh" &&
                                    <Grid item xs={12}>
                                        <ProposedInsuredQuestion77 offerType={offerType} clientSigned={clientSigned} />
                                    </Grid>
                            }
                        </React.Fragment>
                )
            })
        }
        </>
}

/**
 * this component is for rendering a set of questions and checkboxes.
 * It starts by rendering a component (ProposedInsuredQuestion65TopQues)
 * and this component is rendering a set of
 * questions with labels, toggle buttons.
 * @param props
 * clientSigned:boolean
 * showComponent:Function
 * setErrors:Function
 * isFromClientDetail:boolean
 * insuredQuestion:any[]
 * offerType:string
 * @constructor
 */
const ProposedInsuredQuestion65 = (props:{clientSigned:boolean, showComponent:Function, setErrors:Function, isFromClientDetail:boolean, insuredQuestion:any[], offerType:string}) => {
    const { clientSigned, showComponent, setErrors, isFromClientDetail, insuredQuestion, offerType } = props;
    const { values, setFieldValue, setFieldTouched, handleChange, handleBlur, errors, touched }:any = useFormikContext();

    return <>
        <ProposedInsuredQuestion65TopQues clientSigned={clientSigned} proposedInsuredQuestion65TopQues={proposedInsuredQuestion65TopQues(offerType)} offerType={offerType}/>
        {
        insuredQuestion.map((question: EnrollmentFinancialAgreementProps, idx: number) => {
            return (
                <>
                    <Grid item xs={12} sm={12} md={12} key={question.key}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8} lg={9}>
                                <Typography variant="body1"
                                            className="mb15 floatLeft "
                                            component="strong">
                                    <Label
                                            label={`${question.label}`}
                                            required={!isFromClientDetail}
                                    />
                                </Typography>
                                {question.infoTooltip &&
                                    <Tooltip title={<Typography
                                            className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                        <IconButton>
                                            <Info color="primary"/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                            {
                                question.type === "radio" &&
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <InsuredToggleButtonGroup id={question.name} clientSigned={clientSigned}/>
                                    <span style={{float:"right", clear:"both"}}>
                                        <ErrorCommon touched={touched} errors={errors} name={question.name}/>
                                    </span>
                                </Grid>
                            }
                            {question && question.additionalRemarks &&
                                showComponent(question, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors)
                            }
                        </Grid>
                     </Grid>
                </>
            );
        })
    }
    </>
}

export default ProposedInsuredQuestion65;