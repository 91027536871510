import {EstimationErrorCommon, NumberTextField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import * as React from "react";
import {Grid, Typography} from "@material-ui/core";
import {useFormikContext} from "formik";
import {maskCurrency, unmaskCurrency} from "../../../../../utils/commonUtils";
import {getContributionFrequencyText} from "../../../../../utils/commonFunc";
import { useCheckAtlantic } from "../../../../../hooks/useAuth";

export default function AnnualContribution(props: {minCon: number, showTitle?: boolean})  {
    const {minCon, showTitle} = props;
    const {values, errors, touched, handleChange, validateField}: any = useFormikContext();
    const atlantic = useCheckAtlantic();


    React.useEffect(() => {
        validateField('annualContribution')
    }, [minCon]);

    const annualContributionField:any = {
        label: `${getContributionFrequencyText(values["contributionFrequency"], true)} Contribution (For 5 Years)`,
        id: "annualContribution",
        name: "annualContribution",
        placeholder: "Annual Contribution (For 5 Years)",
        component: NumberTextField,
        helperText:  (!atlantic) ? `Minimum ${maskCurrency(minCon+'')} contribution required` : '',
    };

    const validateMinContribution = (value:any) => {
        let val = unmaskCurrency(value+"");
        let error;
        if(Number(val) < minCon){
            error = `Min Amount should be ${maskCurrency(minCon+'')}`
        }
        return error;
    }

    return (
            <React.Fragment>
                <Grid item xs={12} key={1} className="mb15">
                    {showTitle && <Typography variant="body1" component="strong" className="mb15 floatLeft">How Much Would You Like To Contribute</Typography>}
                    <TextFieldCommon
                            {...annualContributionField}
                            values={values}
                            onChange={handleChange}
                            // validate={validateMinContribution}
                    />

                    <Typography variant="caption" className="mt10">
                        {annualContributionField && annualContributionField.helperFieldText}
                    </Typography>

                    <EstimationErrorCommon
                            errors={errors}
                            name={annualContributionField.name}
                            touched={touched}
                    />
                </Grid>
            </React.Fragment>
    );
}