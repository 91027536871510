import React from "react";
import {Dialog as GenericDialog, GenericDialogProps} from "../../dialog";
import {ProposalCommonProps, ProposalContent, UploadProposalActionButtons} from "./index";


export interface UploadProposalPopUp extends ProposalCommonProps {
}


export default function UploadProposalPopUp(props: UploadProposalPopUp) {
    const {open, proposalUpdate, setUploadProposalPopUp} = props;
    const [hasReports, setHasReports] = React.useState(false); // has files
    const [fileName, setFileName] = React.useState(""); // has client upload proposal at least one file

    const handleClose = () => {
        setUploadProposalPopUp(false);
    };

    const dialogProps: GenericDialogProps = {
        actions: (
            <UploadProposalActionButtons fileName={fileName} hasReports={hasReports} proposalUpdate={proposalUpdate}/>
        ),
        "aria-labelledby": "upload_proposal-pop-up-form-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (<ProposalContent {...props} fileName={fileName} setFileName={setFileName} setHasReports={setHasReports}/>),
        dividers: true,
        onClose: handleClose,
        open,
        title: "Upload New or Re-Send Proposal For Approval",
    };

    return (
        <>
            {
                open &&
                <GenericDialog {...dialogProps} />
            }
        </>
    );
};




