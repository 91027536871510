import * as React from "react";
import { Dialog as MuiDialog, DialogProps, IconButton } from "@material-ui/core";
import {DialogActions, DialogContent, DialogTitle} from "./index";
import {Variant} from "@material-ui/core/styles/createTypography";
import CloseIcon from "@material-ui/icons/Close";

export {default as DialogContentText} from "@material-ui/core/DialogContentText";

export interface GenericDialogProps extends DialogProps {
    actions?: React.ReactNode | any;
    content?: React.ReactNode | any;
    disableSpacing?: boolean;
    dividers?: boolean;
    onClose?: () => void;
    open: boolean;
    title?: string;
    titleVariant?: Variant | "inherit";
    dialogClassName?: string;
    onOutsideClick?:Function
}

const GenericDialog: React.FC<GenericDialogProps> = (props) => {
    const {actions, content, dialogClassName, dividers, onClose, onOutsideClick , title, titleVariant, ...dialogProps} = props;
    return (
            <>
                <MuiDialog onClose={()=>{onOutsideClick && onOutsideClick()}} className={dialogClassName} {...dialogProps}>
                    {onOutsideClick && <IconButton
                            edge="end"
                            color="inherit"
                            onClick={(e)=>onOutsideClick(e)}
                            aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    }
                    {
                            title &&
                            <DialogTitle onClose={onClose} title={title} titleVariant={titleVariant}/>
                    }
                    {
                            (!!content || !!props.children) &&
                            <DialogContent dividers={dividers}>{content || props.children}</DialogContent>
                    }
                    {
                            actions &&
                            <DialogActions>{actions}</DialogActions>
                    }
                </MuiDialog>
            </>
    );
};

export default GenericDialog;
