import {
    AppBar,
    FormControl,
    Grid,
    Link,
    MenuItem,
    Select,
    Tab,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {useSelector} from "react-redux";
import { ImoTable } from "../../components/table";
import EnhancedTableHead from "../../components/table/EnhancedTableHead";
import { TabPanel } from "../client/ClientProfile/component/TabsMenu";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { ApproveImoDialog, RejectImoDialog } from "../approvedAgents/ApprovedAgentsPage";
import * as ApprovedAgentActions from "../../actions/approvedAgents";
import { useActions } from "../../actions";
import { fullName } from "../client/ClientProfile/component/tabs/ClientProfileTab";
import { approvedImoTypeDropDown, IrrStatus } from '../../constants';
import { ImoRequestDetailDialog } from "./ImoRequestDetailDialog";
import TableWrap from "../../components/TableWrap/TableWrap";
import {RootState} from "../../reducers";
import {ApprovedIMO} from "../../model/approved";
import {Account} from "../../model/account";


export const imoTabs = [
    "NIW IMO's",
    "AGENT REQUESTED IMO's",
    "DELETED IMO's"
]
const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            "\"Segoe UI\"",
            "Roboto",
            "\"Helvetica Neue\"",
            "Arial",
            "sans-serif",
            "\"Apple Color Emoji\"",
            "\"Segoe UI Emoji\"",
            "\"Segoe UI Symbol\"",
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);
const ImoTab = (props: any) => {
    const {
        accountList,
        handleRefresh,
        handleReInvite,
        handleRequestSort,
        history,
        imoCarrierFilter,
        order,
        orderBy,
        page,
        region,
        rowsPerPage,
        searchText,
        setId,
        setTab,
        tab
    } = props;
    const approvedAgentActions = useActions(ApprovedAgentActions);
    const handleChange = async (event: React.ChangeEvent<{}>, newValue: string) => {
        await approvedAgentActions.flushApprovedIMOList();
        setTab(newValue);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                    >
                        <Tab label={imoTabs[0]} value={imoTabs[0]} />
                        <Tab label={imoTabs[1]} value={imoTabs[1]} />
                        <Tab label={imoTabs[2]} value={imoTabs[2]} />
                    </Tabs>
                </AppBar>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <TabPanel value={tab} index={imoTabs[0]}>
                    {
                        tab === imoTabs[0] &&
                        <TableWrap>
                            <ImoTable
                                accountList={accountList}
                                order={order}
                                orderBy={orderBy}
                                handleRequestSort={handleRequestSort}
                                handleClickOpen={(i: string) => setId(i)}
                                handleClickReInvite={(id: string) => handleReInvite(id)}
                                history={history}
                                handleRefresh={handleRefresh}
                                page={page}
                                rowsPerPage={rowsPerPage}
                            />
                        </TableWrap>
                    }
                </TabPanel>
                <TabPanel value={tab} index={imoTabs[1]}>
                    {
                        tab === imoTabs[1] &&
                        <TableWrap>
                            <AgentRequestedImoTable
                                status={IrrStatus.PENDING}
                                order={order}
                                orderBy={orderBy}
                                handleRequestSort={handleRequestSort}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                searchText={searchText}
                                region={region}
                                imoCarrierFilter={imoCarrierFilter}
                            />
                        </TableWrap>
                    }
                </TabPanel>
                <TabPanel value={tab} index={imoTabs[2]}>
                    {
                        tab === imoTabs[2] &&
                        <TableWrap>
                            <AgentRequestedImoTable
                                status={IrrStatus.REJECTED}
                                order={order}
                                orderBy={orderBy}
                                handleRequestSort={handleRequestSort}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                searchText={searchText}
                                region={region}
                                imoCarrierFilter={imoCarrierFilter}
                            />
                        </TableWrap>
                    }
                </TabPanel>
            </Grid>
        </Grid>
    )
}


const headRows: any = [
    { id: "sNo", label: "S.No" },
    { id: "imo.groupName", label: "IMO Company Name" },
    { id: "imo.imoClass", label: "IMO Type", hasSort: false },
    { id: "imo.requestedBy", label: "Agent Requesting IMO (unverified)", hasSort: false },
    { id: "imo.primaryContact", label: "IMO Primary Contact", hasSort: false },
    { id: "imo.contactPhone", label: "Contact Phone", hasSort: false },
    { id: "imo.carriers", label: "Carriers", hasSort: false },
];

const AgentRequestedImoTable = (props: any) => {
    const {handleRequestSort, imoCarrierFilter, order, orderBy, page, region, rowsPerPage, searchText, status} = props;
    const accountList = useSelector((state: RootState) => state.approvedImoList);
    const approvedAgentActions = useActions(ApprovedAgentActions);
    const _getAgentRegistrationRequests = async () => {
        // func used only to refresh, page load data comes from Account page - _getAgentRegistrationRequests
        await approvedAgentActions.getImoRegistrationRequests({
            page, rowsPerPage, status, searchText, region, carrier: imoCarrierFilter
        });
    }
    let sNo = page * rowsPerPage;

    return (
        <>
            <EnhancedTableHead
                headRows={headRows}
                includeAction={status === IrrStatus.PENDING}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
            />
            <TableBody>
                {
                    (!accountList || accountList.length === 0) &&
                    <TableRow>
                        <TableCell align="center" colSpan={headRows.length}>
                            <Typography align="left">
                                No IMO Accounts
                            </Typography>
                        </TableCell>
                    </TableRow>
                }
                {
                    accountList && accountList.map((account, index: number) => {
                        return (
                            <AgentRequestedImoRow
                                getAgentRegistrationRequests={_getAgentRegistrationRequests}
                                index={++sNo}
                                key={index}
                                request={account}
                                status={status}
                            />
                        );
                    })
                }
            </TableBody>
        </>
    )
}

const AgentRequestedImoRow: ({request, index, getAgentRegistrationRequests, status}: {
    request: ApprovedIMO;
    index: number;
    getAgentRegistrationRequests: Function;
    status: any
}) => JSX.Element = ({ request, index, getAgentRegistrationRequests, status }) => {
    const noOptionName = 'Select action';
    const options = [{id: 0, name: noOptionName}];
    const approvedAgentActions = useActions(ApprovedAgentActions);
    const [selectedOption, setSelectedOption] = React.useState<any>(noOptionName);
    const [requestToApprove, setRequestToApprove] = React.useState<any | null>(null);
    const [requestToReject, setRequestToReject] = React.useState<any | null>(null);
    const [requestDetail, setRequestDetail] = React.useState<any>(null);
    const handleSelect = (e) => {
        const selectedOpt = e.target.value;
        setSelectedOption(selectedOpt);
        if (selectedOpt === "Approve IMO for Agent") {
            setRequestToApprove(request);
        }
        if (selectedOpt === "Delete IMO for Agent") {
            setRequestToReject(request);
        }
    };
    const approveImo = async () => {
        await approvedAgentActions.approveImo(requestToApprove.id);

        setRequestToApprove(null);
        await getAgentRegistrationRequests();
    };
    const rejectImo = async (values) => {
        await approvedAgentActions.rejectImo(requestToReject.id, values.reason);

        setRequestToReject(null);
        await getAgentRegistrationRequests();
    };
    const seeDetail = async () => {
        setRequestDetail(request);
    };
    const getImoNameByValue = value => {
        const imo = approvedImoTypeDropDown.find(x => x.value === value)
        return imo && imo.name
    };
    const isRejected = status === IrrStatus.REJECTED;

    if (status === IrrStatus.PENDING) {
        options.push({id: 1, name: 'Approve IMO for Agent'});
        options.push({id: 2, name: 'Delete IMO for Agent'});
    }

    return (
        <>
            <TableRow hover key={request.id}>
                <TableCell>{index}</TableCell>
                <TableCell padding="default">
                    {
                        isRejected && request.imoName
                    }
                    {
                        !isRejected &&
                        <Link onClick={seeDetail}>{request.imo.groupName}</Link>
                    }
                </TableCell>
                <TableCell padding="default">{getImoNameByValue(isRejected ? request.type : request.imo.imoClass)}</TableCell>
                <TableCell padding="default">{isRejected ? request.agentName : fullName(request.agent.user as Account)}</TableCell>
                <TableCell padding="default">{isRejected ? request.primaryContactName : fullName(request.imo.user)}</TableCell>
                <TableCell padding="default">{isRejected ? request.contactPhone : request.imo.officeNumber}</TableCell>
                <TableCell padding="default">{isRejected ? request.carriers : request.imo.carriers.map(x => x.carrier.carrierName).join(", ")}</TableCell>
                {
                    !isRejected &&
                    <TableCell padding="default" className="width170">
                        <NiwSelect
                                onChange={e => handleSelect(e)}
                                options={options}
                                selectedOption={selectedOption}
                        />
                    </TableCell>
                }
            </TableRow>
            {
                requestToApprove &&
                <ApproveImoDialog
                    imoToApprove={requestToApprove}
                    onApprove={approveImo}
                    onClose={() => {
                        setRequestToApprove(null)
                        setSelectedOption(noOptionName)
                    }}
                />
            }
            {
                requestToReject &&
                <RejectImoDialog
                    imoToReject={requestToReject}
                    onReject={rejectImo}
                    onClose={() => {
                        setRequestToReject(null)
                        setSelectedOption(noOptionName)
                    }}
                />
            }
            {
                requestDetail &&
                <ImoRequestDetailDialog
                        status={status}
                        requestDetail={requestDetail}
                        handleClose={() => setRequestDetail(null)}
                        onApprove={() => setRequestToApprove(requestDetail)}
                        onReject={() => setRequestToReject(requestDetail)}
                />
            }
        </>
    )
}

export const NiwSelect = ({ options, selectedOption, onChange, disable = false }) => {
    return (
        <FormControl variant="outlined" fullWidth={true}>
            <Select
                disabled={disable}
                value={selectedOption}
                onChange={onChange}
                className="textLeft width170 floatRight"
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                input={<BootstrapInput/>}
            >
                {
                    options.map((o: any, index: number) => {
                        const Icon = o.icon;

                        return (
                            <MenuItem key={index} value={o.name}>
                                {Icon && <Icon/>} {o.name}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}

export default ImoTab
