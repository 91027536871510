import React from "react";
import { useFormikContext } from "formik";
import { EnrollmentFinancialAgreementProps } from "../../../../../../../model/enrollment";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import {
    DatePicker,
    ErrorCommon,
    NumberTextField,
    PhoneNumberField,
    TextField,
    TextFieldCommon,
    ErrorHelper,
} from "../../../../../../../components/formikFormInputs";
import InsuredPageRadioButton from "../component/InsuredPageRadioButton";
import InsuredPageCheckBox from "../component/InsuredPageCheckBox";
import { Diving66, DivingExperience66, proposedInsuredQuestion66TopQues } from "../InsuredQuestions";
import { proposedInsuredQuestion68TopQuesHeading } from "./ProposedInsuredQuestion68";
import InsuredToggleButtonGroup from "../component/InsuredToggleButtonGroup";
/**
 * component is responsible for rendering
 * a set of input fields related to diving
 * activities.
 * @param props
 * name:string
 * clientSigned:boolean
 * name:string
 * clientSigned:boolean
 * @constructor
 */
function ProposedInsuredQuestions66XRav(props:{name:string, clientSigned:boolean}) {
    const {name, clientSigned} = props;
    const { values, handleChange, handleBlur, errors, touched }:any = useFormikContext();
    const fieldObj:any = [
        {
            id: `${name}${Diving66.LastDive}`,
            name: `${name}${Diving66.LastDive}`,
            label: <Label label={"When was your last dive?"} required={true}/>,
            helperFieldText: "",
            placeholder: "When was your last dive?",
            disabled: clientSigned,
            filedType: "string",
            component: DatePicker,
            variant: "outlined",
            disableFuture:true,
            saveFormat: "USER"
        },
        {
            id: `${name}${Diving66.AnticipateDivingAgain}`,
            name: `${name}${Diving66.AnticipateDivingAgain}`,
            label: <Label label={"When do you anticipate diving again?"} required={true}/>,
            helperFieldText: "",
            placeholder: "When do you anticipate diving again?",
            component: DatePicker,
            variant: "outlined",
            filedType: "string",
            disabled: clientSigned,
            className: "fullWidthcustomFields",
            disableFuture:false,
            disablePast:true,
            saveFormat: "USER"
        },
        {
            id: `${name}${Diving66.NumberOfDivesLast12Months}`,
            name: `${name}${Diving66.NumberOfDivesLast12Months}`,
            label: <Label label={"Number of dives made in the last 12 months"} required={true}/>,
            placeholder: "Number of dives made in the last 12 months",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
            className: "fullWidthcustomFields"
        },
        {
            id: `${name}${Diving66.NumberOfDivesNext12Months}`,
            name: `${name}${Diving66.NumberOfDivesNext12Months}`,
            label: <Label label={"Number of dives anticipated in the next 12 months"} required={true}/>,
            placeholder: "Number of dives anticipated in the next 12 months",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
            className: "fullWidthcustomFields"
        },
        {
            id: `${name}${Diving66.NumberOfLifetimeDives}`,
            name: `${name}${Diving66.NumberOfLifetimeDives}`,
            label: <Label label={"Number of dives made in your lifetime"} required={true}/>,
            placeholder: "Number of dives made in your lifetime",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            disabled: clientSigned,
            className: "fullWidthcustomFields"
        }
    ];

    return <Grid container spacing={2} className="mt35">
        {fieldObj.map((field, index) => {
            return (
                <Grid item xs={12} md={6} key={index} className="mt10">
                    <TextFieldCommon
                        {...field}
                        values={values}
                        onChange={(e: any) => handleChange(e) }
                        onBlur={handleBlur}
                    />
                    <ErrorCommon errors={errors} name={field.name} touched={touched} />
                </Grid>
            )})}
    </Grid>
}

function ProposedInsured_Questions66XCex(props:{name:string, clientSigned:boolean}) {
    const { name, clientSigned } = props;

    const {values, errors, handleBlur, touched, handleChange}:any = useFormikContext();
    const proposedInsuredQuestions66XCex:any[] = values && values["Proposed_Insured_Questions-66XCex"] || [];
    return (
        <Grid container spacing={2}>
            {proposedInsuredQuestions66XCex.map((val:any, inx) => {
                if(val && val.name === "Proposed_Insured_Questions-66XCex_4") {
                    return <></>;
                }
                let nameIndex:string = "";
                let nameIndexArray = (val && val.name).split("_");
                if(nameIndexArray.length > 0){
                    nameIndex = nameIndexArray[nameIndexArray.length -1];
                }
                const disableProperty:any = {
                    disabled: clientSigned
                }
                const doe:string = `${name}X${nameIndex}${DivingExperience66.DateOfEvent}`;
                const treatment:string = `${name}X${nameIndex}${DivingExperience66.Treatment}`;
                const dfn:string = `${name}X${nameIndex}${DivingExperience66.DoctorFacilityName}`;
                const phnNo:string = `${name}X${nameIndex}${DivingExperience66.PhoneNumber}`;
                const city:string = `${name}X${nameIndex}${DivingExperience66.City}`;
                const state:string = `${name}X${nameIndex}${DivingExperience66.State}`;
                return (
                    <React.Fragment>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="body1" gutterBottom className="mb10" component="strong">
                                {proposedInsuredQuestions66XCex[inx].value || ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} key={`${doe}`} className="mt10">
                            <TextFieldCommon
                                name={`${doe}`}
                                id={`${doe}`}
                                placeholder={"Date of Event"}
                                component={DatePicker}
                                values={values}
                                label={<Label label={"Date of Event"} required={true}/>}
                                onChange={(e: any) => handleChange(e) }
                                onBlur={handleBlur}
                                disableFuture={true}
                                saveFormat={"USER"}
                                {...disableProperty}
                            />
                            <ErrorCommon errors={errors} name={`${doe}`} touched={touched} />
                        </Grid>
                        <Grid xs={9} className="mt20" key={`${treatment}`}>
                            <TextFieldCommon
                                    name={`${treatment}`}
                                    id={`${treatment}`}
                                    component={TextField}
                                    values={values}
                                    label={<Label label={"Treatment"} required={true}/>}
                                    onChange={(e: any) => handleChange(e) }
                                    onBlur={handleBlur}
                                    multiline={true}
                                    {...disableProperty}
                            />
                            <ErrorCommon errors={errors} name={`${treatment}`} touched={touched} />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} key={`${dfn}`} className="mt10">
                            <TextFieldCommon
                                    name={`${dfn}`}
                                    id={`${dfn}`}
                                    placeholder={"Doctor / Facility Name"}
                                    component={TextField}
                                    values={values}
                                    label={<Label label={"Doctor / Facility Name"} required={true}/>}
                                    onChange={(e: any) => handleChange(e) }
                                    onBlur={handleBlur}
                                    {...disableProperty}
                            />
                            <ErrorCommon errors={errors} name={`${dfn}`} touched={touched} />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} key={`${phnNo}`} className="mt10">
                            <TextFieldCommon
                                    name={`${phnNo}`}
                                    id={`${phnNo}`}
                                    placeholder={"Phone Number"}
                                    component={PhoneNumberField}
                                    values={values}
                                    label={<Label label={"Phone Number"} required={true}/>}
                                    onChange={(e: any) => handleChange(e) }
                                    onBlur={handleBlur}
                                    {...disableProperty}
                            />
                            <ErrorCommon errors={errors} name={`${phnNo}`} touched={touched} />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} key={`${city}`} className="mt10">
                            <TextFieldCommon
                                    name={`${city}`}
                                    id={`${city}`}
                                    placeholder={"City"}
                                    component={TextField}
                                    values={values}
                                    label={<Label label={"City"} required={true}/>}
                                    onChange={(e: any) => handleChange(e) }
                                    onBlur={handleBlur}
                                    {...disableProperty}
                            />
                            <ErrorCommon errors={errors} name={`${city}`} touched={touched} />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} key={`${state}`} className="mt10">
                            <TextFieldCommon
                                    name={`${state}`}
                                    id={`${state}`}
                                    placeholder={"State"}
                                    component={TextField}
                                    values={values}
                                    label={<Label label={"State"} required={true}/>}
                                    onChange={(e: any) => handleChange(e) }
                                    onBlur={handleBlur}
                                    {...disableProperty}
                             />
                            <ErrorCommon errors={errors} name={`${state}`} touched={touched} />
                        </Grid>
                        <span className="hLine"/>
                    </React.Fragment>
                )})}
        </Grid>
    )
}

/**
 * component is responsible for rendering a set of diving
 * experience-related questions and input fields based on the
 * proposedInsuredQuestions66XCex array
 * @param props
 * clientSigned:boolean
 * proposedInsuredQuestion66TopQues:any
 * @constructor
 */
const ProposedInsuredQuestion66TopQues = (props:{clientSigned:boolean, proposedInsuredQuestion66TopQues:any}) => {
    const {values}:any = useFormikContext();
    const {clientSigned, proposedInsuredQuestion66TopQues} = props;
    return <>
        {
            proposedInsuredQuestion66TopQues.map((question: any, idx: number) => {
            return (
                    <React.Fragment key={idx}>
                        <Typography variant="body2" component="strong" gutterBottom className="mt20 mb10">
                            {question && question.label}
                        </Typography>

                        {
                            question && question.additionalFieldType === "checkBoxGroup" ?
                                    <InsuredPageCheckBox question={question} clientSigned={clientSigned} /> :
                                    <InsuredPageRadioButton question={question} clientSigned={clientSigned} />
                        }

                        {
                            question && question.name === "Proposed_Insured_Questions-66XRav" &&
                            <ProposedInsuredQuestions66XRav name={"Proposed_Insured_Questions-66X"} clientSigned={clientSigned} />
                        }

                        {
                            question && question.name === "Proposed_Insured_Questions-66XCex" &&
                            values && values.hasOwnProperty("Proposed_Insured_Questions-66XCex") &&
                            values["Proposed_Insured_Questions-66XCex"].length > 0 && (proposedInsuredQuestion68TopQuesHeading(values["Proposed_Insured_Questions-66XCex"],"NONE OF THESE")) &&
                            <Grid item xs={12} sm={12} md={12} key={idx} className="mt10" style={{backgroundColor: '#edf3f8', padding:'20px 15px'}}>
                                <Typography variant="body1" className="floatLeft w100 mt20 mb10" component="strong">
                                    Please Provide Details for Each Event Below
                                </Typography>
                                <span className="hLine"/>
                                <ProposedInsured_Questions66XCex name={"Proposed_Insured_Questions-66"} clientSigned={clientSigned}/>
                            </Grid>
                        }
                    </React.Fragment>
                )
            })
        }
    </>
}

/**
 * Component is responsible for
 * rendering a set of questions
 * with different input types (radio buttons, text fields, etc.)
 * based on the insuredQuestion array
 * @param props
 * clientSigned:boolean
 * showComponent:Function
 * setErrors:Function
 * isFromClientDetail:boolean
 * insuredQuestion:any[]
 * offerType:string
 * @constructor
 */
const ProposedInsuredQuestion66 = (props:{clientSigned:boolean, showComponent:Function, setErrors:Function, isFromClientDetail:boolean, insuredQuestion:any[], offerType:string}) => {
    const { clientSigned, showComponent, setErrors, isFromClientDetail, insuredQuestion, offerType } = props;
    const { values, setFieldValue, setFieldTouched, handleChange, handleBlur, errors, touched }:any = useFormikContext();

    return <>
        <ProposedInsuredQuestion66TopQues clientSigned={clientSigned} proposedInsuredQuestion66TopQues={proposedInsuredQuestion66TopQues(offerType)} />
        {
        insuredQuestion.map((question: EnrollmentFinancialAgreementProps, idx: number) => {
            return (
                <>
                    <Grid item xs={12} sm={12} md={12} key={question.key}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8} lg={9}>
                                <Typography variant="body1"
                                            className="mb15 floatLeft "
                                            component="strong">
                                    <Label
                                            label={`${question.label}`}
                                            required={!isFromClientDetail}
                                    />
                                </Typography>
                                {question.infoTooltip &&
                                        <Tooltip title={<Typography
                                                className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                            <IconButton>
                                                <Info color="primary"/>
                                            </IconButton>
                                        </Tooltip>
                                }
                            </Grid>
                            {
                                question.type === "radio" &&
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <InsuredToggleButtonGroup id={question.name} clientSigned={clientSigned}/>
                                    <span style={{float:"right", clear:"both"}}>
                                        <ErrorHelper errors={errors} name={question.name} />
                                    </span>
                                </Grid>
                            }
                            {question && question.additionalRemarks &&
                                showComponent(question, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors)
                            }
                        </Grid>
                    </Grid>
                </>
            );
        })
    }
    </>
}

export default ProposedInsuredQuestion66;