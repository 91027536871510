import * as React from "react";
import { Grid } from "@material-ui/core";
import EstmateSectionOne from "./EstmateSectionOne";
import EstmateSectionTwo from "./EstmateSectionTwo";
import EstmateSectionThree from "./EstmateSectionThree";


const EstimateReturnsBody = (props: any) => {
    const {startEstimation} = props;
    const [ estimationData, setEstimationData ] = React.useState(null);
    const [ totalContribution, setTotalContribution ] = React.useState(0);

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Grid container spacing={2} justify="center">
            {startEstimation === 1 &&
                <Grid item xs={12}>
                    <EstmateSectionOne {...props} setEstimationData={( data:any )=> setEstimationData( data )} setTotalContribution={( amount:number ) => setTotalContribution( amount ) }  />
                </Grid>

            }
            {startEstimation === 2 && 
                <Grid item xs={12}>
                    <EstmateSectionTwo {...props}  />
                </Grid>
            }
            {
                startEstimation === 3 &&
                <Grid item xs={12}>
                    <EstmateSectionThree {...props}  estimationData={estimationData} totalContribution={totalContribution} />
                </Grid>
            }
			
			{/*<Grid item xs={12} md={12} className={`${startEstimation === 3 ? "bgPrimaryFooter": "pdTB30"}`}>*/}
			{/*	<LandingFooter {...props} />*/}
			{/*</Grid>*/}
		</Grid>
	);
};

export default EstimateReturnsBody;
