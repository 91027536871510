import {ActionType} from "../model";

export const addDivision = (data: { divisionName }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post('division', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Division Saved Successfully.", statusCode: 200}
        });
        return dispatch({type: ActionType.ADD_DIVISION, payload: {}});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

export const getAggregatorDivision = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('division', {}, {});
        return dispatch({type: ActionType.LIST_DIVISIONS, payload: res});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

export const updateDivision = (data: { divisionName, divisionId }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('division', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Division Saved Successfully.", statusCode: 200}
        });
        return dispatch({type: ActionType.ADD_DIVISION, payload: {}});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

export const assignDivision = (data: { divisionId: number, imoUserId: string }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('division/assign-division-imo', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Division Assigned Successfully.", statusCode: 200}
        });
        return dispatch({type: ActionType.ADD_DIVISION, payload: {}});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};