import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
// import { RootState } from "../../../reducers";
// import StrategyName from "../StrategyName";
import { RootState } from "../../../../../reducers";
import StrategyName from "../../../../../components/client/StrategyName";
import NumberFormat from "react-number-format";
import {IconButton, ListItemText, Tooltip, Typography,  List, ListItem} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
    table: {
        minWidth: 867,
        boxShadow: "0 0 2px 1px rgba(0,0,0,0.14)",
    },
});

export default function EstimationListTable(props: any) {
    const { strategySlug, responseData, lifeTimeDeathBenefitSeeking } = props;
    const { user: { client, accessType }, user } = useSelector((state: RootState) => state.auth);
    let strategyName: any = "Kai-Zen";
    if (client) {
        strategyName = (client.strategy && client.strategy.name) || strategyName;
    } else if (accessType === "client" && (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 4)) {
        strategyName = (user.demoStrategy && user.demoStrategy.name) || strategyName;
    }

    const { comparison, common, illustration } = responseData;
    let illustrationIncome:number = illustration && illustration.income || 0;
    let comparisonIncome:number = comparison && comparison.income || 0;
    let aftertaxincome:number = illustration && illustration.data && illustration.data.aftertaxincome || 0;
    let deferredtaxincome:number = illustration && illustration.data && illustration.data.deferredtaxincome || 0;

    let illustrationTotalIncome:number = illustration && illustration.totalincome || 0;
    let comparisonTotalIncome:number = comparison && comparison.totalincome || 0;
    let aftertaxtotalincome:number = illustration && illustration.data && illustration.data.aftertaxtotalincome || 0;
    let deferredtaxtotalincome:number = illustration && illustration.data && illustration.data.deferredtaxtotalincome || 0;

    let illustrationIrrnetdbandincomeage90: number = illustration && illustration.data && illustration.data.irrnetdbandincomeage90 || 0;
    let comparisonIrrnetdbandincomeage90: number = comparison && comparison.irrnetdbandincomeage90 || 0;
    let aftertaxirrage90:number = illustration && illustration.data && illustration.data.aftertaxirrage90 || 0;
    let deferredtaxirrage90:number = illustration && illustration.data && illustration.data.deferredtaxirrage90 || 0;

    let incomeageto:number = common && common.incomeageto || 0;
    let incomeagefrom:number = common && common.incomeagefrom || 0;

    let deathbenefitlessloanyear1:number = illustration && illustration.data && illustration.data.deathbenefitlessloanyear1 || 0;
    let deathbenefitlessloanage90:number = illustration && illustration.data && illustration.data.deathbenefitlessloanage90 || 0;
    let deathbenefitage90:number = comparison && comparison.deathbenefitage90 || 0;
    let deathbenefityear1:number = comparison && comparison.deathbenefityear1 || 0;
    const classes = useStyles();


    const PotentialDistributionSection = () => {
        return <>
            <TableRow key={"Potential-Distribution"} className="bgGreen colorWhite">
                <TableCell component="th" scope="row" colSpan={5} className="colorWhite">
                    <Typography variant="body2" className="colorWhite">Potential Distributions for Ages {incomeagefrom}-{incomeageto}</Typography>
                </TableCell>
            </TableRow>
            <TableRow key={"Potential-Distribution-Rows"}>
                <TableCell component="th" scope="row">
                    Potential Annual Distribution
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={illustrationIncome}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            prefix={"$"}/>
                    <Tooltip style={{height:23}} color="primary"
                             title="This is an estimate of the IUL insurance policy loans taken as income annually." arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={comparisonIncome}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            prefix={"$"}/>
                    <Tooltip style={{height:23}} color="primary"
                             title="This is an estimate of the IUL insurance policy loans taken as income annually." arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={aftertaxincome}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={0}
                            prefix={"$"}/>
                    <Tooltip style={{height:23}} color="primary" title={strategySlug === "kaizen" ? `This is the estimated cumulative total of the income distributions, also known as policy loans, you may take out of the IUL insurance policy, as your insurance carrier provided in your initial illustration. This amount can vary as needed throughout the term of the policy. However, no policy loan amount is guaranteed as it is based on the performance of the policy over time.` :
                            `This is an estimation of distributions taken annually until the account balance reaches $0 at approximately age 90. It assumes no trade costs or interim taxes paid until distributions are taken in retirement. Distributions assume taxes paid using the long-term capital gains rates.`}
                             arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={deferredtaxincome}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            prefix={"$"}/>
                    <Tooltip style={{height:23}} color="primary" title={strategySlug === "kaizen" ? `This is an estimation of policy distributions taken annually. 
                    The estimator assumes the pretax equivalent of the value you put for client contribution.  For example, if you put $20,000 
                    in for ${strategyName} the value for tax deferred would be $31,746 ($20,000 / 0.63).  It is then taxed at ordinary income 
                    rates when the money is taken out as in the case of deferred compensation.`:
                            `This is an estimation of distributions taken annually until the account balance reaches $0 at approximately age 90.
                                It assumes contributions were added made pre-tax (like the way contributions are made to a deferred compensation plan or a 401(k) plan),
                                and that no trade costs or interim taxes were paid until distributions are taken in retirement. Distributions assume taxes paid using the ordinary
                                 income tax rates.`
                    } arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow key={"Potential-Distribution-Rows-Age"}>
                <TableCell component="th" scope="row">
                    Total Potential Distributions Taken from Ages {incomeagefrom}-{incomeageto}
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={illustrationTotalIncome}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}/>
                    <Tooltip style={{height:23}} color="primary"
                             title="This is the estimated cumulative total of the income distributions, using policy loans, taken out of the IUL insurance policy, as shown by your carrier provided illustration. This amount can vary as needed throughout the term of the policy. However, no policy loan amount is guaranteed, as it is based on the policy performance over time." arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={comparisonTotalIncome}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            prefix={"$"}/>
                    <Tooltip style={{height:23}} color="primary"
                             title="This is the estimated cumulative total of the income distributions, also known as policy loans, you may take out of the IUL insurance policy, as your insurance carrier provided in your initial illustration. This amount can vary as needed throughout the term of the policy. However, no policy loan amount is guaranteed as it is based on the performance of the policy over time." arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={aftertaxtotalincome}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            prefix={"$"}/>
                    <Tooltip style={{height:23}} color="primary"
                             title="This is an estimate of the total amount of distributions taken out of the investment by age 90." arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={deferredtaxtotalincome}
                            displayType={"text"}
                            decimalScale={0}
                            thousandSeparator={true}
                            prefix={"$"}/>
                    <Tooltip style={{height:23}} color="primary"
                             title="This is an estimate of the total amount of distributions taken out of the investment by age 90." arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow key={"Potential-Distribution-Rows-IRR"}>
                <TableCell component="th" scope="row">
                    IRR Calculations
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={illustrationIrrnetdbandincomeage90*100}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={false}
                            suffix={"%"}/>
                    <Tooltip style={{height:23}} color="primary" title="Internal Rate of Return (IRR) measures the return on an investment, net of taxes and costs. An IRR can be useful for comparing investments with similar risk profiles. This IRR estimate includes residual death benefits assuming the insured under the IUL insurance policy dies at age 90."
                             arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={comparisonIrrnetdbandincomeage90*100}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={false}
                            suffix={"%"}/>
                    <Tooltip style={{height:23}} color="primary" title="IRR (internal rate of return) measures the
                     return on an investment, net of taxes and costs. An IRR can be useful for comparing investments
                     with similar risk profiles. This IRR estimate includes residual death benefits assuming the
                     insured under the IUL insurance policy dies at age 90."
                             arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={aftertaxirrage90*100}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={false}
                            suffix={"%"}/>
                    <Tooltip style={{height:23}} color="primary" title="IRR (internal rate of return) measures the return on an investment, net of taxes and costs. An IRR can be useful for comparing investments with similar risk profiles."
                             arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <NumberFormat
                            value={deferredtaxirrage90*100}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={false}
                            suffix={"%"}/>
                    <Tooltip style={{height:23}} color="primary" title="IRR (internal rate of return) measures the return on an investment, net of taxes and costs. An IRR can be useful for comparing investments with similar risk profiles."
                             arrow>
                        <IconButton aria-label="Info">
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    }

    return (
            <div className="strategyAndAlternatives">
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Index Life with <br/><StrategyName strategyName={strategyName} tooltip={true} /> Leverage</TableCell>
                            <TableCell>Index Life without <br/> Leverage</TableCell>
                            <TableCell>After Tax Investment</TableCell>
                            <TableCell>Tax-Deferred <br/>Investment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !lifeTimeDeathBenefitSeeking &&
                            <PotentialDistributionSection />
                        }
                        <TableRow key={"Potential-Distribution-Potential-Protection"} className="bgOrange">
                            <TableCell component="th" scope="row" colSpan={5} className="colorWhite">
                                <Typography variant="body2" className="colorWhite"> Potential Protections</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key={"Potential-Distribution-Rows-Initial-Death"}>
                            <TableCell component="th" scope="row">
                                Initial Death Benefit
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                        value={deathbenefitlessloanyear1}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        prefix={"$"}/>
                                <Tooltip style={{height:23}} color="primary" title="This is the death benefit initially applied for with the life insurance company.  It is needed as this is the amount the life insurance company is underwriting you for.  This is different from the death benefit you will likely receive, because it does not take into account the bank loan or any changes over time due to policy performance or reductions resulting from policy loans you take in the form of income or the policy loan used to repay the third party lender." arrow>
                                    <IconButton aria-label="Info">
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                        value={deathbenefityear1}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        prefix={"$"}/>
                                <Tooltip style={{height:23}} color="primary" title="The initial death benefit is the amount the life insurance company is underwriting you for as part of the IUL insurance policy applied for by you. This initial death benefit amount may be different from the actual death benefit you will likely receive from the policy because it does not take into account: (1) any changes over time due to the policy performance; or (2) reductions resulting from policy loans you take in the form of income." arrow>
                                    <IconButton aria-label="Info">
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                        value={0}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}/>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                        value={0}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}/>
                            </TableCell>
                        </TableRow>
                        <TableRow key={"Potential-Distribution-Rows-After-90"}>
                            <TableCell component="th" scope="row">
                                <List>
                                    <ListItem disableGutters={true}>
                                        <ListItemText
                                                primary="Death Benefit Remaining At Age 90 After Distribution"
                                                secondary="(Net of loans)"/>
                                    </ListItem>
                                </List>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                        value={deathbenefitlessloanage90}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        prefix={"$"}/>
                                <Tooltip style={{ height: 23 }} color="primary"
                                         title={strategySlug === "kaizen" ? `This is the amount paid to the IUL insurance policy beneficiary upon the death of the policy’s insured person.
                                          Death benefits vary based on the insured’s age and health. The amount shown here
                                           is the predicted death benefit available at age 90 after any IUL insurance policy
                                            distributions (such as policy loans and other living benefits).` : `The amount
                                                                           paid to a beneficiary upon
                                                                           the death of an insured person. Death
                                                                           Benefits start out
                                                                           higher depending on your age and health. The
                                                                           amount shown
                                                                           here is the remaining amount at age 90 after
                                                                           policy
                                                                           distributions and net of the split dollar
                                                                           employer loan
                                                                           repayment.`} arrow>
                                    <IconButton aria-label="Info">
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                        value={deathbenefitage90}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        prefix={"$"}/>
                                <Tooltip style={{height:23}} color="primary" title={strategyName === "kaizen" ? `This is the amount paid to the IUL insurance policy beneficiary upon the death of the policy’s insured person. Death benefits vary based on the insured’s age and health. The amount shown here is the predicted death benefit available at age 90 after any IUL insurance policy distributions (such as policy loans and other living benefits).` : `This is the amount paid to the IUL insurance policy beneficiary upon the death of the policy’s insured person. Death benefits vary based on the insured’s age and health. The amount shown here is the predicted death benefit available at age 90 after any IUL insurance policy distributions (such as policy loans and other living benefits).`} arrow>
                                    <IconButton aria-label="Info">
                                        <InfoIcon/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                        value={0}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}/>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                        value={0}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
    );
}