
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Viewer} from "../../components";

const styles: any = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function PrivacyPolicyPopUp(props: { showPrivacyPolicy: boolean, setShowPrivacyPolicy: Function }) {
    const {showPrivacyPolicy, setShowPrivacyPolicy} = props;

    const handleClose = () => {
        setShowPrivacyPolicy(false);
    };

    return (
            <div>
                <Dialog fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title" open={showPrivacyPolicy}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Privacy Policy
                    </DialogTitle>
                    <DialogContent dividers>
                        <Viewer url="https://niwcorp-staging.s3.amazonaws.com/Privacy-and-Security-Policy.pdf" />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" className="mt20 mb20" autoFocus onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
    );
}
