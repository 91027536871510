import React from "react";
import { useActions } from "../actions";
import * as Actions from "../actions/auth";
type Props = {
	children: JSX.Element
}
export default function Session(props: Props) {
	const authActions = useActions(Actions);
	React.useEffect(() => {
		const callAccountDetail = async () => {
			await authActions.getAccountDetails();
		};
		callAccountDetail();
	}, []);
	return props.children;
}