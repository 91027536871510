let valueArr: any = [];
export const makeAllFormValues = () => {
    function addVal(val): void {
        valueArr = val;
    }

    function getVal(): any {
        return valueArr;
    }

    return {addVal, getVal};
}