import React from "react";
import {Button, CircularProgress, IconButton, TableCell, TableRow, Tooltip,} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutlined";
import {dateUtils} from "../../utils";
import {Link} from "react-router-dom";
import { ActionItems, PermissionTypes } from "../../constants";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import {RootState} from "../../reducers";
import {useSelector} from "react-redux";
import {useActions} from "../../actions";
import * as AccountActions from "../../actions/account";
import GetInviteStatus from "../GetInviteStatus";
import * as Actions from "../../actions/enrollment";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import { can } from "../navigation/AdminNavigation";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
            popover: {
                pointerEvents: "none",
            },
            buttonProgress: {
                color: 'white',
            },
            iconPadding: {
                padding: "0px !important",
            },
            warning: {
                color:"#ec1459",
            }
        }),
);

const ActionTableCell = ({user, accountUserId, accountUserRole, basePath, history, clientId, accountUserInviteLink, account,
                             inviteTokenId, valHandler, downloadEnvelope, classes, downloadProgress, deletedUser}) => {
    if (user.role === 7) {
        return <></>;
    }
    return <TableCell padding="default" align="right" className="w230">
        {
                user && user.role !== 4 && user.role !== 12 && accountUserId &&
                <Tooltip title="Edit">
                    <IconButton
                            color="default"
                            onClick={() => {
                                if (accountUserRole === 6) {
                                    history.push("/" + basePath + "/" + "individual" + "/edit/" + accountUserId);
                                } else {
                                    history.push("/new-client/detail/" + clientId);
                                }
                            }}
                    >
                        <CreateIcon />
                    </IconButton>
                </Tooltip>
        }
        {
                accountUserInviteLink && user.role !== 12 &&
                <Tooltip placement="left-start" title={ (account.id === inviteTokenId)
                        ?  <React.Fragment>Invite Link Has Been <br/> Copied to Your Clipboard</React.Fragment>
                        : "Copy Invite Link"}>
                    <CopyToClipboard text={accountUserInviteLink}
                                     onCopy={()=>valHandler(account.id)}>
                        <IconButton color="default">
                            <LinkIcon />
                        </IconButton>
                    </CopyToClipboard>
                </Tooltip>
        }

        {user.role === 12 && account["client.trusteeActionItems.actionType"] === "DOWNLOAD_TRUSTEE_DOCUMENTS" &&
                <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className="btnSmall ml5"
                        type={"button"}
                        onClick={() => downloadEnvelope(account["client.trusteeActionItems.enrollmentId"], 6)}
                >
                    {ActionItems.DOWNLOAD_TRUSTEE_DOCUMENTS.actionButton}
                    {downloadProgress.enrollmentId === account["client.trusteeActionItems.enrollmentId"] &&
                            <CircularProgress size={24}
                                              className={classes.buttonProgress}/>}
                </Button>
        }


        { accountUserId &&  <Tooltip title="View">
            <Link to={'/new-client/detail/' + clientId}>
                <IconButton color="primary" className={classes.iconPadding}>
                    <VisibilityOutlinedIcon />
                </IconButton>
            </Link>
        </Tooltip> }


        {user && (user.role === 1 || can(user, PermissionTypes.DELETE_USER) || (user.role === 4 && account.inviteStatus < 4)) && accountUserId &&
                <Tooltip title="Delete">
                    <IconButton
                            color="default"
                            className={classes.iconPadding}
                            onClick={() => {
                                const deleteId: any = account.userId || account.id;
                                const type:string =  typeof deleteId == "number" ? "PARTICIPANT":"USER";
                                deletedUser(deleteId, type, account)
                            }}
                    >
                        <DeleteOutlineIcon className={classes.warning}/>
                    </IconButton>
                </Tooltip>
        }
    </TableCell>
}
function IndividualTableRow(props: any) {
	const { account, setInviteTokenId, inviteTokenId, callingFrom, basePath, handleRefresh, listType, pathName, children, render } = props;
    const user = useSelector((state: RootState) => state.auth.user);
	const accountActions = useActions(AccountActions);
	const enrollmentActions = useActions(Actions);
    const [downloadProgress, setDownloadProgress] = React.useState({enrollmentId : ""});
    const classes = useStyles();

	const refreshTable = () =>{
		handleRefresh();
	}


	function valHandler(accountId: string) {
		setInviteTokenId(accountId);
		accountActions.copyInvitationLink(
			"Invite Link Has Been Copied to Your Clipboard"
		);
	}

    const accountUserId =  listType ==='main' ? account.id : account['user.id'] ;
    const accountUserRole =  listType ==='main' ? account.role : account['user.role'] ;
    const accountUserInviteLink =  listType ==='main' ? account.inviteLink : account['user.inviteLink'] ;
    let clientId =  listType ==='main' ? account['client.id'] : account['user.client.id'] ;
    if(typeof clientId === "undefined"){
        clientId =  account.clientId;
    }

    const downloadEnvelope = async (enrollmentId: string, recipientId?: number) => {
        setDownloadProgress({enrollmentId : enrollmentId});
        let result = await enrollmentActions.downloadEnvelope(enrollmentId, recipientId);
        if (result && result && result.payload.data) {
            let response: any = result.payload.data.fileBuf;
            let blob = new Blob([new Buffer(response, "binary")]
                    , { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = url;
            link.setAttribute("download", "application.pdf");
            window.document.body.appendChild(link);
            link.click();
            setDownloadProgress({enrollmentId : ""});
        }
    };

    return (
        <TableRow hover key={account.id}>
            {children}            
            <TableCell padding="default">{ accountUserId ?
                    <Link to={"/new-client/detail/" + clientId}>{account.firstName}</Link>
                    : account.firstName}
            </TableCell>
            <TableCell padding="default">{ accountUserId ?
                    <Link to={"/new-client/detail/" + clientId}>{account.lastName}</Link>
                    : account.lastName}
            </TableCell>
            {
                    callingFrom === 'accountList' &&
                    <TableCell padding="default">{account["client.tranche.trancheNumber"]}</TableCell>
            }
            <TableCell padding="default">{account.email}</TableCell>
            { render }
            {
                callingFrom === 'accountList' &&
                <TableCell padding="default">{account["client.agent.user.name"]}</TableCell>
            }
            {
                callingFrom === 'accountList' && pathName !== "/imo/individual" && //no need to show groupName for imo loggedin in case of individual list i.e pathName check
                <TableCell padding="default">{account["client.imo.groupName"]}</TableCell>
            }
            {
                callingFrom === 'accountList' && pathName !== "/imo/individual" && //no need to show region for imo loggedin in case of individual list i.e pathName check
                <TableCell padding="default">{account["client.imo.region"]}</TableCell>
            }
            {
                <TableCell padding="default">{account["client.strategyName"] !== "" ? account["client.strategyName"] : account["client.strategy.displayName"]}</TableCell>
            }
                { pathName !== "/imo/individual" &&
                <TableCell padding="default">{(account["client.carrier.carrierName"] !== '' && account["carrier.carrierName"] !== '') ? account["client.carrier.carrierName"] && account["client.carrier.carrierName"] !== '' ?  account["client.carrier.carrierName"] : account["carrier.carrierName"] : account["carrierName"]}</TableCell>
            }
            <GetInviteStatus
                role={user.role}
                account={account}
                refreshTable={()=>refreshTable()}
            />
            {
                callingFrom === 'accountList' &&
                <TableCell padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
            }
            {
                    callingFrom === 'accountList' &&
                    <TableCell padding="default">
                        {account.lastLogin ? dateUtils.asDbDateFormat(account.lastLogin) : 'Never Logged In'}
                        <br />
                        {account.lastLogin ? dateUtils.timeCalculator(account.lastLogin) : ""}
                    </TableCell>
            }
            <ActionTableCell user={user} accountUserId={accountUserId} accountUserRole={accountUserRole}
                             basePath={basePath} history={props.history} clientId={clientId} accountUserInviteLink={accountUserInviteLink} account={account}
                inviteTokenId={inviteTokenId} valHandler={valHandler} downloadEnvelope={downloadEnvelope} classes={classes} downloadProgress={downloadProgress} deletedUser={props.deletedUser} />
        </TableRow>
    )
}
export default IndividualTableRow;
