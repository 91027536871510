import React  from "react";
import {Checkbox} from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import {ErrorCommon} from "./index";
import {Grid} from "@material-ui/core";

export default function FormikFieldArray(props:{
    fieldCollection:[],
    fieldName: string,
    renderObjectData:any,
    clientSigned: boolean
}) {
    const {
        fieldCollection,
        fieldName,
        renderObjectData: {
            insuredAviationSubQuestions,
            insuredForeignTravelSubQuestions,
            insuredAvocationSubQuestions
        },
        clientSigned
    } = props;

    const {values, errors, touched}: any = useFormikContext();

    return (
            <FieldArray
                name={fieldName}
                render={arrayHelpers => (
                        <Grid container spacing={1}>
                            {fieldCollection.map((fieldVal:any) => {
                                return (
                                    <Grid item xs={12}>
                                        <label key={fieldVal.value}>
                                            <Checkbox
                                                name={fieldName}
                                                //type="checkbox"
                                                value={fieldVal}
                                                checked={values[fieldName].includes(fieldVal.value)}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        arrayHelpers.push(fieldVal.value);
                                                    } else {
                                                        const idx = values[fieldName].indexOf(fieldVal.value);
                                                        arrayHelpers.remove(idx);
                                                    }
                                                }}
                                                disabled={clientSigned}
                                            />
                                            <span>&nbsp;{fieldVal.label}</span>
                                        </label>

                                        {(values[fieldName].includes("Insured_Aviation") && fieldVal.value === "Insured_Aviation") && insuredAviationSubQuestions()}
                                        {(values[fieldName].includes("Insured_ForeignTravel") && fieldVal.value === "Insured_ForeignTravel") && insuredForeignTravelSubQuestions()}
                                        {(values[fieldName].includes("Insured_Avocation") && fieldVal.value === "Insured_Avocation") && insuredAvocationSubQuestions()}

                                    </Grid>
                            )})}
                            <ErrorCommon errors={errors} name={fieldName} touched={touched} />
                        </Grid>

                )}
            />
    );
}