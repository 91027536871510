import * as React from "react";
import {
    Card,
    CardContent,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Container,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from "react-player";
import * as QueryString from "query-string";
import AuthenticationForm from "./AuthenticationForm";
import videoIcon from "../../../../../components/client/landingPage/v2/images/sectionOne/Group-25@1x.svg";
import CheckIcon from "@material-ui/icons/Check";
import { LOGO_BASE_URL } from "../../../../../constants";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
        createStyles({
            getStarted: {
                textAlign: "left",
                position: "relative",
                overflow: "visible",
                maxWidth: "100%",
                marginLeft: "40px",
                float: "left",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "0",
                },
                [theme.breakpoints.down("sm")]: {
                    maxWidth: "100%",
                    width: "100%",
                },
            },
            heroCard:{
                padding: "30px 30px 30px !important",
            },
        }),
);
const SectionOne = (props: any) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [showStickyBtn, setShowStickyBtn] = React.useState(false);
    const strategy: string = props.strategyData.name;
    const videoUrl: string = props.strategyData.videoUrl;
    const [muted, setMuted] = React.useState(false);

    let companyName: string = props.groupDetail ? props.groupDetail.companyName : "Group";
    if (props.accountType === props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST) {
        companyName = (props.agent && props.agent.user && props.agent.user.firstName + " " + props.agent.user.lastName) || "Agent";
    }

    if (props.accountType === props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL) {
        companyName = (props.agent && props.agent.user && props.agent.user.firstName + " " + props.agent.user.lastName) || "";
    }

    const params = QueryString.parse(props.location.search);

    let strategyGif: string = "";
    if (props.strategyData && props.strategyData.animationImageThumb) {
        strategyGif = LOGO_BASE_URL + props.strategyData.animationImageThumb;
    }

    React.useEffect(() => {
        if (params && params.video === "play") {
            setOpen(true);
        }
        window.addEventListener("scroll", handleScroll, true);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        });
    }, []);

    const handleClose = () => {
        setOpen(false);
        props.history.push({
            pathname: window.location.pathname,
            search: "?landing=0",
        });
    };

    const handleScroll = () => {
        if (!showStickyBtn && window.scrollY > 120) {
            setShowStickyBtn(true);
        }
    };

    const getDialog = () => {
        return (
                <Dialog
                        className="landingPageModal"
                        fullWidth={true}
                        maxWidth="xl"
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="max-width-dialog-title">
                    <DialogContent className="landingPageDialogContent">
                        <IconButton edge="end" className="closeicon" color="inherit" onClick={handleClose}
                                    aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <ReactPlayer
                                className="videos"
                                controls={true}
                                playing={false}
                                loop={false}
                                autoPlay={false}
                                volume={0.5}
                                muted={muted}
                                url={videoUrl}
                                width='100%'
                                height='800px'
                                playsinline={true}
                        />
                    </DialogContent>
                </Dialog>
        );
    };

    return (
            <div className="sectionPadding mainBg">
                <Grid container justify="center">
                    {getDialog()}
                    <Container maxWidth="lg">
                        <Grid container spacing={2} justify="center" className="reverse">
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <Typography variant="h1" align="left" className="colorWhite">Get started
                                    with <br/> {strategy}</Typography>
                                <Typography variant="body1" align="left" className="colorWhite">
                                    {props.accountType === props.ALLOWED_ACCOUNT_TYPE.INDIVIDUAL && props.firstName + ", "} {companyName} has
                                    invited
                                    you to learn about the {strategy} strategy, simply the most tax efficient benefit
                                    plan currently on the market. If writing those substantial tax checks makes you
                                    queasy, click the video below or get started and learn how {strategy} can work for
                                    you.
                                </Typography>
                                <Typography variant="subtitle1"
                                            className="colorWhite mb0">Your {strategy} potential</Typography>
                                <List className="ulList colorWhite">
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLightGreen f34"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Up to three times more money added to your plan."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLightGreen f34"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Protection with highly tax efficient growth."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLightGreen f34"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Potential for tax-free distributions."
                                        />
                                    </ListItem>
                                </List>

                                <div className="heroVideoBtn" onClick={() => {
                                    setOpen(true);
                                    setMuted(false);
                                }}>
                                    <Grid container className="ht153">
                                        <Grid item xs={6} md={6} lg={6}>
                                            <img className="heroVideoThumb" src={strategyGif} alt=""/>
                                            <img className="videoIcon" src={videoIcon} alt=""/>
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6} className="textLeft contentCenter">
                                            <div className="videoContent">
                                                <Typography variant="subtitle2" className="textHighLight mb0"> 3 minute
                                                    video </Typography>
                                                <Typography variant="caption" className="f14">Learn
                                                    about {strategy} and how it
                                                    works.</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Card className={classes.getStarted}>
                                            <CardContent className={classes.heroCard}>
                                                <AuthenticationForm {...props} />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </div>
    );
};

export default SectionOne;
