import React, { useState } from "react";
import _ from "lodash";
import {
    FormControlLabel,
    FormGroup,
    Checkbox,
    FormControl,
    FormHelperText,
    Tooltip, FormLabel, Grid,
} from "@material-ui/core";

import { FormInputProps } from "../../../../../../model"
import {Field, useFormikContext} from "formik";
import {PhoneNumberField, TextField} from "../../../../../../components/formikFormInputs";

interface EnrollmentCheckBoxGroupProps extends FormInputProps {
    label: string,
    toolTipLabel: string,
    options: any[],
    isFromClientDetail: boolean,
    clientSigned: boolean


}

type OptionProps = {
    value: string,
    text: string,
    additionalFields: any,
    readonly: boolean
}

export default function EnrollmentCheckBoxGroup({
                                          required,
                                          fullWidth,
                                          disabled,
                                          label,
                                          toolTipLabel,
                                          options,
                                          isFromClientDetail,
                                          clientSigned,
                                          meta: { touched, error },
                                          input,
                                          ...custom
                                      }: EnrollmentCheckBoxGroupProps) {

    const [requiredGroup, setRequiredGroup] = useState( true );

    const {values, errors, handleChange, handleBlur, setFieldValue}: any = useFormikContext();

    function handleCbChange(e: React.ChangeEvent<HTMLInputElement>, value: string, o : any) {
        let newValue = [...input.value];
        if (e.target.checked){
            newValue.push(value);
            setRequiredGroup(false);
        } else {
            o.additionalFields && o.additionalFields.map((item, index: any) => { // empty the additional field value, if unchecked
                setFieldValue(`${item.name}`, "");
            })
            newValue.splice(newValue.indexOf(value), 1);
            setRequiredGroup(true);
        }
        return input.onChange(newValue);
    }

    return (
            <FormControl
                    required={required}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    error={touched && error}
            >
                {
                        label &&
                        <FormLabel >{label}</FormLabel>
                }
                <FormGroup >
                    {
                        options.map((o: OptionProps, idx: number) =>
                                <>
                                    <FormControlLabel
                                            className="checkboxGroup mt20 floatLeft w100 mb15"
                                            style={input.style}
                                            key={`${idx}-${o.text}`}
                                            value={o.value}
                                            control={<Tooltip title={'Select ' + (toolTipLabel || o.text)}>
                                                <Checkbox name={`${input.name}[${idx}]`}
                                                          onChange={(event) => { handleCbChange(event, o.value, o); } }
                                                          required={required ? requiredGroup : false}
                                                          disabled={clientSigned || o.readonly}
                                                          color={'primary'}
                                                          checked={_.includes(input.value, o.value) || o.readonly} />
                                            </Tooltip>}
                                            label={o.text}
                                    />
                                    <Grid container spacing={2}>
                                        {(values[input.name].includes(o.value) || o.readonly) &&  o.additionalFields && o.additionalFields.map((value, index: any) => {
                                            return (<Grid item xs={12} md={4}
                                                          key={index + value.name}>
                                                <Field fullWidth name={value.name}
                                                       label={value.label}
                                                       id={value.name}
                                                       component={(value.type && value.type === "phone") ? PhoneNumberField : TextField}
                                                       value={values[(o && o.additionalFields[index].name) || ""]}
                                                       placeholder={value.label}
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       error={isFromClientDetail ? false : !!errors[(o && o.additionalFields[index].name) || ""]}
                                                       helperText={isFromClientDetail ? "" : errors[(o && o.additionalFields[index].name) || ""] ? errors[(o && o.additionalFields[index].name) || ""] : ""}
                                                       disabled={clientSigned}
                                                       variant="outlined"
                                                       validate={(value: string) => {
                                                           if (isFromClientDetail) {
                                                               return "";
                                                           }
                                                           if (!value && values[`${input.name}[${idx}]`]) {
                                                               return "Required Fieldeeee";
                                                           }
                                                           return "";
                                                       }}
                                                />
                                            </Grid>);
                                        })
                                        }
                                    </Grid>

                                </>
                                )
                    }
                </FormGroup>

                {
                        (touched && error) &&
                        <FormHelperText>{touched && error}</FormHelperText>
                }
            </FormControl>
    );
}