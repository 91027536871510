import React from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link,
    Checkbox,
    Button,
    Box,
    TableContainer,
} from "@material-ui/core";
import { usersDesc, useCheckboxSelect } from "../../agent/dashboard/TableQuickView";
import { TableCellLastLogin } from "../../../components/table/AgentTable";
import { Link as RouterLink } from "react-router-dom";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import { DeleteModel } from "../../../components/formInputs";
import { useActions } from "../../../actions";
import * as AccountActions from "../../../actions/account";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableWrap from "../../../components/TableWrap/TableWrap";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            table: {
                minWidth: 767,
            },
            tableHead: {
                backgroundColor: "#F4F6F8",
            },

            container: {
                maxHeight: 600,
            },

        }),
);

export const ImoAgentTable = ({ kpiData, selectable = false }) => {
    const classes = useStyles();
    const {
        handleSelectOneClick,
        isSelected,
        selected,
        clearSelected,
        handleSelectAllClick,
        anySelected,
        allSelected,
    } = useCheckboxSelect(kpiData);

    const [isVisibleDeleteModal, setIsVisibleDeleteModal] = React.useState(false);
    const accountActions = useActions(AccountActions);

    return (
            <>
                {
                        selected.length > 0 &&
                        <Box p={1}>
                            <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setIsVisibleDeleteModal(true);
                                    }}>
                                Delete selected
                            </Button>
                        </Box>
                }
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {
                                selectable &&
                                <TableCell align="left">
                                    <Checkbox
                                            color="primary"
                                            indeterminate={anySelected}
                                            checked={allSelected}
                                            onChange={handleSelectAllClick}
                                            inputProps={{
                                                "aria-label": "select all",
                                            }}
                                    />
                                </TableCell>}
                        <TableCell align="left">First Name</TableCell>
                        <TableCell align="left">Last Name</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Total Invites</TableCell>
                        <TableCell align="left">Total Link leads</TableCell>
                        <TableCell align="left">Total Clients</TableCell>
                        <TableCell align="left">Total Groups</TableCell>
                        <TableCell align="left">Currently Enrolling</TableCell>
                        <TableCell align="left">Paid Cases</TableCell>
                        <TableCell align="left">Last Active</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        kpiData.map((v, i) => {
                            const isItemSelected = isSelected(v);

                            return (
                                    <TableRow key={i}>
                                        {
                                                selectable &&
                                                <TableCell>
                                                    <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            onChange={() => handleSelectOneClick(v)}
                                                            inputProps={{
                                                                "aria-labelledby": "individual",
                                                            }}
                                                    />
                                                </TableCell>
                                        }
                                        <TableCell align="left">
                                            <Link
                                                    component={RouterLink}
                                                    to={"/new-agent/detail/" + v["agent.userId"]}
                                            >{v.firstName}</Link>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Link
                                                    component={RouterLink}
                                                    to={"/new-agent/detail/" + v["agent.userId"]}
                                            >{v.lastName}</Link>
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.email}
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.totalInvites}
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.totalLinkLeads}
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.totalClients}
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.totalGroups}
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.currentlyEnrolling}
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.paidCases}
                                        </TableCell>
                                        <TableCellLastLogin lastLogin={v.lastLogin}/>
                                    </TableRow>
                            );
                        })
                    }
                </TableBody>
                <DeleteModel
                        open={isVisibleDeleteModal}
                        disableDelete={cantDelete(selected)}
                        handleClose={() => setIsVisibleDeleteModal(false)}
                        handleYes={async () => {
                            setIsVisibleDeleteModal(false);
                            await accountActions.deleteAccounts(selected.map(x => ({ ...x, id: x["agent.userId"] })));
                            clearSelected();
                        }}
                        accountType="Agent"
                        title="Delete confirmation"
                        content={deleteDialogContent(selected)}
                />
            </>
    );
};

const hasClientsEnrolling = x => x.currentlyEnrolling > 0;
const hasNotClientsEnrolling = x => !hasClientsEnrolling(x);

export const deleteDialogContent = (users: any[]) => {
    const usersNotEnrolling = users.filter(hasNotClientsEnrolling);

    if (users.length === usersNotEnrolling.length) {
        return `Are you sure you want to delete ${usersDesc(users)} permanently from ilia ?`;
    }

    if (users.length == 1) {
        return "You can't delete this account, the agent selected has clients currently in the enrollment process.";
    }

    const usersEnrolling = users.filter(hasClientsEnrolling);

    return `We are not able to delete all selected accounts. 
        There is/are agents who have clients currently in the enrollment process: ${usersEnrolling.map(user => user.firstName + " " + user.lastName).join(", ")}.
        Would you like to permanently delete all other accounts?`;
};


const cantDelete = (users: any[]) => {
    const usersNotEnrolling = users.filter(hasNotClientsEnrolling);
    if (users.length === usersNotEnrolling.length) {
        return false;
    }
    if (users.length == 1) {
        return true;
    }
    return false;
};

const headRows: any[] = [
    { id: "agentName", label: "Agent Name" },
    { id: "views", label: "Link Views" },
    { id: "emailLeads", label: "Email Leads" },
    { id: "closedLeads", label: "Closed Leads" },
];

export const AgentLinkTable = ({ kpiData, order, orderBy, handleRequestSort }) => {
    const classes = useStyles();

    return (

            <TableWrap>
                <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headRows={headRows}
                        includeAction={false}
                />
                <TableBody>
                    {
                        kpiData.map((v, i) => {
                            return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            <Link
                                                    component={RouterLink}
                                                    to={"/new-agent/detail/" + v.userId}
                                            >{v.agentName}</Link>
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.views || 0}
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.emailLeads}
                                        </TableCell>
                                        <TableCell align="left">
                                            {v.closedLeads}
                                        </TableCell>
                                    </TableRow>
                            );
                        })
                    }
                </TableBody>
            </TableWrap>
    );
};