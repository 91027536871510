import React, { useState } from "react";
import _ from "lodash";
import {
    FormControlLabel,
    FormGroup,
	Checkbox,
	FormControl,
	FormHelperText,
	Tooltip, FormLabel,
} from "@material-ui/core";
import { FormInputProps } from "../../model";

interface CheckBoxGroupProps extends FormInputProps {
	label: string,
	toolTipLabel: string,
	options: any[]
}

type OptionProps = {
	value: string,
	text: string
}

export default function CheckBoxGroup({
											required,
											fullWidth,
											disabled,
											label,
											toolTipLabel,
											options,
											meta: { touched, error },
											input,
											...custom
										}: CheckBoxGroupProps) {

	const [requiredGroup, setRequiredGroup] = useState( true );

	function handleChange(e: React.ChangeEvent<HTMLInputElement>, value: string) {
		let newValue = [...input.value];
		if (e.target.checked){
		  newValue.push(value);
			setRequiredGroup(false);
		} else {
		  newValue.splice(newValue.indexOf(value), 1);
			setRequiredGroup(true);
		}
		return input.onChange(newValue);
	}


        return (
                <FormControl
                        required={required}
                        fullWidth={fullWidth}
                        disabled={disabled}
					error={touched && error}
			>
				{
					label &&
		  		<FormLabel >{label}</FormLabel>
				}
                <FormGroup >
                {
                    options.map((o: OptionProps, idx: number) =>
                            <FormControlLabel
                                    className="checkboxGroup"
                                    style={input.style}
                                    key={`${idx}-${o.text}`}
                                    value={o.value}
                                    control={<Tooltip title={'Select ' + (toolTipLabel || o.text)}>
																			<Checkbox name={`${input.name}[${idx}]`}
																								onChange={(event) => { handleChange(event, o.value); } }
																								required={required ? requiredGroup : false}
                                                                                                color={'primary'}
																								checked={_.includes(input.value, o.value)}/>
                                    </Tooltip>}
                                    label={o.text}
                            />)
                }
                </FormGroup>

				{
					(touched && error) &&
		  		<FormHelperText>{touched && error}</FormHelperText>
				}
			</FormControl>
	);
}