import * as React from "react";
import "babel-polyfill";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import App from "./App";
import configureStore from "./configureStore";
import Session from "./components/Session";
import request, {AxiosRequestConfig} from "axios";

const { persistor, store } = configureStore();

function ReduxRoot() {
	React.useEffect(() => {
		//Request Interceptor
		request.interceptors.request.use((config: AxiosRequestConfig) => {
            const auth: { accessToken?: string } = store.getState().auth;
			if (auth.accessToken) {
				config.headers.Authorization = "Bearer " + auth.accessToken;
			}
			return config;
		});
	}, []);

	return (
		<Provider store={store}>
			<PersistGate
				persistor={persistor}
			>
				<Session>
					<App/>
				</Session>
			</PersistGate>
		</Provider>
	);
}

export default ReduxRoot;
