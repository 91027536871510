import React from 'react';
import { Form as _Form, Formik, FormikConfig, FormikValues as _FormikValues } from 'formik';

export const Form = _Form;
export type FormikValues = _FormikValues;

export interface FormWrapperProps extends FormikConfig<FormikValues> {
    children?: React.ReactChildren | React.ReactElement | any
}

const FormikWrapper: React.FC<FormWrapperProps> = (props) => {
    return (
            <Formik {...props}>
                {
                    formInternalProps => (
                            <>
                                {
                                        props && props.children({...formInternalProps, ...props})
                                }
                            </>
                    )
                }
            </Formik>
    );
};

export default FormikWrapper;
