import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import {Label} from "../../../../../../../components/formInputs";
import {NumberTextField, TextField} from "../../../../../../../components/formikFormInputs";

import * as Yup from "yup";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useActions} from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import LiabilitiesForm from "./LiabilitiesForm";
import { isLongForm, isWorksheet } from "../../Utilities";

const Liabilities = (props: any) => {
    const {
        autoSaveTrigger,
        financialAndLiabilitiesArr,
        formType,
        isFromClientDetail, /*setIsError,*/
        liabilitiesRef,
        offerType,
        setFinancialAndLiabilitiesArr,
        setFormType,
        setLiabilitiesVerified,
        setLiabilitiesVerifyRef,
        setSaveFormContent,
        user,
    } = props;
    const clientSigned = user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? 1 : 0;
    const { updateClientFields } = useActions(AccountActions);
    const [isConfirmCheckbox, setIsConfirmCheckbox] = React.useState(user && user.client && user.client.verifyLiabilities ? true : false);

    const liabilitiesVerifyRef = React.useRef<any>();

    useEffect(() => {
        if (liabilitiesVerifyRef.current && typeof setLiabilitiesVerifyRef === 'function') {
            setLiabilitiesVerifyRef(liabilitiesVerifyRef);
        }
    }, [liabilitiesVerifyRef.current]);

    useEffect(()=>{
        setLiabilitiesVerified(isConfirmCheckbox);
    },[isConfirmCheckbox])

    const fieldObj = [
        {
            id: "unpaidInterestTaxes",
            name: "unpaidInterestTaxes",
            helperFieldText: "",
            // label: <Label label={"Taxes and Interest Due"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Unpaid Interest & Taxes" : "Taxes and Interest Due" } required={false}/>,
            isRequired: true,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "mortgages",
            name: "mortgages",
            helperFieldText: "",
            // label: <Label label={"Real Estate Mortgages or Liens"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Mortgages" : "Real Estate Mortgages or Liens" } required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "creditCardDebt",
            name: "creditCardDebt",
            helperFieldText: "",
            label: <Label label={"Credit Card Debt"} required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: (isLongForm(offerType) || isWorksheet(offerType)),
        },
        {
            id: "loansOnLifeInsurance",
            name: "loansOnLifeInsurance",
            helperFieldText: "",
            // label: <Label label={"Loans on Life Insurance"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Secured Loans" : "Loans on Life Insurance" } required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "personalNotes",
            name: "personalNotes",
            helperFieldText: "",
            // label: <Label label={""} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Personal Notes" : "Notes Payable" } required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "accountsPayable",
            name: "accountsPayable",
            helperFieldText: "",
            label: <Label label={"Accounts Payable"} required={false}/>,
            // label: <Label label={ isIliaApp(offerType) ? "Personal Notes" : "Accounts Payable" } required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: !(isLongForm(offerType) || isWorksheet(offerType))
        },
        {
            id: "otherLongTermDebt",
            name: "otherLongTermDebt",
            helperFieldText: "",
            label: <Label label={"Other Long Term Debt"} required={false} />,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: (isLongForm(offerType) || isWorksheet(offerType)),
        },
        {
            id: "otherLiabilities",
            name: "otherLiabilities",
            helperFieldText: "",
            label: <Label label={ (isLongForm(offerType) || isWorksheet(offerType)) ? "Other Liabilities (Provide Details)" : "Other Liabilities Amount" } required={false}/>,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "otherLiabilitiesDetails",
            name: "otherLiabilitiesDetails",
            helperFieldText: "",
            label: <Label label={"Other Liabilities Details"} required={false} />,
            isRequired: false,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 12,
                xl: 12,
            },
            disabled: clientSigned ? true : false,
            show: !(isLongForm(offerType) || isWorksheet(offerType))
         }
    ];

    /*Set initial values*/
    const initialValues = {};
    /* Validations of fields set */
    let keysValidation = {};

    fieldObj.forEach((field) => {
        initialValues[field.name] = user && user.client && user.client[field.name] || "";
    });

    const validationSchema = Yup.object().shape(keysValidation);


    return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <LiabilitiesForm
                            autoSaveTrigger={autoSaveTrigger}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            updateClientFields={updateClientFields}
                            isConfirmCheckbox={isConfirmCheckbox}
                            user={user}
                            isFromClientDetail={isFromClientDetail}
                            //setIsError={setIsError}
                            fieldObj={fieldObj}
                            setIsConfirmCheckbox={setIsConfirmCheckbox}
                            liabilitiesVerifyRef={liabilitiesVerifyRef}
                            financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                            setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                            liabilitiesRef={liabilitiesRef}
                            setSaveFormContent={setSaveFormContent}
                            formType={formType}
                            setFormType={setFormType}
                        />
                </Grid>
            </Grid>
    );
};

export default Liabilities;