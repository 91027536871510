import numeral from "numeral";
import { NOTA as _NOTA } from "../../../../../constants";

export const NOTA = _NOTA;
export const convertToNumber = (val: String): number | null => {
	return (val ? numeral(val).value() : null);
};

/**
 * Convert Numeric To Radio Value Yes | No | Null
 * @param val
 */
export const convertToRadioValue = (val: number | null): string | null => {
	let newVal: string | null = null;
	switch (val) {
		case 0:
			newVal = "No";
			break;
		case 1:
			newVal = "Yes";
			break;
	}
	return newVal;
};

export const convertToRadioValueToSaving = (val: string | null): number | null => {
	let newVal: number | null = null;
	switch (val) {
		case "No":
			newVal = 0;
			break;
		case "Yes":
			newVal = 1;
			break;
	}
	return newVal;
};

const stagingAndDev = [
	{ docValue: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", value: "Insured_Aviation", label: "Aviation" },
	{ docValue: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", value: "Insured_ForeignTravel", label: "Foreign Travel" },
	{ docValue: "4b34ac90-021e-4c5f-a4b8-18bac65e282b", value: "Insured_Avocation", label: "Avocation" },
];

const additionalFieldOptionsList = {
	development: stagingAndDev,
	production: [
		{ docValue: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", value: "Insured_Aviation", label: "Aviation" },
		{ docValue: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", value: "Insured_ForeignTravel", label: "Foreign Travel" },
		{ docValue: "8dcebea6-3f5b-4e89-b4a2-674c82deadf3", value: "Insured_Avocation", label: "Avocation" },
	],
};

console.log("Working ===>", process.env);
const environment = process.env.REACT_APP_ENV === "production" ? "production" : "development";

export const checkAllApplied: any = additionalFieldOptionsList[environment];

export const avocationType: any = [{
	name: "Insured_AvocationXC50",
	label: "Automobile, Motorcycle or Motorboat Racing",
}, {
	name: "Insured_AvocationXC51",
	label: "Parachuting and Sky Diving",
}, {
	name: "Insured_AvocationXC52",
	label: "Underwater Diving",
}, {
	name: "Insured_AvocationXC53",
	label: "Other Hazardous Sports or Avocations",
}];