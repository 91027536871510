import React from 'react';
import {useSelector} from "react-redux";
import * as Actions from "../../../../actions/verify";
import {RootState} from "../../../../reducers";
import LandingPage from "../v2/LandingPage";
import TrizenLandingPage from "../v2/trizen/LandingPage";
import {useActions} from "../../../../actions";
import CommonLoader from "../../../../components/CommonLoader";

export default function LandingEntryPage(props: any) {
    const verifyActions = useActions(Actions);
    const [loading, setLoading] = React.useState(true);
    const [inviteData, setInviteData] = React.useState({});
    let verifyInvite = useSelector((state: RootState) => state.verify);
    const { user } = useSelector((state: RootState) => state.auth);
    const inviteToken: string = (props.match && props.match.params && props.match.params.inviteToken) || '';

    React.useEffect( () => {
        const getInviteData = async() => {
            if( !('accountType' in verifyInvite)  && !user ) {
                const res = await verifyActions.verifyAccount(inviteToken);
                if (res.payload.statusCode >= 400) {
                    window.location.href = "/login";
                    return false;
                }
            }
            verifyInvite = user || verifyInvite;
            if( verifyInvite ) {
                setInviteData({
                    accountType : 'INDIVIDUAL',
                    authenticType: "",
                    companyId: 0,
                    groupDetail: null,
                    verifiedInvite: verifyInvite && verifyInvite.verifiedInvite ? verifyInvite.verifiedInvite : "",
                    checked: verifyInvite && verifyInvite.checked ? verifyInvite.checked : false,
                    updated: verifyInvite && verifyInvite.updated ? verifyInvite.updated : false,
                    firstName: verifyInvite && verifyInvite.firstName ? verifyInvite.firstName : "",
                    phoneNumber: verifyInvite && verifyInvite.phoneNumber ? verifyInvite.phoneNumber : "",
                    email: verifyInvite && verifyInvite.email ? verifyInvite.email : "",
                    role: verifyInvite && verifyInvite.role ? verifyInvite.role : "",
                    strategy: verifyInvite && verifyInvite.strategy ? verifyInvite.strategy : null,
                    client: verifyInvite && verifyInvite.client ? verifyInvite.client : null,
                    agent: verifyInvite.agent || null,
                    participant: verifyInvite.participant || null,
                    carrier: (verifyInvite && verifyInvite.carrier) || null,
                    nationalLife : (verifyInvite && verifyInvite.nationalLife) || null
                });
            }

            setLoading(false);
        }
        getInviteData();
    }, [ user ]);

    if( loading ){
        return (
           <CommonLoader />
        );
    }

    let { strategy  } = (user && user.client) || verifyInvite;

    let isESDStartegy:boolean = ((strategy && strategy.slug === "esd") || (strategy && strategy.slug === "trizen")) ? true : false;

    if( isESDStartegy ){
        return (
            <TrizenLandingPage {...inviteData} {...props}  />
        );
    }


    return (
        <LandingPage {...inviteData} {...props}  />
    );
}

