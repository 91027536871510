import React, {useState} from "react";
import { useFormikContext } from "formik";
import { Button, Grid, IconButton, Link, Tooltip, Typography, withStyles } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { Theme } from "@material-ui/core/styles";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../../components/dialog";
import { Close } from "@material-ui/icons";
import { isDebug } from "../../../../../utils/commonUtils";
import { LOGO_BASE_URL} from "../../../../../constants";
import { tr } from "date-fns/locale";

export const ANNUALLY = "Annual";
export const MONTHLY = "Monthly";

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#2f2f2f",
        maxWidth: "500px",
        minWidth: "100px",
        color: "#ffccc",
        lineHeight: "18px",
        fontWeight: "normal",
        padding: "20px",
        margin: "0",
        boxShadow: "0 0 15px 0px #8c8c8c",
    },
}))(Tooltip);
export default function ContributionFrequency(props: any) {
    const { callingFrom, clientDetail} = props;
    const text: string = "Contribution Frequency";
    const { values, setFieldValue }: any = useFormikContext();
    const { user }: any = useSelector((state: RootState) => state.auth);
    const [annualShowDialog, setAnnualShowDialog] = React.useState(false);
    const [monthlyShowDialog, setMonthlyShowDialog] = React.useState(false);
    const [PaymentFrequencyPdf, setPaymentFrequencyPdf] = React.useState(false);
    const annualPdfURL = `${LOGO_BASE_URL}estimation/NIW_Annual_Disclosure_Short.pdf`;
    const monthlyPdfURL = `${LOGO_BASE_URL}estimation/NIW_Monthly_Disclosure_Short.pdf`;
    let carrierId: number;
    if (props.carrierId) {
        carrierId = props.carrierId;
    } else {
        carrierId = user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.carrier.id);
    }
    const onAnnulMonthlyChange = (val) => {
        setFieldValue("contributionFrequency", val);
        if (props.onChange) {
            props.onChange(val);
        }
        if (props.setContributionFrequency) {
            props.setContributionFrequency(val);
        }
        setAnnualShowDialog(false);
        setMonthlyShowDialog(false);
        setPaymentFrequencyPdf(false);
    };
    const handleClickPaymentFrequencyPdf = (val) => {
        setPaymentFrequencyPdf(true);
    }
    const annualDialogProps: GenericDialogProps = {
        "aria-labelledby": "paymentFrequency-dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <IconButton aria-label="close"
                                style={{ position: "absolute", top: 3, right: 0 }}
                                onClick={() => setAnnualShowDialog(false)}>
                        <Close />
                    </IconButton>
                    <Typography variant="body1" className="mt15 mb15">
                        You have selected an annual contribution frequency.
                    </Typography>
                    <Typography variant="body1" className="mb15">
                        Annual contribution can be paid via check,
                        wire, or ACH payment. The entire year's first contribution is due up front before the policy is
                        issued. In subsequent years, the entire year's contribution is due 30 days before the policy
                        anniversary date.
                    </Typography>
                    <Typography variant="body1" className="mb15 w100">
                        For more information on Monthly Contribution, please <Link variant="body2" onClick={() => {
                        handleClickPaymentFrequencyPdf(ANNUALLY);
                    }} className="cursor w100"> click here</Link>.
                    </Typography>
                    <Link variant="caption" className="mb20 floatLeft w100" onClick={() => {
                        onAnnulMonthlyChange(ANNUALLY);
                    }}> Click here to continue or change your contribution frequency.</Link>
                </>
        ),
        dividers: false,
        // onClose: () => setAnnualShowDialog(false),
        open: annualShowDialog,
        title: "",
    };
    const monthlyDialogProps: GenericDialogProps = {
        "aria-labelledby": "paymentFrequency-dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <IconButton
                            aria-label="close"
                            style={{ position: "absolute", top: 3, right: 0 }}
                            onClick={() => setMonthlyShowDialog(false)}>
                        <Close />
                    </IconButton>
                    <Typography variant="body1" className="mt15 mb15">
                        You have selected a monthly contribution frequency.
                    </Typography>
                    <Typography variant="body1" className="mb15">
                        A monthly contribution is only payable with
                        an ePay ACH payment method and will be debited on the first of every month. Your first 2 monthly
                        contributions (annual contribution divided by 2) are due up front and at least 30 days before
                        the policy is funded.
                    </Typography>
                    <Typography variant="body1" className="mb15 w100">
                        For more information on Monthly Contribution, please <Link variant="body2" onClick={() => {
                        handleClickPaymentFrequencyPdf(true);
                    }} className="cursor w100"> click here</Link>.
                    </Typography>
                    <Link variant="caption" className="mb20 floatLeft cursor w100" onClick={() => {
                        onAnnulMonthlyChange(MONTHLY);
                    }}>
                        Click here to continue or change your contribution frequency.
                    </Link>
                </>
        ),
        dividers: false,
        //onClose: () => setMonthlyShowDialog(false),
        open: monthlyShowDialog,
        title: "",
    };
    const paymentFrequencyPdfProps: GenericDialogProps = {
        "aria-labelledby": "paymentFrequency-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (
                <>
                    <IconButton aria-label="close"
                                style={{ position: "absolute", top: "-2px", right: "-7px" }}
                                onClick={() => setPaymentFrequencyPdf(false)}>
                        <Close />
                    </IconButton>
                    <iframe
                        src={monthlyShowDialog? monthlyPdfURL : annualPdfURL }
                        width="100%"
                        height="500px"
                        title="PDF Viewer"
                        style={{ border: "none" }}
                    />
                </>
        ),
        dividers: false,
        open: PaymentFrequencyPdf,
        title: "",
    };

    return (
            <>
                {carrierId === 3 &&
                        <Grid container spacing={2} justify="center" className="mb10">
                            <Grid item xs={12} md={7} className="displayF">
                                <Typography variant="caption" className="displayF">{props.text ? props.text : text}
                                    <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography className="f16 txtWhite mb10">
                                                        Annual: the entire year’s contribution will be due up front
                                                        before the
                                                        policy
                                                        is issued. In subsequent years, the entire year’s
                                                        contribution is due 30
                                                        days
                                                        before the policy anniversary date.
                                                    </Typography>
                                                    <Typography className="f16 txtWhite mb0">
                                                        Monthly: Contributions are divided by 12 and due each
                                                        month. <strong><u>Two
                                                        months are due up front before the policy is
                                                        issued.</u></strong> After
                                                        that,
                                                        each month has an equal payment. Monthly contributions must
                                                        be made by
                                                        electronic ACH on a recurring schedule through ILIA's
                                                        payment system.
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                    >
                                        <Info className="f30 icon mr15" color="primary" />
                                    </HtmlTooltip>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <ToggleButtonGroup
                                        className="toggleButtonGroup floatRight"
                                        size="medium"
                                        color={"primary"}
                                        exclusive
                                        id={"contributionFrequency"}
                                        value={values["contributionFrequency"]}
                                        onChange={(_e: any, val: string) => {
                                            isDebug() && console.info(_e, val);
                                            if (val) {
                                                //https://v4.mui.com/api/toggle-button-group/#togglebuttongroup-api val for selected value null
                                                values["contributionFrequency"] === ANNUALLY && setMonthlyShowDialog(true);
                                                values["contributionFrequency"] === MONTHLY && setAnnualShowDialog(true);
                                            }
                                        }}
                                >
                                    <ToggleButton value={ANNUALLY} color={"primary"}
                                                  disabled={props.monthlyDisabled}
                                                  className={values["contributionFrequency"] === ANNUALLY ? "active" : ""}>
                                        {ANNUALLY}
                                    </ToggleButton>
                                    {callingFrom !== "QUICK_ESTIMATOR" &&
                                            <ToggleButton value={MONTHLY} disabled={props.monthlyDisabled}
                                                          className={values["contributionFrequency"] === MONTHLY ? "active" : ""}>
                                                {MONTHLY}
                                            </ToggleButton>
                                    }
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                }
                {annualShowDialog &&
                        <GenericDialog {...annualDialogProps} />
                }
                {monthlyDialogProps &&
                        <GenericDialog {...monthlyDialogProps} />
                }
                {paymentFrequencyPdfProps &&
                    <GenericDialog {...paymentFrequencyPdfProps} />
                }
            </>
    );
}