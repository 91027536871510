import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../reducers";
import {Viewer} from "../../../../../../components";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function ViewDocument(props:any) {
    const { handleClose, openDocument, fileType, documentUrl, title} = props;
    const {user} = useSelector((state: RootState) => state.auth);
    const role = user && user.role;
    const permission:string = (role === 5 || role === 6) ? "allow-scripts allow-same-origin" : "allow-scripts allow-popups allow-same-origin";
    return (
            <div>
                {/*<Dialog fullWidth maxWidth={"lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDocument}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>*/}
                <Dialog onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="customized-dialog-title" open={openDocument}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {title}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Viewer url={documentUrl} />
                    </DialogContent>
                </Dialog>
            </div>
    );
}
