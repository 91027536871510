import {ActionType, PagingParams} from "../model";
import {va} from "../utils/commonUtils";
import {setAlert} from "./alert";
import {ProfileDetail} from "../model/account";

type productType = {
	incomeRequirement: number,
	minAge: number,
	productName: string
}
export const carrierProductList = (query: PagingParams) => async (
	dispatch: Function,
	getState: Function,
	api: { get: Function }
) => {
	try {
		let resp = await api.get("carrier/products", {}, query);
		return dispatch({
			type: ActionType.GET_CARRIER_PRODUCTS_BY_ID,
			payload: resp,
		});
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const addProduct = (payload: productType, query: { id: number }) => async (
	dispatch: Function,
	getState: Function,
	api: { post: Function }
) => {
	try {
		let resp = await api.post("carrier/add_product", payload, query);		
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message : "Product added successfully",
				statusCode: 200
			}
		});
		return resp;
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const getProductById = (query: { id: number }) => async (
	dispatch: Function,
	getState: Function,
	api: { get: Function }
) => {
	try {
		let resp = await api.get("product", {}, query);
		return dispatch({
			type: ActionType.GET_PRODUCT_BY_ID,
			payload: resp,
		});
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const updateProduct = (payload: productType,query: object) => async (
	dispatch: Function,
	getState: Function,
	api: { put: Function }
) => {
	try {
		let resp = await api.put("product", payload, query);
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message : "Product updated successfully",
				statusCode: 200
			}
		});
		return resp;
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const addOfferType = (payload: productType, query: { id: number }) => async (
	dispatch: Function,
	getState: Function,
	api: { post: Function }
) => {
	try {
		let resp = await api.post("carrier/add_offer_type", payload, query);		
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message : "Offer Type added successfully",
				statusCode: 200
			}
		});
		return resp;
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const carrierOfferTypeList = (query: PagingParams) => async (
	dispatch: Function,
	getState: Function,
	api: { get: Function }
) => {
	try {
		let resp = await api.get("carrier/offer_type", {}, query);
		return dispatch({
			type: ActionType.GET_CARRIER_OFFERTYPE_BY_ID,
			payload: resp,
		});
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const getOfferTypeById = (query: { id: number }) => async (
	dispatch: Function,
	getState: Function,
	api: { get: Function }
) => {
	try {
		let resp = await api.get("offer_type", {}, query);
		return dispatch({
			type: ActionType.GET_OFFERTYPE_BY_ID,
			payload: resp,
		});
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const updateOffferType = (payload: productType,query: object) => async (
	dispatch: Function,
	getState: Function,
	api: { put: Function }
) => {
	try {
		let resp = await api.put("offer_type", payload, query);
		dispatch({
			type: ActionType.ALERT,
			payload: {
				message : "Offer Type updated successfully",
				statusCode: 200
			}
		});
		return resp;
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const getCarrierStrategies = (carrierIds: number[], reBrand:number) => async (
	dispatch: Function,
	getState: Function,
	api: any
) => {
	try {
		return await api.post("carrier/strategies", {carrierIds, reBrand});
	} catch (err) {
		return dispatch({
			type: ActionType.ALERT,
			payload: err,
		});
	}
};

export const getCarrierActualHealthTitle = (carrierId: number) => async (
    dispatch: Function,
    getState: Function,
    api: any) => {
    try {
        const res: any[] = await api.get(`carrier/actualHealthRatingTitle/${carrierId}`, {}, {});
        return va(res) && res.filter(hr => hr.title !== 'Other');
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err,
        });
    }
}

export const getCaseProcessorList = (accountType: string) => async (
    dispatch: Function,
    getState: Function,
    api: any) => {
    try {
        return await api.get(`account/user/type`, {}, {});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err,
        });
    }
}

/**
 * Add new carrier
 * @param data
 */
export const addCarrier = (data: any) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
        let payload: any = Object.assign({}, {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            carrier: {
                emailForDocs: data.carrier.emailForDocs,
                emailForDocs2: data.carrier.emailForDocs2,
                carrierName: data.carrier.carrierName,
                carrierStreet: data.carrier.carrierStreet,
                carrierCity: data.carrier.carrierCity,
                carrierState: data.carrier.carrierState,
                carrierZipCode: data.carrier.carrierZipCode,
                carrierEmail: data.carrier.carrierEmail,
                carrierPhone: data.carrier.carrierPhone,
                carrierProductName: data.carrier.carrierProductName,
                onBoard: data.carrier.onBoard,
                onBoardDate: data.carrier.onBoardDate,
                growthRate: data.carrier.growthRate,
            },
            carrierStrategies: data.carrier.carrierStrategies
        });
        await api.post('carrier', payload, {});
        dispatch(setAlert('Account added successfully.', 'success'));
        return true;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

/**
 * Update Carrier Account
 * @param data
 * @param userId
 */
export const updateCarrier = (data: any, userId: number) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
        let carrierStrategies: object[] = [];
        data.carrier.carrierStrategies && data.carrier.carrierStrategies.forEach((v: any) => {
            carrierStrategies.push({
                id: v.carrierStrategyId || null,
                carrierId: data.carrier.id,
                strategyId: v.id,
                status: true
            });
        });
        let payload: any = Object.assign({}, {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            carrier: {
                id: data.carrier.id,
                emailForDocs: data.carrier.emailForDocs,
                emailForDocs2: data.carrier.emailForDocs2,
                carrierName: data.carrier.carrierName,
                carrierStreet: data.carrier.carrierStreet,
                carrierCity: data.carrier.carrierCity,
                carrierState: data.carrier.carrierState,
                carrierZipCode: data.carrier.carrierZipCode,
                carrierEmail: data.carrier.carrierEmail,
                carrierPhone: data.carrier.carrierPhone,
                carrierProductName: data.carrier.carrierProductName,
                growthRate: data.carrier.growthRate,
                carrierStrategies,
                deleteCarrierStrategies: data.carrier.deleteCarrierStrategies,
                onBoard: data.carrier.onBoard,
                onBoardDate: data.carrier.onBoardDate
            }
        });
        await api.put('carrier/' + userId, payload, {});
        dispatch(setAlert('Account updated successfully.', 'success'));
        return true;
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        return false;
    }
};

/**
 * Get Carrier By User Id
 */
export const getCarrierByUserId = () => async (dispatch: Function, _getState: Function, api: any) => {
    try {
        return await api.get(`carriers`, {}, {});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

/**
 * Get All Carriers
 */
export const getAllCarriers = () => async (dispatch: Function, _getState: Function, api: any) => {
    try {
        const resp = await api.get('carrier', {}, {});
        return dispatch({
            type: ActionType.GET_ALL_CARRIER,
            payload: resp
        });

    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

/**
 * Update Carrier User Detail (Profile)
 * @param data
 */
export const updateCarrierUserDetail = (data: ProfileDetail) => async (dispatch: Function, _getState: Function, api: { put: Function }) => {
    try {
        let payload: any = data;
        await api.put('carrier', payload, {});
        dispatch(setAlert('Profile Updated Successfully.', 'success'));
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};