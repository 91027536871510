import React from "react";
import { useSelector } from "react-redux";
import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoIcon from "@material-ui/icons/Info";
import { ApproveDialog, ApprovedTabsEnum, RejectDialog } from "./ApprovedAgentsPage";
import { ApprovedDetails } from "./index";
import { SearchTextField } from "../agent/ClientPage";
import { NiwSelect } from "../imo/ImoTab";
import { Loader, TableView } from "../../components/Common";
import { RootState } from "../../reducers";
import { useActions } from "../../actions";
import * as ApprovedAgentActions from "../../actions/approvedAgents";
import { ImoClass, imoRegionDropDown, IrrStatus } from "../../constants";
import { joinAsFullName, maskPhoneNumber, sleep } from "../../utils/commonUtils";
import { asDbDateTimeFormat } from "../../utils/dateUtils";
import { updatePendingCounts } from "../../actions/notification";
import * as AccountActions from "../../actions/account";

interface AGTabProps {
    onAction: Function;
    status: number;
    search: string;
    setSearch: Function;
    region: string;
    setRegion: Function;
}

type OPN = { icon?: any; id: number; name: string; }

const AATable: React.FC<AGTabProps> = (props) => {
    const { onAction, status, region, search, setRegion, setSearch } = props;
    const noOption = {id: 0, name: 'Select option'};
    const showRejectionReason = status === ApprovedTabsEnum.Deleted;
    const [loading, setLoading] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const [selectedAction, setSelectedAction] = React.useState<any>(noOption.name);
    const [isApprove, setIsApprove] = React.useState<boolean>(false);
    const [isReject, setIsReject] = React.useState<boolean>(false);

    const approvedDetail = useSelector((state: RootState) => state.approvedDetail);
    const registrationRequestList = useSelector((state: RootState) => state.approvedList);
    const { user } = useSelector((state: RootState) => state.auth);
    const approvedAgentActions = useActions(ApprovedAgentActions);
    const accountActions = useActions(AccountActions);

    const loadApprovedList = async () => {
        setLoading(true);
        await approvedAgentActions.getAgentRegistrationRequests(status, search, region);
        setLoading(false);
    };
    const checkIfIMOApproved = (request: any) => {
        return request.agent ? request.agent.agentImos.map(x => x.imo.imoClass)
                .filter(imoClass => imoClass === ImoClass.APPROVED || imoClass === ImoClass.PREFERRED)
                .length > 0 : false;
    }
    const refreshDetails = async (id: number) => {
        setRefreshing(true);
        await approvedAgentActions.getDetails(id);
        setRefreshing(false);
    };
    const getHeaders = () => {
        const _headers = [
            'Agent Name',
            'Email',
            'Work Phone',
            'IMO',
        ];
        if (status !== ApprovedTabsEnum.Pending) {
            _headers.push(showRejectionReason ? 'Deleted By' : 'Approved By');
        }
        _headers.push('Region');
        _headers.push('Date Received');
        if (showRejectionReason) {
            _headers.push('Deleted On');
            _headers.push('Deletion Reason');
        }
        if (status === ApprovedTabsEnum.Pending) {
            _headers.push('Actions');
        }
        return _headers;
    };
    const updateNotificationAlerts = async (payload: any) => {
        let pendingCounts = await updatePendingCounts(0, payload); // Update All action Item 0
        if (pendingCounts) {
            await accountActions.getFreshDataToRefreshReduxState();
        }
    };
    const onApproveAgent = async (id: number) => {
        setLoading(true);
        await approvedAgentActions.approveAgent(id);
        await refreshList();
        let agentApproval = (user && user.agentApproval) || 0;
        if (agentApproval > 0) {
            updateNotificationAlerts({ agentApproval: 0 });
        }
        return onClose();
    };
    const onRejectAgent = async (values: any, id: number, message: string = '') => {
        setLoading(true);
        await approvedAgentActions.rejectAgent(id, values.reason, message);
        await refreshList();
        let agentApproval = (user && user.agentApproval) || 0;
        if (agentApproval > 0) {
            updateNotificationAlerts({ agentApproval: 0 });
        }
        return onClose();
    };
    const onDirectRejectAgentViaIMOReject = async (values: {reason: string}) => {
        if (!values.reason || !approvedDetail.id) {
            return;
        }
        await sleep();
        await onRejectAgent(values, approvedDetail.id, "IMO & Agent deleted successfully.");
        return onClose();
    };
    const onClose = async () => {
        setIsApprove(false);
        setIsReject(false);
        setSelectedAction(noOption.name);
        await approvedAgentActions.flushApprovedDetails();
        setLoading(false);
    };
    const refreshList = async () => {
        await loadApprovedList();
        onAction();
    }
    const imoRegions = [
        {
            id: 0,
            value: 'all',
            name: 'All'
        },
        ...imoRegionDropDown
    ];
    const headers = getHeaders();
    const renderApproveList = (request: any) => {
        const isRejected = request.status === IrrStatus.REJECTED;
        const canBeApprove = !isRejected && checkIfIMOApproved(request);
        const getOptns = () => {
            const approveOption = {
                icon: () => <CheckCircleIcon className="textHighLight floatLeft mr10" />,
                id: 1,
                name: 'Approve',
            };
            let optns: OPN[] = [];
            switch (status) {
                case ApprovedTabsEnum.Pending:
                    optns = [
                        noOption,
                        canBeApprove ? approveOption : {} as OPN,
                        {icon: () => <HighlightOffIcon className="colorRed floatLeft mr10" />, id: 2, name: 'Delete'},
                    ];
                    break;
            }

            return optns;
        }
        const options = getOptns();
        const handleSelect = async (e: any, id: number) => {
            const selectedOpt = e.target.value;
            setSelectedAction(selectedOpt);

            if (selectedOpt === 'Approve') {
                setIsApprove(true);
            }
            if (selectedOpt === 'Delete') {
                setIsReject(true);
            }

            await refreshDetails(id);
        };
        const approvedListItem = {
            name: (
                    <>
                        {
                                !isRejected &&
                                <Link onClick={() => refreshDetails(request.id)}>{request.agentName}</Link>
                        }
                        {
                                isRejected && request.agentName
                        }
                        {
                                !canBeApprove && status === ApprovedTabsEnum.Pending &&
                                <Tooltip title="Agent must be tied to at least one approved or preferred IMO to approve agent.">
                                    <IconButton aria-label="info">
                                        <InfoIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                        }
                    </>
            ),
            email: request.agentEmail,
            workPhone: maskPhoneNumber(request.agentPhone),
            imo: request.imoName,
        };

        if (status !== ApprovedTabsEnum.Pending) {
            Object.assign(approvedListItem, {
                actionBy: request.actionUser && joinAsFullName(request.actionUser),
            });
        }

        Object.assign(approvedListItem, {
            region: request.region,
            dateReceived: asDbDateTimeFormat(request.createdAt),
        });

        if (status === ApprovedTabsEnum.Deleted) {
            Object.assign(approvedListItem, {dateDeletedAt: request.deletedAt ? asDbDateTimeFormat(request.deletedAt) : ''})
        }

        if (showRejectionReason) {
            Object.assign(approvedListItem, {rejectionReason: request.rejectionReason})
        }

        if (status === ApprovedTabsEnum.Pending && options.length > 0) {
            Object.assign(approvedListItem, {
                action: (
                        <NiwSelect
                                onChange={e => handleSelect(e, request.id)}
                                options={options}
                                selectedOption={selectedAction}
                        />
                )
            })
        }

        return approvedListItem;
    };
    const gridProps = {
        headers: headers,
        list: registrationRequestList.map(renderApproveList)
    };

    React.useEffect(() => {
        loadApprovedList()
                .then();

        return () => approvedAgentActions.flushApprovedList();
    }, [search, region]);

    return (
            <>
                {
                        loading && <Loader />
                }
                <Grid container spacing={3} justify="space-between">
                    <Grid item xs={12} md={12} lg={6}>
                        <Typography variant="body1">
                            This is a list of all new leads that have entered the system. These
                            leads must be assigned to an agent before they can be invited to myilia.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <FormControl variant="outlined" fullWidth={true}>
                            <InputLabel htmlFor="imo-type" shrink>Region</InputLabel>
                            <Select className="muiSelect" value={region} onChange={e => setRegion(e.target.value as string)} fullWidth>
                                {
                                    imoRegions.map((o) =>
                                            <MenuItem key={o.id} value={o.value}>
                                                {o.name}
                                            </MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <SearchTextField search={search} label={'Search Agents and IMO'} onChange={(e) => setSearch(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TableView {...gridProps} />
                    </Grid>
                    <Grid item xs={12}>
                        {
                                isApprove &&
                                approvedDetail &&
                                <ApproveDialog
                                        description="Are you sure you want to approve this agent? Have you verified they work with the IMO's they have requested during registration?"
                                        handleClose={onClose}
                                        onApprove={() => onApproveAgent(approvedDetail.id)}
                                        open
                                        title={`Approve ${joinAsFullName(approvedDetail.agent && approvedDetail.agent.user)}`}
                                />
                        }
                        {
                                isReject &&
                                approvedDetail &&
                                <RejectDialog
                                        description="Let this agent know why they were deleted. They will receive an email with this reason."
                                        handleClose={onClose}
                                        id={approvedDetail.id}
                                        onReject={onRejectAgent}
                                        open
                                        title={`Delete ${joinAsFullName(approvedDetail.agent && approvedDetail.agent.user)}`}
                                        refreshing={refreshing}
                                />
                        }
                        {
                                !isApprove && !isReject && (!!approvedDetail && approvedDetail.id) &&
                                <ApprovedDetails
                                        checkIfIMOApproved={checkIfIMOApproved}
                                        handleClose={onClose}
                                        onApproveAgent={() => setIsApprove(true)}
                                        onRejectAgent={() => setIsReject(true)}
                                        onDirectRejectAgent={onDirectRejectAgentViaIMOReject}
                                        refresh={refreshList}
                                        status={status}
                                />
                        }
                    </Grid>
                </Grid>
            </>
    );
};

export default AATable;
