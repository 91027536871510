import React from 'react';
import { InputLabel, MenuItem, FormControl, Select, FormHelperText } from "@material-ui/core";
import { FormInputProps } from '../../model';

interface SelectFieldProps extends FormInputProps {
	options: [],
	handleClick: any
}

export default function SelectField({
										required,
										fullWidth,
										disabled,
										label,
										options,
										meta: { touched, error },
										handleClick,
										input,
										...custom

} : SelectFieldProps) {
	interface oProps {
		id: string | number,
		value: string | number,
		name: any
	}

	const combined = Object.assign({}, input, custom);
	const val = combined.value || combined.defaultValue ;
	const handleChange = (e: any) => {
		if (handleClick) {
			handleClick(e.target.value);
		}
		input.onChange(e.target.value);
	};
	return (
			<FormControl
                variant="outlined"
				required={required}
				fullWidth={fullWidth}
				disabled={disabled}
				error={touched && (error ? true : false)}
			>
                <InputLabel id={input.name}>{label}</InputLabel>
                <Select
                        labelId={input.name}
                        {...input}
                        {...custom}
                        value={val}
                        onChange={handleChange}
                        label={label}
                        inputProps={{
                            name: 'select',
                            id: 'name-error',
                        }}
                >
                    {
                        options.length > 0 ?
                        options.length && options.map((o: oProps, key:Number) =>
                        <MenuItem key={`${key}-${o.id}-${o.name}`} value={o.value} disabled={o.id === -1 ? true : false}>
                            {o.name}
                        </MenuItem>):<MenuItem key={0} value={0}></MenuItem>
                    }
                </Select>
                {
                    (touched && error) &&
                    <FormHelperText>{touched && error}</FormHelperText>
                }
			</FormControl>
	);
}
