import React from 'react';
import {Formik} from "formik";
import { payloadDataHandler } from "../index";
import SourceFundForm from "../../../../../../../components/enrollment/SourceFundForm";

type PropsType = {
    autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void;
    initialValues: any,
    validationSchema: any,
    updateClientFields: Function,
    user: any,
    disableSaveButton: Function,
    isFromClientDetail: boolean,
    errorInForm: boolean,
    setErrorInForm: Function,
    checkBoxObj: any,
    questionList: any,
    enableSaveButton: Function,
    showComponent: Function,
    clientSigned: boolean,
    sourceOfFundRef: any,
    //setIsError: Function,
    handleChangeCheckBox: Function, checkBoxObjError: boolean,
    setFinancialAndLiabilitiesArr: Function, financialAndLiabilitiesArr: any,
    sourceOfFundsRefs:any,
    isWealthTransfer?:boolean,
    sendSaveRequest?:Function
    formType:string
    setFormType:Function
    setSaveSourceOfFundsForm:Function,
    offerType: string
}
function SourceOfFundsForm(props: PropsType){
    const {
        autoSaveTrigger,
        initialValues,
        validationSchema,
        disableSaveButton,
        isFromClientDetail,
        errorInForm,
        setErrorInForm,
        checkBoxObj,
        questionList,
        enableSaveButton,
        showComponent,
        clientSigned,
        sourceOfFundRef,
        /*setIsError,*/ handleChangeCheckBox, checkBoxObjError, setFinancialAndLiabilitiesArr, financialAndLiabilitiesArr,
        sourceOfFundsRefs,
        sendSaveRequest,
        formType,
        setFormType,
        setSaveSourceOfFundsForm,
        offerType
    } = props;

    const [answerChanged, setAnswerChanged] = React.useState(false);
    React.useEffect(() => {
        if (sourceOfFundsRefs && sourceOfFundsRefs.current && !isFromClientDetail) {
            sourceOfFundsRefs.current.validateForm()
        }
    }, [answerChanged])

    return (
            <>
                <Formik
                        innerRef={sourceOfFundsRefs}
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        onSubmit={async (values, { setSubmitting }) => {
                            let payload:any = payloadDataHandler(values, "SOURCE_OF_FUNDS", false);
                            /* await updateClientFields({  // already commented code before task NIWC-1830
                                ...payload,
                                formType: "SOURCE_OF_FUNDS",
                            }, user && user.client && user.client.id || "", isFromClientDetail);*/

                            const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "SOURCE_OF_FUNDS")
                            if(index > -1) {
                                financialAndLiabilitiesArr.splice(index, 1);
                            }
                            setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
                                    ...payload
                            }]);

                            disableSaveButton();
                        }}
                >
                    {
                        ({
                             errors
                         }) => {
                            /*if (Object.keys(errors).length > 0) {
                                if (!errorInForm) {
                                    setErrorInForm(true);
                                    setTimeout(() => setIsError(true), 500);
                                }
                            } else {
                                if (errorInForm) {
                                    setErrorInForm(false);
                                    setTimeout(() => setIsError(false), 500);
                                }
                            }*/
                            return (
                                    <SourceFundForm
                                            autoSaveTrigger={autoSaveTrigger}
                                            checkBoxObj={checkBoxObj}
                                            isWealthTransfer={checkBoxObj}
                                            clientSigned={clientSigned}
                                            handleChangeCheckBox={handleChangeCheckBox}
                                            enableSaveButton={enableSaveButton}
                                            sendSaveRequest={sendSaveRequest}
                                            checkBoxObjError={checkBoxObjError}
                                            isFromClientDetail={isFromClientDetail}
                                            questionList={questionList}
                                            showComponent={showComponent}
                                            sourceOfFundRef={sourceOfFundRef}
                                            formType={formType}
                                            setFormType={setFormType}
                                            setSaveSourceOfFundsForm={setSaveSourceOfFundsForm}
                                            offerType={offerType}
                                            setAnswerChanged={setAnswerChanged}
                                            answerChanged={answerChanged}
                                        />
                                   /* <form id={"sourceOfFunds"} className="floatLeft w100 pb100" onSubmit={handleSubmit}>
                                <FocusError offset={true}/>
                                <FormGroup row className="mt30 mb30">
                                    {
                                        checkBoxObj.map((item, index) => {
                                            let wt:boolean|undefined = ((item && item.name === "wealthGiftTransfer") && isWealthTransfer);  // if it is the wealth transfer case than the value "Wealth-Gift Transfer is auto-selected and disabled NIWC-2038
                                            return (<><Grid item xs={12} sm={6} lg={6} xl={4} key={index}>
                                                <FormControlLabel
                                                        className="checkGroupMb0"
                                                        control={<Checkbox className="padding0" checked={values[`${item.name}`] ? true : false}
                                                                           disabled={clientSigned || wt ? true : false}
                                                                           onChange={(e) => {
                                                                               setFieldValue(`${item.name}`, e.target.checked)
                                                                               handleChangeCheckBox(e, values);
                                                                               enableSaveButton();
                                                                               if(typeof sendSaveRequest === 'function'){
                                                                                   sendSaveRequest(Math.random());
                                                                               }
                                                                           }}
                                                                           color={"primary"}
                                                                           value={values[`${item.name}`] ? true : false}
                                                                           name={`${item.name}`}/>}
                                                        label={`${item.label}`}
                                                />
                                            </Grid></>)}
                                        )
                                    }
                                    {checkBoxObjError && !isFromClientDetail &&
                                    <p style={{margin: 0}}>
                                        <span style={{color: "#FF1744", fontSize: 12}}> Required Field </span>
                                    </p>
                                    }
                                </FormGroup>
                                {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                                        <Grid item xs={12} sm={12} md={12} key={question.key}>
                                            {question && question.additionalRemarks &&
                                            <Grid item xs={12}>
                                                <div
                                                        className="mt30"
                                                        key={`${idx}-${question.key}-remarks`}
                                                >
                                                    {
                                                        showComponent(question, values, touched, errors, setFieldTouched,
                                                                setFieldValue, handleChange, handleBlur, clientSigned)
                                                    }

                                                </div>
                                            </Grid>
                                            }
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={9}>
                                                    <Typography component="strong" variant="body1"
                                                                className="mb0 floatLeft">{question.label}</Typography>
                                                    {question.infoTooltip &&
                                                    <Tooltip title={<Typography
                                                            className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                                        <IconButton>
                                                            <Info color="primary"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    }
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={3}>
                                                    <ToggleButtonGroup
                                                            className="toggleButtonGroupQust floatRight"
                                                            size="medium"
                                                            color={"primary"}
                                                            exclusive
                                                            id={question.name}
                                                            value={values[question.name] === "Yes" ? "Yes" : values[question.name] == undefined ? "" : "No"}
                                                            onChange={(ev: any, value: any) => {
                                                                if (value === "Yes") {
                                                                    setFieldValue(question.name, value);
                                                                }
                                                                if (value === "No") {
                                                                    setFieldValue(question.name, value);
                                                                }
                                                                enableSaveButton();
                                                                if(typeof sendSaveRequest === 'function'){
                                                                    sendSaveRequest(Math.random())
                                                                }
                                                            }}
                                                    >
                                                        <ToggleButton value={"Yes"}
                                                                      disabled={clientSigned ? true : false}
                                                                      className={values[question.name] === "Yes" ? "active" : ""}>
                                                            Yes
                                                        </ToggleButton>
                                                        <ToggleButton value={"No"}
                                                                      disabled={clientSigned ? true : false}
                                                                      className={values[question.name] === "No" ? "active" : ""}>
                                                            No
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                ))}
                                <Button
                                        variant="contained"
                                        color="primary"
                                        className="detailPageSubmitBtn"
                                        type={"submit"}
                                        ref={sourceOfFundRef}
                                        style={{
                                            display: "none",
                                        }}
                                >
                                </Button>
                            </form>*/
                            );
                        }}
                </Formik>
            </>
    )
}
export default SourceOfFundsForm;