import * as React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import { history } from "../../configureStore";

function BankNavigation() {
	return (
		<React.Fragment>
			<List className="mt20 mb20">
				<ListItem className="active" button onClick={() => history.push("/action-items")}>
					<ListItemIcon>
						<ListIcon/>
					</ListItemIcon>
					<ListItemText primary="Action Items"/>
				</ListItem>
			</List>
		</React.Fragment>
	);
}

export default BankNavigation;