export const ContributionAmount = {
    "id": "54e00170-1c68-aca2-ee76-7fdefac9611c",
    "webhook": "https://d471-2600-100e-b155-77a4-ca0-fcb5-bf9-45ac.ngrok.io",
    "run": {
        "stress": true,
        "stresssolvefor": "Contribution_Amount",
        "illustration": true,
        "comparison": true,
        "reports": false,
        "deathbenefitonly": true
    },
    "common": {
        "monthlypayclientextra": 0.05,
        "paymentfrequency": "Annual",
        "verbose": false,
        "productcode": "941",
        "fullname": "John Doe",
        "agent": {
            "fullname": "Rao Garuda",
            "phoneareacode": "800",
            "phonedialnumber": "2949940",
            "addressline1": "5830 Granite Pkwy",
            "addressline2": "Suite 700",
            "addresscity": "Plano",
            "addressstate": "TX",
            "zip": "75024"
        },
        "reportsalessummaryage": 90,
        "reportlowpointduration": 18,
        "incomeagefrom": 65,
        "incomeageto": 90,
        "trustfee": 1350,
        "pensionunderwriting": "Multi-Life",
        "issueage": 37,
        "gender": "Male",
        "rateclass": "Elite_NT",
        "dateofbirth": "04/11/1985",
        "funddateilia": "07/13/2022",
        "tranchenumber": "47",
        "carrier": "NLG",
        "blendpercentage": [
            {
                "agefrom": 18,
                "ageto": 35,
                "blend": 0.1
            },
            {
                "agefrom": 36,
                "ageto": 39,
                "blend": 0.15
            },
            {
                "agefrom": 40,
                "ageto": 44,
                "blend": 0.2
            },
            {
                "agefrom": 45,
                "ageto": 49,
                "blend": 0.3
            },
            {
                "agefrom": 50,
                "ageto": 54,
                "blend": 0.4
            },
            {
                "agefrom": 55,
                "ageto": 100,
                "blend": 0.5
            }
        ],
        "illustrationissuestate": "NV",
        "contributionamount": 0,
        "totaldeathbenefit": 1050000,
        "distributionloanrate": 5.40,
        "growthrate": 0.0592,
        "incometaxrate": 0.37,
        "capitalgainstaxrate": 0.20,
        "statetaxrate": 0.055,
        "afrrate": 0.0335,
        "managementfee": 0.005,
        "arrangementfee": 500
    },
    "stress": {
        "mecavoidance": "Adjust_Premiums",
        "deathbenefittype": "Specify_Amount",
        "deathbenefitoption": "B_Increasing",
        "premiummode": "Annual",
        "premium": [
            {
                "type": "Guideline_Level_Premium",
                "from": "1",
                "through": "10"
            }
        ],
        "balancesheetbenefit": "Yes",
        "balancesheetbenefitpercentage": 100,
        "interestrates": [
            {
                "creditsuisseallocation": 50,
                "creditsuisserate": 0,
                "uspacesetterallocation": 50,
                "uspacesetterrate": 0,
                "from": "1",
                "through": "5"
            },
            {
                "creditsuisseallocation": 50,
                "creditsuisserate": 5.92,
                "uspacesetterallocation": 50,
                "uspacesetterrate": 5.92,
                "from": "6",
                "through": "7"
            },
            {
                "creditsuisseallocation": 50,
                "creditsuisserate": 0,
                "uspacesetterallocation": 50,
                "uspacesetterrate": 0,
                "from": "8",
                "through": "8"
            },
            {
                "creditsuisseallocation": 50,
                "creditsuisserate": 5.92,
                "uspacesetterallocation": 50,
                "uspacesetterrate": 5.92,
                "from": "9",
                "through": "9"
            },
            {
                "creditsuisseallocation": 50,
                "creditsuisserate": 0,
                "uspacesetterallocation": 50,
                "uspacesetterrate": 0,
                "from": "10",
                "through": "12"
            },
            {
                "creditsuisseallocation": 50,
                "creditsuisserate": 5.92,
                "uspacesetterallocation": 50,
                "uspacesetterrate": 5.92,
                "from": "13",
                "through": "M"
            }
        ],
        "loanassumptions": {
            "discountcashvaluelowpoint": 0.88,
            "bankdiscountfirstyear": 0.9,
            "assumptions": [0.04, 0.04, 0.04, 0.04, 0.04, -0.0236, -0.0236, 0.04, -0.0236, 0.04, 0.04, 0.04, -0.0236, -0.0236, -0.0236, -0.0236, 0.0964, 0.0964, 0.0964, -0.0036, 0.05]
        }
    },
    "illustration": {
        "mecavoidance": "Adjust_Premiums",
        "deathbenefittype": "Specify_Amount",
        "deathbenefit": [
            {
                "faceamount": 892500,
                "additionalprotection": 157500,
                "from": "1",
                "through": "10"
            },
            {
                "faceamount": 100000,
                "additionalprotection": 0,
                "from": "11",
                "through": "M"
            }
        ],
        "deathbenefitchangeyear": "15",
        "deathbenefitoption": "B_Increasing",
        "premium": [
            {
                "type": "Guideline_Level_Premium",
                "from": "1",
                "through": "10"
            }
        ],
        "premiummode": "Annual",
        "distributionmode": "Annual",
        "distribution": [
            {
                "type": "Specify_Amount",
                "amount": 0,
                "adjustment": "0",
                "from": "15",
                "through": "15"
            },
            {
                "type": "Solve_for_Income",
                "from": "A65",
                "through": "A90"
            }
        ],
        "balancesheetbenefit": "Yes",
        "balancesheetbenefitpercentage": 100,

        "interestrates": [
            {
                "creditsuisseallocation": 50,
                "uspacesetterallocation": 50,
                "from": "1",
                "through": "M"
            }
        ],
        "loanassumptions": {
            "discountcashvaluelowpoint": 0.85,
            "bankdiscountfirstyear": 0.9,
            "assumptions": [0.04, 0.0468, 0.0446, 0.0431, 0.0424, 0.0420, 0.0419, 0.0418, 0.0418, 0.0418, 0.0417, 0.0417, 0.0417, 0.0417, 0.0417, 0.045]
        }
    },
   /* "reports": {
        "existingrun": {
            "stress": {
                "totaldeathbenefit": 1075000,
                "contributionamount": 25000
            },
            "reports": {
                "internal": "s3://devstack-constructapinationallifes3forprocessnlst-1dxukenp9ghad/723f3e9b-0c5a-80b7-ec8e-76e645ad22eb/a5a1e116-c051-4927-9029-d62f6bf39e6f_InternalReport_NLGIllustrationDocument_2022-07-11T16:25:03.442Z.pdf",
                "client": "s3://devstack-constructapinationallifes3forprocessnlst-1dxukenp9ghad/723f3e9b-0c5a-80b7-ec8e-76e645ad22eb/b4751812-a0fd-434b-ba0c-0154e0281aeb_ClientReport_NLGIllustrationDocument_2022-07-11T16:25:03.359Z.pdf",
                "lowpoint": "s3://devstack-constructapinationallifes3forprocessnlst-1dxukenp9ghad/723f3e9b-0c5a-80b7-ec8e-76e645ad22eb/68ecf8da-1d48-4d90-84a7-89ad8d60aba7_LowpointReport_NLGIllustrationDocument_2022-07-11T16:25:04.041Z.pdf"
            },
            "comparison": {
                "faceamount": 500000,
                "income": 47000,
                "deathbenefitendincome": 243782,
                "totalincome": 1222000
            },
            "illustration": {
                "income": 99000,
                "guaranteedsurrendervalue": 913750,
                "nlgrequest": {
                    "Transaction": {
                        "TransactionID": "b2fdc70d-0cd9-4e2b-ac23-1c6e982d3432",
                        "TransactionDateTime": "07/11/2022",
                        "Product": {
                            "Code": "941"
                        },
                        "TrasType": {
                            "Report": {
                                "IllustratePDF": false
                            }
                        },
                        "Agent": {
                            "FullName": "agentsachin agentsachin",
                            "Address": {
                                "Line1": "5830 Granite Pkwy",
                                "Line2": "Suite 700",
                                "City": "Plano",
                                "State": "TX",
                                "Zip": "75024"
                            },
                            "Phone": {
                                "AreaCode": "800",
                                "DialNumber": "2949940"
                            }
                        },
                        "Insured": {
                            "PrimaryInsured": {
                                "FullName": "sachinjuly4_1 sachinjuly4_1",
                                "Gender": "Female",
                                "IssueAge": 37,
                                "Risk": {
                                    "RateClass": "Elite_NT",
                                    "PensionUnderwriting": "Multi-Life"
                                }
                            }
                        },
                        "IllustrationFor": {
                            "IssueState": {
                                "Code": "NV"
                            }
                        },
                        "DeathBenefitAndFunding": {
                            "Solve": {
                                "Target": "Net_Surrender_Value",
                                "TargetAmount": 1,
                                "TargetYear": "M"
                            },
                            "DeathBenefit": {
                                "FaceSolve": {
                                    "SolveType": "Specify_Amount",
                                    "SolveDetails": [
                                        {
                                            "Amount": 913750,
                                            "AdditionalProtectionBenefit": 161250,
                                            "From": "1",
                                            "Through": "10"
                                        },
                                        {
                                            "Amount": 250000,
                                            "AdditionalProtectionBenefit": 0,
                                            "From": "11",
                                            "Through": "M"
                                        }
                                    ]
                                },
                                "DeathBenefitOption": {
                                    "InitialType": "B_Increasing",
                                    "ChangeYear": "15"
                                }
                            },
                            "TaxAndCompliance": {
                                "MECAvoidance": "Adjust_Premiums"
                            },
                            "Distribution": {
                                "DistributionMode": "Annual",
                                "DistributionDetails": [
                                    {
                                        "Type": "Specify_Amount",
                                        "Amount": 493000,
                                        "Adjustment": "0",
                                        "From": "15",
                                        "Through": "15"
                                    },
                                    {
                                        "Type": "Specify_Amount",
                                        "From": "A65",
                                        "Through": "A90",
                                        "Amount": 99000
                                    }
                                ],
                                "Loan": {
                                    "Type": "Participating_Variable_Loans",
                                    "Rate": 5
                                }
                            },
                            "Premium": {
                                "PremiumMode": "Annual",
                                "PremiumSolve": {
                                    "SolveDetails": [
                                        {
                                            "Type": "Guideline_Level_Premium",
                                            "From": "1",
                                            "Through": "10"
                                        }
                                    ]
                                }
                            }
                        },
                        "Riders": {
                            "BalanceSheetBenefit": {
                                "Opted": "Yes",
                                "BSBPercentage": 100
                            }
                        },
                        "InterestRates": {
                            "PremiumAllocations": {
                                "Strategies": [
                                    {
                                        "Allocations": [
                                            {
                                                "Name": "BalancedTrendIndexPointToPointNoCap",
                                                "AllocationPct": 50
                                            },
                                            {
                                                "Name": "USPacesetterIndexPointtoPointNoCap",
                                                "AllocationPct": 50
                                            }
                                        ],
                                        "From": "1",
                                        "Through": "M"
                                    }
                                ]
                            }
                        }
                    }
                },
                "loanamount": 493000,
                "targetpremium": 12390.45,
                "apiresultmodel": {
                    "Current": {
                        "PolicyValues": {
                            "header": [
                                "Initial Face Amount",
                                "Lapse Year",
                                "MEC Year",
                                "Modal Premium",
                                "Minimum Premium (MMP)",
                                "Death Benefit Protection Premium (MGP)",
                                "Target Premium",
                                "MEC Premium",
                                "Guideline Level Premium",
                                "Guideline Single Premium"
                            ],
                            "rows": [
                                [
                                    913750,
                                    0,
                                    0,
                                    45726,
                                    6147.24,
                                    6011.9,
                                    12390.45,
                                    66896,
                                    45726,
                                    267850
                                ]
                            ]
                        },
                        "PolicyArrays": {
                            "header": [
                                "Policy Year",
                                "Age",
                                "Planned Premium",
                                "Loan",
                                "Annual Income",
                                "Accumulated Value",
                                "Surrender Value",
                                "Net Death Benefit"
                            ],
                            "rows": [
                                [
                                    1,
                                    37,
                                    45726,
                                    0,
                                    0,
                                    40245.96,
                                    40245.96,
                                    1115245.96
                                ],
                                [
                                    2,
                                    38,
                                    45726,
                                    0,
                                    0,
                                    83422.53,
                                    83422.53,
                                    1158422.53
                                ],
                                [
                                    3,
                                    39,
                                    45726,
                                    0,
                                    0,
                                    129278.16,
                                    129278.16,
                                    1204278.16
                                ],
                                [
                                    4,
                                    40,
                                    45726,
                                    0,
                                    0,
                                    177969.07,
                                    177969.07,
                                    1252969.07
                                ],
                                [
                                    5,
                                    41,
                                    45726,
                                    0,
                                    0,
                                    229638.25,
                                    229638.25,
                                    1304638.25
                                ],
                                [
                                    6,
                                    42,
                                    45726,
                                    0,
                                    0,
                                    284354.81,
                                    284354.81,
                                    1359354.81
                                ],
                                [
                                    7,
                                    43,
                                    45726,
                                    0,
                                    0,
                                    342400.03,
                                    342400.03,
                                    1417400.03
                                ],
                                [
                                    8,
                                    44,
                                    45726,
                                    0,
                                    0,
                                    403984.63,
                                    403984.63,
                                    1478984.63
                                ],
                                [
                                    9,
                                    45,
                                    45726,
                                    0,
                                    0,
                                    469303.6,
                                    469303.6,
                                    1544303.6
                                ],
                                [
                                    10,
                                    46,
                                    45726,
                                    0,
                                    0,
                                    538578.04,
                                    538578.04,
                                    1613578.04
                                ],
                                [
                                    11,
                                    47,
                                    0,
                                    0,
                                    0,
                                    571259.54,
                                    571259.54,
                                    1159656.87
                                ],
                                [
                                    12,
                                    48,
                                    0,
                                    0,
                                    0,
                                    606128.51,
                                    606128.51,
                                    1194073.16
                                ],
                                [
                                    13,
                                    49,
                                    0,
                                    0,
                                    0,
                                    643351.29,
                                    643351.29,
                                    1228800.96
                                ],
                                [
                                    14,
                                    50,
                                    0,
                                    0,
                                    0,
                                    683092.29,
                                    683092.29,
                                    1263720.73
                                ],
                                [
                                    15,
                                    51,
                                    0,
                                    493000,
                                    493000,
                                    724865.43,
                                    207215.44,
                                    772610.48
                                ],
                                [
                                    16,
                                    52,
                                    0,
                                    24650,
                                    0,
                                    773443.22,
                                    229910.74,
                                    779055.43
                                ],
                                [
                                    17,
                                    53,
                                    0,
                                    25882.49,
                                    0,
                                    825326.17,
                                    254617.06,
                                    782825.81
                                ],
                                [
                                    18,
                                    54,
                                    0,
                                    27176.63,
                                    0,
                                    880763.37,
                                    281518.82,
                                    783553.95
                                ],
                                [
                                    19,
                                    55,
                                    0,
                                    28535.44,
                                    0,
                                    940012.75,
                                    310805.96,
                                    780812.33
                                ],
                                [
                                    20,
                                    56,
                                    0,
                                    29962.24,
                                    0,
                                    1003275.38,
                                    342608.24,
                                    804114.91
                                ],
                                [
                                    21,
                                    57,
                                    0,
                                    31460.35,
                                    0,
                                    1070846.1,
                                    377145.6,
                                    826900.97
                                ],
                                [
                                    22,
                                    58,
                                    0,
                                    33033.36,
                                    0,
                                    1143011.75,
                                    414626.22,
                                    848970.69
                                ],
                                [
                                    23,
                                    59,
                                    0,
                                    34685.03,
                                    0,
                                    1220084.39,
                                    455279.6,
                                    870108.3
                                ],
                                [
                                    24,
                                    60,
                                    0,
                                    36419.26,
                                    0,
                                    1302392.76,
                                    499347.72,
                                    890065.55
                                ],
                                [
                                    25,
                                    61,
                                    0,
                                    38240.25,
                                    0,
                                    1390189.85,
                                    546992.56,
                                    936245.71
                                ],
                                [
                                    26,
                                    62,
                                    0,
                                    40152.25,
                                    0,
                                    1483796.18,
                                    598439.02,
                                    984226.03
                                ],
                                [
                                    27,
                                    63,
                                    0,
                                    42159.87,
                                    0,
                                    1583754.24,
                                    654129.23,
                                    1034230.24
                                ],
                                [
                                    28,
                                    64,
                                    0,
                                    44267.85,
                                    0,
                                    1690508.37,
                                    714402.1,
                                    1086313.94
                                ],
                                [
                                    29,
                                    65,
                                    0,
                                    145481.26,
                                    99000,
                                    1804390.65,
                                    675529.08,
                                    1036407.21
                                ],
                                [
                                    30,
                                    66,
                                    0,
                                    152755.31,
                                    99000,
                                    1925778.49,
                                    636523.85,
                                    1002421.77
                                ],
                                [
                                    31,
                                    67,
                                    0,
                                    160393.08,
                                    99000,
                                    2055167,
                                    597499.64,
                                    967429.71
                                ],
                                [
                                    32,
                                    68,
                                    0,
                                    168412.73,
                                    99000,
                                    2193081.95,
                                    558581.22,
                                    931405.16
                                ],
                                [
                                    33,
                                    69,
                                    0,
                                    176833.37,
                                    99000,
                                    2340086.51,
                                    519910.74,
                                    894324.58
                                ],
                                [
                                    34,
                                    70,
                                    0,
                                    185675.04,
                                    99000,
                                    2496782.33,
                                    481647.75,
                                    856165.09
                                ],
                                [
                                    35,
                                    71,
                                    0,
                                    194958.79,
                                    99000,
                                    2664073.9,
                                    444232.6,
                                    790562.21
                                ],
                                [
                                    36,
                                    72,
                                    0,
                                    204706.73,
                                    99000,
                                    2842757.75,
                                    407974.36,
                                    720677.71
                                ],
                                [
                                    37,
                                    73,
                                    0,
                                    214942.07,
                                    99000,
                                    3033716.68,
                                    373244.13,
                                    646278.63
                                ],
                                [
                                    38,
                                    74,
                                    0,
                                    225689.17,
                                    99000,
                                    3237928.42,
                                    340482.23,
                                    567137.22
                                ],
                                [
                                    39,
                                    75,
                                    0,
                                    236973.63,
                                    99000,
                                    3456479.61,
                                    310211.1,
                                    483035.08
                                ],
                                [
                                    40,
                                    76,
                                    0,
                                    248822.31,
                                    99000,
                                    3689497.15,
                                    281965.23,
                                    466440.09
                                ],
                                [
                                    41,
                                    77,
                                    0,
                                    261263.43,
                                    99000,
                                    3937911.75,
                                    256053.24,
                                    452948.83
                                ],
                                [
                                    42,
                                    78,
                                    0,
                                    274326.6,
                                    99000,
                                    4202598.93,
                                    232697.49,
                                    442827.44
                                ],
                                [
                                    43,
                                    79,
                                    0,
                                    288042.93,
                                    99000,
                                    4484556.74,
                                    212210.22,
                                    436438.05
                                ],
                                [
                                    44,
                                    80,
                                    0,
                                    302445.07,
                                    99000,
                                    4784830.97,
                                    194917.13,
                                    434158.68
                                ],
                                [
                                    45,
                                    81,
                                    0,
                                    317567.33,
                                    99000,
                                    5104505.31,
                                    181145.78,
                                    436371.05
                                ],
                                [
                                    46,
                                    82,
                                    0,
                                    333445.69,
                                    99000,
                                    5444719.36,
                                    171241.86,
                                    443477.83
                                ],
                                [
                                    47,
                                    83,
                                    0,
                                    350117.98,
                                    99000,
                                    5806662.58,
                                    165561.22,
                                    455894.35
                                ],
                                [
                                    48,
                                    84,
                                    0,
                                    367623.88,
                                    99000,
                                    6191573.8,
                                    164467.37,
                                    474046.06
                                ],
                                [
                                    49,
                                    85,
                                    0,
                                    386005.07,
                                    99000,
                                    6600735.05,
                                    168323.3,
                                    498360.05
                                ],
                                [
                                    50,
                                    86,
                                    0,
                                    405305.32,
                                    99000,
                                    7035387.47,
                                    177405.14,
                                    529174.52
                                ],
                                [
                                    51,
                                    87,
                                    0,
                                    425570.59,
                                    99000,
                                    7496873.16,
                                    192041.72,
                                    566885.38
                                ],
                                [
                                    52,
                                    88,
                                    0,
                                    446849.12,
                                    99000,
                                    7986565.17,
                                    212542.15,
                                    611870.41
                                ],
                                [
                                    53,
                                    89,
                                    0,
                                    469191.57,
                                    99000,
                                    8505864.27,
                                    239190.11,
                                    664483.32
                                ],
                                [
                                    54,
                                    90,
                                    0,
                                    492651.15,
                                    99000,
                                    9056261.75,
                                    272303.89,
                                    725116.98
                                ],
                                [
                                    55,
                                    91,
                                    0,
                                    418283.71,
                                    0,
                                    9646334.2,
                                    423178.45,
                                    809031.82
                                ],
                                [
                                    56,
                                    92,
                                    0,
                                    439197.89,
                                    0,
                                    10280361.51,
                                    596047.97,
                                    904458.82
                                ],
                                [
                                    57,
                                    93,
                                    0,
                                    461157.79,
                                    0,
                                    10963340.99,
                                    794811.78,
                                    1014078.6
                                ],
                                [
                                    58,
                                    94,
                                    0,
                                    484215.67,
                                    0,
                                    11701128.08,
                                    1024172.4,
                                    1141183.68
                                ],
                                [
                                    59,
                                    95,
                                    0,
                                    508426.47,
                                    0,
                                    12499625.76,
                                    1288822.3,
                                    1288822.3
                                ],
                                [
                                    60,
                                    96,
                                    0,
                                    533847.78,
                                    0,
                                    13352894.28,
                                    1581550.65,
                                    1581550.65
                                ],
                                [
                                    61,
                                    97,
                                    0,
                                    560540.17,
                                    0,
                                    14264704.15,
                                    1904793.33,
                                    1904793.33
                                ],
                                [
                                    62,
                                    98,
                                    0,
                                    588567.19,
                                    0,
                                    15239087.05,
                                    2261180.68,
                                    2261180.68
                                ],
                                [
                                    63,
                                    99,
                                    0,
                                    617995.55,
                                    0,
                                    16280352.62,
                                    2653550.93,
                                    2653550.93
                                ],
                                [
                                    64,
                                    100,
                                    0,
                                    648895.32,
                                    0,
                                    17393107.85,
                                    3084966.07,
                                    3084966.07
                                ],
                                [
                                    65,
                                    101,
                                    0,
                                    681340.09,
                                    0,
                                    18582277.97,
                                    3558729.11,
                                    3558729.11
                                ],
                                [
                                    66,
                                    102,
                                    0,
                                    715407.08,
                                    0,
                                    19853128.51,
                                    4078402.2,
                                    4078402.2
                                ],
                                [
                                    67,
                                    103,
                                    0,
                                    751177.45,
                                    0,
                                    21211288.82,
                                    4647826.2,
                                    4647826.2
                                ],
                                [
                                    68,
                                    104,
                                    0,
                                    788736.31,
                                    0,
                                    22662776.93,
                                    5271141.17,
                                    5271141.17
                                ],
                                [
                                    69,
                                    105,
                                    0,
                                    828173.14,
                                    0,
                                    24214026.99,
                                    5952809.45,
                                    5952809.45
                                ],
                                [
                                    70,
                                    106,
                                    0,
                                    869581.78,
                                    0,
                                    25871917.44,
                                    6697639.03,
                                    6697639.03
                                ],
                                [
                                    71,
                                    107,
                                    0,
                                    913060.87,
                                    0,
                                    27643802.26,
                                    7510809.93,
                                    7510809.93
                                ],
                                [
                                    72,
                                    108,
                                    0,
                                    958713.92,
                                    0,
                                    29537544.15,
                                    8397902.21,
                                    8397902.21
                                ],
                                [
                                    73,
                                    109,
                                    0,
                                    1006649.61,
                                    0,
                                    31561548.08,
                                    9364924.05,
                                    9364924.05
                                ],
                                [
                                    74,
                                    110,
                                    0,
                                    1056982.09,
                                    0,
                                    33724802.06,
                                    10418346.84,
                                    10418346.84
                                ],
                                [
                                    75,
                                    111,
                                    0,
                                    1109831.19,
                                    0,
                                    36036913.54,
                                    11565135.57,
                                    11565135.57
                                ],
                                [
                                    76,
                                    112,
                                    0,
                                    1165322.75,
                                    0,
                                    38508155.85,
                                    12812788.98,
                                    12812788.98
                                ],
                                [
                                    77,
                                    113,
                                    0,
                                    1223588.9,
                                    0,
                                    41149511.51,
                                    14169376.28,
                                    14169376.28
                                ],
                                [
                                    78,
                                    114,
                                    0,
                                    1284768.36,
                                    0,
                                    43972723.68,
                                    15643581.69,
                                    15643581.69
                                ],
                                [
                                    79,
                                    115,
                                    0,
                                    1349006.76,
                                    0,
                                    46990346.27,
                                    17244747.19,
                                    17244747.19
                                ],
                                [
                                    80,
                                    116,
                                    0,
                                    1416457.09,
                                    0,
                                    50215804.16,
                                    18982925.13,
                                    18982925.13
                                ],
                                [
                                    81,
                                    117,
                                    0,
                                    1487279.95,
                                    0,
                                    53663450.62,
                                    20868927.64,
                                    20868927.64
                                ],
                                [
                                    82,
                                    118,
                                    0,
                                    1561643.95,
                                    0,
                                    57348626.89,
                                    22914377.74,
                                    22914377.74
                                ],
                                [
                                    83,
                                    119,
                                    0,
                                    1639726.17,
                                    0,
                                    61287741.32,
                                    25131779.72,
                                    25131779.72
                                ],
                                [
                                    84,
                                    120,
                                    0,
                                    1721712.45,
                                    0,
                                    65498337.87,
                                    27534578.2,
                                    27534578.2
                                ],
                                [
                                    85,
                                    121
                                ]
                            ]
                        },
                        "values": {
                            "Initial_Face_Amount": 913750,
                            "Lapse_Year": 0,
                            "MEC_Year": 0,
                            "Modal_Premium": 45726,
                            "Minimum_Premium_MMP": 6147.24,
                            "Death_Benefit_Protection_Premium_MGP": 6011.9,
                            "Target_Premium": 12390.45,
                            "MEC_Premium": 66896,
                            "Guideline_Level_Premium": 45726,
                            "Guideline_Single_Premium": 267850
                        }
                    },
                    "Guaranteed": {
                        "PolicyValues": {
                            "header": [
                                "Initial Face Amount",
                                "Lapse Year",
                                "MEC Year",
                                "Modal Premium",
                                "Minimum Premium (MMP)",
                                "Death Benefit Protection Premium (MGP)",
                                "Target Premium",
                                "MEC Premium",
                                "Guideline Level Premium",
                                "Guideline Single Premium"
                            ],
                            "rows": [
                                [
                                    913750,
                                    26,
                                    0,
                                    45726,
                                    6147.24,
                                    6011.9,
                                    12390.45,
                                    66896,
                                    45726,
                                    267850
                                ]
                            ]
                        },
                        "PolicyArrays": {
                            "header": [
                                "Policy Year",
                                "Age",
                                "Planned Premium",
                                "Loan",
                                "Annual Income",
                                "Accumulated Value",
                                "Surrender Value",
                                "Net Death Benefit"
                            ],
                            "rows": [
                                [
                                    1,
                                    37,
                                    45726,
                                    0,
                                    0,
                                    37317.55,
                                    37317.55,
                                    1112317.55
                                ],
                                [
                                    2,
                                    38,
                                    45726,
                                    0,
                                    0,
                                    74878.93,
                                    74878.93,
                                    1149878.93
                                ],
                                [
                                    3,
                                    39,
                                    45726,
                                    0,
                                    0,
                                    112596.81,
                                    112596.81,
                                    1187596.81
                                ],
                                [
                                    4,
                                    40,
                                    45726,
                                    0,
                                    0,
                                    150493.65,
                                    150493.65,
                                    1225493.65
                                ],
                                [
                                    5,
                                    41,
                                    45726,
                                    0,
                                    0,
                                    188570.64,
                                    188570.64,
                                    1263570.64
                                ],
                                [
                                    6,
                                    42,
                                    45726,
                                    0,
                                    0,
                                    226850.64,
                                    226850.64,
                                    1301850.64
                                ],
                                [
                                    7,
                                    43,
                                    45726,
                                    0,
                                    0,
                                    265334.86,
                                    265334.86,
                                    1340334.86
                                ],
                                [
                                    8,
                                    44,
                                    45726,
                                    0,
                                    0,
                                    304013.9,
                                    304013.9,
                                    1379013.9
                                ],
                                [
                                    9,
                                    45,
                                    45726,
                                    0,
                                    0,
                                    342888.86,
                                    342888.86,
                                    1417888.86
                                ],
                                [
                                    10,
                                    46,
                                    45726,
                                    0,
                                    0,
                                    381896.37,
                                    381896.37,
                                    1456896.37
                                ],
                                [
                                    11,
                                    47,
                                    0,
                                    0,
                                    0,
                                    379531.53,
                                    379531.53,
                                    770449
                                ],
                                [
                                    12,
                                    48,
                                    0,
                                    0,
                                    0,
                                    377143.92,
                                    377143.92,
                                    742973.53
                                ],
                                [
                                    13,
                                    49,
                                    0,
                                    0,
                                    0,
                                    374736.03,
                                    374736.03,
                                    715745.81
                                ],
                                [
                                    14,
                                    50,
                                    0,
                                    0,
                                    0,
                                    372310.95,
                                    372310.95,
                                    688775.25
                                ],
                                [
                                    15,
                                    51,
                                    0,
                                    370547.29,
                                    370547.29,
                                    369877.63,
                                    0,
                                    269307.51
                                ],
                                [
                                    16,
                                    52,
                                    0,
                                    18527.36,
                                    0,
                                    367439.56,
                                    0,
                                    219793.24
                                ],
                                [
                                    17,
                                    53,
                                    0,
                                    19453.74,
                                    0,
                                    364959.94,
                                    0,
                                    186005.11
                                ],
                                [
                                    18,
                                    54,
                                    0,
                                    20426.43,
                                    0,
                                    362406.55,
                                    0,
                                    162003.98
                                ],
                                [
                                    19,
                                    55,
                                    0,
                                    21447.74,
                                    0,
                                    359771.34,
                                    0,
                                    136848.63
                                ],
                                [
                                    20,
                                    56,
                                    0,
                                    22520.13,
                                    0,
                                    357046.23,
                                    0,
                                    110477.36
                                ],
                                [
                                    21,
                                    57,
                                    0,
                                    23646.16,
                                    0,
                                    354223.07,
                                    0,
                                    82825.78
                                ],
                                [
                                    22,
                                    58,
                                    0,
                                    24828.43,
                                    0,
                                    351291.17,
                                    0,
                                    53824
                                ],
                                [
                                    23,
                                    59,
                                    0,
                                    26069.88,
                                    0,
                                    348237.17,
                                    0,
                                    23396.63
                                ],
                                [
                                    24,
                                    60,
                                    0,
                                    27373.36,
                                    0,
                                    345045.15,
                                    0,
                                    0
                                ],
                                [
                                    25,
                                    61,
                                    0,
                                    28742.01,
                                    0,
                                    341699.04,
                                    0,
                                    0
                                ],
                                [
                                    26,
                                    62,
                                    0,
                                    30179.13,
                                    0,
                                    0,
                                    0,
                                    0
                                ],
                                [
                                    27,
                                    63
                                ]
                            ]
                        },
                        "values": {
                            "Initial_Face_Amount": 913750,
                            "Lapse_Year": 26,
                            "MEC_Year": 0,
                            "Modal_Premium": 45726,
                            "Minimum_Premium_MMP": 6147.24,
                            "Death_Benefit_Protection_Premium_MGP": 6011.9,
                            "Target_Premium": 12390.45,
                            "MEC_Premium": 66896,
                            "Guideline_Level_Premium": 45726,
                            "Guideline_Single_Premium": 267850
                        }
                    }
                }
            }
        },
        "details": {
            "Kaizen": {
                "templateS3Key": "1040-pdftest.pdf"
            },
            "Final_ClientFacing": {
                "acceleratedbenefitsridersummary": true,
                "printpuzzlepieceimageswithabrsummary": true,
                "benefitsummaryanalysis": false,
                "diversecreditingoptions": false,
                "illustratedfuturepolicyactions": false,
                "inputsummary": false,
                "internalrateofreturn": false,
                "monthlylowpointvalues": false,
                "myplannedlifeinsuranceoffers": true,
                "myplannedlifeinsuranceoffersdisplayorder": 1,
                "policychargesandexpenses": false,
                "salespresentationpages": false,
                "statutorypremiuminformation": false
            },
            "Final_Internal": {
                "acceleratedbenefitsridersummary": true,
                "printpuzzlepieceimageswithabrsummary": true,
                "benefitsummaryanalysis": false,
                "diversecreditingoptions": false,
                "illustratedfuturepolicyactions": false,
                "inputsummary": true,
                "internalrateofreturn": false,
                "monthlylowpointvalues": false,
                "myplannedlifeinsuranceoffers": true,
                "myplannedlifeinsuranceoffersdisplayorder": 1,
                "policychargesandexpenses": true,
                "salespresentationpages": false,
                "statutorypremiuminformation": false
            },
            "LowPoint": {
                "acceleratedbenefitsridersummary": true,
                "printpuzzlepieceimageswithabrsummary": true,
                "benefitsummaryanalysis": false,
                "diversecreditingoptions": false,
                "illustratedfuturepolicyactions": false,
                "inputsummary": false,
                "internalrateofreturn": false,
                "monthlylowpointvalues": true,
                "myplannedlifeinsuranceoffers": true,
                "myplannedlifeinsuranceoffersdisplayorder": 1,
                "policychargesandexpenses": false,
                "salespresentationpages": false,
                "statutorypremiuminformation": false
            }
        }

    },*/
    "comparison": {
        "mecavoidance": "Adjust_Face",
        "deathbenefitoption": "B_Increasing",
        "balancesheetbenefit": "Yes",
        "balancesheetbenefitpercentage": 100,
        "deathbenefittype": "Min_DB_Max_Cash_Value",
        "deathbenefitchangeyear": "6",
        "distributionmode": "Annual",
        "interestrates": [
            {
                "creditsuisseallocation": 50,
                "uspacesetterallocation": 50,
                "from": "1",
                "through": "M"
            }
        ],
        "premiummode": "Annual",
        "premium": [
            {
                "type": "Specify_Amount",
                "from": "1",
                "through": "5"
            }
        ]
    }
}