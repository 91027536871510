import React from "react";
import { animateScroll } from "react-scroll";
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {Dialog, GenericDialogProps} from '../dialog';
import {Button} from "../formInputs";
import {ErrorCommon, FormikWrapper, PhoneNumberField, TextField, TextFieldCommon} from '../formikFormInputs';
import { Button as NiwButton, Label } from "../formInputs";
import Disclaimer from "../client/kaizen/Disclaimer";
import { useActions } from "../../actions";
import * as LeadActions from "../../actions/lead";
import * as AuthActions from "../../actions/auth";
import { makeQueryString, unmaskPhoneNumber } from "../../utils/commonUtils";
import { datePickerFormat } from "../../utils/dateUtils";
import { getQS } from "../../utils/queryString";
import { email as emailValidation, required, validatePhoneNumber } from "../../utils/validation";


export default function LeadGenerationForm(props: any) {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [email, setEmail] = React.useState(undefined);
    const strategyId: number = props.strategyData.id || 1;
    const leadActions = useActions(LeadActions);
    const authActions = useActions(AuthActions);
    const searchParams = !!props.location.search ? getQS(props.location.search) : "";
    const [isUnderstandDisclaimer, SetUnderstandDisclaimer] = React.useState(false);

    const fieldObj = [
        {
            component: TextField,
            id: "firstName",
            label: <Label label={"Your First Name"} required={true}/>,
            name: "firstName",
            placeholder: "Your First Name",
            validate: required,
        },
        {
            component: TextField,
            id: "lastName",
            label: <Label label={"Your Last Name"} required={true}/>,
            name: "lastName",
            placeholder: "Your Last Name",
            validate: required,
        },
        {
            component: TextField,
            id: "email",
            label: <Label label={"Your Email"} required={true}/>,
            name: "email",
            placeholder: "Email",
            validate: (value: string) => {
                let res: any = required(value);
                if (res) {
                    return res;
                }
                return emailValidation(value);
            },
        },
        {
            component: PhoneNumberField,
            id: "phone",
            label: <Label label={"Your Primary Phone"} required={true}/>,
            name: "phone",
            placeholder: "Phone",
            validate: (value: any) => {
                let res: any = required(value);
                if (res) {
                    return res;
                }
                return validatePhoneNumber(value);
            },
        }
    ];

    const initValues = {email: '', firstName: '', lastName: '', phone: ''};

    const submitFormik = async (values: any, setSubmitting: Function) => {
        setEmail(values.email);

        let agentLinkId: number = (props.agentLinkDetail && props.agentLinkDetail.id) || 0;
        let response = await leadActions.checkDuplicateLead({ email: values.email });

        if (response) {
            authActions.alertNotification({
                statusCode: 400,
                message: response,
            });
            setSubmitting(false);
            return;
        }

        leadActions.addMarketingEmail({ email: values.email, strategyId: strategyId, agentLinkId });

        //Create Lead
        return saveLead(values);
    };

    const saveLead = async (data: any) => {
        data.phone = unmaskPhoneNumber(data.phone || "");

        if (data.contactType === "NEXT_2_BUSINESS_DAY") {
            delete data.specificDate;
            delete data.specificTime;
        } else {
            data.specificDate = datePickerFormat(data.specificDate);
        }

        const defProps: any = {
            contribution: 38350,
            healthType: 'Standard',
            householdIncome: 200000,
        };
        const leadSource: string = props.match.params.guid || undefined ? "ASSIGNED_LEAD" : "AGENT_LINK";
        const agentId: number = props.agentLinkDetail && props.agentLinkDetail.agentId ? props.agentLinkDetail.agentId : 0;
        const carrierId: number = (props.carrier && props.carrier.id) || 0;
        const agentLinkId: number = (props.agentLinkDetail && props.agentLinkDetail.id) || 0;
        const leadPayload = { ...data, ...defProps, agentId: agentId, agentLinkId, carrierId, leadSource, strategyId };

        const res = await leadActions.addLead(leadPayload);

        if (res) {
            const urlObject = { ...searchParams, id: res.id };
            const queryString = makeQueryString(urlObject);
            props.history.push({
                pathname: window.location.pathname,
                search: queryString,
            });
            setOpenDialog(true);
        }

        animateScroll.scrollToTop();
    };

    const handleDisclaim = async () => {
        setOpenDialog(false);
        delete searchParams["landing"];
        let pageData = {
            landing: 3,
            email: encodeURIComponent(email as unknown as string),
        };
        const urlObject = { ...pageData, ...searchParams };
        const queryString = makeQueryString(urlObject);
        props.setStartEstimation(3);
        props.history.push({
            pathname: window.location.pathname,
            search: queryString,
        });
    };

    const dialogProps: GenericDialogProps = {
        actions: (
                <>
                    <FormControlLabel
                            className="formControlCheckbox"
                            control={(
                                    <Checkbox style={{ height: 22 }} color="primary" onChange={() => SetUnderstandDisclaimer(!isUnderstandDisclaimer)}/>
                            )}
                            label="I Understand"
                    />
                    <NiwButton
                            className="btnPrimary "
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleDisclaim}
                            label="Submit"
                            disabled={!isUnderstandDisclaimer}
                    />
                </>
        ),
        'aria-describedby': "alert-dialog-description",
        'aria-labelledby': 'alert-dialog-title',
        content: (<Disclaimer/>),
        maxWidth: "lg",
        open: openDialog,
        title: 'DISCLAIMER',
    };

    const formProps = {
        children: ({dirty, errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                <form onSubmit={handleSubmit}>
                    <Grid container id="get-started" spacing={3}>
                        {
                            fieldObj.map((field, index) => {
                                return (
                                        <>
                                            <Grid item key={index} xs={12} md={6}>
                                                <TextFieldCommon
                                                        {...field}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        values={values}
                                                />
                                                <ErrorCommon errors={errors} name={field.name} touched={touched} />
                                            </Grid>
                                        </>
                                );
                            })
                        }
                    </Grid>
                    <Grid item xs={12} className="positionR textCenter mt20">
                        <Button
                                children={(<div className="arrowAnimation" />)}
                                className="customBtn glow"
                                color="primary"
                                disabled={((Object.keys(errors).length !== 0) || !dirty || isSubmitting)}
                                endIcon={<ArrowForwardIcon/>}
                                label="Get Free Estimate"
                                loading={isSubmitting}
                                size="medium"
                                type="submit"
                                variant="contained"
                        />
                    </Grid>
                </form>
        ),
        initialValues: initValues,
        onSubmit: (values: any, { setSubmitting }) => submitFormik(values, setSubmitting),
    };

    return (
            <>
                <Dialog {...dialogProps} />
                <Typography variant="h3" color="primary" className="mb15">
                    Free Benefits Estimate
                </Typography>
                <Typography variant="body1">
                    Enter a few personal details to view your results.
                </Typography>
                <Grid item xs={12} md={12} lg={12} className="mt20 textCenter estimateReturnsForm">
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <FormikWrapper {...formProps} />

                            <Typography variant="caption" className="textCenter w100 floatLeft textLightGray mb20 mt20">
                                By proceeding you agree to our <a
                                    href="https://www.niwcorp.com/privacy-policy" target="_blank" className="itemHover"
                            >privacy terms</a> and to be
                                <br />
                                contacted by a NIW specialist.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
    );
}
