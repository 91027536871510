import * as React from "react";
import TextField from '@material-ui/core/TextField';
import NumberFormat from "react-number-format"
const MAX_VAL = 100;
const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
};
const noLimit = (inputObj) =>{
    return inputObj
}
type Props = {
    allowMax100?: Function,
    field: {
        name: string,
        onChange: Function,
    },
    form:{
        setFieldValue: Function,
        values: any
    },
    cb: Function,
    thousandSeparator?: boolean
}
const NumberTextFieldWithCb = (props:Props) => {
    const {allowMax100, field: { name }, form:{ setFieldValue, values}, cb, thousandSeparator=true, ...custom} = props;
    return <NumberFormat
            thousandSeparator={thousandSeparator}
            {...custom}
            variant="outlined"
            customInput={TextField}
            isAllowed={allowMax100 ? withValueLimit : noLimit}
            onChange={(e)=>{
                setFieldValue(name, e.target.value);
                if(typeof cb === 'function'){
                    cb(e, setFieldValue, values)
                }
            }}
    />
};

export default NumberTextFieldWithCb;
