import * as React from "react";
import TextField from '@material-ui/core/TextField';
import NumberFormat from "react-number-format"
const MAX_VAL = 100;
const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
};
const noLimit = (inputObj) =>{
    const { value } = inputObj;
    if(Math.sign(value) !== -1)
    return inputObj
}
const NumberTextField = (props:any) => {
    const {allowMax100, ...custom} = props;
    return <NumberFormat
            thousandSeparator={true}
            {...custom}
            variant="outlined"
            customInput={TextField}
            isAllowed={allowMax100 ? withValueLimit : noLimit}
    />
};

export default NumberTextField;