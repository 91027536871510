import React from "react";
import {
    FormControlLabel,
    FormGroup,
	Checkbox,
	FormControl,
	FormHelperText,
	Tooltip, FormLabel,
} from "@material-ui/core";
import { FormInputProps } from "../../model";

interface CheckBoxGroupProps extends FormInputProps {
	label: string,
	toolTipLabel: string,
	options: any[],
    values:any,
    className: string
}

type OptionProps = {
	value: number,
	text: string,
    name:string
}

export default function CheckBoxGroup({
											required,
											fullWidth,
											disabled,
											label,
											toolTipLabel,
											options,
											meta: { touched, error },
											input,
                                            values,
                                            className,
											...custom
										}: CheckBoxGroupProps) {

	function handleChange(e: React.ChangeEvent<HTMLInputElement>, name:string) {
		return input.onChange(e.target.checked ? 1 : 0, name);
	}


	return (
			<FormControl
					required={required}
					fullWidth={fullWidth}
					disabled={disabled}
					error={touched && error}
			>
				{
					label &&
		  		<FormLabel  style={{marginBottom :0}} >{label}</FormLabel>
				}
                <FormGroup style={input.style} >
                {
                    options.map((o: OptionProps, idx: number) =>
                            <FormControlLabel
                                    className={`${className ? `checkboxGroup mb0 ${className}` : "checkboxGroup mb0"}`}
                                    style={{display :"block"}}
                                    key={`${idx}-${o.text}`}
                                    value={o.value}
                                    control={
                                    <Tooltip title={'Select ' + (toolTipLabel || o.text)}>
                                        <Checkbox
                                            name={`${input.name}[${idx}]`}
                                            onChange={(event) => { handleChange(event, o.name); } }
                                            checked={values[o.name] === 1 ? true : false}
                                        />
                                    </Tooltip>
                                    }
                                    label={o.text}
                            />)
                }
                </FormGroup>

				{
					(touched && error) &&
		  		<FormHelperText>{touched && error}</FormHelperText>
				}
			</FormControl>
	);
}