import React from "react";
import { FormHelperText, Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import {
	TextField,
	TextFieldCommon,
} from "../../../../../../../components/formikFormInputs";
import { useFormikContext } from "formik";
import FinancialPageCheckBox from "../component/FinancialPageCheckBox";
import { NAME_IDENTIFIER } from "./FinancialQuestions";

type IncomeFinancialProps = {
	question: any,
	isFromClientDetail: boolean,
	clientSigned: boolean
}

const IncomeCheckboxFinancialPage = (props: IncomeFinancialProps) => {
	const {
		errors,
		handleChange,
		values,
	}: any = useFormikContext();

	const { question, isFromClientDetail, clientSigned } = props;

	const disabledProperty: {
		disabled: boolean
	} = {
		disabled: clientSigned,
	};

	const textFieldCommon = {
		isRequired: true,
		className: "floatLeft",
		filedType: "string",
		variant: "outlined",
		multiline: true,
	};

	const fieldObject = [{
		name: `${NAME_IDENTIFIER}Tnewoh`,
		label: "Clients income and net worth",
		placeholder: "Clients income and net worth",
		showDetailsBox: true,
	}];

	return (
		<Grid container spacing={2} justify="space-between" className="mb15">
			<Grid item xs={12}>
				<span className="hLine" />
				<Typography variant="body1" component="strong" className="mb15 floatLeft w100">
					<Label label="Please check purpose for death benefit need:"
						   required={!isFromClientDetail} />
				</Typography>
			</Grid>
			<FinancialPageCheckBox fieldOptions={question.additionalFieldOptions} mainIndex={question.key}
								   clientSigned={clientSigned} />
			<Grid item xs={12}>
				<Typography variant="body1" component="strong" className="mb20 floatLeft w100">
					<Label label="How was the face amount determined?"
						   required={!isFromClientDetail} />
				</Typography>
				{
					fieldObject.map((item, index) => {
							return (
								<React.Fragment>
									<TextFieldCommon
										{...textFieldCommon}
										component={TextField}
										id={`${item.name}`}
										name={`${item.name}`}
										values={values}
										onChange={(e) => {
											handleChange(e);
										}}
										label={<Label label={`${item.label} Details`}
													  required={!isFromClientDetail} />}
										placeholder={`${item.label === "Other" ? "Provide Other Details" : item.label + " " + "Details"}`}
										{...disabledProperty}
									/>
									<>
										{(errors && errors[item.name]) &&
											<Grid item xs={12}>
												<FormHelperText style={{
													margin: "0",
													fontSize: "10px",
													color: "#FF1744",
												}}>
													<span className={""}>{errors[item.name]}</span>
												</FormHelperText>
											</Grid>
										}
									</>
								</React.Fragment>
							);
						},
					)
				}

			</Grid>
		</Grid>
	);
};

export default IncomeCheckboxFinancialPage;