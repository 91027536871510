import createReducer from "./createReducer";
import {Action, ActionType} from "../model";
import {Document} from "../model/documents";

export const documents = createReducer([], {
    [ActionType.LOAD_AGENT_DOCS](state: any, action: Action<Document>) {
        return action.payload;
    },

    [ActionType.FLUSH_AGENT_DOCS](state: Function, action: Action<void>) {
        return action.payload;
    }
});
