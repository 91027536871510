import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {ErrorCommon, TextFieldCommon} from "../../../../../../components/formikFormInputs";

type PropsType = {
    values: any,
    textFieldCommon: any,
    handleChange: Function,
    enableSaveButton: Function
    handleBlur: Function,
    errors: any,
    touched: any
}

function InsuredOtherHazardousSportsAvocations(props: PropsType) {
    const {
        values,
        textFieldCommon,
        handleChange,
        enableSaveButton,
        handleBlur,
        touched,
        errors
    } = props;
    return <>
        {values['Insured_AvocationXC53'] && <div className="pl16 pr16 innerContentWraptwo">
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body1"
                            component="strong">Provide full details describing participation in
                    competition
                    sports, skin or scuba diving, hang gliding, BASE jumping or bungee cord jumping, big
                    game
                    hunting, mountain climbing, cave exploring, rodeos, or snowmobiling. BASE is an
                    acronym for
                    building, antenna tower, span (usually bridge), and earth formation (usually
                    cliff).</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextFieldCommon
                        {...textFieldCommon}
                        id={`Insured_ParticipationDetails`}
                        name={`Insured_AvocationXT49`}
                        values={values}
                        onChange={(e) => {
                            handleChange(e);
                            enableSaveButton();
                        }}
                        onBlur={handleBlur}
                />
                <ErrorCommon errors={errors} name={"Insured_ParticipationDetails"}
                             touched={touched}/>
            </Grid>
        </div>
        }
    </>
}

export default InsuredOtherHazardousSportsAvocations;