import React from "react";
import FocusError from "../../../../../../components/FocusError";
import {
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import Info from "@material-ui/icons/Info";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import { ErrorCommon } from "../../../../../../components/formikFormInputs";
import Physician from "./Physician";
import { Formik } from "formik";
import { scroller } from "react-scroll";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../reducers";
import { isLongForm } from "../Utilities";
import PhysicianAndMedicalDetailsV1FormContent from "./PhysicianAndMedicalDetailsV1FormContent";

type PropsType = {
    initialValues: any,
    validationSchema: any
    physiciansCount: number,
    isFromClientDetail: boolean
    offerType: string
    updateClientFields: any,
    user: any,
    disableSaveButton: Function,
    setIsError: Function,
    fieldObj: any,
    CommonField: Function,
    enableSaveButton: Function,
    fieldObj10: any,
    classes: any,
    fieldObj11: any, fieldObj1: any, fieldObj2: any,fieldObj3: any, fieldObj4: any, fieldObj5: any, fieldObj6: any,
    fieldObj7: any, fieldObj8: any, fieldObj12: any,
    fieldObj13: any,
    fieldObj15: any, fieldObj14: any,
    setPhysiciansCount: Function,
    getSiblingDetail: Function,
    nationalLifeId: number,
    isClientSigned: boolean,
    physicalAndMedicalDetails?:any,
    physicalAndMedicalPayload?: Function
    setErrorInPhysicianAndMedicalDetails?:Function
    setInitiateSaveMe?: Function;
}

function PhysicianAndMedicalDetailsV1Form (props: PropsType) {
    const {
        initialValues,
        validationSchema,
        physiciansCount,
        isFromClientDetail,
        offerType,
        updateClientFields,
        user,
        disableSaveButton,
        setIsError,
        fieldObj,
        CommonField,
        enableSaveButton,
        fieldObj10, fieldObj1, fieldObj2, fieldObj3, fieldObj4, fieldObj5, fieldObj6, fieldObj7, fieldObj8,
        classes,
        fieldObj11,
        fieldObj12,fieldObj13, fieldObj14, fieldObj15, getSiblingDetail, setPhysiciansCount, nationalLifeId, isClientSigned,
        physicalAndMedicalDetails,
        setInitiateSaveMe,
        physicalAndMedicalPayload, setErrorInPhysicianAndMedicalDetails
    } = props;
    const loggedInUser:any = useSelector((state: RootState) => state.auth);
    let role:number|null = (loggedInUser && loggedInUser.user && loggedInUser.user.role) || null;
    return (
            <Formik
                    innerRef={physicalAndMedicalDetails}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    onSubmit={async (values: any, { setSubmitting }) => {
                        try {
                            if (physiciansCount < 1 && !isFromClientDetail && offerType !== 'gi' && !isFromClientDetail) {
                                scroller.scrollTo("physcianList", {
                                    duration: 800,
                                    delay: 0,
                                    smooth: 'easeInOutQuart',
                                    offset:  -200
                                });
                                return false;
                            }
                            let payload:any = {};

                            if(typeof physicalAndMedicalPayload === 'function') {
                                payload = physicalAndMedicalPayload(values);
                            }
                            await updateClientFields(payload, ((user && user.client && user.client.id) || ""), isFromClientDetail);

                            disableSaveButton();
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 400);
                        } catch (e) {
                            return false;
                        }
                    }}
            >
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         setFieldValue,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         dirty,
                     }) => {
                        /*if (Object.keys(errors).length > 0 && !isFromClientDetail) {
                            setTimeout(() => setIsError(true), 500);
                        }*/
                        return (
                            <PhysicianAndMedicalDetailsV1FormContent
                                offerType={offerType}
                                isFromClientDetail={isFromClientDetail}
                                nationalLifeId={nationalLifeId}
                                user={user}
                                classes={classes} isClientSigned={isClientSigned}
                                enableSaveButton={enableSaveButton}
                                getSiblingDetail={getSiblingDetail}
                                setPhysiciansCount={setPhysiciansCount}
                                setIsError={setIsError}
                                setErrorInPhysicianAndMedicalDetails={setErrorInPhysicianAndMedicalDetails}
                                fieldObj={fieldObj}
                                CommonField={CommonField}
                                fieldObj1={fieldObj1}
                                fieldObj2={fieldObj2}
                                fieldObj3={fieldObj3}
                                fieldObj4={fieldObj4}
                                fieldObj5={fieldObj5}
                                fieldObj6={fieldObj6}
                                fieldObj7={fieldObj7}
                                fieldObj8={fieldObj8}
                                fieldObj15={fieldObj15}
                                fieldObj10={fieldObj10}
                                fieldObj11={fieldObj11}
                                fieldObj12={fieldObj12}
                                fieldObj13={fieldObj13}
                                fieldObj14={fieldObj14}
                                setInitiateSaveMe={setInitiateSaveMe}
                            />
                        );
                    }
                }
            </Formik>
    )
}
export default PhysicianAndMedicalDetailsV1Form;