import * as React from "react";
import {DialogActions, DialogTitle, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {useActions} from "../../actions";
import * as Actions from "../../actions/account";

function SessionExpireAlertPopup(props: { setProgress: Function, setOpenRefreshPopup: Function, openRefreshPopup: boolean }) {
    const accountActions = useActions(Actions);
    const {openRefreshPopup, setOpenRefreshPopup, setProgress} = props;

    const handleClose = () => {
        setOpenRefreshPopup(false);
    };

    const handleContinue = async () => {
        setProgress(true);
        setOpenRefreshPopup(false);
        await accountActions.changeSessionToken();
        setProgress(false);
    };

    return (
            <Dialog
                    fullWidth
                    maxWidth={"sm"}
                    open={openRefreshPopup}
                    aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle>
                    <Typography  variant="body1" align="center" style={{color:'red'}}>
                        Your session is about to expire so you want to stay logged in!
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button className="mb20" variant="contained" color="primary"
                            onClick={() => handleContinue()}>
                        Yes
                    </Button>
                    <Button className="mb20" variant="contained" color="secondary"
                            onClick={handleClose}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default SessionExpireAlertPopup;