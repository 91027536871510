import * as React from "react";
import { Grid } from "@material-ui/core";
import { Field } from "redux-form";
import { stateDropDown } from "../constants";
import { DesignationList } from "../model/account";
import { required, validatePhoneNumber } from "../utils/validation";
import { Label, PhoneNumberField, SelectField, TextField } from "./formInputs";
import { DesignationFields } from "./profiles/DesignationFields";

interface PrimaryProps  {
    isLeadInvitation?: boolean,
    emailExist?: boolean,    
    setEmailExist?: Function,
    setEmailRef?: Function,
    handleSubmit?: Function,
    disabledLastName?: boolean,
    updateProfile?: boolean,
    disabledPhone? : boolean,
    validatePhoneNumber?: boolean,

	ownDesignation: boolean,
	designationList: DesignationList,
    disabledDesignation?: boolean
}

const PrimaryCompanyDetail = (props: PrimaryProps) => {
    const { isLeadInvitation, ownDesignation, designationList, disabledDesignation } = props;
    return (
        <>
            <Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : 6}
                  lg={isLeadInvitation ? 4 : 6}
                  xl={isLeadInvitation ? 4 : 6}>
				<Field
					fullWidth
					label={<Label label={"Company Name"} required={true}/>}
					name="agent.companyName"
					placeholder="enter a Company Name"
					component={TextField}
					validate={[required]}
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
                    disabled={props.disabledLastName || false}
				/>
			</Grid>
            <Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : 6}
                  lg={isLeadInvitation ? 4 : 6}
                  xl={isLeadInvitation ? 4 : 6}>
				<Field
					fullWidth
					label={<Label label={"Company Address 1"} required={true}/>}
					name="agent.companyStreet"
					placeholder="enter a Company Address"
					component={TextField}
					validate={[required]}
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
                    disabled={props.disabledLastName || false}
				/>
			</Grid>
            <Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : 6}
                  lg={isLeadInvitation ? 4 : 6}
                  xl={isLeadInvitation ? 4 : 6}>
				<Field
					fullWidth
					label={<Label label={"Company Address 2"} />}
					name="agent.businessAddress2"
					placeholder="enter a Company Address"
					component={TextField}					
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
                    disabled={props.disabledLastName || false}
				/>
			</Grid>            
            <Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : 6}
                  lg={isLeadInvitation ? 4 : 6}
                  xl={isLeadInvitation ? 4 : 6}>
				<Field
					fullWidth
					label={<Label label={"City"} required={true}/>}
					name="agent.companyCity"
					placeholder="enter a City"
					component={TextField}
					validate={[required]}
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
                    disabled={props.disabledLastName || false}
				/>
			</Grid>
            <Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : 6}
                  lg={isLeadInvitation ? 4 : 6}
                  xl={isLeadInvitation ? 4 : 6}>
				<Field
					fullWidth
					label={<Label label={"State"} required={true}/>}
					name="agent.companyState"
					placeholder="enter a State"
					component={SelectField}                    
					validate={[required]}
                    options={stateDropDown.map(x => ({ ...x, id: x.value }))}
                    onSelectChange={(val: number) => {
                        console.log('val', val)
                      }}
                    disabled={props.disabledLastName}
				/>
			</Grid>
            <Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : 6}
                  lg={isLeadInvitation ? 4 : 6}
                  xl={isLeadInvitation ? 4 : 6}>
				<Field
					fullWidth
					label={<Label label={"Zip"} required={true}/>}
					name="agent.companyZipCode"
					placeholder="enter a Zip"
					component={TextField}
					validate={[required]}
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
                    disabled={props.disabledLastName || false}
				/>
			</Grid>
            <Grid item xs={12} sm={6}  md={isLeadInvitation ? 4 : 6}
                  lg={isLeadInvitation ? 4 : 6}
                  xl={isLeadInvitation ? 4 : 6}>
				<Field
					fullWidth
					label={<Label label="Work Phone" required={true}/>}
					name="agent.officeNumber"
					placeholder="enter a Work number"
					component={PhoneNumberField}
					validate={[required, validatePhoneNumber]}
					disabled={props.disabledPhone}
					onBlur={props.handleSubmit && props.handleSubmit(props.updateProfile)}
				/>
			</Grid>
			<DesignationFields ownDesignation={ownDesignation} designationList={designationList} disabledDesignation={props.disabledDesignation}/>
        </>

    )
}

export default PrimaryCompanyDetail