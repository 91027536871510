import React from 'react';
import {medicalTobaccoQuestions} from "./component/MedicalPageQuestions";
import RenderMedicalQuestions from "./component/RenderMedicalQuestions";

function PhysicalAndMedicalTobaccoQuestionsNew(props: any) {
    return (
            <React.Fragment>
                {
                        medicalTobaccoQuestions && medicalTobaccoQuestions.length > 0 && medicalTobaccoQuestions.map((question: any, index: number) => {
                            return (
                                    <React.Fragment key={index}>
                                        {
                                                question && question.showUpperHrLine &&
                                                <span className="hLine" />
                                        }
                                        <RenderMedicalQuestions
                                                question={question}
                                                index={question && question.key || index}
                                                sendSaveRequest={props.sendSaveRequest}
                                                clientSigned={props.clientSigned}
                                        />
                                        {
                                                question && question.showBottomHrLine &&
                                                <span className="hLine" />
                                        }
                                    </React.Fragment>

                            )
                        })
                }
            </React.Fragment>
    )
}
export default React.memo(PhysicalAndMedicalTobaccoQuestionsNew);
