import * as React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { history } from "../configureStore";
import BadgeOverlap from "./BadgeOverlap";
import { connect } from "react-redux";
import { ActionType } from "../model";

interface listItemType {
    key:string,
    url : string,
    title : string,
    listIcon : any,
    routeName : string,
    setRouteName : Function,
    user:any,
    handleNavClick?: Function,
    dispatch?: Function
}

function NiwListItem(props: listItemType) {
    const {handleNavClick, dispatch} = props;
	return (
        <ListItem 
            button 
            className={(props.routeName === props.url) ? "active" : ""}
            onClick={()=>{
                if((props.url === '/imos/events')){
                   window.open("https://www.niwmarketing.com/", "_blank")
                   return;
                }
                if((props.url === '/agents/events')){
                    window.open("https://www.niwmarketing.com/", "_blank")
                    return;
                }
                if((props.url === '/events')){
                    // TODO - Remove '/imos/events' & '/agents/events' in future
                    window.open("https://www.niwmarketing.com/", "_blank")
                    return;
                }
                if(props.url === '/agent-groups') {
                    if(typeof dispatch === 'function') {
                        dispatch({ type: ActionType.SET_AGENT_GROUP, payload: true });
                    }
                } else {
                    if(typeof dispatch === 'function') {
                        dispatch({ type: ActionType.SET_AGENT_GROUP, payload: false });
                    }
                }
                props.setRouteName(props.url); 
                history.push( props.url );
                if(typeof handleNavClick === 'function'){
                    handleNavClick();
                }
            }}
        >
            <ListItemIcon>
                {props.listIcon}
            </ListItemIcon>
            <ListItemText primary={props.title} />
            {props.url === '/action-items' && props.user && (props.user.role ===  4 || props.user.role ===  12) && props.user.pendingItemsCount > 0 &&
                <BadgeOverlap/>
            }
            {props.url === '/agent-leads' && props.user && (props.user.role ===  4) && props.user.leadsCount > 0 &&
                <BadgeOverlap/>
            }
        </ListItem>
    )
}
export default connect()(NiwListItem);

/*export default NiwListItem;*/
