import * as React from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { Button, TextField } from "./formInputs";
import {
    PASSWORD_VALIDATE_REGEX,
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_SPECIAL_CHAR,
} from "../constants";
import PasswordRequired from "../components/PasswordRequired";
import { Grid } from "@material-ui/core";

type NewPassword = {
    password: string
    confirmPassword: string
}

type Props = {
    onSetPassword: (data: NewPassword, dispatch: Function) => void,
    role?: number | undefined
}

interface eventType {
    target: {
        value: string
    };
}

const validate = (values: NewPassword): object => {
    const errors: { password?: string | boolean, confirmPassword?: string } = {};
    if (!values.password) {
        errors.password = "Required";
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
    } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Password mismatched";
    }
    if (values.password && !PASSWORD_VALIDATE_REGEX.test(values.password)) {
        errors.password = true;
    }
    return errors;
};


const InvitationForm: React.FC<Props & InjectedFormProps<NewPassword, Props>> = (props) => {
    const { handleSubmit, pristine, submitting, onSetPassword, invalid, role } = props;

    const [oneLetter, setOneLetter] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);

    const onPassWordChange = (e: eventType): void => {
        const val = e.target.value;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
    };

    return (
            <form className="loginForm invitationForm pt0" onSubmit={handleSubmit(onSetPassword)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field name="password" fullWidth={true} component={TextField} type="password"
                               placeholder="Create a Password" onChange={(e: eventType) => onPassWordChange(e)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Field name="confirmPassword" fullWidth={true} component={TextField} type="password"
                               placeholder="Confirm Your Password"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                                type="submit"
                                label={role && role === 5 ? "LOG IN TO ILIA" : "CREATE PASSWORD"}
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                className="btnPrimary mt20 mb20"
                                disabled={invalid || pristine || submitting}
                                loading={submitting}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordRequired oneLetter={oneLetter} oneNumber={oneNumber}
                                          oneCapitalLetter={oneCapitalLetter} eightCharacter={eightCharacter}
                                          oneSpecialChar={oneSpecialChar}/>
                    </Grid>
                </Grid>
            </form>
    );
};

export default reduxForm<NewPassword, Props>({
    form: "InvitationForm",  // a unique identifier for this form
    validate,
})(InvitationForm);
