import {Grid} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import {ErrorCommon} from "../../../../../../components/formikFormInputs";
import React from "react";
import {useFormikContext} from "formik";

type EnrollmentToggleButtonProps = {
    question: any,
    role: number,
    clientSigned: boolean,
    onChangeFn: Function
}

const EnrollmentToggleButton = (props: EnrollmentToggleButtonProps) => {
    const {question, role, clientSigned, onChangeFn} = props;
    const {values, setFieldValue, touched, errors}: any = useFormikContext();
    const fieldName = question.name;
    return (<><Grid item xs={12} sm={12} md={3}>
        <ToggleButtonGroup
                className="toggleButtonGroupQust floatRight"
                size="medium"
                color={"primary"}
                exclusive
                id={fieldName}
                value={values[fieldName] === "Yes" ? "Yes" : (values[fieldName] == "No") ? "No" : ""}
                onChange={(ev: any, value: any) => {
                    if (value === "Yes") {
                        setFieldValue(fieldName, value);
                    }
                    if (value === "No") {
                        setFieldValue(fieldName, value);
                    }
                    if (value === null) {
                        setFieldValue(fieldName, "");
                    }
                    onChangeFn(value, values, question);
                }}
        >
            <ToggleButton value={"Yes"}
                          disabled={(role <= 4 || clientSigned)}
                          className={values[fieldName] === "Yes" ? "active" : ""}>
                Yes
            </ToggleButton>
            <ToggleButton value={"No"}
                          disabled={(role <= 4 || clientSigned)}
                          className={values[fieldName] === "No" ? "active" : ""}>
                No
            </ToggleButton>
        </ToggleButtonGroup>
    </Grid>
        {touched[fieldName] && errors[fieldName] && (
                <Grid item xs={12}>
                    <div className={"floatRight"}>
                        <ErrorCommon errors={errors}
                                     name={fieldName}
                                     touched={touched}/>
                    </div>
                </Grid>
        )}
    </>);
};

export default EnrollmentToggleButton;