import * as React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { reset } from "redux-form";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "../components/Common/CircularProgress";
import LeadClientInvitationForm, { LCIPTypes } from "../components/LeadClientInvite/LeadClientInvitationForm";
import StrategyName from "../components/client/StrategyName";
import { PageTitle } from "../components/formInputs";
import { SetPassword } from "../model";
import { useActions } from "../actions";
import { RootState } from "../reducers";
import * as AuthActions from "../actions/auth";
import * as Actions from "../actions/verify";
import { pez } from "../utils/commonUtils";
import { LOGO_BASE_URL } from "../constants";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: "flex",
                alignItems: "center",
            },
            progress: {
                margin: theme.spacing(2),
            },
            leftWrap: {
                maxWidth: 668,
                height: "100%",
                position: "relative",
                margin: "0 auto",
                [theme.breakpoints.down(1024)]: {
                    width: "520px !important",
                },
                [theme.breakpoints.down(767)]: {
                    width: "100% !important",
                    padding: "0 15px",
                    height: "auto",
                },
            },
            inviteClientWrap: {
                display: "flex",
                alignItem: "center",
                width: "100%",
                "&::before": {
                    content: "\"\"",
                    position: "absolute",
                    background: "linear-gradient(90deg, #F50057 0%, #1E88E5 50.06%, #3DDB93 100%)",
                    height: 10,
                    width: "100%",
                    top: 0,
                    zIndex: 9,
                },
            },
            videoWrap: {
                background: "linear-gradient(131.65deg, #12253D 0%, #2196F3 100%);",
                top: 0,
                bottom: 0,
                right: 0,
                height: "100%",
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
                margin: "0 auto",
                flexDirection: "column",
                justifyContent: "center",
                [theme.breakpoints.down(992)]: {
                    padding: "50px 0"
                },

            },
            videos: {
                margin: "0 auto",
            },
        }),
);


const NewLeadClient: React.FC = () => {
    const classes = useStyles();
    const verifyActions = useActions(Actions);
    const authActions = useActions(AuthActions);
    const hist = useHistory();
    const { inviteToken } = useParams();
    const vrData = useSelector((store: RootState) => store.verify);
    const [progress, setProgress] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const videoUrl: string = "https://vimeo.com/370399450";
    const [LoginPage, AccountsPage] = ["/login", "/accounts"];

    const verifyAccount = async () => {
        const res = await verifyActions.verifyAccount(inviteToken);
        if (res.payload.message === "Link is expired") {
            hist.push(LoginPage);
        }
    };
    const firstRun = () => {
        verifyAccount().then();

        return () => {
        };
    };

    React.useEffect(firstRun, []);

    React.useEffect(() => {
        if (vrData.checked && !vrData.verifiedInvite) {
            setProgress(false);
            setChecked(true);
        } else if (!vrData.checked) {
            setProgress(true);
            setChecked(false);
        } else if (vrData.verifiedInvite) {
            setProgress(false);
            setChecked(false);
        }
    }, [vrData]);

    const onSetPassword = async (data: LCIPTypes, dispatch: Function) => {
        const payload: SetPassword = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            inviteToken: inviteToken as string,
        };
        const result = await verifyActions.setPassword(payload, true);
        if (vrData.role === 5) {
            hist.push(AccountsPage);
            return;
        }
        // TODO - confirm if any other role can come here - otherwise remove
        if (result.statusCode === "200") {
            dispatch(reset("LeadClientInvitationForm"));
            hist.push(LoginPage);
        } else if (result.payload && result.payload.user) {
            dispatch(reset("LeadClientInvitationForm"));
            authActions.getAccountDetails();
        } else {
            hist.push(LoginPage);
        }
    };

    if (progress) {
        return (
                <div className={classes.root}>
                    <CircularProgress className={classes.progress}/>
                    <Typography>Verifying Link</Typography>
                </div>
        );
    }

    if (checked) {
        return (
                <div className={classes.root}>
                    <Typography component="h2">
                        Please contact site administrator. Link is invalid or expired
                    </Typography>
                </div>
        );
    }

    const _initialValues = { chkTerms: false, confirmPassword: "", email: vrData.email, password: "" };
    const videoPlayerProps: ReactPlayerProps = {
        className: classes.videos,
        controls: true,
        height: "auto",
        url: videoUrl,
        width: "auto",
    };
    const logo = LOGO_BASE_URL + (
            vrData && vrData.client && vrData.client.isLead && vrData.client.source === "niwcorp.com"
                    ? "email/niwLogo.png" : "email/iliaLogo.png"
    );

    return (
            <div className={classes.inviteClientWrap}>
                <PageTitle title="Create Password for Invited Client from Lead"/>
                <Grid container>
                    <Grid item xs={12} md={7}>
                        <div className={classes.leftWrap}>
                            <img alt="brand-logo" className="floatLeft mt60" src={logo}/>
                            <div id="base">
                                <Typography variant="subtitle2" className="textHighLight mb0">
                                    60 - 100%
                                </Typography>
                                <Typography variant="body2" className="textWhite">
                                    More for Retirement
                                </Typography>
                                <div id="chevron">&nbsp;</div>
                            </div>
                            <Typography component="strong" align="left" variant="body1"
                                        className="textHighLight floatLeft w100 mt100">
                                GET STARTED
                            </Typography>
                            <Typography color="primary" align="left" variant="h3" className="floatLeft w100">
                                Access NIW’s ilia Portal
                            </Typography>
                            <Typography align="left" variant="body1" className="floatLeft w100 mb20">
                                Please create a password to access the NIW <StrategyName
                                    strategyName={pez(vrData && vrData.strategy && vrData.strategy.name)}
                            /> strategy
                                benefits, run personalized estimates, and enroll through ilia, NIW’s interactive
                                learning portal.
                            </Typography>
                            <LeadClientInvitationForm
                                    initialValues={_initialValues}
                                    onSetPassword={onSetPassword}
                                    role={vrData.role}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}  md={5}>
                        <div className={classes.videoWrap}>
                            <Typography variant="caption" className="textWhite floatLeft">
                                Questions? Watch this video to learn about ilia.
                            </Typography>
                            <div className="newLeadClientPageVideo mt20">
                                <ReactPlayer {...videoPlayerProps} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
    );

};

export default NewLeadClient;





