import { EnrollmentQuestionProps } from "../../model/enrollment";


const getAdditionalFields = (fieldIdentifier: string) => {
    return [{
        name: "provide_detailsX" + fieldIdentifier,
        label: "Treatment Details",
        required: true,
        type: "text"
    }, {
        name: "physician_nameX" + fieldIdentifier,
        label: "Physician Full Name",
        required: true,
        type: "text"
    },
        {
        name: "physician_phoneX" + fieldIdentifier,
            label: "Physician Phone",
            required: true,
            type: "phone"
        }];
}

const stagingAndDev = {
    13: [
        {value: "c9146fe9-1f18-4b27-8ef8-42234db184af", text: "Stroke/TIA", additionalFields: getAdditionalFields("13Z1"), readonly: false},
        {value: "18142b67-ac86-42f3-a066-e56ed40ccbb8", text: "Cardiac Event", additionalFields: getAdditionalFields("13Z2"), readonly: false},
        {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("13"), readonly: false}
    ],
    14: [
        {value: "9ed7a5b8-0f7a-48cd-bb2a-debe823ab681", text: "Chronic Pain", additionalFields: getAdditionalFields("14Z1"), readonly: false},
        {value: "c7553019-0d2b-42e4-b2df-25e0d14b367c", text: "Arthritis", additionalFields: getAdditionalFields("14Z2"), readonly: false},
        {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("14"), readonly: false}
    ],
    15: [
        {value: "3f87dc9e-5a9e-4f32-bccb-12528778ff6f", text: "Tumors", additionalFields: getAdditionalFields("15Z1"), readonly: false},
        {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("15"), readonly: false}
    ],
    18: [
        {value: "e49a4d02-8594-475d-814d-308acd9c408b", text: "Gastrointestinal", additionalFields: getAdditionalFields("18Z1"), readonly: false},
        {value: "6aa12d22-1101-4bf3-9bc6-0b3ea1266c43", text: "Genitourinary", additionalFields: getAdditionalFields("18Z2"), readonly: false},
        {value: "87b2bba0-82cc-4fdb-ba30-209bfc8cd27b", text: "Hepatitis/Liver Disorder", additionalFields: getAdditionalFields("18Z3"), readonly: false},
        {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("18"), readonly: false}
    ],
    25: [
        {value: "12bbdce6-abf4-45d9-99a5-c6b458ea3f88", text: "Psychiatric Disorder", additionalFields: getAdditionalFields("25Z1"), readonly: false},
        {value: "4b0ade21-e9c9-48b3-a898-14a3cd1ff2f0", text: "Seizures/Epilepsy", additionalFields: getAdditionalFields("25Z2"), readonly: false},
        {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("25"), readonly: false}
    ],
    38: [
        {value: "57c341fa-4107-436b-a882-3cb1838bf5fe", text: "Asthma/Respiratory", additionalFields: getAdditionalFields("38Z1"), readonly: false},
        {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("38"), readonly: false}
    ]
};

const commonOptions = {
    type: "radio",
    default: false,
    onYes: false,
    additionalRemarks: true,
    showRemarks: false,
    notShowHrLine: true
}

const additionalFieldOptionsList = {
    development: stagingAndDev,
    production: {
        13: [
            {value: "4b01539a-d8b1-4f81-9180-9744c1ad853f", text: "Stroke/TIA", additionalFields: getAdditionalFields("13Z1"), readonly: false},
            {value: "94178841-62ce-403f-956b-2df46674f06c", text: "Cardiac Event", additionalFields: getAdditionalFields("13Z2"), readonly: false},
            {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("13"), readonly: false}
        ],
        14: [
            {value: "81adf254-95bc-4d39-920f-34e54fba04f4", text: "Chronic Pain", additionalFields: getAdditionalFields("14Z1"), readonly: false},
            {value: "71af074c-5cd9-4a28-9ea5-b42195fb2070", text: "Arthritis", additionalFields: getAdditionalFields("14Z2"), readonly: false},
            {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("14"), readonly: false}
        ],
        15: [
            {value: "3c667abf-048d-4fe2-bfde-54c4d1400d4e", text: "Tumors", additionalFields: getAdditionalFields("15Z1"), readonly: false},
            {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("15"), readonly: false}

        ],
        18: [
            {value: "be9432fa-b0de-46dd-84fa-51c337c1a202", text: "Gastrointestinal", additionalFields: getAdditionalFields("18Z1"), readonly: false},
            {value: "c722ea0b-b8a6-44ea-b73b-459abff57cf0", text: "Genitourinary", additionalFields: getAdditionalFields("18Z2"), readonly: false},
            {value: "993a1fc9-1101-4ae8-8acc-7294553ada2e", text: "Hepatitis/Liver Disorder", additionalFields: getAdditionalFields("18Z3"), readonly: false},
            {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("18"), readonly: false}
        ],
        25: [
            {value: "a93ea351-89e4-4946-9b7a-72a3400cf6a2", text: "Psychiatric Disorder", additionalFields: getAdditionalFields("25Z1"), readonly: false},
            {value: "f2b2d9ae-4061-489f-9132-fd1416802fcc", text: "Seizures/Epilepsy", additionalFields: getAdditionalFields("25Z2"), readonly: false},
            {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("25"), readonly: false}
        ],
        38: [
            {value: "e8b1de30-7228-4f5c-8602-e6246bc78eaf", text: "Asthma/Respiratory", additionalFields: getAdditionalFields("38Z1"), readonly: false},
            {value: "other", text: "Other (from question above)", additionalFields: getAdditionalFields("38"), readonly: false}
        ]
    }
};


const environment = process.env.REACT_APP_ENV === "production" ? "production" : "development";

const additionalFieldOptions = additionalFieldOptionsList[environment];

const iliaAppMedicalQuestions: EnrollmentQuestionProps[] = [
    {
        ...commonOptions,
        key: "8",
        name: "Proposed_Insured_Questions-8",
        label: "Within the past 90 days, have you been advised by a member of the medical profession, to have any hospitalization,surgery or medical test (other than related to HIV/AIDS) that has not yet been completed or have any of these tests or procedures been completed and you are waiting for the results?",
        additionalFieldType: "physicianDetails",
        additionalFields: getAdditionalFields("8"),
        notShowUpperHrLine:true,
        notShowHrLine: false
    },
    {
        ...commonOptions,
        key: "13",
        name: "Proposed_Insured_Questions-13",
        label: "Any disease or abnormal condition of the heart, irregular heartbeat, coronary artery disease, chest pain, transient ischemic attack (TIA) or stroke?",
        additionalFieldCaption: "Check if applicable",
        additionalFieldName: 'Multi_FormX13',
        additionalFieldType: 'checkboxAndPhysicianDetails',
        additionalFieldRequired: false,
        additionalFieldLabel: '',
        additionalFieldOptions: additionalFieldOptions[13],
        notShowUpperHrLine:true,
        fieldCaption: "In the past 10 years have you ever been diagnosed, treated, or been given medical advice by a member of the medical profession for, or tested positive for, or taken medication for:"
    },
    {
        ...commonOptions,
        key: "14",
        name: "Proposed_Insured_Questions-14",
        label: "Any spine, hip, knee, shoulder, back, bones, muscles, arthritis, rheumatism, joints, or other gland disorder?",
        additionalFieldCaption: "Check if applicable",
        additionalFieldName: 'Multi_FormX14',
        additionalFieldType: 'checkboxAndPhysicianDetails',
        additionalFieldRequired: false,
        additionalFieldLabel: '',
        additionalFieldOptions: additionalFieldOptions[14]
    },
    {
        ...commonOptions,
        key: "15",
        name: "Proposed_Insured_Questions-15",
        label: "Any lump, tumor, cancer, cysts, melanoma, lymphoma, or any disorder of the lymph nodes?",
        additionalFieldCaption: "Check if applicable",
        additionalFieldName: 'Multi_FormX15',
        additionalFieldType: 'checkboxAndPhysicianDetails',
        additionalFieldRequired: false,
        additionalFieldLabel: '',
        additionalFieldOptions: additionalFieldOptions[15]
    },
    {
        ...commonOptions,
        key: "40",
        name: "Proposed_Insured_Questions-40",
        label: "Anemia, leukemia, clotting disorder, Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex (ARC), Auto Immune Disorders or any other blood disorder?",
        additionalFieldType: "physicianDetails",
        additionalFields: getAdditionalFields("40")
    },
    {
        ...commonOptions,
        key: "16",
        name: "Proposed_Insured_Questions-16",
        label: "Ataxia (muscle movement disorder), osteo, rheumatoid, or psoriatic arthritis, transverse Myelitis, Myasthenia Gravis, Autoimmune Disorder such as Lupus, Blindness, or Post-Polio Syndrome, Parkinson's disease, Muscular Dystrophy, Huntington's Chorea, Motor Neuron Disease, Lou Gehrig's Disease (ALS), or Multiple Sclerosis?",
        additionalFieldType: "physicianDetails",
        additionalFields: getAdditionalFields("16")
    },
    {
        ...commonOptions,
        key: "18",
        name: "Proposed_Insured_Questions-18",
        label: "Any genital, urinary, kidney, liver, jaundice, hepatitis, cirrhosis, gastrointestinal disorder, gastrointestinal bleeding, other disorder of the stomach or pancreas?",
        additionalFieldCaption: "Check if applicable",
        additionalFieldName: 'Multi_FormX18',
        additionalFieldType: 'checkboxAndPhysicianDetails',
        additionalFieldRequired: false,
        additionalFieldLabel: '',
        additionalFieldOptions: additionalFieldOptions[18],
    },
    {
        ...commonOptions,
        key: "19",
        name: "Proposed_Insured_Questions-19",
        label: "Diabetes, high blood sugar, endocrine system, including thyroid, pituitary, adrenal or other gland?",
        additionalFieldType: "physicianDetails",
        additionalFields: getAdditionalFields("19")
    },
    {
        ...commonOptions,
        key: "25",
        name: "Proposed_Insured_Questions-25",
        label: "Any mood or psych disorder or abnormal condition of the brain, or nervous system, including anxiety, depression, bipolar, schizophrenia, PTSD, memory loss, confusion, amnesia, Alzheimer's or Dementia, seizures/epilepsy, tremors, falls or dizzy  spells, or loss of consciousness?",
        additionalFieldCaption: "Check if applicable",
        additionalFieldName: 'Multi_FormX25',
        additionalFieldType: 'checkboxAndPhysicianDetails',
        additionalFieldRequired: false,
        additionalFieldLabel: '',
        additionalFieldOptions: additionalFieldOptions[25],
    },
    {
        ...commonOptions,
        key: "38",
        name: "Proposed_Insured_Questions-38",
        label: "Any disorder or abnormal condition of the lungs or respiratory system, including sleep apnea, shortness of breath, asthma, bronchitis, emphysema, chronic obstructive pulmonary disease, tuberculosis?",
        additionalFieldCaption: "Check if applicable",
        additionalFieldName: 'Multi_FormX38',
        additionalFieldType: 'checkboxAndPhysicianDetails',
        additionalFieldRequired: false,
        additionalFieldLabel: '',
        additionalFieldOptions: additionalFieldOptions[38],
    }
];

export default iliaAppMedicalQuestions;
