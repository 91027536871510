import React from "react";
import {Typography, Grid} from "@material-ui/core";
import {EnrollmentQuestionProps} from "../../../../../../model/enrollment";
import EnrollmentField from "./EnrollmentField";
import {
    ErrorCommon,
    RadioGroup as FormikRadioGroup,
    TextField,
    TextFieldCommon
} from "../../../../../../components/formikFormInputs";
import {Label} from "../../../../../../components/formInputs";

type PropsType = {
    values: any,
    setSaveAviationAvocationQuestions: Function,
    role: number,
    offerType: string,
    clientSigned: boolean,
    isFromClientDetail: boolean,
    errors: any, touched: any,
    handleChange: Function,
    handleBlur: Function
}

const questionList = [{
    key: "",
    name: "Insured_AvocationXR37",
    label: "Are you a member of a skin or scuba diving organization?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: true,
    additionalFieldName: 'Insured_AvocationXT38',
    additionalFieldType: 'textfield',
    additionalFieldRequired: true,
    additionalFieldLabel: "Name of organization",
    notShowUpperHrLine: true,
}]
const describeDivingActivity = [{
    name: "Insured_AvocationXT39",
    title: "Location"
}, {
    name: "Insured_AvocationXT40",
    title: "Purpose (Recreation, research, rescue team - describe)"
}, {
    name: "Insured_AvocationXT41",
    title: "Equipment used"
}, {
    name: "Insured_AvocationXT42",
    title: "Maximum time submerged"
}, {
    name: "Insured_AvocationXT43",
    title: "Maximum depth attained"
}, {
    name: "Insured_AvocationXT44",
    title: "Average depth current diving"
}]
const describeUnderWaterExperience = [{
    name: "Insured_AvocationXT46",
    title: "Number of years diving experience"
}, {
    name: "Insured_AvocationXT47",
    title: "Number of dives last 12 months"
}, {
    name: "Insured_AvocationXT48",
    title: "Number of dives anticipated next 12 months"
}]
function getCommonProps(isFromClientDetail: boolean, clientSigned: boolean) {
    return {
        helperFieldText: "",
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt20",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }
}
function getQuestionList1(clientSigned) {
    const questionList1 = [{
        key: "Insured_AvocationXR45",
        id: "Insured_AvocationXR45",
        name: "Insured_AvocationXR45",
        label: "",
        notShowUpperHrLine: true,
        notShowHrLine: true,
        component: FormikRadioGroup,
        disabled: clientSigned ? true : false,
        variant: "outlined",
        options: [
            {
                label: "Professional",
                value: "Professional",
            }, {
                label: "Amateur",
                value: "Amateur",
            },
        ],
        sizeMd: 6,
    }]
    return questionList1;
}
function InsuredUnderwaterDiving(props: PropsType) {
    const {
        values, clientSigned,
        isFromClientDetail,
        role,
        offerType,
        setSaveAviationAvocationQuestions,
        errors,
        touched,
        handleBlur,
        handleChange
    } = props;
    const commonProps = getCommonProps(isFromClientDetail, clientSigned);
    const questionList1 = getQuestionList1(clientSigned)
    let fieldProps = { onChange: handleChange, onBlur: handleBlur };
    return <>
        {
                values['Insured_AvocationXC52'] &&
                <div className="pl16 pr16 innerContentWraptwo">
                <Grid container>
                    <Grid item xs={12}>
                        {
                            questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                                    <EnrollmentField
                                            question={question}
                                            clientSigned={clientSigned}
                                            isFromClientDetail={isFromClientDetail}
                                            checkIsError={() => {
                                            }}
                                            onChangeFn={() => {
                                            }}
                                            role={role}
                                            offerType={offerType}
                                            setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                    />
                            ))
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"
                                    component="strong">Describe diving activity</Typography>
                    </Grid>
                    {describeDivingActivity.map((element, index) =>
                            <>
                                <TextFieldCommon
                                        id={element.name}
                                        name={element.name}
                                        label={<Label label={`${element.title}`}/>}
                                        values={values}
                                        {...commonProps}
                                        {...fieldProps}
                                />
                                <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                            </>
                    )
                    }
                    <span className="hLine"/>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" component="strong" className="mt20 mb10 floatLeft w100">Describe your status and experience</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {questionList1.map((field, index) => {
                            return (
                                    <div key={index} className="mb15 floatLeft w100">
                                        <TextFieldCommon
                                                {...field}
                                                values={values}
                                                onChange={handleChange}
                                        />
                                        <ErrorCommon errors={errors} name={field.name}
                                                     touched={touched}/>
                                    </div>
                            );
                        })
                        }
                    </Grid>
                    {describeUnderWaterExperience.map((element, index) =>
                            <>
                                <TextFieldCommon
                                        id={element.name}
                                        name={element.name}
                                        label={<Label label={`${element.title}`}/>}
                                        values={values}
                                        {...commonProps}
                                        {...fieldProps}
                                />
                                <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                            </>
                    )
                    }
                </Grid>
                </div>
        }
    </>
}

export default InsuredUnderwaterDiving;