import React from "react";
import {useFormikContext} from "formik";
import {Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import {EnrollmentFinancialAgreementProps, ResetFieldsProps} from "../../../../../../../model/enrollment";
import {Label} from "../../../../../../../components/formInputs";
import {ErrorCommon, TextField, TextFieldCommon} from "../../../../../../../components/formikFormInputs";
import InsuredPageRadioButton from "../component/InsuredPageRadioButton";
import {proposedInsuredQuestion64} from "../InsuredQuestions";
import {FelonyMisdemeanorPrefix, FMFieldsSuffix, showIfApplicable} from "../insuranceValidations";
import InsuredToggleButtonGroup from "../component/InsuredToggleButtonGroup";


type FelonyMisdemeanorProps = { clientSigned: boolean, name: string }
type ProposedInsuredQuestion64Props = {
    clientSigned: boolean;
    insuredQuestion: any[];
    isFromClientDetail: boolean;
    offerType: string;
    setErrors: Function;
    showComponent: Function;
}

/**
 * this component is a form component that renders a radio
 * button group with options "Felony" and "Misdemeanor"
 * and three text fields related to
 * crimes, dates, and locations.
 * @param props
 * @constructor
 * return Component
 */

const FelonyMisdemeanor = (props: FelonyMisdemeanorProps) => {
    const {name, clientSigned} = props;

    const {values, handleChange, handleBlur, errors, touched}: any = useFormikContext();
    const fieldObj = [
        {
            component: TextField,
            id: `${name}${FMFieldsSuffix.CrimeInvolved}`,
            label: "Crime(s) involved",
            name: `${name}${FMFieldsSuffix.CrimeInvolved}`,
            placeholder: "Crime(s) involved",
            type: "text",
            disabled: !!clientSigned
        }, {
            component: TextField,
            id: `${name}${FMFieldsSuffix.DateOfProbationPeriod}`,
            label: "Date(s) of conviction, charge or probation period",
            name: `${name}${FMFieldsSuffix.DateOfProbationPeriod}`,
            placeholder: "Date(s) of conviction, charge or probation period",
            type: "text",
            disabled: !!clientSigned
        }, {
            component: TextField,
            id: `${name}${FMFieldsSuffix.CountyStateInvolved}`,
            label: "County / State involved",
            name: `${name}${FMFieldsSuffix.CountyStateInvolved}`,
            placeholder: "County / State involved",
            type: "text",
            disabled: !!clientSigned
        },
    ];

    const defaultOptionsFelonyMisdemeanor: any = [
        {
            text: "Felony",
            value: "Felony"
        },
        {
            text: "Misdemeanor",
            value: "Misdemeanor"
        }
    ];

    let question: any = {
        notShowHrLine: false,
        additionalFieldType: 'radioBoxGroup',
        additionalFieldOptions: defaultOptionsFelonyMisdemeanor
    };
    return (
            <React.Fragment>
                <Grid item xs={4} sm={4} md={4}>
                    <InsuredPageRadioButton
                            question={{...question, name: `${name}${FMFieldsSuffix.FMType}`}}
                            clientSigned={clientSigned}
                    />
                </Grid>
                {
                    fieldObj.map((field, index) => {
                        return (
                                <Grid item xs={12} sm={12} md={12} key={index}>
                                    <TextFieldCommon
                                            className='formikRadio ml5'
                                            {...field}
                                            values={values}
                                            onChange={(e: any) => handleChange(e)}
                                            onBlur={handleBlur}
                                    />
                                    <ErrorCommon errors={errors} name={field.name} touched={touched}/>
                                </Grid>
                        )
                    })
                }
            </React.Fragment>
    )
}

const FM = (props: { clientSigned: boolean, name: string }) => {
    const {clientSigned, name} = props;
    return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <FelonyMisdemeanor
                            name={name}
                            clientSigned={clientSigned}
                    />
                </Grid>
            </Grid>
    )
}

export const resetProposedInsuredQuestion64 = async (_insuredQuestion64Keys, setFieldValue) => {
    for (let keys64 in _insuredQuestion64Keys) {
        let question: string = _insuredQuestion64Keys[keys64].split('X')[0];
        await setFieldValue(_insuredQuestion64Keys[keys64], null);
        if (!!question) {
            await setFieldValue(`${question}XRfmq`, {});
            await setFieldValue(`${question}XTinv`, "");
            await setFieldValue(`${question}XTpp`, "");
            await setFieldValue(`${question}XTsi`, "");
        }
    }
}

/**
 * This component is rendering a set of
 * questions with labels, toggle buttons,
 * and additional components like
 * FelonyMisdemeanor based on the form's state.
 * @param props
 * clientSigned:boolean;
 *insuredQuestion:any[];
 *isFromClientDetail:boolean;
 *offerType:string;
 *setErrors:Function;
 *showComponent:Function;
 * @constructor
 */

const ProposedInsuredQuestion64 = (props: ProposedInsuredQuestion64Props) => {

    const {clientSigned, showComponent, setErrors, isFromClientDetail, insuredQuestion, offerType} = props;
    const {values, setFieldValue, setFieldTouched, handleChange, handleBlur, errors, touched}: any = useFormikContext();
    const _insuredQuestion64Keys: string[] = proposedInsuredQuestion64(offerType).map(s => s.name);

    const resetFields = async (rfs: ResetFieldsProps[]) => {
        if (rfs.length > 0) {
            for (let rf in rfs) {
                await setFieldValue(rfs[rf].name, rfs[rf].value)
            }
        }
    }

    return (
            <>
                {
                    insuredQuestion.map((question: EnrollmentFinancialAgreementProps, idx: number) => {
                        const _isShow = (name: string, value: string = "Yes") => {
                            return showIfApplicable(values, question.name, name, value);
                        };

                        return (
                                <React.Fragment key={idx}>
                                    <Grid item xs={12} sm={12} md={12} key={question.key}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={8} lg={9}>
                                                <Typography variant="body1"
                                                            className="mb15 floatLeft "
                                                            component="strong">
                                                    <Label
                                                            label={`${question.label}`}
                                                            required={!isFromClientDetail}/>
                                                </Typography>
                                                {question.infoTooltip &&
                                                        <Tooltip title={<Typography
                                                                className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                                            <IconButton>
                                                                <Info color="primary"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                            </Grid>
                                            {
                                                    question.type === "radio" &&
                                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                                        <InsuredToggleButtonGroup id={question.name} onChangeNo={() => {
                                                            if (question && question.resetFields && question.resetFields.length > 0) {
                                                                resetFields(question.resetFields as ResetFieldsProps[]).then(() => {
                                                                })
                                                            }
                                                        }}
                                                        clientSigned={clientSigned}
                                                        />

                                                        <span style={{float: "right", clear: "both"}}>
                                                <ErrorCommon touched={touched} errors={errors} name={question.name}/>
                                            </span>
                                                    </Grid>
                                            }
                                            {
                                                    question && question.additionalRemarks &&
                                                    showComponent(question, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors)
                                            }
                                        </Grid>
                                    </Grid>

                                    {
                                        _insuredQuestion64Keys.map((val: string, key: number) => {
                                            const keyVal: string = `PIQ${70 + (key + 1)}X`;
                                            const name: string = FelonyMisdemeanorPrefix[keyVal];
                                            return (
                                                    _isShow(_insuredQuestion64Keys[key]) ?
                                                            <FM clientSigned={clientSigned} name={name}/> : <></>
                                            )
                                        })
                                    }

                                </React.Fragment>
                        );
                    })
                }
            </>
    )
}

export default ProposedInsuredQuestion64;