import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { FormHelperText } from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            error: {
                color: "#FF1744",
                fontSize:"12px"
            },
        }),
);

const EstimationErrorCommon = (props: any) => {
    const classes = useStyles();
    const { name, errors } = props;

    return (
            <>
                {errors[name] && errors[name] &&
                        <FormHelperText style={{ margin: '0', fontSize: '10px' }}>
                            <span className={classes.error}> {errors[name]} </span>
                        </FormHelperText>
                }
            </>
    )

};

export default EstimationErrorCommon;