import React from "react";
import { Card, CardContent, Tooltip, IconButton } from "@material-ui/core";
import { useState } from "react";
import { DeleteModel, FileUpload } from "../../../components/formInputs";
import { DeleteRounded } from "@material-ui/icons";
import DummyImage from "../../../components/client/kaizen/images/dummyImg.png"
import { useActions } from "../../../actions";
import * as Actions from "../../../actions/account";


const AgentProfileImage = ({ accountId, imageName, setImageName, disabledEditImage }: any) => {
    const accountActions = useActions(Actions);
    let dummyImg = DummyImage
    const [isDeleteImage, setIsDeleteImage] = useState<boolean>(false);


    const updateImage = async (publicUrl: any, dirAndFileNAme: string) => {
        setImageName(publicUrl);
        await accountActions.updateAccountProfileImage(accountId, { image: dirAndFileNAme });
    };

    const deleteImage = async () => {
        setImageName(dummyImg);
        setIsDeleteImage(false);
        await accountActions.updateAccountProfileImage(accountId, { image: null });
    };

    return (
        <Card className="muiCard mt0">
            <CardContent className="muiCardContent userImg mt0">
                {
                    disabledEditImage ? <></> :
                            <FileUpload
                                    iconOnly={true}
                                    width={200}
                                    height={200}
                                    dirName={"profileImage"}
                                    onFinish={(publicUrl: string, dirAndFileNAme: string) => updateImage(publicUrl, dirAndFileNAme)}
                            />
                }
                <img alt="" className=" img-responsive"
                    src={imageName || dummyImg}
                    title="User Image" />
                {(imageName && imageName !== dummyImg) && !disabledEditImage &&
                    <Tooltip title="Delete" className="userImgDelt">
                        <IconButton
                            key="delete"
                            aria-label="delete"
                            color="inherit"
                            onClick={() => setIsDeleteImage(true)}
                        >
                            <DeleteRounded />
                        </IconButton>
                    </Tooltip>
                }
            </CardContent>
            {
                isDeleteImage &&
                <DeleteModel
                    open={true}
                    handleClose={() => setIsDeleteImage(false)}
                    handleYes={deleteImage}
                    accountType="image"
                />
            }
        </Card>
    )
}

export default AgentProfileImage