import React from "react";
import { ClientDocumentType, DocApprovalStatus } from "../../../../../constants";
import * as Clients from "../../../../../actions/client";
import { useActions } from "../../../../../actions";
import { Link, Tooltip } from "@material-ui/core";
import { ccFirst } from "../../../../../utils/commonUtils";
import RejectDocModal from "./RejectDocModal";
import RequestClientDocModal from "../modals/RequestClientDocModal";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import UploadProposalPopUp from '../../../../../components/client/UploadProposalPopUpOld';

export default function DocumentApprovalActions(props: { document: any, clientDetail: any, getClientDocuments: Function, setLoading: Function, setRefresh: Function }) {
    const clientActions = useActions(Clients);
    const { document, clientDetail, getClientDocuments, setLoading, setRefresh } = props;
    const client = clientDetail && clientDetail.client;
    const enrollmentId = clientDetail && clientDetail.enrollment && clientDetail.enrollment.id;
    const agentId = clientDetail && clientDetail.client && clientDetail.client.agent && clientDetail.client.agent.id;
    const [approvedBy, setApprovedBy] = React.useState("");
    const [rejectedBy, setRejectedBy] = React.useState("");
    const [requestedBy, setRequestedBy] = React.useState("");
    const [rejectDocPopup, setRejectDocPopup] = React.useState<boolean>(false);
    const [requestDoc, setRequestDoc] = React.useState(false);
    const [clientId, setClientId] = React.useState(0);
    const [uploadProposalPopUp, setUploadProposalPopUp] = React.useState(false);
    const [proposalUpdate, setProposalUpdate] = React.useState<boolean>(false);
    const [currentDocument, setCurrentDocument] = React.useState<any>()
    React.useEffect(() => {
        if (document) {
            if (document.frontOrBack === "Front") {
                setApprovedBy(document.frontApprovedByUser && "Approved By " + ccFirst(document.frontApprovedByUser.firstName) + " " +
                        ccFirst(document.frontApprovedByUser.lastName));
                setRejectedBy(document.frontRejectedByUser && "Rejected By " + ccFirst(document.frontRejectedByUser.firstName) + " " +
                        ccFirst(document.frontRejectedByUser.lastName));
                setRequestedBy(document.frontRequestedByUser && "Requested By " + ccFirst(document.frontRequestedByUser.firstName) + " " +
                        ccFirst(document.frontRequestedByUser.lastName));
            } else {
                setApprovedBy(document.backApprovedByUser && "Approved By " + ccFirst(document.backApprovedByUser.firstName) + " " +
                        ccFirst(document.backApprovedByUser.lastName));
                setRejectedBy(document.backRejectedByUser && "Rejected By " + ccFirst(document.backRejectedByUser.firstName) + " " +
                        ccFirst(document.backRejectedByUser.lastName));
                setRequestedBy(document.backRequestedByUser && "Requested By " + ccFirst(document.backRequestedByUser.firstName) + " " +
                        ccFirst(document.backRequestedByUser.lastName));
            }
        }
    }, []);

    React.useEffect(() => {
        if (clientDetail) {
            const clientId = clientDetail && clientDetail.client && clientDetail.client.id;
            setClientId(clientId);
        }
    }, [clientDetail]);

    const handleRefresh = () => {
        setRefresh(Math.random());
    };

    const changeDocApprovalStatus = async (docId: number, docFrontOrBack: string, docAction: {
        approvalStatus: string, operationPerformed: string, rejectionNote?: string,
        docName?: string
    }) => {
        setLoading(true);
        await clientActions.updateDocApprovalStatus({ clientId, docId, docFrontOrBack, docAction });
        handleRefresh();
        setLoading(false);
    };

    const submitDocRejection = (formValues: { noteText: string }) => {
        changeDocApprovalStatus(document.id, document.frontOrBack, {
            ...DocApprovalStatus.REJECTED,
            rejectionNote: formValues.noteText,
            docName: document.name,
        }).then(() => {
            setRejectDocPopup(false);
        });
    };

    function Rejected() {
        return (
                <Link className="colorRed mr30" onClick={() => {
                    setRejectDocPopup(true);
                }}>Reject</Link>
        );
    }

    function Approved() {
        return (
                <Link className="colorBlue mr30" onClick={() => {
                    changeDocApprovalStatus(document.id, document.frontOrBack, DocApprovalStatus.APPROVED).then(() => {
                    });
                }}>Approve</Link>
        );
    }

    function Request() {
        if(document.documentType && document.documentType === ClientDocumentType.COVER_LETTER) {
            return null;
        }
        return (
                <Link className="colorBlue mr30" onClick={async () => {
                    await clientActions.updateDocForRequest({ documentName: document.documentName });
                    setRequestDoc(true);
                }}>Request</Link>
        );
    }

    function MarkResolved() {
        return (
                <Link className="colorBlue mr30" onClick={() => {
                    changeDocApprovalStatus(document.id, document.frontOrBack, DocApprovalStatus.MARK_RESOLVED).then(() => {
                    });
                }}>Mark Resolved</Link>
        );
    }

    function Replace(props: { document :any}) {
        return (
                <Link className="colorRed mr30" onClick={() => {
                    setUploadProposalPopUp(true);
                    setProposalUpdate(true);
                    setCurrentDocument(props.document)
                }}>Replace</Link>
        )
    }

    return (
            <div style={{ display: "flex",float:'left' }}>
                {
                    ((document.frontOrBack === "Front" && document.approvalStatusFront === null) ||
                            (document.frontOrBack === "Back" && document.approvalStatusBack === null)
                    ) && <>
                        <Approved/>
                        {(document.documentType && document.documentType !== ClientDocumentType.ADMIN_PROPOSAL) &&
                        <Rejected/>
                        }
                    </>
                }
                {
                (document.documentType && document.documentType === ClientDocumentType.ADMIN_PROPOSAL && document.name == 'Client Proposal') &&
                <Replace document={document} />
                }
                {
                    ((document.frontOrBack === "Front" && document.approvalStatusFront === DocApprovalStatus.APPROVED.approvalStatus) ||
                            (document.frontOrBack === "Back" && document.approvalStatusBack === DocApprovalStatus.APPROVED.approvalStatus))
                    && <>
                        <CheckIcon className="textHighLight"/>
                        <Tooltip title={approvedBy} placement="top">
                           <Link className="mr30">Approved</Link>
                        </Tooltip>
                    </>
                }
                {
                    document.documentType && document.documentType !== ClientDocumentType.ADMIN_PROPOSAL &&
                            ((document.frontOrBack === "Front" && document.approvalStatusFront === DocApprovalStatus.REJECTED.approvalStatus) ||
                            (document.frontOrBack === "Back" && document.approvalStatusBack === DocApprovalStatus.REJECTED.approvalStatus))
                    && <>
                    <CloseIcon className="colorRed"/>
                        <Tooltip title={rejectedBy} placement="top">
                            <Link className="mr30">Rejected</Link>
                        </Tooltip>
                        <Request/>
                    </>
                }
                {
                    document.documentType && document.documentType !== ClientDocumentType.ADMIN_PROPOSAL &&
                    ((document.frontOrBack === "Front" && document.approvalStatusFront === DocApprovalStatus.DOCUMENT_REQUESTED.approvalStatus) ||
                            (document.frontOrBack === "Back" && document.approvalStatusBack === DocApprovalStatus.DOCUMENT_REQUESTED.approvalStatus))
                    && <>
                        <Tooltip title={requestedBy} placement="top">
                            <Link className="colorRed mr30">Document Requested</Link>
                        </Tooltip>
                    </>
                }
                {
                    document.documentType && document.documentType !== ClientDocumentType.ADMIN_PROPOSAL &&
                    ((document.frontOrBack === "Front" && document.approvalStatusFront === DocApprovalStatus.UPLOADED.approvalStatus) ||
                            (document.frontOrBack === "Back" && document.approvalStatusBack === DocApprovalStatus.UPLOADED.approvalStatus))
                    && <>
                        {
                            rejectedBy && <>
                                <CloseIcon className="colorRed"/>
                                <Tooltip title={rejectedBy} placement="top">
                                <Link className="mr30">Rejected</Link>
                                </Tooltip>
                            </>
                        }
                        <MarkResolved/>
                    </>
                }
                <RejectDocModal open={rejectDocPopup} setRejectDocPopup={setRejectDocPopup}
                                submitDocRejection={submitDocRejection}/>
                {
                    requestDoc && <RequestClientDocModal
                            user={clientDetail}
                            open={true}
                            content={"Request Client Documents"}
                            clientDetail={clientDetail}
                            handleClose={() => {
                                setRequestDoc(false);
                            }}
                            document={document}
                            handleRefresh={() => getClientDocuments(clientId)}
                    />
                }
                {
                    (uploadProposalPopUp) &&
                    <UploadProposalPopUp
                        currentDocument={currentDocument}
                        setUploadProposalPopUp={setUploadProposalPopUp} open={uploadProposalPopUp}
                        enrollmentId={enrollmentId} clientId={clientId}
                        agentId={agentId}
                        refreshList={() => {
                            getClientDocuments(clientId)
                        }}
                        client={client}
                        proposalUpdate={proposalUpdate}
                    />
                }
            </div>
    );
}