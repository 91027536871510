import React from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction, IconButton, Tooltip, Box,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InfoIcon from "@material-ui/icons/Info";
import NumberFormat from "react-number-format";
import Assumptions from "../../../components/client/kaizen/Assumptions";
import { LOGO_BASE_URL } from "../../../constants";
import {maskCurrency} from '../../../utils/commonUtils';

function EstimationTabContentOne(props: any) {
    const { age, estimationData, strategyName, strategySlug, isLead } = props;
    let videoName = "kai-zen-strategies-how-contributions-works-animation.mp4";
    if (strategySlug === "trizen" || strategySlug === "esd") {
        videoName = "tri-zen-strategies-how-contributions-works-animation.mp4";
    }
    const btnVideoUrl: string = `${LOGO_BASE_URL}estimation/${videoName}`;
    return (
            <>
                <Grid container>
                    <Grid item xs={12} md={12} lg={7}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h2" className="mt30 floatLeft w100 mb20" color="primary">Your Results Are Below</Typography>
                                <Typography variant="subtitle1" component="strong" className="mt30 floatLeft w100">Policy funding</Typography>
                                <Typography variant="body1" align="left" className="mb20">Your contributions are used to purchase death
                                    benefit protection that can provide cash in the event of a chronic or terminal
                                    illness. The excess funds have the potential to accumulate and grow over time, which
                                    can be used to help supplement your retirement.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" component="strong" className="mb15 floatLeft w100">Benefits and distributions by
                                    age</Typography>
                                <Typography variant="caption" className="floatLeft mr30"><FiberManualRecordIcon
                                        className="colorYellow floatLeft"
                                        style={{ marginTop: "-4px" }}/> Protection</Typography>
                                <Typography variant="caption" className="floatLeft"><FiberManualRecordIcon
                                        className="colorBlue floatLeft"
                                        style={{ marginTop: "-4px" }}/> Accumulation</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="returnRateBox">
                                    <Typography variant="caption" className="f14"><b>Internal Rate of
                                        Return</b></Typography>
                                    <NumberFormat value={estimationData.kaizen_levd_irr}
                                                  displayType={"text"}
                                                  thousandSeparator={false} suffix={"%"}
                                                  renderText={value => <Box className="f22 floatLeft textHighLight"
                                                                            component="strong">{value} <Tooltip arrow
                                                                                                                color="primary"
                                                                                                                title="Internal Rate of Return (IRR) measures the return on an investment, net of taxes and costs. An IRR can be useful for comparing investments with similar risk profiles. This IRR estimate includes residual death benefits assuming the insured under the IUL insurance policy dies at age 90.">
                                                      <IconButton aria-label="Info">
                                                          <InfoIcon/>
                                                      </IconButton>
                                                  </Tooltip></Box>}/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Card className="benefitsCard bgLightGray mt40 mb20">
                                    <CardContent className="benefitsCardContent">
                                        <List>
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <></>
                                                </ListItemAvatar>
                                                <ListItemText primary={
                                                    <React.Fragment>
                                                        <Typography
                                                                variant="subtitle1"
                                                                className="mb0"
                                                                component="strong"
                                                        >
                                                            Age {age}
                                                        </Typography>
                                                    </React.Fragment>
                                                }/>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <FiberManualRecordIcon
                                                            style={{ marginTop: "-23px", float: "left" }}
                                                            className="colorYellow"/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                        primary="Initial death benefit protection"
                                                        secondary="Access to living benefit riders"/>
                                                <ListItemSecondaryAction>
                                                    <Typography variant="subtitle1" className="mb0 valueText">
                                                        <NumberFormat
                                                                value={estimationData.kaizen_initial_death_benefit}
                                                                displayType={"text"}
                                                                thousandSeparator={true} prefix={"$"}
                                                                renderText={value => <Box className="f22 floatLeft"
                                                                                          component="strong">{maskCurrency(value)}</Box>}/>
                                                        <Tooltip
                                                                color="primary"
                                                                title={"This is the death benefit initially applied for with the life insurance company.  It is needed as this is the amount the life insurance company is underwriting you for.  This is different from the death benefit you will likely receive, because it does not take into account the bank loan or any changes over time due to policy performance or reductions resulting from policy loans you take in the form of income or the policy loan used to repay the third party lender."} arrow>
                                                            <IconButton aria-label="Info">
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </Tooltip></Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                                <Card className="benefitsCard bgLightGray mt40 mb20">
                                    <CardContent className="benefitsCardContent">
                                        <List>
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <></>
                                                </ListItemAvatar>
                                                <ListItemText primary={
                                                    <React.Fragment>
                                                        <Typography
                                                                variant="subtitle1"
                                                                className="mb0"
                                                                component="strong"
                                                        >
                                                            Ages {estimationData.assumption_age_begins} - 90
                                                        </Typography>
                                                    </React.Fragment>
                                                }/>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <FiberManualRecordIcon
                                                            style={{ marginTop: "-23px", float: "left" }}
                                                            className="colorBlue"/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                        primary="Begin potential tax-free distributions"
                                                        secondary="when using policy loans"/>
                                                <ListItemSecondaryAction>
                                                    <Typography variant="subtitle1" className="mb0 valueText">
                                                        <NumberFormat
                                                                value={estimationData.kaizen_annual_income}
                                                                displayType={"text"}
                                                                thousandSeparator={true} prefix={"$"}
                                                                renderText={value => <Box className="f22 floatLeft"
                                                                                          component="strong">{maskCurrency(value)}</Box>}/> /
                                                        yr<Tooltip arrow
                                                                   color="primary"
                                                                   title="This as the cumulative total of the planned income distributions (policy loans) illustrated at the start of the plan.  You are not stuck with this schedule and can vary it as needed through the plan’s life, nor are the amounts guaranteed as it will be based on performance over time.">
                                                        <IconButton aria-label="Info">
                                                            <InfoIcon/>
                                                        </IconButton>
                                                    </Tooltip></Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                                <Card className="benefitsCard bgLightGray mt40 mb20">
                                    <CardContent className="benefitsCardContent">
                                        <List>
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <></>
                                                </ListItemAvatar>
                                                <ListItemText primary={
                                                    <React.Fragment>
                                                        <Typography
                                                                variant="subtitle1"
                                                                className="mb0"
                                                                component="strong"
                                                        >
                                                            Age 90
                                                        </Typography>
                                                    </React.Fragment>
                                                }/>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <FiberManualRecordIcon style={{ float: "left" }}
                                                                           className="colorBlue"/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                        primary={`Cumulative distributions taken from age ${estimationData.assumption_age_begins}-90`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Typography variant="subtitle1" className="mb0 valueText">
                                                        <NumberFormat
                                                                value={estimationData.kaizen_total_income}
                                                                displayType={"text"}
                                                                thousandSeparator={true} prefix={"$"}
                                                                renderText={value => <Box className="f22 floatLeft"
                                                                                          component="strong">{maskCurrency(value)}</Box>}/>
                                                        <Tooltip
                                                                arrow
                                                                color="primary"
                                                                title={"This as the cumulative total of the planned income distributions (policy loans) illustrated at the start of the plan.  You are not stuck with this schedule and can vary it as needed through the plan’s life, nor are the amounts guaranteed as it will be based on performance over time."}>
                                                            <IconButton aria-label="Info">
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </Tooltip></Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <FiberManualRecordIcon style={{ float: "left" }}
                                                                           className="colorYellow"/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                        primary="Remaining death benefit for beneficiaries net of all loan repayments."
                                                />
                                                <ListItemSecondaryAction>
                                                    <Typography variant="subtitle1" className="mb0 valueText">
                                                        <NumberFormat
                                                                value={estimationData.kaizen_death_benefit_yr90}
                                                                displayType={"text"}
                                                                thousandSeparator={true} prefix={"$"}
                                                                renderText={value => <Box className="f22 floatLeft"
                                                                                          component="strong">{maskCurrency(value)}</Box>}/>
                                                        <Tooltip
                                                                arrow
                                                                color="primary"
                                                                title="The amount paid to a beneficiary upon the death
                                                                of an insured person. Death benefits start out higher
                                                                depending on your age and health. The amount shown here
                                                                 is the remaining amount at age 90 after policy
                                                                 distributions.">
                                                            <IconButton aria-label="Info">
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </Tooltip></Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5}>
                        <ReactPlayer
                                controls={false}
                                playing={true}
                                loop={true}
                                volume={0}
                                muted={true}
                                url={btnVideoUrl}
                                width='100%'
                                height='800px'
                                playsinline={true}
                                className="tabTwoVideo"
                        />
                    </Grid>
                </Grid>
                {
                    !isLead &&
                    <Assumptions strategyName={strategyName} strategySlug={strategySlug} estimationData={estimationData} />
                }
            </>
    );
}

export default EstimationTabContentOne;