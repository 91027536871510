import React from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Grid,
    Typography,
} from "@material-ui/core";
import { Button } from "../../components/formInputs";
import CloseIcon from "@material-ui/icons/Close";
import { maskPhoneNumber } from "../../utils/commonUtils";

export default function LeadcontactUS(props: any) {
    console.log("Full Props", props);
    const { close, strategyData, agentDetail } = props;
    const strategy: string = strategyData.name;
    const handleClose = () => {
        close();
    };
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={true}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title">
                <DialogTitle id="form-dialog-title">
                    { agentDetail && <>Please Contact Your Agent</>}
                    { !agentDetail && <>Please Contact Us</>}
                    <IconButton
                            edge="end"
                            className="floatRight"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {agentDetail &&
                                    <span>
                                        {strategy} has certain requirements that must be legally met in order for it to be offered.
                                        Your agent may be able to find a great solution for your needs. Please contact {agentDetail.firstName} {agentDetail.lastName} at {maskPhoneNumber(agentDetail.phoneNumber)} or
                                        email {agentDetail.email} for options.
                                    </span>
                                }
                                {!agentDetail &&
                                    <span>
                                        {strategy} has certain eligibility requirements that must be legally met for us to offer it to you.
                                        We may be able to find a great solution for your need so please contact us at 800-294-9940 ext-216 or
                                        email sales@kaizenplan.com and we can assist you.
                                    </span>
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                    size="small"
                                    className="floatRight"
                                    type="button"
                                    label={"Close"}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClose}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

            </Dialog>
    );
}