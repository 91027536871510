import * as React from "react";
import { TableHead, TableBody, TableCell, TableRow, IconButton, Tooltip, Typography } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ImoTeamMemberHeadRow } from "../../model/account";
import { useActions } from "../../actions";
import * as Actions from "../../actions/account";
import { AccountStatusArr } from "../../model";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import {PageTitle} from "../formInputs";

const headRows: ImoTeamMemberHeadRow[] = [
    { id: "memberFirstName", label: "First Name" },
    { id: "memberLastName", label: "Last Name" },
    { id: "memberType", label: "Member Type" },
    { id: "inviteStatus", label: "Invite Status" },
    { id: "action", label: "Action" },
];

function ImoTeamMemberTable(props: any) {
    const { imoTeamMemberList, customPageTitle } = props;

    const [inviteTokenId, setInviteTokenId] = React.useState<string>("");
    const accountActions = useActions(Actions);

    function valHandler(accountId: string) {
        setInviteTokenId(accountId);
        accountActions.copyInvitationLink("Invite Link Has Been Copied to Your Clipboard");
    }

    return (
            <React.Fragment>
                {
                    customPageTitle &&
                    <PageTitle title={`${customPageTitle}`}/>
                }
                <TableHead>
                    <TableRow>
                        {headRows.map((row: any, idx: number) => (
                                <TableCell
                                        key={`${idx}-${row.id}`}
                                        padding={"default"}
                                >
                                    {row.label}
                                </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (!imoTeamMemberList.rows || imoTeamMemberList.rows.length === 0)
                        && <TableRow key={"noDataFound"}>
                            <TableCell align="center" colSpan={5}>
                                <Typography align="left">
                                    No IMO Team Members
                                </Typography>
                            </TableCell>
                        </TableRow>
                    }

                    {imoTeamMemberList.rows && imoTeamMemberList.rows.map((account: any, index: number) => {
                        return  <TableRow hover key={index+""+account.memberLastName}>

                                    <TableCell>{account.memberFirstName}</TableCell>
                                    <TableCell>{account.memberLastName}</TableCell>
                                    <TableCell>{account.ImoAdditionalMembers && account.ImoAdditionalMembers.memberType}</TableCell>
                                    <TableCell>{AccountStatusArr[account.inviteStatus || 0]}</TableCell>
                                    <TableCell>
                                        {
                                            account.inviteLink &&
                                            <Tooltip placement="left-start"
                                                     title={(account.ImoAdditionalMembers && account.ImoAdditionalMembers.userId === inviteTokenId)
                                                             ? <React.Fragment>Invite Link Has Been <br/>Copied to Your
                                                                 Clipboard</React.Fragment>
                                                             : "Copy Invite Link"}>
                                                <CopyToClipboard text={account.inviteLink}
                                                                 onCopy={() => valHandler(account.ImoAdditionalMembers.userId)}>
                                                    <IconButton color="default">
                                                        <LinkIcon/>
                                                    </IconButton>
                                                </CopyToClipboard>
                                            </Tooltip>
                                        }
                                        <Tooltip title="Delete">
                                            <IconButton
                                                    onClick={() => props.handleClickOpen(account.ImoAdditionalMembers.userId)}>
                                                <DeleteOutlineIcon className="colorRed"/>
                                            </IconButton>
                                        </Tooltip>

                                    </TableCell>
                                </TableRow>
                    })
                    }
                </TableBody>

            </React.Fragment>
    );
}

export default ImoTeamMemberTable;
