export const caseDetailsInitialValues = {
    //Case Details initial values
    policyNumber: "",
    policyStatus: 0,
    caseProcessorId: "",
    caseManagerId: "",
    employeeSSN:  "",
    issueAge:  0,
    dob: null,
    healthRating: "",
    strategyName: "",
    carrierId: "",
    productName: "",
    offerType:  "",
    agent: "",
    imo: "",
    salesRegion: "",
    issueDate: null,
    trancheNo: "",
    //targetAmount: 0,
    trancheStarted: null,
    trancheClosed: null,
    livingBenefit:"",

    //Trust Details initial values
    annualTrustFeeAmount: "",
    spouse: "",
    einNumber: "",
    trustName: "",
    trustFirstYearFee: "",
    trustAnnualFee: "",
    spousesSharesTrustFee: 0,

    //Client Payments initial values
    thisYearContributionDueDate:null,
    thisYearContributionAmountDue:"",
    thisYearBalanceReceived:"",
    nextYearContributionDueDate: null,
    nextYearContributionAmountDue: "",
    nextYearBalanceReceived: "",
    annualEmployerContribution: "",

    //Bank initial values
    bankFinancedWith: "",
    bankPremiumAmount: "",
    arrangementFee: "",
    bankMargin: "",

    //Benefits initial values
    initialTotalDeathBenefit: "",
    termRiderDeathBenefits: "",
    baseDeathBenefit: "",
    incomeStartAge: "",
    incomeEndAge: "",
    estimatedAnnualIncome: "",
    targetAmount: "",

    //Distributions initial values
    loanPayOffDate: null,
    startYearOfDeathBenefitReductions: null,
    incomeStartDate: null,
    loanPayOffAmount: ""
};

export const policyYearBreakDown = {
    /* Main */
    currentAge: "",
    clientContributionDue: "",
    employerContributionDue: "",
    deathBenefitReduction: "",
    indexAllocation1: "",
    indexAllocation1Percent: "",
    indexAllocation2: "",
    indexAllocation2Percent: "",

    /* Illustrate Value */
    illustratedPremium: "",
    bankPremium: "",
    loanRate: "",
    projectedInterest: "",
    estLoanBalance: "",
    illustratedSurrenderValue: "",
    netCashValueIllustrated: "",
    netLoanCashValueIllustrated: "",
    illustratedCurrentDeathBenefit: "",
    illustratedNetDeathBenefit: "",
    lowPoint: "",
    illustratedGrowthRate: "",

    /* Actual Values */
    insurancePremiumActual: "",
    amountPaidToPolicy: "",
    bankLoanPremiumDue: "",
    bankInterestRate: "",
    bankLoanAmount: "",
    actualSurrenderValue: "",
    actualNetSurrenderValue: "",
    actualCurrentDeathBenefit: "",
    netDeathBenefitActual: "",
    growthPercent: "",
    growthAmount: "",
    growthRateAverage: "",
    loanRateAverage: "",
    netCashValueActual: "",
    actualLowPoint: "",
    actualInterestAmount: "",
}