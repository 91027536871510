import React from "react";
import { useSelector } from "react-redux";
import { AppBar, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { Field } from "formik";
import * as Yup from "yup";
import { a11yProps, TabPanel } from "../client/ClientProfile/component/TabsMenu";
import { Button, ButtonProps, PageTitle } from "../../components/formInputs";
import { ErrorCommon, TextField, TextFieldCommon } from "../../components/formikFormInputs";
import { useActions } from "../../actions";
import * as ApprovedAgentActions from "../../actions/approvedAgents";
import { RootState } from "../../reducers";
import { ApprovedTable as AgentTab, ApprovedStats } from "./index";
import { Dialog, DialogTitle, GenericDialogProps } from "../../components/dialog";
import FormikWrapper, { FormikValues } from "../../components/formikFormInputs/FormWrapper";
import SellerAgreementActionItems from './SellerAgreementActionItems';
import { CircularLoading } from "../../components/Common";


export enum ApprovedTabsEnum {
    Approved,
    Pending,
    Deleted,
    PendingSellerAgreements,
    CompletedSellerAgreements,
}

export default () => {
    const [activeTab, setActiveTab] = React.useState(ApprovedTabsEnum.Pending);
    const [search, setSearch] = React.useState('');
    const [region, setRegion] = React.useState('all');
    const approvedAgentActions = useActions(ApprovedAgentActions);
    const stats = useSelector((state: RootState) => state.approvedStats);
    const handleStatsClick = (status: number) => {
        setActiveTab(status);
        approvedAgentActions.exportApprovedAgentList(status, search, region);
    };
    const handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };
    const getStats = async () => {
        await approvedAgentActions.getStats();
    };
    const firstCall = async () => {
        await getStats();
    };

    React.useEffect(() => {
        firstCall()
                .then();

        return () => approvedAgentActions.flushStats();
    }, []);

    const commonAAProps = { onAction: getStats, region, search, setSearch, setRegion };

    return (
            <Grid container spacing={2}>
                <PageTitle title="Agent Approval Management" />
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h3" align="left">
                        Agent Approval Management
                    </Typography>
                    <Typography variant="body1" className="floatLeft">
                        Manage agent approvals. Approve or reject new registrations and manage existing approved agents.
                    </Typography>
                </Grid>
                {
                        stats &&
                        <ApprovedStats
                                totalApproved={stats.totalApproved}
                                totalPending={stats.totalPending}
                                totalRejected={stats.totalRejected}
                                onClickApproved={() => handleStatsClick(ApprovedTabsEnum.Approved)}
                                onClickPending={() => handleStatsClick(ApprovedTabsEnum.Pending)}
                                onClickRejected={() => handleStatsClick(ApprovedTabsEnum.Deleted)}
                        />
                }
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <AppBar position="static" color="default">
                                <Tabs
                                        value={activeTab}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        scrollButtons="on"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="scrollable force tabs example"
                                >
                                    <Tab label="Approved Agents" {...a11yProps(ApprovedTabsEnum.Approved)} />
                                    <Tab label="Pending Registrations" {...a11yProps(ApprovedTabsEnum.Pending)} />
                                    <Tab label="Deleted Agents" {...a11yProps(ApprovedTabsEnum.Deleted)} />
                                    <Tab label="Pending Seller Agreements" {...a11yProps(ApprovedTabsEnum.PendingSellerAgreements)} />
                                    <Tab label="Completed Seller Agreements" {...a11yProps(ApprovedTabsEnum.CompletedSellerAgreements)} />
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TabPanel value={activeTab} index={ApprovedTabsEnum.Approved}>
                                <AgentTab {...commonAAProps} status={ApprovedTabsEnum.Approved} />
                            </TabPanel>
                            <TabPanel value={activeTab} index={ApprovedTabsEnum.Pending}>
                                <AgentTab {...commonAAProps} status={ApprovedTabsEnum.Pending} />
                            </TabPanel>
                            <TabPanel value={activeTab} index={ApprovedTabsEnum.Deleted}>
                                <AgentTab {...commonAAProps} status={ApprovedTabsEnum.Deleted} />
                            </TabPanel>
                            <TabPanel index={ApprovedTabsEnum.PendingSellerAgreements} value={activeTab}>
                                <SellerAgreementActionItems tabValue={0} />
                            </TabPanel>
                            <TabPanel index={ApprovedTabsEnum.CompletedSellerAgreements} value={activeTab}>
                                <SellerAgreementActionItems tabValue={1} />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    );
}

export const ApproveDialog = (approveDialogProps: any) => {
    const { description, handleClose, onApprove, open, title } = approveDialogProps;

    const approveButton: ButtonProps = {
        className: "mt30 floatRight ",
        color: "primary",
        label: "Approve",
        onClick: onApprove,
        variant: "contained",
    };
    const cancelButton: ButtonProps = {
        className: "mt30 floatLeft floatRightSM",
        color: "secondary",
        label: "Cancel",
        onClick: handleClose,
        variant: "contained",
    };
    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "customized-dialog-title",
        content: (
                <>
                    <Typography variant="body1">{description}</Typography>
                    <Button {...cancelButton} />
                    <Button {...approveButton} />
                </>
        ),
        fullWidth: true,
        maxWidth: "sm",
        onClose: handleClose,
        open: open,
    };
    return (
            <Dialog {...dialogProps}>
                <DialogTitle onClose={handleClose} title={title} />
            </Dialog>
    );
};

export const RejectDialog = (rejectProps: any) => {
    const { description, handleClose, id, onReject, open, title, refreshing } = rejectProps;

    const RejectForm = ({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, values }: FormikValues) => {
        const cancelButtonProps: ButtonProps = {
            className: "mt30 mb30 floatLeft floatRightSM",
            color: "secondary",
            label: "Cancel",
            onClick: handleClose,
            variant: "contained",
        };
        const fieldProps = {
            component: TextField,
            label: "Reason for Deletion",
            multiline: true,
            name: "reason",
            onBlur: handleBlur,
            onChange: handleChange,
            rows: 2,
            value: values.reason,
        };
        const submitButton: ButtonProps = {
            className: "bgRed mt30 mb30 floatRight ",
            color: "primary",
            label: title,
            loading: isSubmitting,
            type: "submit",
            variant: "contained",
        };
        return (
                <form onSubmit={handleSubmit}>
                    <Typography variant="body1">
                        {description}
                    </Typography>

                    <Field {...fieldProps} />
                    {
                        errors && errors.reason &&
                        <span className="txtRed f12 mt10">{errors.reason}</span>
                    }

                    <Button {...cancelButtonProps} />
                    <Button {...submitButton} />
                </form>
        );
    };
    const formikProps = {
        children: RejectForm,
        initialValues: { reason: "" },
        onSubmit: (values: FormikValues) => onReject(values, id),
        validationSchema: Yup.object({ reason: Yup.string().max(250).required("*") }),
    };
    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "customized-dialog-title",
        content: (
                <>
                    <FormikWrapper {...formikProps} />
                </>
        ),
        fullWidth: true,
        maxWidth: "sm",
        onClose: handleClose,
        open,
        title,
    };
    return (
            <>
                {
                        refreshing && <CircularLoading />
                }
                {
                        !refreshing &&
                        <Dialog {...dialogProps} />
                }
            </>
    );
};

export const ApproveImoDialog = ({ imoToApprove, onApprove, onClose }) => {
    return (
            <ApproveDialog
                    description={`Are you sure you want to approve this IMO ${imoToApprove.imo.groupName} to be an approved imo and invite them to ilia?`}
                    handleClose={onClose}
                    onApprove={onApprove}
                    open
                    title={`Approve ${imoToApprove.imo.groupName} as an IMO`}
            />
    );
};

export const RejectImoDialog = ({ imoToReject, onReject, onClose }) => {
    return (
            <RejectDialog
                    description="Let this agent know why they were deleted to use this IMO. They will receive an email with the deletion reason."
                    handleClose={onClose}
                    onReject={onReject}
                    open
                    title={`Delete ${imoToReject.imo.groupName}`}
            />
    );
};

export function FormInputs({ fields, values, handleChange, errors, touched }) {
    
    return (
            <>
                {
                    fields.map((field, index) => {
                        const { addToOnChange, ...fieldObj } = field; 
                        return (
                                <Grid item xs={12} md={field.size as any} lg={field.size as any} key={index}>
                                    <TextFieldCommon
                                            {...fieldObj}
                                            values={values}
                                            onChange={(e)=>{
                                                handleChange(e);
                                                addToOnChange && addToOnChange(e)
                                            }}
                                    />
                                    <ErrorCommon
                                            errors={errors}
                                            name={field.name}
                                            touched={touched}
                                    />
                                </Grid>
                        );
                    })
                }
            </>
    );
}

