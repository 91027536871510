import { Grid, Table, TableCell, TableContainer } from "@material-ui/core";
import { Pagination } from "../../../components/table";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ParticipantTable } from "../../../components/group/ParticipantList";
import { AccountSortList } from "../../../model/account";
import { RootState } from "../../../reducers";
import { SearchTextField } from "../ClientPage";
import * as AccountActions from "../../../actions/account";
import { useActions } from "../../../actions";
import TableWrap from "../../../components/TableWrap/TableWrap";


export const AgentClientsTab = ({ agentUserId }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [orderBy, setOrderBy] = useState("createdAt");
    const [order, setOrder] = useState("desc");
    const [search, setSearch] = useState("");

    const accountActions = useActions(AccountActions);
    const clientList = useSelector((state: RootState) => state.accounts);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };

    React.useEffect(() => {
        let queryObj: any = { page, rowsPerPage, sortBy: orderBy, order, filter: 16, searchText: search, agentUserId };

        accountActions.agentClientList(queryObj);
    }, [page, rowsPerPage, orderBy, order, search]);

    return (
        <Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <SearchTextField
                    search={search}
                    label={"Search Participants"}
                    onChange={e => {
                        setPage(0);
                        setSearch(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TableWrap>
                        <ParticipantTable
                            page={page}
                            rowsPerPage={rowsPerPage}
                            accountList={clientList}
                            order={order}
                            orderBy={orderBy}
                            handleRequestSort={handleRequestSort}
                            isContributionVisible={account => true}
                            getOfferType={
                                account => account.user? account.user.client.underWritingOfferTypeFinal || account.user.client.underWritingOfferType: account.underWritingOfferType}
                        >
                            {(v) =>
                                <TableCell padding="default" align="right">
                                    -
                                </TableCell>
                            }
                            </ParticipantTable>
                </TableWrap>
                <Pagination page={page} rowsPerPage={rowsPerPage} setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    accountList={clientList}/>
            </Grid>
        </Grid>
    )
}