import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography/Typography";

export default function AllianzAlert(props: any) {
    const { open, setOpenAlert, isNationalLife } = props;

    const handleClose = () => {
        setOpenAlert(false);
    };

    return (
            <div>
                <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                    <Typography variant="subtitle1" component="strong" align="center" className="mt30 mb10">
                        Income Requirements Not Met
                    </Typography>
                    <DialogContent>
                        <Typography variant="body1" align="center">
                            You need at least {isNationalLife ? 2 : 3} years of self employment income to be eligible for enrollment. If you
                            made a mistake you can close this notification and change
                            the dropdown under "time employed" to {isNationalLife ? "2+" : "3+"} years to continue. Also make sure to enter your
                            annual income for the last three years. We will require
                            income verification later in this process. If you have been self employed for less than {isNationalLife ? 2 : 3}
                            years please contact your agent for different enrollment options.
                        </Typography>
                    </DialogContent>
                    <DialogActions className="textCenter pb20 pr15">
                        <Button variant="contained" onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
    );
}
