import * as React from "react";
import ReactPlayer from "react-player";

export default function StepperReactPlayer(props: {btnVideoUrl:string}) {
    const {btnVideoUrl} = props;
    return (
            <ReactPlayer
                    className="estimateBenefitsVideos"
                    controls={false}
                    playing={true}
                    loop={false}
                    autoPlay={true}
                    volume={0}
                    muted={true}
                    url={btnVideoUrl}
                    width="auto"
                    height="auto"
                    playsinline={true}
            />
    )
}