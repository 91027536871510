import * as React from "react";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { FormInputProps } from "../../model";
import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";

const textArea = ({
									required,
									fullWidth,
									disabled,
									label,
								  input,
								  meta: { touched, error },
								  ...custom
				   }: FormInputProps) => (
		<FormControl
				required={required}
				fullWidth={fullWidth}
				disabled={disabled}
				error={touched && error}
		>
			{
				label &&
				<InputLabel htmlFor="name-error">
					{label}
				</InputLabel>
			}
			<TextareaAutosize
					{...input}
					{...custom}
			/>
			{
				(touched && error) &&
				<FormHelperText>{touched && error}</FormHelperText>
			}
		</FormControl>

);

export default textArea;