import React from 'react';
import { FormControl, FormControlLabel, Checkbox, FormHelperText } from "@material-ui/core";
import { FormInputProps } from '../../model';

interface CheckBoxProps extends FormInputProps {
	labelPlacement: "end" | "start" | "top",
	onClick: (e: any) => void
}

export default function CheckBox({
																	 fullWidth,
																	 label,
																	 labelPlacement,
																	 onClick,
																	 meta: { touched, error },
																	 input,
																	 ...custom
																 } : CheckBoxProps) {

	function handleChange () {
		onClick(input.value);
		return input.onChange;
	}
	return (
			<FormControl
					fullWidth={fullWidth}
					error={touched && error}
			>
				<FormControlLabel
						control={
							<Checkbox
									checked={input.value == 1 ? true : false}
									onChange={handleChange()}
									{ ...custom }
							/>
						}
						label={label}
						labelPlacement={labelPlacement}
				/>
				{
					(touched && error) &&
		  <FormHelperText>{touched && error}</FormHelperText>
				}
			</FormControl>
	);
}

