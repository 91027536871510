import { ActionType } from "../model";

export const getImoList = (name: string) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
    try {
        let resp = await api.get('public-api/imo/list', {}, {name});
        return resp;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};


export const getImoById = (imoId: string) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
    try {
        let resp = await api.get(`public-api/imo/${imoId}`, {}, {});
        return resp;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};