import React from "react";
import { Loader } from "../../components/Common";
import { PageTitle } from "../../components/formInputs";
import { useActions } from "../../actions";
import * as AuthActions from "../../actions/auth";

/**
 * Skip login for preferred agent via_niw_corp
 * @param props
 */
export default (props) => {
    const landingToken: string = (props.match && props.match.params && props.match.params.landingToken) || "";
    const [loading, setLoading] = React.useState<boolean>();
    const authActions = useActions(AuthActions);
    const verifyAndLogin = async () => {
        setLoading(true);
        await authActions.verifyPreferredAgentLogin(landingToken);
        setLoading(false);
    };

    const firstEffect = () => {
        setTimeout(() => {
            verifyAndLogin().then();
        }, 2000);
    };

    React.useEffect(firstEffect);

    if (loading) {
        return (
                <Loader />
        );
    }

    return (
            <>
                <PageTitle title="Preferred Login Skip" />
            </>
    );
};

