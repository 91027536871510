import React from "react";
import { Card, CardContent, Chip, Grid, Link, Typography } from "@material-ui/core";
import { DashboardStyle as Styles } from "../agent/dashboard/dashboardStyle";
import {ApprovedStats as ApprovedStatsType} from '../../model/approved';

type ApprovedStatsCard = {
    className: string;
    label: number;
    name: string;
    onClick: () => void;
};
interface ApprovedStatsProps extends ApprovedStatsType {
    onClickApproved: () => void;
    onClickPending: () => void;
    onClickRejected: () => void;
}

function ApprovedStats({totalApproved, totalPending, totalRejected, onClickApproved, onClickPending, onClickRejected}: ApprovedStatsProps) {
    const classes = Styles();
    const cards: ApprovedStatsCard[] = [
        {name: 'Total Approved', className: classes.btnGreen, label: totalApproved, onClick: onClickApproved},
        {name: 'Pending', className: classes.btnGrey, label: totalPending, onClick: onClickPending},
        {name: 'Deleted', className: classes.btnRed, label: totalRejected, onClick: onClickRejected},
    ];

    return (
            <>
                {
                    cards.map((card, key) => (
                            <Grid key={key} item xs={6} md={4} lg={3} xl={2}>
                                <Card className={classes.cardShadow + " " + classes.radius8 + " " + classes.minHeight148}>
                                    <div className="textCenter">
                                        <CardContent>
                                            <Typography variant="body1" component="strong">{card.name}</Typography>
                                            <div style={{ padding: "10px 0" }}>
                                                <Chip label={card.label} className={card.className} />
                                            </div>
                                            <Link variant="caption" onClick={card.onClick}>View & Export List</Link>
                                        </CardContent>
                                    </div>
                                </Card>
                            </Grid>
                    ))
                }
            </>
    );
}

export default ApprovedStats;