import React from "react";
import { useFormikContext } from "formik";
import { proposedInsuredQuestion77 } from "../InsuredQuestions";
import InsuredPageCheckBox from "../component/InsuredPageCheckBox";
import {FormHelperText, Grid, Typography} from "@material-ui/core";
import {
    DatePicker,
    ErrorCommon,
    TextField,
    TextFieldCommon, NumberTextFieldWithCb
} from "../../../../../../../components/formikFormInputs";
import {Label} from "../../../../../../../components/formInputs";

function RacingActivity(props:{name:string, clientSigned:boolean}) {
    const {name, clientSigned} = props;

    const { values, handleChange, handleBlur, errors }:any = useFormikContext();
    const fieldObj = [{
        name: `${name}Tvm`,
        id: `${name}Tvm`,
        placeholder: "Vehicle Make / Model",
        label: <Label label={"Vehicle Make / Model"} required={false}/>,
        component: TextField,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}Teng`,
        id: `${name}Teng`,
        placeholder: "Engine Displacement / Horsepower",
        label: <Label label={"Engine Displacement / Horsepower"} required={false}/>,
        component: TextField,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}Tfuel`,
        id: `${name}Tfuel`,
        placeholder: "Type of gas/fuel",
        label: <Label label={"Type of gas/fuel"} required={false}/>,
        component: TextField,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}Tspe`,
        id: `${name}Tspe`,
        placeholder: "Maximum speed (MPH)",
        label: <Label label={"Maximum speed (MPH)"} required={false}/>,
        component: TextField,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}Tln`,
        id: `${name}Tln`,
        placeholder: "Length of track/course",
        label: <Label label={"Length of track/course"} required={false}/>,
        component: TextField,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}Tlnm`,
        id: `${name}Tlnm`,
        placeholder: "Length of race (miles, laps, time)",
        label: <Label label={"Length of race (miles, laps, time)"} required={false}/>,
        component: TextField,
        type: "text",
        disabled: clientSigned
    }];

    return (
            <>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1" component="strong">
                        Racing Activity
                    </Typography>
                </Grid>
                {fieldObj.map((field, index) => {
                    return (
                            <Grid item xs={6} sm={6} md={6} key={index}>
                                <TextFieldCommon
                                        {...field}
                                        values={values}
                                        onChange={(e: any) => handleChange(e)}
                                        onBlur={handleBlur}
                                />
                               {/* <ErrorCommon errors={errors} name={field.name} touched={touched}/>*/}
                                {errors[field.name] &&
                                        <FormHelperText  style={{margin: '0', fontSize: '10px'}}>
                                            <span style={{color: "#FF1744",
                                                fontSize:"12px"}}> {errors[field.name] } </span>
                                        </FormHelperText>
                                }
                            </Grid>
                    );
                })}
                <span className="hLine"/>
                </>

    )}

const RacingQuesExtras = (props:{name:string, clientSigned:boolean}) => {
    const {name, clientSigned} = props;
    const { values, handleChange, handleBlur, errors, touched }:any = useFormikContext();
    const fieldObj = [{
        name: `${name}Tn12`,
        id: `${name}Tn12`,
        placeholder: "Number of races in the last 12 months",
        label: "Number of races in the last 12 months",
        component: NumberTextFieldWithCb,
        thousandSeparator: false,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}Tna12`,
        id: `${name}Tna12`,
        placeholder: "Number of races anticipated in the next 12 months",
        label: "Number of races anticipated in the next 12 months",
        component: NumberTextFieldWithCb,
        thousandSeparator: false,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}Tep12`,
        id: `${name}Tep12`,
        placeholder: "Years of racing experience",
        label: "Years of racing experience",
        component: NumberTextFieldWithCb,
        thousandSeparator: false,
        type: "text",
        disabled: clientSigned
    }, {
        name: `${name}Dl12`,
        id: `${name}Dl12`,
        label: "Date of last race",
        component: DatePicker,
        disableFuture: true,
        type: "text",
        saveFormat: "USER",
        disabled: clientSigned
    }];

    return (<>
            {fieldObj.map((field, index) => {
               return (
                   <Grid item xs={12} xl={6} key={index}>
                       <TextFieldCommon
                           {...field}
                           values={values}
                           onChange={(e: any) => handleChange(e) }
                           onBlur={handleBlur}
                       />
                       <ErrorCommon errors={errors} name={field.name} touched={touched} />
                   </Grid>
           )})}</>
    )


}



const ProposedInsuredQuestion75 = (props:any) => {
    const { clientSigned, offerType } = props;
    const questions:any[] = proposedInsuredQuestion77({ offerType });
    let i: number ;
    const newName: string = "Proposed_Insured_Questions-77X";
    return <>
        <Grid container spacing={3} style={{backgroundColor: "#edf3f8", margin: "30px 0"}}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body1" className="floatLeft w100 mb15 mt20" component="strong">
                    Please provide the following details for each type of racing activity you have participated in.
                </Typography>
                <span className="hLine"/>
            </Grid>
            {(() => {
                let row: any[] = [];
                for (i = 1; i <= 4; i++) {
                    let racingActivityField = newName+i;
                        row.push(<RacingActivity name={racingActivityField} clientSigned={clientSigned}/>);
                        
                }
                return row;
            })()}
        </Grid>
        <Grid container spacing={3}>
            <RacingQuesExtras name={"Proposed_Insured_Questions-77X"} clientSigned={clientSigned}/>
        </Grid>
        {
                questions && questions.length > 0 && questions.map((question:any, idx: number) => {
                    return <React.Fragment key={idx}>
                        <Typography variant="body2" component="strong" gutterBottom className="mt20 mb10 floatLeft w100">
                            {question && question.label}
                        </Typography>
                        <InsuredPageCheckBox question={question} clientSigned={clientSigned} />
                    </React.Fragment>
                })
        }
    </>
}

export default ProposedInsuredQuestion75;