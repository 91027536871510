import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import Carousel from "react-multi-carousel";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from "@material-ui/core";

import "react-multi-carousel/lib/styles.css";
import { pez } from "../../utils/commonUtils";

type VideoCarouselItem = {
    desc: string;
    imageSrc?: string;
    key: number;
    name?: string;
    strategyTestimonial?: string;
    title: string;
    videoUrl: string;
};
type VideoCarouselGroupProps = {
    key: number,
    id: number,
    name: string,
    showcaseUrl: string,
    videos: VideoCarouselItem [];
};


const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                background: "#F7F9FB",
                boxShadow: "none",
                borderRadius: 0,
            },
            media: {
                height: 269,
                [theme.breakpoints.down(767)]: {
                    height:225,
                },
                [theme.breakpoints.down(360)]: {
                    height:190,
                },
            },
            sectionPT: {
                paddingTop: "20%",
            },
        }),
);

const CarouselItem = (item: VideoCarouselItem) => {
    const _imgStyle: React.CSSProperties = {
        border: "3px solid #D6A122",
        borderRadius: "50%",
        float: "right",
        height: 100,
        width: 100,
    };
    const _innerCardStyle: React.CSSProperties = {
        float: "left",
        padding: "15px",
        width: "100%",
    };
    const classes = useStyles();
    const playerProps: ReactPlayerProps = {
        className: "CarouselvideoWrap",
        controls: true,
        config: { vimeo: { playerOptions: { title: false } } },
    };

    return (
            <Card className={classes.root} key={item.key} style={{borderRight:'1px solid #cfcfcf'}}>
                <CardActionArea>
                    <CardMedia className={classes.media}>
                        <ReactPlayer url={item.videoUrl} {...playerProps} />
                    </CardMedia>
                    <CardContent className="alternateBg" style={_innerCardStyle}>
                        <Grid container>
                            {
                                    item.strategyTestimonial &&
                                    <Grid item xs={12}>
                                        <Typography variant="caption" color="primary">
                                            {item.strategyTestimonial}
                                        </Typography>
                                    </Grid>
                            }
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={1}>
                                    {
                                            item.name &&
                                            <Grid item xs={12}>
                                                <Typography
                                                        variant="h4"
                                                        color="primary"
                                                        className="mb0 floatLeft w100"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Grid>
                                    }
                                    {
                                        item.desc &&
                                            <Grid item xs={12}>
                                                <Typography variant="caption" color="primary">
                                                    {pez(item.desc)}
                                                </Typography>
                                            </Grid>
                                    }
                                    {
                                        item.title &&
                                            <Grid item xs={12}>
                                                <Typography variant="caption" color="primary">
                                                    {item.title}
                                                </Typography>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                            {
                                    item.imageSrc &&
                                    <Grid item xs={12} md={12}>
                                        <img
                                                alt="Video Client Thumb"
                                                src={`/${item.imageSrc || ""}`}
                                                style={_imgStyle}
                                        />
                                    </Grid>
                            }

                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
    );
};
const VideoCarouselGroup: React.FC<VideoCarouselGroupProps> = ({key, name, videos }) => {
    const responsive = {
        lgdesktop: {
            breakpoint: { max: 3000, min: 1920 },
            items: 5,
            slidesToSlide: 4, // optional, default to 1.
        },
        desktop: {
            breakpoint: { max: 1920, min: 1256 },
            items: 4,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1255, min: 464 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    const carouselProps = {
        arrows: true,
        autoPlay: false,
        autoPlaySpeed: 15000,
        containerClass: "carousel-container",
        customTransition: "transform 300ms ease-in-out",
        deviceType: "mobile",
        draggable: true,
        infinite: false,
        itemClass: "carousel-item-padding-40-px",
        keyBoardControl: true,
        responsive: responsive,
        showDots: false,
        ssr: true, // means to render carousel on server-side.
        swipeable: true,
        transitionDuration: 500,
    };

    return (
            <React.Fragment key={key}>
                <div style={{ background: "#01305F", padding: "30px 15px" }}>
                    <Typography variant="h3" className="textWhite textCenter mb0">
                        {name}
                    </Typography>
                </div>

                <div className="pdXsScreenNone">
                    <Carousel {...carouselProps}>
                        {
                            videos.map((item, idx) => {
                                return (
                                        <CarouselItem key={idx} {...item} />
                                );
                            })
                        }
                    </Carousel>
                </div>
            </React.Fragment>
    );
};

export default VideoCarouselGroup;
