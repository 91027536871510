import React  from "react";
import Button from "@material-ui/core/Button";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";


interface StepperButtonProps {
    activeStep: number
    handleBack: Function
    totalSteps: number
    handleResult: Function
    handleNext: Function
    errors: any,
    formikRef: any,
    setViewResultClicked?: Function
    touched: any, dirty:any,
    callingFrom?: string,
    handleClose?: Function,
    disabled?:boolean,
}
const SubmitButton = (props: {setViewResultClicked?: Function, disabled:boolean}) => {
    const {setViewResultClicked, disabled} = props;
    return <Button size="small" type="submit" className="floatRight" variant="contained" color="primary"
                   disabled={disabled}
                   onClick={() => {
                       if(typeof setViewResultClicked === 'function')
                        setViewResultClicked(true);
                   }}>
        View Results
    </Button>
}

const NextButton = (props: {formikRef: any, handleNext: Function, activeStep: number, disabled:boolean, role: number | undefined, user:any}) => {
    const {formikRef, handleNext, activeStep, disabled, role, user} = props;
    let userID:any = (user && user.id) || 0;

    return <Button
            size="small"
            className="floatRight"
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={() => {
                formikRef.current.validateForm().then((data) => {
                    if (Object.keys(data).length === 0) {
                        if ((role === 5 || role === 6)  && activeStep === 0) {
                            if(userID) {
                                const formValue: any = formikRef.current.values;
                                const stepOneValues = {
                                    dob: formValue.dob,
                                    gender: formValue.gender,
                                    healthType: formValue.healthType,
                                    tobacco: formValue.tobacco
                                }
                                localStorage.setItem(`stepOne_${userID}`, JSON.stringify(stepOneValues));
                            }
                        }
                        handleNext();
                    }
                });
            }}
    >
        Next
    </Button>
}

export default function StepperButton(props: StepperButtonProps) {
    const {
        activeStep,
        handleBack,
        totalSteps,
        handleNext,
        errors,
        formikRef,
        disabled,
        //setViewResultClicked,
        callingFrom,
        handleClose
    } = props;

    const {user: {role}, user} = useSelector((state: RootState) => state.auth);

    return (
            <span>
                {   /* show close button in case of invite client */
                        callingFrom === "INVITATION" && activeStep === 0 &&
                        <Button size="small" className="floatLeft" variant="contained"
                                onClick={() => {
                                    if (typeof handleClose === 'function') {
                                        handleClose();
                                    }
                                }}
                                color="primary"
                        >
                            Cancel
                        </Button>
                }
                {
                    activeStep > 0 &&
                    <Button size="small" className="floatLeft" variant="contained"
                        onClick={() => handleBack()}
                        color="primary"
                    >
                        Back
                    </Button>
                }
                {(activeStep === (totalSteps-1)) ?
                        <SubmitButton disabled={(Object.keys(errors).length !== 0)}/>
                :
                    <NextButton formikRef={formikRef} handleNext={handleNext} activeStep={activeStep}
                                disabled={!!disabled}
                                role={role}
                                user={user}
                    />
                }
            </span>
    );
}
