import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Grid, IconButton, Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddRealState from "./AddRealState";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import { dateUtils } from "../../../../../../../utils";
import NumberFormat from "react-number-format";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { Label } from "../../../../../../../components/formInputs";
import TableWrap from "../../../../../../../components/TableWrap/TableWrap";

const useStyles = makeStyles({
	table: {
		minWidth: 767,
	},
	tableHead: {
		backgroundColor: "#EDF3F8",
	},
});

function Row(props: {
	row: any,
	setRecord: Function,
	setIsShowRealEstateModal: Function,
	deleteAccount: Function,
	clientSigned: boolean | number
}) {
	const { row, setRecord, setIsShowRealEstateModal, deleteAccount, clientSigned } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<TableRow>
				<TableCell component="th" scope="row">
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					{`${row.propertyAddress}`}
				</TableCell>
				<TableCell component="th" scope="row">
					<NumberFormat
						value={row.purchasePrice || 0}
						displayType={"text"}
						thousandSeparator={true}
						prefix={"$"}
					/>
				</TableCell>
				<TableCell component="th" scope="row">
					{`${dateUtils.asDbDateFormat(row.datePurchased)}`}
				</TableCell>
				<TableCell component="th" scope="row">
					<NumberFormat
						value={row.currentMarketValue || 0}
						displayType={"text"}
						thousandSeparator={true}
						prefix={"$"}
					/>
				</TableCell>

				<TableCell component="th" scope="row">
					<IconButton aria-label="Info" color="primary">
						<EditIcon onClick={() => {
							setRecord(row);
							setIsShowRealEstateModal(true);
						}} />
					</IconButton>
					<IconButton aria-label="Info" color="primary"
								onClick={(e) => {
									if (clientSigned) {
										e.preventDefault();
										return;
									}
									deleteAccount(row.id);
								}}>
						<DeleteOutlineIcon className="colorRed" />
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell><strong>How was Value Determined</strong></TableCell>
										<TableCell><strong>Ownership</strong></TableCell>
										<TableCell align="right"><strong>Outstanding Mortgage
											Amount</strong></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>
											{row.valueDetermined}
										</TableCell>
										<TableCell>{`${row.ownership ? row.ownership + "%" : ""}`}</TableCell>
										<TableCell align="right">
											<NumberFormat
												value={row.outstandingMortgageAmount || 0}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$"}
											/>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

const RealStateHoldings = (props: any) => {
	const { user, isFromClientDetail, setIsError, setRealEstateVerifyRef, setRealEstateVerified } = props;
	const clientSigned = user && user.enrollment && user.enrollment.clientSigned ? 1 : 0;
	const { updateClientFields } = useActions(AccountActions);
	const [isConfirmCheckbox, setIsConfirmCheckbox] = React.useState(!!(user && user.client && user.client.verifyRealEstate));
	const realStateVerifyRef = React.useRef<any>();
	const classes = useStyles();
	const [isShowRealEstateModal, setIsShowRealEstateModal] = React.useState<Boolean>(false);
	const { getRealEstateList, deleteRealEstateAccount } = useActions(AccountActions);
	const [record, setRecord] = React.useState(undefined);
	const [totalHoldings, setTotalHoldings] = React.useState(0);
	const [data, setData] = React.useState({
		count: 0,
		rows: [],
	});

	useEffect(() => {
		if (realStateVerifyRef.current && typeof setRealEstateVerifyRef === "function") {
			setRealEstateVerifyRef(realStateVerifyRef);
		}
	}, [realStateVerifyRef.current]);

	useEffect(() => {
		setRealEstateVerified(isConfirmCheckbox);
	}, [isConfirmCheckbox]);

	React.useEffect(() => {
		getRealEstate();
	}, [isShowRealEstateModal]);

	const getRealEstate = async () => {
		let resp = await getRealEstateList(user && user.client && user.client.id || 0);
		if (resp && resp.data && resp.data.count > 0) {
			setData(resp.data);
			let currentMarketValue: number = 0;
			for (let index in resp.data.rows) {
				currentMarketValue = Number(resp.data.rows[index].currentMarketValue) + currentMarketValue;
			}
			setTotalHoldings(currentMarketValue);
		} else {
			setData({
				count: 0,
				rows: [],
			});
			setTotalHoldings(0);
		}
	};

	const deleteAccount = async (id: number) => {
		await deleteRealEstateAccount({ id });
		getRealEstate();
	};


	return (
		<Grid container spacing={2} justify="space-between" className="mb15">
			<Grid item xs={12}>
				<span className="hLine" />
				<Typography variant="body1" component="strong" className="mb15 mt20 floatLeft w100">
					<Label label={"Real Estate Holdings"} required={!isFromClientDetail} />
					<Button startIcon={<AddCircleIcon className="floatLeft mr15" />} color="primary" disabled={data && data.count >= 4}
						  className="floatRight mt15Small" style={{ lineHeight: "27px" }} onClick={(e) => {
						if (clientSigned) {
							e.preventDefault();
							return;
						}
						setRecord(undefined);
						setIsShowRealEstateModal(true);
					}}
					>
						Add Real Estate
					</Button>
				</Typography>
				<Typography variant="caption"
							className="textLight mb0 floatLeft w100">
                    Please provide details of your real estate holding(s) below.
				</Typography>

			</Grid>
            {data && data.count > 0 ?
                    <>
                        <Grid item xs={12}>
                            <>
                                <TableWrap>
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell align="left">Address</TableCell>
                                            <TableCell align="left">Purchase Price</TableCell>
                                            <TableCell align="left">Date Purchased</TableCell>
                                            <TableCell align="left">Current Market Value</TableCell>
                                            <TableCell align="left">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data && data.count > 0 &&
                                                data.rows.map((row: any, index) => (
                                                        <Row key={index} row={row} setRecord={(row) => setRecord(row)}
                                                             setIsShowRealEstateModal={(flag: boolean) => setIsShowRealEstateModal(flag)}
                                                             deleteAccount={(id) => deleteAccount(id)}
                                                             clientSigned={clientSigned} />
                                                ))
                                        }
                                    </TableBody>
                                </TableWrap>
                                <Typography variant="subtitle1" component="strong">
                                    <span className="floatRight mt20"> Total Holdings: {totalHoldings > 0 ? <NumberFormat
                                            value={totalHoldings || 0}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                    /> : "N/A"}</span>
                                </Typography>
                            </>
                        </Grid>
                        {!isFromClientDetail &&
                            <Grid item xs={12}>
                                <div style={{ display: "flex" }} id="realStateVerifyRef" ref={realStateVerifyRef}>
                                    <Checkbox style={{ height: "28px", marginRight: "10px" }}
                                                  className="floatLeft pd0 mr10" color={"primary"}
                                                  value={isConfirmCheckbox}
                                                  checked={isConfirmCheckbox}
                                                  onChange={(e, v) => {
                                                      setIsError(!v);
                                                      setIsConfirmCheckbox(v);
                                                      updateClientFields({
                                                          verifyRealEstate: v ? 1 : 0,
                                                          apiType: "REAL_ESTATE",
                                                      }, user && user.client && user.client.id || "", isFromClientDetail);
                                                  }}
                                                  inputProps={{ "aria-label": "uncontrolled-checkbox" }} />
                                        <Typography variant="caption">
                                            <span>I verify that my total real estate holdings are </span>
                                            <NumberFormat
                                                    value={totalHoldings || 0}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"$"}
                                            />.
                                            <span className="requiredHighlight">*</span>
                                        </Typography>
                                    </div>
                                    {
                                            !isConfirmCheckbox &&
                                            <p style={{ margin: 0 }}>
                                                <span style={{ color: "#FF1744", fontSize: 12 }}> Required Field </span>
                                            </p>
                                    }
                                </Grid>
                        }
                    </> : ""
            }
			<AddRealState isFromClientDetail={isFromClientDetail} record={record} user={user}
						  open={isShowRealEstateModal}
						  setOpen={setIsShowRealEstateModal} clientSigned={clientSigned} />
		</Grid>
	);
};

export default RealStateHoldings;