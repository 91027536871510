import { Box, Card, CardContent, Grid } from "@material-ui/core";
import React from "react";
import {Field, reduxForm} from "redux-form";
import { useActions } from "../../../actions";
import * as Actions from "../../../actions/account";
import PrimaryContactDetail from "../../../components/PrimaryContactDetail";
import { unmaskPhoneNumber } from "../../../utils/commonUtils";
import { UpdateButton } from "../../EditAccountPage";
import {Label, SelectField} from "../../../components/formInputs";
import {ADMIN_TYPES, imoRegionDropDown} from "../../../constants";
import {required} from "../../../utils/validation";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";


type AdminProfileTabProps = {
    userId: number
}

type AdminFormData = {}

const AdminProfileTab = (props) => {
    const accountActions = useActions(Actions);
    const { handleSubmit, pristine, submitting, userId, initialValues } = props;
    const [imoRegions, setImoRegions] = React.useState<any>([]);
    const [adminTypes, setAdminTypes] = React.useState<any>(ADMIN_TYPES);
    const user = useSelector((state: RootState) => state.auth.user);
    let imoRegion:any = {
        id: 0,
        value: 'All',
        name: 'All'
    };

    React.useEffect(()=>{
        let regions:any = [imoRegion, ...imoRegionDropDown];
        setImoRegions(regions);
        let adminTypes:any = [];
        if(initialValues && initialValues.role === 1){
            adminTypes = ADMIN_TYPES.filter((val:any) => val.value === "MasterAdmin")
        } else if(initialValues && initialValues.role === 2){
            adminTypes = ADMIN_TYPES.filter((val:any) => val.value !== "MasterAdmin")
        }
        setAdminTypes(adminTypes);
    }, []);

    const [isToggle, setIsToggle] = React.useState<boolean>(false);

    const updateAccount = async (data: any) => {
        delete data.id;
        if (data.phoneNumber) {
            data.phoneNumber = unmaskPhoneNumber(data.phoneNumber);
        }

        const updateAccountData: object = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            image: data.image,
            admin: data.admin
        };
        await accountActions.updateAdminAccount(updateAccountData, userId);
        await accountActions.getAccount(userId, data.role);
    };

    return (
            <Box>
                <h4>Profile information</h4>
                <Card className="muiCard">
                    <CardContent className="muiCardContent">
                        <form onSubmit={handleSubmit(updateAccount)}>
                            <Grid container spacing={2}>
                                <PrimaryContactDetail
                                        role={4}
                                        disabledEmail={true}
                                        disabledPhone={false}
                                />
                                <Grid item xs={12} md={6}>
                                    <Field
                                            fullWidth
                                            name="admin.adminType"
                                            label={<Label label={"Select Admin type"} required={user && user.role === 2 ? false:true}/>}
                                            options={adminTypes}
                                            value={3}
                                            component={SelectField}
                                            validate={user && user.role === 2 ? []:[required]}
                                            disabled={user && user.role === 2 ? true:false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                            fullWidth
                                            name="admin.region"
                                            label={<Label label={"Region"} required={user && user.role === 2 ? false:true}/>}
                                            placeholder="enter region"
                                            component={SelectField}
                                            validate={user && user.role === 2 ? []:[required]}
                                            options={imoRegions}
                                            disabled={user && user.role === 2 ? true:false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <UpdateButton
                                        isUpdated={false}
                                        pristine={pristine}
                                        submitting={submitting}
                                        setIsToggle={setIsToggle}
                                />
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Box>
    );
};

export let AdminProfileTabReduxForm = reduxForm<AdminFormData, AdminProfileTabProps>({
    form: "AdminProfileTab",
    enableReinitialize: true,
})(AdminProfileTab);
