import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {FormikValues} from "../formikFormInputs";
import {CommonProps, DynamicFormFieldType} from "./index";
import {NS} from "../../model/misc";
import {isFunc, wordsToHyphen} from "../../utils/commonUtils";


interface SelProps extends CommonProps, DynamicFormFieldType, FormikValues {
    initVal?: NS;
    onSelect: Function;
}


const SelectBox: React.FC<SelProps> = (props) => {
    const {label, options, onSelect, initVal = ''} = props;

    const [sel, setSel] = React.useState(initVal);

    const handleChange = (event) => {
        setSel(event.target.value);
        if (onSelect && isFunc(onSelect)) {
            onSelect(event.target.value);
        }
    };

    const labelId = `${wordsToHyphen(label)}-select-outlined-label`;

    return (
            <FormControl variant="outlined">
                <InputLabel id={labelId}>
                    {label}
                </InputLabel>
                <Select
                        labelId={labelId}
                        id={`${wordsToHyphen(label)}demo-simple-select-outlined`}
                        value={sel}
                        onChange={handleChange}
                        label={label}
                >
                    {
                            options && options.map((o, index) => (
                                    <MenuItem key={index} value={o}>{o}</MenuItem>
                            ))
                    }
                </Select>
            </FormControl>
    );
};


export default SelectBox;
