import React from "react";
import NumberFormat from "react-number-format";
import { Box, Typography } from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import { trustFeeText } from "../../../../../constants";

const numberWithCommas = (x: string) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function Assumptions(props: any) {
    const { estimationData, strategySlug, registrationMark } = props;
    const { user } = useSelector((state: RootState) => state.auth);
    let growthRate: number|undefined = (user && user.client && user.client.carrier && user.client.carrier.growthRate) || (user && user.demoCarrier && user.demoCarrier.growthRate) || estimationData.assumption_growth_rate;
    if (strategySlug === "kaizen") {
        return (
                <>
                    <Typography variant="body2" component="strong" className="mb0 floatLeft w100">Assumptions:</Typography>
                    {/*Growth Rate of {estimationData.assumption_growth_rate || "6.07" }%.<br/>*/}
                    Growth Rate of&nbsp;{growthRate}%.<br/>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Policy distributions begin at the later of
                        age {estimationData.assumption_age_begins || "65"} or after the lender loan has been repaid
                        (typically the 15th plan year) and continue through
                        age {estimationData.assumption_age_ends || "90"}.</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Management Fee
                        of {estimationData.assumption_management_fee || "0.50"}%.</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Long Term Capital Gains Rate
                        of {estimationData.assumption_long_term_capital_rate || "20"}%.</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Income Tax Rate
                        of {estimationData.assumption_income_tax_rate || "37"}%.</Typography>
                    {estimationData.assumption_state_income_tax_rate ?
                            <Typography variant="caption" className="f12 mb0 floatLeft w100">State Income Tax Rate
                                of {estimationData.assumption_state_income_tax_rate}%.</Typography> : ""}
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Contribution includes {trustFeeText(user && user.client)} per year for the
                        trust fee.</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Trust Pays the life insurance
                        premiums.</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Minimum contribution is  {" "}
                        <NumberFormat
                                value={numberWithCommas(estimationData.assumption_minimum_contribution || "20000")}
                                displayType={"text"}
                                thousandSeparator={true} prefix={"$"}
                                renderText={value => <Box className="f10"
                                                          component="span"
                                >
                                    {value}</Box>}
                        />
                        {" "} with trust fees.</Typography>
                </>
        );
    }

    if (strategySlug === "trizen") {
        return (
                <>
                    <Typography variant="body2" component="strong" className="mb0 floatLeft w100">
                        Assumptions Used for Tri-Zen{(registrationMark) ? <sup>&reg;</sup> : <></>} and Alternative
                        Growth Rate of&nbsp;{growthRate}%.</Typography> <br/>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Growth Rate of 6.07%.</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Distributions beginning at the later of age 65 or after the lender loan has been repaid (typically
                        the 15th plan year) and continue through age 90</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Long term capital gains rate of 20%</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Income tax rate of 37%</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">The after tax and tax deferred investments assume a management fee of 0.50%</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">The index policies include all costs of insurance and fees</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Policy Distributions shown using tax-free policy loans</Typography>
                    <Typography variant="caption" className="f12 mb0 floatLeft w100">Participation in Tri-Zen{(registrationMark) ? <sup>&reg;</sup> : <></>} requires a minimum
                    contribution amount of $20,000 and a participant trust to be established as part of the plan. An
                    additional {trustFeeText(user && user.client)} per year will be added to your five-annual contributions. (The trust pays the
                        premiums and monitors the policy until the loan repayment in approximately the 15th year.)</Typography>
                </>
        );
    }

    return (
            <>
                <b>Assumptions:</b><br/>
                Growth Rate of&nbsp;{growthRate}%.<br/>
                Policy distributions begin at the later of age 65 or after the lender loan has been repaid
                (typically the 15th plan year) and continue through age 90.<br/>
                Management Fee of 0.50%.<br/>
                Long Term Capital Gains Rate of 20%.<br/>
                Income Tax Rate of 37%.<br/>
                Contribution includes {trustFeeText(user && user.client)} per year for the trust fee.<br/>
                Trust Pays the life insurance premiums.<br/>
                Minimum contribution is $20,000 with trust fees.
            </>
    );
}

export default Assumptions;
