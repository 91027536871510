import React  from "react";
import Button from "@material-ui/core/Button";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../../components/dialog";
import LoaderGif from "../../../../../styles/images/loader_nw.gif";
import Typography from "@material-ui/core/Typography";
import { Button as CustomMuiButton } from "../../../../../components/formInputs";
import { useFormikContext } from "formik";
import { addPlus, allocationMapper, InvestmentValues, overRideLabels } from "../../utils/utils";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import TableWrap from "../../../../../components/TableWrap/TableWrap";

export const StepFourPopUp = (props: {open: boolean, setOpen: Function , allocationQuestion :any, title?:string }) => {
    const { open , setOpen, allocationQuestion, title} = props
    const { values } = useFormikContext();
    const InvestmentMapper = values["plus"] ? {
        [InvestmentValues.Conservative]: {
            desc: <Typography variant="caption" className={"mb10"}>The conservative strategy seeks to eliminate a zero credit by leveraging the Classic bonus and the guaranteed 0.90% bonus credited to the policy's annual accumulation value.
                It focuses avoiding a zero credit, but lower interest potential.
                Call out: 2018 and 2022 were able to avoid a 0% interest credit thanks to the 0.90% bonus.
                Hypothetical example assume the product and index allocation options were available during the time shown, which they were not.
            </Typography>,
            question: allocationMapper[InvestmentValues.ConservativePlus],
        },
        [InvestmentValues.Moderate]: {
            desc: <Typography variant="caption" className={"mb10"} >The moderate strategy helps limit zero credits in a given year; you need to be comfortable that you may have a zero return year, but are able to capture the 15% multiplier bonused opportunity.
                Call out: Three years with returns above 20%, with 2 years returning 0%.
                Hypothetical example assume the product and index allocation options were available during the time shown, which they were not.
            </Typography>,
            question: allocationMapper[InvestmentValues.ModeratePlus],
        },
        [InvestmentValues.Aggressive]: {
            desc: <Typography variant="caption" className={"mb10"} >The aggressive mix is designed to help provide the potential for higher interest credits with the Select down years. You take the added risk with the 1% annual asset charge; however, you have greater accumulation potential with a 40% annual interest bonus.
                You need to be comfortable with the possibility of more zeros, but you have the opportunity for higher annual returns.
            </Typography>,
            question: allocationMapper[InvestmentValues.AggressivePlus],
        },
    }:{
        [InvestmentValues.Conservative]: {
            desc: <Typography variant="caption" className={"mb10"}>The conservative strategy seeks to eliminate a zero credit by leveraging the Classic bonus and the guaranteed 0.90% bonus credited to the policy's annual accumulation value.
                It focuses avoiding a zero credit, but lower interest potential.
                Call out: 2018 and 2022 were able to avoid a 0% interest credit thanks to the 0.90% bonus.
                Hypothetical example assume the product and index allocation options were available during the time shown, which they were not.
            </Typography>,
            question: allocationMapper[InvestmentValues.Conservative],
        },
        [InvestmentValues.Moderate]: {
            desc: <Typography variant="caption" className={"mb10"} >The moderate strategy helps limit zero credits in a given year; you need to be comfortable that you may have a zero return year, but are able to capture the 15% multiplier bonused opportunity.
                Call out: Three years with returns above 20%, with 2 years returning 0%.
                Hypothetical example assume the product and index allocation options were available during the time shown, which they were not.
            </Typography>,
            question: allocationMapper[InvestmentValues.Moderate],
        },
        [InvestmentValues.Aggressive]: {
            desc: <Typography variant="caption" className={"mb10"} >The aggressive mix is designed to help provide the potential for higher interest credits with the Select down years. You take the added risk with the 1% annual asset charge; however, you have greater accumulation potential with a 40% annual interest bonus.
                You need to be comfortable with the possibility of more zeros, but you have the opportunity for higher annual returns.
            </Typography>,
            question: allocationMapper[InvestmentValues.Aggressive],
        },
    };

    const modalContent = values["plus"] ? {
        [InvestmentValues.Conservative]: {
            lowerContent: <>
            <Typography className={"mb10 floatLeft w100"} variant="caption">Call out: 2018 and 2022 were able to avoid a 0% interest credit thanks to the 0.90% bonus.
            </Typography>
            <Typography className={"mb10 floatLeft w100"} variant="caption">Hypothetical examples assume the product and index allocation options were available during the time shown, which they were not. These examples are not intended to be provided as a recommendation or advice.
            </Typography>
            <Typography className={"mb10 floatLeft w100"}  variant="caption">
                This chart represent past hypothetical results only and are based on current rates: PIMCO Tactical Balanced ER Index APP [190%] participation rate, Bloomberg US Dynamic Balance II ER Index APP [200%] participation rate, S&P 500 Futures Index ER APP [75%] participation rate, Blended Futures Index APP [130%] participation rate all with a .90% bonus rate, and [5.40%] fixed allocation.
            </Typography>
            <Typography className={"mb10 floatLeft w100"}  variant="caption">
                The back-tested performance is based on the methodology and mechanics of the index retroactively applied to historical market data, as if the index had previously existed, to generate hypothetical performance during the periods of time depicted. This back-tested performance for any constituent that makes up the index may have actual performance and history, and it will also have back-tested data for any period prior to its inception. Back-tested performance may not be a reliable indicator of future results. Prospective application of the methodology and mechanics of the index may not result in performance commensurate with the back-tested returns shown.
            </Typography>
            <Typography className={"mb10 floatLeft w100"}  variant="caption">
                No single crediting method consistently delivers the most interest under all market conditions. These illustrations are hypothetical in nature. Because the life insurance product and indexes did not exist during the entire time frame illustrated, this chart represents hypothetical historical information only and reflects current participation and annual floor rates which are not guaranteed. Actual participation rates that could have been applied over this time frame would have been different from the figures shown here, and in some cases could have been dramatically different depending on a number of factors, including market conditions. These figures represent hypothetical historical results only, are not indicative of future results, and may not be used to predict future results.
            </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    Bonused products may include higher surrender charges, longer surrender periods, lower caps, or other restrictions that are not included in similar products that don’t offer a bonus. The index allocations that offer the interest bonus will generally have lower caps and participation rates. Not all bonuses guarantee that a policy will be credited with an interest bonus every year as some are based on the growth of an index.
                </Typography>
            </>,
        },
        [InvestmentValues.Moderate]: {
            lowerContent: <>
                <Typography className={"mb10 floatLeft w100"} variant="caption">Call out: Two years with returns above 20%, and 2 years you were able to avoid a 0% interest thanks to the fixed allocation.</Typography>
                <Typography className={"mb10 floatLeft w100"} variant="caption">Hypothetical example assume the product and index allocation options were available during the time shown, which they were not. These examples are not intended to be provided as a recommendation or advice.
                </Typography>
                <Typography className={"mb10 floatLeft w100"} variant="caption">This chart represent past hypothetical results only and are based on current rates: PIMCO Tactical Balanced ER Index APP [185%] participation rate, Bloomberg US Dynamic Balance II ER Index APP [205%] participation rate, S&P 500 Futures Index ER APP [80%] participation rate, Blended Futures Index APP [135%] participation rate and with a 15% bonus rate.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    The back-tested performance is based on the methodology and mechanics of the index retroactively applied to historical market data, as if the index had previously existed, to generate hypothetical performance during the periods of time depicted. This back-tested performance for any constituent that makes up the index may have actual performance and history, and it will also have back-tested data for any period prior to its inception. Back-tested performance may not be a reliable indicator of future results. Prospective application of the methodology and mechanics of the index may not result in performance commensurate with the back-tested returns shown.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    No single crediting method consistently delivers the most interest under all market conditions. These illustrations are hypothetical in nature. Because the life insurance product and indexes did not exist during the entire time frame illustrated, this chart represents hypothetical historical information only and reflects current participation and annual floor rates which are not guaranteed. Actual participation rates that could have been applied over this time frame would have been different from the figures shown here, and in some cases could have been dramatically different depending on a number of factors, including market conditions. These figures represent hypothetical historical results only, are not indicative of future results, and may not be used to predict future results.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    Bonused products may include higher surrender charges, longer surrender periods, lower caps, or other restrictions that are not included in similar products that don’t offer a bonus. The index allocations that offer the interest bonus will generally have lower caps and participation rates. Not all bonuses guarantee that a policy will be credited with an interest bonus every year as some are based on the growth of an index.
                </Typography>
            </>,

        },
        [InvestmentValues.Aggressive]: {
            lowerContent: <>
                <Typography className={"mb10 floatLeft w100"} variant="caption">Callout: By including the fixed allocation there are no negative years even taking the 1% annual asset charge into consideration.
                </Typography>
                <Typography className={"mb10 floatLeft w100"} variant="caption">Hypothetical example assume the product and index allocation options were available during the time shown, which they were not. These examples are not intended to be provided as a recommendation or advice.
                </Typography>
                <Typography className={"mb10 floatLeft w100"} variant="caption">These charts represent past hypothetical results only and are based on current rates: PIMCO Tactical Balanced ER Index APP 180% participation rate, Bloomberg US Dynamic Balance II ER Index APP 195% participation rate, S&P 500 Futures Index ER APP 75% participation rate, Blended Futures Index APP 125% participation rate and with a 40% interest bonus and 1% annual asset charge.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    The back-tested performance is based on the methodology and mechanics of the index retroactively applied to historical market data, as if the index had previously existed, to generate hypothetical performance during the periods of time depicted. This back-tested performance for any constituent that makes up the index may have actual performance and history, and it will also have back-tested data for any period prior to its inception. Back-tested performance may not be a reliable indicator of future results. Prospective application of the methodology and mechanics of the index may not result in performance commensurate with the back-tested returns shown.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    No single crediting method consistently delivers the most interest under all market conditions. These illustrations are hypothetical in nature. Because the life insurance product and indexes did not exist during the entire time frame illustrated, this chart represents hypothetical historical information only and reflects current participation and annual floor rates which are not guaranteed. Actual participation rates that could have been applied over this time frame would have been different from the figures shown here, and in some cases could have been dramatically different depending on a number of factors, including market conditions. These figures represent hypothetical historical results only, are not indicative of future results, and may not be used to predict future results.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    Bonused products may include higher surrender charges, longer surrender periods, lower caps, or other restrictions that are not included in similar products that don’t offer a bonus. The index allocations that offer the interest bonus will generally have lower caps and participation rates. Not all bonuses guarantee that a policy will be credited with an interest bonus every year as some are based on the growth of an index.
                </Typography>
            </>,

        },
    }:{
        [InvestmentValues.Conservative]: {
            lowerContent: <>
            <Typography className={"mb10 floatLeft w100"} variant="caption">Call out: 2018 and 2022 were able to avoid a 0% interest credit thanks to the 0.90% bonus
            </Typography>
            <Typography className={"mb10 floatLeft w100"} variant="caption">Hypothetical examples assume the product and index allocation options were available during the time shown, which they were not. These examples are not intended to be provided as a recommendation or advice.
            </Typography>
            <Typography className={"mb10 floatLeft w100"}  variant="caption">
                This chart represents past hypothetical results only and are based on current rates: PIMCO Tactical Balanced ER Index APP [190%] participation rate, Bloomberg US Dynamic Balance II ER Index APP  [200%] participation rate, S&P 500 Futures Index ER APP [75%] participation rate, Blended Futures Index APP [130%] participation rate and with a .90% bonus rate.
            </Typography>
            <Typography className={"mb10 floatLeft w100"}  variant="caption">
                The back-tested performance is based on the methodology and mechanics of the index retroactively applied to historical market data, as if the index had previously existed, to generate hypothetical performance during the periods of time depicted. This back-tested performance for any constituent that makes up the index may have actual performance and history, and it will also have back-tested data for any period prior to its inception. Back-tested performance may not be a reliable indicator of future results. Prospective application of the methodology and mechanics of the index may not result in performance commensurate with the back-tested returns shown.
            </Typography>
            <Typography className={"mb10 floatLeft w100"}  variant="caption">
                No single crediting method consistently delivers the most interest under all market conditions. These illustrations are hypothetical in nature. Because the life insurance product and indexes did not exist during the entire time frame illustrated, this chart represents hypothetical historical information only and reflects current participation and annual floor rates which are not guaranteed. Actual participation rates that could have been applied over this time frame would have been different from the figures shown here, and in some cases could have been dramatically different depending on a number of factors, including market conditions. These figures represent hypothetical historical results only, are not indicative of future results, and may not be used to predict future results.
            </Typography>
            <Typography className={"mb10 floatLeft w100"}  variant="caption">
                Bonused products may include higher surrender charges, longer surrender periods, lower caps, or other restrictions that are not included in similar products that don’t offer a bonus. The index allocations that offer the interest bonus will generally have lower caps and participation rates. Not all bonuses guarantee that a policy will be credited with an interest bonus every year as some are based on the growth of an index.
            </Typography>
            </>,
        },
        [InvestmentValues.Moderate]: {
            lowerContent: <>
                <Typography className={"mb10 floatLeft w100"} variant="caption">Call out: Three years with returns above 20%, with 2 years returning 0%.</Typography>
                <Typography className={"mb10 floatLeft w100"} variant="caption">Hypothetical example assume the product and index allocation options were available during the time shown, which they were not. These examples are not intended to be provided as a recommendation or advice.</Typography>
                <Typography className={"mb10 floatLeft w100"} variant="caption">
                    This chart represent past hypothetical results only and are based on current rates: PIMCO Tactical Balanced ER Index APP [185%] participation rate, Bloomberg US Dynamic Balance II ER Index APP [205%] participation rate, S&P 500 Futures Index ER APP [80%] participation rate, Blended Futures Index APP [135%] participation rate and with a 15% bonus rate.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    The back-tested performance is based on the methodology and mechanics of the index retroactively applied to historical market data, as if the index had previously existed, to generate hypothetical performance during the periods of time depicted. This back-tested performance for any constituent that makes up the index may have actual performance and history, and it will also have back-tested data for any period prior to its inception. Back-tested performance may not be a reliable indicator of future results. Prospective application of the methodology and mechanics of the index may not result in performance commensurate with the back-tested returns shown.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    No single crediting method consistently delivers the most interest under all market conditions. These illustrations are hypothetical in nature. Because the life insurance product and indexes did not exist during the entire time frame illustrated, this chart represents hypothetical historical information only and reflects current participation and annual floor rates which are not guaranteed. Actual participation rates that could have been applied over this time frame would have been different from the figures shown here, and in some cases could have been dramatically different depending on a number of factors, including market conditions. These figures represent hypothetical historical results only, are not indicative of future results, and may not be used to predict future results.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    Bonused products may include higher surrender charges, longer surrender periods, lower caps, or other restrictions that are not included in similar products that don’t offer a bonus. The index allocations that offer the interest bonus will generally have lower caps and participation rates. Not all bonuses guarantee that a policy will be credited with an interest bonus every year as some are based on the growth of an index.
                </Typography>
            </>,

        },
        [InvestmentValues.Aggressive]: {
            lowerContent: <>
                <Typography className={"mb10 floatLeft w100"} variant="caption">Callout: Has the highest average return out of all the strategies at 13.80%, however, also three negative years if you take the 1% annual asset charge into consideration.
                </Typography>
                <Typography className={"mb10 floatLeft w100"} variant="caption">Hypothetical example assume the product and index allocation options were available during the time shown, which they were not. These examples are not intended to be provided as a recommendation or advice.
                </Typography>
                <Typography className={"mb10 floatLeft w100"} variant="caption">
                    This chart represents past hypothetical results only and is based on current rates: PIMCO Tactical Balanced ER Index APP 180% participation rate, Bloomberg US Dynamic Balance II ER Index APP 195% participation rate, S&P 500 Futures Index ER APP 75% participation rate, Blended Futures Index APP 125% participation rate and with a 40% interest bonus and 1% annual asset charge.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    The back-tested performance is based on the methodology and mechanics of the index retroactively applied to historical market data, as if the index had previously existed, to generate hypothetical performance during the periods of time depicted. This back-tested performance for any constituent that makes up the index may have actual performance and history, and it will also have back-tested data for any period prior to its inception. Back-tested performance may not be a reliable indicator of future results. Prospective application of the methodology and mechanics of the index may not result in performance commensurate with the back-tested returns shown.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    No single crediting method consistently delivers the most interest under all market conditions. These illustrations are hypothetical in nature. Because the life insurance product and indexes did not exist during the entire time frame illustrated, this chart represents hypothetical historical information only and reflects current participation and annual floor rates which are not guaranteed. Actual participation rates that could have been applied over this time frame would have been different from the figures shown here, and in some cases could have been dramatically different depending on a number of factors, including market conditions. These figures represent hypothetical historical results only, are not indicative of future results, and may not be used to predict future results.
                </Typography>
                <Typography className={"mb10 floatLeft w100"}  variant="caption">
                    Bonused products may include higher surrender charges, longer surrender periods, lower caps, or other restrictions that are not included in similar products that don’t offer a bonus. The index allocations that offer the interest bonus will generally have lower caps and participation rates. Not all bonuses guarantee that a policy will be credited with an interest bonus every year as some are based on the growth of an index.
                </Typography>
            </>,

        },
    };


    const yearlyPercentage =values["plus"] ? {
        [InvestmentValues.Conservative]: {
                percentage: [
                    {year : 2012 , percentage: 11.16},
                    {year : 2013 , percentage: 18.91},
                    {year : 2014 , percentage: 7.79},
                    {year : 2015 , percentage: 2.02},
                    {year : 2016 , percentage: 9.45},
                    {year : 2017 , percentage: 17.70},
                    {year : 2018 , percentage: 1.88},
                    {year : 2019 , percentage: 17.98},
                    {year : 2020 , percentage: 11.22},
                    {year : 2021 , percentage: 15.24},
                    {year : 2022 , percentage: 1.88},
                    {year : 2023 , percentage: 10.90},
                ] ,
            },
        [InvestmentValues.Moderate]: {
                percentage: [
                    { year: 2012, percentage: 11.63 },
                    { year: 2013, percentage: 20.43 },
                    { year: 2014, percentage: 7.82 },
                    { year: 2015, percentage: 1.24 },
                    { year: 2016, percentage: 9.67 },
                    { year: 2017, percentage: 18.98 },
                    { year: 2018, percentage: 1.08 },
                    { year: 2019, percentage: 19.36 },
                    { year: 2020, percentage: 11.72 },
                    { year: 2021, percentage: 16.26 },
                    { year: 2022, percentage: 1.08 },
                    { year: 2023, percentage: 11.34 },
                ],
            },
            [InvestmentValues.Aggressive]: {
                percentage: [
                    { year: 2012, percentage: 12.55 },
                    { year: 2013, percentage: 22.70 },
                    { year: 2014, percentage: 8.07 },
                    { year: 2015, percentage: 0.46 },
                    { year: 2016, percentage: 10.26 },
                    { year: 2017, percentage: 21.11 },
                    { year: 2018, percentage: 0.28 },
                    { year: 2019, percentage: 21.50 },
                    { year: 2020, percentage: 12.62 },
                    { year: 2021, percentage: 17.89 },
                    { year: 2022, percentage: 0.28 },
                    { year: 2023, percentage: 12.14 },
                ],
            }
        }: {
        [InvestmentValues.Conservative]: {
            percentage: [
                {year : 2012 , percentage: 12.61},
                {year : 2013 , percentage: 22.28},
                {year : 2014 , percentage: 8.39},
                {year : 2015 , percentage: 1.17},
                {year : 2016 , percentage: 10.46},
                {year : 2017 , percentage: 20.78},
                {year : 2018 , percentage: 1.00},
                {year : 2019 , percentage: 21.12},
                {year : 2020 , percentage: 12.68},
                {year : 2021 , percentage: 17.70},
                {year : 2022 , percentage: 1.00},
                {year : 2023 , percentage: 12.28},
            ] ,
        },
        [InvestmentValues.Moderate]: {
            percentage: [
                { year: 2012, percentage: 13.19 },
                { year: 2013, percentage: 24.19 },
                { year: 2014, percentage: 8.42 },
                { year: 2015, percentage: 0.20 },
                { year: 2016, percentage: 10.73 },
                { year: 2017, percentage: 22.37 },
                { year: 2018, percentage: 0.00 },
                { year: 2019, percentage: 22.86 },
                { year: 2020, percentage: 13.31 },
                { year: 2021, percentage: 18.97 },
                { year: 2022, percentage: 0.00 },
                { year: 2023, percentage: 12.83 },
            ],
        },
        [InvestmentValues.Aggressive]: {
            percentage: [
                { year: 2012, percentage: 14.34 },
                { year: 2013, percentage: 27.02 },
                { year: 2014, percentage: 8.73 },
                { year: 2015, percentage: -0.77 },
                { year: 2016, percentage: 11.48 },
                { year: 2017, percentage: 25.04 },
                { year: 2018, percentage: -1.00 },
                { year: 2019, percentage: 25.56 },
                { year: 2020, percentage: 14.42 },
                { year: 2021, percentage: 21.01 },
                { year: 2022, percentage: -1.00 },
                { year: 2023, percentage: 13.83 },
            ],
        }
    }

    const sellerDialogProps: GenericDialogProps = {
        "aria-describedby": "seller-agreement-dialog",
        content: (
                <>
                    <Typography variant="caption"
                                className="floatLeft mb10">
                        {InvestmentMapper[values["InvestmentType"]] && InvestmentMapper[values["InvestmentType"]].question.map((code, index) => {
                            return allocationQuestion && allocationQuestion[code] &&

                                    (index == 0 ? "" : "/ ") +((overRideLabels[addPlus(values["plus"],values["InvestmentType"])] && overRideLabels[addPlus(values["plus"],values["InvestmentType"])][code]) || allocationQuestion[code].label)
                        })}
                    </Typography>
                    { modalContent[values["InvestmentType"]] && modalContent[values["InvestmentType"]].lowerContent}
                    <TableWrap>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="default">Policy year</TableCell>
                                <TableCell padding="default">Allocation Option return</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                    yearlyPercentage[values["InvestmentType"]] && yearlyPercentage[values["InvestmentType"]].percentage.map((data)=>{
                                        return <>
                                            <TableRow>
                                                <TableCell padding="default">{data.year}</TableCell>
                                                <TableCell padding="default">{data.percentage}</TableCell>
                                            </TableRow>
                                        </>
                                    })
                            }
                        </TableBody>
                    </TableWrap>
                </>
        ),
        title: title +" Detail",
        fullWidth: true,
        keepMounted: false,
        maxWidth: "md",
        onClose: () => setOpen(false),
        open: open,
    }
    return<GenericDialog {...sellerDialogProps} />
}