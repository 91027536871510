import {ActionType, CleanResponse} from '../model';
import {BN} from "../model/misc";

type ConfirmOnBoardingType = (id: string, role: number, setPreferred: BN) => (dispatch: Function, store: Function, api: any) => Promise<any>;

export interface AdminActionsActType {
    confirmOnBoarding: ConfirmOnBoardingType
}

const ADMIN_ACTIONS_API_PATH = 'admin-actions';


/**
 * Admin Actions Confirm OnBoarding for servicing (Ghost clients, ilia Agents, ilia IMOs)
 * @param id
 * @param role
 * @param setPreferred
 */
export const confirmOnBoarding: ConfirmOnBoardingType = (id, role, setPreferred = true) => async (dispatch, store, api) => {
    try {
        const payload = {id, role, setPreferred};
        const res: CleanResponse = await api.put(`${ADMIN_ACTIONS_API_PATH}/onboarding`, payload);

        dispatch({type: ActionType.ALERT, payload: {message: res.message, statusCode: 200}});

        return dispatch({type: ActionType.ADMIN_ACTIONS_ONBOARDING, payload: res.data});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};
