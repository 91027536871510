import React, { useEffect } from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import { NumberTextField } from "../../../../../../../components/formikFormInputs";
import * as Yup from "yup";
import { Formik } from "formik";
import GetFormContent from "../../Beneficiaries/BeneficiariesList/GetFormContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import { payloadDataHandler } from "../index";

const Assets = (props: any) => {
	const {
		assetRef,
		autoSaveTrigger,
		financialAndLiabilitiesArr,
		formType,
		isFromClientDetail,
		setAssetsVerified,
		setAssetsVerifyRef,
		setFinancialAndLiabilitiesArr,
		setFormType,
		setSaveFormContent,
		user,
	} = props;
	const clientSigned = user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? 1 : 0;
	const assetsVerifyRef = React.useRef<any>();
	const [isConfirmCheckbox, setIsConfirmCheckbox] = React.useState(!!(user && user.client && user.client.verifyAssets));

	useEffect(() => {
		if (assetsVerifyRef.current && typeof setAssetsVerifyRef === "function") {
			setAssetsVerifyRef(assetsVerifyRef);
		}
	}, [assetsVerifyRef.current]);

	useEffect(() => {
		setAssetsVerified(isConfirmCheckbox);
	}, [isConfirmCheckbox]);
	const fieldObj = [
		{
			id: "cashInBank",
			name: "cashInBank",
			helperFieldText: "",
			label: <Label label={"Cash in Bank"} required={false} />,
			isRequired: true,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "primaryRealEstate",
			name: "primaryRealEstate",
			helperFieldText: "",
			label: <Label label={"Primary Real Estate"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "otherRealEstateHoldings",
			name: "otherRealEstateHoldings",
			helperFieldText: "",
			label: <Label label={"Other Real Estate Holdings"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		/*{
			id: "receivables",
			name: "receivables",
			helperFieldText: "",
			label: <Label label={"Receivables"} required={false} />,
			isRequired: false,
			className: "mt10",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},*/
		{
			id: "stockBondAndSecurity",
			name: "stockBondAndSecurity",
			helperFieldText: "",
			label: <Label label={"Stocks, Bonds, Securities"} required={false} />,
			isRequired: false,
			className: "mt10",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "personalProperty",
			name: "personalProperty",
			helperFieldText: "",
			label: <Label label={"Personal Property"} required={false} />,
			isRequired: false,
			className: "mt10",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "businessEquity",
			name: "businessEquity",
			helperFieldText: "",
			label: <Label label={"Business Equity"} required={false} />,
			isRequired: false,
			className: "mt10",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "lifeInsuranceCashValue",
			name: "lifeInsuranceCashValue",
			helperFieldText: "",
			label: <Label label={"Life Insurance Cash Value"} required={false} />,
			isRequired: false,
			className: "mt10",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "iraOrSep",
			name: "iraOrSep",
			helperFieldText: "",
			label: <Label label={"401K, IRA, or SEP"} required={false} />,
			isRequired: false,
			className: "mt10",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "annuities",
			name: "annuities",
			helperFieldText: "",
			label: <Label label={"Annuities"} required={false} />,
			isRequired: false,
			className: "mt10",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "otherAssets",
			name: "otherAssets",
			helperFieldText: "",
			label: <Label label={"Other Assets - Provide Details"} required={false} />,
			isRequired: false,
			className: "mt10",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			variant: "outlined",
			size: {
				md: 6,
				xl: 4,
			},
			disabled: !!clientSigned
		},
		/*{
			id: "otherAssetDetails",
			name: "otherAssetDetails",
			helperFieldText: "",
			label: <Label label={"Other Assets Details"} required={false} />,
			isRequired: false,
			className: "mt10",
			filedType: "string",
			component: TextField,
			variant: "outlined",
			size: {
				md: 12,
				xl: 12,
			},
			disabled: !!clientSigned
		},*/
	];

	/*Set initial values*/
	const initialValues = {};

	fieldObj.forEach((field) => {
		initialValues[field.name] = user && user.client && user.client[field.name] || "";
	});

	let keysValidation = {};
	const validationSchema = Yup.object().shape(keysValidation);

	return (

		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<Formik
					innerRef={assetRef}
					initialValues={initialValues}
					enableReinitialize={true}
					validationSchema={validationSchema}
					validateOnChange={true}
					onSubmit={async (values: any, { setSubmitting }) => {
						try {
							let payload: any = payloadDataHandler(values, "ASSETS", isConfirmCheckbox);

							const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "ASSETS");
							if (index > -1) {
								financialAndLiabilitiesArr.splice(index, 1);
							}
							setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
								...payload,
							}]);

							setTimeout(() => {
								setSubmitting(false);
							}, 400);
						} catch (e) {
							return false;
						}
					}}
				>
					{
						(formikProps) => {

							let totalValues: number = 0;
							Object.keys(formikProps.values).map((key) => {
								/*if (key === "otherAssetDetails") {
									return;
								}*/
								const value = formikProps.values[key];
								totalValues = totalValues + (numeral(value).value() || 0);
								return true;
							});
							return (
								<form className="floatLeft w100" onBlur={autoSaveTrigger}
									  onSubmit={formikProps.handleSubmit}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography variant="body1" component="strong"
														className="floatLeft w100  mb20">
												Assets
												<span className="floatRight">
                                                            Total Assets:
                                                            <NumberFormat
																value={totalValues || 0}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$"}
															/>
                                                        </span>

											</Typography>
											<Typography variant="caption"
														className="textLight mb15 floatLeft w100">Fill out or
												verify any assets you have
												below making any necessary changes. If a particular field
												doesn’t apply you can leave it blank.
											</Typography>
										</Grid>
									</Grid>
									<GetFormContent isFromClientDetail={isFromClientDetail}
													fieldObj={fieldObj} {...formikProps}
													enableOnChangeSubmit={false}
													setSaveFormContent={setSaveFormContent}
													formType={formType} setFormType={setFormType}
									/>
									{!isFromClientDetail &&
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<div style={{ display: "flex", alignItems: "center" }}
													 className="mt20" ref={assetsVerifyRef}
													 id="assetsVerifyRef">
													<Checkbox
														style={{ height: "28px", marginRight: "10px" }}
														className="floatLeft pd0 mr10" color={"primary"}
														value={isConfirmCheckbox}
														checked={isConfirmCheckbox}
														onChange={(e, v) => {
															console.log("v -=-=-==-=-", v);
															setIsConfirmCheckbox(v);
															if (!!formType && typeof setFormType === "function") {
																setFormType(formType);
															}
														}}
														inputProps={{ "aria-label": "uncontrolled-checkbox" }} />
													<Typography variant="caption">
														<span> I verify that my total assets are </span>
														<NumberFormat
															value={totalValues || 0}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$"}
														/>.
														<span className="requiredHighlight">*</span>
													</Typography>
												</div>
												{
													!isConfirmCheckbox &&
													<p style={{ margin: 0 }}>
                                                                        <span style={{
																			color: "#FF1744",
																			fontSize: 12,
																		}}> Required Field </span>
													</p>
												}
											</Grid>
										</Grid>
									}
								</form>
							);
						}}
				</Formik>
			</Grid>
		</Grid>
	);
};

export default Assets;