import React from 'react';
import {DialogActions} from '@material-ui/core';

type DialogActionProps = { children: React.ReactNode | any; disableSpacing?: boolean; };

const Actions: React.FC<DialogActionProps> = ({children, disableSpacing}) => {
    return (
            <DialogActions disableSpacing={disableSpacing}>
                {
                    children
                }
            </DialogActions>
    );
};

export default Actions;
