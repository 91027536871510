import React from 'react';
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import loginlogo from "../styles/images/logo-ilia.png";
import { Field, reduxForm } from "redux-form";
import { Button, TextField, PhoneNumberField } from "./formInputs";
import { required } from '../utils/validation';

import login from "../styles/images/login.png";
import { PASSWORD_VALIDATE_REGEX, PWD_ONE_LETTER_REGEX, PWD_ONE_CAPITAL_LETTER_REGEX, PWD_ONE_NUMBER_REGEX, PWD_MIN_EIGHT_CHAR_REGEX, PWD_ONE_SPECIAL_CHAR } from '../constants';
import PasswordRequired from '../components/PasswordRequired';
import { createStyles, makeStyles } from "@material-ui/core/styles";

const validate = (values: any) => {
	const errors: any = {}
	if (!values.password) {
		errors.password = 'Required';
	}
	if (!values.confirmPassword) {
		errors.confirmPassword = 'Required';
	} else if (values.confirmPassword !== values.password) {
		errors.confirmPassword = 'Password mismatched';
	}
	if (values.password && !PASSWORD_VALIDATE_REGEX.test(values.password)) {
		errors.password = true;
	}

	return errors
}

interface eventType {
	target: {
		value: string
	}
}
const useStyles = makeStyles((theme) =>
        createStyles({

            logInWrap: {
                "&::after": {
                    content: "\"\"",
                    position: "absolute",
                    background: "#3971EC",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: "42%",
                    [theme.breakpoints.down("sm")]: {
                        position: "inherit",
                        background: "transparent",
                    },
                },

            },
            textWhite: {
                color: "#FFFFFF",
            },
            textCenter: {
                textAlign: "center",
            },
            mt10: {
                marginTop: 10,
            },
            mr30: {
                marginRight: 30,
            },
            loginCard: {
                margin: "auto",
                width: 865,
                boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
                background: "transparent !important",
                position: "absolute",
                left: 0,
                right: 0,
                zIndex: 999,
                border: 0,
                top: "20%",
                padding: "2%",
                [theme.breakpoints.down("sm")]: {
                    marginBottom: 40,
                    background: "#ffffff !important",
                    width: "92%",
                },
            },
            muiCardContent: {
                padding: 15,
                float: "left",
                width: "100%",
                position: "relative",
            },
            loginForm: {
                padding: "60px 15px 50px",
                float: "left",
                width: "100%",
            },
            margin0: {
                margin: 0,
            },
            titleMain: {
                fontSize: 26,
                lineHeight: "34px",
                textAlign: "center",
                margin: 0,
                float: "right",
            },
            dpNone: {
                [theme.breakpoints.down(992)]: {
                    display: "none",
                },
            },
            floatRight: {
                float: "right",
            },
            imgResponsive: {
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginTop: "25%",
            },
            clickableLink: {
                display: "inline-flex",
                alignItems: "center",
                verticalAlign: "middle",
            },

            clickableLinksvg: {
                float: "left",
            },
            mr10: {
                marginRight: 10,
            },
        }),
);
function SetGroupLinkParticipantPassword(props: any) {
    const classes = useStyles();
	const { pristine, submitting } = props;

	const [oneLetter, setOneLetter] = React.useState(false);
	const [oneNumber, setOneNumber] = React.useState(false);
	const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
	const [eightCharacter, setEightCharacter] = React.useState(false);
	const [oneSpecialChar, setOneSpecialChar] = React.useState(false);

	const onPassWordChange = (e: eventType): void => {
		const val = e.target.value;
		PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false)
		PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
		PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
		PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
		PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
	}

	return (
            <div className={classes.logInWrap}>
                <Grid container>
                    <Grid item xs={12}>
                        <Card className={classes.loginCard}>
                            <CardContent className={classes.muiCardContent}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                                        <img alt="loginLogo" src={loginlogo}/>

                                        <form className={classes.loginForm + " " + classes.margin0}>
											<Field
												fullWidth
												name="email"
												component={TextField}
												placeholder="Email"
												validate={required}
											/>
											<Field
												fullWidth
												name="phoneNumber"
												component={PhoneNumberField}
												placeholder="Phone Number"
												validate={required}
											/>
											<Field
												fullWidth
												name="password"
												component={TextField}
												type="password"
												placeholder="Password"
												validate={required}
												onChange={(e: eventType) => onPassWordChange(e)}
											/>
											<Field
												fullWidth
												name="confirmPassword"
												component={TextField}
												type="password"
												placeholder="Confirm Password"
												validate={required}
											/>
											<Button
												type="submit"
												label="LOGIN IN TO ilia"
												fullWidth
												variant="contained"
												color="primary"
												className="btnPrimary"
												disabled={pristine || submitting}
												loading={submitting}
											/>
										</form>
										<PasswordRequired oneLetter={oneLetter} oneNumber={oneNumber} oneCapitalLetter={oneCapitalLetter} eightCharacter={eightCharacter} oneSpecialChar={oneSpecialChar} />
									</Grid>
									<Grid item xs={12} sm={6} md={6} lg={6} className={classes.dpNone}>
										<Typography variant="h3" align="right"
                                                    className={classes.textWhite + " " + classes.mr30 + " " + classes.titleMain}>
											Create <br />New Password
										</Typography>
										<img alt="login" className={classes.floatRight + " " + classes.imgResponsive} src={login} />
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
            </div>
	)
}

export default reduxForm({
	form: "GroupLinkSetPassword",  // a unique identifier for this form
	validate
})(SetGroupLinkParticipantPassword);