import * as React from "react";
import { IconButton, TablePagination } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                float:'right',
                flexShrink: 0,
                color: theme.palette.text.secondary,
                marginLeft: theme.spacing(2.5),
            },
        }),
);

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
            <div className={classes.root}>
                <IconButton
                        className="paginationBtn"
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon/> : <FirstPageIcon/>}
                </IconButton>
                <IconButton className="paginationBtn" onClick={handleBackButtonClick} disabled={page === 0}
                            aria-label="previous page">
                    {theme.direction === "rtl" ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                        className="paginationBtn"
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="next page"
                >
                    {theme.direction === "rtl" ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                        className="paginationBtn"
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon/> : <LastPageIcon/>}
                </IconButton>
            </div>
    );
}

function Pagination(props: any) {
    const { setPage, setRowsPerPage, accountList, rowsPerPage, page } = props;

    function handleChangePage(
            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
            newPage: number,
    ) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (accountList && accountList.count ?
                    <TablePagination
                            component='div'
                            className="mt10 mb20 floatRight"
                            count={accountList.count || 0}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100, { label: "All", value: accountList.count }]}
                            page={page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                    /> : <></>
    );
}

export default Pagination;