import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { ucFirst, upperCase } from "../utils/commonUtils";

interface SimpleBreadcrumbsProps {
    history: { push(url: string): void },
    crumbObject: object
}

export default function SimpleBreadcrumbs(props: SimpleBreadcrumbsProps) {
    const { history, crumbObject } = props;

    const linkString = () => {
        let linkArr: any[] = [];
        Object.entries(crumbObject).forEach(([key, value]: any[]) => {
            if (value) {
                linkArr.push(<Typography variant="body2" component="strong"><Link
                        key={key}
                        color="primary"
                        className={"clickableLink"}
                        onClick={() => history.push(value)}>
                    {upperCase(key)}
                </Link></Typography>);
            } else {
                linkArr.push(<Typography variant="body2" className="mb0"
                                         key={key}><strong>{ucFirst(key)}</strong></Typography>);
            }
        });
        return linkArr;
    };

    return (
            <Breadcrumbs aria-label="breadcrumb" className=" Breadcrumbs floatLeft w100 mb20 mt10">
                {Object.keys(crumbObject).length && linkString().map((v) => v)}
            </Breadcrumbs>
    );
}
