import Typography from "@material-ui/core/Typography/Typography";
import { Button, Dialog } from "@material-ui/core";
import { Button as FormikButton, Label } from "../../../../../components/formInputs";
import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { DialogTitleProps } from "../modals/ViewDocument";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { ErrorCommon, TextField, TextFieldCommon } from "../../../../../components/formikFormInputs";
import MuiDialogContent from "@material-ui/core/DialogContent";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: "18px",
                color: theme.palette.grey[500],
            },
        });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon className="f20"/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
export default function RejectDocModal(props: { open: boolean, setRejectDocPopup: Function, submitDocRejection: Function }) {
    const { open, setRejectDocPopup, submitDocRejection } = props;
    const handleClose = () => {
        setRejectDocPopup(false);
    };
    const initialValues = {
        noteText: "",
    };
    const validate = ((value: any) => {
        const errors: { noteText?: string } = {};
        if (!value.noteText) {
            errors.noteText = "Required";
        }
        return errors;
    });
    const fieldObj = [
        {
            name: "noteText",
            label: <Label label={"Note Text"} required={true}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "mb30",
            multiline: true,
            rows: 6,
        },
    ];
    return (
            <Dialog
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Reject Document
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="body1">
                        Please leave a brief note explaining why you have rejected this document.
                    </Typography>
                    <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validate={values => validate(values)}
                            validateOnChange={true}
                            onSubmit={async (values, { setSubmitting }) => {
                                submitDocRejection(values);
                            }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              setFieldValue,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              dirty,
                          }) => (<form onSubmit={handleSubmit}>
                            {fieldObj.map((field, index) => {
                                return (
                                        <div key={index} className="mt30">
                                            <TextFieldCommon
                                                    {...field}
                                                    values={values}
                                                    onChange={handleChange}
                                            />
                                            <ErrorCommon errors={errors} name={field.name}
                                                         touched={touched}/>
                                        </div>
                                );
                            })
                            }
                            <Button
                                    className=" floatLeft"
                                    type="button"
                                    size="small"
                                    variant="contained"
                                    color="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <FormikButton
                                    fullWidth={false}
                                    type="submit"
                                    label="Reject Document"
                                    variant="contained"
                                    className="floatRight"
                                    color="primary"
                                    disabled={!dirty || isSubmitting}
                                    loading={isSubmitting}
                            />
                        </form>)}

                    </Formik>
                </DialogContent>
            </Dialog>
    );
}