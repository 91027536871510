import React from 'react';
import { createStyles, makeStyles, Theme, WithStyles } from '@material-ui/core/styles';
import {
    Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';

import { asDbDateFormat } from "../../utils/dateUtils";
import GetAppIcon from "@material-ui/icons/GetApp";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}
interface TableProps {
    onClickDocument: Function
    reportData: any,
    showDownloadOption?: boolean,
    onClickDownload: Function,
    icon?:any,
    columns?:string[]
    showPdfFile?: (pdfurl: string) => void
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
        },
    }),
);

export default function ClientDocments(props: TableProps) {
    const { onClickDownload, onClickDocument, reportData, showDownloadOption , icon , columns=['Name','Date Created','Download'] } = props;


    const classes = useStyles();

    return (
        <Table aria-label="simple table">
            <TableHead style={{ background: 'rgb(212 226 233)' }}>
                <TableRow>
                    <TableCell>{columns[0]}</TableCell>
                    <TableCell>{columns[1]}</TableCell>
                    {showDownloadOption && <TableCell>{columns[2]}</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    reportData && reportData.map((data, index) => {
                        if(!data.name){
                            return <></>
                        }
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <Button onClick={() => { onClickDocument(index, data) }} color={"primary"} variant="text">{data.name}</Button>
                                </TableCell>
                                <TableCell>
                                    {asDbDateFormat(data.updatedAt)}
                                </TableCell>
                                {
                                    showDownloadOption &&
                                    <TableCell>
                                        <IconButton aria-label="delete" className={classes.margin}
                                            size="small"
                                            onClick={() => { onClickDownload(index, data) }}>
                                            {icon ? icon : <GetAppIcon />}
                                        </IconButton>
                                    </TableCell>
                                }
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    );
}
