import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import theme from "./theme";

const NIWTheme = createMuiTheme({
            palette: {
                primary: {
                    light: "#01305F",
                    main: "#01305F",
                    dark: "#01305F",
                    contrastText: "#fff",
                },
                secondary: {
                    light: "#FFFFFF",
                    main: "#FFFFFF",
                    dark: "#FFFFFF",
                    contrastText: "#000000",
                },
            },
            typography: {
                h1: {
                    fontFamily: "'Playfair Display', serif",
                    marginBottom: "1.3rem",
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "32px",
                        fontWeight: "900",
                        lineHeight: "42px",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "42px ",
                        fontWeight: "900 ",
                        lineHeight: "63px ",
                    },
                    [ theme.breakpoints.up("lg") ]: {
                        fontSize: "48px",
                        fontWeight: "900 ",
                        lineHeight: "63px",
                        letterSpacing: "0 ",
                    },

                },
                h2: {
                    fontFamily: "'Playfair Display', serif ",
                    marginBottom: 0,
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "32px ",
                        fontWeight: "900 ",
                        letterSpacing: "-0.25px ",
                        lineHeight: "45px ",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "42px ",
                        fontWeight: "900 ",
                        letterSpacing: "0 ",
                        lineHeight: "63px ",
                    },
                    [ theme.breakpoints.up("lg") ]: {
                        fontSize: "42px",
                        letterSpacing: "-0.4px",
                        lineHeight: "62px",
                    },
                },
                h3: {
                    fontFamily: "'Playfair Display', serif ",
                    marginBottom: "1.3rem",
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "31.25px ",
                        fontWeight: "900",
                        lineHeight: "32px ",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "36px",
                        fontWeight: "900 ",
                        lineHeight: "55px",
                    },
                },
                h4: {
                    fontFamily: "'Playfair Display', serif",
                    marginBottom: "1.3rem",
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "25px ",
                        lineHeight: "36px ",
                        fontWeight: 'bold'
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "28px ",
                        letterSpacing: "0.07px ",
                        lineHeight: "39px ",
                        fontWeight: 'bold'
                    },
                },
                h5: {
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "22px",
                        fontFamily: "'Roboto', sans-serif",
                        letterSpacing: "0",
                        lineHeight: "33px",
                        marginBottom: "1.3rem",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "22px",
                        fontFamily: "'Roboto', sans-serif",
                        letterSpacing: "0",
                        lineHeight: "33px",
                        marginBottom: "1.3rem",
                    },
                },
                h6: {
                    fontFamily: "'Roboto', sans-serif",
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: "500",
                        letterSpacing: "0.07px",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "20px ",
                        fontWeight: "500 ",
                        letterSpacing: "0.07px ",
                        lineHeight: "32px ",
                    },
                },
                body1: {
                    fontFamily: "'Roboto', sans-serif ",
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "16px",
                        lineHeight: "24px",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "18px",
                        lineHeight: "27px",
                    },
                },
                body2: {
                    fontFamily: "'Roboto', sans-serif ",
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "14px",
                        lineHeight: "21px",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "16px",
                        letterSpacing: "0 ",
                        lineHeight: "24px",
                    },
                },
                subtitle1: {
                    fontFamily: "'Roboto', sans-serif",
                    marginBottom: 15,
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "18px",
                        lineHeight: "27px",
                        letterSpacing: "0.1px",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "20px",
                        letterSpacing: "0.11px",
                        lineHeight: "30px",
                        marginBottom: "10px",
                    },
                },
                subtitle2: {
                    fontFamily: "'Roboto', sans-serif",
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "18px",
                        lineHeight: "27px",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "18px",
                        letterSpacing: "0.12px",
                        lineHeight: "27px",
                        marginBottom: "10px",
                    },
                },
                caption: {
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "16px",
                        letterSpacing: "0.4px",
                        lineHeight: "21px",
                        fontFamily: "'Roboto', sans-serif",
                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "16px",
                        letterSpacing: "0.4px",
                        lineHeight: "24px",
                        fontFamily: "'Roboto', sans-serif",
                    },

                },
                overline: {
                    [ theme.breakpoints.up("xs") ]: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        letterSpacing: "0.97px",
                        lineHeight: "21px",
                        marginBottom: "1.3rem",
                        fontFamily: "'Roboto', sans-serif",

                    },
                    [ theme.breakpoints.up("md") ]: {
                        fontSize: "16px",
                        fontWeight: "bold",
                        letterSpacing: "1.11px",
                        lineHeight: "24px",
                        marginBottom: "1.3rem",
                        fontFamily: "'Roboto', sans-serif",
                    },
                },
            },
            overrides: {
                MuiTypography: {
                    root: {
                        margin: 'initial'
                    },
                    colorSecondary: {
                        color: '#000 !important',
                    }
                },
                MuiFormHelperText: {
                    filled: {
                        fontSize: "12px !important",
                        lineHeight: "14px !important",
                    },
                },
                MuiListItemText: {
                    primary: {
                        marginBottom: "0",
                    },
                },
                MuiFormControl: {
                    root: {
                        display: "flex",
                    },
                    fullWidth: {
                        maxWidth: "100%",
                        background: "#ffffff !important",
                    },
                },
                MuiFormControlLabel: {
                    root: {
                        display: "flex",
                        verticalAlign: "top",
                        alignItems: "flex-start",
                    },
                },
                MuiDialog: {
                    paper: {
                        margin: 10,
                    },
                },
                MuiDialogActions: {
                    root: {
                        display: "flex",
                    },
                },
                MuiTooltip: {
                    tooltip: {
                        backgroundColor: "#2f2f2f",
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#ffffff",
                        fontWeight: "normal",
                        margin: "0",
                        boxShadow: "0 0 15px 0px #8c8c8c",
                    },
                    arrow: {
                        color: "#2f2f2f",
                    },
                },
                MuiBackdrop: {
                    root: {
                        backgroundColor: "rgb(255 255 255 / 70%)",
                    },
                },
                MuiIconButton: {
                    root: {
                        padding: "4px",
                    },
                },

                MuiListItem: {
                    secondaryAction: {
                        paddingBottom: "0",
                        paddingTop: "0",
                        paddingRight: "192px",
                    },
                },

                MuiButton: {
                    root: {
                        textTransform: "none",
                        padding: "10px 50px",
                        borderRadius: 50,
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "normal",
                        fontFamily: "'Roboto', sans-serif",
                        whiteSpace: "pre",
                    },
                    containedSizeSmall: {
                        padding: "6px 25px",
                        fontSize: "16px",
                    },
                    containedSizeLarge: {
                        padding: "15px 50px",
                        borderRadius: 10,
                        minWidth: 250,
                    },
                    outlinedSizeLarge: {
                        padding: "15px 50px",
                        borderRadius: 10,
                        color: '#01305F',
                        fontSize: 16,
                        fontWeight: 500,
                        minWidth: 250,
                    },
                    containedSecondary: {
                        backgroundColor: "#cfcfcf",
                    },
                    contained: {
                        boxShadow: "none",
                    },
                    outlined: {
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        // fontWeight: "bold",
                    },
                    outlinedSizeSmall: {
                        padding: "6px 25px",
                        minWidth: "200px",
                        fontSize: "18px",
                        fontWeight: 'normal',
                    },
                    fullWidth: {
                        maxWidth: "100%",
                    },
                },

                MuiSelect: {
                    selectMenu: {
                        marginBottom: 0,
                        height: "18px",
                        fontSize: "16px",
                        lineHeight: "20px",
                        width: "100%",
                    },
                },
                MuiInputBase: {
                    root: {
                        marginBottom: 0,
                    },
                    input: {
                        height: "25px !important",
                        fontSize: "18px !important",
                        marginBottom: 0,
                        lineHeight: "28px",
                    },
                },
                MuiFormLabel: {
                    root: {
                        color: "#000 !important",
                        fontSize: "16px",
                        lineHeight: "20px !important",
                        whiteSpace: "pre",
                        marginBottom: "0 !important",
                    },
                },
                MuiTab: {
                    wrapped: {
                        fontSize: 16,
                    },
                    wrapper: {
                        display: "inline-block",
                    },

                },
                MuiListItemIcon: {
                    root: {
                        minWidth: "auto",
                    },
                },

                MuiCardHeader: {
                    content: {
                        tittle: {
                            color: "#2C2C2C",
                            fontSize: 20,
                            fontWeight: "bold",
                            letterSpacing: 0,
                            lineHeight: 30,
                        },
                    },
                },
                MuiMenuItem: {
                    root: {
                        marginBottom: 0,
                        minHeight: 20,
                    },
                },
                MuiInputLabel: {
                    root: {
                        fontSize: 16,
                        color: "#263238",
                    },
                    formControl: {
                        color: "#263238 !important",
                        lineHeight: "28px !important",
                    },
                    shrink: {
                        transform: "translate(14px, -6px) scale(0.90) !important",
                        background: "#ffffff !important ",
                        padding: "0 5px",
                    },
                },
                MuiAppBar: {
                    colorDefault: {
                        backgroundColor: "#FFFFFF",
                    },
                },
                MuiStepLabel: {
                    label: {
                        fontSize: "18px !important",
                    },
                },

                MuiTableCell: {
                    head: {
                        whiteSpace: "pre",
                        padding: "16px 8px",
                    },
                    body: {
                        padding: 8,
                    },
                },
                MuiChip: {
                    root: {
                        marginRight: 10,
                    },
                    sizeSmall: {
                        marginRight: 10,
                    },
                },
            },
        },
        {
            index: 1
        }
);

export const NIWGlobalCss = withStyles({
    "@global": {
        "body": {
            background: "#FFFFFF !important",
            overflowX: "hidden",
        },
        "strong, b": {
            fontWeight: 'bolder !important'
        },
        "a": {
            color: '#01305F !important'
        },
        ".adminClasses .Mui-checked": {
            color: '#01305F !important',
        },
        "#rootElement":{
            width: '100%',
            height: '100%',
        },

    },
})
(() => null);
export default NIWTheme;