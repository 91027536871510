import React from "react";
import { DialogContent, Grid, Typography } from "@material-ui/core";
import { Button, Label } from "../../../../../../../components/formInputs";
import * as Yup from "yup";
import { Formik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { NumberTextField, SelectField, TextField } from "../../../../../../../components/formikFormInputs";
import { AddEditPhysician as styles } from "../../Style";
import InputAdornment from "@material-ui/core/InputAdornment";
import GetFormContent from "../../Beneficiaries/BeneficiariesList/GetFormContent";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import numeral from "numeral";
import { REQUIRED_FIELD } from "../../../../../../../constants";

const getYears = () => {
	const years: any = [];
	for (let year = new Date().getFullYear(); year >= 1930; year--) {
		years.push({
			id: year,
			key: year,
			name: year,
		});
	}
	return years;
};

interface DialogTitleProps {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
	const classes = styles();
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

const AddBusiness = (props: any) => {
	const { record, user, open, setOpen, isFromClientDetail, clientSigned } = props;
	const { saveBusiness } = useActions(AccountActions);

	const formVal = {
		nameOfBusiness: record && record.nameOfBusiness || "",
		typeOfBusiness: record && record.typeOfBusiness || "",
		yearAcquired: record && record.yearAcquired || "",
		percentOwed: record && record.percentOwed || "",
		fairMarketValue: record && record.fairMarketValue || "",
		valueDetermined: record && record.valueDetermined || "",
	};
	const initialValues = formVal;

	const fieldObj = [
		{
			id: "nameOfBusiness",
			name: "nameOfBusiness",
			helperFieldText: "",
			label: <Label label={"Name of Business"} required={true} />,
			placeholder: "Name of Business",
			isRequired: true,
			className: "mt10",
			filedType: "string",
			component: TextField,
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "typeOfBusiness",
			name: "typeOfBusiness",
			helperFieldText: "",
			label: <Label label={"Type of Business"} required={!isFromClientDetail} />,
			placeholder: "Type of Business",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: TextField,
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "yearAcquired",
			name: "yearAcquired",
			helperFieldText: "",
			label: <Label label={"Year Acquired"} required={!isFromClientDetail} />,
			placeholder: "Year Acquired",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: SelectField,
			options: getYears(),
			onSelectChange: (kval: number) => {
			},
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "percentOwed",
			name: "percentOwed",
			helperFieldText: "",
			label: <Label label={"% Owned"} required={!isFromClientDetail} />,
			placeholder: "Owned",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">%</InputAdornment>,
			},
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "fairMarketValue",
			name: "fairMarketValue",
			helperFieldText: "",
			label: <Label label={"Fair Market Value"} required={!isFromClientDetail} />,
			placeholder: "Fair Market Value",
			isRequired: !isFromClientDetail,
			component: NumberTextField,
			InputProps: {
				startAdornment: <InputAdornment disableTypography={true}
												position="start">$</InputAdornment>,
			},
			onSelectChange: (val: number) => {
			},
			variant: "outlined",
			className: "mt10",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
		{
			id: "valueDetermined",
			name: "valueDetermined",
			helperFieldText: "",
			label: <Label label={"How was value determined?"} required={!isFromClientDetail} />,
			placeholder: "How was value determined?",
			isRequired: !isFromClientDetail,
			className: "mt10",
			filedType: "string",
			component: TextField,
			variant: "outlined",
			size: {
				sm: 6,
				md: 4,
				lg: 4,
			},
			disabled: !!clientSigned
		},
	];


	/* Validations of fields set */
	let keysValidation = {};

	fieldObj.forEach((field) => {
		if (field.isRequired) {
			keysValidation[field.name] = field.filedType && field.filedType === "number" ?
				Yup.number().required(REQUIRED_FIELD) : Yup.string().trim().required(REQUIRED_FIELD);

			if (field.name === "percentOwed") {
				keysValidation[field.name] = Yup.number().typeError("Must be a number").min(1, "Please enter between 1-100").max(100, "Please enter between 1-100").integer("Decimal value is not allowed").required(REQUIRED_FIELD);
			}
		} else {
			keysValidation[field.name] = field.filedType &&
			field.filedType === "number" ? Yup.number() : Yup.string().trim();
			if (field.name === "percentOwed") {
				keysValidation[field.name] = Yup.number().typeError("Must be a number").min(1, "Please enter between 1-100").max(100, "Please enter between 1-100").integer("Decimal value is not allowed");
			}
		}
	});

	const validationSchema = Yup.object().shape(keysValidation);

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Dialog onClose={handleClose} fullWidth={true} maxWidth={"md"} aria-labelledby="customized-dialog-title"
				open={open}>
			<DialogTitle id="customized-dialog-title" onClose={handleClose}>
				{!record ? "Add " : "Edit "} Business
			</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Formik
							initialValues={initialValues}
							enableReinitialize={true}
							validationSchema={validationSchema}
							validateOnChange={true}
							onSubmit={async (values: any, { setSubmitting }) => {
								try {
									let finalValues: any = { ...values };
									finalValues.clientId = user && user.client && user.client.id || 0;
									finalValues.fairMarketValue = numeral(finalValues.fairMarketValue).value();
									if (record && record.id > 0) {
										finalValues.id = record.id;
									}
									let resp = await saveBusiness(finalValues);
									if (resp) {
										handleClose();
									}
									setTimeout(() => {
										setSubmitting(false);
									}, 400);
								} catch (e) {
									return false;
								}
							}}
						>
							{
								(formikProps) => {
									return (
										<form className="floatLeft w100" onSubmit={formikProps.handleSubmit}>
											<Grid container spacing={2}>
												<GetFormContent isFromClientDetail={isFromClientDetail}
																fieldObj={fieldObj} {...formikProps} />
											</Grid>
											<Grid item xs={12} md={12}>
												<span className="clearFix" />
												<Button
													className={"floatRight mt30"}
													type={"submit"}
													variant={"contained"}
													color={"primary"}
													disabled={formikProps.isSubmitting || clientSigned}
													label={`${!record ? "Add" : "Update"} Business`}
												/>
											</Grid>
										</form>
									);
								}}
						</Formik>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default AddBusiness;