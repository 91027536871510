import React from "react";
import { Box, Card, CardContent, Link, Typography } from "@material-ui/core";
import { DashboardStyle as Styles } from "./dashboardStyle";
import clientManagement from "./images/ico-client-management.svg";
import clientActionItems from "./images/ico-action-items.svg";
import clientEvents from "./images/ico-events.svg";
import iliaTraining from "./images/ico-training.svg";
import marketingMaterial from "./images/ico-events.svg";
import enrollmentApplication from "../../../pages/client/Enrollment/images/svg/02_pre-enrollment-application.svg"
export interface PortalPagesProps {
    mainTitle: string;
    content: string;
    image: string;
    redirectTo: string;
}

export const PortalPagesData: Array<PortalPagesProps> = [
    {
        mainTitle: "Client Management",
        content: "Your client lists for follow up marketing and reporting.",
        image: clientManagement,
        redirectTo: "/client",
    },
    {
        mainTitle: "Action Items",
        content: "List of your action items to complete on behalf of client.",
        image: clientActionItems,
        redirectTo: "/action-items",
    },
    {
        mainTitle: "Upcoming Events",
        content: "Register for events led by the myilia team.",
        image: clientEvents,
        redirectTo: "/agents/events",
    },
    {
        mainTitle: "ilia Training",
        content: "Preferred Agent training to learn myilia features and functionality.",
        image: iliaTraining,
        redirectTo: "/agents/ILIATrainingPage",
    },
    {
        mainTitle: "Marketing Materials",
        content: "Preferred Agent training to learn myilia features and functionality.",
        image: marketingMaterial,
        redirectTo: "/agents/marketing-material",
    },
    {
        mainTitle: "Client Payment Options",
        content: "",
        image: enrollmentApplication,
        redirectTo: "/client-payments",
    },
];

const MainTypo = (props: { mainTitle: string | undefined, classes: any }) => {
    const {mainTitle, classes} = props;
    return <Typography variant="subtitle1" className={classes.mb0}>
        <strong>{mainTitle}</strong>
    </Typography>
}
const CardContentBlock = (props: { item: Partial<{ mainTitle: string, content: any, image: string, redirectTo: string }>, classes: any }) => {
    const {item, classes} = props;
    return <CardContent>
        <MainTypo mainTitle={item.mainTitle} classes={classes}/>
        <Typography variant="caption" className={classes.textLight}>
            {item.content}
        </Typography>
    </CardContent>
}
export default function PortalPages(props:any) {
    const classes = Styles();

    const redirectTo = (url:string) => {
        if(url === "/agents/events" || url === "/imos/events") {
            window.open("https://www.niwmarketing.com/", "_blank")
            return;
        }
        props.handleNavigation(url);
    }

    return (
            <>
                {props.pages.map((o, idx) => (
                        <Box className={classes.boxItem} m={1} key={o.mainTitle}>
                            <Link onClick={()=>redirectTo(o.redirectTo)} className={classes.textLinkNone}>
                                <Card className={classes.radius8 + " " + classes.textCenter + " " + classes.cardShadow + " " + classes.minHeight}>
                                    <div>
                                        <img height="95px" src={o.image} alt="#" className="mt30"/>
                                    </div>
                                    <CardContentBlock item={o} classes={classes} />
                                </Card>
                            </Link>
                        </Box>

                ))
                }
            </>
    );
}

