import createReducer from "./createReducer";
import {Action, ActionType} from "../model";
import {ApprovedDetail, ApprovedList, ApprovedStats} from "../model/approved";


export const approvedStats = createReducer({}, {
    [ActionType.APPROVED_STATS](state: any, action: Action<ApprovedStats>) {
        return action.payload;
    },

    [ActionType.FLUSH_APPROVED_STATS](state: Function, action: Action<void>) {
        return action.payload;
    }
});

export const approvedList = createReducer([], {
    [ActionType.APPROVED_LIST](state: any, action: Action<ApprovedList>) {
        return action.payload;
    },

    [ActionType.FLUSH_APPROVED_LIST](state: Function, action: Action<void>) {
        return action.payload;
    }
});

export const approvedDetail = createReducer({}, {
    [ActionType.APPROVED_DETAILS](state: any, action: Action<ApprovedDetail>) {
        return action.payload;
    },

    [ActionType.FLUSH_APPROVED_DETAILS](state: Function, action: Action<void>) {
        return action.payload;
    }
});


export const approvedImoList = createReducer([], {
    [ActionType.APPROVED_IMO_LIST](state: any, action: Action<ApprovedList>) {
        return action.payload;
    },

    [ActionType.FLUSH_APPROVED_IMO_LIST](state: Function, action: Action<void>) {
        return action.payload;
    }
});