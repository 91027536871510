import React from "react";
import {
    Button,
    Grid,
    InputAdornment, Paper,
    Table,
    TableBody,
    TableCell, TableFooter,
    TableHead,
    TableRow,
    TextField, Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TableContainer from "@material-ui/core/TableContainer";
import { asDbDateFormat } from "../../utils/dateUtils";
import { Pagination } from "../table";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useQueryString from "../../hooks/useQueryString";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { useActions } from "../../actions";
import * as CompanyActions from "../../actions/company";
import { AddGroupNote } from "./index";
import TableWrap from "../TableWrap/TableWrap";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        marginTop: "0",
        overflow: "auto",
        borderRadius: 6,
    },
    table: {
        minWidth: 700,
        overflowX: "auto",
    },
    actions: {
        color: theme.palette.text.secondary,
    },

}));

export default function Notes(props: any) {
    const classes = useStyles();
    const { detail } = props;

    const [open, setOpen] = React.useState(false),
            [page, setPage] = useQueryString("page", 0),
            [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", 25),
            [search, setSearch] = useQueryString("search", ""),
            [orderBy, setOrderBy] = useQueryString("orderBy", "createdAt"),
            [order, setOrder] = useQueryString("order", "desc");

    const [notesList, setNotesList] = React.useState<any>({ "count": 0, "rows": [] });

    const user: any = useSelector((state: RootState) => state.auth.user);
    //const company = (user && user.role ) === 8 ? user.company: detail.company;

    const companyActions = useActions(CompanyActions);

    const getCompanyId = (user: any): number => {
        if ((user && user.role) === 8) {
            return user.company.id;
        } else if ((user && user.role) === 16) {
            return user.groupSigners.company.id;
        } else {
            return detail.company.id;
        }
    };

    const getNotes = async () => {
        const companyId = getCompanyId(user);
        if (companyId) {
            let queryObj: any = {
                page: page,
                rowsPerPage: rowsPerPage,
                searchText: search,
                sortBy: orderBy,
                order,
                companyId: companyId,
            };
            const list = await companyActions.getGroupNotes(queryObj);
            setNotesList(list);
        }
    };

    React.useEffect(() => {
        getNotes();
    }, [page, rowsPerPage, search, props.match.params, orderBy, order, props.history.location.pathname]);

    const handleSaveNote = async (payload: any) => {
        let companyId = getCompanyId(user);
        if (props.match.params.id) {
            companyId = props.match.params.id;
        }
        await companyActions.saveGroupNotes(Object.assign(payload, { companyId: companyId }));
        setOpen(false);
        getNotes();
    };

    return (
            <>
                <Grid container spacing={3} justify="space-between">
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1"> Below are all notes taken from group administration or
                            enrollment.</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                                value={search}
                                label={"Search Notes"}
                                placeholder={"Search Notes"}
                                onChange={e => {
                                    setSearch(e.target.value);
                                }}
                                fullWidth
                                variant={"outlined"}
                                InputProps={{
                                    endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                        className="iconBtn"
                                                        aria-label="search"
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                    ),
                                }}
                        />
                    </Grid>
                    {(user && (user.role === 1 || user.role === 2 || user.role === 8)) && <Grid item xs={12} md={4}>
                        <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                className="floatRight"
                                onClick={() => setOpen(true)}
                        >
                            Add Note
                        </Button>
                    </Grid>
                    }
                </Grid>
                <TableWrap>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Message Subject</TableCell>
                            <TableCell align="left">Message Author</TableCell>
                            <TableCell align="left">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                notesList.count === 0 &&
                                <TableRow>
                                    <TableCell colSpan={3} align="left">
                                        <Typography variant="caption" align="left" className="floatLeft">No
                                            Notes</Typography>
                                    </TableCell>
                                </TableRow>
                        }
                        {
                                notesList.rows && notesList.rows.length > 0 &&
                                notesList.rows.map((item, idx) => {
                                    let role: string = "Group Admin";
                                    if (item.user && (item.user.role === 1 || item.user.role === 2)) {
                                        role = "NIW Admin";
                                    } else if (item.user && item.user.role === 16) {
                                        role = "Group Signer";
                                    }
                                    return (
                                            <TableRow key={idx}>
                                                <TableCell align="left">
                                                    <Typography color="primary" className="mb0">
                                                        {`${item.subject} - ${item.message}`}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                        align="left">{`${item.user.firstName} ${item.user.lastName} (${role})`}</TableCell>

                                                <TableCell align="left">{asDbDateFormat(item.createdAt)}</TableCell>
                                            </TableRow>
                                    );
                                })
                        }
                    </TableBody>
                    <TableFooter>
                        <Pagination page={Number(page)} rowsPerPage={Number(rowsPerPage)}
                                    setPage={setPage}
                                    setRowsPerPage={setRowsPerPage}
                                    accountList={notesList}/>
                    </TableFooter>
                </TableWrap>
                {
                        open && <AddGroupNote
                                handleClose={() => setOpen(false)}
                                onSave={(payload: any) => handleSaveNote(payload)}
                        />
                }

            </>
    );
}