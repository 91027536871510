import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { TextField, TextFieldCommon, ErrorCommon } from "../../components/formikFormInputs";
import { Formik } from "formik";
import { Label } from "../../components/formInputs";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: 18,
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function AddGroupNotes(props: any) {
    const { handleClose, onSave } = props;

    const initialValues = {
        subject: "",
        message: "",
    };
    const validate = ((value: any) => {
        let errors: any = {};
        if (!value.subject || value.subject.trim().length <= 0) {
            errors.subject = "Required";
        }
        if (!value.message || value.message.trim().length <= 0) {
            errors.message = "Required";
        }
        return errors;
    });
    const fieldObj = [
        {
            name: "subject",
            label: <Label label={"Subject"} required={true}/>,
            placeholder: "Subject",
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
        },
        {
            name: "message",
            label: <Label label={"Message"} required={true}/>,
            placeholder: "Enter Message",
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "TextFieldWidth",
            multiline: true,
            rows: 6,
        },
    ];

    const submitFormik = async (values: any, setSubmitting: Function, resetForm: Function) => {
        onSave(values);
    };

    return (
            <div>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        New Note
                    </DialogTitle>
                    <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validate={values => validate(values)}
                            validateOnChange={true}
                            onSubmit={(values, {
                                setSubmitting,
                                setErrors,
                                setStatus,
                                resetForm,
                            }) => submitFormik(values, setSubmitting, resetForm)}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              setFieldValue,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              dirty,
                          }) => (<form onSubmit={handleSubmit}>
                                    <>
                                        <DialogContent dividers>
                                            <Typography
                                                    variant="caption"
                                            >Write a note that is visible to
                                                others involved with this group (NIW Admin, Signer).</Typography>

                                            {
                                                fieldObj.map((field, index) => {
                                                    return (
                                                            <div className="mt30" key={index}>
                                                                <TextFieldCommon
                                                                        {...field}
                                                                        values={values}
                                                                        onChange={handleChange}
                                                                />
                                                                <ErrorCommon errors={errors} name={field.name}
                                                                             touched={touched}/>
                                                            </div>
                                                    );
                                                })
                                            }
                                            <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className="mb20 floatRight mt15"
                                                    size="medium"
                                                    disabled={!dirty || isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </DialogContent>
                                    </>
                                </form>
                        )}
                    </Formik>
                </Dialog>
            </div>
    );
}
