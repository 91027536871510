import React from "react";
import { Fab } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChatIcon from '@material-ui/icons/Chat';
import LandingContactForm from "./LandingContactForm";
import { JsEvents, LOGO_BASE_URL } from "../../../../constants";
import UserIcon from "../../../../components/client/landingPage/images/UserIcon.jpg";

function Chat(props: any) {
    const agentProfileImage: string = props.agent && props.agent.user && props.agent.user.image ? LOGO_BASE_URL + props.agent.user.image : UserIcon;
    const [open, setOpen] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 992);
    const agentName = props.agent && props.agent.user && props.agent.user.firstName ? props.agent.user.firstName : "Agent";

    React.useEffect(()=>{
        window.addEventListener(JsEvents.Resize, handleResize);
        return (() => {
            window.removeEventListener(JsEvents.Scroll, handleResize);
        });
    },[])

    const handleResize = (e:any) => {
        setIsMobile( window.innerWidth < 992 );
    }

	function handleClick() {
        setOpen(true);
	}

	
	return (
		<div className="chatIcon">
            {
                isMobile &&
                <Fab onClick={handleClick} color="primary" aria-label="add" className="chatBtn">
                    <ChatIcon className="floatRight mr15"/>
                </Fab>
            }

            {
                !isMobile &&
                <Fab onClick={handleClick} color="primary" aria-label="add" className="chatBtn">
                    <img alt={"agent-profile-image"} src={agentProfileImage}/>
                    <strong>Contact</strong><br/> <span>{agentName}</span>
                    <ArrowForwardIosIcon className="floatRight mr15"/>
                </Fab>
            }

            {
                open &&
                <LandingContactForm setOpen={(isOpen: boolean) => setOpen(isOpen)} open={open} {...props}/>
            }
		</div>
	);
}

export default Chat;
