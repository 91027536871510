import {
    IconButton,
    Table, TableBody, TableCell, TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import React from "react";
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { maskPhoneNumber } from "../../../utils/commonUtils";
import { useStyles } from "../../agent/dashboard/TableQuickView";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}
export const ImoTeamMembersTable = ({ kpiData, onDelete, handleNotificationStatus }) => {
    const classes = useStyles();
	const user = useSelector((state: RootState) => state.auth.user);

    const AntSwitch = withStyles((theme: Theme) =>
            createStyles({
                root: {
                    width: 42,
                    height: 26,
                    padding: 0,
                    margin: theme.spacing(1),
                },
                switchBase: {
                    padding: 1,
                    '&$checked': {
                        transform: 'translateX(16px)',
                        color: "#FFFFFF !important",
                        '& + $track': {
                            backgroundColor: '#52d869',
                            opacity: 1,
                            border: 'none',
                        },
                    },
                    '&$focusVisible $thumb': {
                        color: '#52d869',
                        border: '6px solid #fff',
                    },
                },
                thumb: {
                    width: 24,
                    height: 24,
                },
                track: {
                    borderRadius: 26 / 2,
                    border: `1px solid ${theme.palette.grey[400]}`,
                    backgroundColor: theme.palette.grey[50],
                    opacity: 1,
                    transition: theme.transitions.create(['background-color', 'border']),
                },
                checked: {},
                focusVisible: {},
            }),
    )(({ classes, ...props }: Props) => {
        return (
                <Switch
                        focusVisibleClassName={classes.focusVisible}
                        disableRipple
                        classes={{
                            root: classes.root,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                        }}
                        {...props}
                />
        );
    });

    return (
        <>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell align="left">First Name</TableCell>
                    <TableCell align="left">Last Name</TableCell>
                    <TableCell align="left">Member type</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Phone</TableCell>
                    <TableCell align="left">Actions</TableCell>
                    <TableCell align="left" style={{width:150}}>Alerts</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    kpiData.map((teamMember) => {
                        return (
                            <TableRow key={teamMember.ImoAdditionalMembers.userId}>
                                <TableCell align="left">
                                    {teamMember.memberFirstName}
                                </TableCell>
                                <TableCell align="left">
                                    {teamMember.memberLastName}
                                </TableCell>
                                <TableCell align="left">
                                    {teamMember.ImoAdditionalMembers.memberType}
                                </TableCell>
                                <TableCell align="left">
                                    {teamMember.email}
                                </TableCell>
                                <TableCell align="left">
                                    {maskPhoneNumber(teamMember.phoneNumber)}
                                </TableCell>
                                <TableCell>
                                {
                                    user && (user.role === 1 || user.role === 2) &&
                                    <Tooltip title="Delete">
                                        <IconButton
                                            color="default"
                                            onClick={() => onDelete(teamMember.ImoAdditionalMembers.userId)}
                                        >
                                            <DeleteOutlineIcon className="colorRed" />
                                        </IconButton>
                                    </Tooltip>
                                }
                                </TableCell>
                                <TableCell align="left">
                                    <div style={{width:120}}>
                                        <span className="floatLeft">Off</span>
                                                <AntSwitch className="floatLeft mt0 mb0" checked={teamMember.ImoAdditionalMembers.sendNotification} onChange={
                                                    ()=> handleNotificationStatus(teamMember.ImoAdditionalMembers.id, !teamMember.ImoAdditionalMembers.sendNotification)
                                                } name="checkedC" />
                                            <span className="floatLeft">On</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })
                }
            </TableBody>
        </>
    )
}

