import * as React from "react";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { IconButton, TableBody, TableCell, TableRow, Tooltip, Typography, Link } from "@material-ui/core";
import EnhancedTableHead from "./EnhancedTableHead";
import { dateUtils } from "../../utils";
import CopyToClipboard from "react-copy-to-clipboard";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LinkIcon from "@material-ui/icons/Link";
import { useActions } from "../../actions";
import * as AccountActions from "../../actions/account";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import KpiModal from "../group/KpiModal";
import {DeleteModelWithNotes, PageTitle} from "../formInputs";

const headRowsAccountList: any = [
    { id: "sNo", label: "S.No" },
    { id: "companyName", label: "Group Name" },
    { id: "adminName", label: "Group Admin" },
    { id: "groupType", label: "Group Type" },
    { id: "strategy", label: "ilia Product Type" },
    { id: "accountCreated", label: "Accounts Created" },
    { id: "enrollment", label: "Total Enrollments" },
    { id: "createdAt", label: "Date Created" },
];

function MultiLifeTable(props: any) {
    const {
        accountList,
        order,
        orderBy,
        handleRequestSort,
        callingFrom,
        handleRefresh,
        page,
        rowsPerPage,
        loggedInUserRole,
        customPageTitle
    } = props;
    const [inviteTokenId, setInviteTokenId] = React.useState<string>("");
    const accountActions = useActions(AccountActions);
    const user = useSelector((state: RootState) => state.auth.user);
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState<string | undefined>(undefined);
    const [title, setTitle] = React.useState<string | undefined>(undefined);
    const [companyId, setCompanyId] = React.useState(null);
    const [deleteModelTitle, setDeleteModelTitle] = React.useState<string>("");
    const [isDeleteModel, setIsDeleteModel] = React.useState<string>("");
    const [message, setMessage] = React.useState<string>("");
    const [notesValue, setNotesValue] = React.useState("");
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const [companyStrategySlug, setCompanyStrategySlug] = React.useState("");
    const userType = "USER";
    let sNo = page * rowsPerPage;

    const deletedUser = (id: string) => {
        let userDetails: any = accountList.rows.filter((val: any) => (id === val.id));
        if (userDetails.length > 0) {
            setMessage("Are you sure you want to delete this group permanently from ilia ?");
            setDeleteModelTitle(userDetails[0]["firstName"] + " " + userDetails[0]["lastName"]);
        }
        setIsDeleteModel(id);
    };

    const setNotes = (value) => {
        setNotesValue(value);
    };

    const deleteGroup = async () => {
        setButtonLoader(true);
        await accountActions.deleteAccount(isDeleteModel, userType, notesValue);
        setIsDeleteModel("");
        setNotesValue("");
        setButtonLoader(false);
        handleRefresh();
    };

    function valHandler(accountId: string) {
        setInviteTokenId(accountId);
        accountActions.copyInvitationLink("Invite Link Has Been Copied to Your Clipboard");
    }

    const getDeleteButton = (id: any) => {
        if (loggedInUserRole && loggedInUserRole === 4) {//agent should not be able to delete group
            return <></>;
        }
        if (user.role === 3 || user.role === 15) { // imo and imo member should not be able to delete group
            return <></>;
        }
        return (
                <Tooltip title="Delete">
                    <IconButton
                            color="default"
                            onClick={() => deletedUser(id)}
                    >
                        <DeleteOutlineIcon className="colorRed"/>
                    </IconButton>
                </Tooltip>
        );
    };

    const getViewButton = (id: any) => {
        return (
                <Tooltip title="View">
                    <IconButton color="default"
                                onClick={() => props.history.push("/group-detail/" + id)}>
                        <VisibilityIcon/>
                    </IconButton>
                </Tooltip>
        );
    };

    const getOtherTableCell = (account: any) => {
        /* If callingFrom is Not set then render bellow block */
        if (!callingFrom) {
            return (
                    <>
                        <TableCell padding="default" align="center" className="w230">
                            {getDeleteButton(account.id)}
                        </TableCell>
                    </>
            );
        }

        /* If callingFrom is set ("clientManagementPage" or "accountList") then render bellow switch block */
        switch (callingFrom) {
            case "clientManagementPage" :
                return (
                        <>
                            {/*Show Participants Count in this Table Cell*/}
                            <TableCell
                                    padding="default">{account["company.enrollmentCount"]}</TableCell>
                            <TableCell
                                    padding="default">{account["company.accountCreated"]}</TableCell>
                            <TableCell
                                    padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
                            {/*Show All actions (COPY INVITE LINK / VIEW / DELETE )*/}
                            <TableCell padding="default" align="center" className="w230">
                                {
                                    user.role === 4 && account.inviteLink &&
                                    <Tooltip placement="left-start"
                                             title={(account.id === inviteTokenId)
                                                     ? <React.Fragment>Invite Link Has Been <br/> Copied
                                                         to Your
                                                         Clipboard</React.Fragment>
                                                     : "Copy Invite Link"}>
                                        <CopyToClipboard text={account.inviteLink}
                                                         onCopy={() => valHandler(account.id)}>
                                            <IconButton color="default">
                                                <LinkIcon/>
                                            </IconButton>
                                        </CopyToClipboard>
                                    </Tooltip>
                                }
                                {getViewButton(account['company.id'])}
                                {getDeleteButton(account.id)}
                            </TableCell>
                        </>
                );
                break;
            case "accountList" :
                return (
                        <>
                            <TableCell
                                    padding="default">{account["company.accountCreated"]}</TableCell>
                            <TableCell
                                    padding="default">{account["company.enrollmentCount"]}</TableCell>
                            <TableCell
                                    padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
                            <TableCell padding="default" align="center" className="w230">
                                {getViewButton(account['company.id'])}
                                {user && user.role !== 4 && account.inviteLink &&
                                    <Tooltip placement="left-start"
                                             title={(account.id === inviteTokenId)
                                                     ? <React.Fragment>Invite Link Has Been <br/> Copied
                                                         to Your
                                                         Clipboard</React.Fragment>
                                                     : "Copy Invite Link"}>
                                        <CopyToClipboard text={account.inviteLink}
                                                         onCopy={() => valHandler(account.id)}>
                                            <IconButton color="default">
                                                <LinkIcon/>
                                            </IconButton>
                                        </CopyToClipboard>
                                    </Tooltip>
                                }
                                {getDeleteButton(account.id)}
                            </TableCell>
                        </>
                );
                break;
            default:
                return <></>;
        }
    };

    return (
            <React.Fragment>
                {
                    customPageTitle &&
                    <PageTitle title={`${customPageTitle}`}/>
                }
                <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        //headRows={(callingFrom === "accountList") ? headRowsAccountList : headRowsClientManagement}
                        headRows={headRowsAccountList}
                />
                <TableBody>
                    {
                        (!accountList.rows || accountList.rows.length === 0)
                        && <TableRow>
                            <TableCell align="left" colSpan={(callingFrom === "accountList") ? 7 : 8}>
                                <Typography variant="caption" align="left" className="floatLeft">No Group
                                    Accounts</Typography>
                            </TableCell>
                        </TableRow>
                    }
                    {accountList.rows && accountList.rows.map((account: any, index: number) => {
                        return (
                                <TableRow hover key={account.id}>
                                    <TableCell>{++sNo}</TableCell>
                                    <TableCell padding="default">
                                        <Link
                                                variant="body2"
                                                className={"clickableLink"}
                                                href={"javascript:void(0)"}
                                             
                                                onClick={() => props.history.push("/group-detail/" + account['company.id'])}>
                                            {account["company.companyName"]}
                                        </Link>
                                    </TableCell>
                                    <TableCell padding="default">
                                        {account.firstName + " " + account.lastName}
                                    </TableCell>
                                    <TableCell padding="default">
                                        {account["company.groupType"] || "NA"}
                                    </TableCell>
                                    <TableCell padding="default">{account["company.strategies.name"]}</TableCell>
                                    {
                                        getOtherTableCell(account)
                                    }
                                </TableRow>
                        );
                    })
                    }
                </TableBody>

                {
                    open && <KpiModal
                            handleClose={() => setOpen(false)}
                            type={type}
                            modalTitle={title}
                            companyId={companyId}
                            companyStrategySlug={companyStrategySlug}
                    />
                }
                {
                    isDeleteModel ?
                            (
                                    <DeleteModelWithNotes
                                            open={true}
                                            title={deleteModelTitle}
                                            content={message}
                                            handleClose={() => {
                                                setIsDeleteModel("");
                                                setNotesValue("");
                                            }}
                                            handleYes={deleteGroup}
                                            setNotes={setNotes}
                                            buttonLoader={buttonLoader}
                                            notes={notesValue}
                                    />
                            ) : null
                }
            </React.Fragment>
    );
}

export default MultiLifeTable;
