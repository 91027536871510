import React from 'react';
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import {BUN} from "../../../model/misc";

type ActionButtonsProps = {
    fileName: string;
    hasReports: boolean;
    proposalUpdate: BUN;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({fileName, hasReports, proposalUpdate}) => {
  return (
      <Grid item xs={12} className="textRight">
          {
              !proposalUpdate && (!!fileName || hasReports) &&
              <Button
                  className="floatRight"
                  color="primary"
                  form="upload-proposal-form"
                  type={"submit"}
                  variant="contained"
              >
                  Send For Approval
              </Button>
          }
          {
              proposalUpdate && (!!fileName || hasReports) &&
              <Button
                  className="floatRight"
                  color="primary"
                  form="upload-proposal-form"
                  type={"submit"}
                  variant="contained"
              >
                  Replace Proposal
              </Button>
          }
      </Grid>
  );
};

export default ActionButtons;
