import React from 'react';
import { Card, CardContent, Container, Grid, Typography , Link} from "@material-ui/core";
import loginlogo from "../styles/images/logo-ilia.png";
import { Button } from "../components/formInputs";
import { RouteComponentProps } from "react-router-dom";
import login from "../styles/images/login.png";
import { Formik } from "formik";
import { TextField } from "../components/formikFormInputs";
import { Field } from "formik";
import { useActions } from "../actions";
import * as Actions from "../actions/verify";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

interface ParamsProps{
    match:{
        params:{
            token : string
        }
    }
}
interface Props extends RouteComponentProps<void> { }

function VerifyOtp(props: Props & ParamsProps) {
    const verifyActions = useActions(Actions);
    const { token } = props.match.params;
    const [resendDisable, setResendDisable] = React.useState<boolean>(false);

    const submitFormik = async (values: any, setSubmitting: Function, resetForm: Function) => {
        const data = Object.assign(values, {
        	token 
        });
        const resp = await verifyActions.verifyOtp(data);
        if(resp.statusCode === "200"){
            props.history.push("/login");
        }
        return false;
    };

    const resendOtp = async () =>{
        setResendDisable(true);
        setTimeout(function() {
            setResendDisable(false)
        }, 10000);
        const payload: any = { token, attributeType: "email"}
        await verifyActions.resendVerificationOtp(payload);
    }

    const intitialValues = {
        confirmationCode: ""
    };

    const validate = ((value: any) => {
        const errors: {
            confirmationCode?: string
        } = {};
        if (!value.confirmationCode)
            errors.confirmationCode = "Required";
        return errors;
    });

    return (
        <Container component="main" maxWidth="xs">
            <div className="logInWrap">
                <Grid item xs={12} sm container>
                    <Grid item xs>
                        <Card className="muiCard loginCard">
                            <CardContent className="muiCardContent">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} className="txtCenter w100Grid ">
                                        <img alt="loginLogo" src={loginlogo} />
                                        <Typography variant="h5" className="f12">
                                            We have just emailed you a One Time Passcode. Please enter the passcode to access ilia. This passcode is only available for 24 hours.
                                        </Typography>
                                        <div>

                                        </div>
                                        <Formik
                                            initialValues={intitialValues}
                                            validate={values => validate(values)}
                                            onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => submitFormik(values, setSubmitting, resetForm)}
                                        >
                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty, setFieldValue }) =>
                                                (
                                                    <form className="loginForm margin0 pt0 pb0" onSubmit={handleSubmit}>
                                                        <Field
                                                            name="confirmationCode"
                                                            placeholder="Enter One Time Password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            component={TextField}
                                                        />
                                                        <Button
                                                            type="submit"
                                                            label="CONFIRM"
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            className="btnPrimary"
                                                            disabled={!dirty || isSubmitting}
                                                            loading={isSubmitting}
                                                        />
                                                        <Button type="button" fullWidth={true}
                                                                variant="contained"
                                                                color="primary"  disabled={ resendDisable } label="resend OTP" className="btnPrimary mt20 mb20" onClick={()=> resendOtp()}/>
                                                        <Link className={"clickableLink"}  onClick={ () => props.history.push("/login")} variant="body2" >
                                                            <ArrowBackIcon/> Back to login
                                                        </Link>
                                                    </form>
                                                )}

                                        </Formik>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} className="dpNone">
                                        <Typography variant="h3" align="right">
                                            Enter <br />One Time Password
										                    </Typography>
                                        <img alt="login" className="floatRight img-responsive" src={login} />
                                    </Grid>
                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}

export default VerifyOtp;