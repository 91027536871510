import React from "react";
import { useSelector } from "react-redux";
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useFormikContext } from "formik";
import moment from "moment";
import { RootState } from "../../../../reducers";
import { useActions } from "../../../../actions";
import * as Actions from "../../../../actions/account";
import * as CActions from "../../../../actions/client";
import DownloadLink from "../../../../components/DownloadLink";
import { EnrollmentFileUploader as FileUploader, Label } from "../../../../components/formInputs";
import {
    ErrorHelper,
    NumberTextField,
    SelectField,
    TextField,
    TextFieldCommon,
} from "../../../../components/formikFormInputs";
import SelectDocTypeField from "../../Enrollment/EnrollmentHome/basicInfo/SelectDocTypeField";
import ViewAndDelete from "../../Enrollment/EnrollmentHome/basicInfo/ViewAndDelete";
import { Account } from "../../../../model/account";
import { noop, pez } from "../../../../utils/commonUtils";
import {
    AllianzLifeCarrierDetail,
    ClientDocumentType,
    DocNameAndType,
    EMAIL_VALIDATE_REGEX,
    LOGO_BASE_URL,
    NationalLifeCarrierDetail,
} from "../../../../constants";
import { ClientDocsInterface } from "../../../../model/documents";
import { makeStyles } from "@material-ui/styles";

type Relation = { id: string, name: string, value: string };
type BasicFieldProps = {
    className?: string;
    component: any;
    disabled?: boolean;
    id: string;
    label: JSX.Element;
    name: string;
    onBlur?: Function;
    onSelectChange?: Function;
    options?: Relation[];
    placeholder: string;
    variant?: string;
};
type GreatWealthTransferProps = { agentSigned: boolean; gen1User: any; setGen1User: Function; };

enum OtherTypeOfInsurance {
    "Term life insurance" = 1,
    "Whole life insurance",
    "Universal life insurance",
    "Final expense insurance",
    "Simplified issue ",
    "Group life insurance",
}

enum YesNoVal {
    Yes = "1",
    No = "0"
}

const YesNoOptions = [
    { label: "Yes", value: YesNoVal.Yes },
    { label: "No", value: YesNoVal.No },
];

/**
 * Enum to array of string or number values (enum values)
 * Not to be used with keys true in case of enum with string values
 * @param Foo
 * @param keys
 */
const enumAsArray = (Foo: any, keys: boolean = false): Array<string | number> =>
        Object.keys(Foo).filter((k: any) => typeof Foo[k] === (keys ? "number" : "string"));

const GreatWealthTransferDetails = ({ agentSigned, gen1User, setGen1User }: GreatWealthTransferProps) => {
    const accountActions = useActions(Actions);
    const clientActions = useActions(CActions);
    const detail: Account = useSelector((state: RootState) => state.clientAccount);
    const { errors, handleChange, setFieldValue, values } = useFormikContext<any>();

    const directory: string = pez(detail && detail.enrollment && detail.enrollment.id);
    const userId: string = pez(detail && detail.client && detail.client.userId);
    const enrollment = detail && detail.enrollment && detail.enrollment.docusignStatus;
    const carrierName = detail && detail.client && detail.client.carrier && detail.client.carrier.carrierName;
    const carrierId = detail && detail.client && detail.client.carrier && detail.client.carrier.id;
    const gwtClientStatus = detail && detail.client && detail.client.gwtClients && detail.client.gwtClients.status;

    const [greatWealthTransferCoverLetter, setGreatWealthTransferCoverLetter] = React.useState("");
    const [greatWealthTransferDisclosureLetter, setGreatWealthTransferDisclosureLetter] = React.useState("");
    const [uploadDriversLicenseGEN1GWT, setUploadDriversLicenseGEN1GWT] = React.useState("");
    const [uploadDriversLicenseBackGEN1GWT, setUploadDriversLicenseBackGEN1GWT] = React.useState("");
    const [uploadFinancialStatementForms, setUploadFinancialStatementForms] = React.useState("");
    const [taxDocumentYearOne, setTaxDocumentYearOne] = React.useState("");
    const [taxDocumentYearTwo, setTaxDocumentYearTwo] = React.useState("");
    const [taxDocDropDown, setTaxDocDropDown] = React.useState<{ text: string; value: string; }[]>([]);
    const [onProgress, setOnProgress] = React.useState(false);
    const [findingClient, setFindingClient] = React.useState(false);
    const taxDocSubType = values && values.taxDocument;

    const findByEmail = async (email: string) => {
        if (findingClient || !email || !EMAIL_VALIDATE_REGEX.test(email)) {
            setGen1User(null);
            return;
        }
        setFindingClient(true);
        setGen1User(null);
        const gen1User = await clientActions.findClient(email, ['email', 'firstName', 'id', 'lastName', 'client.policyNumber', 'client.trancheNo']);
        if (gen1User) {
            setFieldValue("gen1FirstName", gen1User.firstName);
            setFieldValue("gen1LastName", gen1User.lastName);
            setFieldValue("gen1PolicyNumber", pez(gen1User.client.policyNumber));
            setFieldValue("gen1TranceNo", gen1User.client.trancheNo);
            setGen1User(gen1User);
        }
        setFindingClient(false);
    };
    const relationObj: Relation[] = [
        {
            id: "Mother",
            name: "Mother",
            value: "Mother",
        },
        {
            id: "Father",
            name: "Father",
            value: "Father",
        },
        {
            id: "Grandmother",
            name: "Grand Mother",
            value: "Grandmother",
        },
        {
            id: "Grandfather",
            name: "Grand Father",
            value: "Grandfather",
        },
        {
            id: "Other",
            name: "Other",
            value: "Other",
        },
    ];
    const otherInsuranceTypesObj = enumAsArray(OtherTypeOfInsurance, true)
            .map(otherInsurance => ({ key: OtherTypeOfInsurance[otherInsurance], name: otherInsurance }));
    const basicFields: BasicFieldProps[] = [
        {
            className: "TextFieldWidth",
            component: TextField,
            disabled: gen1User && gen1User.firstName,
            id: "gen1FirstName",
            label: <Label label={"Gen 1 First Name"} required={true} />,
            name: "gen1FirstName",
            placeholder: "Gen 1 First Name",
            variant: "outlined",
        },
        {
            className: "TextFieldWidth",
            component: TextField,
            disabled: gen1User && gen1User.lastName,
            id: "gen1LastName",
            label: <Label label={"Gen 1 Last Name"} required={true} />,
            name: "gen1LastName",
            placeholder: "Gen 1 Last Name",
            variant: "outlined",
        },
        {
            className: "TextFieldWidth",
            component: TextField,
            disabled: findingClient,
            id: "gen1Email",
            label: <Label label={"Gen 1 Email"} required={true} />,
            name: "gen1Email",
            onBlur: (e: any) => findByEmail(e.target.value),
            placeholder: "Gen 1 Email",
            variant: "outlined",
        },
        {
            component: SelectField,
            id: "relationToGen2Client",
            label: <Label label={"Relation to Gen 2 (Client)"} required={true} />,
            name: "relationToGen2Client",
            onSelectChange: noop,
            options: relationObj,
            placeholder: "Relation to Gen 2 (Client)",
        },
        {
            className: "TextFieldWidth",
            component: TextField,
            disabled: !!(values && values.relationToGen2Client !== "Other"),
            id: "otherRelation",
            label: <Label label={"Other Relation"} required={true} />,
            name: "otherRelation",
            placeholder: "Other Relation",
            variant: "outlined",
        }
    ];
    const fields = {
        basic: basicFields,
        policy: [
            {
                disabled: false,
                helperFieldText: "",
                id: "currentKaiZenPolicy",
                isRadio: true,
                isRequired: true,
                name: "currentKaiZenPolicy",
                options: YesNoOptions,
            },
        ],
        documents: [
            {
                id: 1,
                leftDownload1FileName: "SampleCoverLetterGWTGiftorIs55OrOlder.docx",
                leftDownload1FilePath: `${LOGO_BASE_URL}sample/SampleCoverLetterGWTGiftorIs55OrOlder.docx`,
                leftDownload1LinkName: "Download Sample Cover Letter (giftor 55+)",
                leftDownload2FileName: "SampleCoverLetterGWTGiftorUnder55.docx",
                leftDownload2FilePath: `${LOGO_BASE_URL}sample/SampleCoverLetterGWTGiftorUnder55.docx`,
                leftDownload2LinkName: "Download Sample Cover Letter (giftor under 55)",
                leftExactFileName: "Great+Wealth+Transfer+Cover+Letter",
                leftFileUploaderCondition: !greatWealthTransferCoverLetter,
                leftHeading: "Great Wealth Transfer Cover Letter",
                leftIdentityPhoto: greatWealthTransferCoverLetter,
                leftKey: "GWT_COVER_LETTER",
                leftSide: "",
                leftTitle: "Great Wealth Transfer Cover Letter",
                leftType: "GWT_COVER_LETTER",
                leftSubType: "GWT Cover Letter",
                leftUpdateDocument: "uploadGreatWealthTransferCoverLetter",
                rightDownload1FileName: "KaizenGiftorAckAndDisclosure.pdf",
                rightDownload1FilePath: `${LOGO_BASE_URL}sample/KaizenGiftorAckAndDisclosure.pdf`,
                rightDownload1LinkName: "Download Great Wealth Transfer Disclosure Letter",
                rightExactFileName: "Upload+Signed+Great+Wealth+Transfer+Disclosure+Letter",
                rightFileUploaderCondition: !greatWealthTransferDisclosureLetter,
                rightHeading: "Signed Great Wealth Transfer Disclosure Letter",
                rightIdentityPhoto: greatWealthTransferDisclosureLetter,
                rightKey: "GWT_DISCLOSURE_LETTER",
                rightSide: "",
                rightTitle: "Great Wealth Transfer Disclosure Letter",
                rightType: "GWT_DISCLOSURE_LETTER",
                rightSubType: "GWT Disclosure Letter",
                rightUpdateDocument: "uploadSignedGreatWealthTransferDisclosureLetter",
                rightHide: false,
            },
            {
                id: 2,
                leftExactFileName: "Upload+Drivers+License+Front+GEN+1+GWT",
                leftFileUploaderCondition: !uploadDriversLicenseGEN1GWT,
                leftHeading: "Gen 1 Giftor Valid US Drivers Licence",
                leftIdentityPhoto: uploadDriversLicenseGEN1GWT,
                leftKey: "GWT_DRIVERS_LICENSE",
                leftSide: "GWT Identity Document",
                leftTitle: "Drivers License Front (GEN 1 GWT)",
                leftType: "GWT_DRIVERS_LICENSE",
                leftSubType: "GWT Identity Document",
                leftUpdateDocument: "uploadDriversLicenseGEN1GWT",
                rightExactFileName: "Upload+Drivers+License+Back+GEN+1+GWT",
                rightFileUploaderCondition: !uploadDriversLicenseBackGEN1GWT,
                rightHeading: "\u00A0",
                rightIdentityPhoto: uploadDriversLicenseBackGEN1GWT,
                rightKey: "GWT_DRIVERS_LICENSE_BACK",
                rightSide: "GWT Identity Document",
                rightTitle: "Drivers License Back (GEN 1 GWT)",
                rightType: "GWT_DRIVERS_LICENSE_BACK",
                rightSubType: "GWT Identity Document",
                rightUpdateDocument: "uploadDriversLicenseBackGEN1GWT",
                rightHide: false,
            },
            {
                id: 3,
                leftDownload2FileName: carrierId === NationalLifeCarrierDetail.value ? "NL_Financial_Questionaire.pdf" : carrierId === AllianzLifeCarrierDetail.value ? "Allianz_Financial_Questionnaire.pdf" : "",
                leftDownload2FilePath: `${LOGO_BASE_URL}sample/${carrierId === NationalLifeCarrierDetail.value ? "NL_Financial_Questionaire.pdf" : carrierId === AllianzLifeCarrierDetail.value ? "Allianz_Financial_Questionnaire.pdf" : ""}`,
                leftDownload2LinkName: carrierId === NationalLifeCarrierDetail.value ? "National Life Financial Questionaire.pdf" : carrierId === AllianzLifeCarrierDetail.value ? "Allianz Financial Questionnaire.pdf" : "",
                leftExactFileName: "Upload+Financial+Statement+Forms",
                leftFileUploaderCondition: !uploadFinancialStatementForms,
                leftHeading: "Financial Statement Forms",
                leftIdentityPhoto: uploadFinancialStatementForms,
                leftKey: "GWT_FINANCIAL_STATEMENT",
                leftSide: "",
                leftTitle: `Financial Statement Forms For ${carrierName}`,
                leftType: "GWT_FINANCIAL_STATEMENT",
                leftSubType: "GWT Financial Statement Forms",
                leftUpdateDocument: "uploadFinancialStatementForms",
                rightFileUploaderCondition: true,
                rightKey: "",
                rightType: "",
                rightSubType: "",
                rightUpdateDocument: "",
                rightHide: true,
            },
            {
                id: 4,
                leftExactFileName: "Upload+GWT+Income+Verification+Year+1",
                leftIdentityPhoto: taxDocumentYearOne,
                leftKey: "GWT_TAX_DOCUMENT_YEAR_ONE",
                leftSide: "YEAR_ONE",
                leftTitle: "GWT Income Verification (Year 1)",
                leftType: "GWT_TAX_DOCUMENT_YEAR_ONE",
                leftSubType: taxDocSubType,
                leftUpdateDocument: "uploadGWTIncomeVerificationYear1",
                rightExactFileName: "Upload+GWT+Income+Verification+Year+2",
                rightIdentityPhoto: taxDocumentYearTwo,
                rightKey: "GWT_TAX_DOCUMENT_YEAR_TWO",
                rightSide: "YEAR_TWO",
                rightTitle: "GWT Income Verification (Year 2)",
                rightType: "GWT_TAX_DOCUMENT_YEAR_TWO",
                rightSubType: taxDocSubType,
                rightUpdateDocument: "uploadGWTIncomeVerificationYear2",
                verificationDocumentType: true,
                rightHide: false,
            },
        ],
        yesFields: [
            {
                component: TextField,
                disabled: !!(gen1User && gen1User.client && gen1User.client.trancheNo),
                label: <Label label={"Tranche #"} required={true} />,
                name: "gen1TranceNo",
                variant: "outlined",
            },
            {
                component: TextField,
                disabled: !!(gen1User && gen1User.client && gen1User.client.policyNumber),
                label: <Label label={"Policy Number"} required={true} />,
                name: "gen1PolicyNumber",
                variant: "outlined",
            },
        ],
        noFieldHasInsurance: [
            {
                id: "hasCurrentInsuranceCoverage",
                label: "Is the Gen 1 Giftor over 55 and have current insurance coverage?",
                name: "hasCurrentInsuranceCoverage",
                options: YesNoOptions,
            },
        ],
        noFieldHasInsuranceYes: [
            {
                component: TextField,
                label: <Label label={"Insurance Company Name"} required={true} />,
                name: "otherCompanyName",
                variant: "outlined",
            },
            {
                component: SelectField,
                id: "otherType",
                label: <Label label={"Type of Insurance"} />,
                name: "otherType",
                onSelectChange: noop,
                options: otherInsuranceTypesObj,
                placeholder: "Type of Insurance",
            },
            {
                component: NumberTextField,
                id: "otherDeathBenefitAmount",
                InputProps: {
                    startAdornment: <InputAdornment className="margin0 dollerIcon" position="start">$</InputAdornment>,
                },
                label: <Label label={"Death Benefit Amount of the Policy"} required={true} />,
                name: "otherDeathBenefitAmount",
                variant: "outlined",
            },
            {
                component: TextField,
                label: <Label label={"Policy Number"} required={true} />,
                name: "otherPolicyNumber",
                variant: "outlined",
            },
        ],
    };

    const useStyles = makeStyles(() => ({
        hide: {
            display: 'none'
        }
    }))
    const classes = useStyles();

    const TriggerState = (name: string, id: number, section: string) => {
        if (id === 1 && section === "left") {
            setGreatWealthTransferCoverLetter(name);
        } else if (id === 2 && section === "left") {
            setUploadDriversLicenseGEN1GWT(name);
        } else if (id === 3 && section === "left") {
            setUploadFinancialStatementForms(name);
        } else if (id === 4 && section === "left") {
            setTaxDocumentYearOne(name);
        } else if (id === 1 && section === "right") {
            setGreatWealthTransferDisclosureLetter(name);
        } else if (id === 2 && section === "right") {
            setUploadDriversLicenseBackGEN1GWT(name);
        } else if (id === 4 && section === "right") {
            setTaxDocumentYearTwo(name);
        }
    };

    React.useEffect(() => {
        detail && detail.client && detail.client.clientDocuments && (detail.client.clientDocuments as ClientDocsInterface[]).forEach(clientDocument => {
            if (clientDocument.name === ClientDocumentType.GWT_COVER_LETTER) {
                setGreatWealthTransferCoverLetter(clientDocument.path);
            }
            if (clientDocument.name === ClientDocumentType.GWT_DISCLOSURE_LETTER) {
                setGreatWealthTransferDisclosureLetter(clientDocument.path);
            }
            if (clientDocument.name === ClientDocumentType.GWT_FINANCIAL_STATEMENT) {
                setUploadFinancialStatementForms(clientDocument.path);
            }
            if (clientDocument.name === ClientDocumentType.GWT_DRIVERS_LICENSE) {
                setUploadDriversLicenseGEN1GWT(clientDocument.path);
                setUploadDriversLicenseBackGEN1GWT(clientDocument.backPath);
            }
            if (clientDocument.name === ClientDocumentType.GWT_TAX_DOCUMENT_YEAR_ONE) {
                setTaxDocumentYearOne(clientDocument.path);
            }
            if (clientDocument.name === ClientDocumentType.GWT_TAX_DOCUMENT_YEAR_TWO) {
                setTaxDocumentYearTwo(clientDocument.path);
            }
        });

        const arr = Object.keys(DocNameAndType);
        const taxDocArr = arr.filter((item: string, _) => {
            return (DocNameAndType[`${item}`].docName === "Tax Returns");
        }).map(item => ({ text: `${item} (GEN 1 GWT)`, value: item }));
        setTaxDocDropDown(taxDocArr);
    }, [detail]);

    const updateDocument = async (key: string, value: string | string[], field: string, documentSubType: string = "", docSubName: string = "", updateOnlySubType: boolean = false) => {
        const payload = {
            formType: !!field ? "documents" :  'gwt',
            taxDocumentYear1: taxDocumentYearOne,
            taxDocumentYear2: taxDocumentYearTwo,
            greatWealthTransferCoverLetter: greatWealthTransferCoverLetter,
            greatWealthTransferDisclosureLetter: greatWealthTransferDisclosureLetter,
            uploadDriversLicenseGEN1GWT: uploadDriversLicenseGEN1GWT,
            uploadDriversLicenseBackGEN1GWT: uploadDriversLicenseBackGEN1GWT,
            documentType: field,
            documentSubType: documentSubType,
            documentKey: key,
            docSubName: docSubName,
            operation: key ? "Uploaded" : "",
            updateOnlySubType: updateOnlySubType,
        };
        payload[key] = value;
        payload["documentType"] = field;
        payload["documentSubType"] = documentSubType;
        payload["documentKey"] = key;
        payload["docSubName"] = docSubName;
        payload["operation"] = payload[payload["documentKey"]] ? "Uploaded" : "Deleted";
        payload["updateOnlySubType"] = updateOnlySubType;
        if (userId && directory) {
            try {
                await accountActions.updateUserBasicDetails(payload, userId);
                accountActions.getProfileDetails();
            } catch (err) {
                console.log("err", err);
                return false;
            }
        }
    };

    const fileType: string = "image/jpg, image/jpeg, image/png, application/pdf";
    const commonRadioProps = {
        className: "checkGroup checkGroupEnrollment",
        color: "primary",
        onChange: handleChange,
    };
    const radioOptionLabel = (option: any, index: number) => (
            <FormControlLabel
                    className="floatLeft w100px"
                    control={(
                            <Radio color="primary" />
                    )}
                    key={index}
                    label={option.label}
                    value={option.value}
            />
    );
    const clearExistingPolicyFields = () => {
        // clear has existing policy fields
        fields.yesFields.map(f => setFieldValue(f.name, ""));
    };
    const clearOtherPolicyFields = () => {
        // clear other policy fields
        fields.noFieldHasInsuranceYes.map(f => setFieldValue(f.name, ""));
        // clear has other policy radio selection
        fields.noFieldHasInsurance.map(f => setFieldValue(f.name, ""));
    };
    const onCurrentPolicyChange = async (e: any) => {
        if (e.target.value === YesNoVal.No) {
            setGen1User(null);
        }
        // else if (e.target.value ===  YesNoVal.Yes && values['gen1Email']) {
        //     await findByEmail(values['gen1Email']);
        // }

        clearOtherPolicyFields();
        return handleChange(e);
    };
    const onHasInsuranceChange = (e: any) => {
        clearExistingPolicyFields();
        return onCurrentPolicyChange(e);
    };

    return (
            <>
                <Typography className="floatLeft w100 mt30 mb0" component="strong" id="great-wealth-transfer-details" variant="body1">
                    Great Wealth Transfer Details {gwtClientStatus ? <span className=" textGreen floatRight mb20">Completed</span> : "" }
                </Typography>
                {gwtClientStatus ? "" : <Typography variant="body1" className=" textRed"> This Section needs to be completed for case to move forward </Typography> }

                <Grid container spacing={3}>
                    {
                        fields.basic.map((field, index) => {
                            return (
                                    <Grid
                                            className={"mb5 fullWidthcustomFields " + (field.name === "otherRelation" && field.disabled ? classes.hide : "")}
                                            item
                                            key={index}
                                            lg={6}
                                            md={4}
                                            sm={6}
                                            xl={4}
                                            xs={12}
                                    >
                                        <TextFieldCommon
                                                values={values}
                                                onChange={handleChange}
                                                InputLabelProps={{ disabled: false }}
                                                {...field}
                                        />
                                        <ErrorHelper errors={errors} name={field.name} />
                                    </Grid>
                            );
                        })
                    }

                    <Grid item xs={12} md={12}>
                        <Typography variant="body2" className="floatLeft mt0 mb15 w100">
                            <Label label={`Does Gen 1 Have a current Kai-Zen Policy`} required={true} />
                        </Typography>
                        {
                            fields.policy.map((field: any, index) => {
                                return (
                                        <React.Fragment key={index}>
                                            <RadioGroup
                                                    {...commonRadioProps}
                                                    name={field.name}
                                                    onChange={onCurrentPolicyChange}
                                                    value={values && values[field.name]}
                                            >
                                                {
                                                    field.options.map(radioOptionLabel)
                                                }
                                            </RadioGroup>
                                            <ErrorHelper errors={errors} name={field.name} />
                                        </React.Fragment>
                                );
                            })
                        }
                    </Grid>

                    {
                            values.currentKaiZenPolicy === YesNoVal.Yes &&
                            fields.yesFields.map((field, index) => {
                                return (
                                        <Grid item key={index} lg={6} md={4} sm={6} xl={4} xs={12}>
                                            <TextFieldCommon values={values} onChange={handleChange} {...field} />
                                            <ErrorHelper errors={errors} name={field.name} />
                                        </Grid>
                                );
                            })
                    }

                    {
                            values.currentKaiZenPolicy === YesNoVal.No &&
                            fields.noFieldHasInsurance.map((field, index) => {
                                return (
                                        <Grid item key={index} xs={12}>
                                            <Typography variant="body2" className="floatLeft mt0 mb15 w100">
                                                <Label
                                                        label="Is the Gen 1 Giftor over 55 and have current insurance coverage?"
                                                       required={true}
                                                />
                                            </Typography>

                                            <RadioGroup
                                                    {...commonRadioProps}
                                                    {...field}
                                                    onChange={onHasInsuranceChange}
                                                    value={values && values[field.name]}
                                            >
                                                {
                                                    field.options.map(radioOptionLabel)
                                                }
                                            </RadioGroup>
                                            <ErrorHelper errors={errors} name={field.name} />
                                        </Grid>
                                );
                            })
                    }

                    {
                            values.currentKaiZenPolicy === YesNoVal.No &&
                            values.hasCurrentInsuranceCoverage === YesNoVal.Yes &&
                            fields.noFieldHasInsuranceYes.map((field, index) => {
                                return (
                                        <Grid item key={index} lg={6} md={4} sm={6} xl={4} xs={12}>
                                            <React.Fragment>
                                                <TextFieldCommon values={values} onChange={handleChange} {...field} />
                                                <ErrorHelper errors={errors} name={field.name} />
                                            </React.Fragment>
                                        </Grid>
                                );
                            })
                    }

                    {
                            values.currentKaiZenPolicy === YesNoVal.No &&
                            values.hasCurrentInsuranceCoverage === YesNoVal.No &&
                            <Grid item xs={12}>
                                <Typography className="mt20 mb20 colorRed" variant="body1">
                                    Gen 1 is required to have a current Kai-Zen policy or have active insurance coverage
                                    if over the age of 55. If these requirements are not met please contact support
                                </Typography>
                            </Grid>
                    }
                </Grid>

                <Typography variant="body1" className="floatLeft w100 mt40 mb10" component="strong">
                    Required Documents for this GWT case
                </Typography>

                <DownloadLink
                        filePath={`${LOGO_BASE_URL}sample/GWTRequirementsForm.docx`}
                        fileName="GWTRequirementsForm.docx"
                        linkName="Download GWT Requirements"
                        type="AddGroup"
                />

                {
                    fields.documents.map((field, index) => {
                        const leftFileUploaderCondition = field.verificationDocumentType ? !taxDocumentYearOne && taxDocSubType : field.leftFileUploaderCondition;
                        const viewDeleteCondition = field.verificationDocumentType ? taxDocSubType : true;
                        const rightFileUploaderCondition = field.verificationDocumentType ? !taxDocumentYearTwo && taxDocSubType : field.rightFileUploaderCondition;
                        return (
                                <Grid key={index} container spacing={2}>
                                    {
                                            field.verificationDocumentType &&
                                            <Grid item xs={12} className="textLeft">
                                                <Typography variant="body2" className="floatLeft mt0 mb10 w100">
                                                    Income Verification for Gen 1 Giftor
                                                </Typography>
                                                <SelectDocTypeField
                                                        clientSigned={agentSigned}
                                                        handleChange={async (e: any) => {
                                                            setFieldValue("taxDocument", e.target.value);
                                                        }}
                                                        items={taxDocDropDown}
                                                        label={"Verification Document Type"}
                                                        name="taxDocument"
                                                        value={taxDocSubType}
                                                />
                                            </Grid>
                                    }

                                    <Grid item xs={12} md={6} lg={6} className="textLeft">
                                        {
                                                field.leftHeading &&
                                                <Typography variant="body2" className="floatLeft mt0 mb0">
                                                    {field.leftHeading}
                                                </Typography>
                                        }
                                        {
                                                leftFileUploaderCondition &&
                                                <div className="fileUpload mt5 mb10 floatLeft w100">
                                                    <FileUploader
                                                            setOnProgress={setOnProgress}
                                                            onProgress={onProgress}
                                                            exactFileName={field.leftExactFileName + moment().format("DD-MM-YYYY HH:mm:ss")}
                                                            dirName={directory}
                                                            dirType={"documents"}
                                                            fileType={fileType}
                                                            width={15000}
                                                            height={15000}
                                                            content={"Upload " + `${field.leftTitle}`}
                                                            onFinish={async (_publicUrl: string, name: string) => {
                                                                TriggerState(name, field.id, "left");
                                                                await updateDocument(field.leftUpdateDocument, name, field.leftType, field.leftSubType, field.leftKey);
                                                                setOnProgress(false);
                                                            }}
                                                    />
                                                </div>
                                        }
                                        {
                                                !leftFileUploaderCondition && viewDeleteCondition &&
                                                <ViewAndDelete
                                                        identityPhoto={field.leftIdentityPhoto}
                                                        clientSigned={agentSigned}
                                                        title={field.leftTitle}
                                                        action={
                                                            {
                                                                key: field.leftKey,
                                                                type: field.leftType,
                                                                side: field.leftSide,
                                                            }
                                                        }
                                                        userId={userId}
                                                        docusignStatus={enrollment}
                                                        directory={directory}
                                                        setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                        setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                        setGreatWealthTransferCoverLetter={setGreatWealthTransferCoverLetter}
                                                        setGreatWealthTransferDisclosureLetter={setGreatWealthTransferDisclosureLetter}
                                                        setUploadDriversLicenseGEN1GWT={setUploadDriversLicenseGEN1GWT}
                                                        setUploadFinancialStatementForms={setUploadFinancialStatementForms}
                                                />
                                        }
                                        {
                                                field.leftDownload1LinkName &&
                                                <DownloadLink
                                                        filePath={field.leftDownload1FilePath}
                                                        fileName={field.leftDownload1FileName}
                                                        linkName={field.leftDownload1LinkName}
                                                        type="AddGroup"
                                                />
                                        }

                                        {
                                                field.leftDownload2LinkName &&
                                                <DownloadLink
                                                        filePath={field.leftDownload2FilePath}
                                                        fileName={field.leftDownload2FileName}
                                                        linkName={field.leftDownload2LinkName}
                                                        type="AddGroup"
                                                />
                                        }

                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6} className="textLeft">
                                        {
                                                field.rightHeading &&
                                                <Typography variant="body2" className="floatLeft mt0 mb0">
                                                    {field.rightHeading}
                                                </Typography>
                                        }
                                        {
                                                rightFileUploaderCondition && !field.rightHide &&
                                                <div className="fileUpload mt5 mb10 floatLeft w100">
                                                    <FileUploader
                                                            setOnProgress={setOnProgress}
                                                            onProgress={onProgress}
                                                            exactFileName={field.rightExactFileName + moment().format("DD-MM-YYYY HH:mm:ss")}
                                                            dirName={directory}
                                                            dirType={"documents"}
                                                            fileType={fileType}
                                                            width={15000}
                                                            height={15000}
                                                            content={"Upload " + `${field.rightTitle}`}
                                                            onFinish={async (_publicUrl: string, name: string) => {
                                                                TriggerState(name, field.id, "right");
                                                                await updateDocument(field.rightUpdateDocument, name, field.rightType, field.rightSubType, field.rightKey);
                                                                setOnProgress(false);
                                                            }}
                                                    />
                                                </div>
                                        }
                                        {
                                                !rightFileUploaderCondition && viewDeleteCondition &&
                                                <ViewAndDelete
                                                        identityPhoto={field.rightIdentityPhoto}
                                                        clientSigned={agentSigned}
                                                        title={field.rightTitle}
                                                        action={
                                                            {
                                                                key: field.rightKey,
                                                                type: field.rightType,
                                                                side: field.rightSide,
                                                            }
                                                        }
                                                        userId={userId}
                                                        docusignStatus={enrollment}
                                                        directory={directory}
                                                        setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                        setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                        setGreatWealthTransferCoverLetter={setGreatWealthTransferCoverLetter}
                                                        setGreatWealthTransferDisclosureLetter={setGreatWealthTransferDisclosureLetter}
                                                        setUploadDriversLicenseGEN1GWT={setUploadDriversLicenseGEN1GWT}
                                                        setUploadDriversLicenseBackGEN1GWT={setUploadDriversLicenseBackGEN1GWT}
                                                        setUploadFinancialStatementForms={setUploadFinancialStatementForms}
                                                />
                                        }
                                        {
                                                field.rightDownload1LinkName &&
                                                <DownloadLink
                                                        filePath={field.rightDownload1FilePath}
                                                        fileName={field.rightDownload1FileName}
                                                        linkName={field.rightDownload1LinkName}
                                                        type="AddGroup"
                                                />
                                        }
                                    </Grid>
                                </Grid>
                        );
                    })
                }
            </>
    );
};
export default GreatWealthTransferDetails;