import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import {
    kaiZenPhysicianRebrand,
    kaiZenPhysician
} from "../../constants";

type strategyProp = {
    strategyName: string;
    registrationMarkLandingPage?: boolean,
    tooltip?: boolean,
    withText?: boolean,
    ucFirst?: boolean,
    parentComponentStrategyId?: number,
    includeThe?: boolean,
    className?:string,
};

export default function StrategyName(props: strategyProp) {
    const { strategyName, registrationMarkLandingPage, tooltip, withText, ucFirst, parentComponentStrategyId,className, includeThe = true } = props;
    const { user } = useSelector((state: RootState) => state.auth);
    let registrationMark: boolean | undefined = true;
    let strategyId: any = parentComponentStrategyId || 1;
    if (user && user.client) {
        registrationMark = user.client.strategy && user.client.strategy.registrationMark;
        strategyId = (user.client.strategy && user.client.strategy.id) || strategyId;
    } else if (user && user.accessType === "client" && (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 4 || user.role === 15)) {
        registrationMark = user.demoStrategy && user.demoStrategy.registrationMark;
        strategyId = (user.demoStrategy && user.demoStrategy.id) || strategyId;
    } else {
        registrationMark = registrationMarkLandingPage;
    }
    const getColorItem = (strategyName: string) => {
        const strArr = strategyName.split("-");
        let firstSpanClass = "textBlack";
        let secondSpanClass = "highlightTxt";
        if (strArr[0] === "Kai") {
            firstSpanClass = "highlightTxt";
            secondSpanClass = "textBlack";
        }
        return (
                <span style={{ display: "inline-block",lineHeight:'25px' }} className="strategyName">
                    <span className={firstSpanClass}>{strArr[0]}-</span><span className={secondSpanClass}>{strArr[1]}</span>{(registrationMark) ? <sup>&reg;</sup> : <></>}
                </span>
        );
    };
    const simpleStrategyText = () => {
            return <span className={className} style={{display: "inline-block"}}>{strategyName}{(registrationMark) ?
                    <sup>&reg;</sup> : <></>}</span>
    }
    const strategyText = (
            <>
                {strategyName === "Tri-Zen" || strategyName === "Kai-Zen" ? (
                        getColorItem(strategyName)
                ) : simpleStrategyText()}
            </>
    );

    if (tooltip) {
        return simpleStrategyText();
    }

    if (withText) {
        if (strategyId === kaiZenPhysicianRebrand || strategyId === kaiZenPhysician) {
            return simpleStrategyText();
        } else {
            return <>{strategyText} {ucFirst ? "Plan" : "plan"}</>;
        }
    }
    return strategyText;
}
