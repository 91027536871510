import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, ListItem, ListItemIcon, ListItemText, List } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import EnrollmentGetStarted from "./images/EnrollmentGetStarted.jpg";

const useStyles = makeStyles((theme) =>
        createStyles({
            colorBg: {
                float: "left",
                width: "100%",
                paddingBottom: "6%",
                position: "relative",
                paddingTop: "6%",
                [theme.breakpoints.down("sm")]: {
                    padding:'6% 16px'
                },
            },
        }),
);
export default function LearnMore() {
    const classes = useStyles();
    return (
            <div className={classes.colorBg}>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} md={10} xl={8}>
                        <Grid container spacing={3} justify="center" alignItems="center">
                            <Grid item xs={12} md={6}>
                                <Typography variant="h2" align="left" className="tittle"> ilia Makes Strategy
                                    Management Easy.</Typography>
                                <Typography variant="body1" align="left">We understand learning
                                    everything about how a financial strategy works can be stressful. We created ilia
                                    specifically to help make things simple from estimating your potential returns,
                                    hearing from experts, and a streamlined enrollment experience. For additional
                                    information about ilia and it’s benefits, please contact us.
                                </Typography>
                                <List className="ulList">
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLight"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Learn the specifics of your Kai-Zen, Tri-Zen, Leverage Savings Plan or Enhanced Split Dollar Strategy."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLight"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Watch videos from experts about the benefits of NIW strategies."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLight"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Enroll through our streamlined step-by-step process."
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img width="100%" src={EnrollmentGetStarted} alt="EnrollmentGetStarted"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
    );
}