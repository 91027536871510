import React from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { Button, PageTitle, TextField } from "./formInputs";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import loginlogo from "../styles/images/logo-ilia.png";
import login from "../styles/images/login.png";
import { useActions } from "../actions";
import * as Actions from "../actions/auth";
import { required } from "../utils/validation";
import SetGroupLinkParticipantPassword from "./SetGroupLinkParticipantPassword";
import { History } from "history";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
        createStyles({

            logInWrap: {
                "&::after": {
                    content: "\"\"",
                    position: "absolute",
                    background: "#3971EC",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: "42%",
                    [theme.breakpoints.down("sm")]: {
                        position: "inherit",
                        background: "transparent",
                    },
                },

            },
            textWhite: {
                color: "#FFFFFF",
            },
            textCenter: {
                textAlign: "center",
            },
            mt10: {
                marginTop: 10,
            },
            mr30: {
                marginRight: 30,
            },
            loginCard: {
                margin: "auto",
                width: 865,
                boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
                background: "transparent !important",
                position: "absolute",
                left: 0,
                right: 0,
                zIndex: 999,
                border: 0,
                top: "20%",
                padding: "2%",
                [theme.breakpoints.down("sm")]: {
                    marginBottom: 40,
                    background: "#ffffff !important",
                    width: "92%",
                },
            },
            muiCardContent: {
                padding: 15,
                float: "left",
                width: "100%",
                position: "relative",
            },
            loginForm: {
                padding: "60px 15px 50px",
                float: "left",
                width: "100%",
            },
            margin0: {
                margin: 0,
            },
            titleMain: {
                fontSize: 26,
                lineHeight: "34px",
                textAlign: "center",
                margin: 0,
                float: "right",
            },
            dpNone: {
                [theme.breakpoints.down(992)]: {
                    display: "none",
                },
            },
            floatRight: {
                float: "right",
            },
            imgResponsive: {
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginTop: "25%",
            },
            clickableLink: {
                display: "inline-flex",
                alignItems: "center",
                verticalAlign: "middle",
            },

            clickableLinksvg: {
                float: "left",
            },
            mr10: {
                marginRight: 10,
            },
        }),
);
type Props = {
    children: {
        groupLinkDetail?: {
            id: number,
            groupName: string
        }
        history: History
    }
}

function GroupLinkAuth(props: Props & InjectedFormProps<{}, Props>) {
    const { handleSubmit, pristine, submitting, children } = props;
    const [groupLinkParticipantDetial, setgroupLinkParticipantDetial] = React.useState(undefined);
    const authActions = useActions(Actions);
    const classes = useStyles();
    const onGroupLinkAuth = async (data: any) => {
        const resp = await authActions.authGroupLinkParticipant(Object.assign(data, {
            groupLinkId: children.groupLinkDetail && children.groupLinkDetail.id,
        }));
        if (resp && resp.id) {
            setgroupLinkParticipantDetial(resp);
        }
    };

    if (groupLinkParticipantDetial) {
        return (
                <SetGroupLinkParticipantPassword>
                    {{
                        groupLinkParticipantDetial,
                        history: children.history,
                    }}
                </SetGroupLinkParticipantPassword>
        );
    }
    return (
            <div className={classes.logInWrap}>
                <PageTitle title="Group-Link"/>
                <Grid container>
                    <Grid item xs={12}>
                        <Card className={classes.loginCard}>
                            <CardContent className={classes.muiCardContent}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                                        <img alt="loginLogo" src={loginlogo}/>
                                        <Typography variant="h5" className="mb20">
                                            {children.groupLinkDetail &&
                                            <p className={"groupLinkAuth"}>Hello {children.groupLinkDetail.groupName} Member</p>
                                            }
                                            <span>
											Please confirm the details below to
											authenticate your account
										</span>
                                        </Typography>
                                        <form className={classes.loginForm + " " + classes.margin0} onSubmit={handleSubmit(onGroupLinkAuth)}>
                                            <Field
                                                    name="lastName"
                                                    fullWidth
                                                    component={TextField}
                                                    placeholder="LAST NAME"
                                                    type="text"
                                                    validate={[required]}
                                            />
                                            <Field
                                                    name="ssn"
                                                    fullWidth
                                                    component={TextField}
                                                    placeholder="LAST FOUR DIGITS OF SOCIAL"
                                                    type="text"
                                                    validate={[required]}
                                            />
                                            <Button
                                                    fullWidth={true}
                                                    type="submit"
                                                    label="Authenticate"
                                                    variant="contained"
                                                    color="primary"
                                                    className="mt15"
                                                    disabled={pristine || submitting}
                                                    loading={submitting}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item xs={12} md={5} lg={5} className={classes.dpNone}>
                                        <Typography variant="h3" align="right" className="mr10">
                                            VERIFICATION
                                        </Typography>
                                        <img alt="login" className="floatRight img-responsive" src={login}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
    );
}

export default reduxForm<{}, Props>({
    form: "GroupLinkAuth",  // a unique identifier for this form
})(GroupLinkAuth);
