import React, { useEffect } from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import { NumberTextField } from "../../../../../../../components/formikFormInputs";
import * as Yup from "yup";
import { Formik } from "formik";
import GetFormContent from "../../Beneficiaries/BeneficiariesList/GetFormContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import numeral from "numeral";
import NumberFormat from "react-number-format";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import { payloadDataHandler } from "../index";
import { isLongForm } from "../../Utilities";

const EarnedIncome = (props: any) => {
    const {
        autoSaveTrigger,
        earnedIncomeRef,
        financialAndLiabilitiesArr,
        formType,
        isFromClientDetail, /*setIsError ,*/
        offerType,
        setEarnedVerified,
        setEarnedVerifyRef,
        setFinancialAndLiabilitiesArr,
        setFormType,
        setSaveFormContent,
        user,
    } = props;
    const clientSigned = user && user.enrollment && user.enrollment.clientSigned ? 1 : 0;
    const { updateClientFields } = useActions(AccountActions);
    const [isConfirmCheckbox, setIsConfirmCheckbox] = React.useState(user && user.client && user.client.verifyEarnedIncome ? true : false);

    const earnedVerifyRef = React.useRef<any>();
    useEffect(() => {
        if (earnedVerifyRef.current && typeof setEarnedVerifyRef === 'function') {
            setEarnedVerifyRef(earnedVerifyRef);
        }
    }, [earnedVerifyRef.current]);
    useEffect(()=>{
        setEarnedVerified(isConfirmCheckbox);
    },[isConfirmCheckbox])

    const fieldObj = [
        {
            id: "grossAnnualSalary",
            name: "grossAnnualSalary", //grossAnnualSalary is the annualSalary task NIWC-1537
            helperFieldText: "",
            // label: <Label label={"Annual Salary"} required={false}/>,
            label: <Label label={ isLongForm(offerType) ? "Salary (if self employed, provide gross and net)" : "Annual Salary" } required={false}/>,
            isRequired: true,
            filedType: "string",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "bonus",
            name: "bonus",
            helperFieldText: "",
            label: <Label label={"Bonus"} required={false}/>,
            isRequired: false,
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "commission",
            name: "commission",
            helperFieldText: "",
            label: <Label label={"Commission"} required={false}/>,
            isRequired: false,
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "spouseEarnedIncome",
            name: "spouseEarnedIncome",
            helperFieldText: "",
            label: <Label label={"Spouse’s Earned Income"} required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
        {
            id: "otherEarnedIncome",
            name: "otherEarnedIncome",
            helperFieldText: "",
            label: <Label label={"Other"} required={false}/>,
            isRequired: false,
            className: "mt10",
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                                                position="start">$</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 6,
                xl: 4,
            },
            disabled: clientSigned ? true : false,
            show: true
        },
    ];




    /*Set initial values*/
    const initialValues = {  };
    /* Validations of fields set */
    let keysValidation = {};

    fieldObj.forEach((field) => {
        if(field.name === 'grossAnnualSalary') {
            initialValues[field.name] = user && user.client && user.client[field.name] || user.client['annualIncome']; //show annualIncome as annual salary if annualIncome is null
        } else {
            initialValues[field.name] = user && user.client && user.client[field.name] || "";
        }
        // if (field.isRequired) {
        //     keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required("Required Field") : Yup.string().trim().required("Required Field");
        // } else {
        //     keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number() : Yup.string().trim();
        // }
    });

    const validationSchema = Yup.object().shape(keysValidation);


    return (

            <Grid container spacing={2}>

                <Grid item xs={12} md={12}>
                    <Formik
                            innerRef={earnedIncomeRef}
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            validateOnChange={true}
                            onSubmit={async (values: any, { setSubmitting }) => {
                                try {
                                    let payload:any = payloadDataHandler(values, "EARNED", isConfirmCheckbox);
                                    // await updateClientFields({
                                    //     ...payload,
                                    //     verifyEarnedIncome: isConfirmCheckbox ? 1 : 0,
                                    //     formType: "EARNED",
                                    // }, user && user.client && user.client.id || "", isFromClientDetail);

                                    const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "EARNED")
                                    if(index > -1) {
                                        financialAndLiabilitiesArr.splice(index, 1);
                                    }
                                    setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
                                        ...payload
                                    }])

                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 400);
                                } catch (e) {
                                    return false;
                                }
                            }}
                    >
                        {
                            (formikProps) => {
                                /*if (Object.keys(formikProps.errors).length > 0) {
                                    setTimeout(() => setIsError(true), 500);
                                } else {
                                    if (formikProps.dirty) {
                                        setTimeout(() => setIsError(false), 500);
                                    }
                                }*/
                                let totalValues: number = 0;
                                Object.keys(formikProps.values).map((key) => {
                                    if (key === "netIncome") {
                                        return;
                                    }
                                    const value = formikProps.values[key];
                                    totalValues = totalValues + (numeral(value).value() || 0);
                                    return true;
                                });

                                return (
                                    <form className="floatLeft w100" onBlur={autoSaveTrigger} onSubmit={formikProps.handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <span className="hLine"/>
                                                <Typography variant="body1" component="strong"
                                                            className="floatLeft w100 mb20">
                                                    Earned Income - Past Year
                                                    <span className="floatRight">
                                                        Total Earned Income:
                                                        <NumberFormat
                                                                value={totalValues || 0}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                        />
                                                    </span>
                                                </Typography>
                                                <Typography variant="caption"
                                                            className="textLight mb15 floatLeft w100">
                                                    Fill out or verify any earned income you have below making any
                                                    necessary changes. If a particular field doesn’t apply you can
                                                    leave it blank. If self-employed please provide both Gross and Net Annual Salary.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <GetFormContent isFromClientDetail={isFromClientDetail} fieldObj={fieldObj} {...formikProps} isSelfEmployed={false}
                                                        enableOnChangeSubmit={false} setSaveFormContent={setSaveFormContent}
                                                        formType={formType}
                                                        setFormType={setFormType}
                                        />
                                        {!isFromClientDetail &&
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div style={{ display: "flex", alignItems:'center' }} className="mt20" ref={earnedVerifyRef} id="earnedVerifyRef">
                                                    <Checkbox style={{ height: "28px", marginRight: "10px" }}
                                                        className="floatLeft pd0 mr10" color={"primary"}
                                                        value={isConfirmCheckbox ? true : false}
                                                        checked={isConfirmCheckbox ? true : false}
                                                        onChange={(e, v) => {
                                                        //setIsError(!v);
                                                        setIsConfirmCheckbox(v);
                                                        setFormType(formType)
                                                        // updateClientFields({
                                                        //     verifyEarnedIncome: v ? 1 : 0,
                                                        //     apiType: "EARNED",
                                                        // }, user && user.client && user.client.id || "", isFromClientDetail);
                                                        }}
                                                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}/>
                                                    <Typography variant="caption">
                                                        <span>I verify that my total earned income is </span>
                                                        <NumberFormat
                                                                value={totalValues || 0}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                        />.
                                                        <span className="requiredHighlight">*</span>
                                                    </Typography>
                                                </div>
                                                {
                                                    !isConfirmCheckbox &&
                                                    <p style={{margin: 0}}>
                                                        <span style={{color: "#FF1744", fontSize: 12}}> Required Field </span>
                                                    </p>
                                                }
                                            </Grid>
                                        </Grid>
                                        }
                                    </form>
                                );
                            }
                        }
                    </Formik>
                </Grid>
            </Grid>
    );
};

export default EarnedIncome;