import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import { isMobile } from "react-device-detect";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EstimationListTable from "../EstimationListTable";
import Assumptions from "../Assumptions";
import StrategyName from "../../../../../../components/client/StrategyName";
import { maskCurrency } from "../../../../../../utils/commonUtils";
import TabNavigation from "../TabNavigation";
import { trustFeeText } from "../../../../../../constants";
import { Info } from "@material-ui/icons";
import MinimumContribution from "../../../../kaizen/MinimumContribution";

highcharts3d(Highcharts);

function EstimationTabThree(props: any) {
    const {
        estimationData,
        strategyName,
        strategySlug,
        registrationMark,
        responseData,
        setTabValue,
        lifeTimeDeathBenefitSeeking,
        callingFrom,
        tag
    } = props;

    const estimateListView: any = React.useRef(null);
    const [estimateListViewScrollRight, setEstimateListViewScrollRight] = React.useState(true);
    const {s3rootkey, stress, comparison, illustration, common } = responseData;
    let contributionAmount = stress && stress.contributionamount;
    //If Employer Remove that from total
    if (common && common.contributionamountemployer) {
        contributionAmount = contributionAmount - common.contributionamountemployer;
    }
    const illustrationTotalIncome: number = illustration && illustration.totalincome || 0;
    const comparisonTotalIncome: number = comparison && comparison.totalincome || 0;
    const deathbenefitage90:number = comparison && comparison.deathbenefitage90 || 0;

    const deathbenefitlessloanincomeageto: number = illustration && illustration.data && illustration && illustration.data.deathbenefitlessloanincomeageto || 0;
    const deathbenefitendincome: number = comparison && comparison.deathbenefitendincome || 0;

    const aftertaxtotalincome:number = illustration && illustration.data && illustration.data.aftertaxtotalincome || 0;
    const deferredtaxtotalincome:number = illustration && illustration.data && illustration.data.deferredtaxtotalincome || 0;

    const flagBit = lifeTimeDeathBenefitSeeking;

    const paymentFrequency = common && common.paymentfrequency || "Annual";
    let comparisionText: any = `<span class="f22 textCenter">Comparing your plan <br/>you pay the same <span class="textHighLight">${maskCurrency(contributionAmount + "")}</span> for 5 years.</span>`;
    if (paymentFrequency === "Monthly") {
        comparisionText = `<span class="f22 ̰textCenter">Comparing your plan <br/> You pay <span class="textHighLight">${maskCurrency((contributionAmount/12) + "")} per month</span> (${maskCurrency(contributionAmount + "")} for 5 years).</span>`;
    }

    const scrollListView = () => {

        const lastPos = estimateListView.current.scrollLeft;
        estimateListView.current.scrollLeft = estimateListViewScrollRight ? estimateListView.current.scrollLeft + 40 : estimateListView.current.scrollLeft - 40;
        if (lastPos === estimateListView.current.scrollLeft) {
            lastPos ? setEstimateListViewScrollRight(false) : setEstimateListViewScrollRight(true);
        }
    };


    let [labelOne, labelTwo] = ['IUL with Kai-Zen Leverage', 'IUL Without Leverage'];
    let categoryForTwo: any = [ labelOne , labelTwo];
    let seriesForTwo: any = [{
        name: "DeathBenefit",
        data: [deathbenefitlessloanincomeageto, deathbenefitage90],
        color: "#ecb339",
        stack: "deathbenefit",
    }, {
        name: "Distribution",
        data: [illustrationTotalIncome, comparisonTotalIncome],
        color: "#3871ec",
        stack: "distribution",
    }];

let categoryForFour: any = [ labelOne , labelTwo, "After Tax Investment", "Tax Deferred Investment"];
    let seriesForFour: any = [{
        name: "DeathBenefit",
        data: [deathbenefitlessloanincomeageto, deathbenefitendincome, 0, 0],
        color: "#ecb339",
        stack: "deathbenefit",
    }, {
        name: "Distribution",
        data: [illustrationTotalIncome, comparisonTotalIncome, aftertaxtotalincome, deferredtaxtotalincome],
        color: "#3871ec",
        stack: "distribution",

    }];

    const config: any = {
        chart: {
            type: "column",
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                viewDistance: 125,
                depth: isMobile ? 10 : 40,
                frame: {
                    back: {
                        visible: "auto",
                    },
                    left: {
                        visible: "auto",
                    },
                },
            },
            maxWidth: 1350,
            //maxHeight: 800,
            height: isMobile ? 500 : 650,
            marginTop: 100,
        },
        title: {
            text: comparisionText,
            useHTML: true,
        },
        xAxis: {
            categories: flagBit ? categoryForTwo : categoryForFour,
            labels: {
                skew3d: true,
                style: {
                    fontSize: "16px",
                },
            },
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: "",
                skew3d: true,
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            enabled: true,
            y: 0,
            borderWidth: 1,
            backgroundColor: "#ffffff",
            height:50,
            borderRadius: 8,
            border: "none",
            width: 300,
            useHTML: true,
            shadow: false,
            style: {
                fontSize: 14,
            },
            outside: true,
            formatter: function() {
                const that: any = this;
                return "<div>" + that.key + "" + `<ul class="pd0 margin0 listStyle"><li>${that.series.name}: <b>${maskCurrency(that.y)}</b></li></ul></div>`;
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                pointWidth: isMobile ? 20 : 100,
                stacking: "normal",
                groupPadding: flagBit ? 0.2 : 0.1,
                dataLabels: {
                    verticalAlign: "top",
                    overflow: "allow",
                    crop: false,
                    y: isMobile ? -25: -90,
                    z: isMobile ? 2 : 30,
                    x: isMobile ? 2 : 10,
                    enabled: true,
                    useHTML: true,
                    formatter: function() {
                        const that: any = this;
                        if (that.series.name === "Distribution") {
                            return `<img src="/images/pig.png" alt="image not found" title="" id="distributionPig" height=${isMobile ? "20" : "90"} width=${flagBit ? isMobile ? "20" : "90" : isMobile ? "20" : "80"}>`;
                        }
                        if ((that.x == "Index Policy Kaizen Financed" || that.x == "Index Policy Non-Financed") && that.series.name === "DeathBenefit") {
                            return `<img src="/images/umbrella.png" alt="image not found" title="" height=${isMobile ? "20" : "90"} width=${flagBit ? isMobile ? "20" : "90" : isMobile ? "20" : "80"}>`;
                        }
                        if ((that.x == "After Tax Investment" || that.x == "Tax Deferred Investment") && that.series.name === "DeathBenefit") {
                            return `<img src="/images/bandedumbrella.png" alt="image not found" title="" height=${isMobile ? "20" : "90"} width=${flagBit ? isMobile ? "20" : "90" : isMobile ? "20" : "80"}>`;
                        }
                        //bandedumbrella
                    },
                },
            },
            column: {
                stacking: "normal",
                depth: isMobile ? 20 : 150,
            },
        },
        series: flagBit ? seriesForTwo : seriesForFour,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 992,
                    maxHeight: 992,
                    marginTop: 200,
                },
            }]
        },
        accessibility: {
            enabled: false
        }
    };


    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body1" align="center" component="strong"
                            className="mb0 mt30 floatLeft w100"><StrategyName
                    strategyName={strategyName} /> Vs.
                    Alternative
                    Choices</Typography>
                <Typography variant="caption" align="center" className="floatLeft w100 ">See just how much you
                    get
                    with <StrategyName strategyName={strategyName} /> compared with
                    alternatives.</Typography>
                <div className="floatLeft w100 mt30 mb20" style={{ border: "1px solid #cfcfcf" }}>
                    <Grid container justify="flex-end">
                        <Grid item xs={12} md={6} className="mb20 mt15">
                            <Typography variant="caption" className="floatRight mr30 ml15 displayF">
                                Potential policy or other distributions <FiberManualRecordIcon className={strategySlug === "kaizen" ? "colorBlue" : "colorGreen"} />
                            </Typography>

                            <Typography variant="caption" className="floatRight displayF"> Potential Death
                                Benefit <FiberManualRecordIcon
                                    className="colorBlue colorYellow" /></Typography>
                        </Grid>
                    </Grid>
                    <HighchartsReact highcharts={Highcharts} options={config} />

                    <Grid container className="mb15">
                        <Grid item xs={12} md={12}>
                            <Typography variant="caption" className="ml15">IUL with Kai-Zen Leverage <Tooltip title="The Kai-Zen strategy uniquely uses third party lender leverage to minimize out of pocket insurance costs, fees, and expenses and to provide for high possible potential for growth.

" arrow>
                                    <IconButton>
                                        <Info color="primary" />
                                    </IconButton>
                                </Tooltip> </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant="caption" className="ml15">IUL Without Leverage <Tooltip
                                    title="Traditional IUL insurance policies are usually funded entirely by the insured and do not use third-party lender leverage and therefore, in comparison to leveraging, limiting the policy’s cash value. "
                                    arrow>
                                    <IconButton>
                                        <Info color="primary" />
                                    </IconButton>
                                </Tooltip> </Typography>
                        </Grid>
                    </Grid>


                </div>
            </Grid>
            <Grid item xs={12}>

                <Typography variant="body1" component="strong" align="center"
                            className="mt50 mb50 textCenter w100 floatLeft">
                    Full comparison details between <StrategyName strategyName={strategyName} /> and
                    alternatives.
                </Typography>
                <div style={{ position: "relative" }}>
                    <IconButton className="scrollRight" aria-label="ChevronRightIcon"
                                onClick={() => scrollListView()}>
                        {estimateListViewScrollRight ?
                            <ChevronRightIcon fontSize="large" /> :
                            <ChevronLeftIcon fontSize="large" />
                        }
                    </IconButton>
                    <div ref={estimateListView} className="tabsContentWrap mb40 floatLeft w100">
                        <EstimationListTable estimationData={estimationData}
                                             strategySlug={strategySlug}
                                             registrationMark={registrationMark}
                                             responseData={responseData}
                                             lifeTimeDeathBenefitSeeking={lifeTimeDeathBenefitSeeking}
                        />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <span className="hLine" />
                <TabNavigation
                    next={{ title: "", visibility: false, setTabValueNext: setTabValue, tabValNext: "" }}
                    prev={{
                        title: "Prev: Benefits & Distributions",
                        visibility: true,
                        setTabValuePrev: setTabValue,
                        tabValPrev: "two",
                    }}
                />
                <Assumptions strategyName={strategyName} strategySlug={strategySlug}
                             estimationData={estimationData}
                             common={responseData && responseData.common} callingFrom={callingFrom} key={s3rootkey} tag={tag} />
            </Grid>
        </Grid>
    );
}

export default EstimationTabThree;