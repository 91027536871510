import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import ConfirmEnrollment from "../ConfirmEnrollment";
import EnrollmentSelection from "../EnrollmentSelection";
import { useActions } from "../../actions";
import * as Actions from "../../actions/enrollment";
import * as AccountActions from "../../actions/account";
import { useHistory } from "react-router-dom";
import { AccountType, cssPaths, isServicingAccessible } from "../../constants";
import CommonList from "./CommonList";
import AgentLinkClient from "./AgentLinkClient";

const enrollmentTabse = [
    "/new-enrollment",
    "/enrollment/start",
    "/enrollment/start/questionnaire",
    "/enrollment/start/complete-application",
    "/enrollment/start/final-illustration",
    "/enrollmentServicingComing",
];

export default function ClientNavigation(props: any) {
    const { user, handleNavClick, SetIsEstimation } = props;
    const [routeName, setRouteName] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [enrollmentSelection, setEnrollmentSelection] = React.useState(false);
    const accountActions = useActions(AccountActions);
    const enrollmentActions = useActions(Actions);
    const profile = useSelector((state: RootState) => state.profile);
    const isRefresh = useSelector((state: RootState) => state.isRefresh);
    const { user: { client, accessType } } = useSelector((state: RootState) => state.auth);
    const profileEnrollment = (profile && profile.enrollment) || (user && user.enrollment);
    const isClientDemoAccess = (user.accessType === "client" && user.demoClientId);
    const isServiceDashboardAccessible = user && user.inviteStatus && (isServicingAccessible(user) || false);
    let strategySlug: string = client && client.strategy && client.strategy.slug ? client.strategy.slug : "kaizen";
    const estimationRoute = accessType === "client" ? "/client/estimation" : "/client/estimationV1";
    const history = useHistory();
    const isLead = Boolean(client && client.isLead) && (client && client.agent && client.agent.leads && client.agent.leads.length > 0);
    if (accessType === "client" && (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 4 || user.role === 15 || user.role === AccountType.Aggregator || user.role === AccountType.AggregatorMember) && user.demoStrategy) {
        strategySlug = (user.demoStrategy && user.demoStrategy.slug) || strategySlug;
    }

    React.useEffect(() => {
        setRouteName(history.location.pathname === "/accounts" ? "/intro" : history.location.pathname);
        if (history.location.pathname === "/accounts" && user && user.enrollment && [0, 1, 2].indexOf(user.enrollment.status) > -1) {
            setRouteName("/enrollment/start");
        }
    }, [isRefresh]);

    React.useEffect(() => {
        if (user.enrollment && history.location.pathname === "/enrollment") {
            history.push("/enrollment/start");
        }
        setRouteName(history.location.pathname);
        if (history.location.pathname.search("/client/estimation-new") > -1) {
            SetIsEstimation(true);
        } else {
            SetIsEstimation(false);
        }
    }, [user.enrollment, routeName, history.location.pathname]);

    function handleNavigation(route: string) {
        setRouteName(route);
        if (handleNavClick) {
            handleNavClick();
        }
        history.push(route);
    }

    const enrollmentClick = async (user: any) => {
        if (!user || user.accessType === "client") {
            return false;
        }
        await accountActions.checkProfileDetailsCompleted("ClientNavigation");

        if (user && user.enrollment && user.enrollment.version === 1 && user.enrollment.clientSigned === 1) {
            history.push("/enrollmentServicingComing");
            return false;
        }

        if (user && ((user.enrollment && user.enrollment.version === 2) || !user.enrollment)) {
            history.push("/new-enrollment");
        }

        await enrollmentActions.getEnrollment();
    };

    const enrollmentStatus = () => {
        if (profileEnrollment && profileEnrollment.version === 2) {
            if (profileEnrollment.enrollmentStatus === 0 && profileEnrollment.enrollmentSubStatus === -1) {
                return <span className="btnHilightBar glow" style={{ color: "#ffffff" }}> Get Started </span>;
            }
            if (profileEnrollment && ((profileEnrollment.enrollmentStatus + "." + profileEnrollment.enrollmentSubStatus >= "4.0" || user.override === 1) || (profileEnrollment.enrollmentStatus + "." + profileEnrollment.enrollmentSubStatus >= "4.1") || (strategySlug === "cashstream" && profileEnrollment.enrollmentStatus + "." + profileEnrollment.enrollmentSubStatus >= "3.0"))) {
                return <span className="btnHilightBar glow"
                             style={{ color: "#ffffff", animation: "none" }}> Completed </span>;
            } else {
                return <span className="btnHilightBar glow" style={{ color: "#ffffff" }}> In Progress </span>;
            }
        } else if (profileEnrollment && profileEnrollment.version === 1 && profileEnrollment.clientSigned === 1) {
            return <span className="btnHilightBar glow"
                         style={{ color: "#ffffff", animation: "none" }}> Completed </span>;
        }
    };

    return (
            <React.Fragment>
                <link rel="stylesheet" href={`${process.env.PUBLIC_URL + cssPaths.clientNavigation}`} />
                <ConfirmEnrollment open={open} user={user} setOpen={setOpen} />
                <EnrollmentSelection open={enrollmentSelection} setOpen={(flag) => setEnrollmentSelection(flag)} />
                {
                        !isLead &&
                        <CommonList user={user}
                                    routeName={routeName}
                                    setRouteName={setRouteName}
                                    strategySlug={strategySlug}
                                    handleNavClick={handleNavClick}
                                    estimationRoute={estimationRoute}
                                    enrollmentTabse={enrollmentTabse}
                                    enrollmentClick={enrollmentClick}
                                    SetIsEstimation={SetIsEstimation}
                                    handleNavigation={handleNavigation}
                                    enrollmentStatus={enrollmentStatus}
                                    isClientDemoAccess={isClientDemoAccess}
                                    isServiceDashboardAccessible={isServiceDashboardAccessible}
                        />
                }
                {
                        isLead &&
                        <AgentLinkClient user={user}
                                         routeName={routeName}
                                         strategySlug={strategySlug}
                                         handleNavClick={handleNavClick}
                                         enrollmentTabse={enrollmentTabse}
                                         estimationRoute={estimationRoute}
                                         enrollmentClick={enrollmentClick}
                                         handleNavigation={handleNavigation}
                                         enrollmentStatus={enrollmentStatus}
                                         isClientDemoAccess={isClientDemoAccess}
                                         isServiceDashboardAccessible={isServiceDashboardAccessible}
                        />
                }
            </React.Fragment>
    );
}