import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography/Typography";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    InputAdornment,
    Link,
    TableFooter,
    TextField,
} from "@material-ui/core";
import { Button } from "../../../../../components/formInputs";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import IconButton from "@material-ui/core/IconButton";
import { AccountType, ClientDocumentType, DocApprovalStatus, GWT_GEN2_STRATEGY } from "../../../../../constants";
import { dateUtils } from "../../../../../utils";
import ViewDocument from "../modals/ViewDocument";
import * as Aws from "../../../../../actions/aws";
import { useActions } from "../../../../../actions";
import SearchIcon from "@material-ui/icons/Search";
import { Pagination } from "../../../../../components/table";
import * as Clients from "../../../../../actions/client";
import useQueryString from "../../../../../hooks/useQueryString";
import DocumentApprovalActions from "./DocumentApprovalActions";
import DocumentApprovalSnackBar from "./DocumentApprovalSnackBar";
import CoverLetterSnackBar from "./CoverLetterSnackBar";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import ConfirmUploadDialog from "../modals/ConfirmUploadDialog";
import RequestClientDocModal from "../modals/RequestClientDocModal";
import LoaderGif from "../../../../../styles/images/loader_nw.gif";
import UploadSpecificDocument from "../dialogs/UploadSpecificDocument";
import TableWrap from "../../../../../components/TableWrap/TableWrap";
import MuiAlert from "@material-ui/lab/Alert";
import { getDocumentNameAndType, downloadDocument } from "../../../../../utils/commonUtils";
import { CircularLoading, TableView } from "../../../../../components/Common";
import PaymentDocuments from "./PaymentDocuments";
const useStyles = makeStyles({
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
    pd10:{
        padding:10
    } ,
    mb20:{
        marginBottom:20
    },
    mt20:{
        marginTop:20
    }
});

export default function DocumentsTab(props: { role: number | undefined, clientDetail: any, getClientDetail:Function, ssnFlag:boolean }) {
    const clientActions = useActions(Clients);
    const { role, clientDetail, getClientDetail, ssnFlag } = props;
    const classes = useStyles();
    const [documentList, setDocumentList] = React.useState([]);
    const [gwtDocumentList, setGwtDocumentList] = React.useState([]);
    const [documentListCount, setDocumentListCount] = React.useState(0);
    const [uploadMoreDocs, setUploadMoreDocs] = React.useState(false);
    const [openDocument, setOpenDocument] = React.useState(false);
    const [documentUrl, setDocumentUrl] = React.useState("");
    const [fileType, setFileType] = React.useState("");
    const [refresh, setRefresh] = React.useState<any>("");
    const awsActions = useActions(Aws);
    const [page, setPage] = useQueryString("page", 0);
    const [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", 25);
    const [orderBy] = useQueryString("orderBy", "createdAt");
    const [order, setOrder] = useQueryString("order", "desc");
    const [search, setSearch] = useQueryString("search", "");
    const [allDocApproved, setAllDocApproved] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const [singleDocDetail, setSingleDocDetail] = React.useState({});
    const [snackBar, setSnackBar] = React.useState<boolean>(false);
    const [requestDoc, setRequestDoc] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [approveAllDocs, setApproveAllDocs] = React.useState(false);
    const [coverLetterReq, setCoverLetterReq] = React.useState<boolean>(false);
    const [uploadCoverLetter, setUploadCoverLetter] = React.useState<boolean>(false);
    const [documentToView, setDocumentToView] = React.useState<object>({});
    const [uploadAdditionalTaxDocs, setUploadAdditionalTaxDocs] = React.useState<boolean>(false);
    let additionalTaxDoc = !!(clientDetail && clientDetail.client && clientDetail.client.additionalTaxDocReq === 1);
    const [additionalTaxDocReq, setAdditionalTaxDocReq] = React.useState<boolean>(additionalTaxDoc);
    let AdditionalTaxDocsButtonText:string = "This case requires additional tax documents.";
    const isGWTGen2 = (clientDetail && clientDetail.client && clientDetail.client.strategyId === GWT_GEN2_STRATEGY);

    const downloadFile = (document) => {
        return (
                <React.Fragment>
                    {
                            (role === 1 || role === 2) &&
                            <DocumentApprovalActions document={document}
                                                     clientDetail={clientDetail}
                                                     getClientDocuments={getClientDocuments}
                                                     setLoading={setLoading}
                                                     setRefresh={setRefresh}
                            />


                    }
                    <Link className="colorBlue" onClick={async () => {
                        await downloadDocument(document, document.enrollmentId, awsActions);
                    }}><GetAppOutlinedIcon /></Link>

                    {
                            (role === 1 || role === 2) &&
                            ((document.frontOrBack === "Front" && document.approvalStatusFront !== DocApprovalStatus.APPROVED.approvalStatus) ||
                                    (document.frontOrBack === "Back" && document.approvalStatusBack !== DocApprovalStatus.APPROVED.approvalStatus))
                            && document.documentType && document.documentType !== ClientDocumentType.ADMIN_PROPOSAL &&
                            <>
                                <Link className="colorBlue" onClick={() => {
                                    setConfirmDialog(true);
                                    setSingleDocDetail(document);
                                }}><PublishOutlinedIcon /></Link>
                            </>
                    }
                </React.Fragment>
        );
    };

    const gridProps = {
        headers: ["Name", "Doc Type", "File Type", "Date Modified", "Uploaded By", "Actions"],
        list: gwtDocumentList.map((document: any) => {
            return {
                name: (
                        <Link
                                className="backBtn" color="primary"
                                onClick={() => {
                                    handleDocument(document);
                                    setDocumentToView(document);
                                }}
                        > {document.name} </Link>
                ),
                docType: document.documentType,
                fileTYpe: document.fileType,
                dateModified: dateUtils.asDbDateFormat(document.updatedAt),
                uploadedBy: document.user && document.user.firstName + " " + document.user.lastName,
                action: (
                        downloadFile(document)
                ),
            };
        }),
    };

    if(clientDetail && clientDetail.client && clientDetail.client.currentlyEmployed && clientDetail.client.currentlyEmployed.toLowerCase() === "other"){
        if(clientDetail && clientDetail.client && clientDetail.client.parentsAreIncomeEarner){  //WT
            AdditionalTaxDocsButtonText = `This case requires Wealth Transfer docs.`;
        } else {
            AdditionalTaxDocsButtonText = `This case requires Income Earner docs.`;
        }
    } else if(clientDetail && clientDetail.usingSpouseIncome == "1" ){
        AdditionalTaxDocsButtonText = `This case requires Spouse docs.`;
    }

    useEffect(() => {
        if (clientDetail && clientDetail.client && clientDetail.client.id) {
            setAdditionalTaxDocReq(clientDetail.client.additionalTaxDocReq === 1);
        }
    }, [clientDetail]);

    useEffect(() => {
        if (clientDetail && clientDetail.client && clientDetail.client.id) {
            getClientDocuments(clientDetail.client.id).then(() => {
            });
        }
        if (clientDetail && clientDetail.client && clientDetail.client.strategyId) {
            getClientDocuments(clientDetail.client.id, clientDetail.client.strategyId).then(() => {
            });
        }
    }, [clientDetail && clientDetail.client && clientDetail.client.id, clientDetail && clientDetail.client && clientDetail.client.strategyId, page, rowsPerPage, order, orderBy, search, refresh]);

    useEffect(() => {
        if (clientDetail && clientDetail.client && clientDetail.client.id) {
            setCoverLetterReq(!!clientDetail.client.coverLetterReq);
        }
    }, [clientDetail && clientDetail.client && clientDetail.client.id]);



    const getClientDocuments = async (clientId: number, strategyId?: number) => {
        const isGWTGen2 = (strategyId === GWT_GEN2_STRATEGY);
        let queryObj: any = { page, rowsPerPage, search: search, sortBy: orderBy, order};

        if(isGWTGen2){
            let gwtObj: any = { gwt: true};
            queryObj = { ...queryObj, ...gwtObj };
        }
        const clientDetail = await clientActions.getClientDocuments(clientId, queryObj);
        let list: any = [];
        if (clientDetail && clientDetail.rows && clientDetail.rows.length > 0) {
            setSnackBar(true);
            if(!isGWTGen2){
                setDocumentListCount(clientDetail.count);
            }
            setAllDocApproved(true);
            for (let key in clientDetail.rows) {
                let documentName: string = clientDetail.rows[key].name;
                if (clientDetail.rows[key].path) {
                    clientDetail.rows[key].name = documentName + (clientDetail.rows[key].backPath ? " Front" : "");
                    //  clientDetail.rows[key].size = getFileSize(LOGO_BASE_URL + clientDetail.rows[key].path);
                    let path = clientDetail.rows[key].path.split(".");
                    clientDetail.rows[key].fileType = path[path.length - 1].toUpperCase();
                    clientDetail.rows[key].frontOrBack = "Front";
                    if (
                            clientDetail.rows[key].approvalStatusFront !== DocApprovalStatus.APPROVED.approvalStatus &&
                            clientDetail.rows[key].approvalStatusFront !== DocApprovalStatus.DOCUMENT_REQUESTED.approvalStatus &&
                            clientDetail.rows[key].approvalStatusFront !== DocApprovalStatus.REJECTED.approvalStatus
                    ) {
                        setAllDocApproved(false);
                    }
                    list.push({ ...clientDetail.rows[key], documentName: documentName });
                }
                if (clientDetail.rows[key].backPath) {
                    let path = clientDetail.rows[key].backPath.split(".");
                    clientDetail.rows[key].fileType = path[path.length - 1].toUpperCase();
                    clientDetail.rows[key].frontOrBack = "Back";
                    if (clientDetail.rows[key].approvalStatusBack !== DocApprovalStatus.APPROVED.approvalStatus &&
                            clientDetail.rows[key].approvalStatusBack !== DocApprovalStatus.DOCUMENT_REQUESTED.approvalStatus &&
                            clientDetail.rows[key].approvalStatusBack !== DocApprovalStatus.REJECTED.approvalStatus
                    ) {
                        setAllDocApproved(false);
                    }
                    list.push({
                        ...clientDetail.rows[key],
                        name: documentName + " Back",
                        path: "",
                        documentName: documentName,
                    });
                }
            }
        }
        isGWTGen2 ? setGwtDocumentList(list) : setDocumentList(list);
    };

    const handleRefresh = () => {
        setRefresh(Math.random());
    };

    const handleDocument = async (document: any) => {
        let documentUri: string = "";
        let fileType: string = "";
        let dirName: string = clientDetail && clientDetail.enrollment && clientDetail.enrollment.id;
        if (document && document.id > 0) {
            let docNameAndType: any = getDocumentNameAndType(document);
            if (docNameAndType.documentUri && docNameAndType.fileType) {
                documentUri = docNameAndType.documentUri;
                let file = documentUri.split(".");
                fileType = file[file.length - 1].toUpperCase();
                const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
                let queryObj = {
                    objectName: documentUri,
                    contentType: contentType,
                };
                const s3url = await awsActions.getSecureUrl(dirName, queryObj, true);
                setDocumentUrl(s3url);
                setFileType(fileType);
                setOpenDocument(true);
            }
        }
    };

    const dowloadAlldocs = async () => {
        let dirName: string = clientDetail && clientDetail.enrollment && clientDetail.enrollment.id;
        let queryObj = {
            objectName: "notafile",
            contentType: "text/directory",
            isDownloadDir: 1,
        };
        const s3url = await awsActions.getSecureUrlAndDownload(dirName, queryObj);
        console.log("***s3url***", s3url);
        // let blob = new Blob([new Buffer(s3url.Body, "binary")]
        //         , {type: s3url.ContentType});
        // const url = window.URL.createObjectURL(blob);
        // const link = window.document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", documentUri);
        // window.document.body.appendChild(link);
        // link.click();
    };

    const handleClose = () => {
        setOpenDocument(false);
    };
    const handleApproveAll = async () => {
        let clientId: number = clientDetail && clientDetail.client && clientDetail.client.id || 0;
        if (clientId < 1) {
            return false;
        }
        await clientActions.approveAllDocsAction({ clientId, docAction: DocApprovalStatus.APPROVED });
        handleRefresh();
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // TODO NIWC-2640 work in progress
    // 1. button can be same but dialog contents and dropdows will be diffrent
    // upload funxtionality
    const UploadSpecificDocumentButton = (
            <>
                {
                        !(role === 4 && clientDetail.enrollment.clientSigned === 0) &&
                        <Button
                                className="floatRight ml20 mt10"
                                color="primary"
                                label="Upload"
                                onClick={() => {
                                    setUploadCoverLetter(false);
                                    setUploadMoreDocs(true);
                                    setUploadAdditionalTaxDocs(role === 4);
                                }}
                                type="submit"
                                variant="contained"
                        />
                }
            </>
    );

    return (
            <React.Fragment>
                <Grid container spacing={2} justify="space-between">
                    <Grid item xs={12} sm={4}>
                        <TextField
                                value={search}
                                label={"Search"}
                                placeholder={"Search"}
                                variant="outlined"
                                onChange={e => {
                                    setPage(0);
                                    setSearch(e.target.value);
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                        className="iconBtn"
                                                        aria-label="search account"
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                    ),
                                }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        {
                                (role == 1 || role == 2 || role == 4) && clientDetail && clientDetail.enrollment && clientDetail.enrollment.id &&
                                UploadSpecificDocumentButton
                        }
                        {(role == 1 || role == 2) && clientDetail && clientDetail.enrollment && clientDetail.enrollment.id &&
                                <Button
                                        type="submit"
                                        label="Request Docs"
                                        variant="contained"
                                        color="primary"
                                        className="floatRight mt10"
                                        onClick={() => {
                                            setRequestDoc(true);
                                            //setUploadMoreDocs(true)
                                            console.log("Request Docs");
                                        }}
                                />
                        }
                        {/*{( role == 1 || role == 2 ) &&*/}
                        {/*<Link*/}
                        {/*    className="floatLeft"*/}
                        {/*    color="primary"*/}
                        {/*    onClick={() => {*/}
                        {/*        dowloadAlldocs();*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    Download all documents*/}
                        {/*</Link>*/}
                        {/*}*/}

                    </Grid>
                </Grid>
                {
                        (role == 1 || role == 2) && snackBar &&
                        <DocumentApprovalSnackBar allDocApproved={allDocApproved}/>
                }
                {
                        !isGWTGen2 && (role === AccountType.Agent || role === AccountType.Admin || role === AccountType.MasterAdmin) && coverLetterReq &&
                    <CoverLetterSnackBar setUploadMoreDocs={setUploadMoreDocs} setUploadCoverLetter={setUploadCoverLetter} setUploadAdditionalTaxDocs={setUploadAdditionalTaxDocs}/>
                }
                {
                        !isGWTGen2 && (role === AccountType.Agent || role === AccountType.Admin || role === AccountType.MasterAdmin) && additionalTaxDocReq &&
                        <Alert action={
                            <Button
                                    type="button"
                                    variant="contained"
                                    label="Upload Docs"
                                    color="primary"
                                    onClick={() => {
                                        setUploadCoverLetter(false)
                                        setUploadMoreDocs(true);
                                        setUploadAdditionalTaxDocs(true);
                                    }}/>
                        } severity="error" variant="outlined" className={classes.pd10 + " " + classes.mb20 + " " + classes.mt20}>
                            {AdditionalTaxDocsButtonText}
                        </Alert>
                }
                <TableWrap>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>Name</TableCell>
                            <TableCell align="left" className={classes.tableHead}>Doc Type</TableCell>
                            <TableCell align="left" className={classes.tableHead}>File Type</TableCell>
                            <TableCell align="left" className={classes.tableHead}>Date Modified</TableCell>
                            <TableCell align="left" className={classes.tableHead}>Uploaded By</TableCell>
                            <TableCell align="center" style={{ minWidth: "230px" }} className={classes.tableHead}>
                                <span className={"mr15"}>Actions</span>
                                {(role == 1 || role == 2) && !allDocApproved && documentListCount > 0 &&
                                        <Link className="colorBlue" onClick={() => {
                                    setApproveAllDocs(true);
                                }}>Approve All</Link>
                                }
                                </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                                loading &&
                                <img src={LoaderGif} alt="Loading..." style={{
                                    position: "fixed",
                                    left: "0",
                                    right: "0",
                                    top: "30%",
                                    width: "100px",
                                    margin: "0 auto",
                                }}/>

                        }
                        {!loading && documentList && documentList.length > 0 &&
                                documentList.map((document: any) => {
                                    return (
                                            <TableRow key={Math.random()}>
                                                <TableCell component="td" scope="row">
                                                    <a className="colorBlue" onClick={() => {
                                                        handleDocument(document);
                                                    setDocumentToView(document);
                                                    }}>{document.name}</a> {document.name === "Other" && " (" + document.otherDocName + ")"}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {document.documentSubType ? document.documentSubType : "N/A"}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {document.fileType}
                                                </TableCell>

                                                <TableCell component="td" scope="row">
                                                    {dateUtils.asDbDateFormat(document.updatedAt)}
                                                </TableCell>
                                                <TableCell component="td" scope="row">
                                                    {
                                                            document.user && document.user.firstName + " " + document.user.lastName
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    {/*<IconButton aria-label="delete" size="small">
                                                        {document.documentType === ClientDocumentType.ADMIN_PROPOSAL ?
                                                                <a target={"_blank"}  href={document.path ? LOGO_BASE_URL + clientDetail.enrollment.id + '/' + document.path : LOGO_BASE_URL + document.backPath } download={document.path ? document.path : document.backPath} > <GetAppOutlinedIcon/> </a>
                                                        :
                                                            (document.documentType === ClientDocumentType.MORE_DOCUMENTS ?
                                                                <a target={"_blank"} href={document.path ? LOGO_BASE_URL + clientDetail.userId + '/' + document.path : LOGO_BASE_URL + document.backPath } download={document.path ? document.path : document.backPath} > <GetAppOutlinedIcon/> </a>
                                                            :
                                                                <a target={"_blank"} href={document.path ? LOGO_BASE_URL + document.path : LOGO_BASE_URL + document.backPath } download={document.path ? document.path : document.backPath} > <GetAppOutlinedIcon/> </a>
                                                            )
                                                        }
                                                    </IconButton>
                                                    { (role == 1 || role == 2) && false &&
                                                    <IconButton aria-label="delete">
                                                        <DeleteOutlineIcon className="colorRed"/>
                                                    </IconButton>
                                                    }*/}
                                                    {
                                                            (role === 1 || role === 2) &&
                                                            <DocumentApprovalActions document={document}
                                                                                     clientDetail={clientDetail}
                                                                                     getClientDocuments={getClientDocuments}
                                                                                     setLoading={setLoading}
                                                                                     setRefresh={setRefresh}
                                                            />
                                                    }

                                                    <Link className="colorBlue" onClick={async () => {
                                                        await downloadDocument(document, document.enrollmentId, awsActions);
                                                    }}><GetAppOutlinedIcon/></Link>
                                                    {
                                                            (role === 1 || role === 2) &&
                                                            ((document.frontOrBack === "Front" && document.approvalStatusFront !== DocApprovalStatus.APPROVED.approvalStatus) ||
                                                                    (document.frontOrBack === "Back" && document.approvalStatusBack !== DocApprovalStatus.APPROVED.approvalStatus))
                                                            && document.documentType && document.documentType !== ClientDocumentType.ADMIN_PROPOSAL &&
                                                            <>
                                                                <Link className="colorBlue" onClick={() => {
                                                                    setConfirmDialog(true);
                                                                    setSingleDocDetail(document);
                                                                }}><PublishOutlinedIcon/></Link>
                                                            </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                    );
                                })
                        }

                        {documentList && documentList.length === 0 &&
                                    <TableRow key={Math.random()}>
                                        <TableCell component="td" colSpan={6}>
                                        <Typography variant="caption" align="left" className="ml15 mt10 ">
                                        There are no
                                            documents
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                    {!loading && documentList && documentList.length > 0 &&
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Pagination className="floatRight" page={Number(page)} rowsPerPage={Number(rowsPerPage)}
                                                    setPage={setPage}
                                                    setRowsPerPage={setRowsPerPage}
                                                    accountList={{ count: documentListCount }}/>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                    }
                </TableWrap>

                <ViewDocument handleNotes={handleDocument} handleClose={handleClose} openDocument={openDocument}
                                  documentUrl={documentUrl} fileType={fileType} title={"Documents"} documentToView={documentToView} downloadDocument={ (document) => { downloadDocument(document, document.enrollmentId, awsActions)}}/>
                {
                        uploadMoreDocs &&
                        <UploadSpecificDocument
                                documentList={documentList}
                                tag='document_tabs'
                                clientDetail={clientDetail}
                                coverLetterUploadedFromActionItem={uploadCoverLetter}
                                documentsTab={true}
                                handleClose={()=>{
                                    setUploadMoreDocs(false);
                                    handleRefresh();
                                    getClientDetail((clientDetail && clientDetail.client && clientDetail.client.id || 0), ssnFlag)}
                                }
                                open={uploadMoreDocs} 
                                setCoverLetterReq={setCoverLetterReq}
                                uploadAdditionalTaxDocs={uploadAdditionalTaxDocs}
                                uploadCoverLetter={uploadCoverLetter} 
                        />
                }
                <ConfirmUploadDialog document={singleDocDetail} clientDetail={clientDetail}
                                     confirmDialog={confirmDialog}
                                     setConfirmDialog={setConfirmDialog}
                                     handleRefresh={handleRefresh}/>
                {
                        requestDoc && <RequestClientDocModal
                                user={clientDetail}
                                open={true}
                                content="Request Client Documents"
                                clientDetail={clientDetail}
                                handleClose={() => {
                                    setRequestDoc(false);
                                }}
                        />
                }

                <Dialog
                        fullWidth={true}
                        maxWidth="sm"
                        onClose={() => {

                        }}
                        aria-labelledby="customized-dialog-title"
                        open={approveAllDocs}
                >
                    <DialogContent dividers>
                        <Typography variant="body1" className="mb0">
                            Are you sure you want to approve all docs?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                label="Yes"
                                disabled={!approveAllDocs}
                                onClick={() => {
                                    setApproveAllDocs(false);
                                    setTimeout(() => handleApproveAll(), 800);
                                }}
                        >
                            YES
                        </Button>
                        <Button
                                className="bgRed"
                                size="small"
                                variant="contained"
                                color="primary"
                                label="No"
                                onClick={() => {
                                    setApproveAllDocs(false);
                                }}
                        >
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>

                {
                        isGWTGen2 &&
                        <>
                            <Grid container spacing={2} justify="space-between">
                                <Grid item xs={12}>
                                    <Typography variant="body1" component="strong" className="floatLeft w100 mt20">
                                        Great Wealth Transfer Gen 1 Documents
                                    </Typography>
                                    {
                                            isGWTGen2 && (role === AccountType.MasterAdmin || role === AccountType.Admin) &&
                                            clientDetail && clientDetail.enrollment && clientDetail.enrollment.id &&
                                            UploadSpecificDocumentButton
                                    }
                                </Grid>
                            </Grid>
                            {
                                    loading && <CircularLoading />
                            }
                            <TableView {...gridProps} />
                        </>
                }

                {(role === 1 || role === 2) &&  <PaymentDocuments />}

            </React.Fragment>
    );
};