import * as React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	Typography, IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const InforceLifeAlertPopup = (props: any) => {
	const { open, setOpen } = props;

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog maxWidth="md" open={open} onClose={handleClose}>
			<DialogTitle id="customized-dialog-title" onClick={() => handleClose()}>
				1035 Alert
				<IconButton edge="end" className="floatRight" color="inherit" onClick={(e) => handleClose()}
							aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={2} justify="center">
					<Grid item xs={12}>
						<Typography align="center" variant="body1" className="mt20 mb30">
							This strategy does not allow 1035
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default InforceLifeAlertPopup;
