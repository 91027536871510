import React from "react";
import { useFormikContext } from "formik";
import { PIQ, proposedInsuredQuestion75 } from "../InsuredQuestions";
import InsuredPageCheckBox from "../component/InsuredPageCheckBox";
import {
    DatePicker,
    ErrorHelper,
    ErrorCommon,
    NumberTextField,
    RadioGroup as FormikRadioGroup,
    TextField,
    TextFieldCommon
} from "../../../../../../../components/formikFormInputs";
import { Grid, Typography } from "@material-ui/core";
import {Label} from "../../../../../../../components/formInputs";
import { proposedInsuredQuestion68TopQuesHeading } from "./ProposedInsuredQuestion68";
import { FlyFieldsSuffix } from "../insuranceValidations";
import InsuredToggleButtonGroup from "../component/InsuredToggleButtonGroup";
import { NOTA } from "../../../../../../../constants";


const Proposed_Insured_Questions75XCdh = (props:{name:string, clientSigned:boolean}) => {
    const {name, clientSigned} = props;
    const { values, handleChange, handleBlur, errors, touched }:any = useFormikContext();
    const fieldObj:any = [{
        id: `${name}${FlyFieldsSuffix.AnnualFlyingHours}`,
        name: `${name}${FlyFieldsSuffix.AnnualFlyingHours}`,
        label: <Label label={"Annual flying hours in the last 12 months"} required={true}/>,
        placeholder: "Annual flying hours in the last 12 months",
        component: NumberTextField,
        variant: "outlined",
        filedType: "number",
        disabled: clientSigned,
        className: "fullWidthcustomFields"
    }, {
        id: `${name}${FlyFieldsSuffix.ExpectedAnnualFlyingHours}`,
        name: `${name}${FlyFieldsSuffix.ExpectedAnnualFlyingHours}`,
        label: <Label label={"Expected annual flying hours in the last 12 months"} required={true}/>,
        placeholder: "Expected annual flying hours in the last 12 months",
        component: NumberTextField,
        variant: "outlined",
        filedType: "number",
        disabled: clientSigned,
        className: "fullWidthcustomFields"
    }, {
        id: `${name}${FlyFieldsSuffix.NumberOfSoloHours}`,
        name: `${name}${FlyFieldsSuffix.NumberOfSoloHours}`,
        label: <Label label={"Number of solo hours of experience"} required={true}/>,
        placeholder: "Number of solo hours of experience",
        component: NumberTextField,
        variant: "outlined",
        filedType: "number",
        disabled: clientSigned,
        className: "fullWidthcustomFields"
    },{
        id: `${name}${FlyFieldsSuffix.DateLastPiloted}`,
        name: `${name}${FlyFieldsSuffix.DateLastPiloted}`,
        helperFieldText: "",
        label: <Label label={"Date last piloted"} required={true}/>,
        placeholder: "Date last piloted",
        disabled: clientSigned,
        filedType: "string",
        component: DatePicker,
        variant: "outlined",
        disableFuture:true,
        saveFormat: "USER"
    }];

    return <React.Fragment>
        {fieldObj.map((field, index) => {
            return (
                    <Grid item xs={6} sm={6} md={6} key={index} className="mt10">
                        <TextFieldCommon
                                {...field}
                                values={values}
                                onChange={(e: any) => handleChange(e) }
                                onBlur={handleBlur}
                        />
                        <ErrorCommon errors={errors} name={field.name} touched={touched} />
                    </Grid>
            )})}
    </React.Fragment>
}

const Proposed_Insured_Questions75XCav = (props:{name:string, clientSigned:boolean}) => {
    const {name, clientSigned} = props;
    const { errors, values, handleChange, handleBlur }:any = useFormikContext();
    const disableProperty:{disabled:boolean} = {
        disabled: clientSigned
    }
    return (
            <Grid item xs={12} style={{ backgroundColor: "#edf3f8", padding: 10, marginTop: 20 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Typography variant="body1" component="strong" gutterBottom
                                    className="mt20 mb0 floatLeft w100 ml15">
                            Please Provide Details for Each Event Below
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                        <span className="hLine"/>
                        {values && values[`${PIQ}-75XCav`].map((val: any, inx: number) => {
                            if (val && val.name === `${PIQ}-75XCav_4`) {
                                return <React.Fragment key={inx}></React.Fragment>;
                            }
                            let nameIndex: string = "";
                            let nameIndexArray = (val && val.name).split("_");
                            if (nameIndexArray.length > 0) {
                                nameIndex = nameIndexArray[nameIndexArray.length - 1];
                            }
                            return (
                                    <React.Fragment key={inx}>
                                        <Grid item xs={12} lg={5} className="borderR1px">
                                            <Typography variant="body2" component="strong"
                                                        className="mb20 floatLeft w100">
                                                {val && val.value}
                                            </Typography>
                                            <TextFieldCommon
                                                    name={`${name}X${nameIndex}Dev`}
                                                    id={`${name}X${nameIndex}Dev`}
                                                    placeholder={"Date of Event"}
                                                    component={DatePicker}
                                                    values={values}
                                                    label={<Label label={"Date of Event"} required={true}/>}
                                                    onChange={(e: any) => handleChange(e)}
                                                    onBlur={handleBlur}
                                                    disableFuture={true}
                                                    saveFormat={"USER"}
                                                    {...disableProperty}
                                            />
                                            <ErrorHelper errors={errors} name={`${name}X${nameIndex}Dev`} />
                                            <br/>
                                            <Typography variant="body2" component="strong"
                                                        className="mt20 mb15 floatLeft w100">Were
                                                drugs or alcohol involved?</Typography>
                                            <TextFieldCommon
                                                    className="floatLeft w100"
                                                    /*id={`${val && val.name}al`}
                                                    name={`${val && val.name}al`}*/
                                                    name={`${name}X${nameIndex}Ral`}
                                                    id={`${name}X${nameIndex}Ral`}
                                                    component={FormikRadioGroup}
                                                    values={values}
                                                    onChange={(e: any) => handleChange(e)}
                                                    onBlur={handleBlur}
                                                    {...disableProperty}
                                            />
                                            <ErrorHelper errors={errors} name={`${name}X${nameIndex}Ral`} />
                                        </Grid>
                                        <Grid item xs={12} lg={7} className="mt20">
                                            <TextFieldCommon
                                                    className="mt20"
                                                    /* id={`${val && val.name}pd`}
                                                     name={`${val && val.name}pd`}*/
                                                    name={`${name}X${nameIndex}Tal`}
                                                    id={`${name}X${nameIndex}Tal`}
                                                    component={TextField}
                                                    values={values}
                                                    label={<Label label={"Details about event (location, violation, description, fault assigned):"} required={true}/>}
                                                    onChange={(e: any) => handleChange(e)}
                                                    onBlur={handleBlur}
                                                    multiline={true}
                                                    {...disableProperty}
                                            />
                                            <ErrorHelper errors={errors} name={`${name}X${nameIndex}Tal`} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span className="hLine"/>
                                        </Grid>
                                    </React.Fragment>
                            );
                        })}
                    </Grid>
            </Grid>
    );
}

const ProposedInsuredQuestion75 = (props:any) => {
    const { clientSigned, showComponent, offerType, setErrors } = props;
    const { values, setFieldValue, setFieldTouched, handleChange, handleBlur, errors, touched }:any = useFormikContext();
    const questions:any[] = proposedInsuredQuestion75({ offerType });

    return <>
        {
            questions && questions.length > 0 && questions.map((question:any, idx: number) => {
                return (
                        <React.Fragment key={idx}>
                            <Typography variant="body2" component="strong" gutterBottom className="mt20 mb10">
                                {question && question.label}
                            </Typography>
                            {
                                    question && question.additionalFieldOptions && question.additionalFieldOptions.length &&
                                    <InsuredPageCheckBox question={question} clientSigned={clientSigned} />
                            }
                            {
                                    question && question.name === `${PIQ}-75XCdh` &&
                                    <Grid container spacing={2}>
                                        <Proposed_Insured_Questions75XCdh name={`${PIQ}-75X`} clientSigned={clientSigned}/>
                                    </Grid>
                            }
                            {
                                    (question && question.name === `${PIQ}-75XCav`) &&
                                    (
                                            values &&
                                            values[`${PIQ}-75XCav`] &&
                                            values[`${PIQ}-75XCav`].length > 0 &&
                                            (proposedInsuredQuestion68TopQuesHeading(values[`${PIQ}-75XCav`], NOTA))
                                    ) &&
                                    <Proposed_Insured_Questions75XCav name={`${PIQ}-75`} clientSigned={clientSigned}/>
                            }
                            {
                                    question.type === "radio" && question.name === `${PIQ}-76` &&
                                    <Grid item xs={12}>
                                        <InsuredToggleButtonGroup id={question.name} clientSigned={clientSigned}/>
                                        <span style={{ float: "right", clear: "both" }}>
                                            <ErrorCommon touched={touched} errors={errors} name={question.name} />
                                        </span>
                                    </Grid>
                            }
                            {
                                    question && question.additionalRemarks && question.name === `${PIQ}-76` &&
                                    showComponent(question, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, setErrors)
                            }
                        </React.Fragment>
                )
            })
        }
    </>
}

export default ProposedInsuredQuestion75;