import React, { Component } from 'react';
import AvatarEditor from 'react-avatar-editor'
import {Grid, Typography} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import axios from "axios";

interface IRecipeProps {
    src?: string,
    uploadImage?:any,
    showError? : any,
    width?:number,
    height?:number,
    iconOnly?: boolean,
    ext?:string,
    allowZoomOut: any,
    scale: any,
}

interface IRecipeState {
    src?: string,
    crop?: any,
    croppedImageUrl?:any,
    blob?:any,
    iconOnly?:boolean,
    errorText?:string,
    width?:number,
    height?:number | undefined,
    imageWidth?:number,
    maxWidth?:number,
    imageRefWidth?:number,

    allowZoomOut: any,
    position: { x: any, y: any },
    scale: any,
    rotate: any,
    borderRadius: number
}

class cropImage extends Component<IRecipeProps, IRecipeState> {
   constructor(props:any){
       super(props);
       this.state = {
           iconOnly: props.iconOnly ? props.iconOnly : false,
           src: props.src ? props.src : '',
           crop: {
               unit: 'px',
               width: props.width ? props.width : 250,
               height: props.height ?  props.height : 70,
               aspect: props.iconOnly  ? 4 / 4 : props.width / props.height,
           },
           width: Number(props.width) ? Number(props.width) : 250,
           height: Number(props.height) ?  Number(props.height) : 70,
           croppedImageUrl : '',
           blob:null,
           errorText : '',
           imageWidth : Number(props.width) ? Number(props.width) : 250,
           maxWidth : 350,
           imageRefWidth : Number(props.width) ? Number(props.width) : 250,

           allowZoomOut: props.allowZoomOut || false,
           position: { x: 0.5, y: 0.5 },
           scale: props.scale || 1,
           rotate: 0,
           borderRadius: 0,
       }
   }
    imageRef:any = null;
    fileUrl:any = null;
    editor:any = null;

    componentDidUpdate(prevProps:any) {
        if (prevProps.src !== this.props.src) {
            this.setState({
                src : this.props.src
            });
        }
    }

    setEditorRef = (editor:any) => {
        if (editor) this.editor = editor
    }

    handleScale = (zoom:any) => {
        this.setState({
            scale : parseFloat(zoom)
        })
        this.getImageBlob();
    }

    handleBorderRadius = (e:any) => {
        const borderRadius = parseInt(e.target.value)
        this.setState({ borderRadius })
        this.getImageBlob();
    }

    logCallback(e:any) {
        this.getImageBlob();
    }

    handlePositionChange = (position:any) => {
        this.setState({ position })
        this.getImageBlob();
    }

    dataURItoBlob = (dataURI: string | undefined) => {
		var byteString;
		if (dataURI && dataURI.split(",")[0].indexOf("base64") >= 0)
			byteString = atob(dataURI.split(",")[1]);
		else byteString = dataURI && unescape(dataURI.split(",")[1]);
		var mimeString = dataURI && dataURI.split(",")[0].split(":")[1].split(";")[0];
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ia], { type: mimeString });
    };

    getImageBlob = () => {
        const canvas = this.editor.getImageScaledToCanvas().toDataURL();
        axios.get(canvas)
        .then(res => {
            let blob = this.dataURItoBlob(res.config.url);
            let imageURL:any = window.URL.createObjectURL(blob);
            let file:any = blob;
            this.setState({ croppedImageUrl : imageURL, blob : file });
            this.props.uploadImage(file)
        })
    }

    render() {
        const { src, imageWidth, maxWidth } = this.state;

        if(!src){
            return (
                <React.Fragment />
            )
        }

        return (
            <div >
                <Grid item xs={12} sm={12} md={12} className="textCenter">
                    {src && imageWidth && (
                        <AvatarEditor
                            ref={this.setEditorRef}
                            scale={parseFloat(this.state.scale)}
                            width={this.state.width}
                            height={this.state.height}
                            position={this.state.position}
                            onPositionChange={this.handlePositionChange}
                            rotate={parseFloat(this.state.rotate)}
                            borderRadius={Number(this.state.width )/ (100 / this.state.borderRadius)}
                            onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                            onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                            onImageReady={this.logCallback.bind(this, 'onImageReady')}
                            image={src}
                            style={{maxWidth:maxWidth}}
                        />
                    )}
                    <div className="controls">
                        <Slider
                            value={parseFloat(this.state.scale)}
                            min={this.state.allowZoomOut ? 0.1 : 1}
                            max={2}
                            step={0.01}
                            aria-labelledby="Zoom"
                            onChange={(e:any, zoom:any) => this.handleScale(zoom)}
                            defaultValue={1}
                        />
                        <Typography id="track-false-slider" gutterBottom>
                            Zoom (-/+)
                        </Typography>
                    </div>

                    <p> <b>Selected Area</b> : {parseFloat(this.state.crop.width).toFixed(0)}*{parseFloat(this.state.crop.height).toFixed(0)} </p>
                    {this.state.errorText !== '' &&
                    <p style={{color:'red'}}>{this.state.errorText}</p>
                    }
                </Grid>
            </div>
        );
    }
}

export default cropImage;
