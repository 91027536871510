import React from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { aviationPilotCertificate } from "../Utilities";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { EnrollmentQuestionProps } from "../../../../../../model/enrollment";
import EnrollmentField from "./EnrollmentField";
import {
    CheckBoxGroup,
    DatePicker,
    ErrorCommon, RadioGroup as FormikRadioGroup,
    TextField,
    TextFieldCommon,
} from "../../../../../../components/formikFormInputs";
import { Label } from "../../../../../../components/formInputs";
import DescribeAnnualFlyingActivity from "./DescribeAnnualFlyingActivity";

const questionList = [{
    key: "",
    name: "Insured_AviationXR6",
    label: "Are you a member of a Military Reserve or National Guard unit on flying status?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: false
}];
const questionList1 = [{
    key: "",
    name: "Insured_AviationXR11",
    label: "Was your certificate granted subject to physcial waiver?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: false,
    additionalFieldName: "",
    additionalFieldType: "",
    additionalFieldRequired: false,
    additionalFieldLabel: "",
    additionalFieldOptions: [],
    notShowHrLine: true,
}, {
    key: "",
    name: "Insured_AviationXR12",
    label: "Have you ever been grounded or restricted for violation of Civil Air Regulations?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: true,
    additionalFieldName: "Insured_AviationXT13",
    additionalFieldType: "textfield",
    additionalFieldRequired: true,
    additionalFieldLabel: "Provide Details",
}];

const questionList2 = [{
    key: "",
    name: "Insured_AviationXR16",
    label: "Pilot",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: false,
    notShowHrLine: true,
    notShowUpperHrLine: true,
}, {
    key: "",
    name: "Insured_AviationXR17",
    label: "Crew member",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: false,
    notShowHrLine: false,
}];

const questionList3 = [{
    key: "",
    name: "Insured_AviationXR40",
    label: "Do you fly only within the United States?",
    type: "radio",
    default: false,
    onYes: false,
    additionalRemarks: true,
    additionalFieldName: "Insured_AviationXT41",
    additionalFieldType: "textfield",
    additionalFieldRequired: true,
    additionalFieldLabel: "Provide Details",
    notShowUpperHrLine: true,
    notShowHrLine: true,
}];

function getCheckBoxArr(isFromClientDetail: boolean, clientSigned: boolean) {
    const accountServiced = [{
        id: "Insured_AviationXR7",
        name: "Insured_AviationXR7",
        label: "",
        component: FormikRadioGroup,
        disabled: clientSigned ? true : false,
        variant: "outlined",
        options: [
            {
                label: "Active",
                value: "Active",
            }, {
                label: "Inactive",
                value: "Inactive",
            },
        ],
        sizeMd: 6,
    }];
    return accountServiced
}

function getAirCraftQuesTextField(isFromClientDetail: boolean, clientSigned: boolean) {
    return {
        id: "typeOfAirCraftFly",
        name: "Insured_AviationXT9",
        helperFieldText: "",
        label: <Label label={"Please Explain"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    };
};

function getCrewPosition(isFromClientDetail: boolean, clientSigned: boolean) {
    return {
        id: "crewPosition",
        name: "Insured_AviationXT10",
        helperFieldText: "",
        label: <Label label={"Please Explain"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    };
}

function getDateOfLastFlight(isFromClientDetail: boolean, clientSigned: boolean) {
    return {
        name: "Insured_AviationXD14",
        label: <Label label={"Date Of Flight"} required={!isFromClientDetail}/>,
        isRequired: !isFromClientDetail,
        placeholder: "Date Field",
        id: "dateField",
        variant: "outlined",
        component: DatePicker,
        disableFuture: true,
        className: "fullWidthcustomFields",
        validate: (value: any) => {
        },
        disabled: clientSigned ? true : false,
        size: {
            md: 12,
            lg: 12,
        },
    };
}

function getCrewMember(isFromClientDetail: boolean, clientSigned: boolean) {
    return {
        id: "crewMember",
        name: "Insured_AviationXT15",
        helperFieldText: "",
        label: <Label label={"Please Explain"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    };
}

function getFlyingHoursStudent(isFromClientDetail: boolean, clientSigned: boolean) {
    return [{
        id: "flyingHoursStudent",
        name: "flyingHoursStudent",
        helperFieldText: "",
        label: "",
        fullWidth: true,
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        ischeckbox: "true",
        component: CheckBoxGroup,
        options: [{
            name: "Insured_AviationXC18",
            text: "Student",
        }],
    }, {
        id: "flyingHoursStudentDesc",
        name: "Insured_AviationXT19",
        helperFieldText: "",
        label: <Label label={"Please Explain"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }];
}

function getFlyingPilotInCommand(isFromClientDetail: boolean, clientSigned: boolean) {
    return [{
        id: "flyingPilotInCommand",
        name: "flyingPilotInCommand",
        helperFieldText: "",
        label: "",
        fullWidth: true,
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        ischeckbox: "true",
        component: CheckBoxGroup,
        options: [{
            name: "Insured_AviationXC20",
            text: "Pilot In Command",
        }],
    }, {
        id: "flyingPilotInCommandDesc",
        name: "Insured_AviationXT21",
        helperFieldText: "",
        label: <Label label={"Please Explain"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }];
}

function getFlyingOtherCapacity(isFromClientDetail: boolean, clientSigned: boolean) {
    return [{
        id: "flyingOtherCapacity",
        name: "flyingOtherCapacity",
        helperFieldText: "",
        label: "",
        fullWidth: true,
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        ischeckbox: "true",
        component: CheckBoxGroup,
        options: [{
            name: "Insured_AviationXC22",
            text: "Other Capacity",
        }],
    }, {
        id: "flyingOtherCapacityDesc",
        name: "Insured_AviationXT23",
        helperFieldText: "",
        label: <Label label={"Please Explain"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt10",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }];
}

type PropsType = {
    values: any,
    clientSigned: boolean,
    setFieldValue: Function,
    isFromClientDetail: boolean,
    offerType: string,
    setSaveAviationAvocationQuestions: Function,
    role: number,
    enableSaveButton: Function,
    handleBlur: Function,
    handleChange: Function,
    errors: any,
    touched: any
}

function InsuredAviationSubQuestions(props: PropsType) {
    const {
        values,
        clientSigned,
        setFieldValue,
        isFromClientDetail,
        offerType,
        setSaveAviationAvocationQuestions,
        role,
        enableSaveButton,
        handleBlur,
        handleChange,
        errors,
        touched,
    } = props;
    const airCraftQuesTextField = getAirCraftQuesTextField(isFromClientDetail, clientSigned);
    const crewPosition = getCrewPosition(isFromClientDetail, clientSigned);
    const crewMember = getCrewMember(isFromClientDetail, clientSigned);
    const flyingHoursStudent = getFlyingHoursStudent(isFromClientDetail, clientSigned);
    const flyingPilotInCommand = getFlyingPilotInCommand(isFromClientDetail, clientSigned);
    const flyingOtherCapacity = getFlyingOtherCapacity(isFromClientDetail, clientSigned);
    const dateOfLastFlight = getDateOfLastFlight(isFromClientDetail, clientSigned);
    const checkBoxArr = getCheckBoxArr(isFromClientDetail, clientSigned);
    let filedProps = { onChange: handleChange, onBlur: handleBlur };

    if (flyingHoursStudent[0].ischeckbox && flyingHoursStudent[0].ischeckbox === "true") {
        filedProps["meta"] = { touched };
        filedProps["meta"] = { errors };
        filedProps["input"] = {
            name: flyingHoursStudent[0].name,
            value: values[flyingHoursStudent[0].name],
            style: { display: "block" },
            onChange: (value, name) => {
                setFieldValue(name, value);
            },
        };
    }

    return (
        values["InsuredAviationAvocation"].includes("Insured_Aviation") &&
        <div className="pl16 pr16 innerContentWrap">
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1"
                                className="floatLeft w100 mt15">{aviationPilotCertificate.label}</Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {
                                    aviationPilotCertificate.checkBoxArr.map((item, index) => {
                                                return (<><Grid item xs={12} sm={6} lg={6} key={index}>
                                                    <FormControlLabel
                                                            className="checkGroupMb0"
                                                            control={<Checkbox className="padding0"
                                                                               checked={values[`${item.name}`] ? true : false}
                                                                               disabled={clientSigned ? true : false}
                                                                               onChange={(e) => {
                                                                                   setFieldValue(`${item.name}`, e.target.checked);
                                                                               }}
                                                                               color={"primary"}
                                                                               value={values[`${item.name}`] ? true : false}
                                                                               name={`${item.name}`}/>}
                                                            label={`${item.label}`}
                                                    />
                                                </Grid></>);
                                            },
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                                <EnrollmentField
                                        question={question}
                                        clientSigned={clientSigned}
                                        isFromClientDetail={isFromClientDetail}
                                        checkIsError={() => {
                                        }}
                                        onChangeFn={() => {
                                        }}
                                        role={role}
                                        offerType={offerType}
                                        setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                />
                        ))}
                    </Grid>
                    <Grid container spacing={2}>
                        {
                                values["Insured_AviationXR6"] === "Yes" &&
                                <>
                                    {checkBoxArr.map((field, index) => {
                                        return (
                                                <Grid item md={field.sizeMd === 6 ? 6 : 12}
                                                      key={index} className="mb15">
                                                    <TextFieldCommon
                                                            {...field}
                                                            values={values}
                                                            onChange={handleChange}
                                                    />
                                                    <ErrorCommon errors={errors} name={field.name}
                                                                 touched={touched}/>
                                                </Grid>
                                        );
                                    })
                                    }
                                </>
                        }
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong" className="mt15">What type of aircraft do you
                                fly?</Typography>
                            <TextFieldCommon
                                    {...airCraftQuesTextField}
                                    values={values}
                                    onChange={(e) => {
                                        handleChange(e);
                                        enableSaveButton();
                                    }}
                                    onBlur={handleBlur}
                            />
                            <ErrorCommon errors={errors} name={airCraftQuesTextField.name}
                                         touched={touched}/>

                        </Grid>
                        <span className="hLine"/>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong">Crew position</Typography>
                            <TextFieldCommon
                                    {...crewPosition}
                                    values={values}
                                    onChange={(e) => {
                                        handleChange(e);
                                        enableSaveButton();
                                    }}
                                    onBlur={handleBlur}
                            />
                            <ErrorCommon errors={errors} name={crewPosition.name}
                                         touched={touched}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {questionList1.map((question: EnrollmentQuestionProps, idx: number) => (
                                <EnrollmentField
                                        question={question}
                                        clientSigned={clientSigned}
                                        isFromClientDetail={isFromClientDetail}
                                        checkIsError={() => {
                                        }}
                                        onChangeFn={() => {
                                        }}
                                        role={role}
                                        offerType={offerType}
                                        setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                />
                        ))}
                    </Grid>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" component="strong" className="mt15 mb0 floatLeft ">
                                Date of last flight as a Pilot</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextFieldCommon
                                    {...dateOfLastFlight}
                                    values={values}
                                    onChange={(e) => {
                                        handleChange(e);
                                        enableSaveButton();
                                    }}
                                    onBlur={handleBlur}
                            />
                            <ErrorCommon errors={errors} name={crewMember.name}
                                         touched={touched}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong">Crew member</Typography>
                            <TextFieldCommon
                                    {...crewMember}
                                    values={values}
                                    onChange={(e) => {
                                        handleChange(e);
                                        enableSaveButton();
                                    }}
                                    onBlur={handleBlur}
                            />
                            <ErrorCommon errors={errors} name={crewMember.name}
                                         touched={touched}/>
                        </Grid>
                        <span className="hLine"/>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" component="strong" className="mt20 mb15 floatLeft ">
                                Is it your intention to fly in the future as a:
                            </Typography>

                            {questionList2.map((question: EnrollmentQuestionProps, idx: number) => (
                                    <EnrollmentField
                                            question={question}
                                            clientSigned={clientSigned}
                                            isFromClientDetail={isFromClientDetail}
                                            checkIsError={() => {
                                            }}
                                            onChangeFn={() => {
                                            }}
                                            role={role}
                                            offerType={offerType}
                                            setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                    />
                            ))}

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" component="strong" className="mb15 floatLeft w100">
                                Total flying hours:
                            </Typography>
                            {
                                flyingHoursStudent.map((item, index) => {
                                            return <><TextFieldCommon
                                                    {...item}
                                                    values={values}
                                                    {...filedProps}
                                                    className={index === 0 ? "mt15 mb10" : ""}
                                            />
                                                <ErrorCommon errors={errors} name={item.name} touched={touched}/>
                                            </>;
                                        },
                                )}
                            {
                                flyingPilotInCommand.map((item, index) => {
                                            return <><TextFieldCommon
                                                    {...item}
                                                    values={values}
                                                    {...filedProps}
                                                    className={index === 0 ? "mt15 mb10" : ""}
                                            />
                                                <ErrorCommon errors={errors} name={item.name} touched={touched}/>
                                            </>;
                                        },
                                )}
                            {
                                flyingOtherCapacity.map((item, index) => {
                                            return <><TextFieldCommon
                                                    {...item}
                                                    values={values}
                                                    {...filedProps}
                                                    className={index === 0 ? "mt15 mb10" : ""}
                                            />
                                                <ErrorCommon errors={errors} name={item.name} touched={touched}/>
                                            </>;
                                        },
                                )}
                        </Grid>
                        <span className="hLine"/>
                        <DescribeAnnualFlyingActivity isFromClientDetail={isFromClientDetail}
                                                      clientSigned={clientSigned} errors={errors}
                                                      touched={touched} values={values}
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                        />
                        <Grid item xs={12}>
                            {questionList3.map((question: EnrollmentQuestionProps, idx: number) => (
                                    <EnrollmentField
                                            question={question}
                                            clientSigned={clientSigned}
                                            isFromClientDetail={isFromClientDetail}
                                            checkIsError={() => {
                                            }}
                                            onChangeFn={() => {
                                            }}
                                            role={role}
                                            offerType={offerType}
                                            setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                    />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
    );
}

export default InsuredAviationSubQuestions;