import React from 'react';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {NS} from "../../model/misc";


interface ExpansionBoxProps {
    children: any,
    expanded: boolean,
    key?: NS
    mainClassName: string,
    onExpansionChange: (event: React.ChangeEvent<{}>, expanded: boolean) => void,
    summaryChildren?: any,
    summaryClassName: string,
    summaryIconOnly?: boolean,
}


const Expansion = (props: ExpansionBoxProps) => {
    const {
        children,
        expanded,
        mainClassName,
        onExpansionChange,
        summaryChildren,
        summaryClassName,
        summaryIconOnly = false,
    } = props;
    return (
        <ExpansionPanel className={mainClassName} expanded={expanded} onChange={onExpansionChange}>
            <ExpansionPanelSummary className={summaryClassName} expandIcon={<ExpandMoreIcon className="f50"/>}>
                {
                    !summaryIconOnly &&
                    summaryChildren
                }
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{display: 'inline-block'}}>
                {
                    children
                }
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default Expansion;
