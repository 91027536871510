import React from "react";
import {Typography} from "@material-ui/core";
import {EnrollmentFileUploader as FileUploader, Label} from "../../formInputs";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

type UPCI = {
    enrollmentId: number | string;
    exactFileName: string;
    fn: string;
    name: string;
    setFN: Function;
}

const UploadCustomProposalIllustration = (props: UPCI) => {
    const {enrollmentId, exactFileName, fn, name, setFN} = props;
    const uploadProps = {
        content: "Drop files here or click to upload",
        dirName: enrollmentId,
        dirType: "documents",
        exactFileName,
        fileType: "application/pdf, .pdf",
        iconOnly: true,
        name: name,
        onFinish: async (publicUrl: string, name: string) => setFN(name),
        textWithIcon: name,
    };
    return (
        <>
            <Typography className="floatLeft w100 mb0" variant="subtitle2" component="strong">
                <Label label={name} required={true}/>
            </Typography>
            <FileUploader {...uploadProps} />
    
        </>
    );
}

export default UploadCustomProposalIllustration;