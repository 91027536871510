import * as React from "react";
import {
    Dialog,
    DialogContent,
    Typography,
    DialogTitle,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

export default function EnrollmentSelection(props: any) {
    const { open, setOpen } = props;
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={() => setOpen(false)}
            >
                <DialogTitle id="customized-dialog-title">
                    <Typography variant="h6" className="floatLeft"> Please select version</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="caption"
                                style={{ width: "100%", margin: "10px 0 0", fontSize: "18px", float: "left" }}>
                        <Link to={"/enrollment"}
                              onClick={() => setOpen(false)}>
                            Enrollment 1.0
                        </Link>
                    </Typography>
                    <br/>
                    <Typography variant="caption"
                                style={{ width: "100%", margin: "10px 0 0", fontSize: "18px", float: "left" }}>
                        <Link to={"/new-enrollment"}
                              onClick={() => setOpen(false)}>
                            Enrollment 2.0
                        </Link>
                    </Typography>
                    <Button
                            className="floatRight mt20"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpen(false)}
                    >
                        CLOSE
                    </Button>
                </DialogContent>
            </Dialog>
    );
}
