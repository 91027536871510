import * as React from 'react';
import { Helmet } from 'react-helmet';

export default function PageTitle(props: { title: string }) {
  return (
      <Helmet>
          <title>{ props.title }</title>
      </Helmet>
  );
}
