
import * as React from "react";
import TextField from '@material-ui/core/TextField';
// @ts-ignore
import InputMask from  "react-input-mask";
const PhoneNumberField = (props: object) => {
    return <InputMask
            mask="999-99-9999"
            {...props}
    >
        {(inputProps: object) => {
         return   <TextField
                    {...inputProps}
                    type="text"
                    variant="outlined"
                    {...props}
            />
        }
        }
    </InputMask>;
};

export default PhoneNumberField;
