import {Tranche, TrancheList} from "../model/tranche";
import {ActionType, PagingParams} from "../model";

export const addTranche = (data: Tranche) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.post('tranche', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Tranche Saved Successfully.", statusCode: 200}
        });
        return dispatch({type: ActionType.ADD_TRANCHE, payload: {}});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

export const updateTranche = (data: Tranche) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('tranche', data, {});
        let tranches: TrancheList = getState().tranches;
        const index = tranches.rows.findIndex(p => p.id === data.id);
        if (index >= 0) {
            tranches.rows[index].trancheNumber = data.trancheNumber;
            tranches.rows[index].deadlineDate = data.deadlineDate;
        }
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Tranche Updated Successfully.", statusCode: 200}
        });
        dispatch({type: ActionType.ADD_TRANCHE, payload: {}});
        return dispatch({type: ActionType.LIST_TRANCHES, payload: Object.assign({}, tranches)});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

export const editTranche = (data: Tranche) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.ADD_TRANCHE, payload: data});
};

export const deleteTranche = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.delete(`tranche/${id}`, {}, {});
        let tranches: TrancheList = getState().tranches;
        const index = tranches.rows.findIndex(p => p.id === id);
        if (index >= 0) {
            tranches.rows.splice(index, 1);
        }
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Tranche Deleted Successfully.", statusCode: 200}
        });
        return dispatch({type: ActionType.LIST_TRANCHES, payload: Object.assign({}, tranches)});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};


export const listTranches = (query: PagingParams) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche', {}, query);
        return dispatch({type: ActionType.LIST_TRANCHES, payload: res});
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const emptyTrancheForm = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.ADD_TRANCHE, payload: {}});
};

export const saveTrancheNumberForClient = (data: { trancheNumber: string | number, clientId: number }) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        await api.put('account/client/update-tranche-number', data, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {message: "Tranche Number Saved Successfully.", statusCode: 200}
        });
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const getTrancheListForClient = (query: PagingParams) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/client', {}, query);
        return res;
    } catch (err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getAllTranches = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/all', {}, {});
        return res;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const trancheCurrent = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/current', {}, {});
        return res;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
};

export const listUpcomingTranche = (count: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/important', {}, {rowsPerPage: count});
        return res;
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}

export const getUpcomingCurrentYearTranche = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const res = await api.get('tranche/get-upcoming-current-year-tranche');
        return dispatch({type: ActionType.LIST_TRANCHES, payload: res});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: err});
    }
}