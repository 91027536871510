import * as React from "react";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ListIcon from '@material-ui/icons/List';
import { IconButton, TableBody, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { AccountStatusArr } from "../../model";
import { AccountSortList, AccountOrder, AccountHeadRow } from "../../model/account";
import EnhancedTableHead from "./EnhancedTableHead";
import { dateUtils, tableSort } from "../../utils";

const headRowsClientManagement: AccountHeadRow[] = [
	{ id: 'sNo', label: 'S.No' },
	{ id: 'firstName', label: 'First Name' },
	{ id: 'lastName', label: 'Last Name' },
	{ id: '', label: 'Invitation Type' },
	{ id: 'createdAt', label: 'Date Created' },
	{ id: 'inviteStatus', label: 'Status'}
];

type Props = {
    accountList: {
        rows: any
    },
    handleClickOpen: (i: string) => void,
    goToClientList: (val: string) => void
}
function AssociationTable(props: Props) {
	const { accountList, handleClickOpen, goToClientList } = props;
	const [order, setOrder] = React.useState<AccountOrder>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof AccountSortList>('createdAt');

	function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof AccountSortList) {
		const isDesc = orderBy === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderBy(property);
	}

	return (
		<React.Fragment>
			<EnhancedTableHead
				order={order}
				orderBy={orderBy}
				onRequestSort={handleRequestSort}
				headRows={headRowsClientManagement}
			/>
			<TableBody>
				{
					(!accountList.rows || accountList.rows.length === 0)
					&& <TableRow>
						<TableCell align="center" colSpan={9}>
							No Association Accounts
						</TableCell>
					</TableRow>
				}
				{accountList.rows && tableSort.stableSort(accountList.rows, tableSort.getSorting(order, orderBy))
					.map((account: any, index:number) =>  {
						return (
							<TableRow hover key={account.id}>
								<TableCell>{++index}</TableCell>
								<TableCell padding="default">{account.firstName}</TableCell>
								<TableCell padding="default">{account.lastName}</TableCell>
								<TableCell padding="default">Association</TableCell>
								<TableCell padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
								<TableCell padding="default">{AccountStatusArr[account.inviteStatus || 0]}</TableCell>
								<TableCell padding="default" align="center">
									<Tooltip title="List">
										<IconButton
											color="default"
											onClick={ () => goToClientList(account['company.id'])}
										>
											<ListIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title="Delete">
										<IconButton
											color="default"
											onClick={() => handleClickOpen(account.id)}
										>
											<DeleteOutlineIcon className="colorRed" />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						)
					})
				}
			</TableBody>

		</React.Fragment>
	)
}

export default AssociationTable;
