import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { FormInputProps } from "../../model";

const textField = ({
					   input,
					   meta: { touched, invalid, error },
					   emailError,
                        variant="outlined",
					   ...custom
				   }: FormInputProps) => (
	<TextField
		error={emailError ? emailError : (touched && invalid)}
		helperText={touched && error}
        variant={variant}
		{...input}
		{...custom}
	/>
);

export default textField;