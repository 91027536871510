import React from 'react';
import {medicalQuestions} from "./component/MedicalPageQuestions";
import RenderMedicalQuestions from "./component/RenderMedicalQuestions";

type PhysicalAndMedicalQuestionsNewProps = {
    clientSigned: boolean;
    offerType: string;
    sendSaveRequest?: Function;
}

function PhysicalAndMedicalQuestionsNew(props: PhysicalAndMedicalQuestionsNewProps) {
    const { clientSigned, offerType, sendSaveRequest } = props;
    return (
            <React.Fragment>
                {
                        medicalQuestions && medicalQuestions.length > 0 && medicalQuestions.map((question: any, index: number) => {
                            return (
                                    <React.Fragment key={index}>
                                        {
                                                question && question.showUpperHrLine &&
                                                <span className="hLine" />
                                        }
                                        <RenderMedicalQuestions
                                                question={question}
                                                index={question && question.key}
                                                clientSigned={clientSigned}
                                                offerType={offerType}
                                                sendSaveRequest={sendSaveRequest}
                                        />
                                        {
                                                question && question.showBottomHrLine &&
                                                <span className="hLine" />
                                        }
                                    </React.Fragment>
                            );
                        })
                }
            </React.Fragment>
    )
}
export default React.memo(PhysicalAndMedicalQuestionsNew);