import {
    Grid,
    Typography,
} from "@material-ui/core";
import * as React from "react";
import { TextField, Label } from "./formInputs";
import { Field } from "redux-form";
import PrimaryContactDetail from "./PrimaryContactDetail";
import { email, required } from "../utils/validation";

type Props = {dispatch: Function, page: unknown, emailExist?: boolean,
    setEmailExist?: Function,
    setEmailRef?: Function }

export default function AddBank(props: Props) {
    const {emailExist, setEmailExist, setEmailRef} = props;
    const fieldObj = [{
        label: "Bank Name",
        name: "bank.bankName",
        component: TextField,
        placeholder: "Enter Bank Name",
        validate: [required]
    }, {
        label: 'Bank Email',
        name: "bank.email",
        component: TextField,
        placeholder: "email@domain.com (FOR DOCS TO BE SEND TO)",
        validate: [email, required]
    }];
    return (
        <React.Fragment>
            <Grid item xs={12} sm={12} className="pb0">
                <Typography variant="subtitle1" component="strong" className="floatLeft mb10 colorBlue">
                    Bank Details
				</Typography>
            </Grid>
            {
                fieldObj.map((row, index) => {
                    return <Grid item xs={12} sm={6} md={6} className="mb10" key={index}>
                        <Field
                            fullWidth
                            label={<Label label={row.label} required={true} />}
                            name={row.name}
                            placeholder={row.placeholder}
                            component={row.component}
                            validate={row.validate}
                        />
                    </Grid>
                })
            }
            <Grid item xs={12} sm={12} className="pb0 ">
                <Typography variant="subtitle1" component="strong" className="floatLeft mb10 colorBlue">
                    Bank Primary Contact
				</Typography>
            </Grid>
            <PrimaryContactDetail
                    setEmailRef={setEmailRef}
                    emailExist={emailExist}
                    setEmailExist={setEmailExist}
                    disabledEmail={false}
            />
        </React.Fragment>
    );
}
