import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {ErrorCommon, NumberTextFieldWithCb, TextFieldCommon} from "../../../../../../components/formikFormInputs";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {useFormikContext} from "formik";
import {Label} from "../../../../../../components/formInputs";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    formControl: {
        margin: 0,
    }}
))

let scp:any = {
    label: <Label label={"Surrender Charge Percentage"}/>,
    placeHolder: "Surrender Charge Percentage",
    component: NumberTextFieldWithCb,
    variant: "outlined",
    allowMax100: true,
};


const yesNoArr = [
    {
        label: "Yes",
        value: "Yes",
    }, {
        label: "No",
        value: "No",
    },
];

const typeAnsArr = [
    {
        label: "Life",
        value: "Life",
    }, {
        label: "Annuity",
        value: "Annuity",
    },
];

const statusAnsArr = [
    {
        label: "In Force",
        value: "In Force",
    }, {
        label: "Applied For",
        value: "Applied For",
    },
];

const radioChoice = [
    {
        label: "Type",
        choice: typeAnsArr,
        fixName: "type",
    },
    {
        label: "Will This be Replaced?",
        choice: yesNoArr,
        fixName: "willThisBeReplaced",
        showDetailsBox: true,
        placeholder: "Surrender Charge Percentage",
        helperText: "If charged on the annuity or life insurance product",
    },
    {
        label: "Status",
        choice: statusAnsArr,
        fixName: "status",
    }
];

const GetPolicyTaken = (props:any) => {
    const { inforceIndex, clientSigned, enableSaveButton } = props;

    const { values, handleChange, errors }:any = useFormikContext();

    const radioChoice = {
        label: "Will both policies be taken?",
        choice: yesNoArr,
        fixName: "willBothPoliciesBeTaken",
    };

    return (
            <>
            <FormControl
                    component="fieldset"
                    disabled={clientSigned ? true : false}
            >
                <Typography component="strong" variant="body1">
                    Will both policies be taken?
                </Typography>
                <RadioGroup
                        aria-label="quiz"
                        id={`inForcePolicies[${inforceIndex}].${radioChoice.fixName}`}
                        name={`inForcePolicies[${inforceIndex}].${radioChoice.fixName}`}
                        value={values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex]['willBothPoliciesBeTaken'] ? values.inForcePolicies[inforceIndex]['willBothPoliciesBeTaken'] : ""}
                        onChange={(e) => {
                            handleChange(e);
                            enableSaveButton();
                        }}
                >
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                className="RadioFontSize mb0 mr0"
                                value={`Yes`}
                                control={<Radio color={"primary"} className="padding0 mb0"/>}
                                label={<Typography variant="body2" className="mb0">Yes</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                className="RadioFontSize mb0 mr0"
                                value={`No`}
                                control={<Radio color={"primary"} className="padding0 mb0"/>}
                                label={<Typography variant="body2" className="mb0">No</Typography>}
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
            </FormControl>
                {
                    errors && errors.inForcePolicies && errors.inForcePolicies[inforceIndex] && errors.inForcePolicies[inforceIndex].willBothPoliciesBeTaken && (
                            <span className={"colorRed w100 fSize10 floatLeft pr0 pl0"}>Required Field</span>
                    )
                }
            </>)
};

const ExistingPolicy = (props: any) => {
    const classes = useStyles();

    const { inforceIndex, clientSigned, enableSaveButton } = props;

    const { values, handleChange, errors, touched }:any = useFormikContext();
    let numberOfInForcePolicies:any = [];
    if(values && values.hasOwnProperty('inForcePolicies') && values.inForcePolicies.length > 0){
        numberOfInForcePolicies = values.inForcePolicies;
    }
    return (
            <Grid container spacing={1} className="mt30">
                {
                    radioChoice.map((item, index) => {
                        let valueAtIndex:any = values.inForcePolicies[inforceIndex];
                        scp = {
                            ...scp,
                            disabled: !!clientSigned,
                            value: (values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex]['surrenderChargePercentage']) || null
                        }
                        return (
                        <Grid item xs={12} md={6} xl={4} key={index}>
                            <FormControl
                                component="fieldset"
                                disabled={clientSigned ? true : false}
                                className={classes.formControl}
                            >
                                <Typography component="strong" variant="body1">
                                    <Label label={`${item.label}`} required={true}/>
                                </Typography>
                                <RadioGroup
                                    aria-label="quiz"
                                    id={`inForcePolicies[${inforceIndex}].${item.fixName}`}
                                    name={`inForcePolicies[${inforceIndex}].${item.fixName}`}
                                    value={values.inForcePolicies.length > 0 && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex][item.fixName] !== null ? values.inForcePolicies[inforceIndex][item.fixName] : null}
                                    onChange={(e) => {
                                        console.log("values", values)
                                        handleChange(e);
                                        enableSaveButton();
                                    }}
                                >
                                    <Grid container>
                                        {
                                            item.choice.map((element, index) => {
                                                return (
                                                    <Grid item xs={12} md={6}>
                                                        <FormControlLabel
                                                            className="RadioFontSize mb0 mr0"
                                                            value={`${element.value}`}
                                                            control={<Radio color={"primary"} className="padding0 mb0"/>}
                                                            label={<Typography variant="body2" className="mb0">{element.label}</Typography>}
                                                        />
                                                    </Grid>
                                                );
                                            })
                                        }
                                    </Grid>
                                </RadioGroup>
                                {
                                    (values.inForcePolicies && values.inForcePolicies[inforceIndex] && values.inForcePolicies[inforceIndex].willThisBeReplaced === "Yes" && item.showDetailsBox === true) &&
                                    <>
                                        <TextFieldCommon
                                            {...scp}
                                            values={values}
                                            id={`inForcePolicies[${inforceIndex}].surrenderChargePercentage`}
                                            name={`inForcePolicies[${inforceIndex}].surrenderChargePercentage`}
                                            onChange={handleChange}
                                        />
                                        {errors && errors.inForcePolicies && errors.inForcePolicies[inforceIndex] && errors.inForcePolicies[inforceIndex].surrenderChargePercentage && (
                                                <span className={"colorRed w100 fSize10 floatLeft pr0 pl0"}>Required Field</span>
                                        )}
                                    </>
                                }
                            </FormControl>
                            {
                                errors.inForcePolicies && errors.inForcePolicies[inforceIndex] && errors.inForcePolicies[inforceIndex][item.fixName] && (
                                    <ErrorCommon
                                        isMultiLevel={true}
                                        errors={errors.inForcePolicies[inforceIndex][item.fixName]}
                                        name={`inForcePolicies[${inforceIndex}].${item.fixName}`}
                                    />
                                )
                            }
                        </Grid>
                        );
                    })
                }
                {(numberOfInForcePolicies && numberOfInForcePolicies[inforceIndex] && numberOfInForcePolicies[inforceIndex].hasOwnProperty("status") && (!!(numberOfInForcePolicies[inforceIndex]['status'])) && (numberOfInForcePolicies[inforceIndex]['status'] === "Applied For")) &&
                        <GetPolicyTaken inforceIndex={inforceIndex} clientSigned={clientSigned}
                                        enableSaveButton={enableSaveButton}/>
                }
            </Grid>
    )
}
export default ExistingPolicy;