import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Label } from "../../../../../../../components/formInputs";
import { DatePicker, ErrorCommon, TextField, TextFieldCommon } from "../../../../../../../components/formikFormInputs";
import * as Yup from "yup";
import { financialQuestions } from "./FinancialQuestions";
import { EnrollmentFinancialAgreementProps } from "../../../../../../../model/enrollment";
import moment from "moment";
import { useActions } from "../../../../../../../actions";
import * as AccountActions from "../../../../../../../actions/account";
import FinancialAgreementForm from "./FinancialAgreementForm";
import { convertToRadioValue } from "../../Utilities";
import { arrayContainUndefined } from "../../../../../../../utils/commonUtils";
import { REQUIRED_FIELD } from "../../../../../../../constants";
import * as Actions from "../../../../../../../actions/account";

type Props = {
	user: any,
	isFromClientDetail: boolean,
	setFinancialAgreementRef: Function,
	setFinancialAndLiabilitiesArr: Function,
	financialAndLiabilitiesArr: any,
	financialAgreementFormRef: any,
	setSaveFinancialAgreementForm: Function
	formType: string
	setFormType: Function
	autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void;
    setDisableSubmit: Function
}

const isCheckBoxDescriptionRequired = (arrayOption: any) => {
	let checkBoxHavingDescReq: any[] = [];
	arrayOption && arrayOption.length > 0 && arrayOption.map((v) => {
		if (v.showDetailsBox) {
			checkBoxHavingDescReq.push(v.value);
		}
	});
	return checkBoxHavingDescReq;
};

const FinancialAgreement = (props: Props) => {
	const {
		autoSaveTrigger,
		user,
		isFromClientDetail,
		setFinancialAgreementRef,
		setFinancialAndLiabilitiesArr,
		financialAndLiabilitiesArr,
		financialAgreementFormRef,
		setSaveFinancialAgreementForm,
		formType,
		setFormType,
        setDisableSubmit
	} = props;
	const { updateClientFields, getAllianzDetails } = useActions(AccountActions);
    const accountActions = useActions(Actions);
	const [errorInForm, setErrorInForm] = useState(false);
	const [allianzId, setAllianzId] = React.useState(0);
    const [minDateForDischarge, setMinDateForDischarge] = React.useState(moment(user && user.client && user.client.bankruptcyDate).format('YYYY-MM-DD'));
	const questionList: any = financialQuestions();
	let clientDetail = (user && user.client && user.client) || undefined;
	let enrollmentDetails = (user && user.enrollment) || undefined;
	let answers: any = enrollmentDetails.answers ? JSON.parse(enrollmentDetails.answers) : {};
	let keysValidation = {
		"acceptFinancialCondition": Yup.string().required(REQUIRED_FIELD).nullable(),
	};

	const clientSigned = user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId) ? 1 : 0;
	const financialAgreementRef = React.useRef<any>();

	let initialValues = {
		acceptFinancialCondition: clientDetail.acceptFinancialCondition ? "Yes" : clientDetail.acceptFinancialCondition === 0 ? "No" : null,
		changesInIncome: convertToRadioValue(clientDetail.changesInIncome),
		pendingLawsuits: convertToRadioValue(clientDetail.pendingLawsuits),
		pendingLawsuitsDesc: clientDetail.pendingLawsuitsDesc && clientDetail.pendingLawsuitsDesc.trim() || "",
		changesInIncomeDesc: clientDetail.changesInIncomeDesc && clientDetail.changesInIncomeDesc.trim() || "",
		declareBankruptcy: convertToRadioValue(clientDetail.declareBankruptcy),
		financialAgreementDate: clientDetail.financialAgreementDate ? moment(clientDetail.financialAgreementDate).format("MM-DD-YYYY") : moment().format("MM-DD-YYYY"),
		bankruptcyDate: clientDetail.bankruptcyDate ? moment(clientDetail.bankruptcyDate).format("MM-DD-YYYY") : moment().format("MM-DD-YYYY"),
		bankruptcyDischargeDate: clientDetail.bankruptcyDischargeDate ? moment(clientDetail.bankruptcyDischargeDate).format("MM-DD-YYYY") : moment().format("MM-DD-YYYY"),
		typeOfBankruptcy: clientDetail.typeOfBankruptcy || "",
		bankruptcyDetails: clientDetail.bankruptcyDetails || "",
		currentBankruptcyStatus: clientDetail.currentBankruptcyStatus || "",
		FinancialQuestionsXC_2: answers["FinancialQuestionsXC_2"] || [],
		FinancialQuestionsXTnewoh: answers["FinancialQuestionsXTnewoh"] || "",
	};

	questionList.forEach((field: any) => {
		if (field && field.name === "changesInIncome") {
            keysValidation[field && field.name] = Yup.string().required(REQUIRED_FIELD).nullable();
			const resArray: any = isCheckBoxDescriptionRequired(field && field.additionalFieldOptions);
			keysValidation["FinancialQuestionsXC_2"] = Yup.array().test("FinancialQuestionsXC_2", REQUIRED_FIELD, function(va) {
				let checkedArray: any = (va || []).map((v) => v.value);
                const checkedOptionWithRequiredDesc = checkedArray.filter((element)=> resArray.includes(element) )
				const hasAllElems = checkedArray.every(elem => resArray.includes(elem));
				if (this.parent["changesInIncome"] === "No") {
					return true;
				}
				if (checkedArray.length > 0) {
					if (hasAllElems) {
						const selectedDesc: any[] = this.parent["FinancialQuestionsXC_2"].map((p) => p.desc);
						if (arrayContainUndefined(selectedDesc)) {
							return false;
						} else {
							return true;
						}
					}else if(checkedOptionWithRequiredDesc.length > 0){
                        const hasAnyUndefined = this.parent["FinancialQuestionsXC_2"].findIndex(ele=>{
                            return (checkedOptionWithRequiredDesc.includes(ele.value) && !ele.desc)
                        })
                        return hasAnyUndefined < 0;

                    } else {
						return true;
					}
				} else {
					return false;
				}
			});
			keysValidation["changesInIncomeDesc"] = Yup.string().ensure().trim().when("changesInIncome", {
				is: "Yes",
				then: Yup.string().required(REQUIRED_FIELD),
			});
			keysValidation["FinancialQuestionsXTnewoh"] = Yup.string().ensure().trim().when("changesInIncome", {
				is: "Yes",
				then: Yup.string().required(REQUIRED_FIELD),
			});
        } else if (field && field.name === "declareBankruptcy") {
            keysValidation[field && field.name] = Yup.string().required(REQUIRED_FIELD).nullable();
		} else if (field && field.name === "pendingLawsuits") {
            keysValidation[field && field.name] = Yup.string().required(REQUIRED_FIELD).nullable();
			keysValidation[field && field.additionalFieldName] = Yup.string().ensure().trim().when(field.name, {
				is: "Yes",
				then: Yup.string().required(REQUIRED_FIELD),
			});
		} else if (field && field.name === "acceptFinancialCondition") {
			keysValidation[field && field.name] = Yup.string().required(REQUIRED_FIELD).nullable();
		} else if (field && field.additionalFieldName2 === "bankruptcyDetails") {
			const MAX_WORD_LIMIT = 250;
			keysValidation[field && field.additionalFieldName2] =
				Yup.string().ensure().when(field.name, {
					is: "Yes",
					then: Yup.string()
						.required(REQUIRED_FIELD)
						.test(
							field.additionalFieldName2,
							"Exceeded maximum word limit",
							value => value ? value.length <= MAX_WORD_LIMIT : false,
						),
				});
		}

	});

	useEffect(() => {
		if (financialAgreementRef.current) {
			setFinancialAgreementRef(financialAgreementRef);
		}
	}, [financialAgreementRef.current]);

	React.useEffect(() => {
		const getAllianzCarrierDetails = async () => {
			const allianzData = await getAllianzDetails();
			setAllianzId(allianzData.allianzLife.id);
		};
		getAllianzCarrierDetails();
	}, []);

	const fieldObj1 = [{
		name: "financialAgreementDate",
		isRequired: !isFromClientDetail,
		placeholder: "Enter Date",
		id: "financialAgreementDate",
		variant: "outlined",
		label: <Label label={"Enter Date"} required={true} />,
		component: DatePicker,
		className: "fullWidthcustomFields",
		validate: (value: any) => {
			if (isFromClientDetail) {
				return "";
			}
			let error: string = "";
			if (!value) {
				return `Required`;
			}
			return error;
		},
		cb: () => {
			enableSaveButton();
		},
		disabled: !!clientSigned,
	}];
	const fieldObj4 = [{
		name: "bankruptcyDate",
		label: <Label label={"Date Filed"} required={!isFromClientDetail} />,
		isRequired: !isFromClientDetail,
		placeholder: "Date Field",
		id: "dateField",
		variant: "outlined",
		component: DatePicker,
        disableFuture: true,
		className: "fullWidthcustomFields",
		validate: (value: any) => {
			if (isFromClientDetail) {
				return "";
			}
			let error: string = "";
			if (!value) {
				return `Required`;
			}
			return error;
		},
		disabled: !!clientSigned,
        cb: async (val: any) => {
            setMinDateForDischarge(val);
            let payload: any = {};
            payload["bankruptcyDate"] = val;
            await accountActions.updateUserBasicDetails(payload, (user.client && user.client.userId) || 0, "");
        }
	}];
	const fieldObj5 = [{
		name: "bankruptcyDischargeDate",
		label: <Label label={"Date of Discharge"} required={!isFromClientDetail} />,
		isRequired: !isFromClientDetail,
		placeholder: "Date of Discharge",
		id: "dateOfDischarge",
        disableFuture: true,
		variant: "outlined",
		component: DatePicker,
		className: "fullWidthcustomFields",
		validate: (value: any) => {
			if (isFromClientDetail) {
				return "";
			}
			let error: string = "";
			if (!value) {
				return `Required`;
			}
			return error;
		},
        errCb: (err: any) => {
            if (!!err) {
                setDisableSubmit(true);
            } else {
                setDisableSubmit(false);
            }
        },
		disabled: !!clientSigned || !minDateForDischarge,
        minDate: minDateForDischarge
	}];
	const fieldObj2: any = [
		{
			id: "typeOfBankruptcy",
			name: "typeOfBankruptcy",
			helperFieldText: "",
			isRadio: true,
			disabled: !!clientSigned,
			isRequired: !isFromClientDetail,
			options: [{
				label: "Chapter 7 (debt forgiveness)",
				value: "Chapter 7 (debt forgiveness)",
			},
				{
					label: "Chapter 13 (debt reorganization)",
					value: "Chapter 13 (debt reorganization)",
				}],
			validate: (value: any) => {
				if (isFromClientDetail) {
					return "";
				}
				let error: string = "";
				if (!value) {
					return `Required`;
				}
				return error;
			},
		},
	];

    const fieldObj3: any = [
        {
            id: "currentBankruptcyStatus",
            name: "currentBankruptcyStatus",
            helperFieldText: "",
            isRadio: true,
            disabled: !!clientSigned,
            isRequired: !isFromClientDetail,
            options: [
                {
                    label: "Open",
                    value: "Open",
                },
                {
                    label: "Closed",
                    value: "Closed",
                }
            ],
            validate: (value: any) => {
                if (isFromClientDetail) {
                    return "";
                }
                let error: string = "";
                if (!value) {
                    return `Required`;
                }
                return error;
            }
        },
    ];

	const enableSaveButton = () => {
		let stickyFooter: any = document.getElementById("stickyFooter");
		if (stickyFooter) {
			stickyFooter.style.display = "block";
		}
	};
	const disableSaveButton = () => {
		let stickyFooter: any = document.getElementById("stickyFooter");
		if (stickyFooter) {
			stickyFooter.style.display = "none";
		}
	};

	const validationSchema = Yup.object().shape(keysValidation);
	const textFieldCommon = {
		helperFieldText: "",
		label: <Label label={"Please provide details"}
					  required={!isFromClientDetail} />,
		placeholder: "Please provide details",
		isRequired: !isFromClientDetail,
		disabled: !!clientSigned,
		className: "mt10",
		filedType: "string",
		component: TextField,
		variant: "outlined",
		multiline: true,
	};
	const radioComp = (question, fieldLabel, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, fieldObj) => {
		return (
			<>
				{
					question && values[question.name] === "Yes" &&
					<Grid item xs={12} md={12}>
						{fieldObj.map((field, index) => {
							return (
								<>
									<Typography variant="body1" component="strong"
												className="floatLeft w100 mt30 mb0">
										<Label className="floatLeft w100 mt0 mb0" label={fieldLabel || ""}
											   required={!isFromClientDetail} />
									</Typography>
									<RadioGroup className="checkGroup mt10 checkGroupEnrollment mb0"
												name={field.name}
												onChange={(e) => {
													setFieldValue(`${field.name}`, e.target.value);
                                                    setMinDateForDischarge(values["bankruptcyDate"]);
													enableSaveButton();
												}}
												value={values[field.name] ? values[field.name] : field.options[0].value}>
										{field.options.map((option: any) => (
											<FormControlLabel
												value={option.value}
												disabled={field.disabled || false}
												control={<Radio
													color="primary" />}
												label={option.label} />
										))}
									</RadioGroup>
									{errors[field.name] && (
										<ErrorCommon errors={errors}
													 name={field.name}
													 touched={touched} />
									)}
								</>
							);
						})
						}
					</Grid>
				}
			</>
		);
	};
	const showComponent = (question: EnrollmentFinancialAgreementProps, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned) => {
		switch (question.additionalFieldType) {
			case "radio":
				return radioComp(question, question.additionalFieldLabel, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, fieldObj2);
				break;
			default:
				return (<>
					{
						question && values[question.name] === "Yes" &&
						<Grid item xs={12}>
							<div className="mt30">
								<TextFieldCommon
									{...textFieldCommon}
									id={`${question.additionalFieldName}`}
									name={`${question.additionalFieldName}`}
									values={values}
									onChange={(e) => {
										handleChange(e);
										enableSaveButton();
									}}
									onBlur={handleBlur}
								/>
								<ErrorCommon errors={errors} name={`${question.additionalFieldName}`}
											 touched={errors} />
							</div>
						</Grid>
					}
				</>);
		}
	};
	const showComponent1 = (question: EnrollmentFinancialAgreementProps, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned) => {
		switch (question.additionalField1Type) {
			case "radio":
				return radioComp(question, question.additionalField1Label, values, touched, errors, setFieldTouched, setFieldValue, handleChange, handleBlur, clientSigned, fieldObj3);
				break;
			default:
				return (<></>);
		}
	};
	const showComponent2 = (question: EnrollmentFinancialAgreementProps, errors, handleBlur, handleChange, values) => {
		switch (question.additionalFieldType2) {
			case "textfield":
				return (
					question && values[question.name] === "Yes" &&
					<Grid item xs={12}>
						<div className="mt30">
							<TextFieldCommon
								{...textFieldCommon}
								id={`${question.additionalFieldName2}`}
								name={`${question.additionalFieldName2}`}
								label={`${question.additionalFieldLabel2}`}
								placeholder={`${question.additionalFieldLabel2}`}
								values={values}
								onChange={(e) => {
									handleChange(e);
									enableSaveButton();
								}}
								onBlur={handleBlur}
							/>
							<ErrorCommon errors={errors} name={`${question.additionalFieldName2}`}
										 touched={errors} />
						</div>
					</Grid>
				);
			default:
				return (<></>);
		}
	};
	const getComp = (field, values, errors, touched) => {
		return (
			<Grid item xs={12}>
				<div className={`${field.name === "financialAgreementDate" ? "" : "mt30"}`}>
					<TextFieldCommon
						{...field}
						values={values}
						onChange={(e) => {
							enableSaveButton();
						}}
					/>
					<ErrorCommon errors={errors} name={field.name}
								 touched={touched} />
				</div>
			</Grid>

		);
	};
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<FinancialAgreementForm
					autoSaveTrigger={autoSaveTrigger}
					initialValState={initialValues}
					validationSchema={validationSchema}
					updateClientFields={updateClientFields}
					disableSaveButton={disableSaveButton}
					user={user}
					isFromClientDetail={isFromClientDetail}
					errorInForm={errorInForm}
					setErrorInForm={setErrorInForm}
					questionList={questionList}
					fieldObj1={fieldObj1}
					enableSaveButton={enableSaveButton}
					showComponent={showComponent}
					clientSigned={clientSigned}
					fieldObj4={fieldObj4}
					getComp={getComp}
					showComponent1={showComponent1}
					showComponent2={showComponent2}
					fieldObj5={fieldObj5}
					financialAgreementRef={financialAgreementRef}
					clientDetail={clientDetail} allianzId={allianzId}
					setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
					financialAndLiabilitiesArr={financialAndLiabilitiesArr}
					financialAgreementFormRef={financialAgreementFormRef}
					setSaveFinancialAgreementForm={setSaveFinancialAgreementForm}
					formType={formType}
					setFormType={setFormType}
				/>
			</Grid>
		</Grid>
	);
};

export default FinancialAgreement;