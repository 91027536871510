import React from "react";


const NewlineToBreak = ({text}) => {
    const lines = text.split('\\n');
    return (
            <>
                {
                    lines.map((line, index) => (
                            <div key={index}>{line}</div>
                    ))
                }
            </>
    );
};

export default NewlineToBreak;
