import React from "react";
import { Grid } from "@material-ui/core";
import { ErrorCommon, TextFieldCommon } from "../../../../../../components/formikFormInputs";
import { useFormikContext } from "formik";
import { Label } from "../../../../../../components/formInputs";

const BirthDate = (props: any) => {
	const {
		fieldObj4,
		setAnticipatedAge,
		clientSigned,
	}: any = props;

	const {
		values,
		errors,
		touched, setFieldValue,
	}: any = useFormikContext();

	const disableProperty: any = {
		disabled: clientSigned,
	};
	return (
		<React.Fragment>
			{fieldObj4.map((field, index) => {
				return (
					<Grid item xs={12} md={6} xl={4}
						  key={index} className="mb15">
						<TextFieldCommon
							{...field}
                            label={<Label
                                    label={`Birth Date (must match drivers license)`}
                                    required={true} />}
							values={values}
							cb={(date: string) => {
								if (typeof setAnticipatedAge === "function") {
									setAnticipatedAge(date, setFieldValue, values);
								}
							}}
							{...disableProperty}
						/>
						<ErrorCommon errors={errors} name={field.name}
									 touched={touched} />
					</Grid>
				);
			})
			}
			<input name="age" type="hidden" />
		</React.Fragment>
	);
};

export default BirthDate;