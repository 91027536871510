import React from "react";
import {
	IconButton,
	TableCell,
	TableRow,
	Tooltip
} from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from "@material-ui/icons/Create";
import { dateUtils } from "../../utils";
import { AccountTypeByFilter, PermissionTypes } from "../../constants";
import GetInviteStatus from "../GetInviteStatus";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {maskPhoneNumber} from "../../utils/commonUtils";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import {useActions} from "../../actions";
import * as AccountActions from "../../actions/account";
import { can } from "../navigation/AdminNavigation";

function CarrierTableRow(props: any) {
	const { account, handleClickOpen, handleRefresh } = props;
	const user = useSelector((state: RootState) => state.auth.user);
    const [inviteTokenId, setInviteTokenId] = React.useState<string>('');
    const accountActions = useActions(AccountActions);

	let { index } = props;
	const refreshTable = () =>{
		handleRefresh();
	}

    function valHandler(accountId : string){
        setInviteTokenId(accountId);
        accountActions.copyInvitationLink("Invite Link Has Been Copied to Your Clipboard")
    }

    return(
        <TableRow hover key={account.id}>
            <TableCell>{index}</TableCell>
            <TableCell padding="default">{account["carrier.carrierName"]}</TableCell>
            <TableCell padding="default">{maskPhoneNumber(account["carrier.carrierPhone"])}</TableCell>
            <TableCell padding="default">{account.firstName}</TableCell>
            <TableCell padding="default">{account.lastName}</TableCell>
            <TableCell padding="default">{account.email}</TableCell>
            <TableCell padding="default">{'--'}</TableCell>
            <GetInviteStatus
                    role={user.role}
                    account={account}
                    refreshTable={()=>refreshTable()}
            />
            <TableCell padding="default">{dateUtils.asDbDateFormat(account.createdAt)}</TableCell>
            <TableCell padding="default">
              <Tooltip title="Edit">
                <IconButton
                    color="default"
                    onClick={ () => props.history.push('/accounts/'+ AccountTypeByFilter[account.role] +'/edit/' + account.id)}
                >
                  <CreateIcon/>
                </IconButton>
              </Tooltip>
                {
                        account.inviteLink &&
                        <Tooltip placement="left-start" title={ (account.id === inviteTokenId)
                                ?  <React.Fragment>Invite Link Has Been <br/>Copied to Your Clipboard</React.Fragment>
                                : "Copy Invite Link"}>
                            <CopyToClipboard text={account.inviteLink}
                                             onCopy={()=>valHandler(account.id)}>
                                <IconButton color="default">
                                    <LinkIcon />
                                </IconButton>
                            </CopyToClipboard>
                        </Tooltip>
                }
              {
                user && (user.role === 1 || can(user, PermissionTypes.DELETE_USER)) &&
                <Tooltip title="Delete">
                  <IconButton
                    color="default"
                    onClick={() => handleClickOpen(account.id)}
                  >
                    <DeleteOutlineIcon className="colorRed" />
                  </IconButton>
                </Tooltip>
              }
            </TableCell>
          </TableRow>
    )
}
export default CarrierTableRow;