import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";
import licensePassport from "../../images/svg/license-passport-upload.svg";
import utilityBill from "../../images/svg/utility-bill.svg";
import taxDocuments from "../../images/svg/tax-documents.svg";
import visaGreencard from "../../images/svg/visa-greencard.svg";
import InfoIcon from "@material-ui/icons/Info";
import { EnrollmentFileUploader as FileUploader, Label } from "../../../../../components/formInputs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import {
    ATLANTIC_HEALTH,
    ClientDocumentType,
    DocNameAndType,
    identityDocument, INCORRECT_FORMAT, REQUIRED_FIELD,
    residencyDocumentsList,
} from "../../../../../constants";
import ViewAndDelete from "./ViewAndDelete";
import * as Aws from "../../../../../actions/aws";
import moment from "moment";
import OtherDocument from "../component/OtherDocument";
import { checkIsDocUploaded } from "../../../../../utils/commonUtils";
import SelectDocTypeField from "./SelectDocTypeField";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FocusError from "../../../../../components/FocusError";
import PersonalDetailFieldObj from "./PersonalDetailFieldObj";
import CommonFieldFunction from "./CommonFieldFunction";

const UtilityBill = (props: {
    isDocumentAddress: boolean | undefined,
    isFromClientDetail: boolean | undefined,
    utilityBillPrevPhoto: string,
    setOnProgress: Function,
    directory: string,
    fileType: string,
    updateDocument: any,
    setUtilityBillPrevPhoto: Function,
    onProgress: boolean,
    clientSigned: boolean,
    userId: string,
    setIdentityFrontPrevPhoto: Function,
    setResidenceBackPrevPhoto: Function,
    setTaxDocumentYearOne: Function,
    setIdentityBackPrevPhoto: Function,
    setTaxDocumentYearTwo: Function,
    setResidenceFrontPrevPhoto: Function,
    utilityBillDocSubType: string,
    setUtilityBillDocSubType: Function,
    client: any,
    docusignStatus: number
}) => {
    const {
        isDocumentAddress,
        isFromClientDetail,
        utilityBillPrevPhoto,
        setOnProgress,
        onProgress,
        directory,
        fileType,
        updateDocument,
        setUtilityBillPrevPhoto,
        clientSigned,
        userId,
        setIdentityFrontPrevPhoto,
        setResidenceBackPrevPhoto,
        setTaxDocumentYearOne,
        setTaxDocumentYearTwo,
        setIdentityBackPrevPhoto,
        setResidenceFrontPrevPhoto,
        setUtilityBillDocSubType, utilityBillDocSubType, client, docusignStatus,
    } = props;
    const utilityDocDropDown = ["Water Bill", "Gas Bill", "Electric Bill", "Bank Statement", "Notarized Bill"];
    if (typeof isDocumentAddress !== "undefined" && isDocumentAddress && isFromClientDetail) {
        return null;
    }
    return <Grid item xs={12}>
        <div className="DocumentsWrap mt40">
            {!isFromClientDetail &&
                    <div>
                        <img src={utilityBill} alt="#" className="floatLeft" />
                    </div>
            }
            <div className="content">
                <Typography variant="subtitle1" component="strong">
                    <Label label={"Recent Utility Bill"}
                           required={true} /> { /* TODO: NIWC-2801: the "Recent Utility Bill" are marked as Required by requesting QA Team.  */}
                </Typography>
                <Typography>{client.identityType === "passport" ? "(Using a passport as ID requires a utility bill for address verification.)"
                        : !client.isDocumentAddress ? "(Your drivers license address does not match your home address please upload a recent utility to verify your address.)" : ""
                }</Typography>
                <Typography variant="body1">A water, electric or gas bill or a bank statement (less than 60 days old)
                    with the insured name and address (for address verification). If we need anything additional, we
                    will contact you.
                </Typography>
                <SelectDocTypeField label={"Select Doc Type for Address Verification"}
                                    handleChange={async (e) => {
                                        if (e && e.target && e.target.value) {
                                            setUtilityBillDocSubType(e.target.value);
                                        }
                                        await updateDocument("utilityBillPhoto", "", ClientDocumentType.UTILITY_BILL, "", e.target.value, true); // update docSubType
                                    }} items={utilityDocDropDown} value={utilityBillDocSubType} name="utilityBill"
                                    clientSigned={clientSigned} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={utilityBillPrevPhoto ? 3 : 6}
                          lg={utilityBillPrevPhoto ? 3 : 6} className="textLeft">
                        {!utilityBillPrevPhoto && utilityBillDocSubType &&
                                <div className="fileUpload mt15">
                                    <FileUploader
                                            setOnProgress={setOnProgress}
                                            onProgress={onProgress}
                                            exactFileName={"Utility Bill"}
                                            dirName={directory}
                                            dirType={"documents"}
                                            fileType={fileType}
                                            content={`Upload ${utilityBillDocSubType}`}
                                            width={15000}
                                            height={15000}
                                            onFinish={async (publicUrl: string, name: string) => {
                                                await updateDocument("utilityBillPhoto", name, ClientDocumentType.UTILITY_BILL, "", utilityBillDocSubType);
                                                setUtilityBillPrevPhoto(name);
                                                setOnProgress(false);
                                            }}
                                    />
                                </div>
                        }
                        {
                            (utilityBillPrevPhoto
                                    &&
                                    <ViewAndDelete
                                            identityPhoto={utilityBillPrevPhoto}
                                            clientSigned={clientSigned}
                                            title="Identity License"
                                            action={
                                                {
                                                    key: "utilityBillPhoto",
                                                    type: ClientDocumentType.UTILITY_BILL,
                                                    side: "",
                                                }
                                            }
                                            userId={userId}
                                            docusignStatus={docusignStatus}
                                            directory={directory}
                                            setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                            setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                            setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                            setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                            setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                            setTaxDocumentYearOne={setTaxDocumentYearOne}
                                            setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                    />
                            )
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    </Grid>;
};

const UploadDocumentsForm = (props: {
    setNextStep: Function,
    setPrevStep: Function,
    isFromClientDetail?: boolean,
    clientDetail?: any,
    isFromEnrollmentHome?: boolean
}) => {
    const accountActions = useActions(Actions);
    const awsActions = useActions(Aws);
    let userData = useSelector((state: RootState) => state.profile);
    let user = props.clientDetail || userData;
    const { client, role } = user;
    const { setNextStep, isFromClientDetail, isFromEnrollmentHome } = props;
    let clientSigned: boolean = !!(isFromClientDetail && user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId));
    let userId: string = client && client.userId || "";
    let directory: string = user && user.enrollment && user.enrollment.id;
    const [identityType, setIdentityType] = React.useState(client && client.identityType || "");
    const docusignStatus = user && user.enrollment && user.enrollment.docusignStatus;
    const isDocumentAddress = client && client.isDocumentAddress;
    const [identityFrontPrevPhoto, setIdentityFrontPrevPhoto] = React.useState("");
    const [identityBackPrevPhoto, setIdentityBackPrevPhoto] = React.useState("");

    const [utilityBillPrevPhoto, setUtilityBillPrevPhoto] = React.useState("");
    const [residenceFrontPrevPhoto, setResidenceFrontPrevPhoto] = React.useState("");

    const [residencyDocuments, setResidencyDocuments] = React.useState(client && client.residencyDocument || "greenCard");
    const [residenceBackPrevPhoto, setResidenceBackPrevPhoto] = React.useState("");
    const [otherDocPrevPhoto, setOtherDocPrevPhoto] = React.useState("");
    const [otherDocName, setOtherDocName] = React.useState("");

    const [taxDocumentYearOne, setTaxDocumentYearOne] = React.useState("");
    const [taxDocumentYearTwo, setTaxDocumentYearTwo] = React.useState("");

    const [showTaxDocuments, setShowTaxDocuments] = React.useState(true);

    const [taxDocDropDown, setTaxDocDropDown] = React.useState<any>([]);
    const [taxDocSubType, setTaxDocSubType] = React.useState<string>("");
    const [utilityBillDocSubType, setUtilityBillDocSubType] = React.useState<string>("");

    const [onProgress, setOnProgress] = React.useState(false);

    const personalDetailForm: any = React.useRef<any>(null);
    let passportExpiryDate = client && client.passportExpiryDate;
    let passportExpiryYear = 0;
    let passportExpiryMonth = 0;
    if (passportExpiryDate) {
        let passportExpiryDateArr = passportExpiryDate.split("-");
        passportExpiryYear = Number(passportExpiryDateArr[0]);
        passportExpiryMonth = Number(passportExpiryDateArr[1]);
    }
    const initVal = {
        passportBirthCountry: "",
        passportNumber: client.passportNumber,
        passportExpiryMonth: 0,
        passportExpiryYear: 0,
    };
    const [initialValues, setInitialValues] = React.useState<any>(initVal);
    const conditionalRequired = !(role === 1 || role === 2 || role === 4);
    const [passportBirthCountry, setPassportBirthCountry] = React.useState<string>(client.passportBirthCountry);
    const [proofExpired, setProofExpired] = React.useState(false);
    const fieldObj5 = PersonalDetailFieldObj.fieldObj5(conditionalRequired, setPassportBirthCountry, passportBirthCountry, clientSigned);
    const fieldObj13 = PersonalDetailFieldObj.fieldObj13(conditionalRequired, setProofExpired);
    const [labelsActive, setLabelsActive] = React.useState<any[]>([]);
    const [passportNumber, setPassportNumber] = React.useState<string>(client.passportNumber);
    const [passportExpMonth, setPassportExpMonth] = React.useState(passportExpiryMonth);
    const [passportExpYear, setPassportExpYear] = React.useState(passportExpiryYear);
    const [passportExpDate, setPassportExpDate] = React.useState(passportExpiryDate);

    const checkValidation = () => {
        if ((identityType === "passport") && (!passportBirthCountry || proofExpired || !passportNumber)) {
            return true;
        }
        return (!checkIsDocUploaded(client) ? true : (identityType === "" || null) ? true : (identityType === "passport" && !utilityBillPrevPhoto) ? true : (isDocumentAddress === 0 && !utilityBillPrevPhoto));
    };
    useEffect(() => {
        if (!isFromClientDetail) {
            accountActions.getProfileDetails();
        }
    }, []);

    const handleDocument = async (document: any) => {
        if (document && document.id > 0) {
            let documentType = document.documentType;
            let path = document.path;
            let backPath = document.backPath;
            let documentName = document.name;
            if (documentType === ClientDocumentType.IDENTITY_DOCUMENT) {
                if (path) {
                    setIdentityFrontPrevPhoto(path);
                }
                if (backPath) {
                    setIdentityBackPrevPhoto(backPath);
                }
            } else if (documentType === ClientDocumentType.UTILITY_BILL) {
                setUtilityBillPrevPhoto(path);
            } else if (documentType === ClientDocumentType.RESIDENCE_DOCUMENT) {
                if (path) {
                    setResidenceFrontPrevPhoto(path);
                }
                if (backPath) {
                    setResidenceBackPrevPhoto(backPath);
                }
            } else if (documentType === ClientDocumentType.MORE_DOCUMENTS && documentName == "Other") {
                setOtherDocPrevPhoto(path);
                setOtherDocName(document.otherDocName);
            }
        }
    };

    useEffect(() => {
        if (client && client.clientDocuments) {
            for (let key in client.clientDocuments) {
                handleDocument(client.clientDocuments[key]);
                if (client.clientDocuments[key].documentType === ClientDocumentType.TAX_DOCUMENT) {
                    if (client.clientDocuments[key].name === "TAX DOCUMENT YEAR 1") {
                        setTaxDocumentYearOne(client.clientDocuments[key].path);
                    }
                    if (client.clientDocuments[key].name === "TAX DOCUMENT YEAR 2") {
                        setTaxDocumentYearTwo(client.clientDocuments[key].path);
                    }
                    if (!taxDocSubType) {
                        setTaxDocSubType(client.clientDocuments[key].documentSubType ? client.clientDocuments[key].documentSubType : "Tax Returns");
                    }
                }
                if ((client.clientDocuments[key].documentType === ClientDocumentType.UTILITY_BILL) && !utilityBillDocSubType) {
                    setUtilityBillDocSubType(client.clientDocuments[key].documentSubType ? client.clientDocuments[key].documentSubType : "Electric Bill");
                }
            }
        }
        if (client && client.company &&
                client.company.groupType && client.company.groupType === "Corporation") {
            setShowTaxDocuments(false);
        }

        //Special Case Atlantic Health Condition hide tax documents
        if(ATLANTIC_HEALTH.includes(client && client.company && client.company.companyLinkUrl)){
            setShowTaxDocuments(false);
        }
        const arr = Object.keys(DocNameAndType);
        const taxDocArr = arr.filter((item: string, _) => {
            return DocNameAndType[`${item}`].docType === "TAX_DOCUMENT";
        });
        setTaxDocDropDown(taxDocArr);
    }, [client]);

    const updateDocument = async (key: string, value: string | string[], field: string, documentSubType: string = "", docSubName: string = "", updateOnlySubType: boolean = false) => {
        let payload: {
            formType?: string,
            identityFrontPhoto?: string,
            identityBackPhoto?: string,
            residenceBackPhoto?: string,
            residenceFrontPhoto?: string,
            utilityBillPhoto?: string,
            taxDocumentYear1?: string,
            taxDocumentYear2?: string
        } = {
            formType: "documents",
            identityFrontPhoto: identityFrontPrevPhoto,
            identityBackPhoto: identityBackPrevPhoto,
            residenceBackPhoto: residenceBackPrevPhoto,
            residenceFrontPhoto: residenceFrontPrevPhoto,
            utilityBillPhoto: utilityBillPrevPhoto,
            taxDocumentYear1: taxDocumentYearOne,
            taxDocumentYear2: taxDocumentYearTwo,
        };
        payload[key] = value;
        payload["documentType"] = field;
        payload["documentSubType"] = documentSubType;
        payload["documentKey"] = key;
        payload["docSubName"] = docSubName;
        payload["operation"] = payload[payload["documentKey"]] ? "Uploaded" : "Deleted";
        payload["updateOnlySubType"] = updateOnlySubType; // update subtype only

        if (userId && directory) {
            try {
                await accountActions.updateUserBasicDetails(payload, userId);
                accountActions.getProfileDetails();
            } catch (err) {
                console.log("err", err);
                return false;
            }
        }
    };

    const updateUserData = async (value: string | string[], key: string) => {
        const payload = {};
        payload[key] = value;
        payload["usingSpouseIncome"] = client && client.usingSpouseIncome;
        payload["passportBirthCountry"] = "";
        payload["passportNumber"] = "";
        payload["passportExpiryMonth"] = 0;
        payload["passportExpiryYear"] = 0;
        if(identityType === "passport"){
            payload["passportBirthCountry"] = passportBirthCountry;
            payload["passportNumber"] = passportNumber;
            payload["passportExpiryMonth"] = passportExpMonth;
            payload["passportExpiryYear"] = passportExpYear;
        }
        if (userId) {
            try {
                await accountActions.updateUserBasicDetails(payload, userId, "");
                await accountActions.getProfileDetails();
            } catch (err) {
                console.log("err", err);
                return false;
            }
        }
    };

    const fileType: string = "image/jpg, image/jpeg, image/png, application/pdf";

    React.useEffect(() => {
        let formVal: any = {};

        if (client) {
            formVal = {
                passportBirthCountry: {
                    key: client.passportBirthCountry,
                    name: client.passportBirthCountry,
                    title: client.passportBirthCountry,
                },
                passportNumber: client.passportNumber,
                passportExpiryYear: passportExpiryYear,
                passportExpiryMonth: passportExpiryMonth,
            };
        }

        if (client) {
            setInitialValues(formVal);
        }

        if (passportExpDate) {
            const expireDate = moment(new Date(passportExpDate));
            const currentDate = moment(new Date());
            const daysLeft = expireDate.diff(currentDate, "days");
            if (daysLeft <= 0) {
                setProofExpired(true);
            }
        }

    }, [client]);

    React.useEffect(() => {
        const updatePassportData = async () => {
            await updateUserData(passportBirthCountry, "passportBirthCountry");
        };
        updatePassportData();
    }, [passportBirthCountry]);

    React.useEffect(() => {
        const updatePassportData = async () => {
            await updateUserData(passportNumber, "passportNumber");
        };
        updatePassportData();
    }, [passportNumber]);

    React.useEffect(() => {
        const updatePassportData = async () => {
            await updateUserData("", "");
        };
        updatePassportData();
    }, [passportExpMonth, passportExpYear, identityType]);

    function PersonalDetailValidate(validationObj, keysValidation) {
        validationObj.forEach((field: any) => {
            if (field.name && (field.name === "passportBirthCountry")) {
                keysValidation[field.name] = Yup.mixed().nullable().required(REQUIRED_FIELD);
            } else if (field.name && (field.name === "passportNumber")) {
                keysValidation[field.name] = Yup.string().trim().matches(/^[a-zA-Z0-9.-]*$/, INCORRECT_FORMAT).required(REQUIRED_FIELD).nullable();
            } else if (field.name && (field.name === "passportExpiryMonth" || field.name === "passportExpiryYear")) {
                if (field.name === "passportExpiryMonth") {
                    keysValidation[field.name] = Yup.number().min(1, "Please select expiry month").required(REQUIRED_FIELD);
                } else if (field.name === "passportExpiryYear") {
                    keysValidation[field.name] = Yup.number().min(1, "Please select expiry year").required(REQUIRED_FIELD);
                }
            } else {
                keysValidation[field.name] = Yup.string().trim().required(REQUIRED_FIELD)
            }
        });
        return keysValidation;
    }

    let keysValidation = {};
    let validationObj = [
        {
            name: "passportBirthCountry",
            isRequired: conditionalRequired,
            type: "string",
        },
        {
            name: "passportNumber",
            isRequired: conditionalRequired,
            type: "string",
        },
        {
            name: "passportExpiryMonth",
            isRequired: conditionalRequired,
            type: "string",
        },
        {
            name: "passportExpiryYear",
            isRequired: conditionalRequired,
            type: "string",
        },
    ];
    keysValidation = PersonalDetailValidate(validationObj, keysValidation);
    const personalDetailSchema = Yup.object().shape(keysValidation);

    const getFieldFocusValue = (name: string) => {
        let findResult: any = labelsActive.find((label: any) => {
            return label.name === name;
        });
        if (!findResult) {
            return false;
        }
        return findResult.value;
    };

    const CommonField = (
            filedProps,
            action: {
                handleChange?: Function,
                handleBlur: Function,
                values?: any,
                errors?: any,
                touched?: any,
                setFieldValue: any
            },
    ) => {
        return (
                <CommonFieldFunction filedProps={filedProps}
                                     action={action}
                                     conditionalRequired={conditionalRequired}
                                     role={role}
                                     setActionItem={() => {
                                     }}
                                     client={client}
                                     labelsActive={labelsActive}
                                     setLabelsActive={setLabelsActive}
                                     clientSigned={clientSigned}
                                     getFieldFocusValue={getFieldFocusValue}
                />);
    };

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {!isFromClientDetail &&
                                <div className="greenBox">
                                    <Typography variant="body2" className="mb15"><InfoIcon
                                            className="floatLeft mr10 textHighLight" />
                                        <strong className="floatLeft">Did You
                                            Know…</strong></Typography>
                                    <Typography variant="caption" className="mb10 floatLeft">You can search for
                                        “free scanner apps” on your phone or tablet app store and download a
                                        free app from which you can take photos of your documents and create a
                                        PDF to upload or email to yourself for uploading. <strong><i>Adobe Scan is
                                            a good,
                                            free choice.</i></strong></Typography>
                                </div>
                        }

                        <Typography variant="subtitle1" component="strong">
                            <Label label={"Identity Documents"} required={true} /></Typography>
                        <Typography variant="body1">How would you like to verify your identity?</Typography>

                        <SelectDocTypeField label={"Verification Document Type"}
                                            handleChange={async (e) => {
                                                if (e && e.target && e.target.value) {
                                                    setIdentityType(e.target.value);
                                                }
                                                await updateUserData(e.target.value, "identityType");
                                            }}
                                            items={identityDocument}
                                            value={identityType}
                                            name="identityType"
                                            clientSigned={clientSigned} />

                        {
                                identityType === "passport" &&
                                <Formik
                                        innerRef={personalDetailForm}
                                        initialValues={initialValues}
                                        enableReinitialize={true}
                                        validationSchema={personalDetailSchema}
                                        validateOnChange={true}
                                        onSubmit={async (values: any, { setSubmitting, setValues }) => {
                                        }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          setFieldValue,
                                          setFieldError,
                                          handleBlur,
                                          handleSubmit,
                                      }) => {
                                        setPassportExpMonth(values["passportExpiryMonth"]);
                                        setPassportExpYear(values["passportExpiryYear"]);

                                        if (values["passportExpiryYear"] && values["passportExpiryMonth"]) {
                                            setPassportExpDate(values["passportExpiryYear"] + "-" + values["passportExpiryMonth"]);
                                        }
                                        const passportNumber = values["passportNumber"] && values["passportNumber"].trim();
                                        if (!passportBirthCountry && !errors["passportBirthCountry"]) {
                                            setFieldError("passportBirthCountry", REQUIRED_FIELD);
                                        }
                                        return (<>
                                                    <form className="mt20 floatLeft w100" onSubmit={handleSubmit}
                                                          onBlur={(e: any) => {
                                                              setPassportNumber(passportNumber);
                                                              handleBlur(e);
                                                          }}
                                                          onPointerOut={(e: any) => {
                                                              setPassportNumber(passportNumber);
                                                              handleBlur(e);
                                                          }}
                                                          autoComplete="off">
                                                        <FocusError offset={true} />
                                                        <Grid container spacing={2}>
                                                            {
                                                                fieldObj5.map((field, index) => {
                                                                    return (
                                                                            <React.Fragment key={index}>
                                                                                <Grid item xs={12}
                                                                                      md={6}
                                                                                      xl={6}
                                                                                      key={index + field["name"]}>
                                                                                    <>
                                                                                        {
                                                                                            CommonField(
                                                                                                    field,
                                                                                                    {
                                                                                                        values,
                                                                                                        errors,
                                                                                                        touched,
                                                                                                        handleChange,
                                                                                                        handleBlur,
                                                                                                        setFieldValue,
                                                                                                    },
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                    );
                                                                })
                                                            }
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body1"
                                                                            className="floatLeft w100 mb0">Expiry Date
                                                                    (Must match passport)</Typography>
                                                            </Grid>
                                                            {
                                                                fieldObj13.map((field, index) => {
                                                                    return (
                                                                            <React.Fragment key={index}>
                                                                                <Grid item xs={12}
                                                                                      md={6}
                                                                                      xl={6}
                                                                                      key={index + field["name"]}>
                                                                                    <>
                                                                                        {
                                                                                            CommonField(
                                                                                                    field,
                                                                                                    {
                                                                                                        values,
                                                                                                        errors,
                                                                                                        touched,
                                                                                                        handleChange,
                                                                                                        handleBlur,
                                                                                                        setFieldValue,
                                                                                                    },
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                    <>{field.name === "passportExpiryYear" && proofExpired ?
                                                                                            <span className="f12 colorRed w100">Passport has been expired.</span> : ""}</>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                    );
                                                                })
                                                            }
                                                        </Grid>
                                                        <span className="hLine mt30" />
                                                    </form>
                                                </>
                                        );
                                    }}
                                </Formik>
                        }

                        {identityType &&
                                <div className="DocumentsWrap">
                                    {!isFromClientDetail &&
                                            <div>
                                                <img src={licensePassport} alt="#" className="floatLeft" />
                                            </div>
                                    }
                                    <div className="content">
                                        <Typography variant="subtitle1" component="strong">
                                            {identityType === "drivingLicence" ? <Label label={"Drivers License"}
                                                                                        required={checkValidation()} /> :
                                                    <Label label={"Passport"} required={checkValidation()} />}
                                        </Typography>
                                        <Typography variant="body1">
                                            {identityType === "drivingLicence" ?
                                                    <span>
                                                    Front and back of a current, {identityFrontPrevPhoto && identityBackPrevPhoto ?
                                                            <strong>non-expired</strong> : "non-expired"} drivers license <br />
                                                We can not accept a digital ID card. Only upload a scanned copy of your driver's license.
                                                </span>
                                                    :
                                                    <span>
                                                     Photo and signature page of a current {identityFrontPrevPhoto && identityBackPrevPhoto ?
                                                            <strong>non-expired</strong> : "non-expired"} passport to verify your identity
                                                </span>
                                            }
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={identityFrontPrevPhoto ? 3 : 6}
                                                  lg={identityFrontPrevPhoto ? 3 : 6}
                                                  className="textLeft">
                                                {!identityFrontPrevPhoto ?
                                                        <div className="fileUpload">
                                                            <FileUploader
                                                                    setOnProgress={setOnProgress}
                                                                    onProgress={onProgress}
                                                                    exactFileName={"Driver License Front"}
                                                                    dirName={directory}
                                                                    dirType={"documents"}
                                                                    fileType={fileType}
                                                                    width={15000}
                                                                    height={15000}
                                                                    content={identityType === "drivingLicence" ? "Upload drivers license front" : "Upload passport photo page"}
                                                                    onFinish={async (publicUrl: string, name: string) => {
                                                                        console.log(publicUrl, "***DOCUMENT***", name);
                                                                        await updateDocument("identityFrontPhoto", name, ClientDocumentType.IDENTITY_DOCUMENT, "front");
                                                                        setIdentityFrontPrevPhoto(name);
                                                                        setOnProgress(false);
                                                                    }}

                                                            />
                                                        </div>
                                                        :
                                                        <ViewAndDelete
                                                                identityPhoto={identityFrontPrevPhoto}
                                                                clientSigned={clientSigned}
                                                                title="Identity License"
                                                                action={
                                                                    {
                                                                        key: "identityFrontPhoto",
                                                                        type: ClientDocumentType.IDENTITY_DOCUMENT,
                                                                        side: "front",
                                                                    }
                                                                }
                                                                userId={userId}
                                                                docusignStatus={docusignStatus}
                                                                directory={directory}
                                                                setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                                setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                                setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                                setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                                setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                                setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                                setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                        />
                                                }
                                            </Grid>
                                            <Grid item xs={12} md={identityBackPrevPhoto ? 3 : 6}
                                                  lg={identityBackPrevPhoto ? 3 : 6}
                                                  className="textLeft">
                                                {!identityBackPrevPhoto ? <div className="fileUpload">
                                                            <FileUploader
                                                                    setOnProgress={setOnProgress}
                                                                    onProgress={onProgress}
                                                                    exactFileName={"Driver License Back"}
                                                                    dirName={directory}
                                                                    dirType={"documents"}
                                                                    fileType={fileType}
                                                                    content={identityType === "drivingLicence" ? "Upload drivers license back" : "Upload passport signature page"}
                                                                    width={15000}
                                                                    height={15000}
                                                                    onFinish={async (publicUrl: string, name: string) => {
                                                                        await updateDocument("identityBackPhoto", name, ClientDocumentType.IDENTITY_DOCUMENT, "back");
                                                                        setIdentityBackPrevPhoto(name);
                                                                        setOnProgress(false);
                                                                    }}
                                                            />
                                                        </div>
                                                        :
                                                        <ViewAndDelete
                                                                identityPhoto={identityBackPrevPhoto}
                                                                clientSigned={clientSigned}
                                                                title="Identity License"
                                                                action={
                                                                    {
                                                                        key: "identityBackPhoto",
                                                                        type: ClientDocumentType.IDENTITY_DOCUMENT,
                                                                        side: "back",
                                                                    }
                                                                }
                                                                userId={userId}
                                                                docusignStatus={docusignStatus}
                                                                directory={directory}
                                                                setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                                setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                                setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                                setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                                setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                                setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                                setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                        />
                                                }
                                            </Grid>

                                        </Grid>
                                    </div>
                                </div>
                        }
                    </Grid>
                    {showTaxDocuments && <Grid item xs={12}>
                        <div className="DocumentsWrap mt40">
                            {!isFromClientDetail &&
                                    <div>
                                        <img src={taxDocuments} alt="#" className="floatLeft" />
                                    </div>
                            }
                            <div className="content">
                                <Typography variant="subtitle1" component="strong"><Label label={"Tax Documents"}
                                                                                          required={checkValidation()} /></Typography>
                                <Typography variant="body1">Which Tax Documents would you like to verify income
                                    with?</Typography>
                                <SelectDocTypeField label={"Verification Document Type"}
                                                    handleChange={async (e) => {
                                                        if (e && e.target && e.target.value) {
                                                            setTaxDocSubType(e.target.value);
                                                        }
                                                        await updateDocument("taxDocumentYear1", "", ClientDocumentType.TAX_DOCUMENT, "", e.target.value, true); // update docSubType
                                                    }} items={taxDocDropDown} value={taxDocSubType} name="taxDocument"
                                                    clientSigned={clientSigned} />
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Card className="alertMessageWrap success alert "
                                              style={{ border: "1px solid #3DDB93" }}>
                                            <CardContent className="alertMessageWrapCardContent ">
                                                <div className="imgSec floatLeft textHighLight">
                                                    <InfoOutlinedIcon />
                                                </div>
                                                <div className="textSec floatLeft">
                                                    <Typography variant="body1" className="mb20">
                                                        Only upload one form of Tax Document: W2 or Tax
                                                        Returns or 1099.
                                                        Please do not upload placeholder documents or
                                                        references to
                                                        other ilia accounts. We cannot access previously
                                                        uploaded
                                                        documents from the Carrier or other ilia
                                                        accounts.
                                                    </Typography>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} className="textLeft">
                                        <Grid container spacing={2}>

                                            {!taxDocumentYearOne && taxDocSubType ?
                                                    <Grid item xs={12} md={taxDocumentYearOne ? 3 : 6}
                                                          lg={taxDocumentYearOne ? 3 : 6} className="textLeft">
                                                        <div className="mt15 floatLeft w100">
                                                            <div className="fileUpload">
                                                                <FileUploader
                                                                        setOnProgress={setOnProgress}
                                                                        onProgress={onProgress}
                                                                        exactFileName={"Tax-Document-Year-1-" + moment().format("DD-MM-YYYY HH:mm:ss")}
                                                                        dirName={directory}
                                                                        dirType={"documents"}
                                                                        fileType={fileType}
                                                                        width={15000}
                                                                        height={15000}
                                                                        content={"Upload Year 1"}
                                                                        onFinish={async (publicUrl: string, name: string) => {
                                                                            console.log(publicUrl, "***DOCUMENT***", name);
                                                                            setTaxDocumentYearOne(name);
                                                                            await updateDocument("taxDocumentYear1", name, ClientDocumentType.TAX_DOCUMENT, "YEAR_ONE", taxDocSubType);
                                                                            setOnProgress(false);
                                                                        }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    :
                                                    taxDocSubType &&
                                                    <Grid item xs={12} md={taxDocumentYearOne ? 3 : 6}
                                                          lg={taxDocumentYearOne ? 3 : 6} className="textLeft">
                                                        <div className="mt15 floatLeft w100">
                                                            <ViewAndDelete
                                                                    identityPhoto={taxDocumentYearOne}
                                                                    clientSigned={clientSigned}
                                                                    title="Tax Document Year 1"
                                                                    action={
                                                                        {
                                                                            key: "TAX_DOCUMENT",
                                                                            type: ClientDocumentType.TAX_DOCUMENT,
                                                                            side: "YEAR_ONE",
                                                                        }
                                                                    }
                                                                    userId={userId}
                                                                    docusignStatus={docusignStatus}
                                                                    directory={directory}
                                                                    setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                                    setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                                    setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                                    setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                                    setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                                    setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                                    setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                            />
                                                        </div>
                                                    </Grid>
                                            }
                                            {!taxDocumentYearTwo && taxDocSubType ?
                                                    <Grid item xs={12} md={taxDocumentYearTwo ? 3 : 6}
                                                          lg={taxDocumentYearTwo ? 3 : 6} className="textLeft">
                                                        <div className="mt15 floatLeft w100">
                                                            <div className="fileUpload">
                                                                <FileUploader
                                                                        setOnProgress={setOnProgress}
                                                                        onProgress={onProgress}
                                                                        exactFileName={"Tax-Document-Year-2-" + moment().format("DD-MM-YYYY HH:mm:ss")}
                                                                        dirName={directory}
                                                                        dirType={"documents"}
                                                                        fileType={fileType}
                                                                        width={15000}
                                                                        height={15000}
                                                                        content={"Upload Year 2"}
                                                                        onFinish={async (publicUrl: string, name: string) => {
                                                                            console.log(publicUrl, "***DOCUMENT***", name);
                                                                            setTaxDocumentYearTwo(name);
                                                                            await updateDocument("taxDocumentYear2", name, ClientDocumentType.TAX_DOCUMENT, "YEAR_TWO", taxDocSubType);
                                                                            setOnProgress(false);
                                                                        }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    :
                                                    taxDocSubType &&
                                                    <Grid item xs={12} md={taxDocumentYearTwo ? 3 : 6}
                                                          lg={taxDocumentYearTwo ? 3 : 6} className="textLeft">
                                                        <div className="mt15 floatLeft w100">
                                                            <ViewAndDelete
                                                                    identityPhoto={taxDocumentYearTwo}
                                                                    clientSigned={clientSigned}
                                                                    title="Tax Document Year 2"
                                                                    action={
                                                                        {
                                                                            key: "TAX_DOCUMENT",
                                                                            type: ClientDocumentType.TAX_DOCUMENT,
                                                                            side: "YEAR_TWO",
                                                                        }
                                                                    }
                                                                    userId={userId}
                                                                    docusignStatus={docusignStatus}
                                                                    directory={directory}
                                                                    setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                                    setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                                    setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                                    setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                                    setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                                    setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                                    setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                            />
                                                        </div>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>}
                    {((client && !client.isDocumentAddress) || (client && client.identityType && client.identityType !== "drivingLicence")
                                    || (!isDocumentAddress && isFromClientDetail)) &&
                            <UtilityBill
                                    isDocumentAddress={isDocumentAddress}
                                    isFromClientDetail={isFromClientDetail}
                                    utilityBillPrevPhoto={utilityBillPrevPhoto}
                                    setOnProgress={setOnProgress}
                                    onProgress={onProgress}
                                    directory={directory}
                                    fileType={fileType}
                                    updateDocument={updateDocument}
                                    setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                    clientSigned={clientSigned}
                                    userId={userId}
                                    setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                    setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                    setTaxDocumentYearOne={setTaxDocumentYearOne}
                                    setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                    setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                    setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                    setUtilityBillDocSubType={setUtilityBillDocSubType}
                                    utilityBillDocSubType={utilityBillDocSubType}
                                    client={client}
                                    docusignStatus={docusignStatus}
                            />
                    }
                    {client && !client.usCitizen &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component="strong">
                                    <Label label={"Residency Documents"} required={true} /></Typography>
                                <Typography variant="body1">Which residency documents would you like to
                                    upload?</Typography>
                                <SelectDocTypeField label={"Residency Verification Document Type"}
                                                    handleChange={async (e) => {
                                                        if (e && e.target && e.target.value) {
                                                            setResidencyDocuments(e.target.value);
                                                        }
                                                        await updateUserData(e.target.value, "residencyDocument");
                                                    }}
                                                    items={residencyDocumentsList}
                                                    value={residencyDocuments}
                                                    name="residencyDocument"
                                                    clientSigned={clientSigned} />

                                <div className="DocumentsWrap mt40">
                                    {!isFromClientDetail &&
                                            <div>
                                                <img src={visaGreencard} alt="#" className="floatLeft" />
                                            </div>
                                    }
                                    <div className="content">
                                        <Typography variant="subtitle1" component="strong"><Label
                                                label={"Visa, Green Card, Notice of Action, etc."}
                                                required={checkValidation()} />
                                        </Typography>
                                        <Typography variant="body1"> Please upload front and back of a visa, green card,
                                            or other residency document like notice of action to verify legal US residency
                                            status. </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={residenceFrontPrevPhoto ? 3 : 6}
                                                  lg={residenceFrontPrevPhoto ? 3 : 6}
                                                  className="textLeft">
                                                {!residenceFrontPrevPhoto ?
                                                        <div className="fileUpload">
                                                            <FileUploader
                                                                    setOnProgress={setOnProgress}
                                                                    onProgress={onProgress}
                                                                    exactFileName={"Residency Card Front"}
                                                                    dirName={directory}
                                                                    dirType={"documents"}
                                                                    fileType={fileType}
                                                                    content={"Upload document front"}
                                                                    width={15000}
                                                                    height={15000}
                                                                    onFinish={async (publicUrl: string, name: string) => {
                                                                        await updateDocument("residenceFrontPhoto", name, ClientDocumentType.RESIDENCE_DOCUMENT, "front");
                                                                        setResidenceFrontPrevPhoto(name);
                                                                        setOnProgress(false);
                                                                    }}
                                                            />
                                                        </div>
                                                        :
                                                        <ViewAndDelete
                                                                identityPhoto={residenceFrontPrevPhoto}
                                                                clientSigned={clientSigned}
                                                                title="Identity License"
                                                                action={
                                                                    {
                                                                        key: "residenceFrontPhoto",
                                                                        type: ClientDocumentType.RESIDENCE_DOCUMENT,
                                                                        side: "front",
                                                                    }
                                                                }
                                                                userId={userId}
                                                                docusignStatus={docusignStatus}
                                                                directory={directory}
                                                                setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                                setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                                setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                                setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                                setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                                setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                                setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                        />
                                                }
                                            </Grid>
                                            <Grid item xs={12} md={residenceBackPrevPhoto ? 3 : 6}
                                                  lg={residenceBackPrevPhoto ? 3 : 6}
                                                  className="textLeft">
                                                {!residenceBackPrevPhoto ?
                                                        <div className="fileUpload">
                                                            <FileUploader
                                                                    setOnProgress={setOnProgress}
                                                                    onProgress={onProgress}
                                                                    exactFileName={"Residency Card Back"}
                                                                    dirName={directory}
                                                                    dirType={"documents"}
                                                                    fileType={fileType}
                                                                    content={"Upload document back"}
                                                                    width={15000}
                                                                    height={15000}
                                                                    onFinish={async (publicUrl: string, name: string) => {
                                                                        await updateDocument("residenceBackPhoto", name, ClientDocumentType.RESIDENCE_DOCUMENT, "back");

                                                                        let file = name.split(".");
                                                                        let fileType = file[file.length - 1].toUpperCase();
                                                                        const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
                                                                        let queryObj = {
                                                                            objectName: name,
                                                                            contentType: contentType,
                                                                        };
                                                                        await awsActions.getSecureUrl(directory, queryObj, true);
                                                                        setResidenceBackPrevPhoto(name);
                                                                        setOnProgress(false);
                                                                    }}
                                                            />
                                                        </div>
                                                        :
                                                        <ViewAndDelete
                                                                identityPhoto={residenceBackPrevPhoto}
                                                                clientSigned={clientSigned}
                                                                title="Identity License"
                                                                action={
                                                                    {
                                                                        key: "residenceBackPhoto",
                                                                        type: ClientDocumentType.RESIDENCE_DOCUMENT,
                                                                        side: "back",
                                                                    }
                                                                }
                                                                userId={userId}
                                                                docusignStatus={docusignStatus}
                                                                directory={directory}
                                                                setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                                setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                                setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                                setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                                setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                                setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                                setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                        />
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                    }
                    {
                            otherDocPrevPhoto &&
                            <OtherDocument
                                    directory={directory} fileType={fileType} updateDocument={updateDocument}
                                    clientSigned={clientSigned} userId={userId}
                                    setOtherDocPrevPhoto={setOtherDocPrevPhoto}
                                    otherDocPrevPhoto={otherDocPrevPhoto}
                                    otherDocName={otherDocName}
                                    isFromClientDetail={isFromClientDetail}
                            />
                    }
                    {(!isFromClientDetail && !isFromEnrollmentHome) &&
                            <Grid item xs={12} sm={12} md={12}>
                                <span className="hLine mt60 mb30" />
                                {
                                        checkValidation() &&
                                        <span className="colorRed">Required Fields <sup>*</sup></span>
                                }
                                <Button variant="contained" color="primary" disabled={checkValidation()}
                                        className="floatRight mt20 mb30"
                                        onClick={async () => {
                                            accountActions.updateDocCompleteStatus();
                                            setNextStep();
                                        }}
                                >
                                    Save and Continue
                                </Button>
                            </Grid>
                    }
                </Grid>
            </>
    );
};
export default UploadDocumentsForm;