import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import { NumberTextField } from "../../../../../../components/formikFormInputs";
import { showLabelTextByStatus } from "../../../../../../utils/commonUtils";

type HouseHoldIncomeProp = {
	CommonField: Function,
	handleChange: Function,
	handleBlur: Function,
	setFieldValue: Function,
	touched: any,
	errors: any,
	values: any
}

function HouseHoldIncome(props: HouseHoldIncomeProp) {
	const {
		CommonField,
		handleChange,
		handleBlur,
		setFieldValue,
		values,
		touched,
		errors,
	} = props;

	const incomeFields: any[] = [
		{
			id: "householdNetWorth",
			name: "householdNetWorth",
			label: <Label
				label={showLabelTextByStatus(values["marriageStatus"], "Married", "Household Net Worth", "Individual / Household Net Worth")}
				required={true} />,
			isRequired: true,
			component: NumberTextField,
			variant: "outlined",
			className: "TextFieldWidth dollerIcon",
			dollarSign: true
		},
		{
			id: "spouseIncome",
			name: "spouseIncome",
            label: <Label label={"Spouse's / Domestic Partner's / Civil Union Partner's Annual income"}
                          required={((values["marriageStatus"] === "Married") && (values["usingSpouseIncome"] === "0"))} />,
			isRequired: true,
			component: NumberTextField,
			variant: "outlined",
			className: "TextFieldWidth dollerIcon",
			dollarSign: true
		},
		{
			id: "householdLiquidAssets",
			name: "householdLiquidAssets",
			label: <Label
				label={showLabelTextByStatus(values["marriageStatus"], "Married", "Household Liquid Assets", "Individual / Household Assets")}
				required={true} />,
			isRequired: true,
			component: NumberTextField,
			variant: "outlined",
			className: "TextFieldWidth dollerIcon",
			dollarSign: true
		},
		{
			id: "householdAnnualExpenses",
			name: "householdAnnualExpenses",
			label: <Label
				label={showLabelTextByStatus(values["marriageStatus"], "Married", "Household Annual Expenses", "Individual / Household Expenses")}
				required={true} />,
			isRequired: true,
			component: NumberTextField,
			variant: "outlined",
			className: "TextFieldWidth dollerIcon",
			dollarSign: true
		}];
	return <>
		<Grid item xs={12}>
			<Grid container spacing={3} className="mt15">
				<Grid item xs={12}>
					<Typography variant="h6" component="strong" className="mb0 w100 floatLeft">{
						showLabelTextByStatus(values["marriageStatus"], "Married", "Household Net Worth", "Individual / Household Networth")
					}
					</Typography>
				</Grid>
				{
					incomeFields.map((field, index) => {
						return (
							 <Grid item xs={12} md={6} key={index + field["name"]}>
								<div>
									{
										CommonField(
											field,
											{
												values,
												errors,
												touched,
												handleChange,
												handleBlur,
												setFieldValue,
											},
										)
									}
								</div>
							</Grid>
						);
					})
				}
				<Grid item xs={12}>
					<div className="hLine" />
				</Grid>
			</Grid>
		</Grid>
	</>;
}

export default HouseHoldIncome;