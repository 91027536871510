import React from 'react';
import {Typography, Grid} from "@material-ui/core";
import {EnrollmentQuestionProps} from "../../../../../../model/enrollment";
import EnrollmentField from "./EnrollmentField";
import {ErrorCommon, TextField, TextFieldCommon} from "../../../../../../components/formikFormInputs";
import {Label} from "../../../../../../components/formInputs";

type PropsType = {
    values: any,
    setSaveAviationAvocationQuestions: Function,
    role: number,
    offerType: string,
    clientSigned: boolean,
    isFromClientDetail: boolean,
    errors: any, touched: any,
    handleChange: Function,
    handleBlur: Function
}
const questionList = [{
    key: "",
    name: "Insured_AvocationXR22",
    label: "Are you a member of the United States Parachute Association?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: false,
    notShowUpperHrLine:true,
}, {
    key: "",
    name: "Insured_AvocationXR23",
    label: "Do you hold a parachutist license?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: true,
    additionalFieldName: 'Insured_AvocationXT24',
    additionalFieldType: 'textfield',
    additionalFieldRequired: true,
    additionalFieldLabel: "What Class?",
    notShowUpperHrLine:true,
}]
const describeExperienceInParachuting = [{
    name: "Insured_AvocationXT25",
    title: "Total jumps to date"
}, {
    name: "Insured_AvocationXT26",
    title: "Total jumps last 12 months"
}, {
    name: "Insured_AvocationXT27",
    title: "Total anticipated next 12 months"
}]

const questionList1 = [{
    key: "",
    name: "Insured_AvocationXR28",
    label: "Do you perform sky diving or delay jumps?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: false,
}]
const questionList2 = [{
    key: "",
    name: "Insured_AvocationXR32",
    label: "Do you participate in baton passing or other stunts?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: true,
    additionalFieldName: 'Insured_AvocationXT33',
    additionalFieldType: 'textfield',
    additionalFieldRequired: true,
    additionalFieldLabel: "Provide Details",
    notShowUpperHrLine:false,
}, {
    key: "",
    name: "Insured_AvocationXR34",
    label: "Do you participate in local or national competition?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: true,
    additionalFieldName: 'Insured_AvocationXT35',
    additionalFieldType: 'textfield',
    additionalFieldRequired: true,
    additionalFieldLabel: "Provide Details",
    notShowUpperHrLine:true,
}]
const describeSkyDivingDelayJumpsArr = [{
    name: "Insured_AvocationXT29",
    title: "Provide details"
}, {
    name: "Insured_AvocationXT30",
    title: "Number of delay jumps"
}, {
    name: "Insured_AvocationXT31",
    title: "Maximum seconds delay"
}]
const locationJumpAreaArr = [{
    name: "Insured_AvocationXT36",
    title: "Location of jump areas"
}]
function getCommonProps(isFromClientDetail: boolean, clientSigned: boolean) {
    return {
        helperFieldText: "",
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt20",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }
}

function InsuredParachutingSkyDiving(props: PropsType) {
    const {
        values,
        clientSigned,
        isFromClientDetail,
        role,
        offerType,
        setSaveAviationAvocationQuestions,
        errors,
        touched,
        handleChange,
        handleBlur
    } = props;
    const commonProps = getCommonProps(isFromClientDetail, clientSigned);
    let fieldProps = { onChange: handleChange, onBlur: handleBlur };
    return <>
        {
                values['Insured_AvocationXC51'] &&
                <div className="pl16 pr16 innerContentWraptwo">
                <Grid container>
                    <Grid item xs={12}>
                        {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                                <EnrollmentField
                                        question={question}
                                        clientSigned={clientSigned}
                                        isFromClientDetail={isFromClientDetail}
                                        checkIsError={() => {
                                        }}
                                        onChangeFn={() => {
                                        }}
                                        role={role}
                                        offerType={offerType}
                                        setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="strong">Describe your experience in parachuting or sky diving</Typography>
                    </Grid>
                    {describeExperienceInParachuting.map((element, idx: number) =>
                            <>
                                <TextFieldCommon
                                        id={element.name}
                                        name={element.name}
                                        label={<Label label={`${element.title}`}/>}
                                        values={values}
                                        {...commonProps}
                                        {...fieldProps}
                                />
                                <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                            </>
                    )}
                    <Grid item xs={12}>
                        {questionList1.map((question: EnrollmentQuestionProps, idx: number) => (
                                <EnrollmentField
                                        question={question}
                                        clientSigned={clientSigned}
                                        isFromClientDetail={isFromClientDetail}
                                        checkIsError={() => {
                                        }}
                                        onChangeFn={() => {
                                        }}
                                        role={role}
                                        offerType={offerType}
                                        setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                />
                        ))}
                    </Grid>
                    {
                        values["Insured_AvocationXR28"] === "Yes" &&
                        <>
                        {
                            describeSkyDivingDelayJumpsArr.map((element, idx: number) =>
                                    <>
                                        <TextFieldCommon
                                                id={element.name}
                                                name={element.name}
                                                label={<Label label={`${element.title}`}/>}
                                                values={values}
                                                {...commonProps}
                                                {...fieldProps}
                                        />
                                        <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                                    </>
                            )}
                        </>
                    }
                    <Grid item xs={12}>
                        {questionList2.map((question: EnrollmentQuestionProps, idx: number) => (
                                <EnrollmentField
                                        question={question}
                                        clientSigned={clientSigned}
                                        isFromClientDetail={isFromClientDetail}
                                        checkIsError={() => {
                                        }}
                                        onChangeFn={() => {
                                        }}
                                        role={role}
                                        offerType={offerType}
                                        setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" component="strong" className="mt20 floatLeft w100">Location of jump areas</Typography>
                        {
                            locationJumpAreaArr.map((element, idx: number) =>
                                <>
                                    <TextFieldCommon
                                            id={element.name}
                                            name={element.name}
                                            label={<Label label={`${element.title}`}/>}
                                            values={values}
                                            {...commonProps}
                                            {...fieldProps}
                                    />
                                    <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                                </>
                        )}
                    </Grid>
                </Grid>
                </div>
        }
    </>
}

export default InsuredParachutingSkyDiving;