import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import ForgotPassword from "../components/ForgotPassword";

interface Props extends RouteComponentProps<void> {}

function ForgotPasswordPage(props: Props) {
	return (
		<ForgotPassword />
	);
}

export default ForgotPasswordPage;
