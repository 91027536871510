import React from "react";
import {
    Button,
    Grid,
    Card,
    CardContent,
    Typography, Container,
} from "@material-ui/core";
import { useActions } from "../actions";
import * as Actions from "../actions/auth";
import * as AccountActions from "../actions/account";

export default function TermofUse() {
    const authActions = useActions(Actions);
    const accountActions = useActions(AccountActions);

    function handleDecline() {
        authActions.LogOut();
    }

    function handleAccept() {
        accountActions.acceptTermofUse();
    }

    return (
            <Container maxWidth="md">
                <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={12} md={12}>
                        <Card className="muiCard mt60">
                            <CardContent>
                                <Typography variant="h4" className="f22 mb15">REMINDER AND TERMS OF USE.</Typography>
                                <Typography className="f14">
                                    This proposal is hypothetical and may not be used to project or predict insurance performance results in the future. Because these are projections, future policy performance and interest rates are not guaranteed and are subject to change by the insurer and/or lender. Underperformance could result in a lower death benefit, cash surrender value and lower annual income (results could be less favorable).
                                </Typography>
                                <Typography className="f14">
                                    These are hypothetical examples - projections are used for underwriting offers and illustrative purposes only. The estimated value is not an offer of coverage. The benefits and values shown on the estimator are not guaranteed.
                                </Typography>
                                <Typography className="f14">
                                    This presentation is not valid unless accompanied by a complete insurance company illustration. Please see the full illustration for guaranteed values and other important information. NIW, nor any of its affiliates represent the lenders or the insurance carriers. This document is not intended to give legal advice or tax advice. For tax and legal advice, contact your personal tax and legal advisors.
                                </Typography>
                                <Typography className="f14">
                                    Illustrated projected bank loan payoff and income stream from policy loans and withdrawals will vary based on actual performance.
                                </Typography>
                                <Typography className="f14">
                                    Living benefits are provided by the insurance carriers and all riders may not be available with all carriers or in all states. If you accelerate the benefit to access the living benefits, the amount accelerated is no longer available for the death benefit or for loans, and the amount of death benefit accelerated may be taxable. The loan must be paid off before you can accelerate the death benefit. Accelerated Benefit riders are typically available at no additional cost. Please contact your insurance agent for more information.
                                </Typography>

                                <Typography className="f14">
                                    The selected strategy design is dependent on the payor making their 5 annual contribution payments and the policy remaining enforce. A portion of the payor contribution to the strategy includes a trustee and administration fee of per year. The fee covers only 15 years of trust fees. The fees include payment for the initial trust setup, including the master trust, ongoing trustee administration of the insurance trust and annual loan servicing fees. Any changes to the trust outside the scope of the standard transactions are the responsibility of the settlor of the trust. Once the trust has been established the fees are non-refundable.
                                </Typography>
                                <Typography className="f14">
                                    Financing life insurance premiums has certain inherent risks including interest rate fluctuations, financial market performance, credit availability, insurance company ratings and stability which can affect the loan. Not completing the 5 contributions will result in bank loan exit and/or possible policy surrender. The loan terms are dependent on the lender and are not guaranteed for the full term of the plan.
                                </Typography>
                                <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className="ml15 flo atRight mt20 mb15"
                                        onClick={handleAccept}
                                >
                                    Accept
                                </Button>
                                <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className="floatRight mb15 mt20 bgRed"
                                        onClick={handleDecline}
                                >
                                    Decline
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
    );

}