import React from "react";
import { Button, TextArea } from "../formInputs";
import { connect } from "react-redux";
import { RootState } from "../../../src/reducers";
import { Field, reduxForm, InjectedFormProps } from "redux-form";

type Props = {
    notes: string
    sendNotes: Function,
    setNotesArea: Function
}

function AddNotes(props: InjectedFormProps<{}, Props> & Props) {
    const { handleSubmit, pristine, submitting, sendNotes, setNotesArea } = props;

    const handleSendMessage = async (data: any) => {
        sendNotes(data.notes || "");
        setNotesArea(false);
    };

    return (
            <>
                <form className="customModalContent" onSubmit={handleSubmit(handleSendMessage)}>
                    <Field
                            style={{ minHeight: "150px",padding:'15px',marginTop:'15px', }}
                            placeholder="Notes..."
                            fullWidth
                            name="notes"
                            component={TextArea}
                    />
                    <Button
                            size='small'
                            label="Save"
                            className="btnPrimary mt20 mr5 floatRight floatRightSM "
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={pristine || submitting}
                            loading={submitting}
                    />
                </form>
            </>
    );
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        initialValues: { notes: ownProps.notes || "" },
    };
}

const reduxConnect = reduxForm<{}, Props>({
    form: "AddNotes",  // a unique identifier for this form
    enableReinitialize: true,
})(AddNotes);

export default connect(mapStateToProps)(reduxConnect);