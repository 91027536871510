import React, { useEffect } from "react";

import { Grid} from "@material-ui/core";
import  {RootState} from "../../../../reducers";
import { useSelector } from "react-redux";
import  { PageTitle } from "../../../../components/formInputs";
import EstimationTabs from "./EstimationTabs";
import ResultActionAndInfoBox from "./Components/ResultActionAndInfoBox";
import EstimationResultHeader from "./Components/EstimationResultHeader";
import {getStrategyDetails} from "../../../../utils/commonFunc";
import { maskCurrency } from "../../../../utils/commonUtils";

export default function EstimationResult(props:{
    clearEstimationData:Function,
    responseData:any,
    lifeTimeDeathBenefitSeeking:boolean,
    isReportRequestCompleted:boolean,
    setIsReportRequestCompleted:Function,
    handleButtonClick:Function,
    isReportDataLoading:boolean,
    setEditLifeTimeDeathBenefitAmount:Function,
    setContributionConfirmation:Function,
    setEditCalculationInputs:Function,
    isItUnderExpansionPanel?:boolean,
    calculateAgain?:Function
    callingFrom?:string
}) {
    const { clearEstimationData, responseData, lifeTimeDeathBenefitSeeking,
        setIsReportRequestCompleted,
        isReportDataLoading,
        setEditLifeTimeDeathBenefitAmount,
        setEditCalculationInputs,
        isItUnderExpansionPanel=false,
        calculateAgain,
        callingFrom
    } = props;

    useEffect(() => {
        // scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const { user: { enrollment }, user } = useSelector((state: RootState) => state.auth);
    const clientData = useSelector((state: RootState) => state.profile);
    const isEnrolled: boolean = (clientData && clientData.enrollment && clientData.enrollment.enrollmentStatus + "." + clientData.enrollment.enrollmentSubStatus >= "0.3") ||
    (enrollment && enrollment.enrollmentStatus + "." + enrollment.enrollmentSubStatus >= "0.3") ? true : false;
    const isEnrolledCompleted: boolean = (clientData && clientData.enrollment && clientData.enrollment.enrollmentStatus + "." + clientData.enrollment.enrollmentSubStatus >= "4.1") ||
    (enrollment && enrollment.enrollmentStatus + "." + enrollment.enrollmentSubStatus >= "4.1") ? true : false;

    const {strategyName, strategySlug, registrationMark} = getStrategyDetails(user);

    let resolveFor:string|null = responseData && responseData.run && responseData.run.stresssolvefor || null;
    const paymentFrequency = responseData && responseData.common && responseData.common.paymentfrequency || "Annual";
    let contributionAmount = responseData && responseData.common && responseData.common.contributionamount;
    //If Employer Remove that from total
    if (responseData && responseData.common && responseData.common.contributionamountemployer) {
        contributionAmount = contributionAmount - responseData.common.contributionamountemployer;
    }

    return (
            <div>
                <PageTitle title="Estimate My Benefits"/>
                {!isItUnderExpansionPanel &&
                    <React.Fragment>
                        <Grid container alignContent="center"  spacing={4}>
                            <Grid item xs={12} md={12} lg={7}>
                                <EstimationResultHeader
                                    callingFrom={callingFrom}
                                    paymentFrequency={paymentFrequency}
                                    contributionAmount={contributionAmount}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={5}>
                                <ResultActionAndInfoBox
                                    isEnrolled={isEnrolled}
                                    isEnrolledCompleted={isEnrolledCompleted}
                                    setIsReportRequestCompleted={setIsReportRequestCompleted}
                                    isReportDataLoading={isReportDataLoading}
                                    callingFrom={callingFrom}
                                    resolveFor={resolveFor}
                                    setEditLifeTimeDeathBenefitAmount={setEditLifeTimeDeathBenefitAmount}
                                    setEditCalculationInputs={setEditCalculationInputs}
                                    clearEstimationData={clearEstimationData}
                                    calculateAgain={calculateAgain}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    }

                    <EstimationTabs
                            strategyName={strategyName}
                            registrationMark={registrationMark}
                            responseData={responseData}
                            lifeTimeDeathBenefitSeeking={lifeTimeDeathBenefitSeeking}
                            strategySlug={strategySlug}
                            callingFrom={callingFrom}
                    />
            </div>

    );
}