import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function IncomeAlert(props:{incomeAlertOpen: boolean}) {
    const {incomeAlertOpen} = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    React.useEffect(()=>{
        setOpen(incomeAlertOpen);
    },[incomeAlertOpen])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
            <div className={classes.root}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical : "top", horizontal: "center" }}>
                    <Alert onClose={handleClose} severity="error">
                        Annual Income minimum value is $100,000
                    </Alert>
                </Snackbar>
            </div>
    );
}