import * as React from "react";
import RectangleShape from "../../../../components/client/landingPage/v2/images/RectangleShape.svg";
import { Typography, Link, Grid, Card, CardContent } from "@material-ui/core";
import StrategyName from "../../../../components/client/StrategyName";
import { pez } from "../../../../utils/commonUtils";

const LandingSectionFour = (props: any) => {
    // const strategy: string = props.strategyData.text === "Leveraged Savings Plan" ? 'The ' + props.strategyData.text : props.strategyData.name;
    // const strategyName: string = props.strategyData.text === "Leveraged Savings Plan" ? 'The ' + props.strategyData.text : props.strategyData.name;

	const strategyName: string = pez(props.strategyData && props.strategyData.strategyName, 'Kai-Zen');
	const strategyId: string = props.strategyData && props.strategyData.id || 1;
	const strategy = (
		<StrategyName
			tooltip={true}
			includeThe={false}
			strategyName={strategyName}
			registrationMarkLandingPage={true}
			parentComponentStrategyId={Number(strategyId)}
		/>
	);

    let cards: any = [
        {
            firstText: "Did you know…",
            secondText: "Americans are some of the worsts savers in the developed world saving on average only 6.5% for retirement.*",
            thirdText: "gen.medium.com",
            bgColor: "bgGreen",
            fourthText: `offers up to 3 times more money to what you are currently saving, making it easier for you to hit your goals.`,
        },
        {
            firstText: "Did you know…",
            secondText: "If you currently earn $250,000 per year, you will need around $6,500,000 saved for retirement to maintain your current lifestyle.*",
            thirdText: "businessinsider.in",
            bgColor: "bgblue",
            fourthText: `provides you the potential to increase the distributions you take home in retirement by an additional 60-100% more than what you would have without the use of leverage.`,
        },
        {
            firstText: "Did you know…",
            secondText: "Many Americans are one medical emergency away from financial ruin. In fact 66.5% of all bankruptcies filed are due to health-care costs.*",
            thirdText: "cnbc.com",
            bgColor: "bgOrange",
            fourthText: `utilizes a policy that provides death benefit protection and living benefit riders. The riders can provide cash in the event of a qualifying chronic or terminal illness.`,
        },
    ];

    return (
            <div className="sectionShape">
                <img alt={"rectangleshape"} className="rectangleShapeBefore" src={RectangleShape}/>
                <Grid container justify="center">
                    <Grid item xs={12} md={11} lg={10} xl={9} className="textLeft pL7Rem">
                        <Grid container spacing={2}>
                            <Grid item xs={12} data-aos="fade-up"
                                  data-aos-duration="1000">
                                <span className="section-marker four"/>
                                <Typography variant="h1" align="left" className="tittle colorWhite">
                                    Still wondering if {strategy} is right for you?
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} data-aos="fade-up"
                                  data-aos-duration="1000">
                                <Typography variant="body1" align="left" className="colorWhite">Did you know, 90%
                                    of
                                    all
                                    households
                                    earning over $200,000 are chronically under saving for retirement in order to
                                    preserve
                                    their
                                    current quality of life. Source: <Link className="textWhite "  onClick={()=> window.open('https://businessinsider.in')} >Business Insider.</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" spacing={4}>
                            {cards.length > 0 &&
                            cards.map((card: any, index:number) => {
                                return (
                                        <Grid key={index} item xs={12} md={4} lg={4} style={{ display: "flex" }} data-aos="fade-up"
                                              data-aos-duration="1000">
                                            <Card className="muiCard minH430" style={{ flex: "flex" }}>
                                                <CardContent className="pd20">
                                                    <Typography variant="subtitle1" component="strong"> {card.firstText} </Typography>
                                                    <Typography variant="body1">{card.secondText}
                                                    </Typography>
                                                    <Typography variant="caption">*Source: <Link onClick={()=> window.open('https://'+ card.thirdText)} >{card.thirdText}</Link></Typography>
                                                    <div className={`msgBox ${card.bgColor}`}>
                                                        <span className="triangleUp"/>
                                                        <Typography variant="body1">{strategy} {card.fourthText}</Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                );
                            })
                            }
                        </Grid>
                    </Grid>


                </Grid>
            </div>
    );
};

export default LandingSectionFour;
