import * as React from "react";
import iliaMini from "../../../../components/client/landingPage/v2/images/ilia_small.png";
import {
    Grid,
    Link,
    Typography,
    Button,
    Container,
} from "@material-ui/core";
import LandingContactForm from "./LandingContactForm";
import {
    ATLANTIC_HEALTH, ATLANTIC_HEALTH_AGENT_NAME,
    JsEvents,
    LOGO_BASE_URL,
} from "../../../../constants";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import bannerImg from "./Images/bannerShape.png";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {useCallback} from "react";
import { checkIfRebrand, checkIfTextIsBig } from "../../../../utils/commonUtils";

const useStyles = makeStyles((theme) =>
        createStyles({
            footer: {
                background: "#01305F",
                width: "100%",
                padding: "70px 0 120px",
                [theme.breakpoints.down(767)]: {
                    padding: "40px 0 120px",
                },

            },
            title: {
                borderBottom: "1.5px solid #D6A107",
                width: "100%",
                float: "left",
                paddingBottom: 10,
            },
            socialIcons: {
                display: "flex",
                width: "auto",
                [theme.breakpoints.down(767)]: {
                    margin: "0 auto",
                    width: "200px",
                    float: "none",
                },
            },
            socialIcon: {
                width: "auto !important",
                padding: "0 !important",
                marginRight: 10,
                [theme.breakpoints.down(767)]: {
                    marginRight: 15,
                },
            },
            textPrimary: {
                color: "#01305F",
            },
            backToTop: {
                backgroundColor: "#e9eff5",
                padding: "20px 0",
                position: "relative",
                borderBottom:'1px solid #424242'
            },
            footerfixed: {
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                background: "#203053",
                zIndex: 99,
                cursor: "pointer",
                textAlign: "center",
            },
            footerBanner: {
                left: 0,
                right: 0,
                bottom: 20,
                padding: 0,
                zIndex: 99,
                position: "fixed",
                background: "#ffffff",
                width: 800,
                margin: "0 auto",
                borderRadius: 10,
                border: "3px solid #62DD97",
                cursor: "pointer",
                [theme.breakpoints.down(992)]: {
                    display: "none",
                },

            },
            estimateBtnBanner: {
                left: 0,
                right: 0,
                bottom: "-30px",
                padding: 0,
                zIndex: 99,
                position: "fixed",
                margin: "0 auto",
                textAlign: "center",
                display: "none",
                [theme.breakpoints.down(992)]: {
                    display: "block",
                },
            },
        }),
);

const LandingFooter = (props: any) => {
    const classes = useStyles();
    const [footerVisible, setFooterVisible] = React.useState(false);
    const strategy: string = props.strategyData.name;
    const [open, setOpen] = React.useState(false);
    const startegyLogo: string = props.strategyData.logo;
    let clientLogo: string = props.client && props.client.clientLogo ? LOGO_BASE_URL + props.client.clientLogo : (props.agent && props.agent.companyLogo ? LOGO_BASE_URL + props.agent.companyLogo : "");
    if (!props.isViaLead && props.groupDetail && props.groupDetail.user && props.groupDetail.user.image) {
        clientLogo = LOGO_BASE_URL + props.groupDetail.user.image;
    }
    const companyLinkUrl = props.groupDetail && props.groupDetail.companyLinkUrl || "";

    let isRebrand: boolean = false;
    let partnerName: string = "ilia";
    if (!props.isViaLead) {
        const __ret = checkIfRebrand(props);
        isRebrand = __ret.isRebrand;
        partnerName = __ret.partnerName;
    }
    let partnerLogo: string = iliaMini;
    if (!props.isViaLead && props.strategyData && props.strategyData.reBrand === 1 && props.strategyData.reBrandLogo) {
        let isExist: boolean = props.strategyData.reBrandLogo.includes("s3.amazonaws.com");
        if (isExist) {
            partnerLogo = props.strategyData.reBrandLogo;
        } else {
            partnerLogo = LOGO_BASE_URL + props.strategyData.reBrandLogo;
        }
    }
    if (!props.isViaLead && props.strategyData && props.strategyData.reBrand === 2 && props.agent && props.agent.imo && props.agent.imo.reBrand && props.strategyData.reBrandLogo) {
        let isExist: boolean = props.strategyData.reBrandLogo.includes("s3.amazonaws.com");
        if (isExist) {
            partnerLogo = props.strategyData.reBrandLogo;
        } else {
            partnerLogo = LOGO_BASE_URL + props.strategyData.reBrandLogo;
        }
    }

    let advisorName: string = !props.isViaLead && props.agent && props.agent.user ? props.agent.user.firstName + " " + props.agent.user.lastName : "Agent";
    if (ATLANTIC_HEALTH.includes(companyLinkUrl) || companyLinkUrl === "D67GF0G") {
        advisorName = ATLANTIC_HEALTH_AGENT_NAME;
    }
    const controlDirection = useCallback(() => {
        if(window.scrollY > 700) {
            if (props.bannerRef && props.bannerRef.current) {
                props.bannerRef.current.style.bottom = '150px';
            }
            setFooterVisible(true);
        } else {
            if (props.bannerRef && props.bannerRef.current) {
                props.bannerRef.current.style.bottom = '15px';
            }
            setFooterVisible(false);
        }
    },[])

    React.useEffect(() => {
        window.addEventListener(JsEvents.Scroll, controlDirection);
        return () => {
            window.removeEventListener(JsEvents.Scroll, controlDirection);
        };
    },[]);
    const scrollUp = () => {
        const elemId = 'get-started';
        const elem = window.document.getElementById(elemId);
        elem && elem.scrollIntoView({behavior: 'smooth', block: "end", inline: "nearest"});
        if (!elem) {
            window.scrollTo(0, 370);
        }
    }

    let companyName: string = props.groupDetail ? props.groupDetail.companyName : 'Group';
    if (!props.isViaLead && props.accountType === props.ALLOWED_ACCOUNT_TYPE.CLIENTLIST) {
        companyName = props.groupDetail.clientListName || '';
    }
    const {isBig, text} = checkIfTextIsBig(companyName, 15);
    return (
            <>
                <div className="clearFix"/>
                <div className={classes.backToTop}>
                    <Container maxWidth="lg" className="textCenter ">
                        <Typography
                                onClick={scrollUp}
                                variant="caption"
                                align="center"
                                className={classes.textPrimary + " " + " mb0"}
                                style={{ width: 120, margin: "0 auto", display: "block", cursor: "pointer" }}>
                            Back to Top
                            <KeyboardArrowUpIcon style={{ fontSize: 30, float: "right" }}/>
                        </Typography>
                    </Container>
                </div>
                <div className={`${classes.footerBanner} ${footerVisible ? "bannerVisible" : "bannerHideen"}`}>
                    <div onClick={scrollUp}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ width: "50%", paddingLeft: 10 }}>
                                <Typography component="strong" variant="body1" className="mb0 textHighLight floatLeft">
                                    GET STARTED
                                </Typography>
                                <Typography variant="h4" align="left" className="mb0 floatLeft w100" color="primary" title={companyName}>
                                    {!props.isViaLead ? `Access ${isBig ? text : companyName}` : 'Get Free Estimate'}
                                </Typography>
                            </span>
                            <span style={{
                                background: "#01305F",
                                display: "flex",
                                width: "50%",
                                justifyContent: "space-between",
                                padding: 30,
                                position: "relative",
                            }}>
                      <span>
                        <img style={{ position: "absolute", left: "-72px", top: "0" }} src={bannerImg} alt="banner"/>
                        <Typography component="strong" variant="subtitle1"
                                    className="textHighLight f28">60 - 100%</Typography>
                        <Typography variant="body1" className="textWhite">More for Retirement</Typography>
                      </span>
                      <ArrowForwardIcon className="textWhite" style={{ fontSize: 40, float: "right" }}/>
                    </span>
                        </div>
                    </div>
                </div>
                <div onClick={scrollUp}
                     className={`${classes.estimateBtnBanner} ${footerVisible ? "bannerVisible" : " bannerHideen"}`}>
                    <Button
                            className="mt20 customBtn glow mb20 glow"
                            variant="contained"
                            type={"submit"}
                            endIcon={<ArrowForwardIcon/>}
                    >
                        {!props.isViaLead ? `Access ${isBig ? text : companyName}` : 'Get Free Estimate'}
                    </Button>
                </div>

                {
                        !props.isViaLead &&
                        <footer className="footer">
                            <Container maxWidth="lg">
                                <Grid container justify="center">
                                    <Grid item xs={12} className="textLeft">
                                        <Grid container spacing={2} justify="space-between">
                                            <Grid item xs={12} sm={6} md={6}>
                                                <div className="footerLogos">
                                                    <Typography component="span"><img
                                                            alt={"partnerLogo"} src={partnerLogo} className="logo"
                                                    /></Typography>
                                                    {startegyLogo ?
                                                            <Typography component="span"><img
                                                                    alt={"strategylogo"}
                                                                    className={`logo`}
                                                                    src={startegyLogo}
                                                            /></Typography> :
                                                            <Typography
                                                                    color="primary"
                                                                    component="strong"
                                                                    className="f22 ml15 mt5 floatLeft mb0"
                                                            >
                                                                {props.strategyData.name}
                                                            </Typography>
                                                    }
                                                    {clientLogo &&
                                                            <div className="Vdivider">
                                                                <img
                                                                        alt={"clientLogo"}
                                                                        className={`logo ml15`}
                                                                        src={clientLogo}
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                                {!isRebrand &&
                                                        <Typography component="span" className="floatLeft w100 f12 mt20">
                                                            &copy; {(new Date().getFullYear())}. {partnerName} and {strategy} are
                                                            registered
                                                            trademarks
                                                            of NIW Companies Inc.
                                                        </Typography>
                                                }
                                                {isRebrand &&
                                                        <Typography
                                                                component="span"
                                                                variant="caption"
                                                                className="floatLeft w100 mt20 "
                                                        >
                                                            &copy; {(new Date().getFullYear())}. {strategy} is sold under license
                                                            from NIW
                                                            Companies
                                                            Inc.
                                                        </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} className="positionR">
                                                <Typography
                                                        component="strong"
                                                        color="primary"
                                                        variant="subtitle2"
                                                        className="floatRightMd w100 mb15"
                                                >RESOURCES</Typography>
                                                <Link className="floatRightMd w100" onClick={() => setOpen(true)}>
                                                    <Typography
                                                            variant="subtitle2"
                                                            color="primary"
                                                            className="mb0 LinkLine mt10"
                                                    >
                                                        Contact {advisorName}
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        {
                                                open &&
                                                <LandingContactForm
                                                        setOpen={(isOpen: boolean) => setOpen(isOpen)}
                                                        open={open} {...props} />
                                        }
                                    </Grid>
                                </Grid>
                            </Container>
                        </footer>
                }
            </>
    );
};
export default LandingFooter;
