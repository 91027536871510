import React from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import {
    CheckBoxGroup,
    ErrorCommon,
    RadioGroup as FormikRadioGroup,
    TextField,
    TextFieldCommon
} from "../../../../../../components/formikFormInputs";
import { Label } from "../../../../../../components/formInputs";
import { EnrollmentQuestionProps } from "../../../../../../model/enrollment";
import EnrollmentField from "./EnrollmentField";

type PropsType = {
    values: any,
    clientSigned: boolean,
    isFromClientDetail: boolean,
    handleChange: Function,
    handleBlur: Function,
    errors: any,
    touched: any,
    setFieldValue: Function,
    role: number,
    setSaveAviationAvocationQuestions: Function,
    offerType: string
}

function getAutomobileCheckBox(isFromClientDetail: boolean, clientSigned: boolean) {
    return [{
        id: "Insured_Automobile",
        name: "Insured_Automobile",
        helperFieldText: "",
        label: "",
        fullWidth: true,
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        ischeckbox: "true",
        component: CheckBoxGroup,
        options: [{
            name: "Insured_AvocationXC1",
            text: "Automobile",
            value: 1,
        }],
    }, {
        id: "Insured_Automobile_Organization_Name",
        name: "Insured_AvocationXT2",
        helperFieldText: "",
        label: <Label label={"Name of organization"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt20",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }];
}

function getMotorcycleCheckBox(isFromClientDetail: boolean, clientSigned: boolean) {
    return [{
        id: "Insured_Motorcycle",
        name: "Insured_Motorcycle",
        helperFieldText: "",
        label: "",
        fullWidth: true,
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        ischeckbox: "true",
        component: CheckBoxGroup,
        options: [{
            name: "Insured_AvocationXC3",
            text: "Motorcycle",
            value: 1,
        }],
    }, {
        id: "Insured_Motorcycle_Organization_Name",
        name: "Insured_AvocationXT4",
        helperFieldText: "",
        label: <Label label={"Name of organization"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt20",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }];
}

function getMotorboatCheckBox(isFromClientDetail: boolean, clientSigned: boolean) {
    return [{
        id: "Insured_Motorboat",
        name: "Insured_Motorboat",
        helperFieldText: "",
        label: "",
        fullWidth: true,
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        ischeckbox: "true",
        component: CheckBoxGroup,
        options: [{
            name: "Insured_AvocationXC5",
            text: "Motorboat",
            value: 1,
        }],
    }, {
        id: "Insured_Motorboat_Organization_Name",
        name: "Insured_AvocationXT6",
        helperFieldText: "",
        label: <Label label={"Name of organization"}/>,
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt20",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    }];
}

const describeTheDrive = [{
    name: "Insured_AvocationXT7",
    title: "Make/Type",
}, {
    name: "Insured_AvocationXT8",
    title: "Model or Class",
}, {
    name: "Insured_AvocationXT9",
    title: "Size",
}, {
    name: "Insured_AvocationXT10",
    title: "Horsepower",
}];

const describeRacing = [{
    name: "Insured_AvocationXT13",
    title: "Type of course",
}, {
    name: "Insured_AvocationXT14",
    title: "Length",
}, {
    name: "Insured_AvocationXT15",
    title: "Duration of races",
}, {
    name: "Insured_AvocationXT16",
    title: "Location",
}, {
    name: "Insured_AvocationXT17",
    title: "Maximum speed attained",
}];

function getCommonProps(isFromClientDetail: boolean, clientSigned: boolean) {
    return {
        helperFieldText: "",
        placeholder: "Please provide details to include with this application",
        isRequired: !isFromClientDetail,
        disabled: clientSigned ? true : false,
        className: "mt20",
        filedType: "string",
        component: TextField,
        variant: "outlined",
        multiline: true,
        rows: 2,
        size: {
            md: 12,
            lg: 12,
        },
    };
}

const questionList = [{
    key: "",
    name: "Insured_AvocationXR11",
    label: "Is your vehicle equipped for racing?",
    type: "radio",
    default: false,
    onYes: true,
    additionalRemarks: true,
    additionalFieldName: "Insured_AvocationXT12",
    additionalFieldType: "textfield",
    additionalFieldRequired: true,
    additionalFieldLabel: "Provide Details",
}];

function getQuestionList1(clientSigned) {
    const questionList1 = [{
        key: "Insured_AvocationXR18",
        id: "Insured_AvocationXR18",
        name: "Insured_AvocationXR18",
        label: "",
        notShowUpperHrLine: true,
        notShowHrLine: true,
        component: FormikRadioGroup,
        disabled: clientSigned ? true : false,
        variant: "outlined",
        options: [
            {
                label: "Professional",
                value: "Professional",
            }, {
                label: "Amateur",
                value: "Amateur",
            },
        ],
        sizeMd: 6,
    }]
    return questionList1;
};

const describeRacingExperience = [{
    name: "Insured_AvocationXT19",
    title: "Number of races",
}, {
    name: "Insured_AvocationXT20",
    title: "Last 12 months",
}, {
    name: "Insured_AvocationXT21",
    title: "Anticipated next 12 months",
}];

function InsuredAutomobileRacingQuestions(props: PropsType) {
    const {
        values, clientSigned, isFromClientDetail, handleChange, handleBlur, touched, errors, setFieldValue, role,
        setSaveAviationAvocationQuestions,
        offerType,
    } = props;
    const autoMobileCheckBox = getAutomobileCheckBox(isFromClientDetail, clientSigned);
    const motorcycleCheckBox = getMotorcycleCheckBox(isFromClientDetail, clientSigned);
    const motorboatCheckBox = getMotorboatCheckBox(isFromClientDetail, clientSigned);
    const commonProps = getCommonProps(isFromClientDetail, clientSigned);
    const questionList1 = getQuestionList1(clientSigned);
    let fieldProps = { onChange: handleChange, onBlur: handleBlur };

    return <>
        {
                values["Insured_AvocationXC50"] &&
                <div className="pl16 pr16 innerContentWraptwo">
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="body1" component="strong" className="mt15 floatLeft w100 mb10">Are you a member
                            of any of the following racing organizations?</Typography>
                    </Grid>
                    <div className="pl16 pr16">
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    autoMobileCheckBox.map((item, index) => {
                                                fieldProps["meta"] = { touched };
                                                fieldProps["meta"] = { errors };
                                                fieldProps["input"] = {
                                                    name: item.name,
                                                    value: values[item.name],
                                                    style: { display: "block" },
                                                    onChange: (value, name) => {
                                                        setFieldValue(name, value);
                                                    },
                                                };
                                                if (!values["Insured_AvocationXC1"] && index === 1) {
                                                    return <></>;
                                                }
                                                return <><TextFieldCommon
                                                        {...item}
                                                        values={values}
                                                        {...fieldProps}
                                                />
                                                    <ErrorCommon errors={errors} name={item.name} touched={touched}/>
                                                </>;
                                            },
                                    )}
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    motorcycleCheckBox.map((item, index) => {
                                                fieldProps["meta"] = { touched };
                                                fieldProps["meta"] = { errors };
                                                fieldProps["input"] = {
                                                    name: item.name,
                                                    value: values[item.name],
                                                    style: { display: "block" },
                                                    onChange: (value, name) => {
                                                        setFieldValue(name, value);
                                                    },
                                                };
                                                if (!values["Insured_AvocationXC3"] && index === 1) {
                                                    return <></>;
                                                }
                                                return <><TextFieldCommon
                                                        {...item}
                                                        values={values}
                                                        {...fieldProps}
                                                />
                                                    <ErrorCommon errors={errors} name={item.name} touched={touched}/>
                                                </>;
                                            },
                                    )}
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    motorboatCheckBox.map((item, index) => {
                                                fieldProps["meta"] = { touched };
                                                fieldProps["meta"] = { errors };
                                                fieldProps["input"] = {
                                                    name: item.name,
                                                    value: values[item.name],
                                                    style: { display: "block" },
                                                    onChange: (value, name) => {
                                                        setFieldValue(name, value);
                                                    },
                                                };
                                                if (!values["Insured_AvocationXC5"] && index === 1) {
                                                    return <></>;
                                                }
                                                return <><TextFieldCommon
                                                        {...item}
                                                        values={values}
                                                        {...fieldProps}
                                                />
                                                    <ErrorCommon errors={errors} name={item.name} touched={touched}/>
                                                </>;
                                            },
                                    )}
                            </Grid>
                        </Grid>
                    </div>

                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="body1" component="strong" className="floatLeft w100 mt20 mb10">Describe the car or cycle you drive or the boat you race</Typography>
                    </Grid>
                    {describeTheDrive.map((element, index) =>
                            <>
                                <TextFieldCommon
                                        id={element.name}
                                        name={element.name}
                                        label={<Label label={`${element.title}`}/>}
                                        values={values}
                                        {...commonProps}
                                        {...fieldProps}
                                />
                                <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                            </>,
                    )
                    }
                    <Grid item xs={12}>
                        {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                                <EnrollmentField
                                        question={question}
                                        clientSigned={clientSigned}
                                        isFromClientDetail={isFromClientDetail}
                                        checkIsError={() => {
                                        }}
                                        onChangeFn={() => {
                                        }}
                                        role={role}
                                        offerType={offerType}
                                        setSaveApplicationQuestions={setSaveAviationAvocationQuestions}
                                />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"
                                    component="strong">Describe racing</Typography>
                    </Grid>

                    {describeRacing.map((element, idx: number) =>
                            <>
                                <TextFieldCommon
                                        id={element.name}
                                        name={element.name}
                                        label={<Label label={`${element.title}`}/>}
                                        values={values}
                                        {...commonProps}
                                        {...fieldProps}
                                />
                                <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                            </>,
                    )}
                    <span className="hLine"/>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" component="strong" className="floatLeft w100">Describe your status and experience</Typography>
                        {questionList1.map((field, index) => {
                                    return (
                                            <div key={index} className="mb15 floatLeft w100">
                                                <TextFieldCommon
                                                        {...field}
                                                        values={values}
                                                        onChange={handleChange}
                                                />
                                                <ErrorCommon errors={errors} name={field.name}
                                                             touched={touched}/>
                                            </div>
                                    );
                                })
                        }
                    </Grid>
                    {describeRacingExperience.map((element, idx: number) =>
                            <>
                                <TextFieldCommon
                                        id={element.name}
                                        name={element.name}
                                        label={<Label label={`${element.title}`}/>}
                                        values={values}
                                        {...commonProps}
                                        {...fieldProps}
                                />
                                <ErrorCommon errors={errors} name={element.name} touched={touched}/>
                            </>,
                    )}
                </Grid>
                </div>
        }
    </>;
}

export default InsuredAutomobileRacingQuestions;