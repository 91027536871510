import React from "react";
import {
    Grid,
    Typography,
    CardContent,
    Card,
    Link,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { TextField, Button, PageTitle } from "./formInputs";
import { reduxForm, Field, reset } from "redux-form";
import { useActions } from "../actions";
import * as Actions from "../actions/auth";
import loginlogo from "../styles/images/logo-ilia.png";
import login from "../styles/images/login.png";
import { history } from "../configureStore";
import { EMAIL_VALIDATE_REGEX } from "../constants";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
        createStyles({

            logInWrap: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                verticalAlign: "middle",
                height: "100%",
                width: "100%",
                "&::after": {
                    content: "\"\"",
                    position: "absolute",
                    background: "#3971EC",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: "42%",
                    [theme.breakpoints.down("sm")]: {
                        position: "inherit",
                        background: "transparent",
                        width: "auto",
                    },
                },

            },
            textWhite: {
                color: "#FFFFFF",
            },
            textCenter: {
                textAlign: "center",
            },
            mt10: {
                marginTop: 10,
            },
            mr30: {
                marginRight: 30,
            },
            loginCard: {
                margin: "auto",
                width: 865,
                boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
                background: "transparent !important",
                left: 0,
                right: 0,
                zIndex: 999,
                border: 0,
                padding: "2%",
                position: "relative",
                [theme.breakpoints.down("sm")]: {
                    background: "#ffffff !important",
                    width: "100%",
                    margin: "0 15px",
                },
            },
            muiCardContent: {
                padding: 15,
                float: "left",
                width: "100%",
                position: "relative",
            },
            loginForm: {
                padding: "60px 15px 50px",
                float: "left",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                    padding: "30px 15px 50px",
                },
            },
            margin0: {
                margin: 0,
            },
            titleMain: {
                fontSize: 26,
                lineHeight: "34px",
                textAlign: "center",
                margin: 0,
                float: "right",
            },
            dpNone: {
                [theme.breakpoints.down(992)]: {
                    display: "none",
                },
            },
            floatRight: {
                float: "right",
            },
            imgResponsive: {
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginTop: "25%",
            },
            clickableLink: {
                display: "inline-flex",
                alignItems: "center",
                verticalAlign: "middle",
            },

            clickableLinksvg: {
                float: "left",
            },
            mr10: {
                marginRight: 10,
            },
            mt15: {
                marginTop: 15,
            },
        }),
);

interface LoginData {
    email: string,
    password: string
}

const validate = (values: any) => {
    const errors: any = {};
    const requiredFields = [
        "email",
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });
    if (
            values.email &&
            !EMAIL_VALIDATE_REGEX.test(values.email)
    ) {
        errors.email = "Invalid email address";
    }
    return errors;
};
type Props = {
    handleSubmit: Function,
    pristine: boolean,
    submitting: boolean
}

function ForgotPassword(props: Props) {
    const classes = useStyles();
    const authActions = useActions(Actions);
    const { handleSubmit, pristine, submitting } = props;
    const onForgotPassword = async (data: LoginData, dispatch: Function) => {
        const emailFound = await authActions.forgotPassword(data);
        if (emailFound) {
            dispatch(reset("forgotPasswordForm"));
        }
    };
    return (
            <div className={classes.logInWrap}>
                <PageTitle title="Login Page"/>
                <Card className={classes.loginCard}>
                    <CardContent className={classes.muiCardContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                                <img alt="loginLogo" src={loginlogo}/>

                                <form className={classes.loginForm}
                                      onSubmit={handleSubmit(onForgotPassword)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field name="email" fullWidth component={TextField}
                                                   placeholder="Enter Your Email" label={"Enter Your Email"}
                                                   type="text" variant="outlined"/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                    fullWidth={true}
                                                    type="submit"
                                                    label="Reset Password"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={pristine || submitting}
                                                    loading={submitting}
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Link className={classes.clickableLink + " " + " mt15 "} variant="body2"
                                                      onClick={() => history.push("/login")}>
                                                    <ArrowBackIcon
                                                            className={classes.clickableLinksvg + " " + classes.mr10}/>
                                                    Back to Login
                                                </Link>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item xs={12} md={5} lg={5} className={classes.dpNone}>
                                <Typography variant="h3" align="right"
                                            className={classes.textWhite + " " + classes.mr30 + " " + classes.titleMain}>
                                    Forgot Password
                                </Typography>
                                <img className={classes.floatRight + " " + classes.imgResponsive} src={login}
                                     alt="login"/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>

    );
}


export default reduxForm({
    form: "forgotPasswordForm",  // a unique identifier for this form
    validate,
})(ForgotPassword);
