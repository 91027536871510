import React from 'react';
import { createMuiTheme as createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Slider, SliderProps, Tooltip, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

interface _SliderProps extends SliderProps {
    className?: string;
    handleChange?: Function;
    mainTitle?: any;
    name?: string;
    sliderClassName?: any;
    textOne?: string;
}

interface VLCProps {
    children: any;
    open: boolean;
    value: number;
}

function _ValueLabelComponent(props: VLCProps) {
    const {children, open, value} = props;
    const renderText = (value: string) => (
            <Tooltip arrow open={open} enterTouchDelay={0} placement="top"  title={<span className="f14">{value}</span>}>
                {children}
            </Tooltip>
    ) as React.ReactNode;
    return (
            <NumberFormat
                    value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={renderText}
            />
    );
}

const ASlider = createTheme({
    overrides: {
        MuiSlider: {
            root: {
                color: '#3DDB93',
                height: 8
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '4px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus,&:hover,&$active': {
                    boxShadow: '0px 0px 0px 14px rgb(61 219 147 / 16%)'
                }
            },
            valueLabel: {
                left: 'calc(-50% + 14px)',
                top: '-40px',
                '& *': {
                    background: 'transparent',
                    color: '#000'
                }
            },
            track: {
                height: 8,
                borderRadius: 4
            },
            rail: {
                height: 8,
                borderRadius: 4,
                backgroundColor: '#cfcfcf',
            }
        }
    }
});

export default function _Slider(props: _SliderProps) {
    const {className, mainTitle, name, sliderClassName, ...custom} = props;
    const classes = makeStyles({
        root: {
            width: 'auto',
        },
        input: {
            color: '#3DDB93',
            marginLeft: '10px',
            marginTop: '-10px',
            maxWidth: 200,
            overflow: 'auto',
        },
    })();
    const handleSliderChange = (_event: any, newValue: number | number[]) => {
        if (typeof props.handleChange === 'function') {
            props.handleChange(newValue);
        }
    };

    const sliderProps: _SliderProps = {
        'aria-labelledby': 'input-slider',
        className: sliderClassName,
        onChangeCommitted: handleSliderChange,
        step: 5000,
        ValueLabelComponent: _ValueLabelComponent,
        valueLabelDisplay: 'on',
        ...custom
    };

    return (
            <div className={classes.root}>
                {
                        mainTitle &&
                        <Typography variant="caption" className={className}>
                            {mainTitle}
                        </Typography>
                }
                <ThemeProvider theme={ASlider}>
                    <Slider className="" {...sliderProps} />
                </ThemeProvider>
            </div>
    );
}
