import * as Yup from "yup";
import {WhenOptions} from "yup";
import {
    AdventureSportsPIQ69,
    BungeeJumping,
    ClimbingSuffix,
    Diving66,
    DivingExperience66,
    ExerciseEnum,
    getInsuredQuestions,
    PIQ,
    proposedInsuredQuestion64,
    proposedInsuredQuestion65,
    proposedInsuredQuestion65TopQues,
    proposedInsuredQuestion66,
    proposedInsuredQuestion66TopQues,
    proposedInsuredQuestion67,
    proposedInsuredQuestion67TopQues,
    proposedInsuredQuestion68,
    proposedInsuredQuestion68TopQues,
    proposedInsuredQuestion75,
    proposedInsuredQuestion77,
    SkyDivingParticipationSuffix,
    SkyDivingSuffix,
} from "./InsuredQuestions";
import {numArray, unmaskCurrency} from "../../../../../../utils/commonUtils";
import {isLongForm} from "../Utilities";
import {BS} from "../../../../../../model/misc";
import {
    ADDITIONAL_TEXT_FIELD_TYPE as Additional_Text_Type,
    OTHER_VALUE as OtherVal,
    REQUIRED_FIELD,
    YesNoEnum
} from "../../../../../../constants";


interface CVBProps {
    asBuilder?: boolean;
    fieldName: string;
    grandParentFieldName?: string;
    grandParentValue?: string;
    parentFieldName: string;
    parentValue?: string;
    valType: ValType;
}


enum ValType {
	Array,
	String,
}

export enum FelonyMisdemeanorPrefix {
	PIQ71X = "Proposed_Insured_Questions-71X",
	PIQ72X = "Proposed_Insured_Questions-72X",
	PIQ73X = "Proposed_Insured_Questions-73X",
	PIQ74X = "Proposed_Insured_Questions-74X",
}

export enum FMFieldsSuffix {
	FMType = "Rfmq",
	CrimeInvolved = "Tinv",
	DateOfProbationPeriod = "Tpp",
	CountyStateInvolved = "Tsi"
}

export enum FlyFieldsSuffix {
    AnnualFlyingHours = "Taf",
    ExpectedAnnualFlyingHours = "Teaf",
    NumberOfSoloHours = "Tnsh",
    DateLastPiloted = "Dlp"
}

export enum ProposedInsuredQuestionsKeys {
    PIQ55 = "Proposed_Insured_Questions-55",
    PIQ60 = "Proposed_Insured_Questions-60XC",
    PIQ61 = "Proposed_Insured_Questions-61XR",
    PIQ62 = "Proposed_Insured_Questions-62XR",
    PIQ63 = "Proposed_Insured_Questions-63XC",
    PIQ64 = "Proposed_Insured_Questions-64",
	PIQ65 = "Proposed_Insured_Questions-65",
	PIQ75 = "Proposed_Insured_Questions-75",
	PIQ66 = "Proposed_Insured_Questions-66",
	PIQ67 = "Proposed_Insured_Questions-67",
	PIQ68 = "Proposed_Insured_Questions-68",
	PIQ69 = "Proposed_Insured_Questions-69",
	PIQ70 = "Proposed_Insured_Questions-70",
    // PIQ84 = "Proposed_Insured_Questions-84XC",
    // PIQ85 = "Proposed_Insured_Questions-85XC",
    // PIQ86 = "Proposed_Insured_Questions-86",
    // PIQ87 = "Proposed_Insured_Questions-87"
}

const [ADDITIONAL_TEXT_FIELD_TYPE, OTHER_VALUE, YES_VAL, REQUIRED]: [string, string, string, string] = [Additional_Text_Type, OtherVal, YesNoEnum.Yes, REQUIRED_FIELD];

const _commonBuilder: WhenOptions<any> = {
	is: YES_VAL,
	then: Yup.string().required(REQUIRED),
	otherwise: Yup.string().nullable(),
};

const getOjProp = (object: any, key: string, notFound: any = undefined) => {
    return object && object.hasOwnProperty(key) && typeof object[key] === 'object' ? object[key] : notFound;
};

/**
 * common fun yup.when() - only for yup.string()
 * @param parentName
 * @param parentMatcherValue
 * @param grandParentName
 * @param grandParentMatcherValue
 */
const makeFieldRequiredByWhen = (parentName: string, parentMatcherValue: BS = YES_VAL, grandParentName?: string, grandParentMatcherValue: BS = YES_VAL) => {
    const pBuilder = {
        ..._commonBuilder,
        is: parentMatcherValue,
    };
    const pBuilderThen = Yup.string().ensure().when(parentName, pBuilder).nullable();
    const gpBuilder = {
        ..._commonBuilder,
        is: grandParentMatcherValue,
        then: pBuilderThen
    };
    return !!grandParentName ? Yup.string().ensure().when(grandParentName, gpBuilder).nullable() : pBuilderThen;
}

/**
 * common fun yup.test()
 * @param name
 * @param asArray
 * @param parentMatcherValue
 * @param matcherField
 * @param grandParentFieldName
 * @param grandParentValue
 * @param isNotDesc
 * @param grandGrandParentFieldName
 * @param grandGrandParentValue
 */
const makeFieldRequiredByTest = (name: string, asArray: boolean = false, parentMatcherValue: BS = YES_VAL, matcherField?: string, grandParentFieldName?: string, grandParentValue?: string, isNotDesc?: boolean, grandGrandParentFieldName?: string, grandGrandParentValue?: string) => {
    return Yup.string().ensure().test(name, REQUIRED, function (value: any) {
        let hasRequiredField = false;

        if (asArray) {
            const parentVal = getOjProp(this.parent, name, []);
            const field = parentVal.find(v => v.name === matcherField && v.value === parentMatcherValue);
            const fieldMatch = !isNotDesc ? (field && !field.desc) && !value : !!field && !value;
            hasRequiredField = !grandGrandParentFieldName ? (
                !grandParentFieldName ?
                    fieldMatch :
                    fieldMatch && this.parent[`${grandParentFieldName}`] === (grandParentValue || parentMatcherValue)
            ) : (fieldMatch && this.parent[`${grandParentFieldName}`] === (grandParentValue || parentMatcherValue) && this.parent[`${grandGrandParentFieldName}`] === (grandGrandParentValue || grandParentValue || parentMatcherValue));
        } else {
            hasRequiredField = !grandGrandParentFieldName ? (
                !grandParentFieldName ?
                    this.parent[`${name}`] === parentMatcherValue && !value : // (value === undefined || (value && value.trim()) === "")
                    this.parent[`${grandParentFieldName}`] === (grandParentValue || parentMatcherValue) && this.parent[`${name}`] === parentMatcherValue && !value
            ) : (
                this.parent[`${grandGrandParentFieldName}`] === (grandGrandParentValue || grandParentValue || parentMatcherValue) &&
                this.parent[`${grandParentFieldName}`] === (grandParentValue || parentMatcherValue) &&
                this.parent[`${name}`] === parentMatcherValue && !value
            );
        }

        return !hasRequiredField;
    });
};

/**
 * common fun yup.object.test()
 * @param parentName
 * @param parentValue
 * @param hasAdditional
 * @param grandParentFieldName
 * @param grandParentValue
 */
const makeFieldRequiredObjectByTest = (parentName: string, parentValue: string = YES_VAL, hasAdditional?: boolean, grandParentFieldName?: string, grandParentValue?: string) => {
    return Yup.object().test(parentName, REQUIRED, function (value: any) {
        const parentVal = getOjProp(this.parent, parentName, {});
        const matchCondition = hasAdditional ? (parentVal && parentVal.value === parentValue) : parentVal === parentValue;
        const checkForRequired = !grandParentFieldName ?
            matchCondition :
            matchCondition && (grandParentFieldName && this.parent[grandParentFieldName] === (grandParentValue || parentValue || YES_VAL));

        return (checkForRequired && (hasAdditional ? !!parentVal.desc : !!value)) || !checkForRequired;
    });
};

/**
 * common fun yup.array.test()
 * @param parentName
 * @param parentValue
 * @param grandParentFieldName
 * @param grandParentValue
 */
const makeFieldRequiredArrayByTest = (parentName: string, parentValue: string = YES_VAL, grandParentFieldName?: string, grandParentValue?: string) => {
    return Yup.array().ensure().test(parentName, REQUIRED, function (value: any) {
        const checkForRequired = !grandParentFieldName ?
            this.parent[parentName] === parentValue : (
                this.parent[parentName] === parentValue &&
                (grandParentFieldName && this.parent[grandParentFieldName] === (grandParentValue || parentValue))
            );

        return (checkForRequired && !!value.length) || !checkForRequired;
    });
};

/**
 * common fun to get additional sub text field
 * @param field
 */
const getAFIfHasASubFT = (field: {additionalSubFieldName: string, additionalSubFieldType: string, name: string}) => {
    const hasAdditionalField = !!field.additionalSubFieldName && field.additionalSubFieldType === ADDITIONAL_TEXT_FIELD_TYPE;
    return {additionalSubFieldName: field.additionalSubFieldName, hasAdditionalField, name: field.name}
};
/**
 * common fun to get additional text field
 * @param field
 */
const getAFIfHasAFT = (field: {additionalFieldName: string, additionalFieldType: string, name: string}) => {
    const hasAdditionalField = !!field.additionalFieldName && field.additionalFieldType === ADDITIONAL_TEXT_FIELD_TYPE;
    return {additionalFieldName: field.additionalFieldName, hasAdditionalField, name: field.name}
};

/**
 * Returns true if question matches the name to show and value matches the desired value
 * @param values
 * @param questionName
 * @param name
 * @param value
 */
export const showIfApplicable = (values: any, questionName: string, name: string, value: string = YES_VAL) => {
	return (questionName === name && values[name] === value);
};

type GVType = (isAssociation: boolean, isCorporation: boolean, isFromClientDetail: boolean, offerType: string, user: any, formContext: any) => any;

/**
 * Get insurance page Validations Main () => {}
 * @param isAssociation
 * @param isCorporation
 * @param isFromClientDetail
 * @param offerType
 * @param user
 * @param formContext
 */
export const getValidations: GVType = (isAssociation, isCorporation, isFromClientDetail, offerType, user, formContext) => {
	try {
		let _customValidationBuilderKeysArr: CVBProps[] = [];
		let _validationKeys = getInsurancePageValidationsOld(isAssociation, isCorporation, isFromClientDetail, offerType);
		const _questions = getInsuredQuestions({ offerType, isCorporation, isAssociation, user });
		const _questionsToShow = _questions.filter(el => el.show);

        // validating ProposedInsuredQuestionsKeys.PIQ70:
        const _valSchema70 = _getValidationFor70();
        _validationKeys = { ..._validationKeys, ..._valSchema70 };


		_questionsToShow.forEach((parentField) => {
            // validations for all parent questions - no dependency
            if (parentField.type === 'radio') {
                _validationKeys[parentField.name] = Yup.string().ensure().required(REQUIRED);
            }

			// validations for nested questions
			switch (parentField.name) {
				case ProposedInsuredQuestionsKeys.PIQ55:
					const _valSchema55 = _getValidationFor55(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema55 };
                    break;
				case ProposedInsuredQuestionsKeys.PIQ60:
					const _valSchema60 = _getValidationFor60(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema60 };
                    break;
				case ProposedInsuredQuestionsKeys.PIQ61:
					const _valSchema61 = _getValidationFor61(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema61 };
                    break;
				case ProposedInsuredQuestionsKeys.PIQ62:
					const _valSchema62 = _getValidationFor62(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema62 };
                    break;
				case ProposedInsuredQuestionsKeys.PIQ63:
					const _valSchema63 = _getValidationFor63(parentField);
					_validationKeys = { ..._validationKeys, ..._valSchema63 };
                    break;
				case ProposedInsuredQuestionsKeys.PIQ64:
					const _valSchema64 = _getValidationFor64(offerType, parentField.name);
					_customValidationBuilderKeysArr = _customValidationBuilderKeysArr.concat(_valSchema64);
					break;
				case ProposedInsuredQuestionsKeys.PIQ75:
					const _valSchema75 = _getValidationFor75(offerType, parentField.name);
					_validationKeys = { ..._validationKeys, ..._valSchema75 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ65:
					const _valSchema65 = _getValidationFor65(offerType, parentField.name);
					_validationKeys = { ..._validationKeys, ..._valSchema65 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ66:
					const _valSchema66 = _getValidationFor66(offerType, parentField.name);
					_validationKeys = { ..._validationKeys, ..._valSchema66 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ67:
					const _valSchema67 = _getValidationFor67(offerType, parentField.name, formContext);
					_validationKeys = { ..._validationKeys, ..._valSchema67 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ68:
					const _valSchema68 = _getValidationFor68(offerType, parentField.name);
					_validationKeys = { ..._validationKeys, ..._valSchema68 };
					break;
				case ProposedInsuredQuestionsKeys.PIQ69:
					const _valSchema69 = _getValidationFor69(parentField.name);
					_validationKeys = { ..._validationKeys, ..._valSchema69 };
					break;
                // case ProposedInsuredQuestionsKeys.PIQ84:
                //     const _valSchema84 = _getValidationFor84(parentField);
                //     _validationKeys = { ..._validationKeys, ..._valSchema84 };
                //     break;
                // case ProposedInsuredQuestionsKeys.PIQ85:
                //     const _valSchema85 = _getValidationFor84(parentField);
                //     _validationKeys = { ..._validationKeys, ..._valSchema85 };
                //     break;
                // case ProposedInsuredQuestionsKeys.PIQ86:
                //     const _valSchema86 = _getValidationFor86(parentField);
                //     _validationKeys = { ..._validationKeys, ..._valSchema86 };
                //     break;
                // case ProposedInsuredQuestionsKeys.PIQ87:
                //     const _valSchema87 = _getValidationFor86(parentField);
                //     _validationKeys = { ..._validationKeys, ..._valSchema87 };
                //     break;
			}
		});

        // alternate method (if original is not working)
        _customValidationBuilderKeysArr.forEach(customValidationBuilderKeys => {
            const {
                asBuilder,
                fieldName,
                grandParentFieldName,
                grandParentValue,
                parentFieldName,
                parentValue,
                valType,
            } = customValidationBuilderKeys;
            _validationKeys[`${fieldName}`] =
				valType === ValType.Array ?
					Yup.array().when(parentFieldName, _commonBuilder).nullable() :
                    asBuilder ?
                        makeFieldRequiredByWhen(parentFieldName, parentValue, grandParentFieldName, grandParentValue) :
                        makeFieldRequiredByTest(parentFieldName, false, parentValue, undefined, grandParentFieldName, grandParentValue);
		});

        // validating inForcePolicies willThisBeReplaced_desc (Surrender_Charge_Percentage) case
        const _inFPSchema = _getValidationForInForcePolicies();
        _validationKeys = { ..._validationKeys, ..._inFPSchema };


        // validating exercise routine of the last 12 months
        const _valSchema82 = _getValidationFor82();
        _validationKeys = { ..._validationKeys, ..._valSchema82 };

		return _validationKeys;
	} catch (e) {
		console.error(e);
	}
};

type GVOLDType = (isAssociation: boolean, isCorporation: boolean, isFromClientDetail: boolean, offerType: string) => any;

/**
 * Old_getInsurancePageValidations existing - copied as it is from InsuredQuestionsContent.tsx
 * @param isAssociation
 * @param isCorporation
 * @param isFromClientDetail
 * @param offerType
 */
const getInsurancePageValidationsOld: GVOLDType = (isAssociation, isCorporation, isFromClientDetail, offerType) => {
	const _keysValidation = {};
	let validateDataFields = [
		"noOfPolicies",
		"inforcePolicy",
		"noOfInforcePolicies",
		"inforceAmount",
		"liAmount",
		"inforceLifeDesc",
		"insuranceType",
		"businessInsurance",
		"personalInsurance",
	];

	if (offerType === "gi-to-full" || offerType === "si-to-full") {
		validateDataFields = [
			"noOfPolicies",
			"inforcePolicy",
			"noOfInforcePolicies",
			"inforceAmount",
			"liAmount",
			"inforceLifeDesc",
			"insuranceType",
			"businessInsurance",
			"personalInsurance",
		];
	}
	if (offerType === "full") {
		validateDataFields = [
			"noOfPolicies",
			"inforcePolicy",
			"noOfInforcePolicies",
			"inforceLifeDesc",
			"insuranceType",
			"businessInsurance",
			"personalInsurance",
		];
	}
	if (offerType === "gi") {
		validateDataFields = ["noOfPolicies"];
	}
	if (offerType === "gi" && isAssociation) {
		validateDataFields = ["noOfPolicies"];
	}

	if ((offerType === "si" || offerType === "gi") && isCorporation) {
		let insuranceTypeIndex = validateDataFields.indexOf("insuranceType");
		if (insuranceTypeIndex !== -1) {
			validateDataFields.splice(insuranceTypeIndex, 3);
		}
	}

	if(isLongForm(offerType)){
        validateDataFields = [
            "noOfPolicies",
            "inforcePolicy",
            "noOfInforcePolicies",
            "inforceLifeDesc",
            "insuranceType",
            "businessInsurance",
            "personalInsurance",
            "liAmount",
            "inforceAmount"
        ];
    }

	if (isFromClientDetail) {
		validateDataFields = [];
	}

	validateDataFields.forEach((field: any) => {
		if (field === "noOfPolicies") {
			_keysValidation[field] = Yup.string().ensure().when("existPolicies", _commonBuilder);
		} else if (field === "noOfInforcePolicies") {
			_keysValidation[field] = Yup.string().ensure().when("inforcePolicy", _commonBuilder);
		} else if (field === "inforceAmount") {
			_keysValidation[field] = Yup.string().when("inforceAmountNone", {
				is: true,
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().test(
					"inforceAmount",
					"Min value should be 1 or more",
					function() {
						const inforceAmountValue = Number(unmaskCurrency(this.parent.inforceAmount));
						return (inforceAmountValue > 0);
					},
				).required(REQUIRED),
			});
		} else if (field === "liAmount") {
			_keysValidation[field] = Yup.string().when("liAmountNone", {
				is: true,
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().test(
					"liAmount",
					"Min value should be 1 or more",
					function() {
						const liAmountValue = Number(unmaskCurrency(this.parent.liAmount));
						return (liAmountValue > 0);
					},
				).required(REQUIRED),
			});
		} else if (field === "inforceLifeDesc") {
			_keysValidation[field] = Yup.string().when("isInforceLife", {
				is: "No",
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().required(REQUIRED),
			});
		} else if (field === "businessInsurance") {
			_keysValidation[field] = Yup.string().when("insuranceType", {
				is: "personalInsurance",
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().required(REQUIRED),
			}).nullable();
		} else if (field === "personalInsurance") {
			_keysValidation[field] = Yup.string().when("insuranceType", {
				is: "businessInsurance",
				then: Yup.string().notRequired(),
				otherwise: Yup.string().trim().required(REQUIRED),
			}).nullable();
		} else {
			_keysValidation[field] = Yup.string().required(REQUIRED).nullable();
		}
	});


	return _keysValidation;
};

/**
 * GetValidationFor55
 * @param parentField
 */
const _getValidationFor55 = (parentField: any) => {
    const valSchema55 = {};

    // check if additional field
    const {additionalFieldName, hasAdditionalField, name} = getAFIfHasAFT(parentField);

    if (!hasAdditionalField) {
        return;
    }

    valSchema55[`${additionalFieldName}`] = makeFieldRequiredByTest(name);

    return valSchema55;
};

/**
 * GetValidationFor60
 * @param parentField
 */
const _getValidationFor60 = (parentField: any) => {
    const valSchema60 = {};
    valSchema60[`${parentField.name}`] = Yup.array().min(1, REQUIRED).required(REQUIRED);

    // check if additional subfield
    const asf = getAFIfHasASubFT(parentField);
    if (asf.hasAdditionalField) {
        valSchema60[`${asf.additionalSubFieldName}`] = Yup.string().ensure().required(REQUIRED);
    }

    return valSchema60;
};

/**
 * GetValidationFor61
 * @param parentField
 */
const _getValidationFor61 = (parentField: any) => {
    const valSchema61 = {};
    valSchema61[`${parentField.name}`] = Yup.object().required(REQUIRED);
    return valSchema61;
};

/**
 * GetValidationFor62
 * @param parentField
 */
const _getValidationFor62 = (parentField: any) => {
    const valSchema62 = {};
    valSchema62[`${parentField.name}`] = Yup.object().required(REQUIRED);
    return valSchema62;
};

/**
 * GetValidationFor63
 * @param parentField
 */
const _getValidationFor63 = (parentField: any) => {
    const valSchema63 = {};
    valSchema63[`${parentField.name}`] = Yup.array().min(1, REQUIRED).required(REQUIRED);
    return valSchema63;
};

type GVType64 = (offerType: string, parentFieldName: string) => CVBProps[];

/**
 * GetValidationFor64 validations for level-n nest of questions
 * @param offerType
 * @param parentFieldName
 */
const _getValidationFor64: GVType64 = (offerType: string, parentFieldName: string) => {
	const _keysBuilder: CVBProps[] = [];
    // get nested qs for 64
	const _insuredQuestion64fields: any[] = proposedInsuredQuestion64(offerType).map(({additionalFieldName, additionalFieldType, name}) => ({additionalFieldName, additionalFieldType, name}));
	const _insuredQuestion64: string[] = _insuredQuestion64fields.map(s => s.name);

    /**
     * Get Felony Misdemeanor Keys
     * @param matchKey
     */
    const getFMKeys = (matchKey: string) => {
        const nestedKeysFelonyMisdemeanor: string[] = [];
        const felonyMisdemeanorPrefixes = Object.keys(FelonyMisdemeanorPrefix);
        const fmfSuffixes = Object.keys(FMFieldsSuffix);
        for (const fmpKey of felonyMisdemeanorPrefixes) {
            // Get the value for the fmpKey
            const fmpValue = FelonyMisdemeanorPrefix[fmpKey];

            if (matchKey.indexOf(fmpValue) > -1) {
                for (const fmsKey of fmfSuffixes) {
                    const felonyMisdemeanorNestedField: string = `${fmpValue}${FMFieldsSuffix[fmsKey]}`;
                    nestedKeysFelonyMisdemeanor.push(felonyMisdemeanorNestedField);
                }
            }
        }

        return nestedKeysFelonyMisdemeanor;
    };

	_insuredQuestion64.forEach((fieldName, index) => {
        // add each field in validations builder
        _keysBuilder.push({ fieldName, parentFieldName, valType: ValType.String });

        // check if it has nesting sub-lvl
		const _nestedKeysFelonyMisdemeanor = getFMKeys(fieldName);
		_nestedKeysFelonyMisdemeanor.forEach(felonyMisdemeanorNestedField => {
            // push each sub-field in builder, w.r.t parent and grand_parent_field
            _keysBuilder.push({
                fieldName: felonyMisdemeanorNestedField,
                grandParentFieldName: parentFieldName,
                parentFieldName: fieldName,
                valType: ValType.String
            });
		});

        // check if additional field
        const {additionalFieldName, hasAdditionalField, name} = getAFIfHasAFT(_insuredQuestion64fields[index]);

        if (!hasAdditionalField) {
            return;
        }

        // add nest lvl-1 field in validations builder
        _keysBuilder.push({
            fieldName: additionalFieldName,
            grandParentFieldName: parentFieldName,
            parentFieldName: name,
            valType: ValType.String
        });
	});

	return _keysBuilder;
};

/**
 * GetValidationFor65
 * @param offerType
 * @param parentFieldName
 */
const _getValidationFor65 = (offerType: string, parentFieldName: string) => {
	const valSchema65 = {};

    // get nested qs for 65
	const _insuredQuestion65: any[] = proposedInsuredQuestion65(offerType);
	const _insuredQuestion65TopFields: any[] = proposedInsuredQuestion65TopQues(offerType);
	const _insuredQuestion75Fields: any[] = proposedInsuredQuestion77({offerType});
	const _insuredQuestion65Top: string[] = _insuredQuestion65TopFields.map(s => s.name);

    const cpa65 = () => {
        valSchema65['racingValidation'] = Yup.string().test('racingValidation', REQUIRED, function () {
            if(this.parent[`${PIQ}-65`] === "No"){
                return true;
            }
            const [v1, v2, v3, v4, v5]: any[][] = [
                this.parent[`${PIQ}-65XCpa1`],
                this.parent[`${PIQ}-65XCpa2`],
                this.parent[`${PIQ}-65XCpa3`],
                this.parent[`${PIQ}-65XCpa4`],
                this.parent[`${PIQ}-65XCpa5`]
            ];

            if ((v1 && v1.length === 0) && (v2 && v2.length === 0) && (v3 && v3.length === 0) && (v4 && v4.length === 0) && (v5 && v5.length === 0)) {
                return false;
            }
            if (v3 && v3.length > 0) {
                let v3val: any = v3.find((v) => v.value === OTHER_VALUE)
                return !(v3val && v3val.desc === undefined);

            } else if (v4 && v4.length > 0) {
                let v4val: any = v4.find((v) => v.value === OTHER_VALUE)
                return !(v4val && v4val.desc === undefined);

            } else if (v5 && v5.length > 0) {
                let v5val: any = v5.find((v) => v.value === OTHER_VALUE)
                return !(v5val && v5val.desc === undefined);

            } else {
                return true;
            }
        })
    };
    cpa65();

	_insuredQuestion65Top.forEach((fieldName, index) => {
        const foundAdditional = _insuredQuestion65TopFields[index];
        const hasOtherAdditional = foundAdditional.additionalFieldOptions.findIndex(s => s.value === OTHER_VALUE);
        const otherFieldName = `${fieldName}_${hasOtherAdditional}`;
        const otherDescFieldName = `${otherFieldName}_desc`;

        // validations for _insuredQuestion65Top_ check boxes
        valSchema65[`${fieldName}`] = makeFieldRequiredArrayByTest(parentFieldName, YES_VAL);

        // validations for _insuredQuestion65Top_ check boxes other desc
        if (hasOtherAdditional > -1) {
            valSchema65[`${otherDescFieldName}`] = makeFieldRequiredByTest(fieldName, true, OTHER_VALUE, otherFieldName, parentFieldName, YES_VAL);
        }
	});

	_insuredQuestion65.forEach((field, _index) => {
        const fieldName = field.name;

        // validations for _insuredQuestion65_last_two77-78 check boxes
        valSchema65[`${fieldName}`] = makeFieldRequiredByTest(parentFieldName);

        // check if additional field
        const {additionalFieldName, hasAdditionalField, name} = getAFIfHasAFT(field);

        if (!hasAdditionalField) {
            return;
        }

        valSchema65[`${additionalFieldName}`] = makeFieldRequiredByTest(name, false, YES_VAL, undefined, parentFieldName);
    });

    _insuredQuestion75Fields.forEach((f) => {
        const subParent: string = `${PIQ}-77`;
        const fieldName = f.name;
        const hasOtherAdditional = f.additionalFieldOptions.findIndex(s => s.value === OTHER_VALUE);
        const otherFieldName = `${fieldName}_${hasOtherAdditional}`;
        const otherDescFieldName = `${otherFieldName}_desc`;

        valSchema65[`${fieldName}`] = makeFieldRequiredArrayByTest(subParent, YES_VAL, parentFieldName);

        if (hasOtherAdditional > -1) {
            valSchema65[`${otherDescFieldName}`] = makeFieldRequiredByTest(fieldName, true, OTHER_VALUE, otherFieldName, subParent, YES_VAL, false, parentFieldName, YES_VAL);
        }
    });
	return valSchema65;
};

/**
 * GetValidationFor66
 * @param offerType
 * @param parentFieldName
 */
const _getValidationFor66 = (offerType: string, parentFieldName: string) => {
	const valSchema66 = {};

    const _insuredQuestion66: any[] = proposedInsuredQuestion66(offerType);
    const _insuredQuestion66TopQues: any[] = proposedInsuredQuestion66TopQues(offerType);

    _insuredQuestion66TopQues.forEach((field) => {
        const fieldName = field.name;

        // const foundAdditional = _insuredQuestion66TopQues[index];
        // const hasOtherAdditional = foundAdditional.additionalFieldOptions.findIndex(s => s.value === OTHER_VALUE);
        // const otherFieldName = `${fieldName}_${hasOtherAdditional}`;
        // const otherDescFieldName = `${otherFieldName}_desc`;

        // validations for _insuredQuestion66Top_ check boxes
        valSchema66[`${fieldName}`] = field.additionalFieldType === 'radioBoxGroup' ?
            makeFieldRequiredObjectByTest(parentFieldName) :
            makeFieldRequiredByTest(parentFieldName);

        // valSchema66[`${additionalFieldName}`] = makeFieldRequiredByWhen(name, YES_VAL, parentFieldName);

        // // TODO - parent value is getting [object Object]
        // // validations for _insuredQuestion65Top_ check boxes other desc
        // if (hasOtherAdditional > -1) {
        //     valSchema66[`${otherDescFieldName}`] = field.additionalFieldType === 'radioBoxGroup' ?
        //         makeFieldRequiredObjectByTest(fieldName, OTHER_VALUE, true, parentFieldName, YES_VAL) :
        //         makeFieldRequiredByTest(fieldName, true, OTHER_VALUE, otherFieldName, parentFieldName, YES_VAL);
        // }
    });

    _insuredQuestion66.forEach(field => {
        valSchema66[`${field.name}`] = makeFieldRequiredByTest(parentFieldName);

        // check if additional field
        const {additionalFieldName, hasAdditionalField, name} = getAFIfHasAFT(field);

        if (!hasAdditionalField) {
            return;
        }

        valSchema66[`${additionalFieldName}`] = makeFieldRequiredByTest(name, false, YES_VAL, undefined, parentFieldName);
	});

    // validating Diving66
    const diving66Suffix = Object.keys(Diving66);
    for (const diveSuffixKey of diving66Suffix) {
        const divingTextField = `${parentFieldName}X${Diving66[diveSuffixKey]}`;
        valSchema66[divingTextField] = makeFieldRequiredByTest(parentFieldName);
    }

    // validating Diving_Experience66 sub fields based on specific answers
    const divingExperience66Parent: string = `${PIQ}-66XCex`;
    const divingExperience66FaKeys = numArray(6);
    // const divingExperience66Answers = [
    //     "Decompression Illness",
    //     "Decompression Sickness (CDs)",
    //     "Arterial Gas Embolism (AGE)/air embolism",
    //     "Bends",
    //     "None of the Above",
    //     "Diving Accident"
    // ];
    const divingExperience66Keys = Object.keys(DivingExperience66);
    const divingExperience66Fields: string[] = [];

    for (const faKey of divingExperience66FaKeys) {
        for (const divingExperience66Key of divingExperience66Keys) {
            const _divingExperience66Field = `${divingExperience66Parent}X${faKey}${DivingExperience66[divingExperience66Key]}`;
            if (!divingExperience66Fields.includes(_divingExperience66Field)) {
                divingExperience66Fields.push(_divingExperience66Field);
                if (faKey !== 4) {
                    // // TODO - parent value is getting [object Object]
                    // valSchema66[`${_divingExperience66Field}`] = Yup.string().ensure().test(_divingExperience66Field, REQUIRED, function (value: any) {
                    //     const [matcherKey, raAns] = [`${divingExperience66Parent}_${faKey}`, divingExperience66Answers[faKey]];
                    //     const matchArr = getOjProp(this.parent, divingExperience66Parent, []);
                    //     const matchFound = matchArr.find(i => i.name === matcherKey && i.value === raAns);
                    //     const checkForRequired = (this.parent[parentFieldName] === YES_VAL && !!matchFound);
                    //
                    //     return (checkForRequired && value && !!value.length) || !checkForRequired;
                    // });
                    // valSchema66[`${_divingExperience66Field}`] = makeFieldRequiredByTest(divingExperience66Parent, true, divingExperience66Answers[faKey], `${divingExperience66Parent}_${faKey}`, parentFieldName, YES_VAL, true);
                }
            }
        }
    }

	return valSchema66;
};

/**
 * GetValidationFor67
 * @param offerType
 * @param parentFieldName
 * @param formContext
 */
const _getValidationFor67 = (offerType: string, parentFieldName: string, formContext: any) => {
	const valSchema67 = {};

	const _insuredQuestion67TopQS: any[] = proposedInsuredQuestion67TopQues(offerType);
	const _insuredQuestion67: any[] = proposedInsuredQuestion67(offerType);

    _insuredQuestion67TopQS.forEach(field => {
        // validate top67 w.r.t. parent
		valSchema67[field.name] = makeFieldRequiredByTest(parentFieldName);

        const testRequired = (testName: string, parentMatcherValue?: string) => {
            return Yup.mixed().test(testName, REQUIRED, function (value: any) {
                const checkForRequired = this.parent[parentFieldName] === YES_VAL && (!parentMatcherValue ? !!this.parent[field.name] : (this.parent[field.name].value === parentMatcherValue && !!this.parent[field.name].desc));
                return (checkForRequired && !!value) || !checkForRequired;
            });
        };

        const fv = formContext && formContext.current && formContext.current.values;
        if (field.name === `${PIQ}-67XRnp` && (fv[field.name] && fv[field.name].value === "Military")) {
            const skyDiving67ParticipationFields = Object.keys(SkyDivingParticipationSuffix);
            for (const sd67AFKey of skyDiving67ParticipationFields) {
                const skyDiving67ParticipationField = `${parentFieldName}X${SkyDivingParticipationSuffix[sd67AFKey]}`;
                valSchema67[skyDiving67ParticipationField] = testRequired(skyDiving67ParticipationField);
            }

            // Removed as per requirement in docu_sign
            // const skyDiving67ParticipationStaticHaloField = `${PIQ}-66XCsl`;
            // valSchema67[skyDiving67ParticipationStaticHaloField] = testRequired(skyDiving67ParticipationStaticHaloField);

            const skyDiving67ParticipationOtherField = `${PIQ}-67XRnp_5_desc`;

            valSchema67[skyDiving67ParticipationOtherField] = testRequired(skyDiving67ParticipationOtherField, OTHER_VALUE);
        }
    });

	_insuredQuestion67.forEach(field => {
        // check if additional field
        const {additionalFieldName, hasAdditionalField, name} = getAFIfHasAFT(field);

        // validate sub67 w.r.t. parent
        valSchema67[field.name] = makeFieldRequiredByTest(parentFieldName);

        // special additional fields for PIQ80
        if (field.name === `${PIQ}-80`) {
            const skyDiving80AdditionalFields = Object.keys(SkyDivingSuffix);
            for (const sd80AFKey of skyDiving80AdditionalFields) {
                const skyDiving80AdditionalField = `${field.name}X${SkyDivingSuffix[sd80AFKey]}`;
                valSchema67[skyDiving80AdditionalField] = makeFieldRequiredByTest(parentFieldName);
            }
        }

        if (!hasAdditionalField) {
            return;
        }

        // validate additional
        valSchema67[`${additionalFieldName}`] = makeFieldRequiredByTest(name, false, YES_VAL, undefined, parentFieldName);
    });

	return valSchema67;
};

/**
 * GetValidationFor68
 * @param offerType
 * @param parentFieldName
 */
const _getValidationFor68 = (offerType: string, parentFieldName: string) => {
	const valSchema68 = {};
	const _insuredQuestion68TopQues: any[] = proposedInsuredQuestion68TopQues(offerType);
	const _insuredQuestion68: string[] = proposedInsuredQuestion68(offerType).map((s: any) => s.name);

	_insuredQuestion68TopQues.forEach(field => {
        const fieldName = field.name;
		valSchema68[fieldName] = makeFieldRequiredByTest(parentFieldName);

        // check if additional field
        const {additionalFieldName, hasAdditionalField, name} = getAFIfHasAFT(field);

        if (!hasAdditionalField) {
            return;
        }

        valSchema68[`${additionalFieldName}`] = makeFieldRequiredByTest(name, false, YES_VAL, undefined, parentFieldName);
	});
	_insuredQuestion68.forEach((fieldName) => {
        valSchema68[fieldName] = makeFieldRequiredByTest(parentFieldName);
        const piq82 = `${PIQ}-82`;
        if (fieldName === piq82) {
            const climbing82Fields = Object.keys(ClimbingSuffix);
            for (const faKey of climbing82Fields) {
                const climbing82Field = `${fieldName}${ClimbingSuffix[faKey]}`;

                valSchema68[climbing82Field] = makeFieldRequiredByTest(parentFieldName);
            }
        }
	});

	return valSchema68;
};

/**
 * GetValidationFor69
 */
const _getValidationFor69 = (parentFieldName: string) => {
    const valSchema69 = {};
    AdventureSportsPIQ69.forEach((ads, inx) => {
        const chkFieldName = `${parentFieldName}XC_${inx}`;
        const bungeeJumpingKeys = Object.keys(BungeeJumping);
        bungeeJumpingKeys.forEach(bjKey => {
            const advSpFieldName = `${ads.name}${BungeeJumping[bjKey]}`;
            valSchema69[advSpFieldName] = Yup.string().test(`${advSpFieldName}`, REQUIRED, function (){
                if((this.parent[chkFieldName] && this.parent[parentFieldName] === "Yes") && (!(!!(this.parent[advSpFieldName])))){
                    return false
                }
                return true
            })
        });
    })
    return valSchema69;
};

/**
 * GetValidationFor70
 */
const _getValidationFor70 = () => {
    const valSchema70 = {};
    valSchema70[`${PIQ}-70Xfa`] = Yup.array().when(`${PIQ}-70`, {
        ..._commonBuilder,
        then: Yup.array()
            .of(
                Yup.object().shape({
                    Con: Yup.array().min(1, REQUIRED).required(REQUIRED).nullable(),
                    Rlen: Yup.string().required(REQUIRED).nullable(),
                    Dan: Yup.string().required(REQUIRED).nullable(),
                    Tplan: Yup.string().required(REQUIRED).nullable()
                })
            ).required(REQUIRED),
        otherwise: Yup.array().nullable(),
    });

    return valSchema70;
};

/**
 * GetValidationFor82
 */
const _getValidationFor82 = () => {
    const proposedInsuredQuestions83XCeeValidate = (name: string, fn: string) => {
        return Yup.string().ensure().test(name, REQUIRED, function (val) {
            const checkRequired = this.parent[`${PIQ}-83XCee`] === false && this.parent[fn] === true;
            return (checkRequired && !!val) || !checkRequired;
        });
    };

    const fieldName82X = `${PIQ}-82X`;
    const valSchema82Fields = [
        {name: `${fieldName82X}Rypf`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.YOGA}`},
        {name: `${fieldName82X}Ryps`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.YOGA}`},
        {name: `${fieldName82X}Typsa`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.YOGA}`},
        {name: `${fieldName82X}Rlif`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.LIC}`},
        {name: `${fieldName82X}Rlis`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.LIC}`},
        {name: `${fieldName82X}Tlisa`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.LIC}`},
        {name: `${fieldName82X}Rmif`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.MIC}`},
        {name: `${fieldName82X}Rmis`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.MIC}`},
        {name: `${fieldName82X}Tmia`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.MIC}`},
        {name: `${fieldName82X}Rhif`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.HIC}`},
        {name: `${fieldName82X}Rhis`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.HIC}`},
        {name: `${fieldName82X}Thia`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.HIC}`},
        {name: `${fieldName82X}Rwlf`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.WL}`},
        {name: `${fieldName82X}Rwls`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.WL}`},
        {name: `${fieldName82X}Twla`, requiredFieldName: `${fieldName82X}C_${ExerciseEnum.WL}`},
    ];
    const valSchema82 = {};

    valSchema82Fields.forEach(({name, requiredFieldName}) => {
        valSchema82[name] = proposedInsuredQuestions83XCeeValidate(name, requiredFieldName);
    });

    return valSchema82;
};

/**
 * GetValidationFor84
 * @param parentField
 */
// const _getValidationFor84 = (parentField: any) => {
//     const valSchema84 = {};
//
//     valSchema84[`${parentField.name}`] = Yup.array().min(1, REQUIRED).required(REQUIRED);
//
//     // check if additional sub field
//     const asf = getAFIfHasASubFT(parentField);
//
//     if (asf.hasAdditionalField) {
//         valSchema84[`${asf.additionalSubFieldName}`] = Yup.string().ensure().required(REQUIRED);
//     }
//
//     return valSchema84;
// };

/**
 * GetValidationFor86
 * @param parentField
 */
// const _getValidationFor86 = (parentField: any) => {
//     const valSchema86 = {};
//
//     // check if additional sub field
//     const asf = getAFIfHasASubFT(parentField);
//
//     if (asf.hasAdditionalField) {
//         valSchema86[`${asf.additionalSubFieldName}`] = Yup.string().ensure().required(REQUIRED);
//     }
//
//     return valSchema86;
// };

/**
 * GetValidationFor75
 * @param offerType
 * @param parentFieldName
 */
const _getValidationFor75 = (offerType: string, parentFieldName: string) => {
    const _keysValidation = {};

    const _insuredQuestion75: any[] = proposedInsuredQuestion75(offerType);
    _insuredQuestion75.forEach(field => {
        const fieldName = field.name;

        // field lvl-1 validation
        _keysValidation[fieldName] = fieldName === `${PIQ}-76` ? makeFieldRequiredByTest(parentFieldName) : makeFieldRequiredArrayByTest(parentFieldName);

        if (field.additionalFieldOptions) {
            const hasOtherAdditional = field.additionalFieldOptions.findIndex((s: any) => s.value === OTHER_VALUE);
            const otherFieldName = `${fieldName}_${hasOtherAdditional}`;
            const otherDescFieldName = `${otherFieldName}_desc`;

            // check if other addition option field (desc)
            if (hasOtherAdditional === -1) {
                return;
            }

            // other desc validation
            _keysValidation[otherDescFieldName] = makeFieldRequiredByTest(fieldName, true, OTHER_VALUE, otherFieldName, parentFieldName, YES_VAL);
        }

        // check if additional field
        const {additionalFieldName, hasAdditionalField, name} = getAFIfHasAFT(field);

        if (!hasAdditionalField) {
            return;
        }

        // validate additional
        _keysValidation[`${additionalFieldName}`] = makeFieldRequiredByTest(name, false, YES_VAL, undefined, parentFieldName);
    });

    const flyingFieldsSuffix = Object.keys(FlyFieldsSuffix);
    for (const flySuffixKey of flyingFieldsSuffix) {
        const flyTextField = `${parentFieldName}X${FlyFieldsSuffix[flySuffixKey]}`;
        _keysValidation[flyTextField] = makeFieldRequiredByTest(parentFieldName);
    }

    enum FlyViolations {
        DateOfEvent = 'Dev',
        ProvideDetails = 'Ral',
        DrugsOrAlcohol = 'Tal',
    }
    // making it dynamic
    const flyViolationsGrandParent: string = `${PIQ}-75`;
    const flyViolationsParent: string = `${PIQ}-75XCav`;
    const flyViolationsFaKeys = numArray(4);
    const flyViolationsAnswers = ['Aviation violation', 'Aviation accident', 'License suspension or revocation', 'Been grounded'];
    const flyViolationKeys = Object.keys(FlyViolations);
    const flyViolationFields: string[] = [];

    for (const faKey of flyViolationsFaKeys) {
        for (const flyViolationKey of flyViolationKeys) {
            const _flyViolationField = `${flyViolationsGrandParent}X${faKey}${FlyViolations[flyViolationKey]}`;
            if (!flyViolationFields.includes(_flyViolationField)) {
                flyViolationFields.push(_flyViolationField);
                _keysValidation[_flyViolationField] = makeFieldRequiredByTest(flyViolationsParent, true, flyViolationsAnswers[faKey], `${flyViolationsParent}_${faKey}`, parentFieldName, YES_VAL, true);
            }
        }
    }

    return _keysValidation;
};

/**
 * GetValidationForInForcePolicies
 */
const _getValidationForInForcePolicies = () => {
    const _keysValidation = {};
    const [inForcePolicy, inForcePolicies] = ['inforcePolicy', 'inForcePolicies'];
    const _infPolicyShape = {
        coverageFaceAmount: Yup.string().required(REQUIRED).nullable(),
        nameOfCompany: Yup.string().required(REQUIRED).nullable(),
        // TODO not linked with db, also values not updating on text_change
        // willThisBeReplaced_desc: Yup.string().when('willThisBeReplaced', _commonBuilder).nullable(),
        // willThisBeReplaced_desc: makeFieldRequiredByTest('willThisBeReplaced'),
        surrenderChargePercentage: Yup.string().when('willThisBeReplaced', _commonBuilder).nullable(),
        type: Yup.string().required(REQUIRED).nullable(),
        willThisBeReplaced: Yup.string().required(REQUIRED).nullable(),
        status: Yup.string().required(REQUIRED).nullable(),
        willBothPoliciesBeTaken: Yup.string().when('status', {
            is: 'Applied For',
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().nullable(),
        }).nullable()
    };

    _keysValidation[inForcePolicies] = Yup.array().when(inForcePolicy, {
        ..._commonBuilder,
        then: Yup.array().of(Yup.object().shape(_infPolicyShape)).required(REQUIRED),
        otherwise: Yup.array().nullable()
    });

    return _keysValidation;
};
