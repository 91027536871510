import * as React from "react";
import { Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

interface downloadLink {
    filePath: string,
    fileName: string,
    linkName: string,
    type: string
}

export default function DownloadLink(props: downloadLink) {
    const downloadFile = () => {
        fetch(props.filePath)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = props.fileName;
                        a.click();
                    });
                });

    };
    return (
            <React.Fragment>
                <Typography variant="body2"
                            className="floatLeft mt0 mb10 colorBlue w100"
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                            onClick={downloadFile}>{props.linkName} <GetAppIcon className="ml15" /> </Typography>
            </React.Fragment>
    );
}
