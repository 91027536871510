import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions,
    IconButton,
    Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import { CheckBox } from "./formInputs";
import { RootState } from "../reducers";
import { useActions } from "../actions";
import * as Actions from "../actions/account";

const styles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose } = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function AdditionalCarrier(props: any) {
    const { children, handleSubmit, pristine, submitting } = props;
    const { dialog, handleDialogClose, agentId, agentCarrierArr } = children;
    const additionalCarrier = useSelector((state: RootState) => state.additionalCarrier);
    const accountActions = useActions(Actions);
    const { addAgentCarrier } = accountActions;
    // Handle Submit
    const updateAdditionalCarrier = async (data: any) => {
        let dataObj: object[] = [];
        data.carriers.forEach((v: any, idx: number) => {
            if (v.isCheck) {
                dataObj.push(Object.assign({}, additionalCarrier.data[idx], { agentCode: "", id: agentId }));
            }
        });
        await addAgentCarrier(dataObj);
        handleDialogClose();
    };

    const isAdditionalCarrier = () => {
        let isMoreItem = false;
        additionalCarrier.data && additionalCarrier.data.forEach((c: any, idx: number) => {
            if (agentCarrierArr && c.carrier && !agentCarrierArr.find((p: number) => p === c.carrier.id))
                isMoreItem = true;
        });
        return isMoreItem;
    };
    return (
            <Dialog
                    fullWidth={true}
                    maxWidth="sm" onClose={handleDialogClose} aria-labelledby="customized-dialog-title" open={dialog}>

                <>
                    <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
                        Additional Carriers
                    </DialogTitle>
                    <DialogContent dividers>
                        {
                            additionalCarrier.data &&
                            additionalCarrier.data.map((c: any, idx: number) =>
                                    (agentCarrierArr && c.carrier && !agentCarrierArr.find((p: number) => p === c.carrier.id))
                                    && <div className="checkGroup">
                                        <Field
                                                className="mb0"
                                                key={c.carrier.id}
                                                label={c.carrier.carrierName}
                                                name={`carriers.${idx}.isCheck`}
                                                component={CheckBox}
                                                onClick={(val: any) => {
                                                }}
                                        />
                                    </div>,
                            )
                        }
                        {
                            !isAdditionalCarrier() &&
                            <Typography variant="body1" className="mb0">I am sorry your IMO does not have additional
                                carriers available.</Typography>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                                className="btnPrimary"
                                onClick={handleSubmit(updateAdditionalCarrier)}
                                variant="contained"
                                color="primary"
                                disabled={pristine || submitting}
                        >
                            Add Carrier
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
    );
}

function mapStateToProps(state: RootState) {
    return {
        initialValues: {},
    };
}

const reduxConnect = reduxForm({
    form: "additionalCarrierForm",
    enableReinitialize: true,
})(AdditionalCarrier);

export default connect(mapStateToProps)(reduxConnect);