import {parse} from 'json2csv';
import moment from "moment";
import {ActionType, CsvField} from "../model";
import {getClientIP} from "./account";
import {ApprovedTabsEnum} from "../pages/approvedAgents/ApprovedAgentsPage";
import {downloadAsCSV, pez} from "../utils/commonUtils";

type AIMATP = (payload: any) => (dispatch: Function, getState: Function, api: { post: Function }) => Promise<any>;
type EAAL = (status: number, searchText: string, region: string) => (dispatch: Function, getState: Function, api: { get: Function }) => Promise<any>;
type GARR = (status: string, searchText: string, region: string) => (dispatch: Function, getState: Function, api: { get: Function }) => Promise<any>;
type GAD = (id: number) => (dispatch: Function, getState: Function, api: { get: Function }) => Promise<any>;
type GST = () => (dispatch: Function, getState: Function, api: { get: Function }) => Promise<any>;

const API_PATH_AAR = 'agent-requests';

/**
 * Get Approved List
 * @param status
 * @param searchText
 * @param region
 */
export const getAgentRegistrationRequests: GARR = (status, searchText, region) => async (dispatch, getState, api)  => {
    try {
        let resp = await api.get(API_PATH_AAR, {}, {status, searchText, region});
        dispatch({payload: resp, type: ActionType.APPROVED_LIST});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

/**
 * Get Approved (Agent) Stats
 */
export const getStats: GST = () => async (dispatch, getState, api)  => {
    try {
        let resp = await api.get(`${API_PATH_AAR}/stats`);
        dispatch({payload: resp, type: ActionType.APPROVED_STATS});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

export const approveAgent = (id: number) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
        let resp = await api.put(`${API_PATH_AAR}/${id}/approve`);
		dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Agent approved successfully.",
                statusCode: 200
            }
        });
		return resp;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const rejectAgent = (id: number, rejectionReason: string, message: string) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
        const ip = dispatch(getClientIP());
        let resp = await api.put(`${API_PATH_AAR}/${id}/reject`, { ip, rejectionReason }, {});
		dispatch({
            type: ActionType.ALERT,
            payload: {
                message: pez(message, "Agent deleted successfully."),
                statusCode: 200
            }
        });
		return resp;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

/**
 * Get Approved Details
 * @param id
 */
export const getDetails: GAD = (id) => async (dispatch, getState, api)  => {
	try {
		const resp = await api.get(`${API_PATH_AAR}/${id}`);
        dispatch({payload: resp, type: ActionType.APPROVED_DETAILS});
		return resp;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
}

export const getImoRegistrationRequests = (payload: any) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
	try {
		let resp = await api.get('imo-registration-requests', {}, payload);
        dispatch({payload: resp, type: ActionType.APPROVED_IMO_LIST});
		return resp;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
		return false;
	}
};

export const getImoRequestByImoId = (agentImoId: number)  => async (dispatch: Function, getState: Function, api: { get: Function })  => {
	try {
		let resp = await api.get(`imo-registration-requests/by-agent-imo/${agentImoId}`, {}, {});
		return resp;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const approveImo = (id: number) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
        let resp = await api.put(`imo-registration-requests/${id}/approve`);
		dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "IMO approved successfully.",
                statusCode: 200
            }
        });
		return resp;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

export const rejectImo = (id: number, rejectionReason: string) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
        const ip = dispatch(getClientIP());
        let resp = await api.put(`imo-registration-requests/${id}/reject`, { ip, rejectionReason }, {});
		dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "IMO deleted successfully.",
                statusCode: 200
            }
        });
		return resp;
	} catch(err) {
		dispatch({
			type: ActionType.ALERT,
			payload: err
		});
	}
};

/**
 * Get Agent Documents (Seller_Docs)
 * @param agentId
 */
export const getAgentDocs = (agentId: number) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        if (!agentId) {
            return;
        }
        const res = await api.get(`${API_PATH_AAR}/${agentId}/get-docs`);
        dispatch({payload: res.data, type: ActionType.LOAD_AGENT_DOCS});
        return res.data;
    } catch (err) {
        dispatch({payload: err, type: ActionType.ALERT});
        return false;
    }
};

/**
 * Flush Agent Seller Docs
 */
export const flushAgentDocs = () => async (dispatch) => {
    try {
        return dispatch({type: ActionType.FLUSH_AGENT_DOCS, payload: []});
    } catch (err) {
        dispatch({type: ActionType.ALERT, payload: err});
        throw err;
    }
};

/**
 * Flush Approved (Agent) Stats
 */
export const flushStats = () => async (dispatch)  => {
    return dispatch({type: ActionType.FLUSH_APPROVED_STATS, payload: {}});
};

/**
 * Flush Approved List
 */
export const flushApprovedList = () => async (dispatch)  => {
    return dispatch({type: ActionType.FLUSH_APPROVED_LIST, payload: []});
};

/**
 * Flush Approved Details
 */
export const flushApprovedDetails = () => async (dispatch)  => {
    return dispatch({type: ActionType.FLUSH_APPROVED_DETAILS, payload: {}});
};

/**
 * Get Approved List
 * @param status
 * @param searchText
 * @param region
 */
export const exportApprovedAgentList: EAAL = (status, searchText, region) => async (dispatch, getState, api)  => {
    try {
        const data = await api.get(`${API_PATH_AAR}/export`, {}, {region, searchText, status});
        const fields: CsvField [] = [
            {
                label: 'Agent Full Name',
                value: 'agentName'
            },
            {
                label: 'Email',
                value: 'agentEmail'
            },
            {
                label: 'Work Phone',
                value: 'agentPhone'
            },
            {
                label: 'Region',
                value: 'region'
            },
            {
                label: 'Date Received',
                value: (row: any) => row.createdAt ? moment(row.createdAt).format('MM-DD-YYYY') : ''
            }
        ];
        if (status === ApprovedTabsEnum.Deleted) {
            fields.push({label: 'Rejection Reason', value: 'rejectionReason'});
        }
        const [fileName, csvRes] = [`${ApprovedTabsEnum[status]}-List`, parse(data, {fields})];

        downloadAsCSV(fileName, csvRes);

        return dispatch({
            payload: {message: `${ApprovedTabsEnum[status]} list exported`, statusCode: 200},
            type: ActionType.ALERT,
        });
    } catch (err) {
        dispatch({payload: err, type: ActionType.ALERT});
        return false;
    }
};

export const addIMOMoveAgentToPending: AIMATP = (payload) => async (dispatch, getState, api) => {
    try {
        const res = await api.post(`${API_PATH_AAR}/add-rejected-agent-imo`, payload);
        dispatch({payload: {message: res.message, statusCode: 200}, type: ActionType.ALERT})
    } catch (err) {
        dispatch({ payload: err, type: ActionType.ALERT });
        return false;
    }
};

/**
 * Flush Approved IMO List
 */
export const flushApprovedIMOList = () => async (dispatch)  => {
    return dispatch({type: ActionType.FLUSH_APPROVED_IMO_LIST, payload: []});
};
